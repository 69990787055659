import * as React from 'react'
import moment from 'moment'
import { Avatar, Box, Chip, Grid, Link, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { ListChildComponentProps } from 'react-window'
import { Result } from './Result'
import { Facebook, Instagram, NotListedLocation, OpenInNew } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import { countries } from '../core/init/init-localization-context'
import { HmstrColors } from '../core/theme/hmstr-theme'
import { XIcon } from '../common/icons/XIcon'

type ResultEntryProps = ListChildComponentProps<Result[]>

export const ResultEntry = React.forwardRef<HTMLLIElement, ResultEntryProps>((props, ref) => {
    const { style, index, data } = props
    const result = data[index]
    const intl = useIntl()
    const country = countries.getName(result.extra_source_attributes_world_data_country_code, intl.locale)

    const getImage = (result: Result) => {
        let Icon: any

        if (result.source_type.includes('FACEBOOK')) {
            Icon = (
                <Avatar style={{ backgroundColor: '#385898' }}>
                    <Facebook />
                </Avatar>
            )
        } else if (result.source_type.includes('TWITTER')) {
            Icon = (
                <Avatar style={{ backgroundColor: HmstrColors.Platforms.X }}>
                    <XIcon />
                </Avatar>
            )
        } else if (result.source_type.includes('INSTAGRAM')) {
            Icon = (
                <Avatar style={{ backgroundColor: 'rgb(242,29,96)' }}>
                    <Instagram />
                </Avatar>
            )
        } else {
            Icon = (
                <Avatar src={result.images_url || '#'}>
                    <NotListedLocation />
                </Avatar>
            )
        }

        return <ListItemAvatar>{Icon}</ListItemAvatar>
    }

    const handleLinkClick = (url: string) => {
        const windowAsAny = window as any
        windowAsAny.open(url, '_blank').focus()
    }

    return (
        <ListItem key={result.url} style={style} ref={ref}>
            <Box display="flex" alignItems="center" width="100%">
                {getImage(result)}
                <ListItemText
                    primary={
                        <Link color="inherit" href={result.url} target="_blank">
                            {result.title || result.root_url.split('/')[2]}
                        </Link>
                    }
                    secondary={result.content_snippet}
                />
            </Box>
            <Box marginTop={1} paddingLeft={7} width="100%">
                <Grid container spacing={1} justifyContent="flex-start">
                    {result.published && (
                        <Grid item>
                            <Chip size="small" label={moment(result.published_ts).format('lll')} />
                        </Grid>
                    )}
                    <Grid item>
                        <Chip size="small" label={<FormattedMessage id={result.source_type} />} />
                    </Grid>
                    {country && (
                        <Grid item>
                            <Chip size="small" label={country} />
                        </Grid>
                    )}
                    <Grid item>
                        <Chip
                            size="small"
                            label={result.domain_url ? result.domain_url.split('/')[2] : ''}
                            onClick={() => handleLinkClick(result.domain_url)}
                        />
                    </Grid>
                    <Grid item>
                        <Chip size="small" icon={<OpenInNew />} label="Link" onClick={() => handleLinkClick(result.url)} />
                    </Grid>
                    {Boolean(result.reach) && result.reach && result.reach > 0 && (
                        <Grid item>
                            <Chip
                                size="small"
                                label={
                                    <FormattedMessage
                                        id="results.reach-value"
                                        values={{
                                            value: intl.formatNumber(result.reach),
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    )}
                    {Boolean(result.engagement) && result.engagement && result.engagement > 0 && (
                        <Grid item>
                            <Chip
                                size="small"
                                label={
                                    <FormattedMessage
                                        id="results.engagement-value"
                                        values={{
                                            value: intl.formatNumber(result.engagement),
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </ListItem>
    )
})
