import { createAsyncThunk } from '@reduxjs/toolkit'
import { Account, BillingInfo } from './Account'
import { HmstrState } from '../../core/Store'
import { ApiLink } from '../../core/api/ApiLink'
import { UserAccountAccess } from '../user-management/User'
import { doDelete, doGet, doPost, doPut } from '../../core/api/ApiClient'
import { AccountFormValues } from './AccountFormValues'

const ACCOUNTS_SLASH = (action: string) => `accounts/${action}`

export const fetchAccountsForUser = createAsyncThunk<Account[], void, { state: HmstrState }>(ACCOUNTS_SLASH('fetchAccountsForUser'), async (args, thunkAPI) => {
    const state = thunkAPI.getState()
    return await doGet(thunkAPI, state.api.entryPoint._links.accounts)
})

export const fetchAccount = createAsyncThunk<Account, ApiLink, { state: HmstrState }>(ACCOUNTS_SLASH('fetch'), async (accountLink, thunkAPI) => {
    return await doGet(thunkAPI, accountLink)
})

export const createAccount = createAsyncThunk<Account, AccountFormValues, { state: HmstrState }>(
    ACCOUNTS_SLASH('create'),
    async (accountFromValues, thunkApi) => {
        return await doPost(thunkApi, thunkApi.getState().api.entryPoint._links.accounts, accountFromValues)
    }
)

export const updateAccount = createAsyncThunk<Account, { values: AccountFormValues; account: Account }, { state: HmstrState }>(
    ACCOUNTS_SLASH('update'),
    async ({ account, values }, thunkAPI) => {
        return await doPut(thunkAPI, account._links.self, values)
    }
)

export const updateAccountBillingInfo = createAsyncThunk<Account[], { billingInfo: BillingInfo; account: Account }, { state: HmstrState }>(
    ACCOUNTS_SLASH('updateBillingInfo'),
    async ({ account, billingInfo }, thunkAPI) => {
        return await doPut(thunkAPI, account._links.billing_info, billingInfo)
    }
)

export const upgradeAccount = createAsyncThunk<Account, { values: AccountFormValues; account: Account }, { state: HmstrState }>(
    ACCOUNTS_SLASH('upgrade'),
    async ({ values, account }, thunkAPI) => {
        return await doPut(thunkAPI, account._links.upgrade, values)
    }
)

export const updateAccountPackage = createAsyncThunk<Account, { values: AccountFormValues; account: Account }, { state: HmstrState }>(
    ACCOUNTS_SLASH('updatePackage'),
    async ({ account, values }, thunkAPI) => {
        return doPut(thunkAPI, account._links.package, values)
    }
)

export const updateAccountAdditionals = createAsyncThunk<Account, { values: AccountFormValues; account: Account }, { state: HmstrState }>(
    ACCOUNTS_SLASH('updateAdditionals'),
    async ({ account, values }, thunkAPI) => {
        return doPut(thunkAPI, account._links.additionals, values)
    }
)

export const deleteAccount = createAsyncThunk<UserAccountAccess, UserAccountAccess, { state: HmstrState }>(
    ACCOUNTS_SLASH('delete'),
    async (accountAccess, thunkAPI) => {
        return await doDelete(thunkAPI, accountAccess._links.account, () => accountAccess)
    }
)
