import * as React from 'react'
import { FC } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Add, IosShare, Language, MoreHoriz } from '@mui/icons-material'
import { AppInstall } from './microinteractions/AppInstall'
import { getMobileOperatingSystem } from '../core/theme/helper'

type PleaseInstallStandaloneProps = { onContinue: () => void }

export const PleaseInstallStandalone: FC<PleaseInstallStandaloneProps> = ({ onContinue }) => {
    const operatingSystem = getMobileOperatingSystem()
    return (
        <Box
            position={'fixed'}
            height={'100dvh'}
            width={'100vw'}
            component={Paper}
            elevation={0}
            borderRadius={0}
            zIndex={'modal'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            p={2}
            flexDirection={'column'}
        >
            <Box flexGrow={1} />
            <Box width={'100%'} maxWidth={'600px'}>
                <AppInstall />
            </Box>
            {operatingSystem !== 'unknown' && (
                <Typography textAlign={'center'} maxWidth={'600px'}>
                    <FormattedMessage id={'general.please-install-app-part-one'} />
                    <Box
                        display={'inline-flex'}
                        sx={{
                            transform: 'translateY(5px)',
                        }}
                    >
                        {operatingSystem === 'android' ? <MoreHoriz /> : <IosShare />}
                    </Box>
                    <FormattedMessage id={`general.please-install-app-part-two-${operatingSystem}`} />
                    {operatingSystem === 'ios' && (
                        <>
                            {' '}
                            &nbsp;
                            <Box
                                display={'inline-flex'}
                                sx={{
                                    aspectRatio: 1,
                                    borderRadius: 1,
                                    border: '2px solid ',
                                    borderColor: 'primary',
                                    alignItems: 'center',
                                    transform: 'translateY(5px) scale(0.9)',
                                    mr: 0.5,
                                }}
                            >
                                <Add fontSize={'small'} />
                            </Box>
                        </>
                    )}
                    <FormattedMessage id={'general.please-install-app-part-three'} />
                </Typography>
            )}
            <Box flexGrow={1} />
            <Button fullWidth startIcon={<Language />} onClick={onContinue} color={'secondary'} variant={'contained'}>
                <FormattedMessage id={'mobile.continue-in-browser'} />
            </Button>
        </Box>
    )
}
