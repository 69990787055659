import { Box, Button, Card, CardActions, CardContent, CardMedia, List, ListItem, Tooltip, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { FC } from 'react'
import { DashboardTemplate, DashboardTemplateChannelConfig } from '../../dashboards/DashboardTemplate'
import { BrokenImage, Euro, Facebook, Instagram, LinkedIn } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import { HmstrColors } from '../../core/theme/hmstr-theme'
import { getAlertMessageForChannelConfig } from '../../dashboards/getMessageForChannelConfig'

type TemplatePreviewProps = {
    template: DashboardTemplate
    headline: string
    description: string
    onSelect: (template: DashboardTemplate) => void
    selected: boolean
    id: string
    image?: string
}

export const TemplatePreview: FC<TemplatePreviewProps> = ({ template, headline, description, image, onSelect, selected, id }) => {
    const intl = useIntl()
    const theme = useTheme()

    const getDatasourceInfo = (channelConfig: DashboardTemplateChannelConfig) => {
        let icon

        switch (channelConfig.type) {
            case 'FACEBOOK_PAGE':
                icon = <Facebook style={{ fontSize: '24px', marginLeft: '2px', color: HmstrColors.DarkGrey }} />
                break
            case 'FACEBOOK_AD_ACCOUNT':
                icon = (
                    <Euro
                        style={{
                            fontSize: '24px',
                            marginLeft: '2px',
                            paddingRight: '4px',
                            color: HmstrColors.DarkGrey,
                        }}
                    />
                )
                break
            case 'TALKWALKER_JSON':
                break
            case 'LINKED_IN':
                icon = (
                    <LinkedIn
                        style={{
                            fontSize: '24px',
                            marginLeft: '2px',
                            paddingRight: '4px',
                            color: HmstrColors.DarkGrey,
                        }}
                    />
                )
                break
            case 'INSTAGRAM_ACCOUNT':
                icon = (
                    <Instagram
                        style={{
                            fontSize: '24px',
                            marginLeft: '2px',
                            color: HmstrColors.DarkGrey,
                        }}
                    />
                )
                break
        }

        return (
            <Tooltip placement="top" arrow={true} title={getAlertMessageForChannelConfig(intl, channelConfig) || ''} key={channelConfig.type}>
                <ListItem sx={{ padding: 0 }}>{icon}</ListItem>
            </Tooltip>
        )
    }

    return (
        <Card
            id={id}
            sx={{
                position: 'relative',
                border: '1px solid',
                borderColor: selected ? HmstrColors.Orange : 'transparent',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ position: 'relative', paddingBottom: '64.1%' }}>
                {image && (
                    <CardMedia
                        component="img"
                        image={image}
                        sx={{
                            objectFit: 'cover',
                            padding: '16px',
                            borderRadius: '20px',
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            left: '0px',
                            top: '0px',
                        }}
                    />
                )}
                {!image && (
                    <Box
                        sx={{
                            position: 'absolute',
                            padding: '16px',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: theme.palette.background.default,
                            }}
                        >
                            <BrokenImage sx={{ fontSize: '56px', color: theme.palette.action.active }} />
                        </Box>
                    </Box>
                )}
            </Box>

            <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
                <List sx={{ padding: 0 }}>{template.data_sources.map((dataSource) => getDatasourceInfo(dataSource))}</List>
            </Box>
            <CardContent>
                <Typography variant="h5">
                    <FormattedMessage id={headline} />
                </Typography>
                <FormattedMessage id={description} />
            </CardContent>
            <CardActions sx={{ marginTop: 'auto', padding: '12px 16px' }}>
                <Button
                    color={'secondary'}
                    className={'guide_selectTemplate'}
                    variant={selected ? 'contained' : 'outlined'}
                    onClick={() => onSelect(template)}
                    fullWidth
                >
                    {selected ? <FormattedMessage id="template.preview.selected" /> : <FormattedMessage id="template.preview.select" />}
                </Button>
            </CardActions>
        </Card>
    )
}
