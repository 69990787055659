import { User } from '../../settings/user-management/User'

export const orderedAvailableTours: Partial<keyof User['settings']['viewed_tutorials']>[] = [
    'primarySidebarTour',
    'secondarySidebarTour',
    'publishingFormTour',
    'publishingFormIndividualizeTour',
    'engagementTour',
    'settingsTour',
]
