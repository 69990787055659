import { CommonPostData } from './CommonPostData'
import * as React from 'react'
import { FC } from 'react'
import { Box, DialogContent, Link } from '@mui/material'
import { Fallback } from '../../common/fallback/Fallback'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { FormattedMessage } from 'react-intl'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'

type TiktokPostPreviewProps = {
    post?: CommonPostData
}

export const TiktokPostPreview: FC<TiktokPostPreviewProps> = ({ post }) => {
    let setInnerHTML = function (elm: HTMLElement, html: string) {
        elm.innerHTML = html
        Array.from(elm.querySelectorAll('script')).forEach((oldScript: HTMLScriptElement) => {
            const newScript = document.createElement('script')
            Array.from(oldScript.attributes).forEach((attr: Attr) => newScript.setAttribute(attr.name, attr.value))
            newScript.appendChild(document.createTextNode(oldScript.innerHTML))
            if (oldScript.parentNode) {
                oldScript.parentNode.replaceChild(newScript, oldScript)
            }
        })
    }

    useEffectWithIdComparison(() => {
        const videoContainer = document.getElementById('tiktok-post-preview')
        if (videoContainer && post?.embedded_html) {
            setInnerHTML(videoContainer, post.embedded_html)
        }
    }, [post])

    return (
        <>
            <DialogContent>
                <Box width={500}>
                    <Fallback
                        condition={!Boolean(post?.embedded_html)}
                        marginTop={4}
                        messageId="facebook.preview.could-not-load"
                        actionButton={
                            <Link href={post?.perma_link} target="_blank" style={{ display: 'flex', justifyContent: 'center' }}>
                                <OpenInNewIcon style={{ margin: '0 8px 0 0' }} />
                                <FormattedMessage id="general.open-link" />
                            </Link>
                        }
                    >
                        <div id={'tiktok-post-preview'}></div>
                    </Fallback>
                </Box>
            </DialogContent>
        </>
    )
}
