import * as React from 'react'
import { FC, useState } from 'react'
import * as _ from 'lodash'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { TagAutocomplete } from '../content-analytics/tags/TagAutocomplete'
import { TagStatusFilterType } from '../common/filter/TagStatusFilterType'
import { Cancel, LocalOffer } from '@mui/icons-material'
import { TagChip } from '../content-analytics/tags/TagChip'
import { FacebookPostTagData, updateFacebookPostsData } from '../content-analytics/posts/PostsActions'
import { useDispatch, useSelector } from 'react-redux'
import { getTags } from '../core/slices/DataSlice'
import { HmstrDispatch } from '../core/Store'
import { Field, useForm, useFormState } from 'react-final-form'
import { PublishingFormValues } from './PublishingForm'
import { getSelectedProject } from '../core/slices/CoreSlice'
import { getCommonPostsByPostId } from '../engagement/EngagementSlice'
import { getSelectedPostGroup } from './PublishingSlice'
import { checkEqualRawArrays } from '../core/helpers/check-equal-raw-arrays'

type PublishingTaggingComponentProps = {}

export const PublishingTaggingComponent: FC<PublishingTaggingComponentProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const form = useForm()
    const formState = useFormState<PublishingFormValues>()
    const project = useSelector(getSelectedProject)
    const tagsIdMap = useSelector(getTags)
    const [taggingDialogOpen, setTaggingDialogOpen] = useState(false)
    const selectedPostGroup = useSelector(getSelectedPostGroup)
    const selectedTagIdsForPost = formState.values.tag_ids || []
    const selectedTagsForPost = selectedTagIdsForPost.map((tagId) => tagsIdMap[tagId]).filter((tag) => !!tag)
    const posts = useSelector(getCommonPostsByPostId)
    const allPostIds = _.map(selectedPostGroup?.posts || [], (post) => {
        return post.post_id
    })
    const relevantPosts = _.chain(posts)
        .map((post) => {
            if (allPostIds.includes(post.post_id)) {
                return post
            } else {
                return undefined
            }
        })
        .compact()
        .value()

    const generateRemainingTags = () => {
        if (selectedTagsForPost.length > 3) {
            return (
                <Box display={'flex'} flexDirection={'column'} flexWrap={'wrap'} gap={0.5}>
                    {selectedTagsForPost.map((tag, idx) => {
                        return (
                            idx >= 3 && (
                                <Box display={'flex'} key={tag.id} gap={0.5} alignItems={'center'}>
                                    <Cancel
                                        onClick={() => handleDeleteTag(tag.id)}
                                        sx={{
                                            color: tag.color,
                                            background: tag.color,
                                            borderRadius: '100%',
                                            fontSize: '18px',
                                            padding: 0,
                                            transition: 'all 0.1s',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                background: 'transparent',
                                                color: theme.palette.primary.contrastText,
                                            },
                                        }}
                                    />
                                    <Typography id={tag.id} variant={'subtitle2'}>
                                        {tag.label}
                                    </Typography>
                                </Box>
                            )
                        )
                    })}
                </Box>
            )
        } else {
            return ''
        }
    }

    const dispatchUpdate = (newTags: string[]) => {
        if (formState.values.common_post.state !== 'PUBLISH_SUCCESS') {
            return
        }

        let postData: FacebookPostTagData[] = []
        relevantPosts.forEach((post) => {
            if (post) {
                postData.push({
                    _id: post.id,
                    post_id: post.post_id,
                    tag_ids: newTags,
                    data_source_id: post.data_source_id,
                })
            }
        })

        if (project) {
            dispatch(
                updateFacebookPostsData({
                    project: project,
                    postData: postData,
                })
            )
        }
    }
    const handleDeleteTag = (tag: string) => {
        form.change(
            'tag_ids',
            selectedTagIdsForPost.filter((t) => t !== tag)
        )
        dispatchUpdate(selectedTagIdsForPost.filter((t) => t !== tag))
    }

    return (
        <Field name="tag_ids" isEqual={checkEqualRawArrays}>
            {({ input }) => (
                <Box display="flex" gap={1} alignItems="center">
                    <Box id="publishing_tagging_button">
                        <Tooltip title={<FormattedMessage id={'general.add-tags'} />} disableInteractive>
                            <Chip
                                size={'small'}
                                label={
                                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LocalOffer sx={{ fontSize: '20px' }} />
                                    </Typography>
                                }
                                onClick={() => setTaggingDialogOpen(true)}
                            />
                        </Tooltip>
                    </Box>
                    <Grid container spacing={0.5}>
                        {selectedTagsForPost &&
                            selectedTagsForPost.map((tag, idx) => {
                                if (idx < 3) {
                                    return (
                                        <Grid key={tag.id} item>
                                            <TagChip
                                                size={'small'}
                                                onClick={() => setTaggingDialogOpen(true)}
                                                tag={tag}
                                                additionalProps={{ onDelete: () => handleDeleteTag(tag.id) }}
                                            />
                                        </Grid>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        {selectedTagIdsForPost && selectedTagIdsForPost.length > 3 && (
                            <Grid key={'remainingtagschip'} item>
                                <Tooltip title={generateRemainingTags()} placement={'bottom-start'}>
                                    <Chip size={'small'} onClick={() => setTaggingDialogOpen(true)} label={`+ ${selectedTagIdsForPost.length - 3}`} />
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>

                    <Dialog open={taggingDialogOpen} onClose={() => setTaggingDialogOpen(false)} fullWidth maxWidth={'lg'}>
                        <DialogTitle>
                            <FormattedMessage id={'general.add-tags'} />
                        </DialogTitle>
                        <DialogContent>
                            <TagAutocomplete
                                tagFilter={TagStatusFilterType.ALL}
                                onTagsChange={(tags) => input.onChange(tags)}
                                multiplePosts={relevantPosts}
                                post={{
                                    tags: selectedTagIdsForPost || [],
                                    post_id: relevantPosts[0]?.post_id || '',
                                    post_type: relevantPosts[0]?.post_type || 'status',
                                    id: relevantPosts[0]?.id || '',
                                    type: relevantPosts[0]?.type || 'FACEBOOK',
                                    data_source_id: relevantPosts[0]?.data_source_id || '',
                                    project_id: relevantPosts[0]?.project_id || '',
                                    created_at: 'now',
                                    thumbnail_post_id: relevantPosts[0]?.thumbnail_post_id || '',
                                    has_thumbnail: false,
                                    message: relevantPosts[0]?.message || '',
                                    perma_link: relevantPosts[0]?.perma_link || '',
                                    insights: {
                                        spend: 0,
                                        spend_by_platform: null,

                                        impressions: 0,
                                        impressions_organic: 0,
                                        impressions_paid: 0,

                                        reach: 0,
                                        reach_organic: 0,
                                        reach_paid: 0,

                                        engagement: 0,
                                        engagement_organic: 0,
                                        engagement_paid: 0,
                                        engagement_fans: null,

                                        reactions_by_type: undefined,
                                        reactions: 0,
                                        reactions_paid: 0,
                                        reactions_organic: 0,
                                        comments: 0,
                                        comments_paid: 0,
                                        comments_organic: 0,
                                        shares: 0,
                                        saves: 0,
                                        clicks: 0,
                                    },
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setTaggingDialogOpen(false)} color={'inherit'}>
                                <FormattedMessage id={'general.close'} />
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
        </Field>
    )
}
