export class UrlParamsParser {
    private affectedPath: string
    private urlParams: URLSearchParams
    private currentPath: string

    constructor(affectedPath: string) {
        this.affectedPath = affectedPath
        this.urlParams = new URLSearchParams(window.location.search)
        this.currentPath = window.location.pathname
    }

    private doesUrlParameterExist(name: string) {
        if (!this.currentPath.endsWith(this.affectedPath)) {
            return false
        }

        return this.urlParams.has(name) && this.urlParams.get(name)!.trim().length > 0
    }

    getBooleanFromUrl(key: string, defaultValue: boolean): boolean {
        if (this.doesUrlParameterExist(key)) {
            return this.urlParams.get(key) === 'true'
        }

        return defaultValue
    }

    getArrayFromUrl(key: string, defaultValue: string[]): string[] {
        if (this.doesUrlParameterExist(key)) {
            return this.urlParams.get(key)!.split(',')
        }

        return defaultValue
    }

    getStringFromUrl(key: string, defaultValue: string): string {
        if (this.doesUrlParameterExist(key)) {
            return this.urlParams.get(key)!
        }

        return defaultValue
    }

    getIntegerFromUrl(key: string, defaultValue: number): number {
        if (this.doesUrlParameterExist(key)) {
            return parseInt(this.urlParams.get(key)!, 10)
        }

        return defaultValue
    }

    getEnumValueFromUrl<T extends unknown>(enumClass: T, key: string, defaultValue: T[keyof T]): T[keyof T] {
        if (this.doesUrlParameterExist(key)) {
            return enumClass[this.urlParams.get(key)! as keyof T]
        }

        return defaultValue
    }

    getDateFromUrl(key: string, defaultValue: string): string {
        if (this.doesUrlParameterExist(key)) {
            return new Date(this.urlParams.get(key)!).toISOString()
        }

        return defaultValue
    }
}
