import * as _ from 'lodash'

export type Order = 'asc' | 'desc'

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
        return -1
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
        return 1
    }
    return 0
}

export function getComparator<T>(order: Order, orderBy: keyof T): (a: any, b: any) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}
