import { FC, useState } from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { UserAvatar } from '../common/avatars/UserAvatar'
import { ExitToApp, GppGoodOutlined, InfoOutlined, Person } from '@mui/icons-material'
import { getCurrentUser, showInfoDialog } from '../core/slices/CoreSlice'
import { useKeycloak } from '../core/hooks/useKeycloak'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { PrimaryNavigationButton } from './PrimaryNavigationButton'
import { getPaperElevationBackground } from '../core/theme/helper'

type UserMenuProps = {}

export const UserMenu: FC<UserMenuProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const keycloak = useKeycloak()
    const currentUser = useSelector(getCurrentUser)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleInfoClick = () => {
        setAnchorEl(null)
        dispatch(showInfoDialog())
    }

    const handlePrivacyPolicyClick = () => {
        setAnchorEl(null)
        window.cookiehub.openSettings()
    }

    return (
        <>
            <PrimaryNavigationButton
                size="large"
                sx={{ mt: 1, mb: 0 }}
                tooltip="general.user-menu.tooltip"
                icon={currentUser && <UserAvatar id={'primary_nav_user'} user={keycloak} sx={{ width: 48, height: 48 }} />}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            />
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                PaperProps={{
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        ml: 0.8,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            bottom: 25,
                            left: -5,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            backgroundImage: getPaperElevationBackground(8),
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <List dense disablePadding sx={{ minWidth: 200 }}>
                    <ListSubheader sx={{ backgroundImage: getPaperElevationBackground(8) }}>{keycloak.fullName}</ListSubheader>
                    <ListItemButton onClick={() => keycloak.accountManagement()}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="general.keycloak-account" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleInfoClick}>
                        <ListItemIcon>
                            <InfoOutlined />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="general.info" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handlePrivacyPolicyClick}>
                        <ListItemIcon>
                            <GppGoodOutlined />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="general.privacy-policy" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => keycloak.logout()}>
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="general.logout" />
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Menu>
        </>
    )
}
