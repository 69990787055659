import axios, { RawAxiosRequestConfig } from 'axios'
import { useEffect, useState } from 'react'

export const useImage = (
    imageUrl: string | undefined,
    withAuth: boolean = true,
    fetchHeaders?:
        | {
              'X-Datasource-Id': string
              'X-Target-Id': string
          }
        | undefined,
    onLoad?: (objectUrl: string | undefined) => void
) => {
    const [objectUrl, setObjectUrl] = useState<string | undefined>(undefined)
    const [hasErrors, setHasErrors] = useState<boolean>(false)
    const [contentType, setContentType] = useState<string | undefined>(undefined)

    useEffect(() => {
        const abortController = new AbortController()
        const config = {
            headers: fetchHeaders,
            responseType: 'arraybuffer',
            signal: abortController.signal,
        } as RawAxiosRequestConfig

        if (!withAuth) {
            config.transformRequest = (data, headers) => {
                delete headers.common
                delete headers['Authorization']
            }
        }

        if (imageUrl && imageUrl.length > 0) {
            axios
                .get(imageUrl, config)
                .then((response) => {
                    const imageBlob = new Blob([response.data], {
                        type: response.headers['Content-Type'] || response.headers['content-type'],
                    })
                    const myObjectUrl = URL.createObjectURL(imageBlob)
                    setObjectUrl(myObjectUrl)
                    setContentType(response.headers['Content-Type'] || response.headers['content-type'])
                    onLoad && onLoad(myObjectUrl)
                })
                .catch(() => {
                    setHasErrors(true)
                })
        } else {
            setHasErrors(true)
        }

        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl)
                onLoad && onLoad(undefined)
            }

            abortController.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageUrl, withAuth])

    return { objectUrl, hasErrors, contentType }
}
