import axios from 'axios'
import { FC, useState } from 'react'
import { Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Field, useForm, useFormState } from 'react-final-form'
import { CardGiftcard, Close } from '@mui/icons-material'
import { Product } from './Product'
import { useSelector } from 'react-redux'
import { getProductsWithoutTrial } from '../../core/slices/DataSlice'
import { AccountFormValues } from './AccountFormValues'
import { AccountPackageTypeInput } from './inputs/AccountPackageTypeInput'
import { FormattedCurrency } from '../../common/formatted-currency/FormattedCurrency'
import { AccountAdditionalsInput } from './inputs/AccountAdditionalsInput'
import { getCurrentUser } from '../../core/slices/CoreSlice'
import { getApiEntryPoint } from '../../core/api/ApiSlice'
import { StyledTextField } from '../../form/ConnectedTextField'
import { ConnectedHiddenField } from '../../form/ConnectedHiddenField'

type AccountBillingFormProps = {}

export const AccountSubscriptionSection: FC<AccountBillingFormProps> = () => {
    const apiEntryPoint = useSelector(getApiEntryPoint)
    const formState = useFormState<AccountFormValues>()
    const form = useForm()
    const products = useSelector(getProductsWithoutTrial)
    const currentUser = useSelector(getCurrentUser)
    const trial = currentUser.accounts.length === 0
    const [invalidPromoCode, setInvalidPromoCode] = useState(false)

    const selectedType = formState.values.package_type
    const billingInterval = formState.values.interval
    const additionalUsers = formState.values.additional_user
    const additionalDatasources = formState.values.additional_datasource
    const additionalConversionTracking = formState.values.conversion_tracking

    const getPriceForProduct = (product: Product, interval: 'month' | 'year') => {
        const price = product.prices.find((p) => p.recurring?.interval === interval)
        return price ? price.unit_amount / 100 : 0
    }

    const getPriceForFeature = (feature: string) => {
        const product = Object.values(products).find((p) => p.metadata.additional_feature === feature)

        if (product) {
            return getPriceForProduct(product, billingInterval)
        }

        return 0
    }

    const calculateTotal = () => {
        let total = 0

        const selectedProduct = Object.values(products).find((p) => p.metadata.package === selectedType)

        if (selectedProduct) {
            total += getPriceForProduct(selectedProduct, billingInterval)
        }

        total += additionalUsers * getPriceForFeature('ADDITIONAL_USER')
        total += additionalDatasources * getPriceForFeature('ADDITIONAL_DATASOURCE')
        total += additionalConversionTracking * getPriceForFeature('CONVERSION_TRACKING')

        return total
    }

    const handleCouponClick = () => {
        if (formState.values.code && formState.values.code.length > 0) {
            axios
                .get(apiEntryPoint._links.promotion_codes.href + '/' + formState.values.code)
                .then((response) => {
                    setInvalidPromoCode(false)
                    form.change('promotion_code_obj', response.data)
                    form.change('promotion_code', response.data.id)
                })
                .catch(() => setInvalidPromoCode(true))
        }
    }

    const handleDeleteCoupon = () => {
        setInvalidPromoCode(false)
        form.change('promotion_code_obj', undefined)
        form.change('code', undefined)
        form.change('promotion_code', undefined)
    }

    function calculateDiscount() {
        const totalPrice = calculateTotal()

        if (billingInterval === 'year') {
            const discountedMonths = formState.values.promotion_code_obj.coupon.duration_in_months
            const basePriceMonth = totalPrice / 12
            const priceWithoutDiscount = basePriceMonth * discountedMonths
            const priceWithDiscount = basePriceMonth - (basePriceMonth / 100) * formState.values.promotion_code_obj.coupon.percent_off

            return priceWithoutDiscount - priceWithDiscount
        }

        return (totalPrice / 100) * formState.values.promotion_code_obj.coupon.percent_off
    }

    return (
        <Grid container spacing={2}>
            <AccountPackageTypeInput paper={true} />
            <AccountAdditionalsInput paper={true} />

            <Grid item xs={12}>
                <Paper sx={{ p: 2, pt: 1, pb: 1 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 2, pt: 1 }}>
                        <Grid container spacing={1} justifyContent="space-between">
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Field name="code">
                                            {({ input }) => (
                                                <StyledTextField
                                                    value={input.value}
                                                    onChange={(e) => input.onChange(e.target.value.toUpperCase())}
                                                    sx={{ minWidth: 300 }}
                                                    label={<FormattedMessage id="billing.promotion-code" />}
                                                    InputLabelProps={{ variant: 'outlined' }}
                                                    InputProps={{
                                                        endAdornment: <CardGiftcard color="action" />,
                                                    }}
                                                    helperText={invalidPromoCode ? <FormattedMessage id="billing.promotion-code-not-exists" /> : undefined}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    disabled={formState.values.promotion_code !== undefined}
                                                />
                                            )}
                                        </Field>
                                        <ConnectedHiddenField name="promotion_code" />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={handleCouponClick}
                                            disabled={formState.values.promotion_code !== undefined}
                                        >
                                            <FormattedMessage id="billing.redeem-coupon" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                {formState.values.promotion_code_obj && (
                                    <Box display="flex" alignItems="center" justifyContent="end">
                                        <Typography variant="h6">
                                            {formState.values.promotion_code_obj.coupon.duration_in_months ? (
                                                <>
                                                    {formState.values.promotion_code_obj.coupon.duration_in_months} <FormattedMessage id="billing.months" /> -{' '}
                                                </>
                                            ) : (
                                                <FormattedMessage id="billing.unlimited" />
                                            )}
                                            &nbsp;{formState.values.promotion_code_obj.coupon.percent_off}% <FormattedMessage id="billing.percent-off" />
                                        </Typography>
                                        <Tooltip title={<FormattedMessage id="billing.delete-coupon" />}>
                                            <IconButton sx={{ ml: 1 }} onClick={handleDeleteCoupon}>
                                                <Close />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" minHeight={70}>
                        <Box textAlign="right" pt={1}>
                            {formState.values.promotion_code_obj ? (
                                <>
                                    <Typography variant="h6">
                                        <FormattedCurrency value={calculateTotal() - calculateDiscount()} />
                                        &nbsp;
                                        {billingInterval === 'month' ? <FormattedMessage id="billing.per-month" /> : <FormattedMessage id="billing.per-year" />}
                                    </Typography>
                                    {formState.values.promotion_code_obj.coupon.duration_in_months && (
                                        <Typography variant="subtitle2" color="text.secondary">
                                            <FormattedMessage
                                                id={`billing.first-${billingInterval === 'month' ? 'x-month' : 'year'}-after`}
                                                values={{
                                                    months: formState.values.promotion_code_obj.coupon.duration_in_months,
                                                    total: <FormattedCurrency value={calculateTotal()} />,
                                                }}
                                            />
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <Typography variant="h6">
                                    <FormattedCurrency value={calculateTotal()} />
                                    &nbsp;
                                    {billingInterval === 'month' ? <FormattedMessage id="billing.per-month" /> : <FormattedMessage id="billing.per-year" />}
                                </Typography>
                            )}
                            {trial && !formState.values.promotion_code && (
                                <Typography variant="subtitle2" color="text.secondary">
                                    <FormattedMessage id="billing.after-14-day-trial" />
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}
