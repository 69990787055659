import { FC } from 'react'

type PieChartLabelProps = any

export const PieChartLabel: FC<PieChartLabelProps> = (props) => {
    const { cx, cy, innerRadius, outerRadius, percent, midAngle } = props
    let radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180))
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180))

    if (percent < 0.05) {
        return null
    }

    return (
        <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" style={{ pointerEvents: 'none' }}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}
