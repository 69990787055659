import * as React from 'react'
import { FC } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { Field, useForm } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../core/slices/CoreSlice'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

type ConnectedSelectSliderProps = {
    items: { value: string | boolean; label: string | ReactJSXElement }[]
    name: string
    defaultValue?: string | boolean
    label?: string
}

export const ConnectedSelectSlider: FC<ConnectedSelectSliderProps> = ({ items, name, defaultValue, label }) => {
    const form = useForm()
    const darkmode = useSelector(isDarkMode)
    const theme = useTheme()
    return (
        <Field name={name} defaultValue={defaultValue}>
            {({ input }) => {
                const handleChange = (val: string | boolean) => {
                    form.change(name, val)
                }
                return (
                    <Box display={'flex'} gap={1} flexDirection={'column'}>
                        {label && <Typography>{label}</Typography>}
                        <Box
                            sx={{
                                display: 'flex',
                                height: '50px',
                                background: 'transparent',
                                borderRadius: '100px',
                                border: darkmode ? '1px solid rgba(255, 255, 255, 0.23)' : `1px solid rgba(0, 0, 0, 0.23)`,
                            }}
                        >
                            <AnimatePresence>
                                {items.map((item) => {
                                    return (
                                        <Box
                                            key={item.value.toString()}
                                            sx={{
                                                width: `${100 / items.length}%`,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                position: 'relative',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleChange(item.value)}
                                        >
                                            {input.value === item.value && (
                                                <motion.div
                                                    layoutId={`selectedEntryUnderline${name}`}
                                                    style={{
                                                        background: theme.palette.action.selected,
                                                        position: 'absolute',
                                                        height: '100%',
                                                        width: '100%',
                                                        borderRadius: '100px',
                                                        zIndex: 0,
                                                    }}
                                                />
                                            )}
                                            <Box
                                                sx={{
                                                    fontWeight: input.value === item.value ? 600 : undefined,
                                                    transition: 'all 0.2s',
                                                    zIndex: 2,
                                                    '& strong': {
                                                        transition: 'font-size 0.3s cubic-bezier(.17,.67,.13,1.6), margin-right 0.3s',
                                                        fontSize: input.value === item.value ? '24px' : '0px',
                                                        mr: input.value === item.value ? 1 : 0,
                                                    },
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {typeof item.label === 'string' ? <FormattedMessage id={item.label} /> : item.label}
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </AnimatePresence>
                        </Box>
                    </Box>
                )
            }}
        </Field>
    )
}
