import * as React from 'react'
import { FC, useRef, useState } from 'react'
import { Box, Button, Divider, ListSubheader, MenuItem, MenuList, Popover } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ArrowDropDown, AssignmentTurnedIn, AutoFixHigh, Cancel, CheckCircle, Pending } from '@mui/icons-material'
import { PostGroup } from '../post-groups/PostGroup'
import { RequestApprovalDialog } from './RequestApprovalDialog'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { deletePostGroupApproval, fetchPostGroupActivities, updatePostGroupApproval } from '../post-groups/PostGroupActions'
import { RejectApprovalDialog } from './RejectApprovalDialog'
import { ConfirmDialog } from '../../common/confirm-dialog/ConfirmDialog'
import { getIsMobile, isExternalForProject, showErrorSnackbar, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { useHasDatasourcePermission } from '../../core/hooks/useHasPermission'
import _ from 'lodash'

type PublishingFormApprovalProps = {
    postGroup: PostGroup
}

export const PublishingFormApproval: FC<PublishingFormApprovalProps> = ({ postGroup }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const [open, setOpen] = useState(false)

    const datasourceIds = postGroup.posts.map((p) => p.data_source_id)
    const canApprove = useHasDatasourcePermission('post.approve', datasourceIds)
    const isUserExternal = useSelector(isExternalForProject)

    const anchorRef = useRef<HTMLButtonElement>(null)
    const internalPending = postGroup.internal_approval?.state === 'REQUESTED'
    const externalPending = postGroup.external_approval?.state === 'REQUESTED'
    const internalApproved = postGroup.internal_approval?.state === 'APPROVED'
    const externalApproved = postGroup.external_approval?.state === 'APPROVED'
    const internalRework = postGroup.internal_approval?.state === 'REWORK'
    const externalRework = postGroup.external_approval?.state === 'REWORK'

    const approvalPending = internalPending || externalPending
    const isMobile = useSelector(getIsMobile)

    const [requestedApproval, setRequestedApproval] = useState<undefined | 'INTERNAL' | 'EXTERNAL'>(undefined)
    const [revokeApprovalConfirm, setRevokeApprovalConfirm] = useState<undefined | 'INTERNAL' | 'EXTERNAL'>(undefined)
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false)

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }

    const handleRequestApproval = (value: 'INTERNAL' | 'EXTERNAL') => {
        setRequestedApproval(value)
        setOpen(false)
    }

    const handleRevokeApproval = () => {
        dispatch(
            deletePostGroupApproval({
                postGroup,
                request: { external: revokeApprovalConfirm === 'EXTERNAL' },
            })
        ).then((action) => {
            if (_.get(action, 'payload.status') === 403) {
                dispatch(showErrorSnackbar('publishing.disabled-tooltip-no-permission'))
            }

            setRevokeApprovalConfirm(undefined)
            dispatch(
                showSuccessSnackbar(revokeApprovalConfirm === 'EXTERNAL' ? 'publishing.revoked-external-approval' : 'publishing.revoked-internal-approval')
            )
            dispatch(fetchPostGroupActivities(postGroup))
        })
    }

    const handleRevokeApprovalClick = (value: 'INTERNAL' | 'EXTERNAL') => {
        setOpen(false)
        setRevokeApprovalConfirm(value)
    }

    const handleApprove = (value: 'APPROVED' | 'REWORK') => {
        if (value === 'APPROVED') {
            dispatch(
                updatePostGroupApproval({
                    postGroup,
                    request: {
                        state: value,
                        external: !internalPending,
                        message: '',
                    },
                })
            ).then((action: any) => {
                if (_.get(action, 'payload.status') === 403) {
                    dispatch(showErrorSnackbar('publishing.disabled-tooltip-no-permission'))
                }
                dispatch(fetchPostGroupActivities(postGroup))
            })
        } else {
            setRejectDialogOpen(true)
        }

        setOpen(false)
    }

    const renderMenuListRequest = () => {
        return (
            <MenuList>
                <ListSubheader>
                    <FormattedMessage id={'publishing.approvals'} />
                </ListSubheader>

                {isUserExternal ? null : internalApproved ? (
                    <MenuItem sx={{ gap: 1 }} onClick={() => handleRevokeApprovalClick('INTERNAL')}>
                        <FormattedMessage id="publishing.revoke-internal-approval" />
                    </MenuItem>
                ) : (
                    <MenuItem sx={{ gap: 1 }} onClick={() => handleRequestApproval('INTERNAL')}>
                        <FormattedMessage id="publishing.request-internal-approval" />
                    </MenuItem>
                )}

                {externalApproved ? (
                    <MenuItem sx={{ gap: 1 }} onClick={() => handleRevokeApprovalClick('EXTERNAL')}>
                        <FormattedMessage id={isUserExternal ? 'publishing.revoke-approval' : 'publishing.revoke-external-approval'} />
                    </MenuItem>
                ) : (
                    <MenuItem sx={{ gap: 1 }} onClick={() => handleRequestApproval('EXTERNAL')}>
                        <FormattedMessage id={isUserExternal ? 'publishing.request-approval' : 'publishing.request-external-approval'} />
                    </MenuItem>
                )}
            </MenuList>
        )
    }

    const handleAbortApproval = () => {
        dispatch(deletePostGroupApproval({ postGroup, request: { external: externalPending } })).then((action) => {
            if (_.get(action, 'payload.status') === 403) {
                dispatch(showErrorSnackbar('publishing.disabled-tooltip-no-permission'))
                setOpen(false)
            } else {
                dispatch(showSuccessSnackbar(externalPending ? 'publishing.cancelled-externel-approval' : 'publishing.cancelled-internal-approval'))
            }
            dispatch(fetchPostGroupActivities(postGroup))
        })
    }

    const renderMenuListPending = () => {
        return (
            <MenuList>
                {externalPending ? (
                    <ListSubheader>
                        <FormattedMessage id={isUserExternal ? 'publishing.approval' : 'publishing.external-approval'} />
                    </ListSubheader>
                ) : (
                    <ListSubheader>
                        <FormattedMessage id="publishing.internal-approval" />
                    </ListSubheader>
                )}

                {((internalPending && canApprove) || (externalPending && isUserExternal)) && (
                    <>
                        <MenuItem sx={{ gap: 1 }} onClick={() => handleApprove('APPROVED')}>
                            <CheckCircle color="success" />
                            <FormattedMessage id="publishing.accept-approval" />
                        </MenuItem>
                        <MenuItem sx={{ gap: 1 }} onClick={() => handleApprove('REWORK')}>
                            <Cancel color="error" />
                            <FormattedMessage id="publishing.deny-approval" />
                        </MenuItem>
                    </>
                )}

                {!isUserExternal && (
                    <>
                        <Divider />
                        <MenuItem onClick={handleAbortApproval}>
                            <FormattedMessage id="publishing.cancel-approval" />
                        </MenuItem>
                    </>
                )}
            </MenuList>
        )
    }

    const renderButton = () => {
        if (externalPending || (!isUserExternal && internalPending)) {
            return (
                <Button
                    fullWidth={true}
                    ref={anchorRef}
                    variant="outlined"
                    onClick={() => setOpen((prevOpen) => !prevOpen)}
                    endIcon={<ArrowDropDown />}
                    color="warning"
                    startIcon={<Pending />}
                >
                    <FormattedMessage id="publishing.approval.pending" />
                </Button>
            )
        } else if (externalRework || (!isUserExternal && internalRework)) {
            return (
                <Button
                    fullWidth={true}
                    ref={anchorRef}
                    sx={{ pointerEvents: isUserExternal ? 'none' : undefined }}
                    variant="outlined"
                    onClick={() => (!isUserExternal ? setOpen((prevOpen) => !prevOpen) : {})}
                    endIcon={!isUserExternal ? <ArrowDropDown /> : undefined}
                    color="error"
                    startIcon={<AutoFixHigh />}
                >
                    <FormattedMessage id="publishing.approval.state.REWORK" />
                </Button>
            )
        } else if (internalApproved || externalApproved) {
            let label = 'publishing.approval.state.INTERNAL_EXTERNAL_APPROVED'

            if (!externalApproved) {
                label = 'publishing.approval.state.INTERNAL_APPROVED'
            } else if (!internalApproved) {
                label = 'publishing.approval.state.EXTERNAL_APPROVED'
            }

            if (isUserExternal) {
                label = 'publishing.approval.state.APPROVED'
            }

            return (
                <Button
                    fullWidth={true}
                    ref={anchorRef}
                    variant="outlined"
                    onClick={() => setOpen((prevOpen) => !prevOpen)}
                    endIcon={<ArrowDropDown />}
                    color="success"
                    startIcon={<CheckCircle />}
                >
                    <FormattedMessage id={label} />
                </Button>
            )
        } else {
            return (
                <Button
                    fullWidth={true}
                    ref={anchorRef}
                    variant="outlined"
                    onClick={() => setOpen((prevOpen) => !prevOpen)}
                    endIcon={<ArrowDropDown />}
                    color="warning"
                    startIcon={<AssignmentTurnedIn />}
                >
                    <FormattedMessage id="publishing.request-approval" />
                </Button>
            )
        }
    }

    if (isUserExternal && !(externalPending || externalApproved || externalRework)) {
        return null
    }

    return (
        <Box width="100%">
            {renderButton()}
            <Popover
                open={open}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: isMobile ? 'top' : 'bottom',
                    horizontal: isMobile ? 'center' : 'right',
                }}
                transformOrigin={{
                    vertical: isMobile ? 'bottom' : 'top',
                    horizontal: isMobile ? 'center' : 'right',
                }}
                disablePortal
                onClose={handleClose}
                PaperProps={
                    isMobile
                        ? {
                              sx: {
                                  width: 'calc(100% - 40px)',
                              },
                          }
                        : {}
                }
            >
                {approvalPending ? renderMenuListPending() : renderMenuListRequest()}
            </Popover>

            <RequestApprovalDialog
                postGroup={postGroup}
                open={requestedApproval !== undefined}
                onClose={() => setRequestedApproval(undefined)}
                external={requestedApproval === 'EXTERNAL'}
            />

            <RejectApprovalDialog postGroup={postGroup} open={rejectDialogOpen} onClose={() => setRejectDialogOpen(false)} external={!internalPending} />

            <ConfirmDialog
                open={revokeApprovalConfirm !== undefined}
                onClose={() => setRevokeApprovalConfirm(undefined)}
                onDismiss={() => setRevokeApprovalConfirm(undefined)}
                onConfirm={handleRevokeApproval}
                confirmText="publishing.revoke-approval"
            >
                <FormattedMessage id="publishing.revoke-approval-confirm" />
            </ConfirmDialog>
        </Box>
    )
}
