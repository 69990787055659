import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ProjectDatasource, ProjectDatasourceCategory, ProjectDatasourceType } from '../../settings/datasources/ProjectDatasource'

type DatasourceSelectionProps = {
    datasources: ProjectDatasource[]
    initialIds: string[]
    onDatasourceChange: (selectedIds: string[]) => any
    allowedTypes?: ProjectDatasourceType[]
    disabled?: boolean
    size?: 'small' | undefined
    fullWidth?: boolean
    minWidthOverride?: number
    handleOpen?: () => void
    handleClose?: () => void
    isOpen?: boolean
}

export const DatasourceSelection: FC<DatasourceSelectionProps> = ({
    datasources,
    initialIds,
    onDatasourceChange,
    allowedTypes,
    disabled,
    size,
    fullWidth,
    minWidthOverride,
    isOpen,
    handleOpen,
    handleClose,
}) => {
    const [activeIds, setActiveIds] = useState(initialIds)
    const intl = useIntl()

    useEffect(() => {
        setActiveIds(initialIds)
    }, [initialIds])

    const groupedDataSources: Map<ProjectDatasourceCategory, ProjectDatasource[]> = new Map()
    datasources.forEach((source) => {
        if (allowedTypes) {
            if (allowedTypes?.includes(source.type)) {
                groupedDataSources.set(source.category, [...(groupedDataSources.get(source.category) || []), source])
            }
        } else {
            groupedDataSources.set(source.category, [...(groupedDataSources.get(source.category) || []), source])
        }
    })

    const onChange = (ids: string[]) => {
        ids.forEach((id) => {
            if (
                !datasources.find((source) => source.id === id) &&
                (id === 'FACEBOOK' || id === 'DATA_STREAMS' || id === 'LINKED_IN' || id === 'INSTAGRAM' || id === 'TIKTOK')
            ) {
                if (groupedDataSources.get(id)) {
                    const categoryActive = groupedDataSources.get(id)?.every((source) => activeIds.includes(source.id))

                    if (!categoryActive) {
                        groupedDataSources.get(id)?.forEach((source) => {
                            if (!ids.includes(source.id)) {
                                ids.push(source.id)
                            }
                        })
                    } else {
                        let groupIds = groupedDataSources.get(id)?.map((source) => source.id) || []
                        ids = ids.filter((id) => !groupIds.includes(id))
                    }
                }
            }
        })
        ids = ids.filter((id) => !(id === 'FACEBOOK' || id === 'DATA_STREAMS' || id === 'LINKED_IN' || id === 'INSTAGRAM' || id === 'TIKTOK'))
        onDatasourceChange(ids)
        setActiveIds(ids)
    }

    const labelIdByCategory = (category: ProjectDatasourceCategory) => {
        switch (category) {
            case 'FACEBOOK':
                return 'datasource.facebook'
            case 'DATA_STREAMS':
                return 'datasource.data_streams'
            case 'INSTAGRAM':
                return 'datasource.instagram'
            case 'LINKED_IN':
                return 'datasource.linkedin'
            case 'TIKTOK':
                return 'datasource.tiktok'
        }
        return 'datasource.unknown'
    }

    const buildOptions = () => {
        const options: JSX.Element[] = [
            // this required to solve a mui bug that has the first element wrongly highlighted
            <MenuItem key="placeholder" style={{ display: 'none' }} />,
        ]

        groupedDataSources.forEach((datasources, category) => {
            const categoryActive = datasources.every((source) => activeIds.includes(source.id))
            options.push(
                <MenuItem key={category} value={category}>
                    <Checkbox color={'default'} checked={categoryActive} />
                    <ListItemText primary={intl.formatMessage({ id: labelIdByCategory(category) })} />
                </MenuItem>
            )
            datasources.forEach((datasource) => {
                options.push(
                    <MenuItem key={datasource.id} value={datasource.id} sx={{ paddingLeft: '48px' }}>
                        <Checkbox color={'default'} checked={activeIds.includes(datasource.id)} />
                        <ListItemText primary={datasource.name} />
                    </MenuItem>
                )
            })
        })
        return options
    }

    return (
        <FormControl sx={{ minWidth: minWidthOverride || 200 }} fullWidth={fullWidth}>
            <InputLabel id="datasource-selection-label" variant="outlined" size={size}>
                <FormattedMessage id="general.datasources" />
            </InputLabel>
            <Select
                fullWidth={fullWidth}
                disabled={disabled}
                open={isOpen}
                onOpen={handleOpen}
                onClose={handleClose}
                size={size}
                variant={'outlined'}
                labelId="datasource-selection-label"
                id="tags-outlined"
                multiple
                MenuProps={{
                    disablePortal: true,
                }}
                input={<OutlinedInput size={size} label={intl.formatMessage({ id: 'general.datasources' })} />}
                value={activeIds}
                onChange={(e) => onChange(typeof e.target.value === 'string' ? [e.target.value] : e.target.value)}
                renderValue={(selected) =>
                    selected.length === 1
                        ? datasources.find((project) => project.id === selected[0])?.name
                        : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selected.length })
                }
            >
                {buildOptions()}
            </Select>
        </FormControl>
    )
}
