import * as _ from 'lodash'
import { FC, useState } from 'react'
import { Box, Button, Container, Dialog, Grid, InputLabel, LinearProgress, Paper, Typography } from '@mui/material'
import { Speed } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getSelectedAccount, getSelectedAccountAccess } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { getProjects, getTags } from '../../core/slices/DataSlice'
import { AccountOverCapacityDialog, AccountOverCapacityDialogProps } from './AccountOverCapacityDialog'
import { deleteAccountAccess, deleteInvite, fetchInvitesForAccount, fetchUsersForAccount } from '../user-management/UserActions'
import { UserAccountAccess } from '../user-management/User'
import { deleteTag, fetchTagsForProject } from '../../tags/TagsActions'
import { HmstrAsideLayout } from '../../common/hmstr-aside-layout/HmstrAsideLayout'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { getFilteredUserTableEntries } from '../SettingsSlice'
import { UserTableEntry } from '../user-management/table/UserTableEntry'
import { fetchAccount } from './AccountActions'
import { updateProject } from '../project-management/ProjectActions'
import { AccountUpdateSubscriptionWizard } from './AccountUpdateSubscriptionWizard'
import { FullscreenDialog } from '../../common/fullscreen-dialog/FullscreenDialog'
import { fetchProducts } from '../billing/BillingActions'
import { HeaderWithSwitcher } from '../../common/header-with-switcher/HeaderWithSwitcher'

type AccountOverCapacityProps = {}

export const AccountOverCapacity: FC<AccountOverCapacityProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const account = useSelector(getSelectedAccount)
    const currentUser = useSelector(getCurrentUser)
    const tags = useSelector(getTags)
    const userTableEntries = useSelector(getFilteredUserTableEntries)
    const projects = useSelector(getProjects)
    const accountAccess = useSelector(getSelectedAccountAccess) as UserAccountAccess
    const isOwner = account.owner_id === currentUser.id

    const [dialogOpen, setDialogOpen] = useState<AccountOverCapacityDialogProps['mode']>()
    const [updateSubscriptionDialogOpen, setUpdateSubscriptionDialogOpen] = useState(false)

    useEffectWithIdComparison(() => {
        dispatch(fetchProducts())
    }, [])

    useEffectWithIdComparison(() => {
        dispatch(fetchUsersForAccount(accountAccess))
        dispatch(fetchInvitesForAccount(accountAccess))
        if (account.package_type === 'free') {
            Object.values(projects).forEach((p) => dispatch(fetchTagsForProject(p)))
        }
    }, [account])

    const renderProgressBar = (value: number, maxValue: number, label: string, onButtonClick: () => any) => {
        const progressValue = (value / maxValue) * 100
        return (
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={9}>
                        <Box textAlign="left">
                            <InputLabel shrink={true}>
                                <FormattedMessage id={label} />
                            </InputLabel>
                        </Box>
                        <LinearProgress
                            color={value > maxValue ? 'error' : 'success'}
                            variant="determinate"
                            value={progressValue > 100 ? 100 : progressValue}
                        />
                    </Grid>
                    <Grid item xs={1} textAlign="right">
                        <Typography variant="body2" color="text.secondary">
                            {`${value} / ${maxValue}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                        <Button variant="contained" onClick={onButtonClick} disabled={value <= maxValue}>
                            <FormattedMessage id="billing.reduce-limits" />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const calculcateDifferenceToRemote = (mode: AccountOverCapacityDialogProps['mode']) => {
        switch (mode) {
            case 'users':
                return account.user_count - account.user_limit
            case 'datasources':
                return account.data_source_count - account.data_source_limit
            case 'cts':
                return account.conversion_tracking_count - account.conversion_tracking_limit
            case 'tags':
                return Object.values(tags).length - 2
        }
    }

    const handleDatasourceRemove = async (ids: string[]) => {
        const modifiedProjects = Object.values(projects).filter((p) => p.data_sources.find((ds) => ids.includes(ds.id)))

        const promises = modifiedProjects.map((mp) => {
            const data_sources = mp.data_sources.filter((ds) => !ids.includes(ds.id))
            return new Promise((resolve) => dispatch(updateProject({ ...mp, data_sources })).then(resolve))
        })

        await Promise.all(promises)
    }

    const handleUserRemove = async (ids: string[]) => {
        const userTableIdMap = _.mapKeys(userTableEntries, 'id')

        const promises = ids.map((id) => {
            const entryToDelete: UserTableEntry = userTableIdMap[id]

            if (entryToDelete.invite) {
                return new Promise((resolve) => dispatch(deleteInvite(entryToDelete.invite as any)).then(resolve))
            } else {
                return new Promise((resolve) =>
                    dispatch(
                        deleteAccountAccess({
                            access: entryToDelete.user_access as any,
                            user_id: entryToDelete.id,
                        })
                    ).then(resolve)
                )
            }
        })

        await Promise.all(promises)
    }

    const handleConversionTrackingRemove = async (ids: string[]) => {
        const modifiedProjects = Object.values(projects).filter((p) => p.data_sources.find((ds) => ids.includes(ds.id)))

        const promises = modifiedProjects.map((mp) => {
            const data_sources = mp.data_sources.map((ds) => {
                if (ids.includes(ds.id)) {
                    return { ...ds, conversion_tracking: false }
                }

                return ds
            })
            return new Promise((resolve) => dispatch(updateProject({ ...mp, data_sources })).then(resolve))
        })

        await Promise.all(promises)
    }

    const handleTagRemove = async (ids: string[]) => {
        const promises = ids.map((tagId) => {
            const tagToDelete = tags[tagId]
            return new Promise((resolve) => dispatch(deleteTag(tagToDelete)).then(resolve))
        })

        await Promise.all(promises)
    }

    const handleSubmit = (mode: AccountOverCapacityDialogProps['mode'], ids: string[]) => {
        let requests: Promise<void>

        switch (mode) {
            case 'users':
                requests = handleUserRemove(ids)
                break
            case 'datasources':
                requests = handleDatasourceRemove(ids)
                break
            case 'cts':
                requests = handleConversionTrackingRemove(ids)
                break
            case 'tags':
                requests = handleTagRemove(ids)
                break
        }

        requests.then(() => dispatch(fetchAccount(account._links.self)).then(() => setDialogOpen(undefined)))
    }

    return (
        <HmstrAsideLayout>
            <Container maxWidth="md">
                <HeaderWithSwitcher />

                <Box sx={{ textAlign: 'center' }}>
                    <Speed color="action" sx={{ fontSize: 128, mt: 4, mb: 4 }} />

                    <Typography variant="subtitle1">
                        <FormattedMessage id={isOwner ? 'hint.account.over-capacity' : 'hint.account.over-capacity-no-owner'} />
                    </Typography>

                    {isOwner && (
                        <Box mt={4}>
                            <Box mb={4} component={Paper} p={2}>
                                <Typography variant="h6">
                                    <FormattedMessage id="hint.account.over-capacity-book-account" />
                                </Typography>
                                <Button sx={{ mt: 2 }} size="large" variant="contained" color="secondary" onClick={() => setUpdateSubscriptionDialogOpen(true)}>
                                    <FormattedMessage id="hint.account.book-subscription" />
                                </Button>
                            </Box>

                            <Box>
                                <Typography variant="caption">
                                    <FormattedMessage id="general.or" />
                                </Typography>
                            </Box>

                            <Box component={Paper} p={2} mt={4}>
                                <Grid container spacing={4}>
                                    <Grid item>
                                        <Typography variant="subtitle2">
                                            <FormattedMessage id="hint.account.over-capacity.reduce-limit" />
                                        </Typography>
                                    </Grid>
                                    {renderProgressBar(account.user_count, account.user_limit, 'accounts.users', () => setDialogOpen('users'))}
                                    {renderProgressBar(account.data_source_count, account.data_source_limit, 'accounts.datasources', () =>
                                        setDialogOpen('datasources')
                                    )}
                                    {renderProgressBar(account.conversion_tracking_count, account.conversion_tracking_limit, 'conversion-tracking.title', () =>
                                        setDialogOpen('cts')
                                    )}
                                    {account.package_type === 'free' && (
                                        <>{renderProgressBar(Object.values(tags).length, 2, 'general.tags', () => setDialogOpen('tags'))}</>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    )}

                    <Dialog open={!!dialogOpen} onClose={() => setDialogOpen(undefined)}>
                        {dialogOpen && (
                            <AccountOverCapacityDialog
                                mode={dialogOpen}
                                onSubmit={handleSubmit}
                                itemCountToRemove={calculcateDifferenceToRemote(dialogOpen)}
                                onClose={() => setDialogOpen(undefined)}
                            />
                        )}
                    </Dialog>

                    <FullscreenDialog open={updateSubscriptionDialogOpen} handleClose={() => setUpdateSubscriptionDialogOpen(false)}>
                        <AccountUpdateSubscriptionWizard />
                    </FullscreenDialog>
                </Box>
            </Container>
        </HmstrAsideLayout>
    )
}
