import _ from 'lodash'
import { ChangeEvent, FC, useRef, useState } from 'react'
import { Box, Checkbox, Collapse, Grid, IconButton, keyframes, ListItemText, Paper, Tooltip, useTheme } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Project } from '../project-management/Project'
import { getIconForDatasourceType } from '../datasources/DatasourceTypeMappings'
import { FormattedMessage } from 'react-intl'
import { DatasourceNotificationSettings } from './DatasourceNotificationSettings'
import { NotificationSettingsRow } from './NotificationSettingsRow'
import { useForm, useFormState } from 'react-final-form'
import { getDatasourcesForNotificationSettingsTabByProject } from '../SettingsSlice'
import { useSelector } from 'react-redux'
import { IdMap } from '../../core/slices/DataSlice'
import { getSelectedProjectId, isDarkMode } from '../../core/slices/CoreSlice'
import { NotificationSettingsTabType } from './NotificationSettingsTab'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'

type NotificationProjectEntryProps = {
    project: Project
    accountName?: string
    tab: NotificationSettingsTabType
}

export const settingsForTab: {
    engage: (keyof DatasourceNotificationSettings['engage'])[]
    analyse: (keyof DatasourceNotificationSettings['analyse'])[]
    publish: (keyof DatasourceNotificationSettings['publish'])[]
    core: (keyof DatasourceNotificationSettings['core'])[]
} = {
    engage: [
        'ticket_created',
        'ticket_assigned_to_me',
        'mentioned_in_ticket_note',
        'internal_ticket_note_created',
        'ticket_reopened',
        'unread_comment_threshold_reached',
        'own_ticket_closed_by_others',
    ],
    analyse: [],
    publish: [
        'new_draft',
        'post_planned',
        'post_published',
        'post_approved',
        'publishing_failed',
        'internal_post_note_created',
        'mentioned_in_post_note',
        'post_assigned_to_me',
        'post_unplanned',
    ],
    core: ['invited_to_account', 'trial_expired', 'channel_token_will_expire', 'channel_token_expired'],
}

export const NotificationProjectEntry: FC<NotificationProjectEntryProps> = ({ project, tab, accountName }) => {
    const form = useForm()
    const theme = useTheme()
    const darkMode = useSelector(isDarkMode)
    const formState = useFormState()
    const [highlightedSetting, setHighlightedSetting] = useState<string | undefined>(undefined)
    const urlParams = new URLSearchParams(window.location.search)
    const highlightedSettingFromUrl = urlParams.get('highlight')
    const [highlightedDatasourceId, setHighlightedDatasourceId] = useState<string | undefined>(undefined)
    const selectedProjectId = useSelector(getSelectedProjectId)
    const [expanded, setExpanded] = useState(project.id === selectedProjectId)
    const settingsPaperRef = useRef<any>(null)
    useEffectWithIdComparison(() => {
        if (project.id === selectedProjectId && settingsPaperRef.current) {
            settingsPaperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' })
            setExpanded(project.id === selectedProjectId)
        }
    }, [selectedProjectId, project])

    const highlightFromUrlKeyframes = keyframes`
        0% {
            box-shadow: 0 0 0 transparent;
        }
        50% {
            box-shadow: 0 0 20px 5px ${theme.palette.secondary.light};
        }
        100% {
            box-shadow: 0 0 30px 15px transparent;
        }
    `

    const datasourcesForSettingsByProject = useSelector(getDatasourcesForNotificationSettingsTabByProject)
    const datasourcesForSettingsByTab = datasourcesForSettingsByProject[project.id] || {}
    const datasourcesForSettings = datasourcesForSettingsByTab[tab] || []
    const settingsForTabElement = settingsForTab[tab] || []

    const getRowNameForDs = (dsId: string) => {
        return `${dsId}.${tab}`
    }

    const amountOfCheckedDatasourcesForSetting: IdMap<number> = _.chain(settingsForTabElement)
        .mapKeys()
        .mapValues((setting) => {
            return datasourcesForSettings
                .map((ds) => {
                    return _.get(formState.values, `${ds.id}.${tab}.${setting}`, undefined)
                })
                .filter((d) => d).length
        })
        .value()

    const checkedSettingsForDs: IdMap<number> = _.chain(datasourcesForSettings)
        .map((ds) => ds.id)
        .mapKeys()
        .mapValues((dsId) => {
            return settingsForTabElement
                .map((setting) => {
                    return _.get(formState.values, `${getRowNameForDs(dsId)}.${setting}`, false)
                })
                .filter((d) => d).length
        })
        .value()

    const totalNotificationsEnabled = _.sum(_.values(checkedSettingsForDs))
    const totalPossible = datasourcesForSettings.length * settingsForTabElement.length
    const allNotificationsEnabled = totalNotificationsEnabled === totalPossible

    const handleNotificationSettingAllClick = (event: ChangeEvent<HTMLInputElement>, settingName: string) => {
        datasourcesForSettings.forEach((datasource) => {
            form.change(`${datasource.id}.${tab}.${settingName}`, event.target.checked)
        })
    }
    const handleDatasourceAllNotificationSettingsClick = (event: ChangeEvent<HTMLInputElement>, dsId: string) => {
        settingsForTabElement.forEach((setting) => {
            form.change(`${dsId}.${tab}.${setting}`, event.target.checked)
        })
    }

    const handleMainCheckboxClick = (event: ChangeEvent<HTMLInputElement>) => {
        settingsForTabElement.forEach((setting) => {
            handleNotificationSettingAllClick(event, setting)
        })
    }

    if (datasourcesForSettings.length === 0) {
        return null
    }

    return (
        <Paper sx={{ pl: 2 }} elevation={darkMode ? 0 : undefined} ref={settingsPaperRef}>
            <Grid container>
                <Grid item xs={2}>
                    <Grid container flexDirection="column" pr={2} py={1}>
                        <Grid
                            item
                            sx={{
                                height: 66,
                                display: 'flex',
                                alignItems: 'center',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            gap={1}
                        >
                            <IconButton onClick={() => setExpanded(!expanded)} size="small">
                                <ExpandMore
                                    sx={{
                                        transform: `rotate(${expanded ? 180 : 0}deg)`,
                                        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                    }}
                                />
                            </IconButton>

                            <ListItemText primary={<strong>{project.name}</strong>} secondary={accountName} />
                        </Grid>

                        <Collapse in={expanded} sx={{ width: '100%' }}>
                            {datasourcesForSettings.map((ds) => {
                                return (
                                    <Grid
                                        key={ds.id}
                                        item
                                        sx={{
                                            height: 42,
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            gap: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: 34,
                                                minWidth: 34,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {getIconForDatasourceType(ds.type)}
                                        </Box>
                                        <Box
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {ds.name}
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Collapse>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        sx={{
                            py: 1,
                            overflowY: 'auto',
                            overflowX: 'auto',
                            backgroundImage: `linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.paper}), linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.paper}), linear-gradient(to right, rgba(0, 0, 0, .1), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 0, .1), rgba(255, 255, 255, 0))`,
                            backgroundPosition: 'left center, right center, left center, right center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
                            backgroundAttachment: 'local, local, scroll, scroll',
                        }}
                    >
                        <Grid container minWidth={950} flexDirection="column">
                            <Grid item height={66}>
                                <Grid container justifyContent="space-evenly">
                                    <Grid
                                        item
                                        xs={12 / (settingsForTabElement.length + 1)}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flex: '1 1 0',
                                        }}
                                    >
                                        <FormattedMessage id={`notifications.settings.all`} />

                                        <Checkbox
                                            onClick={(event) => event.stopPropagation()}
                                            onChange={handleMainCheckboxClick}
                                            checked={allNotificationsEnabled}
                                            indeterminate={!allNotificationsEnabled && totalNotificationsEnabled !== 0}
                                        />
                                    </Grid>

                                    {settingsForTabElement.map((setting) => {
                                        const allDatasourcesActive = amountOfCheckedDatasourcesForSetting[setting] === datasourcesForSettings.length
                                        const noDatasourcesActive = amountOfCheckedDatasourcesForSetting[setting] === 0

                                        return (
                                            <Tooltip
                                                key={setting}
                                                placement="top"
                                                title={<FormattedMessage id={`notifications.settings.${setting}.tooltip`} />}
                                                disableInteractive
                                            >
                                                <Grid
                                                    item
                                                    zeroMinWidth
                                                    xs={12 / (settingsForTabElement.length + 1)}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flex: '1 1 0',
                                                        textAlign: 'center',
                                                        wordBreak: 'break-word',
                                                        cursor: 'help',
                                                    }}
                                                >
                                                    <FormattedMessage id={`notifications.settings.${setting}`} />

                                                    <Checkbox
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => handleNotificationSettingAllClick(event, setting)}
                                                        indeterminate={!allDatasourcesActive && !noDatasourcesActive}
                                                        checked={allDatasourcesActive}
                                                        onMouseEnter={() => setHighlightedSetting(setting)}
                                                        onMouseLeave={() => setHighlightedSetting(undefined)}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                animation:
                                                                    setting === highlightedSettingFromUrl && project.id === selectedProjectId
                                                                        ? `${highlightFromUrlKeyframes} 1s linear 3`
                                                                        : undefined,
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Tooltip>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                            <Collapse in={expanded}>
                                {datasourcesForSettings.map((ds) => {
                                    const allSettingsForDsChecked = checkedSettingsForDs[ds.id] === settingsForTabElement.length
                                    const noSettingsForDsChecked = checkedSettingsForDs[ds.id] === 0
                                    return (
                                        <Grid item key={ds.id}>
                                            <Grid container justifyContent="space-evenly">
                                                <Grid
                                                    item
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={allSettingsForDsChecked}
                                                        indeterminate={!allSettingsForDsChecked && !noSettingsForDsChecked}
                                                        onChange={(event) => handleDatasourceAllNotificationSettingsClick(event, ds.id)}
                                                        onMouseEnter={() => setHighlightedDatasourceId(ds.id)}
                                                        onMouseLeave={() => setHighlightedDatasourceId(undefined)}
                                                    />
                                                </Grid>

                                                <NotificationSettingsRow
                                                    tab={tab}
                                                    rowName={getRowNameForDs(ds.id)}
                                                    highlightedSetting={highlightedSetting}
                                                    highlighted={ds.id === highlightedDatasourceId}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Collapse>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}
