import * as React from 'react'
import { FC } from 'react'
import { Alert, Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type NoLinkPreviewAvailableProps = {}

export const NoLinkPreviewAvailable: FC<NoLinkPreviewAvailableProps> = () => {
    return (
        <Box
            sx={{
                width: 'calc(100% + 32px)',
                ml: -2,
                mr: -2,
            }}
        >
            <Box display="flex" alignItems="center" py={1} px={2} width={'100%'}>
                <Alert severity="error">
                    <FormattedMessage id={'publishing.link-preview.no-metadata'} />
                </Alert>
            </Box>
        </Box>
    )
}
