import { FC } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { AdBlockIcon } from '../icons/AdBlockIcon'

type AdBlockerDialogProps = {
    open: boolean
    onClose: () => any
}

export const AdBlockerDialog: FC<AdBlockerDialogProps> = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} keepMounted={false}>
            <DialogTitle>
                <FormattedMessage id="adblocker.title" />
            </DialogTitle>

            <DialogContent>
                <Box display="flex" alignItems="center">
                    <Box p={4} pr={6}>
                        <AdBlockIcon sx={{ fontSize: 48 }} color={'error'} />
                    </Box>
                    <FormattedMessage id="adblocker.message" ignoreTag={false} />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage id="general.close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
