import * as React from 'react'
import { FC, useLayoutEffect, useRef } from 'react'
import * as _ from 'lodash'
import { Alert, Box, Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { FacebookPostImage } from '../content-analytics/posts/FacebookPostImage'
import { PostInfo } from '../common/post-info/PostInfo'
import { getLoadingPosts, getOnlyShowUnreadComments, getPostForSelectedTicket, getSelectedDetailedTicket, setOnlyShowUnreadComments } from './EngagementSlice'
import { FormattedMessage } from 'react-intl'
import { TicketViewNode } from './TicketViewNode'
import { Loading } from '../common/loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { Help, OpenInNew } from '@mui/icons-material'
import { HmstrDispatch } from '../core/Store'
import { getIsMobile } from '../core/slices/CoreSlice'
import { gsap } from 'gsap'
import { MarkAllAsReadButton } from './MarkAllAsReadButton'

type TicketViewPostProps = {
    datasource: ProjectDatasource
}

export const TicketViewPost: FC<TicketViewPostProps> = ({ datasource }) => {
    const dispatch = useDispatch<HmstrDispatch>()

    const post = useSelector(getPostForSelectedTicket)
    const onlyShowUnreadComments = useSelector(getOnlyShowUnreadComments)
    const detailedTicket = useSelector(getSelectedDetailedTicket)
    const isLoadingPosts = useSelector(getLoadingPosts)
    const isMobile = useSelector(getIsMobile)
    const main = useRef<any>()

    const openPermalink = () => {
        if (detailedTicket && detailedTicket.type === 'POST') {
            switch (datasource.type) {
                case 'FACEBOOK_PAGE':
                    window.open(`https://facebook.com/${detailedTicket.target_id}`)
                    break
                case 'INSTAGRAM_ACCOUNT':
                    window.open(detailedTicket.metadata?.permalink || `https://instagram.com/${datasource.metadata.name}`)
                    break
                case 'LINKED_IN':
                    window.open(`https://linkedin.com/feed/update/${detailedTicket.target_id}`)
                    break
            }
        }
    }

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const boxes = self.selector!('.skewElem')
            boxes.forEach((box: any) => {
                gsap.to(box, {
                    opacity: 0,
                    y: -20,
                    scale: 0.9,
                    ease: 'none',
                    scrollTrigger: {
                        scroller: main.current,
                        trigger: box,
                        start: 'bottom 20%',
                        end: 'bottom 0%',
                        scrub: 1,
                    },
                })
                gsap.from(box, {
                    opacity: 0,
                    ease: 'none',
                    y: 20,
                    scale: 0.9,
                    scrollTrigger: {
                        scroller: main.current,
                        trigger: box,
                        start: 'top 100%',
                        end: 'top 80%',
                        scrub: 1,
                    },
                })
            })
        }, main) // <- Scope!
        return () => ctx.revert() // <- Cleanup!
    }, [isLoadingPosts, onlyShowUnreadComments, detailedTicket?.nodes.length])

    return (
        <Grid
            item
            xs={11}
            xxl={8}
            maxHeight="100%"
            display="flex"
            flexDirection="column"
            maxWidth={'100%'}
            minWidth={isMobile ? '100%' : undefined}
            ref={main}
            sx={{
                overflowY: isMobile ? 'scroll' : undefined,
                overflowX: isMobile ? 'hidden' : undefined,
            }}
        >
            {post && datasource ? (
                <Box>
                    <Card sx={isMobile ? { display: 'flex', flexDirection: 'column', mb: 2 } : { display: 'flex' }}>
                        <FacebookPostImage post={post} size={220} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                                <PostInfo post={post} datasource={datasource} />
                            </CardContent>
                        </Box>
                    </Card>
                </Box>
            ) : isLoadingPosts ? (
                <Skeleton />
            ) : (
                <Alert
                    severity="info"
                    icon={<Help />}
                    action={
                        <IconButton onClick={openPermalink} color={'inherit'}>
                            <OpenInNew />
                        </IconButton>
                    }
                >
                    <FormattedMessage id="engagement.post-not-found.info" />
                </Alert>
            )}

            {detailedTicket ? (
                <>
                    <Box
                        mt={isMobile ? 0 : 6}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom={1}
                        borderColor="divider"
                        maxWidth={'100%'}
                        flexWrap={isMobile ? 'wrap' : undefined}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => dispatch(setOnlyShowUnreadComments(!onlyShowUnreadComments))}
                                    checked={onlyShowUnreadComments}
                                    size="small"
                                    color={'default'}
                                />
                            }
                            sx={{
                                whiteSpace: 'nowrap',
                            }}
                            label={<FormattedMessage id="engagement.only-show-unread" />}
                        />
                        <Box
                            display="flex"
                            alignItems={'center'}
                            justifyContent={isMobile ? 'space-between' : 'flex-end'}
                            width={'100%'}
                            gap={2}
                            flexShrink={1}
                        >
                            <Typography variant="subtitle2" mb={0.5}>
                                {detailedTicket.node_count}&nbsp;
                                <FormattedMessage id={detailedTicket.type === 'STORY' ? 'engagement.story-replies' : 'engagement.comments'} />
                                {detailedTicket.unread_node_count > 0 && (
                                    <Typography component="span" color="secondary" variant="subtitle2" fontSize={13}>
                                        &nbsp;/&nbsp;{detailedTicket.unread_node_count}&nbsp;
                                        <FormattedMessage id="engagement.unread" />
                                    </Typography>
                                )}
                            </Typography>

                            <MarkAllAsReadButton />
                        </Box>
                    </Box>

                    <Box mt={2} overflow={isMobile ? 'visible' : 'auto'} pr={2} mr={-2}>
                        <Grid container spacing={2}>
                            {_.uniqBy(detailedTicket.nodes, 'node_id').map((node, idx) => {
                                return (
                                    <TicketViewNode key={node.node_id} datasource={datasource} ticket={detailedTicket} node={node} level={0} index={idx + 1} />
                                )
                            })}
                        </Grid>
                    </Box>
                </>
            ) : (
                <Loading />
            )}
        </Grid>
    )
}
