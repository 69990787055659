import { Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import * as React from 'react'
import { FC } from 'react'
import { HmstrColors } from '../core/theme/hmstr-theme'

type DashboardStepperProps = {
    activeStep: number
    labels: string[]
    onStepChange: (step: number) => void
    sx?: any
}

export const DashboardStepper: FC<DashboardStepperProps> = ({ labels, activeStep, onStepChange, sx }) => {
    const ColorConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 23,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: HmstrColors.Orange,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: HmstrColors.Orange,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor: theme.palette.mode === 'dark' ? HmstrColors.DarkGrey : HmstrColors.LightGrey,
            borderRadius: 1,
        },
    }))

    // @ts-ignore
    const ColorConnectorIconRoot: any = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? HmstrColors.DarkGrey : HmstrColors.LightGrey,
        zIndex: 1,
        color: HmstrColors.White,
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        ...(ownerState.active && {
            backgroundColor: HmstrColors.Orange,
        }),
        ...(ownerState.completed && {
            backgroundColor: HmstrColors.Orange,
        }),
    }))

    return (
        <Stepper activeStep={activeStep} alternativeLabel connector={<ColorConnector />} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
            {labels.map((label, index) => {
                return (
                    <Step key={'stepper' + label} onClick={() => onStepChange(index)}>
                        <StepLabel
                            StepIconComponent={(props) => (
                                <ColorConnectorIconRoot ownerState={{ completed: props.completed, active: props.active }} className={props.className}>
                                    {index + 1}
                                </ColorConnectorIconRoot>
                            )}
                        >
                            <FormattedMessage id={label} />
                        </StepLabel>
                    </Step>
                )
            })}
        </Stepper>
    )
}
