import { FC } from 'react'
import { Field } from 'react-final-form'

type ConnectedHiddenFieldProps = {
    name: string
    validate?: any
}

export const ConnectedHiddenField: FC<ConnectedHiddenFieldProps> = (props) => {
    const { name, validate } = props

    return (
        <Field name={name} validate={validate}>
            {({ input }) => <input type="hidden" value={input.value || ''} onChange={input.onChange} onFocus={input.onFocus} onBlur={input.onBlur} />}
        </Field>
    )
}
