import { FC, useState } from 'react'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { createAccount } from './AccountActions'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { changeAccountWizardState, getAccountWizardState } from '../SettingsSlice'
import { AccountCreateForm } from './AccountCreateForm'
import { HmstrDispatch } from '../../core/Store'
import { fetchProducts } from '../billing/BillingActions'
import { AccountFormValues } from './AccountFormValues'
import { defaultAccountFormValues } from '../billing/BillingUtils'
import { FORM_ERROR } from 'final-form'
import { AccountPaymentForm } from './AccountPaymentForm'
import { Account } from './Account'
import { getIsProductive } from '../../core/api/ApiSlice'

type AccountCreateWizardProps = {}

export const AccountWizardSteps = ['accounts.wizard.metadata', 'accounts.wizard.package', 'accounts.wizard.payment-method']

export const AccountCreateWizard: FC<AccountCreateWizardProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const accountWizardState = useSelector(getAccountWizardState)
    const [clientSecret, setClientSecret] = useState<string | undefined>()
    const [customerId, setCustomerId] = useState<string | undefined>()
    const isProd = useSelector(getIsProductive)

    useEffectWithIdComparison(() => {
        dispatch(fetchProducts())
    }, [])

    const handleCreatAccountFormSubmit = (values: AccountFormValues) => {
        return new Promise((resolve) => {
            dispatch(
                createAccount({
                    ...values,
                    customer_id: values.customer_id !== 'new' ? values.customer_id : undefined,
                })
            ).then((action: any) => {
                if (action.type.includes('fulfilled')) {
                    const account = action.payload as Account
                    setClientSecret(account.payment_intent_secret)
                    setCustomerId(account.customer_id)
                    dispatch(changeAccountWizardState(accountWizardState + 1))
                    resolve(true)
                } else {
                    if (values.promotion_code) {
                        resolve({ [FORM_ERROR]: (action.payload && action.payload.detail) || 'general.error' })
                    } else {
                        if (isProd) {
                            resolve({ [FORM_ERROR]: 'general.error' })
                        } else {
                            resolve({ [FORM_ERROR]: (action.payload && action.payload.detail) || 'general.error' })
                        }
                    }
                }
            })
        })
    }

    return (
        <Container maxWidth="lg">
            <Box marginTop={4}>
                <Box marginBottom={4}>
                    <Typography variant="h4">
                        <FormattedMessage id="accounts.setup" />
                    </Typography>
                </Box>
            </Box>

            <Stepper activeStep={accountWizardState}>
                {AccountWizardSteps.map((label) => {
                    return (
                        <Step key={label}>
                            <StepLabel>
                                <FormattedMessage id={label} />
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>

            {accountWizardState !== 2 && <AccountCreateForm onSubmit={handleCreatAccountFormSubmit} initialValues={defaultAccountFormValues} />}

            {accountWizardState === 2 && clientSecret && customerId && <AccountPaymentForm clientSecret={clientSecret} customerId={customerId} />}
        </Container>
    )
}
