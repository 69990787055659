import * as _ from 'lodash'
import { IntlShape } from 'react-intl'

export const extractPieData = (data: { name: any; value: number; color: string; real_value?: string }[], intl: IntlShape) => {
    let totalvalue = 0
    data.forEach((d) => (totalvalue = totalvalue + d.value))

    let dataFitlered = _.reverse(
        _.sortBy(
            _.filter(data, (v) => v.value > 0),
            (d) => d.value
        )
    )
    let dataTop4
    let dataRest = ''
    if (dataFitlered.length > 4) {
        dataTop4 = _.reverse(
            _.sortBy(
                _.filter(data, (v) => v.value > 0),
                (d) => d.value
            )
        ).slice(0, 4)
        dataRest = _.reverse(
            _.sortBy(
                _.filter(data, (v) => v.value > 0),
                (d) => d.value
            )
        )
            .slice(5, data.length)
            .map((v) => {
                return `${intl.formatMessage({ id: v.name })}: ${v.value}`
            })
            .join('\n')
    } else if (dataFitlered.length >= 4) {
        dataTop4 = _.reverse(
            _.sortBy(
                _.filter(data, (v) => v.value > 0),
                (d) => d.value
            )
        ).slice(0, 4)
    } else {
        dataTop4 = dataFitlered
    }
    return { totalvalue, dataTop4, dataRest }
}
