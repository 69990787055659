import React, { FC } from 'react'
import { Box } from '@mui/material'
import { ProjectDatasource } from './ProjectDatasource'
import { PotentialDatasource } from './PotentialDatasource'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { fetchFederatedIdentites } from '../user-management/UserActions'
import { getCurrentUser } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { DatasourceSelectPanel } from './DatasourceSelectPanel'
import { DatasourcesGrid } from './DatasourcesGrid'

type DatasourceManagementProps = {
    selectedDatasources: ProjectDatasource[]
    onSelect?: (potentialDatasource: PotentialDatasource) => any
    onDelete: (datasourceId: string) => any
    onEdit?: (projectDatasource: ProjectDatasource, resolve: any) => any
    hideAlert?: boolean
    limitExceeded?: boolean
}

export const DatasourceManagement: FC<DatasourceManagementProps> = ({ selectedDatasources, onSelect, onDelete, onEdit, hideAlert, limitExceeded }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const currentUser = useSelector(getCurrentUser)

    const handleSelect = (potentialDatasource: PotentialDatasource) => {
        if (onSelect) {
            onSelect(potentialDatasource)
        }
    }

    useEffectWithIdComparison(() => {
        dispatch(fetchFederatedIdentites(currentUser))
    }, [currentUser])

    return (
        <Box>
            <DatasourceSelectPanel onSelect={handleSelect} selectedDatasources={selectedDatasources} limitExceeded={limitExceeded} />
            <DatasourcesGrid datasources={selectedDatasources} onDelete={onDelete} hideAlert={hideAlert} onEdit={onEdit} />
        </Box>
    )
}
