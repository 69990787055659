import { FC } from 'react'
import { HmstrAsideLayout } from '../../common/hmstr-aside-layout/HmstrAsideLayout'
import { AccountPaymentForm } from './AccountPaymentForm'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getSelectedAccount } from '../../core/slices/CoreSlice'
import { Container, Link, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { HeaderWithSwitcher } from '../../common/header-with-switcher/HeaderWithSwitcher'
import { getSelectedAccountSubscription } from '../SettingsSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { HmstrDispatch } from '../../core/Store'
import { fetchSubscriptionById } from '../billing/BillingActions'
import { SnackbarHandler } from '../../common/success-snackbar/SnackbarHandler'

type AccountPaymentRequiredProps = {}

export const AccountPaymentRequired: FC<AccountPaymentRequiredProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const account = useSelector(getSelectedAccount)
    const subscription = useSelector(getSelectedAccountSubscription)
    const total = intl.formatNumber(subscription?.latest_invoice.amount_due / 100, {
        style: 'currency',
        currency: 'EUR',
    })
    const currentUser = useSelector(getCurrentUser)
    const isOwner = account.owner_id === currentUser.id

    useEffectWithIdComparison(() => {
        if (account) {
            dispatch(fetchSubscriptionById(account))
        }
    }, [account])

    return (
        <HmstrAsideLayout>
            <Container maxWidth="md">
                <HeaderWithSwitcher />

                <Typography variant="subtitle1" sx={{ mt: 4 }}>
                    {isOwner ? (
                        <>
                            <FormattedMessage id="hint.account.payment-required-description" values={{ total: total }} />
                            &nbsp;
                            {subscription && subscription.latest_invoice && (
                                <FormattedMessage
                                    id="hint.account.payment-required-link"
                                    values={{
                                        link: (
                                            <Link href={subscription.latest_invoice.invoice_pdf}>
                                                <FormattedMessage id="general.here" />
                                            </Link>
                                        ),
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <FormattedMessage id="hint.account.locked-no-owner" />
                    )}
                </Typography>

                {isOwner && <AccountPaymentForm clientSecret={account.payment_intent_secret} customerId={account.customer_id} account={account} />}
            </Container>

            <SnackbarHandler />
        </HmstrAsideLayout>
    )
}
