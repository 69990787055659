import * as _ from 'lodash'
import * as React from 'react'
import { FC, PropsWithChildren } from 'react'
import { Field, FieldInputProps, useForm, useFormState } from 'react-final-form'
import { Autocomplete, Box, Checkbox, Chip, darken, lighten, TextField, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getIconForDatasourceType } from '../settings/datasources/DatasourceTypeMappings'
import { getDatasourcesAsIdMap, getPagesForPublish, isDarkMode } from '../core/slices/CoreSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { PublishingFormValues, ValidDatasourceTypeForPublishing } from './PublishingForm'
import {
    changePostDialogSelectedNetwork,
    getAllowedDatasourcesForPublishing,
    getAllowedDomainsByDatasource,
    getCheckedDomainsByDatasourceIds,
    getPostDialogSelectedNetwork,
    getSelectedPostGroup,
    unsetErrorsForCommonFilesByNetwork,
} from './PublishingSlice'
import { getIn } from 'final-form'
import { checkIsDatasourceDomainOwner } from './PublishingActions'
import { validateUrl } from '../form/validate'
import { HmstrDispatch } from '../core/Store'
import { checkEqualRawArrays } from '../core/helpers/check-equal-raw-arrays'

type PublishingDatasourceSelectionProps = {
    name: string
    validate?: any
    onChange?: (newType: string) => any
    disabled?: boolean
}

export const PublishingDatasourceSelection: FC<PropsWithChildren<PublishingDatasourceSelectionProps>> = (props) => {
    const { name, disabled } = props

    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)
    const allowedDatasourcesForPublishing = useSelector(getAllowedDatasourcesForPublishing)
    const datasources = useSelector(getPagesForPublish)
    const form = useForm()
    const formState = useFormState<PublishingFormValues>()
    const datasourcesByIds = useSelector(getDatasourcesAsIdMap)
    const selectedNetwork = useSelector(getPostDialogSelectedNetwork)
    const checkedDomainsByDsId = useSelector(getCheckedDomainsByDatasourceIds)
    const allowedDomainsByDsId = useSelector(getAllowedDomainsByDatasource)
    const postGroups = useSelector(getSelectedPostGroup)
    const datasourceIdsWithErrors = _.map(postGroups?.posts || [], (post) => {
        return post.state === 'PUBLISH_FAILED' ? post.data_source_id : undefined
    }).filter((d) => d) as string[]

    const checkDatasourceDomainOwnership = (datasource: ProjectDatasource, url: string) => {
        const domainsForDatasource = _.get(checkedDomainsByDsId, datasource.id) || []
        const hostname = validateUrl(url) ? undefined : new URL(url).hostname

        if (hostname && !domainsForDatasource.includes(hostname)) {
            dispatch(
                checkIsDatasourceDomainOwner({
                    datasource: datasource,
                    link: url,
                })
            )
        }
    }

    let handleChange = (datasources: ProjectDatasource[], input: FieldInputProps<any>) => {
        const currentDatasourceIds = formState.values.data_source_ids
        const changedDatasourceIds = datasources.map((ds) => ds.id)
        input.onChange(changedDatasourceIds)

        const currentDatasources = _.chain(changedDatasourceIds)
            .map((id) => datasourcesByIds[id])
            .value()

        if (currentDatasourceIds.length === 0 && datasources.length === 1) {
            dispatch(changePostDialogSelectedNetwork(datasources[0].type as any))
        }

        const diffDatasources = _.chain(changedDatasourceIds)
            .difference(currentDatasourceIds)
            .map((id) => datasourcesByIds[id])
            .value()

        const removeFieldIfExists = (name: string) => {
            if (form.getRegisteredFields().includes(name)) {
                form.change(name, undefined)
                form.resetFieldState(name)
            }
        }

        const creatorAccounts: ProjectDatasource[] = _.flatMap(
            datasources,
            (igDatasource) => _.get(igDatasource.metadata, 'account_type') === 'CONTENT_CREATOR' && igDatasource
        ).filter((d) => d) as ProjectDatasource[]

        if (creatorAccounts.length > 0) {
            form.change(`postByType.INSTAGRAM_ACCOUNT.ig_publish_as_story`, undefined)
            form.change(`common_post.ig_publish_as_story`, undefined)
        }

        const dsTypes = _.keys(_.groupBy(datasources, 'type'))
        const url = getIn(formState, `values.${formState.values.customize_posts_by_network ? 'postByType.FACEBOOK_PAGE' : 'common_post'}.link`)
        const strippedUrl = url ? (url.endsWith('/') ? url.slice(0, -1) : url) : undefined
        const isUrlValid = validateUrl(strippedUrl) === undefined
        const hostname = isUrlValid ? new URL(strippedUrl).hostname : undefined
        const stillIncludesDomainOwner = _.compact(
            currentDatasources.map((datasource) => {
                if (datasource.type === 'FACEBOOK_PAGE' && Object.keys(allowedDomainsByDsId).includes(datasource.id)) {
                    return allowedDomainsByDsId[datasource.id].includes(hostname || '')
                } else return datasource.type === 'LINKED_IN' && !formState.values.customize_posts_by_network
            })
        ).includes(true)

        if (formState.values.customize_posts_by_network) {
            if (!stillIncludesDomainOwner && hostname) {
                removeFieldIfExists('postByType.FACEBOOK_PAGE.link_preview_description')
                removeFieldIfExists('postByType.FACEBOOK_PAGE.link_preview_title')
                removeFieldIfExists('postByType.FACEBOOK_PAGE.link_preview_file_id')
            }

            const removedDatasources = _.chain(currentDatasourceIds)
                .difference(changedDatasourceIds)
                .map((id) => datasourcesByIds[id])
                .value()
            removedDatasources.forEach((ds) => {
                if (Object.keys(formState.values.postByType).includes(ds.type)) {
                    formState.values.postByType[ds.type as 'LINKED_IN' | 'FACEBOOK_PAGE' | 'TIKTOK_ACCOUNT' | 'INSTAGRAM_ACCOUNT']?.file_ids?.forEach(
                        (file: string) => {
                            dispatch(
                                unsetErrorsForCommonFilesByNetwork({
                                    file_id: file,
                                    network: ds.type as 'LINKED_IN' | 'FACEBOOK_PAGE' | 'TIKTOK_ACCOUNT' | 'INSTAGRAM_ACCOUNT',
                                })
                            )
                        }
                    )
                }
                // formState.values.common_post.file_ids = []
            })

            if (selectedNetwork && !dsTypes.includes(selectedNetwork)) {
                dispatch(changePostDialogSelectedNetwork(dsTypes[0] as ValidDatasourceTypeForPublishing))
                delete formState.values.postByType[selectedNetwork as 'LINKED_IN' | 'FACEBOOK_PAGE' | 'TIKTOK_ACCOUNT' | 'INSTAGRAM_ACCOUNT']
            } else if (diffDatasources.length > 0) {
                dispatch(changePostDialogSelectedNetwork(diffDatasources[0].type as ValidDatasourceTypeForPublishing))
            }

            diffDatasources.forEach((datasource) => {
                if (datasource.type === 'FACEBOOK_PAGE') {
                    checkDatasourceDomainOwnership(datasource, getIn(formState, 'values.postByType.FACEBOOK_PAGE.link'))
                }

                if (!_.get(formState.values, `postByType.${datasource.type}`)) {
                    form.change(`postByType.${datasource.type}` as any, {
                        ...formState.values.common_post,
                        data_source_id: datasource.id,
                        data_source_type: datasource.type,
                    })
                }
            })
        } else {
            diffDatasources.forEach((datasource) => {
                if (datasource.type === 'FACEBOOK_PAGE') {
                    checkDatasourceDomainOwnership(datasource, getIn(formState, 'values.common_post.link'))
                }
            })
        }
    }

    return (
        <Field
            name={name}
            key={formState.values.customize_posts_by_network.toString()}
            isEqual={checkEqualRawArrays}
            validate={(value) => {
                if (value.length === 0) {
                    return intl.formatMessage({ id: 'validations.required' })
                }
            }}
        >
            {({ input, meta }) => {
                const filteredDatasources = datasources.filter((ds) => (input.value || []).includes(ds.id))

                return (
                    <Autocomplete
                        multiple
                        sx={{ flexGrow: 1 }}
                        value={filteredDatasources}
                        onChange={(event, value) => handleChange(value, input)}
                        options={allowedDatasourcesForPublishing}
                        disabled={disabled}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox checked={selected} color={'default'} />
                                {getIconForDatasourceType(option.type)}
                                <Box component="span" ml={1}>
                                    {option.name}
                                </Box>
                            </li>
                        )}
                        renderTags={(value, getTagProps) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} p="3px">
                                {value.map((datasource: ProjectDatasource, index) => {
                                    return (
                                        <Chip
                                            sx={{
                                                background: datasourceIdsWithErrors.includes(datasource.id)
                                                    ? darkmode
                                                        ? darken(theme.palette.error.light, 0.9)
                                                        : lighten(theme.palette.error.light, 0.9)
                                                    : undefined,
                                                border: datasourceIdsWithErrors.includes(datasource.id)
                                                    ? `2.5px solid ${darkmode ? theme.palette.error.dark : theme.palette.error.light}`
                                                    : undefined,
                                            }}
                                            key={datasource.id}
                                            icon={getIconForDatasourceType(datasource.type)}
                                            label={datasource.name}
                                            onDelete={!disabled ? getTagProps({ index }).onDelete : undefined}
                                        />
                                    )
                                })}
                            </Box>
                        )}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    onFocus={input.onFocus}
                                    onBlur={input.onBlur}
                                    variant="outlined"
                                    helperText={(meta.touched ? meta.error : undefined) || ' '}
                                    error={meta.touched && Boolean(meta.error)}
                                    label={
                                        <>
                                            <FormattedMessage id="general.datasources" />*
                                        </>
                                    }
                                    InputLabelProps={{
                                        ...params.InputLabelProps,
                                        variant: 'outlined',
                                    }}
                                />
                            )
                        }}
                    />
                )
            }}
        </Field>
    )
}
