import { Account } from '../settings/accounts/Account'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { FC } from 'react'
import moment from 'moment/moment'
import { AccessAlarm, Lock, Warning } from '@mui/icons-material'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { UserProjectAccess } from '../settings/user-management/User'
import { getCurrentUser, getIsMobile, getSelectedAccount, getSelectedProject, getSelectedProjectId, selectProject } from '../core/slices/CoreSlice'
import * as _ from 'lodash'
import { Dot } from '../common/dot/Dot'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { getAccounts, getProjects } from '../core/slices/DataSlice'
import { getOpenTicketCountByProject } from '../engagement/EngagementSlice'
import { useStartTour } from '../common/guides/useStartTour'
import { useLocation } from 'react-router-dom'
import { ProjectSelectAccountSubheader } from './ProjectSelectAccountSubheader'

type ProjectSelectProps = {}

export const ProjectSelect: FC<ProjectSelectProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const theme = useTheme()
    const navigate = useProjectNavigate()
    const projects = useSelector(getProjects)
    const selectedProjectId = useSelector(getSelectedProjectId)
    const selectedProject = useSelector(getSelectedProject)
    const selectedAccount = useSelector(getSelectedAccount)
    const currentUser = useSelector(getCurrentUser)
    const accounts = useSelector(getAccounts)
    const openTicketCountByProject = useSelector(getOpenTicketCountByProject)
    const location = useLocation()
    const projectsByAccount = _.chain(currentUser?.accounts || [])
        .mapKeys('account_id')
        .mapValues((acc) => acc.projects)
        .value()
    const isMobile = useSelector(getIsMobile)

    useStartTour('secondarySidebarTour')

    const getTicketCountForProject = (projectId: string) => {
        const openTicketCount = _.chain(openTicketCountByProject)
            .find((value, key) => {
                return key === projectId
            })
            .value()

        if (openTicketCount) {
            return openTicketCount
        }
        return 0
    }

    const renderProjectTicketAdornment = (project_id: string) => {
        const valueForProject = getTicketCountForProject(project_id)

        return (
            valueForProject > 0 && (
                <Tooltip title={<FormattedMessage id={'engagement.open-tickets'} values={{ count: valueForProject }} />}>
                    <span>
                        <Dot color={theme.palette.secondary.main} size={24}>
                            <Typography variant="subtitle2" color={theme.palette.secondary.contrastText}>
                                {valueForProject}
                            </Typography>
                        </Dot>
                    </span>
                </Tooltip>
            )
        )
    }

    const renderSelectContent = () => {
        const elements = [] as any
        const userAccountAccesses = currentUser?.accounts || []

        userAccountAccesses.forEach((accountAccess) => {
            const account = accounts[accountAccess.account_id]
            const projectAccessesForAccount = projectsByAccount[accountAccess.account_id] || ([] as UserProjectAccess[])

            if (account) {
                elements.push(
                    <ProjectSelectAccountSubheader
                        key={accountAccess.account_id}
                        accountAccess={accountAccess}
                        account={account}
                        renderSecondaryAction={renderSecondaryActionIfNecessary}
                        projectAccessesForAccount={projectAccessesForAccount}
                    />
                )

                projectAccessesForAccount.forEach((p) => {
                    elements.push(
                        <MenuItem
                            key={p.project_id}
                            sx={{
                                pl: 4,
                                borderLeft: 4,
                                borderColor: selectedProject?.id === p.project_id ? 'secondary.main' : `${theme.palette.secondary.main}80`,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            value={p.project_id}
                            onClick={() => handleProjectSelect(p.project_id)}
                        >
                            {p.name}
                            {renderProjectTicketAdornment(p.project_id)}
                        </MenuItem>
                    )
                })
            }
        })

        return elements
    }

    const handleProjectSelect = (projectId: string) => {
        if (projectId !== selectedProjectId) {
            const newProject = projects[projectId]
            const newAccount = accounts[newProject.account_id]
            if (location.pathname.includes('/settings/billing') && !['ACTIVE', 'TRIALING'].includes(newAccount.status)) {
                navigate('/')
            }

            dispatch(selectProject(projectId))
        }
    }

    const renderSecondaryActionIfNecessary = (account: Account) => {
        const daysRemaining = moment(account.trial_end).diff(moment().subtract(1, 'day'), 'days')
        let icon

        switch (account.status) {
            case 'SETUP':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.setup" />}>
                        <Warning fontSize="small" color="warning" />
                    </Tooltip>
                )
                break
            case 'LOCKED':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.locked" />}>
                        <Lock sx={{ cursor: 'pointer' }} fontSize="small" color="error" onClick={() => navigate('/settings/billing/general')} />
                    </Tooltip>
                )
                break
            case 'TRIAL_EXPIRED':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.trial-expired" />}>
                        <Lock fontSize="small" color="error" />
                    </Tooltip>
                )
                break
            case 'TRIALING':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.trial" values={{ days: daysRemaining }} />}>
                        <AccessAlarm fontSize="small" color="action" />
                    </Tooltip>
                )
                break
            case 'ACTIVE':
            default:
                return null
        }

        return (
            <Box display="flex" mr={1}>
                {icon}
            </Box>
        )
    }

    return (
        <Box mt={2} width="100%" id={'secondary_nav_switcher'}>
            <FormControl fullWidth>
                <InputLabel variant="outlined" id="project-select-label">
                    <FormattedMessage id="general.project-selector-label" />
                </InputLabel>
                <Select
                    fullWidth
                    startAdornment={selectedAccount && renderSecondaryActionIfNecessary(selectedAccount)}
                    MenuProps={{
                        MenuListProps: {
                            sx: !isMobile ? { pt: 0, pb: 0, width: 280 } : {},
                        },
                        PaperProps: {
                            sx: !isMobile ? { left: '104px !important' } : {},
                        },
                    }}
                    label={intl.formatMessage({ id: 'general.project-selector-label' })}
                    labelId="project-select-label"
                    variant="outlined"
                    size="small"
                    value={selectedProjectId}
                    renderValue={() => (selectedAccount && selectedProject ? `${selectedAccount.name} / ${selectedProject.name}` : '')}
                >
                    {renderSelectContent()}
                </Select>
            </FormControl>
        </Box>
    )
}
