import * as React from 'react'
import { FC } from 'react'
import { Form } from 'react-final-form'
import { IconButton, Tooltip } from '@mui/material'
import { MentionProps } from 'react-mentions'
import { FormattedMessage } from 'react-intl'
import { Send } from '@mui/icons-material'
import { FormApi } from 'final-form'
import { ConnectedMentionsInput } from '../../form/ConnectedMentionsInput'

export type NoteFormValues = {
    message: string
    file?: any
}

type NoteFormProps = {
    initialValues: NoteFormValues
    onSubmit: (values: NoteFormValues, form: FormApi<NoteFormValues, Partial<NoteFormValues>>) => void
    autofocus?: boolean
    placeholder?: string
    children: React.ReactElement<MentionProps> | Array<React.ReactElement<MentionProps>>
}

export const NoteForm: FC<NoteFormProps> = ({ onSubmit, initialValues, autofocus, placeholder, children }) => {
    return (
        <Form<NoteFormValues>
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => {
                const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                        if (values.message) {
                            handleSubmit()
                        }
                    }
                }

                return (
                    <ConnectedMentionsInput
                        autofocus={autofocus}
                        placeholder={placeholder}
                        handleKeyDown={handleKeyDown}
                        button={
                            <Tooltip title={<FormattedMessage id={'general.submit'} />}>
                                <IconButton
                                    sx={{
                                        flexShrink: 0,
                                        transition: 'all 0.1s',
                                        pointerEvents: !(values.message?.length > 0) ? 'none' : 'all',
                                        opacity: !(values.message?.length > 0) ? 0 : 1,
                                    }}
                                    type={'submit'}
                                    color={'secondary'}
                                    onClick={handleSubmit}
                                    disabled={!(values.message?.trim().length > 0)}
                                >
                                    <Send />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        {children}
                    </ConnectedMentionsInput>
                )
            }}
        />
    )
}
