import React, { FC, useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { PublishingFormValues, ValidDatasourceTypeForPublishing } from './PublishingForm'
import {
    changePostDialogSelectedNetwork,
    getPostDialogSelectedNetwork,
    getRelevantStateForSelectedPostGroup,
    getSelectedPostGroup,
    isApprovalProcessActive,
} from './PublishingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getDatasourcesAsIdMap } from '../core/slices/CoreSlice'
import { HmstrDispatch } from '../core/Store'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import _ from 'lodash'
import { IdMap } from '../core/slices/DataSlice'
import { Box, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getIconForDatasourceType } from '../settings/datasources/DatasourceTypeMappings'
import { ArrowBackIosRounded, DesktopMac, Smartphone } from '@mui/icons-material'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { PublishingPostPreviewMobile } from './post-preview/PublishingPostPreviewMobile'
import { PublishingFormApproval } from './approval/PublishingFormApproval'
import { PublishingFormNavigationBlocker } from './PublishingFormNavigationBlocker'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'

type PublishingFormComponentMobileProps = {
    handleSubmit: any
    disabled?: boolean
    overwritePostState?: string
    disableNewPosts?: boolean
}

export const PublishingFormComponentMobile: FC<PublishingFormComponentMobileProps> = ({ handleSubmit }) => {
    const theme = useTheme()
    const dispatch = useDispatch<HmstrDispatch>()
    const form = useForm()
    const formState = useFormState<PublishingFormValues>()
    const values = formState.values
    const approvalActive = useSelector(isApprovalProcessActive)
    const postGroup = useSelector(getSelectedPostGroup)
    const relevantPostState = useSelector(getRelevantStateForSelectedPostGroup)
    const customizePostsByNetwork = values.customize_posts_by_network
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const postType = useSelector(getPostDialogSelectedNetwork)
    const [postPreviewDevice, setPostPreviewDevice] = useState<'desktop' | 'mobile'>('desktop')
    const backToList = window.location.pathname.includes('/publishing/list')
    const datasources = values.data_source_ids.map((ds_id) => datasourcesById[ds_id])
    const datasourcesByType = _.groupBy(datasources, 'type') as IdMap<ProjectDatasource[]>
    const datasourceTypes = _.keys(datasourcesByType).sort() as ValidDatasourceTypeForPublishing[]
    const currentPostType = datasourceTypes.length === 0 ? 'COMMON' : postType
    const navigate = useProjectNavigate()

    let handleTabChange = (newType: ValidDatasourceTypeForPublishing) => {
        dispatch(changePostDialogSelectedNetwork(newType))
    }

    useEffect(() => {
        if (datasourceTypes.length === 0) {
            form.change('customize_posts_by_network', false)
        } else if (postType && !datasourceTypes.includes(postType)) {
            dispatch(changePostDialogSelectedNetwork(datasourceTypes[0]))
        }
    })

    const handleBack = () => {
        navigate(backToList ? `/publishing/list` : `/publishing/calendar`)
    }

    return (
        <ContentPanel
            title={
                <Box display="flex" alignItems="center" gap={2} width={'92vw'}>
                    <Box color="inherit" onClick={handleBack}>
                        <Typography variant="h5" display="flex" alignItems="center">
                            <ArrowBackIosRounded />
                            <FormattedMessage id="general.back" />
                        </Typography>
                    </Box>
                    <Box flexGrow={1} />
                    <Box display="flex" gap={2}>
                        {!customizePostsByNetwork && (
                            <ToggleButtonGroup disabled={customizePostsByNetwork} size="small" value={customizePostsByNetwork ? '' : postType}>
                                {datasourceTypes.map((dt) => (
                                    <ToggleButton value={dt} key={dt} onClick={() => handleTabChange(dt)}>
                                        {getIconForDatasourceType(dt)}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        )}

                        <ToggleButtonGroup size="small" value={postPreviewDevice}>
                            <ToggleButton value="desktop" onClick={() => setPostPreviewDevice('desktop')}>
                                <DesktopMac />
                            </ToggleButton>
                            <ToggleButton value="mobile" onClick={() => setPostPreviewDevice('mobile')}>
                                <Smartphone />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>
            }
        >
            <form
                onSubmit={handleSubmit}
                noValidate
                style={{
                    height: '100%',
                    overscrollBehavior: 'none !important',
                    touchAction: 'none !important',
                }}
            >
                <Box
                    height="100%"
                    sx={{
                        overscrollBehavior: 'none !important',
                        touchAction: 'none !important',
                    }}
                >
                    <PublishingPostPreviewMobile currentTypeTab={currentPostType || 'COMMON'} postPreviewDevice={postPreviewDevice} />
                    {approvalActive && postGroup && relevantPostState !== 'PUBLISH_SUCCESS' && (
                        <Box
                            sx={{
                                position: 'sticky',
                                bottom: '0px',
                                top: '100vh',
                                mt: 2,
                                zIndex: '1200',
                                background: theme.palette.background.default,
                                boxShadow: `0 0 5px 20px ${theme.palette.background.default}`,
                            }}
                        >
                            <PublishingFormApproval postGroup={postGroup} />
                        </Box>
                    )}
                </Box>
                <PublishingFormNavigationBlocker />
            </form>
        </ContentPanel>
    )
}
