import { IntlShape } from 'react-intl'
import { FormApi, FormState, getIn } from 'final-form'
import { PublishingFormValues } from './PublishingForm'
import { IdMap } from '../core/slices/DataSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { PublishingMedia } from './PublishingMedia'

export const deniedDatasourceAndTypeCombinations = {
    INSTAGRAM_ACCOUNT: ['LINK', 'TEXT'],
    FACEBOOK_PAGE: [],
    LINKED_IN: [],
    TIKTOK_ACCOUNT: ['LINK', 'TEXT', 'IMAGE'],
}

export const imageLimitations: {
    [key: string]: {
        minAspect: number
        maxAspect: number
        minAspectString: string
        maxAspectString: string
        minAspectLinkPreview: number
        maxAspectLinkPreview: number
        minAspectStringLinkPreview: string
        maxAspectStringLinkPreview: string
        minAspectVideoString: string
        maxAspectVideoString: string
        minAspectVideo: number
        maxAspectVideo: number
        minAspectVideoCarousel: number
        maxAspectVideoCarousel: number
        minAspectVideoCarouselString: string
        maxAspectVideoCarouselString: string
        maxFileSize: number // MB
        maxFileSizeVideo: number // MB
        maxFileSizeVideoCarousel: number // MB
        minHeight: number
        minWidth: number
        minVideoHeight: number
        minVideoWidth: number
        maxHeight: number
        maxWidth: number
        maxVideoHeight: number
        maxVideoWidth: number
        minHeightLinkPreview: number
        minWidthLinkPreview: number
        multimediaVideoDenied: boolean
        minVideoDurationSeconds: number
        maxVideoDurationSeconds: number
        minVideoDurationSecondsCarousel: number
        maxVideoDurationSecondsCarousel: number
        minVideoStoryDurationSeconds: number
        maxVideoStoryDurationSeconds: number
        maxPixelCount?: number
        maxMultimediaFiles?: number
    }
} = {
    INSTAGRAM_ACCOUNT: {
        minAspect: 4 / 5.1,
        maxAspect: 1.91,
        minAspectString: '4 : 5',
        maxAspectString: '1.91 : 1',
        minAspectLinkPreview: 0,
        maxAspectLinkPreview: 600,
        minAspectStringLinkPreview: '0:0',
        maxAspectStringLinkPreview: '600 : 1',
        minAspectVideo: 9 / 16,
        maxAspectVideo: 1.91,
        minAspectVideoString: '9 : 16',
        maxAspectVideoString: '1.91 : 1',
        minAspectVideoCarousel: 4 / 5.1,
        maxAspectVideoCarousel: 16 / 9,
        minAspectVideoCarouselString: '4 : 5',
        maxAspectVideoCarouselString: '16 : 9',
        maxFileSize: 8,
        maxFileSizeVideo: 1000,
        maxFileSizeVideoCarousel: 100,
        minHeight: 200,
        minWidth: 200,
        minVideoWidth: 320,
        minVideoHeight: 320,
        maxHeight: 3000,
        maxWidth: 3000,
        maxVideoWidth: 1920,
        maxVideoHeight: 3500,
        minHeightLinkPreview: 0,
        minWidthLinkPreview: 0,
        multimediaVideoDenied: false,
        minVideoDurationSeconds: 3,
        maxVideoDurationSeconds: 900,
        minVideoDurationSecondsCarousel: 3,
        maxVideoDurationSecondsCarousel: 60,
        minVideoStoryDurationSeconds: 3,
        maxVideoStoryDurationSeconds: 60,
        maxMultimediaFiles: 10,
    },
    FACEBOOK_PAGE: {
        minAspect: 0.5,
        maxAspect: 2,
        minAspectVideo: 9 / 16,
        maxAspectVideo: 16 / 9,
        minAspectString: '9 : 16',
        maxAspectString: '1.91 : 1',
        minAspectVideoString: '9 : 16',
        maxAspectVideoString: '16 : 9',
        minAspectVideoCarousel: 9 / 16,
        maxAspectVideoCarousel: 16 / 9,
        minAspectVideoCarouselString: '9 : 16',
        maxAspectVideoCarouselString: '16 : 9',
        minAspectLinkPreview: 1.9,
        maxAspectLinkPreview: 1.92,
        minAspectStringLinkPreview: '1.91 : 1',
        maxAspectStringLinkPreview: '1.91 : 1',
        maxFileSize: 4,
        maxFileSizeVideoCarousel: 1000,
        maxFileSizeVideo: 1000,
        minHeight: 600,
        minWidth: 600,
        minVideoHeight: 600,
        minVideoWidth: 600,
        maxHeight: 3000,
        maxWidth: 3000,
        maxVideoHeight: 3000,
        maxVideoWidth: 3000,
        minHeightLinkPreview: 600,
        minWidthLinkPreview: 315,
        multimediaVideoDenied: true,
        minVideoDurationSeconds: 3,
        maxVideoDurationSeconds: 1200,
        minVideoDurationSecondsCarousel: 3,
        maxVideoDurationSecondsCarousel: 1200,
        minVideoStoryDurationSeconds: 3,
        maxVideoStoryDurationSeconds: 1200,
    },
    LINKED_IN: {
        minAspect: 2 / 3,
        maxAspect: 3,
        minAspectVideo: 1 / 2.4,
        maxAspectVideo: 2.4,
        minAspectString: '2 : 3',
        maxAspectString: '3 : 1',
        minAspectVideoString: '1 : 2.4',
        maxAspectVideoString: '2.4 : 1',
        minAspectVideoCarousel: 9 / 16,
        maxAspectVideoCarousel: 16 / 9,
        minAspectVideoCarouselString: '9 : 16',
        maxAspectVideoCarouselString: '16 : 9',
        minAspectLinkPreview: 0,
        maxAspectLinkPreview: 600,
        minAspectStringLinkPreview: '0:0',
        maxAspectStringLinkPreview: '600 : 1',
        maxFileSize: 8,
        maxFileSizeVideo: 5000,
        maxFileSizeVideoCarousel: 5000,
        minHeight: 276,
        minWidth: 552,
        minVideoHeight: 360, // https://www.linkedin.com/help/lms/answer/a424737/video-ads-advertising-specifications?lang=en
        minVideoWidth: 360,
        maxHeight: 3000,
        maxWidth: 3000,
        maxVideoHeight: 1920,
        maxVideoWidth: 1920,
        minHeightLinkPreview: 0,
        minWidthLinkPreview: 0,
        multimediaVideoDenied: true,
        minVideoDurationSeconds: 3,
        maxVideoDurationSeconds: 1800,
        minVideoDurationSecondsCarousel: 3,
        maxVideoDurationSecondsCarousel: 1800,
        minVideoStoryDurationSeconds: 3,
        maxVideoStoryDurationSeconds: 1800,
        maxPixelCount: 36152320,
        maxMultimediaFiles: 20,
    },
    TIKTOK_ACCOUNT: {
        minAspect: 2 / 3,
        maxAspect: 3,
        minAspectVideo: 1 / 2.4,
        maxAspectVideo: 2.4,
        minAspectString: '2 : 3',
        maxAspectString: '3 : 1',
        minAspectVideoString: '1 : 2.4',
        maxAspectVideoString: '2.4 : 1',
        minAspectVideoCarousel: 9 / 16,
        maxAspectVideoCarousel: 16 / 9,
        minAspectVideoCarouselString: '9 : 16',
        maxAspectVideoCarouselString: '16 : 9',
        minAspectLinkPreview: 0,
        maxAspectLinkPreview: 600,
        minAspectStringLinkPreview: '0:0',
        maxAspectStringLinkPreview: '600 : 1',
        maxFileSize: 20,
        maxFileSizeVideo: 4000,
        maxFileSizeVideoCarousel: 4000,
        minHeight: 360,
        minWidth: 360,
        minVideoHeight: 360,
        minVideoWidth: 360,
        maxHeight: 1080,
        maxWidth: 1920,
        maxVideoHeight: 4096,
        maxVideoWidth: 4096,
        minHeightLinkPreview: 0,
        minWidthLinkPreview: 0,
        multimediaVideoDenied: true,
        minVideoDurationSeconds: 3,
        maxVideoDurationSeconds: 600,
        minVideoDurationSecondsCarousel: 3,
        maxVideoDurationSecondsCarousel: 600,
        minVideoStoryDurationSeconds: 3,
        maxVideoStoryDurationSeconds: 600,
        maxMultimediaFiles: 10,
    },
}

export const validatePostType = (value: string, allValues: any, meta: any, intl: IntlShape, form: FormApi, datasourceKeys?: string[]) => {
    let forbidden_combinations = []
    let forbidden_combinations_literal = []
    let key: keyof typeof deniedDatasourceAndTypeCombinations
    if (value) {
        for (key in deniedDatasourceAndTypeCombinations) {
            if (datasourceKeys?.includes(key) && deniedDatasourceAndTypeCombinations[key].some((r) => value.includes(r))) {
                forbidden_combinations.push(intl.formatMessage({ id: key }))
                forbidden_combinations_literal.push(key)
            }
        }

        if (forbidden_combinations.length > 0) {
            return intl.formatMessage(
                { id: 'publishing.errors.datasource-and-type-not-matching' },
                {
                    datasources: forbidden_combinations.join(', '),
                    post_type: intl.formatMessage({ id: `publishing.type.${value.toString()}` }),
                }
            )
        }
    }
}

export type PublishingMediaLimitations = {
    maxFileSize: number
    minWidth: number
    minHeight: number
    maxWidth: number
    maxHeight: number
    minAspect: number
    maxAspect: number
    minAspectStr: string
    maxAspectStr: string
    multimediaVideoDenied: boolean
    minVideoDuration: number
    maxVideoDuration: number
    maxPixelCount?: number
}

const generateErrorsPerNetwork = (
    media: PublishingMedia,
    intl: IntlShape,
    platform: string,
    limitations: PublishingMediaLimitations,
    isInstagramStory?: boolean
) => {
    let allErrors: { message: string; network: string }[] = []
    if (media.category === 'VIDEO' && limitations.multimediaVideoDenied) {
        allErrors.push({
            message: intl.formatMessage({ id: 'publishing.errors.multimedia-video-denied' }),
            network: platform,
        })
    }

    if (media.file_size > 1e6 * limitations.maxFileSize) {
        allErrors.push({
            message: intl.formatMessage({ id: 'publishing.errors.file-too-big' }, { maxfilesize: `${limitations.maxFileSize}mb` }),
            network: platform,
        })
    }
    const imgWidth = media.width
    const imgHeight = media.height
    const imgAspect = imgWidth / imgHeight
    if (imgWidth < limitations.minWidth || imgHeight < limitations.minHeight) {
        allErrors.push({
            message: intl.formatMessage(
                { id: 'publishing.errors.min-size' },
                {
                    minheight: limitations.minHeight,
                    minwidth: limitations.minWidth,
                }
            ),
            network: platform,
        })
    }
    if (imgWidth > limitations.maxWidth || imgHeight > limitations.maxHeight) {
        allErrors.push({
            message: intl.formatMessage(
                { id: 'publishing.errors.max-size' },
                {
                    maxheight: limitations.maxHeight,
                    maxwidth: limitations.maxWidth,
                }
            ),
            network: platform,
        })
    }
    const duration = media.duration
    if (duration && (limitations.minVideoDuration > duration || duration > limitations.maxVideoDuration)) {
        allErrors.push({
            message: intl.formatMessage(
                { id: 'publishing.errors.duration-wrong' },
                {
                    minDuration: limitations.minVideoDuration,
                    maxDuration: limitations.maxVideoDuration,
                }
            ),
            network: platform,
        })
    }

    const getAspectError: () => 'publishing.errors.aspect-wrong-ig' | 'publishing.errors.aspect-wrong' | undefined = () => {
        const aspectTooSmall = imgAspect < limitations.minAspect
        const aspectTooLarge = imgAspect > limitations.maxAspect
        const alternateStoryAspect = 9 / 16
        const tolerance = 0.01
        const isDefaultAspectOutOfBounds = aspectTooLarge || aspectTooSmall
        const isInstagramStoryAspectOutOfBounds = !(imgAspect <= alternateStoryAspect) || imgAspect > alternateStoryAspect + tolerance
        if (isDefaultAspectOutOfBounds && !isInstagramStory) {
            return 'publishing.errors.aspect-wrong'
        } else if (isInstagramStory && isDefaultAspectOutOfBounds) {
            if (isInstagramStoryAspectOutOfBounds) {
                return 'publishing.errors.aspect-wrong-ig'
            } else {
                return undefined
            }
        }

        return undefined
    }

    const aspectError = getAspectError()
    if (aspectError) {
        allErrors.push({
            message: intl.formatMessage(
                { id: aspectError },
                {
                    minaspect: limitations.minAspectStr,
                    maxaspect: limitations.maxAspectStr,
                }
            ),
            network: platform,
        })
    }
    if (limitations.maxPixelCount && imgWidth * imgHeight > limitations.maxPixelCount) {
        allErrors.push({
            message: intl.formatMessage({ id: 'publishing.errors.resolution-too-big' }, { maxPixelCount: limitations.maxPixelCount }),
            network: platform,
        })
    }
    return allErrors.length > 0 ? allErrors : []
}

export const validatePublishingImage = (
    platform: string,
    intl: IntlShape,
    formState: FormState<PublishingFormValues>,
    media: PublishingMedia,
    dataSourceTypes: IdMap<ProjectDatasource[]>,
    uploadForNetwork: string[]
):
    | {
          message: string
          network: string
      }[]
    | undefined => {
    let allErrors: { message: string; network: string }[] = []
    if (dataSourceTypes) {
        const networksToIterate = uploadForNetwork.includes('ALL') ? Object.keys(dataSourceTypes) : uploadForNetwork
        networksToIterate.forEach((dskey) => {
            const individual = formState.values.customize_posts_by_network
            const individualMultimedia =
                individual && formState.values.postByType[dskey as 'FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN']?.post_type === 'MULTI_MEDIA'
            const commonMultimedia = formState.values.common_post.post_type === 'MULTI_MEDIA'
            const isMultiMediaPost = individualMultimedia || (!individual && commonMultimedia)
            const videoDenied = () => {
                if (isMultiMediaPost) {
                    return imageLimitations[dskey].multimediaVideoDenied
                } else {
                    return false
                }
            }

            const isInstagramStory = () => {
                if (formState.values.customize_posts_by_network) {
                    return getIn(formState.values, `postByType.${dskey}.ig_publish_as_story`)
                } else {
                    return getIn(formState.values, 'common_post.ig_publish_as_story')
                }
            }

            const getMaxFileSize: () => PublishingMediaLimitations['maxFileSize'] = () => {
                if (media.category === 'IMAGE') {
                    return imageLimitations[dskey].maxFileSize
                } else {
                    if (isMultiMediaPost) {
                        return imageLimitations[dskey].maxFileSizeVideoCarousel
                    } else {
                        return imageLimitations[dskey].maxFileSizeVideo
                    }
                }
            }

            const getMinWidth: () => PublishingMediaLimitations['minWidth'] = () => {
                if (media.link_preview) {
                    return imageLimitations[dskey].minWidthLinkPreview
                } else {
                    if (media.category === 'IMAGE') {
                        return imageLimitations[dskey].minWidth
                    } else {
                        return imageLimitations[dskey].minVideoWidth
                    }
                }
            }

            const getMinHeight: () => PublishingMediaLimitations['minHeight'] = () => {
                if (media.link_preview) {
                    return imageLimitations[dskey].minHeightLinkPreview
                } else {
                    if (media.category === 'IMAGE') {
                        return imageLimitations[dskey].minHeight
                    } else {
                        return imageLimitations[dskey].minVideoHeight
                    }
                }
            }
            const getMaxWidth: () => PublishingMediaLimitations['maxWidth'] = () => {
                if (media.category === 'IMAGE') {
                    return imageLimitations[dskey].maxWidth
                } else {
                    return imageLimitations[dskey].maxVideoWidth
                }
            }
            const getMaxHeight: () => PublishingMediaLimitations['maxHeight'] = () => {
                if (media.category === 'IMAGE') {
                    return imageLimitations[dskey].maxHeight
                } else {
                    return imageLimitations[dskey].maxVideoHeight
                }
            }

            const getMinAspect: () => PublishingMediaLimitations['minAspect'] = () => {
                if (media.link_preview) {
                    return imageLimitations[dskey].minAspectLinkPreview
                } else {
                    if (media.category === 'IMAGE') {
                        return imageLimitations[dskey].minAspect
                    } else if (isMultiMediaPost) {
                        return imageLimitations[dskey].minAspectVideoCarousel
                    } else {
                        return imageLimitations[dskey].minAspectVideo
                    }
                }
            }

            const getMaxAspect: () => PublishingMediaLimitations['maxAspect'] = () => {
                if (media.link_preview) {
                    return imageLimitations[dskey].maxAspectLinkPreview
                } else {
                    if (media.category === 'IMAGE') {
                        return imageLimitations[dskey].maxAspect
                    } else if (isMultiMediaPost) {
                        return imageLimitations[dskey].maxAspectVideoCarousel
                    } else {
                        return imageLimitations[dskey].maxAspectVideo
                    }
                }
            }

            const getMinAspectStr: () => PublishingMediaLimitations['minAspectStr'] = () => {
                if (media.link_preview) {
                    return imageLimitations[dskey].minAspectStringLinkPreview
                } else {
                    if (media.category === 'IMAGE') {
                        return imageLimitations[dskey].minAspectString
                    } else if (isMultiMediaPost) {
                        return imageLimitations[dskey].minAspectVideoCarouselString
                    } else {
                        return imageLimitations[dskey].minAspectVideoString
                    }
                }
            }

            const getMaxAspectStr: () => PublishingMediaLimitations['maxAspectStr'] = () => {
                if (media.link_preview) {
                    return imageLimitations[dskey].maxAspectStringLinkPreview
                } else {
                    if (media.category === 'IMAGE') {
                        return imageLimitations[dskey].maxAspectString
                    } else if (isMultiMediaPost) {
                        return imageLimitations[dskey].maxAspectVideoCarouselString
                    } else {
                        return imageLimitations[dskey].maxAspectVideoString
                    }
                }
            }

            const getMinVideoDuration: () => PublishingMediaLimitations['minVideoDuration'] = () => {
                if (isMultiMediaPost) {
                    return imageLimitations[dskey].minVideoDurationSecondsCarousel
                } else {
                    if (media.category === 'VIDEO' && !isInstagramStory()) {
                        return imageLimitations[dskey].minVideoDurationSeconds
                    } else {
                        return imageLimitations[dskey].minVideoStoryDurationSeconds
                    }
                }
            }

            const getMaxVideoDuration: () => PublishingMediaLimitations['minVideoDuration'] = () => {
                if (isMultiMediaPost) {
                    return imageLimitations[dskey].maxVideoDurationSecondsCarousel
                } else {
                    if (media.category === 'VIDEO' && !isInstagramStory()) {
                        return imageLimitations[dskey].maxVideoDurationSeconds
                    } else {
                        return imageLimitations[dskey].maxVideoStoryDurationSeconds
                    }
                }
            }

            const getMaxPixelCount: () => PublishingMediaLimitations['maxPixelCount'] = () => {
                if (media.category === 'VIDEO') {
                    return undefined
                } else {
                    return imageLimitations[dskey].maxPixelCount
                }
            }
            const limitationsForNetwork: PublishingMediaLimitations = {
                maxFileSize: getMaxFileSize(),
                minWidth: getMinWidth(),
                minHeight: getMinHeight(),
                maxWidth: getMaxWidth(),
                maxHeight: getMaxHeight(),
                minAspect: getMinAspect(),
                maxAspect: getMaxAspect(),
                minAspectStr: getMinAspectStr(),
                maxAspectStr: getMaxAspectStr(),
                multimediaVideoDenied: videoDenied(),
                minVideoDuration: getMinVideoDuration(),
                maxVideoDuration: getMaxVideoDuration(),
                maxPixelCount: getMaxPixelCount(),
            }
            allErrors.push(...generateErrorsPerNetwork(media, intl, dskey, limitationsForNetwork, isInstagramStory()))
        })
    }

    return allErrors.length > 0 ? allErrors : []
}
