import * as React from 'react'
import { FC, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Tooltip } from '@mui/material'
import { finishTour, resetMultipleTours } from '../../settings/user-management/UserActions'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { orderedAvailableTours } from './OrderedAvailableTours'
import { getCurrentUser } from '../../core/slices/CoreSlice'
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material'
import _ from 'lodash'
import { endTour } from './GuideSlice'
import { User } from '../../settings/user-management/User'

type ResetGuidesDialogProps = {}

export const ResetGuidesDialog: FC<ResetGuidesDialogProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const [open, setOpen] = useState(false)
    const currentUser = useSelector(getCurrentUser)
    const resetToursAvailable = _.compact(_.flatMap(currentUser.settings.viewed_tutorials)).length > 0
    const unseenToursFromSettings = _.compact(
        _.map(orderedAvailableTours, (tourKey) => {
            return !Object.keys(currentUser.settings.viewed_tutorials).includes(tourKey) || !currentUser.settings.viewed_tutorials[tourKey]
                ? tourKey
                : undefined
        })
    ) as (keyof User['settings']['viewed_tutorials'])[]

    const [unseenTours, setUnseenTours] = useState<(keyof User['settings']['viewed_tutorials'])[]>(unseenToursFromSettings)

    const handleSave = () => {
        setOpen(false)
        dispatch(resetMultipleTours(unseenTours))
    }

    const handleAbort = () => {
        setOpen(false)
        setUnseenTours(unseenToursFromSettings)
    }

    const renderResettableTours = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Button
                        sx={{ textTransform: 'unset' }}
                        color={'secondary'}
                        onClick={() => {
                            setUnseenTours(orderedAvailableTours)
                        }}
                        disabled={!resetToursAvailable}
                        startIcon={<CheckBoxOutlineBlank />}
                    >
                        <FormattedMessage id={'guide.reset-all-tours'} />
                    </Button>
                    <Divider />
                </Grid>
                {orderedAvailableTours.map((tour) => {
                    const tourSeen = !unseenTours.includes(tour)
                    return (
                        <Grid item xs={12} key={tour} width={'fit-content'}>
                            <Tooltip
                                title={<FormattedMessage id={`guide.${tourSeen ? 'mark-' : ''}tour-not-seen`} />}
                                placement={'bottom-start'}
                                disableInteractive
                            >
                                <span>
                                    <Button
                                        color={'inherit'}
                                        startIcon={tourSeen ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}
                                        sx={{ textTransform: 'unset' }}
                                        onClick={() => {
                                            if (tourSeen) {
                                                setUnseenTours([...unseenTours, tour])
                                            } else {
                                                dispatch(finishTour(tour)).then(() => {
                                                    dispatch(endTour(tour))
                                                    setUnseenTours(
                                                        unseenTours.filter((tourKey) => {
                                                            return tourKey !== tour
                                                        })
                                                    )
                                                })
                                            }
                                        }}
                                    >
                                        <FormattedMessage id={`guide.tournames.${tour}`} />
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <Button
                onClick={() => {
                    setOpen(true)
                }}
                variant={'contained'}
                color={'secondary'}
            >
                <FormattedMessage id={'guide.reset-tours'} />
            </Button>
            <Dialog open={open}>
                <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormattedMessage id={'guide.tutorials'} />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} width={'fit-content'} maxWidth={'300px'}>
                        {renderResettableTours()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'inherit'}
                        onClick={() => {
                            handleAbort()
                        }}
                    >
                        <FormattedMessage id={'general.cancel'} />
                    </Button>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {
                            handleSave()
                        }}
                    >
                        <FormattedMessage id={'general.save'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
