import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { HmstrState } from '../core/Store'
import { doGet } from '../core/api/ApiClient'
import moment from 'moment'
import { FacebookConversionData } from './FacebookConversionData'
import { ConversionTrackingAd } from './ConversionTrackingAd'
import { FacebookDailyInsights } from './FacebookDailyInsights'

const FACEBOOK_SLASH = (action: string) => `facebook/${action}`

export const fetchFacebookConversionData = createAsyncThunk<
    FacebookConversionData[],
    ProjectDatasource,
    {
        state: HmstrState
    }
>(FACEBOOK_SLASH('fetchFacebookConversionData'), async (datasource, thunkAPI) => {
    const state = thunkAPI.getState().conversion_tracking

    return await doGet(thunkAPI, datasource._links.fb_conversions, undefined, {
        params: {
            since: moment(state.selectedTimePeriodStartDate).format('YYYY-MM-DD'),
            until: moment(state.selectedTimePeriodEndDate).format('YYYY-MM-DD'),
            event: state.conversionEvent,
        },
    })
})

export const fetchFacebookAdPerformanceData = createAsyncThunk<
    ConversionTrackingAd[],
    ProjectDatasource,
    {
        state: HmstrState
    }
>(FACEBOOK_SLASH('fetchFacebookAdPerformanceData'), async (channel, thunkAPI) => {
    const state = thunkAPI.getState().conversion_tracking

    return await doGet(thunkAPI, channel._links.ad_performance, undefined, {
        params: {
            since: moment(state.selectedTimePeriodStartDate).format('YYYY-MM-DD'),
            until: moment(state.selectedTimePeriodEndDate).format('YYYY-MM-DD'),
        },
    })
})

export const fetchFacebookDailyAdInsights = createAsyncThunk<FacebookDailyInsights[], ProjectDatasource, { state: HmstrState }>(
    FACEBOOK_SLASH('fetchAdData'),
    async (channel, thunkAPI) => {
        const state = thunkAPI.getState().conversion_tracking

        return await doGet(thunkAPI, channel._links.ads, undefined, {
            params: {
                since: moment(state.selectedTimePeriodStartDate).format('YYYY-MM-DD'),
                until: moment(state.selectedTimePeriodEndDate).format('YYYY-MM-DD'),
            },
        })
    }
)
