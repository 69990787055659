import { FC } from 'react'
import * as _ from 'lodash'
import { Box, Typography } from '@mui/material'
import { ResultTooltipBox } from '../../monitoring/Results.styles'
import { Dot } from '../dot/Dot'
import { FormattedMessage, FormattedNumber } from 'react-intl'

type ResultsChartTooltipProps = {
    active: boolean | undefined
    payload: any
    label: any
    schema: { color: string; dataKey: string; label: string | JSX.Element }[]
    unit?: any
}

export const TaggingChartTooltip: FC<ResultsChartTooltipProps> = ({ active, payload, schema, label, unit }) => {
    const getColor = (key: any): string => {
        let result = '#000000'
        schema.map((entry: { color: string; dataKey: string; label: string | JSX.Element }) => {
            if (entry.dataKey === key) {
                result = entry.color
            }
            return result
        })
        return result
    }

    if (active && payload) {
        return (
            <ResultTooltipBox>
                {_.orderBy(payload, ['value'], ['desc'])
                    .filter((p) => p.value > 0 || p.value < 0 || p.value === 0)
                    .map((p) => (
                        <Box key={p.dataKey as any} display="flex" alignItems="center">
                            <Box paddingRight={1}>
                                <Dot color={getColor(p.payload.key)} />
                            </Box>
                            <Typography>
                                <FormattedMessage id={label} />: <FormattedNumber value={p.value as any} maximumFractionDigits={2} />
                                {unit ? unit : ''}
                            </Typography>
                        </Box>
                    ))}
            </ResultTooltipBox>
        )
    }

    return null
}
