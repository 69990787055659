import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../core/Store'
import { doGet, doPut } from '../core/api/ApiClient'
import { DatasourceNotificationSettings } from './notifications/DatasourceNotificationSettings'
import { User } from './user-management/User'

const NOTIFICATIONS_SLASH = (action: string) => `notifications/${action}`

export const fetchNotificationSettingsForUser = createAsyncThunk<
    DatasourceNotificationSettings[],
    User,
    {
        state: HmstrState
    }
>(NOTIFICATIONS_SLASH('fetchNotificationSettingsForUser'), async (user, thunkAPI) => {
    return await doGet(thunkAPI, user._links.notification_settings)
})

export const updateNotificationSettingsForUser = createAsyncThunk<any, { user: User; request: DatasourceNotificationSettings[] }, { state: HmstrState }>(
    NOTIFICATIONS_SLASH('updateNotificationSettingsForUser'),
    async (args, thunkAPI) => {
        return await doPut(thunkAPI, args.user._links.notification_settings, { datasources: args.request })
    }
)
