import * as React from 'react'
import { FC } from 'react'
import { alpha, Box, Skeleton, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { PublishingMedia } from '../PublishingMedia'
import { InstagramStoryVideo } from './instagram/InstagramStoryVideo'
import { TiktokVideo } from './tiktok/TiktokVideo'
import { useVideoWithThumbnail } from './useVideoWithThumbnail'

type PostPreviewVideoProps = {
    file: PublishingMedia
    variant: 'IG_STORY' | 'TIKTOK' | 'STANDARD'
    title?: string
}

export const PostPreviewVideo: FC<PostPreviewVideoProps> = ({ file, variant, title }) => {
    const theme = useTheme()
    const { objectUrl, playerRef, getRenderedHeight, isCloseToPortrait } = useVideoWithThumbnail(file)

    if (variant === 'IG_STORY') {
        return <InstagramStoryVideo playerRef={playerRef} objectUrl={objectUrl} height={isCloseToPortrait(0.4) ? '100%' : getRenderedHeight()} />
    }

    if (variant === 'TIKTOK') {
        return <TiktokVideo playerRef={playerRef} objectUrl={objectUrl} height={getRenderedHeight()} />
    }

    return (
        <Box mr={-2} ml={-2} display="flex" flexDirection="column">
            {objectUrl ? (
                <video
                    playsInline
                    ref={playerRef}
                    autoPlay={true}
                    muted={true}
                    controls
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'contain', maxHeight: 450 }}
                    src={objectUrl}
                >
                    <FormattedMessage id="publishing.video-not-supported" />
                </video>
            ) : (
                <Skeleton variant="rectangular" width="100%" height={getRenderedHeight()} animation="wave" sx={{ transform: 'none', maxHeight: 450 }} />
            )}

            {title && (
                <Box px={2} py={1} bgcolor={alpha(theme.palette.info.main, 0.1)}>
                    <Typography variant={'h6'} fontSize={'0.9em'}>
                        {title}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
