import * as _ from 'lodash'
import React, { FC } from 'react'
import { Alert, Box, Checkbox, FormControlLabel, Grid, Link, MenuItem, Typography } from '@mui/material'
import { ConnectedSwitch } from '../../form/ConnectedSwitch'
import { Post } from '../posts/Post'
import { FormattedMessage, useIntl } from 'react-intl'
import { getIconForDatasourceType } from '../../settings/datasources/DatasourceTypeMappings'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getMedia, IdMap } from '../../core/slices/DataSlice'
import { ConnectedDropdown } from '../../form/ConnectedDropdown'
import { validationRequired } from '../../form/validate'
import { TiktokCreatorInfo } from '../TiktokCreatorInfo'
import moment from 'moment'
import { PublishingFormValues } from '../PublishingForm'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { Link as RouterLink } from 'react-router-dom'

type PostFormPlatformSectionProps = {
    datasourceType: Post['data_source_type']
    postType: Post['post_type']
    prefix: string
    disabled: boolean
    tiktokCreatorInfo: TiktokCreatorInfo
    datasources: IdMap<ProjectDatasource[]>
    noHeader?: boolean
}

const AvailablePlatformSettings = {
    FACEBOOK_PAGE: {
        TEXT: ['fb_plan_via_facebook', 'fb_dark_post'],
        IMAGE: ['fb_plan_via_facebook', 'fb_dark_post'],
        VIDEO: ['fb_plan_via_facebook', 'fb_dark_post'],
        LINK: ['fb_plan_via_facebook', 'fb_dark_post'],
        MULTI_MEDIA: ['fb_plan_via_facebook', 'fb_dark_post'],
    },
    INSTAGRAM_ACCOUNT: {
        IMAGE: ['ig_publish_as_story', 'ig_disable_comments'],
        VIDEO: ['ig_publish_as_story', 'ig_disable_comments', 'ig_dont_share_to_feed', 'ig_thumb_offset_ms'], // ig_cover_url
        MULTI_MEDIA: ['ig_disable_comments'],
    },
    TIKTOK_ACCOUNT: {
        VIDEO: ['tt_enable_comments', 'tt_enable_duet', 'tt_enable_stitch', 'tt_thumb_offset_ms', 'tt_disclose_brand_promotion'],
        MULTI_MEDIA: ['tt_enable_comments', 'tt_auto_add_music'],
    },
    LINKED_IN: {},
    LINKED_IN_PROFILE: {},
    FACEBOOK_AD_ACCOUNT: {},
    TALKWALKER_JSON: {},
    TIKTOK_ADVERTISER: {},
    TWITTER: {},
}

export const PostFormPlatformSection: FC<PostFormPlatformSectionProps> = ({
    datasourceType,
    postType,
    prefix,
    disabled,
    tiktokCreatorInfo,
    noHeader,
    datasources,
}) => {
    const intl = useIntl()
    const platformSettingsByPostType: any = AvailablePlatformSettings[datasourceType]
    const availableSettings: string[] = _.get(platformSettingsByPostType, postType) || []
    const form = useForm()
    const formState = useFormState<PublishingFormValues>()
    const media = useSelector(getMedia)
    const fileIds = _.get(form.getState().values, `${prefix}.file_ids`)
    const fbPublishTime = moment(formState.values.common_post.publish_time)
    const maxDateTime = moment().add(30, 'day')
    const video = fileIds && fileIds.length > 0 ? media[fileIds[0]] : undefined
    const isTimeForPlannedOutOfRange = fbPublishTime && maxDateTime.isBefore(fbPublishTime)
    const creatorAccounts: ProjectDatasource[] =
        (datasourceType === 'INSTAGRAM_ACCOUNT' &&
            (_.flatMap(datasources, (igDatasources) =>
                _.map(igDatasources, (igDatasource) => _.get(igDatasource.metadata, 'account_type') === 'CONTENT_CREATOR' && igDatasource)
            ).filter((d) => d) as ProjectDatasource[])) ||
        []

    const validationMax = (value: any) => {
        if (value && video && video.duration && video.duration * 1000 < value) {
            return intl.formatMessage({ id: 'publishing.platform-specific-setting.max-thumb_offset_ms' }, { maxMs: video.duration * 1000 })
        }

        return undefined
    }

    if (availableSettings.length === 0) {
        return (
            <Box pl={1}>
                <Typography color={'text.secondary'}>
                    <FormattedMessage id="publishing.platform-specific-setting.no-settings-available" />
                </Typography>
            </Box>
        )
    }

    const renderTiktokBrandCheckbox = (value: 'OWN_BRAND' | 'OTHER_BRAND') => {
        const currentValues = form.getState().values
        const checkboxMessageId = `publishing.platform-specific-setting.tt_disclose_brand_promotion.${value}`
        return (
            <Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            color={'default'}
                            checked={_.get(currentValues, `${prefix}.tt_disclose_brand_promotion`) === value}
                            onChange={(event, checked) => {
                                if (checked) form.change(`${prefix}.tt_disclose_brand_promotion`, value)
                            }}
                        />
                    }
                    label={<FormattedMessage id={checkboxMessageId} />}
                />
                <Typography ml={4} color="text.secondary">
                    <FormattedMessage
                        id={checkboxMessageId + '.description'}
                        values={{
                            musicLink: (chunks: any) => {
                                return (
                                    <Link
                                        color="inherit"
                                        sx={{ cursor: 'pointer' }}
                                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                                        target="_blank"
                                    >
                                        {chunks}
                                    </Link>
                                )
                            },
                            brandLink: (chunks: any) => {
                                return (
                                    <Link
                                        color="inherit"
                                        sx={{ cursor: 'pointer' }}
                                        href="https://www.tiktok.com/legal/page/global/bc-policy/en"
                                        target="_blank"
                                    >
                                        {chunks}
                                    </Link>
                                )
                            },
                        }}
                    />
                </Typography>
            </Box>
        )
    }

    return (
        <>
            {!noHeader && (
                <Grid
                    item
                    xs={12}
                    sx={{
                        borderTop: 1,
                        borderColor: 'divider',
                        mt: ['IMAGE', 'VIDEO', 'MULTI_MEDIA'].includes(postType) ? 0 : 4,
                    }}
                >
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="subtitle2">
                            <FormattedMessage id="publishing.platform-specific-setting" />
                        </Typography>
                        {getIconForDatasourceType(datasourceType, 'small')}
                    </Box>
                </Grid>
            )}
            {datasourceType === 'TIKTOK_ACCOUNT' && (
                <Grid item xs={12}>
                    <Box pl={2}>
                        <ConnectedDropdown
                            name={`${prefix}.tt_privacy_level`}
                            disabled={disabled}
                            required={formState.values.common_post.state !== 'DRAFT'}
                            label="publishing.platform-specific-setting.tt_privacy_level"
                            validate={validationRequired}
                        >
                            <MenuItem value="PUBLIC_TO_EVERYONE" disabled={!tiktokCreatorInfo.privacy_level_options.includes('PUBLIC_TO_EVERYONE')}>
                                <FormattedMessage id="publishing.platform-specific-setting.tt_privacy_level.PUBLIC_TO_EVERYONE" />
                            </MenuItem>
                            <MenuItem value="MUTUAL_FOLLOW_FRIENDS" disabled={!tiktokCreatorInfo.privacy_level_options.includes('MUTUAL_FOLLOW_FRIENDS')}>
                                <FormattedMessage id="publishing.platform-specific-setting.tt_privacy_level.MUTUAL_FOLLOW_FRIENDS" />
                            </MenuItem>
                            <MenuItem value="FOLLOWER_OF_CREATOR" disabled={!tiktokCreatorInfo.privacy_level_options.includes('FOLLOWER_OF_CREATOR')}>
                                <FormattedMessage id="publishing.platform-specific-setting.tt_privacy_level.FOLLOWER_OF_CREATOR" />
                            </MenuItem>
                            <MenuItem value="SELF_ONLY" disabled={!tiktokCreatorInfo.privacy_level_options.includes('SELF_ONLY')}>
                                <FormattedMessage id="publishing.platform-specific-setting.tt_privacy_level.SELF_ONLY" />
                            </MenuItem>
                        </ConnectedDropdown>
                    </Box>
                </Grid>
            )}
            {availableSettings.map((setting) => {
                const isThumbnail = setting.includes('thumb_offset_ms')
                const isContentDisclosure = setting.includes('disclose_brand_promotion')
                const switchName = `${prefix}.${isThumbnail || isContentDisclosure ? setting + '_enabled' : setting}`
                const isSettingActive = _.get(form.getState().values, switchName)

                const handleChange = (checked: boolean) => {
                    if (isThumbnail && !checked) {
                        form.change(`${prefix}.${setting}`, undefined)
                    }

                    if (isContentDisclosure && !checked) {
                        form.change(`${prefix}.${setting}`, undefined)
                    }

                    if (setting === 'ig_publish_as_story' && checked) {
                        form.change(`${prefix}.ig_disable_comments`, undefined)
                        form.change(`${prefix}.ig_dont_share_to_feed`, undefined)
                        form.change(`${prefix}.ig_thumb_offset_ms_enabled`, undefined)
                        form.change(`${prefix}.ig_thumb_offset_ms`, undefined)
                    }

                    if (setting === 'fb_plan_via_facebook' && checked) {
                        form.change(`${prefix}.fb_dark_post`, undefined)
                    }

                    if (setting === 'fb_dark_post' && checked) {
                        form.change(`${prefix}.fb_plan_via_facebook`, undefined)
                    }
                }

                const isCustomDisabled = (): boolean => {
                    const storyDisabled = ['ig_disable_comments', 'ig_dont_share_to_feed', 'ig_thumb_offset_ms']

                    if (storyDisabled.includes(setting)) {
                        return _.get(form.getState().values, `${prefix}.ig_publish_as_story`) === true
                    }

                    if (['ig_thumb_offset_ms', 'tt_thumb_offset_ms'].includes(setting)) {
                        return video === undefined
                    }

                    if (setting === 'fb_plan_via_facebook') {
                        return form.getState().values.common_post.state === 'PUBLISH_NOW'
                    }

                    if (setting === 'tt_enable_comments') {
                        return tiktokCreatorInfo.comment_disabled
                    }

                    if (setting === 'tt_enable_duet') {
                        return tiktokCreatorInfo.duet_disabled
                    }

                    if (setting === 'tt_enable_stitch') {
                        return tiktokCreatorInfo.stitch_disabled
                    }

                    return false
                }

                return (
                    <Grid key={setting} item xs={12}>
                        <Box ml={2}>
                            {setting === 'ig_publish_as_story' && creatorAccounts.length > 0 && (
                                <Alert color={'warning'} severity={'warning'}>
                                    <FormattedMessage
                                        id={'publishing.warning.ig-story-content-creator'}
                                        values={{
                                            acc: creatorAccounts.map((acc) => acc.name).join(', '),
                                            link: (
                                                <RouterLink
                                                    style={{ color: 'inherit' }}
                                                    to={'https://www.instagram.com/accounts/professional_account_settings/'}
                                                >
                                                    <FormattedMessage id={'publishing.warning.ig-story-content-creator-link'} />
                                                </RouterLink>
                                            ),
                                        }}
                                    />
                                </Alert>
                            )}
                            <ConnectedSwitch
                                onChange={handleChange}
                                name={switchName}
                                label={`publishing.platform-specific-setting.${setting}`}
                                disabled={disabled || isCustomDisabled() || (setting === 'ig_publish_as_story' && creatorAccounts.length > 0)}
                                helperText={
                                    setting === 'fb_plan_via_facebook' && !(disabled || isCustomDisabled()) && isTimeForPlannedOutOfRange && isSettingActive
                                        ? 'publishing.plan-via-facebook-hint'
                                        : undefined
                                }
                            />

                            {isThumbnail && isSettingActive && (
                                <Box mt={2} ml={3}>
                                    <ConnectedTextField
                                        disabled={disabled}
                                        label="publishing.platform-specific-setting.thumb_offset_ms"
                                        variant="outlined"
                                        validate={validationMax}
                                        name={`${prefix}.${setting}`}
                                        type="number"
                                    />
                                </Box>
                            )}

                            {isContentDisclosure && isSettingActive && (
                                <Box ml={6}>
                                    <Box>{renderTiktokBrandCheckbox('OWN_BRAND')}</Box>
                                    <Box>{renderTiktokBrandCheckbox('OTHER_BRAND')}</Box>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                )
            })}
        </>
    )
}
