import { ConversionTrackingAdWithConversions } from './ConversionTrackingAd'

export function descendingComparator(
    a: ConversionTrackingAdWithConversions,
    b: ConversionTrackingAdWithConversions,
    orderBy: keyof ConversionTrackingAdWithConversions
) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export type Order = 'asc' | 'desc'

export function getComparator(
    order: Order,
    orderBy: keyof ConversionTrackingAdWithConversions
): (a: ConversionTrackingAdWithConversions, b: ConversionTrackingAdWithConversions) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort(
    array: ConversionTrackingAdWithConversions[],
    comparator: (a: ConversionTrackingAdWithConversions, b: ConversionTrackingAdWithConversions) => number
): ConversionTrackingAdWithConversions[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [ConversionTrackingAdWithConversions, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export interface ConversionTrackingHeadCell {
    id: keyof ConversionTrackingAdWithConversions
    label: string
    numeric: boolean
}
