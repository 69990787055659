import type { InputHTMLAttributes } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { PublishingFileUpload } from './PublishingFileUpload'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { getMedia, IdMap } from '../core/slices/DataSlice'
import { PublishingFormValues } from './PublishingForm'
import * as _ from 'lodash'
import { imageLimitations } from './FormValidators'
import { useIntl } from 'react-intl'
import { checkEqualRawArrays } from '../core/helpers/check-equal-raw-arrays'
import { useSelector } from 'react-redux'
import { getErrorsForCommonFile } from './PublishingSlice'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    dataSourceTypes: IdMap<ProjectDatasource[]>
    publishingType?: string
    disabled?: boolean
    disableValidation?: boolean
}

export const PublishingFileField = ({ name, dataSourceTypes, publishingType, disabled, disableValidation }: Props) => {
    const intl = useIntl()
    const form = useForm()
    const state = form.getState().values.common_post.state.toString()
    const formState = useFormState<PublishingFormValues>()
    const commonPostState = formState.values.common_post.state
    const currentKeys = name.includes('common_post') ? 'ALL' : name.replace('postByType.', '').replace('.file_ids', '').replace('.link_preview_file_id', '')

    const getMaxMultimediaImages = () => {
        const selectedTypes =
            currentKeys === 'ALL' ? (Object.keys(dataSourceTypes) as ('FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN' | 'TIKTOK_ACCOUNT')[]) : [currentKeys]
        const maxFilesForEachNetwork = _.map(selectedTypes, (dstype) => {
            const limit = imageLimitations[dstype].maxMultimediaFiles
            return { value: limit || 999999, network: dstype }
        })

        return _.minBy(maxFilesForEachNetwork, (obj) => obj.value) || { value: 999999, network: 'all' }
    }

    const validateMaxMultimediaImages = (amount: number) => {
        const maxImages = getMaxMultimediaImages()
        if (amount > maxImages.value) {
            return intl.formatMessage(
                { id: 'validations.multimedia-too-many-files' },
                { amount: maxImages.value, network: intl.formatMessage({ id: maxImages.network }) }
            )
        } else {
            return undefined
        }
    }
    const imageErrors = useSelector(getErrorsForCommonFile)
    const media = useSelector(getMedia)
    const fileIds: string[] =
        typeof _.get(form.getState().values, name) === 'string' ? [_.get(form.getState().values, name) || []] : _.get(form.getState().values, name) || []
    const currentFiles = fileIds.map((fileId) => media[fileId]).filter((m) => !!m)

    const commonErrors = _.flattenDeep(
        currentFiles
            .map((file) => {
                const errorsForFile = imageErrors[file.id]
                const exifErrorsForFile = file.exif_scan?.errors

                const completeErrorArray = [errorsForFile?.map((val) => val.message), exifErrorsForFile].filter((d) => d)

                return _.flattenDeep(completeErrorArray)
            })
            .filter((arr) => arr.length > 0)
    )

    const commonFilesContainErrors = commonErrors.length !== 0

    return (
        <Field<string[]>
            name={name}
            disabled={disabled}
            isEqual={checkEqualRawArrays}
            key={name + state.toLowerCase() + publishingType + commonPostState + commonFilesContainErrors}
            validate={(value) => {
                if (disableValidation) {
                    return undefined
                }
                if (commonFilesContainErrors) {
                    return 'validations.files-contain-errors'
                } else if (publishingType && publishingType === 'MULTI_MEDIA') {
                    if (!value || value.length <= 1) {
                        return 'publishing.errors.multiple-files-required'
                    }

                    const maxImagesError = validateMaxMultimediaImages(value?.length || 0)

                    if (maxImagesError) {
                        return maxImagesError
                    }
                } else if (publishingType && ['IMAGE', 'VIDEO'].includes(publishingType)) {
                    if (!value || value.length !== 1) {
                        return 'publishing.errors.single-file-required'
                    }

                    if (publishingType === 'IMAGE' && !!currentFiles.find((f) => f.category === 'VIDEO')) {
                        return 'publishing.errors.video-not-allowed'
                    }

                    if (publishingType === 'VIDEO' && !!currentFiles.find((f) => f.category === 'IMAGE')) {
                        return 'publishing.errors.image-not-allowed'
                    }
                }

                return !value || value.length === 0 ? 'validations.required' : undefined
            }}
        >
            {() => <PublishingFileUpload disabled={disabled} name={name} dataSourceTypes={dataSourceTypes} disableValidation={disableValidation} />}
        </Field>
    )
}
