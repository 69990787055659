import moment from 'moment'
import * as _ from 'lodash'
import { FC, useState, MouseEvent } from 'react'
import { IdMap } from '../core/slices/DataSlice'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Tooltip, Typography } from '@mui/material'
import { CheckCircle, CheckCircleOutlined, ExpandMore } from '@mui/icons-material'
import { FormattedCurrency } from '../common/formatted-currency/FormattedCurrency'
import { useSelector } from 'react-redux'
import { getConversionTrackingAttributionWindows } from './ConversionTrackingSlice'
import { FacebookConversionData } from './FacebookConversionData'
import { FormattedMessage } from 'react-intl'
import { Conversion } from './Conversion'
import { ActualConversionPreview } from './ActualConversionPreview'
import { ConversionTrackingTableList } from './ConversionTrackingTableList'

type ConversionTrackingTableProps = {
    data: IdMap<IdMap<FacebookConversionData[]>>
}

export const ConversionTrackingTable: FC<ConversionTrackingTableProps> = ({ data }) => {
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewConversions, setPreviewConversions] = useState<Conversion[]>([])
    const attributionWindows = useSelector(getConversionTrackingAttributionWindows)

    const getActalConversionForAd = (ad: FacebookConversionData): Conversion[] => {
        return _.flatMap(attributionWindows, (aw) => ad[`actual_conversions_${aw}`] || [])
    }

    const getConversionsForAd = (ad: FacebookConversionData) => {
        return _.sum(_.map(attributionWindows, (aw) => ad[`conversions_${aw}`] || 0))
    }

    const getConversionValuesForAd = (ad: FacebookConversionData) => {
        return _.sum(_.map(attributionWindows, (aw) => ad[`conversion_values_${aw}`] || 0))
    }

    const dailyOverview = (dailyData: IdMap<FacebookConversionData[]>) => {
        const conversionCount = _.chain(dailyData)
            .values()
            .sumBy((hourlyAds) => _.sumBy(hourlyAds, (ad) => getConversionsForAd(ad)))
            .value()

        const conversionValue = _.chain(dailyData)
            .values()
            .sumBy((ads) => _.sumBy(ads, (ad) => getConversionValuesForAd(ad)))
            .value()

        const actualConversions = _.chain(dailyData)
            .values()
            .flatMap((hourlyAds) => _.flatMap(hourlyAds, (ad) => getActalConversionForAd(ad)))
            .value()

        const actualConversionCount = actualConversions.length

        return { conversionCount, conversionValue, actualConversionCount, actualConversions }
    }

    const monthlyOverview = _.chain(data)
        .mapValues((dailyData) => dailyOverview(dailyData))
        .values()
        .value()

    const monthlyConversions = _.sumBy(monthlyOverview, (day) => day.conversionCount)
    const monthlyConversionValues = _.sumBy(monthlyOverview, (day) => day.conversionValue)

    const handleOpenActualConversionPreview = (event: MouseEvent<any>, conversions: Conversion[]) => {
        event.preventDefault()
        event.stopPropagation()
        setPreviewConversions(conversions)
        setPreviewOpen(true)
    }

    return (
        <Box pb={2}>
            <Accordion expanded={false}>
                <AccordionSummary sx={{ backgroundColor: 'divider', cursor: 'default !important' }}>
                    <Typography
                        sx={{
                            mr: 2,
                            flexShrink: 0,
                            minWidth: 90,
                        }}
                    >
                        <strong>
                            <FormattedMessage id="conversion-tracking.table.date" />
                        </strong>
                    </Typography>
                    <Typography minWidth={100} textAlign="right">
                        <strong>
                            <FormattedMessage id="conversion-tracking.table.conversions" />
                        </strong>
                    </Typography>
                    <Typography minWidth={300} textAlign="right">
                        <strong>
                            <FormattedMessage id="conversion-tracking.table.average-order-value" />
                        </strong>
                    </Typography>
                    <Typography sx={{ flexGrow: 1, textAlign: 'right', pr: 7 }}>
                        <strong>
                            <FormattedMessage id="conversion-tracking.table.conversion-values" />
                        </strong>
                    </Typography>
                </AccordionSummary>
            </Accordion>

            {Object.keys(data)
                .sort()
                .map((day) => {
                    const dailyData = dailyOverview(data[day])
                    const dailyExactMatch = dailyData.conversionCount === dailyData.actualConversionCount
                    const MatchIcon = dailyExactMatch ? CheckCircle : CheckCircleOutlined

                    return (
                        <Accordion key={day} TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography
                                    sx={{
                                        mr: 2,
                                        color: 'text.secondary',
                                        flexShrink: 0,
                                        minWidth: 90,
                                    }}
                                >
                                    {moment(day).format('L')}
                                </Typography>
                                <Typography minWidth={100} textAlign="right">
                                    {dailyData.conversionCount}
                                </Typography>
                                <Typography minWidth={300} textAlign="right" color="text.secondary">
                                    <FormattedCurrency value={dailyData.conversionValue / dailyData.conversionCount} />
                                </Typography>
                                <Box sx={{ flexGrow: 1, textAlign: 'right', pr: 4 }}>
                                    <Grid container justifyContent="flex-end" spacing={2}>
                                        <Grid item>
                                            {dailyData.actualConversionCount > 0 && (
                                                <Tooltip
                                                    title={
                                                        <FormattedMessage
                                                            id={dailyExactMatch ? 'conversion-tracking.exact-match' : 'conversion-tracking.partial-match'}
                                                        />
                                                    }
                                                >
                                                    <MatchIcon
                                                        onClick={(event) => handleOpenActualConversionPreview(event, dailyData.actualConversions)}
                                                        color="success"
                                                    />
                                                </Tooltip>
                                            )}
                                        </Grid>

                                        <Grid item>
                                            <Typography sx={{ minWidth: 120 }}>
                                                <strong>
                                                    <FormattedCurrency value={dailyData.conversionValue} />
                                                </strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ borderTop: 1, borderColor: 'divider' }}>
                                {Object.keys(data[day]).map((hour) => (
                                    <ConversionTrackingTableList key={hour} data={data} hour={hour} day={day} onPreview={handleOpenActualConversionPreview} />
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

            <Accordion expanded={false}>
                <AccordionSummary sx={{ backgroundColor: 'divider', cursor: 'default !important' }}>
                    <Typography
                        sx={{
                            mr: 2,
                            color: 'text.secondary',
                            flexShrink: 0,
                            minWidth: 90,
                        }}
                    >
                        <strong>Total</strong>
                    </Typography>
                    <Typography textAlign="right" minWidth={100}>
                        {monthlyConversions}
                    </Typography>
                    <Typography minWidth={300} textAlign="right" color="text.secondary">
                        <FormattedCurrency value={monthlyConversions ? monthlyConversionValues / monthlyConversions : 0} />
                    </Typography>
                    <Typography sx={{ flexGrow: 1, textAlign: 'right', pr: 7 }}>
                        <strong>
                            <FormattedCurrency value={monthlyConversionValues} />
                        </strong>
                    </Typography>
                </AccordionSummary>
            </Accordion>

            <ActualConversionPreview open={previewOpen} onClose={() => setPreviewOpen(false)} conversions={previewConversions} />
        </Box>
    )
}
