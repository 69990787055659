import { User } from '../../settings/user-management/User'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getIsMobile } from '../../core/slices/CoreSlice'
import { useEffect } from 'react'
import { HmstrDispatch } from '../../core/Store'
import { endTour, getTourRunning, startTour } from './GuideSlice'

export const useStartTour = (tourName: keyof User['settings']['viewed_tutorials']) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const currentUser = useSelector(getCurrentUser)
    const currentTour = useSelector(getTourRunning)
    const isTourViewed = currentUser.settings.viewed_tutorials[tourName]
    const isMobile = useSelector(getIsMobile)

    useEffect(() => {
        if (!isTourViewed && !isMobile) {
            dispatch(startTour(tourName))
        }

        return () => {
            if (currentTour !== tourName) {
                dispatch(endTour(tourName))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTourViewed, currentTour, tourName])
}
