import {
    createIntl,
    createIntlCache,
    IntlShape,
    InvalidConfigError,
    MessageFormatError,
    MissingDataError,
    MissingTranslationError,
    UnsupportedFormatterError,
} from 'react-intl'
import { FormatError } from 'intl-messageformat'
import moment from 'moment'
import 'moment/locale/de'

const messagesDe = require('../../resources/translations/de.json')
const messagesEn = require('../../resources/translations/en.json')

const cache = createIntlCache()

export const messages: any = {
    de: messagesDe,
    en: messagesEn,
}

export interface LocalizationContext {
    context: IntlShape
    language: string
    messages: any
}

export const onReactIntlError = (
    err: MissingTranslationError | MessageFormatError | MissingDataError | InvalidConfigError | UnsupportedFormatterError | FormatError
) => {
    if (err.code.toString() !== 'MISSING_TRANSLATION') {
        console.error(err)
    }
}

export const countries = require('i18n-iso-countries')

countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export const initLocalizationContext = (language: string = 'de'): LocalizationContext => {
    const intlShape = createIntl(
        {
            locale: language,
            messages: messages[language],
            onError: onReactIntlError,
        },
        cache
    )

    const getMonthShort = () => {
        switch (language) {
            case 'de':
                return ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
            case 'en':
            default:
                return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }
    }

    moment.updateLocale(language, {
        monthsShort: getMonthShort(),
    })

    return {
        context: intlShape,
        language: language,
        messages: messages,
    }
}
