import { FC } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import hmstrLogoWhite from '../../resources/images/hmstr-logo.png'
import hmstrLogoInverted from '../../resources/images/hmstr-logo-inverted.png'
import hmstrLogo from '../../resources/images/logo96.png'
import { Cancel, CheckCircle, SyncAlt } from '@mui/icons-material'
import { FacebookIcon } from '../../common/icons/FacebookIcon'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'
import { FederatedIdentity } from './FederatedIdentity'
import { LinkedInIcon } from '../../common/icons/LinkedInIcon'
import { TiktokIcon } from '../../common/icons/TiktokIcon'
import { HmstrColors } from '../../core/theme/hmstr-theme'
import { XIcon } from '../../common/icons/XIcon'

type AccountLinkInfoProps = {
    state: 'error' | 'success'
    type: FederatedIdentity['type']
    primaryText: string
    secondaryText: string
    buttonText: string
    onButtonClick: () => any
}

export const AccountLinkInfo: FC<AccountLinkInfoProps> = ({ state, type, primaryText, secondaryText, buttonText, onButtonClick }) => {
    const darkMode = useSelector(isDarkMode)

    const getIcon = () => {
        switch (type) {
            case 'META':
                return <FacebookIcon sx={{ fontSize: 72 }} />
            case 'LINKED_IN':
                return <LinkedInIcon sx={{ fontSize: 72 }} />
            case 'TIKTOK':
            case 'TIKTOK_BUSINESS':
                return <TiktokIcon sx={{ fontSize: 72 }} />
            case 'TWITTER':
                return <XIcon htmlColor={HmstrColors.Platforms.X} sx={{ fontSize: 72 }} />
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100vw"
            height="100dvh"
            alignItems="center"
            justifyContent="flex-start"
            borderTop={8}
            paddingTop={8}
            component={Paper}
        >
            <Box display="flex" flexDirection="column" width={460} alignItems="center" p={2} m={2} height={550}>
                <Box marginTop={-8}>
                    <img src={darkMode ? hmstrLogoWhite : hmstrLogoInverted} alt="hmstr-logo-inverted" height="32px" />
                </Box>
                <Box mt={6} mb={8} paddingLeft={8} paddingRight={8} display="flex" justifyContent="space-between" width="100%" alignItems="center">
                    <img src={hmstrLogo} alt="hmstr-logo-inverted" height="64px" />

                    <SyncAlt />

                    {getIcon()}
                </Box>
                <Box textAlign="center" width="100%" p={2}>
                    <Box display="flex" alignItems="center">
                        {state === 'error' ? <Cancel sx={{ color: 'error.light', mr: 1 }} /> : <CheckCircle sx={{ color: 'success.light', mr: 1 }} />}
                        <Typography variant="h6" component="p">
                            {primaryText}
                        </Typography>
                    </Box>
                    <Typography variant="body2" component="p">
                        {secondaryText}
                    </Typography>
                </Box>
                <Box width="100%">
                    <Button fullWidth sx={{ mt: 4 }} variant="contained" color="secondary" onClick={onButtonClick}>
                        {buttonText}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
