import * as React from 'react'
import { FC, useRef } from 'react'
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material'
import { primaryMobileNavHeight } from '../navigation/PrimaryNavigationMobile'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { fetchOpenTicketCountForUserByCategory, fetchSimpleTicketsForProject } from './EngagementActions'
import { getEntriesPerPage, getLoadingSimpleTickets, getSelectedDatasourceIds } from './EngagementSlice'
import { getSelectedProject } from '../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'

type TicketCardMobileLoaderProps = {
    shouldShowLastTickets: boolean
    openTicketCount: number
    currentlyDisplayedTickets: number
    tab: 'mine' | 'others' | 'archived' | 'new'
}

export const TicketCardMobileLoader: FC<TicketCardMobileLoaderProps> = ({ shouldShowLastTickets, openTicketCount, currentlyDisplayedTickets, tab }) => {
    const loaderRef = useRef<any>(null)
    const dispatch = useDispatch<HmstrDispatch>()
    const elementIsVisibleInViewport = (el: { getBoundingClientRect: () => { top: any; left: any; bottom: any; right: any } }, partiallyVisible = false) => {
        const { top, left, bottom, right } = el.getBoundingClientRect()
        const { innerHeight, innerWidth } = window
        return partiallyVisible
            ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) && ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
            : top >= 0 && left >= 0 && bottom <= innerHeight - primaryMobileNavHeight && right <= innerWidth
    }
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)
    const selectedProject = useSelector(getSelectedProject)
    const entriesPerPage = useSelector(getEntriesPerPage)
    const roundedUpShownTickets = Math.ceil(currentlyDisplayedTickets / entriesPerPage) * entriesPerPage // ticket count rounded up to next multiple of pagination
    const currentPage = Math.floor(roundedUpShownTickets / entriesPerPage) - 1 // get which page we are on based on how many tickets are on screen
    const isLoading = useSelector(getLoadingSimpleTickets)

    const handleScroll = () => {
        if (elementIsVisibleInViewport(loaderRef.current) && selectedProject && !isLoading) {
            dispatch(
                fetchSimpleTicketsForProject({
                    tab: tab,
                    page: shouldShowLastTickets ? 0 : currentPage + 1,
                    datasource_ids: selectedDatasourceIds,
                    project: selectedProject,
                    entriesPerPage: shouldShowLastTickets ? openTicketCount + 10 : entriesPerPage,
                    lazyScroll: !shouldShowLastTickets,
                })
            )
            if (shouldShowLastTickets) {
                dispatch(
                    fetchOpenTicketCountForUserByCategory({
                        project: selectedProject,
                        datasource_ids: selectedDatasourceIds,
                    })
                )
            }
        }
    }

    useEffectWithIdComparison(() => {
        window.addEventListener('scroll', handleScroll, true)
        if (elementIsVisibleInViewport(loaderRef.current) && selectedProject && !isLoading) {
            if (shouldShowLastTickets) {
                dispatch(
                    fetchOpenTicketCountForUserByCategory({
                        project: selectedProject,
                        datasource_ids: selectedDatasourceIds,
                    })
                )
            }
            dispatch(
                fetchSimpleTicketsForProject({
                    tab: tab,
                    page: shouldShowLastTickets ? 0 : currentPage + 1,
                    datasource_ids: selectedDatasourceIds,
                    project: selectedProject,
                    entriesPerPage: shouldShowLastTickets ? openTicketCount + 10 : entriesPerPage,
                    lazyScroll: !shouldShowLastTickets,
                })
            )
        }
        return () => {
            window.removeEventListener('scroll', handleScroll, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, selectedProject])

    return (
        <Card sx={{ flexShrink: 0 }} ref={loaderRef}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Box fontWeight={600} display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Skeleton width={100} />
                    <Skeleton
                        variant={'circular'}
                        sx={{
                            pl: 1,
                            pr: 1,
                            height: 24,
                            width: 24,
                            borderRadius: 12,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    />
                </Box>
                <Skeleton />
                <Box display="flex" alignItems="center" justifyContent="center" width={'100%'} gap={0.5}>
                    <Box display={'flex'} alignItems={'center'} gap={0.5}>
                        <Skeleton variant={'circular'} height={20} width={20} />
                        <Skeleton width={100} />
                    </Box>
                    <Box flexGrow={1} />
                    <Skeleton variant={'circular'} height={20} width={20} />
                    <Box display={'flex'} alignItems={'center'} gap={0.5}>
                        <Typography>
                            <Skeleton width={30} />
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
