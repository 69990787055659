import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { FacebookMonthlyReport } from './FacebookMonthlyReport'
import { doGet, doPost } from '../../core/api/ApiClient'
import { MonthlyReportRequest } from './MonthlyReportRequest'
import { Dashboard } from '../../dashboards/Dashboard'

const MONTHLY_REPORT_SLASH = (action: string) => `monthly-report/${action}`

export const fetchFacebookMonthlyReport = createAsyncThunk<FacebookMonthlyReport, { dashboard: Dashboard; month: string }, { state: HmstrState }>(
    MONTHLY_REPORT_SLASH('fetchMonthlyReport'),
    async ({ dashboard, month }, thunkAPI) => {
        return await doGet(thunkAPI, dashboard._links.monthly_report, undefined, {
            params: { month },
        })
    }
)

export const createOrUpdateFacebookMonthlyReport = createAsyncThunk<FacebookMonthlyReport, { dashboard: Dashboard; monthlyReport: FacebookMonthlyReport }>(
    MONTHLY_REPORT_SLASH('createOrUpdateMonthlyReport'),
    async ({ dashboard, monthlyReport }, thunkAPI) => {
        return await doPost(thunkAPI, dashboard._links.monthly_report, monthlyReport)
    }
)

export const sendMonthlyReport = createAsyncThunk<boolean, MonthlyReportRequest, { state: HmstrState }>(
    MONTHLY_REPORT_SLASH('sendMonthlyReport'),
    async (monthlyreportRequest, thunkAPI) => {
        const state = thunkAPI.getState()
        return await doPost<boolean>(thunkAPI, state.api.entryPoint._links.notifications, monthlyreportRequest)
    }
)
