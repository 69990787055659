import { FC, PropsWithChildren } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Close } from '@mui/icons-material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

type ConfirmDialogProps = {
    open: boolean
    title?: string | ReactJSXElement
    onDismiss?: () => any
    onClose: () => any
    onConfirm: () => any
    confirmText: string
    confirmIcon?: JSX.Element
    confirmButtonDisabled?: boolean
    abortText?: string
}

export const ConfirmDialog: FC<PropsWithChildren<ConfirmDialogProps>> = (props) => {
    const { open, children, onConfirm, onClose, onDismiss, title, confirmIcon, confirmText, confirmButtonDisabled, abortText = 'general.close' } = props

    return (
        <Dialog open={open} keepMounted={false}>
            {title && (
                <DialogTitle display="flex" alignItems="center">
                    {typeof title === 'string' ? <FormattedMessage id={title} /> : title}

                    {onDismiss && (
                        <Box flexGrow={1} textAlign="right">
                            <IconButton onClick={onDismiss}>
                                <Close />
                            </IconButton>
                        </Box>
                    )}
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onClose} color={'inherit'}>
                    <FormattedMessage id={abortText} />
                </Button>
                <Button variant="contained" color="secondary" startIcon={confirmIcon} onClick={onConfirm} disabled={confirmButtonDisabled}>
                    <FormattedMessage id={confirmText} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
