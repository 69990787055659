import { ProjectDatasource } from './ProjectDatasource'

export interface DatasourceError {
    message: string
    fix_link?: string
}

export const getDatasourceErrors = (datasource: ProjectDatasource): DatasourceError[] => {
    const errors = [] as DatasourceError[]

    if (datasource.access_token_invalid) {
        errors.push({
            message: 'settings.datasource-token-invalid',
            fix_link: '/settings/linked-accounts',
        })
    }

    if (!datasource.federated_identity_id && datasource.type !== 'TALKWALKER_JSON') {
        errors.push({
            message: 'settings.datasource-federated-identity-missing',
        })
    }

    return errors
}
