import { FC, useEffect } from 'react'
import { AccountLinkInfo } from './AccountLinkInfo'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { FederatedIdentity } from './FederatedIdentity'
import { linkAccount } from './AccountLinkService'
import { useSelector } from 'react-redux'
import { getApiEntryPoint } from '../../core/api/ApiSlice'
import { ProjectDatasourceType } from '../datasources/ProjectDatasource'

type AccountLinkErrorProps = {}

export const AccountLinkError: FC<AccountLinkErrorProps> = () => {
    const intl = useIntl()
    const location = useLocation()
    const apiEntryPoint = useSelector(getApiEntryPoint)
    const params = new URLSearchParams(location.search)
    const federatedIdentityType = (params.get('federated_identity_type') as FederatedIdentity['type']) || 'META'
    const datasourceType = params.get('data_source_type') as ProjectDatasourceType | undefined

    const handleTryAgainClick = () => {
        linkAccount(apiEntryPoint, federatedIdentityType, datasourceType).then(() => console.log('Try to login again ...'))
    }

    useEffect(() => {
        const onUnload = () => {
            const bc = new BroadcastChannel('hmstr-account-link')
            bc.postMessage(false)
        }
        window.addEventListener('beforeunload', onUnload)
    }, [])

    return (
        <AccountLinkInfo
            state="error"
            type={federatedIdentityType}
            primaryText={intl.formatMessage({ id: 'account-link.primary-error-text' })}
            secondaryText={intl.formatMessage({ id: 'account-link.secondary-error-text' })}
            buttonText={intl.formatMessage({ id: 'account-link.error-button-text' })}
            onButtonClick={handleTryAgainClick}
        />
    )
}
