import { PublishingMedia } from '../PublishingMedia'
import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { stringToColor } from '../../core/helpers/string-to-color'
import { useSelector } from 'react-redux'
import { getThumbnailsForCommonFile } from '../PublishingSlice'

type PostVideoThumbnailProps = {
    file: PublishingMedia
    minBrightness?: number
}
export const PostVideoThumbnail: FC<PostVideoThumbnailProps> = ({ file, minBrightness = 255 }) => {
    const thumbnail = useSelector(getThumbnailsForCommonFile)[file.id]

    const getContrastTextColor = (hex: string) => {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        let red = 0
        let green = 0
        let blue = 0
        if (result) {
            red = parseInt(result[1], 16)
            green = parseInt(result[2], 16)
            blue = parseInt(result[3], 16)
        }
        if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
            return '#000000'
        } else {
            return '#ffffff'
        }
    }

    const capHex = (value: number) => {
        return Math.min(Math.max(0, value), 255)
    }
    const lightenDarkenColor = (col: string) => {
        let redvalue = parseInt(col.slice(0, 2), 16)
        let greenvalue = parseInt(col.slice(2, 4), 16)
        let bluevalue = parseInt(col.slice(4, 6), 16)
        let r = capHex(redvalue < minBrightness ? redvalue + 50 : redvalue)
        let g = capHex(greenvalue < minBrightness ? greenvalue + 50 : greenvalue)
        let b = capHex(bluevalue < minBrightness ? bluevalue + 50 : bluevalue)
        return ('00' + r.toString(16)).slice(-2) + ('00' + g.toString(16)).slice(-2) + ('00' + b.toString(16)).slice(-2)
    }

    const finalThumbnailBackgroundColor = `#${lightenDarkenColor(stringToColor(file.name).replace('#', ''))}`
    const textColor = getContrastTextColor(finalThumbnailBackgroundColor)

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                wordBreak: 'break-word',
                background: finalThumbnailBackgroundColor,
                backgroundImage: `url(${thumbnail})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                padding: 2,
                color: textColor,
            }}
        >
            {!thumbnail && <Typography>{file.name}</Typography>}
        </Box>
    )
}
