import * as React from 'react'
import { FC } from 'react'
import { useDrawerState } from '../core/hooks/useDrawerState'
import { SecondaryNavigationHeader } from './SecondaryNavigationHeader'
import { Divider } from '@mui/material'
import { StyledDrawer } from './Drawer.styles'
import { Outlet } from 'react-router-dom'

type SecondaryNavigationDrawerProps = {}

export const SecondaryNavigationDrawer: FC<SecondaryNavigationDrawerProps> = () => {
    const drawerState = useDrawerState()

    return (
        <StyledDrawer drawerState={drawerState} variant="permanent">
            <SecondaryNavigationHeader />

            <Divider />
            <Outlet />
        </StyledDrawer>
    )
}
