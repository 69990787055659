import * as React from 'react'
import { FC } from 'react'
import { Box, darken, lighten, Paper, Typography, useTheme } from '@mui/material'
import { TicketActivity } from '../TicketActivity'
import reactStringReplace from 'react-string-replace'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { highlightNode } from '../EngagementSlice'
import { getCurrentUser, getSimpleUsersForSelectedProject, isDarkMode } from '../../core/slices/CoreSlice'

type TicketNoteProps = {
    comment: TicketActivity
}

export const TicketNote: FC<TicketNoteProps> = ({ comment }) => {
    const theme = useTheme()
    const dispatch = useDispatch<HmstrDispatch>()
    const users = useSelector(getSimpleUsersForSelectedProject)
    const currentUser = useSelector(getCurrentUser)
    const darkmode = useSelector(isDarkMode)
    const highlightColor = darkmode ? darken(theme.palette.info.dark, 0.2) : lighten(theme.palette.info.light, 0.8)
    const isHighlighted = comment.text?.includes(`(${currentUser.id})`)

    let timeout: any

    const handlePostRefClick = (node_id: string) => {
        dispatch(highlightNode(node_id))
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => dispatch(highlightNode(undefined)), 3000)
    }

    const generateContent = (text: string) => {
        return reactStringReplace(text, /#{#(\d+(?:\.\d+)?)}/, (match, idx) => (
            <Typography
                key={`${match}${idx}`}
                component={'span'}
                sx={{
                    cursor: 'pointer',
                    display: 'inline',
                    backgroundColor: highlightColor,
                    px: 0.5,
                    py: 0.1,
                    borderRadius: 1,
                }}
                onClick={() => handlePostRefClick(match)}
            >
                #{match}
            </Typography>
        ))
    }

    const generateFullContent = (text: string) => {
        const replacedHashtags = generateContent(text)
        return reactStringReplace(
            reactStringReplace(replacedHashtags, /@(\[.+?]\(.+?\))/, (match, idx) => {
                const uid = match.split('(')[1].replace(')', '')
                const name = match.split('[')[1].split(']')[0]
                const user = users[uid] || name
                return (
                    <Typography
                        component={'span'}
                        key={`${match}${idx}`}
                        sx={{
                            display: 'inline',
                            backgroundColor: highlightColor,
                            px: 0.5,
                            py: 0.1,
                            borderRadius: 1,
                        }}
                    >
                        {user ? `${user.first_name} ${user.last_name}` : name}
                    </Typography>
                )
            }),
            '​', // replace all zero width spaces with line breaks
            () => {
                return <br />
            }
        )
    }

    return (
        <Paper
            sx={{
                width: '100%',
                my: 1,
                outline: isHighlighted ? `1px solid ${theme.palette.secondary.light}` : undefined,
            }}
        >
            <Box p={1} display={'flex'} flexDirection={'column'} gap={1}>
                <Box>
                    <Typography
                        lineHeight={'180%'}
                        sx={{
                            hyphens: 'auto',
                        }}
                    >
                        {generateFullContent(comment.text || '')}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    )
}
