import { ChangeEvent, FC } from 'react'
import { Box, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Euro, Facebook, Instagram, Language, LinkedIn, Square } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedAccount, isDarkMode } from '../../core/slices/CoreSlice'
import { changeProjectDatasourceFilterSearchTerm, changeProjectDatasourceFilterType, getProjectDatasourceFilters } from '../SettingsSlice'
import { HmstrDispatch } from '../../core/Store'
import { TiktokIcon } from '../../common/icons/TiktokIcon'

type DatasourceOverviewToolbarProps = {}

export const DatasourceOverviewToolbar: FC<DatasourceOverviewToolbarProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const darkMode = useSelector(isDarkMode)
    const selectedAccount = useSelector(getSelectedAccount)
    const datasourceFilters = useSelector(getProjectDatasourceFilters)

    const handleSelectChange = (event: SelectChangeEvent) => {
        dispatch(changeProjectDatasourceFilterType(event.target.value as any))
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeProjectDatasourceFilterSearchTerm(event.target.value))
    }

    return (
        <Box pt={2} pb={2}>
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <InputLabel shrink={true} sx={{ fontWeight: '600' }}>
                        <FormattedMessage
                            id="billing.limits-datasources"
                            values={{
                                limit: `${selectedAccount.data_source_count} / ${selectedAccount.data_source_limit}`,
                            }}
                        />
                    </InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" gap={4} mb={2}>
                        <Box flexGrow={1}>
                            <LinearProgress
                                variant="determinate"
                                color={darkMode ? 'secondary' : 'primary'}
                                value={(selectedAccount.data_source_count / selectedAccount.data_source_limit) * 100}
                            />
                        </Box>
                        <Typography variant="body2" color="text.secondary" fontWeight={800}>
                            {`${selectedAccount.data_source_count} / ${selectedAccount.data_source_limit}`}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8} sx={{ height: '100%' }}>
                    <TextField
                        InputLabelProps={{
                            variant: 'outlined',
                        }}
                        variant={'outlined'}
                        label={intl.formatMessage({ id: 'datasources.filter_names' })}
                        onChange={handleInputChange}
                        value={datasourceFilters.searchTerm}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} sx={{ alignSelf: 'flex-end', height: 'fit-content' }}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="channel-type-select-label" shrink={true} htmlFor="channel-type-select" variant="outlined">
                            <FormattedMessage id="datasources.filter_types" />
                        </InputLabel>
                        <Select
                            id="channel-type-select"
                            variant="outlined"
                            labelId="channel-type-select-label"
                            value={datasourceFilters.datasourceType}
                            onChange={handleSelectChange}
                            renderValue={(value: string) => <FormattedMessage id={value === 'all' ? 'filters.all' : value} />}
                            label={<FormattedMessage id="datasources.filter_types" />}
                        >
                            <MenuItem value={'all'}>
                                <Grid container>
                                    <Grid item sx={{ height: '23px' }}>
                                        <Square htmlColor={'transparent'} sx={{ marginRight: 1 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id={'filters.all'} />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="FACEBOOK_PAGE">
                                <Grid container>
                                    <Grid item sx={{ height: '23px' }}>
                                        <Facebook color={'action'} sx={{ marginRight: 1 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="FACEBOOK_PAGE" />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="FACEBOOK_AD_ACCOUNT">
                                <Grid container>
                                    <Grid item sx={{ height: '23px' }}>
                                        <Euro color={'action'} sx={{ marginRight: 1 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="FACEBOOK_AD_ACCOUNT" />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="INSTAGRAM_ACCOUNT">
                                <Grid container>
                                    <Grid item sx={{ height: '23px' }}>
                                        <Instagram color={'action'} sx={{ marginRight: 1 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="INSTAGRAM_ACCOUNT" />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="LINKED_IN">
                                <Grid container>
                                    <Grid item sx={{ height: '23px' }}>
                                        <LinkedIn color={'action'} sx={{ marginRight: 1 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="LINKED_IN" />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="LINKED_IN_PROFILE">
                                <Grid container>
                                    <Grid item sx={{ height: '23px' }}>
                                        <LinkedIn color={'action'} sx={{ marginRight: 1 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="LINKED_IN_PROFILE" />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="TIKTOK_ACCOUNT">
                                <Grid container>
                                    <Grid item sx={{ height: '23px' }}>
                                        <TiktokIcon color={'action'} sx={{ marginRight: 1, fontSize: 20, width: 25 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="TIKTOK_ACCOUNT" />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="TALKWALKER_JSON">
                                <Grid container>
                                    <Grid item sx={{ height: '23px' }}>
                                        <Language color={'action'} sx={{ marginRight: 1 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="TALKWALKER_JSON" />
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}
