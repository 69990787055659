import moment from 'moment'
import { Badge, Box, Drawer, Toolbar } from '@mui/material'
import { PrimaryNavigationButton } from './PrimaryNavigationButton'
import { AccessAlarm, BarChart, CalendarMonth, Feedback, Mail, Settings } from '@mui/icons-material'
import * as React from 'react'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAllowedModulesForSelectedProject,
    getCurrentUser,
    getInvalidDatasourceCountForProject,
    getSelectedAccount,
    getSelectedProject,
} from '../core/slices/CoreSlice'
import { FeedbackDialog } from './components/FeedbackDialog'
import { Account } from '../settings/accounts/Account'
import hmstrLogo from '../resources/images/hmstr-only.png'
import { TrialDialog } from './components/TrialDialog'
import { UserMenu } from './UserMenu'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import * as _ from 'lodash'
import { getOpenTicketCountForSelectedProject } from '../engagement/EngagementSlice'
import { useStartTour } from '../common/guides/useStartTour'
import { getScopeErrors } from '../core/slices/DataSlice'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { fetchOpenTicketCountForUser } from '../engagement/EngagementActions'
import { HmstrDispatch } from '../core/Store'

interface NavbarProps {}

export const primaryNavigationWidth = 90

export const PrimaryNavigation: FC<NavbarProps> = () => {
    const currentUser = useSelector(getCurrentUser)
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedAcount: Account | undefined = useSelector(getSelectedAccount)
    const remainingDays = moment(selectedAcount?.trial_end).diff(moment().subtract(1, 'day'), 'days')
    const isOwner = selectedAcount?.owner_id === currentUser?.id
    const navigate = useProjectNavigate()
    const projectsByAccount = _.chain(currentUser?.accounts || [])
        .mapKeys('account_id')
        .mapValues((acc) => acc.projects)
        .value()
    const project = useSelector(getSelectedProject)

    useEffectWithIdComparison(() => {
        if (project && allowedModules.includes('engagement')) {
            dispatch(fetchOpenTicketCountForUser(currentUser))
        }
    }, [currentUser, project])

    const allowedModules = useSelector(getAllowedModulesForSelectedProject)
    const openTicketsForProject = useSelector(getOpenTicketCountForSelectedProject)
    const projectCount = _.flatten(Object.values(projectsByAccount)).length
    const invalidDatasourceCount = useSelector(getInvalidDatasourceCountForProject)
    const missingScopes = useSelector(getScopeErrors)
    const missingScopesCount = Object.keys(missingScopes).length
    const multipleProjectsOrAccounts = currentUser.accounts.length > 1 || projectCount > 1

    const [feedbackOpen, setFeedbackOpen] = useState(false)
    const [trialDialogOpen, setTrialDialogOpen] = useState(false)

    useStartTour('primarySidebarTour')

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: primaryNavigationWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: primaryNavigationWidth,
                    boxSizing: 'border-box',
                    backgroundColor: 'primary.main',
                    color: 'white',
                },
            }}
        >
            <Toolbar sx={{ alignItems: 'center', flexDirection: 'column', height: '100%', pt: 2, pb: 2 }}>
                <PrimaryNavigationButton
                    size="large"
                    // tooltip="Startseite"
                    icon={<img alt="hmstr-logo" height="36px" src={hmstrLogo} />}
                    sx={{ mb: 3 }}
                    onClick={() => navigate(multipleProjectsOrAccounts ? '/settings/projects' : '/analytics/dashboards')}
                    // path="/home"
                />

                <PrimaryNavigationButton
                    id={'primary_nav_publishing'}
                    disabled={!allowedModules.includes('publishing')}
                    icon={<CalendarMonth />}
                    path="/publishing"
                    tooltip="publishing.title"
                />
                <Badge badgeContent={allowedModules.includes('engagement') ? openTicketsForProject : undefined} color={'error'} overlap="circular">
                    <PrimaryNavigationButton
                        id={'primary_nav_engagement'}
                        disabled={!allowedModules.includes('engagement')}
                        icon={<Mail />}
                        path="/engagement"
                        tooltip="engagement.title"
                    />
                </Badge>

                <PrimaryNavigationButton
                    id={'primary_nav_analytics'}
                    disabled={!allowedModules.includes('analytics')}
                    tooltip="analytics.title"
                    icon={<BarChart />}
                    path="/analytics"
                />

                <Box flexGrow={1} />

                {selectedAcount?.status === 'TRIALING' && (
                    <PrimaryNavigationButton
                        id={'primary_nav_trial'}
                        tooltip={<FormattedMessage id="hint.account.trial" values={{ days: remainingDays }} />}
                        icon={<AccessAlarm />}
                        onClick={() => (isOwner ? setTrialDialogOpen(true) : {})}
                        alwaysActive={true}
                        color="info"
                    />
                )}

                <PrimaryNavigationButton
                    id={'primary_nav_feedback'}
                    tooltip="feedback.feedback-tooltip"
                    icon={<Feedback />}
                    onClick={() => setFeedbackOpen(true)}
                />

                <Badge badgeContent={invalidDatasourceCount + missingScopesCount} color="error" overlap="circular">
                    <PrimaryNavigationButton id={'primary_nav_settings'} tooltip="settings.title" icon={<Settings />} path="/settings" />
                </Badge>

                <UserMenu />

                {feedbackOpen && <FeedbackDialog open={feedbackOpen} onClose={() => setFeedbackOpen(false)} />}
                {trialDialogOpen && <TrialDialog open={trialDialogOpen} onClose={() => setTrialDialogOpen(false)} remainingDays={remainingDays} />}
            </Toolbar>
        </Drawer>
    )
}
