import { Grid, styled } from '@mui/material'
import { HmstrColors } from '../../core/theme/hmstr-theme'

export const TagsHeaderRow = styled(Grid)(({ theme }) => ({
    borderBottom: `solid 1px ${HmstrColors.Orange}`,
    height: 'auto',
    padding: '12px 0px 8px',
    position: 'sticky',
    top: '0',
    zIndex: '5',
    backgroundColor: `${theme.palette.background.paper}`,

    '& .MuiAvatar-root': {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    '& > div': {
        textAlign: 'right',
        padding: theme.spacing(0.5),
    },

    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
}))
