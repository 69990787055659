import _ from 'lodash'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../core/Store'
import moment, { Moment } from 'moment'
import { getPostGroups, IdMap } from '../core/slices/DataSlice'
import { getCurrentUser, getPagesForPublish, getSelectedProject, getSelectedProjectAccess } from '../core/slices/CoreSlice'
import { Post } from './posts/Post'
import { ValidDatasourceTypeForPublishing } from './PublishingForm'
import { checkIsDatasourceDomainOwner, fetchLinkPreviewMetadata, fetchTiktokCreatorInfo, uploadMedia } from './PublishingActions'
import { PublishingLinkPreviewMetadata } from './PublishingLinkPreviewMetadata'
import { Path } from 'path-parser'
import { TiktokCreatorInfo } from './TiktokCreatorInfo'
import { PostGroupActivity } from './post-groups/PostGroupActivity'
import { getRelevantPostState } from './getRelevantPostState'
import { PostGroup } from './post-groups/PostGroup'
import {
    createNoteForPostGroup,
    createPostGroup,
    deletePostGroup,
    fetchPostGroupActivities,
    fetchPostGroupById,
    updatePostGroup,
} from './post-groups/PostGroupActions'
import { replaceUrlParams } from '../core/helpers/replace-url-params'
import { ProjectDatasourceType } from '../settings/datasources/ProjectDatasource'

export interface PublishingState {
    selectedStartDate: string
    selectedEndDate: string
    selectedStartDateListView: string
    selectedEndDateListView: string
    selectedPostGroupId?: string
    calendarView: 'month' | 'week'
    postDialogInitialTimestamp?: string
    postDialogScheduledPosts?: Post[]
    postDialogSelectedNetwork?: ValidDatasourceTypeForPublishing
    postDialogSelectedTab: ValidDatasourceTypeForPublishing | 'COMMON' | 'INTERNAL' | 'EXTERNAL'
    currentlyUploadingFileCount: number
    postLimitReached: boolean
    isPostGroupLoaded: boolean
    isLoadingPreview: boolean
    lastUrlPreviewLoaded?: string
    previewMetadata: PublishingLinkPreviewMetadata | null
    isLoadingLinkOwners: boolean
    allowedDomainsByDatasource: {
        [allowed_datasource_id: string]: string[]
    }
    deniedDomainsByDatasource: {
        [denied_datasource_id: string]: string[]
    }
    errorsForCommonFiles: { [file_id: string]: { network?: string; message?: string }[] | undefined }
    thumbnailsForCommonFiles: { [file_id: string]: string }
    showOnlyAssignedPosts: boolean
    showOnlyAssignedToOthersPosts: boolean
    showOnlyUnassignedPosts: boolean
    showFullTagNames: boolean
    selectedDatasourceIds: string[]
    selectedCalendarTimePeriod: 'month' | 'week'
    tiktokCreatorInfos: IdMap<TiktokCreatorInfo>
    postActivities: PostGroupActivity[]
    compactCalendarViewForMonthly: boolean
    compactCalendarViewForWeekly: boolean
}

const urlParams = new URLSearchParams(window.location.search)

const publishingPath = '/portal/:projectId/publishing/:view/:groupId'
const path = new Path<{ projectId: string; groupId: string }>(publishingPath)
const pathVariables = path.partialTest(window.location.pathname)

const selectedDatasourceIdsFromParams = urlParams.get('selected_datasources')
const calendarView = (urlParams.get('view') || 'month') as PublishingState['calendarView']
const startDate = urlParams.get('startDate') || moment().startOf(calendarView).format('YYYY-MM-DD')
const endDate = urlParams.get('endDate') || moment().endOf(calendarView).format('YYYY-MM-DD')
const startDateListView = urlParams.get('startDate') || moment().startOf(calendarView).format('YYYY-MM-DD')
const endDateListView = urlParams.get('endDate') || moment().endOf(calendarView).format('YYYY-MM-DD')
const selectedTab = urlParams.get('selectedTab') || 'COMMON'

const showOnlyAssignedPostsKey = 'hmstr.showOnlyAssignedPosts'
const showOnlyAssignedPostsDefault = localStorage.getItem(showOnlyAssignedPostsKey) === 'true'
const showOnlyAssignedToOthersPostsKey = 'hmstr.showOnlyAssignedToOthersPosts'
const showOnlyAssignedToOthersPostsDefault = localStorage.getItem(showOnlyAssignedToOthersPostsKey) === 'true'
const showOnlyUnassignedPostsKey = 'hmstr.showOnlyUnassignedPosts'
const showOnlyUnassignedPostsDefault = localStorage.getItem(showOnlyUnassignedPostsKey) === 'true'
const showFullTagNamesKey = 'hmstr.showFullTagNames'
const showFullTagNamesDefault = localStorage.getItem(showFullTagNamesKey) === 'true'
const calendarTimePeriodKey = 'hmstr.calendarTimePeriod'
const calendarTimePeriodDefault = localStorage.getItem(calendarTimePeriodKey) === 'week' ? 'week' : 'month'
const compactCalendarKeyMonthly = 'hmstr.compactCalendarViewMonthly'
const compactCalendarDefaultMonthly =
    localStorage.getItem(compactCalendarKeyMonthly) === null ? true : localStorage.getItem(compactCalendarKeyMonthly) === 'true'
const compactCalendarKeyWeekly = 'hmstr.compactCalendarViewWeekly'
const compactCalendarDefaultWeekly = localStorage.getItem(compactCalendarKeyWeekly) === 'true'

const initialState: PublishingState = {
    selectedPostGroupId: pathVariables ? pathVariables.groupId : undefined,
    calendarView: calendarView,
    selectedStartDate: startDate,
    postLimitReached: false,
    previewMetadata: null,
    isLoadingPreview: false,
    isLoadingLinkOwners: false,
    isPostGroupLoaded: false,
    selectedEndDate: endDate,
    selectedStartDateListView: startDateListView,
    selectedEndDateListView: endDateListView,
    postDialogSelectedTab: selectedTab as PublishingState['postDialogSelectedTab'],
    currentlyUploadingFileCount: 0,
    errorsForCommonFiles: {},
    thumbnailsForCommonFiles: {},
    allowedDomainsByDatasource: {},
    deniedDomainsByDatasource: {},
    showOnlyAssignedPosts: showOnlyAssignedPostsDefault,
    showOnlyAssignedToOthersPosts: showOnlyAssignedToOthersPostsDefault,
    showOnlyUnassignedPosts: showOnlyUnassignedPostsDefault,
    showFullTagNames: showFullTagNamesDefault,
    selectedDatasourceIds: selectedDatasourceIdsFromParams !== null ? selectedDatasourceIdsFromParams.split(',') : [],
    selectedCalendarTimePeriod: calendarTimePeriodDefault,
    tiktokCreatorInfos: {},
    postActivities: [],
    compactCalendarViewForMonthly: compactCalendarDefaultMonthly,
    compactCalendarViewForWeekly: compactCalendarDefaultWeekly,
}

export const PublishingSlice = createSlice({
    name: 'publishing',
    initialState,
    reducers: {
        selectPostGroup: (state, action: PayloadAction<string | undefined>) => {
            state.selectedPostGroupId = action.payload
        },
        startNewPost: (state, action: PayloadAction<string | undefined>) => {
            state.postDialogInitialTimestamp = action.payload
            state.selectedPostGroupId = undefined
        },
        resetPostForm: (state) => {
            state.postDialogInitialTimestamp = undefined
            state.postDialogScheduledPosts = undefined
            state.postDialogSelectedNetwork = undefined
            state.errorsForCommonFiles = {}
            state.previewMetadata = null
        },
        changeDate: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
            state.selectedStartDate = action.payload.startDate
            state.selectedEndDate = action.payload.endDate
        },
        changeCalendarTimePeriod: (state, action: PayloadAction<'week' | 'month'>) => {
            state.selectedCalendarTimePeriod = action.payload
            localStorage.setItem(calendarTimePeriodKey, state.selectedCalendarTimePeriod)
        },
        changeDateListView: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
            state.selectedStartDateListView = action.payload.startDate
            state.selectedEndDateListView = action.payload.endDate
        },
        changePostDialogSelectedNetwork: (state, action: PayloadAction<ProjectDatasourceType | undefined>) => {
            if (action.payload === 'LINKED_IN_PROFILE') {
                state.postDialogSelectedNetwork = 'LINKED_IN'
            } else if (
                action.payload === 'TALKWALKER_JSON' ||
                action.payload === 'FACEBOOK_AD_ACCOUNT' ||
                action.payload === 'TIKTOK_ADVERTISER' ||
                action.payload === 'TWITTER'
            ) {
                // nop
            } else {
                state.postDialogSelectedNetwork = action.payload
            }
        },
        changePostDialogSelectedTab: (state, action: PayloadAction<PublishingState['postDialogSelectedTab']>) => {
            state.postDialogSelectedTab = action.payload

            const urlParams = new URLSearchParams(window.location.search)
            if (action.payload !== 'COMMON') {
                urlParams.set('selectedTab', action.payload)
            } else {
                urlParams.delete('selectedTab')
            }
            replaceUrlParams(urlParams)
        },
        setErrorsForCommonFiles: (state, action: PayloadAction<Partial<PublishingState['errorsForCommonFiles']>>) => {
            state.errorsForCommonFiles = {
                ...state.errorsForCommonFiles,
                ...action.payload,
            }
        },
        unsetErrorsForCommonFilesByNetwork: (
            state,
            action: PayloadAction<{
                file_id: string
                network: string
            }>
        ) => {
            state.errorsForCommonFiles[action.payload.file_id]?.forEach((d, idx) => {
                if (d.network === action.payload.network && state.errorsForCommonFiles[action.payload.file_id]) {
                    delete state.errorsForCommonFiles[action.payload.file_id]![idx]
                }
            })

            const newVals = state.errorsForCommonFiles[action.payload.file_id]?.filter((val) => val)
            state.errorsForCommonFiles = {
                ...state.errorsForCommonFiles,
                [action.payload.file_id]: newVals?.length && newVals?.length > 0 ? newVals : [],
            }
        },
        unsetErrorsForAllCommonFiles: (state) => {
            state.errorsForCommonFiles = {}
        },
        setThumbnailForCommonFiles: (
            state,
            action: PayloadAction<{
                file_id: string
                thumbnail: string
            }>
        ) => {
            state.thumbnailsForCommonFiles = {
                ...state.thumbnailsForCommonFiles,
                [action.payload.file_id]: action.payload.thumbnail,
            }
        },
        toggleOnlyAssignedPosts: (state) => {
            state.showOnlyAssignedPosts = !state.showOnlyAssignedPosts
            localStorage.setItem(showOnlyAssignedPostsKey, state.showOnlyAssignedPosts.toString())
        },
        toggleCompactCalendarMonthly: (state) => {
            state.compactCalendarViewForMonthly = !state.compactCalendarViewForMonthly
            localStorage.setItem(compactCalendarKeyMonthly, state.compactCalendarViewForMonthly.toString())
        },
        toggleCompactCalendarWeekly: (state) => {
            state.compactCalendarViewForWeekly = !state.compactCalendarViewForWeekly
            localStorage.setItem(compactCalendarKeyWeekly, state.compactCalendarViewForWeekly.toString())
        },
        toggleOnlyAssignedToOthersPosts: (state) => {
            state.showOnlyAssignedToOthersPosts = !state.showOnlyAssignedToOthersPosts
            localStorage.setItem(showOnlyAssignedToOthersPostsKey, state.showOnlyAssignedToOthersPosts.toString())
        },
        toggleShowOnlyUnassignedPosts: (state) => {
            state.showOnlyUnassignedPosts = !state.showOnlyUnassignedPosts
            localStorage.setItem(showOnlyUnassignedPostsKey, state.showOnlyUnassignedPosts.toString())
        },
        toggleShowFullTagNames: (state) => {
            state.showFullTagNames = !state.showFullTagNames
            localStorage.setItem(showFullTagNamesKey, state.showFullTagNames.toString())
        },
        selectDatasourceIds: (state, action: PayloadAction<string[]>) => {
            state.selectedDatasourceIds = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadMedia.pending, (state) => {
                state.currentlyUploadingFileCount += 1
            })
            .addCase(uploadMedia.fulfilled, (state) => {
                if (state.currentlyUploadingFileCount > 0) {
                    state.currentlyUploadingFileCount -= 1
                }
            })
            .addCase(uploadMedia.rejected, (state) => {
                if (state.currentlyUploadingFileCount > 0) {
                    state.currentlyUploadingFileCount -= 1
                }
            })
            .addCase(updatePostGroup.rejected, (state, action: any) => {
                if (action.payload.status === 426) {
                    state.postLimitReached = true
                }
            })
            .addCase(updatePostGroup.fulfilled, (state) => {
                state.postLimitReached = false
            })
            .addCase(createPostGroup.rejected, (state, action: any) => {
                if (action.payload.status === 426) {
                    state.postLimitReached = true
                }
            })
            .addCase(createPostGroup.fulfilled, (state) => {
                state.postLimitReached = false
            })
            .addCase(deletePostGroup.fulfilled, (state, action) => {
                if (getRelevantPostState(action.meta.arg.posts) === 'PLANNED') {
                    state.postLimitReached = false
                }
            })
            .addCase(checkIsDatasourceDomainOwner.pending, (state) => {
                state.isLoadingLinkOwners = true
            })
            .addCase(checkIsDatasourceDomainOwner.fulfilled, (state, action) => {
                state.isLoadingLinkOwners = false
                const datasourceId = action.meta.arg.datasource.id
                const { hostname } = new URL(action.meta.arg.link)

                if (action.payload) {
                    if (state.allowedDomainsByDatasource[datasourceId] && !state.allowedDomainsByDatasource[datasourceId].includes(hostname)) {
                        state.allowedDomainsByDatasource[datasourceId].push(hostname)
                    } else if (!Object.keys(state.allowedDomainsByDatasource).includes(datasourceId)) {
                        state.allowedDomainsByDatasource[datasourceId] = [hostname]
                    }
                } else {
                    if (state.deniedDomainsByDatasource[datasourceId] && !state.deniedDomainsByDatasource[datasourceId].includes(hostname)) {
                        state.deniedDomainsByDatasource[datasourceId].push(hostname)
                    } else if (!Object.keys(state.deniedDomainsByDatasource).includes(datasourceId)) {
                        state.deniedDomainsByDatasource[datasourceId] = [hostname]
                    }
                }
            })
            .addCase(checkIsDatasourceDomainOwner.rejected, (state) => {
                state.isLoadingLinkOwners = false
            })
            .addCase(fetchLinkPreviewMetadata.pending, (state) => {
                state.isLoadingPreview = true
            })
            .addCase(fetchLinkPreviewMetadata.fulfilled, (state, action) => {
                state.isLoadingPreview = false
                state.previewMetadata = action.payload
                state.lastUrlPreviewLoaded = action.meta.arg.endsWith('/') ? action.meta.arg.slice(0, -1) : action.meta.arg
            })
            .addCase(fetchLinkPreviewMetadata.rejected, (state, action) => {
                state.isLoadingPreview = false
                state.previewMetadata = null
                state.lastUrlPreviewLoaded = action.meta.arg.endsWith('/') ? action.meta.arg.slice(0, -1) : action.meta.arg
            })
            .addCase(fetchPostGroupById.pending, (state) => {
                state.isPostGroupLoaded = false
            })
            .addCase(fetchPostGroupById.fulfilled, (state) => {
                state.isPostGroupLoaded = true
            })
            .addCase(fetchPostGroupById.rejected, (state) => {
                state.isPostGroupLoaded = true
            })
            .addCase(fetchTiktokCreatorInfo.fulfilled, (state, action) => {
                if (action.payload) {
                    state.tiktokCreatorInfos[action.meta.arg.id] = action.payload
                }
            })
            .addCase('core/selectProject', (state) => {
                state.selectedDatasourceIds = []
            })
            .addCase(fetchPostGroupActivities.fulfilled, (state, action) => {
                state.postActivities = action.payload
            })
            .addCase(createNoteForPostGroup.fulfilled, (state, action) => {
                state.postActivities.push(action.payload)
            })
    },
})

export const getSelectedStartDate: Selector<HmstrState, string> = (state) => state.publishing.selectedStartDate
export const getCompactCalendarViewForMonthly: Selector<HmstrState, boolean> = (state) => state.publishing.compactCalendarViewForMonthly
export const getCompactCalendarViewForWeekly: Selector<HmstrState, boolean> = (state) => state.publishing.compactCalendarViewForWeekly
export const getSelectedEndDate: Selector<HmstrState, string> = (state) => state.publishing.selectedEndDate
export const getSelectedCalendarTimePeriod: Selector<HmstrState, 'month' | 'week'> = (state) => state.publishing.selectedCalendarTimePeriod
export const getSelectedStartDateListView: Selector<HmstrState, string> = (state) => state.publishing.selectedStartDateListView
export const getSelectedEndDateListView: Selector<HmstrState, string> = (state) => state.publishing.selectedEndDateListView
export const getIsLoadingLinkOwner: Selector<HmstrState, boolean> = (state) => state.publishing.isLoadingLinkOwners
export const getPostDialogInitialTimestamp: Selector<HmstrState, string | undefined> = (state) => state.publishing.postDialogInitialTimestamp
export const getPostDialogSelectedNetwork: Selector<HmstrState, PublishingState['postDialogSelectedNetwork']> = (state) =>
    state.publishing.postDialogSelectedNetwork
export const getPostDialogSelectedTab: Selector<HmstrState, PublishingState['postDialogSelectedTab']> = (state) => state.publishing.postDialogSelectedTab
export const getCurrentlyUploadingFileCount: Selector<HmstrState, number> = (state) => state.publishing.currentlyUploadingFileCount
export const getErrorsForCommonFile: Selector<HmstrState, PublishingState['errorsForCommonFiles']> = (state) => state.publishing.errorsForCommonFiles
export const getPostLimitReached: Selector<HmstrState, PublishingState['postLimitReached']> = (state) => state.publishing.postLimitReached
export const getThumbnailsForCommonFile: Selector<HmstrState, PublishingState['thumbnailsForCommonFiles']> = (state) =>
    state.publishing.thumbnailsForCommonFiles
export const getAllowedDomainsByDatasource: Selector<HmstrState, PublishingState['allowedDomainsByDatasource']> = (state) =>
    state.publishing.allowedDomainsByDatasource
export const getDeniedDomainsByDatasource: Selector<HmstrState, PublishingState['deniedDomainsByDatasource']> = (state) =>
    state.publishing.deniedDomainsByDatasource
export const getIsLoadingPreview: Selector<HmstrState, PublishingState['isLoadingPreview']> = (state) => state.publishing.isLoadingPreview
export const getPreviewMetadata: Selector<HmstrState, PublishingState['previewMetadata']> = (state) => state.publishing.previewMetadata
export const getLastUrlPreviewLoaded: Selector<HmstrState, PublishingState['lastUrlPreviewLoaded']> = (state) => state.publishing.lastUrlPreviewLoaded
export const getSelectedPostGroupId: Selector<HmstrState, PublishingState['selectedPostGroupId']> = (state) => state.publishing.selectedPostGroupId
export const getIsPostGroupLoaded: Selector<HmstrState, PublishingState['isPostGroupLoaded']> = (state) => state.publishing.isPostGroupLoaded
export const getShowOnlyAssignedPosts: Selector<HmstrState, PublishingState['showOnlyAssignedPosts']> = (state) => state.publishing.showOnlyAssignedPosts
export const getShowOnlyAssignedToOthersPosts: Selector<HmstrState, PublishingState['showOnlyAssignedToOthersPosts']> = (state) =>
    state.publishing.showOnlyAssignedToOthersPosts
export const getShowOnlyUnassignedPosts: Selector<HmstrState, PublishingState['showOnlyUnassignedPosts']> = (state) => state.publishing.showOnlyUnassignedPosts
export const getShowFullTagNames: Selector<HmstrState, PublishingState['showFullTagNames']> = (state) => state.publishing.showFullTagNames
export const getSelectedDatasourceIds: Selector<HmstrState, PublishingState['selectedDatasourceIds']> = (state) => state.publishing.selectedDatasourceIds
export const getTiktokCreatorInfos: Selector<HmstrState, PublishingState['tiktokCreatorInfos']> = (state) => state.publishing.tiktokCreatorInfos
export const getPostActivities: Selector<HmstrState, PublishingState['postActivities']> = (state) => state.publishing.postActivities

export const getCheckedDomainsByDatasourceIds = createSelector(
    [getAllowedDomainsByDatasource, getDeniedDomainsByDatasource],
    (allowedDomains, deniedDomains) => {
        let res: IdMap<string[]> = {}
        Object.keys({ ...allowedDomains, ...deniedDomains }).forEach((key) => {
            res[key] = _.compact(_.concat(allowedDomains[key], deniedDomains[key]))
        })
        return res
    }
)

export const getAllowedDatasourcesForPublishing = createSelector([getPagesForPublish, getSelectedProjectAccess], (datasources, projectAccess) => {
    if (!projectAccess) {
        return []
    }

    if (projectAccess.role === 'ADMIN') {
        return datasources
    }

    const datasourcesById = _.mapKeys(datasources, 'id')
    return projectAccess.access
        .filter((a) => a.publishing_role && a.publishing_role !== 'OBSERVER')
        .map((a) => datasourcesById[a.data_source_id])
        .filter((d) => Boolean(d))
})

export const getPostGroupsFilteredByDatasourceId = createSelector(
    [getPostGroups, getSelectedDatasourceIds, getSelectedProject],
    (postGroups, selectedDsIds, project): IdMap<PostGroup> => {
        const datasourcesById = _.mapKeys(project?.data_sources || [], 'id')

        return _.chain(postGroups)
            .values()
            .filter((postGroup) => {
                if (postGroup.project_id !== project?.id) {
                    return false
                }

                return postGroup.posts
                    .map((post) => {
                        const includesDatasource = selectedDsIds.includes(post.data_source_id)
                        const datasourceExists = Boolean(datasourcesById[post.data_source_id])
                        return datasourceExists && (selectedDsIds.length === 0 || includesDatasource)
                    })
                    .includes(true)
            })
            .mapKeys('id')
            .value()
    }
)

export const getFilteredPostGroups = createSelector(
    [getPostGroupsFilteredByDatasourceId, getShowOnlyAssignedPosts, getShowOnlyAssignedToOthersPosts, getShowOnlyUnassignedPosts, getCurrentUser],
    (postGroups, showOnlyAssignedPosts, showOnlyAssignedToOthersPosts, showOnlyUnassignedPosts, currentUser): IdMap<PostGroup> => {
        if (!showOnlyAssignedPosts && !showOnlyAssignedToOthersPosts && !showOnlyUnassignedPosts) {
            return postGroups
        } else if (showOnlyAssignedToOthersPosts) {
            return _.chain(postGroups)
                .flatMap((p) => p)
                .filter((p) => {
                    return (p.assignee_id && p.assignee_id !== currentUser.id) || false
                })
                .mapKeys('id')
                .value()
        } else if (showOnlyUnassignedPosts) {
            return _.chain(postGroups)
                .flatMap((p) => p)
                .filter((p) => !p.assignee_id)
                .mapKeys('id')
                .value()
        }

        return _.chain(postGroups)
            .flatMap((p) => p)
            .filter((p) => p.assignee_id === currentUser.id)
            .mapKeys('id')
            .value()
    }
)

export const getFilteredPostGroupsOnlySelectedDs = createSelector(
    [getFilteredPostGroups, getSelectedDatasourceIds],
    (filteredPostGroups, datasourceIds): IdMap<PostGroup> => {
        const clonedPostGroups = _.cloneDeep(filteredPostGroups)
        _.forEach(clonedPostGroups, (postGroup) => {
            if (datasourceIds.length > 0) {
                postGroup.posts = postGroup.posts.filter((p) => datasourceIds.includes(p.data_source_id))
            }
        })
        return clonedPostGroups
    }
)
export const getPostGroupsForSelectedTimePeriodForListView = createSelector(
    [getFilteredPostGroupsOnlySelectedDs, getSelectedStartDateListView, getSelectedEndDateListView],
    (postsGroups, startDate, endDate): IdMap<PostGroup> => {
        return _.chain(postsGroups)
            .filter((p) => {
                return (
                    moment(p.posts[0].publish_time).isSameOrAfter(moment(startDate), 'day') &&
                    moment(p.posts[0].publish_time).isSameOrBefore(moment(endDate), 'day')
                )
            })
            .mapKeys('id')
            .value()
    }
)

export const getSelectedPostGroup = createSelector([getPostGroups, getSelectedPostGroupId], (postGroups, selectedGroupId): PostGroup | undefined => {
    if (!selectedGroupId) {
        return undefined
    }

    return postGroups[selectedGroupId]
})

export const getSelectedPostGroupIncludesPlannedByFacebook = createSelector([getSelectedPostGroup], (selectedPostGroup): boolean => {
    return (selectedPostGroup?.posts.map((post) => post.fb_publish_time).filter((d) => d) || []).length > 0
})

export const getSelectedPostGroupPlanViaFacebookMoment = createSelector([getSelectedPostGroup], (selectedPostGroup): Moment | undefined => {
    return selectedPostGroup ? moment(selectedPostGroup?.publish_time) : undefined
})

export const getDatasourceIdsForSelectedPostGroup = createSelector([getSelectedPostGroup], (postGroup) => postGroup?.posts.map((p) => p.data_source_id) || [])

export const getSelectedPostGroupFailedPosts = createSelector([getSelectedPostGroup], (selectedPostGroup) =>
    (selectedPostGroup?.posts || []).filter((p) => p.state === 'PUBLISH_FAILED')
)

export const getRelevantStateForSelectedPostGroup = createSelector([getSelectedPostGroup], (selectedPostGroup): Post['state'] | 'PLANNED_BY_FACEBOOK' => {
    return getRelevantPostState(selectedPostGroup?.posts || [])
})

export const getPostGroupsForProjectByDay = createSelector(
    [getFilteredPostGroupsOnlySelectedDs, getSelectedStartDate, getSelectedEndDate],
    (posts, start, end): IdMap<PostGroup[]> => {
        const startDate = moment(start)
        const endDate = moment(end)

        return _.chain(posts)
            .values()
            .filter((p) => {
                return moment(p.publish_time).isSameOrAfter(startDate, 'day') && moment(p.publish_time).isSameOrBefore(endDate, 'day')
            })
            .groupBy((postGroup) => moment(postGroup.publish_time).format('YYYY-MM-DD'))
            .mapValues((postGroups) => _.sortBy(postGroups, 'publish_time'))
            .value()
    }
)

export const getInternalActivities = createSelector([getPostActivities], (activities) => {
    return activities.filter((a) => !a.type.includes('EXTERNAL'))
})

export const getExternalActivities = createSelector([getPostActivities], (activities) => {
    return activities.filter((a) => !a.type.includes('INTERNAL'))
})

export const isApprovalProcessActive = createSelector([getSelectedProject], (selectedProject) => selectedProject?.approval_enabled || false)

export const {
    selectPostGroup,
    startNewPost,
    resetPostForm,
    changeDate,
    changeDateListView,
    changePostDialogSelectedNetwork,
    setErrorsForCommonFiles,
    unsetErrorsForCommonFilesByNetwork,
    unsetErrorsForAllCommonFiles,
    setThumbnailForCommonFiles,
    toggleOnlyAssignedPosts,
    toggleOnlyAssignedToOthersPosts,
    toggleShowOnlyUnassignedPosts,
    toggleShowFullTagNames,
    selectDatasourceIds,
    changeCalendarTimePeriod,
    changePostDialogSelectedTab,
    toggleCompactCalendarWeekly,
    toggleCompactCalendarMonthly,
} = PublishingSlice.actions

export const PublishingReducer = PublishingSlice.reducer
