import * as React from 'react'
import { FC } from 'react'
import { Box, keyframes } from '@mui/material'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'

type EmptyBoxProps = {}

export const EmptyBox: FC<EmptyBoxProps> = () => {
    const darkMode = useSelector(isDarkMode)
    const leafColor = darkMode ? '#1b6400' : '#238500'
    const pantsColor = darkMode ? '#505050' : '#2f2e41'
    const blobColor = darkMode ? '#2a2a2a' : '#efefef'

    const leavesAnimation1 = keyframes`
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(-10deg);
      }
      100% {
        transform: rotate(0deg);
      }`

    const leavesAnimation2 = keyframes`
      0% {
        transform: scaleY(1);
      }
      50% {
        transform: scaleY(1.2) skewX(-5deg);
      }
      100% {
        transform: scaleY(1);
      }`

    const blobAnimation = keyframes`
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1);
      }`

    return (
        <Box
            sx={{
                '.leaves1': {
                    transformOrigin: 'center',
                    animation: `${leavesAnimation1} 5s infinite`,
                },
                '.leaves2': {
                    transformOrigin: 'bottom',
                    animation: `${leavesAnimation2} 4s infinite`,
                },
                '.blob': {
                    animation: `${blobAnimation} 10s infinite`,
                },
                '.blob1': {
                    transformOrigin: ' 150px 465px',
                },
                '.blob2': {
                    transformOrigin: '457px 423px',
                    animationDelay: '4.5s',
                },
                '.blob3': {
                    transformOrigin: '400px 70px',
                    animationDelay: '3s',
                },
            }}
        >
            <svg data-name="Layer 1" viewBox="0 0 862.70323 644.78592" xmlns="http://www.w3.org/2000/svg">
                <defs></defs>
                <circle cx="150" cy="465" r="120" fill={blobColor} className={'blob blob1'}></circle>
                <circle cx="457" cy="423" r="290" fill={blobColor} className={'blob blob2'}></circle>
                <circle cx="400" cy="70" r="30" fill={blobColor} className={'blob blob3'}></circle>
                <polygon points="629.943 612.644 612.777 612.644 604.608 546.435 629.943 546.435 629.943 612.644" fill="#9e616a"></polygon>
                <path
                    d="M807.65107,769.99215H795.34112l-2.19727-11.62205-5.62754,11.62205H754.86738A7.33919,7.33919,0,0,1,750.697,756.6135l26.07247-18.00658v-11.7495l27.42368,1.63683Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
                <polygon points="731.923 590.981 718.148 601.224 672.085 552.969 692.415 537.851 731.923 590.981" fill={pantsColor}></polygon>
                <path
                    d="M925.58816,737.04791,915.71,744.39344l-8.69827-8.015,2.41922,12.68419-26.19923,19.48211a7.33918,7.33918,0,0,1-11.32976-8.24721l10.17712-30.00728-7.0111-9.42842,22.98294-15.05066Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
                <path
                    d="M818.57583,398.64705s32.56879,28.13791,17.542,108.35207l-18.3454,78.59653,59.8294,99.2561-19.07664,23.20771-77.77961-107.4334-28.18529-66.11365L744.6516,416.843Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
                <polygon points="599.447 425.746 597.488 456.084 603.483 585.365 631.692 580.452 637.083 488.406 599.447 425.746" fill={pantsColor}></polygon>
                <polygon points="237.445 628.211 252.796 628.21 260.098 569.001 237.443 569.002 237.445 628.211" fill="#ffb6b6"></polygon>
                <path
                    d="M402.178,750.80612l4.32074-.00018,16.86888-6.86018,9.0412,6.85913H432.41A19.26648,19.26648,0,0,1,451.67546,770.07v.62605l-49.49658.00183Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
                <polygon points="296.932 618.538 311.905 621.918 332.071 565.772 309.972 560.782 296.932 618.538" fill="#ffb6b6"></polygon>
                <path
                    d="M462.86463,740.39329l4.21465.9516,17.96568-2.97583,7.3082,8.68223.0012.00027a19.26648,19.26648,0,0,1,14.54854,23.03569l-.1379.61067L458.48379,759.7967Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
                <path
                    d="M386.6516,393.843c-7.19708,21.70636-6.43618,45.268,1.72992,70.55606l3.49087,142.37821S386.67128,700.146,403.4543,733.00177h24.34l12.05112-134.75129,1.5133-90.44591,52.18244,76.30583L460.30462,730.79868l29.9568,2.678,53.93408-159.1909L477.6516,419.843Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
                <g className={'leaves1'}>
                    <path
                        d="M 498.698 204.408 C 517.315 187.631 545.007 179.196 568.235 188.603 C 544.408 201.832 526.028 223.062 516.347 248.538 C 512.649 258.376 509.56 269.704 500.465 274.971 C 494.806 278.249 487.765 278.409 481.419 276.827 C 475.074 275.244 469.257 272.073 463.52 268.933 L 461.888 268.896 C 469.216 244.93 480.08 221.184 498.698 204.408 Z"
                        fill={leafColor}
                    ></path>
                    <path
                        d="M 568.105 189.112 C 547.533 191.771 528.321 200.844 513.199 215.043 C 509.889 218.042 507.073 221.544 504.853 225.42 C 502.813 229.265 501.841 233.587 502.036 237.935 C 502.136 241.994 502.709 246.133 501.821 250.145 C 500.841 254.285 498.14 257.81 494.397 259.834 C 489.851 262.447 484.637 263.271 479.511 263.899 C 473.819 264.597 467.896 265.231 462.968 268.424 C 462.371 268.811 461.806 267.862 462.402 267.475 C 470.975 261.921 481.822 263.942 491.039 260.235 C 495.341 258.505 499.146 255.468 500.493 250.878 C 501.671 246.863 501.084 242.594 500.947 238.486 C 500.694 234.246 501.484 230.009 503.246 226.145 C 505.261 222.169 507.938 218.564 511.162 215.486 C 518.31 208.44 526.521 202.562 535.495 198.066 C 545.708 192.879 556.734 189.477 568.095 188.008 C 568.798 187.917 568.803 189.022 568.105 189.112 L 568.105 189.112 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 517.799 210.184 C 513.59 204.276 514.281 196.189 519.431 191.08 C 519.937 190.582 520.736 191.345 520.229 191.844 C 515.423 196.594 514.794 204.138 518.747 209.618 C 519.162 210.195 518.212 210.758 517.799 210.184 L 517.799 210.184 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 501.714 235.884 C 508.783 237.137 516.065 235.679 522.108 231.801 C 522.706 231.416 523.271 232.365 522.674 232.749 C 516.379 236.773 508.797 238.275 501.444 236.955 C 500.743 236.829 501.017 235.759 501.714 235.884 L 501.714 235.884 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 545.798 194.341 C 546.931 196.923 549.287 198.762 552.067 199.235 C 552.769 199.353 552.494 200.423 551.797 200.306 C 548.729 199.769 546.127 197.747 544.85 194.907 C 544.704 194.644 544.787 194.312 545.041 194.15 C 545.303 193.995 545.641 194.08 545.798 194.341 L 545.798 194.341 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 593.813 269.507 C 593.372 269.447 592.932 269.386 592.485 269.332 C 586.559 268.545 580.578 268.242 574.603 268.424 C 574.141 268.43 573.672 268.444 573.211 268.464 C 558.82 269.056 544.663 272.314 531.461 278.074 C 526.21 280.371 521.141 283.064 516.298 286.13 C 509.61 290.364 502.696 295.487 495.22 297.214 C 494.442 297.403 493.653 297.544 492.858 297.635 L 461.969 270.89 C 461.93 270.794 461.884 270.704 461.844 270.608 L 460.561 269.597 C 460.8 269.421 461.052 269.244 461.29 269.068 C 461.428 268.966 461.573 268.87 461.711 268.767 C 461.805 268.701 461.9 268.635 461.981 268.569 C 462.013 268.547 462.044 268.525 462.069 268.51 C 462.151 268.445 462.233 268.393 462.308 268.334 C 463.71 267.341 465.122 266.357 466.543 265.38 C 466.549 265.373 466.549 265.373 466.562 265.372 C 477.32 257.826 488.935 251.582 501.163 246.772 C 501.53 246.634 501.903 246.488 502.284 246.361 C 507.791 244.314 513.456 242.72 519.223 241.595 C 522.383 240.989 525.571 240.544 528.776 240.261 C 537.058 239.545 545.401 240.136 553.499 242.012 C 569.642 245.755 584.409 254.62 593.155 268.445 C 593.379 268.799 593.595 269.147 593.813 269.507 Z"
                        fill={leafColor}
                    ></path>
                    <path
                        d="M 593.404 269.839 C 575.377 259.576 554.575 255.254 533.952 257.486 C 529.504 257.888 525.147 258.988 521.042 260.747 C 517.098 262.589 513.719 265.454 511.257 269.044 C 508.894 272.344 506.859 275.995 503.734 278.664 C 500.458 281.379 496.18 282.567 491.973 281.929 C 486.77 281.279 482.111 278.797 477.64 276.213 C 472.675 273.343 467.564 270.283 461.707 269.866 C 460.998 269.815 461.118 268.717 461.826 268.768 C 472.015 269.494 479.459 277.638 489.051 280.228 C 493.527 281.436 498.393 281.303 502.233 278.449 C 505.591 275.953 507.692 272.19 510.056 268.828 C 512.407 265.29 515.588 262.383 519.321 260.359 C 523.324 258.397 527.632 257.13 532.059 256.614 C 542.009 255.291 552.104 255.541 561.976 257.355 C 573.254 259.363 584.105 263.285 594.061 268.951 C 594.677 269.302 594.016 270.188 593.404 269.839 L 593.404 269.839 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 540.551 256.376 C 540.747 249.125 546.168 243.084 553.356 242.106 C 554.06 242.012 554.239 243.103 553.533 243.196 C 546.836 244.095 541.792 249.739 541.649 256.495 C 541.633 257.206 540.535 257.083 540.551 256.376 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 512.234 267.212 C 517.125 272.469 523.817 275.689 530.976 276.23 C 531.686 276.283 531.566 277.381 530.857 277.328 C 523.408 276.751 516.451 273.386 511.374 267.904 C 510.89 267.382 511.754 266.692 512.234 267.212 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 572.445 260.584 C 571.796 263.328 572.569 266.214 574.504 268.266 C 574.994 268.782 574.13 269.471 573.643 268.958 C 571.517 266.683 570.657 263.502 571.347 260.465 C 571.389 260.167 571.656 259.952 571.956 259.975 C 572.258 260.01 572.477 260.281 572.445 260.584 Z"
                        fill="#fff"
                    ></path>
                </g>
                <g className={'leaves2'}>
                    <path
                        d="M 52.382 624.104 C 50.932 629.544 47.122 634.074 42.522 637.374 C 41.772 637.914 41.002 638.414 40.222 638.884 C 39.982 639.024 39.742 639.174 39.492 639.304 C 39.222 639.464 38.952 639.614 38.682 639.754 L 17.052 639.754 C 16.662 638.964 16.282 638.164 15.902 637.374 C 6.632 617.894 0.122 596.874 1.232 575.464 C 1.673 567.159 3.417 558.976 6.402 551.214 C 12.342 535.744 23.182 522.354 38.092 515.614 C 38.462 515.444 38.852 515.274 39.232 515.114 C 39.112 515.544 38.992 515.964 38.872 516.394 C 37.273 522.156 36.142 528.039 35.492 533.984 C 35.432 534.444 35.382 534.904 35.342 535.374 C 33.922 549.711 35.183 564.188 39.062 578.064 C 39.072 578.094 39.082 578.134 39.092 578.164 C 39.942 581.234 40.929 584.257 42.052 587.234 C 42.932 589.584 43.882 591.904 44.922 594.184 C 49.262 603.754 55.082 613.954 52.382 624.104 Z"
                        fill={leafColor}
                    ></path>
                    <path
                        d="M 38.393 518.596 C 38.183 518.876 37.973 519.146 37.763 519.426 C 33.371 525.247 29.641 531.539 26.643 538.186 C 26.483 538.516 26.333 538.846 26.203 539.186 C 22.095 548.468 19.457 558.333 18.383 568.426 C 18.372 568.495 18.365 568.566 18.363 568.636 C 18.113 570.996 17.963 573.376 17.903 575.756 C 17.681 580.217 18.163 584.685 19.333 588.996 C 20.4 592.516 22.269 595.741 24.793 598.416 C 25.043 598.686 25.293 598.956 25.563 599.216 C 25.763 599.426 25.983 599.636 26.193 599.836 C 28.213 601.766 30.423 603.556 32.323 605.626 C 33.194 606.554 33.981 607.558 34.673 608.626 C 36.909 612.246 37.492 616.649 36.273 620.726 C 34.913 625.786 31.803 630.056 28.623 634.126 C 27.033 636.166 25.393 638.226 23.973 640.406 C 23.453 641.186 22.973 641.976 22.533 642.786 L 21.273 642.786 C 21.693 641.976 22.153 641.186 22.653 640.406 C 26.303 634.656 31.493 629.716 34.183 623.386 C 36.003 619.116 36.553 614.276 34.253 610.086 C 33.579 608.868 32.764 607.733 31.823 606.706 C 29.993 604.636 27.803 602.866 25.813 600.996 C 25.313 600.526 24.823 600.046 24.353 599.546 C 21.862 597.014 19.939 593.98 18.713 590.646 C 17.326 586.411 16.67 581.971 16.773 577.516 C 16.773 574.676 16.923 571.816 17.203 568.976 C 17.233 568.616 17.273 568.246 17.313 567.876 C 19.358 550.004 26.15 533.006 36.983 518.646 C 37.183 518.366 37.393 518.096 37.603 517.826 C 38.033 517.266 38.823 518.046 38.393 518.596 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 17.717 569.071 C 10.563 567.869 5.333 561.662 5.363 554.408 C 5.368 553.982 5.833 553.723 6.198 553.94 C 6.356 554.034 6.457 554.2 6.467 554.384 C 6.427 561.141 11.316 566.92 17.987 568 C 18.689 568.114 18.414 569.184 17.717 569.071 L 17.717 569.071 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 24.516 598.617 C 30.4 594.504 34.518 588.324 36.049 581.309 C 36.2 580.614 37.271 580.885 37.12 581.579 C 35.514 588.876 31.215 595.299 25.082 599.565 C 24.497 599.972 23.934 599.021 24.516 598.617 L 24.516 598.617 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 26.312 538.069 C 28.939 539.093 31.906 538.728 34.206 537.097 C 34.785 536.684 35.348 537.635 34.772 538.046 C 32.223 539.835 28.954 540.245 26.042 539.14 C 25.753 539.057 25.577 538.763 25.642 538.469 C 25.718 538.175 26.017 537.996 26.312 538.069 L 26.312 538.069 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 113.413 557.266 C 113.063 557.536 112.703 557.806 112.353 558.086 C 107.605 561.719 103.161 565.733 99.063 570.086 C 98.743 570.416 98.423 570.756 98.113 571.096 L 98.103 571.106 C 88.387 581.653 80.705 593.906 75.443 607.246 L 75.413 607.336 C 75.403 607.366 75.393 607.386 75.383 607.416 C 73.298 612.756 71.616 618.244 70.353 623.836 C 69.133 629.296 68.133 635.146 66.223 640.406 C 65.933 641.216 65.613 642.016 65.273 642.786 L 20.813 642.786 C 20.963 641.996 21.123 641.196 21.283 640.406 C 23.545 628.819 27.08 617.517 31.823 606.706 C 31.983 606.346 32.143 605.986 32.323 605.626 C 34.772 600.284 37.652 595.151 40.933 590.276 C 40.933 590.27 40.938 590.266 40.943 590.266 C 42.745 587.606 44.681 585.039 46.743 582.576 C 52.091 576.213 58.409 570.732 65.463 566.336 C 65.503 566.306 65.553 566.286 65.593 566.256 C 79.633 557.546 96.273 553.396 112.183 556.986 L 112.193 556.986 C 112.603 557.076 113.003 557.166 113.413 557.266 Z"
                        fill={leafColor}
                    ></path>
                    <path
                        d="M 113.363 557.796 C 113.023 557.886 112.683 557.986 112.353 558.086 C 105.345 560.089 98.58 562.862 92.183 566.356 C 91.863 566.526 91.543 566.706 91.223 566.886 C 82.383 571.82 74.358 578.091 67.433 585.476 C 67.428 585.481 67.424 585.488 67.423 585.496 C 67.343 585.576 67.253 585.666 67.183 585.746 C 65.583 587.466 64.043 589.256 62.583 591.096 C 59.727 594.527 57.426 598.383 55.763 602.526 C 54.493 605.979 54.044 609.68 54.453 613.336 C 54.483 613.706 54.533 614.066 54.583 614.436 C 54.623 614.726 54.663 615.016 54.713 615.316 C 55.373 619.326 56.513 623.346 56.193 627.436 C 55.799 631.675 53.612 635.543 50.183 638.066 C 49.031 638.957 47.799 639.74 46.503 640.406 C 44.651 641.361 42.72 642.157 40.733 642.786 L 36.803 642.786 C 37.333 642.636 37.853 642.486 38.383 642.336 C 40.264 641.805 42.111 641.161 43.913 640.406 C 44.943 639.975 45.945 639.481 46.913 638.926 C 50.933 636.616 54.283 633.076 54.983 628.346 C 55.593 624.206 54.413 620.066 53.713 616.016 C 53.593 615.316 53.483 614.626 53.423 613.936 C 52.946 610.429 53.236 606.86 54.273 603.476 C 55.712 599.255 57.863 595.311 60.633 591.816 C 62.347 589.54 64.176 587.354 66.113 585.266 C 66.353 584.996 66.603 584.726 66.863 584.456 C 73.934 577.018 82.098 570.702 91.073 565.726 L 91.083 565.726 C 97.757 562.011 104.837 559.078 112.183 556.986 L 112.193 556.986 C 112.523 556.886 112.863 556.786 113.193 556.696 C 113.883 556.516 114.043 557.606 113.363 557.796 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 66.468 585.645 C 61.479 580.379 61.041 572.274 65.431 566.5 C 65.864 565.936 66.761 566.581 66.328 567.146 C 62.228 572.517 62.652 580.075 67.328 584.953 C 67.819 585.467 66.956 586.156 66.468 585.645 L 66.468 585.645 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 54.107 613.33 C 61.282 613.589 68.291 611.134 73.736 606.454 C 74.275 605.99 74.967 606.851 74.429 607.315 C 68.753 612.174 61.454 614.714 53.988 614.428 C 53.277 614.4 53.399 613.302 54.107 613.33 L 54.107 613.33 Z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M 91.996 566.067 C 93.476 568.467 96.065 569.961 98.883 570.044 C 99.595 570.063 99.471 571.161 98.764 571.142 C 95.652 571.036 92.794 569.395 91.135 566.76 C 90.954 566.519 90.991 566.179 91.219 565.983 C 91.457 565.794 91.804 565.831 91.996 566.067 L 91.996 566.067 Z"
                        fill="#fff"
                    ></path>
                </g>
                <path
                    d="M625.03076,300.73673a11.59945,11.59945,0,0,1-17.7667.83759l-37.80039,16.44009,3.682-21.10161,35.3314-12.37668a11.66235,11.66235,0,0,1,16.55372,16.20061Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#ffb8b8"
                ></path>
                <path
                    d="M599.80571,307.32525l-87.7976,39.10831-.18835-.06738-100.067-35.65889a32.95966,32.95966,0,0,1-14.78168-42.75569h0a32.92423,32.92423,0,0,1,46.9872-14.63652l74.4685,44.85908,72.21121-9.35878Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#bbb"
                ></path>
                <path
                    d="M1031.35162,771.203a1.1865,1.1865,0,0,1-1.19,1.19h-860.29a1.19,1.19,0,0,1,0-2.38h860.29A1.1865,1.1865,0,0,1,1031.35162,771.203Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#ccc"
                ></path>
                <path
                    d="M481.99193,424.40352l-88.50585-14.15674a16.89334,16.89334,0,0,1-9.95557-23.646l4.01367-8.02832-1.55908-84.34668A62.48156,62.48156,0,0,1,416.32152,239.572l8.63086-5.16064,4.36182-11.07666,40.22022.981.11718,14.52734,14.40381,22.96826-.00049.09522-.90381,125.01367-3.96972,12.90137,6.00244,15.00586Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#bbb"
                ></path>
                <circle cx="284.4591" cy="45.40997" r="36.54413" fill="#ffb8b8"></circle>
                <path
                    d="M415.05385,180.98352c-1.09-4.59187-.58956-11.05349.02641-15.677,1.61485-12.12129,8.3464-23.64474,18.57336-30.47048a13.37957,13.37957,0,0,1,6.66453-2.64845c2.41939-.101,5.04189,1.19418,5.78465,3.499a11.99254,11.99254,0,0,1,6.76552-6.709,21.1355,21.1355,0,0,1,9.63075-1.29746,35.19728,35.19728,0,0,1,29.36306,20.98947c.97609,2.3188,3.70246-6.24621,4.93916-4.05528a9.7407,9.7407,0,0,0,5.52388,4.85342c2.4233.67619,3.40756,10.66034,4.3612,8.33222a11.0984,11.0984,0,0,1-10.61055,15.47525c-2.46642-.09228-4.82489-.99947-7.262-1.39-8.71512-1.39642-17.96,4.92316-19.82312,13.55058a23.98689,23.98689,0,0,0-3.15565-7.021,8.1187,8.1187,0,0,0-6.51321-3.57866c-2.47957.09278-4.6591,1.7139-6.26793,3.60295s-2.81713,4.093-4.43782,5.97186c-4.7555,5.513-11.18745,18.3697-17.96453,17.432C425.30335,201.103,416.54206,187.25309,415.05385,180.98352Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
                <path
                    d="M674.01238,342.14754a7.1328,7.1328,0,0,0-4.80706-7.85363l-98.41317-32.77709a7.13219,7.13219,0,0,0-2.933-.3368l-24.66687,2.33267-14.15377,1.34255-26.11867,2.46833a7.15519,7.15519,0,0,0-6.38357,5.98973l-13.26135,82.8376a7.18646,7.18646,0,0,0,4.48439,7.79592l99.4404,38.38442a6.94669,6.94669,0,0,0,1.44636.38836,7.13621,7.13621,0,0,0,2.17571.01648l64.25546-9.52349a7.12057,7.12057,0,0,0,6.023-5.99919Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#fff"
                ></path>
                <path
                    d="M490.01349,398.1102l99.44009,38.38234a.89711.89711,0,0,0,.457.05366l64.247-9.52224a.88347.88347,0,0,0,.7549-.75161l12.91979-85.06677a.90469.90469,0,0,0-.59937-.98151l-.66169-.22392-97.75762-32.54588a.67787.67787,0,0,0-.13742-.03318.88732.88732,0,0,0-.23-.01192l-60.16426,5.6932-4.77428.44794a.90314.90314,0,0,0-.7947.74781l-13.259,82.83439A.89735.89735,0,0,0,490.01349,398.1102Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#ff6900"
                ></path>
                <path
                    d="M508.28194,313.10237l60.16426-5.6932a.88732.88732,0,0,1,.23.01192.67787.67787,0,0,1,.13742.03318l97.75762,32.54588-25.78658,2.72965-9.65046,1.01669-27.46045,2.90123a1.939,1.939,0,0,1-.24081-.0029c-.04881-.01472-.09762-.02944-.15639-.04511Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#2f2e41"
                ></path>
                <path
                    d="M487.75761,403.95209l99.44009,38.38233a6.72242,6.72242,0,0,0,1.4505.37968,7.22358,7.22358,0,0,0,2.17727.02722l64.247-9.52224a7.13521,7.13521,0,0,0,6.02839-6.00387l12.90982-85.06772a7.19014,7.19014,0,0,0-.4184-3.71669c-.06533-.15688-.13072-.31384-.207-.46172a6.99031,6.99031,0,0,0-2.26369-2.69758,7.13789,7.13789,0,0,0-1.91579-.97662l-.11659-.04131-98.29175-32.73751a8.95539,8.95539,0,0,0-1.22721-.29807,7.08573,7.08573,0,0,0-1.71463-.03323l-24.66295,2.32468-14.15253,1.35L502.917,307.3259a7.09173,7.09173,0,0,0-3.01853.99744,1.32948,1.32948,0,0,0-.20245.12125,1.1922,1.1922,0,0,0-.12992.09813,7.14818,7.14818,0,0,0-3.02682,4.76367l-13.2699,82.84346A7.19418,7.19418,0,0,0,487.75761,403.95209Zm10.54219-90.35694a5.29965,5.29965,0,0,1,1.26984-2.6713,4.65147,4.65147,0,0,1,.67571-.65875,5.31719,5.31719,0,0,1,2.32365-1.08389,4.059,4.059,0,0,1,.50915-.07189l43.98466-4.15521,20.96479-1.995c.14217-.01658.27254-.01418.40386-.02168a5.00673,5.00673,0,0,1,.94761.07043,4.14489,4.14489,0,0,1,.84467.20125l98.4084,32.77882c.07775.02754.14554.05407.22323.0816a5.218,5.218,0,0,1,2.27305,1.6537,5.25912,5.25912,0,0,1,1.12074,4.14541l-12.92068,85.07674a5.34916,5.34916,0,0,1-4.5086,4.50155l-64.257,9.52134a5.41346,5.41346,0,0,1-2.72281-.31038l-99.441-38.37237a5.40237,5.40237,0,0,1-3.35921-5.846Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#3f3d56"
                ></path>
                <path
                    d="M499.35216,308.99439a.87724.87724,0,0,1,.268-.38623,1.05132,1.05132,0,0,1,.129-.08817c.04169-.01607.08434-.04216.12611-.05828a.87349.87349,0,0,1,.62383-.01066l2.06994.73016,101.1157,35.66943,23.66513-2.5004,13.24288-1.39675,28.02932-2.96742,2.50639-.26279.48732-.05387a.9043.9043,0,0,1,.95216.65352.73938.73938,0,0,1,.02649.14313.893.893,0,0,1-.55014.92188.98843.98843,0,0,1-.24752.06673l-3.40944.35738-27.60268,2.91775-9.65046,1.01669-27.46045,2.90123a1.939,1.939,0,0,1-.24081-.0029c-.04881-.01472-.09762-.02944-.15639-.04511L500.24535,310.2651l-.3498-.1238a.67025.67025,0,0,1-.21942-.12146A.91016.91016,0,0,1,499.35216,308.99439Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#3f3d56"
                ></path>
                <path
                    d="M588.91905,442.97456a.89376.89376,0,0,1-.74251-1.01574l14.51687-96.33414a.894.894,0,0,1,1.017-.75056l.008.00129a.89377.89377,0,0,1,.74252,1.01574l-14.51687,96.33414a.894.894,0,0,1-1.017.75055Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#3f3d56"
                ></path>
                <path
                    d="M625.716,436.86342l-9.6548,1.01888,11.29337-95.5347s12.89458-2.33464,13.23951-1.39846C640.80631,341.50808,625.80805,436.25066,625.716,436.86342Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#3f3d56"
                ></path>
                <polygon points="331.25 182.533 330.99 226.1 408.116 255.488 435.813 218.284 331.25 182.533" fill="#3f3d56"></polygon>
                <path
                    d="M671.13144,337.72465a5.30105,5.30105,0,0,0-2.49688-1.73654l-98.40594-32.7777a5.10582,5.10582,0,0,0-.848-.20665,5.00894,5.00894,0,0,0-.95065-.07115l.15966-.99731.98511-.71323,23.36822-16.9188,78.04053,23.91705.13549,27.05154Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#3f3d56"
                ></path>
                <path
                    d="M503.829,380.07963a1.51326,1.51326,0,0,1,.326.06843l30.19365,9.91686a1.50014,1.50014,0,0,1-.93555,2.85069l-30.19364-9.91685a1.50039,1.50039,0,0,1,.60952-2.91913Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#fff"
                ></path>
                <path
                    d="M589.34024,397.72852A11.59947,11.59947,0,0,1,573.433,389.7714L532.421,385.62792l13.53022-16.60628,36.87128,6.48065a11.66236,11.66236,0,0,1,6.5177,22.22623Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#ffb8b8"
                ></path>
                <path
                    d="M564.115,391.14082l-95.70849-8.81836-.13135-.15088L398.42455,302.135a32.95967,32.95967,0,0,1,8.01319-44.52344h0a32.92425,32.92425,0,0,1,48.14355,10.209l43.02246,75.54443,67.56543,27.147Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#bbb"
                ></path>
                <path
                    d="M804.33859,237.22376c-2.37688-17.43387-5.35788-36.15172-17.65411-48.7369a41.34992,41.34992,0,0,0-59.74384.61837c-8.95079,9.54876-12.90365,22.95672-13.2654,36.03983s2.55205,26.02081,5.78442,38.70347a119.28958,119.28958,0,0,0,49.78577-9.79937c3.92617-1.70407,7.789-3.63056,11.93689-4.68634,4.14784-1.05571,7.10454,1.60088,10.96292,3.45335l2.118-4.05545c1.73377,3.22659,7.10244,2.27017,9.04978-.83224C805.26007,244.82608,804.83352,240.853,804.33859,237.22376Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
                <path
                    d="M736.532,334.53244l-69.876,1.49441a11.05455,11.05455,0,1,0-4.93974,15.57383c9.26761.52674,81.77191,10.81733,86.0974,4.18549,4.39027-6.73106,27.82423-30.48612,27.82423-30.48612l-18.01271-25.64378Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#9e616a"
                ></path>
                <circle cx="584.91096" cy="94.03525" r="32.83012" fill="#9e616a"></circle>
                <path d="M599.36147,299.184" transform="translate(-168.64838 -127.60704)" fill="#ff6900"></path>
                <path
                    d="M806.14195,284.81075c-3.86888-7.69981-5.74873-17.212-13.99671-19.70823-5.56965-1.68563-28.09691.84048-33.17312,3.6859-8.44356,4.73313-.79189,13.60234-5.77332,21.90214-5.41517,9.02271-20.132,27.12978-25.5472,36.15241-3.72279,6.20279,8.8171,24.40947,6.80408,31.358-2.01273,6.94848-2.10962,14.74736,1.31952,21.11722,3.06888,5.70141-1.37137,10.745,1.71521,16.437,3.20957,5.91962,7.14849,28.05274,4.16119,34.08785l-2,6c19.84682,1.16609,36.53459-22.54427,56.25813-25.04188,4.89894-.62032,9.98565-1.43073,14.02251-4.27435,5.94639-4.18864,8.29717-11.78923,9.76638-18.91282A159.32576,159.32576,0,0,0,806.14195,284.81075Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#3f3d56"
                ></path>
                <path
                    d="M835.89793,366.11245c-2.76443-7.54563-7.769-40.5366-7.769-40.5366l-31.32417-.91848,15.31443,37.772-41.79036,58.50283s.07739.12853.21808.35778a11.052,11.052,0,1,0,9.26964,11.74483.76305.76305,0,0,0,.95807-.16445C785.42465,427.035,838.66236,373.65815,835.89793,366.11245Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#9e616a"
                ></path>
                <path
                    d="M839.0826,345.27741c-2.87511-12.13478-5.77152-24.33549-10.61887-35.82566s-11.78661-22.34286-21.54669-30.10543c-3.12048-2.48179-6.609-4.67232-10.52078-5.44389-3.91147-.77165-8.31967.09193-11.0667,2.98137-4.39621,4.62357-3.07339,12.0451-1.4611,18.21781Q791,322.40224,798.13123,349.70286q20.59418-2.18287,41.188-4.36591Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill="#3f3d56"
                ></path>
                <path
                    d="M793.7871,226.19592c-1.20908-7.942-2.47188-15.95043-5.31228-23.42857-2.8404-7.47821-7.41882-14.48249-13.98647-18.71882-10.39879-6.70709-23.862-5.41352-35.52074-1.55544-9.01622,2.9837-17.81761,7.51864-24.17574,14.8093-6.35848,7.29074-9.92957,17.69379-7.56439,27.22665q18.65464-4.40738,37.30893-8.81483l-1.36137.962a30.03765,30.03765,0,0,1,16.03083,20.8927,31.12209,31.12209,0,0,1-6.56554,25.84773q12.72244-4.51323,25.44489-9.0263c5.23526-1.85713,10.83833-3.997,13.94267-8.76047C795.62723,240.107,794.79091,232.78685,793.7871,226.19592Z"
                    transform="translate(-168.64838 -127.60704)"
                    fill={pantsColor}
                ></path>
            </svg>
        </Box>
    )
}
