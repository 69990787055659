import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { initClientConfiguration } from './core/init/init-client-config'
import { initKeycloak } from './core/init/init-keycloak'
import { initLocalizationContext, LocalizationContext, onReactIntlError } from './core/init/init-localization-context'
import { KeycloakContext } from './core/keycloak/KeycloakContext'
import { KeycloakWrapper } from './core/keycloak/KeycloakWrapper'
import { HmstrStore, initHmstrStore } from './core/Store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import ReactGA from 'react-ga4'
import { Typography } from '@mui/material'
import { HmstrColors } from './core/theme/hmstr-theme'
import { AppEntryPoints } from './core/AppEntryPoints'
import { loadInitialData } from './core/init/init-data'
import { initUser } from './core/init/init-user'
import { hasCookieAdsConsent } from './core/helpers/check-cookie-consent'

export let stripePromise: Promise<Stripe | null>

const routes = [{ path: '*', Component: AppEntryPoints }]

export const basePath = '/portal'
const routerConfig = {
    basename: basePath,
}

const renderApplication = (hmstrStore: HmstrStore, keycloakWrapper: KeycloakWrapper, localizationContext: LocalizationContext) => {
    const router = createBrowserRouter(routes, routerConfig)
    const container = document.getElementById('root') as HTMLElement
    const root = createRoot(container)
    root.render(
        <IntlProvider
            locale={localizationContext.language}
            messages={localizationContext.messages[localizationContext.language]}
            onError={onReactIntlError}
            defaultRichTextElements={{
                strong: (chunks) => <strong>{chunks}</strong>,
                strongOrange: (chunks) => (
                    <Typography component={'strong'} fontWeight={'bolder'} color={HmstrColors.Orange}>
                        {chunks}
                    </Typography>
                ),
                italicOrange: (chunks) => (
                    <Typography component={'span'} variant={'inherit'} fontStyle={'italic'} color={HmstrColors.Orange}>
                        {chunks}
                    </Typography>
                ),
                orange: (chunks) => (
                    <Typography component={'span'} color={HmstrColors.Orange} sx={{ letterSpacing: 'normal', lineHeight: 'inherit' }}>
                        {chunks}
                    </Typography>
                ),
                br: () => <br />,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localizationContext.language}>
                <KeycloakContext.Provider value={keycloakWrapper}>
                    <Provider store={hmstrStore}>
                        <RouterProvider router={router} />
                    </Provider>
                </KeycloakContext.Provider>
            </LocalizationProvider>
        </IntlProvider>
    )
}

const bootstrapApplication = async () => {
    const [clientConfig, apiEntrypoint] = await initClientConfiguration()
    const keycloakInstance = await initKeycloak(clientConfig)
    const keycloakWrapper = new KeycloakWrapper(keycloakInstance, clientConfig)

    window.cookiehub.load({})

    if (clientConfig.profiles.includes('prod') && hasCookieAdsConsent()) {
        ReactGA.initialize('G-D9PV3W24HS')
        fbq('init', '492371441541730')
    }

    const currentUser = await initUser(clientConfig, keycloakWrapper, apiEntrypoint)
    const initData = await loadInitialData(apiEntrypoint, currentUser)
    const hmstrStore = initHmstrStore(keycloakWrapper, clientConfig, apiEntrypoint, initData)
    const localizationContext = initLocalizationContext(hmstrStore.getState().keycloak.locale)
    stripePromise = loadStripe(clientConfig.stripe_public_key)

    const windowPathName = window.location.pathname

    if (!windowPathName.includes(basePath)) {
        window.history.replaceState(window.history.state, '', basePath)
    }

    renderApplication(hmstrStore, keycloakWrapper, localizationContext)
}

bootstrapApplication().then(() => console.log('Application started.'))
