import * as React from 'react'
import { FC } from 'react'
import { Grid, Skeleton, Typography } from '@mui/material'
import { PostAvatar } from '../../../common/avatars/PostAvatar'
import { InstagramMore } from '../../../common/icons/InstagramMore'
import { PostPreviewImageInstagram } from './PostPreviewImageInstagram'
import { PostPreviewVideo } from '../PostPreviewVideo'
import { InstagramPostActionsPreview } from './InstagramPostActionsPreview'
import { Post } from '../../posts/Post'
import { ProjectDatasource } from '../../../settings/datasources/ProjectDatasource'
import { ReadMore } from '../ReadMore'

type InstagramPostPreviewProps = {
    post: Post
    selectedDatasource: ProjectDatasource
    darkmode: boolean
    igFontFamily: string
    generatePostText: (text: string) => JSX.Element
    actualFiles: any[]
    mockupDevice: 'mobile' | 'desktop'
}

export const InstagramPostPreview: FC<InstagramPostPreviewProps> = ({
    post,
    selectedDatasource,
    darkmode,
    igFontFamily,
    generatePostText,
    actualFiles,
    mockupDevice,
}) => {
    return (
        <Grid container spacing={'10px'} alignItems="center">
            {selectedDatasource.metadata.profile_picture_url && (
                <Grid item borderRadius={'100%'}>
                    <PostAvatar
                        width={32}
                        height={32}
                        name={selectedDatasource.name}
                        src={selectedDatasource.metadata.profile_picture_url}
                        instagramStoryBorder
                    />
                </Grid>
            )}
            <Grid item xs={6} sx={{ lineHeight: 1 }}>
                <Typography
                    sx={{
                        fontSize: 14,
                        color: darkmode ? 'inherit' : 'rgb(38,38,38)',
                        fontWeight: 600,
                        lineHeight: '18px',
                        fontFamily: igFontFamily,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {selectedDatasource.metadata.description}
                    <Typography
                        component="span"
                        sx={{
                            display: 'inline-block',
                            ml: 1,
                            lineHeight: '18px',
                            fontWeight: 400,
                            fontSize: 12,
                            letterSpacing: '.2px',
                            color: 'rgb(115, 115, 115)',
                            textTransform: 'lowercase',
                            fontFamily: igFontFamily,
                        }}
                    >
                        {' '}
                        • now
                    </Typography>
                </Typography>
            </Grid>
            <Grid item flexGrow={1} justifyContent={'flex-end'} display={'flex'}>
                <InstagramMore />
            </Grid>

            {post.post_type !== 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12}>
                    <PostPreviewImageInstagram uploadFiles={actualFiles} mockupDevice={mockupDevice} />
                </Grid>
            )}

            {post.post_type === 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12}>
                    <PostPreviewVideo variant="STANDARD" file={actualFiles[0]} />
                </Grid>
            )}

            <Grid item xs={12}>
                <InstagramPostActionsPreview />
            </Grid>
            <Grid item xs={12} sx={{ pt: '2% !important' }}>
                <Typography
                    sx={{
                        fontSize: 14,
                        color: darkmode ? 'inherit' : '#050505',
                        fontWeight: 600,
                        lineHeight: '14px',
                        display: 'inline-block',
                        mr: 0.7,
                        fontFamily: igFontFamily,
                    }}
                >
                    1,234 Likes
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: '1% !important' }}>
                <Typography>
                    <span
                        style={{
                            fontSize: 14,
                            color: darkmode ? 'inherit' : '#050505',
                            fontWeight: 600,
                            lineHeight: '14px',
                            fontFamily: igFontFamily,
                        }}
                    >
                        {selectedDatasource.metadata.description}
                    </span>
                    <span style={{ display: 'inline-block', width: 5 }}></span>
                    {post.text ? (
                        <ReadMore sliceLimit={100} passedElement={generatePostText(post.text)}>
                            {generatePostText(post.text)}
                        </ReadMore>
                    ) : (
                        <>
                            <Skeleton animation={false} sx={{ width: '50%', display: 'inline-block' }} />
                        </>
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: '2% !important' }}>
                <Typography
                    sx={{
                        lineHeight: '18px',
                        fontSize: 14,
                        fontWeight: 400,
                        color: 'rgb(115, 115, 115)',
                        fontFamily: igFontFamily,
                        cursor: 'pointer',
                    }}
                >
                    View all 10 comments
                </Typography>
            </Grid>
        </Grid>
    )
}
