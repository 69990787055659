import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { getSelectedProject, isDarkMode, isProjectAdmin, showSuccessSnackbar } from '../core/slices/CoreSlice'
import { updateProject } from './project-management/ProjectActions'
import { fetchCurrentUser } from './user-management/UserActions'
import { Alert, Box, Button, CircularProgress, Container, Grid, Tooltip, Typography, useTheme } from '@mui/material'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { Info } from '@mui/icons-material'
import { ConnectedTextField, StyledTextField } from '../form/ConnectedTextField'
import { composeValidators, validationMaxLength, validationRequired } from '../form/validate'
import { Project } from './project-management/Project'
import { ConnectedColorpicker } from '../form/ConnectedColorPicker'
import { WhiteLabelLogoPicker } from './WhiteLabelLogoPicker'
import { WhiteLabelingPreviewDialog } from './WhiteLabelingPreviewDialog'
import { ConnectedCheckbox } from '../form/ConnectedCheckbox'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { getIsProjectUpdating } from './SettingsSlice'

type ProjectFormProps = {}

const validateIfContactPersonExists = (value: any, allValues: Partial<Project>) => {
    if (!Object.keys(allValues).includes('contact_person') || allValues.contact_person === null) {
        return undefined
    }

    return validationRequired(value)
}

export const ProjectForm: FC<ProjectFormProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const darkmode = useSelector(isDarkMode)
    const navigate = useProjectNavigate()
    const isUserProjectAdmin = useSelector(isProjectAdmin)
    const selectedProject = useSelector(getSelectedProject)
    const theme = useTheme()
    const isProjectUpdating = useSelector(getIsProjectUpdating)
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false)
    const handleCloseDialog = () => {
        setPreviewDialogOpen(false)
    }

    useEffect(() => {
        if (!isUserProjectAdmin) {
            navigate('settings')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserProjectAdmin])

    const handleSubmit = (project: Project) => {
        dispatch(updateProject(project)).then(() => {
            dispatch(fetchCurrentUser())
            dispatch(showSuccessSnackbar('projects.updated'))
        })
    }

    return (
        <Container maxWidth="lg">
            <Box mt={2}>
                <Typography variant="h5">
                    <FormattedMessage id="projects.settings" />
                </Typography>
            </Box>

            <Box mt={4}>
                <Alert icon={<Info />} color="info">
                    <FormattedMessage id="settings.project-settings-hint" />
                </Alert>
            </Box>

            {selectedProject && (
                <Form<Project>
                    onSubmit={handleSubmit}
                    initialValues={{
                        ...selectedProject,
                        whitelabel_bg: selectedProject.whitelabel_bg || theme.palette.primary.main,
                        whitelabel_text: selectedProject.whitelabel_text || theme.palette.primary.contrastText,
                    }}
                    mutators={{ ...arrayMutators }}
                    render={({ handleSubmit, values, pristine }) => {
                        const descriptionLength = values.description ? values.description.length : 0
                        const maxLength = 512

                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3} sx={{ mt: 2 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="overline">
                                            <strong>
                                                <FormattedMessage id="general.common" />
                                            </strong>
                                        </Typography>
                                    </Grid>

                                    {selectedProject?.id && (
                                        <Grid item xs={12}>
                                            <StyledTextField readonly={true} value={selectedProject.id} disabled={true} fullWidth={true} label={'ID'} />
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <ConnectedTextField
                                            label="general.name"
                                            name="name"
                                            required={true}
                                            validate={composeValidators(validationRequired, validationMaxLength(intl, 32))}
                                            maxLength={128}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ConnectedTextField
                                            label="general.description"
                                            name="description"
                                            multiline={true}
                                            helperText={`${descriptionLength} / ${maxLength} ${intl.formatMessage({
                                                id: 'general.characters',
                                            })}`}
                                            maxLength={maxLength}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="overline">
                                            <strong>
                                                <FormattedMessage id="settings.project.approval" />
                                            </strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ConnectedCheckbox name="approval_enabled" label="settings.approval.enable" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="overline">
                                            <strong>
                                                <FormattedMessage id="settings.project.whitelabeling" />
                                            </strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ConnectedCheckbox name="whitelabeling_enabled" label="settings.whitelabeling.enable" />
                                    </Grid>
                                    {values.whitelabeling_enabled && (
                                        <>
                                            <Grid item>
                                                <WhiteLabelLogoPicker
                                                    required
                                                    name={'whitelabel_image'}
                                                    fieldLabel={'settings.project.whitelabel-image'}
                                                    buttonLabel={'settings.project.pick_logo'}
                                                />
                                            </Grid>
                                            <Grid item position={'relative'} height={'225px'} mt={-4}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction={'column'}
                                                    bottom={0}
                                                    justifyContent={'space-between'}
                                                    position={'absolute'}
                                                    width={'200px'}
                                                    alignItems={'center'}
                                                >
                                                    <Grid item xs={12} pt={0}>
                                                        <ConnectedColorpicker
                                                            centerItems
                                                            name={'whitelabel_bg'}
                                                            label={'settings.project.whitelabel-bg'}
                                                            outline={theme.palette.primary.main}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ConnectedColorpicker
                                                            centerItems
                                                            name={'whitelabel_text'}
                                                            label={'settings.project.whitelabel-text'}
                                                            outline={theme.palette.primary.main}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant={'contained'}
                                                            color={darkmode ? 'primary' : 'inherit'}
                                                            onClick={() => {
                                                                setPreviewDialogOpen(true)
                                                            }}
                                                        >
                                                            <FormattedMessage id={'settings.project.whitelabel-open-preview'} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}

                                    <Grid item xs={12}>
                                        <Typography
                                            variant="overline"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <strong>
                                                <FormattedMessage id="notifications.manage" />
                                            </strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display={'flex'} alignItems={'end'} gap={1} maxWidth={300}>
                                            <ConnectedTextField
                                                label={intl.formatMessage({
                                                    id: 'settings.project.engagement_comment_threshold',
                                                })}
                                                type={'number'}
                                                required
                                                name="unread_comment_threshold"
                                                min={5}
                                                step={5}
                                                fullWidth={true}
                                            />
                                            <Tooltip title={<FormattedMessage id="settings.project.engagement_comment_threshold.tooltip" />}>
                                                <Info />
                                            </Tooltip>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box marginTop={4}>
                                            <Typography variant="overline">
                                                <strong>
                                                    <FormattedMessage id="general.contactPerson" />
                                                </strong>
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <ConnectedTextField
                                            label="general.firstName"
                                            name="contact_person.first_name"
                                            maxLength={64}
                                            validate={validateIfContactPersonExists}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ConnectedTextField
                                            label="general.lastName"
                                            name="contact_person.last_name"
                                            maxLength={64}
                                            validate={validateIfContactPersonExists}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ConnectedTextField label="general.title" name="contact_person.title" maxLength={128} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ConnectedTextField label="general.email" name="contact_person.email" maxLength={128} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ConnectedTextField label="general.phone" name="contact_person.phone" maxLength={64} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ConnectedTextField label="general.facebook-profile" name="contact_person.facebook" maxLength={128} />
                                    </Grid>
                                </Grid>

                                <Box marginTop={6} marginBottom={8}>
                                    <Grid container spacing={1} justifyContent="flex-end">
                                        <Grid item>
                                            <Button color="secondary" variant="contained" type="submit" fullWidth disabled={pristine || isProjectUpdating}>
                                                {isProjectUpdating ? (
                                                    <CircularProgress variant={'indeterminate'} size={24} />
                                                ) : (
                                                    <FormattedMessage id="general.save" />
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <WhiteLabelingPreviewDialog open={previewDialogOpen} onClose={handleCloseDialog} passedTitle={selectedProject?.name} />
                            </form>
                        )
                    }}
                />
            )}
        </Container>
    )
}
