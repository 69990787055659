import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Box, Switch, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedProject, isProjectAdmin } from '../../core/slices/CoreSlice'
import { getTagsData } from '../../core/slices/DataSlice'
import { getIsLoadingTags } from '../../tags/TagsSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchTagsForProject } from '../../tags/TagsActions'
import { ContentPanel } from '../../common/content-panel/ContentPanel'
import { Fallback } from '../../common/fallback/Fallback'
import { TagsList } from './TagsList'
import { Loading } from '../../common/loading/Loading'
import { Tag } from '../../tags/Tag'
import { HmstrDispatch } from '../../core/Store'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { FilterDrawer, FilterSetting } from '../../common/filterbar/FilterDrawer'
import { TitlebarWithFilters } from '../../common/filterbar/TitlebarWithFilters'
import { Colorize, Search } from '@mui/icons-material'
import { SearchChip } from '../../common/filterbar/SearchChip'

type TagProps = {}

export const Tags: FC<TagProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()

    const selectedProject = useSelector(getSelectedProject)
    const tags = useSelector(getTagsData)
    const isLoading = useSelector(getIsLoadingTags)
    const [showBlankTags, setShowBlankTags] = useState(false)
    const [displayedTags, setDisplayedTags] = useState(tags)
    const [searchQuery, setSearchQuery] = useState('')
    const [orderedTags, setOrderedTags] = useState<string[]>([])
    const isUserProjectAdmin = useSelector(isProjectAdmin)

    useEffect(() => {
        sortFilterTags(tags)
        if (!isUserProjectAdmin) {
            navigate('analytics')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBlankTags, searchQuery, isUserProjectAdmin])

    useEffect(() => {
        setDisplayedTags(tags)

        if (orderedTags.length === 0) {
            sortFilterTags(tags)
        } else if (tags.length !== orderedTags.length) {
            let clone = [...orderedTags]
            clone = clone.filter((tagId) => !!tags.find((tag) => tagId === tag.id))
            tags.forEach((tag) => {
                if (!clone.includes(tag.id) && tag.label.includes(searchQuery)) {
                    if (showBlankTags && tag.color === '#EEE') {
                        clone = [tag.id, ...clone]
                    } else if (!showBlankTags) {
                        clone = [tag.id, ...clone]
                    }
                }
                if (clone.includes(tag.id) && !tag.label.includes(searchQuery)) {
                    const index = clone.indexOf(tag.id)
                    if (index > -1) {
                        // only splice array when item is found
                        clone.splice(index, 1) // 2nd parameter means remove one item only
                    }
                }
            })
            setOrderedTags(clone)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags, searchQuery, showBlankTags])

    const sortFilterTags = (tags: Tag[]) => {
        let filteredTags = tags

        if (showBlankTags) {
            filteredTags = filteredTags.filter((tag) => tag.color === '#EEE')
        }

        if (searchQuery.length > 0) {
            filteredTags = filteredTags.filter((tag) => tag.label.trim().toLowerCase().includes(searchQuery.trim().toLowerCase()))
        }
        filteredTags.sort((a, b) => {
            if (!a.active) {
                return 1
            }
            if (!b.active) {
                return -1
            }
            return a.label.trim().toLowerCase() < b.label.trim().toLowerCase() ? -1 : 1
        })
        setOrderedTags(filteredTags.map((tag) => tag.id))
    }

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTagsForProject(selectedProject))
        }
    }, [selectedProject])

    const getTagSearchSetting = () => {
        const hasChangedFromDefault = searchQuery.length > 0
        const onDeleteFunction = () => {
            setSearchQuery('')
        }
        const set: FilterSetting = {
            chipElement: <SearchChip onChange={(value) => setSearchQuery(value)} value={searchQuery} searchbarTitle={'tags.search'} />,
            onlyChip: true,
            tooltip: 'tags.search',
            hasChangedFromDefault,
            onDeleteFunction,
            deletable: true,
            important: true,
            icon: <Search fontSize={'small'} />,
        }
        return set
    }

    const getTagsWithoutColorSetting = () => {
        const hasChangedFromDefault = showBlankTags
        const onDeleteFunction = () => {
            setShowBlankTags(false)
        }

        const set: FilterSetting = {
            settingInput: (
                <Box display={'flex'} alignItems={'center'}>
                    <Switch
                        color={'secondary'}
                        checked={showBlankTags}
                        onChange={(e) => {
                            setShowBlankTags(e.target.checked)
                            e.target.blur()
                        }}
                    />
                    <Typography variant="subtitle2">
                        <FormattedMessage id="tags.show-blank-tags" />
                    </Typography>
                </Box>
            ),
            tooltip: 'tags.show-blank-tags',
            icon: <Colorize fontSize={'small'} />,
            deletable: true,
            hasChangedFromDefault,
            onDeleteFunction,
            currentValueAsString: showBlankTags ? 'tags.show-blank-tags-short' : 'general.off',
        }
        return set
    }

    return (
        <ContentPanel
            fullWidthTitle
            maxWidth={false}
            title={
                <TitlebarWithFilters
                    title={
                        <Typography variant="h5">
                            <FormattedMessage id="tags.title" /> <FormattedMessage id="tags.tag-managment" />
                        </Typography>
                    }
                    filterBar={<FilterDrawer filters={[getTagSearchSetting(), getTagsWithoutColorSetting()]} />}
                />
            }
            disableToolbarToggle
        >
            <Fallback condition={!Boolean(selectedProject)} messageId="tags.no-tags">
                {isLoading ? (
                    <Box padding={4}>
                        <Loading />
                    </Box>
                ) : (
                    <TagsList tags={displayedTags} orderedTags={orderedTags} />
                )}
            </Fallback>
        </ContentPanel>
    )
}
