import { FC } from 'react'
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { getApiEntryPoint } from '../../core/api/ApiSlice'
import { useKeycloak } from '../../core/hooks/useKeycloak'
import { getSelectedProjectId, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { ConnectedDropdown } from '../../form/ConnectedDropdown'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { ConnectedHiddenField } from '../../form/ConnectedHiddenField'
import { ConnectedImageUpload } from '../../form/ConnectedImageUpload'
import { Error, Send } from '@mui/icons-material'
import { FORM_ERROR } from 'final-form'
import { validationRequired } from '../../form/validate'
import { getSelectedDashboard } from '../../dashboards/DashboardsSlice'
import { useLocation } from 'react-router-dom'

type FeedbackDialogueProps = {
    open: boolean
    onClose: () => void
}

type FeedbackFormValues = {
    email: string
    type: 'ERROR' | 'FEEDBACK'
    subject: string
    message: string
    image: File
    module: 'analytics' | 'settings' | 'engagement' | 'publishing'
    url: string
    project_id: string
    dashboard_id: string
}

export const FeedbackDialog: FC<FeedbackDialogueProps> = ({ open, onClose }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const keycloak = useKeycloak()
    const apiEntryPoint = useSelector(getApiEntryPoint)
    const selectedProjectId = useSelector(getSelectedProjectId)
    const selectedDashboardId = useSelector(getSelectedDashboard)?.id
    const currentLocation = useLocation()
    const moduleOptions = currentLocation.pathname.replace(`/${selectedProjectId}/`, '').split('/')
    const currentModule = moduleOptions[0] as FeedbackFormValues['module']

    const handleFeedbackClose = () => {
        if (onClose) {
            onClose()
        }
    }

    const handleFeedbackSend = async (formValues: FeedbackFormValues) => {
        const formData = new FormData()
        const ticketId = Date.now().toString()

        if (formValues.image) {
            formData.append('feedback_image', formValues.image)
        }

        formData.append('feedback_mail', formValues.email)
        formData.append('feedback_type', formValues.type)
        formData.append('feedback_subject', formValues.subject)
        formData.append('feedback_message', formValues.message)
        formData.append('ticket_id', '#' + ticketId)
        formData.append('feedback_name', keycloak.fullName)
        formData.append('feedback_module', formValues.module)
        formData.append('feedback_url_from', currentLocation.pathname)
        formData.append('feedback_project_id', selectedProjectId || 'undefined')
        formData.append('feedback_dashboard_id', selectedDashboardId || 'undefined')

        const response = await axios.post(apiEntryPoint._links.feedback.href, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })

        if (response.data === 'success') {
            dispatch(showSuccessSnackbar(intl.formatMessage({ id: 'feedback.email-success' }, { ticketId })))
            handleFeedbackClose()
        } else {
            return { [FORM_ERROR]: 'feedback.email-error' }
        }
    }

    return (
        <Form<FeedbackFormValues>
            initialValues={{ email: keycloak.email, type: 'ERROR', module: currentModule }}
            onSubmit={handleFeedbackSend}
            render={({ submitting, handleSubmit, submitError, invalid }) => {
                return (
                    <Dialog open={open} onClose={handleFeedbackClose}>
                        <form onSubmit={handleSubmit}>
                            <DialogTitle>
                                <FormattedMessage id="feedback.feedback-dialogue-title" />
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <FormattedMessage id="feedback.feedback-dialogue-text" />
                                </DialogContentText>

                                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                                    <ConnectedHiddenField name="email" />

                                    <Grid item xs={12}>
                                        <ConnectedDropdown name="type" label="feedback.feedback-type" validate={validationRequired} required>
                                            <MenuItem value="ERROR">
                                                <FormattedMessage id="feedback.feedback-error" />
                                            </MenuItem>
                                            <MenuItem value="FEEDBACK">
                                                <FormattedMessage id="feedback.feedback" />
                                            </MenuItem>
                                        </ConnectedDropdown>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ConnectedDropdown name="module" label="feedback.module" validate={validationRequired} required>
                                            {['analytics', 'settings', 'engagement', 'publishing'].map((module) => {
                                                return (
                                                    <MenuItem key={module} value={module}>
                                                        <FormattedMessage id={`feedback.module-names.${module}`} />
                                                    </MenuItem>
                                                )
                                            })}
                                        </ConnectedDropdown>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ConnectedTextField autoFocus name="subject" label="general.subject" validate={validationRequired} required />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ConnectedTextField
                                            name="message"
                                            disableMaxLength={true}
                                            label="general.message"
                                            multiline={true}
                                            rows={5}
                                            validate={validationRequired}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ConnectedImageUpload name="image" />
                                    </Grid>
                                </Grid>

                                {submitError && (
                                    <Box mt={2}>
                                        <Alert severity="error" icon={<Error />}>
                                            <FormattedMessage id={submitError} />
                                        </Alert>
                                    </Box>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleFeedbackClose} color={'inherit'}>
                                    <FormattedMessage id="general.cancel" />
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={submitting ? <CircularProgress color="inherit" size={20} /> : <Send />}
                                    disabled={submitting || invalid}
                                    type="submit"
                                >
                                    <FormattedMessage id="general.submit" />
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                )
            }}
        />
    )
}
