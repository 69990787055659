import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { Box, Button, ButtonGroup, CircularProgress, MenuItem, MenuList, Popover, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm, useFormState } from 'react-final-form'
import {
    getPostLimitReached,
    getRelevantStateForSelectedPostGroup,
    getSelectedPostGroup,
    getSelectedPostGroupPlanViaFacebookMoment,
    isApprovalProcessActive,
} from './PublishingSlice'
import { PublishingFormValues } from './PublishingForm'
import { getActiveTarget } from '../common/guides/GuideSlice'
import { Post } from './posts/Post'
import { ArrowDropDown, Check, Drafts, EventAvailable, Public } from '@mui/icons-material'
import { useHasDatasourcePermission } from '../core/hooks/useHasPermission'
import { ConfirmDialog } from '../common/confirm-dialog/ConfirmDialog'
import { ConnectedHiddenField } from '../form/ConnectedHiddenField'
import { useWillSaveRevokeApproval } from './PublishingHooks'
import moment from 'moment'

type PublishingFormSubmitProps = {
    partiallyFailed: boolean
    failedPostCount: number
}

export const PublishingFormSubmit: FC<PublishingFormSubmitProps> = ({ partiallyFailed, failedPostCount }) => {
    const intl = useIntl()
    const [open, setOpen] = useState(false)
    const [notApprovedHintOpen, setNotApprovedHintOpen] = useState(false)
    const [revokeApprovalHintOpen, setRevokeApprovalHintOpen] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)
    const form = useForm()
    const disableNewPosts = useSelector(getPostLimitReached)
    const formState = useFormState<PublishingFormValues>()
    const approvalActive = useSelector(isApprovalProcessActive)
    const selectedPostGroup = useSelector(getSelectedPostGroup)
    const persistedPostState = useSelector(getRelevantStateForSelectedPostGroup)
    const planViaFacebookMoment = useSelector(getSelectedPostGroupPlanViaFacebookMoment)
    const fbPublishTimeInFuture = moment().isBefore(planViaFacebookMoment)
    const isPlannedViaFacebook = persistedPostState === 'PLANNED_BY_FACEBOOK'
    const hasPostGroupChanges = (!formState.submitSucceeded && formState.dirty) || (formState.submitSucceeded && formState.dirtySinceLastSubmit)

    const newPostState = isPlannedViaFacebook && hasPostGroupChanges ? 'PLANNED' : formState.values.common_post.state

    const isAlreadyPublished = ['PUBLISH_SUCCESS', 'PUBLISH_IN_PROGRESS'].includes(persistedPostState)

    const canPublish = useHasDatasourcePermission('post.publish', formState.values.data_source_ids)
    const canCreateDraft = useHasDatasourcePermission('post.create_draft', formState.values.data_source_ids)
    const approved =
        selectedPostGroup && (selectedPostGroup.internal_approval?.state === 'APPROVED' || selectedPostGroup.external_approval?.state === 'APPROVED')
    const actionWillRevokeApproval = useWillSaveRevokeApproval()

    const activeTarget = useSelector(getActiveTarget)

    useEffect(() => {
        if (activeTarget === '#publishing_actions_button') {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [activeTarget])

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }

    const getOptions = () => {
        // When no data sources are selected, we do not know yet if he has permissions, so the button is not disabled
        const canPublishForSelectedDatasources = canPublish || formState.values.data_source_ids.length === 0
        const canCreateDraftForSelectedDatasources = canCreateDraft || formState.values.data_source_ids.length === 0

        return {
            PLANNED: {
                state: 'PLANNED',
                label: intl.formatMessage({ id: 'publishing.plan' }),
                icon: <EventAvailable />,
                disabled: !canPublishForSelectedDatasources || disableNewPosts,
            },
            DRAFT: {
                state: 'DRAFT',
                label: intl.formatMessage({ id: 'publishing.save-post-as-draft' }),
                icon: <Drafts />,
                disabled: !canCreateDraftForSelectedDatasources || isPlannedViaFacebook,
            },
            PUBLISH_NOW: {
                state: 'PUBLISH_NOW',
                label: intl.formatMessage({
                    id:
                        persistedPostState === 'PUBLISH_FAILED'
                            ? partiallyFailed
                                ? 'publishing.publish-failed-again'
                                : 'publishing.publish-again'
                            : 'publishing.publish-now',
                }),
                icon: <Public />,
                disabled: !canPublishForSelectedDatasources || isPlannedViaFacebook,
            },
            PUBLISH_SUCCESS: {
                state: 'PUBLISH_SUCCESS',
                label: intl.formatMessage({
                    id: isPlannedViaFacebook && fbPublishTimeInFuture ? 'publishing.post-state.PLANNED_BY_FACEBOOK' : 'publishing.post-state.PUBLISH_SUCCESS',
                }),
                icon: <Check />,
                disabled: false,
            },
        }
    }

    const options = getOptions()
    const selectedOption =
        options[isPlannedViaFacebook && newPostState === 'PUBLISH_IN_PROGRESS' ? 'PUBLISH_SUCCESS' : (newPostState as 'PLANNED' | 'DRAFT' | 'PUBLISH_NOW')] ||
        undefined

    const handleMenuItemClick = (state: string) => {
        if (state === 'PUBLISH_NOW') {
            form.change('common_post.fb_plan_via_facebook', undefined)
            form.change('postByType.FACEBOOK_PAGE.fb_plan_via_facebook', undefined)
        }

        form.change('common_post.state', state as Post['state'])
        setOpen(false)
    }

    const handleClick = () => {
        if (approvalActive && !approved && selectedOption?.state !== 'DRAFT' && !notApprovedHintOpen && !formState.invalid) {
            // If the state was set to planned or publish without approval, show confirm dialog.
            setNotApprovedHintOpen(true)
        } else if (approvalActive && !revokeApprovalHintOpen && actionWillRevokeApproval) {
            // If the action will revoke an existing approval, show confirm dialog.
            setRevokeApprovalHintOpen(true)
        } else {
            form.blur('data_source_ids')
            form.blur('common_post.post_type')
            if (formState.values.customize_posts_by_network) {
                Object.keys(formState.values.postByType).forEach((dskey) => {
                    form.blur(`postByType.${dskey}.post_type`)
                })
            }
            form.change('common_post.state', newPostState)
            form.submit()
            setRevokeApprovalHintOpen(false)
            setNotApprovedHintOpen(false)
        }
    }

    const getDisabledTooltip = () => {
        if (persistedPostState === 'PUBLISH_FAILED') {
            return ''
        }

        if (disableNewPosts && selectedOption.state === 'PLANNED') {
            return <FormattedMessage id="publishing.post-limit-reached" />
        }

        if (isAlreadyPublished) {
            return <FormattedMessage id="publishing.disabled-tooltip" />
        }

        if (selectedOption.disabled) {
            return <FormattedMessage id="publishing.disabled-tooltip-no-permission" />
        }

        if (selectedPostGroup && !hasPostGroupChanges) {
            return <FormattedMessage id="publishing.disabled-tooltip-no-changes" />
        }

        return ''
    }

    return (
        <Box width="100%">
            <ConnectedHiddenField name="common_post.state" />

            {selectedOption && (
                <Tooltip title={getDisabledTooltip()}>
                    <span>
                        <ButtonGroup
                            fullWidth={true}
                            variant="contained"
                            color="secondary"
                            disabled={isAlreadyPublished || formState.submitting}
                            ref={anchorRef}
                            aria-label="split button"
                        >
                            <Button
                                fullWidth={true}
                                type={'button'}
                                onClick={handleClick}
                                startIcon={
                                    formState.submitting || persistedPostState === 'PUBLISH_IN_PROGRESS' ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : (
                                        selectedOption.icon
                                    )
                                }
                                disabled={selectedOption.disabled || (selectedPostGroup && !hasPostGroupChanges && persistedPostState !== 'PUBLISH_FAILED')}
                            >
                                <FormattedMessage
                                    id={
                                        persistedPostState === 'PUBLISH_IN_PROGRESS' && !isPlannedViaFacebook
                                            ? 'publishing.dialog-title.PUBLISH_IN_PROGRESS'
                                            : selectedOption.label
                                    }
                                />
                                {formState.values.data_source_ids.length > 1 && (
                                    <>&nbsp; ({partiallyFailed ? failedPostCount : formState.values.data_source_ids.length})</>
                                )}
                            </Button>
                            <Button
                                id="publishing_actions_button"
                                size="small"
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select publish action"
                                aria-haspopup="menu"
                                sx={{ width: 60 }}
                                onClick={() => setOpen((prevOpen) => !prevOpen)}
                            >
                                <ArrowDropDown />
                            </Button>
                        </ButtonGroup>
                    </span>
                </Tooltip>
            )}
            <Popover
                open={open}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                disablePortal
                onClose={handleClose}
            >
                <MenuList>
                    {Object.values(options)
                        .filter((o) => o.state !== 'PUBLISH_SUCCESS')
                        .map((option) => {
                            return (
                                <MenuItem
                                    selected={option.state === selectedOption?.state}
                                    key={option.label}
                                    onClick={() => handleMenuItemClick(option.state)}
                                    disabled={option.disabled}
                                >
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                </MenuList>
            </Popover>

            <ConfirmDialog
                open={notApprovedHintOpen}
                onClose={() => setNotApprovedHintOpen(false)}
                onConfirm={handleClick}
                confirmButtonDisabled={!selectedOption}
                title="publishing.publish-no-approval"
                confirmText={selectedOption?.label || 'general.loading'}
                abortText="general.cancel"
            >
                <FormattedMessage id="publishing.publish-without-approval-hint" />
            </ConfirmDialog>

            <ConfirmDialog
                open={revokeApprovalHintOpen}
                onClose={() => setRevokeApprovalHintOpen(false)}
                onConfirm={handleClick}
                title="publishing.post-already-approved"
                confirmText="general.save"
                abortText="general.cancel"
            >
                <FormattedMessage id="publishing.save-approved-post-are-you-sure" />
            </ConfirmDialog>
        </Box>
    )
}
