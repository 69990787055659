import { Alert, AlertTitle, Box, List, Tooltip, Typography } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import { getErrorsForCommonFile } from './PublishingSlice'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { PublishingFileUploadThumbnailProps } from './PublishingFileUploadThumbnail'

type PublishingMediaErrorTooltipProps = PublishingFileUploadThumbnailProps
export const PublishingMediaErrorTooltip: FC<PropsWithChildren<PublishingMediaErrorTooltipProps>> = ({ children, file, currentKeys }) => {
    const imageErrors = useSelector(getErrorsForCommonFile)[file.id]
    const groupedByNetwork = _.mapValues(_.groupBy(imageErrors, 'network'), (clist) => clist.map((error) => _.omit(error, 'network')))
    const includesValidationErrors = imageErrors && (currentKeys !== 'ALL' ? Object.keys(groupedByNetwork).includes(currentKeys) : true)
    const includesExifErrors = file.exif_scan?.errors && file.exif_scan?.errors.length > 0

    const generateExifErrorJSX = () => {
        return (
            <Box width="fit-content">
                <Typography variant={'body2'}>
                    <FormattedMessage id={'general.exif-errors'} />
                </Typography>
            </Box>
        )
    }

    const generateErrorsJSX = (currentKeys: string) => {
        return (
            <Box width="fit-content">
                <AlertTitle>
                    <FormattedMessage id={'general.problems'} />
                </AlertTitle>
                <>
                    {_.map(groupedByNetwork, (errorsForNetwork, networkKey) => {
                        return (
                            (currentKeys === networkKey || currentKeys === 'ALL') && (
                                <Box mt={1} key={networkKey}>
                                    <FormattedMessage id={networkKey} />:
                                    <List>
                                        {errorsForNetwork.map((error) => {
                                            return (
                                                <Typography key={networkKey + error.message} variant={'body2'}>
                                                    - {error.message}
                                                </Typography>
                                            )
                                        })}
                                    </List>
                                </Box>
                            )
                        )
                    })}
                </>
            </Box>
        )
    }

    const generateEverything = () => {
        return (
            <Box p={1} display={'flex'} flexDirection={'column'} gap={1}>
                {includesValidationErrors && (
                    <Alert severity={'error'} variant={'filled'}>
                        {generateErrorsJSX(currentKeys)}
                    </Alert>
                )}
                {includesExifErrors && (
                    <Alert severity={'error'} variant={'filled'}>
                        {generateExifErrorJSX()}
                    </Alert>
                )}
            </Box>
        )
    }
    return includesValidationErrors || includesExifErrors ? (
        <Tooltip
            arrow
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: 'transparent',
                        maxWidth: 'max(30vw, 600px)',
                    },
                    '& .MuiTooltip-arrow::before': {
                        backgroundColor: 'transparent',
                    },
                },
            }}
            title={generateEverything()}
            placement={'top'}
        >
            <div>{children}</div>
        </Tooltip>
    ) : (
        <>{children}</>
    )
}
