import React, { FC } from 'react'
import { Badge, Box, Button, CircularProgress, IconButton, ListItemAvatar, ListItemText, Paper, Tooltip, Typography } from '@mui/material'
import { PostAvatar } from '../../common/avatars/PostAvatar'
import { Delete, Edit, Refresh, WarningAmber } from '@mui/icons-material'
import { ProjectDatasource } from './ProjectDatasource'
import { getIconForDatasourceType } from './DatasourceTypeMappings'
import { FormattedMessage, useIntl } from 'react-intl'
import { getDatasourceErrors } from './DatasourceError'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedProject, isProjectAdmin, showErrorSnackbarWithLink, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import _ from 'lodash'
import { HmstrDispatch } from '../../core/Store'
import { Link } from 'react-router-dom'
import { updateDatasourceInProject } from './ProjectDatasourceActions'
import { getIsUpdatingDatasource } from '../SettingsSlice'

type DatasourcePaperProps = {
    datasource: ProjectDatasource
    onDelete?: (datasourceId: string) => any
    handleEditClick: () => any
}

export const DatasourcePaper: FC<DatasourcePaperProps> = ({ datasource, onDelete, handleEditClick }) => {
    const datasourceErrors = getDatasourceErrors(datasource)
    const isUserProjectAdmin = useSelector(isProjectAdmin)
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const selectedProject = useSelector(getSelectedProject)
    const isUpdatingDatasource = useSelector(getIsUpdatingDatasource)

    const getAvatarUrlForDatasource = (datasource: ProjectDatasource) => {
        if (!datasource.metadata.profile_picture_url && datasource.type === 'FACEBOOK_PAGE') {
            return 'https://graph.facebook.com/' + datasource.metadata.id + '/picture'
        }

        return datasource.metadata.profile_picture_url
    }

    const retryAccountType = () => {
        if (selectedProject)
            dispatch(
                updateDatasourceInProject({
                    project: selectedProject,
                    datasource: datasource,
                })
            ).then((res: any) => {
                const newType = _.get(_.get(res.payload, 'metadata', {}), 'account_type')
                if (newType === 'CONTENT_CREATOR') {
                    dispatch(
                        showErrorSnackbarWithLink({
                            id: 'datasource.warnings.content-creator-account.refresh-still-content-creator',
                            link: {
                                href: 'https://www.instagram.com/accounts/professional_account_settings/',
                                text: intl.formatMessage({
                                    id: 'instagram.settings-link-title',
                                }),
                            },
                        })
                    )
                } else {
                    dispatch(showSuccessSnackbar('datasources.updated-success'))
                }
            })
    }

    return (
        <Paper variant="outlined">
            <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={88}>
                <Box display="flex" padding={2} overflow="hidden">
                    <ListItemAvatar sx={{ mr: 1 }}>
                        <Badge
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            overlap="circular"
                            badgeContent={getIconForDatasourceType(datasource.type)}
                        >
                            <PostAvatar height={48} width={48} name={datasource.name} color={datasource.color} src={getAvatarUrlForDatasource(datasource)} />
                        </Badge>
                    </ListItemAvatar>
                    <ListItemText
                        primary={datasource.name}
                        secondary={datasource.metadata.description}
                        secondaryTypographyProps={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    />
                </Box>
                <Box padding={2} display="flex" gap={1} alignItems="center">
                    {datasourceErrors.length > 0 && (
                        <Tooltip title={<FormattedMessage id="settings.datasource-errors" />}>
                            <IconButton onClick={handleEditClick}>
                                <Box
                                    sx={{
                                        cursor: 'pointer',
                                        pl: 1,
                                        pr: 1,
                                        backgroundColor: 'error.main',
                                        height: 24,
                                        borderRadius: 12,
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'white',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="subtitle2">{datasourceErrors.length}</Typography>
                                </Box>
                            </IconButton>
                        </Tooltip>
                    )}
                    {_.get(datasource.metadata, 'account_type') === 'CONTENT_CREATOR' && (
                        <Tooltip
                            title={
                                <>
                                    <Typography>
                                        <FormattedMessage
                                            id={'datasource.warnings.content-creator-account'}
                                            values={{
                                                link: (
                                                    <Link style={{ color: 'white' }} to={'https://www.instagram.com/accounts/professional_account_settings/'}>
                                                        <FormattedMessage id={'datasource.warnings.content-creator-account.instagram-settings'} />
                                                    </Link>
                                                ),
                                            }}
                                        />
                                    </Typography>
                                    <Box display={'flex'} justifyContent={'center'} py={1}>
                                        <Button
                                            color={'secondary'}
                                            variant={'contained'}
                                            startIcon={isUpdatingDatasource ? <CircularProgress size={20} /> : <Refresh />}
                                            disabled={isUpdatingDatasource}
                                            onClick={() => {
                                                retryAccountType()
                                            }}
                                        >
                                            <FormattedMessage id={'datasource.warnings.content-creator-account.refresh-btn'} />
                                        </Button>
                                    </Box>
                                </>
                            }
                        >
                            <WarningAmber color={'warning'} />
                        </Tooltip>
                    )}

                    {handleEditClick && (
                        <IconButton onClick={handleEditClick}>
                            <Edit />
                        </IconButton>
                    )}
                    {onDelete && (
                        <Tooltip title={!isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : ''}>
                            <span>
                                <IconButton disabled={!isUserProjectAdmin} onClick={() => onDelete(datasource.metadata.id)}>
                                    <Delete />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                </Box>
            </Box>
        </Paper>
    )
}
