import { Box, IconButton, lighten, TextField, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'
import { Delete, Search } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import { UserAccountAccess } from '../User'
import { changeUserSearchTerm, getUserSearchTerm } from '../../SettingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountById } from '../../../core/slices/DataSlice'

interface UserTableToolbarProps {
    numSelected: number
    accountAccess: UserAccountAccess
    onDeleteSelected: () => any
    selectedIds: string[]
}

export const UserTableToolbar = (props: UserTableToolbarProps) => {
    const intl = useIntl()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { numSelected, accountAccess, onDeleteSelected } = props
    const userSearchTerm = useSelector(getUserSearchTerm)
    const account = useSelector(getAccountById(accountAccess.account_id))

    const disableDelete = account ? props.selectedIds.includes(account.owner_id) : false

    const highlightAttributes =
        theme.palette.mode === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: 'rgb(255 105 0 / 16%)',
              }

    return (
        <Toolbar sx={{ paddingLeft: 2, paddingRight: 1, ...highlightAttributes }}>
            {numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} <FormattedMessage id="general.chosen" />
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                    <FormattedMessage id="account" />: {account?.name}
                </Typography>
            )}
            {numSelected > 0 && (
                <Tooltip title={disableDelete ? <FormattedMessage id="general.delete-disabled" /> : <FormattedMessage id="general.delete" />} placement="top">
                    <span>
                        <IconButton disabled={disableDelete} aria-label="delete" size="large" onClick={onDeleteSelected}>
                            <Delete />
                        </IconButton>
                    </span>
                </Tooltip>
            )}

            <Box marginLeft={2} marginRight={1}>
                <TextField
                    variant={'outlined'}
                    value={userSearchTerm}
                    onChange={(e) => dispatch(changeUserSearchTerm(e.target.value))}
                    sx={{ minWidth: 200 }}
                    placeholder={intl.formatMessage({ id: 'general.search' })}
                    size="small"
                    InputProps={{
                        startAdornment: <Search fontSize="small" color="action" sx={{ marginTop: '-3px', marginRight: 1 }} />,
                    }}
                />
            </Box>
        </Toolbar>
    )
}
