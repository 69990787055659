import * as _ from 'lodash'
import { isFinite } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Info, TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getIsPeriodStartDateAndEndateEqualsCompareDate } from '../DashboardsSlice'
import { DownloadGraphButton } from '../../common/download-graph-button/DownloadGraphButton'

type FacebookNumberChartProps = {
    title: string
    value: number
    compareValue?: number
    percentualChange?: number
    invert?: boolean
    neutral?: boolean
    tooltip?: JSX.Element
    noRounding?: boolean
    height?: number
    unit?: string
    format?: boolean
    commas?: number
}

export const NumberChart: FC<FacebookNumberChartProps> = (props) => {
    const { title, value, compareValue, unit, percentualChange, tooltip, neutral = false, invert = false, height = 150, noRounding = false, commas } = props
    const theme = useTheme()

    const timePeriodAndCompareEqual = useSelector(getIsPeriodStartDateAndEndateEqualsCompareDate)

    const formatNumber = (value: number) => {
        if (isNaN(value) || !isFinite(value)) {
            return <FormattedNumber value={0} maximumFractionDigits={2} />
        }

        if (value > 999999 && !noRounding) {
            return (
                <>
                    <FormattedNumber value={value / 1000000} maximumFractionDigits={2} />M
                </>
            )
        }

        if (value > 999 && !noRounding) {
            return (
                <>
                    <FormattedNumber value={value / 1000} maximumFractionDigits={2} />K
                </>
            )
        }

        return <FormattedNumber value={value} maximumFractionDigits={_.isNumber(commas) ? commas : 2} />
    }

    const getColor = (percentualChange: number) => {
        if (neutral) {
            return theme.palette.text.primary
        }

        const valueForColor = invert ? _.clone(percentualChange) * -1 : percentualChange

        if (valueForColor > 0) {
            return theme.palette.success.main
        }

        if (valueForColor < 0) {
            return theme.palette.error.main
        }

        return theme.palette.text.primary
    }
    const randomId = '_' + Math.random().toString(36).substr(2, 9)

    const [updated, setUpdated] = useState(false)
    const [initialRender, setInitialRender] = useState(true)
    useEffect(() => {
        if (initialRender) {
            setInitialRender(false)
            return
        }
        setUpdated(true)
        setTimeout(() => {
            setUpdated(false)
        }, 300)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const backgroundColor = '#ff690020'

    return (
        <Paper
            id={randomId}
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                '&::after': {
                    content: '""',
                    display: 'block',
                    background: updated ? backgroundColor : 'transparent',
                    transition: 'background 0.2s',
                    pointerEvents: 'none',
                    position: 'absolute',
                    zIndex: 0,
                    height: '100%',
                    width: '100%',
                    borderRadius: 'inherit',
                },
            }}
        >
            <Box padding={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box paddingLeft={1}>
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Typography>
                                <strong>
                                    <FormattedMessage id={title} />
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            {tooltip && (
                                <Tooltip title={tooltip}>
                                    <Box marginLeft={1} height={20}>
                                        <Info fontSize="small" />
                                    </Box>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <DownloadGraphButton tileId={randomId} parentRelativeFlex />

            <Box height={height} display="flex" justifyContent="center" alignItems="center" flexGrow={1} paddingBottom={1}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h3" component="span" noWrap={true}>
                            {formatNumber(value)} {unit}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {!_.isUndefined(percentualChange) && !timePeriodAndCompareEqual ? (
                            <Typography component="span" style={{ color: getColor(percentualChange) }}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        {percentualChange > 0 ? (
                                            <TrendingUp />
                                        ) : percentualChange === 0 || isNaN(percentualChange) ? (
                                            <TrendingFlat />
                                        ) : (
                                            <TrendingDown />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Box>
                                            {!isFinite(percentualChange) || isNaN(percentualChange) ? (
                                                <FormattedMessage id="general.nan" />
                                            ) : (
                                                <>
                                                    <FormattedNumber
                                                        value={percentualChange}
                                                        /* eslint-disable-next-line react/style-prop-object */
                                                        style="percent"
                                                        maximumFractionDigits={2}
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    </Grid>

                                    {Boolean(compareValue) && compareValue && (
                                        <Grid item>
                                            <Box>
                                                <Typography color="textSecondary">
                                                    ({formatNumber(compareValue)}
                                                    {unit && ' ' + unit})
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Typography>
                        ) : (
                            <Typography />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}
