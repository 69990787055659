import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApiError, getCurrentUser, getIsMobile, getShowInfoDialog, selectProject } from '../core/slices/CoreSlice'
import { HmstrDispatch } from '../core/Store'
import { createAccount, fetchAccount } from '../settings/accounts/AccountActions'
import { FORM_ERROR } from 'final-form'
import { fetchCurrentUser, fetchCurrentUserPermissions, fetchFederatedIdentites } from '../settings/user-management/UserActions'
import { User } from '../settings/user-management/User'
import { Alert, Box, Button, CircularProgress, Container, Link, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { IdMap } from '../core/slices/DataSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { OnboardingPackageSelection } from './OnboardingPackageSelection'
import { DatasourceManagement } from '../settings/datasources/DatasourceManagement'
import { PotentialDatasource } from '../settings/datasources/PotentialDatasource'
import { useKeycloak } from '../core/hooks/useKeycloak'
import { InfoDialog } from '../common/info-dialog/InfoDialog'
import { ErrorDialog } from '../common/error-dialog/ErrorDialog'
import { createProject } from '../settings/project-management/ProjectActions'
import { Account, BillingInfo } from '../settings/accounts/Account'
import { Project } from '../settings/project-management/Project'
import { RocketLaunch } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { HmstrAsideLayout } from '../common/hmstr-aside-layout/HmstrAsideLayout'
import { AdBlockerDialog } from '../common/adblocker-dialog/AdBlockerDialog'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { loadVidalyticsVideo } from './loadVidalyticsVideo'
import hmstrLogo from '../resources/images/hmstr-logo.png'
import hmstrOnly from '../resources/images/hmstr-only.png'
import { HmstrColors } from '../core/theme/hmstr-theme'
import { SnackbarHandler } from '../common/success-snackbar/SnackbarHandler'

type OnboardingWizardProps = {}

export const OnboardingWizardSteps = ['onboarding.wizard.package', 'onboarding.wizard.metadata', 'onboarding.wizard.datasources']

export const Onboarding: FC<OnboardingWizardProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const intl = useIntl()
    const isMobile = useSelector(getIsMobile)
    const smallDevices = useMediaQuery(theme.breakpoints.down('md'))
    const showHmstrBreakpoint = 'xl'
    const showHmstr = useMediaQuery(theme.breakpoints.down(showHmstrBreakpoint))
    const keycloak = useKeycloak()
    const infoDialogOpen = useSelector(getShowInfoDialog)
    const navigate = useNavigate()
    const apiError = useSelector(getApiError)
    const currentUser = useSelector(getCurrentUser)
    const [finalizing, setFinalizing] = useState(false)
    const [datasources, setDatasources] = useState<IdMap<ProjectDatasource>>({})
    const [showAdBlockerHint, setShowAdBlockerHint] = useState(false)
    const noChannelsPresent = Object.values(datasources).length === 0

    useEffect(() => {
        const adBlockerInactive = (window as any).adBlockerInactive

        if (!adBlockerInactive) {
            setShowAdBlockerHint(true)
        }

        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', theme.palette.background.default)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        loadVidalyticsVideo()
    }, [])

    useEffectWithIdComparison(() => {
        dispatch(fetchFederatedIdentites(currentUser))
    }, [currentUser])

    const handleDatasourceSelect = (potentialDatasource: PotentialDatasource) => {
        setDatasources({ ...datasources, [potentialDatasource.metadata.id]: potentialDatasource as ProjectDatasource })
    }

    const handleDatasourceDelete = (datasourceId: string) => {
        const newDatasources = { ...datasources }
        delete newDatasources[datasourceId]
        setDatasources(newDatasources)
    }

    const handleDatasourceEdit = (projectDatasource: ProjectDatasource, resolve: any) => {
        const newDatasources = { ...datasources }
        newDatasources[projectDatasource.metadata.id] = projectDatasource
        setDatasources(newDatasources)
        resolve()
    }

    const handleFinalize = () => {
        setFinalizing(true)
        return new Promise((resolve) => {
            const billingInfo: BillingInfo = {
                email: currentUser.email,
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                phone_number: currentUser.phone_number,
            }
            if (Object.values(datasources).length > 0) {
                dispatch(
                    createAccount({
                        name: intl.formatMessage({ id: 'onboarding.default-account-name' }),
                        billing_info: billingInfo,
                        package_type: 'trial',
                        interval: 'month',
                        additional_user: 0,
                        additional_datasource: 0,
                        conversion_tracking: 0,
                    })
                ).then((action: any) => {
                    if (action.type.includes('fulfilled')) {
                        const createdAccount = action.payload as Account
                        dispatch(
                            createProject({
                                name: intl.formatMessage({ id: 'onboarding.default-project-name' }),
                                account_id: createdAccount.id,
                                data_sources: Object.values(datasources),
                            } as Project)
                        ).then((action: any) => {
                            if (action.type.includes('fulfilled')) {
                                dispatch(fetchAccount(createdAccount._links.self))
                                dispatch(fetchCurrentUser()).then((action: any) => {
                                    const updatedUser = action.payload as User
                                    dispatch(fetchCurrentUserPermissions(updatedUser))
                                    if (updatedUser && updatedUser.accounts.length > 0 && updatedUser.accounts[0].projects.length > 0) {
                                        const projectId = updatedUser.accounts[0].projects[0].project_id
                                        dispatch(selectProject(projectId))
                                        navigate('/' + projectId)
                                        resolve(true)
                                        setFinalizing(false)
                                    } else {
                                        resolve({ [FORM_ERROR]: 'general.error' })
                                        setFinalizing(false)
                                    }
                                })
                            } else {
                                resolve({ [FORM_ERROR]: 'general.error' })
                                setFinalizing(false)
                            }
                        })
                    } else {
                        resolve({ [FORM_ERROR]: 'general.error' })
                        setFinalizing(false)
                    }
                })
            }
        })
    }

    return (
        <HmstrAsideLayout
            sideBreakpoint={showHmstrBreakpoint}
            sideContent={
                <Box>
                    <Box textAlign="center" mb={6}>
                        <img src={hmstrLogo} alt="hmstr-logo" height={32} />
                        <Typography lineHeight={1} fontSize={20} color={HmstrColors.Orange} fontStyle="italic">
                            social made easy
                        </Typography>
                    </Box>

                    <Typography color="secondary" variant="h6" fontSize={24}>
                        <FormattedMessage id="onboarding.need-help" />
                    </Typography>

                    <Typography color="white" mt={2}>
                        <FormattedMessage id="onboarding.check-video" />
                    </Typography>

                    <Box mt={2}>
                        <div id="vidalytics_embed_uQuL9npBcBk2S1TA" style={{ width: '100%', position: 'relative', paddingTop: '56.25%', zIndex: 100 }} />
                    </Box>

                    <Typography color="white" mt={2}>
                        <FormattedMessage id="onboarding.need-further-help" />{' '}
                        <FormattedMessage
                            id="onboarding.contact-us"
                            values={{
                                link: (
                                    <Link href="mailto:support@hmstr.de" sx={{ color: 'white', textDecorationColor: 'white' }} underline="always">
                                        support@hmstr.de
                                    </Link>
                                ),
                            }}
                        />
                    </Typography>
                </Box>
            }
        >
            <Container maxWidth="xl" sx={{ pb: 1 }}>
                <Box display="flex" alignItems="center" gap={2}>
                    {showHmstr && (
                        <Box>
                            <Box
                                sx={{ backgroundColor: theme.palette.primary.main }}
                                width={48}
                                height={48}
                                borderRadius={8}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img src={hmstrOnly} alt="hmstr-logo-inverted" height="32px" />
                            </Box>
                        </Box>
                    )}

                    <Box>
                        <Typography variant="h4" sx={{ mt: 2 }}>
                            <FormattedMessage id="general.welcome" values={{ name: `${currentUser.first_name} ${currentUser.last_name}` }} />
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            <FormattedMessage id="general.not-you" />
                            &nbsp;
                            <Link sx={{ cursor: 'pointer' }} onClick={() => keycloak.logout()}>
                                <FormattedMessage id="general.change-user" />
                            </Link>
                        </Typography>
                    </Box>
                </Box>

                <Box display="flex" gap={4} mt={2}>
                    <Box flexGrow={1} minWidth="70%" minHeight="100%" display="flex" flexDirection="column">
                        <Box mb={2}>
                            <Typography variant="h6">
                                <FormattedMessage id="onboarding.subtitle" />
                            </Typography>

                            <DatasourceManagement
                                selectedDatasources={Object.values(datasources)}
                                onSelect={handleDatasourceSelect}
                                onDelete={handleDatasourceDelete}
                                onEdit={handleDatasourceEdit}
                                limitExceeded={Object.values(datasources).length >= 6}
                            />
                        </Box>

                        {smallDevices && noChannelsPresent && (
                            <Box mt={2}>
                                <Alert severity={'warning'}>
                                    <FormattedMessage id="onboarding.please-add-channel-hint" />
                                </Alert>
                            </Box>
                        )}

                        <Box display="flex" justifyContent="flex-end">
                            <Tooltip title={noChannelsPresent ? <FormattedMessage id="onboarding.please-add-channel-hint" /> : ''}>
                                <Box width="fit-content">
                                    <Button
                                        sx={{ mt: 2 }}
                                        // fullWidth
                                        startIcon={finalizing ? <CircularProgress color="inherit" size={20} /> : <RocketLaunch />}
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        disabled={noChannelsPresent || finalizing}
                                        onClick={handleFinalize}
                                    >
                                        <FormattedMessage id={isMobile ? 'general.finalize-short' : 'general.finalize'} />
                                    </Button>
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>

                    {!smallDevices && (
                        <Box flexShrink={1}>
                            <OnboardingPackageSelection />
                        </Box>
                    )}
                </Box>
            </Container>

            <InfoDialog open={infoDialogOpen} />
            <ErrorDialog apiError={apiError} />
            <SnackbarHandler />

            <AdBlockerDialog open={showAdBlockerHint} onClose={() => setShowAdBlockerHint(false)} />
        </HmstrAsideLayout>
    )
}
