import * as React from 'react'
import { FC, MouseEvent, useState } from 'react'
import { TitlebarWithFilters } from '../common/filterbar/TitlebarWithFilters'
import { Box, Breadcrumbs, Button, lighten, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Fallback } from '../common/fallback/Fallback'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import {
    getConversionTrackingDailyAdInsightsLoading,
    getConversionTrackingDailyTotal,
    getConversionTrackingDataLoading,
    getConversionTrackingDatasource,
    getConversionTrackingEndDate,
    getConversionTrackingEvent,
    getConversionTrackingFilteredDayPerformance,
    getConversionTrackingStartDate,
    getFacebookAdAccountsWithConversionTracking,
    selectDatasource,
} from './ConversionTrackingSlice'
import { getSelectedAccount, getSelectedProject } from '../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { fetchFacebookConversionData, fetchFacebookDailyAdInsights } from './ConversionActions'
import moment from 'moment/moment'
import { replaceUrlParams } from '../core/helpers/replace-url-params'
import TableHead from '@mui/material/TableHead'
import { formatNumber } from '../core/theme/helper'
import { Conversion } from './Conversion'
import { ActualConversionPreview } from './ActualConversionPreview'
import { ConversionTrackingFilterDrawer } from './ConversionTrackingFilterDrawer'
import { Loading } from '../common/loading/Loading'
import { FacebookDailyInsightsWithConversions } from './FacebookDailyInsights'
import { FormattedCurrency } from '../common/formatted-currency/FormattedCurrency'
import { FormattedPercent } from '../common/formatted-percent/FormattedPercent'
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material'

type ConversionTrackingAdsProps = {}

export const ConversionTrackingDailyPerformance: FC<ConversionTrackingAdsProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const navigate = useProjectNavigate()
    const selectedDatasource = useSelector(getConversionTrackingDatasource)
    const conversionDataLoading = useSelector(getConversionTrackingDataLoading)
    const dailyAdInsightsLoading = useSelector(getConversionTrackingDailyAdInsightsLoading)
    const selectedProject = useSelector(getSelectedProject)
    const adAccounts = useSelector(getFacebookAdAccountsWithConversionTracking)
    const timePeriodStart = useSelector(getConversionTrackingStartDate)
    const timePeriodEnd = useSelector(getConversionTrackingEndDate)
    const conversionEvent = useSelector(getConversionTrackingEvent)
    const dailyData = useSelector(getConversionTrackingFilteredDayPerformance)
    const totalValues = useSelector(getConversionTrackingDailyTotal)
    const selectedAccount = useSelector(getSelectedAccount)

    const [previewConversions, setPreviewConversions] = useState<Conversion[]>([])
    const [previewOpen, setPreviewOpen] = useState(false)

    useEffectWithIdComparison(() => {
        if (selectedDatasource) {
            dispatch(fetchFacebookDailyAdInsights(selectedDatasource))
            dispatch(fetchFacebookConversionData(selectedDatasource))
        }
    }, [selectedDatasource, timePeriodStart, timePeriodEnd, conversionEvent])

    useEffectWithIdComparison(() => {
        if (selectedProject && !selectedDatasource && adAccounts.length > 0) {
            dispatch(selectDatasource(adAccounts[0].id))
        }
    }, [selectedProject])

    const handleOpenActualConversionPreview = (event: MouseEvent<any>, conversions: Conversion[]) => {
        event.preventDefault()
        event.stopPropagation()
        if (conversions.length > 0) {
            setPreviewConversions(conversions)
            setPreviewOpen(true)
        }
    }

    useEffectWithIdComparison(() => {
        if (selectedDatasource) {
            const urlParams = new URLSearchParams(window.location.search)
            urlParams.set('startDate', moment(timePeriodStart).format('YYYY-MM-DD'))
            urlParams.set('endDate', moment(timePeriodEnd).format('YYYY-MM-DD'))
            urlParams.set('datasourceId', selectedDatasource.id)
            urlParams.set('eventType', conversionEvent)
            replaceUrlParams(urlParams)
        }
    }, [selectedDatasource, timePeriodStart, timePeriodEnd, conversionEvent])

    return (
        <ContentPanel
            title={
                <TitlebarWithFilters
                    title={
                        <Breadcrumbs>
                            <Typography variant="h5">
                                <FormattedMessage id="conversion-tracking.title" />
                            </Typography>
                            <Typography variant="h5">
                                <FormattedMessage id="conversion-tracking.daily-performance" />
                            </Typography>
                        </Breadcrumbs>
                    }
                    filterBar={<ConversionTrackingFilterDrawer />}
                />
            }
            fullWidthTitle
        >
            <Fallback
                condition={adAccounts.length === 0}
                messageId="conversion-tracking.no-active-adaccounts-hint"
                marginTop={4}
                actionButton={
                    <Button variant="contained" color="secondary" onClick={() => navigate('analytics/ct-management')}>
                        <FormattedMessage id="conversion-tracking.navigate-to-management" />
                    </Button>
                }
            >
                {dailyAdInsightsLoading || conversionDataLoading ? (
                    <Loading />
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">
                                    <FormattedMessage id="conversion-tracking.head.date" />
                                </TableCell>
                                <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>
                                    <FormattedMessage id="conversion-tracking.head.spend" />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="conversion-tracking.head.impressions" />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="conversion-tracking.head.cpm" />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="conversion-tracking.head.outbound_clicks" />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="conversion-tracking.head.outbound_ctr" />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="conversion-tracking.head.landing-page-views" />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="conversion-tracking.head.landing-page-rate" />
                                </TableCell>
                                <TableCell align="right">
                                    {conversionEvent === 'offsite_conversion.fb_pixel_lead' ? (
                                        <FormattedMessage id="conversion-tracking.head.leads" />
                                    ) : (
                                        <FormattedMessage id="conversion-tracking.head.purchases" />
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    {conversionEvent === 'offsite_conversion.fb_pixel_lead' ? (
                                        <FormattedMessage id="conversion-tracking.head.cost-per-lead" />
                                    ) : (
                                        <FormattedMessage id="conversion-tracking.head.cost-per-purchase" />
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="conversion-tracking.head.matches" />
                                </TableCell>
                                {selectedAccount.beta && (
                                    <>
                                        <TableCell align="right">
                                            <FormattedMessage id="conversion-tracking.head.trial-rate" />
                                        </TableCell>
                                        <TableCell align="right">
                                            <FormattedMessage id="conversion-tracking.head.trial-started" />
                                        </TableCell>
                                        <TableCell align="right">
                                            <FormattedMessage id="conversion-tracking.head.lead-quality" />
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dailyData.length > 0
                                ? dailyData.map((dayData) => {
                                      const getStyleByMetric = (
                                          metric: keyof FacebookDailyInsightsWithConversions,
                                          invert: boolean = false,
                                          ignoreZero: boolean = false
                                      ) => {
                                          let backgroundColor: string

                                          const value = dayData[metric] as number
                                          const previousValue = dayData.previousDay[metric] as number

                                          const errorColor = lighten(theme.palette.error.light, 0.8)
                                          const successColor = lighten(theme.palette.success.light, 0.8)

                                          if (value === 0 && ignoreZero) {
                                              backgroundColor = 'inherit'
                                          } else if (value > previousValue) {
                                              backgroundColor = invert ? errorColor : successColor
                                          } else if (value < previousValue) {
                                              backgroundColor = invert ? successColor : errorColor
                                          } else {
                                              backgroundColor = 'inherit'
                                          }

                                          return { backgroundColor }
                                      }

                                      const getArrow = (metric: keyof FacebookDailyInsightsWithConversions, invert: boolean = false) => {
                                          let arrow: JSX.Element

                                          if (dayData[metric] > dayData.previousDay[metric]) {
                                              arrow = <TrendingUp fontSize="small" color={invert ? 'error' : 'success'} />
                                          } else if (dayData[metric] < dayData.previousDay[metric]) {
                                              arrow = <TrendingDown fontSize="small" color={invert ? 'success' : 'error'} />
                                          } else {
                                              arrow = <TrendingFlat fontSize="small" />
                                          }

                                          return arrow
                                      }

                                      return (
                                          <TableRow
                                              key={dayData.id}
                                              hover={dayData.actual_conversion_count > 0}
                                              sx={{ cursor: dayData.actual_conversion_count > 0 ? 'pointer' : undefined }}
                                              onClick={(event) => handleOpenActualConversionPreview(event, dayData.conversions)}
                                          >
                                              <TableCell sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                  <Box color="text.secondary" component="span">
                                                      {moment(dayData.day).format('dddd')}
                                                  </Box>
                                                  <Box>{moment(dayData.day).format('L')}</Box>
                                              </TableCell>
                                              <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>
                                                  <FormattedCurrency value={dayData.spend} />
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('impressions')}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {getArrow('impressions')}
                                                      {formatNumber(dayData.impressions)}
                                                  </Box>
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('cpm', true)}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {getArrow('cpm', true)}
                                                      <FormattedCurrency value={dayData.cpm} />
                                                  </Box>
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('outbound_clicks')}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {getArrow('outbound_clicks')}
                                                      {dayData.outbound_clicks}
                                                  </Box>
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('outbound_ctr')}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {getArrow('outbound_ctr')}
                                                      <FormattedPercent value={dayData.outbound_ctr} />
                                                  </Box>
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('landing_page_views')}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {getArrow('landing_page_views')}
                                                      {formatNumber(dayData.landing_page_views)}
                                                  </Box>
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('landing_page_conversion_rate')}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {getArrow('landing_page_conversion_rate')}
                                                      <FormattedPercent value={dayData.landing_page_conversion_rate} />
                                                  </Box>
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('fb_conversion_count')}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {getArrow('fb_conversion_count')}
                                                      {formatNumber(dayData.fb_conversion_count)}
                                                  </Box>
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('cost_per_conversion', true, true)}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {dayData.cost_per_conversion > 0 ? (
                                                          <>
                                                              {getArrow('cost_per_conversion', true)}
                                                              <FormattedCurrency value={dayData.cost_per_conversion} />
                                                          </>
                                                      ) : (
                                                          '-'
                                                      )}
                                                  </Box>
                                              </TableCell>
                                              <TableCell align="right" sx={getStyleByMetric('actual_conversion_count')}>
                                                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                      {getArrow('actual_conversion_count')}
                                                      {formatNumber(dayData.actual_conversion_count)}
                                                  </Box>
                                              </TableCell>
                                              {selectedAccount.beta && (
                                                  <>
                                                      <TableCell align="right" sx={getStyleByMetric('trial_started_rate')}>
                                                          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                              {getArrow('trial_started_rate')}
                                                              <FormattedPercent value={dayData.trial_started_rate} />
                                                          </Box>
                                                      </TableCell>
                                                      <TableCell align="right" sx={getStyleByMetric('trial_started')}>
                                                          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                              {getArrow('trial_started')}
                                                              {formatNumber(dayData.trial_started)}
                                                          </Box>
                                                      </TableCell>
                                                      <TableCell align="right" sx={getStyleByMetric('qualityScore')}>
                                                          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                              {dayData.qualityScore !== -1 ? (
                                                                  <>
                                                                      {getArrow('qualityScore')}
                                                                      {formatNumber(dayData.qualityScore)}
                                                                  </>
                                                              ) : (
                                                                  '-'
                                                              )}
                                                          </Box>
                                                      </TableCell>
                                                  </>
                                              )}
                                          </TableRow>
                                      )
                                  })
                                : null}
                            <TableRow sx={{ borderTop: 2, borderColor: 'divider' }}>
                                <TableCell colSpan={1}>
                                    <Typography fontWeight={600}>
                                        <FormattedMessage id="conversion-tracking.sum" />
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ borderRight: 1, borderColor: 'divider' }}>
                                    <FormattedCurrency value={totalValues.spend} />
                                </TableCell>
                                <TableCell align="right">{formatNumber(totalValues.impressions)}</TableCell>
                                <TableCell align="right">
                                    <FormattedCurrency value={totalValues.cpm} />
                                </TableCell>
                                <TableCell align="right">{formatNumber(totalValues.outbound_clicks)}</TableCell>
                                <TableCell align="right">
                                    <FormattedPercent value={totalValues.outbound_ctr} />
                                </TableCell>
                                <TableCell align="right">{formatNumber(totalValues.landing_page_views)}</TableCell>
                                <TableCell align="right">
                                    <FormattedPercent value={totalValues.landing_page_rate} />
                                </TableCell>
                                <TableCell align="right">{formatNumber(totalValues.conversion_count)}</TableCell>
                                <TableCell align="right">
                                    <FormattedCurrency value={totalValues.cost_per_conversion} />
                                </TableCell>
                                <TableCell align="right">{formatNumber(totalValues.matches)}</TableCell>
                                {selectedAccount.beta && (
                                    <>
                                        <TableCell align="right">
                                            <FormattedPercent value={totalValues.trial_started_rate} />
                                        </TableCell>
                                        <TableCell align="right">{formatNumber(totalValues.trial_started)}</TableCell>
                                        <TableCell align="right">{formatNumber(totalValues.quality)}</TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                )}

                <ActualConversionPreview open={previewOpen} onClose={() => setPreviewOpen(false)} conversions={previewConversions} />
            </Fallback>
        </ContentPanel>
    )
}
