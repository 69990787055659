import * as React from 'react'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { getSelectedProject, getSimpleUsersAsSuggestion, isDarkMode } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchUsersForProject } from '../../settings/user-management/UserActions'
import { addTicketNote } from '../EngagementActions'
import { DetailedTicket } from '../DetailedTicket'
import { darken, lighten, ListItemButton, useTheme } from '@mui/material'
import { FormApi } from 'final-form'
import { Mention } from 'react-mentions'
import _ from 'lodash'
import { getNodeToMention, setNodeToMention } from '../EngagementSlice'
import { NoteForm, NoteFormValues } from '../../common/note-form/NoteForm'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'

type TicketNoteCommentBarProps = {
    detailedTicket: DetailedTicket
    datasource: ProjectDatasource
}

export const TicketNoteCommentBar: FC<TicketNoteCommentBarProps> = ({ detailedTicket, datasource }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedProject = useSelector(getSelectedProject)
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)
    const highlightColor = darkmode ? darken(theme.palette.info.dark, 0.2) : lighten(theme.palette.info.light, 0.8)
    const usersAsSuggestions = useSelector(getSimpleUsersAsSuggestion)
    const nodeToMention = useSelector(getNodeToMention)

    const availableTicketNumbers = _.flattenDeep(
        _.map(detailedTicket.nodes, (node, idx) => {
            const actualIndex = detailedTicket.nodes.length - 1 - idx + 1
            const childNodes = node.children
            let totalNodeIds: any = [{ id: '#' + actualIndex.toString(), display: `${actualIndex.toString()}` }]
            if (childNodes.length > 0) {
                totalNodeIds.push(
                    childNodes.map((childNode, childIdx) => {
                        return {
                            id: `#${actualIndex}.${childIdx + 1}`,
                            display: `${actualIndex}.${childIdx + 1}`,
                        }
                    })
                )
            }
            return totalNodeIds
        })
    )

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchUsersForProject(selectedProject))
        }
    }, [selectedProject])

    const handleSubmitNote = (values: NoteFormValues, form: FormApi<NoteFormValues, Partial<NoteFormValues>>) => {
        const formattedNoteMessage = values.message.replaceAll('\n', '​') // replace line break with zero width space
        dispatch(
            addTicketNote({
                ticket: detailedTicket,
                payload: {
                    message: formattedNoteMessage,
                    data_source_id: datasource.id,
                    data_source_name: datasource.name,
                    data_source_type: datasource.type,
                },
            })
        )
        form.reset()
        dispatch(setNodeToMention(undefined))
    }

    return (
        <NoteForm
            onSubmit={handleSubmitNote}
            initialValues={{ message: !!nodeToMention ? `#{${nodeToMention}}` : '' }}
            autofocus={!!nodeToMention}
            placeholder="engagement.internal-note"
        >
            <Mention
                trigger="@"
                data={usersAsSuggestions}
                renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
                    return <ListItemButton selected={focused}>{highlightedDisplay}</ListItemButton>
                }}
                style={{
                    fontSize: 'inherit',
                    background: highlightColor,
                    borderRadius: 4,
                }}
            />
            <Mention
                trigger="#"
                data={availableTicketNumbers}
                appendSpaceOnAdd
                markup={'#{__id__}'}
                renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
                    return <ListItemButton selected={focused}>{suggestion.id}</ListItemButton>
                }}
                style={{
                    fontSize: 'inherit',
                    background: highlightColor,
                    borderRadius: 4,
                }}
            />
        </NoteForm>
    )
}
