import { FC } from 'react'
import { Avatar, AvatarProps } from '@mui/material'
import { User } from '../../settings/user-management/User'
import { KeycloakWrapper } from '../../core/keycloak/KeycloakWrapper'

type UserAvatarProps = {
    user:
        | {
              first_name: string
              last_name: string
          }
        | {
              firstName: string
              lastName: string
          }
} & AvatarProps

export const UserAvatar: FC<UserAvatarProps> = ({ user, sx, ...otherProps }) => {
    const stringToColor = (string: string) => {
        let hash = 0
        let i

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash)
        }

        let color = '#'

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff
            color += `00${value.toString(16)}`.slice(-2)
        }

        /* eslint-enable no-bitwise */
        return color
    }

    const stringAvatar = (user: UserAvatarProps['user']) => {
        const firstName = Object.keys(user).includes('first_name') ? (user as User).first_name : (user as KeycloakWrapper).firstName
        const lastName = Object.keys(user).includes('last_name') ? (user as User).last_name : (user as KeycloakWrapper).lastName

        return {
            sx: {
                bgcolor: stringToColor(`${firstName} ${lastName}`),
                ...sx,
            },
            children: `${firstName[0]?.toUpperCase()}${lastName[0]?.toUpperCase()}`,
        }
    }

    return <Avatar {...otherProps} {...stringAvatar(user)} />
}
