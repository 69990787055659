import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
    getIsLoadingDashboards,
    getRunningExports,
    getSortDirection,
    getSortedDashboards,
    getSortField,
    selectDashboard,
    setDashboardWizardStep,
    setSortDirection,
    setSortField,
} from './DashboardsSlice'
import {
    getCurrentUser,
    getIsMobile,
    getSelectedProject,
    getSelectedProjectAccess,
    isDarkMode,
    isProjectAdmin,
    openFullscreenDialog,
    setAppThemeColor,
    showErrorSnackbar,
    showSuccessSnackbar,
} from '../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { deleteDashboard, fetchDashboardsForProject, fetchTemplates, generateDashboardPdf, generateDashboardPptx } from './DashboardsActions'
import { getTemplates } from '../core/slices/DataSlice'
import { Add, BackupTable, Dashboard as DashboardIcon, Delete, Edit } from '@mui/icons-material'
import { ConfirmDialog } from '../common/confirm-dialog/ConfirmDialog'
import { Dashboard } from './Dashboard'
import SortableTableHead from '../common/sorted-paginated-table/SortableTableHead'
import { DashboardSortType } from '../common/filter/DashboardSortType'
import moment from 'moment'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { HmstrColors } from '../core/theme/hmstr-theme'
import { updateUserSettings } from '../settings/user-management/UserActions'
import { HmstrDispatch } from '../core/Store'
import { generateEffectiveTimePeriodForDashboard } from './generateEffectiveTimePeriodForDashboard'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { Fallback } from '../common/fallback/Fallback'
import { NoDashboards } from './NoDashboards'
import { Loading } from '../common/loading/Loading'
import _ from 'lodash'
import { MobileDashboardCard } from './MobileDashboardCard'
import { FilterDrawer, FilterSetting } from '../common/filterbar/FilterDrawer'
import { TitlebarWithFilters } from '../common/filterbar/TitlebarWithFilters'
import { SearchChip } from '../common/filterbar/SearchChip'
import {
    getTemplateTypeFocused,
    getTemplateTypeFocusedFromHighlight,
    setTemplateTypeFocused,
    setTemplateTypeFocusedFromHighlight,
} from '../common/filterbar/FilterSlice'

type DashboardsProps = {}

export const Dashboards: FC<DashboardsProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const intl = useIntl()
    const darkMode = useSelector(isDarkMode)
    const theme = useTheme()

    const isMobile = useSelector(getIsMobile)

    const selectedProject = useSelector(getSelectedProject)
    const isUserProjectAdmin = useSelector(isProjectAdmin)
    const dashboards = useSelector(getSortedDashboards)
    const isLoadingDashboards = useSelector(getIsLoadingDashboards)
    const runningExports = useSelector(getRunningExports)
    const [filteredDashboards, setFilteredDashboards] = useState(Object.values(dashboards))
    const templates = useSelector(getTemplates)

    const sortField = useSelector(getSortField)
    const sortDirection = useSelector(getSortDirection)
    const currentUser = useSelector(getCurrentUser)
    const currentUserProjectAccess = useSelector(getSelectedProjectAccess)

    const [modalIsOpen, setModalOpen] = useState(false)
    const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | undefined>(undefined)
    const [searchQuery, setSearchQuery] = useState('')
    const [templateType, setTemplateType] = useState<string | undefined>(undefined)
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
    const searchBarRef = useRef<any>(null)
    const templateTypeRef = useRef<any>(null)
    const templateTypeFocused = useSelector(getTemplateTypeFocused)
    const templateTypeFocusedFromHighlight = useSelector(getTemplateTypeFocusedFromHighlight)

    useEffect(() => {
        dispatch(setAppThemeColor(theme.palette.background.default))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [darkMode])
    const handleOpenDownloadDialog = (dashboard: Dashboard) => {
        setSelectedDashboard(dashboard)
        setOpenDownloadDialog(true)
    }

    const handleCloseDownloadDialog = () => {
        setOpenDownloadDialog(false)
        setSelectedDashboard(undefined)
    }

    useEffect(() => {
        filterDashboards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, dashboards])

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTemplates(selectedProject))
            dispatch(fetchDashboardsForProject(selectedProject))
        }
    }, [selectedProject])

    const handleEditDashboard = (event: any, dashboard: Dashboard) => {
        event.stopPropagation()
        dispatch(selectDashboard(dashboard))
        dispatch(setDashboardWizardStep(2))
        dispatch(openFullscreenDialog('edit-dashboard'))
    }

    const handleConfirmClose = () => {
        setModalOpen(false)
    }

    const handleConfirm = () => {
        if (selectedDashboard) {
            dispatch(deleteDashboard(selectedDashboard))
            dispatch(showSuccessSnackbar('reporting.dashboards.delete-success'))
        }
        setModalOpen(false)
    }

    const handleSelectDashboard = (dashboard: Dashboard) => {
        dispatch(selectDashboard(dashboard))
        navigate(`/analytics/dashboards/${dashboard.id}`)
    }

    const handleNewClick = () => {
        dispatch(setDashboardWizardStep(0))
        dispatch(openFullscreenDialog('new-dashboard'))
    }

    const downloadPdf = () => {
        if (selectedDashboard) {
            const effectiveTimePeriod = generateEffectiveTimePeriodForDashboard(selectedDashboard)

            dispatch(
                generateDashboardPdf({
                    dashboard: selectedDashboard,
                    exportParams: {
                        fileType: 'pdf',
                        startDate: moment(effectiveTimePeriod.startDate),
                        endDate: moment(effectiveTimePeriod.endDate),
                        compareStartDate: moment(effectiveTimePeriod.compareStartDate),
                        compareEndDate: moment(effectiveTimePeriod.compareEndDate),
                        attribution_windows: selectedDashboard.settings.attribution_windows?.join(','),
                        without_consent_loss: selectedDashboard.settings.conversions_with_consent_loss,
                        selected_datasources: selectedDashboard.data_source_ids?.join(','),
                        selected_tag_ids: selectedDashboard.settings.tag_ids?.join(','),
                    },
                })
            ).then((action: any) => {
                if (!action.payload.downloaded) {
                    if (action.payload.statusCode === 403) {
                        dispatch(showErrorSnackbar('reporting.dashboards.access-denied-short'))
                    } else {
                        dispatch(showErrorSnackbar('general.error-occured'))
                    }
                }
            })
        }

        handleCloseDownloadDialog()
    }
    const downloadPptx = () => {
        if (selectedDashboard) {
            const effectiveTimePeriod = generateEffectiveTimePeriodForDashboard(selectedDashboard)

            dispatch(
                generateDashboardPptx({
                    dashboard: selectedDashboard,
                    exportParams: {
                        fileType: 'pptx',
                        startDate: moment(effectiveTimePeriod.startDate),
                        endDate: moment(effectiveTimePeriod.endDate),
                        compareStartDate: moment(effectiveTimePeriod.compareStartDate),
                        compareEndDate: moment(effectiveTimePeriod.compareEndDate),
                        attribution_windows: selectedDashboard.settings.attribution_windows?.join(','),
                        without_consent_loss: selectedDashboard.settings.conversions_with_consent_loss,
                        selected_datasources: selectedDashboard.data_source_ids?.join(','),
                        selected_tag_ids: selectedDashboard.settings.tag_ids?.join(','),
                    },
                })
            ).then((action: any) => {
                if (!action.payload.downloaded) {
                    if (action.payload.statusCode === 403) {
                        dispatch(showErrorSnackbar('reporting.dashboards.access-denied-short'))
                    } else {
                        dispatch(showErrorSnackbar('general.error-occured'))
                    }
                }
            })
        }

        handleCloseDownloadDialog()
    }

    const handleOpenDashboardInTab = (dashboard: Dashboard) => {
        window.open(
            window.location.href.split('/')[0] + `/portal/${dashboard.project_id}/analytics/dashboards/${dashboard.id}`,
            '_blank',
            'noopener,noreferrer'
        )
    }

    const tableHead = (
        <SortableTableHead
            onRequestSort={(event: React.MouseEvent<unknown>, property: string) => {
                if (sortField !== (property as DashboardSortType)) {
                    dispatch(setSortField(property as DashboardSortType))
                    dispatch(setSortDirection('desc'))
                } else {
                    dispatch(setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc'))
                }
            }}
            order={sortDirection}
            orderBy={sortField}
            headCells={[
                {
                    id: DashboardSortType.NAME,
                    label: intl.formatMessage({ id: 'reporting.dashboards.name-of-dashboard' }),
                },
                {
                    id: DashboardSortType.TEMPLATE_TYPE,
                    label: intl.formatMessage({ id: 'reporting.dashboards.template-type' }),
                },
                {
                    id: DashboardSortType.CREATION_DATE,
                    label: intl.formatMessage({ id: 'reporting.dashboards.creation-date' }),
                },
                {
                    id: DashboardSortType.DATE_OF_CHANGE,
                    label: intl.formatMessage({ id: 'reporting.dashboards.date-of-change' }),
                },
                {
                    id: DashboardSortType.AUTHOR,
                    label: intl.formatMessage({ id: 'reporting.dashboards.author' }),
                },
                {
                    id: 'ACTIONS',
                    label: intl.formatMessage({ id: 'reporting.dashboards.actions' }),
                    sortDisabled: true,
                },
            ]}
        />
    )

    const generateDownloadDialog = () => {
        return (
            <Dialog
                open={openDownloadDialog}
                onClose={handleCloseDownloadDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id={'download-dialog.title'} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id={'download-dialog.description.with-name'} values={{ dashboardName: selectedDashboard?.name }} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Tooltip title={selectedDashboard?.name + '.pptx'}>
                        <Button
                            onClick={downloadPptx}
                            disabled={!!runningExports.find((e) => e.dashboardId === selectedDashboard?.id && e.type === 'pptx')}
                            variant={'contained'}
                            color={'secondary'}
                        >
                            .pptx
                        </Button>
                    </Tooltip>
                    <Tooltip title={selectedDashboard?.name + '.pdf'}>
                        <Button
                            onClick={downloadPdf}
                            disabled={!!runningExports.find((e) => e.dashboardId === selectedDashboard?.id && e.type === 'pdf')}
                            variant={'contained'}
                            color={'secondary'}
                        >
                            .pdf
                        </Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        )
    }

    const updateDashboardFavorites = (dashboard: Dashboard) => {
        if (!currentUser.settings.favorite_dashboard_ids.includes(dashboard.id)) {
            let newFavorites = [...currentUser.settings.favorite_dashboard_ids, dashboard.id]
            dispatch(
                updateUserSettings({
                    settings: { ...currentUser.settings, favorite_dashboard_ids: newFavorites },
                    user: currentUser,
                })
            )
        } else {
            let newFavorites = currentUser.settings.favorite_dashboard_ids.filter((dashboardId) => dashboardId !== dashboard.id)
            dispatch(
                updateUserSettings({
                    settings: { ...currentUser.settings, favorite_dashboard_ids: newFavorites },
                    user: currentUser,
                })
            )
        }
    }

    const cellActions = (dashboard: Dashboard) => {
        const hasAccessToDashboard =
            isUserProjectAdmin ||
            _.difference(
                dashboard.data_source_ids,
                _.flatMap(currentUserProjectAccess?.access, (dsAccess) => dsAccess.data_source_id)
            ).length === 0
        return (
            <TableCell>
                <Tooltip
                    title={!isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : <FormattedMessage id="general.edit" />}
                    disableInteractive
                >
                    <span>
                        <IconButton
                            disabled={!isUserProjectAdmin}
                            color="inherit"
                            aria-label="menu"
                            onClick={(event) => handleEditDashboard(event, dashboard)}
                            sx={{ mr: 1 }}
                        >
                            <Edit color={!isUserProjectAdmin ? undefined : 'action'} />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="general.add-to-favorites" />} disableInteractive>
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            updateDashboardFavorites(dashboard)
                        }}
                        sx={{ mr: 1 }}
                    >
                        {currentUser.settings.favorite_dashboard_ids.includes(dashboard.id) ? (
                            <StarIcon color={'action'} />
                        ) : (
                            <StarBorderIcon color={'action'} />
                        )}
                    </IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="general.open-in-new-tab" />} disableInteractive>
                    <IconButton color="inherit" aria-label="menu" onClick={() => handleOpenDashboardInTab(dashboard)} sx={{ mr: 1 }}>
                        <OpenInNewIcon color={'action'} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="download-dialog.title" />} disableInteractive>
                    <IconButton
                        color="inherit"
                        disabled={!hasAccessToDashboard}
                        aria-label="menu"
                        onClick={() => handleOpenDownloadDialog(dashboard)}
                        sx={{ mr: 1 }}
                    >
                        <FileDownloadIcon color={!hasAccessToDashboard ? undefined : 'action'} />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={!isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : <FormattedMessage id="general.delete" />}
                    disableInteractive
                >
                    <span>
                        <IconButton
                            disabled={!isUserProjectAdmin}
                            color="inherit"
                            aria-label="menu"
                            onClick={(event) => {
                                event.stopPropagation()
                                setModalOpen(true)
                                setSelectedDashboard(dashboard)
                            }}
                        >
                            <Delete color={!isUserProjectAdmin ? undefined : 'action'} />
                        </IconButton>
                    </span>
                </Tooltip>
            </TableCell>
        )
    }

    const tableBody = (
        <TableBody>
            {filteredDashboards
                .filter((dashboard) => templateType === undefined || dashboard.template_id === templateType)
                .map((dashboard, idx) => (
                    <TableRow key={dashboard.id} selected={selectedDashboard?.id === dashboard.id}>
                        <TableCell
                            id={idx === 0 ? 'guide-dashboard-name-link' : ''}
                            onClick={() => handleSelectDashboard(dashboard)}
                            sx={{
                                cursor: 'pointer',
                                ':hover': { color: HmstrColors.Orange, textDecoration: 'underline' },
                            }}
                        >
                            {dashboard.name}
                        </TableCell>
                        <TableCell>{templates[dashboard.template_id]?.display_name}</TableCell>
                        <TableCell>{moment(dashboard.created_at).format('DD.MM.YYYY')}</TableCell>
                        <TableCell>{moment(dashboard.updated_at).format('DD.MM.YYYY')}</TableCell>
                        <TableCell>{dashboard.created_by}</TableCell>

                        {cellActions(dashboard)}
                    </TableRow>
                ))}
            {generateDownloadDialog()}
        </TableBody>
    )

    const filterDashboards = () => {
        if (searchQuery.trim().length > 0) {
            setFilteredDashboards(
                Object.values(dashboards).filter(
                    (dashboard) =>
                        dashboard.name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase()) ||
                        templates[dashboard.template_id].name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())
                )
            )
        } else {
            setFilteredDashboards(Object.values(dashboards))
        }
    }

    const getTemplateSelectionField = () => {
        return (
            <FormControl
                sx={{
                    margin: 0,
                    paddingY: '8px',
                    height: '100%',
                    minWidth: '100%',
                }}
            >
                <InputLabel id={'template-select-label'} style={{ left: '14px', paddingTop: '2px' }}>
                    <FormattedMessage id={'reporting.dashboards.template-type'} />
                </InputLabel>
                <Select
                    inputRef={templateTypeRef}
                    labelId="template-select-label"
                    label={intl.formatMessage({ id: 'reporting.dashboards.template-type' })}
                    variant="outlined"
                    open={templateTypeFocused || templateTypeFocusedFromHighlight}
                    size="small"
                    fullWidth
                    MenuProps={{ disablePortal: true }}
                    onClick={() => {
                        if (templateTypeFocused || templateTypeFocusedFromHighlight) {
                            dispatch(setTemplateTypeFocused(false))
                            dispatch(setTemplateTypeFocusedFromHighlight(false))
                        } else {
                            dispatch(setTemplateTypeFocused(true))
                        }
                    }}
                    style={{ marginRight: '8px' }}
                    onChange={(e) => {
                        setTemplateType(e.target.value !== 'all' ? e.target.value : undefined)
                    }}
                    value={templateType || 'all'}
                >
                    <MenuItem value={'all'} key={'all'}>
                        <FormattedMessage id="reporting.dashboards.all-template-types" />
                    </MenuItem>
                    {Object.values(templates).map((template) => {
                        return (
                            <MenuItem value={template.id} key={template.id}>
                                {template.display_name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }

    const getSearchBarFilter = () => {
        const onDeleteFunction = () => {
            setSearchQuery('')
        }

        const onClickFunction = () => {
            if (searchBarRef.current) {
                setTimeout(() => {
                    searchBarRef.current.focus()
                }, 300)
            }
        }
        const set: FilterSetting = {
            onlyChip: true,
            chipElement: <SearchChip onChange={(value) => setSearchQuery(value)} value={searchQuery} searchbarTitle={'reporting.dashboards.search'} />,
            tooltip: 'reporting.dashboards.search',
            onDeleteFunction: onDeleteFunction,
            deletable: true,
            hasChangedFromDefault: searchQuery.length > 0,
            important: true,
            onClickFunction,
        }
        return set
    }

    const getTemplateFilterSetting = () => {
        const hasChangedFromDefault = !!templateType

        const onDeleteFunction = () => {
            setTemplateType(undefined)
        }
        const onClickFunction = () => {
            if (templateTypeRef.current) {
                setTimeout(() => {
                    templateTypeRef.current.focus()
                    dispatch(setTemplateTypeFocusedFromHighlight(true))
                }, 300)
            }
        }

        const renderSettingString = () => {
            const selectedTemplate = Object.values(templates).find((template) => template.id === templateType)
            return selectedTemplate?.display_name || intl.formatMessage({ id: 'reporting.dashboards.all-template-types' })
        }

        const set: FilterSetting = {
            settingInput: getTemplateSelectionField(),
            tooltip: 'reporting.dashboards.template-type',
            deletable: true,
            important: true,
            onDeleteFunction: onDeleteFunction,
            hasChangedFromDefault: hasChangedFromDefault,
            currentValueAsString: renderSettingString(),
            icon: <BackupTable fontSize={'small'} />,
            onClickFunction,
        }
        return set
    }

    return (
        <ContentPanel
            toolbar={
                isMobile ? (
                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <FilterDrawer
                            filters={[getSearchBarFilter(), getTemplateFilterSetting()]}
                            previousAppThemeColor={theme.palette.background.default}
                        ></FilterDrawer>
                    </Box>
                ) : undefined
            }
            disableToolbarToggle={true}
            toolbarInSameLine={true}
            fullWidthTitle
            maxWidth={false}
            title={
                <>
                    {isMobile && (
                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant={'h5'} display="flex" alignItems="center">
                                <DashboardIcon sx={{ mr: 1 }} fontSize="inherit" />
                                <FormattedMessage id="reporting.dashboards.title" />
                            </Typography>
                        </Box>
                    )}
                    {!isMobile && (
                        <TitlebarWithFilters
                            title={
                                <Typography variant={'h5'} display="flex" alignItems="center">
                                    <DashboardIcon sx={{ mr: 1 }} fontSize="inherit" />
                                    <FormattedMessage id="reporting.dashboards.title" />
                                </Typography>
                            }
                            filterBar={<FilterDrawer filters={[getSearchBarFilter(), getTemplateFilterSetting()]}></FilterDrawer>}
                            filterBarInSameLine
                            disableXPadding
                            actionButton={
                                <Tooltip title={!isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : ''}>
                                    <span>
                                        <Button
                                            color={'secondary'}
                                            variant={'contained'}
                                            startIcon={<Add />}
                                            onClick={handleNewClick}
                                            fullWidth
                                            disabled={!isUserProjectAdmin}
                                        >
                                            <FormattedMessage id="reporting.dashboards.new" />
                                        </Button>
                                    </span>
                                </Tooltip>
                            }
                        />
                    )}
                </>
            }
        >
            <Fallback condition={isLoadingDashboards} actionButton={<Loading />}>
                <Box sx={{ pb: 2 }}>
                    <Fallback condition={dashboards.length === 0} actionButton={<NoDashboards />}>
                        <>
                            {!isMobile && (
                                <Paper sx={{ overflowX: 'auto' }}>
                                    <Table size="small">
                                        {tableHead}
                                        {tableBody}
                                    </Table>
                                </Paper>
                            )}
                            {isMobile && (
                                <Box display={'flex'} flexDirection={'column'} gap={2}>
                                    {filteredDashboards
                                        .filter((dashboard) => templateType === undefined || dashboard.template_id === templateType)
                                        .map((dashboard) => (
                                            <MobileDashboardCard
                                                key={dashboard.id}
                                                onClick={() => handleSelectDashboard(dashboard)}
                                                dashboard={dashboard}
                                                template={templates[dashboard.template_id]}
                                            />
                                        ))}
                                </Box>
                            )}
                        </>
                    </Fallback>
                </Box>
            </Fallback>
            {selectedDashboard && (
                <ConfirmDialog open={modalIsOpen} onClose={handleConfirmClose} onConfirm={handleConfirm} confirmText="general.delete">
                    <FormattedMessage id="reporting.dashboards.delete-confirm" values={{ dashboardName: selectedDashboard.name }} />
                </ConfirmDialog>
            )}
        </ContentPanel>
    )
}
