import Keycloak, { KeycloakLogoutOptions } from 'keycloak-js'
import { ClientConfig } from '../config/config'
import { messages } from '../init/init-localization-context'

export class KeycloakWrapper {
    private readonly keycloak: Keycloak
    private readonly clientConfig: ClientConfig

    public id: string
    public firstName: string
    public lastName: string
    public locale: string
    public email: string

    public inviteCode: string | undefined

    public hasFacebook: boolean
    public hasLinkedIn: boolean
    public hasTiktokBusiness: boolean
    public hasTiktokBasic: boolean

    constructor(keycloakInstance: Keycloak, clientConfig: ClientConfig) {
        this.keycloak = keycloakInstance
        this.clientConfig = clientConfig

        const getDefaultLanguage = () => {
            const availableLanguages = Object.keys(messages)
            const browserLanguage = navigator.language?.split('-')[0]

            if (availableLanguages.includes(browserLanguage)) {
                return browserLanguage
            }

            return 'de'
        }

        const tokenParsed = keycloakInstance.tokenParsed as any
        this.id = tokenParsed['sub']
        this.firstName = tokenParsed['given_name']
        this.lastName = tokenParsed['family_name']
        this.locale = tokenParsed['locale'] || getDefaultLanguage()
        this.hasFacebook = tokenParsed['facebook'] || false
        this.hasLinkedIn = tokenParsed['linkedin'] || false
        this.hasTiktokBusiness = tokenParsed['tiktok_business'] || false
        this.hasTiktokBasic = tokenParsed['tiktok_basic'] || false
        this.email = tokenParsed['email']
        this.inviteCode = tokenParsed['invite_code'] || undefined
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`
    }

    logout(options?: KeycloakLogoutOptions) {
        return this.keycloak.logout(options)
    }

    accountManagement() {
        return this.keycloak.accountManagement()
    }

    createLogoutUrl(options?: KeycloakLogoutOptions) {
        return this.keycloak.createLogoutUrl(options)
    }

    createAccountUrl() {
        return this.keycloak.createAccountUrl()
    }

    hasResourceRole(role: string, resource: string) {
        return this.keycloak.hasResourceRole(role, resource)
    }

    getInstance() {
        return this.keycloak
    }

    getClientConfig() {
        return this.clientConfig
    }

    hasFacebookIdp() {
        return this.hasFacebook && this.hasResourceRole('read-token', 'broker')
    }

    hasLinkedInIdp() {
        return this.hasLinkedIn && this.hasResourceRole('read-token', 'broker')
    }

    hasTiktokBusinessIdp() {
        return this.hasTiktokBusiness && this.hasResourceRole('read-token', 'broker')
    }

    hasTiktokBasicIdp() {
        return this.hasTiktokBasic && this.hasResourceRole('read-token', 'broker')
    }

    getAccessToken() {
        return this.keycloak.token
    }

    getIdToken() {
        return this.keycloak.idToken
    }

    getRefreshToken() {
        return this.keycloak.refreshToken
    }
}
