import * as React from 'react'
import { FC } from 'react'
import { useImage } from '../core/hooks/useImage'
import { Skeleton } from '@mui/material'
import { PublishingMedia } from './PublishingMedia'

type PublishingPreviewImageGridItemProps = {
    media: PublishingMedia
    width: string | number
    height?: string | number
    visible?: boolean
    story?: boolean
    mockupDevice?: 'desktop' | 'mobile'
    onLoad?: (objectUrl: string | undefined) => void
}

export const PublishingPreviewImageGridItem: FC<PublishingPreviewImageGridItemProps> = ({ media, width, height, visible, story, mockupDevice, onLoad }) => {
    const { objectUrl } = useImage(media._links.file.href, true, undefined, onLoad)

    const getRenderedHeight = () => {
        const ratio = (mockupDevice === 'mobile' ? 350 : 418) / media.width
        return ratio * media.height
    }

    return objectUrl ? (
        story ? (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    objectFit: 'contain',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        height: '100%',
                        width: '200%',
                        left: '-50%',
                        display: 'flex',
                        objectFit: 'contain',
                        alignItems: 'center',
                        backgroundImage: `url(${objectUrl})`,
                        backgroundPosition: 'center',
                        backgroundSize: '100% 50%',
                        position: 'absolute',
                        top: 0,
                        zIndex: 0,
                        filter: 'blur(100px) brightness(0.9)',
                    }}
                ></div>
                <img
                    src={objectUrl}
                    alt={media.name}
                    width={width}
                    height={mockupDevice ? getRenderedHeight() : height}
                    style={
                        story
                            ? { zIndex: 1 }
                            : {
                                  objectFit: 'cover',
                                  display: visible === undefined || visible ? 'block' : 'none',
                              }
                    }
                    loading="lazy"
                />
            </div>
        ) : (
            <img
                src={objectUrl}
                alt={media.name}
                width={width}
                height={mockupDevice ? getRenderedHeight() : height}
                style={
                    story
                        ? undefined
                        : {
                              objectFit: 'cover',
                              display: visible === undefined || visible ? 'block' : 'none',
                          }
                }
                loading="lazy"
            />
        )
    ) : story ? (
        <Skeleton
            variant="rectangular"
            width={'100%'}
            height={'100%'}
            animation="wave"
            sx={{ transform: 'none', display: visible === true || visible === undefined ? 'block' : 'none' }}
        />
    ) : (
        <Skeleton
            variant="rectangular"
            width={450}
            height={mockupDevice ? getRenderedHeight() : height}
            animation="wave"
            sx={{
                transform: 'none',
                display: visible === true || visible === undefined ? 'block' : 'none',
                maxWidth: '100%',
            }}
        />
    )
}
