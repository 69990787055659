import { FC, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { BillingSubscription } from './BillingSubscription'
import { Account } from '../accounts/Account'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { getPaymentMethods } from '../../core/slices/DataSlice'
import { BillingPaymentMethodItem } from './BillingPaymentMethodItem'
import { PaymentMethod } from '@stripe/stripe-js'
import { Check, CreditCard } from '@mui/icons-material'

type BillingUpdatePaymentMethodDialogProps = {
    open: boolean
    subscription: BillingSubscription
    account?: Account
    onSave: (paymentMethodId: string) => any
    onClose: () => any
}

export const BillingUpdatePaymentMethodDialog: FC<BillingUpdatePaymentMethodDialogProps> = (props) => {
    const { open, subscription, account, onClose, onSave } = props
    const paymentMethods = useSelector(getPaymentMethods)
    const selectedPaymentMethod: undefined | PaymentMethod = paymentMethods[subscription.default_payment_method || '']
    const [newPaymentMethodId, setNewPaymentMethodId] = useState<undefined | string>()

    const handlePaymentMethodSelect = (paymentMethod: PaymentMethod) => {
        setNewPaymentMethodId(paymentMethod.id)
    }

    return (
        <Dialog open={open} keepMounted={false} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage id="billing.payment-methods.update-dialog.title" values={{ name: account?.name }} />
            </DialogTitle>
            <DialogContent>
                <List>
                    {Object.values(paymentMethods).map((pm) => {
                        const selected = newPaymentMethodId !== undefined ? newPaymentMethodId === pm.id : selectedPaymentMethod?.id === pm.id

                        return (
                            <BillingPaymentMethodItem key={pm.id} paymentMethod={pm} onSelect={() => handlePaymentMethodSelect(pm)} selected={selected}>
                                {selected ? (
                                    <Box flexGrow={1} textAlign="right">
                                        <Check />
                                    </Box>
                                ) : null}
                            </BillingPaymentMethodItem>
                        )
                    })}

                    <ListItemButton
                        selected={newPaymentMethodId === undefined ? !Boolean(selectedPaymentMethod) : newPaymentMethodId === ''}
                        onClick={() => setNewPaymentMethodId('')}
                    >
                        <ListItemIcon>
                            <CreditCard />
                        </ListItemIcon>
                        <ListItemText
                            primary={<FormattedMessage id="billing.payment-methods.automatic" />}
                            secondary={<FormattedMessage id="billing.payment-methods.automatic.info" />}
                        />
                        {(newPaymentMethodId === undefined ? !Boolean(selectedPaymentMethod) : newPaymentMethodId === '') ? (
                            <Box flexGrow={1} textAlign="right">
                                <Check />
                            </Box>
                        ) : null}
                    </ListItemButton>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage id="general.close" />
                </Button>
                <Button color="secondary" variant="contained" disabled={newPaymentMethodId === undefined} onClick={() => onSave(newPaymentMethodId as string)}>
                    <FormattedMessage id="general.save" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
