import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../core/Store'
import { Result } from './Result'
import { doGet } from '../core/api/ApiClient'
import moment from 'moment'
import { Project } from '../settings/project-management/Project'

const RESULTS_SLASH = (action: string) => `results/${action}`

export const fetchResultsForProject = createAsyncThunk<Result[], Project, { state: HmstrState }>(
    RESULTS_SLASH('fetchResultsForProject'),
    async (project, thunkAPI) => {
        const state = thunkAPI.getState()
        return await doGet(thunkAPI, project._links.talkwalker, undefined, {
            params: {
                since: state.results.selectedTimePeriodStartDate,
                until: moment(state.results.selectedTimePeriodEndDate).add(1, 'day').format(),
            },
        })
    }
)
