import { FC, useEffect, useState } from 'react'
import { ConnectedDropdown } from '../../form/ConnectedDropdown'
import { MenuItem } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm } from 'react-final-form'
import { deniedDatasourceAndTypeCombinations, validatePostType } from '../FormValidators'
import { ValidDatasourceTypeForPublishing } from '../PublishingForm'

type PublishingPostTypeSelectProps = {
    name: string
    disabled?: boolean
    datasourceKeys?: string[]
    datasourceKeyOverride?: ValidDatasourceTypeForPublishing
}

export const PublishingPostTypeSelect: FC<PublishingPostTypeSelectProps> = ({ name, disabled, datasourceKeys, datasourceKeyOverride }) => {
    const form = useForm()
    const intl = useIntl()
    const singleDatasourceKey = datasourceKeyOverride
        ? datasourceKeyOverride
        : datasourceKeys?.length === 1
        ? (datasourceKeys[0] as PublishingPostTypeSelectProps['datasourceKeyOverride'])
        : undefined
    const disableFields = singleDatasourceKey ? deniedDatasourceAndTypeCombinations[singleDatasourceKey] || [] : []
    const [updatedKey, setUpdatedKey] = useState(name + Date.now())
    const prefix = name.replace('post_type', '')
    useEffect(() => {
        setUpdatedKey(name + datasourceKeys?.toString())
    }, [datasourceKeys, name])

    const removeFieldIfExists = (name: string) => {
        if (form.getRegisteredFields().includes(prefix + name)) {
            form.change(prefix + name, undefined)
            form.resetFieldState(prefix + name)
        }
    }

    const handlePostTypeChange = (newPostType: string) => {
        if (['LINK', 'TEXT'].includes(newPostType)) {
            removeFieldIfExists('file_ids')
        }

        if (newPostType !== 'LINK') {
            removeFieldIfExists('link')
            removeFieldIfExists('cta_type')
        }
    }

    return (
        <ConnectedDropdown
            name={name}
            label={intl.formatMessage({ id: 'publishing.post-type' })}
            customKey={updatedKey}
            required={true}
            fullWidth={true}
            onChange={(newType) => handlePostTypeChange(newType)}
            disabled={disabled}
            validate={(value: string, allValues: any, meta: any) => {
                if (!value) {
                    return 'validations.required'
                } else {
                    return validatePostType(value, allValues, meta, intl, form, datasourceKeys)
                }
            }}
        >
            <MenuItem value="TEXT" disabled={disableFields.includes('TEXT')}>
                <FormattedMessage id="publishing.type.TEXT" />
            </MenuItem>
            <MenuItem value="LINK" disabled={disableFields.includes('LINK')}>
                <FormattedMessage id="publishing.type.LINK" />
            </MenuItem>
            <MenuItem value="IMAGE" disabled={disableFields.includes('IMAGE')}>
                <FormattedMessage id="publishing.type.IMAGE" />
            </MenuItem>
            <MenuItem value="VIDEO" disabled={disableFields.includes('VIDEO')}>
                <FormattedMessage id="publishing.type.VIDEO" />
            </MenuItem>
            <MenuItem value="MULTI_MEDIA" disabled={disableFields.includes('MULTI_MEDIA')}>
                <FormattedMessage id="publishing.type.MULTI_MEDIA" />
            </MenuItem>
        </ConnectedDropdown>
    )
}
