import { textColorBasedOnBackground } from '../../core/theme/helper'
import { Chip } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import * as React from 'react'
import { FC } from 'react'
import { Tag } from '../../tags/Tag'

type TagAutocompleteProps = {
    tag: Tag
    additionalProps: Object
    onClick?: any
    size?: 'small' | 'medium'
    disabled?: boolean
}

export const TagChip: FC<TagAutocompleteProps> = ({ tag, additionalProps, onClick, size, disabled }) => {
    let baseColor = tag.color
    let textColor = textColorBasedOnBackground(baseColor)
    return (
        <Chip
            variant="outlined"
            style={{
                backgroundColor: baseColor,
                border: `solid 1px ${baseColor}`,
                borderColor: baseColor,
                color: textColor,
                opacity: tag.active ? '1.0' : '0.4',
                cursor: 'pointer',
            }}
            size={size}
            onClick={onClick}
            id={tag.id}
            label={tag.label}
            deleteIcon={!disabled ? <CancelIcon style={{ color: textColor }} /> : undefined}
            {...additionalProps}
        />
    )
}
