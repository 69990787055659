import { FC, useState } from 'react'
import { Alert, Box, Card, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { deDE, Importer, ImporterField } from '@hmstr/react-csv-importer'
import './upload.css'
import { Close, PermContactCalendar, ShoppingCart } from '@mui/icons-material'
import { Conversion } from './Conversion'
import { useSelector } from 'react-redux'
import { getFacebookAdAccountForUpload } from './ConversionTrackingSlice'
import { getSelectedAccount, getSelectedProject } from '../core/slices/CoreSlice'
import axios from 'axios'
import moment from 'moment'

type ConversionTrackingUploadDialogProps = {
    open: boolean
    onClose: () => any
}

export const ConversionTrackingUploadDialog: FC<ConversionTrackingUploadDialogProps> = (props) => {
    const intl = useIntl()
    const { open, onClose } = props
    const selectedAccount = useSelector(getSelectedAccount)
    const adAccount = useSelector(getFacebookAdAccountForUpload)
    const project = useSelector(getSelectedProject)
    const [eventType, setEventType] = useState<'lead' | 'purchase'>()

    function parseValue(row: Conversion) {
        if (row.value.includes(',')) {
            return parseFloat(row.value.replace('.', '').replace(',', '.'))
        }

        return parseFloat(row.value)
    }

    const handleClose = () => {
        onClose()
        setTimeout(() => {
            setEventType(undefined)
        }, 300)
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg">
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <span>
                            <FormattedMessage id="conversion-tracking.upload-conversions" />{' '}
                            {eventType && (
                                <Typography component="span" color="text.secondary">
                                    (<FormattedMessage id={'conversion-tracking.event.' + eventType} />)
                                </Typography>
                            )}
                        </span>
                    </Box>

                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                {!eventType && (
                    <Box minHeight={250}>
                        <Typography mb={2}>
                            <FormattedMessage id="conversion-tracking.which-conversion-type" />
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Card
                                    onClick={() => setEventType('purchase')}
                                    variant="outlined"
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);`,
                                        },
                                    }}
                                >
                                    <Box textAlign="center" p={4}>
                                        <ShoppingCart color="primary" sx={{ fontSize: 64 }} />
                                        <Typography variant="h6">
                                            <FormattedMessage id="conversion-tracking.event.purchase" />
                                        </Typography>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card
                                    onClick={() => setEventType('lead')}
                                    variant="outlined"
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);`,
                                        },
                                    }}
                                >
                                    <Box textAlign="center" p={4}>
                                        <PermContactCalendar color="primary" sx={{ fontSize: 64 }} />
                                        <Typography variant="h6">
                                            <FormattedMessage id="conversion-tracking.event.leads" />
                                        </Typography>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>

                        <Alert severity="info" sx={{ mt: 4 }}>
                            <FormattedMessage id="conversion-tracking.type-hint" />
                        </Alert>
                    </Box>
                )}
                {eventType && (
                    <Importer<Conversion>
                        locale={deDE}
                        assumeNoHeaders={false}
                        restartable={false}
                        onStart={({ file, preview, fields, columnFields }) => {
                            // optional, invoked when user has mapped columns and started import
                        }}
                        processChunk={async (rows, { startIndex }) => {
                            // required, may be called several times
                            // receives a list of parsed objects based on defined fields and user column mapping;
                            // (if this callback returns a promise, the widget will wait for it before parsing more data)
                            const actualRows = rows
                                .filter((row) => {
                                    if (!Boolean(row.identifier)) {
                                        return false
                                    }

                                    const valuePresent = eventType === 'purchase' ? Boolean(parseValue(row)) : true
                                    const allValuesPresent = valuePresent && Boolean(row.timestamp)
                                    return allValuesPresent && moment(row.timestamp).isValid()
                                })
                                .map((row) => ({
                                    ...row,
                                    ad_id: Boolean(row.ad_id) ? row.ad_id : undefined,
                                    timestamp: moment(row.timestamp).format(),
                                    value: eventType === 'purchase' ? parseValue(row) : 0,
                                }))
                            console.log(`${actualRows.length} from ${rows.length} were parsable.`)

                            if (adAccount && project) {
                                await axios.post(adAccount._links.conversions.href, {
                                    project_id: project.id,
                                    data_source_id: adAccount.id,
                                    conversions: actualRows,
                                })
                            }
                        }}
                        onComplete={({ file, preview, fields, columnFields }) => {
                            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                            // showMyAppToastNotification();
                        }}
                        onClose={() => onClose()}
                        skipEmptyLines={true}
                    >
                        <ImporterField name="identifier" label={intl.formatMessage({ id: 'conversion-tracking.field.identifier' })} />
                        <ImporterField name="timestamp" label={intl.formatMessage({ id: 'conversion-tracking.field.timestamp' })} />
                        {selectedAccount.beta && <ImporterField name="ad_id" label={intl.formatMessage({ id: 'conversion-tracking.field.ad_id' })} />}
                        {eventType === 'purchase' && <ImporterField name="value" label={intl.formatMessage({ id: 'conversion-tracking.field.value' })} />}
                    </Importer>
                )}
            </DialogContent>
        </Dialog>
    )
}
