import { FC } from 'react'
import { Box, Container } from '@mui/material'
import { DashboardForm } from './DashboardForm'
import { Dashboard } from './Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { closeFullscreenDialog, getSelectedProject } from '../core/slices/CoreSlice'
import { createDashboard } from './DashboardsActions'
import { FORM_ERROR } from 'final-form'
import { HmstrDispatch } from '../core/Store'

type DashboardNewProps = {}

export const DashboardNew: FC<DashboardNewProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const project = useSelector(getSelectedProject)

    const handleSubmit = (dashboard: Dashboard, closeDialogAfterSave: boolean = true) => {
        const dashboardRequest = {
            ...dashboard,
            data_source_ids: dashboard.data_source_ids.filter((id) => Boolean(id)),
        } as Dashboard

        if (dashboardRequest.data_source_ids.length <= 0) {
            return {
                [FORM_ERROR]: 'reporting.dashboards.no-datasources-selected',
            }
        }

        if (project) {
            dispatch(createDashboard({ project, dashboard: dashboardRequest }))
            if (closeDialogAfterSave) {
                dispatch(closeFullscreenDialog())
            }
        }
    }

    return (
        <Container maxWidth="lg">
            <Box paddingTop={2} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {project && (
                    <DashboardForm
                        project={project}
                        onSubmit={handleSubmit}
                        initialValues={{
                            project_id: project.id,
                            data_source_ids: [],
                        }}
                    />
                )}
            </Box>
        </Container>
    )
}
