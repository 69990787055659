import * as React from 'react'
import { FC, useState } from 'react'
import { Button, Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import {
    getDatasourceIdsForSelectedPostGroup,
    getRelevantStateForSelectedPostGroup,
    getSelectedPostGroup,
    getSelectedPostGroupFailedPosts,
    isApprovalProcessActive,
    resetPostForm,
    selectPostGroup,
} from './PublishingSlice'
import { FormattedMessage } from 'react-intl'
import { DeleteForever } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmDialog } from '../common/confirm-dialog/ConfirmDialog'
import { HmstrDispatch } from '../core/Store'
import { showErrorSnackbar, showSuccessSnackbar } from '../core/slices/CoreSlice'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { PublishingFormSubmit } from './PublishingFormSubmit'
import { PublishingFormApproval } from './approval/PublishingFormApproval'
import { deletePostGroup } from './post-groups/PostGroupActions'
import { useHasDatasourcePermission } from '../core/hooks/useHasPermission'
import { useForm } from 'react-final-form'
import _ from 'lodash'

type PublishingFormActionsProps = {
    formDisabled?: boolean
}

export const PublishingFormActions: FC<PublishingFormActionsProps> = ({ formDisabled }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const theme = useTheme()

    const smallScreen = useMediaQuery(theme.breakpoints.down('xl'))
    const backToList = window.location.pathname.includes('/publishing/list')
    const approvalActive = useSelector(isApprovalProcessActive)
    const postGroup = useSelector(getSelectedPostGroup)
    const relevantPostState = useSelector(getRelevantStateForSelectedPostGroup)
    const failedPosts = useSelector(getSelectedPostGroupFailedPosts)
    const partiallyFailed = relevantPostState === 'PUBLISH_FAILED' && failedPosts.length < (postGroup?.posts.length || 0)
    const showDeleteButton = postGroup && !['PUBLISH_SUCCESS', 'PUBLISH_IN_PROGRESS'].includes(relevantPostState) && !partiallyFailed
    const [areYouSureDelete, setAreYouSureDelete] = useState(false)
    const datasourceIdsForPostGroup = useSelector(getDatasourceIdsForSelectedPostGroup)
    const canDelete = useHasDatasourcePermission('post.delete', datasourceIdsForPostGroup)
    const form = useForm()

    const handleDelete = () => {
        if (postGroup) {
            if (areYouSureDelete) {
                dispatch(resetPostForm())
                form.reset()
                dispatch(deletePostGroup(postGroup)).then((action) => {
                    if (!(_.get(action, 'payload.status') === 403)) {
                        dispatch(showSuccessSnackbar('publishing.delete-success'))
                    } else {
                        dispatch(showErrorSnackbar('publishing.disabled-tooltip-no-permission'))
                    }
                    navigate(backToList ? 'publishing/list' : 'publishing/calendar')
                })
                dispatch(selectPostGroup(undefined))
                setAreYouSureDelete(false)
            } else {
                setAreYouSureDelete(true)
            }
        }
    }

    return (
        <>
            <Grid container spacing={1} pr={2}>
                <Grid item xs={12} xl={'auto'} order={smallScreen ? 4 : 1}>
                    {showDeleteButton && (
                        <Tooltip title={!canDelete ? <FormattedMessage id="general.no-permission" /> : ''}>
                            <span>
                                <Button fullWidth variant="contained" color="error" startIcon={<DeleteForever />} onClick={handleDelete} disabled={!canDelete}>
                                    <FormattedMessage id="general.delete" />
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </Grid>

                {!smallScreen && <Grid item flexGrow={1} order={2} />}

                <Grid item xs={12} xl={'auto'} order={3}>
                    {approvalActive && postGroup && relevantPostState !== 'PUBLISH_SUCCESS' && relevantPostState !== 'PUBLISH_IN_PROGRESS' && (
                        <PublishingFormApproval postGroup={postGroup} />
                    )}
                </Grid>
                <Grid item xs={12} xl={'auto'} order={smallScreen ? 1 : 4}>
                    {!formDisabled && <PublishingFormSubmit partiallyFailed={partiallyFailed} failedPostCount={failedPosts.length} />}
                </Grid>
            </Grid>

            <ConfirmDialog
                open={areYouSureDelete}
                onClose={() => setAreYouSureDelete(false)}
                onConfirm={handleDelete}
                title="publishing.delete-post-title"
                confirmText="general.delete"
            >
                <FormattedMessage id="publishing.delete-post-are-you-sure" />
            </ConfirmDialog>
        </>
    )
}
