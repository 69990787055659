import * as _ from 'lodash'
import {
    Box,
    Checkbox,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
    useTheme,
} from '@mui/material'
import { AdminPanelSettings, CheckCircle, Delete, Edit, Mail, RadioButtonUnchecked } from '@mui/icons-material'
import { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Dot } from '../../../common/dot/Dot'
import { openEditInvite, openUserSettings } from '../../SettingsSlice'
import { UserAccountAccess } from '../User'
import { getComparator, Order, stableSort } from './Order'
import { UserTableEntry } from './UserTableEntry'
import { UserTableToolbar } from './UserTableToolbar'
import { deleteAccountAccess, deleteInvite, inviteUserToAccount } from '../UserActions'
import { ConfirmDialog } from '../../../common/confirm-dialog/ConfirmDialog'
import { Invite } from '../Invite'
import { getCurrentUser, openFullscreenDialog, showSuccessSnackbar } from '../../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../../core/Store'
import { getAccountById } from '../../../core/slices/DataSlice'
import { useKeycloak } from '../../../core/hooks/useKeycloak'

type UserTableProps = {
    account: UserAccountAccess
    users: UserTableEntry[]
}

const headCells: {
    property?: keyof UserTableEntry
    label: string
    align?: 'center' | 'right' | 'left'
    noSort?: boolean
}[] = [
    { property: 'first_name', label: 'general.firstName' },
    { property: 'last_name', label: 'general.lastName' },
    { property: 'email', label: 'general.email' },
    { property: 'is_admin', label: 'users.role', align: 'center', noSort: true },
    { property: 'status', label: 'users.status' },
    { label: 'users.action', align: 'right' },
]

export const UserTable: FC<UserTableProps> = ({ users, account }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const keycloak = useKeycloak()
    const currentUser = useSelector(getCurrentUser)
    const selectedAccount = useSelector(getAccountById(account.account_id))

    const [isResending, setIsResending] = useState(false)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<keyof UserTableEntry>('email')
    const [selected, setSelected] = useState<string[]>([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [deleteSelected, setDeleteSelected] = useState(false)
    const [inviteToDelete, setInviteToDelete] = useState<undefined | Invite>(undefined)
    const [accountAccessToDelete, setAccountAccessToDelete] = useState<undefined | UserAccountAccess>(undefined)
    const [userIdToDeleteAccountAccessFrom, setUserIdToDeleteAccountAccessFrom] = useState<undefined | string>(undefined)
    const isOwner = account.role === 'OWNER'

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage)
    const selectedCount = selected.length
    const userCount = users.length

    const handleRequestSort = (property: keyof UserTableEntry) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = users.map((u) => u.id)
            setSelected(newSelecteds)
            return
        }

        setSelected([])
    }

    const handleClick = (event: MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected: string[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    }

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleEditClick = (event: MouseEvent<HTMLButtonElement>, user: UserTableEntry) => {
        event.stopPropagation()

        if (user.status === 'active') {
            dispatch(openUserSettings(user.id))
            dispatch(openFullscreenDialog('edit-user'))
        } else {
            dispatch(openEditInvite(user.id))
            dispatch(openFullscreenDialog('edit-invite'))
        }
    }

    const handleResendClick = (event: MouseEvent<HTMLButtonElement>, userTableEntry: UserTableEntry) => {
        event.stopPropagation()

        if (userTableEntry.invite && selectedAccount) {
            setIsResending(true)
            dispatch(
                inviteUserToAccount({
                    account: selectedAccount,
                    userFormValues: {
                        email: userTableEntry.invite.email,
                        language: userTableEntry.invite.language || keycloak.locale,
                        account_id: selectedAccount.id,
                        account_name: selectedAccount.name,
                        external: userTableEntry.invite.account.external,
                        account_role: userTableEntry.invite.account.role,
                        projects: userTableEntry.invite.account.projects,
                    },
                })
            ).then(() => {
                setIsResending(false)
                dispatch(showSuccessSnackbar('users.invite.created'))
            })
        }
    }

    const handleDeleteClick = (event: MouseEvent<HTMLButtonElement>, userTableEntry: UserTableEntry) => {
        event.stopPropagation()

        if (userTableEntry.invite) {
            setShowConfirmDialog(true)
            setInviteToDelete(userTableEntry.invite)
        } else if (userTableEntry.user_access) {
            setShowConfirmDialog(true)
            setAccountAccessToDelete(userTableEntry.user_access)
            setUserIdToDeleteAccountAccessFrom(userTableEntry.id)
        }
    }

    const handleConfirmDelete = () => {
        if (inviteToDelete && showConfirmDialog) {
            dispatch(deleteInvite(inviteToDelete))
            dispatch(showSuccessSnackbar('accounts.invite-deleted'))
        } else if (accountAccessToDelete && userIdToDeleteAccountAccessFrom && showConfirmDialog) {
            dispatch(
                deleteAccountAccess({
                    access: accountAccessToDelete,
                    user_id: userIdToDeleteAccountAccessFrom,
                })
            )
            dispatch(showSuccessSnackbar('accounts.access-deleted'))
        } else if (deleteSelected && showConfirmDialog) {
            const userEntries: UserTableEntry[] = _.filter(users, (u) => selected.includes(u.id))

            userEntries.forEach((entry) => {
                if (entry.invite) {
                    dispatch(deleteInvite(entry.invite))
                } else if (entry.user_access) {
                    dispatch(
                        deleteAccountAccess({
                            access: entry.user_access,
                            user_id: entry.id,
                        })
                    )
                }
            })

            setSelected([])
            dispatch(showSuccessSnackbar('accounts.user-and-invites-deleted'))
        }

        setDeleteSelected(false)
        setShowConfirmDialog(false)
        setUserIdToDeleteAccountAccessFrom(undefined)
        setAccountAccessToDelete(undefined)
        setInviteToDelete(undefined)
    }

    const handleDeleteSelected = () => {
        if (!showConfirmDialog) {
            setShowConfirmDialog(true)
            setDeleteSelected(true)
        }
    }

    return (
        <Paper elevation={2}>
            <UserTableToolbar numSelected={selected.length} selectedIds={selected} accountAccess={account} onDeleteSelected={handleDeleteSelected} />
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="secondary"
                                    indeterminate={selectedCount > 0 && selectedCount < userCount}
                                    checked={userCount > 0 && selectedCount === userCount}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            {headCells.map((headCell) => (
                                <TableCell key={headCell.label} sx={{ textAlign: headCell.align }}>
                                    {headCell.property && !headCell.noSort ? (
                                        <TableSortLabel
                                            active={orderBy === headCell.property}
                                            direction={orderBy === headCell.property ? order : 'asc'}
                                            onClick={() => handleRequestSort(headCell.property as any)}
                                        >
                                            <FormattedMessage id={headCell.label} />
                                        </TableSortLabel>
                                    ) : (
                                        <FormattedMessage id={headCell.label} />
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort<UserTableEntry>(users, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((user) => {
                                const isItemSelected = selected.indexOf(user.id) !== -1
                                const canEdit = isOwner || (!isOwner && !user.is_owner)

                                return (
                                    <TableRow
                                        key={user.id}
                                        hover
                                        onClick={(event) => handleClick(event, user.id)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox color="secondary" checked={isItemSelected} />
                                        </TableCell>
                                        <TableCell>{user.first_name}</TableCell>
                                        <TableCell>{user.last_name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell align="center">
                                            {user.is_owner ? (
                                                <Tooltip title={<FormattedMessage id="accounts.role.OWNER" />}>
                                                    <AdminPanelSettings fontSize="small" color="action" />
                                                </Tooltip>
                                            ) : user.is_admin ? (
                                                <Tooltip title={<FormattedMessage id="accounts.role.ADMIN" />}>
                                                    <CheckCircle fontSize="small" color="action" />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={<FormattedMessage id="accounts.role.USER" />}>
                                                    <RadioButtonUnchecked fontSize="small" color="action" />
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <Dot color={user.status === 'active' ? theme.palette.success.main : theme.palette.warning.main} />
                                                &nbsp;&nbsp;
                                                <FormattedMessage id={`users.status.${user.status}`} />
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Grid container alignItems="center" justifyContent="flex-end" spacing={1} wrap="nowrap">
                                                {user.status === 'pending' && (
                                                    <Grid item>
                                                        <Tooltip placement="top" title={<FormattedMessage id="users.reinvite" />}>
                                                            <span>
                                                                <IconButton size="small" disabled={isResending} onClick={(e) => handleResendClick(e, user)}>
                                                                    <Mail fontSize="small" />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Tooltip
                                                        placement="top"
                                                        title={
                                                            currentUser.id === user.id ? (
                                                                <FormattedMessage id="accounts.no-self-edit" />
                                                            ) : !canEdit ? (
                                                                <FormattedMessage id="accounts.no-higher-role-edit" />
                                                            ) : (
                                                                <FormattedMessage id="general.edit" />
                                                            )
                                                        }
                                                    >
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                disabled={currentUser.id === user.id || !canEdit}
                                                                onClick={(e) => handleEditClick(e, user)}
                                                            >
                                                                <Edit fontSize="small" />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip
                                                        placement="top"
                                                        title={
                                                            currentUser.id === user.id ? (
                                                                <FormattedMessage id="accounts.no-self-delete" />
                                                            ) : !canEdit ? (
                                                                <FormattedMessage id="accounts.no-higher-role-delete" />
                                                            ) : (
                                                                <FormattedMessage id="general.delete" />
                                                            )
                                                        }
                                                        disableInteractive
                                                    >
                                                        <span>
                                                            <IconButton
                                                                disabled={currentUser.id === user.id || !canEdit}
                                                                size="small"
                                                                onClick={(e) => handleDeleteClick(e, user)}
                                                            >
                                                                <Delete fontSize="small" />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={7} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={<FormattedMessage id="general.rowsPerPage" />}
                labelDisplayedRows={(args) => <FormattedMessage id="general.displayedRows" values={args as any} />}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={userCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <ConfirmDialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={handleConfirmDelete}
                confirmText="general.delete"
                confirmIcon={<Delete />}
            >
                {inviteToDelete ? (
                    <FormattedMessage id="accounts.delete-invite.are-you-sure" />
                ) : deleteSelected ? (
                    <FormattedMessage id="accounts.user-and-invites-deleted.are-you-sure" />
                ) : (
                    <FormattedMessage id="accounts.delete-user-access.are-you-sure" />
                )}
            </ConfirmDialog>
        </Paper>
    )
}
