import { ProjectDatasourceType } from './ProjectDatasource'
import { InstagramIcon } from '../../common/icons/InstagramIcon'
import { LinkedInIcon } from '../../common/icons/LinkedInIcon'
import { TiktokIcon } from '../../common/icons/TiktokIcon'
import { FacebookIcon } from '../../common/icons/FacebookIcon'
import { Euro, Facebook, Instagram, Language, LinkedIn, QuestionMark } from '@mui/icons-material'
import React from 'react'
import { FederatedIdentity } from '../linked-accounts/FederatedIdentity'
import { XIcon } from '../../common/icons/XIcon'

export const getIconComponentForDatasourceType = (type: ProjectDatasourceType | undefined, simpleIcon: boolean = false) => {
    switch (type) {
        case 'INSTAGRAM_ACCOUNT':
            return simpleIcon ? Instagram : InstagramIcon
        case 'LINKED_IN':
        case 'LINKED_IN_PROFILE':
            return simpleIcon ? LinkedIn : LinkedInIcon
        case 'TIKTOK_ADVERTISER':
        case 'TIKTOK_ACCOUNT':
            return TiktokIcon
        case 'FACEBOOK_PAGE':
            return simpleIcon ? Facebook : FacebookIcon
        case 'FACEBOOK_AD_ACCOUNT':
            return Euro
        case 'TALKWALKER_JSON':
            return Language
        case 'TWITTER':
            return XIcon
        default:
            return QuestionMark
    }
}

export const getIconForDatasourceType = (type: ProjectDatasourceType | undefined, size: 'small' | 'medium' | 'large' = 'medium'): JSX.Element => {
    const IconComponent = getIconComponentForDatasourceType(type)

    switch (type) {
        case 'TALKWALKER_JSON':
            return <IconComponent color="secondary" fontSize={size} />
        default:
            return <IconComponent fontSize={size} />
    }
}

export const getLabelForDatasourceType = (type: ProjectDatasourceType, plural: boolean = false): string => {
    let labelId = type

    if (labelId === 'TIKTOK_ADVERTISER') {
        labelId = 'TIKTOK_ACCOUNT'
    }

    if (plural) {
        labelId += 'S'
    }

    return labelId
}

export const getFederatedTypeForDatasourceType = (type: ProjectDatasourceType): FederatedIdentity['type'] => {
    switch (type) {
        case 'LINKED_IN':
        case 'LINKED_IN_PROFILE':
            return 'LINKED_IN'
        case 'TIKTOK_ADVERTISER':
        case 'TIKTOK_ACCOUNT':
            return 'TIKTOK'
        case 'TWITTER':
            return 'TWITTER'
        case 'FACEBOOK_PAGE':
        case 'FACEBOOK_AD_ACCOUNT':
        case 'INSTAGRAM_ACCOUNT':
        default:
            return 'META'
    }
}
