import * as React from 'react'
import { FC } from 'react'
import { Box, Button, Tooltip, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import moment, { Moment } from 'moment/moment'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getSelectedCalendarTimePeriod } from '../PublishingSlice'
import { HotkeyTooltip } from '../../common/hotkeys/HotkeyTooltip'
import { isDarkMode } from '../../core/slices/CoreSlice'
import { useHotkeys } from 'react-hotkeys-hook'

type CalendarDatePickerButtonsProps = {
    selectedStartDate: string
    onChange: (date: Moment | null) => any
}

export const CalendarDatePickerButtons: FC<CalendarDatePickerButtonsProps> = ({ selectedStartDate, onChange }) => {
    const calendarView = useSelector(getSelectedCalendarTimePeriod)
    const darkmode = useSelector(isDarkMode)
    const theme = useTheme()

    const handleChange = (value: Moment | null) => {
        if (value === null) {
            return null
        }

        onChange(value)
    }

    useHotkeys('left', () => handleChange(moment(selectedStartDate).subtract(1, calendarView)))

    useHotkeys('right', () => handleChange(moment(selectedStartDate).add(1, calendarView)))

    useHotkeys('t', () => handleChange(moment()))

    const renderBackButton = () => {
        return (
            <Tooltip
                title={
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <FormattedMessage id={`general.back`} />
                        <HotkeyTooltip keys={['arrowLeft']} invert fontSize={14} />
                    </Box>
                }
                disableInteractive
                placement={'left'}
            >
                <Button
                    onClick={() => handleChange(moment(selectedStartDate).subtract(1, calendarView))}
                    color={'inherit'}
                    size={'small'}
                    variant={darkmode ? 'outlined' : 'contained'}
                    disableElevation
                    sx={{ minWidth: 0, borderColor: darkmode ? theme.palette.divider : undefined }}
                >
                    <KeyboardArrowLeft />
                </Button>
            </Tooltip>
        )
    }

    const renderTodayButton = () => {
        return (
            <Tooltip
                disableInteractive
                placement={'left'}
                title={
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <FormattedMessage id={'publishing.jump-to-today'} />
                        <HotkeyTooltip keys={['T']} invert fontSize={14} />
                    </Box>
                }
            >
                <Button
                    onClick={() => handleChange(moment())}
                    color={'inherit'}
                    variant={darkmode ? 'outlined' : 'contained'}
                    disableElevation
                    size={'small'}
                    sx={{ borderColor: darkmode ? theme.palette.divider : undefined }}
                >
                    <FormattedMessage id={'publishing.jump-to-today'} />
                </Button>
            </Tooltip>
        )
    }

    const renderForwardButton = () => {
        return (
            <Tooltip
                disableInteractive
                placement={'left'}
                title={
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <FormattedMessage id={`general.next`} />
                        <HotkeyTooltip keys={['arrowRight']} invert fontSize={14} />
                    </Box>
                }
            >
                <Button
                    onClick={() => handleChange(moment(selectedStartDate).add(1, calendarView))}
                    color={'inherit'}
                    size={'small'}
                    variant={darkmode ? 'outlined' : 'contained'}
                    disableElevation
                    sx={{ minWidth: 0, borderColor: darkmode ? theme.palette.divider : undefined }}
                >
                    <KeyboardArrowRight />
                </Button>
            </Tooltip>
        )
    }
    return (
        <Box display={'flex'} gap={1}>
            {renderTodayButton()}
            {renderBackButton()}
            {renderForwardButton()}
        </Box>
    )
}
