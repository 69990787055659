import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type FacebookLikeProps = SvgIconProps

export const FacebookLike: FC<FacebookLikeProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" id="Ebene_1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                        style={{ fill: 'none', stroke: props.htmlColor }}
                        d="M2,17V9c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v8c0,0.6-0.4,1-1,1H3C2.4,18,2,17.6,2,17z"
                    />
                    <path
                        style={{ fill: 'none', stroke: props.htmlColor }}
                        d="M7,10l0.9-0.9C8.6,8,9.1,6.8,9.4,5.6c0.2-0.7,0.2-3,0.4-3.1c1-0.6,1.6-0.6,2.3,0
		c0.1,0.1,1.1,1.1,1.3,1.9c0.1,0.5,0.3,1.8,0.3,2.9l3.6,0c0.2,0,0.3,0,0.5,0.1c0.6,0.4,0.9,0.9,0.9,1.4c0,0.6-0.1,1.1-0.2,1.6
		c0.3,0.4,0.5,0.8,0.5,1.4c0,0.5-0.3,1-0.9,1.5c0.1,0.6,0,1-0.1,1.5s-0.5,0.8-1.1,1.1c0,0.4-0.1,0.7-0.1,1c-0.1,0.2-0.3,0.6-0.7,1
		c-0.2,0.2-0.4,0.3-0.6,0.3H8.6c-0.3,0-0.6-0.2-0.8-0.4L7,16.5V10z"
                    />
                    <rect x="0.5" y="0.5" style={{ fill: 'none', stroke: props.htmlColor, strokeOpacity: 0 }} width="19" height="19" />
                </g>
            </svg>
        </SvgIcon>
    )
}
