import * as React from 'react'
import { FC } from 'react'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { PostPreviewVideo } from './PostPreviewVideo'
import { PostPreviewImageGrid } from './PostPreviewImageGrid'
import { LinkPreview } from '../../common/link-preview/LinkPreview'
import { Post } from '../posts/Post'
import { useSelector } from 'react-redux'
import { getMedia } from '../../core/slices/DataSlice'
import { getErrorsForCommonFile } from '../PublishingSlice'
import { getDatasourcesAsIdMap } from '../../core/slices/CoreSlice'

type CommonPostPreviewProps = {
    post: Post
}

export const CommonPostPreview: FC<CommonPostPreviewProps> = ({ post }) => {
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const selectedDatasource = datasourcesById[post.data_source_id]
    const postType = post.post_type
    const media = useSelector(getMedia)
    const files = (post.file_ids || []).map((file_id) => media[file_id]).filter((m) => !!m)
    const thumbnailFile = post.link_preview_file_id ? media[post.link_preview_file_id] : undefined
    const errorsForFiles = useSelector(getErrorsForCommonFile)
    const actualFiles = files.filter(
        (f) =>
            f.category === postType &&
            !errorsForFiles[f.id]
                ?.map((error) => {
                    if (selectedDatasource) {
                        return error.network === selectedDatasource.type
                    } else {
                        return false
                    }
                })
                .includes(true)
    )

    return (
        <Grid container spacing={1.5} alignItems="center">
            <Grid item>
                <Skeleton animation={false} variant="rectangular" width={40} height={40} sx={{ borderRadius: 1 }} />
            </Grid>
            <Grid item xs={6} sx={{ lineHeight: 1 }}>
                <Skeleton animation={false} height={30} />
            </Grid>

            {postType === 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12}>
                    <PostPreviewVideo variant="STANDARD" file={actualFiles[0]} title={post.video_title} />
                </Grid>
            )}
            <Grid item xs={12}>
                <Box minHeight={50}>
                    <Typography sx={{ wordWrap: 'break-word', color: 'inherit' }}>
                        {post.text ? (
                            post.text
                        ) : (
                            <>
                                <Skeleton animation={false} />
                                <Skeleton animation={false} />
                            </>
                        )}
                    </Typography>
                </Box>
            </Grid>

            {postType !== 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12}>
                    <PostPreviewImageGrid uploadFiles={actualFiles} />
                </Grid>
            )}

            {post.link && (
                <Grid item xs={12}>
                    <LinkPreview
                        url={post.link}
                        ctaType={post.cta_type}
                        title={post.link_preview_title}
                        description={post.link_preview_description}
                        thumbnail={thumbnailFile}
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <Typography>
                    <Skeleton animation={false} />
                </Typography>
            </Grid>
        </Grid>
    )
}
