import { Drawer, styled } from '@mui/material'
import { HmstrState } from '../core/Store'
import { primaryNavigationWidth } from './PrimaryNavigation'

export const drawerWidth = 220 + primaryNavigationWidth

export const StyledDrawer = styled(Drawer, {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'drawerState',
})<{ drawerState: HmstrState['core']['drawerState'] }>(({ theme, drawerState }) => ({
    width: drawerState === 'expanded' ? drawerWidth : 0,
    transition: 'width .2s ease-in-out',
    flexShrink: 0,

    '& .MuiPaper-root': {
        width: drawerState === 'expanded' ? drawerWidth : 0,
        marginLeft: primaryNavigationWidth,
        transition: 'width .2s ease-in-out',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        '& li': {
            whiteSpace: 'break-spaces',
            minWidth: drawerWidth,
        },
    },
}))
