import React, { FC, useState } from 'react'
import { Box, Dialog, Grid, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ProjectDatasource, ProjectDatasourceType } from './ProjectDatasource'
import { FormattedMessage } from 'react-intl'
import { linkAccount } from '../linked-accounts/AccountLinkService'
import { fetchFederatedIdentites, fetchPotentialDatasources } from '../user-management/UserActions'
import { getCurrentUser, getIsMobile, showErrorSnackbar, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { getFederatedTypeForDatasourceType, getIconForDatasourceType, getLabelForDatasourceType } from './DatasourceTypeMappings'
import { useDispatch, useSelector } from 'react-redux'
import { getFederatedIdentites } from '../../core/slices/DataSlice'
import { getApiEntryPoint } from '../../core/api/ApiSlice'
import { HmstrDispatch } from '../../core/Store'
import { PotentialDatasource } from './PotentialDatasource'
import { TalkwalkerNew } from './TalkwalkerNew'
import { DatasourceNewSelect } from './DatasourceNewSelect'

type DatasourceSelectPanelProps = {
    onSelect: (potentialDatasource: PotentialDatasource) => any
    selectedDatasources: ProjectDatasource[]
    limitExceeded?: boolean
}

export const DatasourceSelectPanel: FC<DatasourceSelectPanelProps> = ({ onSelect, selectedDatasources, limitExceeded }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const currentUser = useSelector(getCurrentUser)
    const apiEntryPoint = useSelector(getApiEntryPoint)
    const federatedIdentities = useSelector(getFederatedIdentites)
    const [selectedDatasourceType, setSelectedDatasourceType] = useState<ProjectDatasourceType | undefined>()
    const isMobile = useSelector(getIsMobile)
    const theme = useTheme()
    const smallDevices = useMediaQuery(theme.breakpoints.down('lg'))

    const handleCardClick = (type: ProjectDatasourceType) => {
        if (type === 'TALKWALKER_JSON') {
            setSelectedDatasourceType(type)
            return
        }

        const identityType = getFederatedTypeForDatasourceType(type)
        const hasIdentities = !!Object.values(federatedIdentities).find((identity) => identity.type === identityType)

        if (hasIdentities) {
            setSelectedDatasourceType(type)
        } else {
            linkAccount(apiEntryPoint, identityType).then((success) => {
                dispatch(fetchFederatedIdentites(currentUser))

                if (type) {
                    dispatch(fetchPotentialDatasources({ user: currentUser, type }))
                }

                if (success) {
                    dispatch(showSuccessSnackbar('federated-identites.link-success'))
                    setSelectedDatasourceType(type)
                } else {
                    dispatch(showErrorSnackbar('federated-identites.link-failed'))
                }
            })
        }
    }

    const renderCard = (type: ProjectDatasourceType) => (
        <Grid item xs={12} md={smallDevices ? 6 : 12} lg={6} xl={4}>
            <ListItemButton component={Paper} sx={{ height: 64 }} onClick={() => handleCardClick(type)}>
                <ListItemAvatar>{getIconForDatasourceType(type)}</ListItemAvatar>
                <ListItemText primary={<FormattedMessage id={getLabelForDatasourceType(type)} />} />
            </ListItemButton>
        </Grid>
    )

    const renderDialog = () => {
        if (!selectedDatasourceType) {
            return null
        }

        if (selectedDatasourceType === 'TALKWALKER_JSON') {
            const handleTwAdd = (ds: Partial<ProjectDatasource>) => {
                onSelect(ds as PotentialDatasource)
                setSelectedDatasourceType(undefined)
            }

            return <TalkwalkerNew handleClose={() => setSelectedDatasourceType(undefined)} onAddDatasource={handleTwAdd} />
        }

        return (
            <DatasourceNewSelect
                type={selectedDatasourceType}
                selectedDatasources={selectedDatasources}
                onSelect={onSelect}
                handleClose={() => setSelectedDatasourceType(undefined)}
                limitExceeded={limitExceeded}
            />
        )
    }

    return (
        <Box mt={2} mb={4}>
            <Typography sx={{ mb: 1 }}>
                <FormattedMessage id="settings.choose-datasource" />
            </Typography>

            <Grid container spacing={2}>
                {renderCard('FACEBOOK_PAGE')}
                {renderCard('FACEBOOK_AD_ACCOUNT')}
                {renderCard('INSTAGRAM_ACCOUNT')}
                {renderCard('LINKED_IN')}
                {renderCard('LINKED_IN_PROFILE')}
                {renderCard('TIKTOK_ACCOUNT')}
                {renderCard('TALKWALKER_JSON')}
            </Grid>

            <Dialog
                open={!!selectedDatasourceType}
                fullWidth
                maxWidth={selectedDatasourceType === 'TALKWALKER_JSON' ? 'sm' : 'md'}
                scroll={'paper'}
                fullScreen={isMobile}
            >
                {renderDialog()}
            </Dialog>
        </Box>
    )
}
