import { HmstrColors } from '../../core/theme/hmstr-theme'

function hexToRgb(hex: string) {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b
    })

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

function luminance(r: any, g: any, b: any) {
    let a = [r, g, b].map(function (v) {
        v /= 255
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
    })
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

const ratio = (color1luminance: number, color2luminance: number) => {
    return color1luminance > color2luminance ? (color2luminance + 0.05) / (color1luminance + 0.05) : (color1luminance + 0.05) / (color2luminance + 0.05)
}

export const calculateContrast = (hex1: string, hex2: string) => {
    const color1rgb = hexToRgb(hex1.substr(1))
    const color2rgb = hexToRgb(hex2.substr(1))
    const lum1 = luminance(color1rgb?.r, color1rgb?.g, color1rgb?.b)
    const lum2 = luminance(color2rgb?.r, color2rgb?.g, color2rgb?.b)
    const ratioCalc = ratio(lum1, lum2)
    return {
        aaLargeText: ratioCalc < 1 / 3,
        aaSmallText: ratioCalc < 1 / 4.5,
        aaaLargeText: ratioCalc < 1 / 4.5,
        aaaSmallText: ratioCalc < 1 / 7,
    }
}

export const invertColor = (col: string) => {
    col = col.toLowerCase()
    const colors = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
    let inverseColor = '#'
    col.replace('#', '')
        .split('')
        .forEach((i) => {
            const index = colors.indexOf(i)
            inverseColor += colors.reverse()[index]
        })
    return inverseColor
}

export const calculateOptimalColor = (impairedVision: boolean, hex: string, darkMode: boolean) => {
    const actualHex = hex || HmstrColors.Orange
    return impairedVision ? (calculateContrast(actualHex, darkMode ? '#424242' : '#ffffff').aaLargeText ? actualHex : invertColor(actualHex)) : actualHex
}
