import * as React from 'react'
import { FC, SyntheticEvent } from 'react'
import { Alert, Box, Container, Grid, Tab, Tabs, Typography } from '@mui/material'
import { TabPanel } from '../../common/tab-panel/TabPanel'
import { NotificationSettingsTab, NotificationSettingsTabType } from './NotificationSettingsTab'
import { FormattedMessage } from 'react-intl'
import { CalendarMonth, Info, Mail } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchNotificationSettingsForUser } from '../SettingsActions'
import { getCurrentUser } from '../../core/slices/CoreSlice'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Path } from 'path-parser'
import { settingsForTab } from './NotificationProjectEntry'
import { Loading } from '../../common/loading/Loading'

type NotificationSettingsProps = {}

export const NotificationSettings: FC<NotificationSettingsProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const currentUser = useSelector(getCurrentUser)
    const navigate = useProjectNavigate()
    const location = useLocation()
    const notificationSettingsPath = '/:projectId/settings/notifications/:tab'
    const path = new Path<{ tab: NotificationSettingsTabType }>(notificationSettingsPath)
    const pathVariables = path.partialTest(location.pathname)
    const selectedTab = pathVariables?.tab || 'engage'

    const handleTabChange = (ev: SyntheticEvent, val: NotificationSettingsTabType) => {
        navigate('/settings/notifications/' + val)
    }

    useEffectWithIdComparison(() => {
        dispatch(fetchNotificationSettingsForUser(currentUser))
    }, [currentUser])

    useEffectWithIdComparison(() => {
        if (!Object.keys(settingsForTab).includes(selectedTab)) {
            navigate('/settings/notifications/engage')
        }
    }, [selectedTab])

    if (!Object.keys(settingsForTab).includes(selectedTab)) {
        return <Loading />
    }

    return (
        <Container
            maxWidth="xl"
            sx={{
                alignSelf: 'center',
                justifySelf: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box marginBottom={2} marginTop={2}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Box display="flex" alignItems="center">
                            <Typography component="h1" variant="h5">
                                <FormattedMessage id={'notifications.manage'} />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box mb={1}>
                <Alert color="info" icon={<Info />}>
                    <FormattedMessage id="notifications.helper-text" />
                </Alert>
            </Box>

            <Grid container spacing={2} pb={2} justifyContent="space-between">
                <Grid item>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor={'secondary'}
                        textColor={'inherit'}
                        sx={{
                            '& .MuiTab-root': {
                                minHeight: 48,
                            },
                        }}
                    >
                        <Tab
                            value={'engage'}
                            label={<FormattedMessage id={'notifications.tab.engage'} />}
                            icon={<Mail fontSize={'small'} />}
                            iconPosition={'start'}
                        />
                        <Tab
                            value={'publish'}
                            label={<FormattedMessage id={'notifications.tab.publish'} />}
                            icon={<CalendarMonth fontSize={'small'} />}
                            iconPosition={'start'}
                        />
                    </Tabs>
                </Grid>
                <Grid item alignSelf="flex-end">
                    {/*<SearchChip onChange={() => {}} value={''} />*/}
                </Grid>
                <Grid item xs={12}>
                    <TabPanel value={0} index={0}>
                        <Routes>
                            <Route path="" element={<Navigate to="engage" replace />} />
                            <Route path=":tab" element={<NotificationSettingsTab />} />
                        </Routes>
                    </TabPanel>
                </Grid>
            </Grid>
        </Container>
    )
}
