import * as React from 'react'
import { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import {
    getShowOnlyAssignedPosts,
    getShowOnlyAssignedToOthersPosts,
    getShowOnlyUnassignedPosts,
    toggleOnlyAssignedPosts,
    toggleOnlyAssignedToOthersPosts,
    toggleShowOnlyUnassignedPosts,
} from '../PublishingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'

type PublishingAssignmentSelectProps = {
    onOpen?: () => void
    onClose?: () => void
    open?: boolean
}

export const PublishingAssignmentSelect: FC<PublishingAssignmentSelectProps> = ({ onOpen, onClose, open }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const onlyAssigned = useSelector(getShowOnlyAssignedPosts)
    const onlyAssignedToOthers = useSelector(getShowOnlyAssignedToOthersPosts)
    const onlyUnassigned = useSelector(getShowOnlyUnassignedPosts)
    const value = onlyAssigned ? 'ME' : onlyAssignedToOthers ? 'OTHERS' : onlyUnassigned ? 'UNASSIGNED' : 'ALL'

    const handleChange = (event: SelectChangeEvent) => {
        if (onlyAssigned && (event.target.value as string) !== 'ME') {
            dispatch(toggleOnlyAssignedPosts())
        } else if (!onlyAssigned && (event.target.value as string) === 'ME') {
            dispatch(toggleOnlyAssignedPosts())
        }

        if (onlyAssignedToOthers && (event.target.value as string) !== 'OTHERS') {
            dispatch(toggleOnlyAssignedToOthersPosts())
        } else if (!onlyAssignedToOthers && (event.target.value as string) === 'OTHERS') {
            dispatch(toggleOnlyAssignedToOthersPosts())
        }

        if (onlyUnassigned && (event.target.value as string) !== 'UNASSIGNED') {
            dispatch(toggleShowOnlyUnassignedPosts())
        } else if (!onlyUnassigned && (event.target.value as string) === 'UNASSIGNED') {
            dispatch(toggleShowOnlyUnassignedPosts())
        }
    }

    return (
        <FormControl fullWidth>
            <InputLabel variant={'outlined'}>
                <FormattedMessage id={'filters.assignment.title'} />
            </InputLabel>
            <Select
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                value={value}
                label={<FormattedMessage id={'filters.assignment.title'} />}
                variant={'outlined'}
                onChange={handleChange}
                sx={{ minWidth: '80px', textAlign: 'left' }}
                size={'small'}
                MenuProps={{ disablePortal: true }}
            >
                <MenuItem value={'ALL'}>
                    <FormattedMessage id={'filters.all'} />
                </MenuItem>
                <MenuItem value={'ME'}>
                    <FormattedMessage id={'publishing.filter.assigned-to-me'} />
                </MenuItem>
                <MenuItem value={'OTHERS'}>
                    <FormattedMessage id={'engagement.inbox.assigned-to-others'} />
                </MenuItem>
                <MenuItem value={'UNASSIGNED'}>
                    <FormattedMessage id={'filters.unassigned'} />
                </MenuItem>
            </Select>
        </FormControl>
    )
}
