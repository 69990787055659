import { FC } from 'react'
import { Box, Button, DialogActions, DialogTitle, Grid } from '@mui/material'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { ConnectedAutocomplete } from '../../form/ConnectedAutocomplete'
import { addDatasourceTag, getDatasourceTags } from '../SettingsSlice'
import { ConnectedColorpicker } from '../../form/ConnectedColorPicker'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-final-form'
import { validationRequired } from '../../form/validate'
import { v4 as uuidv4 } from 'uuid'
import { PotentialDatasource } from './PotentialDatasource'
import { showSuccessSnackbar } from '../../core/slices/CoreSlice'

type TalkwalkerNewProps = {
    handleClose: any
    onAddDatasource: (datasource: PotentialDatasource) => any
}

export const TalkwalkerNew: FC<TalkwalkerNewProps> = ({ onAddDatasource, handleClose }) => {
    const dispatch = useDispatch()
    const datasourceTags = useSelector(getDatasourceTags)

    const onSubmit = (values: PotentialDatasource) => {
        onAddDatasource({
            ...values,
            category: 'DATA_STREAMS',
            type: 'TALKWALKER_JSON',
            metadata: {
                ...values.metadata,
                id: uuidv4(),
            },
        })
        handleClose()
        dispatch(showSuccessSnackbar('datasources.created-success'))
    }

    return (
        <Form<PotentialDatasource>
            initialValues={{ color: '#000000' }}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle>
                        <FormattedMessage id="TALKWALKER_JSON" />
                    </DialogTitle>
                    <Box p={2} pt={0}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <ConnectedTextField name="name" label="general.name" validate={validationRequired} fullWidth required={true} />
                            </Grid>
                            <Grid item xs={12}>
                                <ConnectedTextField
                                    name="metadata.link"
                                    label="results.results-link"
                                    maxLength={400}
                                    required={true}
                                    validate={validationRequired}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ConnectedTextField name="metadata.deleted_link" label="results.deleted-link" maxLength={400} fullWidth />
                            </Grid>
                            <Grid item xs={11}>
                                <ConnectedAutocomplete
                                    name="tags"
                                    label="general.tags"
                                    options={datasourceTags}
                                    onAddOption={(o) => dispatch(addDatasourceTag(o))}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Box display="flex" justifyContent="center">
                                    <ConnectedColorpicker name="color" label="general.color" fullWidth />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            <FormattedMessage id="general.close" />
                        </Button>
                        <Button variant="contained" color="secondary" type="submit">
                            <FormattedMessage id="general.add" />
                        </Button>
                    </DialogActions>
                </form>
            )}
        />
    )
}
