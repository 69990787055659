import * as React from 'react'
import { FC, Fragment } from 'react'
import { PostCard } from './PostCard'
import { Box, Divider, Typography } from '@mui/material'
import { TagStatusFilterType } from '../../common/filter/TagStatusFilterType'
import { FormattedMessage } from 'react-intl'
import { CommonPostData } from './CommonPostData'

type PostCardsListProps = {
    posts: CommonPostData[]
    tagFilter: TagStatusFilterType
}

export const PostCardsList: FC<PostCardsListProps> = ({ posts, tagFilter = TagStatusFilterType.ALL }) => {
    if (posts.length === 0) {
        return (
            <Box textAlign="center">
                <Typography variant="h6">
                    <FormattedMessage id="filters.no-posts-found" />
                </Typography>
            </Box>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                overflowY: 'visible',
            }}
        >
            {posts.map((post, index) => (
                <Fragment key={`post-card-${post.id}`}>
                    <PostCard post={post} tagFilter={tagFilter} />
                    {index < posts.length - 1 && <Divider style={{ margin: '0 0 16px 0' }} />}
                </Fragment>
            ))}
        </div>
    )
}
