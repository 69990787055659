import { FC } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { processUserFormValues, UserForm, UserFormValues } from './UserForm'
import { fetchUsersForAccount, inviteUserToAccount } from './UserActions'
import { HmstrDispatch } from '../../core/Store'
import {
    closeFullscreenDialog,
    getProjectsForSelectedAccount,
    getSelectedAccount,
    getSelectedAccountAccess,
    showSuccessSnackbar,
} from '../../core/slices/CoreSlice'
import { UserAccountAccess } from './User'
import { useKeycloak } from '../../core/hooks/useKeycloak'
import { FORM_ERROR } from 'final-form'

type UserInviteProps = {}

export const UserInvite: FC<UserInviteProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const projects = useSelector(getProjectsForSelectedAccount)
    const keycloak = useKeycloak()
    const account = useSelector(getSelectedAccount)
    const accountAccess = useSelector(getSelectedAccountAccess) as UserAccountAccess

    const onSubmit = async (values: UserFormValues) => {
        const processedValues = processUserFormValues(values)

        if (processedValues.account_role === 'USER' && processedValues.projects.length === 0) {
            return {
                [FORM_ERROR]: 'accounts.at-least-one-project-required',
            }
        }

        const action = await dispatch(inviteUserToAccount({ account, userFormValues: processedValues }))

        if (action.type === inviteUserToAccount.fulfilled.type) {
            dispatch(showSuccessSnackbar('users.invite.created'))
            dispatch(closeFullscreenDialog())
            dispatch(fetchUsersForAccount(accountAccess))
        } else {
            const payload: any = action.payload || {}

            if (payload.status === 409) {
                return {
                    email: 'users.invite.email-already-exists',
                }
            }
        }
    }

    const generateInitialValues = (): Partial<UserFormValues> => ({
        account_name: account.name,
        account_role: 'USER',
        language: keycloak.locale,
        account_id: account.id,
        external: false,
        projects: projects.map((p) => ({
            project_id: p.id,
            name: p.name,
            description: p.description,
            external: false,
            role: '',
            access: p.data_sources.map((ds) => ({
                data_source_id: ds.id,
            })),
            _links: {
                project: p._links.self,
            },
        })),
    })

    return (
        <Container maxWidth="lg">
            <Box marginTop={4} marginBottom={2}>
                <Box marginBottom={4}>
                    <Typography variant="h4">
                        <FormattedMessage id="users.new" />
                    </Typography>
                </Box>

                <UserForm onSubmit={onSubmit} initialValues={generateInitialValues()} mode="invite" />
            </Box>
        </Container>
    )
}
