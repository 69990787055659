import { FC, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { getProjects, getTags } from '../../core/slices/DataSlice'
import * as _ from 'lodash'
import { getFilteredUserTableEntries } from '../SettingsSlice'
import { Close, Mail, Person } from '@mui/icons-material'
import { getIconForDatasourceType } from '../datasources/DatasourceTypeMappings'
import { getSelectedAccount } from '../../core/slices/CoreSlice'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dot } from '../../common/dot/Dot'

export type AccountOverCapacityDialogProps = {
    mode: 'users' | 'datasources' | 'cts' | 'tags'
    onSubmit: (mode: AccountOverCapacityDialogProps['mode'], ids: string[]) => any
    itemCountToRemove: number
    onClose: () => any
}

type AccountOverCapacityItem = {
    id: string
    name: string
    picture?: JSX.Element
    subtext: string
    disabled?: boolean
}

export const AccountOverCapacityDialog: FC<AccountOverCapacityDialogProps> = ({ mode, onSubmit, itemCountToRemove, onClose }) => {
    const intl = useIntl()
    const account = useSelector(getSelectedAccount)
    const projects = useSelector(getProjects)
    const datasources = _.flatMap(_.values(projects), (p) => p.data_sources)
    const conversionTracking = datasources.filter((ds) => ds.conversion_tracking)
    const tags = useSelector(getTags)
    const userTableEntries = useSelector(getFilteredUserTableEntries)
    const [itemsToDelete, setItemsToDelete] = useState<string[]>([])

    const handleChange = (id: string, checked: boolean) => {
        if (checked && !itemsToDelete.includes(id)) {
            setItemsToDelete([...itemsToDelete, id])
        } else if (!checked && itemsToDelete.includes(id)) {
            setItemsToDelete([...itemsToDelete].filter((i) => i !== id))
        }
    }

    const getListItems = (): AccountOverCapacityItem[] => {
        switch (mode) {
            case 'users':
                return userTableEntries.map((u) => ({
                    id: u.id,
                    name: u.invite ? 'Einladung' : `${u.first_name} ${u.last_name}`,
                    picture: u.invite ? <Mail /> : <Person />,
                    subtext: u.email,
                    disabled: u.id === account.owner_id,
                }))
            case 'datasources':
            case 'cts':
                return (mode === 'datasources' ? datasources : conversionTracking).map((d) => ({
                    id: d.id,
                    name: d.name,
                    picture: getIconForDatasourceType(d.type),
                    subtext: d.metadata.description || '',
                }))
            case 'tags':
                return Object.values(tags).map((t) => ({
                    id: t.id,
                    name: t.label,
                    picture: <Dot color={t.color} size={16} />,
                    subtext: `${t.usage_count} Verwendungen`,
                }))
            default:
                return []
        }
    }

    const typographyProps = {
        sx: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    }

    return (
        <>
            <DialogTitle>
                <Box width="100%" display="flex" justifyContent="space-between">
                    <FormattedMessage
                        id="billing.reduce-limits-hint"
                        values={{
                            countUnit: intl.formatMessage({ id: `general.${mode}-plural` }, { count: itemCountToRemove }),
                        }}
                    />
                    <IconButton size="small" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box minWidth={500}>
                    <List disablePadding>
                        {getListItems().map((item) => {
                            return (
                                <ListItem key={item.id} sx={{ minHeight: 72 }} disabled={item.disabled}>
                                    <ListItemIcon>{item.picture}</ListItemIcon>
                                    <ListItemText
                                        sx={{ mr: 2 }}
                                        primaryTypographyProps={typographyProps}
                                        secondaryTypographyProps={typographyProps}
                                        primary={item.name}
                                        secondary={item.subtext}
                                    />
                                    <ListItemSecondaryAction>
                                        <Checkbox disabled={item.disabled} onChange={(event, checked) => handleChange(item.id, checked)} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => onSubmit(mode, itemsToDelete)} disabled={itemsToDelete.length < itemCountToRemove}>
                    <FormattedMessage id="billing.reduce-limits" />
                </Button>
            </DialogActions>
        </>
    )
}
