import * as _ from 'lodash'
import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAllowedDomainsByDatasource,
    getDatasourceIdsForSelectedPostGroup,
    getErrorsForCommonFile,
    getIsPostGroupLoaded,
    getPostDialogInitialTimestamp,
    getRelevantStateForSelectedPostGroup,
    getSelectedPostGroup,
    getSelectedPostGroupId,
    selectPostGroup,
} from './PublishingSlice'
import { PublishingForm, PublishingFormValues } from './PublishingForm'
import { Post } from './posts/Post'
import { getDatasourcesAsIdMap, getSelectedProject, showErrorSnackbar, showSuccessSnackbar } from '../core/slices/CoreSlice'
import { v4 as uuidv4 } from 'uuid'
import { HmstrDispatch } from '../core/Store'
import { FORM_ERROR, FormApi } from 'final-form'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { Loading } from '../common/loading/Loading'
import { Fallback } from '../common/fallback/Fallback'
import { fetchTagsForProject } from '../tags/TagsActions'
import { createPostGroup, fetchPostGroupActivities, fetchPostGroupById, updatePostGroup } from './post-groups/PostGroupActions'
import { convertValuesToPostGroupRequest } from './post-groups/PostGroupRequest'
import { PostGroup } from './post-groups/PostGroup'
import { useHasAnyDatasourcePermission, useHasDatasourcePermission } from '../core/hooks/useHasPermission'
import { getRelevantPostState } from './getRelevantPostState'

type PublishingDialogProps = {}

export const PublishingPostView: FC<PublishingDialogProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const selectedProject = useSelector(getSelectedProject)
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const selectedPostGroupId = useSelector(getSelectedPostGroupId)
    const selectedPostGroup = useSelector(getSelectedPostGroup)
    const initialTimestamp = useSelector(getPostDialogInitialTimestamp)
    const isPostGroupLoaded = useSelector(getIsPostGroupLoaded)
    const isListView = window.location.pathname.includes('/publishing/list')
    const relevantPostState = useSelector(getRelevantStateForSelectedPostGroup)
    const datasourcesOwningDomain = useSelector(getAllowedDomainsByDatasource)
    const datasourceIdsForPostGroup = useSelector(getDatasourceIdsForSelectedPostGroup)
    const imageErrors = useSelector(getErrorsForCommonFile)
    const canUpdatePost = useHasDatasourcePermission('post.update', datasourceIdsForPostGroup)
    const canUpdateDraft = useHasDatasourcePermission('post.update_draft', datasourceIdsForPostGroup)
    const canPublish = useHasAnyDatasourcePermission('post.publish')
    const formDisabled =
        relevantPostState === 'PUBLISH_SUCCESS' ||
        relevantPostState === 'PUBLISH_IN_PROGRESS' ||
        (Boolean(selectedPostGroup) && !canUpdatePost && !canUpdateDraft)

    useEffectWithIdComparison(() => {
        if (selectedPostGroup === undefined && selectedPostGroupId !== undefined && selectedPostGroupId !== 'new' && isPostGroupLoaded) {
            navigate(isListView ? '/publishing/list' : '/publishing/calendar')
        }
    }, [selectedProject, selectedPostGroupId, isPostGroupLoaded])

    useEffectWithIdComparison(() => {
        if (selectedPostGroup === undefined && selectedPostGroupId !== undefined && selectedPostGroupId !== 'new') {
            dispatch(fetchPostGroupById(selectedPostGroupId))
        }
    }, [selectedPostGroupId, selectedProject])

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTagsForProject(selectedProject))
        }
    }, [selectedProject])

    const handleSubmit = async (values: PublishingFormValues, form: FormApi<PublishingFormValues>) => {
        if (!selectedProject) {
            return false
        }

        const hasImageErrors = Object.values(imageErrors).filter((e) => e !== undefined && e.length !== 0).length > 0

        if (hasImageErrors && values.common_post.state !== 'DRAFT') {
            dispatch(showErrorSnackbar('Please check errors for your file(s).'))
            return { [FORM_ERROR]: 'Please check errors for your file(s).' }
        }

        const response = await new Promise<PostGroup | undefined>((resolve) => {
            const postGroupRequest = convertValuesToPostGroupRequest(values, datasourcesById, datasourcesOwningDomain, selectedPostGroup)

            let response
            if (selectedPostGroup) {
                response = dispatch(updatePostGroup({ postGroup: selectedPostGroup, request: postGroupRequest }))
            } else {
                response = dispatch(createPostGroup({ project: selectedProject, request: postGroupRequest }))
            }

            response.then((action) => {
                if (action.meta.requestStatus === 'rejected') {
                    if ((action.payload as any).status === 426) {
                        dispatch(showErrorSnackbar('publishing.post-limit-reached'))
                    } else if ((action.payload as any).status === 400) {
                        dispatch(showErrorSnackbar('publishing.post-request-malformed'))
                    } else if ((action.payload as any).status === 403) {
                        dispatch(showErrorSnackbar('publishing.disabled-tooltip-no-permission'))
                    }
                    resolve(undefined)
                } else {
                    const postGroup = action.payload as PostGroup
                    const hasErrors = !!postGroup.posts.find((post) => post.state === 'PUBLISH_FAILED')

                    if (hasErrors) {
                        dispatch(showErrorSnackbar('publishing.schedule-post-failed'))
                        resolve(undefined)
                    } else {
                        // If it is a new post group, select this post group after save and put its id into the url.
                        if (!selectedPostGroup) {
                            dispatch(selectPostGroup(postGroup.id))
                            window.history.replaceState(null, '', window.location.pathname.replace('new', postGroup.id))
                            form.reset()
                        } else {
                            // Just fetch activities if the post group already exists.
                            dispatch(fetchPostGroupActivities(postGroup))
                        }

                        dispatch(showSuccessSnackbar('publishing.save-post-success'))
                        resolve(postGroup)
                    }
                }
            })
        })

        if (!response) {
            return { [FORM_ERROR]: 'failure' }
        }

        if (response) {
            let tries = 1
            let fetchedPostGroup = response
            const finishStates = ['PUBLISH_SUCCESS', 'PUBLISH_FAILED']
            while (tries <= 5 && !finishStates.includes(getRelevantPostState(fetchedPostGroup.posts))) {
                setTimeout(() => {
                    dispatch(fetchPostGroupById(response.id))
                }, 1000 * tries)
                tries += 1
            }
        }

        return true
    }

    const initialValues = useMemo((): Partial<PublishingFormValues> => {
        if (selectedPostGroup) {
            const firstPost = selectedPostGroup.posts[0]
            const availableTypes = _.groupBy(selectedPostGroup.posts, 'data_source_type')
            const postsByType = _.mapValues(availableTypes, (posts) => ({
                ...posts[0],
                state: posts[0].state,
                publish_time: posts[0].fb_publish_time || posts[0].publish_time,
                fb_plan_via_facebook: !!posts[0].fb_publish_time,
            })) as PublishingFormValues['postByType']

            return {
                customize_posts_by_network: selectedPostGroup.customized_by_network,
                project_id: selectedProject?.id,
                group_id: selectedPostGroup.group_id,
                data_source_ids: selectedPostGroup.posts.map((p) => p.data_source_id),
                assignee_id: selectedPostGroup.assignee_id,
                tag_ids: selectedPostGroup.tags,
                postByType: postsByType,
                common_post: {
                    ...firstPost,
                    publish_time: firstPost.fb_publish_time || firstPost.publish_time,
                    ig_thumb_offset_ms_enabled: !!firstPost.ig_thumb_offset_ms,
                    tt_thumb_offset_ms_enabled: !!firstPost.tt_thumb_offset_ms,
                    tt_disclose_brand_promotion_enabled: !!firstPost.tt_disclose_brand_promotion,
                    fb_plan_via_facebook: !!firstPost.fb_publish_time,
                    state: relevantPostState === 'PUBLISH_FAILED' ? 'PUBLISH_NOW' : firstPost.state,
                    tags: selectedPostGroup.tags,
                },
            }
        } else {
            const approvalActive = selectedProject?.approval_enabled || false
            const stateWithoutApprovalActive = initialTimestamp === 'now' || initialTimestamp === undefined ? 'PUBLISH_NOW' : 'PLANNED'

            return {
                customize_posts_by_network: false,
                project_id: selectedProject?.id,
                group_id: uuidv4(),
                common_post: {
                    state: approvalActive ? 'DRAFT' : canPublish ? stateWithoutApprovalActive : 'DRAFT',
                    publish_time: initialTimestamp,
                } as Post,
                data_source_ids: [],
            }
        }
    }, [canPublish, initialTimestamp, relevantPostState, selectedPostGroup, selectedProject?.approval_enabled, selectedProject?.id]) // Memoize function, since a recalculation of the initial values is unnessecary if the domain ownership selector changes.

    return (
        <Fallback
            condition={!isPostGroupLoaded && selectedPostGroupId !== undefined && selectedPostGroup === undefined && selectedPostGroupId !== 'new'}
            centered={true}
            icon={<Loading />}
        >
            <PublishingForm onSubmit={handleSubmit} initialValues={initialValues} disabled={formDisabled} overwritePostState={relevantPostState} />
        </Fallback>
    )
}
