import * as React from 'react'
import { FC, Fragment, PropsWithChildren, useState } from 'react'
import { alpha, Badge, Box, Button, Drawer, IconButton, keyframes, Tooltip, Typography, useTheme } from '@mui/material'
import { drawerWidth } from '../../navigation/Drawer.styles'
import { primaryNavigationWidth } from '../../navigation/PrimaryNavigation'
import { FormattedMessage } from 'react-intl'
import { Close, FilterAlt, FilterAltOff } from '@mui/icons-material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { FilterChip } from './FilterChip'
import _ from 'lodash'
import { useHotkeys } from 'react-hotkeys-hook'
import { HotkeyTooltip } from '../hotkeys/HotkeyTooltip'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterDrawerOpen, toggleFilterDrawerOpen } from './FilterSlice'
import { getAppThemeColor, getIsMobile, setAppThemeColor } from '../../core/slices/CoreSlice'

export type FilterSetting = {
    settingInput?: ReactJSXElement
    chipElement?: ReactJSXElement
    onlyChip?: boolean
    tooltip: string
    icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
    currentValueAsString?: string
    hasChangedFromDefault?: boolean
    important?: boolean
    deletable?: boolean
    onDeleteFunction?: () => void
    onClickFunction?: () => void
}

type FilterDrawerProps = {
    filters: FilterSetting[]
    previousAppThemeColor?: string
}

export const FilterDrawer: FC<PropsWithChildren<FilterDrawerProps>> = ({ children, filters, previousAppThemeColor }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useSelector(getIsMobile)
    const appThemeColor = useSelector(getAppThemeColor)
    const blink = keyframes`
        0% {
            opacity: 0
        }
        50% {
            opacity: 1
        }
        100% {
            opacity: 0
        }
    `
    const drawerOpen = useSelector(getFilterDrawerOpen)
    useHotkeys('f', () => {
        if (!drawerOpen) dispatch(setAppThemeColor(theme.palette.background.paper))
        else if (previousAppThemeColor) dispatch(setAppThemeColor(previousAppThemeColor))
        dispatch(toggleFilterDrawerOpen())
    })
    const [focusedElement, setFocusedElement] = useState<string | undefined>(undefined)
    const totalChangedSettingsForSidebar = _.compact(filters.map((setting) => setting.hasChangedFromDefault && setting.deletable && !setting.onlyChip)).length
    const totalResettableSettings = _.compact(filters.map((setting) => setting.hasChangedFromDefault && setting.deletable)).length

    const handleClickFunction = (setting: FilterSetting) => {
        dispatch(toggleFilterDrawerOpen())
        setFocusedElement(setting.tooltip)
        dispatch(setAppThemeColor(theme.palette.background.paper))
        setTimeout(() => {
            setFocusedElement(undefined)
        }, 1000)
        if (setting.onClickFunction) {
            setting.onClickFunction()
        }
    }

    const renderDrawerFilter = (setting: FilterSetting) => {
        const isHighlighted = setting.tooltip === focusedElement && !setting.onClickFunction
        return (
            <Box width={'100%'} display={'flex'} alignItems={'center'} gap={1} sx={{ position: 'relative' }}>
                <Box
                    sx={
                        isHighlighted
                            ? {
                                  position: 'absolute',
                                  left: 0,
                                  width: 'calc(100% + 32px)',
                                  background: theme.palette.divider,
                                  height: '100%',
                                  animation: `${blink} 0.5s infinite`,
                                  ml: -2,
                                  opacity: 1,
                              }
                            : {
                                  position: 'absolute',
                                  left: 0,
                                  width: 'calc(100% + 32px)',
                                  background: theme.palette.divider,
                                  height: '100%',
                                  ml: -2,
                                  opacity: 0,
                              }
                    }
                ></Box>
                <Box flexGrow={1} id={setting.tooltip} sx={{ maxWidth: 'calc(100% - 48px)' }}>
                    {setting.settingInput}
                </Box>
                <Tooltip title={<FormattedMessage id={'general.filter-reset'} />} disableInteractive>
                    <span>
                        <IconButton onClick={setting.onDeleteFunction} disabled={!setting.hasChangedFromDefault}>
                            <FilterAltOff />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
        )
    }
    const renderFilterChip = (setting: FilterSetting) => {
        if (setting.onlyChip && setting.chipElement) {
            return setting.chipElement
        }

        return (
            (setting.important || setting.hasChangedFromDefault) && (
                <FilterChip
                    tooltip={setting.tooltip}
                    val={setting.currentValueAsString}
                    icon={setting.icon}
                    onClick={() => {
                        handleClickFunction(setting)
                    }}
                    onDelete={setting.deletable ? (setting.hasChangedFromDefault ? setting.onDeleteFunction : undefined) : undefined}
                />
            )
        )
    }

    const handleResetAllFilters = () => {
        filters.forEach((setting) => {
            if (setting.deletable && setting.onDeleteFunction) {
                setting.onDeleteFunction()
            }
        })
    }

    return (
        <>
            <Box
                sx={{
                    scrollBehavior: 'auto !important',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
                display={'flex'}
                gap={1}
                py={0.5}
                maxWidth={isMobile ? 'calc(100%)' : undefined}
                overflow={isMobile ? 'scroll' : undefined}
            >
                {filters.map((setting) => (
                    <Fragment key={setting.tooltip}>{renderFilterChip(setting)}</Fragment>
                ))}
                <Box
                    sx={{
                        position: 'sticky',
                        pr: 0.5,
                        right: 1,
                        background: isMobile ? appThemeColor : undefined,
                        boxShadow: isMobile ? `0px 0px 10px 10px ${appThemeColor}` : undefined,
                    }}
                >
                    <Tooltip
                        title={
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                                <FormattedMessage id={'general.filter-expand'} />
                                <HotkeyTooltip keys={['f']} invert fontSize={14} />
                            </Box>
                        }
                        disableInteractive
                        placement={'left'}
                    >
                        <Badge
                            badgeContent={totalChangedSettingsForSidebar}
                            overlap={'circular'}
                            color={'secondary'}
                            sx={{ '& .MuiBadge-badge': { scale: isMobile ? '0.8' : '0.9' } }}
                        >
                            <IconButton
                                size={'small'}
                                color="inherit"
                                onClick={() => {
                                    dispatch(toggleFilterDrawerOpen())
                                    dispatch(setAppThemeColor(theme.palette.background.paper))
                                }}
                                sx={{
                                    height: '32px',
                                    width: '32px',
                                    backgroundColor: theme.palette.action.selected,
                                    '&:hover': {
                                        backgroundColor: alpha(
                                            theme.palette.action.selected,
                                            theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
                                        ),
                                    },
                                }}
                            >
                                <FilterAlt fontSize={'small'} />
                            </IconButton>
                        </Badge>
                    </Tooltip>
                </Box>
            </Box>
            <Drawer
                transitionDuration={200}
                elevation={0}
                anchor={isMobile ? 'right' : 'right'}
                open={drawerOpen}
                variant={'temporary'}
                onClose={() => {
                    dispatch(toggleFilterDrawerOpen())
                    if (previousAppThemeColor) dispatch(setAppThemeColor(previousAppThemeColor))
                }}
                keepMounted={true}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                        width: isMobile ? '100vw' : drawerWidth + primaryNavigationWidth,
                        maxWidth: '100vw',
                        height: '100vh',
                    }}
                    gap={2}
                    justifyContent={'flex-start'}
                    px={2}
                >
                    <Box
                        position={'sticky'}
                        top={0}
                        bgcolor={theme.palette.background.paper}
                        zIndex={2}
                        pb={2}
                        borderBottom={`1px solid ${theme.palette.divider}`}
                        boxSizing={'border-box'}
                    >
                        <Box height={'16px'} />
                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant={'h5'}>
                                <FormattedMessage id={'general.filter-selection'} />
                            </Typography>
                            <Tooltip
                                placement={'left'}
                                title={
                                    <Box display={'flex'} alignItems={'center'} gap={1}>
                                        <FormattedMessage id={'general.filter-collapse'} />
                                        <HotkeyTooltip keys={['f']} invert fontSize={14} />
                                    </Box>
                                }
                            >
                                <IconButton
                                    onClick={() => {
                                        dispatch(toggleFilterDrawerOpen())
                                        if (previousAppThemeColor) dispatch(setAppThemeColor(previousAppThemeColor))
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    {filters.map((setting) => !setting.onlyChip && <Fragment key={setting.tooltip}>{renderDrawerFilter(setting)}</Fragment>)}
                    {children}
                    <Box flexGrow={1} />
                    <Box
                        sx={{
                            position: 'sticky',
                            background: theme.palette.background.paper,
                            bottom: 0,
                            zIndex: 1,
                            py: 2,
                            borderTop: `1px solid ${theme.palette.divider}`,
                        }}
                    >
                        <Button
                            variant={'outlined'}
                            color={'inherit'}
                            fullWidth
                            endIcon={<FilterAltOff />}
                            sx={{
                                justifyContent: 'space-between',
                            }}
                            onClick={handleResetAllFilters}
                            disabled={!(totalResettableSettings > 0)}
                        >
                            <FormattedMessage id={'general.filter-reset-all'} />
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}
