import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { doGet } from '../../core/api/ApiClient'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'

import { MentionCandidate } from './MentionCandidate'

const MENTIONS_SLASH = (action: string) => `mentions/${action}`

export const fetchMentionsForName = createAsyncThunk<
    MentionCandidate[],
    {
        datasource: ProjectDatasource
        searchString: string
    },
    { state: HmstrState }
>(MENTIONS_SLASH('fetchLinkedinMentionsForName'), async (args, thunkApi) => {
    return await doGet(thunkApi, args.datasource._links.mention_candidates.href, undefined, {
        params: {
            q: args.searchString,
        },
    })
})
