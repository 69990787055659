import { Path } from 'path-parser'

export const replaceProjectId = (projectId: string) => {
    const path = new Path<{ projectId: string; splat: string }>('/portal/:projectId*splat')
    const pathVariables = path.partialTest(window.location.pathname)

    const proto = window.location.protocol
    const host = window.location.host
    window.history.replaceState(
        null,
        '',
        `${proto}//${host}${path.build({
            projectId: projectId,
            splat: pathVariables?.splat || '',
        })}`
    )
}
