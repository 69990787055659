import * as React from 'react'
import { FC } from 'react'
import Joyride, { CallBackProps, Step } from 'react-joyride'
import {
    engagementTourSteps,
    primarySidebarTourSteps,
    publishingFormTourSteps,
    publishingIndividualizeTourSteps,
    secondarySidebarTourSteps,
    settingsTourSteps,
} from './TourSteps'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { CustomTourTooltip } from './CustomTourTooltip'
import { endTour, getTourRunning } from './GuideSlice'
import { HmstrDispatch } from '../../core/Store'
import { finishTour } from '../../settings/user-management/UserActions'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { lighten, useTheme } from '@mui/material'
import { handleJoyrideStep } from './handleJoyrideStep'

type CustomTourProps = {
    continuous?: boolean
    disableScrolling?: boolean
}

export const CustomTour: FC<CustomTourProps> = ({ continuous = false, disableScrolling }) => {
    const theme = useTheme()
    const intl = useIntl()
    const navigate = useProjectNavigate()
    const dispatch = useDispatch<HmstrDispatch>()
    const currentTour = useSelector(getTourRunning)

    const getSteps = (): Step[] => {
        switch (currentTour) {
            case 'primarySidebarTour':
                return primarySidebarTourSteps
            case 'secondarySidebarTour':
                return secondarySidebarTourSteps
            case 'publishingFormTour':
                return publishingFormTourSteps
            case 'publishingFormIndividualizeTour':
                return publishingIndividualizeTourSteps
            case 'engagementTour':
                return engagementTourSteps
            case 'settingsTour':
                return settingsTourSteps
            default:
                return []
        }
    }
    const handleJoyrideCallback = (data: CallBackProps) => {
        if (data.action === 'skip' && currentTour) {
            dispatch(finishTour(currentTour)).then(() => {
                dispatch(endTour(currentTour))
            })
        }
        if (data.type === 'tour:end' && currentTour && data.action !== 'skip' && data.action === 'close') {
            dispatch(finishTour(currentTour)).then(() => {
                dispatch(endTour(currentTour))
            })
        }

        handleJoyrideStep(data.type, data.step.target, navigate, dispatch)
    }

    if (!currentTour) {
        return null
    }

    return (
        <Joyride
            key={currentTour}
            disableScrolling={disableScrolling}
            continuous={continuous}
            callback={handleJoyrideCallback}
            showProgress
            steps={getSteps()}
            disableOverlay
            run={true}
            styles={{
                options: {
                    zIndex: 1200,
                    primaryColor: '#ff6900',
                    arrowColor: lighten(theme.palette.background.paper, 3 * 0.025),
                },
                spotlight: {
                    borderRadius: 10,
                },
            }}
            tooltipComponent={CustomTourTooltip}
            locale={{
                back: intl.formatMessage({ id: 'general.back' }),
                close: intl.formatMessage({ id: 'general.close' }),
                last: intl.formatMessage({ id: 'general.close' }),
                next: intl.formatMessage({ id: 'general.next' }),
                open: intl.formatMessage({ id: 'general.open' }),
                skip: intl.formatMessage({ id: 'guide.skip' }),
            }}
        />
    )
}
