import React, { FC, useEffect, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { PublishingFormValues, ValidDatasourceTypeForPublishing } from './PublishingForm'
import {
    changePostDialogSelectedNetwork,
    changePostDialogSelectedTab,
    getPostDialogSelectedNetwork,
    getPostDialogSelectedTab,
    getRelevantStateForSelectedPostGroup,
    getSelectedPostGroup,
    PublishingState,
} from './PublishingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedProject, isExternalForProject } from '../core/slices/CoreSlice'
import { HmstrDispatch } from '../core/Store'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import _ from 'lodash'
import { IdMap } from '../core/slices/DataSlice'
import { Post } from './posts/Post'
import { Box, Breadcrumbs, Grid, Link as MuiLink, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { PublishingDatasourceSelection } from './PublishingDatasourceSelection'
import { PublishingTabs } from './PublishingTabs'
import { TabPanel } from '../common/tab-panel/TabPanel'
import { CommonPostFormSection } from './post-form-sections/CommonPostFormSection'
import { getIconForDatasourceType } from '../settings/datasources/DatasourceTypeMappings'
import { DesktopMac, Event, NavigateNext, Smartphone, TableChartOutlined } from '@mui/icons-material'
import { PublishingPostPreview } from './post-preview/PublishingPostPreview'
import { PublishingFormActions } from './PublishingFormActions'
import { PublishingFormTitle } from './PublishingFormTitle'
import { IndividualizeNetworksButton } from './IndividualizeNetworksButton'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { Link } from 'react-router-dom'
import { PublishingTaggingComponent } from './PublishingTaggingComponent'
import { FailedPostsAlert } from './FailedPostsAlert'
import { AssigneeDropdown } from '../common/assignee-dropdown/AssigneeDropdown'
import { SimpleUser } from '../settings/user-management/SimpleUser'
import { PublishingPostGroupActivities } from './PublishingPostGroupActivities'
import { usePublishingFormDatasources, usePublishingFormDatasourceTypes } from './PublishingHooks'
import { ConnectedHiddenField } from '../form/ConnectedHiddenField'
import { PublishingFormNavigationBlocker } from './PublishingFormNavigationBlocker'

type PublishingFormComponentProps = {
    handleSubmit: any
    disabled?: boolean
    overwritePostState?: string
}

export const validTabValuesWithoutNetworks = ['COMMON', 'INTERNAL', 'EXTERNAL']

export const PublishingFormComponent: FC<PublishingFormComponentProps> = ({ handleSubmit, disabled }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const project = useSelector(getSelectedProject)
    const selectedNetwork = useSelector(getPostDialogSelectedNetwork)
    const selectedTab = useSelector(getPostDialogSelectedTab)
    const selectedPostGroup = useSelector(getSelectedPostGroup)
    const relevantPostState = useSelector(getRelevantStateForSelectedPostGroup)
    const isUserExternal = useSelector(isExternalForProject)
    const form = useForm()
    const formState = useFormState<PublishingFormValues>()
    const customizePostsByNetwork = formState.values.customize_posts_by_network
    const [postPreviewDevice, setPostPreviewDevice] = useState<'desktop' | 'mobile'>('desktop')
    const backToList = window.location.pathname.includes('/publishing/list')
    const datasources = usePublishingFormDatasources()
    const datasourcesByType = _.groupBy(datasources, 'type') as IdMap<ProjectDatasource[]>
    const datasourceTypes = usePublishingFormDatasourceTypes()

    useEffect(() => {
        if (datasourceTypes.length === 0) {
            form.change('customize_posts_by_network', false)
        } else if (selectedNetwork && !datasourceTypes.includes(selectedNetwork)) {
            dispatch(changePostDialogSelectedNetwork(datasourceTypes[0]))
        }

        if (isUserExternal && selectedTab === 'INTERNAL') {
            dispatch(changePostDialogSelectedTab('EXTERNAL'))
        }
    })

    const handleChannelTypeChange = (newType: ValidDatasourceTypeForPublishing) => {
        if (customizePostsByNetwork && !validTabValuesWithoutNetworks.includes(selectedTab)) {
            dispatch(changePostDialogSelectedTab(newType))
        }

        dispatch(changePostDialogSelectedNetwork(newType))
    }

    const handleTabChange = (newTab: PublishingState['postDialogSelectedTab']) => {
        if (customizePostsByNetwork && !validTabValuesWithoutNetworks.includes(newTab)) {
            dispatch(changePostDialogSelectedNetwork(newTab as ValidDatasourceTypeForPublishing))
        }

        dispatch(changePostDialogSelectedTab(newTab))
    }

    let handleCustomizeClick = () => {
        dispatch(changePostDialogSelectedTab(datasourceTypes[0]))
        dispatch(changePostDialogSelectedNetwork(datasourceTypes[0] as ValidDatasourceTypeForPublishing))
        form.change('customize_posts_by_network', true)
        const posts = datasourceTypes.map(
            (t) =>
                ({
                    ...formState.values.common_post,
                    data_source_id: datasourcesByType[t][0].id,
                    data_source_type: datasourcesByType[t][0].type,
                } as Post)
        )
        const postsByDsType = _.mapKeys(posts, 'data_source_type') as any
        form.change('postByType', postsByDsType)
    }

    return (
        <ContentPanel
            title={
                <Box display="flex" alignItems="center" gap={2}>
                    <Breadcrumbs separator={<NavigateNext />}>
                        <MuiLink
                            underline="hover"
                            color="inherit"
                            component={Link}
                            to={backToList ? `/${project?.id}/publishing/list` : `/${project?.id}/publishing/calendar`}
                        >
                            <Typography variant="h5" display="flex" alignItems="center">
                                {backToList ? <TableChartOutlined sx={{ mr: 1 }} fontSize="inherit" /> : <Event sx={{ mr: 1 }} fontSize="inherit" />}
                                <FormattedMessage id={backToList ? 'features.publishing.list-view' : 'features.publishing.calendar'} />
                            </Typography>
                        </MuiLink>
                        <Typography variant="h5" display="flex" alignItems="center" color="text.primary">
                            <PublishingFormTitle />
                        </Typography>
                    </Breadcrumbs>

                    <PublishingTaggingComponent />
                </Box>
            }
            toolbar={
                <Box display="flex" gap={2}>
                    <ToggleButtonGroup size="small" value={selectedNetwork}>
                        {datasourceTypes.map((dt) => (
                            <ToggleButton value={dt} key={dt} onClick={() => handleChannelTypeChange(dt)}>
                                {getIconForDatasourceType(dt)}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>

                    <ToggleButtonGroup size="small" value={postPreviewDevice}>
                        <ToggleButton value="desktop" onClick={() => setPostPreviewDevice('desktop')}>
                            <DesktopMac />
                        </ToggleButton>
                        <ToggleButton value="mobile" onClick={() => setPostPreviewDevice('mobile')}>
                            <Smartphone />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            }
            toolbarInSameLine={true}
            disableToolbarToggle={true}
        >
            <form onSubmit={handleSubmit} noValidate style={{ height: '100%' }}>
                <Box display="flex" height="100%">
                    <Box flexGrow={1} display="flex" flexDirection="column" height="100%">
                        <Box flexGrow={1} pr={3} overflow="auto">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <FailedPostsAlert />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display={'flex'} alignItems={'flex-start'} gap={2}>
                                        <PublishingDatasourceSelection
                                            name="data_source_ids"
                                            disabled={disabled || relevantPostState === 'PLANNED_BY_FACEBOOK'}
                                        />
                                        <Field
                                            name="assignee_id"
                                            render={({ input }) => {
                                                const handleAssign = (user: SimpleUser | undefined) => {
                                                    input.onFocus()
                                                    input.onChange(user?.id)
                                                    input.onBlur()
                                                }

                                                return <AssigneeDropdown assigneeId={formState.values.assignee_id} onAssign={handleAssign} avatar />
                                            }}
                                        ></Field>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <ConnectedHiddenField name="customize_posts_by_network" />
                                        {customizePostsByNetwork && selectedNetwork ? (
                                            <PublishingTabs onTabChange={handleTabChange} />
                                        ) : (
                                            <Tabs
                                                variant="scrollable"
                                                value={validTabValuesWithoutNetworks.includes(selectedTab) ? selectedTab : 'COMMON'}
                                                onChange={(event, value) => handleTabChange(value)}
                                                textColor={'inherit'}
                                                indicatorColor={'secondary'}
                                            >
                                                <Tab label={<FormattedMessage id={'publishing.tabs.content'} />} value="COMMON" />

                                                {selectedPostGroup && !isUserExternal && (
                                                    <Tab label={<FormattedMessage id="general.internal-activity-log" />} value="INTERNAL" />
                                                )}
                                                {selectedPostGroup && (
                                                    <Tab
                                                        label={
                                                            <FormattedMessage id={isUserExternal ? 'general.activity-log' : 'general.external-activity-log'} />
                                                        }
                                                        value="EXTERNAL"
                                                    />
                                                )}
                                            </Tabs>
                                        )}

                                        {!customizePostsByNetwork && datasourceTypes.length > 1 && (
                                            <IndividualizeNetworksButton
                                                handleCustomizeClick={handleCustomizeClick}
                                                datasourceTypes={datasourceTypes}
                                                disabled={disabled}
                                            />
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <TabPanel value={selectedTab} index="COMMON" keepMounted={!customizePostsByNetwork}>
                                        <CommonPostFormSection disabled={disabled} datasourcesByType={datasourcesByType} />
                                    </TabPanel>
                                    <TabPanel
                                        value={selectedTab}
                                        index="FACEBOOK_PAGE"
                                        keepMounted={customizePostsByNetwork && datasourceTypes.includes('FACEBOOK_PAGE')}
                                    >
                                        <CommonPostFormSection disabled={disabled} channelType={'FACEBOOK_PAGE'} datasourcesByType={datasourcesByType} />
                                    </TabPanel>
                                    <TabPanel
                                        value={selectedTab}
                                        index="INSTAGRAM_ACCOUNT"
                                        keepMounted={customizePostsByNetwork && datasourceTypes.includes('INSTAGRAM_ACCOUNT')}
                                    >
                                        <CommonPostFormSection disabled={disabled} channelType={'INSTAGRAM_ACCOUNT'} datasourcesByType={datasourcesByType} />
                                    </TabPanel>
                                    <TabPanel
                                        value={selectedTab}
                                        index="LINKED_IN"
                                        keepMounted={customizePostsByNetwork && datasourceTypes.includes('LINKED_IN')}
                                    >
                                        <CommonPostFormSection disabled={disabled} channelType={'LINKED_IN'} datasourcesByType={datasourcesByType} />
                                    </TabPanel>
                                    <TabPanel
                                        value={selectedTab}
                                        index="TIKTOK_ACCOUNT"
                                        keepMounted={customizePostsByNetwork && datasourceTypes.includes('TIKTOK_ACCOUNT')}
                                    >
                                        <CommonPostFormSection disabled={disabled} channelType={'TIKTOK_ACCOUNT'} datasourcesByType={datasourcesByType} />
                                    </TabPanel>

                                    <TabPanel index="INTERNAL" value={selectedTab} keepMounted={false}>
                                        {selectedPostGroup && <PublishingPostGroupActivities postGroup={selectedPostGroup} />}
                                    </TabPanel>
                                    <TabPanel index="EXTERNAL" value={selectedTab} keepMounted={false}>
                                        {selectedPostGroup && <PublishingPostGroupActivities postGroup={selectedPostGroup} isExternal={true} />}
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Box>

                        <PublishingFormActions formDisabled={disabled} />
                    </Box>

                    <PublishingPostPreview currentTypeTab={selectedNetwork || 'COMMON'} postPreviewDevice={postPreviewDevice} />
                </Box>

                <PublishingFormNavigationBlocker />
            </form>
        </ContentPanel>
    )
}
