import { FC } from 'react'
import { List } from '@mui/material'
import { Event, TableChartOutlined } from '@mui/icons-material'
import { SecondaryNavigationButton } from '../SecondaryNavigationButton'

type PublishingNavigationProps = {}

export const PublishingNavigation: FC<PublishingNavigationProps> = () => {
    return (
        <List>
            <SecondaryNavigationButton path="/publishing/calendar" title="features.publishing.calendar" icon={<Event />} />
            <SecondaryNavigationButton path="/publishing/list" title="features.publishing.list-view" icon={<TableChartOutlined />} />
            {/*<SecondaryNavigationButton path="/publishing/publish" title="Post veröffentlichen" icon={<UploadFile />} />*/}
        </List>
    )
}
