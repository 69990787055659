import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type FacebookCommentProps = SvgIconProps

export const FacebookComment: FC<FacebookCommentProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <g>
                    <path
                        style={{ fill: 'none', stroke: props.htmlColor }}
                        d="M6.7,18H6.4v-3.3H5c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h10c1.7,0,3,1.3,3,3v6.7c0,1.7-1.3,3-3,3
		h-5L6.7,18z"
                    />
                    <rect x="0.5" y="0.5" style={{ fill: 'none', stroke: props.htmlColor, strokeOpacity: 0 }} width="19" height="19" />
                </g>
            </svg>
        </SvgIcon>
    )
}
