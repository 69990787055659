import { PublishingMedia } from '../PublishingMedia'
import { useImage } from '../../core/hooks/useImage'
import { useDispatch } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { useEffect, useRef } from 'react'
import { setThumbnailForCommonFiles } from '../PublishingSlice'

export const useVideoWithThumbnail = (file: PublishingMedia | undefined) => {
    const { objectUrl } = useImage(file?._links.file.href + '&cache=bust')
    const dispatch = useDispatch<HmstrDispatch>()
    const playerRef = useRef<any>(null)

    const getRenderedHeight = () => {
        const ratio = 455 / (file?.width || 1)
        return ratio * (file?.height || 1)
    }

    const isCloseToPortrait = (threshhold: number) => {
        const ratio = 455 / (file?.width || 1)

        return 9 / 16 - ratio <= threshhold
    }

    const generateVideoThumbnail = () => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas')
            const video = playerRef.current

            video.onloadeddata = () => {
                let ctx = canvas.getContext('2d')

                canvas.width = video.videoWidth
                canvas.height = video.videoHeight

                if (ctx) {
                    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
                }
                return resolve(canvas.toDataURL('image/png'))
            }
        })
    }

    useEffect(() => {
        if (objectUrl && playerRef.current && file) {
            generateVideoThumbnail().then((res) => {
                dispatch(setThumbnailForCommonFiles({ file_id: file.id, thumbnail: res as string }))
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objectUrl])
    return { objectUrl, playerRef, getRenderedHeight, isCloseToPortrait }
}
