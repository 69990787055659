import { FC } from 'react'
import { Form } from 'react-final-form'
import { Box, Button, CircularProgress, DialogActions, Grid } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { changeAccountWizardState, getAccountWizardState } from '../SettingsSlice'
import { BillingInfoFormFields } from './BillingInfoFormFields'
import { Account, BillingInfo } from '../accounts/Account'
import { useHasAccountPermission } from '../../core/hooks/useHasPermission'

type BillingInfoFormProps = {
    initialValues?: Partial<Account>
    onSubmit: (billingInfo: BillingInfo) => any
    onBack?: () => any
    inWizard?: boolean
}

export const BillingInfoForm: FC<BillingInfoFormProps> = (props) => {
    const { onSubmit, initialValues, inWizard, onBack } = props
    const dispatch = useDispatch()
    const wizardStep = useSelector(getAccountWizardState)

    const canManageBilling = useHasAccountPermission('account.manage_billing', initialValues?.id)

    const handleContinue = (pristine: boolean, invalid: boolean, handleSubmit: any) => {
        if (invalid || !pristine) {
            handleSubmit()
        } else {
            dispatch(changeAccountWizardState(wizardStep + 1))
        }
    }

    return (
        <Form<Account>
            onSubmit={(values) => onSubmit(values.billing_info)}
            initialValues={{ ...initialValues }}
            render={({ handleSubmit, pristine, submitting, invalid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ mt: 4, minHeight: 500 }}>
                            <Grid container spacing={2}>
                                <BillingInfoFormFields disableCustomerId={true} disableHint={inWizard} />
                            </Grid>
                        </Box>

                        <DialogActions sx={{ p: 0, mt: 2 }}>
                            {inWizard && (
                                <Button disabled={submitting} onClick={() => (onBack ? onBack() : {})}>
                                    <FormattedMessage id="general.back" />
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleContinue(pristine, invalid, handleSubmit)}
                                disabled={submitting || !canManageBilling}
                                startIcon={submitting ? <CircularProgress color="inherit" size={20} /> : undefined}
                            >
                                <FormattedMessage id={inWizard ? 'billing.continue-with-payment' : 'general.save'} />
                            </Button>
                        </DialogActions>
                    </form>
                )
            }}
        />
    )
}
