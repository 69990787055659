import { FC, useEffect, useState } from 'react'
import { Box, Button, DialogActions, DialogContent, Link } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { hidePreview } from './PostsSlice'
import { Loading } from '../../common/loading/Loading'
import { CommonPostData } from './CommonPostData'
import { Fallback } from '../../common/fallback/Fallback'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import * as React from 'react'

type FacebookPreviewProps = {
    post?: CommonPostData
}

export const FacebookPostPreview: FC<FacebookPreviewProps> = ({ post }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        FB.XFBML.parse(undefined, () => {
            setLoading(false)
            setTimeout(() => {
                let facebookContainer = document.getElementById('facebook-post-preview')
                setError(!facebookContainer?.hasChildNodes() ?? false)
            }, 300)
        })
    }, [post])

    const generateFacebookEmbedLink = () => {
        return `https://facebook.com/${post?.post_id.split('_')[0]}/posts/${post?.post_id.split('_')[1]}`
    }

    return (
        <>
            <DialogContent
                sx={{
                    padding: loading ? 0 : undefined,
                    height: loading ? 0 : undefined,
                }}
            >
                <Box width={500}>
                    <Fallback
                        condition={post?.type !== 'FACEBOOK'}
                        marginTop={4}
                        messageId="facebook.preview.only-facebook"
                        actionButton={
                            <Link href={post?.perma_link} target="_blank" style={{ display: 'flex', justifyContent: 'center' }}>
                                <OpenInNewIcon style={{ margin: '0 8px 0 0' }} />
                                <FormattedMessage id="general.open-link" />
                            </Link>
                        }
                    >
                        {loading && <Loading />}
                        <div id="facebook-post-preview" className="fb-post" data-href={generateFacebookEmbedLink()} data-width={500}>
                            {error && <FormattedMessage id="facebook.preview.could-not-load" />}
                        </div>
                    </Fallback>
                </Box>
            </DialogContent>
            {!loading && (
                <DialogActions>
                    <Button onClick={() => dispatch(hidePreview())}>
                        <FormattedMessage id="general.close" />
                    </Button>
                </DialogActions>
            )}
        </>
    )
}
