import { styled } from '@mui/material'
import { HmstrState } from '../../core/Store'
import { drawerWidth } from '../../navigation/Drawer.styles'
import { primaryMobileNavHeight } from '../../navigation/PrimaryNavigationMobile'
import { isMobileDevice } from '../../core/theme/helper'

type StyledMainProps = {
    drawerState: HmstrState['core']['drawerState']
    noSecondaryNav?: boolean
    isOnboarding?: boolean
}

const shouldForwardProp = (propName: string) => !['drawerState', 'noSecondaryNav', 'white', 'isOnboarding'].includes(propName)
const isMobile = isMobileDevice()

export const StyledMainElement = styled('main', {
    shouldForwardProp,
})<StyledMainProps>(({ drawerState, isOnboarding }) => ({
    height: isMobile && !isOnboarding ? `calc(100dvh - ${primaryMobileNavHeight}px)` : '100dvh',
    width: isMobile ? '100dvw' : `calc(100dvw - ${drawerWidth - (drawerState === 'collapsed' ? 220 : 0)}px)`,
    padding: 0,
    transitionProperty: 'width, margin-left',
    transitionDuration: '.2s',
    transitionTimingFunction: 'ease-in-out',
    overflowY: 'hidden',
}))
