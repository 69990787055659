import * as React from 'react'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedDatasourceIds, getSelectedEndDate, getSelectedStartDate } from '../PublishingSlice'
import { PublishingList } from '../PublishingList'
import { PublishingListViewToolbar } from './PublishingListViewToolbar'
import { StyledMainElement } from '../../common/content-panel/ContentPanel.styles'
import { useDrawerState } from '../../core/hooks/useDrawerState'
import { Box, useTheme } from '@mui/material'
import { primaryMobileNavHeight } from '../../navigation/PrimaryNavigationMobile'
import { getIsMobile, isDarkMode, setAppThemeColor } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { replaceUrlParams } from '../../core/helpers/replace-url-params'

type PublishingListViewProps = {}

export const PublishingListView: FC<PublishingListViewProps> = () => {
    const dispatch = useDispatch()
    const drawerState = useDrawerState()
    const theme = useTheme()
    const darkMode = useSelector(isDarkMode)
    const isMobile = useSelector(getIsMobile)
    const startDate = useSelector(getSelectedStartDate)
    const endDate = useSelector(getSelectedEndDate)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)

    useEffectWithIdComparison(() => {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.set('startDate', startDate)
        urlParams.set('endDate', endDate)
        if (selectedDatasourceIds.length > 0) {
            urlParams.set('selected_datasources', selectedDatasourceIds.join(','))
        } else {
            urlParams.delete('selected_datasources')
        }
        replaceUrlParams(urlParams)
    }, [startDate, endDate, selectedDatasourceIds.toString()])

    useEffect(() => {
        dispatch(setAppThemeColor(theme.palette.background.default))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [darkMode])

    return (
        <StyledMainElement drawerState={drawerState}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                maxHeight={`calc(100dvh - ${isMobile ? primaryMobileNavHeight : 0}px)`}
                overflow={'hidden'}
                height={'100%'}
            >
                <PublishingListViewToolbar />
                <Box
                    height={'100%'}
                    display={'flex'}
                    flexShrink={1}
                    sx={{
                        overflowY: 'hidden',
                        overflowX: 'scroll',
                        overscrollBehavior: 'contain !important',
                        touchActions: 'none !important',
                    }}
                    flexDirection={'column'}
                >
                    <PublishingList />
                </Box>
            </Box>
        </StyledMainElement>
    )
}
