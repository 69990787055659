import { FC } from 'react'
import { AccountFormValues } from './AccountFormValues'
import { getQuantityForAdditionalFeature } from '../billing/BillingUtils'
import { AccountSubscriptionSection } from './AccountSubscriptionSection'
import { Alert, Box, Button, CircularProgress, DialogActions, Typography } from '@mui/material'
import { getSelectedAccount } from '../../core/slices/CoreSlice'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedAccountSubscription } from '../SettingsSlice'
import { getPaymentMethods, getProducts } from '../../core/slices/DataSlice'
import { Loading } from '../../common/loading/Loading'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { HmstrDispatch } from '../../core/Store'
import { fetchSubscriptionById } from '../billing/BillingActions'
import { Error, Info } from '@mui/icons-material'
import { FORM_ERROR } from 'final-form'
import { Account } from './Account'
import moment from 'moment'

type AccountUpdateSubscriptionFormProps = {
    handleSubmit: (values: AccountFormValues) => any
    handleClose?: () => any
    initialValues?: Partial<AccountFormValues>
}

const packageHierarchy: Account['package_type'][] = ['free', 'basic', 'pro', 'enterprise']

export const AccountUpdateSubscriptionForm: FC<AccountUpdateSubscriptionFormProps> = ({ handleSubmit, handleClose, initialValues }) => {
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const account = useSelector(getSelectedAccount)
    const subscription = useSelector(getSelectedAccountSubscription)
    const products = useSelector(getProducts)
    const paymentMethods = useSelector(getPaymentMethods)

    useEffectWithIdComparison(() => {
        if (!subscription) {
            dispatch(fetchSubscriptionById(account))
        }
    }, [subscription])

    if (!subscription) {
        return <Loading />
    }

    const isDowngradePossible = (values: AccountFormValues): JSX.Element | undefined => {
        const baseProduct = Object.values(products).find((p) => p.metadata.package === values.package_type)

        if (!baseProduct) {
            return <FormattedMessage id="product-not-found" />
        }

        const newUserLimit = baseProduct.metadata.users + values.additional_user
        const newDatasourceLimit = baseProduct.metadata.datasources + values.additional_datasource
        const newCtLimit = (baseProduct.metadata.conversion_tracking || 0) + values.conversion_tracking

        if (newUserLimit < account.user_count || newDatasourceLimit < account.data_source_count || newCtLimit < account.conversion_tracking_count) {
            return (
                <Box>
                    <Typography fontWeight={600}>
                        <FormattedMessage id="billing.downgrade-not-possible" />
                    </Typography>

                    <Typography component="ul">
                        {newUserLimit < account.user_count && (
                            <li>
                                <FormattedMessage
                                    id="billing.downgrade-not-possible-reason"
                                    values={{
                                        current: account.user_count + ' ' + intl.formatMessage({ id: 'accounts.users' }),
                                        diff: account.user_count - newUserLimit,
                                    }}
                                />
                            </li>
                        )}
                        {newDatasourceLimit < account.data_source_count && (
                            <li>
                                <FormattedMessage
                                    id="billing.downgrade-not-possible-reason"
                                    values={{
                                        current: account.data_source_count + ' ' + intl.formatMessage({ id: 'general.datasources' }),
                                        diff: account.data_source_count - newDatasourceLimit,
                                    }}
                                />
                            </li>
                        )}
                        {newCtLimit < account.conversion_tracking_count && (
                            <li>
                                <FormattedMessage
                                    id="billing.downgrade-not-possible-reason"
                                    values={{
                                        current: account.conversion_tracking_count + ' ' + intl.formatMessage({ id: 'conversion-tracking.title' }),
                                        diff: account.conversion_tracking_count - newCtLimit,
                                    }}
                                />
                            </li>
                        )}
                    </Typography>
                </Box>
            )
        }

        return undefined
    }

    return (
        <Form<AccountFormValues>
            initialValues={
                initialValues || {
                    package_type: account.status === 'TRIAL_EXPIRED' || account.package_type === 'trial' ? 'basic' : account.package_type,
                    interval: subscription.items.data[0].plan.interval,
                    additional_user: getQuantityForAdditionalFeature(subscription, products, 'ADDITIONAL_USER'),
                    additional_datasource: getQuantityForAdditionalFeature(subscription, products, 'ADDITIONAL_DATASOURCE'),
                    conversion_tracking: getQuantityForAdditionalFeature(subscription, products, 'CONVERSION_TRACKING'),
                }
            }
            onSubmit={handleSubmit}
            render={({ handleSubmit, submitting, values, initialValues, submitErrors }) => {
                const initialPackageIndex = packageHierarchy.indexOf((initialValues as AccountFormValues).package_type)
                const newPackageIndex = packageHierarchy.indexOf((values as AccountFormValues).package_type)
                const isDowngrade = initialPackageIndex > newPackageIndex
                const downgradeError = isDowngradePossible(values)

                return (
                    <form onSubmit={handleSubmit}>
                        <AccountSubscriptionSection />

                        {submitErrors && (
                            <Box mt={2}>
                                <Alert icon={<Error />} color="error">
                                    <Typography>
                                        <FormattedMessage id={submitErrors[FORM_ERROR]} />
                                    </Typography>
                                </Alert>
                            </Box>
                        )}

                        {account.status !== 'TRIAL_EXPIRED' && (isDowngrade || !!downgradeError) && (
                            <Box mt={2}>
                                <Alert icon={<Info />} color={downgradeError ? 'error' : 'info'}>
                                    {isDowngrade && !downgradeError ? (
                                        <Typography>
                                            <FormattedMessage
                                                id="billing.downgrade-description"
                                                values={{
                                                    date: moment.unix(subscription.current_period_end).format('L'),
                                                }}
                                            />
                                        </Typography>
                                    ) : (
                                        downgradeError
                                    )}
                                </Alert>
                            </Box>
                        )}

                        <DialogActions sx={{ mr: -1 }}>
                            {handleClose && (
                                <Button variant="text" onClick={handleClose}>
                                    <FormattedMessage id="general.close" />
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                disabled={submitting || !!downgradeError}
                                startIcon={submitting ? <CircularProgress color="inherit" size={20} /> : undefined}
                            >
                                {Object.values(paymentMethods).length > 0 || values.package_type === 'free' ? (
                                    <FormattedMessage id={values.package_type === 'free' ? 'billing.downgrade-to-free' : 'billing.finalize'} />
                                ) : (
                                    <FormattedMessage id="general.next" />
                                )}
                            </Button>
                        </DialogActions>
                    </form>
                )
            }}
        />
    )
}
