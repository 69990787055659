import { FC, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { stripePromise } from '../../index'
import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { cancelSetupIntent, createSetupIntent } from './BillingActions'
import { SetupIntent, Stripe, StripeElements } from '@stripe/stripe-js'
import { Loading } from '../../common/loading/Loading'
import { Error, Save } from '@mui/icons-material'
import { getSelectedAccount, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'

type BillingPaymentMethodAddDialogProps = {
    open: boolean
    onClose: () => any
}

export const BillingPaymentMethodAddDialog: FC<BillingPaymentMethodAddDialogProps> = (props) => {
    const { open, onClose } = props
    const dispatch = useDispatch<HmstrDispatch>()
    const [setupIntent, setSetupIntent] = useState<SetupIntent | undefined>()
    const [error, setError] = useState<string | undefined>()
    const account = useSelector(getSelectedAccount)
    const [paymentInProcess, setPaymentInProcess] = useState(false)

    useEffectWithIdComparison(() => {
        if (!setupIntent) {
            dispatch(createSetupIntent(account)).then((action: any) => {
                setSetupIntent(action.payload)
            })
        }

        return () => {
            if (setupIntent) {
                dispatch(cancelSetupIntent(setupIntent))
            }
        }
    }, [setupIntent])

    const handleSubmit = async (stripe: Stripe | null, elements: StripeElements | null) => {
        if (stripe && elements) {
            setPaymentInProcess(true)
            const { error } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: window.location.href,
                },
            })

            if (error) {
                console.error(error.message)
                setError(error.message)
                setPaymentInProcess(false)
            } else {
                dispatch(showSuccessSnackbar('billing.payment-methods.added-successfully'))
                onClose()
                setPaymentInProcess(false)
            }
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" keepMounted={false}>
            <DialogTitle>
                <FormattedMessage id="billing.payment-methods.add" />
            </DialogTitle>

            {error && (
                <Box pr={3} pl={3}>
                    <Alert color="error" icon={<Error />}>
                        {error}
                    </Alert>
                </Box>
            )}

            <Box>
                {setupIntent && setupIntent.client_secret ? (
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret: setupIntent.client_secret,
                        }}
                    >
                        <ElementsConsumer>
                            {({ stripe, elements }) => {
                                return (
                                    <>
                                        <DialogContent>
                                            <Box minHeight={650} minWidth={552}>
                                                {stripe && elements && <PaymentElement />}
                                            </Box>
                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={onClose}>
                                                <FormattedMessage id="general.close" />
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={paymentInProcess}
                                                startIcon={paymentInProcess ? <CircularProgress color="inherit" size={20} /> : <Save />}
                                                onClick={() => handleSubmit(stripe, elements)}
                                            >
                                                <FormattedMessage id="general.save" />
                                            </Button>
                                        </DialogActions>
                                    </>
                                )
                            }}
                        </ElementsConsumer>
                    </Elements>
                ) : (
                    <Box mb={4}>
                        <Loading />
                    </Box>
                )}
            </Box>
        </Dialog>
    )
}
