import { FC } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { AccountSubscriptionSection } from './AccountSubscriptionSection'
import { changeAccountWizardState, getAccountWizardState } from '../SettingsSlice'
import { Alert, Box, Button, CircularProgress, DialogActions, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { FORM_ERROR, FormApi } from 'final-form'
import { AccountFormValues } from './AccountFormValues'
import { Error } from '@mui/icons-material'
import { AccountInfoSection } from './AccountInfoSection'

type CreateAccountFormProps = {
    initialValues?: Partial<AccountFormValues>
    onSubmit: (createAccountRequest: AccountFormValues) => any
}

export const AccountCreateForm: FC<CreateAccountFormProps> = ({ onSubmit, initialValues }) => {
    const dispatch = useDispatch()
    const activeStep = useSelector(getAccountWizardState)

    const handleNext = (form: FormApi<AccountFormValues>, errors: any) => {
        if (activeStep === 0) {
            form.blur('name')
            form.blur('customer_id')
            form.blur('billing_info.email' as any)
            form.blur('billing_info.first_name' as any)
            form.blur('billing_info.last_name' as any)
            form.blur('billing_info.address.street' as any)
            form.blur('billing_info.address.street_no' as any)
            form.blur('billing_info.address.zip_code' as any)
            form.blur('billing_info.address.city' as any)
            form.blur('billing_info.address.country_code' as any)
            form.blur('billing_info.phone_number' as any)

            if (Object.keys(errors).length === 0) {
                dispatch(changeAccountWizardState(activeStep + 1))
            }
        } else {
            form.submit()
        }
    }

    return (
        <Form<AccountFormValues>
            onSubmit={onSubmit}
            initialValues={initialValues}
            keepDirtyOnReinitialize={true}
            render={({ errors, submitErrors, form, submitting }) => {
                return (
                    <>
                        <Box sx={{ p: 4, mt: 2, minHeight: 500 }}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                }}
                            >
                                {activeStep === 0 && <AccountInfoSection />}
                                {activeStep === 1 && <AccountSubscriptionSection />}
                            </form>
                        </Box>

                        {submitErrors && activeStep === 2 && (
                            <Box pl={4} pr={4}>
                                <Alert icon={<Error />} color="error">
                                    <Typography>
                                        <FormattedMessage id={submitErrors[FORM_ERROR]} />
                                    </Typography>
                                </Alert>
                            </Box>
                        )}

                        <DialogActions sx={{ mt: 2, mb: 2, pr: activeStep === 1 ? 4 : 0 }}>
                            <Button color="inherit" onClick={() => dispatch(changeAccountWizardState(activeStep - 1))} disabled={activeStep === 0}>
                                <FormattedMessage id="general.back" />
                            </Button>

                            <Button
                                startIcon={submitting ? <CircularProgress color="inherit" size={20} /> : undefined}
                                disabled={activeStep === 2 && submitting}
                                variant={activeStep === 0 ? undefined : 'contained'}
                                color={activeStep === 0 ? 'inherit' : 'secondary'}
                                onClick={() => handleNext(form, errors)}
                            >
                                <FormattedMessage id={activeStep === 0 ? 'general.continue' : 'billing.finalize'} />
                            </Button>
                        </DialogActions>
                    </>
                )
            }}
        />
    )
}
