import React, { FC, PropsWithChildren } from 'react'
import { Box, darken, Paper, useTheme } from '@mui/material'
import { SignalCellularAlt, Wifi } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getIsMobile, isDarkMode } from '../../core/slices/CoreSlice'
import { HmstrColors } from '../../core/theme/hmstr-theme'
import { IOSBatteryIcon } from '../../common/icons/IOSBatteryIcon'

type PostPreviewMockupDeviceProps = {
    device: 'desktop' | 'mobile'
    time: string
    story?: boolean
    tiktokPreview?: boolean
}

export const PostPreviewMockupDevice: FC<PropsWithChildren<PostPreviewMockupDeviceProps>> = ({ children, device, time, story, tiktokPreview }) => {
    const darkmode = useSelector(isDarkMode)
    const theme = useTheme()
    const isMobile = useSelector(getIsMobile)

    return device === 'desktop' ? (
        <Paper
            sx={
                !isMobile
                    ? {
                          p: 2,
                          mb: '1px',
                          mx: 'auto',
                          width: 450,
                          minWidth: 450,
                          overflow: 'auto',
                          background: darkmode ? '#242526' : undefined,
                      }
                    : {
                          p: 2,
                          mb: '1px',
                          mx: 'auto',
                          width: '100%',
                          maxWidth: '100%',
                          overflow: 'auto',
                          background: darkmode ? '#242526' : undefined,
                      }
            }
        >
            {children}
        </Paper>
    ) : (
        <Box width={isMobile ? '100%' : 'calc(100% - 32px)'}>
            <Box
                sx={
                    isMobile
                        ? {
                              position: 'relative',
                              mx: 'auto',
                              width: '100%',
                              maxWidth: isMobile ? 'calc(80dvh * 1179 / 2300)' : '100%',
                              overflow: 'auto',
                              border: '10px solid black',
                              aspectRatio: '1179 / 2300',
                              borderRadius: '45px',
                              boxShadow: `5px 5px 10px ${darken(theme.palette.background.default, 0.2)}`,
                              display: 'flex',
                              flexDirection: 'column',
                              '&::-webkit-scrollbar': {
                                  display: 'none',
                              },
                              scrollbarWidth: 'none',
                              msOverflowStyle: 'none',
                              color: story ? '#ffffff' : undefined,
                              background: story ? HmstrColors.PostPreviewColorsDarkMode.background : undefined,
                              overscrollBehavior: 'auto !important',
                              zIndex: 0,
                          }
                        : {
                              position: 'relative',
                              mx: 'auto',
                              width: 370,
                              minWidth: 370,
                              overflow: 'auto',
                              border: '10px solid black',
                              aspectRatio: '1179 / 2300',
                              borderRadius: '45px',
                              boxShadow: `5px 5px 10px ${darken(theme.palette.background.default, 0.2)}`,
                              display: 'flex',
                              flexDirection: 'column',
                              '&::-webkit-scrollbar': {
                                  display: 'none',
                              },
                              scrollbarWidth: 'none',
                              msOverflowStyle: 'none',
                              color: story ? '#ffffff' : undefined,
                              background: story ? HmstrColors.PostPreviewColorsDarkMode.background : undefined,
                          }
                }
            >
                <Box
                    sx={{
                        mb: story ? undefined : 2,
                        background: 'black',
                        height: '30px',
                        minHeight: '30px',
                        width: 1 / 2,
                        mx: 'auto',
                        borderRadius: '0px 0px 20px 20px',
                        display: 'flex',
                        opacity: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        marginBottom: story ? '-30px' : undefined,
                    }}
                >
                    <Box
                        sx={{
                            mt: '-5px',
                            height: '10px',
                            width: 2 / 5,
                            borderRadius: '10px',
                            border: '1px solid lightgrey',
                            backgroundImage: 'radial-gradient(#fff9 30%, transparent 31%)',
                            backgroundColor: '#131313',
                            backgroundSize: '3px 3px',
                            backgroundRepeat: 'repeat',
                            position: 'relative',
                            '&::after': {
                                content: '""',
                                display: 'block',
                                height: '15px',
                                width: '15px',
                                mt: '-2.5px',
                                background: 'black',
                                ml: '120%',
                                border: '1px solid lightgrey',
                                position: 'absolute',
                                borderRadius: '100%',
                                backgroundImage: 'radial-gradient(transparent 40%, #fff 100%), radial-gradient(#666 30%, transparent 31%)',
                            },
                        }}
                    ></Box>
                </Box>
                <Box
                    sx={{
                        position: 'sticky',
                        top: '0px',
                        zIndex: 1,
                        mt: -6,
                        mb: 4,
                        '&::after': {
                            content: "''",
                            height: '24px',
                            background: story && !tiktokPreview ? '#0008' : tiktokPreview ? '#0003' : undefined,
                            width: '100%',
                            position: 'absolute',
                            zIndex: -1,
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 1,
                            left: 0,
                            pl: 3.5,
                            pt: 0.5,
                            fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
                            fontSize: '1em',
                            fontWeight: '600',
                        }}
                    >
                        {time}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 1,
                            right: 0,
                            pr: 2.5,
                            pt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '3px',
                        }}
                    >
                        <SignalCellularAlt sx={{ fontSize: '1em' }} />
                        <Wifi sx={{ fontSize: '1em' }} />
                        <IOSBatteryIcon sx={{ fontSize: '1.5em' }} />
                    </Box>
                </Box>
                <Box
                    p={story ? undefined : 2}
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {children}
                </Box>
                <Box
                    sx={{
                        visibility: tiktokPreview ? 'hidden' : undefined,
                        position: 'sticky',
                        width: 1 / 3,
                        height: '6px',
                        minHeight: '6px',
                        transform: 'translateX(100%)',
                        background: '#bdd4e7',
                        backgroundImage: 'linear-gradient(315deg, #eee 0%, #ccc 74%)',
                        bottom: '6px',
                        mt: '-6px',
                        borderRadius: '10px',
                        filter: story ? undefined : 'brightness(0.5)',
                    }}
                ></Box>
            </Box>
        </Box>
    )
}
