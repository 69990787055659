import { FC, useEffect, useMemo, useState } from 'react'
import { Alert, alpha, Box, Grid, ImageList, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDropzone } from 'react-dropzone'
import { useForm, useFormState } from 'react-final-form'
import { PublishingFileUploadThumbnail } from './PublishingFileUploadThumbnail'
import * as _ from 'lodash'
import Compressor from 'compressorjs'
import { PublishingEditFileDialog } from './PublishingEditFileDialog'
import { PublishingMedia } from './PublishingMedia'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { updateMedia, uploadMedia } from './PublishingActions'
import { getSelectedProject, showErrorSnackbar } from '../core/slices/CoreSlice'
import { getMedia, IdMap } from '../core/slices/DataSlice'
import {
    getCurrentlyUploadingFileCount,
    getSelectedPostGroupIncludesPlannedByFacebook,
    setErrorsForCommonFiles,
    unsetErrorsForCommonFilesByNetwork,
} from './PublishingSlice'
import { PublishingLoadingThumbnail } from './PublishingLoadingThumbnail'
import { PublishingFormValues } from './PublishingForm'
import { imageLimitations, validatePublishingImage } from './FormValidators'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { FormState, getIn } from 'final-form'
import { UploadMediaRequest } from './UploadMediaRequest'

type PublishingFileUploadProps = {
    name: string
    dataSourceTypes: IdMap<ProjectDatasource[]>
    disableValidation?: boolean
    disabled?: boolean
    isLinkPreview?: boolean
}

export const PublishingFileUpload: FC<PublishingFileUploadProps> = ({ name, dataSourceTypes, disableValidation, disabled, isLinkPreview }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const theme = useTheme()
    const currentlyUploadingFileCount = useSelector(getCurrentlyUploadingFileCount)
    const project = useSelector(getSelectedProject)
    const form = useForm()
    const fieldState = form.getFieldState(name)
    const formState = useFormState<PublishingFormValues>()
    const isCustomizePostsEnabled = formState.values.customize_posts_by_network
    const singleSocialNetworkSelected = (formState.values.data_source_ids?.length || 0) === 1
    const hasPostsPlannedByFacebook = useSelector(getSelectedPostGroupIncludesPlannedByFacebook)
    const selectedDatasource = !singleSocialNetworkSelected ? undefined : project?.data_sources.find((ds) => ds.id === formState.values.data_source_ids[0])
    const isInstagramStoryActivated = getIn(
        formState.values,
        isCustomizePostsEnabled ? 'postByType.INSTAGRAM_ACCOUNT.ig_publish_as_story' : 'common_post.ig_publish_as_story'
    )
    const media = useSelector(getMedia)
    const fileIds: string[] =
        typeof _.get(form.getState().values, name) === 'string' ? [_.get(form.getState().values, name) || []] : _.get(form.getState().values, name) || []

    const currentFiles = fileIds.map((fileId) => media[fileId]).filter((m) => !!m)
    const [editFile, setEditFile] = useState<PublishingMedia | undefined>()
    const isImage = (file: File) => {
        return file.type.startsWith('image')
    }

    useEffect(() => {
        const errors = currentFiles
            .map((m) => {
                const errorsForImage = validatePublishingImage(
                    currentKeys,
                    intl,
                    form.getState() as unknown as FormState<PublishingFormValues, Partial<PublishingFormValues>>,
                    m,
                    dataSourceTypes,
                    uploadForNetwork(m)
                )
                return { fileId: m.id, error: errorsForImage }
            })
            .filter((e) => !!e.error)
            .filter((val) => (_.isEmpty(val.error) ? undefined : val.error))
        if (errors.length <= 0) {
            currentFiles.map((m) => dispatch(setErrorsForCommonFiles({ [m.id]: undefined })))
        }
        errors.forEach((e) => {
            let mappedErrors: { [file_id: string]: { network?: string; message?: string }[] } = {}
            e.error?.forEach((err) => {
                if (mappedErrors.hasOwnProperty(e.fileId)) {
                    mappedErrors[e.fileId] = [
                        ...mappedErrors[e.fileId],
                        {
                            network: err.network.toString(),
                            message: err.message.toString(),
                        },
                    ]
                } else {
                    mappedErrors[e.fileId] = [
                        {
                            network: err.network.toString(),
                            message: err.message.toString(),
                        },
                    ]
                }
            })
            dispatch(setErrorsForCommonFiles(mappedErrors))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFiles.length, Object.keys(dataSourceTypes).length, isInstagramStoryActivated, fileIds.length])

    const currentKeys = name.includes('common_post') ? 'ALL' : name.replace('postByType.', '').replace('.file_ids', '').replace('.link_preview_file_id', '')

    const convertFileToUploadFile = (file: File, media?: PublishingMedia): Promise<PublishingMedia> => {
        return new Promise((resolve) => {
            if (isImage(file)) {
                new Compressor(file, {
                    maxWidth: 1920,
                    maxHeight: 1920,
                    quality: 0.8,
                    mimeType: 'image/jpeg',
                    success: (result: File) => {
                        const objectUrl = URL.createObjectURL(result)
                        const img = new Image()
                        img.onload = () => {
                            URL.revokeObjectURL(objectUrl)
                            const mediaRequest: UploadMediaRequest = {
                                project_id: project!.id,
                                name: file.name,
                                height: img.naturalHeight,
                                width: img.naturalWidth,
                                category: 'IMAGE' as PublishingMedia['category'],
                                mime_type: result.type,
                                file: result,
                                file_size: result.size,
                                link_preview: !!isLinkPreview,
                            }

                            let action

                            if (media) {
                                action = dispatch(updateMedia({ media, request: mediaRequest }))
                            } else {
                                action = dispatch(uploadMedia(mediaRequest))
                            }

                            action.then((action) => resolve(action.payload as PublishingMedia))
                        }
                        img.src = objectUrl
                    },
                    error: (error) => {
                        dispatch(showErrorSnackbar('publishing.errors.file-upload'))
                        console.error(error.message)
                    },
                })
            } else {
                const objectUrl = URL.createObjectURL(file)
                const video = document.createElement('video')
                video.addEventListener('loadedmetadata', () => {
                    URL.revokeObjectURL(objectUrl)
                    const mediaRequest = {
                        project_id: project!.id,
                        name: file.name,
                        category: 'VIDEO' as PublishingMedia['category'],
                        mime_type: file.type,
                        file: file,
                        width: video.videoWidth,
                        height: video.videoHeight,
                        file_size: file.size,
                        duration: video.duration,
                        link_preview: false,
                    }

                    let action

                    if (media) {
                        action = dispatch(updateMedia({ media, request: mediaRequest }))
                    } else {
                        action = dispatch(uploadMedia(mediaRequest))
                    }

                    action.then((action) => resolve(action.payload as PublishingMedia))
                })
                video.src = objectUrl
            }
        })
    }

    const getAcceptOptions = ():
        | { 'image/*': string[] }
        | { 'image/jpeg': string[]; 'image/webp': string[] }
        | {
              'video/*': string[]
          }
        | undefined => {
        const postTypeField =
            currentKeys === 'ALL'
                ? formState.values.common_post.post_type
                : formState.values.postByType[currentKeys as 'FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN' | 'TIKTOK_ACCOUNT']?.post_type
        const includesTikTok = _.map(
            _.flatten(_.map(dataSourceTypes, (dstype) => dstype)),
            (datasource: ProjectDatasource) => datasource.type === 'TIKTOK_ACCOUNT'
        ).includes(true)

        if (postTypeField === 'IMAGE' || postTypeField === 'LINK') {
            return { 'image/*': [] }
        } else if (postTypeField === 'VIDEO') {
            return { 'video/*': [] }
        } else if (postTypeField === 'MULTI_MEDIA') {
            return includesTikTok ? { 'image/jpeg': [], 'image/webp': [] } : { 'video/*': [], 'image/*': [] }
        }
    }
    const getMaxFiles = (): boolean | undefined => {
        const postTypeField =
            currentKeys === 'ALL'
                ? formState.values.common_post.post_type
                : formState.values.postByType[currentKeys as 'FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN' | 'TIKTOK_ACCOUNT']?.post_type
        if (postTypeField === 'IMAGE') {
            return false
        } else if (postTypeField === 'VIDEO' || postTypeField === 'LINK') {
            return false
        } else if (postTypeField === 'MULTI_MEDIA') {
            return true
        } else {
            return true
        }
    }
    const allowMoreUploads = currentFiles.length >= 1 ? getMaxFiles() : true

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (!isDragReject && allowMoreUploads) {
                form.focus(name)
                const mappedFiles = acceptedFiles.map((file) => {
                    return convertFileToUploadFile(file)
                })

                Promise.all(mappedFiles).then((response) => {
                    if (isLinkPreview) {
                        // Only 1 link preview image is possible
                        form.change(name, response.length > 0 ? response[0].id : undefined)
                    } else {
                        form.change(name, [...fileIds, ...response.map((m) => m.id)])
                    }

                    if (!disableValidation) {
                        const errors = response
                            .map((m) => {
                                const errorsForImage = validatePublishingImage(
                                    currentKeys,
                                    intl,
                                    form.getState() as unknown as FormState<PublishingFormValues, Partial<PublishingFormValues>>, // Get current form state since hook wont run until end of the then function
                                    m,
                                    dataSourceTypes,
                                    uploadForNetwork(m)
                                )
                                if (!errorsForImage) {
                                    dispatch(setErrorsForCommonFiles({ [m.id]: undefined }))
                                }
                                return { fileId: m.id, error: errorsForImage }
                            })
                            .filter((e) => !!e.error)
                        errors.map((e) => {
                            let mappedErrors: { [file_id: string]: { network: string; message: string }[] } = {}
                            e.error?.map((err: any) => {
                                if (mappedErrors.hasOwnProperty(e.fileId)) {
                                    mappedErrors[e.fileId] = [
                                        ...mappedErrors[e.fileId],
                                        {
                                            network: err.network.toString(),
                                            message: err.message.toString(),
                                        },
                                    ]
                                } else {
                                    mappedErrors[e.fileId] = [
                                        {
                                            network: err.network.toString(),
                                            message: err.message.toString(),
                                        },
                                    ]
                                }
                                return undefined
                            })
                            dispatch(setErrorsForCommonFiles(mappedErrors))
                            return undefined
                        })
                    }
                })
                form.blur(name)
            }
        },
        accept: getAcceptOptions(),
        multiple: getMaxFiles(),
        disabled: !allowMoreUploads || disabled,
    })

    const uploadForNetwork = (publishingMedia: PublishingMedia) => {
        // get current form state since this function can be called inside a then which returns an old formstate before the hook runs again
        const currentFormState = form.getState()
        if (dataSourceTypes && currentKeys !== 'ALL') {
            const usedInNetworks = _.compact(
                Object.keys(dataSourceTypes)
                    .map((dstype) => {
                        const link_fid =
                            currentFormState.values.postByType[dstype as 'FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN' | 'TIKTOK_ACCOUNT']
                                ?.link_preview_file_id
                        if (
                            currentFormState.values.postByType[dstype as 'FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN' | 'TIKTOK_ACCOUNT']?.file_ids &&
                            currentFormState.values.postByType[
                                dstype as 'FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN' | 'TIKTOK_ACCOUNT'
                            ]?.file_ids.includes(publishingMedia.id)
                        ) {
                            return dstype
                        } else if (link_fid && link_fid === publishingMedia.id) {
                            return dstype
                        }
                        return undefined
                    })
                    .filter((d) => d)
            )
            return usedInNetworks.length > 0 ? usedInNetworks : ['ALL']
        }
        return ['ALL']
    }

    const handleChangeFile = (media: PublishingMedia, file: File) => {
        let mediaPassed: PublishingMedia | undefined = media
        if (hasPostsPlannedByFacebook && isCustomizePostsEnabled) {
            mediaPassed = undefined
        }
        convertFileToUploadFile(file, mediaPassed).then((publishingMedia: PublishingMedia) => {
            const newFileIds = [...fileIds]
            const indexToReplace = newFileIds.findIndex((fId) => fId === media.id)
            newFileIds[indexToReplace] = publishingMedia.id
            form.change(name, newFileIds)
            const errors = [publishingMedia]
                .map((m) => {
                    const errorsForImage = validatePublishingImage(
                        currentKeys,
                        intl,
                        form.getState() as unknown as FormState<PublishingFormValues, Partial<PublishingFormValues>>,
                        m,
                        dataSourceTypes,
                        uploadForNetwork(m)
                    )
                    return { fileId: m.id, error: errorsForImage }
                })
                .filter((e) => !!e.error)
                .filter((val) => (_.isEmpty(val.error) ? undefined : val.error))
            if (errors.length <= 0) {
                dispatch(setErrorsForCommonFiles({ [media.id]: undefined }))
            }
            errors.forEach((e) => {
                let mappedErrors: { [file_id: string]: { network: string; message: string }[] } = {}
                e.error?.forEach((err: any) => {
                    if (mappedErrors.hasOwnProperty(e.fileId)) {
                        mappedErrors[e.fileId] = [
                            ...mappedErrors[e.fileId],
                            {
                                network: err.network.toString(),
                                message: err.message.toString(),
                            },
                        ]
                    } else {
                        mappedErrors[e.fileId] = [
                            {
                                network: err.network.toString(),
                                message: err.message.toString(),
                            },
                        ]
                    }
                })
                dispatch(setErrorsForCommonFiles(mappedErrors))
            })
            setEditFile(undefined)
        })
    }

    const handleRemoveFile = (file: PublishingMedia, network?: string) => {
        let newFileIds = [...fileIds]
        _.remove(newFileIds, (fileId) => {
            return fileId === file.id
        })
        form.change(name, newFileIds)
        if (network === 'ALL') {
            dispatch(setErrorsForCommonFiles({ [file.id]: undefined }))
        } else if (network && network !== 'ALL') {
            dispatch(unsetErrorsForCommonFilesByNetwork({ file_id: file.id, network: network }))
        }
    }

    const handleEditFile = (file: PublishingMedia) => {
        setEditFile(file)
    }

    let listItemCount = currentFiles.length + currentlyUploadingFileCount
    let rowCount = Math.floor(listItemCount / 4) + (listItemCount % 4 === 0 ? 0 : 1)

    const renderThumbnails = () => {
        const thumbnails = currentFiles.map((file, idx) => {
            return (
                <Tooltip
                    key={file.id}
                    title={!isCustomizePostsEnabled && hasPostsPlannedByFacebook ? intl.formatMessage({ id: 'publishing.planned-via-fb-edit-image' }) : ''}
                >
                    <Box key={file.id}>
                        <PublishingFileUploadThumbnail
                            currentKeys={currentKeys}
                            file={file}
                            index={idx}
                            totalElements={currentFiles.length}
                            fieldName={name}
                            disabled={disabled}
                            onDelete={(network?: string) => handleRemoveFile(file, network)}
                            onEdit={() => handleEditFile(file)}
                        />
                    </Box>
                </Tooltip>
            )
        })

        const loadingThumbnails = Array(currentlyUploadingFileCount)
            .fill(1)
            .map((v, i) => <PublishingLoadingThumbnail key={i} />)

        return [...thumbnails, ...loadingThumbnails]
    }

    const baseStyle = {
        borderStyle: 'dashed',
        cursor: 'pointer !important',
        color: theme.palette.text.secondary,
        transition: 'all 0.1s',
    }

    const acceptStyle = {
        borderColor: theme.palette.success.contrastText,
        background: alpha(theme.palette.success.light, 0.8),
        color: theme.palette.success.contrastText,
    }

    const rejectStyle = {
        borderColor: theme.palette.error.contrastText,
        background: alpha(theme.palette.error.light, 0.8),
        color: theme.palette.error.contrastText,
    }
    const disabledStyle = {
        opacity: 0.5,
    }

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(!allowMoreUploads || disabled ? disabledStyle : {}),
        }), // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDragAccept, isDragReject, allowMoreUploads, disabled]
    )
    const isVideo =
        (currentKeys === 'ALL'
            ? formState.values.common_post.post_type
            : formState.values.postByType[currentKeys as 'FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN' | 'TIKTOK_ACCOUNT']?.post_type) === 'VIDEO'

    return (
        <Box>
            <Box
                sx={{ userSelect: 'none' }}
                p={3}
                component={Paper}
                width="100%"
                variant="outlined"
                display="flex"
                alignItems="center"
                justifyContent="center"
                {...getRootProps({ className: 'dropzone', style })}
            >
                <input {...getInputProps()} />
                <Box>
                    <Typography variant="subtitle2" color="inherit" textAlign="center">
                        {isDragReject ? (
                            <FormattedMessage id="publishing.drag-n-drop.rejected" />
                        ) : isDragAccept ? (
                            <FormattedMessage id="publishing.drag-n-drop.accepted" />
                        ) : (
                            <FormattedMessage id="publishing.drag-n-drop" />
                        )}
                    </Typography>

                    {selectedDatasource && isVideo && (
                        <Grid container justifyContent="center" spacing={4}>
                            <Grid item>
                                <Typography fontSize={11}>
                                    <FormattedMessage id="publishing.max-file-size" />: {imageLimitations[selectedDatasource.type].maxFileSizeVideo} MB
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography fontSize={11}>
                                    <FormattedMessage id="publishing.max-file-duration" />: {imageLimitations[selectedDatasource.type].maxVideoDurationSeconds}s
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Box>

            {fieldState?.error && (fieldState?.visited || fieldState?.touched || fieldState?.dirty || fieldState?.initial !== undefined) && (
                <Alert severity={'error'} sx={{ mt: 1 }}>
                    <FormattedMessage id={fieldState?.error} />
                </Alert>
            )}
            <Box>
                <ImageList
                    sx={{
                        height: rowCount * 148 + 4,
                        mt: 1,
                    }}
                    rowHeight={148}
                    cols={4}
                    gap={4}
                >
                    {renderThumbnails()}
                </ImageList>
            </Box>

            <PublishingEditFileDialog
                open={editFile !== undefined}
                media={editFile}
                onClose={() => setEditFile(undefined)}
                onSave={handleChangeFile}
                datasourceTypes={dataSourceTypes}
            />
        </Box>
    )
}
