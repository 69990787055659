import * as React from 'react'
import { FC } from 'react'
import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { Field, Form } from 'react-final-form'
import { TicketNode } from './TicketNode'
import { DetailedTicket } from './DetailedTicket'
import { validationRequired } from '../form/validate'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { updateComment } from './EngagementActions'
import { FormApi } from 'final-form'
import { getIsMobile, showSuccessSnackbar } from '../core/slices/CoreSlice'

type TicketAnswerFormProps = {
    ticket: DetailedTicket
    node: TicketNode
    userName: string
    onClose: () => any
}

export const TicketAnswerForm: FC<TicketAnswerFormProps> = ({ node, ticket, userName, onClose }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const isMobile = useSelector(getIsMobile)

    const onSubmit = (values: { message: string }, form: FormApi<{ message: string }>) => {
        return new Promise((resolve) => {
            dispatch(
                updateComment({
                    node,
                    request: {
                        target_id: ticket.target_id,
                        node_id: node.node_id,
                        ticket_id: ticket.id,
                        type: 'ANSWER',
                        message: values.message,
                        data_source_id: ticket.data_source_id,
                    },
                })
            ).then(() => {
                resolve(true)
                form.reset()
                dispatch(showSuccessSnackbar('engagement.answer-was-sent'))
                onClose()
            })
        })
    }

    return (
        <Form<{ message: string }>
            onSubmit={onSubmit}
            render={({ handleSubmit, invalid, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Box p={2}>
                        <Field name="message" validate={validationRequired}>
                            {({ input, meta }) => {
                                const handleBlur = (ev: any) => {
                                    input.onBlur(ev)
                                    isMobile && onClose()
                                }
                                return (
                                    <TextField
                                        InputLabelProps={{ variant: 'outlined', shrink: true }}
                                        label={intl.formatMessage({ id: 'engagement.answer-lowercase' }, { name: userName })}
                                        multiline={true}
                                        variant="outlined"
                                        fullWidth
                                        autoFocus={true}
                                        error={meta.error && meta.touched}
                                        value={input.value || ''}
                                        onChange={input.onChange}
                                        onFocus={input.onFocus}
                                        onBlur={handleBlur}
                                        helperText={meta.touched && meta.error ? <FormattedMessage id={meta.error} /> : undefined}
                                    />
                                )
                            }}
                        </Field>

                        <Box display="flex" justifyContent="flex-end" mt={1}>
                            <Button
                                disabled={invalid || submitting}
                                type="submit"
                                size="small"
                                variant="contained"
                                color="secondary"
                                startIcon={submitting ? <CircularProgress color="inherit" size={20} /> : undefined}
                            >
                                <FormattedMessage id="engagement.answer" />
                            </Button>
                        </Box>
                    </Box>
                </form>
            )}
        />
    )
}
