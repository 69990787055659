import { FC } from 'react'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import * as React from 'react'

type TiktokVideoProps = {
    objectUrl: string | undefined
    playerRef: any
    height: number
}

export const TiktokVideo: FC<TiktokVideoProps> = ({ objectUrl, playerRef, height }) => {
    return (
        <Box mr={-2} ml={-2}>
            <Box
                sx={{
                    ml: '76px',
                    aspectRatio: '9 / 16',
                    overflow: 'hidden',
                    width: 300,
                    borderRadius: 2,
                    backgroundColor: 'black',
                    alignItems: 'center',
                    position: 'relative',
                    display: 'flex',
                }}
            >
                <video
                    ref={playerRef}
                    autoPlay={true}
                    muted={true}
                    loop
                    controls
                    width="100%"
                    height={height}
                    style={{ objectFit: 'contain', maxHeight: '100%', zIndex: 1 }}
                    src={objectUrl}
                >
                    <FormattedMessage id="publishing.video-not-supported" />
                </video>
            </Box>
        </Box>
    )
}
