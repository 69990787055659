import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, FormControlLabel, Grid, MenuItem, Switch, Tooltip, useTheme } from '@mui/material'
import { ConnectedTextField, StyledTextField } from '../form/ConnectedTextField'
import { Field, FieldInputProps, useForm, useFormState } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { Check, Error, WarningRounded } from '@mui/icons-material'
import { ConnectedDropdown } from '../form/ConnectedDropdown'
import { PublishingFileUpload } from './PublishingFileUpload'
import { IdMap } from '../core/slices/DataSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import _, { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedProject } from '../core/slices/CoreSlice'
import { checkIsDatasourceDomainOwner, fetchLinkPreviewMetadata } from './PublishingActions'
import { HmstrDispatch } from '../core/Store'
import { getIn } from 'final-form'
import {
    getAllowedDomainsByDatasource,
    getCheckedDomainsByDatasourceIds,
    getIsLoadingLinkOwner,
    getIsLoadingPreview,
    getLastUrlPreviewLoaded,
    getPostDialogSelectedNetwork,
    getPreviewMetadata,
} from './PublishingSlice'
import { validateUrl } from '../form/validate'

type PublishingLinkFieldProps = {
    prefix?: string
    datasourcesByType: IdMap<ProjectDatasource[]>
    disabled?: boolean
    disableValidation?: boolean
    channelType?: string
}

export const PublishingLinkField: FC<PublishingLinkFieldProps> = ({ prefix, datasourcesByType, disabled, disableValidation, channelType }) => {
    const formState = useFormState()
    const dispatch = useDispatch<HmstrDispatch>()
    const form = useForm()
    const theme = useTheme()
    const linkValue = getIn(formState, `values.${prefix}.link`)

    const project = useSelector(getSelectedProject)
    const postType = useSelector(getPostDialogSelectedNetwork)
    const customizePostsByNetwork = formState.values.customize_posts_by_network
    const datasourcesOwningDomain = useSelector(getAllowedDomainsByDatasource)
    const checkedDomainsByDsId = useSelector(getCheckedDomainsByDatasourceIds)
    const selectedFbDatasources = datasourcesByType['FACEBOOK_PAGE'] || []
    const loadingLinkOwner = useSelector(getIsLoadingLinkOwner)
    const isLoadingPreview = useSelector(getIsLoadingPreview)
    const previewMetadata = useSelector(getPreviewMetadata)
    const lastUrlPreviewLoaded = useSelector(getLastUrlPreviewLoaded)
    const linkValueAsHostname = validateUrl(linkValue) ? undefined : new URL(linkValue).hostname

    const metaData = useSelector(getPreviewMetadata)
    const loadingMetadata = useSelector(getIsLoadingPreview)

    const hasDatasourceDomainAccess = (datasource: ProjectDatasource) => {
        if (Object.keys(datasourcesOwningDomain).includes(datasource.id) && linkValueAsHostname) {
            return datasourcesOwningDomain[datasource.id].includes(linkValueAsHostname)
        }

        return false
    }

    const includesLinkedin = Object.keys(datasourcesByType).includes('LINKED_IN')
    const fbLinkCustomizable = !!_.find(selectedFbDatasources, hasDatasourceDomainAccess)
    const isOnlyFacebook = customizePostsByNetwork ? channelType === 'FACEBOOK_PAGE' : postType === 'FACEBOOK_PAGE' && !includesLinkedin
    const mustProvideMetadata = includesLinkedin && !loadingMetadata && !metaData && !isOnlyFacebook && !disableValidation

    const isLinkPreviewCustomizable = () => {
        if (customizePostsByNetwork && postType === 'FACEBOOK_PAGE') {
            return fbLinkCustomizable
        } else {
            return includesLinkedin || fbLinkCustomizable
        }
    }

    const linkPreviewCustomizable = isLinkPreviewCustomizable()
    const hasLinkPreviewCustomized = prefix
        ? Boolean(
              formState.initialValues[prefix]?.link_preview_title ||
                  formState.initialValues[prefix]?.link_preview_description ||
                  formState.initialValues[prefix]?.link_preview_file_id ||
                  getIn(formState, `values.${prefix}.link_preview_title`) ||
                  getIn(formState, `values.${prefix}.link_preview_desctiption`) ||
                  getIn(formState, `values.${prefix}.link_preview_file_id`)
          )
        : false
    const [customizeLinkPreview, setCustomizeLinkPreview] = useState(hasLinkPreviewCustomized)

    useEffect(() => {
        if (mustProvideMetadata) {
            setCustomizeLinkPreview(true)
        }
    }, [mustProvideMetadata])

    useEffect(() => {
        if (linkValue) {
            fetchLinkPreview(linkValue)
            getIsLinkOwner(linkValue, selectedFbDatasources)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDisableLinkPreview = () => {
        setCustomizeLinkPreview(false)
        removeFieldIfExists(getNameWithPrefix('link_preview_title'))
        removeFieldIfExists(getNameWithPrefix('link_preview_description'))
        removeFieldIfExists(getNameWithPrefix('link_preview_file_id'))
    }

    const filteredDeniedDatasources = selectedFbDatasources.filter((ds) => !hasDatasourceDomainAccess(ds))

    const getIsLinkOwner = (link: string, fbDatasources: ProjectDatasource[]) => {
        if (link && project) {
            fbDatasources.forEach((datasource) => {
                dispatch(
                    checkIsDatasourceDomainOwner({
                        datasource: datasource,
                        link: link,
                    })
                )
            })
        }
    }

    const debouncedLinkOwnerData = useMemo(
        () =>
            debounce((link, fbDatasources) => {
                getIsLinkOwner(link, fbDatasources)
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const renderEndAdornment = () => {
        if (!linkValue) {
            return undefined
        }

        if (validateUrl(linkValue) !== undefined) {
            return <Error fontSize="small" color="error" />
        }

        if (isLoadingPreview) {
            return <CircularProgress size={20} variant="indeterminate" sx={{ color: theme.palette.action.disabled }} />
        }

        if (previewMetadata === null) {
            return (
                <Tooltip title={<FormattedMessage id="publishing.link-preview-not-available-for" />}>
                    <WarningRounded fontSize="small" color="warning" />
                </Tooltip>
            )
        }

        return <Check fontSize="small" color="success" />
    }

    const getNameWithPrefix = (name: string) => {
        if (prefix) {
            return `${prefix}.${name}`
        }

        return name
    }

    const removeFieldIfExists = (name: string) => {
        if (form.getRegisteredFields().includes(name)) {
            form.change(name, undefined)
            form.resetFieldState(name)
        }
    }

    const fetchLinkPreview = useMemo(
        () =>
            _.debounce((url: string) => {
                const strippedUrl = url.endsWith('/') ? url.slice(0, -1) : url

                if (lastUrlPreviewLoaded !== strippedUrl) {
                    dispatch(fetchLinkPreviewMetadata(url))
                }
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lastUrlPreviewLoaded]
    )

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, input: FieldInputProps<string>) => {
        if (validateUrl(event.target.value) === undefined) {
            fetchLinkPreview(event.target.value)

            const newDatasourcesToCheck = selectedFbDatasources.filter((ds) => {
                return !(_.get(checkedDomainsByDsId, ds.id) || []).includes(new URL(event.target.value).hostname)
            })

            debouncedLinkOwnerData(event.target.value, newDatasourcesToCheck)
        }
        input.onChange(event)
    }

    return (
        <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
                <Field
                    name={getNameWithPrefix('link')}
                    key={disableValidation ? `${getNameWithPrefix('link')}disableValidation` : `${getNameWithPrefix('link')}enableValidation`}
                    validate={disableValidation ? undefined : validateUrl}
                >
                    {({ input, meta }) => (
                        <StyledTextField
                            value={input.value || ''}
                            onFocus={input.onFocus}
                            required={true}
                            onChange={(event) => handleChange(event, input)}
                            onBlur={input.onBlur}
                            label={<FormattedMessage id="Link" />}
                            type="text"
                            disabled={disabled}
                            fullWidth={true}
                            InputProps={{
                                endAdornment: renderEndAdornment(),
                            }}
                            error={!disableValidation && (meta.error || meta.submitError) && (meta.visited || meta.touched || meta.initial !== undefined)}
                            helperText={
                                (meta.visited || meta.touched || meta.initial !== undefined) &&
                                (meta.error || meta.submitError) && <FormattedMessage id={meta.error || meta.submitError} />
                            }
                        />
                    )}
                </Field>
            </Grid>
            {((!customizePostsByNetwork && postType !== 'LINKED_IN') || (customizePostsByNetwork && postType !== 'LINKED_IN')) && (
                <Grid item xs={12}>
                    <ConnectedDropdown name={getNameWithPrefix('cta_type')} disabled={disabled} label="Call to action">
                        <MenuItem value="BOOK_TRAVEL">
                            <FormattedMessage id="publishing.cta.BOOK_TRAVEL" />
                        </MenuItem>
                        <MenuItem value="BUY_NOW">
                            <FormattedMessage id="publishing.cta.BUY_NOW" />
                        </MenuItem>
                        <MenuItem value="CALL_NOW">
                            <FormattedMessage id="publishing.cta.CALL_NOW" />
                        </MenuItem>
                        <MenuItem value="DOWNLOAD">
                            <FormattedMessage id="publishing.cta.DOWNLOAD" />
                        </MenuItem>
                        <MenuItem value="GET_DIRECTIONS">
                            <FormattedMessage id="publishing.cta.GET_DIRECTIONS" />
                        </MenuItem>
                        <MenuItem value="GET_QUOTE">
                            <FormattedMessage id="publishing.cta.GET_QUOTE" />
                        </MenuItem>
                        <MenuItem value="INSTALL_APP">
                            <FormattedMessage id="publishing.cta.INSTALL_APP" />
                        </MenuItem>
                        <MenuItem value="INSTALL_MOBILE_APP">
                            <FormattedMessage id="publishing.cta.INSTALL_MOBILE_APP" />
                        </MenuItem>
                        <MenuItem value="LEARN_MORE">
                            <FormattedMessage id="publishing.cta.LEARN_MORE" />
                        </MenuItem>
                        <MenuItem value="LIKE_PAGE">
                            <FormattedMessage id="publishing.cta.LIKE_PAGE" />
                        </MenuItem>
                        <MenuItem value="LISTEN_MUSIC">
                            <FormattedMessage id="publishing.cta.LISTEN_MUSIC" />
                        </MenuItem>
                        <MenuItem value="MESSAGE_PAGE">
                            <FormattedMessage id="publishing.cta.MESSAGE_PAGE" />
                        </MenuItem>
                        <MenuItem value="NO_BUTTON">
                            <FormattedMessage id="publishing.cta.NO_BUTTON" />
                        </MenuItem>
                        <MenuItem value="OPEN_LINK">
                            <FormattedMessage id="publishing.cta.OPEN_LINK" />
                        </MenuItem>
                        <MenuItem value="PLAY_GAME">
                            <FormattedMessage id="publishing.cta.PLAY_GAME" />
                        </MenuItem>
                        <MenuItem value="SHOP_NOW">
                            <FormattedMessage id="publishing.cta.SHOP_NOW" />
                        </MenuItem>
                        <MenuItem value="SIGN_UP">
                            <FormattedMessage id="publishing.cta.SIGN_UP" />
                        </MenuItem>
                        <MenuItem value="SUBSCRIBE">
                            <FormattedMessage id="publishing.cta.SUBSCRIBE" />
                        </MenuItem>
                        <MenuItem value="USE_APP">
                            <FormattedMessage id="publishing.cta.USE_APP" />
                        </MenuItem>
                        <MenuItem value="USE_MOBILE_APP">
                            <FormattedMessage id="publishing.cta.USE_MOBILE_APP" />
                        </MenuItem>
                        <MenuItem value="WATCH_VIDEO">
                            <FormattedMessage id="publishing.cta.WATCH_VIDEO" />
                        </MenuItem>
                        <MenuItem value="APPLY_NOW">
                            <FormattedMessage id="publishing.cta.APPLY_NOW" />
                        </MenuItem>
                    </ConnectedDropdown>
                </Grid>
            )}
            <Grid item xs={12}>
                <Tooltip title={linkPreviewCustomizable ? '' : <FormattedMessage id="publishing.custom-link-preview-disabled" />}>
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <FormControlLabel
                            sx={{ ml: 0.5 }}
                            disabled={disabled || !linkPreviewCustomizable || mustProvideMetadata}
                            control={
                                <Switch
                                    color={'secondary'}
                                    checked={(customizeLinkPreview && linkPreviewCustomizable) || mustProvideMetadata}
                                    onChange={(event) => {
                                        if (!mustProvideMetadata) {
                                            if (!event.target.checked) {
                                                handleDisableLinkPreview()
                                            } else {
                                                setCustomizeLinkPreview(event.target.checked)
                                            }
                                        }
                                    }}
                                />
                            }
                            label={<FormattedMessage id={'publishing.customize-link-preview'} />}
                            labelPlacement="end"
                        />
                        {filteredDeniedDatasources.length > 0 &&
                            !loadingLinkOwner &&
                            linkPreviewCustomizable &&
                            !(customizePostsByNetwork && postType === 'LINKED_IN') && (
                                <Tooltip
                                    title={
                                        <FormattedMessage
                                            id={'publishing.link-preview-not-customizable-for'}
                                            values={{
                                                datasources: filteredDeniedDatasources.map((ds) => ds.name).join(', '),
                                            }}
                                        />
                                    }
                                >
                                    <WarningRounded fontSize="small" color="warning" />
                                </Tooltip>
                            )}
                        {loadingLinkOwner && <CircularProgress size={20} variant={'indeterminate'} sx={{ color: theme.palette.action.disabled }} />}
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sx={{ display: customizeLinkPreview && linkPreviewCustomizable ? 'block' : 'none' }}>
                <ConnectedTextField
                    required={mustProvideMetadata}
                    disabled={disabled}
                    fullWidth
                    name={`${prefix}.link_preview_title`}
                    label={'publishing.link-preview-title'}
                    validate={(val: string | undefined) => {
                        if (!val) {
                            if (mustProvideMetadata) {
                                return 'publishing.link-preview-title-required-linkedin'
                            }
                        }
                    }}
                />
                <ConnectedTextField
                    required={mustProvideMetadata}
                    disabled={disabled}
                    fullWidth
                    name={`${prefix}.link_preview_description`}
                    label={'publishing.link-preview-desc'}
                    validate={(val: string | undefined) => {
                        if (!val) {
                            if (mustProvideMetadata) {
                                return 'publishing.link-preview-description-required-linkedin'
                            }
                        }
                    }}
                />
                <Field<string>
                    key={`link_preview_file_id_` + mustProvideMetadata.toString() + customizeLinkPreview.toString()}
                    name={getNameWithPrefix('link_preview_file_id')}
                    validate={(val) => {
                        if (!val || val.length === 0) {
                            if (mustProvideMetadata) {
                                return 'publishing.link-preview-image-required-linkedin'
                            }
                        }
                    }}
                >
                    {() => (
                        <Box pb={2} pt={2}>
                            <PublishingFileUpload
                                disabled={disabled}
                                name={getNameWithPrefix('link_preview_file_id')}
                                dataSourceTypes={datasourcesByType}
                                disableValidation={disableValidation}
                                isLinkPreview
                            />
                        </Box>
                    )}
                </Field>
            </Grid>
        </Grid>
    )
}
