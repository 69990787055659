import axios from 'axios'
import { User } from '../../settings/user-management/User'
import ReactGA from 'react-ga4'
import { ClientConfig } from '../config/config'
import { KeycloakWrapper } from '../keycloak/KeycloakWrapper'
import { ApiEntryPoint } from '../api/ApiEntryPoint'
import { hasCookieAdsConsent } from '../helpers/check-cookie-consent'

export const initUser = async (clientConfig: ClientConfig, keycloakWrapper: KeycloakWrapper, apiEntrypoint: ApiEntryPoint) => {
    const userCheckResponse = await axios.get<User>(apiEntrypoint._links.currentUserCheck.href).catch((error) => error)
    const isFirstLogin = userCheckResponse.status === 404
    const hasNoInviteCode = !keycloakWrapper.inviteCode
    const hasConsented = hasCookieAdsConsent()

    // Only track registration event on first login if user has accepted cookies and was not invited by someone else
    if (clientConfig.profiles.includes('prod') && isFirstLogin && hasConsented && hasNoInviteCode) {
        ReactGA.event({
            category: 'registration',
            action: 'complete_registration',
        })
        fbq('track', 'Lead', {}, { eventID: keycloakWrapper.id + 'reg' })
    }

    let currentUser: User
    if (userCheckResponse.status === 200) {
        currentUser = userCheckResponse.data
    } else {
        const userResponse = await axios.get(apiEntrypoint._links.currentUser.href, {
            params: { has_consented: hasConsented },
        })
        currentUser = userResponse.data
    }

    return currentUser
}
