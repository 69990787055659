import { FC, useEffect, useState } from 'react'
import { AccountLinkInfo } from './AccountLinkInfo'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { FederatedIdentity } from './FederatedIdentity'

type AccountLinkSuccessProps = {}

export const AccountLinkSuccess: FC<AccountLinkSuccessProps> = () => {
    const intl = useIntl()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const type = (params.get('federated_identity_type') as FederatedIdentity['type']) || 'META'
    const closeDelay = 10
    const [secondsUntilWindowCloses, setSecondsUntilWindowCloses] = useState(closeDelay)

    useEffect(() => {
        if (secondsUntilWindowCloses > 0) {
            setTimeout(() => {
                setSecondsUntilWindowCloses(secondsUntilWindowCloses - 1)
            }, 1000)
        } else {
            handleClose()
        }
    }, [secondsUntilWindowCloses])

    const handleClose = () => {
        const bc = new BroadcastChannel('hmstr-account-link')
        bc.postMessage(true)
        window.close()
    }

    return (
        <AccountLinkInfo
            state="success"
            type={type}
            primaryText={intl.formatMessage({ id: 'account-link.primary-success-text' })}
            secondaryText={intl.formatMessage({ id: 'account-link.secondary-success-text' }, { seconds: secondsUntilWindowCloses })}
            buttonText={intl.formatMessage({ id: 'account-link.success-button-text' })}
            onButtonClick={handleClose}
        />
    )
}
