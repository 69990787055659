import * as _ from 'lodash'
import React, { FC } from 'react'
import { Badge, Box, Tab, Tabs } from '@mui/material'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { isExternalForProject } from '../core/slices/CoreSlice'
import { PublishingFormValues } from './PublishingForm'
import { getIconForDatasourceType } from '../settings/datasources/DatasourceTypeMappings'
import { getErrorsForCommonFile, getPostDialogSelectedTab, getSelectedPostGroup, PublishingState } from './PublishingSlice'
import { FormattedMessage } from 'react-intl'
import { usePublishingFormDatasourceTypes } from './PublishingHooks'

type PublishingTabsProps = {
    onTabChange: (newTabValue: PublishingState['postDialogSelectedTab']) => any
}
export const PublishingTabs: FC<PublishingTabsProps> = ({ onTabChange }) => {
    const formState = useFormState<PublishingFormValues>()
    const selectedTab = useSelector(getPostDialogSelectedTab)
    const selectedPostGroup = useSelector(getSelectedPostGroup)
    const datasourceTypes = usePublishingFormDatasourceTypes()
    const imageErrors = useSelector(getErrorsForCommonFile)
    const groupedByNetwork = _.chain(imageErrors).values().flatten().groupBy('network').value()
    const isUserExternal = useSelector(isExternalForProject)

    return (
        <Box flexGrow={1}>
            <Tabs variant="scrollable" value={selectedTab} onChange={(e, value) => onTabChange(value)} indicatorColor={'secondary'}>
                {(datasourceTypes || []).map((type) => {
                    const errorsByNetwork = _.get(formState, `errors.postByType.${type}`)
                    const fileErrorsForNetwork = groupedByNetwork[type]?.length || 0
                    let errorCount = Object.keys(errorsByNetwork || {}).length + fileErrorsForNetwork

                    return (
                        <Tab
                            key={type}
                            sx={{
                                p: 0,
                            }}
                            value={type}
                            icon={
                                <Badge badgeContent={errorCount} color={'error'}>
                                    {getIconForDatasourceType(type)}
                                </Badge>
                            }
                            iconPosition="start"
                        />
                    )
                })}

                <Box flexGrow={1} />

                {selectedPostGroup && !isUserExternal && <Tab label={<FormattedMessage id="general.internal-activity-log" />} value="INTERNAL" />}
                {selectedPostGroup && (
                    <Tab label={<FormattedMessage id={isUserExternal ? 'general.activity-log' : 'general.external-activity-log'} />} value="EXTERNAL" />
                )}
            </Tabs>
        </Box>
    )
}
