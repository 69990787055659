import * as React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { visuallyHidden } from '@mui/utils'
import { Tooltip } from '@mui/material'
import { Info } from '@mui/icons-material'

interface HeadCell {
    id: string
    label: string
    sortDisabled?: boolean
    tooltip?: string
}

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
    order: 'desc' | 'asc'
    orderBy: string
    headCells: HeadCell[]
    dense?: boolean
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { order, orderBy, onRequestSort, headCells, dense } = props
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={!!dense ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
                        {!!headCell.sortDisabled ? (
                            headCell.label
                        ) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.tooltip && (
                                    <Tooltip title={headCell.tooltip}>
                                        <Info sx={{ mr: 1 }} fontSize="small" color="primary" />
                                    </Tooltip>
                                )}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                {headCell.label}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default EnhancedTableHead
