import { FC, useState } from 'react'
import { Button, CircularProgress, Grid, Tooltip } from '@mui/material'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { useForm, useFormState } from 'react-final-form'
import { getFederatedIdentites, getPotentialDatasourcesById } from '../../core/slices/DataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectDatasource } from './ProjectDatasource'
import { Check, Error, Refresh, Token } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { PotentialDatasource } from './PotentialDatasource'
import { updateDatasourceInProject } from './ProjectDatasourceActions'
import { getCurrentUser, getSelectedProject, getSelectedProjectAccess, isProjectAdmin, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { refreshAccount } from '../linked-accounts/AccountLinkService'
import { fetchFederatedIdentites, fetchPotentialDatasources } from '../user-management/UserActions'
import { fetchProject } from '../project-management/ProjectActions'

type DatasourceFederatedIdentitySectionProps = {}

export const DatasourceFederatedIdentitySection: FC<DatasourceFederatedIdentitySectionProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const form = useForm<ProjectDatasource>()
    const currentUser = useSelector(getCurrentUser)
    const selectedProjectAccess = useSelector(getSelectedProjectAccess)
    const project = useSelector(getSelectedProject)
    const isUserProjectAdmin = useSelector(isProjectAdmin)
    const formState = useFormState<ProjectDatasource>()
    const datasource = formState.values
    const federatedIdentities = useSelector(getFederatedIdentites)
    const federatedIdentity = federatedIdentities[formState.values.federated_identity_id || '']
    const potentialDatasourcesById = useSelector(getPotentialDatasourcesById)
    const potentialDatasource: PotentialDatasource | undefined = potentialDatasourcesById[datasource.metadata.id]
    const [isTakingOver, setTakingOver] = useState(false)

    const handleReauthorize = () => {
        if (federatedIdentity && selectedProjectAccess) {
            refreshAccount(federatedIdentity).then(() => {
                dispatch(showSuccessSnackbar('federated-identites.refresh-success'))
                dispatch(fetchFederatedIdentites(currentUser))
                dispatch(fetchPotentialDatasources({ user: currentUser, type: datasource.type }))
                dispatch(fetchProject(selectedProjectAccess))
            })
        }
    }

    const handleTakeOver = () => {
        if (project && isUserProjectAdmin) {
            setTakingOver(true)
            const newDatasourceValues = {
                ...datasource,
                federated_identity_id: potentialDatasource.federated_identity_id,
                federated_identity_username: potentialDatasource.federated_identity_username,
                access_token: potentialDatasource.access_token,
                owner_id: currentUser.id,
                owner_name: `${currentUser.first_name} ${currentUser.last_name}`,
            } as ProjectDatasource

            dispatch(updateDatasourceInProject({ project, datasource: newDatasourceValues })).then((action) => {
                setTakingOver(false)
                const updatedDatesource = action.payload as ProjectDatasource
                form.change('federated_identity_id', updatedDatesource.federated_identity_id)
                form.change('federated_identity_username', updatedDatesource.federated_identity_username)
                form.change('webhooks_enabled', updatedDatesource.webhooks_enabled)
                form.change('access_token', undefined)
                form.change('owner_id', updatedDatesource.owner_id)
                form.change('owner_name', updatedDatesource.owner_name)
                form.change('access_token_invalid', updatedDatesource.access_token_invalid)
                dispatch(showSuccessSnackbar('datasources.updated-success'))
            })
        }
    }

    return (
        <>
            <Grid item xs={federatedIdentity && isUserProjectAdmin && !formState.values.access_token_invalid ? 12 : 8}>
                <ConnectedTextField
                    name="federated_identity_username"
                    readonly={true}
                    label="settings.datasource.federated-identity"
                    shrinkLabel={true}
                    placeholder={
                        formState.values.federated_identity_id ? 'settings.datasource.foreign-federated-identity' : 'settings.datasource.no-federated-identity'
                    }
                    endIcon={formState.values.federated_identity_id ? <Check color="success" /> : <Error color="error" />}
                />
            </Grid>
            {!federatedIdentity && isUserProjectAdmin && (
                <Grid item xs={4} alignSelf="flex-end">
                    <Tooltip placement="right" title={!potentialDatasource ? <FormattedMessage id="settings.datsource.take-over-impossible" /> : ''}>
                        <span>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="inherit"
                                startIcon={isTakingOver ? <CircularProgress color="inherit" size={20} /> : <Token />}
                                disabled={!potentialDatasource || isTakingOver}
                                onClick={handleTakeOver}
                            >
                                <FormattedMessage id="settings.datasource.take-over" />
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            )}
            {federatedIdentity && formState.values.access_token_invalid && (
                <Grid item xs={4} alignSelf="flex-end">
                    <Button fullWidth variant="outlined" color="inherit" startIcon={<Refresh />} onClick={handleReauthorize}>
                        <FormattedMessage id="settings.datasource.reauthorise" />
                    </Button>
                </Grid>
            )}
        </>
    )
}
