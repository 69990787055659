import * as _ from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getDatasourceTypeToAdd, showErrorSnackbar, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { Box, Container, Grid, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from '@mui/icons-material'
import { TiktokIcon } from '../../common/icons/TiktokIcon'
import { InstagramIcon } from '../../common/icons/InstagramIcon'
import { FacebookIcon } from '../../common/icons/FacebookIcon'
import { LinkedInIcon } from '../../common/icons/LinkedInIcon'
import { getApiEntryPoint } from '../../core/api/ApiSlice'
import { linkAccount } from './AccountLinkService'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { getFederatedIdentites } from '../../core/slices/DataSlice'
import { FederatedIdentity } from './FederatedIdentity'
import { AccountLinkTable } from './AccountLinkTable'
import { fetchFederatedIdentites, fetchPotentialDatasources } from '../user-management/UserActions'

type AccountLinkDialogProps = {}

export const AccountLinking: FC<AccountLinkDialogProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const apiEntryPoint = useSelector(getApiEntryPoint)
    const currentUser = useSelector(getCurrentUser)
    const federatedIdentities = useSelector(getFederatedIdentites)
    const groupedIdentites = _.groupBy(federatedIdentities, 'type')
    const datasourceTypeToAdd = useSelector(getDatasourceTypeToAdd)

    useEffectWithIdComparison(() => {
        dispatch(fetchFederatedIdentites(currentUser))
    }, [currentUser])

    const handleAccountLink = (type: FederatedIdentity['type']) => {
        linkAccount(apiEntryPoint, type, datasourceTypeToAdd).then((success) => {
            dispatch(fetchFederatedIdentites(currentUser))

            if (datasourceTypeToAdd) {
                dispatch(fetchPotentialDatasources({ user: currentUser, type: datasourceTypeToAdd }))
            }

            if (success) {
                dispatch(showSuccessSnackbar('federated-identites.link-success'))
            } else {
                dispatch(showErrorSnackbar('federated-identites.link-failed'))
            }
        })
    }

    const renderCard = (platform: FederatedIdentity['type'], icon: JSX.Element) => (
        <Grid item xs={12} md={4}>
            <ListItemButton component={Paper} onClick={() => handleAccountLink(platform)}>
                <ListItemAvatar>{icon}</ListItemAvatar>
                <ListItemText
                    primary={intl.formatMessage({ id: `federated-identites.${platform}` })}
                    secondary={intl.formatMessage({ id: `federated-identites.${platform}.description` })}
                />
            </ListItemButton>
        </Grid>
    )

    return (
        <Container maxWidth="lg">
            <Box marginTop={2}>
                <Box marginBottom={4}>
                    <Typography variant="h5">
                        <FormattedMessage id="accounts.manage-linked-accounts" />
                    </Typography>
                </Box>
            </Box>

            <Box mb={2}>
                <Paper sx={{ p: 2 }}>
                    <Box display="flex" alignItems="center">
                        <Box pr={2} pt={0.25}>
                            <Link />
                        </Box>
                        <Typography variant="h6">
                            <FormattedMessage id="federated-identites.link-accounts" />
                        </Typography>
                    </Box>

                    <Grid sx={{ mt: 2 }} container spacing={2}>
                        {renderCard(
                            'META',
                            <>
                                <FacebookIcon />
                                <InstagramIcon />
                            </>
                        )}
                        {renderCard('LINKED_IN', <LinkedInIcon />)}
                        {renderCard('TIKTOK', <TiktokIcon />)}
                        {/*{renderCard('TWITTER', <XIcon />)}*/}
                    </Grid>
                </Paper>
            </Box>

            <AccountLinkTable groupedIdentites={groupedIdentites} />
        </Container>
    )
}
