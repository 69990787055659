import { Box, Button, Container, FormLabel, Grid, LinearProgress, Tooltip, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getFilteredUserTableEntries } from '../SettingsSlice'
import { UserTable } from './table/UserTable'
import { fetchInvitesForAccount, fetchUsersForAccount } from './UserActions'
import { getSelectedAccount, getSelectedAccountAccess, openFullscreenDialog } from '../../core/slices/CoreSlice'
import { getAccountById } from '../../core/slices/DataSlice'
import { HmstrColors } from '../../core/theme/hmstr-theme'
import { HmstrDispatch } from '../../core/Store'
import { UserAccountAccess } from './User'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { useHasAccountPermission } from '../../core/hooks/useHasPermission'

interface UserManagementProps {}

export const UserManagement: FC<UserManagementProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const accountAccess = useSelector(getSelectedAccountAccess) as UserAccountAccess
    const userTableEntries = useSelector(getFilteredUserTableEntries)
    const account = useSelector(getSelectedAccount)
    const selectedAccount = useSelector(getAccountById(accountAccess.account_id))
    const canManageUsers = useHasAccountPermission('account.manage_users', account.id)
    const canManageSubscription = useHasAccountPermission('account.manage_subscription', account.id)

    useEffectWithIdComparison(() => {
        dispatch(fetchUsersForAccount(accountAccess))
        dispatch(fetchInvitesForAccount(accountAccess))
    }, [account])

    const checkIfUserLimitReached = () => {
        return account.user_count >= account.user_limit
    }

    useEffect(() => {
        if (!canManageUsers) {
            navigate('settings')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canManageUsers])

    const userLimitReachedTooltip = () => {
        if (canManageSubscription && checkIfUserLimitReached()) {
            return (
                <>
                    <FormattedMessage id={'limits.maximum-users-reached'} />
                    <br />
                    <span
                        style={{ textDecoration: 'underline', cursor: 'pointer', color: HmstrColors.Orange }}
                        onClick={() => dispatch(openFullscreenDialog('extend-account'))}
                    >
                        <FormattedMessage id={'limits.maximum-users-reached.admin'} />
                    </span>
                </>
            )
        } else if (!canManageSubscription && checkIfUserLimitReached()) {
            return (
                <>
                    <FormattedMessage id={'limits.maximum-users-reached'} />
                    &nbsp;
                    <FormattedMessage id={'limits.maximum-users-reached.non-admin'} />
                </>
            )
        } else {
            return ''
        }
    }

    return (
        <Container maxWidth="lg">
            <Box marginTop={2} marginBottom={2}>
                <Box marginBottom={4}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5">
                                <FormattedMessage id="accounts.manage-users" />
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Box display="flex" alignItems="flex-end" gap={2}>
                                <Box width={300}>
                                    <FormLabel>
                                        <FormattedMessage id="conversion-tracking.contingent" />
                                        :&nbsp;
                                        {userTableEntries.length}&nbsp;/&nbsp;
                                        {selectedAccount?.user_limit || 0}
                                    </FormLabel>
                                    <LinearProgress
                                        color="secondary"
                                        variant="determinate"
                                        value={(userTableEntries.length / (selectedAccount?.user_limit || 1)) * 100}
                                    />
                                </Box>

                                <Tooltip title={userLimitReachedTooltip()}>
                                    <span>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            startIcon={<Add />}
                                            disabled={checkIfUserLimitReached()}
                                            onClick={() => dispatch(openFullscreenDialog('invite-user'))}
                                        >
                                            <FormattedMessage id="users.invite" />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <UserTable account={accountAccess} users={userTableEntries} />
            </Box>
        </Container>
    )
}
