import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type InstagramSaveProps = SvgIconProps

export const InstagramSave: FC<InstagramSaveProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    fill: 'rgb(115,115,115) !important',
                    color: 'rgb(115,115,115) !important',
                },
            }}
        >
            <svg aria-label="Save" className="x1lliihq x1n2onr6" height="24" role="img" viewBox="0 0 24 24" width="24">
                <title>Save</title>
                <polygon
                    fill="none"
                    points="20 21 12 13.44 4 21 4 3 20 3 20 21"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                ></polygon>
            </svg>
        </SvgIcon>
    )
}
