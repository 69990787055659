import React, { FC, useState } from 'react'
import { Alert, Box, Grid, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { DatasourcePaper } from './DatasourcePaper'
import { ProjectDatasource } from './ProjectDatasource'
import { DatasourceEditDialog } from './DatasourceEditDialog'

type DatasourcesGridProps = {
    hideAlert?: boolean
    datasources: ProjectDatasource[]
    onDelete: (datasourceId: string) => any
    onEdit?: (datasource: ProjectDatasource, resolve: (value: any) => any) => any
}

export const DatasourcesGrid: FC<DatasourcesGridProps> = ({ datasources, hideAlert, onDelete, onEdit }) => {
    const [datasourceToEdit, setDatasourceToEdit] = useState<ProjectDatasource | undefined>()

    const handleEditClick = (datasource: ProjectDatasource) => {
        setDatasourceToEdit(datasource)
    }

    const handleSubmit = async (datasource: ProjectDatasource) => {
        if (onEdit) {
            return new Promise<any>((resolve) => {
                onEdit(datasource, resolve)
            }).then(() => {
                setDatasourceToEdit(undefined)
            })
        } else {
            setDatasourceToEdit(undefined)
        }
    }

    return (
        <Box>
            {datasources.length === 0 ? (
                <Typography variant="subtitle2">
                    <FormattedMessage id="settings.no-datasources" />
                </Typography>
            ) : (
                <Box overflow="auto">
                    {!hideAlert && (
                        <Alert sx={{ mb: 2 }}>
                            <strong style={{ fontWeight: 800 }}>{datasources.length}</strong>
                            <FormattedMessage
                                id="datasources.n-datasources-added"
                                values={{
                                    value: datasources.length,
                                }}
                            />
                        </Alert>
                    )}
                    <Grid container spacing={2}>
                        {datasources.map((ds) => (
                            <Grid item xs={12} md={6} key={ds.metadata.id || ds.id}>
                                <DatasourcePaper datasource={ds} onDelete={onDelete} handleEditClick={() => handleEditClick(ds)} />
                            </Grid>
                        ))}
                    </Grid>

                    {datasourceToEdit && (
                        <DatasourceEditDialog
                            open={!!datasourceToEdit}
                            datasource={datasourceToEdit}
                            onSubmit={handleSubmit}
                            onClose={() => setDatasourceToEdit(undefined)}
                        />
                    )}
                </Box>
            )}
        </Box>
    )
}
