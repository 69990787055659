import { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'

type DatasourceFilterProps = {
    selectedDatasource?: ProjectDatasource
    datasources: ProjectDatasource[]
    onChange: (selectedDatasourceId: string) => any
    variant?: 'standard' | 'outlined' | 'filled'
    handleOpen: () => void
    handleClose: () => void
    isOpen: boolean
    fullWidth?: boolean
}

export const DatasourceFilter: FC<DatasourceFilterProps> = ({
    datasources,
    selectedDatasource,
    onChange,
    variant,
    isOpen,
    handleOpen,
    handleClose,
    fullWidth,
}) => {
    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel shrink={true} variant={variant}>
                <FormattedMessage id="facebook.page" />
            </InputLabel>
            <Select
                fullWidth={fullWidth}
                variant={variant}
                open={isOpen}
                onClose={handleClose}
                onOpen={handleOpen}
                label={<FormattedMessage id="facebook.page" />}
                value={datasources.length > 0 ? selectedDatasource?.id || '' : ''}
                renderValue={() => selectedDatasource?.name || ''}
                onChange={(event) => onChange(event.target.value)}
                MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'right' },
                }}
                displayEmpty
            >
                {datasources.map((ds) => (
                    <MenuItem key={ds.id} value={ds.id}>
                        {ds.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
