import { FC, useState } from 'react'
import { Box, Button, CircularProgress, Container, Typography } from '@mui/material'
import { Lock } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getSelectedAccount } from '../../core/slices/CoreSlice'
import { Account } from './Account'
import { fetchPaymentMethods, fetchProducts, fetchSubscriptionById } from '../billing/BillingActions'
import { HmstrDispatch } from '../../core/Store'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { FullscreenDialog } from '../../common/fullscreen-dialog/FullscreenDialog'
import { Billing } from '../billing/Billing'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AccountUpdateSubscriptionWizard } from './AccountUpdateSubscriptionWizard'
import { updateAccountPackage } from './AccountActions'
import { HmstrAsideLayout } from '../../common/hmstr-aside-layout/HmstrAsideLayout'
import { HeaderWithSwitcher } from '../../common/header-with-switcher/HeaderWithSwitcher'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'

type LaunchpadAccountLockedProps = {
    status: Account['status'] | undefined
}

export const AccountLocked: FC<LaunchpadAccountLockedProps> = ({ status }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const account = useSelector(getSelectedAccount)
    const location = useLocation()
    const isBilling = location.pathname.includes('billing')
    const currentUser = useSelector(getCurrentUser)
    const navigate = useProjectNavigate()
    const isOwner = account.owner_id === currentUser.id
    const [open, setOpen] = useState(isBilling)
    const [updateSubscriptionOpen, setUpdateSubscriptionOpen] = useState(false)
    const [downgradeInProcess, setDowngradeInProcess] = useState(false)

    useEffectWithIdComparison(() => {
        if (isOwner) {
            dispatch(fetchProducts())
            dispatch(fetchPaymentMethods(account))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    const handleInvoiceSettingsClick = () => {
        navigate('/settings/billing/history')
        setOpen(true)
    }

    const handleExtendAccountClick = () => {
        setUpdateSubscriptionOpen(true)
    }

    const handleDowngradeToFreeClick = () => {
        setDowngradeInProcess(true)
        dispatch(
            updateAccountPackage({
                values: {
                    customer_id: account.customer_id,
                    billing_info: account.billing_info,
                    package_type: 'free',
                    conversion_tracking: 0,
                    additional_datasource: 0,
                    additional_user: 0,
                    interval: 'month',
                    name: account.name,
                },
                account,
            })
        ).then(() => {
            dispatch(fetchSubscriptionById(account))
            setDowngradeInProcess(false)
            navigate('/')
        })
    }

    const renderTextAndButtons = () => {
        switch (status) {
            case 'TRIAL_EXPIRED':
                return (
                    <>
                        <Typography variant="subtitle1">
                            <FormattedMessage id={isOwner ? 'hint.account.trial-expired' : 'hint.account.trial-expired-no-owner'} />
                        </Typography>

                        <Box mt={4}>
                            {isOwner && (
                                <>
                                    <Button variant="contained" onClick={handleExtendAccountClick} color="secondary">
                                        <FormattedMessage id="billing.extend-subscription" />
                                    </Button>
                                    <Button
                                        onClick={handleDowngradeToFreeClick}
                                        sx={{ ml: 2 }}
                                        variant="outlined"
                                        startIcon={downgradeInProcess ? <CircularProgress color="inherit" size={20} /> : undefined}
                                        disabled={downgradeInProcess}
                                    >
                                        <FormattedMessage id="billing.downgrade-to-free" />
                                    </Button>
                                </>
                            )}
                        </Box>
                    </>
                )
            case 'LOCKED':
                return (
                    <>
                        <Typography variant="subtitle1">
                            <FormattedMessage id={isOwner ? 'hint.account.locked' : 'hint.account.locked-no-owner'} />
                        </Typography>

                        {isOwner && (
                            <Box mt={4}>
                                <Button variant="contained" onClick={handleInvoiceSettingsClick}>
                                    <FormattedMessage id="general.billing" />
                                </Button>
                            </Box>
                        )}
                    </>
                )
            case 'ARCHIVED':
                return (
                    <>
                        <Typography variant="subtitle1">
                            <FormattedMessage id={isOwner ? 'hint.account.archived' : 'hint.account.archived-no-owner'} />
                        </Typography>

                        {isOwner && (
                            <Box mt={4}>
                                <Button variant="contained" color="secondary" onClick={() => setUpdateSubscriptionOpen(true)}>
                                    <FormattedMessage id="billing.reactivate" />
                                </Button>
                            </Box>
                        )}
                    </>
                )
        }
    }

    return (
        <HmstrAsideLayout>
            <Container maxWidth="md">
                <HeaderWithSwitcher />

                <Box sx={{ textAlign: 'center' }}>
                    <Lock color="action" sx={{ fontSize: 128, mt: 8 }} />

                    {renderTextAndButtons()}
                </Box>

                <FullscreenDialog open={open} keepMounted={false} handleClose={() => setOpen(false)}>
                    <Routes>
                        <Route path="/:projectId/settings/billing/*" element={<Billing />} />
                    </Routes>
                </FullscreenDialog>

                <FullscreenDialog open={updateSubscriptionOpen} keepMounted={false} handleClose={() => setUpdateSubscriptionOpen(false)}>
                    <AccountUpdateSubscriptionWizard />
                </FullscreenDialog>
            </Container>
        </HmstrAsideLayout>
    )
}
