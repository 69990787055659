import { FC } from 'react'
import { Box, Grid } from '@mui/material'
import { ConnectedTextField } from '../../../form/ConnectedTextField'
import { ConnectedAutocomplete } from '../../../form/ConnectedAutocomplete'
import { addDatasourceTag, getDatasourceTags } from '../../SettingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedColorpicker } from '../../../form/ConnectedColorPicker'
import { useFormState } from 'react-final-form'
import { ProjectDatasource } from '../ProjectDatasource'
import { DatasourceFederatedIdentitySection } from '../DatasourceFederatedIdentitySection'
import { DatasourceWebhooksField } from '../DatasourceWebhooksField'

type FacebookDatasourceProps = { disabled?: boolean }

export const FacebookDatasource: FC<FacebookDatasourceProps> = ({ disabled }) => {
    const dispatch = useDispatch()
    const datasourceTags = useSelector(getDatasourceTags)
    const formState = useFormState<ProjectDatasource>()

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
                <ConnectedTextField shrinkLabel={true} name="id" label="hmstr ID" maxLength={400} fullWidth readonly />
            </Grid>
            <Grid item xs={6}>
                <ConnectedTextField shrinkLabel={true} name="metadata.id" label="Facebook ID" maxLength={400} fullWidth readonly />
            </Grid>
            <Grid item xs={11}>
                <ConnectedAutocomplete
                    disabled={disabled}
                    name="tags"
                    shrinkLabel={true}
                    label="general.tags"
                    options={datasourceTags}
                    onAddOption={(o) => dispatch(addDatasourceTag(o))}
                />
            </Grid>
            <Grid item xs={1}>
                <Box display="flex" justifyContent="flex-end">
                    <ConnectedColorpicker disabled={disabled} name="color" label="general.color" fullWidth />
                </Box>
            </Grid>

            <DatasourceFederatedIdentitySection />

            {formState.values.type === 'FACEBOOK_PAGE' && <DatasourceWebhooksField />}
        </Grid>
    )
}
