import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useDrawerState } from '../../core/hooks/useDrawerState'
import { AppBar, Box, Collapse, Container, darken, Grid, IconButton, lighten, Paper, SwipeableDrawer, Typography, useTheme } from '@mui/material'
import { StyledMainElement } from './ContentPanel.styles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getAppThemeColor, getSelectedProject, getToolbarExpanded, isDarkMode, toggleToolbar } from '../../core/slices/CoreSlice'
import ClearIcon from '@mui/icons-material/Clear'
import { Breakpoint } from '@mui/system'
import { isMobileDevice } from '../../core/theme/helper'

type ContentPanelProps = {
    title?: JSX.Element
    toolbar?: JSX.Element
    toolbarToggleText?: string
    disableToolbarToggle?: boolean
    toolbarInSameLine?: boolean
    noSidebar?: boolean
    filterSummaryWhenCollapsed?: JSX.Element
    clearFilterAvailable?: boolean
    onClearFilter?: () => void
    maxWidth?: Breakpoint | false
    fullWidthTitle?: boolean
}

export const ContentPanel: FC<PropsWithChildren<ContentPanelProps>> = (props) => {
    const {
        children,
        toolbar,
        title,
        disableToolbarToggle,
        toolbarInSameLine,
        noSidebar,
        filterSummaryWhenCollapsed,
        clearFilterAvailable,
        onClearFilter,
        maxWidth = 'xl',
        toolbarToggleText,
        fullWidthTitle,
    } = props
    const dispatch = useDispatch()
    const appThemeColor = useSelector(getAppThemeColor)
    const drawerState = useDrawerState()
    const toolbarExpanded = useSelector(getToolbarExpanded)
    const showAppbar = Boolean(title) || Boolean(toolbar)
    const toolbarRef = useRef<HTMLDivElement | null>(null)
    const [toolbarHeight, setToolbarHeight] = useState(0)
    const [toolbarHeightCalculated, setToolbarHeightCalculated] = useState(toolbarRef.current?.offsetHeight)
    const isMobile = isMobileDevice()
    const darkMode = useSelector(isDarkMode)
    const theme = useTheme()
    const selectedProject = useSelector(getSelectedProject)

    const handleResize = () => {
        setToolbarHeightCalculated(toolbarRef.current ? toolbarRef.current?.offsetHeight : 0)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize, false)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setToolbarHeight(toolbarRef.current ? toolbarRef.current.offsetHeight : 0)
        }, 300)
    }, [toolbarExpanded, toolbarHeightCalculated])

    return (
        <StyledMainElement drawerState={noSidebar ? 'collapsed' : drawerState} isOnboarding={!selectedProject}>
            {showAppbar && (
                <AppBar elevation={0} color="transparent" position="sticky" sx={{ overscrollBehavior: 'none', touchAction: 'none' }} ref={toolbarRef}>
                    <Box pt={1} pb={1} pr={2} pl={2} sx={{ overscrollBehavior: 'scroll', touchAction: 'none' }}>
                        {title && (
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={fullWidthTitle ? 12 : undefined}>
                                    {title}
                                </Grid>

                                {!isMobile ? (
                                    <Grid
                                        item
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="flex-end"
                                        justifyContent={disableToolbarToggle && toolbarInSameLine ? 'center' : undefined}
                                        height={60}
                                    >
                                        {toolbar && !disableToolbarToggle && (
                                            <>
                                                <Box display="flex" alignItems="center">
                                                    {toolbarExpanded && clearFilterAvailable && onClearFilter && (
                                                        <>
                                                            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                                                                <FormattedMessage id={'general.filter-reset'} />
                                                            </Typography>
                                                            <IconButton onClick={() => onClearFilter()} sx={{ mr: 1 }}>
                                                                <ClearIcon fontSize={'small'} />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                                                        <FormattedMessage id={toolbarExpanded ? 'general.filter-collapse' : 'general.filter-expand'} />
                                                    </Typography>
                                                    <IconButton onClick={() => dispatch(toggleToolbar())}>
                                                        {toolbarExpanded ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>
                                                </Box>
                                                {!!filterSummaryWhenCollapsed && !toolbarExpanded && filterSummaryWhenCollapsed}
                                            </>
                                        )}
                                        {toolbar && toolbarInSameLine && disableToolbarToggle && <Box>{toolbar}</Box>}
                                    </Grid>
                                ) : (
                                    toolbar &&
                                    toolbarInSameLine &&
                                    disableToolbarToggle && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'end',
                                                flexGrow: 1,
                                                p: 1,
                                                pb: 0,
                                            }}
                                        >
                                            {toolbar}
                                        </Box>
                                    )
                                )}
                            </Grid>
                        )}
                        {toolbar && !toolbarInSameLine && (
                            <>
                                {isMobile ? (
                                    <>
                                        <Box
                                            onClick={() => dispatch(toggleToolbar())}
                                            display={'flex'}
                                            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                                            component={Paper}
                                            p={1}
                                            mt={2}
                                        >
                                            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                                                <FormattedMessage
                                                    id={toolbarToggleText || (toolbarExpanded ? 'general.filter-collapse' : 'general.filter-expand')}
                                                />
                                            </Typography>
                                            {toolbarExpanded ? <ExpandLess /> : <ExpandMore />}
                                        </Box>
                                        <SwipeableDrawer
                                            keepMounted
                                            open={toolbarExpanded}
                                            anchor={'top'}
                                            onClose={() => dispatch(toggleToolbar())}
                                            onOpen={() => {}}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    background: appThemeColor,
                                                    borderBottomLeftRadius: 20,
                                                    borderBottomRightRadius: 20,
                                                    overflow: 'visible',
                                                },
                                            }}
                                        >
                                            <Box p={2} pb={3}>
                                                {toolbar}
                                            </Box>
                                            <Box
                                                sx={{
                                                    height: '5px',
                                                    width: '30%',
                                                    background: darkMode
                                                        ? lighten(theme.palette.background.paper, 0.3)
                                                        : darken(theme.palette.background.paper, 0.3),
                                                    margin: '10px auto',
                                                    borderRadius: 100,
                                                }}
                                            />
                                        </SwipeableDrawer>
                                    </>
                                ) : (
                                    <Collapse in={disableToolbarToggle ? true : toolbarExpanded}>
                                        <Box mt={1}>{toolbar}</Box>
                                    </Collapse>
                                )}
                            </>
                        )}
                    </Box>
                </AppBar>
            )}
            <Box
                sx={{
                    height: `calc(100% - ${toolbarHeight}px)`,
                    overflow: 'scroll',
                    pt: 2,
                    pb: 2,
                }}
            >
                <Container
                    maxWidth={maxWidth}
                    sx={{
                        height: '100%',
                        position: 'relative',
                        paddingLeft: '16px !important',
                        paddingRight: '16px !important',
                    }}
                >
                    {children as any}
                </Container>
            </Box>
        </StyledMainElement>
    )
}
