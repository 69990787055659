import * as _ from 'lodash'
import { FC } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getSelectedTags } from './ResultsSlice'
import { getPersistedDatasourceTags } from '../settings/SettingsSlice'

type ResultTagFilterProps = {
    open: boolean
    onOpen: () => void
    onClose: () => void
    onChange: (value: string[]) => void
    value: string[]
    fullWidth?: boolean
}

export const ResultTagFilter: FC<ResultTagFilterProps> = ({ open, onOpen, onClose, onChange, fullWidth }) => {
    const availableTags = useSelector(getPersistedDatasourceTags)
    const selectedTags = useSelector(getSelectedTags)
    const intl = useIntl()

    const handleChange = (event: SelectChangeEvent<any>) => {
        onChange(event.target.value)
    }

    const renderValue = (tagsToDisplay: string[]) => {
        if (tagsToDisplay.length > 2) {
            return intl.formatMessage({ id: 'filters.xTagsSelected' }, { x: tagsToDisplay.length })
        }

        if (tagsToDisplay.length === 0) {
            return intl.formatMessage({ id: 'post.analysis.no-tags-selected' })
        }
        return tagsToDisplay
            .map((option, index) => {
                if (!!option) {
                    return index === tagsToDisplay.length - 1 ? option : option + ', '
                }

                return ''
            })
            .toString()
    }

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel shrink={true} variant={'outlined'}>
                <FormattedMessage id="filters.tags" />
            </InputLabel>
            <Select
                variant={'outlined'}
                fullWidth={fullWidth}
                notched
                label={<FormattedMessage id="filters.tags" />}
                value={selectedTags}
                onChange={handleChange}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                renderValue={renderValue}
                MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'right' },
                }}
                displayEmpty
                multiple
            >
                {_.map(availableTags, (tag) => (
                    <MenuItem dense key={tag} value={tag}>
                        <Checkbox size="small" checked={selectedTags.indexOf(tag) > -1} />
                        <ListItemText primary={tag} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
