import { styled, TextField } from '@mui/material'
import { FC } from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextFieldProps } from '@mui/material/TextField/TextField'

export type ConnectedTextFieldProps = {
    name: string
    variant?: 'standard' | 'filled' | 'outlined'
    label?: string
    type?: string
    helperText?: string
    shrinkLabel?: boolean
    validate?: any
    fullWidth?: boolean
    multiline?: boolean
    rows?: number
    minRows?: number
    maxRows?: number
    maxLength?: number
    disabled?: boolean
    required?: boolean
    readonly?: boolean
    autoFocus?: boolean
    updateKey?: string
    placeholder?: string
    endIcon?: JSX.Element
    disableMaxLength?: boolean
    disableValidation?: boolean
    min?: number
    max?: number
    step?: number
    inputSx?: any
}

export const StyledTextField = styled(TextField, {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'readonly',
})<TextFieldProps & { readonly?: boolean }>(({ theme, readonly }) => {
    return readonly
        ? {
              '& .MuiInputBase-input': {
                  color: theme.palette.text.primary,
                  WebkitTextFillColor: `${theme.palette.text.primary} !important`,
              },
              // '& .Mui-disabled:before': {
              //     borderBottomStyle: 'none',
              // },
          }
        : {}
})

export const ConnectedTextField: FC<ConnectedTextFieldProps> = (props) => {
    const {
        name,
        label,
        type,
        shrinkLabel,
        validate,
        helperText,
        fullWidth,
        multiline,
        maxLength,
        disabled,
        required,
        readonly,
        placeholder,
        rows,
        minRows,
        maxRows,
        autoFocus = false,
        updateKey,
        endIcon,
        disableMaxLength,
        variant,
        disableValidation,
        min,
        max,
        step,
        inputSx,
    } = props

    const intl = useIntl()

    return (
        <Field name={name} validate={disableValidation ? undefined : validate} key={updateKey}>
            {({ input, meta }) => {
                return (
                    <StyledTextField
                        variant={variant}
                        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : undefined}
                        autoFocus={autoFocus}
                        value={input.value || ''}
                        onChange={input.onChange}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        disabled={disabled || readonly}
                        readonly={readonly}
                        multiline={multiline}
                        rows={rows}
                        minRows={minRows}
                        maxRows={maxRows}
                        label={
                            label ? (
                                <>
                                    <FormattedMessage id={label} />
                                    {required ? ' *' : ''}
                                </>
                            ) : undefined
                        }
                        type={type || 'text'}
                        InputLabelProps={{
                            variant: variant,
                            shrink: shrinkLabel,
                            disabled: readonly ? false : disabled,
                        }}
                        fullWidth={fullWidth !== false}
                        error={
                            !disableValidation && (meta.error || meta.submitError) && (meta.visited || meta.touched || meta.dirty || meta.initial !== undefined)
                        }
                        inputProps={
                            type === 'number'
                                ? {
                                      min: min,
                                      max: max,
                                      step: step,
                                  }
                                : {
                                      maxLength: disableMaxLength ? undefined : maxLength || 72,
                                  }
                        }
                        InputProps={{
                            endAdornment: endIcon,
                            sx: inputSx,
                        }}
                        helperText={
                            (meta.visited || meta.touched || meta.dirty || meta.initial !== undefined) && (meta.error || meta.submitError) ? (
                                <FormattedMessage id={meta.error || meta.submitError} />
                            ) : (
                                helperText && <FormattedMessage id={helperText} />
                            )
                        }
                    />
                )
            }}
        </Field>
    )
}
