import { DashboardTemplateChannelConfig } from './DashboardTemplate'
import { FormattedMessage, IntlShape } from 'react-intl'

export const getAlertMessageForChannelConfig = (intl: IntlShape, channel: DashboardTemplateChannelConfig) => {
    if (!channel.min_data_sources && !channel.max_data_sources) {
        return <FormattedMessage id="reporting.dashboards.can-select-any-channels" values={{ type: intl.formatMessage({ id: channel.type }) }} />
    }

    if (channel.min_data_sources === channel.max_data_sources) {
        return (
            <FormattedMessage
                id="reporting.dashboards.must-select-x-channel"
                values={{
                    amount: channel.min_data_sources,
                    type: intl.formatMessage({ id: channel.type }),
                }}
            />
        )
    }

    if (channel.min_data_sources && channel.max_data_sources) {
        return (
            <FormattedMessage
                id="reporting.dashhboards.must-select-between-channels"
                values={{
                    min_amount: channel.min_data_sources,
                    max_amount: channel.max_data_sources,
                    type: intl.formatMessage({ id: channel.type }),
                }}
            />
        )
    }

    if (channel.min_data_sources && !channel.max_data_sources) {
        return (
            <FormattedMessage
                id="reporting.dashboards.must-select-min-channels"
                values={{
                    amount: channel.min_data_sources,
                    type: intl.formatMessage({ id: channel.type }),
                }}
            />
        )
    }

    if (!channel.min_data_sources && channel.max_data_sources) {
        return (
            <FormattedMessage
                id="reporting.dashboards.can-select-up-to-x-channels"
                values={{
                    amount: channel.max_data_sources,
                    type: intl.formatMessage({ id: channel.type }),
                }}
            />
        )
    }

    return null
}
