import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../core/Store'
import { doDelete, doGet, doPost, doPut } from '../core/api/ApiClient'
import { PublishingMedia } from './PublishingMedia'
import { UploadMediaRequest } from './UploadMediaRequest'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { PublishingLinkPreviewMetadata } from './PublishingLinkPreviewMetadata'
import { TiktokCreatorInfo } from './TiktokCreatorInfo'

const PUBLISHING_SLASH = (action: string) => `publishing/${action}`

export const uploadMedia = createAsyncThunk<
    PublishingMedia,
    UploadMediaRequest,
    {
        state: HmstrState
    }
>(PUBLISHING_SLASH('uploadMedia'), async (uploadMediaRequest, thunkAPI) => {
    const state = thunkAPI.getState()
    const project = state.data.projects[uploadMediaRequest.project_id]

    const formData = new FormData()

    formData.append('project_id', uploadMediaRequest.project_id)
    formData.append('name', uploadMediaRequest.file.name)
    formData.append('category', uploadMediaRequest.category)
    formData.append('mime_type', uploadMediaRequest.file.type)
    formData.append('width', uploadMediaRequest.width.toString())
    formData.append('height', uploadMediaRequest.height.toString())
    formData.append('file_size', uploadMediaRequest.file_size.toString())
    formData.append('link_preview', uploadMediaRequest.link_preview.toString())
    if (uploadMediaRequest.duration) {
        formData.append('duration', uploadMediaRequest.duration.toString())
    }

    formData.append('file', uploadMediaRequest.file)

    return doPost(thunkAPI, project._links.media, uploadMediaRequest, undefined, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
})

export const updateMedia = createAsyncThunk<PublishingMedia, { request: UploadMediaRequest; media: PublishingMedia }>(
    PUBLISHING_SLASH('updateMedia'),
    async (args, thunkAPI) => {
        return await doPut(thunkAPI, args.media._links.self, args.request, undefined, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    }
)

export const deleteMedia = createAsyncThunk<PublishingMedia, PublishingMedia>(PUBLISHING_SLASH('deleteMedia'), async (media, thunkAPI) => {
    await doDelete(thunkAPI, media._links.self)
    return media
})

export const checkIsDatasourceDomainOwner = createAsyncThunk<
    boolean,
    {
        datasource: ProjectDatasource
        link: string
    }
>(PUBLISHING_SLASH('checkIsDatasourceDomainOwner'), async ({ datasource, link }, thunkAPI) => {
    return await doGet(thunkAPI, datasource._links.check_domain_ownership.href, undefined, { params: { url: link } })
})

export const fetchLinkPreviewMetadata = createAsyncThunk<
    PublishingLinkPreviewMetadata | null,
    string,
    {
        state: HmstrState
    }
>(PUBLISHING_SLASH('fetchLinkPreviewMetadata'), async (url, thunkAPI) => {
    const state = thunkAPI.getState()

    return await doGet<PublishingLinkPreviewMetadata, { metadata: PublishingLinkPreviewMetadata }>(
        thunkAPI,
        state.api.entryPoint._links.link_preview_metadata,
        (response) => response.metadata,
        { params: { url: url } }
    )
})

export const fetchTiktokCreatorInfo = createAsyncThunk<TiktokCreatorInfo | undefined, ProjectDatasource>(
    PUBLISHING_SLASH('fetchTiktokCreatorInfo'),
    async (datasource, thunkAPI) => {
        return await doGet<TiktokCreatorInfo | undefined>(thunkAPI, datasource._links.tiktok_creator_info)
    }
)
