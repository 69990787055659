import { ColorChangeHandler, ColorResult, SketchPicker } from 'react-color'
import * as React from 'react'
import { FC } from 'react'
import { Button, emphasize, Popover } from '@mui/material'

type TagColorPickerProps = {
    color: string
    changeCallback?: ColorChangeHandler
    changeCompleteCallback?: ColorChangeHandler
    disabled?: boolean
}

export const TagColorPicker: FC<TagColorPickerProps> = ({ color, changeCallback, changeCompleteCallback, disabled = false }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <>
            <Button
                sx={{
                    height: '24px',
                    width: '24px',
                    maxHeight: '24px',
                    minHeight: '24px',
                    maxWidth: '24px',
                    minWidth: '24px',
                    backgroundColor: color,
                    borderRadius: '100%',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    transition: '0s', // do not remove or else it feels like this thing is lagging/rebuilding to slowly,
                    '&:hover': {
                        backgroundColor: emphasize(color),
                    },
                }}
                onClick={handleClick}
                disabled={disabled}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SketchPicker
                    color={color}
                    onChange={(color: ColorResult, event: any) => {
                        if (!!changeCallback) {
                            changeCallback(color, event)
                        }
                    }}
                    onChangeComplete={changeCompleteCallback}
                    disableAlpha={true}
                />
            </Popover>
        </>
    )
}
