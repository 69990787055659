import * as React from 'react'
import { FC } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { Box, Chip, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Tag } from '../../tags/Tag'
import { Rectangle, ResponsiveContainer, Tooltip, TooltipProps, Treemap } from 'recharts'
import { HmstrColors } from '../../core/theme/hmstr-theme'
import { ResultTooltipBox } from '../../monitoring/Results.styles'
import FacebookIcon from '@mui/icons-material/Facebook'
import CampaignIcon from '@mui/icons-material/Campaign'
import { useSelector } from 'react-redux'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { clampText, formatNumber } from '../../core/theme/helper'
import { CommonPostData } from '../posts/CommonPostData'
import { Instagram, LinkedIn } from '@mui/icons-material'
import { NoData } from '../../common/chart-tooltip/NoData'
import { IdMap } from '../../core/slices/DataSlice'
import { getPagesForAnalyse } from '../../core/slices/CoreSlice'

type PostAnalysisTreeChartProps = {
    title: string
    data: IdMap<CommonPostData[]>
    tags: Tag[]
    getValue: (entry: CommonPostData) => number
    onClick?: (entry: CommonPostData) => void
    summaryThreshold?: number
}

export const PostAnalysisTreeChart: FC<PostAnalysisTreeChartProps> = ({ title, data, tags, getValue, onClick, summaryThreshold = 0.03 }) => {
    const facebookPages = useSelector(getPagesForAnalyse)

    const formatData = () => {
        let transformedData: any[] = []
        Object.keys(data).forEach((entry) => {
            const value = data[entry]
            let tag = tags.find((tag) => tag.id === entry)
            if (tag) {
                let maxValue = 0

                transformedData.push({
                    name: tag.label,
                    tag: tag,
                    children: value.map((post) => {
                        let value = getValue(post)
                        if (maxValue < value) {
                            maxValue = value
                        }
                        return { name: '', size: getValue(post), post: post }
                    }),
                })

                if (summaryThreshold) {
                    let firstEntry: any
                    let aggregateValue = 0
                    let aggregateAmount = 0
                    let aggregateThreshold = Math.floor(maxValue * summaryThreshold)

                    transformedData[transformedData.length - 1].children = transformedData[transformedData.length - 1].children.filter((data: any) => {
                        if (data.size < aggregateThreshold) {
                            if (!firstEntry) {
                                firstEntry = data
                            }
                            aggregateValue += data.size
                            aggregateAmount++
                            return false
                        }
                        return true
                    })

                    if (aggregateAmount === 1) {
                        // there was no reason to aggregate
                        transformedData[transformedData.length - 1].children.push(firstEntry)
                    } else {
                        transformedData[transformedData.length - 1].children.push({
                            name: '',
                            size: aggregateValue,
                            aggregateAmount: aggregateAmount,
                            aggregateThreshold: aggregateThreshold,
                        })
                    }
                }
            }
        })
        transformedData.map((i) => (i['total'] = _.sum(i.children.map((entry: any) => entry.size))))
        transformedData = transformedData.sort((a, b) => a.total - b.total)
        return transformedData
    }

    const getTextWidth = (text: string, font: string | null) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')

        context!.font = font || getComputedStyle(document.body).font

        return context!.measureText(text).width
    }

    const renderTreeMapBox = (props: any) => {
        const { name, depth, x, y, width, height, index, aggregateAmount } = props
        const color = formatData().map((data) => data.tag.color)
        let text = null
        if (width > 40 && height > 20 && depth < 2) {
            const textWidth = getTextWidth(name, '16px')
            text = (
                <text x={x + width / 2 - textWidth / 2} y={y + height / 2 - 7} fontSize={16} fill="#fff">
                    {name}
                </text>
            )
        }
        let number = null
        if (depth === 1) {
            number = (
                <text x={x + 4} y={y + 18} fill="#fff" fontSize={16}>
                    {index + 1}
                </text>
            )
        }
        return (
            <g>
                <Rectangle
                    fill={depth < 2 ? (Boolean(name) ? color[index % color.length] : '#000000') : 'rgba(255,255,255,0)'}
                    stroke="#fff"
                    {..._.omit(props, 'children', 'z')}
                    style={!aggregateAmount ? { cursor: 'pointer' } : {}}
                />
                {text}
                {number}
            </g>
        )
    }

    const TreemapTooltip: FC<TooltipProps<any, any>> = ({ active, payload }) => {
        if (active && payload && payload[0] && payload[0].payload && (payload[0].payload.post || payload[0].payload.aggregateThreshold)) {
            if (payload[0].payload.aggregateThreshold) {
                return (
                    <ResultTooltipBox>
                        <Box display="flex" alignItems="flex-start" flexDirection="column" padding={1}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="post.analysis.tooltip.post-summary-title" />
                                </Typography>
                            </Box>

                            <Typography sx={{ margin: '0 0 12px' }}>
                                <FormattedMessage
                                    id="post.analysis.tooltip.post-summary-description"
                                    values={{
                                        amount: payload[0].payload.aggregateAmount,
                                        threshold: formatNumber(payload[0].payload.aggregateThreshold as number),
                                    }}
                                />
                            </Typography>

                            <Box display="flex">
                                <CampaignIcon style={{ color: HmstrColors.White }} sx={{ marginRight: '4px' }} />
                                <Typography>
                                    <FormattedMessage id="facebook.impressions" /> {formatNumber(payload[0].value as number)}
                                </Typography>
                            </Box>
                        </Box>
                    </ResultTooltipBox>
                )
            }

            const post = payload[0].payload.post as CommonPostData
            const dataSource: ProjectDatasource | undefined = facebookPages.find((page) => page.id === post.data_source_id)

            const getPostTypeIcon = (postType: CommonPostData['type']) => {
                switch (postType) {
                    case 'INSTAGRAM':
                        return <Instagram htmlColor={HmstrColors.White} sx={{ mr: '4px' }} />
                    case 'DARK_POST':
                    case 'FACEBOOK':
                        return <FacebookIcon htmlColor={HmstrColors.White} sx={{ mr: '4px' }} />
                    case 'LINKED_IN':
                        return <LinkedIn htmlColor={HmstrColors.White} sx={{ mr: '4px' }} />
                }
            }

            return (
                <ResultTooltipBox>
                    <Box display="flex" alignItems="flex-start" flexDirection="column" padding={1}>
                        <Box display="flex" alignItems="center">
                            {getPostTypeIcon(post.type)}
                            <Typography variant="subtitle1">{dataSource?.name}</Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1" gutterBottom component="div" style={{ display: 'flex', alignItems: 'center' }}>
                                {moment(post.created_at).format('DD.MM.YYYY | HH:mm')}
                            </Typography>
                            {post.type === 'DARK_POST' && (
                                <Chip sx={{ ml: 1 }} size="small" color="primary" label={<FormattedMessage id="tagging.dark-post" />} />
                            )}
                        </Box>

                        <Typography sx={{ margin: '0 0 12px' }}>
                            {post.message === '{{product.brand}}' ? (
                                <em>
                                    <FormattedMessage id="tagging.dynamic-ad" />
                                </em>
                            ) : (
                                clampText(post.message || '', 80)
                            )}
                        </Typography>

                        <Box display="flex">
                            <CampaignIcon style={{ color: HmstrColors.White }} sx={{ marginRight: '4px' }} />
                            <Typography>
                                <FormattedMessage id="facebook.impressions" /> {formatNumber(payload[0].value as number)}
                            </Typography>
                        </Box>
                    </Box>
                </ResultTooltipBox>
            )
        }
        return null
    }

    return (
        <>
            <Box padding={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Typography sx={{ pl: 1 }}>
                    <strong>
                        <FormattedMessage id={title} />
                    </strong>
                </Typography>
            </Box>

            <Box width="100%" padding={2} height={520}>
                <ResponsiveContainer width="100%" height="100%">
                    {Object.keys(data).length > 0 ? (
                        <Treemap
                            data={formatData()}
                            dataKey="size"
                            aspectRatio={4 / 3}
                            content={renderTreeMapBox as any}
                            onClick={(e) => onClick && e.post && onClick(e.post)}
                        >
                            <Tooltip content={TreemapTooltip} cursor={false} />
                        </Treemap>
                    ) : (
                        <NoData />
                    )}
                </ResponsiveContainer>
            </Box>
        </>
    )
}
