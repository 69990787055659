import * as _ from 'lodash'
import { FC } from 'react'
import { Alert, Box, Grid, MenuItem, Typography } from '@mui/material'
import { AddCircle, Info, Receipt } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { validationRequired } from '../../form/validate'
import { useSelector } from 'react-redux'
import { getOwnedAccounts } from '../../core/slices/CoreSlice'
import { ConnectedDropdown } from '../../form/ConnectedDropdown'
import { useForm } from 'react-final-form'
import { ConnectedCountryDropdown } from '../../form/ConnectedCountryDropdown'

type BillingInfoFormFieldsProps = {
    disableCustomerId: boolean
    disableHint?: boolean
}

export const BillingInfoFormFields: FC<BillingInfoFormFieldsProps> = ({ disableCustomerId, disableHint }) => {
    const accounts = useSelector(getOwnedAccounts)
    const accountsByCustomerId = _.groupBy(accounts, 'customer_id')
    const form = useForm()

    const handleChange = (newCustomerId: string) => {
        const accountsForCustomer = accountsByCustomerId[newCustomerId]

        if (accountsForCustomer && accountsForCustomer.length > 0) {
            form.change('billing_info', accountsForCustomer[0].billing_info)
        } else if (newCustomerId === 'new') {
            form.change('billing_info', {})
        }
    }

    return (
        <>
            {!disableHint && (
                <Grid item xs={12}>
                    <Alert color="info" icon={<Info />} sx={{ mt: disableCustomerId ? 0 : 2 }}>
                        {disableCustomerId ? <FormattedMessage id="billing.already-customer" /> : <FormattedMessage id="billing.new-customer-hint" />}
                    </Alert>
                </Grid>
            )}

            {Object.keys(accountsByCustomerId).length > 0 && (
                <Grid item xs={12}>
                    <ConnectedDropdown
                        disabled={disableCustomerId}
                        required={true}
                        validate={validationRequired}
                        name="customer_id"
                        label="billing.customer-number"
                        fullWidth={true}
                        onChange={handleChange}
                    >
                        {[
                            ...Object.keys(accountsByCustomerId).map((cid) => (
                                <MenuItem key={cid} value={cid}>
                                    <Box display="flex" gap={2} alignItems="center">
                                        <Receipt color="action" fontSize="small" />
                                        {disableCustomerId ? (
                                            <Typography>{cid}</Typography>
                                        ) : (
                                            <Typography>
                                                {accountsByCustomerId[cid][0].billing_info.email} ({cid})
                                            </Typography>
                                        )}
                                    </Box>
                                </MenuItem>
                            )),
                            <MenuItem key="new" value="new">
                                <Box display="flex" gap={2} alignItems="center">
                                    <AddCircle color="action" fontSize="small" />
                                    <Typography>Neue Kundennummer anlegen</Typography>
                                </Box>
                            </MenuItem>,
                        ]}
                    </ConnectedDropdown>
                </Grid>
            )}

            <Grid item xs={12}>
                <ConnectedTextField name="billing_info.email" label="general.email" required={true} validate={validationRequired} />
            </Grid>

            <Grid item xs={12}>
                <ConnectedTextField name="billing_info.company" label="accounts.company-optional" />
            </Grid>

            <Grid item xs={12} md={6}>
                <ConnectedTextField
                    name="billing_info.first_name"
                    label="general.firstName"
                    helperText="accounts.contact-person"
                    validate={validationRequired}
                    required={true}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <ConnectedTextField name="billing_info.last_name" label="general.lastName" validate={validationRequired} required={true} />
            </Grid>

            <Grid item xs={12} md={6}>
                <ConnectedTextField name="billing_info.address.street" label="general.street" validate={validationRequired} required={true} />
            </Grid>

            <Grid item xs={12} md={6}>
                <ConnectedTextField name="billing_info.address.street_no" label="general.streetNo" validate={validationRequired} required={true} />
            </Grid>

            <Grid item xs={12} md={6}>
                <ConnectedTextField name="billing_info.address.zip_code" label="general.plz" validate={validationRequired} required={true} />
            </Grid>

            <Grid item xs={12} md={6}>
                <ConnectedTextField name="billing_info.address.city" label="general.city" validate={validationRequired} required={true} />
            </Grid>

            <Grid item xs={12}>
                <ConnectedCountryDropdown name="billing_info.address.country_code" label="general.country" validate={validationRequired} required={true} />
            </Grid>

            <Grid item xs={6}>
                <ConnectedTextField name="billing_info.phone_number" label="general.phone" validate={validationRequired} required={true} />
            </Grid>

            <Grid item xs={6}>
                <ConnectedTextField name="billing_info.mobile_number" label="general.mobile_number" />
            </Grid>
        </>
    )
}
