import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { ProjectCard } from './ProjectCard'
import { UserProjectAccess } from '../user-management/User'
import { openFullscreenDialog } from '../../core/slices/CoreSlice'
import { useHasAccountPermission } from '../../core/hooks/useHasPermission'
import { Account } from '../accounts/Account'
import { Add, Info } from '@mui/icons-material'

type LaunchpadListProps = {
    selectedAccount?: Account
    projectAccessList: Array<UserProjectAccess>
}

export const ProjectList: FC<LaunchpadListProps> = ({ projectAccessList, selectedAccount }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const canCreateProject = useHasAccountPermission('account.create_project', selectedAccount?.id)

    return (
        <Container
            maxWidth="lg"
            sx={{
                alignSelf: 'center',
                justifySelf: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box marginBottom={2} marginTop={2}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Box display="flex" alignItems="center">
                            {projectAccessList.length >= 1 && (
                                <>
                                    <Typography component="h1" variant="h5">
                                        <FormattedMessage
                                            id="projects.title"
                                            values={{
                                                accountName: selectedAccount?.name,
                                            }}
                                        />
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Grid>

                    {canCreateProject && (
                        <Grid item>
                            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="secondary"
                                        startIcon={<Add />}
                                        onClick={() => dispatch(openFullscreenDialog('new-project'))}
                                        sx={{ whiteSpace: 'nowrap' }}
                                    >
                                        <FormattedMessage id="projects.new" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Box mb={2}>
                <Alert color="info" icon={<Info />}>
                    <FormattedMessage id="projects.explained" />
                </Alert>
            </Box>

            <Grid container spacing={2} pb={2}>
                {projectAccessList.map((projectAccess) => (
                    <ProjectCard key={projectAccess.project_id} projectAccess={projectAccess} />
                ))}
            </Grid>
        </Container>
    )
}
