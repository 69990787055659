import { FC } from 'react'
import { Box, darken, lighten, List, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { UserAvatar } from '../common/avatars/UserAvatar'
import { Brightness4, ExitToApp, Info, Person } from '@mui/icons-material'
import { getCurrentUser, getMobileUserMenuExpanded, isDarkMode, showInfoDialog, toggleDarkMode, toggleMobileUserMenuExpanded } from '../core/slices/CoreSlice'
import { useKeycloak } from '../core/hooks/useKeycloak'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { PrimaryNavigationButtonMobile } from './PrimaryNavigationButtonMobile'
import { ProjectSelect } from './ProjectSelect'

type UserMenuMobileProps = {}

export const UserMenuMobile: FC<UserMenuMobileProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const keycloak = useKeycloak()
    const currentUser = useSelector(getCurrentUser)
    const theme = useTheme()
    const darkMode = useSelector(isDarkMode)
    const mobileUserMenuExpanded = useSelector(getMobileUserMenuExpanded)

    const handleInfoClick = () => {
        dispatch(toggleMobileUserMenuExpanded())
        dispatch(showInfoDialog())
    }
    const handleDarmodeClick = () => {
        dispatch(toggleDarkMode())
    }

    return (
        <>
            <PrimaryNavigationButtonMobile
                size="large"
                // tooltip="general.user-menu.tooltip"
                icon={
                    currentUser && (
                        <UserAvatar
                            id={'primary_nav_user'}
                            user={keycloak}
                            sx={{
                                height: 40,
                                width: 40,
                            }}
                        />
                    )
                }
                onClick={() => {
                    dispatch(toggleMobileUserMenuExpanded())
                }}
            />
            <SwipeableDrawer
                open={mobileUserMenuExpanded}
                onClose={() => dispatch(toggleMobileUserMenuExpanded())}
                onOpen={() => {}}
                anchor={'bottom'}
                disableSwipeToOpen
                PaperProps={{
                    sx: {
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                        pb: 4,
                    },
                }}
            >
                <Box
                    sx={{
                        height: '5px',
                        width: '30%',
                        background: darkMode ? lighten(theme.palette.background.paper, 0.3) : darken(theme.palette.background.paper, 0.3),
                        margin: '10px auto',
                        borderRadius: 100,
                    }}
                />
                <Box px={2}>
                    <ProjectSelect />
                </Box>
                <List sx={{ minWidth: 200 }}>
                    <Box p={2}>{keycloak.fullName}</Box>
                    <ListItemButton onClick={() => keycloak.accountManagement()}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="general.keycloak-account" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleInfoClick}>
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="general.info" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleDarmodeClick}>
                        <ListItemIcon>
                            <Brightness4 />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id={darkMode ? 'settings.light-mode' : 'settings.dark-mode'} />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => keycloak.logout()}>
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="general.logout" />
                        </ListItemText>
                    </ListItemButton>
                </List>
            </SwipeableDrawer>
        </>
    )
}
