export const scopesByModule: { [scope: string]: 'analytics' | 'publishing' | 'engagement' | 'general' } = {
    instagram_manage_insights: 'analytics',
    ads_management: 'analytics',
    ads_read: 'analytics',
    read_insights: 'analytics',
    r_ads: 'analytics',
    r_ads_reporting: 'analytics',
    rw_organization_admin: 'analytics',
    'video.list': 'publishing',
    pages_manage_posts: 'publishing',
    instagram_content_publish: 'publishing',
    w_organization_social: 'publishing',
    w_member_social: 'publishing',
    'video.publish': 'publishing',
    'video.upload': 'publishing',
    pages_manage_engagement: 'engagement',
    pages_messaging: 'engagement',
    instagram_manage_messages: 'engagement',
    instagram_manage_comments: 'engagement',
    pages_read_user_content: 'engagement',
    pages_read_engagement: 'engagement',
    r_organization_social: 'general',
    pages_manage_metadata: 'general',
    instagram_basic: 'general',
    pages_show_list: 'general',
    business_management: 'general',
    email: 'general',
    r_basicprofile: 'general',
    r_emailaddress: 'general',
    'user.info.basic': 'general',
    'user.info.profile': 'general',
    'user.info.stats': 'general',
}
