import { Autocomplete, TextField } from '@mui/material'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getSortedTagsData } from '../../core/slices/DataSlice'
import { createTag } from '../../tags/TagsActions'
import { HmstrDispatch } from '../../core/Store'
import { getSelectedProject, showErrorSnackbar } from '../../core/slices/CoreSlice'
import { Tag } from '../../tags/Tag'
import { FacebookPostTagData, updateFacebookPostsData } from '../posts/PostsActions'
import { TagChip } from './TagChip'
import { TagStatusFilterType } from '../../common/filter/TagStatusFilterType'
import { CommonPostData } from '../posts/CommonPostData'

type TagAutocompleteProps = {
    post: CommonPostData
    tagFilter: TagStatusFilterType
    onTagsChange: (tags: string[]) => any
    multiplePosts?: CommonPostData[]
    disabled?: boolean
}

export const TagAutocomplete: FC<TagAutocompleteProps> = ({ post, tagFilter = TagStatusFilterType.ALL, onTagsChange, multiplePosts, disabled }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const project = useSelector(getSelectedProject)
    const tags = useSelector(getSortedTagsData)
    const intl = useIntl()
    const [activeTagIds, setActiveTagIds] = useState<string[]>(post.tags)

    useEffect(() => {
        setActiveTagIds(post.tags)
    }, [post])
    const activeTags = activeTagIds
        .map((id) => tags.find((tag) => tag.id === id))
        .filter(
            (tag) =>
                tag !== undefined &&
                (tagFilter === TagStatusFilterType.ALL ||
                    (tagFilter === TagStatusFilterType.ACTIVE && tag.active) ||
                    (tagFilter === TagStatusFilterType.INACTIVE && !tag.active))
        )

    const dispatchUpdate = (newTags: string[]) => {
        setActiveTagIds(newTags)
        onTagsChange(newTags)
        let postData: FacebookPostTagData[] = []

        if (multiplePosts) {
            multiplePosts.forEach((singlePost) => {
                postData.push({
                    _id: singlePost.id,
                    post_id: singlePost.post_id,
                    tag_ids: newTags,
                    data_source_id: singlePost.data_source_id,
                })
            })
        } else {
            postData.push({
                _id: post.id,
                post_id: post.post_id,
                tag_ids: newTags,
                data_source_id: post.data_source_id,
            })
        }

        if (project != null) {
            dispatch(
                updateFacebookPostsData({
                    project: project,
                    postData: postData,
                })
            )
        }
    }

    const saveTag = (tag: Tag) => {
        dispatchUpdate([...activeTagIds, tag.id])
    }

    const removeTag = (tag: Tag) => {
        let newTags = activeTagIds.filter((element) => element !== tag.id)
        dispatchUpdate(newTags)
    }

    const createNewTag = (label: string) => {
        if (project != null) {
            let tag: Tag = {
                id: '',
                tag_id: '',
                label: label,
                active: true,
                color: '#EEE',
                project_id: project.id,
                usage_count: 0,
                _links: { self: { href: '' } },
            }
            dispatch(createTag({ project, tag: tag })).then((result: any) => {
                if (result.meta.requestStatus === 'rejected' && (result.payload as any).status === 426) {
                    dispatch(showErrorSnackbar('tags.tag-limit-reached'))
                } else {
                    saveTag(result.payload)
                }
            })
        }
    }

    return (
        <Autocomplete
            multiple
            disabled={disabled}
            autoHighlight={true}
            id="tags-outlined"
            options={tags.filter((tag) => tag.active)}
            value={activeTags}
            onChange={(event, newValue) => {
                if (newValue && typeof newValue === 'object' && project !== undefined) {
                    let values = Object.values(newValue)

                    if (values.length > activeTags.length) {
                        values.forEach((element) => {
                            if (!!element) {
                                if (typeof element === 'string') {
                                    createNewTag(element)
                                } else {
                                    if (!activeTagIds.includes(element.id)) {
                                        saveTag(element)
                                    }
                                }
                            }
                        })
                    } else {
                        activeTags.forEach((element) => {
                            if (!!element && values.filter((tag) => !!tag && typeof tag !== 'string' && tag.id === element.id).length === 0) {
                                removeTag(element)
                            }
                        })
                    }
                }
            }}
            filterSelectedOptions
            freeSolo
            disableClearable
            renderOption={(props, option) => {
                if (!option) {
                    return undefined
                }

                return (
                    <li {...props}>
                        <TagChip tag={option} additionalProps={{}} />
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={intl.formatMessage({ id: 'general.add-tags' })}
                    sx={{
                        margin: '12px 0 0 0',
                        padding: '8px 0px',
                        '&>#tags-outlined-label': {
                            left: '12px',
                            paddingTop: '2px',
                        },
                    }}
                    variant={'outlined'}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    if (!!option) {
                        return (
                            <TagChip
                                key={option.id}
                                tag={option}
                                disabled={disabled}
                                additionalProps={
                                    disabled
                                        ? {
                                              ...getTagProps({ index }),
                                              onDelete: undefined,
                                          }
                                        : getTagProps({ index })
                                }
                            />
                        )
                    }
                    return <></>
                })
            }
        />
    )
}
