import moment from 'moment'
import { Range } from 'react-date-range'
import { IntlShape } from 'react-intl'

export const lastXUnit = (x: number, unit: 'years' | 'days' | 'months', intl?: IntlShape, strict: boolean = false) => {
    return {
        id: 'last_' + x + '_' + unit,
        label: intl ? intl.formatMessage({ id: `filters.${unit}` }, { days: x }) : '',
        range: () => ({
            startDate: moment().subtract(x, unit).toDate(),
            endDate: moment().endOf(unit).toDate(),
        }),
        isSelected: (range: Range) =>
            moment(range.startDate).isSame(moment().subtract(x, unit), 'day') &&
            moment(range.endDate).isSame(moment().endOf(unit).toDate(), strict ? 'hour' : 'day'),
        hasCustomRendering: true,
    }
}

export const currentUnit = (unit: 'week' | 'month', intl?: IntlShape, strict: boolean = false) => {
    return {
        id: 'current_' + unit,
        label: intl ? intl.formatMessage({ id: 'filters.current.' + unit }) : '',
        range: () => ({
            startDate: moment().startOf(unit).toDate(),
            endDate: moment().endOf(unit).toDate(),
        }),
        isSelected: (range: Range) =>
            moment(range.startDate).isSame(moment().startOf(unit), 'day') && moment(range.endDate).isSame(moment().endOf(unit), strict ? 'hour' : 'day'),
        hasCustomRendering: true,
    }
}

export const lastUnit = (unit: 'year' | 'week' | 'month', intl?: IntlShape, strict: boolean = false) => {
    return {
        id: 'last_' + unit,
        label: intl ? intl.formatMessage({ id: 'filters.last.' + unit }) : '',
        range: () => ({
            startDate: moment().subtract(1, unit).startOf(unit).toDate(),
            endDate: moment().subtract(1, unit).endOf(unit).toDate(),
        }),
        isSelected: (range: Range) =>
            moment(range.startDate).isSame(moment().subtract(1, unit).startOf(unit), 'day') &&
            moment(range.endDate).isSame(moment().subtract(1, unit).endOf(unit), strict ? 'hour' : 'day'),
        hasCustomRendering: true,
    }
}

export const nextToLastUnit = (unit: 'month', intl?: IntlShape, strict: boolean = false) => {
    return {
        id: 'next_to_last_' + unit,
        label: intl ? intl.formatMessage({ id: 'filters.next_to_last.' + unit }) : '',
        range: () => ({
            startDate: moment().subtract(2, unit).startOf(unit).toDate(),
            endDate: moment().subtract(2, unit).endOf(unit).toDate(),
        }),
        isSelected: (range: Range) =>
            moment(range.startDate).isSame(moment().subtract(2, unit).startOf(unit), 'day') &&
            moment(range.endDate).isSame(moment().subtract(2, unit).endOf(unit), strict ? 'hour' : 'day'),
        hasCustomRendering: true,
    }
}

export const DateRangePickerStaticRanges = (intl?: IntlShape, strict: boolean = false) => [
    currentUnit('week', intl, strict),
    currentUnit('month', intl, strict),
    lastUnit('month', intl, strict),
    nextToLastUnit('month', intl, strict),
    lastXUnit(7, 'days', intl, strict),
    lastXUnit(30, 'days', intl, strict),
    lastXUnit(90, 'days', intl, strict),
    lastXUnit(365, 'days', intl, strict),
]
