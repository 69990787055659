import { FederatedIdentity } from './FederatedIdentity'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Delete, Error, ExpandMore, Refresh } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { getScopeErrors, IdMap } from '../../core/slices/DataSlice'
import { FacebookIcon } from '../../common/icons/FacebookIcon'
import { InstagramIcon } from '../../common/icons/InstagramIcon'
import { LinkedInIcon } from '../../common/icons/LinkedInIcon'
import { TiktokIcon } from '../../common/icons/TiktokIcon'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFederatedIdentity, fetchFederatedIdentites } from '../user-management/UserActions'
import { HmstrDispatch } from '../../core/Store'
import { ConfirmDialog } from '../../common/confirm-dialog/ConfirmDialog'
import { getCurrentUser, getSelectedProjectAccess, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { Fallback } from '../../common/fallback/Fallback'
import { refreshAccount } from './AccountLinkService'
import { fetchProject } from '../project-management/ProjectActions'
import { XIcon } from '../../common/icons/XIcon'
import { AccountLinkErrorAlert } from './AccountLinkErrorAlert'

type AccountLinkTableProps = {
    groupedIdentites: IdMap<FederatedIdentity[]>
}

export const AccountLinkTable: FC<AccountLinkTableProps> = ({ groupedIdentites }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const currentUser = useSelector(getCurrentUser)
    const selectedProjectAccess = useSelector(getSelectedProjectAccess)
    const noSmallScreen = useMediaQuery(theme.breakpoints.up('md'))
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [identityToDelete, setIdentityToDelete] = useState<undefined | FederatedIdentity>(undefined)
    const scopeErrors = useSelector(getScopeErrors)

    const getIconForType = (type: FederatedIdentity['type']) => {
        switch (type) {
            case 'META':
                return (
                    <>
                        <FacebookIcon />
                        <InstagramIcon />
                    </>
                )
            case 'LINKED_IN':
                return <LinkedInIcon sx={{ mr: 3 }} />
            case 'TIKTOK':
            case 'TIKTOK_BUSINESS':
                return <TiktokIcon />
            case 'TWITTER':
                return <XIcon />
        }
    }

    const handleDeleteFederatedIdentity = () => {
        if (!showConfirmDialog) {
            setShowConfirmDialog(true)
        } else {
            if (identityToDelete) {
                dispatch(deleteFederatedIdentity(identityToDelete)).then(() => {
                    setShowConfirmDialog(false)
                    dispatch(showSuccessSnackbar('federated-identites.remove-success'))
                })
            }
        }
    }

    const handleRefreshIdentity = (identity: FederatedIdentity) => {
        refreshAccount(identity).then(() => {
            dispatch(showSuccessSnackbar('federated-identites.refresh-success'))
            dispatch(fetchFederatedIdentites(currentUser))
            if (selectedProjectAccess) {
                dispatch(fetchProject(selectedProjectAccess))
            }
        })
    }

    const renderRow = (identity: FederatedIdentity) => {
        return (
            <TableRow key={identity.id}>
                <TableCell width={200}>{identity.display_name}</TableCell>
                <TableCell>{moment(identity.last_refresh_date).format('L')}</TableCell>
                {noSmallScreen && (
                    <TableCell>
                        {identity.state === 'EXPIRED' ? (
                            <FormattedMessage id={`federated-identites.state.${identity.state}`} />
                        ) : (
                            moment(identity.expire_date).format('L')
                        )}
                    </TableCell>
                )}
                <TableCell>
                    <Box display="flex" alignItems="center" gap={0.5}>
                        {identity.state === 'EXPIRED' && <Error color="error" fontSize="small" />}
                        <FormattedMessage id={`federated-identites.state.${identity.state}`} />
                        {Object.keys(scopeErrors).includes(identity.id) && Object.keys(scopeErrors[identity.id]).length > 0 && (
                            <Tooltip title={<FormattedMessage id="settings.missing-scopes-info" />}>
                                <Error color="error" />
                            </Tooltip>
                        )}
                    </Box>
                </TableCell>
                <TableCell>{identity.user_display_name}</TableCell>
                <TableCell align="center">
                    <Box>
                        <IconButton size="small" onClick={() => handleRefreshIdentity(identity)}>
                            <Refresh />
                        </IconButton>
                    </Box>
                </TableCell>
                <TableCell align="center">
                    <Box>
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleDeleteFederatedIdentity()
                                setIdentityToDelete(identity)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
        )
    }

    const renderAccordion = (federatedIdentityType: string) => {
        const type = federatedIdentityType as FederatedIdentity['type']
        const identities = groupedIdentites[type] as FederatedIdentity[]
        const hasTypeAnyErrors = identities.map((identity) => Object.keys(scopeErrors).includes(identity.id)).includes(true)

        return (
            <Accordion defaultExpanded={true} key={federatedIdentityType} variant="elevation">
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Box display="flex" alignItems="center">
                        <Box pr={2} pt={0.25}>
                            {getIconForType(type)}
                        </Box>
                        <Typography variant="h6">
                            <FormattedMessage id={`federated-identites.${type}`} />
                        </Typography>
                    </Box>
                </AccordionSummary>
                {hasTypeAnyErrors && <AccountLinkErrorAlert identities={identities} />}
                <AccordionDetails>
                    <Box paddingTop={1}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <FormattedMessage id={'settings.account-table.name'} />
                                    </TableCell>
                                    {noSmallScreen && (
                                        <TableCell>
                                            <FormattedMessage id={'settings.account-table.renewal-date'} />
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <FormattedMessage id={'settings.account-table.runs-out'} />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id={'settings.account-table.status'} />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id={'settings.account-table.created-by'} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormattedMessage id={'general.refresh'} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormattedMessage id={'general.delete'} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{identities.map((identity) => renderRow(identity))}</TableBody>
                        </Table>
                    </Box>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <Fallback condition={Object.keys(groupedIdentites).length === 0} messageId="federated-identities.empty" marginTop={8}>
            {Object.keys(groupedIdentites)
                .sort()
                .map((federatedIdentityType) => renderAccordion(federatedIdentityType))}

            {identityToDelete && (
                <ConfirmDialog
                    open={showConfirmDialog}
                    onClose={() => setShowConfirmDialog(false)}
                    onConfirm={handleDeleteFederatedIdentity}
                    confirmText="general.delete"
                    title="federated-identites.remove"
                >
                    <FormattedMessage id="federated-identites.remove-are-you-sure" />
                </ConfirmDialog>
            )}
        </Fallback>
    )
}
