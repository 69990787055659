import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type InstagramShareProps = SvgIconProps

export const InstagramShare: FC<InstagramShareProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    fill: 'rgb(115,115,115) !important',
                    color: 'rgb(115,115,115) !important',
                },
            }}
        >
            <svg aria-label="Share Post" className="x1lliihq x1n2onr6" height="24" role="img" viewBox="0 0 24 24" width="24">
                <title>Share Post</title>
                <line fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2" x1="22" x2="9.218" y1="3" y2="10.083"></line>
                <polygon
                    fill="none"
                    points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334"
                    stroke="currentColor"
                    strokeLinejoin="round"
                    strokeWidth="2"
                ></polygon>
            </svg>
        </SvgIcon>
    )
}
