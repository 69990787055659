import * as React from 'react'
import { FC } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Person } from '@mui/icons-material'
import moment from 'moment'
import { DetailedTicket } from './DetailedTicket'
import { useSelector } from 'react-redux'
import { getSimpleUsersForSelectedProject } from '../core/slices/CoreSlice'
import { TicketActivity } from './TicketActivity'
import { TicketNote } from './TicketNoteComponents/TicketNote'

type TicketActivitiesProps = {
    detailedTicket: DetailedTicket
}

export const TicketActivities: FC<TicketActivitiesProps> = ({ detailedTicket }) => {
    const intl = useIntl()
    const users = useSelector(getSimpleUsersForSelectedProject)

    const getAssigneeName = (activity: TicketActivity) => {
        const assignee = activity.assignee_id ? users[activity.assignee_id] : undefined

        return assignee ? `${assignee.first_name} ${assignee.last_name}` : undefined
    }

    const getActivityType = (activity: TicketActivity) => {
        if (activity.type === 'ASSIGN' && !activity.assignee_id) {
            return 'UNASSIGN'
        } else if (activity.type === 'ASSIGN' && activity.user_id === activity.assignee_id) {
            return 'ASSIGN_SELF'
        }

        return activity.type
    }

    return (
        <Box display="flex" flexDirection="column" overflow="hidden">
            <Typography variant="h6" mt={4}>
                <FormattedMessage id="engagement.activity" />
            </Typography>

            <List sx={{ overflow: 'auto' }}>
                {detailedTicket.activities
                    .slice()
                    .reverse()
                    .map((activity) => (
                        <ListItem key={activity.timestamp} sx={{ pl: 0 }}>
                            <ListItemIcon sx={{ minWidth: 42 }}>
                                {activity.user_id ? (
                                    <Box
                                        sx={{
                                            borderRadius: 12,
                                            backgroundColor: 'divider',
                                            height: 24,
                                            width: 24,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Person fontSize="small" />
                                    </Box>
                                ) : (
                                    <img height={24} width={24} src="/portal/logo64.png" alt="hmstr-icon" />
                                )}
                            </ListItemIcon>

                            <ListItemText
                                primary={
                                    <>
                                        <FormattedMessage
                                            id={`engagement.activity.${getActivityType(activity)}`}
                                            values={{
                                                assignee: getAssigneeName(activity),
                                                user: !activity.user_id
                                                    ? 'engage_service_acc'
                                                    : activity.user_name || intl.formatMessage({ id: 'engagement.anonymous' }),
                                            }}
                                        />
                                        {activity.type === 'INTERNAL_NOTE' && <TicketNote comment={activity} />}
                                    </>
                                }
                                secondary={moment.unix(activity.timestamp).format('LLL')}
                            />
                        </ListItem>
                    ))}
            </List>
        </Box>
    )
}
