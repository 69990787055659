'use client'
import React, { useEffect, useState } from 'react'
import { initParticlesEngine, Particles } from '@tsparticles/react'
import type { Container, SingleOrMultiple } from '@tsparticles/engine'
import { loadSlim } from '@tsparticles/slim'
import { motion, useAnimation } from 'framer-motion'

type ParticlesProps = {
    id?: string
    className?: string
    background?: string
    particleSize?: number
    minSize?: number
    maxSize?: number
    opacitySpeed?: number
    maxMoveSpeed?: number
    minMoveSpeed?: number
    particleColor?: string[]
    particleAmount?: number
    borderRadius?: number | string
    minOpacity?: number
    maxOpacity?: number
    containerWidth: number
    containerHeight: number
}

export const SparklesLight = (props: ParticlesProps) => {
    const {
        id,
        className,
        background,
        minSize,
        maxSize,
        opacitySpeed,
        maxMoveSpeed,
        minMoveSpeed,
        particleColor,
        particleAmount,
        borderRadius,
        maxOpacity,
        minOpacity,
        containerWidth,
        containerHeight,
    } = props
    const [init, setInit] = useState(false)

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine)
        }).then(() => {
            setInit(true)
        })
    }, [])
    const controls = useAnimation()

    const particlesLoaded = async (container?: Container) => {
        if (container) {
            controls.start({
                opacity: 1,
                transition: {
                    duration: 1,
                },
            })
        }
    }

    return (
        <motion.div animate={controls} className={className} style={{ height: '100%', overflow: 'hidden', borderRadius: borderRadius }}>
            {init && (
                <Particles
                    id={id || 'tsparticles'}
                    className={'particlesFullHeight'}
                    particlesLoaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: background || '#0d47a1',
                            },
                        },
                        fullScreen: {
                            enable: false,
                            zIndex: 1,
                        },

                        fpsLimit: 120,
                        particles: {
                            color: {
                                value: particleColor || '#ffffff',
                                animation: {
                                    h: {
                                        count: 0,
                                        enable: false,
                                        speed: 1,
                                        decay: 0,
                                        delay: 0,
                                        sync: true,
                                        offset: 0,
                                    },
                                    s: {
                                        count: 0,
                                        enable: false,
                                        speed: 1,
                                        decay: 0,
                                        delay: 0,
                                        sync: true,
                                        offset: 0,
                                    },
                                    l: {
                                        count: 0,
                                        enable: false,
                                        speed: 1,
                                        decay: 0,
                                        delay: 0,
                                        sync: true,
                                        offset: 0,
                                    },
                                },
                            },
                            number: {
                                density: {
                                    enable: true,
                                    width: containerWidth,
                                    height: containerHeight,
                                },
                                limit: {
                                    mode: 'delete',
                                    value: 0,
                                },
                                value: particleAmount || 120,
                            },
                            opacity: {
                                value: {
                                    min: minOpacity || 0.1,
                                    max: maxOpacity || 1,
                                },
                                animation: {
                                    count: 0,
                                    enable: true,
                                    speed: opacitySpeed || 4,
                                    decay: 0,
                                    delay: 0,
                                    sync: false,
                                    mode: 'auto',
                                    startValue: 'random',
                                    destroy: 'none',
                                },
                            },
                            shape: {
                                close: true,
                                fill: true,
                                options: {},
                                type: 'circle',
                            },
                            size: {
                                value: {
                                    min: minSize || 1,
                                    max: maxSize || 3,
                                },
                                animation: {
                                    count: 0,
                                    enable: false,
                                    speed: 5,
                                    decay: 0,
                                    delay: 0,
                                    sync: false,
                                    mode: 'auto',
                                    startValue: 'random',
                                    destroy: 'none',
                                },
                            },
                            effect: {
                                close: true,
                                fill: true,
                                options: {},
                                type: {} as SingleOrMultiple<string> | undefined,
                            },
                            move: {
                                angle: {
                                    offset: 0,
                                    value: 90,
                                },
                                attract: {
                                    distance: 200,
                                    enable: false,
                                    rotate: {
                                        x: 3000,
                                        y: 3000,
                                    },
                                },
                                center: {
                                    x: 50,
                                    y: 50,
                                    mode: 'percent',
                                    radius: 0,
                                },
                                decay: 0,
                                distance: {},
                                direction: 'none',
                                drift: 0,
                                enable: true,
                                gravity: {
                                    acceleration: 9.81,
                                    enable: false,
                                    inverse: false,
                                    maxSpeed: 5,
                                },
                                path: {
                                    clamp: true,
                                    delay: {
                                        value: 0,
                                    },
                                    enable: false,
                                    options: {},
                                },
                                outModes: {
                                    default: 'out',
                                },
                                random: false,
                                size: false,
                                speed: {
                                    min: minMoveSpeed || 0.1,
                                    max: maxMoveSpeed || 1,
                                },
                                spin: {
                                    acceleration: 0,
                                    enable: false,
                                },
                                straight: false,
                                trail: {
                                    enable: false,
                                    length: 1,
                                    fill: {},
                                },
                                vibrate: false,
                                warp: false,
                            },
                        },
                        detectRetina: false,
                    }}
                />
            )}
        </motion.div>
    )
}
