import { ProjectDatasource } from '../../../settings/datasources/ProjectDatasource'
import { Post } from '../../posts/Post'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import {
    TiktokComment,
    TiktokForward,
    TiktokHeart,
    TiktokLive,
    TiktokMusic,
    TiktokNavCreate,
    TiktokNavDiscover,
    TiktokNavHome,
    TiktokNavInbox,
    TiktokNavProfile,
} from './TiktokPreviewIcons'
import { Bookmark, PlayArrowRounded, Search } from '@mui/icons-material'
import { useVideoWithThumbnail } from '../useVideoWithThumbnail'
import { FormattedMessage } from 'react-intl'
import { PostAvatar } from '../../../common/avatars/PostAvatar'
import { ReadMore } from '../ReadMore'
import { useSelector } from 'react-redux'
import { getMedia } from '../../../core/slices/DataSlice'
import { TiktokImagePreview } from './TiktokImagePreview'

type TiktokPreviewMobileProps = {
    selectedDatasource: ProjectDatasource
    post: Post
    generatePostText: (text: string, white?: boolean) => JSX.Element
    showProgressBar?: boolean
    progressRefreshRate?: number
}

export const TiktokPreviewMobile: FC<TiktokPreviewMobileProps> = ({ selectedDatasource, post, showProgressBar = true, progressRefreshRate = 150 }) => {
    const media = useSelector(getMedia)
    const isVideo = post.post_type === 'VIDEO'
    const mediaFiles = isVideo
        ? post.file_ids && post.file_ids.length > 0
            ? [media[post.file_ids[0]]]
            : undefined
        : post.file_ids && post.file_ids.length > 0
        ? post.file_ids.map((file_id) => media[file_id])
        : undefined
    const video = post.file_ids && post.file_ids.length > 0 ? media[post.file_ids[0]] : undefined
    const { objectUrl, playerRef, isCloseToPortrait } = useVideoWithThumbnail(video)
    const [playing, setPlaying] = useState(true)
    const bottomBarHeight = 60

    const [timeInterval, setTimeInterval] = useState<any>(undefined)

    const handleTimeUpdate = () => {
        if (playerRef.current && showProgressBar) {
            setPlayProgress(playerRef.current.currentTime / playerRef.current.duration)
        }
    }
    const [playProgress, setPlayProgress] = useState(0)

    const handleInitializeProgress = () => {
        if (showProgressBar) {
            setTimeInterval(setInterval(handleTimeUpdate, progressRefreshRate))
        }
    }

    useEffect(() => {
        if (playerRef.current && showProgressBar) {
            playerRef.current.addEventListener('loadedmetadata', handleInitializeProgress)
        }
        return () => {
            if (timeInterval) {
                clearInterval(timeInterval)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePause = () => {
        if (playerRef.current) {
            playerRef.current.pause()
            setPlaying(false)
            if (timeInterval && showProgressBar) {
                clearInterval(timeInterval)
            }
        }
    }
    const handlePlay = () => {
        if (playerRef.current) {
            playerRef.current.play()
            setPlaying(true)
            if (showProgressBar) {
                setTimeInterval(setInterval(handleTimeUpdate, progressRefreshRate))
            }
        }
    }

    const handleClick = () => {
        if (playerRef.current) {
            if (playerRef.current.paused) {
                handlePlay()
            } else {
                handlePause()
            }
        }
    }

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                alignItems: 'center',
                position: 'relative',
                display: 'flex',
                'progress::-webkit-progress-value': {
                    background: '#fff !important',
                },
                '.progress .seekThumb': {
                    height: '10px',
                    width: '10px',
                    background: '#fff',
                    position: 'absolute',
                    left: playProgress * 100 + '%',
                    borderRadius: '100%',
                    top: -2,
                },
            }}
        >
            <Box height={`calc(100% - ${bottomBarHeight + 15}px)`} top={'15px'} position={'absolute'}>
                {isVideo ? (
                    <video
                        ref={playerRef}
                        autoPlay={true}
                        muted={true}
                        onPlay={() => setPlaying(true)}
                        loop
                        playsInline={true}
                        controls={false}
                        width="100%"
                        height={`100%`}
                        style={{
                            objectFit: isCloseToPortrait(0.4) ? 'cover' : 'contain',
                            maxHeight: 718,
                            zIndex: 0,
                        }}
                        src={video ? objectUrl : ''}
                    >
                        <FormattedMessage id="publishing.video-not-supported" />
                    </video>
                ) : (
                    mediaFiles && <TiktokImagePreview uploadFiles={mediaFiles} />
                )}
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: '0px',
                    }}
                    onClick={handleClick}
                >
                    {!playing && <PlayArrowRounded sx={{ color: '#fffb', fontSize: '12em' }} />}
                </Box>
                {showProgressBar && (
                    <div id="video-controls" className="controls" data-state="visible" style={{ position: 'absolute', width: '100%', bottom: -3, zIndex: 2 }}>
                        <div
                            className="progress"
                            style={{
                                height: '4px',
                                position: 'relative',
                            }}
                        >
                            <progress
                                id="progress"
                                value={playProgress}
                                style={{
                                    width: '100%',
                                    height: 4,
                                    background: '#8888',
                                    position: 'absolute',
                                    top: '0px',
                                }}
                            >
                                <span id="progress-bar"></span>
                            </progress>
                            <div
                                className="seekThumb"
                                style={{
                                    height: '10px',
                                    width: '10px',
                                    background: '#fff',
                                    position: 'absolute',
                                    left: playProgress * 100 + '%',
                                    borderRadius: '100%',
                                    top: -3,
                                }}
                            ></div>
                        </div>
                    </div>
                )}

                <Box
                    position="absolute"
                    display="flex"
                    justifyContent="space-between"
                    p={1}
                    top={3 * 8 + 1}
                    pt={2}
                    width="100%"
                    sx={{
                        background: 'linear-gradient(#0003, transparent)',
                    }}
                >
                    <TiktokLive />

                    <Typography>
                        <FormattedMessage id="publishing.tiktok.following" />
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <span style={{ fontWeight: 600 }}>
                            <FormattedMessage id="publishing.tiktok.for-you" />
                        </span>
                    </Typography>
                    <Search />
                </Box>

                <Box
                    position="absolute"
                    p={1}
                    bottom={0}
                    sx={{
                        width: '100%',
                        background: 'linear-gradient(0deg, #0008, #0005, transparent)',
                    }}
                >
                    <Typography fontSize={17} fontWeight={600} maxWidth="300px">
                        {selectedDatasource.name}
                    </Typography>
                    <Box sx={{ maxHeight: '304px', overflowY: 'scroll' }}>
                        <Box maxWidth="280px">
                            <Typography>
                                <ReadMore sliceLimit={50}>{post.text || ''}</ReadMore>
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap={0.5} mt={2} mb={0.5}>
                        <TiktokMusic fontSize="small" />
                        <Typography fontSize="small">
                            <FormattedMessage id="publishing.tiktok.original-sound" /> - {selectedDatasource.name}
                        </Typography>
                    </Box>
                </Box>

                <Box position="absolute" p={1} bottom={16} right={0} display="flex" flexDirection="column" zIndex={2} alignItems="center">
                    {selectedDatasource.metadata.profile_picture_url && (
                        <Box mb={2}>
                            <PostAvatar
                                width={40}
                                height={40}
                                color="white"
                                borderWidth={1}
                                name={selectedDatasource.name}
                                src={selectedDatasource.metadata.profile_picture_url}
                            />
                        </Box>
                    )}

                    <TiktokHeart htmlColor="white" />
                    <Typography
                        sx={{
                            color: 'white',
                            fontWeight: 700,
                            fontSize: '12px',
                            mb: 1,
                        }}
                    >
                        0
                    </Typography>
                    <TiktokComment htmlColor="white" />
                    <Typography
                        sx={{
                            color: 'white',
                            fontWeight: 700,
                            fontSize: '12px',
                            mb: 1,
                        }}
                    >
                        0
                    </Typography>
                    <Bookmark htmlColor="white" />
                    <Typography
                        sx={{
                            color: 'white',
                            fontWeight: 700,
                            fontSize: '12px',
                            mb: 1,
                        }}
                    >
                        0
                    </Typography>
                    <TiktokForward htmlColor="white" />
                    <Typography
                        sx={{
                            color: 'white',
                            fontWeight: 700,
                            fontSize: '12px',
                            mb: 1,
                        }}
                    >
                        0
                    </Typography>

                    {selectedDatasource.metadata.profile_picture_url && (
                        <Box
                            mt={1}
                            sx={{
                                '@keyframes rotateVinyl': {
                                    '0%': { transform: 'rotate(0deg)' },
                                    '100%': { transform: 'rotate(360deg)' },
                                },
                                animation: 'rotateVinyl 4s infinite linear',
                                animationPlayState: playing && objectUrl ? 'playing' : 'paused',
                            }}
                        >
                            <PostAvatar
                                width={38}
                                height={38}
                                color="#777"
                                borderWidth={4}
                                name={selectedDatasource.name}
                                src={selectedDatasource.metadata.profile_picture_url}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    bottom: 0,
                    height: `${bottomBarHeight}px`,
                    background: '#000',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '43px',
                    }}
                >
                    <TiktokNavHome
                        sx={{
                            fontSize: '1.7em',
                        }}
                    />
                    <Typography
                        sx={{
                            lineHeight: '12px',
                            fontSize: '12px',
                        }}
                    >
                        Home
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '43px',
                    }}
                >
                    <TiktokNavDiscover
                        sx={{
                            fontSize: '1.7em',
                        }}
                    />
                    <Typography
                        sx={{
                            lineHeight: '12px',
                            fontSize: '12px',
                        }}
                    >
                        Discover
                    </Typography>
                </Box>
                <TiktokNavCreate
                    sx={{
                        fontSize: '4em',
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '43px',
                    }}
                >
                    <TiktokNavInbox
                        sx={{
                            fontSize: '1.7em',
                        }}
                    />
                    <Typography
                        sx={{
                            lineHeight: '12px',
                            fontSize: '12px',
                        }}
                    >
                        Inbox
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '43px',
                    }}
                >
                    <TiktokNavProfile
                        sx={{
                            fontSize: '1.7em',
                        }}
                    />
                    <Typography
                        sx={{
                            lineHeight: '12px',
                            fontSize: '12px',
                        }}
                    >
                        Profile
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
