import * as React from 'react'
import { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type ConversionTrackingEventSelectProps = {
    value: string
    onChange: (value: string) => void
    onOpen: () => void
    onClose: () => void
    open: boolean
}

export const ConversionTrackingEventSelect: FC<ConversionTrackingEventSelectProps> = ({ value, onOpen, onClose, onChange, open }) => {
    return (
        <FormControl fullWidth>
            <InputLabel variant={'outlined'} shrink={true}>
                <FormattedMessage id="conversion-tracking.tracking-event" />
            </InputLabel>
            <Select
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                variant={'outlined'}
                label={<FormattedMessage id="conversion-tracking.tracking-event" />}
                sx={{ minWidth: 150 }}
                value={value}
                onChange={(event) => onChange(event.target.value)}
            >
                <MenuItem value="offsite_conversion.fb_pixel_purchase">
                    <FormattedMessage id={'offsite_conversion.fb_pixel_purchase'} />
                </MenuItem>
                <MenuItem value="offsite_conversion.fb_pixel_lead">
                    <FormattedMessage id={'offsite_conversion.fb_pixel_lead'} />
                </MenuItem>
            </Select>
        </FormControl>
    )
}
