import * as _ from 'lodash'
import { Alert, Box, Button, Grid, List, MenuItem, TextField, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { FC } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { closeFullscreenDialog, getProjectsForSelectedAccount, getSelectedAccountAccess } from '../../core/slices/CoreSlice'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { composeValidators, validationEmail, validationRequired } from '../../form/validate'
import { User, UserAccountAccess, UserDatasourceAccess, UserProjectAccess } from './User'
import { UserFormProjectItem } from './UserFormProjectItem'
import { Project } from '../project-management/Project'
import { ConnectedDropdown } from '../../form/ConnectedDropdown'
import { ConnectedSwitch } from '../../form/ConnectedSwitch'
import { InviteProjectAccess } from './Invite'

export type UserFormValues = {
    email: string
    account_name: string
    account_id: string
    external: boolean
    language: string
    account_role: UserAccountAccess['role']
    projects: (UserProjectAccess | InviteProjectAccess)[]
}

type UserFormProps = {
    onSubmit: (values: UserFormValues) => void
    mode: 'invite' | 'edit'
    initialValues?: Partial<UserFormValues>
    user?: User
}

export const processUserFormValues = (userFormValues: UserFormValues): UserFormValues => {
    if (['ADMIN', 'OWNER'].includes(userFormValues.account_role)) {
        return {
            ...userFormValues,
            projects: userFormValues.projects.map((p) => ({
                ...p,
                access: [],
                role: 'ADMIN',
            })),
        }
    }

    const mappedProjects = userFormValues.projects
        .filter((p) => Boolean(p.role))
        .map((p) => {
            const access = p.access
                .filter((a) => Boolean(a.publishing_role) || Boolean(a.engagement_role) || Boolean(a.analytics_role))
                .map((a) => {
                    return {
                        data_source_id: a.data_source_id,
                        analytics_role: a.analytics_role || undefined,
                        publishing_role: a.publishing_role || undefined,
                        engagement_role: a.engagement_role || undefined,
                    } as UserDatasourceAccess
                })

            return { ...p, access: access }
        })

    return { ...userFormValues, projects: mappedProjects }
}

export const UserForm: FC<UserFormProps> = ({ onSubmit, initialValues, mode, user }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const projects = useSelector(getProjectsForSelectedAccount)
    const selectedAccountAccess = useSelector(getSelectedAccountAccess)
    const currentAccountRole = selectedAccountAccess?.role

    return (
        <Form<UserFormValues>
            onSubmit={onSubmit}
            mutators={{ ...arrayMutators }}
            initialValues={initialValues}
            render={(renderProps) => {
                const { handleSubmit, values, submitError, form } = renderProps
                const isExternal = values.external === true

                return (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} sx={{ minWidth: 800 }} alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    <FormattedMessage id="users.inviteViaMail" />
                                </Typography>
                            </Grid>

                            {user && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField label={<FormattedMessage id="general.firstName" />} value={user.first_name} disabled fullWidth />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField label={<FormattedMessage id="general.lastName" />} value={user.last_name} disabled fullWidth />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12}>
                                <ConnectedTextField
                                    label="general.email"
                                    name="email"
                                    maxLength={128}
                                    validate={composeValidators(validationRequired, validationEmail)}
                                    disabled={mode === 'edit'}
                                />
                            </Grid>
                            <Grid item xs={mode === 'invite' ? 7 : 10}>
                                <ConnectedDropdown
                                    name="account_role"
                                    label="accounts.role"
                                    helperText={isExternal ? 'users.external-no-admin-or-owner' : undefined}
                                >
                                    <MenuItem value="OWNER" disabled={currentAccountRole !== 'OWNER' || isExternal}>
                                        <FormattedMessage id="accounts.role.OWNER" />
                                    </MenuItem>
                                    <MenuItem value="ADMIN" disabled={isExternal}>
                                        <FormattedMessage id="accounts.role.ADMIN" />
                                    </MenuItem>
                                    <MenuItem value="USER">
                                        <FormattedMessage id="accounts.role.USER" />
                                    </MenuItem>
                                </ConnectedDropdown>
                            </Grid>
                            {mode === 'invite' && (
                                <Grid item xs={3}>
                                    <ConnectedDropdown required label={intl.formatMessage({ id: 'languages.label' })} name="language">
                                        <MenuItem value="de">
                                            <FormattedMessage id={'languages.german'} />
                                        </MenuItem>
                                        <MenuItem value="en">
                                            <FormattedMessage id={'languages.english'} />
                                        </MenuItem>
                                    </ConnectedDropdown>
                                </Grid>
                            )}
                            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                                <Box mt={2}>
                                    <ConnectedSwitch
                                        name="external"
                                        label="users.external"
                                        tooltip="users.external-hint"
                                        onChange={() => form.change('account_role', 'USER')}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    <FormattedMessage id={`accounts.role.${values.account_role}.hint`} />
                                </Alert>
                            </Grid>

                            {values.account_role === 'USER' && (
                                <>
                                    <Grid item xs={6}>
                                        <Box marginTop={2}>
                                            <Typography variant="overline">
                                                <strong>
                                                    <FormattedMessage id="accounts.projectAccess" />
                                                </strong>
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <List>
                                            {_.values(projects).map((project: Project, index) => (
                                                <UserFormProjectItem key={project.id} projectFormName={`projects[${index}]`} project={project} />
                                            ))}
                                        </List>
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        <Box pt={2} pb={2}>
                            <Grid container spacing={1}>
                                {submitError && (
                                    <Grid item xs={12}>
                                        <Alert severity="error" sx={{ mb: 2 }}>
                                            <FormattedMessage id={submitError} />
                                        </Alert>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Button color="secondary" variant="contained" type="submit" fullWidth>
                                        <FormattedMessage id={mode === 'invite' ? 'general.invite' : 'general.save'} />
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button color="inherit" variant="outlined" fullWidth onClick={() => dispatch(closeFullscreenDialog())}>
                                        <FormattedMessage id="general.close" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                )
            }}
        />
    )
}
