import * as React from 'react'
import { FC, Fragment, useState } from 'react'
import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material'
import { getSelectedDetailedTicket } from './EngagementSlice'
import { FormattedMessage, useIntl } from 'react-intl'
import { Loading } from '../common/loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { TicketNode } from './TicketNode'
import { TicketNodePaper } from './TicketNodePaper'
import { DetailedTicket } from './DetailedTicket'
import { HmstrDispatch } from '../core/Store'
import { createTicketNode } from './EngagementActions'
import { getIsMobile, showSuccessSnackbar } from '../core/slices/CoreSlice'
import * as _ from 'lodash'
import { useHasDatasourcePermission } from '../core/hooks/useHasPermission'
import { TicketNodePaperMobile } from './TicketNodePaperMobile'
import { MarkAllAsReadButton } from './MarkAllAsReadButton'

type TicketViewMessageProps = {
    datasource: ProjectDatasource
}

export const TicketViewMessage: FC<TicketViewMessageProps> = ({ datasource }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const theme = useTheme()

    const detailedTicket = useSelector(getSelectedDetailedTicket)
    const [answerText, setAnswerText] = useState('')
    const canAnswer = useHasDatasourcePermission('ticket.answer', detailedTicket?.data_source_id)
    const isMobile = useSelector(getIsMobile)

    const userName = detailedTicket?.author
        ? detailedTicket.author.name ||
          (detailedTicket.author.id === datasource.metadata.id ? datasource.name : intl.formatMessage({ id: 'engagement.anonymous' }))
        : intl.formatMessage({ id: 'engagement.anonymous' })

    const renderChatNode = (node: TicketNode, index: number) => {
        const ticket = detailedTicket as DetailedTicket

        return (
            <Fragment key={node.node_id}>
                <Grid container>
                    {node.author?.id !== ticket.target_id && <Grid item xs={4} />}
                    <Grid item xs={isMobile ? 12 : 8}>
                        {isMobile ? (
                            <TicketNodePaperMobile ticket={ticket} node={node} datasource={datasource} level={0} index={index.toString()} />
                        ) : (
                            <TicketNodePaper datasource={datasource} ticket={ticket} node={node} level={0} index={index.toString()} />
                        )}
                    </Grid>
                    {node.author?.id === ticket.target_id && <Grid item xs={4} />}
                </Grid>
            </Fragment>
        )
    }

    const handleSend = () => {
        if (detailedTicket) {
            dispatch(
                createTicketNode({
                    ticket: detailedTicket,
                    payload: {
                        data_source_id: detailedTicket.data_source_id,
                        ticket_id: detailedTicket.id,
                        target_id: detailedTicket.target_id,
                        message: answerText,
                    },
                })
            ).then(() => {
                setAnswerText('')
                dispatch(showSuccessSnackbar('engagement.answer-was-sent'))
            })
        }
    }

    return (
        <Grid item xs={isMobile ? 12 : 11} xxl={8} maxHeight="100%" display="flex" flexDirection="column" minWidth={isMobile ? '100%' : undefined}>
            <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={1} borderColor="divider">
                <Typography variant="subtitle2" color="text.secondary">
                    <FormattedMessage id="engagement.direct-messages" />
                </Typography>

                <MarkAllAsReadButton />
            </Box>

            {detailedTicket ? (
                <Box mt={2} pr={2} mr={-2} overflow="auto" display="flex" flexDirection="column-reverse" gap={2}>
                    {_.reverse([...detailedTicket.nodes]).map((node, idx) => {
                        const actualIndex = detailedTicket.nodes.length - 1 - idx
                        return renderChatNode(node, actualIndex + 1)
                    })}
                </Box>
            ) : (
                <Loading />
            )}

            <Box flexGrow={1} />

            <Box mt={2}>
                <TextField
                    disabled={!canAnswer}
                    InputLabelProps={{ variant: 'outlined', shrink: true }}
                    label={intl.formatMessage({ id: 'engagement.answer-lowercase' }, { name: userName })}
                    variant="outlined"
                    value={answerText}
                    maxRows={4}
                    onChange={(e) => setAnswerText(e.target.value)}
                    fullWidth={true}
                    multiline={true}
                    InputProps={{
                        sx: { background: theme.palette.background.paper },
                        endAdornment: (
                            <Button color="secondary" variant="contained" onClick={handleSend} disabled={!canAnswer}>
                                <FormattedMessage id="engagement.send" />
                            </Button>
                        ),
                    }}
                />
            </Box>
        </Grid>
    )
}
