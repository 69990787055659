import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Inbox } from './Inbox'
import { Archive } from './Archive'
import { TicketsAssignedToOthers } from './TicketsAssignedToOthers'
import { TicketsAssignedToMe } from './TicketsAssignedToMe'
import { alpha, Box, Tab, Tabs, useTheme } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import { primaryMobileNavHeight } from '../navigation/PrimaryNavigationMobile'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedProject, isDarkMode, setAppThemeColor } from '../core/slices/CoreSlice'
import { HmstrDispatch } from '../core/Store'
import { fetchSimpleTicketsForProject } from './EngagementActions'
import { basePath } from '../index'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'

type TicketTab = 'new' | 'mine' | 'others' | 'archived'

interface TicketViewMobileProps {
    tab: TicketTab
}

export const TicketViewMobile: FC<TicketViewMobileProps> = ({ tab }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const availableTabs: TicketTab[] = ['new', 'mine', 'others', 'archived']
    const getSelectedIndex = availableTabs.indexOf(tab)
    const selectedProject = useSelector(getSelectedProject)
    const [selectedTab, setSelectedTab] = useState(getSelectedIndex)
    const theme = useTheme()
    const darkMode = useSelector(isDarkMode)
    useEffect(() => {
        dispatch(setAppThemeColor(theme.palette.background.paper))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [darkMode])

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(
                fetchSimpleTicketsForProject({
                    tab: availableTabs[selectedTab],
                    page: 0,
                    datasource_ids: [],
                    project: selectedProject,
                    entriesPerPage: 10,
                    lazyScroll: true,
                })
            )
        }
    }, [selectedTab, selectedProject])

    useEffect(() => {
        setSelectedTab(getSelectedIndex)
    }, [tab, getSelectedIndex])
    const getFocusedElement = (tabName: TicketTab) => {
        switch (tabName) {
            case 'new':
                return <Inbox key={tabName} />
            case 'archived':
                return <Archive key={tabName} />
            case 'others':
                return <TicketsAssignedToOthers key={tabName} />
            case 'mine':
                return <TicketsAssignedToMe key={tabName} />
        }
    }

    const handleChange = (event: any, index: number) => {
        setSelectedTab(index)
    }
    const handleChangeIndex = (index: number) => {
        setSelectedTab(index)

        window.history.pushState({}, '', `${basePath}/${selectedProject?.id}/engagement/tickets/${availableTabs[index]}`)
    }

    useEffect(() => {
        const selectedTabElement = document.querySelector(`.${availableTabs[selectedTab]}`)
        if (selectedTabElement) {
            selectedTabElement.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab])

    return (
        <Box maxHeight={`calc(100% - ${primaryMobileNavHeight}px)`} display={'flex'} flexDirection={'column'} flexGrow={1} flexShrink={1}>
            <Box display={'flex'}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    sx={{
                        pb: 1,
                        boxShadow: theme.shadows[3],
                        zIndex: 2,
                        background: theme.palette.background.paper,
                        '&>div>div::before': {
                            content: "''",
                            display: 'inline-flex',
                            minWidth: '50vw',
                        },
                        '&>div>div::after': {
                            content: "'hvfqwdjbjherwjeqkefwbnerwekqlfwbnewek'",
                            display: 'inline-flex',
                            minWidth: '50vw',
                            opacity: 0,
                        },
                        '&>div>div': {
                            alignItems: 'center',
                            overflowX: 'hidden',
                            scrollSnapType: 'x mandatory',
                        },
                        '.MuiTab-textColorPrimary': {
                            color: `${theme.palette.getContrastText(theme.palette.background.paper)} !important`,
                            textTransform: 'unset',
                        },
                        '.MuiTabs-indicator': {
                            left: '50% !important',
                            transform: 'translateX(-50%)',
                            background: `linear-gradient(to right, ${alpha(theme.palette.getContrastText(theme.palette.background.paper), 0.2)}, #ff6900c0)`,
                            height: 3,
                            borderRadius: 100,
                        },
                    }}
                >
                    {availableTabs.map((tab) => {
                        return (
                            <Tab
                                key={tab}
                                className={tab}
                                label={<FormattedMessage id={`engagement.tabnames.${tab}`} />}
                                disableRipple
                                sx={{
                                    opacity: tab === availableTabs[selectedTab] ? 1 : 0.5,
                                    transform: tab === availableTabs[selectedTab] ? 'scale(1)' : 'scale(0.7)',
                                    transition: 'all 0.3s',
                                    mx: 1,
                                    scrollSnapAlign: tab === availableTabs[selectedTab] ? 'center' : 'center',
                                }}
                            />
                        )
                    })}
                </Tabs>
            </Box>
            <Box
                flexShrink={1}
                flexGrow={1}
                display={'flex'}
                flexDirection={'column'}
                overflow={'scroll'}
                maxHeight={'100%'}
                sx={{
                    '&>div': {
                        maxHeight: '100%',
                        minHeight: '100%',
                    },
                    '&>div>div': {
                        maxHeight: '100%',
                        minHeight: '100%',
                    },
                    '&>div>div>div': {
                        maxHeight: '100%',
                        minHeight: '100%',
                    },
                    '&>div>div>div>div': {
                        maxHeight: '100%',
                        minHeight: '100%',
                        overflowY: 'scroll',
                        display: 'flex',
                        overscrollBehavior: 'none !important',
                    },
                }}
            >
                <SwipeableViews index={selectedTab} onChangeIndex={handleChangeIndex} className={'ticketList'}>
                    {availableTabs.map((tab) => {
                        return (
                            <Box p={1} key={`swipeable${tab}`}>
                                {getFocusedElement(tab)}
                            </Box>
                        )
                    })}
                </SwipeableViews>
            </Box>
        </Box>
    )
}
