import * as React from 'react'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { getAiResults } from './PostTextGenerationSlice'
import _ from 'lodash'
import { PostTextSuggestion } from './PostTextSuggestion'
import { Box } from '@mui/material'

type PostTextSuggestionListProps = {
    handleSelect: (val: string) => void
}

export const PostTextSuggestionList: FC<PostTextSuggestionListProps> = ({ handleSelect }) => {
    const results = useSelector(getAiResults)
    return (
        <Box display={'flex'} gap={4} flexDirection={'column'}>
            <Box />
            {_.map(results, (res, idx) => {
                return <PostTextSuggestion key={idx} result={res} index={idx + 1} handleSelect={handleSelect} />
            })}
            <Box />
        </Box>
    )
}
