import * as React from 'react'
import { FC } from 'react'
import { Box, Button, CircularProgress, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useForm, useFormState } from 'react-final-form'
import { Save } from '@mui/icons-material'
import { NotificationSettingsTabType } from './NotificationSettingsTab'

type SettingsSubmitButtonProps = {
    tab: NotificationSettingsTabType
}

export const SettingsSubmitButton: FC<SettingsSubmitButtonProps> = ({ tab }) => {
    const theme = useTheme()
    const form = useForm()
    const formState = useFormState()

    return (
        <Box
            sx={{
                position: 'sticky',
                bottom: 0,
                right: 2,
                width: '100%',
                display: 'flex',
                mt: 2,
                justifyContent: 'flex-end',
                boxShadow: `0 10px 20px 40px ${theme.palette.background.default}`,
                background: theme.palette.background.default,
            }}
        >
            <Button
                disabled={formState.submitting || formState.pristine}
                onClick={() => form.submit()}
                variant={'contained'}
                color={'secondary'}
                startIcon={formState.submitting ? <CircularProgress color="inherit" size={20} /> : <Save />}
            >
                <FormattedMessage id={`notifications.settings.save-${tab}`} />
            </Button>
        </Box>
    )
}
