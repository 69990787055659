import * as React from 'react'
import { FC, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Box, useTheme } from '@mui/material'
import { AutoAwesome, AutoFixHigh, Check } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'

type PostTextSuggestionActionsProps = {
    hovered: boolean
    index: number
    handleSelect: () => void
    handleRefine: () => void
    accentColor: string
}

export const PostTextSuggestionActions: FC<PostTextSuggestionActionsProps> = ({ hovered, index, handleSelect, handleRefine, accentColor }) => {
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)
    const [hoveredAccept, setHoveredAccept] = useState(false)
    const [hoveredRefine, setHoveredRefine] = useState(false)
    const refineBackground = darkmode ? theme.palette.info.dark : theme.palette.info.light
    const acceptBackground = darkmode ? theme.palette.success.dark : theme.palette.success.light
    return (
        <AnimatePresence>
            <motion.div
                key={'resultIndex'}
                className={'resultIndex'}
                style={{
                    position: 'absolute',
                    bottom: '-16px',
                    right: '16px',
                }}
                layout
                transition={{ duration: 0.2 }}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    sx={
                        hovered
                            ? {
                                  height: '32px',
                                  lineHeight: '32px',
                                  border: `1px solid ${accentColor}`,
                                  borderRadius: theme.shape.borderRadius,
                                  transition: 'color 0.1s, background 0.1s, border 0.1s',
                                  color: 'inherit',
                                  overflow: 'hidden',
                                  background: theme.palette.background.paper,
                                  cursor: 'pointer',
                              }
                            : {
                                  height: '32px',
                                  lineHeight: '32px',
                                  transition: 'border 0.3s',
                                  border: `1px solid ${theme.palette.action.disabled}`,
                                  background: theme.palette.background.paper,
                                  borderRadius: theme.shape.borderRadius,
                                  overflow: 'hidden',
                              }
                    }
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: hovered ? 0 : '-32px',
                            maxWidth: hovered ? 'auto' : '10px',
                            opacity: hovered ? 1 : 0,
                            transition: 'all 0.2s, color 0s, opacity 0.1s',
                        }}
                    >
                        <AnimatePresence>
                            <Box
                                key={'aiAcceptButton'}
                                className={'aiAcceptButton'}
                                sx={{
                                    position: 'relative',
                                    px: 2,
                                    transition: 'color 0.3s',
                                    '&:hover': {
                                        color: 'white',
                                    },
                                }}
                                onMouseEnter={() => setHoveredAccept(true)}
                                onMouseLeave={() => setHoveredAccept(false)}
                                onClick={handleSelect}
                            >
                                {hoveredAccept && (
                                    <motion.div
                                        key={'hoveraccept'}
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            height: '100%',
                                            width: '100%',
                                            background: acceptBackground,
                                            zIndex: 0,
                                            borderRadius: '100px',
                                        }}
                                        layoutId={'hoveredEntry'}
                                    />
                                )}
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        zIndex: 2,
                                        color: 'inherit',
                                    }}
                                >
                                    <Check className={'checkIcon'} fontSize={'small'} />
                                    <FormattedMessage id={'ai.accept'} />
                                </Box>
                            </Box>
                            <Box
                                key={'aiRefineButton'}
                                className={'aiRefineButton'}
                                onMouseEnter={() => setHoveredRefine(true)}
                                onMouseLeave={() => setHoveredRefine(false)}
                                sx={{
                                    transition: 'color 0.3s',
                                    position: 'relative',
                                    px: 2,
                                    '&:hover': {
                                        color: 'white',
                                    },
                                }}
                                onClick={handleRefine}
                            >
                                {hoveredRefine && (
                                    <motion.div
                                        key={'hoverrefine'}
                                        style={{
                                            position: 'absolute',
                                            height: '100%',
                                            left: 0,
                                            top: 0,
                                            width: '100%',
                                            background: refineBackground,
                                            zIndex: 0,
                                            borderRadius: '100px',
                                        }}
                                        layoutId={'hoveredEntry'}
                                    />
                                )}
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        zIndex: 2,
                                    }}
                                >
                                    <AutoFixHigh className={'checkIcon'} fontSize={'small'} />
                                    <FormattedMessage id={'ai.refine'} />
                                </Box>
                            </Box>
                        </AnimatePresence>
                    </Box>
                    <Box
                        sx={{
                            opacity: hovered ? 0 : 1,
                            transition: 'all 0.2s, color 0s, opacity 0.1s',
                            display: 'flex',
                            alignItems: 'center',
                            px: 2,
                            gap: 1,
                        }}
                    >
                        <AutoAwesome
                            color={'info'}
                            sx={{
                                fontSize: '16px',
                            }}
                        />
                        <FormattedMessage id={'ai.result'} />
                        &nbsp;{index.toString()}
                    </Box>
                </Box>
            </motion.div>
        </AnimatePresence>
    )
}
