import * as _ from 'lodash'
import moment from 'moment'
import { FC } from 'react'
import { Grid } from '@mui/material'
import { CalendarDay } from './CalendarDay'
import { getSelectedCalendarTimePeriod } from '../PublishingSlice'
import { useSelector } from 'react-redux'

type CalendarWeekProps = {
    week: number
    calendarWeek: any
    selectedMonth: number
    countOfWeeks: number
}

export const CalendarWeek: FC<CalendarWeekProps> = ({ week, calendarWeek, selectedMonth, countOfWeeks }) => {
    const calendarView = useSelector(getSelectedCalendarTimePeriod)
    const getMaxPositionForWeek = () => {
        const tmp = _.chain(calendarWeek).values().flatten().maxBy('position').value()
        return tmp ? tmp.position : 6
    }

    const getBiggestDay = () => {
        let count = 0
        _.forEach(calendarWeek, (day) => {
            if (_.size(day) > count) count = _.size(day)
        })
        return count
    }

    return (
        <Grid
            id="week"
            container
            sx={{
                display: calendarView === 'week' ? 'flex' : 'table',
                minHeight: 130,
                tableLayout: 'fixed',
                flexGrow: 1,
            }}
        >
            {_.keys(calendarWeek).map((dayString, index) => {
                return (
                    <CalendarDay
                        key={dayString}
                        inSelectedMonth={calendarView === 'week' ? true : moment(dayString).month() === selectedMonth}
                        day={moment(dayString)}
                        maxPosition={getMaxPositionForWeek()}
                        isFirstDay={index === 0}
                        week={week}
                        biggestDayInWeek={getBiggestDay()}
                        countOfWeeks={countOfWeeks}
                    />
                )
            })}
        </Grid>
    )
}
