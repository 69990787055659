import * as React from 'react'
import { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { PostGroup } from '../post-groups/PostGroup'
import { HmstrDispatch } from '../../core/Store'
import { fetchPostGroupActivities, requestPostGroupApproval } from '../post-groups/PostGroupActions'
import { getIsMobile, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { FormattedMessage } from 'react-intl'
import { AssigneeMultipleDropdown } from '../../common/assignee-multiple-dropdown/AssigneeMultipleDropdown'

type RequestApprovalDialogProps = {
    postGroup: PostGroup
    external: boolean
    open: boolean
    onClose: () => any
}

export const RequestApprovalDialog: FC<RequestApprovalDialogProps> = ({ postGroup, open, onClose, external }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const isMobile = useSelector(getIsMobile)

    const handleRequestApproval = (values: any) => {
        dispatch(
            requestPostGroupApproval({
                postGroup,
                request: {
                    external: Boolean(external),
                    message: values.message,
                    assignee_ids: values.assignee_ids,
                    type: 'USER',
                },
            })
        ).then(() => {
            dispatch(showSuccessSnackbar('publishing.approval.requested'))
            dispatch(fetchPostGroupActivities(postGroup))
            onClose()
        })
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <DialogTitle sx={{ pb: 0 }}>
                <FormattedMessage id="publishing.request-approval" />
            </DialogTitle>
            <Form
                onSubmit={handleRequestApproval}
                initialValues={{ assignee_ids: [] }}
                render={({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <Grid container spacing={1} minWidth={isMobile ? '70vw' : 600 - 48}>
                                    <Grid item xs={12}>
                                        <Field name="assignee_ids">
                                            {({ input }) => {
                                                return (
                                                    <AssigneeMultipleDropdown
                                                        assigneeIds={input.value}
                                                        onChange={input.onChange}
                                                        mode={external ? 'EXTERNAL' : 'INTERNAL'}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ConnectedTextField
                                            name="message"
                                            disableMaxLength={true}
                                            label="general.message-optional"
                                            multiline={true}
                                            minRows={5}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={onClose}>
                                    <FormattedMessage id="general.cancel" />
                                </Button>
                                <Button variant="contained" color="secondary" type="submit">
                                    <FormattedMessage id="publishing.request-approval" />
                                </Button>
                            </DialogActions>
                        </form>
                    )
                }}
            />
        </Dialog>
    )
}
