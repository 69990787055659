import * as React from 'react'
import { FC } from 'react'
import { alpha, Box, Typography, useTheme } from '@mui/material'
import { HideImageOutlined } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'

type NoImageAvailablePlaceholderProps = {}

export const NoImageAvailablePlaceholder: FC<NoImageAvailablePlaceholderProps> = () => {
    const darkmode = useSelector(isDarkMode)
    const theme = useTheme()
    const errorBackground = darkmode ? theme.palette.error.dark : theme.palette.error.light
    return (
        <Box
            sx={{
                width: '100%',
                aspectRatio: '1.91 / 1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                background: alpha(errorBackground, 0.1),
            }}
        >
            <HideImageOutlined
                sx={{
                    color: errorBackground,
                    fontSize: '100px',
                }}
            />
            <Typography
                sx={{
                    color: errorBackground,
                    fontSize: '20px',
                }}
            >
                <FormattedMessage id={'publishing.link-preview.no-image'} />
            </Typography>
        </Box>
    )
}
