import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Collapse, darken, Grid, keyframes, lighten, Typography, useTheme } from '@mui/material'
import { PostAvatar } from '../common/avatars/PostAvatar'
import moment from 'moment/moment'
import { Delete, VisibilityOff } from '@mui/icons-material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dot } from '../common/dot/Dot'
import { TicketAnswerForm } from './TicketAnswerForm'
import { TicketNode } from './TicketNode'
import { ConfirmDialog } from '../common/confirm-dialog/ConfirmDialog'
import { fetchDetailedTicket, findOrCreateMissingTicket, updateComment } from './EngagementActions'
import { getIsMobile, getSelectedProject, isDarkMode, showSuccessSnackbar } from '../core/slices/CoreSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { DetailedTicket } from './DetailedTicket'
import {
    closeAnswerDialogForStoryReply,
    getAnswerDialogForStoryReplyAuthorId,
    getHighlightedNodeIndex,
    getOnlyShowUnreadComments,
    getPrivateConversationForAuthor,
    openAnswerDialogForStoryReply,
    openShowCloseTicketDialog,
    selectTicketId,
} from './EngagementSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { TicketAttachment } from './TicketAttachment'
import { TicketNodeStoryPreview } from './TicketNodeStoryPreview'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { SimpleTicket } from './SimpleTicket'
import { useHasDatasourcePermission } from '../core/hooks/useHasPermission'
import { TicketNodeActionsMobile } from './TicketNodeActionsMobile'
import { useLongPress } from 'use-long-press'

type TicketNodePaperMobileProps = {
    ticket: DetailedTicket
    node: TicketNode
    datasource: ProjectDatasource
    level: number
    index?: string
    firstElement?: boolean
    lastElement?: boolean
    hasChildren?: boolean
}

export const TicketNodePaperMobile: FC<TicketNodePaperMobileProps> = ({ ticket, node, datasource, level, index, firstElement, lastElement, hasChildren }) => {
    const theme = useTheme()
    const intl = useIntl()
    const navigate = useProjectNavigate()
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedProject = useSelector(getSelectedProject)
    const darkmode = useSelector(isDarkMode)
    const showOnlyUnread = useSelector(getOnlyShowUnreadComments)
    const isMobile = useSelector(getIsMobile)

    const removed = ['REMOVED_BY_USER', 'REMOVED_BY_ADMIN'].includes(node.state)
    const hidden = node.state === 'HIDDEN'
    const isOwnComment = node.author?.id === datasource.metadata.id
    const nodeAuthor = node.author?.name || (isOwnComment ? datasource.name : intl.formatMessage({ id: 'engagement.anonymous' }))
    const userName = node.author ? nodeAuthor : intl.formatMessage({ id: 'engagement.anonymous' })
    const isPost = ticket.type === 'POST'
    const isStory = ticket.type === 'STORY'

    const [open, setOpen] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isHiding, setIsHiding] = useState(false)
    const [isLiking, setIsLiking] = useState(false)
    const privateConverstionForCurrentNode = useSelector(getPrivateConversationForAuthor)
    const nodeIdForStoryReplyDialog = useSelector(getAnswerDialogForStoryReplyAuthorId)
    const answerStoryDialogOpen = nodeIdForStoryReplyDialog !== undefined

    const canMarkAsRead = useHasDatasourcePermission('ticket.mark_as_read', ticket.data_source_id)
    const canAnswerTicket = useHasDatasourcePermission('ticket.answer', ticket.data_source_id)
    const canHideTicket = useHasDatasourcePermission('ticket.hide', ticket.data_source_id)
    const canDeleteTicket = useHasDatasourcePermission('ticket.delete', ticket.data_source_id)
    const canLikeTicket = useHasDatasourcePermission('ticket.like', ticket.data_source_id)
    const highlightedNodeIndex = useSelector(getHighlightedNodeIndex)
    const isHighlighted = highlightedNodeIndex === index

    const nodeRef = useRef<any>(null)
    const [longPressStarted, setLongPressStarted] = useState(false)
    const [longPressFinished, setLongPressFinished] = useState(false)
    const [shouldOpenContext, setShouldOpenContext] = useState(false)

    const bindLongPress = useLongPress(
        () => {
            setShouldOpenContext(true)
            setLongPressStarted(false)
            setTimeout(() => {
                setShouldOpenContext(false)
            }, 100)
            setLongPressFinished(true)
            setTimeout(() => {
                setLongPressFinished(false)
            }, 500)
        },
        {
            threshold: 500,
            onStart: () => {
                setLongPressStarted(true)
            },
            onCancel: () => {
                setLongPressStarted(false)
            },
            onFinish: () => {
                setLongPressStarted(false)
            },
        }
    )

    useEffect(() => {
        if (nodeRef.current && isHighlighted) {
            nodeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [isHighlighted])

    const highlightAnimation = keyframes`
      0% {
        transform: scale(1);
      }
      40% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.98) translateX(4px);
        box-shadow: 0 0 2px ${theme.palette.secondary.main};
      }
      60% {
        transform: scale(0.98) translateX(4px);
      }
      95% {
      }
      100% {
        box-shadow: 0 0 50px ${theme.palette.secondary.main}00;
        transform: scale(1);
      }`

    const highlightAnimationLongPress = keyframes`
      0% {
        box-shadow: 0 0 2px ${theme.palette.secondary.main};
      }
      100% {
        box-shadow: 0 0 20px ${theme.palette.secondary.main}00;
      }`

    const handleClose = () => {
        setOpen(false)
        dispatch(fetchDetailedTicket(ticket)).then((action) => {
            if ((action.payload as DetailedTicket).unread_node_count === 0 && ticket.state === 'OPEN') {
                dispatch(openShowCloseTicketDialog())
            }
        })
    }

    const handleStateChange = (state: 'MARK_AS_READ' | 'MARK_AS_UNREAD') => {
        dispatch(
            updateComment({
                node,
                request: {
                    target_id: ticket.target_id,
                    node_id: node.node_id,
                    ticket_id: ticket.id,
                    type: state,
                    message: node.message,
                    data_source_id: ticket.data_source_id,
                },
            })
        ).then(() => {
            dispatch(fetchDetailedTicket(ticket)).then((action) => {
                if ((action.payload as DetailedTicket).unread_node_count === 0 && ticket.state === 'OPEN') {
                    dispatch(openShowCloseTicketDialog())
                }
            })
        })
    }

    const handleDelete = () => {
        if (showConfirmDelete) {
            setIsDeleting(true)
            dispatch(
                updateComment({
                    node,
                    request: {
                        target_id: ticket.target_id,
                        node_id: node.node_id,
                        ticket_id: ticket.id,
                        type: 'REMOVE',
                        message: node.message,
                        data_source_id: ticket.data_source_id,
                    },
                })
            ).then(() => {
                setShowConfirmDelete(false)
                dispatch(fetchDetailedTicket(ticket)).then((action) => {
                    if ((action.payload as DetailedTicket).unread_node_count === 0 && ticket.state === 'OPEN') {
                        dispatch(openShowCloseTicketDialog())
                    }
                })
                setIsDeleting(false)
                showSuccessSnackbar('engagement.delete-success')
            })
        } else {
            setShowConfirmDelete(true)
        }
    }

    const handleHiddenChange = (action: 'HIDE' | 'SHOW') => {
        setIsHiding(true)
        dispatch(
            updateComment({
                node,
                request: {
                    target_id: ticket.target_id,
                    node_id: node.node_id,
                    ticket_id: ticket.id,
                    type: action,
                    message: node.message,
                    data_source_id: ticket.data_source_id,
                },
            })
        ).then(() => {
            dispatch(fetchDetailedTicket(ticket)).then((action) => {
                if ((action.payload as DetailedTicket).unread_node_count === 0 && ticket.state === 'OPEN') {
                    dispatch(openShowCloseTicketDialog())
                }
            })
            setIsHiding(false)
            showSuccessSnackbar('engagement.hide-success')
        })
    }

    const handleLikeChange = (action: 'LIKE' | 'UNLIKE') => {
        setIsLiking(true)
        dispatch(
            updateComment({
                node,
                request: {
                    target_id: ticket.target_id,
                    node_id: node.node_id,
                    ticket_id: ticket.id,
                    type: action,
                    message: node.message,
                    data_source_id: ticket.data_source_id,
                },
            })
        ).then(() => {
            dispatch(fetchDetailedTicket(ticket)).then((action) => {
                if ((action.payload as DetailedTicket).unread_node_count === 0 && ticket.state === 'OPEN') {
                    dispatch(openShowCloseTicketDialog())
                }
            })
            setIsLiking(false)
        })
    }

    const openComment = () => {
        if (datasource.type === 'FACEBOOK_PAGE') {
            const comment_id = node.node_id.split('_')[1]
            window.open(`https://www.facebook.com/${ticket.target_id}?comment_id=${comment_id}`, '_blank')
        } else if (datasource.type === 'INSTAGRAM_ACCOUNT') {
            window.open(ticket.metadata.permalink + `c/${node.node_id}`, '_blank')
        } else if (datasource.type === 'LINKED_IN') {
            const post_id = node.node_id.split('(')[1].split(',')[0]
            window.open(`https://www.linkedin.com/feed/update/${post_id}?commentUrn=${node.node_id}`, '_blank')
        }
    }

    const handleAnswerClick = () => {
        if (ticket.type === 'STORY' && node.author?.id) {
            dispatch(openAnswerDialogForStoryReply(node.author.id))
        } else if (ticket.type === 'POST') {
            setOpen(!open)
        }
    }

    const handleOpenConversation = () => {
        if (answerStoryDialogOpen && node.author && selectedProject) {
            if (privateConverstionForCurrentNode) {
                dispatch(selectTicketId(privateConverstionForCurrentNode.id))
                navigate(`/engagement/${ticket.state === 'OPEN' ? 'inbox' : 'archive'}/${privateConverstionForCurrentNode.id}`)
            } else {
                dispatch(
                    findOrCreateMissingTicket({
                        project: selectedProject,
                        request: {
                            data_source_id: ticket.data_source_id,
                            target_id: node.author.id,
                            author: node.author,
                            node_id: node.node_id,
                            message: node.message,
                            is_liked: node.is_liked,
                            timestamp: node.created_at,
                            story: node.story,
                        },
                    })
                ).then((action: any) => {
                    const simpleTicket: SimpleTicket = action.payload

                    dispatch(selectTicketId(simpleTicket.id))
                    navigate(`/engagement/${simpleTicket.state === 'OPEN' ? 'inbox' : 'archive'}/${simpleTicket.id}`)
                })
            }
        }

        dispatch(closeAnswerDialogForStoryReply())
    }

    return (
        <Grid item xs={12}>
            <Box ref={nodeRef} pl={level * 3}>
                <Box display={'flex'}>
                    <Box
                        pt={1}
                        pr={0.5}
                        sx={{
                            position: 'relative',
                            '&:before': {
                                content: "''",
                                position: 'absolute',
                                display: firstElement && !showOnlyUnread ? 'block' : 'none',
                                height: 8 * 3,
                                width: (8 * 3) / 2,
                                top: 0,
                                left: -((8 * 3) / 2) + 1,
                                borderBottomLeftRadius: 100,
                                borderLeft: `1px solid ${theme.palette.primary.light}`,
                                borderBottom: `1px solid ${theme.palette.primary.light}`,
                            },
                            '&:after': {
                                content: "''",
                                position: 'absolute',
                                display: (hasChildren || (level > 0 && !lastElement)) && !showOnlyUnread ? 'block' : 'none',
                                height: 'calc(100%)',
                                width: (8 * 3) / 2,
                                top: 8 * 3,
                                left: (8 * 3) / 2 + 1,
                                borderLeft: `1px solid ${theme.palette.primary.light}`,
                                zIndex: -1,
                            },
                        }}
                    >
                        <PostAvatar height={24} width={24} name={userName} src={node.author?.profile_pic || ''} />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} className={'skewElem'} width={'100%'}>
                        <Box
                            {...bindLongPress()}
                            sx={{
                                userSelect: 'none',
                                msTouchSelect: 'none',
                                backgroundColor: hidden
                                    ? 'action.hover'
                                    : darkmode
                                    ? lighten(theme.palette.background.paper, 0.2)
                                    : darken(theme.palette.background.paper, 0.1),
                                borderRadius: 5,
                                p: 1,
                                px: 2,
                                width: '100%',
                                animation: isHighlighted
                                    ? `${highlightAnimation} 1s infinite`
                                    : longPressFinished
                                    ? `${highlightAnimationLongPress} 0.3s 1`
                                    : undefined,
                                transform: longPressStarted ? 'scale(0.95)' : 'scale(1)',
                                transition: 'transform 0.3s',
                            }}
                        >
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center" gap={1} sx={{ opacity: hidden ? 1 : 1 }}>
                                    <Typography variant="subtitle2" sx={{ textDecoration: removed ? 'line-through' : undefined }}>
                                        {userName}
                                    </Typography>
                                    {removed && (
                                        <Box display="flex" alignItems="center">
                                            <Delete fontSize="small" color="error" />
                                            {!isMobile && (
                                                <Typography variant="subtitle2" color="error">
                                                    &nbsp;
                                                    <FormattedMessage id={`engagement.${node.state}`} />
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                    {hidden && (
                                        <Box display="flex" alignItems="center">
                                            <VisibilityOff fontSize="small" color="disabled" />
                                        </Box>
                                    )}
                                </Box>
                                <Box mr={1} display={'flex'} alignItems={'center'} gap={1}>
                                    {!node.is_read && <Dot color={theme.palette.secondary.main} size={9} />}
                                    <Typography sx={{ color: theme.palette.text.disabled }}>#{index}</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ opacity: hidden ? 0.5 : 1 }}>
                                {ticket.type === 'MESSAGE' && node.story && (
                                    <Box>
                                        <Box position="relative" display="flex" alignItems="center" mb={0.5}>
                                            <Box
                                                sx={{
                                                    backgroundColor: 'grey.A400',
                                                    width: '4px',
                                                    height: '16px',
                                                    borderRadius: 0.5,
                                                }}
                                            />
                                            <Typography
                                                component="span"
                                                sx={{
                                                    pl: 0.5,
                                                    color: 'text.secondary',
                                                    fontSize: 12,
                                                }}
                                            >
                                                <FormattedMessage id={`engagement.story.${node.story.type}`} />
                                            </Typography>
                                        </Box>

                                        <TicketNodeStoryPreview story={node.story} />
                                    </Box>
                                )}

                                <Typography
                                    sx={{
                                        textDecoration: removed ? 'line-through' : undefined,
                                        overflow: 'hidden',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {node.message}
                                </Typography>
                                <TicketAttachment
                                    isMobile
                                    ticket={ticket}
                                    node={node}
                                    datasource={datasource}
                                    openCommentFunction={!removed && ticket.type === 'POST' ? openComment : undefined}
                                />
                            </Box>

                            <Collapse in={open}>
                                {open ? <TicketAnswerForm ticket={ticket} node={node} userName={userName} onClose={handleClose} /> : <Box height={100}></Box>}
                            </Collapse>
                        </Box>
                        <Box pl={1}>
                            <TicketNodeActionsMobile
                                canMarkAsRead={canMarkAsRead}
                                node={node}
                                handleStateChange={handleStateChange}
                                isPost={isPost}
                                isStory={isStory}
                                datasource={datasource}
                                level={level}
                                removed={removed}
                                canAnswerTicket={canAnswerTicket}
                                handleAnswerClick={handleAnswerClick}
                                canDeleteTicket={canDeleteTicket}
                                handleDelete={handleDelete}
                                isHiding={isHiding}
                                isOwnComment={isOwnComment}
                                canHideTicket={canHideTicket}
                                hidden={hidden}
                                handleHiddenChange={handleHiddenChange}
                                isLiking={isLiking}
                                canLikeTicket={canLikeTicket}
                                handleLikeChange={handleLikeChange}
                                overwriteOpen={shouldOpenContext}
                                passedIndex={index}
                                time={
                                    <Typography
                                        variant="subtitle2"
                                        color="text.secondary"
                                        whiteSpace={'nowrap'}
                                        textOverflow={'ellipsis'}
                                        overflow={'hidden'}
                                        sx={
                                            !removed && isPost
                                                ? {
                                                      cursor: 'pointer',
                                                      textDecoration: 'underline',
                                                  }
                                                : undefined
                                        }
                                        onClick={() => {
                                            !removed && isPost && openComment()
                                        }}
                                    >
                                        {moment(node.created_at).fromNow(true)}
                                    </Typography>
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <ConfirmDialog
                open={showConfirmDelete}
                onClose={() => setShowConfirmDelete(false)}
                onConfirm={handleDelete}
                confirmText="general.delete"
                confirmIcon={isDeleting ? <CircularProgress color="inherit" size={18} /> : <Delete />}
                confirmButtonDisabled={isDeleting}
            >
                <FormattedMessage id="engagement.delete-confirm" />
            </ConfirmDialog>

            <ConfirmDialog
                open={answerStoryDialogOpen}
                onClose={() => dispatch(closeAnswerDialogForStoryReply())}
                onConfirm={handleOpenConversation}
                confirmText="engagement.open-private-chat"
            >
                <FormattedMessage id="engagement.open-private-chat-question" />
            </ConfirmDialog>
        </Grid>
    )
}
