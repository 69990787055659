import * as React from 'react'
import { FC } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { BugFixing } from '../common/microinteractions/BugFixing'
import { Refresh } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

type ErrorComponentProps = {}

export const ErrorComponent: FC<ErrorComponentProps> = () => {
    const theme = useTheme()
    const handleRefreshClick = () => {
        window.location.reload()
    }

    return (
        <Box
            margin={'0 auto'}
            maxWidth={'100%'}
            px={2}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={4}
        >
            <Box width={'100%'} maxWidth={'min(600px, 80vw)'} display={'flex'} alignItems={'center'} justifyContent={'center'} overflow={'hidden'}>
                <BugFixing />
            </Box>
            <Typography variant={'h5'} textAlign={'center'}>
                <FormattedMessage id={'general.error-title'} />
            </Typography>
            <Typography variant={'subtitle1'} color={theme.palette.action.disabled} textAlign={'center'}>
                <FormattedMessage id={'general.error-text'} />
            </Typography>
            <Button onClick={handleRefreshClick} color="secondary" variant="contained" startIcon={<Refresh />}>
                <FormattedMessage id={'general.refresh'} />
            </Button>
        </Box>
    )
}
