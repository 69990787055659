import * as React from 'react'
import { FC } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getAccounts } from '../../core/slices/DataSlice'
import { getCurrentUser, getSelectedAccount, getSelectedAccountId, selectProject } from '../../core/slices/CoreSlice'
import { Account } from '../../settings/accounts/Account'
import moment from 'moment'
import { AccessAlarm, Lock, Speed, Warning } from '@mui/icons-material'
import * as _ from 'lodash'

type AccountSwitcherProps = {}

export const AccountSwitcher: FC<AccountSwitcherProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const currentUser = useSelector(getCurrentUser)
    const accounts = useSelector(getAccounts)
    const selectedAccountId = useSelector(getSelectedAccountId)
    const selectedAccount = useSelector(getSelectedAccount)
    const projectsByAccount = _.chain(currentUser?.accounts || [])
        .mapKeys('account_id')
        .mapValues((acc) => acc.projects)
        .value()

    const handleAccountSelect = (accountId: string) => {
        const projectsForAccount = projectsByAccount[accountId]

        if (projectsForAccount && projectsForAccount.length > 0) {
            dispatch(selectProject(projectsForAccount[0].project_id))
        }
    }

    const renderSecondaryActionIfNecessary = (account: Account) => {
        const daysRemaining = moment(account.trial_end).diff(moment().subtract(1, 'day'), 'days')
        let icon

        switch (account.status) {
            case 'SETUP':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.setup" />}>
                        <Warning fontSize="small" color="warning" />
                    </Tooltip>
                )
                break
            case 'LOCKED':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.locked" />}>
                        <Lock sx={{ cursor: 'pointer' }} fontSize="small" color="error" />
                    </Tooltip>
                )
                break
            case 'TRIAL_EXPIRED':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.trial-expired" />}>
                        <Lock fontSize="small" color="error" />
                    </Tooltip>
                )
                break
            case 'TRIALING':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.trial" values={{ days: daysRemaining }} />}>
                        <AccessAlarm fontSize="small" color="info" />
                    </Tooltip>
                )
                break
            case 'OVER_CAPACITY':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.over-capacity" />}>
                        <Speed fontSize="small" color="warning" />
                    </Tooltip>
                )
                break
            case 'PAYMENT_REQUIRED':
            case 'PAYMENT_IN_PROCESS':
                icon = (
                    <Tooltip title={<FormattedMessage id="hint.account.pending-payment" />}>
                        <Warning fontSize="small" color="warning" />
                    </Tooltip>
                )
                break
            case 'ACTIVE':
            default:
                return null
        }

        return (
            <Box display="flex" mr={1}>
                {icon}
            </Box>
        )
    }

    return (
        <FormControl fullWidth>
            <InputLabel variant="outlined" id="account-select-label">
                <FormattedMessage id="general.account-selector-label" />
            </InputLabel>
            <Select
                fullWidth
                // startAdornment={selectedAccount && renderSecondaryActionIfNecessary(selectedAccount)}
                label={intl.formatMessage({ id: 'general.account-selector-label' })}
                labelId="account-select-label"
                variant="outlined"
                size="small"
                value={selectedAccountId}
                renderValue={() => (selectedAccount ? selectedAccount.name : '')}
            >
                {Object.values(accounts).map((acc) => (
                    <MenuItem key={acc.id} value={acc.id} onClick={() => handleAccountSelect(acc.id)}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            {acc.name}
                            {renderSecondaryActionIfNecessary(acc)}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
