import * as React from 'react'
import { FC, useRef } from 'react'
import { FilterDrawer, FilterSetting } from '../common/filterbar/FilterDrawer'
import moment from 'moment/moment'
import {
    changeAttributionWindows,
    changeConversionEvent,
    changeSearchTerm,
    changeTimePeriod,
    getConversionTrackingAdsets,
    getConversionTrackingAttributionWindows,
    getConversionTrackingCampaigns,
    getConversionTrackingDatasource,
    getConversionTrackingEndDate,
    getConversionTrackingEvent,
    getConversionTrackingSearchTerm,
    getConversionTrackingSelectedAdsetIds,
    getConversionTrackingSelectedCampaignIds,
    getConversionTrackingStartDate,
    getFacebookAdAccountsWithConversionTracking,
    selectAdsetIds,
    selectCampaignIds,
    selectDatasource,
} from './ConversionTrackingSlice'
import { DatasourceFilter } from '../common/filter/DatasourceFilter'
import { CalendarToday, Folder, FolderCopy, Tune, Web } from '@mui/icons-material'
import { SearchChip } from '../common/filterbar/SearchChip'
import _ from 'lodash'
import { AttributionWindowFilter } from '../common/AttributionWindowFilter'
import { ConversionTrackingEventSelect } from './ConversionTrackingEventSelect'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAttributionSettingsOpen,
    getConversionEventSelectOpen,
    getDatasourceSelectionOpen,
    getTimePeriodSettingOpen,
    setAdsetSelectOpen,
    setAttributionSettingsOpen,
    setCampaignSelectOpen,
    setConversionEventSelectOpen,
    setDatasourceSelectionOpen,
    setTimePeriodSettingOpen,
} from '../common/filterbar/FilterSlice'
import { useIntl } from 'react-intl'
import { DateRangePicker } from '../common/daterange-picker/DateRangePicker'
import { renderTimePeriodString } from '../resources/translations/Helper'
import { ConversionTrackingCampaignSelect, renderCampaignValueAsString } from './ConversionTrackingCampaignSelect'
import { ConversionTrackingAdsetSelect, renderAdsetValueAsString } from './ConversionTrackingAdsetSelect'

type ConversionTrackingFilterDrawerProps = {
    includeCampaignAndAdsetFilters?: boolean
}

export const ConversionTrackingFilterDrawer: FC<ConversionTrackingFilterDrawerProps> = ({ includeCampaignAndAdsetFilters }) => {
    const dispatch = useDispatch()
    const intl = useIntl()

    const selectedDatasource = useSelector(getConversionTrackingDatasource)
    const adAccounts = useSelector(getFacebookAdAccountsWithConversionTracking)
    const timePeriodStart = useSelector(getConversionTrackingStartDate)
    const timePeriodEnd = useSelector(getConversionTrackingEndDate)
    const attributionWindows = useSelector(getConversionTrackingAttributionWindows)
    const searchTerm = useSelector(getConversionTrackingSearchTerm)
    const conversionEvent = useSelector(getConversionTrackingEvent)
    const selectedCampaignIds = useSelector(getConversionTrackingSelectedCampaignIds)
    const selectedAdsetIds = useSelector(getConversionTrackingSelectedAdsetIds)
    const campaigns = useSelector(getConversionTrackingCampaigns)
    const adsets = useSelector(getConversionTrackingAdsets)

    const searchBarRef = useRef<any>(null)
    const attributionSettingsOpen = useSelector(getAttributionSettingsOpen)
    const handleAttributionSettingsOpen = () => dispatch(setAttributionSettingsOpen(true))
    const handleAttributionSettingsClose = () => dispatch(setAttributionSettingsOpen(false))
    const conversionSelectOpen = useSelector(getConversionEventSelectOpen)
    const handleConversionSelectOpen = () => dispatch(setConversionEventSelectOpen(true))
    const handleConversionSelectClose = () => dispatch(setConversionEventSelectOpen(false))

    const datasourceSelectionOpen = useSelector(getDatasourceSelectionOpen)
    const handleDatasourceSelectionOpen = () => {
        dispatch(setDatasourceSelectionOpen(true))
    }
    const handleDatasourceSelectionClose = () => {
        dispatch(setDatasourceSelectionOpen(false))
    }

    const timePeriodOpen = useSelector(getTimePeriodSettingOpen)
    const handleTimePeriodOpen = () => {
        dispatch(setTimePeriodSettingOpen(true))
    }
    const handleTimePeriodClose = () => {
        dispatch(setTimePeriodSettingOpen(false))
    }

    const handleTimePeriodChange = (startDate: string, endDate: string) => {
        dispatch(changeTimePeriod({ startDate, endDate }))
    }

    const handleDatasourceChange = (adAccountId: string) => {
        dispatch(selectDatasource(adAccountId))
    }

    const getDatasourceSetting = () => {
        const generateSettingString = () => {
            return selectedDatasource ? selectedDatasource.name : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: 0 })
        }

        const onChipClick = () => {
            setTimeout(handleDatasourceSelectionOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: (
                <DatasourceFilter
                    fullWidth
                    handleClose={handleDatasourceSelectionClose}
                    handleOpen={handleDatasourceSelectionOpen}
                    isOpen={datasourceSelectionOpen}
                    variant={'outlined'}
                    datasources={adAccounts}
                    onChange={handleDatasourceChange}
                    selectedDatasource={selectedDatasource}
                />
            ),
            tooltip: 'general.datasources',
            hasChangedFromDefault: false,
            currentValueAsString: generateSettingString(),
            onClickFunction: onChipClick,
            deletable: false,
            important: true,
            icon: <Web fontSize={'small'} />,
        }
        return set
    }

    const getSearchBarFilter = () => {
        const onDeleteFunction = () => {
            dispatch(changeSearchTerm(''))
        }

        const onClickFunction = () => {
            if (searchBarRef.current) {
                setTimeout(() => {
                    searchBarRef.current.focus()
                }, 300)
            }
        }
        const set: FilterSetting = {
            onlyChip: true,
            chipElement: <SearchChip onChange={(value) => dispatch(changeSearchTerm(value))} value={searchTerm} searchbarTitle={'general.search'} />,
            tooltip: 'general.search',
            onDeleteFunction: onDeleteFunction,
            deletable: true,
            hasChangedFromDefault: searchTerm.length > 0,
            important: true,
            onClickFunction,
        }
        return set
    }

    const getAttributionSetting = () => {
        const getAttributionWindowResetFunction = () => {
            const defaultAsArray = ['7d_click', '1d_view'].map((d) => d)
            const selectedAsArray = (attributionWindows || []).map((d) => d)
            if (!_.isEqual(defaultAsArray.sort(), selectedAsArray.sort())) {
                return () => {
                    dispatch(changeAttributionWindows(['7d_click', '1d_view'] || []))
                }
            }
        }
        const attributionWindowsString = attributionWindows
            .map((s) =>
                intl.formatMessage({
                    id: `facebook.ads.attribution-window.${s}`,
                })
            )
            .join(', ')

        const onChipClick = () => {
            setTimeout(handleAttributionSettingsOpen, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <AttributionWindowFilter
                    open={attributionSettingsOpen}
                    onOpen={handleAttributionSettingsOpen}
                    onClose={handleAttributionSettingsClose}
                    value={attributionWindows || []}
                    onChange={(attributionWindows) => dispatch(changeAttributionWindows(attributionWindows))}
                    variant={'outlined'}
                />
            ),
            tooltip: 'general.attribution-window',
            important: true,
            icon: <Tune fontSize={'small'} />,
            currentValueAsString: attributionWindowsString,
            onDeleteFunction: getAttributionWindowResetFunction(),
            onClickFunction: onChipClick,
            deletable: true,
            hasChangedFromDefault: !!getAttributionWindowResetFunction(),
        }
        return set
    }

    const getTimePeriodFilterSetting = () => {
        const defaultStartDate = moment().startOf('month').format('YYYY-MM-DD')
        const defaultEndDate = moment().endOf('month').format('YYYY-MM-DD')

        const hasChanged = () => {
            return !(timePeriodStart === defaultStartDate && timePeriodEnd === defaultEndDate)
        }
        const onDeleteFunction = () => {
            handleTimePeriodChange(defaultStartDate, defaultEndDate)
        }

        const onChipClick = () => {
            setTimeout(handleTimePeriodOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: (
                <DateRangePicker
                    variant="outlined"
                    onOpen={handleTimePeriodOpen}
                    onClose={handleTimePeriodClose}
                    open={timePeriodOpen}
                    startDate={timePeriodStart}
                    endDate={timePeriodEnd}
                    onChange={(startDate, endDate) => handleTimePeriodChange(startDate, endDate)}
                />
            ),
            tooltip: 'filters.timeperiod',
            currentValueAsString: renderTimePeriodString(timePeriodStart, timePeriodEnd, intl),
            important: true,
            deletable: true,
            hasChangedFromDefault: hasChanged(),
            onDeleteFunction: onDeleteFunction,
            onClickFunction: onChipClick,
            icon: <CalendarToday fontSize={'small'} />,
        }
        return set
    }

    const getConversionEventFilterSetting = () => {
        const defaultValue = 'offsite_conversion.fb_pixel_purchase'

        const hasChanged = () => {
            return conversionEvent !== defaultValue
        }
        const onDeleteFunction = () => {
            dispatch(changeConversionEvent(defaultValue))
        }

        const onChipClick = () => {
            setTimeout(handleConversionSelectOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: (
                <ConversionTrackingEventSelect
                    value={conversionEvent}
                    onChange={(value) => dispatch(changeConversionEvent(value))}
                    onOpen={handleConversionSelectOpen}
                    onClose={handleConversionSelectClose}
                    open={conversionSelectOpen}
                />
            ),
            tooltip: 'conversion-tracking.tracking-event',
            currentValueAsString: intl.formatMessage({ id: conversionEvent }),
            important: true,
            deletable: true,
            hasChangedFromDefault: hasChanged(),
            onDeleteFunction: onDeleteFunction,
            onClickFunction: onChipClick,
            icon: <CalendarToday fontSize={'small'} />,
        }
        return set
    }

    const getCampaignFilter = (): FilterSetting => {
        return {
            settingInput: <ConversionTrackingCampaignSelect />,
            tooltip: 'conversion-tracking.campaigns',
            currentValueAsString: renderCampaignValueAsString(selectedCampaignIds, campaigns, intl),
            important: true,
            deletable: true,
            hasChangedFromDefault: selectedCampaignIds.length !== 0,
            onDeleteFunction: () => dispatch(selectCampaignIds([])),
            onClickFunction: () => setTimeout(() => dispatch(setCampaignSelectOpen(true)), 300),
            icon: <Folder fontSize="small" />,
        }
    }

    const getAdsetFilter = (): FilterSetting => {
        return {
            settingInput: <ConversionTrackingAdsetSelect />,
            tooltip: 'conversion-tracking.adsets',
            currentValueAsString: renderAdsetValueAsString(selectedAdsetIds, adsets, intl),
            important: true,
            deletable: true,
            hasChangedFromDefault: selectedAdsetIds.length !== 0,
            onDeleteFunction: () => dispatch(selectAdsetIds([])),
            onClickFunction: () => setTimeout(() => dispatch(setAdsetSelectOpen(true)), 300),
            icon: <FolderCopy fontSize="small" />,
        }
    }

    let filters = [getAttributionSetting(), getTimePeriodFilterSetting(), getConversionEventFilterSetting()]

    if (includeCampaignAndAdsetFilters) {
        filters = [getDatasourceSetting(), getCampaignFilter(), getAdsetFilter(), ...filters]
    } else {
        filters = [getSearchBarFilter(), getDatasourceSetting(), ...filters]
    }

    return <FilterDrawer filters={filters} />
}
