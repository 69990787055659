import * as _ from 'lodash'
import { Post } from '../posts/Post'
import { PublishingFormValues, ValidDatasourceTypeForPublishing } from '../PublishingForm'
import { validateUrl } from '../../form/validate'
import moment from 'moment/moment'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { IdMap } from '../../core/slices/DataSlice'
import { PublishingState } from '../PublishingSlice'
import { PostGroup } from './PostGroup'

export type PostGroupRequest = {
    project_id: string
    assignee_id?: string
    tags: string[]
    customized_by_network: boolean
    publish_time?: string

    state: Post['state']
    posts: Post[]
}

export const convertValuesToPostGroupRequest = (
    values: PublishingFormValues,
    datasourcesById: IdMap<ProjectDatasource>,
    datasourcesOwningDomain: PublishingState['allowedDomainsByDatasource'],
    selectedPostGroup?: PostGroup
): PostGroupRequest => {
    const posts = [] as PostGroupRequest['posts']
    const postsByDatasourceId = selectedPostGroup ? _.mapKeys(selectedPostGroup.posts, 'data_source_id') : undefined

    const hasDatasourceDomainAccess = (datasource: ProjectDatasource, linkValueAsHostname: string) => {
        if (Object.keys(datasourcesOwningDomain).includes(datasource.id) && linkValueAsHostname) {
            return datasourcesOwningDomain[datasource.id].includes(linkValueAsHostname)
        }

        return false
    }

    values.data_source_ids.forEach((datasourceId) => {
        const datasource = datasourcesById[datasourceId]
        const postTemplate = values.customize_posts_by_network
            ? values.postByType[datasource.type as ValidDatasourceTypeForPublishing] || values.common_post
            : values.common_post
        const customizedPlanViaFacebookTime =
            datasource.type === 'FACEBOOK_PAGE' && values.customize_posts_by_network ? values.postByType['FACEBOOK_PAGE']?.fb_plan_via_facebook : undefined
        const commonPlanViaFacebookTime =
            datasource.type === 'FACEBOOK_PAGE' && !values.customize_posts_by_network ? values.common_post.fb_plan_via_facebook : false
        const planViaFacebook = customizedPlanViaFacebookTime || commonPlanViaFacebookTime
        const linkValueAsHostname = postTemplate.link ? (validateUrl(postTemplate.link) ? undefined : new URL(postTemplate.link).hostname) : undefined
        const canCustomizeLinkPreview =
            datasource.type === 'LINKED_IN' ? true : linkValueAsHostname ? hasDatasourceDomainAccess(datasource, linkValueAsHostname) : false

        posts.push({
            ...postTemplate,
            id: postsByDatasourceId ? postsByDatasourceId[datasourceId]?.id : undefined,
            data_source_id: datasourceId,
            data_source_type: datasource.type,
            link_preview_title: canCustomizeLinkPreview ? postTemplate.link_preview_title : undefined,
            link_preview_description: canCustomizeLinkPreview ? postTemplate.link_preview_description : undefined,
            link_preview_file_id: canCustomizeLinkPreview
                ? postTemplate.link_preview_file_id?.length && postTemplate.link_preview_file_id?.length > 0
                    ? postTemplate.link_preview_file_id?.toString()
                    : undefined
                : undefined,
            fb_publish_time: planViaFacebook ? postTemplate.publish_time : undefined,
        } as Post)
    })

    return {
        project_id: values.project_id,
        state: values.common_post.state,
        publish_time: values.common_post.publish_time === 'now' ? moment().toISOString() : values.common_post.publish_time,
        customized_by_network: values.customize_posts_by_network,
        assignee_id: values.assignee_id,
        tags: values.tag_ids || [],
        posts: posts,
    }
}
