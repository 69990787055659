import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { App } from './App'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { createHmstrTheme } from './theme/hmstr-theme'
import { AccountLinkSuccess } from '../settings/linked-accounts/AccountLinkSuccess'
import { useSelector } from 'react-redux'
import { getCurrentUser, getSelectedAccount, getSelectedProject, isDarkMode } from './slices/CoreSlice'
import { AccountLinkError } from '../settings/linked-accounts/AccountLinkError'
import { Onboarding } from '../onboarding/Onboarding'
import { useEffectWithIdComparison } from './hooks/useEffectWithIdComparison'
import { AccountLocked } from '../settings/accounts/AccountLocked'
import { AccountOverCapacity } from '../settings/accounts/AccountOverCapacity'
import { AccountPaymentRequired } from '../settings/accounts/AccountPaymentRequired'
import { AccountPaymentInProcess } from '../settings/accounts/AccountPaymentInProcess'

type AppEntryPointsProps = {}

export const AppEntryPoints: FC<AppEntryPointsProps> = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const darkModeEnabled = useSelector(isDarkMode)
    const selectedProject = useSelector(getSelectedProject)
    const selectedAccount = useSelector(getSelectedAccount)
    const currentUser = useSelector(getCurrentUser)
    const isOwner = selectedAccount?.owner_id === currentUser.id

    useEffectWithIdComparison(() => {
        if (!selectedProject && !['/account-link-success', '/account-link-error'].includes(location.pathname)) {
            navigate('/')
        }
    }, [selectedProject])

    const renderEntryPoint = () => {
        if (location.pathname === '/account-link-success') {
            return <AccountLinkSuccess />
        }

        if (location.pathname === '/account-link-error') {
            return <AccountLinkError />
        }

        if (!selectedProject) {
            return <Onboarding />
        }

        if (['LOCKED', 'TRIAL_EXPIRED', 'ARCHIVED'].includes(selectedAccount?.status)) {
            return <AccountLocked status={selectedAccount?.status} />
        }

        if (selectedAccount?.status === 'OVER_CAPACITY') {
            return <AccountOverCapacity />
        }

        if (selectedAccount?.status === 'PAYMENT_IN_PROCESS' && isOwner) {
            return <AccountPaymentInProcess />
        }

        if (
            ['PAYMENT_REQUIRED', 'SETUP', 'PAYMENT_IN_PROCESS'].includes(selectedAccount?.status) &&
            selectedAccount?.payment_intent_secret &&
            selectedAccount?.payment_intent_secret !== 'REDACTED'
        ) {
            return <AccountPaymentRequired />
        }

        return <App />
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createHmstrTheme(darkModeEnabled ? 'dark' : 'light')}>
                <CssBaseline />

                {renderEntryPoint()}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
