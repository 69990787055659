import * as React from 'react'
import { FC } from 'react'
import { DynamicFeed, InsertLink, Notes, Photo, QuestionMark, Subscriptions, Videocam } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { Tooltip, Typography, useTheme } from '@mui/material'
import { PostGroup } from '../post-groups/PostGroup'

type PostTypeIconProps = {
    postGroup: PostGroup
    isListView?: boolean
}

export const PostTypeIcon: FC<PostTypeIconProps> = ({ postGroup, isListView }) => {
    const theme = useTheme()

    const allPostTypes = postGroup.posts.map((post) => {
        return post.post_type
    })

    const type = allPostTypes.length > 1 ? 'MULTIPLE' : allPostTypes[0]

    const generatePostTypeIcon = () => {
        switch (type) {
            case 'TEXT':
                return <Notes fontSize={!isListView ? 'small' : undefined} />
            case 'LINK':
                return <InsertLink fontSize={!isListView ? 'small' : undefined} />
            case 'VIDEO':
                return <Videocam fontSize={!isListView ? 'small' : undefined} />
            case 'IMAGE':
                return <Photo fontSize={!isListView ? 'small' : undefined} />
            case 'MULTI_MEDIA':
                return <Subscriptions fontSize={!isListView ? 'small' : undefined} />
            case 'MULTIPLE':
                return <DynamicFeed fontSize={!isListView ? 'small' : undefined} />
            default:
                return <QuestionMark fontSize={!isListView ? 'small' : undefined} />
        }
    }

    return (
        <Tooltip title={<FormattedMessage id={`publishing.type.${type}`} />} disableInteractive sx={{ display: 'flex' }}>
            <Typography color={theme.palette.action.active}>{generatePostTypeIcon()}</Typography>
        </Tooltip>
    )
}
