import React, { FC } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { composeValidators, validationMaxLength, validationRequired } from '../../form/validate'
import { useFormState } from 'react-final-form'
import { Project } from './Project'

type ProjectNewWizardMetadataProps = {}

export const validateIfContactPersonExists = (value: any, allValues: Partial<Project>) => {
    if (!Object.keys(allValues).includes('contact_person') || allValues.contact_person === null) {
        return undefined
    }

    return validationRequired(value)
}

export const ProjectNewWizardMetadata: FC<ProjectNewWizardMetadataProps> = () => {
    const intl = useIntl()
    const formState = useFormState()

    const descriptionLength = formState.values && formState.values.description ? formState.values.description.length : 0
    const maxLength = 512

    return (
        <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12}>
                <Typography variant="overline">
                    <strong>
                        <FormattedMessage id="general.common" />
                    </strong>
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <ConnectedTextField
                    label="general.name"
                    name="name"
                    required={true}
                    validate={composeValidators(validationRequired, validationMaxLength(intl, 32))}
                    maxLength={128}
                />
            </Grid>
            <Grid item xs={6}>
                <ConnectedTextField
                    label="general.description"
                    name="description"
                    multiline={true}
                    helperText={`${descriptionLength} / ${maxLength} ${intl.formatMessage({
                        id: 'general.characters',
                    })}`}
                    maxLength={maxLength}
                />
            </Grid>

            <Grid item xs={12}>
                <Box marginTop={4}>
                    <Typography variant="overline">
                        <strong>
                            <FormattedMessage id="general.contactPerson" />
                        </strong>
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <ConnectedTextField label="general.firstName" name="contact_person.first_name" maxLength={64} validate={validateIfContactPersonExists} />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConnectedTextField label="general.lastName" name="contact_person.last_name" maxLength={64} validate={validateIfContactPersonExists} />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConnectedTextField label="general.title" name="contact_person.title" maxLength={128} />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConnectedTextField label="general.email" name="contact_person.email" maxLength={128} />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConnectedTextField label="general.phone" name="contact_person.phone" maxLength={64} />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConnectedTextField label="general.facebook-profile" name="contact_person.facebook" maxLength={128} />
            </Grid>
        </Grid>
    )
}
