import { FC, useEffect, useRef, useState } from 'react'
import { Box, IconButton, Skeleton } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { PublishingMedia } from '../../PublishingMedia'
import { useImage } from '../../../core/hooks/useImage'
import { PlayArrowRounded, VolumeOff, VolumeUp } from '@mui/icons-material'

type PostPreviewVideoInstagramProps = {
    file: PublishingMedia
    onscreen: boolean
}

export const PostPreviewVideoInstagram: FC<PostPreviewVideoInstagramProps> = ({ file, onscreen }) => {
    const { objectUrl } = useImage(file._links.file.href)
    const [muted, setMuted] = useState(true)
    const videoRef = useRef<any>(undefined)
    const [playing, setPlaying] = useState(true)
    const [remainingTime, setRemainingTime] = useState(0)
    const [showTimer, setShowTimer] = useState(true)
    useEffect(() => {
        if (showTimer && playing) {
            setTimeout(() => {
                setShowTimer(false)
            }, 4500)
        } else if (!playing) {
            setShowTimer(true)
        }
    }, [showTimer, playing])

    const getRenderedHeight = () => {
        const ratio = 450 / file.width
        return ratio * file.height
    }

    const updateCountdown = () => {
        if (videoRef.current) {
            setRemainingTime(videoRef.current.duration - videoRef.current.currentTime)
        }
    }
    const generateRemainingTime = (passedTime: number) => {
        if (isNaN(passedTime)) {
            return '0:00'
        } else {
            const minutes = Math.floor(remainingTime / 60)
            return minutes + ':' + ('00' + (passedTime - 60 * minutes).toFixed(0)).slice(-2)
        }
    }

    useEffect(() => {
        if (!onscreen) {
            if (videoRef.current) {
                if (playing) {
                    videoRef.current.pause()
                    setPlaying(false)
                    videoRef.current.removeEventListener('timeupdate', updateCountdown)
                }
            }
        } else {
            if (videoRef.current) {
                if (!playing) {
                    videoRef.current.play()
                    setRemainingTime(videoRef.current.duration - videoRef.current.currentTime)
                    videoRef.current.addEventListener('timeupdate', updateCountdown)
                    setPlaying(true)
                    setShowTimer(true)
                } else {
                    setRemainingTime(videoRef.current.duration - videoRef.current.currentTime)
                    videoRef.current.addEventListener('timeupdate', updateCountdown)
                    setShowTimer(true)
                }
            } else {
                setTimeout(() => {
                    if (videoRef.current) {
                        if (!playing) {
                            videoRef.current.play()
                            setRemainingTime(videoRef.current.duration - videoRef.current.currentTime)
                            videoRef.current.addEventListener('timeupdate', updateCountdown)
                            setPlaying(true)
                            setShowTimer(true)
                        } else {
                            setRemainingTime(videoRef.current.duration - videoRef.current.currentTime)
                            videoRef.current.addEventListener('timeupdate', updateCountdown)
                            setShowTimer(true)
                        }
                    }
                }, 200)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onscreen])

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (playing) {
                videoRef.current.pause()
                setPlaying(false)
                setShowTimer(true)
            } else {
                videoRef.current.play()
                setPlaying(true)
            }
        }
    }

    return (
        <Box sx={{ height: '100%', width: '100%', overflow: 'hidden', position: 'relative', background: 'black' }}>
            <Box
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                    color: 'white',
                    background: '#666',
                    fontWeight: 600,
                    fontSize: '14px',
                    transition: !showTimer ? 'all 1s' : 'none',
                    opacity: showTimer ? 1 : 0,
                    px: 1,
                    py: 0.3,
                    borderRadius: '100px',
                }}
            >
                {generateRemainingTime(remainingTime)}
            </Box>
            {objectUrl ? (
                <>
                    <Box position={'absolute'} width={'100%'} height={'100%'} bgcolor={'transparent'} onClick={handleVideoClick} />
                    <video
                        ref={videoRef}
                        autoPlay={true}
                        loop
                        controls={false}
                        muted={muted}
                        playsInline
                        height="100%"
                        style={{
                            objectFit: 'contain',
                            maxHeight: 450,
                            width: '100%',
                            height: '100%',
                            touchAction: 'auto',
                        }}
                        src={objectUrl}
                    >
                        <FormattedMessage id="publishing.video-not-supported" />
                    </video>
                </>
            ) : (
                <Skeleton variant="rectangular" width="100%" height={getRenderedHeight()} animation="wave" sx={{ transform: 'none', maxHeight: 450 }} />
            )}
            <IconButton
                sx={{
                    position: 'absolute',
                    background: 'rgb(38,38,38)',
                    bottom: '15px',
                    right: '15px',
                    p: 1,
                    transform: 'scale(0.8)',
                    color: '#fff',
                }}
                disableRipple
                size={'small'}
                onClick={() => setMuted(!muted)}
            >
                {muted ? <VolumeOff fontSize={'small'} /> : <VolumeUp fontSize={'small'} />}
            </IconButton>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {!playing && <PlayArrowRounded sx={{ fontSize: '12em', color: 'white' }} />}
            </Box>
        </Box>
    )
}
