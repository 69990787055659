import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type ImageEditorCropIconProps = SvgIconProps

export const ImageEditorCropIcon: FC<ImageEditorCropIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" stroke="none" fill="none"></path>
                <path stroke="none" fill="inherit" d="M4 0h1v20a1 1 0 0 1-1-1V0zM20 17h-1V5h1v12zm0 2v5h-1v-5h1z"></path>
                <path stroke="none" fill="inherit" d="M5 19h19v1H5zM4.762 4v1H0V4h4.762zM7 4h12a1 1 0 0 1 1 1H7V4z"></path>
            </svg>
        </SvgIcon>
    )
}
