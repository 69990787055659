import * as React from 'react'
import { FC, MouseEvent, useState } from 'react'
import { TitlebarWithFilters } from '../common/filterbar/TitlebarWithFilters'
import { Box, Breadcrumbs, Button, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Fallback } from '../common/fallback/Fallback'
import { ConversionTrackingAdPreview } from './ConversionTrackingAdPreview'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import {
    getConversionTrackingAdDataLoading,
    getConversionTrackingAdDataTotal,
    getConversionTrackingDataLoading,
    getConversionTrackingDatasource,
    getConversionTrackingEndDate,
    getConversionTrackingEvent,
    getConversionTrackingFilteredAdDataWithConversions,
    getConversionTrackingStartDate,
    getFacebookAdAccountsWithConversionTracking,
    selectDatasource,
    showAdPreview,
} from './ConversionTrackingSlice'
import { getSelectedAccount, getSelectedProject } from '../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { fetchFacebookAdPerformanceData, fetchFacebookConversionData } from './ConversionActions'
import moment from 'moment/moment'
import { replaceUrlParams } from '../core/helpers/replace-url-params'
import TableHead from '@mui/material/TableHead'
import { formatNumber } from '../core/theme/helper'
import { QuestionMark, Visibility } from '@mui/icons-material'
import { Conversion } from './Conversion'
import { ActualConversionPreview } from './ActualConversionPreview'
import { ConversionTrackingFilterDrawer } from './ConversionTrackingFilterDrawer'
import { ConversionTrackingHeadCell, getComparator, Order, stableSort } from './ConversionTrackingAdsUtils'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { ConversionTrackingAdWithConversions } from './ConversionTrackingAd'
import { Loading } from '../common/loading/Loading'
import { FormattedCurrency } from '../common/formatted-currency/FormattedCurrency'
import { FormattedPercent } from '../common/formatted-percent/FormattedPercent'
import { Account } from '../settings/accounts/Account'

type ConversionTrackingAdsProps = {}

const getHeadCells = (selectedAccount: Account, conversionEvent: 'lead' | 'purchase'): ConversionTrackingHeadCell[] => {
    const headCells: ConversionTrackingHeadCell[] = [
        {
            id: 'name',
            numeric: false,
            label: 'conversion-tracking.head.name',
        },
        {
            id: 'spend',
            numeric: true,
            label: 'conversion-tracking.head.spend',
        },
        {
            id: 'impressions',
            numeric: true,
            label: 'conversion-tracking.head.impressions',
        },
        {
            id: 'ctr',
            numeric: true,
            label: 'conversion-tracking.head.ctr',
        },
        {
            id: 'outbound_clicks',
            numeric: true,
            label: 'conversion-tracking.head.outbound_clicks',
        },
        {
            id: 'outbound_ctr',
            numeric: true,
            label: 'conversion-tracking.head.outbound_ctr',
        },
        {
            id: 'fb_conversion_count',
            numeric: true,
            label: 'conversion-tracking.head.conversions',
        },
        {
            id: 'actual_conversion_count',
            numeric: true,
            label: 'conversion-tracking.head.matches',
        },
        {
            id: 'cost_per_conversion',
            numeric: true,
            label: 'conversion-tracking.head.cost-per-' + conversionEvent,
        },
    ]

    if (selectedAccount.beta) {
        headCells.push({
            id: 'qualityScore',
            numeric: true,
            label: 'conversion-tracking.head.lead-quality',
        })
    }

    return headCells
}

export const ConversionTrackingAds: FC<ConversionTrackingAdsProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const selectedDatasource = useSelector(getConversionTrackingDatasource)
    const conversionDataLoading = useSelector(getConversionTrackingDataLoading)
    const adDataLoading = useSelector(getConversionTrackingAdDataLoading)
    const selectedProject = useSelector(getSelectedProject)
    const adAccounts = useSelector(getFacebookAdAccountsWithConversionTracking)
    const timePeriodStart = useSelector(getConversionTrackingStartDate)
    const timePeriodEnd = useSelector(getConversionTrackingEndDate)
    const conversionEvent = useSelector(getConversionTrackingEvent)
    const adData = useSelector(getConversionTrackingFilteredAdDataWithConversions)
    const adDataTotal = useSelector(getConversionTrackingAdDataTotal)
    const selectedAccount = useSelector(getSelectedAccount)
    const [orderBy, setOrderBy] = useState<keyof ConversionTrackingAdWithConversions>()
    const [order, setOrder] = useState<Order>('asc')

    useEffectWithIdComparison(() => {
        if (selectedDatasource) {
            dispatch(fetchFacebookAdPerformanceData(selectedDatasource))
            dispatch(fetchFacebookConversionData(selectedDatasource))
        }
    }, [selectedDatasource, timePeriodStart, timePeriodEnd, conversionEvent])

    useEffectWithIdComparison(() => {
        if (selectedProject && !selectedDatasource && adAccounts.length > 0) {
            dispatch(selectDatasource(adAccounts[0].id))
        }
    }, [selectedProject])

    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewConversions, setPreviewConversions] = useState<Conversion[]>([])

    useEffectWithIdComparison(() => {
        if (selectedDatasource) {
            const urlParams = new URLSearchParams(window.location.search)
            urlParams.set('startDate', moment(timePeriodStart).format('YYYY-MM-DD'))
            urlParams.set('endDate', moment(timePeriodEnd).format('YYYY-MM-DD'))
            urlParams.set('datasourceId', selectedDatasource.id)
            urlParams.set('eventType', conversionEvent)
            replaceUrlParams(urlParams)
        }
    }, [selectedDatasource, timePeriodStart, timePeriodEnd, conversionEvent])

    const handleOpenActualConversionPreview = (event: MouseEvent<any>, conversions: Conversion[]) => {
        event.preventDefault()
        event.stopPropagation()
        if (conversions.length > 0) {
            setPreviewConversions(conversions)
            setPreviewOpen(true)
        }
    }

    const handleRequestSort = (property: keyof ConversionTrackingAdWithConversions) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <ContentPanel
            title={
                <TitlebarWithFilters
                    title={
                        <Breadcrumbs>
                            <Typography variant="h5">
                                <FormattedMessage id="conversion-tracking.title" />
                            </Typography>
                            <Typography variant="h5">
                                <FormattedMessage id="conversion-tracking.ads" />
                            </Typography>
                        </Breadcrumbs>
                    }
                    filterBar={<ConversionTrackingFilterDrawer includeCampaignAndAdsetFilters={true} />}
                />
            }
            fullWidthTitle
        >
            <Fallback
                condition={adAccounts.length === 0}
                messageId="conversion-tracking.no-active-adaccounts-hint"
                marginTop={4}
                actionButton={
                    <Button variant="contained" color="secondary" onClick={() => navigate('analytics/ct-management')}>
                        <FormattedMessage id="conversion-tracking.navigate-to-management" />
                    </Button>
                }
            >
                {adDataLoading || conversionDataLoading ? (
                    <Loading />
                ) : (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="none" />
                                <TableCell />
                                {getHeadCells(selectedAccount, conversionEvent === 'offsite_conversion.fb_pixel_lead' ? 'lead' : 'purchase').map((headCell) => {
                                    return (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.numeric ? 'right' : 'left'}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            sx={{ whiteSpace: 'nowrap' }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={() => handleRequestSort(headCell.id)}
                                            >
                                                <FormattedMessage id={headCell.label} />
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(orderBy ? stableSort(adData, getComparator(order, orderBy)) : adData).map((ad) => {
                                const handleAdPreviewClick = (event: MouseEvent<any>) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    dispatch(showAdPreview(ad.id))
                                }

                                return (
                                    <TableRow
                                        key={ad.id}
                                        hover={ad.actual_conversion_count > 0}
                                        sx={{ cursor: ad.actual_conversion_count > 0 ? 'pointer' : undefined }}
                                        onClick={(event) => handleOpenActualConversionPreview(event, ad.conversions)}
                                    >
                                        <TableCell>
                                            <Tooltip title={<FormattedMessage id="conversion-tracking.load-ad-preview" />} placement="top">
                                                <Visibility fontSize="small" color="action" sx={{ cursor: 'pointer' }} onClick={handleAdPreviewClick} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {ad.thumbnail_url ? (
                                                <img height={64} src={ad.thumbnail_url} alt={'ad-thumbnail-' + ad.id} />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'grey.A200',
                                                        width: 64,
                                                        height: 64,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: 'white',
                                                    }}
                                                >
                                                    <QuestionMark />
                                                </Box>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: 250,
                                                pr: 0,
                                            }}
                                        >
                                            <Tooltip title={ad.id + ' - ' + ad.name} placement="top">
                                                <span>{ad.name}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="right">
                                            <FormattedCurrency value={ad.spend} />
                                        </TableCell>
                                        <TableCell align="right">{formatNumber(ad.impressions)}</TableCell>
                                        <TableCell align="right">
                                            <FormattedPercent value={ad.ctr} />
                                        </TableCell>
                                        <TableCell align="right">{ad.outbound_clicks}</TableCell>
                                        <TableCell align="right">
                                            <FormattedPercent value={ad.outbound_ctr} />
                                        </TableCell>
                                        <TableCell align="right">{formatNumber(ad.fb_conversion_count)}</TableCell>
                                        <TableCell align="right">{formatNumber(ad.actual_conversion_count)}</TableCell>
                                        <TableCell align="right">
                                            {ad.cost_per_conversion > 0 ? <FormattedCurrency value={ad.cost_per_conversion} /> : '-'}
                                        </TableCell>
                                        {selectedAccount.beta && (
                                            <TableCell align="right">{ad.qualityScore !== -1 ? formatNumber(ad.qualityScore) : '-'}</TableCell>
                                        )}
                                    </TableRow>
                                )
                            })}
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Box height={64} />
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight={600}>
                                        <FormattedMessage id="conversion-tracking.sum" />
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedCurrency value={adDataTotal.spend} />
                                </TableCell>
                                <TableCell align="right">{formatNumber(adDataTotal.impressions)}</TableCell>
                                <TableCell align="right">
                                    <FormattedPercent value={adDataTotal.ctr} />
                                </TableCell>
                                <TableCell align="right">{adDataTotal.outbound_clicks}</TableCell>
                                <TableCell align="right">
                                    <FormattedPercent value={adDataTotal.outbound_ctr} />
                                </TableCell>
                                <TableCell align="right">{formatNumber(adDataTotal.conversion_count)}</TableCell>
                                <TableCell align="right">{formatNumber(adDataTotal.matches)}</TableCell>
                                <TableCell align="right">
                                    {adDataTotal.cost_per_conversion > 0 ? <FormattedCurrency value={adDataTotal.cost_per_conversion} /> : '-'}
                                </TableCell>
                                {selectedAccount.beta && (
                                    <TableCell align="right">{adDataTotal.quality !== -1 ? formatNumber(adDataTotal.quality) : '-'}</TableCell>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                )}

                <ConversionTrackingAdPreview />
                <ActualConversionPreview open={previewOpen} onClose={() => setPreviewOpen(false)} conversions={previewConversions} />
            </Fallback>
        </ContentPanel>
    )
}
