import * as _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { UserAccountAccess, UserProjectAccess } from '../user-management/User'
import { doDelete, doGet, doPost, doPut } from '../../core/api/ApiClient'
import { Project } from './Project'

const PROJECTS_SLASH = (action: string) => `projects/${action}`

export const fetchProject = createAsyncThunk<Project, UserProjectAccess>(PROJECTS_SLASH('fetchById'), async (userProjectAccess, thunkAPI) => {
    return await doGet(thunkAPI, userProjectAccess._links.project)
})

export const fetchAllProjects = createAsyncThunk<Project[], void, { state: HmstrState }>(PROJECTS_SLASH('fetchAll'), async (args, thunkAPI) => {
    const state = thunkAPI.getState()
    return await doGet(thunkAPI, state.api.entryPoint._links.projects)
})

export const fetchProjectsForAccount = createAsyncThunk<Project[], UserAccountAccess>(
    PROJECTS_SLASH('fetchByAccountId'),
    async (userAccountAccess, thunkAPI) => {
        return await doGet(thunkAPI, userAccountAccess._links.projects)
    }
)

export const createProject = createAsyncThunk<Project, Project, { state: HmstrState }>(PROJECTS_SLASH('create'), async (project, thunkApi) => {
    const filteredDatasources = _.filter(project.data_sources, (ds) => Boolean(ds.name))
    const projectToCreate = {
        ...project,
        data_sources: filteredDatasources,
    } as Project

    return await doPost(thunkApi, thunkApi.getState().api.entryPoint._links.projects, projectToCreate)
})
export const updateProject = createAsyncThunk<Project, Project, { state: HmstrState }>(PROJECTS_SLASH('update'), async (project, thunkAPI) => {
    let imageAsBase64 = undefined

    function blobToBase64(blob: Blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob)
        })
    }

    if (typeof project.whitelabel_image !== 'string' && project.whitelabel_image) {
        imageAsBase64 = await blobToBase64(project.whitelabel_image)
    } else if (typeof project.whitelabel_image === 'string') {
        imageAsBase64 = project.whitelabel_image
    }
    const projectToCreate = {
        ...project,
        data_sources: project.data_sources.filter((ds) => Boolean(ds.name)),
        whitelabel_image: imageAsBase64,
    } as Project

    return await doPut(thunkAPI, project._links.self, projectToCreate)
})

export const deleteProject = createAsyncThunk<Project, Project>(PROJECTS_SLASH('delete'), async (project, thunkAPI) => {
    return await doDelete(thunkAPI, project._links.self, undefined)
})
