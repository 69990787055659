import Config, { ClientConfig } from '../config/config'
import axios from 'axios'
import { ApiEntryPoint } from '../api/ApiEntryPoint'

export const initClientConfiguration = (): Promise<[ClientConfig, ApiEntryPoint]> => {
    axios.defaults.baseURL = Config.backend.basePath
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.headers.common['Accept'] = 'application/json'
    axios.defaults.paramsSerializer = {
        ...axios.defaults.paramsSerializer,
        indexes: null,
    }

    if (['dev', 'local'].includes(process.env.REACT_APP_ENV || '')) {
        axios.defaults.baseURL = Config.backend.dev
    }

    return new Promise((resolve) => {
        const entryPoint = axios.get('')
        const init = axios.get('/init')

        Promise.all([init, entryPoint]).then((response) => {
            const [initResponse, entryPointResponse] = response
            let config = initResponse.data

            let entryPoint = entryPointResponse.data

            config = Object.assign({}, Config, config)

            resolve([config, entryPoint])
        })
    })
}
