import { FC } from 'react'
import { FormattedNumber } from 'react-intl'

type FormattedCurrencyProps = {
    value: number
}

export const FormattedCurrency: FC<FormattedCurrencyProps> = ({ value }) => {
    return (
        <FormattedNumber
            value={value}
            minimumFractionDigits={2}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency="EUR"
        />
    )
}
