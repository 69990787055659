import * as React from 'react'
import { FC, useRef, useState } from 'react'
import _ from 'lodash'
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Dashboard } from './Dashboard'
import { getTags, getTemplateData } from '../core/slices/DataSlice'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { ChevronLeft, ChevronRight, Close, Euro, Facebook, Info, Instagram, Language, LinkedIn } from '@mui/icons-material'
import { getDatasources, getImpairedVision, isDarkMode } from '../core/slices/CoreSlice'
import { DashboardStepper } from './DashboardStepper'
import { TemplatePreview } from '../common/template/TemplatePreview'
import MonthlyPageInsightsPreview from '../resources/images/hmstr-monitoring-dashboard-monthly-page-insights.png'
import MonthlyReportPreview from '../resources/images/hmstr-monitoring-dashboard-monthly-report.png'
import PerformanceReportPreview from '../resources/images/hmstr-monitoring-dashboard-performance-report.png'
import PostReportPreview from '../resources/images/hmstr-monitoring-dashboard-post-report.png'
import InstagramAccountReportPreview from '../resources/images/hmstr-monitoring-dashboard-instagram-account-report.png'
import FBIGComparisonReportPreview from '../resources/images/hmstr-monitoring-dashboard-fb-ig-comparison.png'
import LinkedinReportPreview from '../resources/images/hmstr-monitoring-dashboard-linkedin-report.png'
import moment from 'moment'
import { DashboardTemplate, DashboardTemplateChannelConfig } from './DashboardTemplate'
import { defaultLast30DaysPeriod, defaultMonthlyPeriod, defaultYearlyPeriod } from './TemplateDefaultTimePeriods'
import { DashboardFormSettings } from './DashboardFormSettings'
import { checkStaticValues, getAttributionTranslation } from '../resources/translations/Helper'
import { fetchTagsForProject } from '../tags/TagsActions'
import { getDashboardWizardStep, setDashboardWizardStep } from './DashboardsSlice'
import { HmstrDispatch } from '../core/Store'
import { Project } from '../settings/project-management/Project'
import { ProjectDatasourceOverview } from '../settings/datasources/ProjectDatasourceOverview'
import { getAlertMessageForChannelConfig } from './getMessageForChannelConfig'
import { TiktokIcon } from '../common/icons/TiktokIcon'

type DashboardFormProps = {
    project: Project
    initialValues: Partial<Dashboard>
    overwriteableSettings?: Partial<Dashboard['settings']>
    onSubmit: (dashboard: Dashboard, closeDialog: boolean) => any
}

export const DashboardForm: FC<DashboardFormProps> = ({ onSubmit, initialValues, project, overwriteableSettings }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const impairedVision = useSelector(getImpairedVision)
    const darkMode = useSelector(isDarkMode)
    const templates = useSelector(getTemplateData)
    const activeStep = useSelector(getDashboardWizardStep)
    const datasources = useSelector(getDatasources)
    const [name, setName] = useState<string | undefined>(initialValues?.name)
    const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>(initialValues?.template_id)
    const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>(initialValues?.data_source_ids || [])
    const [selectedTags, setSelectedTags] = useState<string[]>(initialValues?.settings?.tag_ids || [])
    const [addDatasourceDialogOpen, setAddDatasourceDialogOpen] = useState(false)

    useEffectWithIdComparison(() => {
        dispatch(fetchTagsForProject(project))
    }, [project])

    const availableTags = useSelector(getTags)
    const availableTagsArr = _.map(availableTags, (tag) => tag)
    const template = templates.find((template) => template.id === selectedTemplate)
    const settingsKey = useRef<any>(Date.now())

    const isChannelConfigValid = () => {
        if (!template) {
            return false
        }

        const currentDatasourcesByType = _.chain(datasources)
            .filter((ds) => selectedChannelIds.includes(ds.id))
            .groupBy('type')
            .value()

        for (let i = 0; i < template.data_sources.length; i++) {
            const currentTypeConfig = template.data_sources[i]
            const channelsForType = _.get(currentDatasourcesByType, currentTypeConfig.type) as ProjectDatasource[] | undefined
            const channelCountForType = channelsForType ? channelsForType.length : 0

            if (currentTypeConfig.min_data_sources && channelCountForType < currentTypeConfig.min_data_sources) {
                return false
            }

            if (currentTypeConfig.max_data_sources && channelCountForType > currentTypeConfig.max_data_sources) {
                return false
            }
        }

        return true
    }

    const getDefaultSettingsForTemplate = (template: DashboardTemplate | undefined) => {
        if (template) {
            let initials = initialValues?.settings

            switch (template.name) {
                case 'post-report':
                    if (!initials || (!initials.relative_time_period && (!initials.fixed_start_date || !initials.fixed_end_date) && !initials.tag_ids))
                        return {
                            fixed_start_date: defaultLast30DaysPeriod.startDate,
                            fixed_end_date: defaultLast30DaysPeriod.endDate,
                            tag_ids: [],
                        }
                    break
                case 'fb-ig-comparison':
                    if (!initials || (!initials.relative_time_period && (!initials.fixed_start_date || !initials.fixed_end_date) && !initials.tag_ids))
                        return {
                            fixed_start_date: defaultYearlyPeriod.startDate,
                            fixed_end_date: defaultYearlyPeriod.endDate,
                            tag_ids: [],
                        }
                    break
                case 'page-insights':
                    if (!initials || (!initials.relative_time_period && (!initials.fixed_start_date || !initials.fixed_end_date)))
                        return {
                            fixed_start_date: defaultLast30DaysPeriod.startDate,
                            fixed_end_date: defaultLast30DaysPeriod.endDate,
                        }
                    break
                case 'monthly-report':
                    if (
                        !initials ||
                        ((!initials.relative_time_period || !initials.relative_compare_time_period) &&
                            (!initials.fixed_start_date || !initials.fixed_end_date || !initials.fixed_compare_start_date || !initials.fixed_compare_end_date))
                    ) {
                        return {
                            relative_time_period: 'last_month',
                            relative_compare_time_period: 'next_to_last_month',
                        }
                    }
                    break
                case 'linkedin-page-insights':
                case 'instagram-account-report':
                case 'performance':
                    if (
                        !initials ||
                        ((!initials.relative_time_period || !initials.relative_compare_time_period) &&
                            (!initials.fixed_start_date || !initials.fixed_end_date || !initials.fixed_compare_start_date || !initials.fixed_compare_end_date))
                    )
                        return {
                            relative_time_period: 'last_month',
                            relative_compare_time_period: 'next_to_last_month',
                            attribution_windows: ['7d_click', '1d_view'] as ('7d_click' | '1d_click' | '1d_view')[],
                        }
                    break
                default:
                    if (!initials || (!initials.relative_time_period && (!initials.fixed_start_date || !initials.fixed_end_date))) {
                        return {
                            fixed_start_date: defaultLast30DaysPeriod.startDate,
                            fixed_end_date: defaultLast30DaysPeriod.endDate,
                        }
                    } else if (
                        !initials ||
                        (!initials.relative_compare_time_period && (!initials.fixed_compare_start_date || !initials.fixed_compare_end_date))
                    ) {
                        return {
                            fixed_compare_start_date: defaultLast30DaysPeriod.compareStartDate,
                            fixed_compare_end_date: defaultLast30DaysPeriod.compareEndDate,
                        }
                    }
                    break
            }

            if (initials) {
                return { ...initials }
            }
        }
        return {}
    }
    const [settings, setSettings] = useState<Dashboard['settings']>(getDefaultSettingsForTemplate(template))
    const [showDynamic, setShowDynamic] = useState<boolean>(!!settings.relative_time_period)

    const getIconForDatasource = (datasource: ProjectDatasource) => {
        switch (datasource.type) {
            case 'FACEBOOK_PAGE':
                return <Facebook />
            case 'FACEBOOK_AD_ACCOUNT':
                return <Euro />
            case 'INSTAGRAM_ACCOUNT':
                return <Instagram />
            case 'LINKED_IN':
                return <LinkedIn />
            case 'TIKTOK_ACCOUNT':
                return <TiktokIcon />
            default:
                return <Language />
        }
    }

    const canChangeStepTo = (step: number) => {
        if (step === 0) {
            return true
        } else if (!selectedTemplate) {
            return false
        } else if (step === 1) {
            return true
        } else if (step === 2) {
            return selectedChannelIds.length > 0 && isChannelConfigValid()
        } else if (step === 3) {
            return name && name.trim().length > 0
        }
        return false
    }

    const submit = (overwriteSettings?: Dashboard['settings'], closeDialog: boolean = true) => {
        if (!!selectedTemplate && !!template) {
            if (!overwriteSettings && template.name === 'monthly-report') {
                settings.fixed_start_date = settings.fixed_start_date || defaultMonthlyPeriod.startDate
                settings.fixed_end_date = settings.fixed_end_date || defaultMonthlyPeriod.endDate
                settings.fixed_compare_start_date = settings.fixed_compare_start_date || defaultMonthlyPeriod.compareStartDate
                settings.fixed_compare_end_date = settings.fixed_compare_end_date || defaultMonthlyPeriod.compareEndDate
                settings.relative_time_period = settings.relative_time_period || 'last_month'
                settings.relative_compare_time_period = settings.relative_compare_time_period || 'next_to_last_month'

                if (showDynamic) {
                    delete settings.fixed_start_date
                    delete settings.fixed_end_date
                    delete settings.fixed_compare_start_date
                    delete settings.fixed_compare_end_date
                } else {
                    delete settings.relative_time_period
                    delete settings.relative_compare_time_period
                }
            }

            // @ts-ignore
            onSubmit(
                // @ts-ignore
                {
                    ...initialValues,
                    template_id: selectedTemplate,
                    name: name!,
                    project_id: project.id,
                    data_source_ids: selectedChannelIds,
                    settings: !!overwriteSettings ? overwriteSettings : settings,
                },
                closeDialog
            )
        }
    }

    const goToStep = (step: number) => {
        if (step === 2 && !name) {
            const dataSources = project.data_sources.filter((dataSources) => selectedChannelIds.includes(dataSources.id))
            setName(template?.display_name + '-' + dataSources.map((source) => source.name.trim()).join('-') + moment().format('-DD.MM.YY'))
        }

        dispatch(setDashboardWizardStep(step))

        if (step === 3) {
            submit()
        }
    }

    const renderFilterTemplate = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ pb: 1 }}>
                        <FormattedMessage id="reporting.dashboards.choose-template-description" />
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const getPreviewForTemplate = (template: DashboardTemplate): string | undefined => {
        switch (template.name) {
            case 'page-insights':
                return MonthlyPageInsightsPreview
            case 'monthly-report':
                return MonthlyReportPreview
            case 'performance':
                return PerformanceReportPreview
            case 'post-report':
                return PostReportPreview
            case 'instagram-account-report':
                return InstagramAccountReportPreview
            case 'linkedin-page-insights':
                return LinkedinReportPreview
            case 'fb-ig-comparison':
                return FBIGComparisonReportPreview
            default:
                return undefined
        }
    }

    const renderChooseTemplate = () => {
        return (
            <Grid
                container
                spacing={1}
                sx={{
                    height: 'calc(100dvh - 270px)',
                    overflowY: 'scroll',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    pr: 2,
                    pb: 1,
                }}
            >
                {templates.map((template, idx) => {
                    return (
                        <Grid key={template.id} sm={12} md={6} item>
                            <TemplatePreview
                                id={idx === 0 ? 'guide-choose-dashboard-template' : ''}
                                template={template}
                                headline={'templates.headline.' + template.name}
                                description={'templates.description.' + template.name}
                                image={getPreviewForTemplate(template)}
                                onSelect={(template) => {
                                    setSelectedChannelIds([])
                                    setSelectedTemplate(selectedTemplate !== template.id ? template.id : undefined)

                                    if (template) {
                                        setSettings(getDefaultSettingsForTemplate(template))
                                        setShowDynamic(true)
                                    }
                                }}
                                selected={selectedTemplate === template.id}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    const onAddDatasource = (source: ProjectDatasource) => {
        const relevantSources = project.data_sources.filter((dataSources) => dataSources.type === source.type && selectedChannelIds.includes(dataSources.id))

        if (!!relevantSources.find((relSource) => relSource.id === source.id)) {
            setSelectedChannelIds(selectedChannelIds.filter((activeSource) => activeSource !== source.id))
        } else {
            let maxAmount = template?.data_sources.find((requiredSources) => requiredSources.type === source.type)?.max_data_sources
            if (!maxAmount || relevantSources.length < maxAmount) {
                setSelectedChannelIds([...selectedChannelIds, source.id])
            }
        }
    }

    const renderDataSourceType = (channelConfig: DashboardTemplateChannelConfig) => {
        let matchingSources = project.data_sources.filter((source) => source.type === channelConfig.type)
        const alertMessageForChannelConfig = getAlertMessageForChannelConfig(intl, channelConfig)

        return (
            <Grid item xs={12} key={channelConfig.type}>
                {alertMessageForChannelConfig !== null && (
                    <Alert icon={<Info />} variant={impairedVision && !darkMode ? 'outlined' : 'standard'} color="info" sx={{ marginButton: '8px', mr: 1 }}>
                        {alertMessageForChannelConfig}
                    </Alert>
                )}
                <List sx={{ marginButton: '12px' }}>
                    {matchingSources.map((source, idx) => (
                        <ListItem key={source.id} disableGutters={true}>
                            <ListItemIcon>{getIconForDatasource(source)}</ListItemIcon>
                            <ListItemText primary={source.name} />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    id={idx === 0 ? 'guide-datasource-item' : ''}
                                    color="secondary"
                                    checked={selectedChannelIds.includes(source.id)}
                                    onChange={() => onAddDatasource(source)}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        )
    }

    const renderDatasourceHeader = () => {
        return (
            <Grid container>
                <Grid item xs={12} sx={{ mb: '12px' }}>
                    <Typography variant="h5">
                        <FormattedMessage id="reporting.dashboards.choose-datasource-description" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>
                        <Typography variant="body2">
                            <FormattedMessage id="datasources.cant-find-question" />
                            &nbsp;
                            <Link
                                sx={{ cursor: 'pointer' }}
                                color="secondary"
                                onClick={() => {
                                    setAddDatasourceDialogOpen(true)
                                }}
                            >
                                <FormattedMessage id="datasources.add-edit" />
                            </Link>
                        </Typography>
                    </InputLabel>
                </Grid>
                <Dialog
                    maxWidth={'lg'}
                    open={addDatasourceDialogOpen}
                    onClose={() => {
                        setAddDatasourceDialogOpen(false)
                    }}
                >
                    <DialogTitle>
                        <FormattedMessage id="datasources.add-edit" />
                        <IconButton
                            onClick={() => {
                                setAddDatasourceDialogOpen(false)
                            }}
                            sx={{ float: 'right' }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <ProjectDatasourceOverview />
                    </DialogContent>
                </Dialog>
            </Grid>
        )
    }

    const renderChooseDatasource = () => {
        return (
            <Grid
                container
                sx={{
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    flexGrow: 1,
                    margin: '12px 0',
                    height: 'calc(100dvh - 340px)',
                    pr: 1,
                }}
            >
                {template && (
                    <Grid item xs={12}>
                        <List>{template.data_sources.map((ds) => renderDataSourceType(ds))}</List>
                    </Grid>
                )}
            </Grid>
        )
    }

    const renderNameHeader = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ pb: 2 }}>
                        <FormattedMessage id="reporting.dashboards.choose-name-description" />
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const getCurrentChanges = () => {
        let changes: any = {}

        if (template && overwriteableSettings && initialValues.settings) {
            let differentRelative = false
            let differentFixedStart = false
            let differentFixedEnd = false

            switch (template.name) {
                // @ts-ignore we want to use fallthrough here
                case 'linkedin-page-insights':
                case 'instagram-account-report':
                case 'performance':
                    if (
                        !!overwriteableSettings?.attribution_windows &&
                        !!initialValues.settings.attribution_windows &&
                        !!(
                            overwriteableSettings?.attribution_windows.find((a) => !initialValues.settings!.attribution_windows!.includes(a)) ||
                            initialValues.settings?.attribution_windows.find((a) => !overwriteableSettings.attribution_windows!.includes(a))
                        )
                    ) {
                        changes['general.attribution-window'] = overwriteableSettings.attribution_windows.map((attributionWindow) => {
                            return intl.formatMessage({ id: getAttributionTranslation(attributionWindow) })
                        })
                    }

                    if (!!overwriteableSettings?.relative_time_period && !!initialValues.settings?.relative_time_period) {
                        differentRelative = overwriteableSettings?.relative_time_period !== initialValues.settings?.relative_time_period
                    } else {
                        differentFixedStart =
                            (!initialValues.settings.fixed_start_date && !!overwriteableSettings?.fixed_start_date) ||
                            (!!initialValues.settings.fixed_start_date && !overwriteableSettings?.fixed_start_date) ||
                            !initialValues.settings.fixed_start_date!.includes(overwriteableSettings.fixed_start_date!)
                        differentFixedEnd =
                            (!initialValues.settings.fixed_start_date && !!overwriteableSettings?.fixed_start_date) ||
                            (!!initialValues.settings.fixed_end_date && !overwriteableSettings?.fixed_end_date) ||
                            !initialValues.settings.fixed_end_date!.includes(overwriteableSettings.fixed_end_date!)
                    }

                    if (differentRelative || differentFixedStart || differentFixedEnd) {
                        let staticMatch = checkStaticValues(
                            new Date(overwriteableSettings?.fixed_start_date!),
                            new Date(overwriteableSettings?.fixed_end_date!),
                            intl,
                            false
                        )
                        if (staticMatch) {
                            changes['filters.timeperiod'] = staticMatch.label
                        } else {
                            changes['filters.timeperiod'] =
                                moment(overwriteableSettings?.fixed_start_date).format('DD.MM.YYYY') +
                                ' - ' +
                                moment(overwriteableSettings?.fixed_end_date).format('DD.MM.YYYY')
                        }
                    }
                    let differentRelativeCompare = false
                    let differentFixedCompareStart = false
                    let differentFixedCompareEnd = false

                    if (!!overwriteableSettings?.relative_compare_time_period && !!initialValues.settings?.relative_compare_time_period) {
                        differentRelativeCompare = overwriteableSettings?.relative_compare_time_period !== initialValues.settings?.relative_compare_time_period
                    } else {
                        differentFixedCompareStart =
                            (!initialValues.settings.fixed_compare_start_date && !!overwriteableSettings?.fixed_compare_start_date) ||
                            (!!initialValues.settings.fixed_compare_start_date && !overwriteableSettings?.fixed_compare_start_date) ||
                            !initialValues.settings.fixed_compare_start_date!.includes(overwriteableSettings.fixed_compare_start_date!)
                        differentFixedCompareEnd =
                            (!initialValues.settings.fixed_compare_end_date && !!overwriteableSettings?.fixed_compare_end_date) ||
                            (!!initialValues.settings.fixed_compare_end_date && !overwriteableSettings?.fixed_compare_end_date) ||
                            !initialValues.settings.fixed_compare_end_date!.includes(overwriteableSettings.fixed_compare_end_date!)
                    }

                    if (differentRelativeCompare || differentFixedCompareStart || differentFixedCompareEnd) {
                        let staticMatch = checkStaticValues(
                            new Date(overwriteableSettings?.fixed_compare_start_date!),
                            new Date(overwriteableSettings?.fixed_compare_end_date!),
                            intl,
                            false
                        )
                        if (staticMatch) {
                            changes['filters.compare-timeperiod'] = staticMatch.label
                        } else {
                            changes['filters.compare-timeperiod'] =
                                moment(overwriteableSettings?.fixed_compare_start_date).format('DD.MM.YYYY') +
                                ' - ' +
                                moment(overwriteableSettings?.fixed_compare_end_date).format('DD.MM.YYYY')
                        }
                    }
                    break
                case 'page-insights':
                    if (!!overwriteableSettings?.relative_time_period && !!initialValues.settings?.relative_time_period) {
                        differentRelative = overwriteableSettings?.relative_time_period !== initialValues.settings?.relative_time_period
                    } else {
                        differentFixedStart =
                            (!initialValues.settings.fixed_start_date && !!overwriteableSettings?.fixed_start_date) ||
                            (!!initialValues.settings.fixed_start_date && !overwriteableSettings?.fixed_start_date) ||
                            !initialValues.settings.fixed_start_date!.includes(overwriteableSettings.fixed_start_date!)
                        differentFixedEnd =
                            (!initialValues.settings.fixed_start_date && !!overwriteableSettings?.fixed_start_date) ||
                            (!!initialValues.settings.fixed_end_date && !overwriteableSettings?.fixed_end_date) ||
                            !initialValues.settings.fixed_end_date!.includes(overwriteableSettings.fixed_end_date!)
                    }

                    if (differentRelative || differentFixedStart || differentFixedEnd) {
                        let staticMatch = checkStaticValues(
                            new Date(overwriteableSettings?.fixed_start_date!),
                            new Date(overwriteableSettings?.fixed_end_date!),
                            intl,
                            false
                        )
                        if (staticMatch) {
                            changes['filters.timeperiod'] = staticMatch.label
                        } else {
                            changes['filters.timeperiod'] =
                                moment(overwriteableSettings?.fixed_start_date).format('DD.MM.YYYY') +
                                ' - ' +
                                moment(overwriteableSettings?.fixed_end_date).format('DD.MM.YYYY')
                        }
                    }
                    break
                case 'monthly-report':
                    if (
                        !!overwriteableSettings?.fixed_start_date &&
                        !!initialValues.settings?.fixed_start_date &&
                        overwriteableSettings?.fixed_start_date !== initialValues.settings?.fixed_start_date
                    ) {
                        changes['filters.timeperiod'] = moment(overwriteableSettings?.fixed_start_date).format('MMMM YYYY')
                    }

                    if (
                        !!overwriteableSettings?.fixed_compare_start_date &&
                        !!initialValues.settings?.fixed_compare_start_date &&
                        overwriteableSettings?.fixed_compare_start_date !== initialValues.settings?.fixed_compare_start_date
                    ) {
                        changes['filters.compare-timeperiod'] = moment(overwriteableSettings?.fixed_compare_start_date).format('MMMM YYYY')
                    }
                    break
                case 'post-report':
                    if (
                        !!overwriteableSettings?.fixed_start_date &&
                        !!initialValues.settings?.fixed_start_date &&
                        overwriteableSettings?.fixed_start_date !== initialValues.settings?.fixed_start_date
                    ) {
                        changes['filters.timeperiod'] = moment(overwriteableSettings?.fixed_start_date).format('MMMM YYYY')
                    }
                    if (
                        !!overwriteableSettings?.fixed_end_date &&
                        !!initialValues.settings?.fixed_end_date &&
                        overwriteableSettings?.fixed_end_date !== initialValues.settings?.fixed_end_date
                    ) {
                        changes['filters.timeperiod'] = moment(overwriteableSettings?.fixed_end_date).format('MMMM YYYY')
                    }
                    if (
                        !!overwriteableSettings?.relative_time_period &&
                        !!initialValues.settings?.relative_time_period &&
                        overwriteableSettings?.relative_time_period !== initialValues.settings?.relative_time_period
                    ) {
                        changes['filters.timeperiod'] = moment(overwriteableSettings?.relative_time_period).format('MMMM YYYY')
                    }
                    if (
                        !!overwriteableSettings?.tag_ids &&
                        !!initialValues.settings?.tag_ids &&
                        overwriteableSettings?.tag_ids !== initialValues.settings?.tag_ids
                    ) {
                        changes['filters.tag_ids'] = overwriteableSettings?.tag_ids
                    }
                    break
                default:
                    if (
                        !!overwriteableSettings?.fixed_start_date &&
                        !!initialValues.settings?.fixed_start_date &&
                        overwriteableSettings?.fixed_start_date !== initialValues.settings?.fixed_start_date
                    ) {
                        changes['filters.timeperiod'] = moment(overwriteableSettings?.fixed_start_date).format('MMMM YYYY')
                    }
                    if (
                        !!overwriteableSettings?.fixed_end_date &&
                        !!initialValues.settings?.fixed_end_date &&
                        overwriteableSettings?.fixed_end_date !== initialValues.settings?.fixed_end_date
                    ) {
                        changes['filters.timeperiod'] = moment(overwriteableSettings?.fixed_end_date).format('MMMM YYYY')
                    }
                    if (
                        !!overwriteableSettings?.relative_time_period &&
                        !!initialValues.settings?.relative_time_period &&
                        overwriteableSettings?.relative_time_period !== initialValues.settings?.relative_time_period
                    ) {
                        changes['filters.timeperiod'] = moment(overwriteableSettings?.relative_time_period).format('MMMM YYYY')
                    }
                    if (
                        !!overwriteableSettings?.tag_ids &&
                        !!initialValues.settings?.tag_ids &&
                        overwriteableSettings?.tag_ids !== initialValues.settings?.tag_ids
                    ) {
                        changes['filters.tag_ids'] = overwriteableSettings?.tag_ids
                    }
                    break
            }
        }

        return changes
    }

    const adoptChanges = () => {
        if (!!initialValues && !!overwriteableSettings) {
            settingsKey.current = Date.now()
            setSettings(overwriteableSettings)
            submit({ ...initialValues?.settings, ...overwriteableSettings }, false)
        }
    }

    const renderSettings = () => {
        if (template) {
            let changes = getCurrentChanges()
            return (
                <>
                    <Box>
                        <Grid container spacing={2}>
                            {!!overwriteableSettings && Object.keys(overwriteableSettings).length > 0 && Object.keys(changes).length > 0 && (
                                <Grid item xs={12}>
                                    <Alert
                                        icon={<Info />}
                                        variant={'standard'}
                                        color={'warning'}
                                        sx={{ whiteSpace: 'pre-line' }}
                                        action={
                                            <Button color="inherit" size="small" onClick={adoptChanges} sx={{ alignSelf: 'center' }}>
                                                <FormattedMessage id="general.adopt-changes" />
                                            </Button>
                                        }
                                    >
                                        <FormattedMessage id="general.filter-from-selection" />
                                        {Object.keys(changes).map((changeKey) => {
                                            return '\n' + intl.formatMessage({ id: changeKey }) + ': ' + changes[changeKey]
                                        })}
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    variant={'outlined'}
                                    id="dashboard-name-field"
                                    size={'medium'}
                                    InputLabelProps={{
                                        shrink: true,
                                        variant: 'outlined',
                                        size: 'small',
                                    }}
                                    value={name}
                                    inputProps={{ maxLength: 52 }}
                                    label={intl.formatMessage({ id: 'reporting.dashboards.name' })}
                                    fullWidth
                                    onChange={(e) => setName(e.target.value)}
                                    sx={{
                                        margin: 0,
                                        '&.MuiFormControl-root .MuiOutlinedInput-root': {
                                            height: '100%',
                                        },
                                    }}
                                />
                            </Grid>
                            <DashboardFormSettings
                                availableTags={availableTagsArr}
                                onTagChange={setSelectedTags}
                                selectedTagProp={selectedTags}
                                key={settingsKey.current}
                                childKey={settingsKey.current}
                                template={template}
                                settings={settings}
                                onChange={setSettings}
                                dynamic={showDynamic}
                                onDynamicChange={(_) => setShowDynamic(!showDynamic)}
                                datasources={selectedChannelIds}
                            />
                        </Grid>
                    </Box>
                </>
            )
        }
    }

    const renderCurrentStep = () => {
        switch (activeStep) {
            case 0:
                return (
                    <>
                        {renderFilterTemplate()}
                        {renderChooseTemplate()}
                    </>
                )
            case 1:
                return (
                    <>
                        {renderDatasourceHeader()}
                        {renderChooseDatasource()}
                    </>
                )
        }
        return (
            <>
                {renderNameHeader()}
                {renderSettings()}
            </>
        )
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0 }}>
            <DashboardStepper
                activeStep={activeStep}
                labels={['reporting.dashboards.choose-template', 'reporting.dashboards.choose-datasource', 'reporting.dashboards.set-parameters']}
                onStepChange={(newStep) => canChangeStepTo(newStep) && dispatch(setDashboardWizardStep(newStep))}
                sx={{ mb: 2 }}
            />

            <Box>{renderCurrentStep()}</Box>

            <Box sx={{ pt: 1, pb: 1, position: 'fixed', bottom: 0, backgroundColor: 'inherit', width: '100vw', left: 0 }} textAlign="right">
                <Container>
                    <Button
                        disabled={activeStep <= 0}
                        color={'primary'}
                        startIcon={<ChevronLeft />}
                        variant={'contained'}
                        onClick={() => goToStep(activeStep - 1)}
                    >
                        <FormattedMessage id="general.back" />
                    </Button>

                    <Button
                        style={{ marginLeft: '24px' }}
                        disabled={!canChangeStepTo(activeStep + 1)}
                        color={'secondary'}
                        endIcon={<ChevronRight />}
                        variant={'contained'}
                        onClick={() => goToStep(activeStep + 1)}
                    >
                        {activeStep >= 2 ? <FormattedMessage id="general.save" /> : <FormattedMessage id="general.continue" />}
                    </Button>
                </Container>
            </Box>
        </Box>
    )
}
