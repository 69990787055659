import * as _ from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccounts, getPaymentMethods, getProducts } from '../../core/slices/DataSlice'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Tooltip,
    Typography,
} from '@mui/material'
import { BillingSubscription, SubscriptionItem } from './BillingSubscription'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { AccessAlarm, Cancel, Check, DoDisturb, Edit, ExpandMore, Info, Warning } from '@mui/icons-material'
import { BillingPaymentMethodItem } from './BillingPaymentMethodItem'
import { BillingUpdatePaymentMethodDialog } from './BillingUpdatePaymentMethodDialog'
import { cancelBillingSubscription, continueBillingSubscription, fetchSubscriptionById, updatePaymentMethodForSubscription } from './BillingActions'
import { getBillingDataLoading, getSelectedAccount, isDarkMode, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { BillingCancelSubscriptionDialog } from './BillingCancelSubscriptionDialog'
import moment from 'moment'
import { ConfirmDialog } from '../../common/confirm-dialog/ConfirmDialog'
import { Loading } from '../../common/loading/Loading'
import { BillingCurrentPlanActionButton } from './BillingCurrentPlanActionButton'
import { BillingReactivateSubscriptionDialog } from './BillingReactivateSubscriptionDialog'
import { fetchAccount, updateAccountPackage } from '../accounts/AccountActions'
import { Account } from '../accounts/Account'
import { fetchCurrentUser } from '../user-management/UserActions'
import { AccountFormValues } from '../accounts/AccountFormValues'
import { getBaseProductForSubscription, getProductForSubscriptionItem, getQuantityForAdditionalFeature } from './BillingUtils'
import { getSubscriptionsForSelectedCustomer } from '../SettingsSlice'
import { useHasAccountPermission } from '../../core/hooks/useHasPermission'

type BillingCurrentPlansProps = {}

export const BillingCurrentPlans: FC<BillingCurrentPlansProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()

    const [openSubscriptionId, setOpenSubscriptionId] = useState<undefined | string>()
    const [openCancelSubscriptionId, setOpenCancelSubscriptionId] = useState<undefined | string>()
    const [openContinueSubscriptionId, setOpenContinueSubscriptionId] = useState<undefined | string>()
    const [openReactivateSubscriptionId, setOpenReactivateSubscriptionId] = useState<undefined | string>()

    const isBillingDataLoading = useSelector(getBillingDataLoading)
    const accounts = useSelector(getAccounts)
    const subscriptions = useSelector(getSubscriptionsForSelectedCustomer)
    const products = useSelector(getProducts)
    const paymentMethods = useSelector(getPaymentMethods)
    const accountsBySubscriptionId = _.mapKeys(accounts, 'subscription_id')
    const darkmode = useSelector(isDarkMode)

    const account = useSelector(getSelectedAccount)
    const canManageBilling = useHasAccountPermission('account.manage_billing', account.id)

    const calculatePriceForSubscription = (subscription: BillingSubscription) => {
        return _.sumBy(subscription.items.data, (item) => {
            return (item.price.unit_amount * item.quantity) / 100
        })
    }
    const handleSave = (subscription: BillingSubscription, newPaymentMethodId: string) => {
        dispatch(
            updatePaymentMethodForSubscription({
                billingSubscription: subscription,
                paymentMethodId: newPaymentMethodId,
            })
        ).then(() => {
            dispatch(showSuccessSnackbar('Zahlungsmethode wurde aktualisiert.'))
            setOpenSubscriptionId(undefined)
        })
    }

    const isSubscriptionStillActive = (subscription: BillingSubscription) => {
        const validSubscriptionIds = Object.keys(accountsBySubscriptionId)
        return validSubscriptionIds.includes(subscription.id)
    }

    const getColorForSubscriptionState = (subscriptionState: string) => {
        switch (subscriptionState) {
            case 'active':
                return 'success'
            case 'canceled':
            case 'incomplete_expired':
                return 'error'
            case 'open':
            case 'incomplete':
            case 'unpaid':
                return 'info'
            case 'trialing':
                return 'warning'
            default:
                return undefined
        }
    }

    const getIconForSubscriptionState = (subscriptionState: string) => {
        switch (subscriptionState) {
            case 'active':
                return <Check />
            case 'canceled':
                return <DoDisturb />
            case 'open':
            case 'incomplete_expired':
                return <Warning />
            case 'incomplete':
            case 'unpaid':
                return <Info />
            case 'trialing':
                return <AccessAlarm />
            default:
                return undefined
        }
    }

    const renderSubscriptionState = (subscription: BillingSubscription) => {
        const expired = subscription.status === 'incomplete_expired'

        if (!expired && subscription.cancel_at_period_end) {
            return (
                <Chip
                    size="small"
                    sx={{ pl: 1, pr: 1 }}
                    icon={<Cancel />}
                    color={getColorForSubscriptionState('canceled')}
                    label={
                        <>
                            <FormattedMessage id="billing.subscription.status.canceled" /> zum {moment.unix(subscription.cancel_at).format('L')}
                        </>
                    }
                />
            )
        }

        return (
            <Tooltip placement="top" title={<FormattedMessage id={`billing.subscription.status.${subscription.status}.tooltip`} />}>
                <Chip
                    size="small"
                    sx={{ pl: 1, pr: 1 }}
                    icon={getIconForSubscriptionState(subscription.status)}
                    color={getColorForSubscriptionState(subscription.status)}
                    label={<FormattedMessage id={`billing.subscription.status.${subscription.status}`} />}
                />
            </Tooltip>
        )
    }

    const handleSubscriptionCancel = (subscription: BillingSubscription) => {
        dispatch(cancelBillingSubscription(subscription)).then((action: any) => {
            setOpenCancelSubscriptionId(undefined)
            dispatch(
                showSuccessSnackbar(
                    intl.formatMessage(
                        { id: 'billing.subscription.cancelled-success' },
                        {
                            date: moment.unix((action.payload as BillingSubscription).cancel_at).format('L'),
                        }
                    )
                )
            )
        })
    }

    const handleSubscriptionContinue = (subscription: BillingSubscription) => {
        dispatch(continueBillingSubscription(subscription)).then(() => {
            setOpenContinueSubscriptionId(undefined)
            dispatch(showSuccessSnackbar('billing.subscription.confirm-success'))
        })
    }

    const handleSubscriptionReactive = (subscription: BillingSubscription) => {
        const accountForSubscription = accountsBySubscriptionId[subscription.id] as Account

        dispatch(
            updateAccountPackage({
                values: {
                    package_type: accountForSubscription.package_type,
                    interval: subscription.items.data[0].plan.interval,
                    additional_user: getQuantityForAdditionalFeature(subscription, products, 'ADDITIONAL_USER'),
                    additional_datasource: getQuantityForAdditionalFeature(subscription, products, 'ADDITIONAL_DATASOURCE'),
                    conversion_tracking: getQuantityForAdditionalFeature(subscription, products, 'CONVERSION_TRACKING'),
                    name: accountForSubscription.name,
                } as AccountFormValues,
                account: accountForSubscription,
            })
        ).then((action: any) => {
            dispatch(fetchCurrentUser())
            dispatch(fetchAccount(accountForSubscription._links.self))
            dispatch(fetchSubscriptionById(action.payload)).then(() => {
                setOpenReactivateSubscriptionId(undefined)
                dispatch(showSuccessSnackbar('billing.subscription.reactivate-success'))
            })
        })
    }

    if (isBillingDataLoading && Object.values(subscriptions).length === 0) {
        return <Loading />
    }

    return (
        <>
            {Object.values(subscriptions)
                .filter(isSubscriptionStillActive)
                .map((subscription) => {
                    const start = moment.unix(subscription.current_period_start)
                    const end = moment.unix(subscription.current_period_end)
                    let discountActive = false
                    let discountAmount = 0
                    let discountEnd = undefined
                    if (subscription.discount) {
                        const discountStart = moment.unix(subscription.discount.start)
                        discountEnd = subscription.discount.end ? moment.unix(subscription.discount.end) : undefined
                        discountActive = discountStart.isSameOrBefore(end) && (!discountEnd || discountEnd.isSameOrAfter(start))
                        discountAmount = _.get(subscription, 'discount.coupon.percent_off') || 0
                    }
                    const paymentMethod = paymentMethods[subscription.default_payment_method || '']
                    const paymentInterval = subscription.items.data[0].price.recurring.interval

                    const renderEditPaymentMethodButton = () => (
                        <ListItemSecondaryAction>
                            <Button
                                startIcon={<Edit />}
                                variant="contained"
                                color={darkmode ? 'secondary' : 'inherit'}
                                size="small"
                                onClick={() => setOpenSubscriptionId(subscription.id)}
                            >
                                <FormattedMessage id="billing.payment-methods.edit" />
                            </Button>
                        </ListItemSecondaryAction>
                    )

                    const subscriptionTotalPrice = calculatePriceForSubscription(subscription)

                    return (
                        <Accordion key={subscription.id} defaultExpanded={Object.values(subscriptions).length <= 1}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                    <ListItem>
                                        <ListItemText
                                            primary={`${subscription.metadata.account_name}`}
                                            secondary={getBaseProductForSubscription(subscription, products)?.name}
                                        />

                                        {renderSubscriptionState(subscription)}

                                        {subscriptionTotalPrice > 0 && (
                                            <Chip
                                                sx={{ ml: 1, pl: 1, pr: 1 }}
                                                size="small"
                                                label={<FormattedMessage id={`billing.subscription.interval.${paymentInterval}`} />}
                                            />
                                        )}
                                    </ListItem>
                                    {subscriptionTotalPrice > 0 && (
                                        <Box padding={2} whiteSpace="nowrap">
                                            <FormattedNumber value={subscriptionTotalPrice} minimumFractionDigits={2} />
                                            &nbsp;€
                                        </Box>
                                    )}
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails sx={{ borderTop: 1, borderColor: 'divider' }}>
                                <List>
                                    {subscription.items.data
                                        .filter((item) => getProductForSubscriptionItem(item, products))
                                        .map((item: SubscriptionItem) => {
                                            return (
                                                <ListItem key={item.id}>
                                                    <ListItemIcon>{item.quantity} x</ListItemIcon>
                                                    <ListItemText>
                                                        {getProductForSubscriptionItem(item, products)?.name}
                                                        &nbsp; (
                                                        <FormattedNumber value={item.price.unit_amount / 100} minimumFractionDigits={2} />
                                                        &nbsp;€)
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <FormattedNumber value={(item.price.unit_amount / 100) * item.quantity} minimumFractionDigits={2} />
                                                        &nbsp;€
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        })}

                                    {discountActive && discountAmount > 0 && (
                                        <ListItem>
                                            <ListItemIcon>1 x</ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        <FormattedMessage id="billing.promotion-code" />
                                                        &nbsp;-&nbsp;
                                                        {discountAmount}%
                                                    </>
                                                }
                                                secondary={
                                                    <FormattedMessage
                                                        id="billing.valid-until"
                                                        values={{
                                                            endDate: discountEnd ? (
                                                                discountEnd.format('MMMM YYYY')
                                                            ) : (
                                                                <FormattedMessage id="billing.unlimited-discount" />
                                                            ),
                                                        }}
                                                    />
                                                }
                                            />

                                            <ListItemSecondaryAction>
                                                <FormattedNumber value={subscriptionTotalPrice * (discountAmount / 100) * -1} minimumFractionDigits={2} />
                                                &nbsp;€
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                    {!['canceled', 'incomplete_expired'].includes(subscription.status) && (
                                        <>
                                            <ListSubheader>
                                                <FormattedMessage id="accounts.payment-method" />
                                            </ListSubheader>
                                            {paymentMethod ? (
                                                <BillingPaymentMethodItem paymentMethod={paymentMethod}>
                                                    {renderEditPaymentMethodButton()}
                                                </BillingPaymentMethodItem>
                                            ) : (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Info />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Default"
                                                        secondary={intl.formatMessage({
                                                            id: 'billing.payment-methods.automatic.info',
                                                        })}
                                                    />
                                                    {renderEditPaymentMethodButton()}
                                                </ListItem>
                                            )}
                                        </>
                                    )}
                                    <ListItem sx={{ mt: 2 }}>
                                        <ListItemSecondaryAction>
                                            <BillingCurrentPlanActionButton
                                                subscription={subscription}
                                                onContiue={() => setOpenContinueSubscriptionId(subscription.id)}
                                                onCancel={() => setOpenCancelSubscriptionId(subscription.id)}
                                                onReactive={() => setOpenReactivateSubscriptionId(subscription.id)}
                                                disabled={!canManageBilling}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                            {openSubscriptionId === subscription.id && (
                                <BillingUpdatePaymentMethodDialog
                                    open={openSubscriptionId === subscription.id}
                                    onClose={() => setOpenSubscriptionId(undefined)}
                                    subscription={subscription}
                                    account={accountsBySubscriptionId[openSubscriptionId || '']}
                                    onSave={(newPaymentMethodId) => handleSave(subscription, newPaymentMethodId)}
                                />
                            )}
                            {openCancelSubscriptionId === subscription.id && (
                                <BillingCancelSubscriptionDialog
                                    open={openCancelSubscriptionId === subscription.id}
                                    subscription={subscription}
                                    onClose={() => setOpenCancelSubscriptionId(undefined)}
                                    onSave={() => handleSubscriptionCancel(subscription)}
                                />
                            )}
                            {openReactivateSubscriptionId === subscription.id && (
                                <BillingReactivateSubscriptionDialog
                                    open={openReactivateSubscriptionId === subscription.id}
                                    onClose={() => setOpenReactivateSubscriptionId(undefined)}
                                    onSave={() => handleSubscriptionReactive(subscription)}
                                    subscription={subscription}
                                />
                            )}
                            {openContinueSubscriptionId === subscription.id && (
                                <ConfirmDialog
                                    open={openContinueSubscriptionId === subscription.id}
                                    onClose={() => setOpenContinueSubscriptionId(undefined)}
                                    onConfirm={() => handleSubscriptionContinue(subscription)}
                                    confirmText="billing.subscription.confirm"
                                >
                                    <Typography>
                                        <FormattedMessage id="billing.subscription.continue-confirm" />
                                    </Typography>
                                </ConfirmDialog>
                            )}
                        </Accordion>
                    )
                })}
        </>
    )
}
