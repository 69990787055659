import { FC } from 'react'
import { Box, Container } from '@mui/material'
import { DashboardForm } from './DashboardForm'
import { Dashboard } from './Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { closeFullscreenDialog, getSelectedProject } from '../core/slices/CoreSlice'
import { editDashboard } from './DashboardsActions'
import { getSelectedDashboard } from './DashboardsSlice'
import { FORM_ERROR } from 'final-form'
import { HmstrDispatch } from '../core/Store'

type DashboardEditProps = {
    overwriteSettings?: Partial<Dashboard['settings']>
}

export const DashboardEdit: FC<DashboardEditProps> = ({ overwriteSettings }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedDashboard = useSelector(getSelectedDashboard)
    const project = useSelector(getSelectedProject)

    const handleSubmit = (dashboard: Dashboard, closeDialogAfterSave: boolean = true) => {
        const dashboardRequest = {
            ...dashboard,
            data_source_ids: dashboard.data_source_ids.filter((id) => Boolean(id)),
        } as Dashboard

        if (dashboardRequest.data_source_ids.length <= 0) {
            return {
                [FORM_ERROR]: 'reporting.dashboards.no-datasources-selected',
            }
        }

        if (project) {
            dispatch(editDashboard({ project, dashboard: dashboardRequest }))
            if (closeDialogAfterSave) {
                dispatch(closeFullscreenDialog())
            }
        }
    }

    return (
        <Container maxWidth="lg" style={{ height: '100%' }}>
            <Box paddingTop={2} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {project && selectedDashboard && (
                    <DashboardForm project={project} onSubmit={handleSubmit} initialValues={selectedDashboard} overwriteableSettings={overwriteSettings} />
                )}
            </Box>
        </Container>
    )
}
