import * as React from 'react'
import { FC } from 'react'
import { Box, keyframes, useTheme } from '@mui/material'

type ProgressHideBarProps = { duration: number }

export const ProgressHideBar: FC<ProgressHideBarProps> = ({ duration }) => {
    const theme = useTheme()
    const animation = keyframes`
        0% {
            width: 0;
        }
        100% {
            width: 100%
        }
    `

    return (
        <>
            <Box
                sx={{
                    background: theme.palette.primary.contrastText,
                    width: '100%',
                    height: '2px',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    animation: `${animation} ${duration / 1000}s 1 linear`,
                    borderBottomLeftRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius,
                }}
            />
        </>
    )
}
