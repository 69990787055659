import { FC } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { getResultDatasources } from './ResultsSlice'
import { useSelector } from 'react-redux'

type ResultDatasourceFilterProps = {
    open: boolean
    onOpen: () => void
    onClose: () => void
    onChange: (value: string[]) => void
    fullWidth?: boolean
    value: string[]
}

export const ResultDatasourceFilter: FC<ResultDatasourceFilterProps> = ({ open, onOpen, onClose, onChange, fullWidth, value }) => {
    const intl = useIntl()
    const datasources = useSelector(getResultDatasources)

    const handleChange = (event: SelectChangeEvent<any>) => {
        onChange(event.target.value)
    }

    const renderValue = () => {
        return value.length === 1
            ? datasources.find((project) => project.id === value[0])?.name
            : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: value.length })
    }

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel shrink={true} variant={'outlined'}>
                <FormattedMessage id="filters.datasource" />
            </InputLabel>
            <Select
                fullWidth={fullWidth}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                notched
                variant={'outlined'}
                value={value}
                label={intl.formatMessage({ id: 'filters.datasource' })}
                onChange={handleChange}
                renderValue={renderValue}
                MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'right' },
                }}
                displayEmpty
                multiple
            >
                {datasources.map((ds) => (
                    <MenuItem key={ds.id} value={ds.id} dense>
                        <Checkbox size="small" checked={value.indexOf(ds.id) > -1} />
                        <ListItemText primary={ds.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
