import { FC } from 'react'
import { Box, Grid } from '@mui/material'
import { ConnectedTextField } from '../../../form/ConnectedTextField'
import { ConnectedAutocomplete } from '../../../form/ConnectedAutocomplete'
import { addDatasourceTag, getDatasourceTags } from '../../SettingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedColorpicker } from '../../../form/ConnectedColorPicker'
import { DatasourceFederatedIdentitySection } from '../DatasourceFederatedIdentitySection'
import { DatasourceWebhooksField } from '../DatasourceWebhooksField'

type InstagramDatasourceProps = { disabled?: boolean }

export const InstagramDatasource: FC<InstagramDatasourceProps> = ({ disabled }) => {
    const dispatch = useDispatch()
    const datasourceTags = useSelector(getDatasourceTags)

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
                <ConnectedTextField name="name" shrinkLabel={true} label="general.name" readonly fullWidth />
            </Grid>
            <Grid item xs={4}>
                <ConnectedTextField name="metadata.username" shrinkLabel={true} label="general.username" readonly fullWidth />
            </Grid>
            <Grid item xs={4}>
                <ConnectedTextField shrinkLabel={true} name="metadata.id" label="InstragramBusinessAccount ID" maxLength={400} fullWidth readonly />
            </Grid>
            <Grid item xs={11}>
                <ConnectedAutocomplete
                    name="tags"
                    shrinkLabel={true}
                    label="general.tags"
                    options={datasourceTags}
                    disabled={disabled}
                    onAddOption={(o) => dispatch(addDatasourceTag(o))}
                />
            </Grid>
            <Grid item xs={1}>
                <Box display="flex" justifyContent="flex-end">
                    <ConnectedColorpicker disabled={disabled} name="color" label="general.color" fullWidth />
                </Box>
            </Grid>
            <DatasourceFederatedIdentitySection />
            <DatasourceWebhooksField />
        </Grid>
    )
}
