import * as React from 'react'
import { FC, memo } from 'react'
import { Box, keyframes } from '@mui/material'
import { SparklesLight } from './Sparkles'
import _ from 'lodash'

type SparkleBackgroundProps = {
    colors: string[]
    sparkleKey: string | number
    maxWidth: number
    maxHeight: number
    particleAmount: number
}

const checkPropsEqual = (oldProps: SparkleBackgroundProps, newProps: SparkleBackgroundProps) => {
    return _.isEqual(oldProps, newProps)
}

export const SparkleBackgroundMemoized: FC<SparkleBackgroundProps> = memo(({ colors, sparkleKey, maxWidth, maxHeight, particleAmount }) => {
    const fadeIn = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `
    return (
        <Box
            className={'particleContainer'}
            key={sparkleKey}
            sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '100%',
                maxWidth: `${maxWidth}px`,
                height: '100%',
                maxHeight: `${maxHeight}px`,
                opacity: 0,
                animation: `${fadeIn} 0.5s 0s 1`,
                animationFillMode: 'forwards',
                maskImage: 'radial-gradient(100% 100% at bottom right, #fff 90%, transparent)',
            }}
        >
            <SparklesLight
                id={`tsparticlesfullpage-${sparkleKey}`}
                background={'transparent'}
                minSize={1.4}
                maxSize={2}
                opacitySpeed={1}
                maxMoveSpeed={0.5}
                particleAmount={particleAmount}
                maxOpacity={0.6}
                minOpacity={0.1}
                particleColor={colors}
                containerHeight={maxHeight}
                containerWidth={maxWidth}
            />
        </Box>
    )
}, checkPropsEqual)

export const SparkleBackground: FC<SparkleBackgroundProps> = (props) => <SparkleBackgroundMemoized {...props} />
