import * as React from 'react'
import { FC } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { FacebookLike } from '../../../common/icons/FacebookLike'
import { FacebookComment } from '../../../common/icons/FacebookComment'
import { FacebookShare } from '../../../common/icons/FacebookShare'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../../core/slices/CoreSlice'

type FacebookPostActionsPreviewProps = {}

export const FacebookPostActionsPreview: FC<FacebookPostActionsPreviewProps> = () => {
    const darkmode = useSelector(isDarkMode)
    const theme = useTheme()
    const actionColor = darkmode ? theme.palette.primary.contrastText : '#606770'

    return (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <Box display="flex" alignItems="center">
                    <FacebookLike fontSize="small" htmlColor={actionColor} />
                    <Typography fontSize="small" fontWeight={600} sx={{ ml: 1, color: { actionColor }, pt: 0.25 }}>
                        Like
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Box display="flex" alignItems="center">
                    <FacebookComment fontSize="small" htmlColor={actionColor} />
                    <Typography fontSize="small" fontWeight={600} sx={{ ml: 1, color: { actionColor }, pt: 0.25 }}>
                        Comment
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Box display="flex" alignItems="center">
                    <FacebookShare fontSize="small" htmlColor={actionColor} />
                    <Typography fontSize="small" fontWeight={600} sx={{ ml: 1, color: { actionColor }, pt: 0.25 }}>
                        Share
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}
