import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedAccount, getSelectedProjectId, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { BillingInfoForm } from './BillingInfoForm'
import { Navigate, NavLink, Route, Routes, useLocation } from 'react-router-dom'
import { Box, Container, Tab, Tabs, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { HmstrDispatch } from '../../core/Store'
import { TabPanel } from '../../common/tab-panel/TabPanel'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { BillingCurrentPlans } from './BillingCurrentPlans'
import { BillingPaymentMethods } from './BillingPaymentMethods'
import { fetchInvoices, fetchPaymentMethods, fetchProducts, fetchSubscriptionsForUser } from './BillingActions'
import { BillingInvoices } from './BillingInvoices'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { updateAccountBillingInfo } from '../accounts/AccountActions'
import { BillingInfo } from '../accounts/Account'
import { useHasAccountPermission } from '../../core/hooks/useHasPermission'

type BillingDialogProps = {}

export const Billing: FC<BillingDialogProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const account = useSelector(getSelectedAccount)
    const selectedProjectId = useSelector(getSelectedProjectId)
    const location = useLocation()
    const navigate = useProjectNavigate()

    const canReadBilling = useHasAccountPermission('account.read_billing', account.id)

    useEffectWithIdComparison(() => {
        dispatch(fetchProducts())
        dispatch(fetchPaymentMethods(account))
        dispatch(fetchSubscriptionsForUser())
        dispatch(fetchInvoices(account))
    }, [account])

    const handleSubmit = (billingInfo: BillingInfo) => {
        return new Promise((resolve) => {
            dispatch(updateAccountBillingInfo({ billingInfo, account })).then(() => {
                dispatch(showSuccessSnackbar('billing.update-successful'))
                resolve(true)
            })
        })
    }

    const getValueFromPath = () => {
        if (location.pathname.includes('general')) {
            return 0
        } else if (location.pathname.includes('accounts')) {
            return 1
        } else if (location.pathname.includes('payment-methods')) {
            return 2
        } else if (location.pathname.includes('history')) {
            return 3
        } else {
            return 0
        }
    }

    return (
        <Container maxWidth="lg">
            <Box marginTop={2}>
                <Box marginBottom={4} display="flex" alignItems="flex-end" gap={1}>
                    <Typography variant="h5">
                        <FormattedMessage id="general.billing" />
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary">
                        (<FormattedMessage id="billing.customer-number" />: {account.customer_id})
                    </Typography>
                </Box>
            </Box>

            {canReadBilling ? (
                <>
                    <Tabs textColor="inherit" indicatorColor="secondary" variant="scrollable" scrollButtons="auto" value={getValueFromPath()}>
                        <Tab
                            component={NavLink}
                            to={`/${selectedProjectId}/settings/billing/general`}
                            label={<FormattedMessage id="billing.tab.billing-info" />}
                        />
                        <Tab
                            component={NavLink}
                            to={`/${selectedProjectId}/settings/billing/accounts`}
                            label={<FormattedMessage id="billing.tab.current-subscriptions" />}
                        />
                        <Tab onClick={() => navigate('/settings/billing/payment-methods')} label={<FormattedMessage id="billing.tab.payment-methods" />} />
                        <Tab
                            component={NavLink}
                            to={`/${selectedProjectId}/settings/billing/history`}
                            label={<FormattedMessage id="billing.tab.billing-history" />}
                        />
                    </Tabs>

                    <Box mt={1} pb={4}>
                        <TabPanel value={0} index={0}>
                            <Routes>
                                <Route path="" element={<Navigate to="general" replace />} />
                                <Route path="general" element={<BillingInfoForm onSubmit={handleSubmit} initialValues={account} inWizard={false} />} />
                                <Route path="accounts" element={<BillingCurrentPlans />} />
                                <Route path="payment-methods" element={<BillingPaymentMethods />} />
                                <Route path="history" element={<BillingInvoices />} />
                            </Routes>
                        </TabPanel>
                    </Box>
                </>
            ) : (
                <Box textAlign="center" mt={16}>
                    <Typography variant="subtitle1" fontWeight={600}>
                        <FormattedMessage id="billing.change.only-owner" />
                    </Typography>
                </Box>
            )}
        </Container>
    )
}
