import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type FacebookShareProps = SvgIconProps

export const FacebookShare: FC<FacebookShareProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 20">
                <g id="FB-share">
                    <path
                        id="Path-6"
                        style={{ fill: 'none', stroke: props.htmlColor }}
                        d="M2,17.9c0.2-4,1.1-6.7,2.6-8.2c1.5-1.5,3.5-2.2,5.9-2.3V3.3c0-0.2,0.1-0.3,0.3-0.3l0,0
		c0.2,0,0.4,0.1,0.5,0.2l6.2,6.4c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0l-6.4,6.1c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
		c0,0,0-0.1,0-0.1v-4.3c-1.7-0.1-3.2,0.2-4.4,1S3.5,16.1,2.2,18H2.1C2.1,18,2,17.9,2,17.9C2,17.9,2,17.9,2,17.9L2,17.9z"
                    />
                    <rect id="Rectangle-Copy-2" x="0.5" y="0.5" style={{ fill: 'none', stroke: props.htmlColor, strokeOpacity: 0 }} width="19" height="19" />
                </g>
            </svg>
        </SvgIcon>
    )
}
