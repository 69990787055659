import { FC } from 'react'
import { ImageListItem, Skeleton } from '@mui/material'

type PublishingLoadingThumbnailProps = {}

export const PublishingLoadingThumbnail: FC<PublishingLoadingThumbnailProps> = () => {
    return (
        <ImageListItem
            sx={{
                position: 'relative',
                overflow: 'hidden',
            }}
            rows={1}
            cols={1}
        >
            <Skeleton animation="wave" height="100%" sx={{ transform: 'none' }} />
        </ImageListItem>
    )
}
