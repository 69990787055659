import { FC } from 'react'
import { Box, Grid, Paper, Tooltip as MuiTooltip, Typography } from '@mui/material'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Area, Bar, CartesianGrid, Cell, ComposedChart as RComposedChart, LabelList, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ChartTooltip } from '../../common/chart-tooltip/ChartTooltip'
import { AreaChartData } from '../../common/chart-data-types/AreaChartData'
import { Info, Square } from '@mui/icons-material'
import { DownloadGraphButton } from '../../common/download-graph-button/DownloadGraphButton'
import { useSelector } from 'react-redux'
import { getImpairedVision, isDarkMode } from '../../core/slices/CoreSlice'
import { calculateOptimalColor } from '../../common/accessibility/ContrastChecker'
import { getTags } from '../../core/slices/DataSlice'
import _ from 'lodash'
import { TaggingChartTooltip } from '../../common/chart-tooltip/TaggingChartTooltip'
import { NoData } from '../../common/chart-tooltip/NoData'
import { DashboardWidgetData } from '../DashboardData'
import { formatDateRangeDefaults, getTimePeriodWithPrefix } from '../../core/theme/helper'

type FacebookComposedChartProps = {
    title: string
    tooltip?: JSX.Element
    total?: number
    data: AreaChartData
    schema: {
        key: string
        dataSets: {
            color: string
            dataKey: string
            label: string | JSX.Element
        }[]
    }
    withoutLegend?: boolean
    height?: number
    totalArea?: boolean
    totalLabel?: boolean
    taggingOverview?: boolean
    unit?: string
    fullTicks?: boolean
    metadata?: DashboardWidgetData['metadata']
}

export const ComposedChart: FC<FacebookComposedChartProps> = (props) => {
    const { data, total, schema, title, withoutLegend, height, tooltip, totalArea, taggingOverview, unit, fullTicks, metadata } = props
    const { key, dataSets } = schema

    const RotatedTick = (props: any) => {
        const { x, y, payload } = props

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={12} textAnchor="end" fill="#666" transform="rotate(-45)">
                    {formatTagName(payload.value || '0')}
                </text>
            </g>
        )
    }

    const darkMode = useSelector(isDarkMode)
    const visionImpaired = useSelector(getImpairedVision)
    const allTags = useSelector(getTags)

    const formatBarLabel = (value: number) => {
        return value > 0 ? value : ''
    }

    const intl = useIntl()
    const randomId = '_' + Math.random().toString(36).substr(2, 9)

    const formatTagName = (tag_id: string) => {
        let tagName = ''
        _.map(allTags, (tag) => {
            if (tag.id === tag_id) {
                tagName = tag.label
            }
        })
        if (tag_id === 'UNTAGGED') {
            tagName = intl.formatMessage({ id: 'tags.untagged-posts' })
        }
        if (tagName === '') {
            tagName = 'undefined'
        }
        return tagName
    }

    const CustomCursor = (props: any) => {
        const { points, width, height, items } = props
        const { x, y } = points[0]
        return <Rectangle fill={'url(#totalGradient)'} x={x} y={y} width={width / items} height={height} />
    }

    return (
        <Paper id={randomId} sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
            <DownloadGraphButton tileId={randomId} parentRelativeFlex />
            <Box padding={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box paddingLeft={1} display="flex">
                    <Typography>
                        <strong>
                            <FormattedMessage id={title} />
                        </strong>
                    </Typography>
                    {tooltip && (
                        <MuiTooltip title={tooltip}>
                            <Info fontSize="small" sx={{ ml: 1, mt: 0.25 }} />
                        </MuiTooltip>
                    )}
                    <Typography color="textSecondary">
                        {total && (
                            <>
                                &nbsp; (<FormattedNumber value={total} />)
                            </>
                        )}
                    </Typography>
                </Box>
            </Box>

            <Box
                width="100%"
                padding={1}
                height={height ? (taggingOverview ? height + 120 : height) : taggingOverview ? 330 : 210}
                paddingRight={9}
                sx={{ flexGrow: 1 }}
            >
                {data.length > 0 ? (
                    <ResponsiveContainer width="100%" height="100%" debounce={50}>
                        <RComposedChart data={data} height={height ? (taggingOverview ? height + 120 : height) : taggingOverview ? 330 : 210} maxBarSize={80}>
                            {totalArea && (
                                <>
                                    <defs>
                                        {dataSets.map((ds) => (
                                            <linearGradient key={ds.dataKey} id={ds.dataKey} x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="50%" stopColor={ds.color} stopOpacity={1} />
                                                <stop offset="100%" stopColor={ds.color} stopOpacity={0} />
                                            </linearGradient>
                                        ))}
                                    </defs>
                                    <defs>
                                        <linearGradient key={'totalGradient'} id={'totalGradient'} x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor={'#cccccc'} stopOpacity={1} />
                                            <stop offset="50%" stopColor={'#cccccc'} stopOpacity={0.2} />
                                            <stop offset="100%" stopColor={'#cccccc'} stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                </>
                            )}
                            <CartesianGrid strokeDasharray="1 1" />
                            <XAxis
                                tickFormatter={(value) => {
                                    return metadata?.date_format ? getTimePeriodWithPrefix(value, metadata.date_format, intl) : formatDateRangeDefaults(value)
                                }}
                                dataKey={key}
                                tickMargin={8}
                                tickLine={false}
                                minTickGap={50}
                                height={taggingOverview ? 120 : undefined}
                                scale={taggingOverview ? 'auto' : 'band'}
                                interval={taggingOverview ? 0 : undefined}
                                tick={taggingOverview ? <RotatedTick /> : { fill: darkMode ? 'white' : 'rgb(102, 102, 102)' }}
                            />
                            <YAxis
                                tick={{ fill: darkMode ? 'white' : 'rgb(102, 102, 102)' }}
                                unit={unit}
                                allowDecimals={!fullTicks}
                                domain={[
                                    0,
                                    (dataMax: number) => {
                                        const dataMaxBeforeCommaLength = dataMax.toString().split('.')[0].length - 1
                                        return isNaN(dataMax)
                                            ? 0
                                            : Math.ceil(dataMax / Math.pow(10, dataMaxBeforeCommaLength)) * Math.pow(10, dataMaxBeforeCommaLength)
                                    },
                                ]}
                            />
                            <Tooltip
                                wrapperStyle={{ outline: 'none' }}
                                content={({ payload, label, active }) =>
                                    taggingOverview ? (
                                        <TaggingChartTooltip active={active} payload={payload} label={formatTagName(label)} schema={dataSets} unit={unit} />
                                    ) : (
                                        <ChartTooltip
                                            active={active}
                                            payload={payload}
                                            label={label}
                                            dataSets={dataSets}
                                            dateFormatForArea={metadata?.date_format}
                                        />
                                    )
                                }
                                cursor={taggingOverview ? false : <CustomCursor items={data.length} />}
                            />
                            {totalArea && (
                                <Area
                                    type="monotone"
                                    legendType={'none'}
                                    name={intl.formatMessage({ id: 'facebook.post-count' })}
                                    dataKey={'total'}
                                    fill={'#aaaaaa'}
                                    stroke={'#aaaaaa'}
                                    fillOpacity={0.3}
                                    strokeWidth={2}
                                />
                            )}
                            {taggingOverview ? (
                                <Bar
                                    dataKey={Object.keys(data[0])[1]}
                                    key={Object.keys(data[0])[1]}
                                    barSize={taggingOverview ? undefined : 20}
                                    stackId={1}
                                    fill="#000000"
                                >
                                    {dataSets.map((ds) => (
                                        <Cell key={ds.dataKey} fill={calculateOptimalColor(visionImpaired, ds.color, darkMode)}>
                                            <LabelList
                                                fill={darkMode ? '#ffffff' : '#000000'}
                                                dataKey={'total'}
                                                formatter={formatBarLabel}
                                                position="top"
                                                offset={8}
                                            />
                                        </Cell>
                                    ))}
                                </Bar>
                            ) : (
                                dataSets.map((ds) => (
                                    <Bar
                                        dataKey={ds.dataKey}
                                        key={ds.dataKey}
                                        barSize={taggingOverview ? undefined : 20}
                                        stackId={ds.dataKey}
                                        fill={calculateOptimalColor(visionImpaired, ds.color, darkMode)}
                                    >
                                        <Cell fill={calculateOptimalColor(visionImpaired, ds.color, darkMode)}>
                                            <LabelList
                                                fill={darkMode ? '#ffffff' : '#000000'}
                                                dataKey={'total'}
                                                formatter={formatBarLabel}
                                                position="top"
                                                offset={8}
                                            />
                                        </Cell>
                                    </Bar>
                                ))
                            )}
                        </RComposedChart>
                    </ResponsiveContainer>
                ) : (
                    <NoData />
                )}
            </Box>
            {withoutLegend !== true && (
                <Box pb={2}>
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        {dataSets.map((ds) => (
                            <Grid
                                key={ds.dataKey}
                                item
                                sx={{
                                    color: calculateOptimalColor(visionImpaired, ds.color, darkMode),
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Square fontSize="small" />
                                &nbsp;{intl.formatMessage({ id: ds.label.toString() })}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Paper>
    )
}
