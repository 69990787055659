import * as React from 'react'
import { FC, useRef, useState } from 'react'
import moment, { Moment } from 'moment/moment'
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import { styled } from '@mui/material'

type DatePickerWithWeekSelectionProps = {
    weekView?: boolean
    selectedStartDate: string
    handleChange: (date: Moment | null) => any
}

interface CustomPickerDayProps extends PickersDayProps<Moment> {
    isSelected: boolean
    isHovered: boolean
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(isHovered && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],
        },
    }),
    ...(day.weekday() === 0 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(day.weekday() === 6 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
})) as React.ComponentType<CustomPickerDayProps>

const isInSameWeek = (dayA: Moment, dayB: Moment | null | undefined) => {
    if (dayB == null) {
        return false
    }

    return dayA.isSame(dayB, 'week')
}

const renderDayIfSelected = (
    props: PickersDayProps<Moment> & {
        selectedDay?: Moment | null
        hoveredDay?: Moment | null
    }
) => {
    const { day, selectedDay, hoveredDay, ...other } = props
    return (
        <CustomPickersDay
            className={'weekDay'}
            {...other}
            day={day}
            sx={{ px: 2.5, transition: 'all 0.4s' }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    )
}

export const DatePickerWithWeekSelection: FC<DatePickerWithWeekSelectionProps> = ({ weekView, selectedStartDate, handleChange }) => {
    const [hoveredDay, setHoveredDay] = useState<Moment | null>(null)
    const valueInState = useRef<Moment | null>(moment(selectedStartDate))

    const renderDay = (props: any) => {
        return renderDayIfSelected({
            selectedDay: valueInState,
            hoveredDay: props,
            ...props,
        })
    }
    return (
        <DateCalendar
            minDate={moment().subtract(5, 'years')}
            maxDate={moment().add(14, 'years')}
            value={valueInState.current}
            onChange={handleChange}
            views={weekView ? ['day'] : ['year', 'month']}
            openTo={weekView ? 'day' : 'month'}
            displayWeekNumber
            showDaysOutsideCurrentMonth
            slots={
                weekView
                    ? {
                          day: renderDay,
                      }
                    : {}
            }
            slotProps={{
                day: (ownerState) =>
                    ({
                        selectedDay: valueInState.current,
                        hoveredDay: hoveredDay,
                        onPointerEnter: () => setHoveredDay(moment(ownerState.day as any)),
                        onPointerLeave: () => setHoveredDay(null),
                    } as any),
            }}
        />
    )
}
