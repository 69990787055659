import * as React from 'react'
import { FC } from 'react'
import { Badge, Box, Button, ButtonProps } from '@mui/material'
import { TipsAndUpdates } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

type AiSubmitButtonProps = { onClick: () => void } & ButtonProps

export const AiSubmitButton: FC<AiSubmitButtonProps> = (props) => {
    const { onClick, ...otherProps } = props
    return (
        <Box
            display={'flex'}
            justifyContent={'flex-end'}
            sx={{
                '& .aiBetaBadge .MuiBadge-badge': {
                    background: 'linear-gradient(45deg, rgba(255,105,0,1) 0%, #ff00b3 100%)',
                },
            }}
        >
            <svg height={0} width={0} visibility={'hidden'} style={{ position: 'fixed', opacity: 0 }}>
                <defs>
                    <linearGradient id="ColoredGradient">
                        <stop offset="0%" stopColor="#ff6900" />
                        <stop offset="100%" stopColor="#ff00b3" />
                    </linearGradient>
                </defs>
            </svg>
            <Badge
                badgeContent={'beta'}
                className={'aiBetaBadge'}
                color={'secondary'}
                overlap={'rectangular'}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Button
                    size={'small'}
                    startIcon={<TipsAndUpdates color={'secondary'} fontSize={'small'} sx={{ fill: 'url(#ColoredGradient)' }} />}
                    onClick={onClick}
                    sx={{
                        px: 2,
                    }}
                    {...otherProps}
                >
                    <FormattedMessage id={'ai.text-generation-form-title'} />
                </Button>
            </Badge>
        </Box>
    )
}
