import * as React from 'react'
import { FC } from 'react'
import { Box, lighten, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getIsMobile, isDarkMode } from '../../core/slices/CoreSlice'
import { FormattedMessage } from 'react-intl'

type PublishingListTimeDisplayProps = { time: string; fullTime?: boolean; now?: boolean; showOnlyTime?: boolean }

export const PublishingListTimeDisplay: FC<PublishingListTimeDisplayProps> = ({ time, fullTime, now = false, showOnlyTime }) => {
    const formattedTime = moment(time).format('DD. MMM. YY')
    const remainingTimeString = moment(time).format('LT')
    const theme = useTheme()
    const isMobile = useSelector(getIsMobile)
    const darkmode = useSelector(isDarkMode)
    return (
        <Box
            bgcolor={lighten(theme.palette.divider, 0.1)}
            sx={{
                borderRadius: isMobile ? 20 : 1.5,
                overflow: 'hidden',
                px: 1,
                // border: 1,
                // borderColor: darken(theme.palette.success.light, 0.1),
            }}
        >
            <Typography
                variant={'body2'}
                color={darkmode ? theme.palette.text.primary : lighten(theme.palette.text.primary, 0.2)}
                sx={{
                    borderRadius: '0.3em',
                    width: 'fit-content',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'inline-flex',
                }}
            >
                {now ? <FormattedMessage id={'general.now'} /> : showOnlyTime ? remainingTimeString : formattedTime}
            </Typography>
            {!now && !showOnlyTime && (
                <Typography
                    variant={'body2'}
                    color={darkmode ? theme.palette.text.primary : lighten(theme.palette.text.primary, 0.4)}
                    sx={{
                        maxWidth: fullTime ? '100px' : '0px',
                        transition: 'max-width 0.3s',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        display: 'inline-flex',
                    }}
                >
                    &nbsp;&nbsp;{remainingTimeString}
                </Typography>
            )}
        </Box>
    )
}
