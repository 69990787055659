import * as React from 'react'
import { FC, useEffect, useRef } from 'react'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { Box, Breadcrumbs, Button, Dialog, Grid, IconButton, Link as MuiLink, Paper, Typography, useTheme } from '@mui/material'
import { Link, useLocation, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
    getCurrentUser,
    getDatasourcesAsIdMap,
    getIsMobile,
    getSelectedProject,
    getSimpleUsersForSelectedProject,
    isDarkMode,
    setAppThemeColor,
    showErrorSnackbar,
    showSnackbarWithLink,
} from '../core/slices/CoreSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
    closeShowCloseTicketDialog,
    decrementOpenTicketCountForProject,
    getLoadingSimpleTickets,
    getSelectedDatasourceIds,
    getSelectedDetailedTicket,
    getShowCloseTicketDialog,
    incrementTicketCountForProject,
    selectTicketId,
} from './EngagementSlice'
import { HmstrDispatch } from '../core/Store'
import { fetchDetailedTicket, fetchDetailedTicketById, fetchOpenTicketCountForUserByCategory, updateTicket } from './EngagementActions'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { getPreviewPost, hidePreview } from '../content-analytics/posts/PostsSlice'
import { FacebookPostPreview } from '../content-analytics/posts/FacebookPostPreview'
import { fetchCommonPostDataByPostIds } from '../content-analytics/posts/PostsActions'
import { Archive, AssignmentInd, Groups, Inbox, MoveToInbox, NavigateNext, Refresh } from '@mui/icons-material'
import { renderTicketId } from './renderTicketId'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { ConfirmDialog } from '../common/confirm-dialog/ConfirmDialog'
import { TicketViewPost } from './TicketViewPost'
import { TicketViewMessage } from './TicketViewMessage'
import { AssigneeDropdown } from '../common/assignee-dropdown/AssigneeDropdown'
import { SimpleUser } from '../settings/user-management/SimpleUser'
import { Path } from 'path-parser'
import { useHasDatasourcePermission } from '../core/hooks/useHasPermission'
import { TicketOverview } from './TicketOverview'
import _ from 'lodash'

type TicketViewProps = {}

export const TicketView: FC<TicketViewProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const params = useParams<{ projectId: string; ticketId: string }>()
    const navigate = useProjectNavigate()
    const theme = useTheme()
    const darkMode = useSelector(isDarkMode)

    const currentUser = useSelector(getCurrentUser)
    const project = useSelector(getSelectedProject)
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const detailedTicket = useSelector(getSelectedDetailedTicket)
    const previewPost = useSelector(getPreviewPost)
    const showCloseTicketDialog = useSelector(getShowCloseTicketDialog)
    const loadingTickets = useSelector(getLoadingSimpleTickets)
    const usersForProject = useSelector(getSimpleUsersForSelectedProject)
    const assignee = usersForProject[detailedTicket?.assignee_id || ''] as SimpleUser | undefined

    const canAssignOthersTicket = useHasDatasourcePermission('ticket.assign_others', detailedTicket?.data_source_id)
    const canAssignSelfTicket = useHasDatasourcePermission('ticket.assign_self', detailedTicket?.data_source_id)
    const canUnassignTicket = useHasDatasourcePermission('ticket.unassign', detailedTicket?.data_source_id)
    const canAssignTicket = canUnassignTicket && canAssignOthersTicket && canAssignSelfTicket
    const canReopenTicket = useHasDatasourcePermission('ticket.reopen', detailedTicket?.data_source_id)
    const canCloseTicket = useHasDatasourcePermission('ticket.close', detailedTicket?.data_source_id)

    const datasource = datasourcesById[detailedTicket?.data_source_id ?? '']
    const location = useLocation()
    const ticketPath = '/:projectId/engagement/tickets/:state/:ticketId'
    const path = new Path<{ projectId: string; state: string; ticketId: string }>(ticketPath)
    const pathVariables = path.partialTest(location.pathname)
    const ticketFilter = pathVariables?.state || 'new'
    const isMobile = useSelector(getIsMobile)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)

    const getTicketState = () => {
        if (!detailedTicket) {
            return 'new'
        }

        if (detailedTicket.state === 'CLOSED') {
            return 'archived'
        } else if (detailedTicket.assignee_id === currentUser.id) {
            return 'mine'
        } else if (!!detailedTicket.assignee_id) {
            return 'others'
        } else {
            return 'new'
        }
    }

    useEffect(() => {
        if (pathVariables && pathVariables.state !== getTicketState() && detailedTicket) {
            navigate(`/engagement/tickets/${getTicketState()}/${detailedTicket.id}`, { replace: true })
        }
    })

    useEffect(() => {
        if (params.ticketId) {
            dispatch(selectTicketId(params.ticketId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffectWithIdComparison(() => {
        if (project && !detailedTicket) {
            dispatch(
                fetchOpenTicketCountForUserByCategory({
                    datasource_ids: selectedDatasourceIds,
                    project: project,
                })
            )
        }
    }, [project])

    useEffectWithIdComparison(() => {
        if (params.ticketId && project) {
            dispatch(fetchDetailedTicketById({ project: project, ticket_id: params.ticketId }))
        } else if (!loadingTickets) {
            navigate(`/engagement/tickets/${ticketFilter}`)
        }
    }, [detailedTicket, loadingTickets])

    useEffectWithIdComparison(() => {
        dispatch(
            fetchCommonPostDataByPostIds({
                data_source_ids: detailedTicket?.data_source_id ? [detailedTicket.data_source_id] : [],
                post_ids: detailedTicket?.target_id ? [detailedTicket.target_id] : [],
            })
        )
    }, [detailedTicket])

    const getTicketLink = () => {
        if (detailedTicket) {
            return {
                href: `/${detailedTicket.project_id}/engagement/tickets/${ticketFilter}/${detailedTicket.id}`,
                text: `#${renderTicketId(detailedTicket.ticket_id)}`,
            }
        }

        return undefined
    }

    const handleTicketStateToggle = () => {
        if (detailedTicket) {
            const newTicketState = detailedTicket.state === 'CLOSED' ? 'OPEN' : 'CLOSED'
            dispatch(
                updateTicket({
                    ...detailedTicket,
                    state: newTicketState,
                    data_source_id: datasource.id,
                    data_source_name: datasource.name,
                    data_source_type: datasource.type,
                })
            ).then((res) => {
                if (_.get(res.payload, 'status') === 403) {
                    dispatch(showErrorSnackbar('general.no-permission'))
                } else {
                    if (newTicketState === 'CLOSED') {
                        dispatch(decrementOpenTicketCountForProject({ project_id: detailedTicket.project_id, value: 1 }))
                        dispatch(
                            showSnackbarWithLink({
                                id: 'engagement.ticket-action.archived',
                                link: getTicketLink(),
                            })
                        )
                    } else if (newTicketState === 'OPEN') {
                        dispatch(incrementTicketCountForProject({ project_id: detailedTicket.project_id, value: 1 }))
                        dispatch(
                            showSnackbarWithLink({
                                id: 'engagement.ticket-action.opened',
                                link: getTicketLink(),
                            })
                        )
                    }

                    navigate('/engagement/tickets/' + ticketFilter)
                }
            })
        }
    }

    const handleTicketAssign = (user: SimpleUser | undefined) => {
        if (detailedTicket) {
            dispatch(
                updateTicket({
                    ...detailedTicket,
                    assignee_id: user?.id,
                    data_source_id: datasource.id,
                    data_source_name: datasource.name,
                    data_source_type: datasource.type,
                })
            ).then((res) => {
                if (_.get(res.payload, 'status') === 403) {
                    dispatch(showErrorSnackbar('general.no-permission'))
                } else {
                    const fromInboxToOthers = !detailedTicket.assignee_id && user && user.id !== currentUser.id
                    const fromMineToOthers = detailedTicket.assignee_id === currentUser.id && !!user?.id
                    const assignedToOthers = detailedTicket.assignee_id && detailedTicket.assignee_id !== currentUser.id
                    const fromOthersToNewOrMine = assignedToOthers && (!user || user?.id === currentUser.id)

                    if (fromInboxToOthers || fromMineToOthers) {
                        dispatch(decrementOpenTicketCountForProject({ project_id: detailedTicket.project_id, value: 1 }))
                    } else if (fromOthersToNewOrMine) {
                        dispatch(incrementTicketCountForProject({ project_id: detailedTicket.project_id, value: 1 }))
                    }

                    dispatch(
                        showSnackbarWithLink({
                            id: `engagement.ticket-action.${user ? 'assigned' : 'unassigned'}`,
                            link: getTicketLink(),
                        })
                    )
                    navigate('/engagement/tickets/' + ticketFilter)
                }
            })
        }
    }

    const handleTicketClose = () => {
        if (detailedTicket) {
            dispatch(
                updateTicket({
                    ...detailedTicket,
                    state: 'CLOSED',
                    data_source_id: datasource.id,
                    data_source_name: datasource.name,
                    data_source_type: datasource.type,
                })
            )
            dispatch(decrementOpenTicketCountForProject({ project_id: detailedTicket.project_id, value: 1 }))
            dispatch(closeShowCloseTicketDialog())
            dispatch(
                showSnackbarWithLink({
                    id: 'engagement.ticket-action.archived',
                    link: getTicketLink(),
                })
            )
            navigate('/engagement/tickets/new')
        }
    }

    useEffect(() => {
        dispatch(setAppThemeColor(theme.palette.background.default))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [darkMode])

    const renderTitle = () => {
        switch (ticketFilter) {
            case 'archived':
                return (
                    <>
                        <Archive sx={{ mr: 1 }} fontSize="inherit" />
                        <FormattedMessage id="engagement.tickets.CLOSED" />
                    </>
                )
            case 'others':
                return (
                    <>
                        <Groups sx={{ mr: 1 }} fontSize="inherit" />
                        <FormattedMessage id="engagement.inbox.assigned-to-others" />
                    </>
                )
            case 'mine':
                return (
                    <>
                        <AssignmentInd sx={{ mr: 1 }} fontSize="inherit" />
                        <FormattedMessage id="engagement.inbox.assigned-to-me" />
                    </>
                )
            case 'new':
            default:
                return (
                    <>
                        <Inbox sx={{ mr: 1 }} fontSize="inherit" />
                        <FormattedMessage id="engagement.tickets.OPEN" />
                    </>
                )
        }
    }

    const contentPanelRef = useRef<any>()

    return (
        <ContentPanel
            disableToolbarToggle={!isMobile}
            toolbarInSameLine={!isMobile}
            title={
                <Breadcrumbs separator={<NavigateNext />}>
                    <MuiLink underline="hover" color="inherit" component={Link} to={`/${project?.id}/engagement/tickets/${ticketFilter}`}>
                        <Typography variant="h5" display="flex" alignItems="center">
                            {renderTitle()}
                        </Typography>
                    </MuiLink>
                    {detailedTicket && (
                        <Typography color="text.primary" variant="h5">
                            #{renderTicketId(detailedTicket.ticket_id)}
                        </Typography>
                    )}
                </Breadcrumbs>
            }
            toolbarToggleText={'engagement.more-ticket-actions'}
            toolbar={
                detailedTicket ? (
                    <Box display="flex" gap={1} justifyContent={isMobile ? 'center' : undefined}>
                        {isMobile && (
                            <>
                                <IconButton
                                    sx={{
                                        backgroundColor: theme.palette.secondary.main,
                                        color: theme.palette.secondary.contrastText,
                                    }}
                                    size="small"
                                    onClick={() => dispatch(fetchDetailedTicket(detailedTicket))}
                                >
                                    <Refresh fontSize="small" />
                                </IconButton>
                            </>
                        )}
                        {detailedTicket.state !== 'CLOSED' && (
                            <AssigneeDropdown assigneeId={detailedTicket?.assignee_id} onAssign={handleTicketAssign} disabled={!canAssignTicket} />
                        )}
                        {!isMobile && (
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => dispatch(fetchDetailedTicket(detailedTicket))}
                                startIcon={<Refresh fontSize="small" />}
                            >
                                <FormattedMessage id="engagement.refresh" />
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disabled={!canReopenTicket || !canCloseTicket}
                            onClick={handleTicketStateToggle}
                            startIcon={detailedTicket.state === 'OPEN' ? <Archive fontSize="small" /> : <MoveToInbox fontSize="small" />}
                        >
                            {detailedTicket.state === 'OPEN' ? (
                                <FormattedMessage id={isMobile ? 'general.close' : 'engagement.close'} />
                            ) : (
                                <FormattedMessage id={isMobile ? 'general.open' : 'engagement.open'} />
                            )}
                        </Button>
                    </Box>
                ) : undefined
            }
        >
            <Grid
                container
                height={isMobile ? `calc(100% - ${48}px)` : '100%'}
                ref={contentPanelRef}
                position={'relative'}
                maxWidth={'100%'}
                className={'mobileScrollContainerNodes'}
                sx={{
                    overflowY: isMobile ? 'scroll' : undefined,
                    overflowX: 'hidden',
                }}
            >
                {['POST', 'STORY'].includes(detailedTicket?.type || '') ? (
                    <TicketViewPost datasource={datasource} />
                ) : (
                    <TicketViewMessage datasource={datasource} />
                )}
                {!isMobile && (
                    <Grid item xs={0} xxl={4} maxHeight="100%">
                        {detailedTicket && <TicketOverview detailedTicket={detailedTicket} datasource={datasource} assignee={assignee} />}
                    </Grid>
                )}
            </Grid>
            {isMobile && detailedTicket && (
                <Box mt={2} sx={{ position: 'absolute', maxWidth: '100vw', left: 0, borderRadius: 0 }} component={Paper} elevation={0}>
                    <TicketOverview detailedTicket={detailedTicket} datasource={datasource} assignee={assignee} />
                </Box>
            )}

            <Dialog open={Boolean(previewPost)} onClose={() => dispatch(hidePreview())}>
                {previewPost && <FacebookPostPreview post={previewPost} />}
            </Dialog>

            <ConfirmDialog
                open={showCloseTicketDialog}
                onClose={() => dispatch(closeShowCloseTicketDialog())}
                onConfirm={handleTicketClose}
                confirmText="engagement.close"
            >
                <FormattedMessage id="engagement.close-for-no-unread-comments" />
            </ConfirmDialog>
        </ContentPanel>
    )
}
