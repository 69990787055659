import { ProjectDatasource } from '../../../settings/datasources/ProjectDatasource'
import { Post } from '../../posts/Post'
import * as React from 'react'
import { FC } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { PostAvatar } from '../../../common/avatars/PostAvatar'
import { PostPreviewVideo } from '../PostPreviewVideo'
import { TiktokComment, TiktokForward, TiktokHeart } from './TiktokPreviewIcons'
import { Bookmark } from '@mui/icons-material'
import { ReadMore } from '../ReadMore'
import { useSelector } from 'react-redux'
import { getMedia } from '../../../core/slices/DataSlice'
import { TiktokImagePreview } from './TiktokImagePreview'

type TiktokPreviewDesktopProps = {
    selectedDatasource: ProjectDatasource
    post: Post
    generatePostText: (text: string) => JSX.Element
}

export const TiktokPreviewDesktop: FC<TiktokPreviewDesktopProps> = ({ selectedDatasource, post, generatePostText }) => {
    const media = useSelector(getMedia)
    const isVideo = post.post_type === 'VIDEO'
    const mediaFiles = isVideo
        ? post.file_ids && post.file_ids.length > 0
            ? [media[post.file_ids[0]]]
            : undefined
        : post.file_ids && post.file_ids.length > 0
        ? post.file_ids.map((file_id) => media[file_id])
        : undefined
    return (
        <Box>
            <Box display="flex" alignItems="flex-start" gap={2} width={418}>
                {selectedDatasource.metadata.profile_picture_url && (
                    <PostAvatar width={48} height={48} name={selectedDatasource.name} src={selectedDatasource.metadata.profile_picture_url} />
                )}

                <Box width={354}>
                    <Box display="flex">
                        <Typography fontSize="17px" fontWeight={700}>
                            {selectedDatasource.name}
                        </Typography>
                    </Box>
                    <Typography sx={{ wordWrap: 'break-word' }}>
                        <ReadMore sliceLimit={100} passedElement={generatePostText(post.text || '')}>
                            {post.text ? generatePostText(post.text) : null}
                        </ReadMore>
                    </Typography>
                </Box>
            </Box>

            {isVideo && mediaFiles && (
                <Grid container ml={1} mt={1}>
                    <Grid item>
                        <PostPreviewVideo variant="TIKTOK" file={mediaFiles[0]} />
                    </Grid>
                    <Grid item>
                        <Box ml={3} gap={0.5} display="flex" alignItems="center" flexDirection="column" justifyContent="flex-end" height="100%">
                            <IconButton
                                size="large"
                                color="primary"
                                sx={{
                                    backgroundColor: 'rgba(22, 24, 35, 0.06)',
                                }}
                            >
                                <TiktokHeart htmlColor={'white'} />
                            </IconButton>
                            <Typography
                                sx={{
                                    color: 'rgba(22, 24, 35, 0.75)',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                }}
                            >
                                0
                            </Typography>
                            <IconButton
                                size="large"
                                color="primary"
                                sx={{
                                    backgroundColor: 'rgba(22, 24, 35, 0.06)',
                                }}
                            >
                                <TiktokComment htmlColor={'white'} />
                            </IconButton>
                            <Typography
                                sx={{
                                    color: 'rgba(22, 24, 35, 0.75)',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                }}
                            >
                                0
                            </Typography>
                            <IconButton
                                size="large"
                                color="primary"
                                sx={{
                                    backgroundColor: 'rgba(22, 24, 35, 0.06)',
                                }}
                            >
                                <Bookmark htmlColor={'white'} />
                            </IconButton>
                            <Typography
                                sx={{
                                    color: 'rgba(22, 24, 35, 0.75)',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                }}
                            >
                                0
                            </Typography>{' '}
                            <IconButton
                                size="large"
                                color="primary"
                                sx={{
                                    backgroundColor: 'rgba(22, 24, 35, 0.06)',
                                }}
                            >
                                <TiktokForward htmlColor={'white'} />
                            </IconButton>
                            <Typography
                                sx={{
                                    color: 'rgba(22, 24, 35, 0.75)',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                }}
                            >
                                0
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            )}
            {!isVideo && mediaFiles && mediaFiles.length > 0 && (
                <Grid
                    container
                    ml={1}
                    mt={1}
                    sx={{
                        position: 'relative',
                    }}
                >
                    <Grid item>
                        <TiktokImagePreview uploadFiles={mediaFiles} />
                    </Grid>
                    <Grid item sx={{ position: 'absolute', right: 0, bottom: 0 }}>
                        <Box ml={3} gap={0.5} display="flex" alignItems="center" flexDirection="column" justifyContent="flex-end" height="100%">
                            <IconButton size="large" color="primary">
                                <TiktokHeart htmlColor={'white'} />
                            </IconButton>
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                }}
                            >
                                0
                            </Typography>
                            <IconButton size="large" color="primary">
                                <TiktokComment htmlColor={'white'} />
                            </IconButton>
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                }}
                            >
                                0
                            </Typography>
                            <IconButton size="large" color="primary">
                                <Bookmark htmlColor={'white'} />
                            </IconButton>
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                }}
                            >
                                0
                            </Typography>{' '}
                            <IconButton size="large" color="primary">
                                <TiktokForward htmlColor={'white'} />
                            </IconButton>
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                }}
                            >
                                0
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}
