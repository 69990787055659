import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { fetchPostTextSuggestions } from './PostTextGenerationActions'

export interface AiState {
    results: string[]
    dialogOpen: boolean
    loadingResults: boolean
    selectedResultToRefine?: string
}

const initialState: AiState = {
    results: [],
    dialogOpen: false,
    loadingResults: false,
}

export const PostTextGenerationSlice = createSlice({
    name: 'post_text_generation',
    initialState,
    reducers: {
        clearResults: (state) => {
            state.results = []
        },
        setAiDialogOpen: (state, action: PayloadAction<AiState['dialogOpen']>) => {
            state.dialogOpen = action.payload
        },
        setSelectedResultToRefine: (state, action: PayloadAction<AiState['selectedResultToRefine']>) => {
            state.selectedResultToRefine = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPostTextSuggestions.pending, (state) => {
                state.loadingResults = true
            })
            .addCase(fetchPostTextSuggestions.rejected, (state) => {
                state.loadingResults = false
                state.results = []
            })
            .addCase(fetchPostTextSuggestions.fulfilled, (state, action) => {
                state.loadingResults = false
                state.results = action.payload
            })
    },
})

export const getAiResults: Selector<HmstrState, AiState['results']> = (state) => state.post_text_generation.results
export const getAiDialogOpen: Selector<HmstrState, AiState['dialogOpen']> = (state) => state.post_text_generation.dialogOpen
export const getAiResultsLoading: Selector<HmstrState, AiState['loadingResults']> = (state) => state.post_text_generation.loadingResults
export const getSelectedResultToRefine: Selector<HmstrState, AiState['selectedResultToRefine']> = (state) => state.post_text_generation.selectedResultToRefine

export const { clearResults, setAiDialogOpen, setSelectedResultToRefine } = PostTextGenerationSlice.actions

export const PostTextGenerationReducer = PostTextGenerationSlice.reducer
