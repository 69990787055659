import * as React from 'react'
import { FC } from 'react'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { FormattedMessage } from 'react-intl'
import { Breadcrumbs, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
    getConversionOverviewData,
    getConversionTrackingDataLoading,
    getConversionTrackingDatasource,
    getConversionTrackingEndDate,
    getConversionTrackingEvent,
    getConversionTrackingStartDate,
    getFacebookAdAccountsWithConversionTracking,
    selectDatasource,
} from './ConversionTrackingSlice'
import { getSelectedProject } from '../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { Loading } from '../common/loading/Loading'
import { ConversionTrackingTable } from './ConversionTrackingTable'
import { ConversionTrackingAdPreview } from './ConversionTrackingAdPreview'
import { fetchFacebookConversionData } from './ConversionActions'
import { Fallback } from '../common/fallback/Fallback'
import moment from 'moment'
import { DownloadCSVButton } from './DownloadCSVButton'
import { HmstrDispatch } from '../core/Store'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { replaceUrlParams } from '../core/helpers/replace-url-params'
import { TitlebarWithFilters } from '../common/filterbar/TitlebarWithFilters'
import { ConversionTrackingFilterDrawer } from './ConversionTrackingFilterDrawer'

type ConversionTrackingProps = {}

export const ConversionTracking: FC<ConversionTrackingProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const selectedDatasource = useSelector(getConversionTrackingDatasource)
    const isDataLoading = useSelector(getConversionTrackingDataLoading)
    const selectedProject = useSelector(getSelectedProject)
    const adAccounts = useSelector(getFacebookAdAccountsWithConversionTracking)
    const timePeriodStart = useSelector(getConversionTrackingStartDate)
    const timePeriodEnd = useSelector(getConversionTrackingEndDate)
    const data = useSelector(getConversionOverviewData)
    const conversionEvent = useSelector(getConversionTrackingEvent)

    useEffectWithIdComparison(() => {
        if (selectedDatasource) {
            dispatch(fetchFacebookConversionData(selectedDatasource))
        }
    }, [selectedDatasource, timePeriodStart, timePeriodEnd, conversionEvent])

    useEffectWithIdComparison(() => {
        if (selectedProject && !selectedDatasource && adAccounts.length > 0) {
            dispatch(selectDatasource(adAccounts[0].id))
        }
    }, [selectedProject])

    useEffectWithIdComparison(() => {
        if (selectedDatasource) {
            const urlParams = new URLSearchParams(window.location.search)
            urlParams.set('startDate', moment(timePeriodStart).format('YYYY-MM-DD'))
            urlParams.set('endDate', moment(timePeriodEnd).format('YYYY-MM-DD'))
            urlParams.set('datasourceId', selectedDatasource.id)
            urlParams.set('eventType', conversionEvent)
            replaceUrlParams(urlParams)
        }
    }, [selectedDatasource, timePeriodStart, timePeriodEnd, conversionEvent])

    return (
        <ContentPanel
            title={
                <TitlebarWithFilters
                    actionButton={
                        selectedDatasource && (
                            <DownloadCSVButton
                                startDate={timePeriodStart}
                                endDate={timePeriodEnd}
                                event={conversionEvent}
                                datasource={selectedDatasource}
                                project={selectedProject}
                            />
                        )
                    }
                    title={
                        <Breadcrumbs>
                            <Typography variant="h5">
                                <FormattedMessage id="conversion-tracking.title" />
                            </Typography>
                            <Typography variant="h5">
                                <FormattedMessage id="conversion-tracking.overview" />
                            </Typography>
                        </Breadcrumbs>
                    }
                    filterBar={<ConversionTrackingFilterDrawer />}
                />
            }
            fullWidthTitle
        >
            <Fallback
                condition={adAccounts.length === 0}
                messageId="conversion-tracking.no-active-adaccounts-hint"
                marginTop={4}
                actionButton={
                    <Button variant="contained" color="secondary" onClick={() => navigate('analytics/ct-management')}>
                        <FormattedMessage id="conversion-tracking.navigate-to-management" />
                    </Button>
                }
            >
                {isDataLoading || !selectedDatasource ? <Loading /> : <ConversionTrackingTable data={data} />}

                <ConversionTrackingAdPreview />
            </Fallback>
        </ContentPanel>
    )
}
