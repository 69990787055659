import * as React from 'react'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    cleanUpDashboardUrlParams,
    getDashboardAccessDenied,
    getDashboardData,
    getDatasourcesForSelectedDashboard,
    getFbAdAccountsForSelectedDashboard,
    getFirstPageForSelectedDashboard,
    getIsLoadingDashboards,
    getIsLoadingTemplates,
    getSelectedDashboard,
    getSelectedDashboardAttributionWindows,
    getSelectedDashboardCompareEndDate,
    getSelectedDashboardCompareStartDate,
    getSelectedDashboardDatasourceIds,
    getSelectedDashboardEndDate,
    getSelectedDashboardEstimateWithoutConsentLoss,
    getSelectedDashboardStartDate,
    getSelectedDashboardTags,
    getSendDashboardDialogOpen,
    getTemplateOfSelectedDashboard,
    isDashboardDataLoading,
    setDashboardWizardStep,
} from './DashboardsSlice'
import { DashboardTitle } from './DashboardTitle'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { fetchDashboardsForProject, fetchDataForSelectedDashboard, fetchTemplates } from './DashboardsActions'
import { HmstrDispatch } from '../core/Store'
import { DashboardWidgetData } from './DashboardData'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, Grid, Paper, Typography, useTheme } from '@mui/material'
import { NumberChart } from './charts/NumberChart'
import { AreaChart } from './charts/AreaChart'
import { ComposedChart } from './charts/ComposedChart'
import { PieChart } from './charts/PieChart'
import { ArrowBackIos, Https, KeyboardArrowDown } from '@mui/icons-material'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { Loading } from '../common/loading/Loading'
import { DynamicToolbar } from '../templates/components/DynamicToolbar'
import { PostAnalysisTreeChart } from '../content-analytics/post-analysis/PostAnalysisTreeChart'
import { CommonPostData } from '../content-analytics/posts/CommonPostData'
import { getTags } from '../core/slices/DataSlice'
import * as _ from 'lodash'
import { getUntaggedTag } from '../tags/TagsSlice'
import { getPreviewPost, hidePreview, previewPost } from '../content-analytics/posts/PostsSlice'
import { FacebookPostPreview } from '../content-analytics/posts/FacebookPostPreview'
import { DynamicTable } from './DynamicTable'
import { Fallback } from '../common/fallback/Fallback'
import { dashboardInvalid } from './validateDashboard'
import { Navigate } from 'react-router-dom'
import { getIsMobile, getSelectedProject, isDarkMode, openFullscreenDialog, setAppThemeColor } from '../core/slices/CoreSlice'
import { replaceUrlParams } from '../core/helpers/replace-url-params'
import { MonthlyReportSummary } from '../templates/monthly-report/MonthlyReportSummary'
import { ContactPersonCard } from '../templates/components/ContactPersonCard'
import moment from 'moment'
import { fetchFacebookMonthlyReport } from '../templates/monthly-report/MonthlyReportActions'
import { SendMonthlyReportDialog } from '../templates/monthly-report/SendMonthlyReportDialog'
import { NoData } from '../common/chart-tooltip/NoData'
import { NoAdAccountDashboardHint } from './NoAdAccountDashboardHint'
import { DynamicToolbarDesktop } from '../templates/components/DynamicToolbarDesktop'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'

type DynamicDashboardViewProps = {}

export const DynamicDashboardView: FC<DynamicDashboardViewProps> = () => {
    const intl = useIntl()
    const theme = useTheme()
    const navigate = useProjectNavigate()
    const dispatch = useDispatch<HmstrDispatch>()
    const isSendDashboardDialogOpen = useSelector(getSendDashboardDialogOpen)
    const selectedPage = useSelector(getFirstPageForSelectedDashboard)
    const adAccountsForDashboard = useSelector(getFbAdAccountsForSelectedDashboard)
    const selectedProject = useSelector(getSelectedProject)
    const selectedDashboard = useSelector(getSelectedDashboard)
    const template = useSelector(getTemplateOfSelectedDashboard)
    const startDate = useSelector(getSelectedDashboardStartDate)
    const month = moment(startDate).format('YYYY-MM')
    const endDate = useSelector(getSelectedDashboardEndDate)
    const compareStartDate = useSelector(getSelectedDashboardCompareStartDate)
    const compareEndDate = useSelector(getSelectedDashboardCompareEndDate)
    const compareMonth = moment(compareStartDate).format('YYYY-MM')
    const dashboardData = useSelector(getDashboardData)
    const datasources = useSelector(getDatasourcesForSelectedDashboard)
    const isLoading = useSelector(isDashboardDataLoading)
    const selectedDataSourceIds = useSelector(getSelectedDashboardDatasourceIds)
    const availableTags = useSelector(getTags)
    const tags = _.map(availableTags, (tag) => tag)
    const tagsWithUntagged = [getUntaggedTag(intl.formatMessage({ id: 'tags.untagged-posts' })), ...tags]
    const previewPostData = useSelector(getPreviewPost)
    const isLoadingDashboards = useSelector(getIsLoadingDashboards)
    const isLoadingTemplates = useSelector(getIsLoadingTemplates)
    const attributionWindows = useSelector(getSelectedDashboardAttributionWindows)
    const estimateWithousConsentLoss = useSelector(getSelectedDashboardEstimateWithoutConsentLoss)
    const dashboardAccessDenied = useSelector(getDashboardAccessDenied)
    const selectedTags = useSelector(getSelectedDashboardTags)
    const darkMode = useSelector(isDarkMode)
    const isMobile = useSelector(getIsMobile)

    const datasourcesForDashboard = useSelector(getDatasourcesForSelectedDashboard)
    const isMonthlyReport = template?.name.includes('monthly-report')

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchDashboardsForProject(selectedProject))
            dispatch(fetchTemplates(selectedProject))
        }
    }, [selectedProject])

    useEffect(() => {
        dispatch(setAppThemeColor(theme.palette.background.default))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [darkMode])

    useEffect(() => {
        if (selectedDashboard) {
            dispatch(cleanUpDashboardUrlParams(selectedDashboard))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDashboard])

    useEffectWithIdComparison(() => {
        if (selectedDashboard) {
            const urlParams = new URLSearchParams(window.location.search)
            urlParams.set('startDate', startDate)
            urlParams.set('endDate', endDate)
            if (selectedDataSourceIds.length > 0) {
                urlParams.set('selected_datasources', selectedDataSourceIds.join(','))
            }

            if (compareStartDate && template?.settings.available_settings.compare_time_period) urlParams.set('compareStartDate', compareStartDate)
            if (compareEndDate && template?.settings.available_settings.compare_time_period) urlParams.set('compareEndDate', compareEndDate)
            if (estimateWithousConsentLoss !== undefined && template?.settings.available_settings.consent_loss) {
                urlParams.set('estimateWithoutConsentLoss', estimateWithousConsentLoss.toString())
            }
            if (attributionWindows && attributionWindows.length > 0 && template?.settings.available_settings.attribution_windows) {
                urlParams.set('attributionWindows', attributionWindows.join(','))
            }
            if (selectedTags && selectedTags?.length > 0 && template?.settings.available_settings.tags) {
                urlParams.set('selected_tags', selectedTags.join(','))
            }
            replaceUrlParams(urlParams)
        }
    }, [
        selectedDashboard,
        selectedDataSourceIds.toString(),
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        estimateWithousConsentLoss,
        attributionWindows?.toString(),
        selectedTags?.toString(),
    ])

    useEffectWithIdComparison(() => {
        if (selectedDataSourceIds.length > 0 && selectedDashboard && template && !dashboardInvalid(datasources, template)) {
            dispatch(fetchDataForSelectedDashboard({ reloadDashboard: true }))
        }
    }, [selectedDashboard, selectedDataSourceIds.length, template])

    useEffectWithIdComparison(() => {
        if (selectedDashboard && template && !dashboardInvalid(datasources, template)) {
            dispatch(fetchFacebookMonthlyReport({ dashboard: selectedDashboard, month }))
        }
    }, [month, selectedDashboard])

    const getDatasourceStrings = (passed_datasources: ProjectDatasource[]): 'facebook' | 'instagram' | 'linkedin' | undefined => {
        let returnstr: 'facebook' | 'instagram' | 'linkedin' | undefined = undefined
        passed_datasources.map((ds) => {
            switch (ds.type) {
                case 'LINKED_IN':
                    returnstr = 'linkedin'
                    break
                case 'FACEBOOK_AD_ACCOUNT':
                case 'FACEBOOK_PAGE':
                    returnstr = 'facebook'
                    break
                case 'INSTAGRAM_ACCOUNT':
                    returnstr = 'instagram'
                    break
                default:
                    returnstr = undefined
                    break
            }
            return returnstr
        })
        return returnstr
    }

    const renderDashboardWidget = (widget: DashboardWidgetData): any => {
        let widgetElement: JSX.Element

        switch (widget.type) {
            case 'SECTION':
                widgetElement = (
                    <Typography variant={'subtitle1'} color={'text.secondary'}>
                        <FormattedMessage id={widget.title} />
                    </Typography>
                )
                break
            case 'NUMBER':
                widgetElement = (
                    <NumberChart
                        title={widget.title}
                        value={widget.data}
                        tooltip={widget.tooltip ? <FormattedMessage id={`${widget.title}.tooltip`} /> : undefined}
                        unit={widget.metadata.unit}
                        invert={widget.metadata.inverted}
                        percentualChange={(widget.data - widget.compare_data) / widget.compare_data}
                        noRounding={widget.metadata.no_rounding}
                        commas={widget.metadata.commas}
                        format={true}
                    />
                )
                break
            case 'AREA':
                widgetElement = (
                    <AreaChart
                        yAxis1Unit={widget.metadata.unit}
                        title={widget.title}
                        data={widget.data}
                        schema={widget.data_schema}
                        multipleAxis={widget.metadata.dualAxis}
                        minValue={widget.metadata.min_non_zero_value}
                        maxValue={widget.metadata.max_value}
                        metadata={widget.metadata}
                    />
                )
                break
            case 'BAR':
                widgetElement = (
                    <ComposedChart
                        title={widget.title}
                        data={widget.data}
                        schema={widget.data_schema}
                        taggingOverview={widget.metadata.tagging_overview}
                        withoutLegend={!widget.metadata.legend}
                        unit={widget.metadata.unit}
                        fullTicks={widget.metadata.full_ticks}
                        metadata={widget.metadata}
                    />
                )
                break
            case 'PIE':
                widgetElement = (
                    <PieChart
                        title={widget.title}
                        data={widget.data}
                        legend={widget.metadata.legend}
                        straightAngle={widget.metadata.halfPie}
                        height={widget.metadata.halfPie ? 150 : undefined}
                        top4={widget.metadata.top4}
                        metadata={widget.metadata}
                    />
                )
                break
            case 'TABLE':
                widgetElement = (
                    <DynamicTable
                        data={widget.data}
                        compare_data={widget.compare_data}
                        metadata={widget.metadata}
                        datasources={datasources}
                        tags={tagsWithUntagged}
                    />
                )
                break
            case 'TREEMAP':
                widgetElement = (
                    <Paper>
                        <PostAnalysisTreeChart
                            title={widget.title}
                            data={widget.data}
                            tags={tagsWithUntagged}
                            getValue={(data: CommonPostData) => data.insights.impressions}
                            onClick={(post) => dispatch(previewPost(post))}
                        />
                    </Paper>
                )
                break
            default:
                widgetElement = <div />
        }

        return (
            <Grid
                key={widget.title + widget.type}
                item
                xs={widget.sizes.web.xs}
                sm={widget.sizes.web.sm}
                md={widget.sizes.web.md}
                lg={widget.sizes.web.lg}
                xl={widget.sizes.web.xl}
            >
                {widget.type === 'SECTION' ? (
                    <Accordion
                        sx={{
                            background: 'transparent',
                            border: 'none',
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            boxShadow: 'none',
                        }}
                        disableGutters
                        defaultExpanded
                    >
                        <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ ml: 1, mr: 1 }} />} sx={{ pl: 0, pr: 0, flexDirection: 'row-reverse', mt: 2 }}>
                            {widgetElement}
                        </AccordionSummary>
                        <AccordionDetails sx={{ pl: 0, pr: 0 }}>
                            <Grid container spacing={1}>
                                {widget.title === 'facebook.monthly-report-subtitle.ads' &&
                                isMonthlyReport &&
                                !datasources.map((ds) => ds.type).includes('FACEBOOK_AD_ACCOUNT') ? (
                                    <Grid item xs={12}>
                                        <NoAdAccountDashboardHint />
                                    </Grid>
                                ) : (
                                    (widget.data || []).map(renderDashboardWidget)
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    widgetElement
                )}
            </Grid>
        )
    }

    const generateMonthlyReportFirstRow = () => {
        if (selectedProject && isMonthlyReport) {
            if (selectedProject.contact_person) {
                return (
                    <>
                        <Grid item xs={12} lg={3}>
                            <ContactPersonCard contactPerson={selectedProject.contact_person} />
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <MonthlyReportSummary month={month} selectedProject={selectedProject} selectedDashboard={selectedDashboard} />
                        </Grid>
                    </>
                )
            } else {
                return (
                    <Grid item xs={12}>
                        <MonthlyReportSummary month={month} selectedProject={selectedProject} selectedDashboard={selectedDashboard} />
                    </Grid>
                )
            }
        }
    }

    const handleInvalidDashboardAction = () => {
        dispatch(setDashboardWizardStep(1))
        dispatch(openFullscreenDialog('edit-dashboard'))
    }

    if (!selectedDashboard || !template) {
        if (isLoadingDashboards || isLoadingTemplates) {
            return <Loading />
        } else {
            return <Navigate to={`/${selectedProject?.id}/analytics/dashboards`} replace />
        }
    }
    return (
        <>
            {dashboardInvalid(datasourcesForDashboard, template) ? (
                <ContentPanel>
                    <Fallback
                        centered
                        variant={'body1'}
                        icon={<Https sx={{ fontSize: '70px' }} color={'action'} />}
                        condition={
                            dashboardAccessDenied || (!isLoadingDashboards && !isLoadingTemplates && dashboardInvalid(datasourcesForDashboard, template))
                        }
                        messageId={dashboardAccessDenied ? 'reporting.dashboards.access-denied' : 'reporting.dashboards.not-valid'}
                        actionButton={
                            dashboardAccessDenied ? undefined : (
                                <Box display={'flex'} flexDirection={'column'} gap={2}>
                                    <Button color="secondary" variant="contained" onClick={handleInvalidDashboardAction}>
                                        <FormattedMessage id="reporting.dashboards.change-datasources" />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            navigate(`/analytics/dashboards`)
                                        }}
                                        startIcon={<ArrowBackIos />}
                                    >
                                        <FormattedMessage id="general.back" />
                                    </Button>
                                </Box>
                            )
                        }
                    >
                        <div />
                    </Fallback>
                </ContentPanel>
            ) : (
                <ContentPanel
                    fullWidthTitle
                    title={
                        <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-between'}>
                            <DashboardTitle dashboard={selectedDashboard} mainDatasourceType={getDatasourceStrings(datasources)} hasChanges={false} />
                            {!isMobile && <DynamicToolbarDesktop template={template} />}
                        </Box>
                    }
                    toolbar={isMobile ? <DynamicToolbar template={template} /> : undefined}
                >
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <Box paddingBottom={2}>
                            <Grid container spacing={1}>
                                {generateMonthlyReportFirstRow()}
                                {dashboardData.map(renderDashboardWidget)}
                                {dashboardData.length === 0 && <NoData />}
                            </Grid>
                        </Box>
                    )}

                    <Dialog open={Boolean(previewPostData)} onClose={() => dispatch(hidePreview())}>
                        {previewPostData && <FacebookPostPreview post={previewPostData} />}
                    </Dialog>
                    {isMonthlyReport && (
                        <SendMonthlyReportDialog
                            open={isSendDashboardDialogOpen}
                            selectedProjectId={selectedProject?.id}
                            selectedDashboard={selectedDashboard}
                            selectedMonth={month}
                            selectedCompareMonth={compareMonth}
                            selectedPage={selectedPage}
                            selectedAdAccounts={adAccountsForDashboard}
                        />
                    )}
                </ContentPanel>
            )}
        </>
    )
}
