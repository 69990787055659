import * as React from 'react'
import { FC, Fragment } from 'react'
import { History } from '@mui/icons-material'
import { Box, darken, lighten, LinearProgress, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { isDarkMode, isExternalForProject } from '../../core/slices/CoreSlice'
import _ from 'lodash'
import { getIconForDatasourceType } from '../../settings/datasources/DatasourceTypeMappings'
import { Dot } from '../../common/dot/Dot'
import { PostGroup } from '../post-groups/PostGroup'
import { getCompactCalendarViewForMonthly, getCompactCalendarViewForWeekly, getSelectedCalendarTimePeriod, isApprovalProcessActive } from '../PublishingSlice'
import { getRelevantPostState } from '../getRelevantPostState'

type PostCalendarCardHeaderProps = { postGroup: PostGroup }

export const PostCalendarCardHeader: FC<PostCalendarCardHeaderProps> = ({ postGroup }) => {
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)
    const relevantPostState = getRelevantPostState(postGroup.posts)
    const isApprovalActive = useSelector(isApprovalProcessActive)
    const isUserExternal = useSelector(isExternalForProject)
    const calendarView = useSelector(getSelectedCalendarTimePeriod)
    const compactViewMonthly = useSelector(getCompactCalendarViewForMonthly)
    const compactViewWeekly = useSelector(getCompactCalendarViewForWeekly)
    const compactCalendar = calendarView === 'month' ? compactViewMonthly : compactViewWeekly

    const internalPending = postGroup.internal_approval?.state === 'REQUESTED'
    const externalPending = postGroup.external_approval?.state === 'REQUESTED'
    const internalApproved = postGroup.internal_approval?.state === 'APPROVED'
    const externalApproved = postGroup.external_approval?.state === 'APPROVED'
    const internalRework = postGroup.internal_approval?.state === 'REWORK'
    const externalRework = postGroup.external_approval?.state === 'REWORK'
    const needsApproval = relevantPostState === 'DRAFT' && ((internalPending && !isUserExternal) || externalPending)
    const needsRework = relevantPostState === 'DRAFT' && ((internalRework && !isUserExternal) || externalRework)
    const allPostsStories = _.map(postGroup.posts, (post) => post.ig_publish_as_story).every((publish_as_story) => publish_as_story)

    const renderBarText = () => {
        let messageId = `publishing.post-state.${relevantPostState}`

        if (isApprovalActive && relevantPostState === 'DRAFT') {
            if ((!isUserExternal && internalPending) || (isUserExternal && externalPending)) {
                messageId = 'publishing.approval.pending'
            } else if (needsRework) {
                messageId = 'publishing.approval.state.REWORK'
            } else if (externalApproved || (!isUserExternal && internalApproved)) {
                messageId = 'publishing.approval.state.APPROVED'
            }
        }

        return <FormattedMessage id={messageId} />
    }

    const generateNeedsApprovalBackgroundColor = (initialColor: string) => {
        if (relevantPostState === 'DRAFT' && isApprovalActive) {
            if (needsApproval) {
                return darkmode ? darken(theme.palette.secondary.dark, 0.4) : lighten(theme.palette.secondary.main, 0.8)
            } else if (needsRework) {
                return darkmode ? darken(theme.palette.error.dark, 0.5) : lighten(theme.palette.error.main, 0.8)
            } else if (externalApproved || (!isUserExternal && internalApproved)) {
                return darkmode ? darken(theme.palette.success.dark, 0.4) : lighten(theme.palette.success.main, 0.8)
            } else {
                return initialColor
            }
        }

        return initialColor
    }

    const generateNeedsApprovalBorderColor = (initialColor: string) => {
        if (!isApprovalActive) {
            return initialColor
        }

        if (needsApproval) {
            return theme.palette.secondary.main
        } else if (needsRework) {
            return theme.palette.error.main
        } else if (externalApproved || (!isUserExternal && internalApproved)) {
            return theme.palette.success.main
        } else {
            return initialColor
        }
    }
    const getColors = (): { border?: string; backgroundColor: string; text: string } => {
        switch (relevantPostState) {
            case 'DRAFT':
                return {
                    backgroundColor: generateNeedsApprovalBackgroundColor(darkmode ? theme.palette.grey.A700 : theme.palette.grey.A400),
                    border: generateNeedsApprovalBorderColor(darkmode ? theme.palette.secondary.light : theme.palette.grey['800']),
                    text: theme.palette.text.secondary,
                }
            case 'PLANNED':
            case 'PLANNED_BY_FACEBOOK':
            case 'PUBLISH_NOW':
                return {
                    backgroundColor: darkmode ? theme.palette.secondary.dark : theme.palette.secondary.light,
                    text: 'white',
                }
            case 'PUBLISH_IN_PROGRESS':
                return {
                    backgroundColor: darkmode ? theme.palette.secondary.dark : lighten(theme.palette.secondary.light, 0.25),
                    text: 'white',
                }
            case 'PUBLISH_SUCCESS':
                return {
                    backgroundColor: darkmode ? theme.palette.success.dark : theme.palette.success.light,
                    text: 'white',
                }
            case 'PUBLISH_FAILED':
                return {
                    backgroundColor: darkmode ? theme.palette.error.dark : theme.palette.error.light,
                    text: 'white',
                }
        }
    }
    const colors = getColors()

    const datasourceTypes = _.chain(postGroup.posts)
        .map((post) => {
            return post.data_source_type
        })
        .uniq()
        .value()

    const renderDatasources = () => {
        if (datasourceTypes.length > 1) {
            return (
                <Box>
                    <Dot size={22} color={colors.border || colors.text}>
                        <span style={{ fontWeight: 600, color: colors.backgroundColor }}>{datasourceTypes.length}</span>
                    </Dot>
                </Box>
            )
        }
        return (
            <Box display={'inline-flex'} gap={0.5}>
                {datasourceTypes.map((dsType) => (
                    <Fragment key={dsType}>{getIconForDatasourceType(dsType, 'small')}</Fragment>
                ))}
            </Box>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    p: 1,
                    py: 0.8,
                    position: 'relative',
                    transition: 'all 0.3s',
                    background: colors.backgroundColor,
                    color: colors.text,
                    boxShadow: theme.shadows[2],
                    borderTopRightRadius: theme.shape.borderRadius,
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: compactCalendar ? theme.shape.borderRadius : 0,
                    borderBottomLeftRadius: compactCalendar ? theme.shape.borderRadius : 0,
                    overflow: 'hidden',
                    borderLeft: `4px solid ${colors.border || 'transparent'}`,
                }}
            >
                <Typography variant={'body2'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} fontWeight={'bold'}>
                    {renderBarText()}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: colors.text,
                        gap: 0.5,
                    }}
                >
                    {allPostsStories && <History />}
                    {renderDatasources()}
                </Box>
            </Box>
            {relevantPostState === 'PUBLISH_IN_PROGRESS' && <LinearProgress color="secondary" sx={{ height: 3 }} />}
        </>
    )
}
