import { TimePeriodWithCompare } from './TimePeriodWithCompare'
import moment from 'moment'

export const defaultMonthlyPeriod: TimePeriodWithCompare = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    compareStartDate: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    compareEndDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
}
export const defaultYearlyPeriod: TimePeriodWithCompare = {
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').format('YYYY-MM-DD'),
    compareStartDate: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
    compareEndDate: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
}

export const defaultLastMonthPeriod: TimePeriodWithCompare = {
    startDate: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    compareStartDate: moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
    compareEndDate: moment().subtract(2, 'month').endOf('month').format('YYYY-MM-DD'),
}

export const defaultLast30DaysPeriod: TimePeriodWithCompare = {
    startDate: moment().subtract(30, 'days').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    compareStartDate: moment().subtract(30, 'days').toISOString(),
    compareEndDate: moment().endOf('day').toISOString(),
}
