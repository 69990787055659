import React, { FC, useEffect } from 'react'
import { Form } from 'react-final-form'
import { Post } from './posts/Post'
import { useDispatch, useSelector } from 'react-redux'
import { getDatasourcesAsIdMap, getIsMobile } from '../core/slices/CoreSlice'
import { changePostDialogSelectedNetwork, changePostDialogSelectedTab, getPostDialogSelectedTab, getSelectedPostGroup } from './PublishingSlice'
import { PublishingFormComponent } from './PublishingFormComponent'
import { PublishingFormComponentMobile } from './PublishingFormComponentMobile'
import { PublishingPostGroupActivities } from './PublishingPostGroupActivities'
import { Box, Paper } from '@mui/material'

type PublishingFormProps = {
    onSubmit: (values: any, form: any) => any
    initialValues: Partial<PublishingFormValues>
    disabled?: boolean
    disableNewPosts?: boolean
    overwritePostState?: string
}

export type PublishingFormValues = {
    project_id: string
    group_id: string
    data_source_ids: string[]
    assignee_id: string
    tag_ids: string[]
    common_post: Post
    customize_posts_by_network: boolean
    errors_by_network: {
        FACEBOOK_PAGE?: number
        LINKED_IN?: number
        LINKED_IN_PROFILE?: number
        INSTAGRAM_ACCOUNT?: number
        TIKTOK_ACCOUNT?: number
    }
    postByType: {
        FACEBOOK_PAGE?: Post
        LINKED_IN?: Post
        LINKED_IN_PROFILE?: Post
        INSTAGRAM_ACCOUNT?: Post
        TIKTOK_ACCOUNT?: Post
    }
}

export type ValidDatasourceTypeForPublishing = 'FACEBOOK_PAGE' | 'INSTAGRAM_ACCOUNT' | 'LINKED_IN' | 'TIKTOK_ACCOUNT'

export const PublishingForm: FC<PublishingFormProps> = ({ onSubmit, initialValues, disabled, overwritePostState }) => {
    const dispatch = useDispatch()
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const selectedTab = useSelector(getPostDialogSelectedTab)
    const isMobile = useSelector(getIsMobile)
    const postGroup = useSelector(getSelectedPostGroup)

    useEffect(() => {
        if (initialValues.data_source_ids && initialValues.data_source_ids.length > 0) {
            const datasourceTypes = initialValues.data_source_ids.map((dsId) => datasourcesById[dsId]?.type)
            const datasourceType = datasourceTypes[0]
            dispatch(changePostDialogSelectedNetwork(datasourceType as ValidDatasourceTypeForPublishing))

            const isInternalExternal = ['INTERNAL', 'EXTERNAL'].includes(selectedTab)
            const isCommon = !initialValues.customize_posts_by_network && selectedTab === 'COMMON'
            const isCustomized = !!initialValues.customize_posts_by_network && datasourceTypes.includes(selectedTab as any)
            const validSelectedTab = isInternalExternal || isCommon || isCustomized
            if (!validSelectedTab) {
                dispatch(
                    changePostDialogSelectedTab(initialValues.customize_posts_by_network ? (datasourceType as ValidDatasourceTypeForPublishing) : 'COMMON')
                )
            }
        } else {
            dispatch(changePostDialogSelectedTab('COMMON'))
        }

        return () => {
            dispatch(changePostDialogSelectedTab('COMMON'))
            dispatch(changePostDialogSelectedNetwork(undefined))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form<PublishingFormValues>
            initialValues={initialValues}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize={true}
            render={({ handleSubmit }) => {
                return isMobile ? (
                    <>
                        <PublishingFormComponentMobile handleSubmit={handleSubmit} disabled={disabled} overwritePostState={overwritePostState} />
                        {postGroup && (
                            <Box component={Paper}>
                                <PublishingPostGroupActivities postGroup={postGroup} />
                            </Box>
                        )}
                    </>
                ) : (
                    <PublishingFormComponent handleSubmit={handleSubmit} disabled={disabled} overwritePostState={overwritePostState} />
                )
            }}
        />
    )
}
