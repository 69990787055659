import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'

type ConnectedDropdownProps = {
    name: string
    label?: string
    helperText?: string
    shrinkLabel?: boolean
    disabled?: boolean
    validate?: any
    fullWidth?: boolean
    required?: boolean
    onChange?: (newType: string) => any
    validateFields?: string[]
    autoFocus?: boolean
    customKey?: string
    variant?: 'outlined' | undefined
}

export const ConnectedDropdown: FC<PropsWithChildren<ConnectedDropdownProps>> = (props) => {
    const { name, label, shrinkLabel, validate, helperText, fullWidth, children, disabled, required, onChange, validateFields = [], customKey, variant } = props

    return (
        <Field name={name} key={customKey} validate={validate} validateFields={validateFields}>
            {({ input, meta }) => {
                const handleChange = (event: SelectChangeEvent) => {
                    input.onChange(event)

                    if (onChange) {
                        onChange(event.target.value)
                    }
                }

                return (
                    <FormControl
                        error={meta.error && (meta.touched || meta.dirty || meta.initial !== undefined)}
                        fullWidth={fullWidth !== false}
                        disabled={disabled}
                        variant={variant}
                    >
                        {label && (
                            <InputLabel id={'select-' + name} shrink={shrinkLabel} variant={variant}>
                                <FormattedMessage id={label} />
                                {required ? ' *' : ''}
                            </InputLabel>
                        )}
                        <Select
                            variant={variant}
                            displayEmpty={true}
                            value={input.value || ''}
                            onChange={handleChange}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            labelId={'select-' + name}
                            label={label}
                            fullWidth={fullWidth !== false}
                            error={meta.error && (meta.touched || meta.dirty || meta.initial !== undefined)}
                        >
                            {children}
                        </Select>
                        <FormHelperText error={(meta.touched || meta.dirty || meta.initial !== undefined) && Boolean(meta.error)} sx={{ marginLeft: 0 }}>
                            {(meta.touched || meta.dirty || meta.initial !== undefined) && Boolean(meta.error) ? (
                                <FormattedMessage id={meta.error} />
                            ) : (
                                helperText && <FormattedMessage id={helperText} />
                            )}
                        </FormHelperText>
                    </FormControl>
                )
            }}
        </Field>
    )
}
