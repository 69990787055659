import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectDatasource } from './ProjectDatasource'
import { doDelete, doGet, doPost, doPut } from '../../core/api/ApiClient'
import { Project } from '../project-management/Project'
import { PotentialDatasource } from './PotentialDatasource'

const DATASOURCE_SLASH = (action: string) => `datasource/${action}`

export const fetchDatasource = createAsyncThunk<ProjectDatasource, ProjectDatasource>(DATASOURCE_SLASH('fetchById'), async (datasource, thunkAPI) => {
    return await doGet(thunkAPI, datasource._links.self)
})

export const createDatasourceForProject = createAsyncThunk<
    ProjectDatasource,
    {
        project: Project
        datasource: PotentialDatasource
    }
>(DATASOURCE_SLASH('create'), async ({ datasource, project }, thunkAPI) => {
    return await doPost(thunkAPI, project._links.datasources, datasource)
})

export const updateDatasourceInProject = createAsyncThunk<
    ProjectDatasource,
    {
        project: Project
        datasource: ProjectDatasource
    }
>(DATASOURCE_SLASH('update'), async ({ datasource, project }, thunkAPI) => {
    return await doPut(thunkAPI, datasource._links.self, datasource)
})

export const deleteDatasourceFromProject = createAsyncThunk<
    ProjectDatasource,
    {
        project: Project
        datasource: ProjectDatasource
    }
>(DATASOURCE_SLASH('delete'), async ({ datasource, project }, thunkAPI) => {
    return await doDelete(thunkAPI, datasource._links.self)
})
