import * as React from 'react'
import { FC } from 'react'
import { IdMap } from '../../core/slices/DataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { DatasourceNotificationSettings } from './DatasourceNotificationSettings'
import { NotificationSettingsComponent } from './NotificationSettingsComponent'
import { getCurrentUser, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { useIntl } from 'react-intl'
import { getAreNotificationSettingsLoading, getNotificationSettingsForUser } from '../SettingsSlice'
import { updateNotificationSettingsForUser } from '../SettingsActions'
import { HmstrDispatch } from '../../core/Store'
import { Loading } from '../../common/loading/Loading'
import { useParams } from 'react-router-dom'

export type NotificationSettingsTabType = 'publish' | 'engage' | 'analyse' | 'core'

type NotificationSettingsTabProps = {}

export const NotificationSettingsTab: FC<NotificationSettingsTabProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const currentUser = useSelector(getCurrentUser)
    const { tab } = useParams<{ tab: NotificationSettingsTabType }>()
    const settingsForUser = useSelector(getNotificationSettingsForUser)
    const isLoading = useSelector(getAreNotificationSettingsLoading)

    const handleSubmit = (values: IdMap<DatasourceNotificationSettings>) => {
        return new Promise((resolve) => {
            dispatch(
                updateNotificationSettingsForUser({
                    user: currentUser,
                    request: Object.values(values),
                })
            ).then(() => {
                dispatch(showSuccessSnackbar(intl.formatMessage({ id: 'general.settings-saved-snackbar' })))
                resolve(true)
            })
        })
    }

    if (isLoading || !tab) {
        return <Loading />
    }

    return (
        <Form
            initialValues={settingsForUser}
            subscription={{ pristine: true, dirty: true, submitting: true }}
            onSubmit={handleSubmit}
            render={() => {
                return <NotificationSettingsComponent tab={tab} />
            }}
        />
    )
}
