import * as React from 'react'
import { FC } from 'react'
import { range } from 'lodash'
import { alpha, Box, Theme } from '@mui/material'
import { Circle } from '@mui/icons-material'
import { SxProps } from '@mui/system'

type DottedProgressProps = {
    progress: number
    totalSteps?: number
    color?: string
    circleSize?: number
    justify?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'center' | 'space-evenly'
    showOne?: boolean
    sx?: SxProps<Theme>
}

export const DottedProgress: FC<DottedProgressProps> = ({
    progress,
    totalSteps = 10,
    color = '#000',
    circleSize = 10,
    justify = 'center',
    showOne = false,
    sx,
}) => {
    const totalRange = range(1, totalSteps + 1)
    if (totalSteps > 1 || showOne)
        return (
            <Box display={'inline-flex'} gap={1} flexShrink={1} justifyContent={justify} alignItems={'center'} my={1} sx={sx}>
                {totalRange.map((idx) => {
                    return (
                        <Circle
                            key={`progressDot${idx}`}
                            sx={{
                                color: alpha(color, progress >= idx ? 1 : 0.3),
                                fontSize: `${circleSize}px`,
                            }}
                        />
                    )
                })}
            </Box>
        )
    else return null
}
