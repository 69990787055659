import { Product, ProductMetadata } from '../accounts/Product'
import { AccountFormValues } from '../accounts/AccountFormValues'
import { BillingSubscription, SubscriptionItem } from './BillingSubscription'
import { IdMap } from '../../core/slices/DataSlice'

export const defaultAccountFormValues: Partial<AccountFormValues> = {
    name: '',
    package_type: 'basic',
    additional_user: 0,
    additional_datasource: 0,
    conversion_tracking: 0,
    interval: 'month',
}

export const getQuantityForAdditionalFeature = (
    subscription: BillingSubscription,
    products: IdMap<Product>,
    feature: ProductMetadata['additional_feature']
) => {
    const subscriptionItem = (subscription?.items.data || []).find((item) => {
        const p = typeof item.plan.product === 'string' ? products[item.plan.product] : item.plan.product
        return p.metadata.additional_feature === feature
    })

    return subscriptionItem?.quantity || 0
}

export const getBaseProductForSubscription = (subscription: BillingSubscription, products: IdMap<Product>) => {
    return (subscription?.items.data || [])
        .map((item) => (typeof item.plan.product === 'string' ? products[item.plan.product] : item.plan.product))
        .find((p) => Boolean(p?.metadata.package))
}

export const getProductForSubscriptionItem = (item: SubscriptionItem, products: IdMap<Product>) => {
    return typeof item.plan.product === 'string' ? products[item.plan.product] : item.plan.product
}
