import { FormattedMessage } from 'react-intl'
import { Box, Tooltip, Typography } from '@mui/material'
import { SyncProblem } from '@mui/icons-material'

export const NoData = () => {
    return (
        <Tooltip title={<FormattedMessage id={'general.no-data-available.tooltip'} />} disableInteractive>
            <Box
                sx={{ width: '100%', height: '100%', opacity: 0.3 }}
                display={'flex'}
                color={'primary'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <SyncProblem fontSize={'large'} />
                <Typography textAlign={'center'}>
                    <FormattedMessage id={'general.no-data-available'} />
                </Typography>
            </Box>
        </Tooltip>
    )
}
