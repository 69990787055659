import * as React from 'react'
import { FC, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { clearResults, getSelectedResultToRefine, setAiDialogOpen, setSelectedResultToRefine } from './PostTextGenerationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { useForm } from 'react-final-form'
import { PostTextSuggestionActions } from './PostTextSuggestionActions'
import { formatPostText } from '../../core/theme/helper'
import { isDarkMode } from '../../core/slices/CoreSlice'
import { SparkleBackground } from '../../common/ai/SparkleBackground'

type PostTextSuggestionProps = {
    result: string
    index: number
    handleSelect: (val: string) => void
}

export const PostTextSuggestion: FC<PostTextSuggestionProps> = ({ result, index, handleSelect }) => {
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)
    const accentColor = darkmode ? theme.palette.success.dark : theme.palette.success.light
    const accentColorSecond = theme.palette.success.main
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedResultToRefine = useSelector(getSelectedResultToRefine)
    const [hovered, setHovered] = useState(false)
    const form = useForm()
    const handleClick = () => {
        handleSelect(result)
        form.reset()
        dispatch(clearResults())
        dispatch(setAiDialogOpen(false))
    }
    const handleRefine = () => {
        dispatch(setSelectedResultToRefine(result))
    }

    return (
        <Box
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            sx={{
                position: 'relative',
                border: `1px solid ${theme.palette.action.disabled}`,
                borderRadius: theme.shape.borderRadius,
                background: theme.palette.background.paper,
                transition: 'all 0.1s',
                '&:hover, &:hover .resultIndex': {
                    borderColor: accentColor,
                },
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    p: 2,
                    borderRadius: theme.shape.borderRadius,
                    overflow: 'hidden',
                    '& .particlesFullHeight': {
                        height: '100%',
                        zIndex: -1,
                    },
                }}
            >
                {hovered && !selectedResultToRefine && (
                    <SparkleBackground sparkleKey={index} colors={[accentColor, accentColorSecond]} maxWidth={300} maxHeight={100} particleAmount={200} />
                )}
                <Box sx={{ zIndex: 2, position: 'relative', whiteSpace: 'break-spaces' }}>{formatPostText(result, darkmode)}</Box>
            </Box>
            <PostTextSuggestionActions hovered={hovered} index={index} handleSelect={handleClick} handleRefine={handleRefine} accentColor={accentColor} />
        </Box>
    )
}
