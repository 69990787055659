import axios, { AxiosPromise, AxiosRequestConfig, RawAxiosRequestConfig } from 'axios'
import { ApiLink } from './ApiLink'
import { showCoreError } from '../slices/CoreSlice'

export interface ApiError {
    status: number
    error_id: string
    detail?: string
}

const handleResponse = <T>(axiosPromise: AxiosPromise<T>, thunkAPI: any, transform?: Function): Promise<T> => {
    return axiosPromise
        .then((response) => {
            return transform ? transform(response.data) : response.data
        })
        .catch((error) => {
            if (error.status === 500 && error.data) {
                thunkAPI.dispatch(showCoreError(error.data))
                return thunkAPI.rejectWithValue(error.data as ApiError)
            }

            return thunkAPI.rejectWithValue(error.data.detail as ApiError)
        })
}

export const doGet = <T = any, A = T>(thunkAPI: any, target: string | ApiLink, transform?: (args: A) => T, config?: RawAxiosRequestConfig): Promise<T> => {
    const axiosPromise = axios.get(typeof target === 'string' ? target : target.href, config)
    return handleResponse<T>(axiosPromise, thunkAPI, transform)
}

export const doPost = <T = any>(
    thunkAPI: any,
    target: string | ApiLink,
    payload: any,
    transform?: (args: any) => T,
    config?: AxiosRequestConfig
): Promise<T> => {
    const axiosPromise = axios.post(typeof target === 'string' ? target : target.href, payload, config)

    return handleResponse<T>(axiosPromise, thunkAPI, transform)
}

export const doPut = <T = any>(
    thunkAPI: any,
    target: string | ApiLink,
    payload: any,
    transform?: (args: any) => T,
    config?: AxiosRequestConfig
): Promise<T> => {
    const axiosPromise = axios.put(typeof target === 'string' ? target : target.href, payload, config)

    return handleResponse<T>(axiosPromise, thunkAPI, transform)
}

export const doDelete = <T = any>(thunkAPI: any, target: string | ApiLink, transform?: (args: any) => T, config?: AxiosRequestConfig): Promise<T> => {
    const axiosPromise = axios.delete(typeof target === 'string' ? target : target.href, config)

    return handleResponse<T>(axiosPromise, thunkAPI, transform)
}
