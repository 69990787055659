import { FC } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import moment, { Moment } from 'moment'
import { useSelector } from 'react-redux'
import { getSelectedCalendarTimePeriod, getSelectedStartDate } from '../PublishingSlice'
import { isDarkMode } from '../../core/slices/CoreSlice'

type CalendarDayNumberProps = {
    day: Moment
}

export const CalendarDayNumber: FC<CalendarDayNumberProps> = ({ day }) => {
    const theme = useTheme()
    const selectedMonth = useSelector(getSelectedStartDate)
    const isToday = day.isSame(moment(), 'day')
    const inSelectedMonth = day.month() === moment(selectedMonth).month()
    const calendarTimePeriod = useSelector(getSelectedCalendarTimePeriod)
    const isWeekView = calendarTimePeriod === 'week'
    const darkmode = useSelector(isDarkMode)
    const highlightColor = darkmode ? theme.palette.secondary.dark : theme.palette.secondary.light

    return (
        <Box
            sx={{
                m: 0.5,
                right: 0,
                top: 0,
                position: 'absolute',
                backgroundColor: isToday ? highlightColor : undefined,
                color: isToday ? 'white' : inSelectedMonth || isWeekView ? undefined : 'text.disabled',
                borderRadius: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 32,
                height: 32,
            }}
        >
            <Typography sx={{ marginLeft: '-1px', fontWeight: isToday ? 600 : 400 }} variant="body2">
                {day.format('DD')}
            </Typography>
        </Box>
    )
}
