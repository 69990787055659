import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper } from '@mui/material'
import React, { FC, useEffect, useRef, useState } from 'react'
import { TooltipRenderProps } from 'react-joyride'
import { Check, Close, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { DottedProgress } from '../progress/DottedProgress'
import { useDispatch, useSelector } from 'react-redux'
import { endTour, getGuideButtonsDeactivated } from './GuideSlice'
import { finishAllTours } from '../../settings/user-management/UserActions'
import { orderedAvailableTours } from './OrderedAvailableTours'
import { HmstrDispatch } from '../../core/Store'

export const CustomTourTooltip: FC<TooltipRenderProps> = ({
    continuous,
    index,
    step,
    skipProps,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    size,
}) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const guideButtonsDeactivated = useSelector(getGuideButtonsDeactivated)
    const [skipDialogOpen, setSkipDialogOpen] = useState(false)
    const continueRef = useRef<any>(null)
    const backRef = useRef<any>(null)
    const intl = useIntl()

    useEffect(() => {
        document.addEventListener('keyup', handleKeyboardInput)
        return () => {
            document.removeEventListener('keyup', handleKeyboardInput)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFinishAllTours = () => {
        dispatch(finishAllTours()).then(() => {
            orderedAvailableTours.forEach((tour) => {
                dispatch(endTour(tour))
            })
        })
    }

    const handleKeyboardInput = (event: KeyboardEvent) => {
        if (event.code === 'ArrowRight' && continueRef.current && !guideButtonsDeactivated) {
            continueRef.current.click()
        } else if (event.code === 'ArrowLeft' && backRef.current && !guideButtonsDeactivated) {
            backRef.current.click()
        }
    }
    return (
        <>
            <Paper {...tooltipProps} elevation={3}>
                <DialogTitle
                    minWidth={400}
                    sx={{ maxWidth: 600 }}
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    noWrap
                >
                    <DottedProgress
                        progress={index + 1}
                        totalSteps={size}
                        color={step.styles?.options?.primaryColor}
                        justify={'flex-start'}
                        circleSize={7}
                        sx={{ mr: 2 }}
                    />
                    {step.title && typeof step.title === 'string' && (
                        <Box flexShrink={1} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                            {intl.formatMessage({ id: step.title })}
                        </Box>
                    )}
                    <span></span>
                    <Box display={'inline-flex'} flexShrink={1}>
                        <IconButton
                            onClick={() => {
                                setSkipDialogOpen(true)
                            }}
                            size={'small'}
                            sx={{ float: 'left' }}
                            color={'error'}
                        >
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                {typeof step.content === 'string' && (
                    <DialogContent sx={{ minWidth: 400, maxWidth: 600 }}>
                        <FormattedMessage id={step.content} />
                    </DialogContent>
                )}
                <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', maxWidth: 600 }}>
                    <Box display={'inline-flex'}>
                        {index > 0 && (
                            <Button ref={backRef} {...backProps} color={'inherit'} disabled={guideButtonsDeactivated}>
                                <KeyboardArrowLeft />
                            </Button>
                        )}
                        {continuous && !isLastStep && (
                            <Button ref={continueRef} color={'secondary'} {...primaryProps} disabled={guideButtonsDeactivated}>
                                <KeyboardArrowRight />
                            </Button>
                        )}
                        {(!continuous || isLastStep) && (
                            <Button ref={continueRef} color={'success'} {...closeProps} data-action={'primary'} disabled={guideButtonsDeactivated}>
                                <Check />
                            </Button>
                        )}
                    </Box>
                </DialogActions>
            </Paper>
            <Dialog
                open={skipDialogOpen}
                onClose={() => {
                    setSkipDialogOpen(false)
                }}
                sx={{ zIndex: 9999 }}
            >
                <DialogTitle>
                    <FormattedMessage id={'guide.skip'} />
                </DialogTitle>
                <DialogActions sx={{ minWidth: 400 }}>
                    <Button onClick={handleFinishAllTours}>
                        <FormattedMessage id={'guide.skip-all'} />
                    </Button>
                    <Button color={'secondary'} variant={'contained'} sx={{ textWrap: 'noWrap' }} {...skipProps}>
                        <FormattedMessage id={'guide.skip-only-this'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
