import { FC, useState } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { Moment } from 'moment'
import { CalendarDayNumber } from './CalendarDayNumber'
import { CalendarDayAddButton } from './CalendarDayAddButton'
import { useSelector } from 'react-redux'
import { getPostGroupsForProjectByDay, getSelectedCalendarTimePeriod } from '../PublishingSlice'
import { PostGroup } from '../post-groups/PostGroup'
import { PublishingCalendarPostCard } from '../PublishingListCardComponents/PublishingCalendarPostCard'

type CalendarDayProps = {
    day: Moment
    isFirstDay: boolean
    inSelectedMonth: boolean
    week: number
    maxPosition: number
    biggestDayInWeek: number
    countOfWeeks: number
}

export const CalendarDay: FC<CalendarDayProps> = ({ day, isFirstDay, week, inSelectedMonth }) => {
    const theme = useTheme()
    const [mouseOver, setMouseOver] = useState(false)
    const postGroupsByDay = useSelector(getPostGroupsForProjectByDay)
    const postGroupForDay: PostGroup[] = postGroupsByDay[day.format('YYYY-MM-DD')] || []
    const calendarView = useSelector(getSelectedCalendarTimePeriod)
    const isWeekView = calendarView === 'week'
    const dayStyles = {
        position: 'relative',
        display: isWeekView ? 'inline-flex' : 'table-cell',
        verticalAlign: isWeekView ? 'top' : 'unset',
        height: '100%',
        textAlign: 'right',
        width: 1 / 7,
        maxWidth: 1 / 7,
        minHeight: 130,
        borderTop: 1,
        borderRight: 1,
        paddingTop: 1,
        paddingBottom: 5,
        borderColor: 'divider',
        '&:last-child': {
            borderRight: 0,
        },
        backgroundColor: isWeekView ? 'inherit' : inSelectedMonth ? (mouseOver ? theme.palette.secondary.main + '40' : 'inherit') : 'divider',
    }

    return (
        <Grid item sx={dayStyles} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
            <CalendarDayNumber day={day} />

            {isFirstDay && (
                <Box sx={{ position: 'absolute', pl: 1, opacity: 0.8 }}>
                    <Typography color="text.secondary" variant="body2">
                        {week}
                    </Typography>
                </Box>
            )}

            <Box mt={3} textAlign="left" p={1} sx={{ width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                <Grid container spacing={0.5}>
                    {postGroupForDay.map((postGroup) => (
                        <Grid key={postGroup.id} item xs={12} zIndex={2}>
                            <PublishingCalendarPostCard postGroup={postGroup} showOnlyTime />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {mouseOver && inSelectedMonth && <CalendarDayAddButton day={day} />}
        </Grid>
    )
}
