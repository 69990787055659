import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedProjectId, setLastPageForModule } from '../slices/CoreSlice'
import { NavigateOptions } from 'react-router/dist/lib/context'

export const useProjectNavigate = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selectedProjectId = useSelector(getSelectedProjectId)

    return (path: string, options?: NavigateOptions) => {
        const splat = path.substring(1).split(/\/(.*)/s)
        const module = splat[0] as any
        const page = splat[1]

        if (module && page && ['publishing', 'analytics', 'engagement', 'settings'].includes(module) && options?.replace === undefined) {
            dispatch(setLastPageForModule({ module, page }))
        }
        navigate(`/${selectedProjectId}/${path}`, options)
    }
}
