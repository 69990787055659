import _ from 'lodash'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { Account } from '../../settings/accounts/Account'
import {
    createAccount,
    deleteAccount,
    fetchAccount,
    fetchAccountsForUser,
    updateAccount,
    updateAccountAdditionals,
    updateAccountBillingInfo,
    updateAccountPackage,
    upgradeAccount,
} from '../../settings/accounts/AccountActions'
import {
    createProject,
    deleteProject,
    fetchAllProjects,
    fetchProject,
    fetchProjectsForAccount,
    updateProject,
} from '../../settings/project-management/ProjectActions'
import { Invite } from '../../settings/user-management/Invite'
import { User } from '../../settings/user-management/User'
import {
    deleteAccountAccess,
    deleteFederatedIdentity,
    deleteInvite,
    fetchFederatedIdentites,
    fetchInvitesForAccount,
    fetchPotentialDatasources,
    fetchUsersForAccount,
    fetchUsersForProject,
    inviteUserToAccount,
    updateUserAccountAccess,
} from '../../settings/user-management/UserActions'
import { HmstrState } from '../Store'
import { Result } from '../../monitoring/Result'
import { fetchResultsForProject } from '../../monitoring/ResultsActions'
import { Dashboard } from '../../dashboards/Dashboard'
import { createDashboard, deleteDashboard, editDashboard, fetchDashboardsForProject, fetchTemplates } from '../../dashboards/DashboardsActions'
import { DashboardTemplate } from '../../dashboards/DashboardTemplate'
import { fetchCommonPostData, fetchCommonPostDataByPostIds } from '../../content-analytics/posts/PostsActions'
import { Product } from '../../settings/accounts/Product'
import { PaymentMethod } from '@stripe/stripe-js'
import { BillingSubscription } from '../../settings/billing/BillingSubscription'
import {
    cancelBillingSubscription,
    cancelSubscriptionUpdate,
    continueBillingSubscription,
    deletePaymentMethod,
    fetchInvoices,
    fetchPaymentMethods,
    fetchProducts,
    fetchSubscriptionById,
    fetchSubscriptionsForUser,
    updatePaymentMethodForSubscription,
} from '../../settings/billing/BillingActions'
import { BillingInvoice } from '../../settings/billing/BillingInvoice'
import { Tag } from '../../tags/Tag'
import { createTag, deleteTag, editTag, fetchTagsForProject } from '../../tags/TagsActions'
import { fetchFacebookConversionData } from '../../conversion-tracking/ConversionActions'
import { FacebookConversionData } from '../../conversion-tracking/FacebookConversionData'
import { ApiLink } from '../api/ApiLink'
import { CommonPostData } from '../../content-analytics/posts/CommonPostData'
import { FederatedIdentity } from '../../settings/linked-accounts/FederatedIdentity'
import { PotentialDatasource } from '../../settings/datasources/PotentialDatasource'
import { ProjectDatasourceType } from '../../settings/datasources/ProjectDatasource'
import { Project } from '../../settings/project-management/Project'
import { deleteMedia, updateMedia, uploadMedia } from '../../publishing/PublishingActions'
import { PublishingMedia } from '../../publishing/PublishingMedia'
import { SimpleTicket } from '../../engagement/SimpleTicket'
import { fetchSimpleTicketsForProject, findOrCreateMissingTicket, updateTicket } from '../../engagement/EngagementActions'
import { createDatasourceForProject, deleteDatasourceFromProject, updateDatasourceInProject } from '../../settings/datasources/ProjectDatasourceActions'
import { getMissingScopes } from '../../settings/linked-accounts/Scopes'
import { SimpleUser } from '../../settings/user-management/SimpleUser'
import { PostGroup } from '../../publishing/post-groups/PostGroup'
import {
    createPostGroup,
    deletePostGroup,
    deletePostGroupApproval,
    fetchPostGroupById,
    fetchPostGroups,
    requestPostGroupApproval,
    updatePostGroup,
    updatePostGroupApproval,
} from '../../publishing/post-groups/PostGroupActions'

export type IdMap<T> = {
    [id: string]: T
}

export interface DataState {
    projects: IdMap<Project>
    accounts: IdMap<Account>
    users: IdMap<User[]>
    simpleUsers: IdMap<IdMap<SimpleUser>>
    invites: IdMap<Invite[]>
    results: IdMap<Result[]>
    dashboards: IdMap<Dashboard>
    tags: IdMap<Tag>
    templates: IdMap<DashboardTemplate>
    products: IdMap<Product>
    paymentMethods: IdMap<PaymentMethod>
    subscriptions: IdMap<BillingSubscription>
    invoices: IdMap<BillingInvoice>
    conversionData: IdMap<IdMap<FacebookConversionData[]>>
    commonPostData: IdMap<IdMap<CommonPostData>>
    federated_identities: IdMap<FederatedIdentity>
    potential_datasources: IdMap<PotentialDatasource[]>
    media: IdMap<PublishingMedia>
    simpleTickets: IdMap<SimpleTicket>
    postGroups: IdMap<PostGroup>
}

export const initialDataState: DataState = {
    projects: {},
    accounts: {},
    users: {},
    simpleUsers: {},
    invites: {},
    results: {},
    dashboards: {},
    tags: {},
    templates: {},
    products: {},
    paymentMethods: {},
    subscriptions: {},
    invoices: {},
    conversionData: {},
    commonPostData: {},
    federated_identities: {},
    potential_datasources: {},
    media: {},
    simpleTickets: {},
    postGroups: {},
}

const handleSinglePostGroup = (action: PayloadAction<PostGroup>, state: HmstrState['data']) => {
    const mediaFromPosts = _.flatMap(action.payload.posts, (post) => post.media)
    const mediaById = _.mapKeys(mediaFromPosts, 'id')
    state.media = { ...state.media, ...mediaById }
    state.postGroups[action.payload.id] = action.payload
}

export const DataSlice = createSlice({
    name: 'data',
    initialState: initialDataState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createAccount.fulfilled, (state, action) => {
                state.accounts[action.payload.id] = action.payload
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.projects[action.payload.id] = action.payload
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                state.projects[action.payload.id] = action.payload
            })
            .addCase(fetchAccount.fulfilled, (state, action) => {
                state.accounts[action.payload.id] = action.payload
            })
            .addCase(fetchUsersForAccount.fulfilled, (state, action) => {
                state.users = { ...state.users, ...action.payload }
            })
            .addCase(fetchProject.fulfilled, (state, action) => {
                state.projects[action.payload.id] = action.payload
            })
            .addCase(fetchProjectsForAccount.fulfilled, (state, action) => {
                state.projects = {
                    ...state.projects,
                    ..._.mapKeys(action.payload, 'id'),
                }
            })
            .addCase(fetchAllProjects.fulfilled, (state, action) => {
                state.projects = {
                    ...state.projects,
                    ..._.mapKeys(action.payload, 'id'),
                }
            })
            .addCase(updateUserAccountAccess.fulfilled, (state, action) => {
                const usersForAcc = state.users[action.payload.accountId]
                const indexOfUser = usersForAcc.findIndex((u) => u.id === action.payload.data.id)
                indexOfUser === -1 ? usersForAcc.push(action.payload.data) : (usersForAcc[indexOfUser] = action.payload.data)
            })
            .addCase(fetchInvitesForAccount.fulfilled, (state, action) => {
                state.invites = { ...state.invites, ...action.payload }
            })
            .addCase(fetchResultsForProject.fulfilled, (state, action) => {
                state.results[action.meta.arg.id] = action.payload
            })
            .addCase(deleteAccount.fulfilled, (state, action) => {
                delete state.accounts[action.payload.account_id]
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                delete state.projects[action.payload.id]
            })
            .addCase(deleteInvite.fulfilled, (state, action) => {
                _.remove(state.invites[action.payload.account.account_id], (invite) => invite.id === action.payload.id)
            })
            .addCase(deleteAccountAccess.fulfilled, (state, action) => {
                _.remove(state.users[action.payload.access.account_id], (user) => user.id === action.payload.user_id)
            })
            .addCase('core/selectAccount', (state) => {
                state.projects = {}
                state.users = {}
            })
            .addCase('core/selectProject', (state) => {
                state.results = {}
                state.dashboards = {}
                state.commonPostData = {}
                state.tags = {}
                state.templates = {}
                state.conversionData = {}
                state.simpleTickets = {}
                state.postGroups = {}
                state.media = {}
            })
            .addCase(fetchDashboardsForProject.fulfilled, (state, action) => {
                state.dashboards = _.mapKeys(action.payload, 'id')
            })
            .addCase(fetchTagsForProject.fulfilled, (state, action) => {
                state.tags = {
                    ...state.tags,
                    ..._.mapKeys(action.payload, 'id'),
                }
            })
            .addCase(fetchTemplates.fulfilled, (state, action) => {
                state.templates = _.mapKeys(action.payload, 'id')
            })
            .addCase(createDashboard.fulfilled, (state, action) => {
                state.dashboards[action.payload.id] = action.payload
            })
            .addCase(editDashboard.fulfilled, (state, action) => {
                state.dashboards[action.payload.id] = action.payload
            })
            .addCase(deleteDashboard.fulfilled, (state, action) => {
                delete state.dashboards[action.payload.id]
            })
            .addCase(createTag.fulfilled, (state, action) => {
                state.tags[action.payload.id] = action.payload
            })
            .addCase(editTag.fulfilled, (state, action) => {
                state.tags[action.payload.id] = action.payload
            })
            .addCase(deleteTag.fulfilled, (state, action) => {
                delete state.tags[action.payload.id]
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.products = _.mapKeys(action.payload, 'id')
            })
            .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
                state.paymentMethods = {
                    ...state.paymentMethods,
                    ..._.mapKeys(action.payload, 'id'),
                }
            })
            .addCase(fetchAccountsForUser.fulfilled, (state, action) => {
                state.accounts = _.mapKeys(action.payload, 'id')
            })
            .addCase(fetchSubscriptionsForUser.fulfilled, (state, action) => {
                state.subscriptions = {
                    ...state.subscriptions,
                    ..._.mapKeys(action.payload, 'id'),
                }
            })
            .addCase(fetchInvoices.fulfilled, (state, action) => {
                state.invoices = {
                    ...state.invoices,
                    ..._.mapKeys(action.payload, 'id'),
                }
            })
            .addCase(updatePaymentMethodForSubscription.fulfilled, (state, action) => {
                state.subscriptions[action.payload.id] = action.payload
            })
            .addCase(cancelBillingSubscription.fulfilled, (state, action) => {
                state.subscriptions[action.payload.id] = action.payload
            })
            .addCase(continueBillingSubscription.fulfilled, (state, action) => {
                state.subscriptions[action.payload.id] = action.payload
            })
            .addCase(fetchSubscriptionById.fulfilled, (state, action) => {
                state.subscriptions[action.payload.id] = action.payload
            })
            .addCase(updateAccount.fulfilled, (state, action) => {
                state.accounts[action.payload.id] = action.payload
            })
            .addCase(upgradeAccount.fulfilled, (state, action) => {
                state.accounts[action.payload.id] = action.payload
            })
            .addCase(deletePaymentMethod.fulfilled, (state, action) => {
                delete state.paymentMethods[action.payload.id]
            })
            .addCase(updateAccountPackage.fulfilled, (state, action) => {
                state.accounts[action.payload.id] = action.payload
            })
            .addCase(updateAccountAdditionals.fulfilled, (state, action) => {
                state.accounts[action.payload.id] = action.payload
            })
            .addCase(fetchFacebookConversionData.fulfilled, (state, action) => {
                const datasource = action.meta.arg
                const conversionDataByDay: IdMap<FacebookConversionData[]> = _.groupBy(action.payload, 'day')

                state.conversionData[datasource.id] = {
                    ...state.conversionData[datasource.id],
                    ...conversionDataByDay,
                }
            })
            .addCase(fetchCommonPostData.fulfilled, (state, action) => {
                const postsByDatasource = _.groupBy(action.payload, 'data_source_id')
                const datasourceIds = Object.keys(postsByDatasource)

                datasourceIds.forEach((dsid) => {
                    state.commonPostData[dsid] = {
                        ...state.commonPostData[dsid],
                        ..._.mapKeys(postsByDatasource[dsid], 'id'),
                    }
                })
            })
            .addCase(fetchCommonPostDataByPostIds.fulfilled, (state, action) => {
                const postsByDatasource = _.groupBy(action.payload, 'data_source_id')
                const datasourceIds = Object.keys(postsByDatasource)

                datasourceIds.forEach((dsid) => {
                    state.commonPostData[dsid] = {
                        ...state.commonPostData[dsid],
                        ..._.mapKeys(postsByDatasource[dsid], 'id'),
                    }
                })
            })
            .addCase(fetchFederatedIdentites.fulfilled, (state, action) => {
                state.federated_identities = _.mapKeys(action.payload, 'id')
            })
            .addCase(fetchPotentialDatasources.fulfilled, (state, action) => {
                state.potential_datasources[action.meta.arg.type] = action.payload
            })
            .addCase(deleteFederatedIdentity.fulfilled, (state, action) => {
                delete state.federated_identities[action.meta.arg.id]
            })
            .addCase(cancelSubscriptionUpdate.fulfilled, (state, action) => {
                state.subscriptions[action.payload.id] = action.payload
            })
            .addCase(inviteUserToAccount.fulfilled, (state, action) => {
                if (typeof action.payload !== 'boolean') {
                    state.invites[action.payload.account.account_id].push(action.payload)
                }
            })
            .addCase(fetchPostGroupById.fulfilled, (state, action) => {
                handleSinglePostGroup(action, state)
            })
            .addCase(updatePostGroup.fulfilled, (state, action) => {
                handleSinglePostGroup(action, state)
            })
            .addCase(createPostGroup.fulfilled, (state, action) => {
                handleSinglePostGroup(action, state)
            })
            .addCase(requestPostGroupApproval.fulfilled, (state, action) => {
                handleSinglePostGroup(action, state)
            })
            .addCase(updatePostGroupApproval.fulfilled, (state, action) => {
                handleSinglePostGroup(action, state)
            })
            .addCase(deletePostGroupApproval.fulfilled, (state, action) => {
                handleSinglePostGroup(action, state)
            })
            .addCase(uploadMedia.fulfilled, (state, action) => {
                state.media[action.payload.id] = action.payload
            })
            .addCase(updateMedia.fulfilled, (state, action) => {
                state.media[action.payload.id] = action.payload
            })
            .addCase(deleteMedia.fulfilled, (state, action) => {
                delete state.media[action.payload.id]
            })
            .addCase(deletePostGroup.fulfilled, (state, action) => {
                delete state.postGroups[action.payload]
            })
            .addCase(fetchSimpleTicketsForProject.fulfilled, (state, action) => {
                if (action.meta.arg.lazyScroll) {
                    const val = _.mapKeys(action.payload, 'id')
                    state.simpleTickets = { ...state.simpleTickets, ...val }
                } else {
                    state.simpleTickets = _.mapKeys(action.payload, 'id')
                }
            })
            .addCase(updateTicket.fulfilled, (state, action) => {
                if (state.simpleTickets[action.payload.id] !== undefined) {
                    state.simpleTickets[action.payload.id].state = action.payload.state
                    state.simpleTickets[action.payload.id].assignee_id = action.payload.assignee_id
                }
            })
            .addCase(createDatasourceForProject.fulfilled, (state, action) => {
                state.projects[action.meta.arg.project.id].data_sources.push(action.payload)
            })
            .addCase(updateDatasourceInProject.fulfilled, (state, action) => {
                const index = _.findIndex(state.projects[action.meta.arg.project.id].data_sources, {
                    id: action.payload.id,
                })
                state.projects[action.meta.arg.project.id].data_sources.splice(index, 1, action.payload)
            })
            .addCase(deleteDatasourceFromProject.fulfilled, (state, action) => {
                _.remove(state.projects[action.meta.arg.project.id].data_sources, (ds) => ds.id === action.payload.id)
            })
            .addCase(findOrCreateMissingTicket.fulfilled, (state, action) => {
                state.simpleTickets[action.payload.id] = action.payload
            })
            .addCase(fetchUsersForProject.fulfilled, (state, action) => {
                state.simpleUsers[action.meta.arg.id] = _.mapKeys(action.payload, 'id')
            })
            .addCase(fetchPostGroups.fulfilled, (state, action) => {
                state.postGroups = _.mapKeys(action.payload, 'id')

                const postsFromGroup = _.flatMap(action.payload, (postGroup) => postGroup.posts)
                const mediaFromPosts = _.flatMap(postsFromGroup, (post) => post.media)
                const mediaById = _.mapKeys(mediaFromPosts, 'id')
                state.media = { ...state.media, ...mediaById }
            })
            .addCase(updateAccountBillingInfo.fulfilled, (state, action) => {
                const accountsById = _.mapKeys(action.payload, 'id')
                state.accounts = { ...state.accounts, ...accountsById }
            })
    },
})

export const getAccountById =
    (accountId: string): Selector<HmstrState, Account | undefined> =>
    (state) =>
        state.data.accounts[accountId]

export const getUsers: Selector<HmstrState, IdMap<User[]>> = (state) => state.data.users
export const getProjects: Selector<HmstrState, IdMap<Project>> = (state) => state.data.projects
export const getAccounts: Selector<HmstrState, IdMap<Account>> = (state) => state.data.accounts

export const getInvites: Selector<HmstrState, IdMap<Invite[]>> = (state) => state.data.invites
export const getResults: Selector<HmstrState, IdMap<Result[]>> = (state) => state.data.results
export const getCommonPostData: Selector<HmstrState, IdMap<IdMap<CommonPostData>>> = (state) => state.data.commonPostData
export const getDashboards: Selector<HmstrState, IdMap<Dashboard>> = (state) => state.data.dashboards
export const getDashboardsData: Selector<HmstrState, Dashboard[]> = (state) => {
    return Object.values(state.data.dashboards)
}
export const getTags: Selector<HmstrState, IdMap<Tag>> = (state) => state.data.tags
export const getTagsData: Selector<HmstrState, Tag[]> = (state) => {
    return Object.values(state.data.tags)
}
export const getTemplates: Selector<HmstrState, IdMap<DashboardTemplate>> = (state) => state.data.templates
export const getTemplateData: Selector<HmstrState, DashboardTemplate[]> = (state) => {
    return Object.values(state.data.templates)
}
export const getProducts: Selector<HmstrState, IdMap<Product>> = (state) => state.data.products
export const getPaymentMethods: Selector<HmstrState, IdMap<PaymentMethod>> = (state) => state.data.paymentMethods
export const getSubscriptions: Selector<HmstrState, IdMap<BillingSubscription>> = (state) => state.data.subscriptions
export const getInvoices: Selector<HmstrState, IdMap<BillingInvoice>> = (state) => state.data.invoices
export const getConversionData: Selector<HmstrState, DataState['conversionData']> = (state) => state.data.conversionData
export const getGatewayLink: Selector<HmstrState, ApiLink> = (state) => state.api.gatewayLink
export const getFederatedIdentites: Selector<HmstrState, IdMap<FederatedIdentity>> = (state) => state.data.federated_identities
export const getMedia: Selector<HmstrState, IdMap<PublishingMedia>> = (state) => state.data.media
export const getSimpleTickets: Selector<HmstrState, IdMap<SimpleTicket>> = (state) => state.data.simpleTickets
export const getPotentialDatasources: Selector<HmstrState, DataState['potential_datasources']> = (state) => state.data.potential_datasources
export const getSimpleUsers: Selector<HmstrState, DataState['simpleUsers']> = (state) => state.data.simpleUsers
export const getPostGroups: Selector<HmstrState, DataState['postGroups']> = (state) => state.data.postGroups

export const getPotentialDatasourcesForType =
    (type: ProjectDatasourceType) =>
    (state: HmstrState): PotentialDatasource[] =>
        state.data.potential_datasources[type] || []

export const getPotentialDatasourcesById = createSelector([getPotentialDatasources], (potentialDatasources): IdMap<PotentialDatasource> => {
    return _.chain(potentialDatasources).values().flatten().mapKeys('metadata.id').value()
})

export const getProductsWithoutTrial = createSelector([getProducts], (products) => {
    return _.chain(products)
        .values()
        .filter((p) => p.metadata.package !== 'trial')
        .mapKeys('id')
        .value()
})

export const getCommonPostDataAsIdMap = createSelector([getCommonPostData], (commonPostData) => _.mapValues(commonPostData, (p) => _.values(p)))

export const getScopeErrors = createSelector([getFederatedIdentites], (federatedIdentities) => {
    const scopeErrors: IdMap<string[]> = {}
    _.forEach(federatedIdentities, (identity) => {
        const missingScopes = getMissingScopes(identity.type, identity.scope)
        if (missingScopes.length > 0) {
            scopeErrors[identity.id] = missingScopes
        }
    })
    return scopeErrors
})

export const getSortedTagsData = createSelector([getTagsData], (tagsData) => {
    tagsData.sort((a, b) => {
        if (a.active && b.active) {
            return a.label.localeCompare(b.label)
        } else if (a.active) {
            return -1
        } else if (b.active) {
            return 1
        } else {
            return a.label.localeCompare(b.label)
        }
    })
    return tagsData
})

// export const { } = DataSlice.actions
export const DataReducer = DataSlice.reducer
