import * as _ from 'lodash'
import moment from 'moment'
import { Result } from './Result'
import { HmstrState } from '../core/Store'
import { AreaChartData } from '../common/chart-data-types/AreaChartData'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { getResults } from '../core/slices/DataSlice'
import { getDatasourcesAsIdMap, getSelectedProject, getSelectedProjectId } from '../core/slices/CoreSlice'
import { fetchResultsForProject } from './ResultsActions'
import { SourceTypeColors } from './SourceTypeColors'
import { PieChartData } from '../common/chart-data-types/PieChartData'

interface ResultsState {
    isLoadingResults: boolean
    selectedTimePeriodStartDate: string
    selectedTimePeriodEndDate: string
    selectedDatasourceIds: string[]
    selectedTags: string[]
    selectedMediaTypes: string[]
    selectedChartType: 'line' | 'bar'
    resultsGroupedBy?: keyof Result
    resultsSortedBy: keyof Result
    resultsSortDirection: 'asc' | 'desc'
    showPieChart: boolean
    showGraph: boolean
    searchTerm: string
    opinionLeaderSearchTerm: string
    opinionLeaderSortBy: string
    opinionLeaderSortDirection: 'asc' | 'desc'
    showResultsPreviewDialog: boolean
    previewFilter: {
        startDate?: string
        endDate?: string
        selectedDatasourceId?: string
        selectedMediaType?: string
        domain?: string
        allowDrilldown?: boolean
    }
}

const initialState: ResultsState = {
    isLoadingResults: false,
    selectedTimePeriodStartDate: moment().subtract(30, 'days').format(),
    selectedTimePeriodEndDate: moment().format(),
    selectedDatasourceIds: [],
    selectedMediaTypes: [],
    selectedTags: [],
    selectedChartType: 'line',
    showPieChart: false,
    showGraph: true,
    searchTerm: '',
    resultsSortedBy: 'published_ts',
    resultsSortDirection: 'desc',
    opinionLeaderSearchTerm: '',
    opinionLeaderSortBy: 'total',
    opinionLeaderSortDirection: 'desc',
    showResultsPreviewDialog: false,
    previewFilter: {},
}

export const ResultsSlice = createSlice({
    name: 'results',
    initialState,
    reducers: {
        changeTimePeriod: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
            state.selectedTimePeriodStartDate = action.payload.startDate
            state.selectedTimePeriodEndDate = action.payload.endDate
        },
        changeSelectedDatasourceIds: (state, action: PayloadAction<string[]>) => {
            state.selectedDatasourceIds = action.payload
        },
        changeGroupBy: (state, action: PayloadAction<keyof Result>) => {
            state.resultsGroupedBy = action.payload
        },
        changeSelectedMediaTypes: (state, action: PayloadAction<string[]>) => {
            state.selectedMediaTypes = action.payload
        },
        changeSelectedTags: (state, action: PayloadAction<string[]>) => {
            state.selectedTags = action.payload
        },
        selectChartType: (state, action: PayloadAction<ResultsState['selectedChartType']>) => {
            state.selectedChartType = action.payload
        },
        togglePieChart: (state) => {
            state.showPieChart = !state.showPieChart
        },
        toggleGraph: (state) => {
            state.showGraph = !state.showGraph
        },
        changeResultsSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload
        },
        changeResultsSortedBy: (state, action: PayloadAction<keyof Result>) => {
            state.resultsSortedBy = action.payload
        },
        toggleResultsSortDirection: (state) => {
            state.resultsSortDirection = state.resultsSortDirection === 'desc' ? 'asc' : 'desc'
        },
        changeOpinionLeaderSearchTerm: (state, action: PayloadAction<string>) => {
            state.opinionLeaderSearchTerm = action.payload
        },
        changeOpinionLeaderSorting: (
            state,
            action: PayloadAction<{
                sortBy: string
                sortDirection: 'asc' | 'desc'
            }>
        ) => {
            state.opinionLeaderSortBy = action.payload.sortBy
            state.opinionLeaderSortDirection = action.payload.sortDirection
        },
        openPreviewDialog: (state, action: PayloadAction<ResultsState['previewFilter']>) => {
            state.showResultsPreviewDialog = true
            state.previewFilter = action.payload
        },
        closePreviewDialog: (state) => {
            state.showResultsPreviewDialog = false
        },
        previewDrillDown: (state) => {
            state.showResultsPreviewDialog = false

            if (state.previewFilter.startDate && state.previewFilter.endDate) {
                state.selectedTimePeriodStartDate = state.previewFilter.startDate
                state.selectedTimePeriodEndDate = state.previewFilter.endDate
            } else if (state.previewFilter.selectedDatasourceId) {
                state.selectedDatasourceIds = [state.previewFilter.selectedDatasourceId]
            } else if (state.previewFilter.selectedMediaType) {
                state.selectedMediaTypes = [state.previewFilter.selectedMediaType]
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchResultsForProject.pending, (state) => {
                state.isLoadingResults = true
            })
            .addCase(fetchResultsForProject.fulfilled, (state) => {
                state.isLoadingResults = false
            })
            .addCase(fetchResultsForProject.rejected, (state) => {
                state.isLoadingResults = false
            })
    },
})

export const getSelectedTags: Selector<HmstrState, string[]> = (state) => state.results.selectedTags
export const getSelectedDatasourceIds: Selector<HmstrState, string[]> = (state) => state.results.selectedDatasourceIds
export const getSelectedTimePeriodStart: Selector<HmstrState, string> = (state) => state.results.selectedTimePeriodStartDate
export const getSelectedTimePeriodEnd: Selector<HmstrState, string> = (state) => state.results.selectedTimePeriodEndDate
export const getSelectedChartType: Selector<HmstrState, ResultsState['selectedChartType']> = (state) => state.results.selectedChartType
export const getSelectedMediaTypes: Selector<HmstrState, string[]> = (state) => state.results.selectedMediaTypes
export const getResultsGroupedBy: Selector<HmstrState, keyof Result | undefined> = (state) => state.results.resultsGroupedBy
export const getShowPieChart: Selector<HmstrState, boolean> = (state) => state.results.showPieChart
export const getShowGraph: Selector<HmstrState, boolean> = (state) => state.results.showGraph
export const getResultsSearchTerm: Selector<HmstrState, string> = (state) => state.results.searchTerm
export const getResultsSortedBy: Selector<HmstrState, keyof Result> = (state) => state.results.resultsSortedBy
export const getResultsSortDirection: Selector<HmstrState, 'asc' | 'desc'> = (state) => state.results.resultsSortDirection
export const getIsLoadingResults: Selector<HmstrState, boolean> = (state) => state.results.isLoadingResults
export const getOpinionLeaderSearchTerm: Selector<HmstrState, string> = (state) => state.results.opinionLeaderSearchTerm
export const getOpinionLeaderSortBy: Selector<HmstrState, string> = (state) => state.results.opinionLeaderSortBy
export const getOpinionLeaderSortDirection: Selector<HmstrState, 'asc' | 'desc'> = (state) => state.results.opinionLeaderSortDirection
export const getShowResultsPreview: Selector<HmstrState, boolean> = (state) => state.results.showResultsPreviewDialog
export const getResultsPreviewFilter: Selector<HmstrState, ResultsState['previewFilter']> = (state) => state.results.previewFilter

export const getResultDatasources = createSelector([getSelectedProject], (project) =>
    (project?.data_sources || []).filter((ds) => ds.category === 'DATA_STREAMS')
)

export const getResultsForProject = createSelector(
    [getSelectedProjectId, getResults],
    (getSelectedProjectId, results) => results[getSelectedProjectId || ''] || []
)

export const getAvailableMediaTypes = createSelector([getResultsForProject], (results) => {
    const sourceTypes = _.chain(results).groupBy('source_type').keys().value()
    const menuTree = {} as { [group: string]: string[] }

    sourceTypes.forEach((st) => {
        if (st.startsWith('SOCIALMEDIA')) {
            if (!menuTree['SOCIALMEDIA']) {
                menuTree['SOCIALMEDIA'] = []
            }

            menuTree['SOCIALMEDIA'].push(st)
        } else if (st.startsWith('ONLINENEWS')) {
            if (!menuTree['ONLINENEWS']) {
                menuTree['ONLINENEWS'] = []
            }

            menuTree['ONLINENEWS'].push(st)
        } else if (st.startsWith('PRINT')) {
            if (!menuTree['PRINT']) {
                menuTree['PRINT'] = []
            }

            menuTree['PRINT'].push(st)
        } else {
            if (!menuTree['OTHERS']) {
                menuTree['OTHERS'] = []
            }

            menuTree['OTHERS'].push(st)
        }
    })

    return menuTree
})

export const getDatasourceIdsWithMatchingTags = createSelector([getSelectedTags, getResultDatasources], (selectedTags, datasources): string[] => {
    if (selectedTags.length === 0) {
        return []
    }

    const matchingDatasources = datasources.filter((ds) => _.intersection(ds.tags, selectedTags).length > 0)

    return matchingDatasources.map((ds) => ds.id)
})

export const getFilteredResultsForProject = createSelector(
    [
        getResultsForProject,
        getSelectedTimePeriodStart,
        getSelectedTimePeriodEnd,
        getSelectedDatasourceIds,
        getSelectedProject,
        getDatasourceIdsWithMatchingTags,
        getSelectedMediaTypes,
        getResultsSearchTerm,
    ],
    (results, start, end, datasourceIds, project, datasourceIdsViaTags, mediaTypes, searchTerm) => {
        const datasourceIdsForProject = (project?.data_sources || []).map((ds) => ds.id)

        return results.filter((r) => {
            // Check if datasource exists in project or was already deleted
            if (!datasourceIdsForProject.includes(r.data_source_id)) {
                return false
            }

            if (datasourceIds.length > 0 && !datasourceIds.includes(r.data_source_id)) {
                return false
            }

            if (datasourceIdsViaTags.length > 0 && !datasourceIdsViaTags.includes(r.data_source_id)) {
                return false
            }

            if (mediaTypes.length > 0 && !mediaTypes.includes(r.source_type)) {
                return false
            }

            if (searchTerm && !r.title?.toLowerCase().includes(searchTerm.toLowerCase()) && !r.content?.toLowerCase().includes(searchTerm.toLowerCase())) {
                return false
            }

            return moment(r.published_ts).isSameOrAfter(moment(start), 'day') && moment(r.published_ts).isSameOrBefore(moment(end), 'day')
        })
    }
)

export const getSortedFilteredResultsForProject = createSelector(
    [getFilteredResultsForProject, getResultsSortedBy, getResultsSortDirection],
    (filteredResults, sortedBy, sortDirection) => {
        return _.orderBy(filteredResults, [sortedBy], [sortDirection])
    }
)

export const getPreviewResultsForProject = createSelector([getSortedFilteredResultsForProject, getResultsPreviewFilter], (results: Result[], filter) => {
    const { startDate, endDate, selectedMediaType, selectedDatasourceId, domain } = filter

    if (startDate && endDate) {
        return results.filter(
            (r) => moment(r.published_ts).isSameOrAfter(moment(startDate), 'day') && moment(r.published_ts).isSameOrBefore(moment(endDate), 'day')
        )
    }

    if (selectedMediaType) {
        return results.filter((r) => r.source_type === selectedMediaType)
    }

    if (selectedDatasourceId) {
        return results.filter((r) => r.data_source_id === selectedDatasourceId)
    }

    if (domain) {
        return results.filter((r) => r.domain_url && r.domain_url.includes(domain))
    }

    return results
})

export const getResultsChartData = createSelector(
    [getFilteredResultsForProject, getSelectedTimePeriodStart, getSelectedTimePeriodEnd, getResultsGroupedBy],
    (filteredResults, start, end, groupedBy) => {
        const resultsByDate = _.groupBy(filteredResults, (result) => moment(result.published_ts).startOf('day').format())
        const resultsByGroup = _.groupBy(filteredResults, groupedBy)
        const groups = _.keys(resultsByGroup)

        const startDate = moment(start).startOf('day')
        const endDate = moment(end).startOf('day')
        const resultGraphData: AreaChartData = []

        while (startDate.isSameOrBefore(endDate, 'day')) {
            const resultsForDay = resultsByDate[startDate.format()]

            if (!groupedBy) {
                resultGraphData.push({
                    key: startDate.format(),
                    results: resultsForDay ? resultsForDay.length : 0,
                })
            } else {
                const data: any = { key: startDate.format() }

                groups.forEach((gr) => {
                    const resultsForDayWithGroup = resultsForDay ? resultsForDay.filter((r) => r[groupedBy] === gr) : []
                    data[gr] = resultsForDay ? resultsForDayWithGroup.length : 0
                })

                resultGraphData.push(data)
            }

            startDate.add(1, 'day')
        }

        return resultGraphData
    }
)

export const getChartSchema = createSelector(
    [getResultsGroupedBy, getFilteredResultsForProject, getDatasourcesAsIdMap],
    (groupedBy, filteredResults, datasources) => {
        const resultsByGroup = _.groupBy(filteredResults, groupedBy)
        const groups = _.keys(resultsByGroup)

        switch (groupedBy) {
            case 'source_type':
                return {
                    key: 'key',
                    dataSets: groups.map((g) => ({
                        dataKey: g,
                        label: g,
                        color: SourceTypeColors[g],
                    })),
                }
            case 'data_source_id':
                return {
                    key: 'key',
                    dataSets: groups.map((g) => ({
                        dataKey: g,
                        label: datasources[g].name,
                        color: datasources[g].color,
                    })),
                }
            default:
                return {
                    key: 'key',
                    dataSets: [
                        {
                            dataKey: 'results',
                            color: '#ff6900',
                            label: 'results.title',
                        },
                    ],
                }
        }
    }
)

export const getResultsPieData = createSelector(
    [getFilteredResultsForProject, getResultsGroupedBy, getDatasourcesAsIdMap],
    (filteredResults, groupedBy, datasources) => {
        if (!groupedBy) {
            return [
                {
                    name: 'results.title',
                    value: filteredResults.length,
                    color: '#ff6900',
                },
            ]
        }

        const resultsByGroup = _.groupBy(filteredResults, groupedBy)
        const pieData: PieChartData = []

        _.keys(resultsByGroup).forEach((g) =>
            pieData.push({
                real_value: groupedBy === 'data_source_id' ? datasources[g].id : g,
                name: groupedBy === 'data_source_id' ? datasources[g].name : g,
                value: resultsByGroup[g].length,
                color: groupedBy === 'data_source_id' ? datasources[g].color : SourceTypeColors[g],
            })
        )

        return pieData
    }
)

export const getOpinionLeaders = createSelector(
    [getFilteredResultsForProject, getOpinionLeaderSearchTerm, getOpinionLeaderSortBy, getOpinionLeaderSortDirection],
    (results, searchTerm, sortBy, sortDirection) => {
        const opinionLeaders = [] as {
            domain: string
            source_type: string
            total: number
            reach: number
            engagement: number
            positive: number
            neutral: number
            negative: number
        }[]

        const groupedByDomain = _.groupBy(results, 'domain_url')

        _.forOwn(groupedByDomain, (values, key) => {
            const matchesSearchTerm = key && key.toLowerCase().includes(searchTerm.toLowerCase())

            if (matchesSearchTerm) {
                opinionLeaders.push({
                    domain: key.split('/')[2],
                    source_type: values[0].source_type,
                    total: values.length,
                    reach: _.sumBy(values, (v) => v.reach || 0),
                    engagement: _.sumBy(values, (v) => v.engagement || 0),
                    positive: _.filter(values, (v) => (v.sentiment ? parseInt(v.sentiment) : 0) > 0).length,
                    neutral: _.filter(values, (v) => (v.sentiment ? parseInt(v.sentiment) : 0) === 0).length,
                    negative: _.filter(values, (v) => (v.sentiment ? parseInt(v.sentiment) : 0) < 0).length,
                })
            }
        })

        return _.orderBy(opinionLeaders, sortBy, sortDirection)
    }
)

export const {
    changeTimePeriod,
    changeSelectedDatasourceIds,
    changeGroupBy,
    changeSelectedMediaTypes,
    changeResultsSearchTerm,
    changeResultsSortedBy,
    changeOpinionLeaderSearchTerm,
    changeOpinionLeaderSorting,
    changeSelectedTags,
    closePreviewDialog,
    openPreviewDialog,
    previewDrillDown,
    togglePieChart,
    toggleGraph,
    toggleResultsSortDirection,
    selectChartType,
} = ResultsSlice.actions

export const ResultsReducer = ResultsSlice.reducer
