import * as React from 'react'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem, Typography } from '@mui/material'
import { BillingPaymentMethodItem } from './BillingPaymentMethodItem'
import { PaymentMethod } from '@stripe/stripe-js'
import { Add, MoreVert } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { deletePaymentMethod, fetchPaymentMethods, updateDefaultPaymentMethod } from './BillingActions'
import { HmstrDispatch } from '../../core/Store'
import { getBillingDataLoading, getSelectedAccount, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { Loading } from '../../common/loading/Loading'
import { Fallback } from '../../common/fallback/Fallback'
import { BillingPaymentMethodAddDialog } from './BillingPaymentMethodAddDialog'
import { getPaymentMethodsForSelectedCustomer } from '../SettingsSlice'
import { useHasAccountPermission } from '../../core/hooks/useHasPermission'

type BillingPaymentMethodsProps = {}

export const BillingPaymentMethods: FC<BillingPaymentMethodsProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()

    const [addPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] = useState(false)
    const isBillingDataLoading = useSelector(getBillingDataLoading)
    const paymentMethods = useSelector(getPaymentMethodsForSelectedCustomer)
    const account = useSelector(getSelectedAccount)
    const canManageBilling = useHasAccountPermission('account.manage_billing', account.id)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [paymentMethod, setPaymentMethod] = useState<null | PaymentMethod>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, paymentMethod: PaymentMethod) => {
        setAnchorEl(event.currentTarget)
        setPaymentMethod(paymentMethod)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setPaymentMethod(null)
    }

    const handleSetDefault = () => {
        if (paymentMethod) {
            dispatch(updateDefaultPaymentMethod({ paymentMethod, account })).then(() => {
                dispatch(fetchPaymentMethods(account)).then(() => {
                    dispatch(showSuccessSnackbar('billing.payment-methods.update-default-success'))
                })
            })
        }

        setAnchorEl(null)
        setPaymentMethod(null)
    }

    const handleDelete = () => {
        if (paymentMethod) {
            dispatch(deletePaymentMethod(paymentMethod)).then(() => {
                setTimeout(() => {
                    dispatch(fetchPaymentMethods(account))
                    dispatch(showSuccessSnackbar('billing.payment-methods.delete-success'))
                }, 500)
            })
        }

        setAnchorEl(null)
        setPaymentMethod(null)
    }

    if (isBillingDataLoading && Object.values(paymentMethods).length === 0) {
        return <Loading />
    }

    return (
        <>
            <List sx={{ mt: 2 }}>
                <Fallback condition={Object.values(paymentMethods).length === 0} messageId="billing.no-payment-methods">
                    {Object.values(paymentMethods).map((paymentMethod) => (
                        <BillingPaymentMethodItem key={paymentMethod.id} paymentMethod={paymentMethod}>
                            <ListItemSecondaryAction>
                                <IconButton onClick={(event) => handleClick(event, paymentMethod)} disabled={!canManageBilling}>
                                    <MoreVert />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </BillingPaymentMethodItem>
                    ))}
                </Fallback>

                <ListItemButton sx={{ mt: 2 }} onClick={() => setAddPaymentMethodDialogOpen(true)} disabled={!canManageBilling}>
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>

                    <ListItemText>
                        <FormattedMessage id="billing.payment-methods.add" />
                    </ListItemText>
                </ListItemButton>
            </List>
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
                <ListSubheader>
                    <FormattedMessage id="general.actions" />
                </ListSubheader>
                <MenuItem onClick={handleSetDefault}>
                    <ListItemText>
                        <FormattedMessage id="billing.payment-methods.set-default" />
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <Typography color="error">
                        <FormattedMessage id="general.delete" />
                    </Typography>
                </MenuItem>
            </Menu>

            {addPaymentMethodDialogOpen && (
                <BillingPaymentMethodAddDialog open={addPaymentMethodDialogOpen} onClose={() => setAddPaymentMethodDialogOpen(false)} />
            )}
        </>
    )
}
