import { FC } from 'react'
import { Moment } from 'moment'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { FormattedMessage } from 'react-intl'

type DatepickerProps = {
    readonly?: boolean
    label?: string
    variant?: 'filled' | 'standard' | 'outlined' | undefined
    size?: 'small' | 'medium' | undefined
    fullWidth?: boolean
    onOpen?: () => void
    onClose?: () => void
    open?: boolean
} & DatePickerProps<Moment>

export const Datepicker: FC<DatepickerProps> = (props) => {
    const { label, disabled, fullWidth, slotProps, size, variant, onClose, onOpen, open, ...otherProps } = props

    return (
        <DatePicker
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            slotProps={{
                popper: {
                    placement: 'bottom-start',
                    disablePortal: true,
                    ...slotProps?.popper,
                },
                textField: {
                    variant: variant,
                    style: disabled ? { opacity: 0.4 } : undefined,
                    fullWidth: fullWidth,
                    size: size,
                    label: <FormattedMessage id={label} />,
                    InputProps: {
                        sx: disabled ? { '&.Mui-disabled:before': { borderBottomStyle: 'dotted !important' } } : undefined,
                    },
                    InputLabelProps: {
                        variant: variant,
                    },
                    ...slotProps?.textField,
                },
                field: {
                    disabled: disabled,
                    ...slotProps?.field,
                },
                ...slotProps,
            }}
            {...otherProps}
        />
    )
}
