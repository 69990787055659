import { Box, Button, Dialog, DialogActions, DialogContent, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { closeInfoDialog, isDarkMode } from '../../core/slices/CoreSlice'
import { HmstrState } from '../../core/Store'
import hmstrLogoInverted from '../../resources/images/hmstr-logo-inverted.png'
import hmstrLogoWhite from '../../resources/images/hmstr-logo.png'

type InfoDialogProps = {
    open: boolean
}

export const InfoDialog: FC<InfoDialogProps> = ({ open }) => {
    const dispatch = useDispatch()
    const apiState = useSelector((state: HmstrState) => state.api)
    const darkMode = useSelector(isDarkMode)

    const handleClose = () => dispatch(closeInfoDialog())

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="info-dialog-title" aria-describedby="info-dialog-description">
            <DialogContent>
                <Box textAlign="center" padding={4}>
                    <img src={darkMode ? hmstrLogoWhite : hmstrLogoInverted} alt="hmstr-logo-inverted" height="40px" />
                    <Box>
                        <Typography variant="subtitle2" color="secondary" fontStyle="italic">
                            <FormattedMessage id="general.slogan" />
                        </Typography>

                        <Box mt={2}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage id="general.frontend-version" />
                                        </TableCell>
                                        <TableCell>{apiState.frontendVersion}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage id="general.cms-version" />
                                        </TableCell>
                                        <TableCell>{apiState.cmsVersion || <FormattedMessage id="general.service-not-available" />}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage id="general.gateway-version" />
                                        </TableCell>
                                        <TableCell>{apiState.gatewayVersion || <FormattedMessage id="general.service-not-available" />}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    <FormattedMessage id="general.close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
