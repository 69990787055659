import * as React from 'react'
import { FC } from 'react'
import { useImage } from '../../core/hooks/useImage'
import { Box, useTheme } from '@mui/material'
import { VideoFile } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'

type PublishingListPostImageProps = { publishingImage?: string; isFinalLink?: boolean; isVideo?: boolean }

export const PublishingListPostImage: FC<PublishingListPostImageProps> = ({ publishingImage, isFinalLink, isVideo }) => {
    const { objectUrl, hasErrors } = useImage(publishingImage)
    const darkmode = useSelector(isDarkMode)
    const theme = useTheme()
    return (
        <Box sx={{ width: '100%' }}>
            {((!hasErrors && objectUrl) || isFinalLink) && !isVideo && (
                <img style={{ width: '100%' }} src={isFinalLink ? publishingImage : objectUrl} alt={'posting thumbnail'} />
            )}
            {isVideo && (
                <Box
                    sx={{
                        aspectRatio: '16 / 9',
                        width: '100%',
                        background: darkmode ? '#fff3' : '#0003',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <VideoFile sx={{ fontSize: '4em', color: theme.palette.primary.contrastText }} />
                </Box>
            )}
        </Box>
    )
}
