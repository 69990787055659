import { Box, Container, Typography } from '@mui/material'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { getSettingsSelectedInvite } from '../SettingsSlice'
import { Invite } from './Invite'
import { UserDatasourceAccess, UserProjectAccess } from './User'
import { updateInvite } from './UserActions'
import { processUserFormValues, UserForm, UserFormValues } from './UserForm'
import { getAccountById } from '../../core/slices/DataSlice'
import * as _ from 'lodash'
import { closeFullscreenDialog, getProjectsForSelectedAccount, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { FORM_ERROR } from 'final-form'

type InviteEditProps = {}

export const InviteEdit: FC<InviteEditProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const projects = useSelector(getProjectsForSelectedAccount)
    const invite = useSelector(getSettingsSelectedInvite) as Invite
    const account = useSelector(getAccountById(invite.account.account_id))

    const convertProjects = (): UserProjectAccess[] => {
        return _.values(projects).map((p) => {
            const inviteProjectAccess = invite.account.projects.find((up) => up.project_id === p.id)
            const access = [] as UserDatasourceAccess[]

            p.data_sources.forEach((ds) => {
                const existingAccess = inviteProjectAccess && inviteProjectAccess.access.find((a) => a.data_source_id === ds.id)
                access.push(existingAccess || { data_source_id: ds.id })
            })

            return {
                project_id: p.id,
                name: p.name,
                role: inviteProjectAccess ? inviteProjectAccess.role : '',
                description: p.description,
                access: access,
                external: false,
                _links: { project: p._links.self },
            }
        })
    }

    const onSubmit = (values: UserFormValues) => {
        const newInvite = { ...invite }
        const processedFormValues = processUserFormValues(values)

        if (processedFormValues.account_role === 'USER' && processedFormValues.projects.length === 0) {
            return {
                [FORM_ERROR]: 'accounts.at-least-one-project-required',
            }
        }

        newInvite.account = {
            ...invite.account,
            role: processedFormValues.account_role,
            projects: processedFormValues.projects,
            external: processedFormValues.external,
        }

        dispatch(updateInvite(newInvite)).then(() => {
            dispatch(closeFullscreenDialog())
            dispatch(showSuccessSnackbar('users.invite.updated'))
        })
    }

    return (
        <Container maxWidth="lg">
            <Box marginTop={4} marginBottom={2}>
                <Box marginBottom={4}>
                    <Typography variant="h4">
                        <FormattedMessage id="users.invite.edit" />
                    </Typography>
                </Box>
                <UserForm
                    onSubmit={onSubmit}
                    mode="edit"
                    initialValues={{
                        email: invite.email,
                        account_name: account?.name,
                        account_role: invite.account.role,
                        external: invite.account.external,
                        projects: convertProjects(),
                    }}
                />
            </Box>
        </Container>
    )
}
