import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Bar, BarChart as RBarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { ResultTooltipBox } from '../../monitoring/Results.styles'
import { AggregatedTagData } from '../../tags/AggregatedTagData'
import { Dot } from '../../common/dot/Dot'

type PostAnalysisBarChartProps = {
    title: string
    data: AggregatedTagData[]
    unit?: string
    getValue: (entry: AggregatedTagData) => number
}

export const PostAnalysisBarChart: FC<PostAnalysisBarChartProps> = ({ title, data, getValue, unit }) => {
    const intl = useIntl()

    const formatTagName = (tagName: string) => {
        const maxLength = 17
        if (tagName === 'tags.untagged-posts') {
            return intl.formatMessage({ id: tagName })
        } else if (tagName.length <= maxLength) {
            return tagName
        } else {
            return tagName.substring(0, maxLength - 3) + '...'
        }
    }

    const formatData = () => {
        let transformedData: any[] = []
        data.forEach((value) => {
            transformedData.push({ name: value.tag.label, total: getValue(value), tag: value.tag })
        })
        transformedData = transformedData.sort((a, b) => b.total - a.total)
        return transformedData
    }

    const formatNumber = (value: number): string => {
        if (isNaN(value)) {
            return '0'
        }

        if (value > 999999) {
            return value / 1000000 + 'M'
        }

        if (value > 9999) {
            return value / 1000 + 'K'
        }

        return value.toString()
    }

    const RotatedTick = (props: any) => {
        const { x, y, payload } = props

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={12} textAnchor="end" fill="#666" transform="rotate(-45)">
                    {formatTagName(payload.value || '0')}
                </text>
            </g>
        )
    }

    const BarChartTooltip: FC<TooltipProps<any, any>> = ({ active, payload }) => {
        if (active && payload && payload[0]) {
            return (
                <ResultTooltipBox>
                    <Box display="flex" alignItems="center">
                        {payload[0].payload.tag && (
                            <Box paddingRight={1}>
                                <Dot color={payload[0].payload.tag.color} />
                            </Box>
                        )}
                        <Typography>
                            {formatTagName(payload[0].payload.name)}&nbsp;
                            <FormattedNumber value={payload[0].value as any} maximumFractionDigits={2} />
                            {unit || ''}
                        </Typography>
                    </Box>
                </ResultTooltipBox>
            )
        }
        return null
    }

    return (
        <>
            <Box padding={1} paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Typography sx={{ pl: 1 }}>
                    <strong>
                        <FormattedMessage id={title} />
                    </strong>
                </Typography>
            </Box>

            <Box width="100%" paddingRight={2} height={450}>
                <ResponsiveContainer width="100%" height="100%">
                    <RBarChart
                        data={formatData()}
                        maxBarSize={80}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis axisLine={false} dataKey="name" tickMargin={8} tickLine={false} minTickGap={-200} height={120} tick={<RotatedTick />} />
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            tickMargin={6}
                            unit={unit}
                            interval={0}
                            domain={[
                                0,
                                (dataMax: number) => {
                                    const dataMaxBeforeCommaLength = dataMax.toString().split('.')[0].length - 1
                                    return isNaN(dataMax)
                                        ? 0
                                        : Math.ceil(dataMax / Math.pow(10, dataMaxBeforeCommaLength)) * Math.pow(10, dataMaxBeforeCommaLength)
                                },
                            ]}
                            tickFormatter={formatNumber}
                            allowDecimals={false}
                        />
                        <Tooltip wrapperStyle={{ outline: 'none' }} content={BarChartTooltip} cursor={false} />
                        <Bar dataKey="total" fill="#00a0fc" strokeWidth={0}>
                            {formatData().map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.tag.color} />
                            ))}
                        </Bar>
                    </RBarChart>
                </ResponsiveContainer>
            </Box>
        </>
    )
}
