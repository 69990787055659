import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers'
import { Moment } from 'moment/moment'

export type DatetimePickerProps = {
    readonly?: boolean
    fullWidth?: boolean
    label?: string
    variant?: 'filled' | 'standard' | 'outlined' | undefined
    size?: 'small' | 'medium' | undefined
    disableValidation?: boolean
    required?: boolean
    error?: boolean
    helperText?: string
} & DateTimePickerProps<Moment>

export const DatetimePicker: FC<DatetimePickerProps> = (props) => {
    const { label, fullWidth, disabled, disableValidation, maxDateTime, minDateTime, error, variant, required, size, slotProps, ...otherProps } = props
    const [open, setOpen] = useState(false)

    return (
        <DateTimePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            minDateTime={disableValidation ? undefined : minDateTime}
            maxDateTime={disableValidation ? undefined : maxDateTime}
            disabled={disabled}
            slotProps={{
                popper: {
                    placement: 'bottom-start',
                    ...slotProps?.popper,
                },
                textField: {
                    variant: variant,
                    error: disableValidation ? false : error,
                    helperText: disableValidation ? undefined : error && otherProps.helperText,
                    fullWidth: fullWidth,
                    size: size,
                    label: <FormattedMessage id={label} />,
                    InputProps: {
                        required: required,
                        sx: disabled ? { '&.Mui-disabled:before': { borderBottomStyle: 'dotted !important' } } : undefined,
                    },
                    InputLabelProps: {
                        required: required,
                        variant: variant,
                    },
                    ...slotProps?.textField,
                },
                field: {
                    disabled: disabled,
                    ...slotProps?.field,
                },
                ...slotProps,
            }}
            {...otherProps}
        />
    )
}
