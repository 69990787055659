import { FC } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPaymentMethods, fetchProducts } from '../../settings/billing/BillingActions'
import { HmstrDispatch } from '../../core/Store'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { getSelectedAccount, openFullscreenDialog } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'

type TrialDialogProps = {
    open: boolean
    onClose: () => any
    remainingDays: number
}

export const TrialDialog: FC<TrialDialogProps> = ({ open, onClose, remainingDays }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const account = useSelector(getSelectedAccount)

    const handleExtendAccountClick = () => {
        dispatch(openFullscreenDialog('extend-account'))
        onClose()
    }

    const handleClick = () => {
        navigate('/settings/billing/accounts')
        onClose()
    }

    useEffectWithIdComparison(() => {
        dispatch(fetchProducts())
        dispatch(fetchPaymentMethods(account))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" keepMounted={false}>
            <DialogTitle>
                <FormattedMessage id="billing.free-trial" />
            </DialogTitle>

            <DialogContent>
                <Typography sx={{ mb: 2 }}>
                    <FormattedMessage id="hint.account.trial" values={{ days: remainingDays }} />
                </Typography>
                <Typography>
                    <FormattedMessage id="billing.free-trial-extend" />
                </Typography>

                <Box mt={4} mb={4}>
                    <Button variant="contained" onClick={handleExtendAccountClick} color="secondary" fullWidth={true}>
                        <FormattedMessage id="billing.extend-subscription" />
                    </Button>
                </Box>

                <Typography variant="body2">
                    <FormattedMessage
                        id="billing.free-trial-hint"
                        values={{
                            link: (chunks: any) => {
                                return (
                                    <Link sx={{ cursor: 'pointer' }} onClick={handleClick}>
                                        {chunks}
                                    </Link>
                                )
                            },
                        }}
                    />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage id="general.close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
