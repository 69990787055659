import { Select, styled } from '@mui/material'
import { DateRange, DateRangePicker as DateRangePickerComponent } from 'react-date-range'

export const DatepickerField = styled(Select, {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'expanded' && propName !== 'size',
})<{ expanded: boolean }>(({ theme, expanded, size }) => ({
    cursor: 'pointer',
    width: '200px',

    '& .MuiInput-input': {
        WebkitTextFillColor: `${theme.palette.text.primary} !important`,
        cursor: 'pointer !important',
        padding: size === 'small' ? '9px' : '',
    },

    '& .MuiOutlinedInput-input': {
        padding: size === 'small' ? '9px' : '',
    },

    '& .MuiSelect-root': {
        color: theme.palette.text.primary,
        cursor: 'pointer !important',
    },

    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
    },

    '&:hover:before': {
        borderBottomWidth: 2,
        borderBottomColor: theme.palette.text.primary,
        opacity: 1,
    },

    '&:before': {
        borderBottomStyle: 'solid !important',
    },
}))

export const StyledDateRangePickerComponent = styled(DateRangePickerComponent as any)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,

    '& .rdrDayToday span:after': {
        background: theme.palette.secondary.main,
    },
    '& .rdrCalendarWrapper': {
        backgroundColor: theme.palette.background.paper,
    },
    '& .rdrDefinedRangesWrapper': {
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
        '& button': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.divider,
            '& span': {
                padding: 0,
            },
            '& span p': {
                padding: theme.spacing(1),
            },
            '&:hover span': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
    },
    '& .rdrDayNumber span': {
        color: theme.palette.text.primary,
    },
    '& .rdrDayPassive span': {
        color: theme.palette.text.disabled,
    },
})) as any as typeof DateRangePickerComponent

export const StyledDateRangeComponent = styled(DateRange as any)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    background: theme.palette.background.paper + ' !important',

    '& .rdrDayToday span:after': {
        background: theme.palette.secondary.main,
    },
    '& .rdrDateDisplayWrapper': {
        background: theme.palette.background.paper,
    },
    '& .rdrDateInput.rdrDateDisplayItem': {
        background: theme.palette.background.default,
    },
    '& .rdrDefinedRangesWrapper': {
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
        '& button': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.divider,
            '& span': {
                padding: 0,
            },
            '& span p': {
                padding: theme.spacing(1),
            },
            '&:hover span': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
    },
    '& .rdrDayNumber span': {
        color: theme.palette.text.primary,
    },
    '& .rdrDayPassive span': {
        color: theme.palette.text.disabled,
    },
})) as any as typeof DateRange
