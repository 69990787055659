import { DashboardTemplate } from './DashboardTemplate'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'

export const dashboardInvalid = (dashboardDatasources: ProjectDatasource[], template: DashboardTemplate) => {
    let isInvalid = false

    if (dashboardDatasources.length === 0) {
        return true
    }

    template.data_sources.forEach((dsc) => {
        const datasourcesByType = dashboardDatasources.filter((ds) => ds && ds.type === dsc.type)

        if (dsc.max_data_sources && datasourcesByType.length !== dsc.max_data_sources) {
            isInvalid = true
        }
    })

    return isInvalid
}
