import React, { ChangeEvent, FC, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { alpha, Box, Button, IconButton, InputLabel, Tooltip, Typography, useTheme } from '@mui/material'
import { DeleteForever, UploadFile } from '@mui/icons-material'
import { Field, FieldInputProps } from 'react-final-form'

type WhiteLabelLogoPickerProps = { buttonLabel: string; fieldLabel: string; name: string; required?: boolean }

export const WhiteLabelLogoPicker: FC<WhiteLabelLogoPickerProps> = ({ buttonLabel, fieldLabel, name, required }) => {
    const fileInputRef = useRef<HTMLInputElement>(null)
    const theme = useTheme()
    const [mouseOver, setMouseOver] = useState(false)

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, input: FieldInputProps<any>) => {
        if (event.target.files) {
            const fileUploaded = event.target.files[0]
            input.onChange(fileUploaded)
        }
    }

    const handleDeleteFile = (input: FieldInputProps<any>) => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
            input.onChange(null)
        }
    }
    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    return (
        <Field
            name={name}
            validate={(value) => {
                if (required && !value) {
                    return 'validations.required'
                }
            }}
            render={({ input, meta }) => {
                const imageUrl = typeof input.value === 'string' ? input.value : URL.createObjectURL(input.value)
                return (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        onMouseEnter={() => {
                            setMouseOver(true)
                        }}
                        onBlur={() => {
                            setMouseOver(false)
                        }}
                        onMouseLeave={() => setMouseOver(false)}
                    >
                        {fieldLabel && (
                            <InputLabel
                                sx={{
                                    transformOrigin: 'center',
                                    transform: 'translate(0, 0) scale(0.75)',
                                }}
                            >
                                <FormattedMessage id={fieldLabel} />
                            </InputLabel>
                        )}
                        {input.value ? (
                            <div
                                style={{
                                    aspectRatio: '1',
                                    padding: 10,
                                    objectFit: 'contain',
                                    backgroundImage:
                                        'linear-gradient(45deg, #ccc 25%, transparent 25%),        linear-gradient(135deg, #ccc 25%, transparent 25%),       linear-gradient(45deg, transparent 75%, #ccc 75%),       linear-gradient(135deg, transparent 75%, #ccc 75%)',
                                    backgroundSize: '25px 25px',
                                    backgroundPosition: '0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px',
                                    position: 'relative',
                                    width: '150px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={imageUrl}
                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                    alt={'Thumbnail'}
                                    onLoad={(i) => {
                                        let naturalWidth = i.currentTarget.naturalWidth
                                        let naturalHeight = i.currentTarget.naturalHeight
                                        let aspect = naturalWidth / naturalHeight

                                        i.currentTarget.style.width = '130px'
                                        i.currentTarget.style.height = 130 / aspect + 'px'
                                    }}
                                />
                                <div
                                    style={{
                                        maxHeight: mouseOver ? '400px' : 0,
                                        maxWidth: '150px',
                                        transition: 'max-height 0.2s',
                                        overflow: 'hidden',
                                        position: 'absolute',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        bottom: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        background: alpha(theme.palette.primary.main, 0.5),
                                    }}
                                >
                                    {input.value && (
                                        <Tooltip title={<FormattedMessage id={'general.delete'} />} placement={'right'}>
                                            <IconButton sx={{ color: theme.palette.primary.contrastText }} onClick={() => handleDeleteFile(input)}>
                                                <DeleteForever fontSize={'large'} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <Typography color={meta.error && 'error'}>
                                    <FormattedMessage id={'general.no-image-selected'} />
                                </Typography>
                                <Button variant={'contained'} color={'inherit'} sx={{ mt: 2 }} startIcon={<UploadFile />} onClick={handleClick}>
                                    <FormattedMessage id={buttonLabel} />
                                </Button>
                            </>
                        )}
                        <input
                            type={'file'}
                            style={{ display: 'none' }}
                            onChange={(event) => handleFileChange(event, input)}
                            accept="image/png, image/jpeg"
                            ref={fileInputRef}
                        />
                    </Box>
                )
            }}
        />
    )
}
