import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type LinkedInShareProps = SvgIconProps

export const LinkedInShare: FC<LinkedInShareProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(0,24) scale(0.037,-0.037)" fill={props.htmlColor} stroke="none">
                    <path
                        d="M320 505 c0 -3 7 -16 15 -29 l16 -24 -91 -3 c-124 -5 -130 -13 -130
-155 0 -97 1 -104 20 -104 19 0 20 7 20 110 l0 110 91 0 91 0 -16 -25 c-20
-31 -20 -35 2 -35 16 0 62 59 62 80 0 21 -46 80 -62 80 -10 0 -18 -2 -18 -5z"
                    />
                    <path
                        d="M450 240 l0 -110 -91 0 -91 0 16 25 c20 31 20 35 -1 35 -17 0 -63
-59 -63 -80 0 -21 46 -80 63 -80 21 0 21 4 2 34 l-16 24 91 3 c124 5 130 13
130 155 0 97 -1 104 -20 104 -19 0 -20 -7 -20 -110z"
                    />
                </g>
            </svg>
        </SvgIcon>
    )
}
