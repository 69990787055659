import { FC, useState } from 'react'
import { alpha, Box, IconButton, ImageListItem, Skeleton, Tooltip, Typography, useTheme } from '@mui/material'
import { CameraAltOutlined, DeleteForever, Edit, ErrorOutline, KeyboardArrowLeft, KeyboardArrowRight, PlayArrow } from '@mui/icons-material'
import { PublishingMedia } from './PublishingMedia'
import { useImage } from '../core/hooks/useImage'
import { getErrorsForCommonFile } from './PublishingSlice'
import { useSelector } from 'react-redux'
import _, { clone } from 'lodash'
import { PostVideoThumbnail } from './post-preview/PostVideoThumbnail'
import { PublishingMediaErrorTooltip } from './PublishingMediaErrorTooltip'
import { useForm } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

export type PublishingFileUploadThumbnailProps = {
    file: PublishingMedia
    currentKeys: string
    onEdit?: () => any
    onDelete?: (network?: string) => any
    disabled?: boolean
    index?: number
    totalElements?: number
    fieldName?: string
}

export const PublishingFileUploadThumbnail: FC<PublishingFileUploadThumbnailProps> = ({
    file,
    onEdit,
    onDelete,
    currentKeys,
    disabled,
    index,
    totalElements,
    fieldName,
}) => {
    const { objectUrl } = useImage(file.category === 'IMAGE' ? file._links.file.href : undefined)
    const theme = useTheme()
    const form = useForm()
    const [mouseOver, setMouseOver] = useState(false)
    const imageErrors = useSelector(getErrorsForCommonFile)[file.id]
    const includesExifErrors = file.exif_scan?.errors && file.exif_scan?.errors.length > 0
    const groupedByNetwork = _.mapValues(_.groupBy(imageErrors, 'network'), (clist) => clist.map((error) => _.omit(error, 'network')))

    const array_move = (arr: (string | undefined)[], old_index: number, new_index: number) => {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1
            while (k--) {
                arr.push(undefined)
            }
        }
        const copiedArray = clone(arr)
        copiedArray.splice(new_index, 0, copiedArray.splice(old_index, 1)[0])
        return copiedArray
    }

    const fileIds: string[] = !!fieldName
        ? typeof _.get(form.getState().values, fieldName) === 'string'
            ? [_.get(form.getState().values, fieldName) || []]
            : _.get(form.getState().values, fieldName) || []
        : []

    const handleMoveForward = () => {
        if (fieldName) {
            // form.change(fieldName, [])
            form.change(fieldName, array_move(fileIds, index!, index! + 1))
            setMouseOver(false)
        }
    }

    const handleMoveBackward = () => {
        if (fieldName) {
            form.change(fieldName, [])
            form.change(fieldName, array_move(fileIds, index!, index! - 1))
            setMouseOver(false)
        }
    }

    const handleDeleteClick = () => {
        if (onDelete) {
            onDelete(currentKeys)
        }
    }

    const handleEditClick = () => {
        if (onEdit) {
            onEdit()
        }
    }

    return (
        <PublishingMediaErrorTooltip file={file} currentKeys={currentKeys}>
            <ImageListItem
                onMouseEnter={() => setMouseOver(true)}
                onMouseLeave={() => setMouseOver(false)}
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    '& .MuiImageListItem-img': {
                        height: '100%',
                    },
                }}
                rows={1}
                cols={1}
            >
                {file.category === 'IMAGE' &&
                    (objectUrl ? (
                        <img src={objectUrl} alt={file.name} width="100%" height="100%" loading="lazy" />
                    ) : (
                        <Skeleton animation="wave" width="100%" height="100%" sx={{ transform: 'none' }} />
                    ))}
                {file.category === 'VIDEO' && <PostVideoThumbnail file={file} />}

                {(objectUrl || file.category === 'VIDEO') && (
                    <Box>
                        <Box
                            zIndex={1}
                            position="absolute"
                            top={0}
                            left={0}
                            width="100%"
                            height="100%"
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            p={0.5}
                        >
                            <IconButton
                                size="small"
                                sx={{
                                    backgroundColor: 'secondary.main',
                                    color: 'white',
                                }}
                            >
                                {file.category === 'VIDEO' ? (
                                    <PlayArrow fontSize="small" color="inherit" />
                                ) : (
                                    <CameraAltOutlined fontSize="small" color="inherit" />
                                )}
                            </IconButton>
                        </Box>
                        {(imageErrors || includesExifErrors) && (currentKeys !== 'ALL' ? Object.keys(groupedByNetwork).includes(currentKeys) : true) && (
                            <Box
                                position={'absolute'}
                                bgcolor={alpha(theme.palette.primary.main, 0.65)}
                                left={0}
                                bottom={0}
                                zIndex={2}
                                height={'100%'}
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{
                                    backdropFilter: 'blur(2px)',
                                }}
                            >
                                <ErrorOutline
                                    sx={{
                                        fontSize: '65px',
                                        color: theme.palette.error.main,
                                    }}
                                />
                            </Box>
                        )}

                        <Box
                            zIndex={2}
                            position="absolute"
                            bottom={0}
                            left={0}
                            height={mouseOver ? '100%' : '0%'}
                            width="100%"
                            sx={{
                                overflow: 'hidden',
                                transition: 'height .3s cubic-bezier(0.175, 0.885, 0.275, 1.4)',
                                background: 'rgb(0, 0, 0, 0.5)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <Box position={'absolute'} top={0} left={0} p={0.5}>
                                {totalElements && index && index > 0 ? (
                                    <Tooltip title={<FormattedMessage id={'publishing.image-order-move-to-front'} />}>
                                        <IconButton
                                            onClick={() => {
                                                handleMoveBackward()
                                            }}
                                            sx={{
                                                opacity: disabled ? 0 : 1,
                                                pointerEvents: disabled ? 'none' : undefined,
                                            }}
                                        >
                                            <KeyboardArrowLeft sx={{ color: '#ffffff' }} />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {totalElements && index !== undefined && index < totalElements - 1 ? (
                                    <Tooltip title={<FormattedMessage id={'publishing.image-order-move-to-back'} />}>
                                        <IconButton
                                            onClick={() => {
                                                handleMoveForward()
                                            }}
                                            sx={{
                                                opacity: disabled ? 0 : 1,
                                                pointerEvents: disabled ? 'none' : undefined,
                                            }}
                                        >
                                            <KeyboardArrowRight sx={{ color: '#ffffff' }} />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </Box>
                            {file.category === 'IMAGE' ? (
                                <IconButton
                                    size="large"
                                    sx={{
                                        color: 'white',
                                        marginBottom: '16px',
                                        marginTop: '40px',
                                        opacity: disabled ? 0 : 1,
                                        pointerEvents: disabled ? 'none' : undefined,
                                    }}
                                    onClick={handleEditClick}
                                >
                                    <Edit fontSize="large" />
                                </IconButton>
                            ) : (
                                <Box height={116} />
                            )}

                            <Box color="white" display="flex" width="100%" alignItems="center" pb={0.5}>
                                <Typography whiteSpace="nowrap" pl={1} overflow="hidden" textOverflow="ellipsis" flexGrow={1}>
                                    {file.name}
                                </Typography>

                                <IconButton
                                    size="small"
                                    sx={{
                                        color: 'white',
                                        display: disabled ? 'none' : undefined,
                                    }}
                                    onClick={handleDeleteClick}
                                >
                                    <DeleteForever fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                )}
            </ImageListItem>
        </PublishingMediaErrorTooltip>
    )
}
