import { FC } from 'react'
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { ProjectDatasource } from './ProjectDatasource'
import { Form } from 'react-final-form'
import { FacebookDatasource } from './sections/FacebookDatasource'
import { TalkwalkerDatasource } from './sections/TalkwalkerDatasource'
import { InstagramDatasource } from './sections/InstagramDatasource'
import { LinkedInDatasource } from './sections/LinkedInDatasource'
import { TiktokDatasource } from './sections/TiktokDatasource'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getDatasourcesAsIdMap, isProjectAdmin } from '../../core/slices/CoreSlice'
import { PostAvatar } from '../../common/avatars/PostAvatar'
import { getFederatedIdentites } from '../../core/slices/DataSlice'
import { Save } from '@mui/icons-material'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchPotentialDatasources } from '../user-management/UserActions'
import { HmstrDispatch } from '../../core/Store'

type DatasourceEditDialogProps = {
    open: boolean
    datasource: ProjectDatasource
    onSubmit: (projectDatasource: ProjectDatasource) => any
    onClose: () => any
}

export const DatasourceEditDialog: FC<DatasourceEditDialogProps> = ({ open, datasource, onSubmit, onClose }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const user = useSelector(getCurrentUser)
    const federatedIdentities = useSelector(getFederatedIdentites)
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const actualDatasource = datasourcesById[datasource.id]
    const federatedIdentity = federatedIdentities[actualDatasource.federated_identity_id || '']
    const isUserProjectAdmin = useSelector(isProjectAdmin)

    useEffectWithIdComparison(() => {
        dispatch(fetchPotentialDatasources({ user, type: actualDatasource.type }))
    }, [])

    const renderFormSection = () => {
        switch (actualDatasource.type) {
            case 'FACEBOOK_PAGE':
            case 'FACEBOOK_AD_ACCOUNT':
                return <FacebookDatasource disabled={!isUserProjectAdmin} />
            case 'TALKWALKER_JSON':
                return <TalkwalkerDatasource disabled={!isUserProjectAdmin} />
            case 'INSTAGRAM_ACCOUNT':
                return <InstagramDatasource disabled={!isUserProjectAdmin} />
            case 'LINKED_IN':
            case 'LINKED_IN_PROFILE':
                return <LinkedInDatasource disabled={!isUserProjectAdmin} isPrivateProfile={actualDatasource.type === 'LINKED_IN_PROFILE'} />
            case 'TIKTOK_ADVERTISER':
            case 'TIKTOK_ACCOUNT':
                return <TiktokDatasource disabled={!isUserProjectAdmin} />
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle display="flex" gap={1} alignItems="center">
                <PostAvatar name={actualDatasource.name} src={actualDatasource.metadata.profile_picture_url || ''} height={32} width={32} />

                <FormattedMessage id={actualDatasource.name} />

                <Typography component="span" color="text.secondary">
                    <FormattedMessage id={actualDatasource.type} />
                </Typography>
            </DialogTitle>

            <Form<ProjectDatasource>
                onSubmit={onSubmit}
                initialValues={{
                    ...actualDatasource,
                    federated_identity_username: federatedIdentity?.display_name || actualDatasource.federated_identity_username,
                }}
                render={({ handleSubmit, pristine, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent sx={{ pt: 0 }}>
                            {!actualDatasource.federated_identity_id && actualDatasource.type !== 'TALKWALKER_JSON' && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    <FormattedMessage id="settings.datasource-federated-identity-missing" />
                                </Alert>
                            )}

                            {actualDatasource.access_token_invalid && actualDatasource.federated_identity_id && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    <FormattedMessage
                                        id={
                                            federatedIdentity
                                                ? 'settings.actualDatasource-token-invalid.owner'
                                                : 'settings.actualDatasource-token-invalid.not-owner'
                                        }
                                    />
                                </Alert>
                            )}
                            <Box mt={1}>{renderFormSection()}</Box>
                        </DialogContent>

                        <DialogActions>
                            <Button variant="text" onClick={onClose} color={'inherit'}>
                                <FormattedMessage id="general.close" />
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={pristine || invalid || submitting || !isUserProjectAdmin}
                                startIcon={submitting ? <CircularProgress color="inherit" size={20} /> : <Save />}
                            >
                                <FormattedMessage id="general.save" />
                            </Button>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}
