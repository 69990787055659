import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { FC, useState } from 'react'
import { formatNumber } from '../core/theme/helper'
import _ from 'lodash'
import { DashboardWidgetData } from './DashboardData'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Info, TrendingDown, TrendingFlat, TrendingUp, UnfoldMore } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../core/slices/CoreSlice'
import { Tag } from '../tags/Tag'
import moment, { Moment } from 'moment'

type PostAnalysisTableProps = {
    data: any
    compare_data?: any
    metadata: DashboardWidgetData['metadata']
    datasources: ProjectDatasource[]
    tags: Tag[]
}

export const DynamicTable: FC<PostAnalysisTableProps> = ({ data, compare_data, metadata, datasources, tags }) => {
    const mergeData = (roles: any) => {
        var merger = function (a: any, b: any) {
            if (_.isObject(a)) {
                return _.merge({}, a, b, merger)
            } else {
                return a || b
            }
        }
        let args: any = _.flatten([{}, roles, merger])
        return _.merge.apply(_, args)
    }
    const intl = useIntl()
    const darkMode = useSelector(isDarkMode)
    const heads = metadata.table_heads
    const firstColFormat = metadata.table_index
    const dataNew = mergeData(data)
    const dataNewCompare = mergeData(compare_data)
    const theme = useTheme()
    let availableKeys: string[] | undefined = undefined
    if (heads) {
        availableKeys = Object.keys(heads)
        delete availableKeys[0]
    }

    const [sortCriteria, setSortCriteria] = useState('TABLE_INDEX')
    const [sortReverse, setSortReverse] = useState(false)

    const handleSort = (field: string) => {
        if (sortCriteria === field && !sortReverse) {
            setSortReverse(true)
        } else if (sortCriteria === field && sortReverse) {
            setSortCriteria(field)
            setSortReverse(false)
        } else if (sortCriteria !== field) {
            setSortCriteria(field)
            setSortReverse(false)
        }
    }

    const isHeadHighlighted = (key: string) => {
        return !!metadata.highlight_table_cells?.includes(key)
    }

    const sortArrow = (criteria: string, title: string, tooltip?: string) => {
        const tooltipmsg = tooltip
        if (sortCriteria === criteria) {
            if (sortReverse) {
                return (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            userSelect: 'none',
                            whiteSpace: 'noWrap',
                            display: 'flex',
                            justifyContent: criteria !== 'TABLE_INDEX' ? 'flex-end' : 'flex-start',
                        }}
                        variant={'body2'}
                    >
                        {criteria !== 'TABLE_INDEX' && (
                            <UnfoldMore
                                className={'sortIcon'}
                                sx={{
                                    verticalAlign: 'middle',
                                    fontSize: '1.5em',
                                    marginRight: '-14px',
                                    transform: 'translateX(-14px)',
                                    fill: 'url(#linearColorsReverse)',
                                    filter: `invert(${+darkMode})`,
                                }}
                            />
                        )}
                        {title}
                        {tooltipmsg && (
                            <Tooltip title={intl.formatMessage({ id: tooltipmsg })}>
                                <Info sx={{ ml: 1 }} fontSize="small" color="inherit" />
                            </Tooltip>
                        )}
                        {criteria === 'TABLE_INDEX' && (
                            <UnfoldMore
                                className={'sortIcon'}
                                sx={{
                                    verticalAlign: 'middle',
                                    fontSize: '1.5em',
                                    marginRight: '-14px',
                                    fill: 'url(#linearColorsReverse)',
                                    filter: `invert(${+darkMode})`,
                                }}
                            />
                        )}
                    </Typography>
                )
            } else {
                return (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            userSelect: 'none',
                            whiteSpace: 'noWrap',
                            display: 'flex',
                            justifyContent: criteria !== 'TABLE_INDEX' ? 'flex-end' : 'flex-start',
                        }}
                        variant={'body2'}
                    >
                        {criteria !== 'TABLE_INDEX' && (
                            <UnfoldMore
                                className={'sortIcon'}
                                sx={{
                                    verticalAlign: 'middle',
                                    fontSize: '1.5em',
                                    marginLeft: '-14px',
                                    fill: 'url(#linearColors)',
                                    filter: `invert(${+darkMode})`,
                                }}
                            />
                        )}
                        {title}
                        {tooltipmsg && (
                            <Tooltip title={intl.formatMessage({ id: tooltipmsg })}>
                                <Info sx={{ ml: 1 }} fontSize="small" color="inherit" />
                            </Tooltip>
                        )}
                        {criteria === 'TABLE_INDEX' && (
                            <UnfoldMore
                                className={'sortIcon'}
                                sx={{
                                    verticalAlign: 'middle',
                                    fontSize: '1.5em',
                                    marginRight: '-14px',
                                    fill: 'url(#linearColors)',
                                    filter: `invert(${+darkMode})`,
                                }}
                            />
                        )}
                    </Typography>
                )
            }
        } else {
            return (
                <Typography
                    sx={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        userSelect: 'none',
                        whiteSpace: 'noWrap',
                        display: 'flex',
                        justifyContent: criteria !== 'TABLE_INDEX' ? 'flex-end' : 'flex-start',
                        '&:hover': { '& .sortIcon': { opacity: 0.5 } },
                    }}
                    variant={'body2'}
                >
                    {criteria !== 'TABLE_INDEX' && (
                        <UnfoldMore
                            className={'sortIcon'}
                            sx={{
                                verticalAlign: 'middle',
                                fontSize: '1.5em',
                                marginLeft: '-14px',
                                opacity: +(sortCriteria === '') * 0.15,
                                transition: 'opacity .1s',
                            }}
                        />
                    )}
                    {title}
                    {tooltipmsg && (
                        <Tooltip title={intl.formatMessage({ id: tooltipmsg })}>
                            <Info sx={{ ml: 1 }} fontSize="small" color="inherit" />
                        </Tooltip>
                    )}
                    {criteria === 'TABLE_INDEX' && (
                        <UnfoldMore
                            className={'sortIcon'}
                            sx={{
                                verticalAlign: 'middle',
                                fontSize: '1.5em',
                                marginRight: '-14px',
                                opacity: +(sortCriteria === '') * 0.15,
                                transition: 'opacity .1s',
                            }}
                        />
                    )}
                </Typography>
            )
        }
    }

    const getColorTrend = (percentualChange: number, invert: boolean = false, neutral: boolean = false) => {
        if (neutral) {
            return theme.palette.text.primary
        }

        const valueForColor = invert ? _.clone(percentualChange) * -1 : percentualChange

        if (valueForColor > 0) {
            return theme.palette.success.main
        }

        if (valueForColor < 0) {
            return theme.palette.error.main
        }

        return theme.palette.text.primary
    }

    const renderNumberWithIcon = (value: number, invert = false, justifyContent?: string) => {
        return (
            <Typography component="div" variant={'body2'} style={{ color: getColorTrend(value, invert) }}>
                <Grid container justifyContent={justifyContent || 'center'}>
                    <Grid item>{value > 0 ? <TrendingUp /> : value === 0 || isNaN(value) ? <TrendingFlat /> : <TrendingDown />}</Grid>
                    <Grid item>
                        <Box marginLeft={1}>
                            {!isFinite(value) || isNaN(value) ? (
                                <FormattedMessage id="general.nan" />
                            ) : (
                                <>
                                    {/* eslint-disable-next-line react/style-prop-object */}
                                    <FormattedNumber value={value} style="percent" maximumFractionDigits={2} />
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Typography>
        )
    }

    const formatObjectiveMessage = (unformatted: string) => {
        const split = unformatted.split('.objective.')[1].split('.goal.')
        const objective = split[0]
        const optimizationGoal = split[1]
        return (
            <>
                {intl.formatMessage({ id: `ad_insights.optimization_goal.${optimizationGoal}` })}
                <br />({intl.formatMessage({ id: `ad_insights.objective.${objective}` })})
            </>
        )
    }

    const formatDateString = (unformatted: string) => {
        return moment(unformatted).format(metadata.date_format)
    }

    const weekOfMonth = (input: Moment) => {
        const firstDayOfMonth = input.clone().startOf('month')
        const firstDayOfWeek = firstDayOfMonth.clone().startOf('week')

        const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days')

        return Math.ceil((input.date() + offset) / 7)
    }

    const formatWeek = (unformatted: string) => {
        return weekOfMonth(moment(unformatted)).toString() + ' ' + moment(unformatted).format('MMM') + ' ' + moment(unformatted).year()
    }

    const formatFirstCol = (value: string, returnOnlyValue: boolean = false) => {
        switch (firstColFormat) {
            case 'DATA_SOURCE_ID':
                const mapped_datasources = _.map(datasources, (ds) => {
                    return ds.id === value ? ds.name : null
                })
                return _.filter(mapped_datasources, (d) => d).toString()
            case 'TAG_ID':
                const mapped_tags = _.map(tags, (tag) => {
                    return tag.id === value ? tag.label : null
                })
                return _.filter(mapped_tags, (d) => d).toString()
            case 'ADS_OBJECTIVE':
                return formatObjectiveMessage(value)
            case 'MONTH':
                return value
            case 'FLEXIBLE_DATE':
                const firstDate = value.split(' - ')[0]
                const secondDate = value.split(' - ')[1]
                let formattedFirstDate = formatDateString(firstDate)
                let formattedSecondDate = formatDateString(secondDate)
                let prefix = ''
                if (metadata.date_format === 'weekends_range' || metadata.date_format === 'weekdays_range') {
                    formattedFirstDate = formatWeek(firstDate)
                    formattedSecondDate = formatWeek(firstDate)
                    prefix =
                        metadata.date_format === 'weekends_range'
                            ? intl.formatMessage({ id: 'general.weekend' })
                            : intl.formatMessage({ id: 'general.work-week' })
                    prefix = prefix + ' '
                }
                if (metadata.date_format === 'W') {
                    prefix = intl.formatMessage({ id: 'publishing.calendar-week-abbr' }) + ' '
                }
                if (metadata.date_format === 'Q YYYY') {
                    prefix = 'Q'
                }
                if (formattedFirstDate === formattedSecondDate && !returnOnlyValue) {
                    return prefix + formattedFirstDate
                }
                return returnOnlyValue ? firstDate : `${prefix}${formattedFirstDate} - ${formattedSecondDate}`
            default:
                return value
        }
    }
    const getColor = (value: string) => {
        switch (firstColFormat) {
            case 'DATA_SOURCE_ID':
                const mapped_datasources = _.map(datasources, (ds) => {
                    return ds.id === value ? ds.color : null
                })
                return _.filter(mapped_datasources, (d) => d).toString()
            case 'TAG_ID':
                const mapped_tags = _.map(tags, (tag) => {
                    return tag.id === value ? tag.color : null
                })
                return _.filter(mapped_tags, (d) => d).toString()
            default:
                return '#00000000'
        }
    }

    const dsTableValues = () => {
        if (sortReverse && sortCriteria !== 'TABLE_INDEX') {
            return _.fromPairs(
                _.sortBy(_.toPairs(dataNew), [
                    ([key]) => {
                        return dataNew[key][sortCriteria]
                    },
                ]).reverse()
            )
        } else if (sortReverse && sortCriteria === 'TABLE_INDEX') {
            return _.fromPairs(
                _.sortBy(_.toPairs(dataNew), [
                    ([key]) => {
                        return formatFirstCol(key.toString(), true)
                    },
                ]).reverse()
            )
        } else if (sortCriteria !== 'TABLE_INDEX') {
            return _.fromPairs(
                _.sortBy(_.toPairs(dataNew), [
                    ([key]) => {
                        return dataNew[key][sortCriteria]
                    },
                ])
            )
        } else if (sortCriteria === 'TABLE_INDEX') {
            return _.fromPairs(
                _.sortBy(_.toPairs(dataNew), [
                    ([key]) => {
                        return formatFirstCol(key.toString(), true)
                    },
                ])
            )
        } else {
            return dataNew
        }
    }

    const generateSuffix = (key: string, value?: number) => {
        if (metadata.suffix_table_cells) {
            if (key in metadata.suffix_table_cells) {
                if (metadata.suffix_table_cells[key] === '€' && value && value < 0.01) {
                    return ' ct'
                } else if (metadata.suffix_table_cells[key] === '€') {
                    return ' ' + metadata.suffix_table_cells[key]
                }
                return ' ' + metadata.suffix_table_cells[key]
            }
        }
    }

    const generateTooltip = (key: string, value: string) => {
        if (metadata.tooltip_table_heads) {
            if (metadata.tooltip_table_heads.includes(key)) {
                return value + '.tooltip'
            }
        }
    }

    return availableKeys ? (
        <Paper sx={{ overflow: 'scroll' }}>
            <svg width={0} height={0} style={{ position: 'absolute' }}>
                <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="rgba(0,0,0,15)" />
                    <stop offset={0.5} stopColor="rgba(0,0,0,15)" />
                    <stop offset={0.5} stopColor="rgba(0,0,0,0.1)" />
                    <stop offset={1} stopColor="rgba(0,0,0,0.1)" />
                </linearGradient>
                <linearGradient id="linearColorsReverse" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="rgba(0,0,0,0.15)" />
                    <stop offset={0.5} stopColor="rgba(0,0,0,0.15)" />
                    <stop offset={0.5} stopColor="rgba(0,0,0,1)" />
                    <stop offset={1} stopColor="rgba(0,0,0,1)" />
                </linearGradient>
            </svg>
            <Table>
                <TableHead>
                    <TableRow
                        sx={
                            isHeadHighlighted('TABLE_INDEX')
                                ? {
                                      borderLeft: '3px solid',
                                      borderLeftColor: 'grey.A200',
                                  }
                                : {}
                        }
                    >
                        <TableCell
                            onClick={() => handleSort('TABLE_INDEX')}
                            sx={
                                isHeadHighlighted('TABLE_INDEX')
                                    ? {
                                          backgroundColor: 'grey.A200',
                                          maxWidth: 200,
                                          position: 'sticky',
                                          left: '1px',
                                          '&::before': {
                                              content: '""',
                                              display: 'block',
                                              background: 'grey.A200',
                                              position: 'absolute',
                                              width: '4px',
                                              left: '-3px',
                                              top: '-1px',
                                              height: 'calc(100% + 2px)',
                                          },
                                      }
                                    : {
                                          position: 'sticky',
                                          left: '1px',
                                          background: darkMode
                                              ? 'linear-gradient(90deg, #4c4c4c, #4c4c4c 90%, transparent)'
                                              : 'linear-gradient(90deg, white, white 90%, transparent)',
                                          '&::before': {
                                              content: '""',
                                              display: 'block',
                                              background: darkMode ? '#4c4c4c' : 'white',
                                              position: 'absolute',
                                              width: '4px',
                                              left: '-3px',
                                              top: '-1px',
                                              height: 'calc(100% + 2px)',
                                          },
                                      }
                            }
                        >
                            {sortArrow('TABLE_INDEX', intl.formatMessage({ id: heads!['TABLE_INDEX'] }))}
                        </TableCell>
                        {availableKeys.map((key: string) => (
                            <TableCell
                                key={'table_head_' + key}
                                onClick={() => handleSort(key)}
                                sx={
                                    isHeadHighlighted(key)
                                        ? {
                                              backgroundColor: darkMode ? theme.palette.background.paper : 'grey.A200',
                                              maxWidth: 200,
                                          }
                                        : {}
                                }
                            >
                                {sortArrow(key, intl.formatMessage({ id: heads![key] }), generateTooltip(key, heads![key]))}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataNew &&
                        _.map(Object.keys(dsTableValues()), (d: string) => {
                            return (
                                <TableRow key={'table_row' + d} sx={{ borderLeft: '3px solid ' + getColor(d) }}>
                                    <TableCell
                                        sx={{
                                            fontWeight: firstColFormat !== 'ADS_OBJECTIVE' ? 'bold' : 'unset',
                                            position: 'sticky',
                                            left: '1px',
                                            background: darkMode
                                                ? 'linear-gradient(90deg, #4c4c4c, #4c4c4c 90%, transparent)'
                                                : 'linear-gradient(90deg, white, white 90%, transparent)',
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                background: getColor(d),
                                                position: 'absolute',
                                                width: '4px',
                                                left: '-3px',
                                                top: '-1px',
                                                height: 'calc(100% + 2px)',
                                            },
                                        }}
                                    >
                                        {metadata.date_format && metadata.first_col_type === 'date' && metadata.table_index !== 'FLEXIBLE_DATE'
                                            ? formatDateString(d)
                                            : formatFirstCol(d)}
                                    </TableCell>
                                    {availableKeys &&
                                        availableKeys.map((key: string) => {
                                            const changedValue = dataNewCompare[d] ? (dataNew[d][key] - dataNewCompare[d][key]) / dataNewCompare[d][key] : 0
                                            return (
                                                <TableCell align={'right'} key={'table_value' + d + key}>
                                                    {metadata.unformatted_cols?.includes(key)
                                                        ? dataNew[d][key]
                                                        : metadata.suffix_table_cells && metadata.suffix_table_cells[key] === '€' && dataNew[d][key] < 0.01
                                                        ? formatNumber(dataNew[d][key] * 100)
                                                        : formatNumber(dataNew[d][key])}
                                                    {generateSuffix(key, dataNew[d][key])}
                                                    {metadata.table_index === 'ADS_OBJECTIVE' &&
                                                        renderNumberWithIcon(changedValue, metadata.inverted_cols?.includes(key), 'flex-end')}
                                                </TableCell>
                                            )
                                        })}
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </Paper>
    ) : (
        <></>
    )
}
