import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { TicketNode } from './TicketNode'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { Box, Button, CircularProgress, darken, IconButton, keyframes, lighten, ListItem, SwipeableDrawer, Tooltip, useTheme } from '@mui/material'
import { ChatBubble, ContentCopy, Delete, InsertLink, MarkEmailRead, MarkEmailUnread, MoreHoriz, ThumbUp, Visibility, VisibilityOff } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { isDarkMode, showSuccessSnackbar } from '../core/slices/CoreSlice'
import confetti from 'canvas-confetti'
import { HmstrDispatch } from '../core/Store'
import { setNodeToMention } from './EngagementSlice'

type TicketNodeActionsMobileProps = {
    canMarkAsRead: boolean
    node: TicketNode
    handleStateChange: (state: 'MARK_AS_READ' | 'MARK_AS_UNREAD') => void
    isPost: boolean
    isStory: boolean
    datasource: ProjectDatasource
    level: number
    removed: boolean
    canAnswerTicket: boolean
    handleAnswerClick: () => void
    canDeleteTicket: boolean
    handleDelete: () => void
    isHiding: boolean
    isOwnComment: boolean
    canHideTicket: boolean
    hidden: boolean
    handleHiddenChange: (action: 'HIDE' | 'SHOW') => void
    isLiking: boolean
    canLikeTicket: boolean
    handleLikeChange: (action: 'LIKE' | 'UNLIKE') => void
    time: JSX.Element
    passedIndex?: string
    overwriteOpen?: boolean
}

export const TicketNodeActionsMobile: FC<TicketNodeActionsMobileProps> = ({
    canMarkAsRead,
    node,
    handleStateChange,
    isPost,
    isStory,
    datasource,
    level,
    removed,
    canAnswerTicket,
    handleAnswerClick,
    canDeleteTicket,
    handleDelete,
    isHiding,
    isOwnComment,
    canHideTicket,
    hidden,
    handleHiddenChange,
    isLiking,
    canLikeTicket,
    handleLikeChange,
    time,
    passedIndex,
    overwriteOpen,
}) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const [contextMenuAnchor, setContextMenuAnchor] = useState<any>(false)
    const moreButtonRef = useRef<any>(null)
    const darkMode = useSelector(isDarkMode)
    const theme = useTheme()
    const likeButtonRef = useRef<any>(null)

    const copyText = () => {
        navigator.clipboard.writeText(node.message).then(() => {
            dispatch(showSuccessSnackbar(`general.copy-success`))
            setContextMenuAnchor(false)
        })
    }

    const handleMentionNode = () => {
        dispatch(setNodeToMention(`#${passedIndex}`))
        setContextMenuAnchor(false)
    }

    const likeAnimation = keyframes`
      0% {
        transform: scale(1) rotate(0deg);
      }
      10% {
        transform: scale(1.3) rotate(-10deg);
      }
      100% {
        transform: scale(1) rotate(0deg);
      }
    `

    const unLikeAnimation = keyframes`
      0% {
        transform: scale(1) rotate(0deg);
      }
      10% {
        transform: scale(1) rotate(-180deg);
      }
      50% {
        transform: scale(1.2) rotate(-180deg);
        color: unset;

      }
      60% {
        color: ${theme.palette.error.main};
        transform: scale(1) rotate(-180deg) translateY(-10px);
      }
      80% {
        transform: scale(1) rotate(-180deg) translateY(0px);
      }
      100% {
        transform: scale(1) rotate(0deg);
      }
    `

    useEffect(() => {
        if (overwriteOpen && !contextMenuAnchor && moreButtonRef.current) {
            setContextMenuAnchor(moreButtonRef.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overwriteOpen, contextMenuAnchor])
    const renderRemainingOptions = () => {
        return (
            <>
                <ListItem>
                    <Button
                        disabled={!canMarkAsRead}
                        color="inherit"
                        size="small"
                        startIcon={node.is_read ? <MarkEmailUnread color="action" fontSize="small" /> : <MarkEmailRead color="action" fontSize="small" />}
                        sx={{
                            textTransform: 'unset',
                        }}
                        onClick={() => handleStateChange(node.is_read ? 'MARK_AS_UNREAD' : 'MARK_AS_READ')}
                    >
                        {node.is_read ? <FormattedMessage id="engagement.mark-as-unread" /> : <FormattedMessage id="engagement.mark-as-read" />}
                    </Button>
                </ListItem>
                {passedIndex && (
                    <ListItem>
                        <Button
                            color="inherit"
                            size="small"
                            startIcon={<InsertLink />}
                            sx={{
                                textTransform: 'unset',
                            }}
                            onClick={() => handleMentionNode()}
                        >
                            <FormattedMessage id={'engagement.create-note-from-node'} />
                        </Button>
                    </ListItem>
                )}
                {isPost && (
                    <ListItem>
                        <Button
                            size="small"
                            disabled={removed || !canDeleteTicket}
                            startIcon={<Delete color="action" fontSize="small" />}
                            onClick={handleDelete}
                            color={'inherit'}
                            sx={{
                                textTransform: 'unset',
                            }}
                        >
                            <FormattedMessage id="engagement.delete" />
                        </Button>
                    </ListItem>
                )}
                {node.message && (
                    <ListItem>
                        <Button
                            size="small"
                            startIcon={<ContentCopy color="action" fontSize="small" />}
                            onClick={() => copyText()}
                            color={'inherit'}
                            sx={{
                                textTransform: 'unset',
                            }}
                        >
                            <FormattedMessage id="general.copy" />
                        </Button>
                    </ListItem>
                )}
                {isPost && datasource.type !== 'LINKED_IN' && (
                    <ListItem>
                        <Button
                            size="small"
                            disabled={removed || isHiding || isOwnComment || !canHideTicket}
                            startIcon={
                                isHiding ? (
                                    <CircularProgress color="inherit" size={18} />
                                ) : hidden ? (
                                    <Visibility color="action" fontSize="small" />
                                ) : (
                                    <VisibilityOff color="action" fontSize="small" />
                                )
                            }
                            sx={{
                                textTransform: 'unset',
                            }}
                            color={'inherit'}
                            onClick={() => handleHiddenChange(hidden ? 'SHOW' : 'HIDE')}
                        >
                            <FormattedMessage id={hidden ? 'engagement.show' : 'engagement.hide'} />
                        </Button>
                    </ListItem>
                )}
            </>
        )
    }
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setContextMenuAnchor(event.currentTarget)
    }

    const handleLikeClick = () => {
        if (likeButtonRef.current && !node.is_liked) {
            const x = likeButtonRef.current.getBoundingClientRect().x / window.innerWidth
            const y = likeButtonRef.current.getBoundingClientRect().y / window.innerHeight
            confetti({ origin: { x: x, y: y }, spread: 360, scalar: 0.5, startVelocity: 5, gravity: 0.4 })
        }
        handleLikeChange(node.is_liked ? 'UNLIKE' : 'LIKE')
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            }}
        >
            <Box overflow={'hidden'} pl={1.5} pt={0.1} display={'inline-flex'}>
                {time}
            </Box>
            <Box
                borderColor="divider"
                p={1}
                pb={0}
                pt={0}
                display={!removed ? 'flex' : 'inline-flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexGrow={1}
            >
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    {!removed && (
                        <>
                            {(isPost || isStory) && (
                                <Tooltip
                                    title={datasource.type === 'LINKED_IN' && level > 0 ? <FormattedMessage id="engagement.linkedin.only-first-level" /> : ''}
                                >
                                    <span>
                                        <Button
                                            color="inherit"
                                            disabled={removed || (datasource.type === 'LINKED_IN' && level > 0) || !canAnswerTicket}
                                            sx={{
                                                textTransform: 'unset',
                                            }}
                                            size="small"
                                            onClick={handleAnswerClick}
                                            startIcon={<ChatBubble color="action" fontSize="small" />}
                                        >
                                            <FormattedMessage id="engagement.answer" />
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                            {((isPost && datasource.type !== 'INSTAGRAM_ACCOUNT') || isStory) && (
                                <Button
                                    size="small"
                                    disabled={removed || isLiking || !canLikeTicket}
                                    startIcon={
                                        <ThumbUp
                                            sx={{
                                                animation:
                                                    !node.is_liked && isLiking
                                                        ? `${likeAnimation} 1s 1`
                                                        : isLiking && node.is_liked
                                                        ? `${unLikeAnimation} 1s 1`
                                                        : undefined,
                                            }}
                                            color={(node.is_liked && !isLiking) || (!node.is_liked && isLiking) ? 'info' : 'action'}
                                            fontSize="small"
                                            ref={likeButtonRef}
                                        />
                                    }
                                    color={'inherit'}
                                    sx={{
                                        textTransform: 'unset',
                                        color:
                                            (node.is_liked && !isLiking) || (!node.is_liked && isLiking)
                                                ? theme.palette.info.main + ' !important'
                                                : theme.palette.action.active + ' !important',
                                    }}
                                    onClick={() => handleLikeClick()}
                                >
                                    <FormattedMessage id={'facebook.reaction.like'} />
                                </Button>
                            )}
                        </>
                    )}
                </Box>
                <div>
                    <IconButton onClick={handleClick} ref={moreButtonRef}>
                        <MoreHoriz />
                    </IconButton>
                    <SwipeableDrawer
                        anchor={'bottom'}
                        open={!!contextMenuAnchor}
                        onOpen={() => setContextMenuAnchor(true)}
                        onClose={() => {
                            setContextMenuAnchor(false)
                        }}
                        PaperProps={{
                            sx: {
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                                pb: 4,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                height: '5px',
                                width: '30%',
                                background: darkMode ? lighten(theme.palette.background.paper, 0.3) : darken(theme.palette.background.paper, 0.3),
                                margin: '10px auto',
                                borderRadius: 100,
                            }}
                        />
                        {renderRemainingOptions()}
                    </SwipeableDrawer>
                </div>
            </Box>
        </Box>
    )
}
