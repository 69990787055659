import * as React from 'react'
import { FC } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { PublishingMedia } from '../../PublishingMedia'
import { alpha } from '@mui/material'

type TiktokImagePreviewProps = {
    uploadFiles: PublishingMedia[]
}
const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

export const TiktokImagePreview: FC<TiktokImagePreviewProps> = ({ uploadFiles }) => {
    const images = uploadFiles.map((file) => file._links.file.href)
    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = images.length

    const handleStepChange = (step: number) => {
        setActiveStep(step)
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Box position={'relative'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <AutoPlaySwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={activeStep} onChangeIndex={handleStepChange}>
                    {images.map((step, index) => (
                        <div key={step}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <Box
                                    component="img"
                                    sx={{
                                        margin: 'auto',
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                    src={step}
                                />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                    sx={{
                        background: alpha(theme.palette.background.paper, 0.5),
                        position: 'absolute',
                        bottom: 8,
                        borderRadius: '100px',
                    }}
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={<></>}
                    backButton={<></>}
                />
            </Box>
        </Box>
    )
}
