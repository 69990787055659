import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { PublishingMedia } from '../../PublishingMedia'
import { PublishingPreviewImageGridItem } from '../../PublishingPreviewImageGridItem'
import { ChevronLeftRounded, ChevronRightRounded, Circle } from '@mui/icons-material'
import { PostPreviewVideoInstagram } from './PostPreviewVideoInstagram'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getIsMobile } from '../../../core/slices/CoreSlice'

type PostPreviewImageInstagramProps = {
    uploadFiles: PublishingMedia[]
    story?: boolean
    mockupDevice?: 'desktop' | 'mobile'
}

export const PostPreviewImageInstagram: FC<PostPreviewImageInstagramProps> = ({ uploadFiles, story, mockupDevice }) => {
    const theme = useTheme()
    const [step, setStep] = useState<number>(0)
    const [scrolling, setScrolling] = useState(false)
    const instagramSlideContainerRef = useRef<any>(null)
    const [widthOfCarousel, setWidthOfCarousel] = useState(
        instagramSlideContainerRef.current ? instagramSlideContainerRef.current.getBoundingClientRect().width : 0
    )
    const isMobile = useSelector(getIsMobile)

    useEffect(() => {
        if (instagramSlideContainerRef.current) {
            setWidthOfCarousel(instagramSlideContainerRef.current.getBoundingClientRect().width)
        }
    }, [uploadFiles.length])

    const scrollImages = (dir: 'left' | 'right', amount: number = 1) => {
        const slideContainer = document.getElementById('instagramSlideContainer')

        if (slideContainer && !scrolling) {
            setScrolling(true)
            const runAfterScrollTo = (finalPosition: number) => {
                const checkIfScrollToIsFinished = setInterval(() => {
                    if (Math.round(finalPosition) === Math.round(slideContainer.scrollLeft)) {
                        setScrolling(false)
                        clearInterval(checkIfScrollToIsFinished)
                    }
                }, 25)
            }
            if (dir === 'left') {
                slideContainer.scrollTo({
                    left: slideContainer.scrollLeft - slideContainer.getBoundingClientRect().width * amount,
                    behavior: 'smooth',
                })
                runAfterScrollTo(slideContainer.scrollLeft - slideContainer.getBoundingClientRect().width * amount)
            } else if (dir === 'right') {
                slideContainer.scrollTo({
                    left: slideContainer.scrollLeft + slideContainer.getBoundingClientRect().width * amount,
                    behavior: 'smooth',
                })
                runAfterScrollTo(slideContainer.scrollLeft + slideContainer.getBoundingClientRect().width * amount)
            }
        }
    }

    const handleScroll = (e: any) => {
        if (instagramSlideContainerRef.current) {
            if (step !== _.round(e.target.scrollLeft / widthOfCarousel)) {
                setStep(_.round(e.target.scrollLeft / widthOfCarousel))
            }
        }
    }

    const handleStepClick = (idx: number) => {
        if (idx < step) {
            scrollImages('left', step - idx)
        } else if (idx > step) {
            scrollImages('right', idx - step)
        }
    }

    return (
        <Box
            mr={story ? undefined : -2}
            ml={story ? undefined : -2}
            position={'relative'}
            height={'fit-content'}
            sx={{
                userSelect: 'none',
                height: '100%',
                borderTop: '1px solid transparent',
                borderTopColor: theme.palette.divider,
                width: story ? '100%' : undefined,
            }}
        >
            <Box
                height={'50%'}
                position={'absolute'}
                sx={{
                    px: 1,
                    display: step > 0 && (mockupDevice === 'desktop' || !isMobile) ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    left: 0,
                    zIndex: 100,
                    top: '25%',
                }}
            >
                <ChevronLeftRounded
                    onClick={() => scrollImages('left')}
                    fontSize={'medium'}
                    sx={{
                        color: theme.palette.primary.main,
                        background: theme.palette.primary.contrastText,
                        borderRadius: '100%',
                        opacity: 0.8,
                        cursor: 'pointer',
                        filter: 'drop-shadow(0px 0px 5px #3334)',
                    }}
                />
            </Box>
            <Box
                height={'50%'}
                position={'absolute'}
                sx={{
                    px: 1,
                    display: step < uploadFiles.length - 1 && (mockupDevice === 'desktop' || !isMobile) ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    right: 0,
                    top: '25%',
                    filter: 'drop-shadow(0px 0px 5px #3334)',
                    zIndex: 100,
                }}
            >
                <ChevronRightRounded
                    onClick={() => scrollImages('right')}
                    fontSize={'medium'}
                    sx={{
                        color: theme.palette.primary.main,
                        background: theme.palette.primary.contrastText,
                        borderRadius: '100%',
                        opacity: 0.8,
                        cursor: 'pointer',
                    }}
                />
            </Box>

            <Box
                position={'absolute'}
                width={'100%'}
                height={'20px'}
                alignItems={'center'}
                display={'flex'}
                justifyContent={'center'}
                bottom={0}
                gap={'3px'}
                zIndex={1300}
            >
                {uploadFiles.length > 1 &&
                    uploadFiles.map((file, idx) => (
                        <Circle
                            onClick={() => handleStepClick(idx)}
                            key={file.id}
                            sx={{
                                color: theme.palette.primary.contrastText,
                                opacity: idx === step ? 1 : 0.5,
                                fontSize: '8px',
                                borderRadius: '100%',
                                boxShadow: `0px 0px 0px 0.5px ${theme.palette.primary.main}`,
                            }}
                        />
                    ))}
            </Box>
            <Box
                id={'instagramSlideContainer'}
                ref={instagramSlideContainerRef}
                flexWrap={'nowrap'}
                display={'flex'}
                sx={{
                    overflowX: 'scroll',
                    scrollSnapType: 'x mandatory',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    height: story ? '100%' : undefined,
                }}
                onScroll={handleScroll}
            >
                {uploadFiles.map((mediaFile, idx) => {
                    if (mediaFile.category === 'IMAGE') {
                        return (
                            <div
                                key={`gridItemImage${mediaFile.id}`}
                                style={{
                                    minWidth: '100%',
                                    scrollSnapAlign: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <PublishingPreviewImageGridItem media={mediaFile} width="100%" story={story} mockupDevice={mockupDevice} />
                            </div>
                        )
                    } else if (mediaFile.category === 'VIDEO') {
                        return (
                            <div
                                key={`gridItemImage${mediaFile.id}`}
                                style={{
                                    minWidth: '100%',
                                    scrollSnapAlign: 'center',
                                    minHeight: '100%',
                                }}
                            >
                                <PostPreviewVideoInstagram onscreen={step === idx} file={mediaFile} />
                            </div>
                        )
                    }
                    return <></>
                })}
            </Box>
        </Box>
    )
}
