import { FC, useEffect, useRef, useState } from 'react'
import { Box, Paper, Tooltip, Typography } from '@mui/material'
import { Cell, Legend, Pie, PieChart as RPieChart, ResponsiveContainer, Sector, Tooltip as PieTooltip } from 'recharts'
import { ChartTooltip } from '../../common/chart-tooltip/ChartTooltip'
import { PieChartData } from '../../common/chart-data-types/PieChartData'
import Animation from 'react-smooth'
import { FormattedMessage, useIntl } from 'react-intl'
import { PieChartLabel } from '../../common/pie-chart-label/PieChartLabel'
import { DownloadGraphButton } from '../../common/download-graph-button/DownloadGraphButton'
import { calculateOptimalColor } from '../../common/accessibility/ContrastChecker'
import { useSelector } from 'react-redux'
import { getImpairedVision, getIsMobile, isDarkMode } from '../../core/slices/CoreSlice'
import * as _ from 'lodash'
import { Info } from '@mui/icons-material'
import { NoData } from '../../common/chart-tooltip/NoData'
import { extractPieData } from './extractPieData'
import { DashboardWidgetData } from '../DashboardData'
import { getTimePeriodWithPrefix } from '../../core/theme/helper'

type FacebookPieChartProps = {
    title: string
    data: PieChartData
    straightAngle?: boolean
    legend?: boolean
    height?: number
    top4?: boolean
    debounceInMs?: number
    metadata?: DashboardWidgetData['metadata']
}

const activePieOffset = 5
const activePieAnimationDurationMs = 200

export const PieChart: FC<FacebookPieChartProps> = ({ data, title, straightAngle, legend = true, height, top4 = false, debounceInMs = 20, metadata }) => {
    const intl = useIntl()
    const darkMode = useSelector(isDarkMode)
    const randomId = useRef('_' + Math.random().toString(36).substr(2, 9))
    const [pieOffset, setPieOffset] = useState<number>(0)
    const visionImpaired = useSelector(getImpairedVision)
    const [activePieIndex, setActivePieIndex] = useState<number | undefined>(undefined)
    const [legendHeight, setLegendHeight] = useState(0)
    const [outerRadius, setOuterRadius] = useState(90)
    const [innerRadius, setInnerRadius] = useState(40)
    const [heightWithoutLegend, setHeightWithoutLegend] = useState(height ? height : 150)
    const { totalvalue, dataTop4, dataRest } = extractPieData(data, intl)
    const filteredData = data.filter((d) => d.value > 0)
    const isMobile = useSelector(getIsMobile)

    useEffect(() => {
        const checkInterval = setInterval(() => {
            const element = document.querySelector(`#${randomId.current} .recharts-legend-wrapper`)

            if (element) {
                setPieDimensions()
                clearInterval(checkInterval)
            }
        }, 100)

        const timeout = setTimeout(() => {
            clearInterval(checkInterval)
        }, 3000)

        return () => {
            clearTimeout(timeout)
            clearInterval(checkInterval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [randomId.current, data.length])

    useEffect(() => {
        const setPieDimensionsDebounced = _.debounce(setPieDimensions, 100)
        window.addEventListener('resize', setPieDimensionsDebounced)
        return () => {
            window.removeEventListener('resize', setPieDimensionsDebounced)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onMouseEnter = (_: any, index: number) => {
        setPieOffset(activePieOffset)
        setActivePieIndex(index)
    }

    const onMouseLeave = () => {
        setPieOffset(0)
    }

    const renderActiveShape = (props: any) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

        return (
            <g>
                <Animation from={{ t: 0 }} to={{ t: pieOffset }} duration={activePieAnimationDurationMs}>
                    {({ t }: { t: number }) => {
                        return (
                            <Sector
                                style={{ cursor: 'pointer' }}
                                cx={cx}
                                cy={cy}
                                innerRadius={innerRadius - t}
                                outerRadius={outerRadius + t}
                                startAngle={startAngle}
                                endAngle={endAngle}
                                fill={fill}
                            />
                        )
                    }}
                </Animation>
            </g>
        )
    }

    const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)

    const setPieDimensions = () => {
        setTimeout(() => {
            const label = document.querySelector(`#${randomId.current} .recharts-legend-wrapper`)
            const wrapper = document.querySelector(`#${randomId.current} .recharts-wrapper .recharts-surface`)
            const pie = document.querySelector(`#${randomId.current} .recharts-wrapper .recharts-layer.recharts-pie`)
            const titleBar = document.querySelector(`#${randomId.current} > div`)

            if (wrapper && label && titleBar && pie) {
                let minSize = Math.min(
                    wrapper.getBoundingClientRect().width,
                    straightAngle
                        ? (wrapper.getBoundingClientRect().height - label.getBoundingClientRect().height) * 2
                        : wrapper.getBoundingClientRect().height - label.getBoundingClientRect().height
                )

                const clampOuterRadius = clamp(minSize / 2 - 6, 40, 100)
                const clampInnerRadius = clamp(clampOuterRadius - 50, 20, 40)

                setHeightWithoutLegend(clampOuterRadius * (straightAngle ? 1 : 2) + titleBar.getBoundingClientRect().height)
                setLegendHeight(label.getBoundingClientRect().height)
                setOuterRadius(clampOuterRadius)
                setInnerRadius(clampInnerRadius)
            }
        }, debounceInMs + 50)
    }

    return (
        <Paper
            id={randomId.current}
            sx={{
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <DownloadGraphButton tileId={randomId.current} parentRelativeFlex />
            <Box padding={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box paddingLeft={1} display={'flex'}>
                    <Typography>
                        <strong>
                            <FormattedMessage id={title} />
                        </strong>
                    </Typography>
                    {dataRest.trim().length > 0 ? (
                        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{dataRest}</div>}>
                            <Box paddingLeft={1} justifyContent={'flex-start'} height={20} mt={0.25}>
                                <Info fontSize="small" />
                            </Box>
                        </Tooltip>
                    ) : null}
                </Box>
            </Box>

            <Box
                height={heightWithoutLegend + legendHeight}
                width="100%"
                padding={1}
                flexGrow={1}
                sx={{
                    '.recharts-layer.recharts-pie-sector:focus': {
                        outline: 'none !important',
                    },
                }}
            >
                {totalvalue > 0 && (
                    <ResponsiveContainer width="100%" height="100%" debounce={debounceInMs} id={randomId.current + '_responsive_container'}>
                        <RPieChart
                            width={100}
                            style={{ width: '100% !important' }}
                            margin={{
                                top: height && straightAngle ? height - height / 2 : 0,
                            }}
                        >
                            <Pie
                                startAngle={straightAngle ? 180 : undefined}
                                endAngle={straightAngle ? 0 : undefined}
                                activeShape={renderActiveShape}
                                activeIndex={activePieIndex}
                                data={top4 ? dataTop4 : filteredData}
                                isAnimationActive={false}
                                dataKey="value"
                                stroke="0"
                                innerRadius={innerRadius}
                                outerRadius={outerRadius}
                                paddingAngle={1}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                                labelLine={isMobile}
                                label={isMobile || PieChartLabel}
                            >
                                {(top4 ? dataTop4 : data).map((entry, index) => {
                                    return (
                                        <Cell
                                            style={{ cursor: 'pointer', outline: 'none !important' }}
                                            key={`cell-${index}`}
                                            fill={calculateOptimalColor(visionImpaired, entry.color, darkMode)}
                                        />
                                    )
                                })}
                            </Pie>
                            <PieTooltip
                                wrapperStyle={{ outline: 'none !important' }}
                                content={({ active, label, payload }) => (
                                    <ChartTooltip active={active} label={label} payload={payload} dateFormat={metadata?.date_format} />
                                )}
                            />
                            {legend && (
                                <Legend
                                    verticalAlign={'bottom'}
                                    formatter={(value) =>
                                        metadata?.date_format ? (
                                            getTimePeriodWithPrefix(value, metadata.date_format, intl)
                                        ) : (
                                            <>
                                                <FormattedMessage id={value} />
                                            </>
                                        )
                                    }
                                />
                            )}
                        </RPieChart>
                    </ResponsiveContainer>
                )}
                {totalvalue === 0 && <NoData />}
            </Box>
        </Paper>
    )
}
