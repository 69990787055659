import { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { BillingSubscription } from './BillingSubscription'

type BillingReactivateSubscriptionDialogProps = {
    open: boolean
    onClose: () => any
    onSave: () => any
    subscription: BillingSubscription
}

export const BillingReactivateSubscriptionDialog: FC<BillingReactivateSubscriptionDialogProps> = (props) => {
    const { open, onClose, onSave } = props

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" keepMounted={false}>
            <DialogTitle>
                <FormattedMessage id="billing.subscription.account-reactivate" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="billing.reactivate-details" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage id="general.close" />
                </Button>
                <Button color="secondary" variant="contained" onClick={onSave}>
                    <FormattedMessage id="billing.reactivate" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
