import { FC } from 'react'
import { Grid } from '@mui/material'
import { BillingInfoFormFields } from '../billing/BillingInfoFormFields'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { validationRequired } from '../../form/validate'

type AccountInfoSectionProps = {}

export const AccountInfoSection: FC<AccountInfoSectionProps> = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ConnectedTextField name="name" label="accounts.name" required validate={validationRequired} />
            </Grid>

            <BillingInfoFormFields disableCustomerId={false} />
        </Grid>
    )
}
