import { Box, Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import { Info } from '@mui/icons-material'
import { ChangeEvent, FC } from 'react'
import { Field, FieldInputProps } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

type ConnectedCheckboxProps = {
    name: string
    label?: string
    tooltip?: string
    disabled?: boolean
    handleChange?: (value: boolean) => any
}

export const ConnectedCheckbox: FC<ConnectedCheckboxProps> = ({ name, label, tooltip, disabled, handleChange }) => {
    const handleCheckboxChange = (event: ChangeEvent, checked: boolean, input: FieldInputProps<any>) => {
        input.onChange(event)
        if (handleChange) {
            handleChange(checked)
        }
    }

    return (
        <Field name={name} type="checkbox">
            {({ input }) => (
                <FormControlLabel
                    disabled={disabled}
                    control={<Checkbox color={'default'} checked={input.checked} onChange={(event, checked) => handleCheckboxChange(event, checked, input)} />}
                    label={
                        <Box alignItems="center" display="flex">
                            <Box marginRight={1}>
                                <FormattedMessage id={label} />
                            </Box>
                            {tooltip && (
                                <Tooltip title={<FormattedMessage id={tooltip} />}>
                                    <Info fontSize="small" />
                                </Tooltip>
                            )}
                        </Box>
                    }
                />
            )}
        </Field>
    )
}
