import { FC } from 'react'
import { alpha, Checkbox, Grid, useTheme } from '@mui/material'
import { Field } from 'react-final-form'

type NotificationCheckboxProps = {
    rowName: string
    settingName: string
    highlighted?: boolean
}

export const NotificationCheckbox: FC<NotificationCheckboxProps> = ({ rowName, settingName, highlighted }) => {
    const theme = useTheme()
    const fieldName = `${rowName}.${settingName}`

    return (
        <Grid
            item
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1,
                background: highlighted ? alpha(theme.palette.secondary.main, 0.1) : undefined,
                transition: 'background 0.05s',
            }}
        >
            <Field name={fieldName} type="checkbox">
                {({ input }) => <Checkbox checked={input.checked} onChange={input.onChange} />}
            </Field>
        </Grid>
    )
}
