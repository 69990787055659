import moment from 'moment'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { getCommonPostDataAsIdMap, getSortedTagsData, getTagsData, IdMap } from '../../core/slices/DataSlice'
import { getUntaggedTag, UNTAGGED } from '../../tags/TagsSlice'
import { PostAnalysisSortType } from '../../common/filter/PostAnalysisSortType'
import { AggregatedTagData } from '../../tags/AggregatedTagData'
import { UrlParamsParser } from '../../common/parse-url-params/parseUrlParams'
import { CommonPostData } from '../posts/CommonPostData'
import { Tag } from '../../tags/Tag'
import * as _ from 'lodash'
import { createTag, deleteTag } from '../../tags/TagsActions'
import { getPagesForAnalyse } from '../../core/slices/CoreSlice'

interface PostAnalysisState {
    selectedTimePeriodStartDate: string
    selectedTimePeriodEndDate: string
    selectedDatasourceIds: string[]
    selectedTags: string[]
    sortDirection: 'asc' | 'desc'
    sortField: PostAnalysisSortType
    tagLimitReached: boolean
}

const generateInitialState = (): PostAnalysisState => {
    const urlParamsParser = new UrlParamsParser('/post-analysis')
    const defaultStartDate = moment().subtract(30, 'days').format()

    return {
        selectedTimePeriodStartDate: urlParamsParser.getDateFromUrl('startDate', defaultStartDate),
        selectedTimePeriodEndDate: urlParamsParser.getDateFromUrl('endDate', moment().format()),
        selectedDatasourceIds: urlParamsParser.getArrayFromUrl('dataSources', []),
        selectedTags: urlParamsParser.getArrayFromUrl('tags', []),
        sortDirection: 'desc',
        sortField: PostAnalysisSortType.NAME,
        tagLimitReached: false,
    }
}

export const PostAnalysisSlice = createSlice({
    name: 'postAnalysis',
    initialState: generateInitialState(),
    reducers: {
        changeTimePeriod: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
            state.selectedTimePeriodStartDate = action.payload.startDate
            state.selectedTimePeriodEndDate = action.payload.endDate
        },
        selectDatasources: (state, action: PayloadAction<string[]>) => {
            state.selectedDatasourceIds = action.payload
        },
        setSelectedTags: (state, action: PayloadAction<string[]>) => {
            state.selectedTags = action.payload
        },
        setSortDirection: (state, action: PayloadAction<'asc' | 'desc'>) => {
            state.sortDirection = action.payload
        },
        setSortField: (state, action: PayloadAction<PostAnalysisSortType>) => {
            state.sortField = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase('core/selectProject', (state) => {
                state.selectedDatasourceIds = []
                state.selectedTags = []
            })
            .addCase(createTag.rejected, (state, action: any) => {
                if (action.payload.status === 426) {
                    state.tagLimitReached = true
                }
            })
            .addCase(deleteTag.fulfilled, (state) => {
                state.tagLimitReached = false
            })
    },
})

export const getSelectedTimePeriodStart: Selector<HmstrState, string> = (state) => state.post_analysis.selectedTimePeriodStartDate
export const getSelectedTimePeriodEnd: Selector<HmstrState, string> = (state) => state.post_analysis.selectedTimePeriodEndDate
export const getSelectedDatasourceIds: Selector<HmstrState, string[]> = (state) => state.post_analysis.selectedDatasourceIds
export const getSelectedTags: Selector<HmstrState, string[]> = (state) => state.post_analysis.selectedTags
export const getSortDirection: Selector<HmstrState, 'asc' | 'desc'> = (state) => state.post_analysis.sortDirection
export const getSortField: Selector<HmstrState, PostAnalysisSortType> = (state) => state.post_analysis.sortField
export const getTagLimitReached: Selector<HmstrState, boolean> = (state) => state.post_analysis.tagLimitReached

export const getSelectedDatasources: Selector<HmstrState, ProjectDatasource[]> = createSelector(
    [getPagesForAnalyse, getSelectedDatasourceIds],
    (pages, datasourceIds) => {
        return pages.filter((page) => datasourceIds.includes(page.id))
    }
)

export const getPostDataForDatasources = createSelector(
    [getCommonPostDataAsIdMap, getSelectedDatasourceIds],
    (commonPostData, selectedDatasourceIds): CommonPostData[] => {
        return _.chain(selectedDatasourceIds)
            .map((dsid) => (commonPostData[dsid] || []) as CommonPostData[])
            .flatten()
            .value()
    }
)

export const combinePostsForDatasourceAndTimePeriod = (postData: CommonPostData[], startDate: string, endDate: string) => {
    const postsGroupedByTags: IdMap<CommonPostData[]> = {}
    const start = moment(startDate)
    const end = moment(endDate)

    postData.forEach((post) => {
        if (moment(post.created_at).isBetween(start, end, 'day', '[]')) {
            if (post.tags.length === 0) {
                if (!Object.keys(postsGroupedByTags).includes(UNTAGGED)) {
                    postsGroupedByTags[UNTAGGED] = []
                }
                postsGroupedByTags[UNTAGGED].push(post)
            } else {
                post.tags.forEach((tagId) => {
                    if (!Object.keys(postsGroupedByTags).includes(tagId)) {
                        postsGroupedByTags[tagId] = []
                    }
                    postsGroupedByTags[tagId].push(post)
                })
            }
        }
    })

    return postsGroupedByTags
}

export const getPostsByTagIdForSelectedDatasources = createSelector(
    [getPostDataForDatasources, getSelectedTimePeriodStart, getSelectedTimePeriodEnd],
    combinePostsForDatasourceAndTimePeriod
)

export const getFilteredPostsByTagIdForSelectedDatasources = createSelector(
    [getPostsByTagIdForSelectedDatasources, getSelectedTags],
    (postDataByTag, selectedTags) => {
        const filteredPostDataByTag = { ...postDataByTag }

        Object.keys(postDataByTag).forEach((key) => {
            if (!selectedTags.includes(key)) {
                delete filteredPostDataByTag[key]
            }
        })

        return filteredPostDataByTag
    }
)

export const combineAggregatedFilteredTagDataForSelectedDatasources = (postDataByTag: IdMap<CommonPostData[]>, tags: Tag[]) => {
    let tagsWithUntagged = [getUntaggedTag('tags.untagged-posts'), ...tags]

    const aggregatedTagData: AggregatedTagData[] = []

    Object.keys(postDataByTag).forEach((key) => {
        const value = postDataByTag[key]
        let tag = tagsWithUntagged.find((tag) => tag.id === key)
        if (tag) {
            let post_amount = value.length
            let post_reactions = 0
            let post_comments = 0
            let post_shares = 0
            let post_engagement_rate
            let post_impressions = 0
            let post_engagement = 0
            let post_clicks = 0
            let spend = 0

            value.forEach((data) => {
                let reactions = data.insights.reactions
                let clicks = data.insights.clicks || 0
                let engagement = reactions + data.insights.comments + (data.insights.shares || 0) + (data.insights.saves || 0)
                post_reactions += reactions
                post_comments += data.insights.comments
                post_shares += data.insights.shares || 0
                // CHECK HOW THIS SHOULD BE CALCULATED
                post_impressions += data.insights.impressions || 0
                post_engagement += engagement
                post_clicks += clicks
                spend += data.insights.spend
            })
            post_engagement_rate = (post_engagement / (post_impressions || 1)) * 100

            aggregatedTagData.push({
                tag: tag,
                post_amount: post_amount,
                post_reactions: post_reactions,
                post_comments: post_comments,
                post_shares: post_shares,
                post_engagement_rate: post_engagement_rate,
                post_impressions: post_impressions,
                post_engagement: post_engagement,
                post_clicks: post_clicks,
                spend: spend,
            })
        }
    })
    return aggregatedTagData
}
export const getAggregatedFilteredTagDataForSelectedDatasources = createSelector(
    [getFilteredPostsByTagIdForSelectedDatasources, getTagsData],
    combineAggregatedFilteredTagDataForSelectedDatasources
)

export const combineSortedAggregatedFilteredTagDataForSelectedDatasources = (
    aggregatedTagData: AggregatedTagData[],
    sortField: PostAnalysisSortType,
    sortDirection: 'asc' | 'desc'
) => {
    let copyOfAggregatedTagData = [...aggregatedTagData]
    return copyOfAggregatedTagData.sort((a, b) => {
        let res: number = 0
        switch (sortField) {
            case PostAnalysisSortType.NAME:
                res = a.tag.label.toLowerCase().localeCompare(b.tag.label.toLowerCase())
                break
            case PostAnalysisSortType.AMOUNT:
                res = a.post_amount - b.post_amount
                break
            case PostAnalysisSortType.REACTIONS:
                res = a.post_reactions - b.post_reactions
                break
            case PostAnalysisSortType.COMMENTS:
                res = a.post_comments - b.post_comments
                break
            case PostAnalysisSortType.SHARES:
                res = a.post_shares - b.post_shares
                break
            case PostAnalysisSortType.RKS:
                res = a.post_reactions + a.post_comments + a.post_shares - (b.post_reactions + b.post_comments + b.post_shares)
                break
            case PostAnalysisSortType.ENGAGEMENTRATE:
                res = a.post_engagement_rate - b.post_engagement_rate
                break
            case PostAnalysisSortType.IMPRESSIONS:
                res = a.post_impressions - b.post_impressions
                break
            case PostAnalysisSortType.ENGANGEMENT:
                res = a.post_engagement - b.post_engagement
                break
            case PostAnalysisSortType.SPEND:
                res = a.spend - b.spend
        }
        return sortDirection === 'asc' ? res : res * -1
    })
}
export const getSortedAggregatedFilteredTagDataForSelectedDatasources = createSelector(
    [getAggregatedFilteredTagDataForSelectedDatasources, getSortField, getSortDirection],
    combineSortedAggregatedFilteredTagDataForSelectedDatasources
)

export const getTagsForFilteredPostsForSelectedDatasources = createSelector(
    [getPostsByTagIdForSelectedDatasources, getSortedTagsData],
    (postDataByTag, tags) => {
        return tags.filter((tag) => Object.keys(postDataByTag).includes(tag.id))
    }
)

export const { changeTimePeriod, selectDatasources, setSelectedTags, setSortDirection, setSortField } = PostAnalysisSlice.actions

export const PostAnalysisReducer = PostAnalysisSlice.reducer
