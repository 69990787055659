import _ from 'lodash'
import { Box, FormHelperText, InputLabel, TextField } from '@mui/material'
import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { Field, FieldInputProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getAlreadySearchedMentions, getMentions, getMentionsLoading } from './MentionSlice'
import { HmstrDispatch } from '../../core/Store'
import { fetchMentionsForName } from './MentionActions'
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { CustomSuggestionsContainer } from './CustomSuggestionsContainer'
import { replaceMentions } from './MentionService'
import { MentionCandidate } from './MentionCandidate'
import classNames from './mentions.module.css'
import { CustomSuggestion } from './CustomSuggestion'
import { usePublishingFormDatasources } from '../PublishingHooks'
import { PostTextGenerationComponent } from '../text-generation/PostTextGenerationComponent'
import { ValidDatasourceTypeForPublishing } from '../PublishingForm'

type ConnectedTextFieldWithAutocompleteProps = {
    prefix: string
    disabled?: boolean
    disableValidation?: boolean
    datasourceKeyOverride?: ValidDatasourceTypeForPublishing
    hasAi?: boolean
}

type MaxPostingTextLengths = {
    FACEBOOK_PAGE: number
    INSTAGRAM_ACCOUNT: number
    LINKED_IN: number
    TIKTOK_ACCOUNT: number
}
const maxPostingTextLengths: MaxPostingTextLengths = {
    FACEBOOK_PAGE: 9000,
    INSTAGRAM_ACCOUNT: 2200,
    LINKED_IN: 3000,
    TIKTOK_ACCOUNT: 2200,
}

export const PublishingTextAreaComponent: FC<ConnectedTextFieldWithAutocompleteProps> = (props) => {
    const { prefix, disabled, disableValidation, datasourceKeyOverride, hasAi } = props
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const datasources = usePublishingFormDatasources()
    const mentionCandidates: MentionCandidate[] = useSelector(getMentions)
    const mentionsLoading = useSelector(getMentionsLoading)
    const alreadySearchedMentions = useSelector(getAlreadySearchedMentions)
    const originalInputRef = useRef<HTMLDivElement | null>(null)
    const duplicateInputRef = useRef<HTMLDivElement | null>(null)
    const [textInputHeight, setTextInputHeight] = useState(115)
    const maxInputHeight = 460

    const [textBoxSelected, setTextBoxSelected] = useState<boolean>(false)
    const [snap, setSnap] = useState<boolean>(true)
    const [exitMention, setExitMention] = useState<boolean>(false)

    const maxPostingTextLengthByType = datasources.map((ds) => maxPostingTextLengths[ds.type as keyof MaxPostingTextLengths] as number)
    const maxPostingTextLength = (datasourceKeyOverride ? (maxPostingTextLengths[datasourceKeyOverride] as number) : _.min(maxPostingTextLengthByType)) || 5000

    const getNewInputData = (originalValue: string, input: FieldInputProps<any>) => {
        let originalValueCopy = _.clone(originalValue)
        if (!datasourceKeyOverride) {
            input.onChange({ target: { value: replaceMentions(originalValueCopy) } })
        }
        return originalValueCopy
    }

    const renderSuggestion = (suggestion: SuggestionDataItem, search: string, highlightedDisplay: React.ReactNode, index: number, focused: boolean) => {
        return (
            <CustomSuggestion
                key={index}
                suggestion={suggestion as SuggestionDataItem & MentionCandidate}
                highlightedDisplay={highlightedDisplay}
                focused={focused}
                snap={snap}
                setExitMention={setExitMention}
            />
        )
    }

    const getSuggestionsForCandidates = (query: string, candidates: MentionCandidate[], ds: ProjectDatasource) => {
        const search = query.toLowerCase()
        return candidates
            .filter((mc) => {
                const matchesSearch = mc.handle.toLowerCase().includes(search) || mc.display_name.toLowerCase().includes(search)

                return datasourceKeyOverride === mc.type && matchesSearch && mc.data_source_id === ds.id
            })
            .map((mc) => ({ display: mc.display_name, ...mc }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchMentionCandidates = useCallback(
        _.debounce((search: any, firstDatasource: ProjectDatasource, cands: MentionCandidate[], callback: any) => {
            dispatch(fetchMentionsForName({ searchString: search, datasource: firstDatasource })).then((response: any) => {
                const fetchedCandidates = response.payload as MentionCandidate[]
                const combinedCandidates = _.uniqBy([...cands, ...fetchedCandidates], 'id')
                const combinedSuggestions = getSuggestionsForCandidates(search, combinedCandidates, firstDatasource)
                if (combinedSuggestions.length === 0) {
                    setExitMention(true)
                } else {
                    callback(combinedSuggestions)
                }
            })
        }, 300),
        []
    )

    const getMentionData = (search: any, callback: any) => {
        const firstDatasource = datasources.find((ds) => ds.type === datasourceKeyOverride)
        if (firstDatasource && datasourceKeyOverride && search.length >= 2) {
            const queriedMentions = alreadySearchedMentions[datasourceKeyOverride][firstDatasource?.id || ''] || []
            const existingSuggestions = getSuggestionsForCandidates(search, mentionCandidates, firstDatasource)

            callback(existingSuggestions)

            // Fetch new mentions if the query was not yet searched and more than 3 letters are searched
            if (!queriedMentions.includes(search) && firstDatasource && search.length >= 3) {
                fetchMentionCandidates(search, firstDatasource, mentionCandidates, callback)
            } else if (existingSuggestions.length === 0) {
                setExitMention(true)
            }
        } else {
            callback([])
        }
    }

    const validate = (value: any, values?: any) => {
        if (!value && !_.get(values, `${prefix}.ig_publish_as_story`)) {
            return 'validations.required'
        }
    }

    useLayoutEffect(() => {
        const syncHeightWithFlushSync = () => {
            const ref = duplicateRef || originalRef
            if (!ref) {
                return
            }

            const textArea = ref.querySelector('textarea:not([aria-hidden])')

            // In React 18, state updates in a ResizeObserver's callback are happening after
            // the paint, this leads to an infinite rendering.
            //
            // Using flushSync ensures that the states is updated before the next pain.
            // Related issue - https://github.com/facebook/react/issues/24331
            if (textArea) {
                flushSync(() => {
                    setTextInputHeight(textArea.clientHeight)
                })
            }
        }

        let resizeObserver: ResizeObserver

        const duplicateRef = duplicateInputRef.current
        const originalRef = originalInputRef.current
        if (typeof ResizeObserver !== 'undefined') {
            resizeObserver = new ResizeObserver(syncHeightWithFlushSync)
            if (duplicateRef) {
                resizeObserver.observe(duplicateRef)
            } else if (originalRef) {
                resizeObserver.observe(originalRef)
            }
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect()
            }

            setTextBoxSelected(false)
        }
    }, [datasourceKeyOverride])

    return (
        <Field name={`${prefix}.text`} validate={disableValidation ? undefined : validate}>
            {({ input, meta }) => {
                const handleChange = (event: { target: { value: string } }) => {
                    if (event.target.value.length >= maxPostingTextLength) {
                        const shortened = event.target.value.slice(0, maxPostingTextLength)
                        input.onChange({ target: { value: shortened } })
                    } else {
                        input.onChange(event)
                    }
                }

                const handleFocus = (ev: any) => {
                    input.onFocus(ev)
                    setTextBoxSelected(true)
                }

                const handleBlur = (ev: any) => {
                    input.onBlur(ev)
                    setTextBoxSelected(false)
                }

                const errors = meta.error || meta.submitError
                const notPristine = meta.visited || meta.touched || meta.dirty || meta.initial !== undefined

                return (
                    <Box position="relative">
                        <div style={{ position: 'relative' }}>
                            {!datasourceKeyOverride && (
                                <TextField
                                    ref={originalInputRef}
                                    value={getNewInputData(input.value, input) || ''}
                                    onChange={handleChange}
                                    onFocus={input.onFocus}
                                    onBlur={input.onBlur}
                                    disabled={disabled}
                                    fullWidth={true}
                                    multiline={true}
                                    minRows={5}
                                    maxRows={20}
                                    label={<FormattedMessage id="publishing.text" />}
                                    InputLabelProps={{
                                        disabled: disabled,
                                    }}
                                    error={notPristine && !!errors}
                                    helperText={
                                        notPristine && errors ? (
                                            <FormattedMessage id={errors} />
                                        ) : (
                                            <FormattedMessage
                                                id={'publishing.n-out-of-x-characters-used'}
                                                values={{ used: input.value?.length || 0, max: maxPostingTextLength }}
                                            />
                                        )
                                    }
                                    inputProps={{ maxLength: maxPostingTextLength }}
                                />
                            )}

                            {datasourceKeyOverride && (
                                <>
                                    <InputLabel
                                        variant={'standard'}
                                        shrink={textBoxSelected || input.value.length > 0}
                                        sx={!(textBoxSelected || input.value.length > 0) ? { transform: 'translate(0, 20px) scale(1)' } : {}}
                                        error={notPristine && !!errors}
                                    >
                                        <FormattedMessage id="publishing.text" />
                                    </InputLabel>
                                    <TextField
                                        sx={{ visibility: 'hidden', position: 'absolute' }}
                                        minRows={5}
                                        maxRows={20}
                                        value={replaceMentions(input.value)}
                                        fullWidth
                                        multiline
                                        ref={duplicateInputRef}
                                    />
                                    <MentionsInput
                                        disabled={disabled}
                                        className={'mentions-input' + (textBoxSelected ? ' focused' : '') + (notPristine && !!errors ? ' error' : '')}
                                        classNames={classNames}
                                        rows={5}
                                        maxLength={maxPostingTextLength}
                                        value={input.value}
                                        onChange={(ev) => handleChange(ev)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        allowSuggestionsAboveCursor
                                        a11ySuggestionsListLabel={intl.formatMessage({
                                            id: 'mentions.screenreader.suggested-mentions',
                                        })}
                                        allowSpaceInQuery={!exitMention}
                                        style={{
                                            input: {
                                                height: textInputHeight,
                                                overflow: textInputHeight >= maxInputHeight ? 'scroll' : 'hidden',
                                            },
                                            highlighter: {
                                                height: textInputHeight,
                                                boxSizing: 'border-box',
                                                overflow: 'hidden',
                                                border: 'none',
                                            },
                                        }}
                                        customSuggestionsContainer={(children) => (
                                            <CustomSuggestionsContainer setSnap={setSnap} loading={mentionsLoading}>
                                                {children}
                                            </CustomSuggestionsContainer>
                                        )}
                                    >
                                        <Mention
                                            appendSpaceOnAdd
                                            isLoading={mentionsLoading}
                                            style={{
                                                fontSize: 'inherit',
                                                background: '#39f8',
                                                zIndex: 100,
                                            }}
                                            trigger={'@'}
                                            data={(search, callback) => getMentionData(search, callback)}
                                            renderSuggestion={renderSuggestion}
                                            displayTransform={(id, display) => '@' + display}
                                        />
                                    </MentionsInput>
                                    <FormHelperText error={notPristine && !!errors}>
                                        {!notPristine || !errors ? (
                                            <FormattedMessage
                                                id={'publishing.n-out-of-x-characters-used'}
                                                values={{ used: input.value?.length || 0, max: maxPostingTextLength }}
                                            />
                                        ) : (
                                            <FormattedMessage id={errors} />
                                        )}
                                    </FormHelperText>
                                    <FormHelperText error>
                                        {datasourceKeyOverride ? null : <FormattedMessage id={'publishing.mention-data-source-please-select'} />}
                                    </FormHelperText>
                                </>
                            )}

                            {hasAi && !disabled && (
                                <PostTextGenerationComponent
                                    datasources={datasources}
                                    handleSelect={(val: string) => handleChange({ target: { value: val } })}
                                />
                            )}
                        </div>
                    </Box>
                )
            }}
        </Field>
    )
}
