import * as React from 'react'
import { FC, useState } from 'react'
import * as _ from 'lodash'
import { Alert, Box, Button, Typography } from '@mui/material'
import { useFormState } from 'react-final-form'
import { FacebookPostPreview } from './facebook/FacebookPostPreview'
import { PublishingFormValues, ValidDatasourceTypeForPublishing } from '../PublishingForm'
import { CommonPostPreview } from './CommonPostPreview'
import { InstagramPreview } from './instagram/InstagramPreview'
import { LinkedInPostPreview } from './linkedin/LinkedInPostPreview'
import { useSelector } from 'react-redux'
import { getDatasourcesAsIdMap } from '../../core/slices/CoreSlice'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { Post } from '../posts/Post'
import { FormattedMessage } from 'react-intl'
import { PostPreviewMockupDevice } from './PostPreviewMockupDevice'
import moment from 'moment'
import { OpenInNew } from '@mui/icons-material'
import { TiktokPreview } from './tiktok/TiktokPreview'
import { getSelectedPostGroup } from '../PublishingSlice'
import { usePublishingFormDatasources } from '../PublishingHooks'

type PublishingPostPreviewProps = {
    currentTypeTab: ValidDatasourceTypeForPublishing | 'COMMON'
    postPreviewDevice: 'desktop' | 'mobile'
}

export const PublishingPostPreview: FC<PublishingPostPreviewProps> = ({ currentTypeTab, postPreviewDevice }) => {
    const formState = useFormState<PublishingFormValues>()
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const [postTime, setPostTime] = useState('now')
    const postGroup = useSelector(getSelectedPostGroup)
    const channelForPublishing = usePublishingFormDatasources()

    const getPreviewPost = () => {
        const datasourceForPreview: ProjectDatasource | undefined = _.chain(channelForPublishing)
            .filter((ds) => ds && ds.type === currentTypeTab)
            .first()
            .value()

        let previewPost: Post
        let actualType = currentTypeTab

        if (datasourceForPreview) {
            const postForNetwork = _.get(formState.values, `postByType.${actualType}`)
            const commonPost = {
                ...formState.values.common_post,
                data_source_id: datasourceForPreview.id,
                data_source_type: datasourceForPreview.type,
            }

            previewPost = formState.values.customize_posts_by_network ? postForNetwork || commonPost : commonPost

            if (postGroup) {
                const postForDatasource = postGroup.posts.find((p) => p.data_source_id === datasourceForPreview.id)
                if (postForDatasource) {
                    previewPost.post_id = postForDatasource.post_id
                }
            }

            const date = moment(formState.values.common_post.publish_time)
            const generatedTime = date.format('LT')
            if (postTime !== generatedTime) {
                setPostTime(generatedTime)
            }
        } else {
            previewPost = { ...formState.values.common_post }
            const date = moment(previewPost.publish_time)
            const generatedTime = date.format('LT')
            if (postTime !== generatedTime) {
                setPostTime(generatedTime)
            }
        }
        return { previewPost, actualType }
    }

    const { previewPost, actualType } = getPreviewPost()

    const renderPostPreview = () => {
        let { previewPost, actualType } = getPreviewPost()

        if (!datasourcesById[previewPost.data_source_id]) {
            return <CommonPostPreview post={previewPost} />
        }

        switch (actualType) {
            case 'LINKED_IN':
                return <LinkedInPostPreview post={previewPost} />
            case 'FACEBOOK_PAGE':
                return <FacebookPostPreview post={previewPost} />
            case 'INSTAGRAM_ACCOUNT':
                return <InstagramPreview post={previewPost} mockupDevice={postPreviewDevice} />
            case 'TIKTOK_ACCOUNT':
                return <TiktokPreview post={previewPost} mockupDevice={postPreviewDevice} />
            case 'COMMON':
            default:
                return <CommonPostPreview post={previewPost} />
        }
    }

    const getPermalinkUrl = () => {
        if (!previewPost.post_id) {
            return undefined
        }

        switch (actualType) {
            case 'FACEBOOK_PAGE':
                return `https://www.facebook.com/${previewPost.post_id}`
            case 'INSTAGRAM_ACCOUNT':
                return previewPost.post_permalink
            case 'LINKED_IN':
                return `https://www.linkedin.com/feed/update/${previewPost.post_id}`
            case 'TIKTOK_ACCOUNT':
                return previewPost.post_id ? previewPost.post_permalink : undefined
            case 'COMMON':
            default:
                return undefined
        }
    }

    return (
        <Box
            sx={{
                height: '100%',
                overflow: 'auto',
                pl: 3,
                display: 'flex',
                flexDirection: 'column',
                borderLeft: 1,
                borderColor: 'divider',
                backgroundColor: 'background.default',
                flex: '0 0 475px',
            }}
        >
            <Alert severity="info" sx={{ mb: 2, width: 450 }}>
                <Typography
                    sx={{
                        mx: 'auto',
                        overflow: 'auto',
                        textAlign: 'center',
                        fontSize: 13,
                    }}
                >
                    <FormattedMessage id={'publishing.post-preview-tooltip'} />
                </Typography>
            </Alert>

            <PostPreviewMockupDevice
                time={postTime}
                device={postPreviewDevice}
                story={previewPost.ig_publish_as_story || actualType === 'TIKTOK_ACCOUNT'}
                tiktokPreview={actualType === 'TIKTOK_ACCOUNT'}
            >
                {renderPostPreview()}
            </PostPreviewMockupDevice>

            {getPermalinkUrl() && (
                <Box width="100%" mt={2}>
                    <Button
                        component="a"
                        href={getPermalinkUrl()}
                        target="_blank"
                        rel="noreferrer"
                        endIcon={<OpenInNew />}
                        color="secondary"
                        variant="contained"
                        fullWidth={true}
                    >
                        <FormattedMessage id="publishing.view-post-now" />
                    </Button>
                </Box>
            )}
        </Box>
    )
}
