import { Box, List, Tooltip, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { FC } from 'react'
import { PostAvatar } from '../../common/avatars/PostAvatar'
import CampaignIcon from '@mui/icons-material/Campaign'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TouchAppIcon from '@mui/icons-material/TouchApp'
import * as _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { addLockedPosts } from './PostsSlice'
import { AddReaction, Euro, Favorite, ModeComment, ThumbsUpDown } from '@mui/icons-material'
import { TagAutocomplete } from '../tags/TagAutocomplete'
import { TagStatusFilterType } from '../../common/filter/TagStatusFilterType'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { formatNumber } from '../../core/theme/helper'
import { FacebookPostImage } from './FacebookPostImage'
import { CommonPostData } from './CommonPostData'
import { FormattedCurrency } from '../../common/formatted-currency/FormattedCurrency'
import { PostInfo } from '../../common/post-info/PostInfo'
import { getPagesForAnalyse, isProjectAdmin } from '../../core/slices/CoreSlice'

type PostCardProps = {
    post: CommonPostData
    tagFilter: TagStatusFilterType
}

export const PostCard: FC<PostCardProps> = ({ post, tagFilter = TagStatusFilterType.ALL }) => {
    const dispatch = useDispatch()
    const isUserProjectAdmin = useSelector(isProjectAdmin)
    const intl = useIntl()
    const theme = useTheme()
    const pages = useSelector(getPagesForAnalyse)
    const dataSource: ProjectDatasource | undefined = pages.find((page) => page.id === post.data_source_id)
    const getReactionEmoji = (reaction: string) => {
        switch (reaction) {
            case 'love':
                return '❤️'
            case 'haha':
                return '😂'
            case 'yay':
                return '😊'
            case 'wow':
                return '😯'
            case 'sad':
                return '😢'
            case 'anger':
                return '😡'
            case 'insta_like':
                return <Favorite fontSize={'inherit'} htmlColor={'#fb3958'} />
            case 'like':
            default:
                return '👍'
        }
    }

    const KPIItem = (tooltipTitle: JSX.Element, icon: JSX.Element, value: string) => {
        return (
            <Tooltip title={tooltipTitle} placement="top" arrow={true}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 32px 0 0',
                    }}
                >
                    {icon}
                    <Typography variant="subtitle2" gutterBottom component="div" style={{ margin: '0 0 0 8px' }}>
                        {value}
                    </Typography>
                </div>
            </Tooltip>
        )
    }

    const KPIRow = (
        <div
            style={{
                display: 'flex',
                margin: '32px 0 0 0',
            }}
        >
            {KPIItem(
                <Box>
                    <Typography component="div">
                        <strong>
                            <FormattedMessage id="facebook.reach" />
                        </strong>
                        {post.insights.reach_organic !== null && (
                            <>
                                <br />
                                <FormattedMessage id="facebook.organic" /> {formatNumber(post.insights.reach_organic)}
                            </>
                        )}
                        {post.insights.reach_paid !== null && (
                            <>
                                <br />
                                <FormattedMessage id="facebook.paid" /> {formatNumber(post.insights.reach_paid)}
                            </>
                        )}
                    </Typography>
                </Box>,

                <TrackChangesIcon style={{ color: theme.palette.secondary.main }} />,
                formatNumber(post.insights.reach)
            )}

            {KPIItem(
                <Box>
                    <Typography component="div">
                        <strong>
                            <FormattedMessage id="facebook.impressions" />
                        </strong>
                        {post.insights.impressions_organic !== null && (
                            <>
                                <br />
                                <FormattedMessage id="facebook.organic" />
                                &nbsp;
                                {formatNumber(post.insights.impressions_organic)}
                            </>
                        )}
                        {post.insights.impressions_paid !== null && (
                            <>
                                <br />
                                <FormattedMessage id="facebook.paid" />
                                &nbsp;
                                {formatNumber(post.insights.impressions_paid)}
                            </>
                        )}
                    </Typography>
                </Box>,
                <CampaignIcon style={{ color: theme.palette.secondary.main }} />,
                formatNumber(post.insights.impressions)
            )}

            {KPIItem(
                <Box>
                    <Typography>
                        <strong>
                            <FormattedMessage id="facebook.engagement" />
                        </strong>
                        {post.insights.engagement_organic !== null && (
                            <>
                                <br />
                                <FormattedMessage id="facebook.organic" />
                                &nbsp;
                                {formatNumber(post.insights.engagement_organic)}
                            </>
                        )}
                        {post.insights.engagement_paid !== null && (
                            <>
                                <br />
                                <FormattedMessage id="facebook.paid" />
                                &nbsp;
                                {formatNumber(post.insights.engagement_paid)}
                            </>
                        )}
                    </Typography>
                </Box>,
                <ThumbsUpDown style={{ color: theme.palette.secondary.main }} />,
                formatNumber(post.insights.engagement)
            )}

            {KPIItem(
                <Box>
                    <Typography>
                        <strong>
                            <FormattedMessage id="facebook.reactions" />
                        </strong>
                    </Typography>
                    {post.insights.reactions_by_type && (
                        <List>
                            {_.map(post.insights.reactions_by_type, (value, key) => (
                                <Box key={key}>
                                    <Typography>
                                        {getReactionEmoji(key)}&nbsp;
                                        {formatNumber(value)}
                                    </Typography>
                                </Box>
                            ))}
                        </List>
                    )}
                </Box>,
                <AddReaction style={{ color: theme.palette.secondary.main }} />,
                formatNumber(post.insights.reactions)
            )}

            {post.insights.clicks !== null &&
                KPIItem(
                    <Box>
                        <Typography>
                            <strong>
                                <FormattedMessage id="facebook.clicks" />
                            </strong>
                        </Typography>
                    </Box>,
                    <TouchAppIcon style={{ color: theme.palette.secondary.main }} />,
                    formatNumber(post.insights.clicks)
                )}

            {KPIItem(
                <Box>
                    <Typography>
                        <strong>
                            <FormattedMessage id="facebook.comments" />
                        </strong>
                        {post.insights.comments_organic !== null && (
                            <>
                                <br />
                                <FormattedMessage id="facebook.organic" />
                                &nbsp;
                                {formatNumber(post.insights.comments_organic)}
                            </>
                        )}
                        {post.insights.comments_paid !== null && (
                            <>
                                <br />
                                <FormattedMessage id="facebook.paid" />
                                &nbsp;
                                {formatNumber(post.insights.comments_paid)}
                            </>
                        )}
                    </Typography>
                </Box>,
                <ModeComment style={{ color: theme.palette.secondary.main }} />,
                formatNumber(post.insights.comments)
            )}

            {KPIItem(
                <Box>
                    <Typography>
                        <strong>
                            <FormattedMessage id="facebook.total-spend.tooltip" />
                        </strong>
                    </Typography>

                    {post.insights.spend_by_platform && (
                        <Typography>
                            <FormattedMessage id="facebook.platform.facebook" />
                            &nbsp;
                            <FormattedCurrency value={post.insights.spend_by_platform.facebook} />
                            <br />
                            <FormattedMessage id="facebook.platform.instagram" />
                            &nbsp;
                            <FormattedCurrency value={post.insights.spend_by_platform.instagram} />
                        </Typography>
                    )}
                </Box>,
                <Euro style={{ color: theme.palette.secondary.main }} />,
                intl.formatNumber(post.insights.spend, { minimumFractionDigits: 2 })
            )}
        </div>
    )

    const getAvatarUrlForDatasource = (datasource: ProjectDatasource) => {
        if (!datasource.metadata.profile_picture_url && datasource.type === 'FACEBOOK_PAGE') {
            return 'https://graph.facebook.com/' + datasource.metadata.id + '/picture'
        }

        return datasource.metadata.profile_picture_url
    }

    return (
        <div style={{ display: 'flex', margin: '0 0 16px 0' }} key={post.post_id}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 20px 0 0',
                    paddingLeft: '10px',
                }}
            >
                <PostAvatar name={dataSource?.name ?? ''} src={dataSource ? getAvatarUrlForDatasource(dataSource) : ''} />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 20px 0 0',
                }}
            >
                <div style={{ display: 'flex' }}>
                    <FacebookPostImage post={post} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0 0 0 48px',
                        }}
                    >
                        {dataSource && <PostInfo post={post} datasource={dataSource} />}
                        {KPIRow}
                    </div>
                </div>
                <TagAutocomplete disabled={!isUserProjectAdmin} post={post} tagFilter={tagFilter} onTagsChange={() => dispatch(addLockedPosts(post.post_id))} />
            </div>
        </div>
    )
}
