import * as React from 'react'
import { FC } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { LinkedInComment } from '../../../common/icons/LinkedInComment'
import { LinkedInLike } from '../../../common/icons/LinkedInLike'
import { LinkedInShare } from '../../../common/icons/LinkedInShare'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../../core/slices/CoreSlice'

type LinkedinPostActionsPreviewProps = {}

export const LinkedInPostActionsPreview: FC<LinkedinPostActionsPreviewProps> = () => {
    const darkmode = useSelector(isDarkMode)
    const theme = useTheme()
    const actionColor = darkmode ? theme.palette.primary.contrastText : '#00000099'
    return (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
                    <LinkedInLike fontSize="medium" htmlColor={actionColor} />
                    <Typography fontSize="small" fontWeight={600} sx={{ ml: 1, color: actionColor, pt: 0.25 }}>
                        Like
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
                    <LinkedInComment fontSize="medium" htmlColor={actionColor} sx={{ pt: 0.25 }} />
                    <Typography fontSize="small" fontWeight={600} sx={{ ml: 1, color: actionColor, pt: 0.25 }}>
                        Comment
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
                    <LinkedInShare fontSize="small" htmlColor={actionColor} />
                    <Typography fontSize="small" fontWeight={600} sx={{ ml: 1, color: actionColor, pt: 0.25 }}>
                        Share
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}
