import * as React from 'react'
import { FC } from 'react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { getIsMobile } from '../../core/slices/CoreSlice'

type TitlebarWithFiltersProps = {
    title: ReactJSXElement | string
    actionButton?: ReactJSXElement
    viewOptions?: ReactJSXElement
    filterBar?: ReactJSXElement
    filterBarInSameLine?: boolean
    disableXPadding?: boolean
}

export const TitlebarWithFilters: FC<TitlebarWithFiltersProps> = ({ title, actionButton, viewOptions, filterBar, filterBarInSameLine, disableXPadding }) => {
    let processedTitle: any
    const isMobile = useSelector(getIsMobile)
    if (typeof title === 'string') {
        processedTitle = (
            <Typography variant={'h5'}>
                <FormattedMessage id={title} />
            </Typography>
        )
    } else {
        processedTitle = title
    }

    return (
        <Box display={'flex'} px={disableXPadding ? 0 : 2} py={1} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
                {!isMobile && processedTitle}
                {!isMobile && viewOptions}
            </Box>
            <Box
                display={'flex'}
                flexDirection={filterBarInSameLine ? 'row-reverse' : 'column'}
                alignItems={filterBarInSameLine ? 'center' : 'flex-end'}
                justifyContent={filterBarInSameLine ? 'flex-end' : 'center'}
                gap={2}
                flexGrow={0}
                maxWidth="100%"
            >
                {!isMobile && actionButton}
                {filterBar}
            </Box>
        </Box>
    )
}
