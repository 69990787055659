import { FC, useState } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, ListSubheader, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import * as _ from 'lodash'

type AttributionWindowFilterProps = {
    value: string[]
    size?: 'small' | 'medium' | undefined
    variant?: 'filled' | 'standard' | 'outlined' | undefined
    onChange: (attributionWindows: ('1d_click' | '7d_click' | '1d_view')[]) => any
    disabled?: boolean
    open?: boolean
    onOpen?: () => void
    onClose?: () => void
}

export const AttributionWindowFilter: FC<AttributionWindowFilterProps> = ({ value, size, variant, onChange, disabled = false, open, onOpen, onClose }) => {
    const intl = useIntl()
    const [internalOpen, setInternalOpen] = useState(false)

    const handleAttributionWindowChange = (event: SelectChangeEvent<string[]>) => {
        const oldAttributionWindows = [...value] as ('1d_click' | '7d_click' | '1d_view')[]
        const newAttributionWindows = event.target.value as ('1d_click' | '7d_click' | '1d_view')[]
        const newValue = newAttributionWindows[newAttributionWindows.length - 1]

        if (newAttributionWindows.length > 1) {
            if (newValue.indexOf('click') > -1) {
                _.remove(oldAttributionWindows, (aw) => aw.indexOf('click') > -1)
                onChange([...oldAttributionWindows, newValue])
            } else if (newValue.indexOf('view') > -1) {
                _.remove(oldAttributionWindows, (aw) => aw.indexOf('view') > -1)
                onChange([...oldAttributionWindows, newValue])
            }
        } else if (newAttributionWindows.length === 1) {
            onChange(newAttributionWindows)
        }
        if (onClose) {
            onClose()
        } else {
            setInternalOpen(false)
        }
    }

    return (
        <FormControl fullWidth sx={{ minWidth: 230 }}>
            <InputLabel id="attribution-window-label" variant={variant}>
                <FormattedMessage id="general.attribution-window" />
            </InputLabel>
            <Select
                disabled={disabled}
                multiple={true}
                open={internalOpen || open}
                labelId="attribution-window-label"
                label={intl.formatMessage({ id: 'general.attribution-window' })}
                value={value}
                variant={variant}
                size={size}
                renderValue={(selected) =>
                    selected
                        .map((s) =>
                            intl.formatMessage({
                                id: `facebook.ads.attribution-window.${s}`,
                            })
                        )
                        .join(', ')
                }
                onChange={handleAttributionWindowChange}
                onOpen={
                    disabled
                        ? () => {}
                        : () => {
                              if (onOpen) {
                                  onOpen()
                              } else {
                                  setInternalOpen(true)
                              }
                          }
                }
                onClose={() => {
                    if (onClose) {
                        onClose()
                    } else {
                        setInternalOpen(false)
                    }
                }}
            >
                <ListSubheader>
                    <FormattedMessage id="general.attribution-window.clicks" />
                </ListSubheader>
                <MenuItem value="1d_click">
                    <Checkbox checked={value.indexOf('1d_click') > -1} />
                    <ListItemText>
                        <FormattedMessage id="facebook.ads.attribution-window.1d_click" />
                    </ListItemText>
                </MenuItem>
                <MenuItem value="7d_click">
                    <Checkbox checked={value.indexOf('7d_click') > -1} />
                    <ListItemText>
                        <FormattedMessage id="facebook.ads.attribution-window.7d_click" />
                    </ListItemText>
                </MenuItem>
                <ListSubheader>
                    <FormattedMessage id="general.attribution-window.views" />
                </ListSubheader>
                <MenuItem value="1d_view">
                    <Checkbox checked={value.indexOf('1d_view') > -1} />
                    <ListItemText>
                        <FormattedMessage id="facebook.ads.attribution-window.1d_view" />
                    </ListItemText>
                </MenuItem>
            </Select>
        </FormControl>
    )
}
