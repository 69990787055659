import { createSlice } from '@reduxjs/toolkit'
import { ClientConfig } from '../config/config'
import { ApiEntryPoint } from './ApiEntryPoint'
import { ApiLink } from './ApiLink'
import { fetchGatewayVersion } from '../slices/CoreActions'
import { Selector } from 'react-redux'
import { HmstrState } from '../Store'

export interface ApiState {
    entryPoint: ApiEntryPoint
    frontendVersion: string
    cmsVersion: string
    gatewayVersion: string
    gatewayLink: ApiLink
    profiles: string[]
}

export const initApiSlice = (clientConfig: ClientConfig, apiEntrypoint: ApiEntryPoint) => {
    const initialState: ApiState = {
        gatewayLink: clientConfig._links.gateway,
        entryPoint: apiEntrypoint,
        profiles: clientConfig.profiles,
        cmsVersion: clientConfig.version,
        frontendVersion: process.env.REACT_APP_VERSION || '',
        gatewayVersion: '',
    }

    return createSlice({
        name: 'api',
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(fetchGatewayVersion.fulfilled, (state, action) => {
                state.gatewayVersion = action.payload.version
            })
        },
    })
}

export const getApiEntryPoint: Selector<HmstrState, ApiEntryPoint> = (state) => state.api.entryPoint
export const getIsProductive: Selector<HmstrState, boolean> = (state) => state.api.profiles.includes('prod')
