import { Box, FormControl, FormControlLabel, Grid, Switch, Tooltip, Typography } from '@mui/material'
import * as React from 'react'
import { FC, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dashboard } from './Dashboard'
import moment, { Moment } from 'moment'
import { DateRangePicker } from '../common/daterange-picker/DateRangePicker'
import { DashboardTemplate } from './DashboardTemplate'
import { Datepicker } from '../common/datepicker/Datepicker'
import { DateRangePickerStaticRanges } from '../common/daterange-picker/DateRangePickerRanges'
import { AttributionWindowFilter } from '../common/AttributionWindowFilter'
import { defaultMonthlyPeriod, defaultYearlyPeriod } from './TemplateDefaultTimePeriods'
import { useDispatch } from 'react-redux'
import { selectDatasources } from '../content-analytics/post-analysis/PostAnalysisSlice'
import { Tag } from '../tags/Tag'
import { CustomTagFilter } from '../common/filter/CustomTagFilter'
import { HmstrDispatch } from '../core/Store'
import { Info } from '@mui/icons-material'
import { dynamicMonthOptions, RenderDynamicSelection } from './RenderDynamicSelection'

type DashboardFormSettingsProps = {
    childKey: any
    template: DashboardTemplate
    dynamic: boolean
    onDynamicChange: (dynamic: boolean) => any
    settings: Dashboard['settings']
    onChange: (settings: Dashboard['settings']) => any
    datasources: string[]
    onTagChange: (selectedTags: string[]) => any
    selectedTagProp: string[]
    availableTags: Tag[]
}

export const DashboardFormSettings: FC<DashboardFormSettingsProps> = ({
    childKey,
    template,
    settings,
    onChange,
    dynamic,
    onDynamicChange,
    datasources,
    onTagChange,
    selectedTagProp,
    availableTags,
}) => {
    const intl = useIntl()
    let startDate = settings.fixed_start_date
    let endDate = settings.fixed_end_date
    const dispatch = useDispatch<HmstrDispatch>()

    useEffect(() => {
        dispatch(selectDatasources(datasources))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMonthIntervalForDate = (date: Moment) => {
        const startDate = date.clone().startOf('month').format('YYYY-MM-DD')
        const endDate = date.clone().endOf('month').format('YYYY-MM-DD')
        return { startDate, endDate }
    }
    const getYearIntervalForDate = (date: Moment) => {
        const startDate = date.clone().startOf('year').format('YYYY-MM-DD')
        const endDate = date.clone().endOf('year').format('YYYY-MM-DD')
        return { startDate, endDate }
    }

    if (!template || !template.settings.available_settings) {
        return null
    }

    if (settings.relative_time_period) {
        let relativePeriod = DateRangePickerStaticRanges(intl).find((entry) => entry.id === settings.relative_time_period)

        if (relativePeriod) {
            startDate = relativePeriod.range().startDate.toString()
            endDate = relativePeriod.range().endDate.toString()
        }
    }
    let compareStartDate = settings.fixed_compare_start_date
    let compareEndDate = settings.fixed_compare_end_date
    if (settings.relative_compare_time_period) {
        let relativePeriod = DateRangePickerStaticRanges(intl).find((entry) => entry.id === settings.relative_compare_time_period)

        if (relativePeriod) {
            compareStartDate = relativePeriod.range().startDate.toString()
            compareEndDate = relativePeriod.range().endDate.toString()
        }
    }

    const renderDefaultTimePickerMonth = () => {
        return (
            <Grid item xs={3}>
                <Datepicker
                    key={childKey}
                    label="facebook.month"
                    format="MMMM YYYY"
                    openTo="month"
                    views={['year', 'month']}
                    value={moment(startDate || defaultMonthlyPeriod.startDate)}
                    variant={'outlined'}
                    size={'medium'}
                    fullWidth={true}
                    onChange={(date: Moment | null) => {
                        if (date) {
                            let dateRange = getMonthIntervalForDate(date)
                            let dateRangeCompare = getMonthIntervalForDate(date.clone().subtract(1, 'month'))

                            onChange({
                                ...settings,
                                relative_time_period: undefined,
                                relative_compare_time_period: undefined,
                                fixed_start_date: dateRange.startDate,
                                fixed_end_date: dateRange.endDate,
                                fixed_compare_start_date: dateRangeCompare.startDate,
                                fixed_compare_end_date: dateRangeCompare.endDate,
                            })
                        }
                    }}
                />
            </Grid>
        )
    }

    const renderDefaultTimePickerYear = () => {
        return (
            <Grid item xs={3}>
                <Datepicker
                    key={childKey}
                    label="facebook.year"
                    format="YYYY"
                    openTo="year"
                    views={['year']}
                    value={moment(startDate || defaultYearlyPeriod.startDate)}
                    variant={'outlined'}
                    size={'medium'}
                    fullWidth={true}
                    onChange={(date: Moment | null) => {
                        if (date) {
                            let dateRange = getYearIntervalForDate(date)
                            let dateRangeCompare = getYearIntervalForDate(date.clone().subtract(1, 'year'))

                            onChange({
                                ...settings,
                                relative_time_period: undefined,
                                relative_compare_time_period: undefined,
                                fixed_start_date: dateRange.startDate,
                                fixed_end_date: dateRange.endDate,
                                fixed_compare_start_date: dateRangeCompare.startDate,
                                fixed_compare_end_date: dateRangeCompare.endDate,
                            })
                        }
                    }}
                />
            </Grid>
        )
    }
    const renderDefaultTimePickerCompareYear = () => {
        return (
            <Grid item xs={3}>
                <Datepicker
                    key={childKey}
                    label="facebook.compare-year"
                    format="YYYY"
                    openTo="year"
                    views={['year']}
                    value={moment(settings.fixed_compare_start_date || defaultYearlyPeriod.compareStartDate)}
                    variant={'outlined'}
                    size={'medium'}
                    fullWidth={true}
                    onChange={(date: Moment | null) => {
                        if (date) {
                            let dateRangeCompare = getYearIntervalForDate(date.clone().subtract(1, 'year'))

                            onChange({
                                ...settings,
                                fixed_compare_start_date: dateRangeCompare.startDate,
                                fixed_compare_end_date: dateRangeCompare.endDate,
                            })
                        }
                    }}
                />
            </Grid>
        )
    }

    const renderDefaultTimePickerCompareMonth = () => {
        return (
            <Grid item xs={3}>
                <Datepicker
                    key={childKey}
                    label="facebook.compare-month"
                    format="MMMM YYYY"
                    openTo={'month'}
                    views={['year', 'month']}
                    variant={'outlined'}
                    size={'medium'}
                    fullWidth={true}
                    value={moment(settings.fixed_compare_start_date || defaultMonthlyPeriod.compareStartDate)}
                    onChange={(date: Moment | null) => {
                        if (date) {
                            let dateRangeCompare = getMonthIntervalForDate(date.clone())

                            onChange({
                                ...settings,
                                fixed_compare_start_date: dateRangeCompare.startDate,
                                fixed_compare_end_date: dateRangeCompare.endDate,
                            })
                        }
                    }}
                />
            </Grid>
        )
    }

    const renderDynamicMonthPicker = () => {
        return (
            <Grid item xs={3}>
                <RenderDynamicSelection
                    label={'facebook.month'}
                    //@ts-ignore
                    baseValue={
                        !!settings.relative_time_period && dynamicMonthOptions.includes(settings.relative_time_period)
                            ? settings.relative_time_period
                            : 'last_month'
                    }
                    onChange={(value: string) => {
                        let compareMonth = 'next_to_last_month'
                        if (value === 'current_month' || value === 'next_to_last_month') {
                            compareMonth = 'last_month'
                        }

                        onChange({
                            ...settings,
                            relative_time_period: value,
                            relative_compare_time_period: compareMonth,
                        })
                    }}
                />
            </Grid>
        )
    }

    const renderDynamicCompareMonthPicker = () => {
        return (
            <Grid item xs={3}>
                <RenderDynamicSelection
                    label={'facebook.compare-month'}
                    //@ts-ignore
                    baseValue={
                        !!settings.relative_compare_time_period && dynamicMonthOptions.includes(settings.relative_compare_time_period)
                            ? settings.relative_compare_time_period
                            : 'next_to_last_month'
                    }
                    onChange={(compareMonth: string) => {
                        onChange({
                            ...settings,
                            relative_compare_time_period: compareMonth,
                        })
                    }}
                />
            </Grid>
        )
    }

    const renderDynamicTimeRange = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="body2">
                            <FormattedMessage id="reporting.dashboards.choose-dynamic-timespan" />
                        </Typography>
                    </Box>
                    <Box>
                        <FormControlLabel
                            value={intl.formatMessage({ id: 'reporting.dashboards.dynamic-timespan' })}
                            control={
                                <Switch
                                    color={dynamic ? 'warning' : 'primary'}
                                    defaultChecked={dynamic}
                                    onChange={(_) => {
                                        onDynamicChange(!dynamic)
                                    }}
                                />
                            }
                            label={intl.formatMessage({ id: 'reporting.dashboards.dynamic-timespan' })}
                            labelPlacement="end"
                        />
                    </Box>
                </Grid>

                <Grid container px={1} mt={1} spacing={2}>
                    {dynamic ? (
                        <>
                            {renderDynamicMonthPicker()}
                            {renderDynamicCompareMonthPicker()}
                        </>
                    ) : (
                        <>
                            {renderDefaultTimePickerMonth()}
                            {renderDefaultTimePickerCompareMonth()}
                        </>
                    )}
                </Grid>
            </>
        )
    }

    const renderDefaultTimePickerFlexible = () => {
        return (
            <Grid item flexGrow={1} xs={3}>
                <DateRangePicker
                    fullWidth
                    key={childKey}
                    startDate={startDate!}
                    endDate={endDate!}
                    onChange={(startDate, endDate, label) => {
                        if (label) {
                            onChange({
                                ...settings,
                                relative_time_period: label,
                            })
                        } else {
                            onChange({
                                ...settings,
                                fixed_start_date: startDate,
                                fixed_end_date: endDate,
                                relative_time_period: undefined,
                            })
                        }
                    }}
                    size={'medium'}
                    variant={'outlined'}
                    strict={true}
                />
            </Grid>
        )
    }

    const renderDefaultTimePickerCompareFlexible = () => {
        return (
            <Grid item flexGrow={1} xs={3}>
                <DateRangePicker
                    key={childKey}
                    fullWidth
                    startDate={compareStartDate!}
                    endDate={compareEndDate!}
                    onChange={(startDateInput, endDateInput, label) => {
                        if (label) {
                            onChange({
                                ...settings,
                                relative_compare_time_period: label,
                            })
                        } else {
                            onChange({
                                ...settings,
                                fixed_compare_start_date: startDateInput,
                                fixed_compare_end_date: endDateInput,
                            })
                        }
                    }}
                    size={'medium'}
                    variant={'outlined'}
                    strict={true}
                    label="filters.compare-timeperiod"
                />
            </Grid>
        )
    }

    const renderAttributionWindowSelection = () => {
        return (
            <>
                <Grid item xs={12} />
                <Grid item xs={6}>
                    <AttributionWindowFilter
                        key={childKey}
                        value={settings.attribution_windows || ['7d_click', '1d_view']}
                        onChange={(attributionWindows) => {
                            onChange({
                                ...settings,
                                attribution_windows: attributionWindows,
                            })
                        }}
                        size={'medium'}
                        variant={'outlined'}
                    />
                </Grid>
            </>
        )
    }

    const renderConsentLossSwitch = () => {
        return (
            <Grid item xs={12}>
                <FormControlLabel
                    sx={{ mr: 0 }}
                    control={
                        <Switch
                            color="secondary"
                            checked={settings.conversions_with_consent_loss || false}
                            onChange={(e) => {
                                onChange({
                                    ...settings,
                                    conversions_with_consent_loss: e.target.checked,
                                })
                            }}
                        />
                    }
                    label={
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ mr: 1 }}>
                                <FormattedMessage id="facebook.ads.conversion-estimate-without-consent-loss" />
                            </Typography>
                            <Tooltip title={<FormattedMessage id="facebook.ads.conversion-estimate-without-consent-loss.tooltip" />}>
                                <Info sx={{ cursor: 'default' }} />
                            </Tooltip>
                        </Box>
                    }
                />
            </Grid>
        )
    }

    const renderTagSelection = () => {
        return (
            <Grid item xs={9}>
                <FormControl fullWidth={true}>
                    <CustomTagFilter
                        label={intl.formatMessage({ id: 'filters.tags' })}
                        tags={availableTags}
                        selectedTagIds={selectedTagProp}
                        showUntaggedOption={true}
                        onChange={(newValue: (String | Tag | undefined)[]) => {
                            let values = Object.values(newValue)
                            let selectedTags: string[] = []

                            values.forEach((element) => {
                                // noinspection SuspiciousTypeOfGuard
                                if (!!element && typeof element !== 'string') {
                                    // @ts-ignore
                                    selectedTags.push(element.id)
                                }
                            })
                            onTagChange(selectedTags)
                            onChange({ ...settings, tag_ids: selectedTags })
                        }}
                    />
                </FormControl>
            </Grid>
        )
    }

    const renderTimePickers = () => {
        if (template.settings.available_settings.dynamic_time_range) {
            return renderDynamicTimeRange()
        } else if (template.settings.available_time_interval === 'month') {
            return renderDefaultTimePickerMonth()
        } else if (template.settings.available_time_interval === 'year') {
            return renderDefaultTimePickerYear()
        } else {
            return renderDefaultTimePickerFlexible()
        }
    }
    const renderTimePickersCompare = () => {
        if (template.settings.available_settings.compare_time_period) {
            if (!template.settings.available_settings.dynamic_time_range) {
                if (template.settings.available_time_interval === 'month') {
                    return renderDefaultTimePickerCompareMonth()
                } else if (template.settings.available_time_interval === 'year') {
                    return renderDefaultTimePickerCompareYear()
                } else {
                    return renderDefaultTimePickerCompareFlexible()
                }
            }
        }
    }

    return (
        <>
            {renderTimePickers()}
            {renderTimePickersCompare()}
            {template.settings.available_settings.tags && renderTagSelection()}
            {template.settings.available_settings.attribution_windows && renderAttributionWindowSelection()}
            {template.settings.available_settings.consent_loss && renderConsentLossSwitch()}
        </>
    )
}
