import * as React from 'react'
import { FC } from 'react'
import { Box, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material'
import { Datepicker } from '../../common/datepicker/Datepicker'
import moment, { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeAttributionWindows,
    changeCompareTimePeriod,
    changeEstimateWithoutConsentLoss,
    changeTimePeriod,
    getSelectedDashboard,
    getSelectedDashboardAttributionWindows,
    getSelectedDashboardCompareEndDate,
    getSelectedDashboardCompareStartDate,
    getSelectedDashboardDatasourceIds,
    getSelectedDashboardEndDate,
    getSelectedDashboardEstimateWithoutConsentLoss,
    getSelectedDashboardStartDate,
    getSelectedDashboardTags,
    isDashboardDataLoadingInBackground,
    selectDatasourcesDashboard,
    setSelectedDashboardTags,
} from '../../dashboards/DashboardsSlice'
import { FormattedMessage, useIntl } from 'react-intl'
import { AttachMoney, CalendarToday, CompareArrows, Info, MoneyOff, Sell, Tune, Web } from '@mui/icons-material'
import { AttributionWindowFilter } from '../../common/AttributionWindowFilter'
import { DatasourceSelection } from '../../content-analytics/datasource/DatasoureSelection'
import { CustomTagFilter } from '../../common/filter/CustomTagFilter'
import { Tag } from '../../tags/Tag'
import { getTags } from '../../core/slices/DataSlice'
import * as _ from 'lodash'
import { DateRangePicker } from '../../common/daterange-picker/DateRangePicker'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchTagsForProject } from '../../tags/TagsActions'
import { getPagesForAnalyse, getSelectedProject } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { DashboardTemplate } from '../../dashboards/DashboardTemplate'
import { ProjectDatasourceType } from '../../settings/datasources/ProjectDatasource'
import { fetchDataForSelectedDashboard } from '../../dashboards/DashboardsActions'
import { defaultMonthlyPeriod } from '../../dashboards/TemplateDefaultTimePeriods'
import { DateView } from '@mui/x-date-pickers'
import { FilterDrawer, FilterSetting } from '../../common/filterbar/FilterDrawer'
import { DateRangePickerStaticRanges } from '../../common/daterange-picker/DateRangePickerRanges'
import { renderTimePeriodString } from '../../resources/translations/Helper'
import { getUntaggedTag } from '../../tags/TagsSlice'
import {
    getAttributionSettingsOpen,
    getCompareTimePeriodSettingOpen,
    getDatasourceSelectionOpen,
    getTagSelectionOpen,
    getTimePeriodSettingOpen,
    setAttributionSettingsOpen,
    setCompareTimePeriodSettingOpen,
    setDatasourceSelectionOpen,
    setTagSelectionOpen,
    setTimePeriodSettingOpen,
} from '../../common/filterbar/FilterSlice'

type DynamicToolbarDesktopProps = {
    template?: DashboardTemplate
}

export const DynamicToolbarDesktop: FC<DynamicToolbarDesktopProps> = ({ template }) => {
    const selectedDashboard = useSelector(getSelectedDashboard)
    const hasCompareTimePeriod = template?.settings.available_settings.compare_time_period
    const hasConsentLoss = template?.settings.available_settings.consent_loss
    const hasAttributionWindows = template?.settings.available_settings.attribution_windows
    const hasTagSelection = template?.settings.available_settings.tags || false
    const hasDatasourceSelection = template?.settings.available_settings.data_sources || false
    const granularity = template?.settings.available_time_interval || false
    const pagesForAnalyse = useSelector(getPagesForAnalyse)

    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const monthlyReportStartDate = useSelector(getSelectedDashboardStartDate)
    const monthlyReportCompareStartDate = useSelector(getSelectedDashboardCompareStartDate)
    const attributionWindows = useSelector(getSelectedDashboardAttributionWindows)
    const estimateWithousConsentLoss = useSelector(getSelectedDashboardEstimateWithoutConsentLoss)
    const selectedDataSourceIds = useSelector(getSelectedDashboardDatasourceIds)
    const selectedProject = useSelector(getSelectedProject)
    const availableTags = useSelector(getTags)
    const tags = _.map(availableTags, (tag) => tag)
    const tagsWithUntagged = [getUntaggedTag(intl.formatMessage({ id: 'tags.untagged-posts' })), ...tags]
    const selectedTagIds = useSelector(getSelectedDashboardTags)
    const selectedTags = (selectedTagIds || []).map((id) => tagsWithUntagged.find((tag) => tag.id === id)).filter((tag) => !!tag) as Tag[]
    const timePeriodStart = useSelector(getSelectedDashboardStartDate)
    const timePeriodEnd = useSelector(getSelectedDashboardEndDate)
    const compareTimePeriodStart = useSelector(getSelectedDashboardCompareStartDate)
    const compareTimePeriodEnd = useSelector(getSelectedDashboardCompareEndDate)
    const loadingInBackground = useSelector(isDashboardDataLoadingInBackground)
    const allowedDatasourceTypes: ProjectDatasourceType[] = _.flatMap(template?.data_sources, (d) => d.type)
    const timePeriodSettingOpen = useSelector(getTimePeriodSettingOpen)
    const compareTimePeriodSettingOpen = useSelector(getCompareTimePeriodSettingOpen)
    const attributionSettingsOpen = useSelector(getAttributionSettingsOpen)
    const datasourceSettingsOpen = useSelector(getDatasourceSelectionOpen)
    const tagSettingsOpen = useSelector(getTagSelectionOpen)
    const handleTimePeriodOpen = () => dispatch(setTimePeriodSettingOpen(true))
    const handleTimePeriodClose = () => dispatch(setTimePeriodSettingOpen(false))

    const handleCompareTimePeriodOpen = () => dispatch(setCompareTimePeriodSettingOpen(true))
    const handleCompareTimePeriodClose = () => dispatch(setCompareTimePeriodSettingOpen(false))
    const handleAttributionSettingsOpen = () => dispatch(setAttributionSettingsOpen(true))
    const handleAttributionSettingsClose = () => dispatch(setAttributionSettingsOpen(false))
    const handleDatasourceSettingsOpen = () => dispatch(setDatasourceSelectionOpen(true))
    const handleDatasourceSettingsClose = () => dispatch(setDatasourceSelectionOpen(false))
    const handleTagSettingsOpen = () => dispatch(setTagSelectionOpen(true))
    const handleTagSettingsClose = () => dispatch(setTagSelectionOpen(false))

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTagsForProject(selectedProject))
        }
    }, [selectedProject])

    const handleTimePeriodChange = (startDate: string, endDate: string) => {
        dispatch(changeTimePeriod({ startDate, endDate }))
        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
    }

    const handleCompareTimePeriodChange = (startDate: string, endDate: string) => {
        dispatch(changeCompareTimePeriod({ startDate, endDate }))
        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
    }

    const getMonthIntervalForDate = (date: Moment) => {
        const startDate = date.clone().startOf('month').format('YYYY-MM-DD')
        const endDate = date.clone().endOf('month').format('YYYY-MM-DD')
        return { startDate, endDate }
    }

    const getYearIntervalForDate = (date: Moment) => {
        const startDate = date.clone().startOf('year').format('YYYY-MM-DD')
        const endDate = date.clone().endOf('year').format('YYYY-MM-DD')
        return { startDate, endDate }
    }

    const handleMonthIntervalChange = (date: Moment | null) => {
        if (date) {
            dispatch(changeTimePeriod(getMonthIntervalForDate(date)))
            dispatch(changeCompareTimePeriod(getMonthIntervalForDate(date.clone().subtract(1, 'month'))))
            dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
        }
    }
    const handleYearIntervalChange = (date: Moment | null) => {
        if (date) {
            dispatch(changeTimePeriod(getYearIntervalForDate(date)))
            dispatch(changeCompareTimePeriod(getYearIntervalForDate(date.clone().subtract(1, 'year'))))
            dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
        }
    }

    if (!selectedDashboard) {
        return <></>
    }
    const getDateGranularity = (): DateView[] => {
        if (granularity === 'flexible') {
            return ['day']
        } else if (granularity === 'month') {
            return ['year', 'month']
        } else if (granularity === 'year') {
            return ['year']
        } else {
            return ['day']
        }
    }

    const getOpenTo = (): DateView => {
        if (granularity === 'flexible') {
            return 'day'
        } else if (granularity === 'month') {
            return 'month'
        } else if (granularity === 'year') {
            return 'year'
        } else {
            return 'day'
        }
    }

    const handleConsentLossChange = () => {
        dispatch(changeEstimateWithoutConsentLoss(!estimateWithousConsentLoss))
        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
    }

    const handleAttributionWindowsChange = (attributionWindows: ('1d_click' | '7d_click' | '1d_view')[]) => {
        dispatch(changeAttributionWindows(attributionWindows))
        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
    }
    const settings = selectedDashboard.settings
    let defaultStartDate = settings.fixed_start_date || moment().format('YYYY-MM-DD')
    let defaultEndDate = settings.fixed_end_date || moment().format('YYYY-MM-DD')
    if (settings.relative_time_period) {
        let relativePeriod = DateRangePickerStaticRanges(intl).find((entry) => entry.id === settings.relative_time_period)

        if (relativePeriod) {
            defaultStartDate = moment(relativePeriod.range().startDate).format('YYYY-MM-DD')
            defaultEndDate = moment(relativePeriod.range().endDate).format('YYYY-MM-DD')
        }
    }
    let defaultCompareStartDate = settings.fixed_compare_start_date || moment().format('YYYY-MM-DD')
    let defaultCompareEndDate = settings.fixed_compare_end_date || moment().format('YYYY-MM-DD')
    if (settings.relative_compare_time_period) {
        let relativePeriod = DateRangePickerStaticRanges(intl).find((entry) => entry.id === settings.relative_compare_time_period)

        if (relativePeriod) {
            defaultCompareStartDate = moment(relativePeriod.range().startDate).format('YYYY-MM-DD')
            defaultCompareEndDate = moment(relativePeriod.range().endDate).format('YYYY-MM-DD')
        }
    }

    const defaultConsentLoss = settings.conversions_with_consent_loss
    const defaultAttributionWindows = settings.attribution_windows
    const defaultTags = settings.tag_ids
    const defaultDatasourceIds = selectedDashboard.data_source_ids

    const renderTimePeriodStringWithGranularity = (startTime: string, endTime: string) => {
        if (granularity === 'month') {
            return moment(startTime).format('MMMM YYYY')
        } else if (granularity === 'year') {
            return moment(startTime).format('YYYY')
        } else {
            return renderTimePeriodString(startTime, endTime, intl)
        }
    }

    const getTimePeriodResetFunction = () => {
        if (!(timePeriodStart === defaultStartDate && timePeriodEnd === defaultEndDate)) {
            return () => {
                dispatch(changeTimePeriod({ startDate: defaultStartDate, endDate: defaultEndDate }))
                dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
            }
        }
    }

    const getCompareTimePeriodResetFunction = () => {
        if (!(compareTimePeriodStart === defaultCompareStartDate && compareTimePeriodEnd === defaultCompareEndDate)) {
            return () => {
                dispatch(changeCompareTimePeriod({ startDate: defaultCompareStartDate, endDate: defaultCompareEndDate }))
                dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
            }
        }
    }
    const getAttributionWindowResetFunction = () => {
        const defaultAsArray = (defaultAttributionWindows || []).map((d) => d)
        const selectedAsArray = (attributionWindows || []).map((d) => d)
        if (!_.isEqual(defaultAsArray.sort(), selectedAsArray.sort())) {
            return () => {
                dispatch(changeAttributionWindows(defaultAttributionWindows || []))
                dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
            }
        }
    }
    const getConsentLossResetFunction = () => {
        if (!_.isEqual(defaultConsentLoss || false, estimateWithousConsentLoss || false)) {
            return () => {
                dispatch(changeEstimateWithoutConsentLoss(defaultConsentLoss || false))
                dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
            }
        }
    }
    const getTagResetFunction = () => {
        const defaultAsArray = (defaultTags || []).map((d) => d)
        const selectedAsArray = (selectedTagIds || []).map((d) => d).filter((d) => d !== 'THEREISNOTAG')
        if (!_.isEqual(defaultAsArray.sort(), selectedAsArray.sort())) {
            return () => {
                dispatch(setSelectedDashboardTags(defaultTags || []))
                dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
            }
        }
    }
    const getDatasourceResetFunction = () => {
        const defaultAsArray = (defaultDatasourceIds || []).map((d) => d)
        const selectedAsArray = (selectedDataSourceIds || []).map((d) => d)
        if (!_.isEqual(defaultAsArray.sort(), selectedAsArray.sort())) {
            return () => {
                dispatch(selectDatasourcesDashboard(defaultDatasourceIds))
                dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
            }
        }
    }

    const getTimePeriodSetting = () => {
        const getInput = () => {
            if (granularity === 'flexible') {
                return (
                    <DateRangePicker
                        onOpen={handleTimePeriodOpen}
                        onClose={handleTimePeriodClose}
                        open={timePeriodSettingOpen}
                        fullWidth
                        startDate={timePeriodStart}
                        endDate={timePeriodEnd}
                        disabled={loadingInBackground}
                        onChange={(startDate, endDate) => {
                            handleTimePeriodChange(startDate, endDate)
                        }}
                        variant={'outlined'}
                    />
                )
            } else if (granularity === 'year') {
                return (
                    <Datepicker
                        onOpen={handleTimePeriodOpen}
                        onClose={handleTimePeriodClose}
                        open={timePeriodSettingOpen}
                        fullWidth
                        disabled={loadingInBackground}
                        label="facebook.year"
                        format={'YYYY'}
                        openTo={getOpenTo()}
                        views={getDateGranularity()}
                        value={moment(monthlyReportStartDate)}
                        onChange={handleYearIntervalChange}
                        variant={'outlined'}
                    />
                )
            } else if (granularity === 'month') {
                return (
                    <Datepicker
                        onOpen={handleTimePeriodOpen}
                        onClose={handleTimePeriodClose}
                        open={timePeriodSettingOpen}
                        fullWidth
                        disabled={loadingInBackground}
                        label="facebook.month"
                        format={'MMMM YYYY'}
                        openTo={getOpenTo()}
                        views={getDateGranularity()}
                        value={moment(monthlyReportStartDate)}
                        onChange={handleMonthIntervalChange}
                        variant={'outlined'}
                    />
                )
            } else {
                return <></>
            }
        }

        const onChipClick = () => {
            setTimeout(handleTimePeriodOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: getInput(),
            tooltip: 'filters.timeperiod',
            important: true,
            currentValueAsString: renderTimePeriodStringWithGranularity(timePeriodStart, timePeriodEnd),
            icon: <CalendarToday fontSize={'small'} />,
            hasChangedFromDefault: !!getTimePeriodResetFunction(),
            onDeleteFunction: getTimePeriodResetFunction(),
            onClickFunction: onChipClick,
            deletable: true,
        }
        return set
    }

    const getTimePeriodCompareSetting = () => {
        if (!hasCompareTimePeriod) {
            return
        }
        const getInput = () => {
            if (granularity === 'month') {
                return (
                    <Datepicker
                        onOpen={handleCompareTimePeriodOpen}
                        onClose={handleCompareTimePeriodClose}
                        open={compareTimePeriodSettingOpen}
                        fullWidth
                        disabled={loadingInBackground}
                        label="facebook.compare-month"
                        format={'MMMM YYYY'}
                        openTo={'month'}
                        views={['year', 'month']}
                        value={moment(monthlyReportCompareStartDate)}
                        onChange={(date: Moment | null) => (date ? dispatch(changeCompareTimePeriod(getMonthIntervalForDate(date))) : {})}
                        variant={'outlined'}
                    />
                )
            } else if (granularity === 'flexible') {
                return (
                    <DateRangePicker
                        onOpen={handleCompareTimePeriodOpen}
                        onClose={handleCompareTimePeriodClose}
                        open={compareTimePeriodSettingOpen}
                        fullWidth
                        disabled={loadingInBackground}
                        startDate={compareTimePeriodStart || defaultMonthlyPeriod.compareStartDate}
                        endDate={compareTimePeriodEnd || defaultMonthlyPeriod.compareEndDate}
                        onChange={(startDate, endDate) => {
                            handleCompareTimePeriodChange(startDate, endDate)
                        }}
                        label="filters.compare-timeperiod"
                        variant={'outlined'}
                    />
                )
            } else {
                return <></>
            }
        }

        const onChipClick = () => {
            setTimeout(handleCompareTimePeriodOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: getInput(),
            tooltip: 'filters.compare-timeperiod',
            important: true,
            currentValueAsString: renderTimePeriodStringWithGranularity(compareTimePeriodStart!, compareTimePeriodEnd!),
            icon: <CompareArrows fontSize={'small'} />,
            hasChangedFromDefault: !!getCompareTimePeriodResetFunction(),
            onDeleteFunction: getCompareTimePeriodResetFunction(),
            onClickFunction: onChipClick,
            deletable: true,
        }
        return set
    }

    const getAttributionSetting = () => {
        if (!hasAttributionWindows || !attributionWindows) {
            return
        }
        const attributionWindowsString = attributionWindows
            .map((s) =>
                intl.formatMessage({
                    id: `facebook.ads.attribution-window.${s}`,
                })
            )
            .join(', ')

        const onChipClick = () => {
            setTimeout(handleAttributionSettingsOpen, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <AttributionWindowFilter
                    open={attributionSettingsOpen}
                    onOpen={handleAttributionSettingsOpen}
                    onClose={handleAttributionSettingsClose}
                    value={attributionWindows || []}
                    disabled={loadingInBackground}
                    onChange={handleAttributionWindowsChange}
                    variant={'outlined'}
                />
            ),
            tooltip: 'general.attribution-window',
            important: true,
            icon: <Tune fontSize={'small'} />,
            currentValueAsString: attributionWindowsString,
            onDeleteFunction: getAttributionWindowResetFunction(),
            onClickFunction: onChipClick,
            deletable: true,
            hasChangedFromDefault: !!getAttributionWindowResetFunction(),
        }
        return set
    }

    const getConsentLossSetting = () => {
        if (!hasConsentLoss) {
            return
        }
        const consentLossString =
            intl.formatMessage({ id: 'facebook.ads.consent-loss' }) +
            ' ' +
            intl.formatMessage({ id: estimateWithousConsentLoss ? 'general.off' : 'general.on' })
        const consentLossIcon = <Box display={'flex'}>{estimateWithousConsentLoss ? <AttachMoney fontSize={'small'} /> : <MoneyOff fontSize={'small'} />}</Box>
        const set: FilterSetting = {
            settingInput: (
                <FormControlLabel
                    control={
                        <Switch color="secondary" disabled={loadingInBackground} checked={estimateWithousConsentLoss} onChange={handleConsentLossChange} />
                    }
                    label={
                        <Box display="flex" alignItems="center">
                            <Typography sx={{ mr: 1 }}>
                                <FormattedMessage id="facebook.ads.conversion-estimate-without-consent-loss" />
                            </Typography>
                            <Tooltip title={<FormattedMessage id="facebook.ads.conversion-estimate-without-consent-loss.tooltip" />}>
                                <Info sx={{ cursor: 'default' }} />
                            </Tooltip>
                        </Box>
                    }
                />
            ),
            tooltip: 'facebook.ads.conversion-estimate-without-consent-loss',
            important: true,
            deletable: true,
            hasChangedFromDefault: !!getConsentLossResetFunction(),
            onDeleteFunction: getConsentLossResetFunction(),
            currentValueAsString: consentLossString,
            icon: consentLossIcon,
        }
        return set
    }

    const getDatasourceSetting = () => {
        if (!hasDatasourceSelection) {
            return
        }
        const renderDatasourceStrings = () => {
            return selectedDataSourceIds.length === 1
                ? pagesForAnalyse.find((page) => page.id === selectedDataSourceIds[0])?.name
                : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selectedDataSourceIds.length })
        }

        const onChipClick = () => {
            setTimeout(handleDatasourceSettingsOpen, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <DatasourceSelection
                    datasources={pagesForAnalyse}
                    isOpen={datasourceSettingsOpen}
                    handleOpen={handleDatasourceSettingsOpen}
                    handleClose={handleDatasourceSettingsClose}
                    fullWidth
                    allowedTypes={allowedDatasourceTypes}
                    disabled={loadingInBackground}
                    initialIds={selectedDataSourceIds!}
                    onDatasourceChange={(ids) => {
                        dispatch(selectDatasourcesDashboard(ids))
                        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
                    }}
                />
            ),
            tooltip: 'general.datasources',
            onDeleteFunction: getDatasourceResetFunction(),
            deletable: true,
            hasChangedFromDefault: !!getDatasourceResetFunction(),
            currentValueAsString: renderDatasourceStrings(),
            onClickFunction: onChipClick,
            important: true,
            icon: <Web fontSize={'small'} />,
        }
        return set
    }

    const getTagSetting = () => {
        if (!hasTagSelection) {
            return
        }

        const renderTagStrings = (tagsToDisplay: Tag[]) => {
            if (tagsToDisplay.length > 2) {
                return intl.formatMessage({ id: 'filters.xTagsSelected' }, { x: tagsToDisplay.length })
            }

            if (tagsToDisplay.length === 0) {
                return intl.formatMessage({ id: 'post.analysis.no-tags-selected' })
            }
            return tagsToDisplay
                .map((option, index) => {
                    if (!!option) {
                        return index === tagsToDisplay.length - 1 ? option.label : option.label + ', '
                    }

                    return ''
                })
                .toString()
        }

        const onChipClick = () => {
            setTimeout(handleTagSettingsOpen, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <CustomTagFilter
                    isOpen={tagSettingsOpen}
                    handleOpen={handleTagSettingsOpen}
                    handleClose={handleTagSettingsClose}
                    label={intl.formatMessage({ id: 'filters.tags' })}
                    tags={tags}
                    selectedTagIds={selectedTagIds!}
                    showUntaggedOption={true}
                    onChange={(newValue: (String | Tag | undefined)[]) => {
                        let values = Object.values(newValue)
                        let selectedTags: string[] = []

                        values.forEach((element) => {
                            // noinspection SuspiciousTypeOfGuard
                            if (!!element && typeof element !== 'string') {
                                // @ts-ignore
                                selectedTags.push(element.id)
                            }
                        })
                        if (selectedTags.length === 0) {
                            dispatch(setSelectedDashboardTags(['THEREISNOTAG']))
                            dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
                        } else {
                            dispatch(setSelectedDashboardTags(selectedTags))
                            dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
                        }
                    }}
                />
            ),
            tooltip: 'filters.tags',
            important: true,
            deletable: true,
            onDeleteFunction: getTagResetFunction(),
            hasChangedFromDefault: !!getTagResetFunction(),
            currentValueAsString: renderTagStrings(selectedTags),
            onClickFunction: onChipClick,
            icon: <Sell fontSize={'small'} />,
        }
        return set
    }
    const allFilterSettings = _.compact([
        getTimePeriodSetting(),
        getTimePeriodCompareSetting(),
        getAttributionSetting(),
        getConsentLossSetting(),
        getDatasourceSetting(),
        getTagSetting(),
    ])

    return (
        <Box flexBasis={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <FilterDrawer filters={allFilterSettings} />
        </Box>
    )
}
