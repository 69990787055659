import * as React from 'react'
import { FC } from 'react'
import { Box, darken, keyframes, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'

type EmptyScheduleProps = {}

export const EmptySchedule: FC<EmptyScheduleProps> = () => {
    const darkMode = useSelector(isDarkMode)
    const theme = useTheme()
    const plantColor = darkMode ? '#4e7c4b' : '#13a23d'
    const plantColorStroke = theme.palette.background.default
    const tableColor = darkMode ? theme.palette.background.paper : darken(theme.palette.background.paper, 0.1)
    const shirtColor = darken(theme.palette.background.paper, 0.5)
    const uiColor = darkMode ? theme.palette.background.paper : darken(theme.palette.background.paper, 0.1)
    const lowerColor = darken(theme.palette.background.paper, 0.7)
    const hairColor = darken(theme.palette.background.paper, 0.5)

    const plantAnimation = keyframes`
      0% {
        transform: rotate(0deg) translateY(0%)
      }
      50% {
        transform: rotate(10deg) translateY(3%);
      }
      100% {
        transform: rotate(0deg) translateY(0%)
      }
    `

    const scheduleAnimation = keyframes`
      0% {
        transform: rotate(0deg)
      }
      25% {
        transform: rotate(2deg);
      }
      75% {
        transform: rotate(-2deg);
      }
      100% {
        transform: rotate(0deg)
      }
    `

    const armAnimation = keyframes`
      0% {
        transform: rotate(0deg)
      }
      25% {
        transform: rotate(1deg);
      }
      75% {
        transform: rotate(-1deg);
      }
      100% {
        transform: rotate(0deg)
      }
    `

    return (
        <Box
            sx={{
                '#plantGroup': {
                    transformOrigin: 'bottom center',
                    animation: `${plantAnimation} 10s infinite`,
                },
                '#floatingPosts': {
                    transformOrigin: 'top center',
                    animation: `${scheduleAnimation} 8s infinite linear`,
                },
                '#armWithBox': {
                    transformOrigin: '50% 0%',
                    animation: `${armAnimation} 7s infinite linear`,
                },
            }}
        >
            <svg height="100%" width="100%" viewBox="0 0 579.232 563.506" strokeMiterlimit="10">
                <defs />
                <g opacity="1" id="plantGroup">
                    <path
                        d="M223.642 513.249L208.558 527.135L220.546 507.021C211.101 489.894 195.655 475.092 195.655 475.092C195.655 475.092 163.609 505.795 163.609 529.929C163.609 554.063 177.957 562.49 195.655 562.49C213.353 562.49 227.701 554.063 227.701 529.929C227.701 524.557 226.111 518.86 223.642 513.249Z"
                        fill={plantColor}
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M204.679 527.887L204.679 529.077C204.608 542.905 202.276 553.693 197.701 561.29C197.636 561.402 197.565 561.508 197.5 561.62L196.988 561.308L196.499 561.002C201.576 552.798 203.372 541.203 203.437 529.142C203.442 528.753 203.448 528.358 203.442 527.964C203.425 522.858 203.113 517.693 202.612 512.675C202.577 512.287 202.536 511.892 202.494 511.498C201.799 504.878 200.798 498.553 199.774 493.059C199.703 492.67 199.626 492.281 199.55 491.899C197.777 482.605 195.969 475.874 195.557 474.384C195.51 474.202 195.48 474.102 195.474 474.078L196.034 473.919L196.04 473.913L196.605 473.754C196.611 473.778 196.711 474.113 196.876 474.738C197.506 477.07 199.143 483.401 200.751 491.751C200.822 492.128 200.898 492.517 200.969 492.906C201.805 497.364 202.624 502.346 203.272 507.576C203.437 508.889 203.584 510.184 203.713 511.462C203.76 511.857 203.802 512.251 203.837 512.64C204.379 518.064 204.66 523.146 204.679 527.887Z"
                        fill={plantColorStroke}
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M200.751 491.751C200.357 491.804 199.956 491.857 199.55 491.899C198.446 492.012 197.338 492.069 196.228 492.069C191.5 492.076 186.832 491.021 182.565 488.983C182.318 489.296 182.071 489.608 181.817 489.926C186.305 492.116 191.234 493.252 196.228 493.247C197.412 493.247 198.596 493.184 199.773 493.059C200.174 493.017 200.574 492.964 200.969 492.906C204.226 492.438 207.393 491.479 210.362 490.061C210.109 489.737 209.862 489.419 209.62 489.107C206.81 490.42 203.822 491.311 200.751 491.751Z"
                        fill={plantColorStroke}
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M203.713 511.462C203.307 511.486 202.901 511.498 202.494 511.498C202.371 511.504 202.241 511.504 202.117 511.504C191.72 511.504 181.98 506.416 176.04 497.882C175.805 498.229 175.569 498.577 175.339 498.93C181.528 507.562 191.497 512.681 202.117 512.681C202.282 512.681 202.447 512.681 202.612 512.675C203.024 512.67 203.431 512.658 203.837 512.64C210.125 512.318 216.187 510.192 221.298 506.515C221.11 506.168 220.921 505.82 220.727 505.473C215.759 509.082 209.847 511.164 203.713 511.462Z"
                        fill={plantColorStroke}
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M204.679 527.887C204.267 527.923 203.855 527.946 203.442 527.964C203.001 527.981 202.559 527.993 202.117 527.993C187.979 527.977 175.542 518.644 171.576 505.073C171.311 505.55 171.046 506.021 170.793 506.498C175.255 520.018 187.88 529.156 202.117 529.171C202.559 529.171 203.001 529.165 203.437 529.142C203.855 529.13 204.267 529.106 204.679 529.077C213.006 528.425 220.774 524.634 226.41 518.47C226.286 518.035 226.145 517.605 226.004 517.169C220.56 523.379 212.911 527.223 204.679 527.887Z"
                        fill={plantColorStroke}
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                </g>
                <path
                    d="M578.394 30.1771L0.83826 30.1771C0.376435 30.1755 0.00289517 29.8007 0.00289517 29.3388C0.00289517 28.877 0.376435 28.5022 0.83826 28.5006L578.394 28.5006C578.856 28.5022 579.229 28.877 579.229 29.3388C579.229 29.8007 578.856 30.1755 578.394 30.1771Z"
                    fill="#cacaca"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M10.4848 9.22077C10.4848 4.12828 14.613 0 19.7055 0C24.798 0 28.9263 4.12828 28.9263 9.22077C28.9263 14.3133 24.798 18.4415 19.7055 18.4415C14.613 18.4415 10.4848 14.3133 10.4848 9.22077Z"
                    fill={uiColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M42.3383 9.22077C42.3383 4.12828 46.4666 0 51.5591 0C56.6516 0 60.7799 4.12828 60.7799 9.22077C60.7799 14.3133 56.6516 18.4415 51.5591 18.4415C46.4666 18.4415 42.3383 14.3133 42.3383 9.22077Z"
                    fill={uiColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M74.1919 9.22077C74.1919 4.12828 78.3202 0 83.4127 0C88.5052 0 92.6335 4.12828 92.6335 9.22077C92.6335 14.3133 88.5052 18.4415 83.4127 18.4415C78.3202 18.4415 74.1919 14.3133 74.1919 9.22077Z"
                    fill={uiColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M559.309 5.64005L536.676 5.64005C535.753 5.63686 535.005 4.8872 535.005 3.96355C535.005 3.0399 535.753 2.29024 536.676 2.28705L559.309 2.28705C559.909 2.28498 560.465 2.60406 560.766 3.12362C561.066 3.64319 561.066 4.28391 560.766 4.80348C560.465 5.32304 559.909 5.64212 559.309 5.64005Z"
                    fill={uiColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M559.309 11.9269L536.676 11.9269C535.753 11.9237 535.005 11.1741 535.005 10.2504C535.005 9.32678 535.753 8.57712 536.676 8.57393L559.309 8.57393C559.909 8.57186 560.465 8.89094 560.766 9.4105C561.066 9.93007 561.066 10.5708 560.766 11.0904C560.465 11.6099 559.909 11.929 559.309 11.9269Z"
                    fill={uiColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M559.309 18.2138L536.676 18.2138C535.753 18.2106 535.005 17.461 535.005 16.5373C535.005 15.6137 535.753 14.864 536.676 14.8608L559.309 14.8608C559.909 14.8588 560.465 15.1778 560.766 15.6974C561.066 16.217 561.066 16.8577 560.766 17.3773C560.465 17.8968 559.909 18.2159 559.309 18.2138Z"
                    fill={uiColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M17.3993 68.0859L546.679 68.0859L546.679 68.0859L546.679 348.506L546.679 348.506L17.3993 348.506L17.3993 348.506L17.3993 68.0859L17.3993 68.0859Z"
                    fill={tableColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M546.679 120.576L546.679 117.826L443.649 117.826L443.649 68.0859L440.899 68.0859L440.899 117.826L337.869 117.826L337.869 68.0859L335.119 68.0859L335.119 117.826L232.089 117.826L232.089 68.0859L229.339 68.0859L229.339 117.826L126.309 117.826L126.309 68.0859L123.559 68.0859L123.559 117.826L17.3994 117.826L17.3994 120.576L123.559 120.576L123.559 175.526L17.3994 175.526L17.3994 178.276L123.559 178.276L123.559 233.226L17.3994 233.226L17.3994 235.976L123.559 235.976L123.559 290.916L17.3994 290.916L17.3994 293.666L123.559 293.666L123.559 348.506L126.309 348.506L126.309 293.666L229.339 293.666L229.339 348.506L232.089 348.506L232.089 293.666L335.119 293.666L335.119 348.506L337.869 348.506L337.869 293.666L440.899 293.666L440.899 348.506L443.649 348.506L443.649 293.666L546.679 293.666L546.679 290.916L443.649 290.916L443.649 235.976L546.679 235.976L546.679 233.226L443.649 233.226L443.649 178.276L546.679 178.276L546.679 175.526L443.649 175.526L443.649 120.576L546.679 120.576ZM229.339 290.916L126.309 290.916L126.309 235.976L229.339 235.976L229.339 290.916ZM229.339 233.226L126.309 233.226L126.309 178.276L229.339 178.276L229.339 233.226ZM229.339 175.526L126.309 175.526L126.309 120.576L229.339 120.576L229.339 175.526ZM335.119 290.916L232.089 290.916L232.089 235.976L335.119 235.976L335.119 290.916ZM335.119 233.226L232.089 233.226L232.089 178.276L335.119 178.276L335.119 233.226ZM335.119 175.526L232.089 175.526L232.089 120.576L335.119 120.576L335.119 175.526ZM440.899 290.916L337.869 290.916L337.869 235.976L440.899 235.976L440.899 290.916ZM440.899 233.226L337.869 233.226L337.869 178.276L440.899 178.276L440.899 233.226ZM440.899 175.526L337.869 175.526L337.869 120.576L440.899 120.576L440.899 175.526Z"
                    fill={plantColorStroke}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M472.805 244.939L508.214 244.939L508.214 244.939L508.214 279.22L508.214 279.22L472.805 279.22L472.805 279.22L472.805 244.939L472.805 244.939Z"
                    fill="#cacaca"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M157.805 301.939L193.214 301.939L193.214 301.939L193.214 336.22L193.214 336.22L157.805 336.22L157.805 336.22L157.805 301.939L157.805 301.939Z"
                    fill="#cacaca"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <g opacity="1" id="floatingPosts">
                    <path
                        d="M146.805 133.939L182.214 133.939L182.214 133.939L182.214 168.22L182.214 168.22L146.805 168.22L146.805 168.22L146.805 133.939L146.805 133.939Z"
                        fill="#cacaca"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M168.27 127.579L203.679 127.579L203.679 127.579L203.679 161.86L203.679 161.86L168.27 161.86L168.27 161.86L168.27 127.579L168.27 127.579Z"
                        fill="#ff6900"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                </g>
                <path
                    d="M370.13 270.076L412.149 232.639L424.545 238.781L374.149 291.639L370.13 270.076Z"
                    fill="#ffb6b6"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M407.679 235.506C407.679 229.983 412.156 225.506 417.679 225.506C423.202 225.506 427.679 229.983 427.679 235.506C427.679 241.029 423.202 245.506 417.679 245.506C412.156 245.506 407.679 241.029 407.679 235.506Z"
                    fill="#ffb6b6"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M330.799 551.856L320.856 551.856L316.126 513.506L330.8 513.507L330.799 551.856Z"
                    fill="#ffb6b6"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M333.334 561.494L301.276 561.493L301.276 561.087C301.276 554.196 306.862 548.609 313.754 548.609L313.755 548.609L333.335 548.61L333.334 561.494Z"
                    fill={lowerColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M294.799 551.856L284.856 551.856L280.126 513.506L294.8 513.507L294.799 551.856Z"
                    fill="#ffb6b6"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M297.334 561.494L265.276 561.493L265.276 561.087C265.276 554.196 270.862 548.609 277.754 548.609L277.755 548.609L297.335 548.61L297.334 561.494Z"
                    fill={lowerColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M339.149 332.639C339.149 332.639 364.149 330.639 356.149 374.639C348.149 418.639 331.578 539 331.578 539L316.555 541.065L307.679 380.506L300.964 541.065L281.857 539L263.149 366.639C263.149 366.639 257.142 352.781 263.146 343.21C269.149 333.639 339.149 332.639 339.149 332.639Z"
                    fill={lowerColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M323.149 223.639L330.09 219.92C330.09 219.92 347.191 218.462 354.17 238.55L372.679 266.506L402.679 240.506L415.679 249.506L377.87 294.63L362.149 296.639L328.149 252.639L323.149 223.639Z"
                    fill={shirtColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M297.963 142.506C269.445 142.506 261.463 173.833 261.463 191.506C261.463 209.179 277.804 206.506 297.963 206.506C306.615 206.506 314.559 206.994 320.815 206.159L323.824 198.812L326.245 204.9C331.38 203.013 334.463 199.178 334.463 191.506C334.463 173.833 327.463 142.506 297.963 142.506Z"
                    fill={hairColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M273.28 181.915C273.28 168.351 284.276 157.354 297.841 157.354C311.405 157.354 322.402 168.351 322.402 181.915C322.402 195.48 311.405 206.476 297.841 206.476C284.276 206.476 273.28 195.48 273.28 181.915Z"
                    fill="#ffb6b6"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M269.463 179.506L269.463 179.506L279.177 179.506L283.463 167.506L284.32 179.506L288.963 179.506L291.463 172.506L291.963 179.506L326.463 179.506L326.463 179.506C326.463 165.146 314.822 153.506 300.463 153.506L295.463 153.506C281.103 153.506 269.463 165.146 269.463 179.506Z"
                    fill={hairColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M398.27 197.58L433.679 197.58L433.679 197.58L433.679 231.86L433.679 231.86L398.27 231.86L398.27 231.86L398.27 197.58Z"
                    fill="#ff6900"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M346.92 323.591C343.149 323.639 331.79 256.241 331.79 256.241L330.09 219.921L317.679 219.503L317.679 218.687C317.679 215.273 314.912 212.506 311.498 212.506L287.86 212.506C284.601 212.507 281.904 215.041 281.699 218.294L271.966 217.967L255.11 271.52C255.11 271.52 270.642 321.281 263.146 343.21C263.146 343.21 336.184 349.482 350.166 335.56C350.166 335.56 350.691 323.542 346.92 323.591Z"
                    fill={shirtColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <path
                    d="M276.149 224.639L271.966 217.967C271.966 217.967 258.356 214.602 252.753 223.62C247.149 232.639 230.679 281.506 230.679 281.506L247.679 287.506L266.97 260.46L276.149 224.639Z"
                    fill={shirtColor}
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <g opacity="1" id="armWithBox">
                    <path
                        d="M283.27 259.579L318.679 259.579L318.679 259.579L318.679 293.86L318.679 293.86L283.27 293.86L283.27 293.86L283.27 259.579L283.27 259.579Z"
                        fill="#ff6900"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M256.103 271.969L243.784 270.666L243.784 270.666C242.208 274.029 242.265 277.929 243.939 281.244C245.614 284.559 248.719 286.92 252.361 287.646L301.105 297.375L296.02 281.716L256.103 271.969Z"
                        fill="#ffb6b6"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M291.251 287.555C291.251 282.032 295.729 277.555 301.251 277.555C306.774 277.555 311.251 282.032 311.251 287.555C311.251 293.078 306.774 297.555 301.251 297.555C295.729 297.555 291.251 293.078 291.251 287.555Z"
                        fill="#ffb6b6"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M288.768 297.859L251.633 289.374C246.16 288.108 241.748 284.072 240.001 278.733L238.5 274.105L248.138 266.521L292.29 278.862L288.768 297.859Z"
                        fill={shirtColor}
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                </g>
                <path
                    d="M497.439 563.506L116.439 563.506C115.887 563.506 115.439 563.058 115.439 562.506C115.439 561.954 115.887 561.506 116.439 561.506L497.439 561.506C497.991 561.506 498.439 561.954 498.439 562.506C498.439 563.058 497.991 563.506 497.439 563.506Z"
                    fill="#cacaca"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
            </svg>
        </Box>
    )
}
