import * as React from 'react'
import { FC } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    alpha,
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    useTheme,
} from '@mui/material'
import { ArrowBackIos, ExpandMore, TipsAndUpdates } from '@mui/icons-material'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { ConnectedSwitch } from '../../form/ConnectedSwitch'
import { useDispatch, useSelector } from 'react-redux'
import {
    clearResults,
    getAiResults,
    getAiResultsLoading,
    getSelectedResultToRefine,
    setAiDialogOpen,
    setSelectedResultToRefine,
} from './PostTextGenerationSlice'
import { HmstrDispatch } from '../../core/Store'
import SwipeableViews from 'react-swipeable-views'
import { PostTextSuggestionList } from './PostTextSuggestionList'
import { AiLoadingButton } from '../../common/ai/AiLoadingButton'
import { FormattedMessage } from 'react-intl'
import { ConnectedSelectSlider } from '../../form/ConnectedSelectSlider'
import { GooeyLoader } from '../../common/ai/GooeyLoader'
import { ConnectedSimpleLanguageDropdown } from '../../form/ConnectedSimpleLanguageDropdown'
import { RefineResultDialog } from './RefineResultDialog'
import { ConnectedTextFieldWithSuggestions } from '../../form/ConnectedTextFieldWithSuggestions'

type PostTextGenerationDialogProps = {
    open: boolean
    handleSubmit: () => void
    handleSelect: (val: string) => void
    fullScreenLoader?: boolean
}

export const PostTextGenerationDialog: FC<PostTextGenerationDialogProps> = ({ open, handleSubmit, handleSelect, fullScreenLoader }) => {
    const theme = useTheme()
    const aiResultsLoading = useSelector(getAiResultsLoading)
    const results = useSelector(getAiResults)
    const dispatch = useDispatch<HmstrDispatch>()
    const hasResults = results.length > 0
    const resultToRefine = useSelector(getSelectedResultToRefine)
    const getPageIndex = () => {
        if (hasResults) {
            if (resultToRefine) {
                return 2
            }
            return 1
        } else {
            return 0
        }
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown={hasResults}
            onClose={hasResults ? undefined : () => dispatch(setAiDialogOpen(false))}
            maxWidth={'md'}
            fullWidth
            scroll={'paper'}
            keepMounted={false}
            PaperProps={{ elevation: 0 }}
        >
            <DialogTitle
                sx={{
                    width: '100%',
                    '& .aiBetaBadge .MuiBadge-badge': {
                        background: 'linear-gradient(45deg, rgba(255,105,0,1) 0%, #ff00b3 100%)',
                    },
                }}
            >
                <SwipeableViews index={getPageIndex()} style={{ overflowX: 'clip' }} slideStyle={{ overflow: 'visible' }}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={1}>
                        <Badge
                            className={'aiBetaBadge'}
                            badgeContent={'beta'}
                            color={'secondary'}
                            overlap={'rectangular'}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            sx={{ my: 1 }}
                        >
                            <Box display={'flex'} alignItems={'center'} gap={1} pr={2}>
                                <TipsAndUpdates color={'secondary'} fontSize={'small'} sx={{ fill: 'url(#ColoredGradient)' }} />
                                <FormattedMessage id={'ai.text-generation-form-title'} />
                            </Box>
                        </Badge>
                        <Box flexShrink={1}>
                            <ConnectedSimpleLanguageDropdown name={'language'}>
                                <MenuItem value={'de'}>
                                    <FormattedMessage id={'ai.language.german'} />
                                </MenuItem>
                                <MenuItem value={'en'}>
                                    <FormattedMessage id={'ai.language.english'} />
                                </MenuItem>
                                <MenuItem value={'fr'}>
                                    <FormattedMessage id={'ai.language.french'} />
                                </MenuItem>
                            </ConnectedSimpleLanguageDropdown>
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <IconButton onClick={() => dispatch(clearResults())} color={'secondary'} size={'small'}>
                            <ArrowBackIos fontSize={'small'} />
                        </IconButton>
                        <FormattedMessage id={'general.back'} />
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <IconButton onClick={() => dispatch(setSelectedResultToRefine(undefined))} color={'secondary'} size={'small'}>
                            <ArrowBackIos fontSize={'small'} />
                        </IconButton>
                        <FormattedMessage id={'ai.text-generation-form-title-back-to-results'} />
                    </Box>
                </SwipeableViews>
            </DialogTitle>
            <DialogContent sx={{ position: 'relative' }}>
                <SwipeableViews index={getPageIndex()}>
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                        <Alert severity={'info'}>
                            <FormattedMessage id={'ai.beta-disclaimer'} />
                        </Alert>
                        <ConnectedTextFieldWithSuggestions
                            suggestions={[
                                'ai.placeholder-suggestion-1',
                                'ai.placeholder-suggestion-2',
                                'ai.placeholder-suggestion-3',
                                'ai.placeholder-suggestion-4',
                                'ai.placeholder-suggestion-5',
                            ]}
                            required
                            validate={(val: string | undefined) => {
                                if (!val) {
                                    return 'validations.required'
                                }
                            }}
                            name={'prompt'}
                            label={'ai.post-content-query'}
                            variant={'outlined'}
                            multiline
                            minRows={5}
                            disableMaxLength
                        />
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <FormattedMessage id={'ai.dialog.more-options'} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box display={'flex'} flexDirection={'column'} gap={2}>
                                    <ConnectedTextField name={'target_audience'} label={'ai.post-content-audience'} variant={'outlined'} />
                                    <ConnectedSelectSlider
                                        items={[
                                            { label: 'ai.sentiment.default', value: 'default' },
                                            { label: 'ai.sentiment.happy', value: 'happy' },
                                            {
                                                label: 'ai.sentiment.excited',
                                                value: 'excited',
                                            },
                                            { label: 'ai.sentiment.hopeful', value: 'hopeful' },
                                            { label: 'ai.sentiment.sad', value: 'sad' },
                                        ]}
                                        name={'sentiment'}
                                    />
                                    <ConnectedSelectSlider
                                        items={[
                                            { label: 'ai.text-length.short', value: 'short' },
                                            {
                                                label: 'ai.text-length.normal',
                                                value: 'normal',
                                            },
                                            { label: 'ai.text-length.long', value: 'long' },
                                        ]}
                                        name={'text_length'}
                                    />
                                    <Box display={'flex'} justifyContent={'flex-start'} gap={1} mt={1}>
                                        <Box pl={1}>
                                            <ConnectedSwitch name={'use_emojis'} label={'ai.post-content-insert-emojis'}></ConnectedSwitch>
                                        </Box>
                                        <Box pl={1}>
                                            <ConnectedSwitch name={'use_hashtags'} label={'ai.post-content-insert-hashtags'}></ConnectedSwitch>
                                        </Box>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <PostTextSuggestionList handleSelect={handleSelect} />
                    <>{resultToRefine && <RefineResultDialog />}</>
                </SwipeableViews>
                {aiResultsLoading && fullScreenLoader && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: alpha(theme.palette.background.paper, 0.5),
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <GooeyLoader size={300} animationDuration={2} />
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ height: '100px' }}>
                {!aiResultsLoading && (
                    <Button onClick={() => dispatch(setAiDialogOpen(false))} color={'inherit'}>
                        <FormattedMessage id={'general.cancel'} />
                    </Button>
                )}
                {(!hasResults || resultToRefine) && (
                    <AiLoadingButton
                        onClick={handleSubmit}
                        type={'submit'}
                        label={'general.submit'}
                        loading={aiResultsLoading}
                        variant={'contained'}
                        textColor={'#fff'}
                        strokeColor={theme.palette.secondary.main}
                        color={'secondary'}
                        hideOnLoading={fullScreenLoader}
                    />
                )}
            </DialogActions>
        </Dialog>
    )
}
