import * as React from 'react'
import { FC, useState } from 'react'
import { Field } from 'react-final-form'
import { alpha, Box, lighten, useTheme } from '@mui/material'
import { MentionProps, MentionsInput } from 'react-mentions'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { isMobileDevice, isMobileTablet } from '../core/theme/helper'
import { isDarkMode } from '../core/slices/CoreSlice'

type ConnectedMentionsInputProps = {
    autofocus?: boolean
    placeholder?: string
    children: React.ReactElement<MentionProps> | Array<React.ReactElement<MentionProps>>
    button?: JSX.Element
    handleKeyDown?: any
}

export const ConnectedMentionsInput: FC<ConnectedMentionsInputProps> = ({ autofocus, placeholder, children, button, handleKeyDown }) => {
    const intl = useIntl()
    const theme = useTheme()
    const isMobile = useSelector(isMobileDevice)
    const isTablet = useSelector(isMobileTablet)
    const darkMode = useSelector(isDarkMode)

    const [textBoxHovered, setTextBoxHovered] = useState(false)
    const [textBoxSelected, setTextBoxSelected] = useState(false)
    const itemBackground = darkMode ? lighten(theme.palette.background.paper, 0.1) : theme.palette.background.paper

    const outlinedSx = {
        background: theme.palette.background.paper,
        outline: `${textBoxSelected ? '2.5px' : '1px'} solid ${alpha('#000', textBoxSelected ? 1 : textBoxHovered ? 0.42 : 0.2)}`,
        px: 1,
        py: 1,
        borderRadius: 1,
        mt: 2,
        '& textarea': {
            px: isMobile ? '4px !important' : isTablet ? '0px !important' : undefined,
        },
    }

    return (
        <Box className={'internal-note-bar'} display={'flex'} alignItems={'center'} gap={1} sx={outlinedSx}>
            <Box
                sx={{
                    flexGrow: 1,
                    pl: 1,
                    '& div:has(.customMentionsInput)': {
                        backgroundColor: 'transparent !important',
                    },
                }}
            >
                <Field
                    name={'message'}
                    render={({ input }) => {
                        return (
                            <MentionsInput
                                onMouseEnter={() => setTextBoxHovered(true)}
                                onMouseLeave={() => setTextBoxHovered(false)}
                                onFocus={() => setTextBoxSelected(true)}
                                placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : undefined}
                                allowSuggestionsAboveCursor
                                allowSpaceInQuery
                                style={{
                                    wordBreak: 'break-word',
                                    fontWeight: 'inherit',
                                    fontSize: 'inherit',
                                    lineHeight: 'normal',
                                    input: {
                                        border: 'none',
                                        transition: 'all 0.1s',
                                        outline: 'none',
                                        color: 'inherit',
                                    },
                                }}
                                value={input.value}
                                onChange={input.onChange}
                                onKeyDown={handleKeyDown}
                                autoFocus={autofocus}
                                customSuggestionsContainer={(children) => {
                                    return (
                                        <Box className="customMentionsInput" sx={{ boxShadow: theme.shadows[3], borderRadius: 1, background: itemBackground }}>
                                            {children}
                                        </Box>
                                    )
                                }}
                            >
                                {children}
                            </MentionsInput>
                        )
                    }}
                />
            </Box>
            {button !== undefined ? button : null}
        </Box>
    )
}
