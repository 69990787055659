import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Box, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import {
    getCompactCalendarViewForMonthly,
    getCompactCalendarViewForWeekly,
    getSelectedCalendarTimePeriod,
    getShowFullTagNames,
    selectPostGroup,
} from '../PublishingSlice'
import { HmstrDispatch } from '../../core/Store'
import { useDispatch, useSelector } from 'react-redux'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import _ from 'lodash'
import { getIconForDatasourceType } from '../../settings/datasources/DatasourceTypeMappings'
import { PublishingListPostImage } from './PublishingListPostImage'
import { getMedia } from '../../core/slices/DataSlice'
import { PublishingListTimeDisplay } from './PublishingListTimeDisplay'
import { FormattedMessage } from 'react-intl'
import { PostTagsList } from './PostTagsList'
import { getDatasourcesAsIdMap, getSimpleUsersForSelectedProject } from '../../core/slices/CoreSlice'
import { UserAvatar } from '../../common/avatars/UserAvatar'
import { fetchLinkPreviewMetadata } from '../PublishingActions'
import { PostGroup } from '../post-groups/PostGroup'
import { PostCalendarCardHeader } from './PostCalendarCardHeader'
import AnimateHeight from 'react-animate-height'
import { PostTypeIcon } from './PostTypeIcon'

type PublishingListPostCardProps = {
    postGroup: PostGroup
    showOnlyTime?: boolean
}

export const PublishingCalendarPostCard: FC<PublishingListPostCardProps> = ({ postGroup, showOnlyTime }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const theme = useTheme()
    const post = postGroup.posts[0]
    const media = useSelector(getMedia)
    const users = useSelector(getSimpleUsersForSelectedProject)
    const assignee = postGroup.assignee_id ? users[postGroup.assignee_id] : undefined
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const showFullTagNames = useSelector(getShowFullTagNames)
    const [cardHovered, setCardHovered] = useState(false)
    const image = post.link
        ? post.link_preview_file_id
            ? media[post.link_preview_file_id]
            : undefined
        : post.file_ids
        ? post.file_ids.map((file_id) => media[file_id]).filter((m) => !!m)[0]
        : undefined
    const isOnList = window.location.pathname.includes('/publishing/list')
    const calendarView = useSelector(getSelectedCalendarTimePeriod)
    const compactViewMonthly = useSelector(getCompactCalendarViewForMonthly)
    const compactViewWeekly = useSelector(getCompactCalendarViewForWeekly)
    const compactView = calendarView === 'month' ? compactViewMonthly : compactViewWeekly

    const [previewMetadata, setPreviewMetadata] = useState<any>(undefined)

    useEffect(() => {
        if (post.link && !image) {
            dispatch(fetchLinkPreviewMetadata(post.link)).then((action) => setPreviewMetadata(action.payload))
        } else {
            setPreviewMetadata(undefined)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post.link, image])
    const finalImage = image?._links.file.href || (previewMetadata && _.get(previewMetadata, 'image')) || undefined

    const handleClick = () => {
        dispatch(selectPostGroup(postGroup.id))
        navigate(isOnList ? `/publishing/list/${postGroup.id}` : `/publishing/calendar/${postGroup.id}`)
    }

    const renderTooltip = () => {
        return (
            <Box>
                {postGroup.posts.map((p) => {
                    const dsForPost = datasourcesById[p.data_source_id]

                    if (!dsForPost) {
                        return null
                    }

                    return (
                        <Box key={dsForPost.id} display="flex" alignItems="center" gap={0.5} pb={0.5}>
                            {getIconForDatasourceType(dsForPost.type)}
                            {dsForPost.name}
                        </Box>
                    )
                })}
            </Box>
        )
    }

    return (
        <Tooltip title={renderTooltip()} disableInteractive placement={'top'}>
            <Paper
                sx={{
                    width: '100%',
                    cursor: 'pointer',
                    outline: '1px solid transparent',
                    transition: 'all 0.3s',
                    '&:hover': {
                        transform: 'scale(1.02)',
                    },
                    mb: 0.75,
                    boxShadow: cardHovered ? theme.shadows[6] : theme.shadows[3],
                }}
                elevation={0}
                onClick={() => handleClick()}
                onMouseEnter={() => {
                    setCardHovered(true)
                }}
                onMouseLeave={() => {
                    setCardHovered(false)
                }}
            >
                <PostCalendarCardHeader postGroup={postGroup} />
                <AnimateHeight height={compactView ? 0 : 'auto'}>
                    {(finalImage || post.post_type === 'VIDEO') && (
                        <>
                            <PublishingListPostImage
                                publishingImage={finalImage}
                                isFinalLink={!!previewMetadata?.image}
                                isVideo={post.post_type === 'VIDEO' || image?.mime_type.includes('video')}
                            />
                        </>
                    )}
                    <Box
                        sx={{
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 0.5,
                        }}
                    >
                        <Box display="flex" justifyContent={'space-between'} alignItems="center">
                            <PublishingListTimeDisplay
                                time={post.publish_time}
                                fullTime={cardHovered}
                                now={post.state === 'PUBLISH_NOW'}
                                showOnlyTime={showOnlyTime}
                            />
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                                {assignee && (
                                    <UserAvatar
                                        user={assignee}
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                        }}
                                    />
                                )}
                                <PostTypeIcon postGroup={postGroup} />
                            </Box>
                        </Box>
                        {post.text ? (
                            <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                {post.text.length > 100 ? `${post.text.slice(0, 100)}...` : post.text}
                            </Typography>
                        ) : (
                            <Typography color={theme.palette.text.disabled}>
                                <FormattedMessage id={'publishing.no-text-available'} />
                            </Typography>
                        )}
                    </Box>
                    <Box>
                        <PostTagsList postGroup={postGroup} displayTags={showFullTagNames} />
                    </Box>
                </AnimateHeight>
            </Paper>
        </Tooltip>
    )
}
