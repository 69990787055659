import * as React from 'react'
import { FC } from 'react'
import { Post } from '../../posts/Post'
import { useSelector } from 'react-redux'
import { getDatasourcesAsIdMap, isDarkMode } from '../../../core/slices/CoreSlice'
import { TiktokPreviewDesktop } from './TiktokPreviewDesktop'
import { TiktokPreviewMobile } from './TiktokPreviewMobile'
import { hashTagExpr } from '../../../core/theme/helper'

type TiktokPreviewProps = {
    post: Post
    mockupDevice: 'mobile' | 'desktop'
}

export const TiktokPreview: FC<TiktokPreviewProps> = ({ post, mockupDevice }) => {
    const darkmode = useSelector(isDarkMode)
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const selectedDatasource = datasourcesById[post.data_source_id]

    const generatePostText = (text: string, white?: boolean) => {
        const escapeHtml = (unsafe: string) => {
            return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
        }
        const hashtags = text.match(hashTagExpr)
        const generateContent = () => {
            let newText = escapeHtml(text)
            hashtags
                ?.sort((a, b) => b.length - a.length)
                .forEach((hashtag: string) => {
                    const pattern = `${hashtag}(?!\\w)`
                    const hashtagRegex = new RegExp(pattern, 'g')
                    newText = newText.replaceAll(
                        hashtagRegex,
                        `<span style="color: ${darkmode ? 'hsl(214, 100%, 59%)' : white ? 'white' : 'rgb(43, 93, 185)'}; cursor: pointer;">${escapeHtml(
                            hashtag
                        )}</span>`
                    )
                })
            return <span dangerouslySetInnerHTML={{ __html: newText }}></span>
        }

        return (
            <span
                style={{
                    lineHeight: '21px',
                    letterSpacing: '0.03px',
                    fontSize: 15,
                    fontWeight: 400,
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                }}
            >
                {generateContent()}
            </span>
        )
    }

    return mockupDevice === 'mobile' ? (
        <TiktokPreviewMobile selectedDatasource={selectedDatasource} post={post} generatePostText={generatePostText} />
    ) : (
        <TiktokPreviewDesktop selectedDatasource={selectedDatasource} post={post} generatePostText={generatePostText} />
    )
}
