import * as React from 'react'
import { FC } from 'react'
import { Avatar, Skeleton } from '@mui/material'
import { useImage } from '../../core/hooks/useImage'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'
import { HmstrColors } from '../../core/theme/hmstr-theme'

type PostAvatarProps = {
    name: string
    src: string
    height?: number
    width?: number
    color?: string
    instagramStoryBorder?: boolean
    borderWidth?: number
}

export const PostAvatar: FC<PostAvatarProps> = ({ name, src, color, height = 70, width = 70, borderWidth = 4, instagramStoryBorder }) => {
    const { objectUrl, hasErrors } = useImage(src, false)
    const darkmode = useSelector(isDarkMode)

    const stringToColor = (string: string) => {
        let hash = 0
        let i

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash)
        }

        let color = '#'

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff
            color += `00${value.toString(16)}`.substr(-2)
        }
        /* eslint-enable no-bitwise */

        return color
    }

    const stringAvatar = (name: string) => {
        const bgcolor = stringToColor(name)
        return {
            sx: instagramStoryBorder
                ? {
                      bgcolor: bgcolor,
                      width: `${width + 4.5}px`,
                      height: `${height + 4.5}px`,
                      boxShadow: darkmode ? `0px 0px 0px 2px ${HmstrColors.PostPreviewColorsDarkMode.background} inset` : '0px 0px 0px 2px white inset',
                      border: 'solid 2.5px transparent',
                      backgroundImage: `linear-gradient(${bgcolor}, ${bgcolor}),linear-gradient(45deg, #fdf497, #d6249f,#fd5949)`,
                      backgroundOrigin: 'border-box',
                      backgroundClip: 'content-box, border-box',
                  }
                : {
                      bgcolor: bgcolor,
                      border: color ? `${borderWidth}px solid ${color}` : undefined,
                      width: `${width}px`,
                      height: `${height}px`,
                      m: '2px',
                  },
            children: name.substr(0, 1),
        }
    }

    return objectUrl ? (
        <Avatar src={objectUrl} {...stringAvatar(name)} />
    ) : hasErrors ? (
        <Avatar {...stringAvatar(name)} />
    ) : (
        <Skeleton height={height} width={width} variant="circular" sx={{ border: color ? `4px solid ${color}` : undefined }} />
    )
}
