import * as React from 'react'
import { FC } from 'react'
import { Grid, Skeleton, Typography } from '@mui/material'
import { PostAvatar } from '../../../common/avatars/PostAvatar'
import { Public } from '@mui/icons-material'
import { PostPreviewImageGrid } from '../PostPreviewImageGrid'
import { PostPreviewVideo } from '../PostPreviewVideo'
import { LinkPreview } from '../../../common/link-preview/LinkPreview'
import { FacebookPostActionsPreview } from './FacebookPostActionsPreview'
import { Post } from '../../posts/Post'
import { useSelector } from 'react-redux'
import { getDatasourcesAsIdMap, isDarkMode } from '../../../core/slices/CoreSlice'
import { ProjectDatasource } from '../../../settings/datasources/ProjectDatasource'
import { getMedia } from '../../../core/slices/DataSlice'
import { getErrorsForCommonFile } from '../../PublishingSlice'
import { ReadMore } from '../ReadMore'
import { replaceMentions } from '../../mentions/MentionService'
import { hashTagExpr } from '../../../core/theme/helper'

type FacebookPostPreviewProps = {
    post: Post
}

export const FacebookPostPreview: FC<FacebookPostPreviewProps> = ({ post }) => {
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const selectedDatasource = datasourcesById[post.data_source_id]
    const postType = post.post_type
    const media = useSelector(getMedia)
    const files = (post.file_ids || []).map((file_id) => media[file_id]).filter((m) => !!m)
    const thumbnailFile = post.link_preview_file_id ? media[post.link_preview_file_id] : undefined
    const errorsForFiles = useSelector(getErrorsForCommonFile)
    const darkmode = useSelector(isDarkMode)
    const hashtagColor = darkmode ? 'hsl(214, 100%, 59%)' : 'rgb(0, 55, 200)'

    const actualFiles = files.filter(
        (f) =>
            (f.category === postType || postType === 'MULTI_MEDIA') &&
            !errorsForFiles[f.id]
                ?.map((error) => {
                    return error ? error.network === selectedDatasource.type : false
                })
                .includes(true)
    )

    const getAvatarUrlForDatasource = (datasource: ProjectDatasource) => {
        if (!datasource.metadata.profile_picture_url) {
            return 'https://graph.facebook.com/' + datasource.metadata.id + '/picture'
        }

        return datasource.metadata.profile_picture_url
    }

    const generatePostText = (text: string) => {
        const escapeHtml = (unsafe: string) => {
            return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
        }
        const hashtags = text.match(hashTagExpr)
        const generateContent = () => {
            let newText = escapeHtml(text)
            newText = replaceMentions(newText, hashtagColor)

            hashtags
                ?.sort((a, b) => b.length - a.length)
                .forEach((hashtag: string) => {
                    const pattern = `${hashtag}(?!\\w)`
                    const hashtagRegex = new RegExp(pattern, 'g')
                    newText = newText.replaceAll(hashtagRegex, `<span style='color: ${hashtagColor}; cursor: pointer;'>${escapeHtml(hashtag)}</span>`)
                })
            return <span dangerouslySetInnerHTML={{ __html: newText }}></span>
        }

        return (
            <span
                style={{
                    fontSize: 15,
                    color: darkmode ? 'inherit' : '#050505',
                    fontWeight: 400,
                    lineHeight: '14px',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                    hyphens: 'auto',
                }}
            >
                {generateContent()}
            </span>
        )
    }

    return (
        <Grid container spacing={1.5} alignItems="center">
            <Grid item>
                <PostAvatar width={40} height={40} name={selectedDatasource.name} src={getAvatarUrlForDatasource(selectedDatasource)} />
            </Grid>
            <Grid item xs={6} sx={{ lineHeight: 1 }}>
                {selectedDatasource ? (
                    <>
                        <Typography
                            sx={{
                                fontSize: 15,
                                color: darkmode ? 'inherit' : '#050505',
                                fontWeight: 600,
                                lineHeight: 1.3,
                            }}
                        >
                            {selectedDatasource.name}
                        </Typography>
                        {['FACEBOOK', 'LINKED_IN'].includes(selectedDatasource.category) && (
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: '#65676b',
                                    lineHeight: 1.3,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Just now &nbsp;·&nbsp;
                                <Public sx={{ fontSize: 12 }} />
                            </Typography>
                        )}
                    </>
                ) : (
                    <Skeleton animation={false} height={30} />
                )}
            </Grid>

            {postType === 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12}>
                    <PostPreviewVideo variant="STANDARD" file={actualFiles[0]} />
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography sx={{ wordWrap: 'break-word', hyphens: 'auto' }}>
                    {post.text ? (
                        <ReadMore sliceLimit={100} passedElement={generatePostText(post.text)}>
                            {generatePostText(post.text)}
                        </ReadMore>
                    ) : (
                        <>
                            <Skeleton animation={false} />
                            <Skeleton animation={false} />
                        </>
                    )}
                </Typography>
            </Grid>

            {postType !== 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12}>
                    <PostPreviewImageGrid uploadFiles={actualFiles} />
                </Grid>
            )}

            {post.link && (
                <Grid item xs={12}>
                    <LinkPreview
                        url={post.link}
                        ctaType={post.cta_type}
                        title={post.link_preview_title}
                        description={post.link_preview_description}
                        thumbnail={thumbnailFile}
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <FacebookPostActionsPreview />
            </Grid>
        </Grid>
    )
}
