import { HmstrColors } from '../core/theme/hmstr-theme'

export const SourceTypeColors = {
    'SOCIALMEDIA,SOCIALMEDIA_FACEBOOK': HmstrColors.Platforms.Facebook,
    'SOCIALMEDIA,SOCIALMEDIA_TWITTER': HmstrColors.Platforms.X,
    'SOCIALMEDIA,SOCIALMEDIA_YOUTUBE': HmstrColors.Platforms.Youtube,
    'SOCIALMEDIA,SOCIALMEDIA_FLICKR': HmstrColors.Platforms.Flickr,
    'BLOG,BLOG_OTHER': HmstrColors.Palette[0],
    'ONLINENEWS,ONLINENEWS_PRESSRELEASES': HmstrColors.Palette[1],
    'MESSAGEBOARD,MESSAGEBOARD_OTHER': HmstrColors.Palette[2],
    'ONLINENEWS,ONLINENEWS_OTHER': HmstrColors.Palette[3],
    'ONLINENEWS,ONLINENEWS_MAGZINE': HmstrColors.Palette[4],
    'ONLINENEWS,ONLINENEWS_NEWSPAPER': HmstrColors.Palette[5],
    'ONLINENEWS,ONLINENEWS_TVRADIO': HmstrColors.DarkGrey,
    'ONLINENEWS,ONLINENEWS_MAGAZINE': HmstrColors.Orange,
    OTHER: '#1F2121FF',
} as { [key: string]: string }
