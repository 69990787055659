import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type LinkedInLikeProps = SvgIconProps

export const LinkedInLike: FC<LinkedInLikeProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24px" height="24px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M14.0909091,14.0159216 L14.0909091,9.96497181 L12.5566504,9.96497181 C11.5076513,8.40231768 10.8005103,7.20142612 10.4352275,6.36229711 C10.3362044,6.13482096 10.1957344,5.70020568 10.0138176,5.05845127 C9.09548139,4.88516655 8.5088078,5.09717192 8.25379682,5.69446738 C8.05088412,6.16973647 8.07095349,6.87993997 8.25379682,7.35517383 C8.35743457,7.54415816 8.57253961,8.13873099 8.89911196,9.13889231 L6.30242282,9.13889231 C5.65248347,9.13889231 5.26404413,9.21242427 5.13710479,9.35948819 C4.84618975,9.88956516 5.24407044,9.99472227 5.24407044,10.1475038 C5.24407044,10.3002853 5,11.0367512 5,11.2167539 C5,11.3967567 5.43274311,11.8681116 5.43274311,11.9521288 C5.43274311,12.0361461 5.43274311,12.8756389 5.43274311,13.1039489 C5.43274311,13.4894485 6.40594482,13.4711431 6.30242282,13.7866483 C6.19890082,14.1021535 6.3286032,14.5628198 6.45399927,14.7964077 C6.53759665,14.952133 6.94209346,15.018524 7.6674897,14.9955807 C9.28208494,14.9497577 10.2046642,14.8833667 10.4352275,14.7964077 C10.6657907,14.7094487 11.3729317,14.4492867 12.5566504,14.0159216 L14.0909091,14.0159216 Z"
                        stroke={props.htmlColor}
                        strokeWidth="1.5"
                    ></path>
                    <rect strokeOpacity="0" stroke={props.htmlColor} x="0.5" y="0.5" width="19" height="19"></rect>
                </g>
            </svg>
        </SvgIcon>
    )
}
