import * as React from 'react'
import { FC } from 'react'
import { Box, Skeleton } from '@mui/material'
import { useImage } from '../../core/hooks/useImage'
import { ImageNotSupported } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getApiEntryPoint } from '../../core/api/ApiSlice'
import { CommonPostData } from './CommonPostData'
import { getIsMobile } from '../../core/slices/CoreSlice'

type FacebookPostImageProps = {
    post: CommonPostData
    size?: number
}

export const FacebookPostImage: FC<FacebookPostImageProps> = ({ post, size = 240 }) => {
    const entryPoint = useSelector(getApiEntryPoint)
    const thumbnailUrl = `${entryPoint._links.thumbnails.href}/${post.thumbnail_post_id}`
    const { objectUrl, hasErrors } = useImage(post.has_thumbnail ? thumbnailUrl : post.picture!, post.has_thumbnail)
    const isMobile = useSelector(getIsMobile)

    const styles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: isMobile ? '100%' : `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        backgroundColor: 'lightgrey',
        cursor: 'pointer',
        position: 'relative',
        objectFit: 'cover',
    } as any

    return objectUrl ? (
        <>
            <img src={objectUrl} onClick={() => window.open(post.perma_link, '_blank')} style={styles} alt={''} loading="lazy" />
        </>
    ) : hasErrors ? (
        <Box style={styles} onClick={() => window.open(post.perma_link, '_blank')}>
            <ImageNotSupported color="action" fontSize="large" />
        </Box>
    ) : (
        <Skeleton
            onClick={() => window.open(post.perma_link, '_blank')}
            variant="rectangular"
            animation="pulse"
            height={size}
            width={size}
            style={{ minWidth: size }}
        />
    )
}
