import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { User } from '../../settings/user-management/User'
import _, { indexOf } from 'lodash'
import { orderedAvailableTours } from './OrderedAvailableTours'

export interface GuideState {
    toursRunning: (keyof User['settings']['viewed_tutorials'])[]
    guideButtonsDeactivated?: boolean
    activeTarget?: string
}

const initialState: GuideState = {
    toursRunning: [],
}

export const GuideSlice = createSlice({
    name: 'guides',
    initialState,
    reducers: {
        startTour: (state, action: PayloadAction<keyof User['settings']['viewed_tutorials']>) => {
            if (!state.toursRunning.includes(action.payload)) {
                state.toursRunning.push(action.payload)
            }
        },
        endTour: (state, action: PayloadAction<keyof User['settings']['viewed_tutorials']>) => {
            state.toursRunning = state.toursRunning.filter((tour) => tour !== action.payload)
        },
        disableGuideButtons: (state) => {
            state.guideButtonsDeactivated = true
        },
        enableGuideButtons: (state) => {
            state.guideButtonsDeactivated = false
        },
        setActiveTarget: (state, action: PayloadAction<GuideState['activeTarget']>) => {
            state.activeTarget = action.payload
        },
    },
})

export const getToursRunning: Selector<HmstrState, GuideState['toursRunning']> = (state) => state.guides.toursRunning
export const getGuideButtonsDeactivated: Selector<HmstrState, GuideState['guideButtonsDeactivated']> = (state) => state.guides.guideButtonsDeactivated
export const getActiveTarget: Selector<HmstrState, GuideState['activeTarget']> = (state) => state.guides.activeTarget

export const getTourRunning = createSelector([getToursRunning], (toursRunning): keyof User['settings']['viewed_tutorials'] | undefined => {
    const order: Partial<keyof User['settings']['viewed_tutorials']>[] = orderedAvailableTours
    return _.chain(toursRunning)
        .map((tour) => ({ name: tour, priority: indexOf(order, tour) }))
        .sortBy('priority', 'asc')
        .first()
        .get('name')
        .value() as keyof User['settings']['viewed_tutorials'] | undefined
})

export const { startTour, endTour, disableGuideButtons, enableGuideButtons, setActiveTarget } = GuideSlice.actions
export const GuideReducer = GuideSlice.reducer
