import * as React from 'react'
import { FC, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getSortedTagsData } from '../../core/slices/DataSlice'
import _ from 'lodash'
import { Tag } from '../../tags/Tag'
import { Box, Typography, useTheme } from '@mui/material'
import { textColorBasedOnBackground } from '../../core/theme/helper'
import { PostGroup } from '../post-groups/PostGroup'
import { getIsMobile } from '../../core/slices/CoreSlice'

type PostTagsListProps = { postGroup: PostGroup; displayTags?: boolean }

export const PostTagsList: FC<PostTagsListProps> = ({ postGroup, displayTags }) => {
    const theme = useTheme()
    const tags = useSelector(getSortedTagsData)
    const isWindows = navigator.userAgent.toLowerCase().includes('windows')
    const isMobile = useSelector(getIsMobile)
    const tagsForPost = _.map(postGroup.tags, (tag) => {
        return tags.find((t) => t.id === tag)
    }).filter((d) => d) as Tag[]

    const tagContainerRef = useRef<any>(null)

    const handleScroll = (event: WheelEvent) => {
        if (tagContainerRef.current) {
            const scrolledToEnd = tagContainerRef.current.scrollLeft >= tagContainerRef.current.scrollWidth - tagContainerRef.current.offsetWidth - 1
            const scrolledToStart = tagContainerRef.current.scrollLeft === 0
            if (!(scrolledToEnd && event.deltaY > 0) && !(scrolledToStart && event.deltaY < 0)) {
                event.preventDefault()
                // noinspection JSSuspiciousNameCombination
                tagContainerRef.current.scrollLeft += event.deltaY
            }
        }
    }

    useEffect(() => {
        const containerRef = tagContainerRef.current
        if (containerRef && isWindows) {
            containerRef.addEventListener('wheel', handleScroll)
        }
        return () => {
            if (containerRef && isWindows) {
                containerRef.removeEventListener('wheel', handleScroll)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWindows])

    const renderTag = (tag: Tag) => {
        const textColor = textColorBasedOnBackground(tag.color)
        return (
            <Box
                bgcolor={tag.color}
                key={tag.id}
                sx={{
                    py: displayTags ? 0.2 : 0,
                    px: displayTags ? 1 : 0,
                    flexBasis: 32,
                    borderRadius: displayTags && !isMobile ? 1 : 20,
                    boxShadow: displayTags ? theme.shadows[2] : undefined,
                    transition: displayTags ? 'all 0.15s' : 'all 0.2s 0.1s ease-out',
                }}
            >
                <Typography
                    variant={'body2'}
                    width={'fit-content'}
                    sx={{
                        whiteSpace: 'nowrap',
                        fontSize: displayTags ? undefined : 0,
                        minWidth: 16,
                        minHeight: 8,
                        transition: displayTags ? 'all 0.15s' : 'all 0.2s 0.1s ease-out',
                        color: displayTags ? textColor : 'transparent',
                    }}
                >
                    {tag.label}
                </Typography>
            </Box>
        )
    }
    return (
        <Box
            display={tagsForPost.length > 0 ? 'flex' : 'none'}
            flexWrap={'nowrap'}
            flexDirection={'column'}
            px={1}
            height={40}
            sx={{
                overflowY: 'hidden',
            }}
            overflow={'scroll'}
            ref={tagContainerRef}
        >
            <Box flexGrow={1} />
            <Box display={'flex'} gap={1} pb={2}>
                {tagsForPost.map((t) => renderTag(t))}
                <Box p={0.1}></Box>
            </Box>
        </Box>
    )
}
