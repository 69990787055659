import { FC } from 'react'
import { CalendarGrid } from './CalendarGrid'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedDatasourceIds, getSelectedEndDate, getSelectedStartDate } from '../PublishingSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { getSelectedProject } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { replaceUrlParams } from '../../core/helpers/replace-url-params'
import { fetchTagsForProject } from '../../tags/TagsActions'
import { fetchCommonPostData } from '../../content-analytics/posts/PostsActions'
import _ from 'lodash'
import { fetchPostGroups } from '../post-groups/PostGroupActions'

type PublishCalendarProps = {}

export const Calendar: FC<PublishCalendarProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedProject = useSelector(getSelectedProject)
    const startDate = useSelector(getSelectedStartDate)
    const endDate = useSelector(getSelectedEndDate)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTagsForProject(selectedProject))
        }
    }, [selectedProject])

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(
                fetchPostGroups({
                    project: selectedProject,
                    data_source_ids: selectedDatasourceIds,
                    since: startDate,
                    until: endDate,
                })
            )
            dispatch(
                fetchCommonPostData({
                    since: startDate,
                    until: endDate,
                    data_source_ids: _.map(selectedProject.data_sources, (ds) => ds.id),
                })
            )
        }
    }, [selectedProject, startDate, endDate])

    useEffectWithIdComparison(() => {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.set('startDate', startDate)
        urlParams.set('endDate', endDate)
        if (selectedDatasourceIds.length > 0) {
            urlParams.set('selected_datasources', selectedDatasourceIds.join(','))
        }
        replaceUrlParams(urlParams)
    }, [startDate, endDate, selectedDatasourceIds.toString()])

    return (
        <Box width="100%" height="100%" sx={{ overflowX: 'auto' }}>
            <CalendarGrid selectedStartDate={startDate} />
        </Box>
    )
}
