import { Step } from 'react-joyride'

export const primarySidebarTourSteps: Step[] = [
    {
        target: '#primary_nav_publishing',
        content: 'guide.publishing-tab.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#primary_nav_engagement',
        content: 'guide.engagement-tab.content',
        placement: 'right',
    },
    {
        target: '#primary_nav_analytics',
        content: 'guide.analytics-tab.content',
        placement: 'right',
    },
    {
        target: '#primary_nav_settings',
        content: 'guide.settings-tab.content',
        placement: 'right',
    },
    {
        target: '#primary_nav_user',
        content: 'guide.user-tab.content',
        placement: 'top',
    },
]

export const secondarySidebarTourSteps: Step[] = [
    {
        target: '#secondary_nav_switcher',
        content: 'guide.account-switcher.content',
        placement: 'right',
        disableBeacon: true,
    },
]

export const publishingFormTourSteps: Step[] = [
    {
        target: '#publishing_tagging_button',
        content: 'guide.publishing-form.tags.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#publishing_post_type_select',
        content: 'guide.publishing-form.post-type.content',
        placement: 'right',
    },
    {
        target: '#publishing_actions_button',
        content: 'guide.publishing-form.actions.content',
        placement: 'top',
    },
]

export const publishingIndividualizeTourSteps: Step[] = [
    {
        target: '#individualize_networks_button',
        content: 'guide.publishing-form.individualize.content',
        title: 'guide.publishing-form.individualize.title',
        placement: 'top',
        disableBeacon: true,
    },
]
export const engagementTourSteps: Step[] = [
    {
        target: '#inbox_tab',
        content: 'guide.engagement-inbox.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#archive_tab',
        content: 'guide.engagement-archive.content',
        placement: 'right',
        disableBeacon: true,
    },
]

export const settingsTourSteps: Step[] = [
    {
        target: '#datasources_tab',
        content: 'guide.settings-datasources.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#project_tab',
        content: 'guide.settings-project.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#linked_accounts_tab',
        content: 'guide.settings-linked-accounts.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#interface_tab',
        content: 'guide.settings-interface.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#darkmode_switch',
        content: 'guide.settings-interface-darkmode.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#subscription_tab',
        content: 'guide.settings-subscription.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#invoice_tab',
        content: 'guide.settings-invoice.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#users_tab',
        content: 'guide.settings-user-management.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#projects_tab',
        content: 'guide.settings-manage-projects.content',
        placement: 'right',
        disableBeacon: true,
    },
    {
        target: '#create_account_button',
        content: 'guide.settings-create-account.content',
        placement: 'top',
        disableBeacon: true,
    },
]
