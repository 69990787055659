import { FC, useRef, useState } from 'react'
import { Box, FormControl, IconButton, InputLabel, MenuItem, Popover, Typography, useTheme } from '@mui/material'
import moment, { Moment } from 'moment'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { DatepickerField, StyledDateRangeComponent, StyledDateRangePickerComponent } from './DateRangePicker.styles'
import { DateRangePickerStaticRanges } from './DateRangePickerRanges'
import { RangeKeyDict, StaticRange } from 'react-date-range'
import { FormattedMessage, useIntl } from 'react-intl'

// @ts-ignore
import * as locales from 'react-date-range/dist/locale'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css'
import { checkStaticValues } from '../../resources/translations/Helper'
import { useSelector } from 'react-redux'
import { getIsMobile } from '../../core/slices/CoreSlice'

type DateRangePickerProps = {
    label?: string
    startDate: string | Moment
    endDate: string | Moment
    onChange: (startDate: string, endDate: string, staticRange?: string) => any
    strict?: boolean
    variant?: 'filled' | 'standard' | 'outlined' | undefined
    size?: 'small' | 'medium' | undefined
    disabled?: boolean
    fullWidth?: boolean
    open?: boolean
    onClose?: () => void
    onOpen?: () => void
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
    label = 'filters.timeperiod',
    startDate,
    endDate,
    onChange,
    strict = false,
    variant,
    size,
    disabled,
    fullWidth,
    open,
    onOpen,
    onClose,
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const staticRanges = DateRangePickerStaticRanges(intl, strict)
    const isMobile = useSelector(getIsMobile)
    const [internalOpen, setInternalOpen] = useState(false)

    const datePickerFieldRef = useRef<any>(null)

    const handleOpen = () => {
        if (onOpen) {
            onOpen()
        } else {
            setInternalOpen(true)
        }
    }
    const handleClose = () => {
        if (onClose) {
            onClose()
        } else {
            setInternalOpen(false)
        }
    }
    const selectionRange = {
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
        key: 'selection',
    }

    const handleChange = (onChangeProps: RangeKeyDict) => {
        onChange(
            moment(onChangeProps.selection.startDate).startOf('day').format(),
            moment(onChangeProps.selection.endDate).endOf('day').format(),
            checkStaticValues(onChangeProps.selection.startDate, onChangeProps.selection.endDate, intl, strict)?.id
        )
    }

    const navigatorRenderer = (currentFocusedDate: Date, changeShownDate: (shownDate: Date) => void) => {
        return (
            <Box display="flex" justifyContent="space-between" padding={1} alignItems="center">
                <IconButton size="small" onClick={() => changeShownDate(moment(currentFocusedDate).subtract(1, 'month').toDate())}>
                    <ChevronLeft color="action" />
                </IconButton>

                <Typography variant="subtitle2" color="textPrimary">
                    {moment(currentFocusedDate).format('MMMM YYYY')}
                </Typography>

                <IconButton size="small" onClick={() => changeShownDate(moment(currentFocusedDate).add(1, 'month').toDate())}>
                    <ChevronRight color="action" />
                </IconButton>
            </Box>
        )
    }

    const renderValue = () => {
        let staticMatch = checkStaticValues(selectionRange.startDate, selectionRange.endDate, intl, strict)
        if (staticMatch) {
            return staticMatch.label
        }
        return moment(startDate).format('l') + ' - ' + moment(endDate).format('l')
    }

    return (
        <>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel variant={variant} shrink>
                    <FormattedMessage id={label} />
                </InputLabel>
                <DatepickerField
                    ref={datePickerFieldRef}
                    disabled={disabled}
                    className={Boolean(datePickerFieldRef.current) && open ? 'Mui-focused' : undefined}
                    expanded={Boolean(datePickerFieldRef.current) && Boolean(open)}
                    open={false}
                    label={<FormattedMessage id={label} />}
                    value="value"
                    fullWidth={fullWidth}
                    onClick={() => handleOpen()}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    variant={variant}
                    size={size}
                    style={
                        variant === 'outlined'
                            ? {
                                  height: '100%',
                                  width: '100%',
                              }
                            : { height: '100%' }
                    }
                >
                    <MenuItem value="value">{renderValue()}</MenuItem>
                </DatepickerField>
            </FormControl>

            <Popover
                open={(Boolean(datePickerFieldRef.current) && !!open) || internalOpen}
                anchorEl={datePickerFieldRef.current}
                onClose={() => handleClose()}
                anchorOrigin={{ vertical: 'bottom', horizontal: isMobile ? 'center' : 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: isMobile ? 'center' : 'right' }}
                disablePortal
            >
                {isMobile ? (
                    <StyledDateRangeComponent
                        color={theme.palette.secondary.main}
                        rangeColors={[theme.palette.secondary.main]}
                        ranges={[selectionRange]}
                        onChange={(changeProps: RangeKeyDict) => handleChange(changeProps)}
                        staticRanges={staticRanges}
                        renderStaticRangeLabel={(range: StaticRange) => (
                            <Typography onClick={() => handleClose()} variant="body2">
                                {range.label}
                            </Typography>
                        )}
                        inputRanges={[]}
                        weekStartsOn={1}
                        navigatorRenderer={navigatorRenderer}
                        showMonthAndYearPickers={false}
                        showDateDisplay={true}
                        locale={locales['de']}
                    />
                ) : (
                    <StyledDateRangePickerComponent
                        color={theme.palette.secondary.main}
                        rangeColors={[theme.palette.secondary.main]}
                        ranges={[selectionRange]}
                        onChange={(changeProps: RangeKeyDict) => handleChange(changeProps)}
                        staticRanges={staticRanges}
                        renderStaticRangeLabel={(range: StaticRange) => (
                            <Typography onClick={() => handleClose()} variant="body2">
                                {range.label}
                            </Typography>
                        )}
                        inputRanges={[]}
                        weekStartsOn={1}
                        navigatorRenderer={navigatorRenderer}
                        showMonthAndYearPickers={false}
                        showDateDisplay={false}
                        locale={locales['de']}
                    />
                )}
            </Popover>
        </>
    )
}
