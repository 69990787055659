import { FC, useEffect, useState } from 'react'
import * as _ from 'lodash'
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    Tooltip,
} from '@mui/material'
import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js'
import { PaymentMethod, Stripe, StripeElements } from '@stripe/stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { closeFullscreenDialog, showErrorSnackbar, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { getPaymentMethods, IdMap } from '../../core/slices/DataSlice'
import { FormattedMessage, useIntl } from 'react-intl'
import { BillingPaymentMethodItem } from '../billing/BillingPaymentMethodItem'
import { fetchCurrentUser } from '../user-management/UserActions'
import { stripePromise } from '../../index'
import { HmstrDispatch } from '../../core/Store'
import { AddCard, Info, Save } from '@mui/icons-material'
import { fetchAccount } from './AccountActions'
import { Account } from './Account'

type AccountPaymentProps = {
    clientSecret: string
    customerId: string
    account?: Account
}

export const AccountPaymentForm: FC<AccountPaymentProps> = ({ clientSecret, customerId, account }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const allPaymentMethods = useSelector(getPaymentMethods)
    const paymentMethods = _.mapKeys(
        _.values(allPaymentMethods).filter((pm) => pm.customer === customerId),
        'id'
    ) as IdMap<PaymentMethod>
    const defaultPaymentMethod = Object.values(paymentMethods).find((pm) => pm.default)
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | undefined>(defaultPaymentMethod?.id)
    const [newPaymentMethod, setNewPaymentMethod] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        if (Object.values(paymentMethods).length === 0 && !selectedPaymentMethodId) {
            setNewPaymentMethod(true)
        } else {
            setNewPaymentMethod(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async (stripeObj: Stripe | null, elements: StripeElements | null) => {
        setSubmitting(true)

        async function processPayment(stripe: Stripe) {
            let result: any

            if (selectedPaymentMethodId) {
                const selectedPaymentMethod = paymentMethods[selectedPaymentMethodId]

                switch (selectedPaymentMethod.type) {
                    case 'sepa_debit':
                        result = await stripe.confirmSepaDebitPayment(clientSecret, {
                            payment_method: selectedPaymentMethodId,
                            return_url: window.location.href,
                        })

                        break
                    case 'card':
                    default:
                        result = await stripe.confirmCardPayment(clientSecret, {
                            payment_method: selectedPaymentMethodId,
                            return_url: window.location.href,
                        })
                }

                if (!result.error) {
                    dispatch(fetchCurrentUser())
                    dispatch(closeFullscreenDialog())
                    if (account) {
                        dispatch(fetchAccount(account._links.self))
                    }
                    dispatch(showSuccessSnackbar(intl.formatMessage({ id: 'accounts.payment-success' })))
                }
            } else if (newPaymentMethod && elements) {
                if (clientSecret && clientSecret.startsWith('pi')) {
                    result = await stripe.confirmPayment({
                        elements,
                        confirmParams: {
                            return_url: window.location.href,
                            save_payment_method: true,
                        },
                    })
                } else if (clientSecret && clientSecret.startsWith('seti')) {
                    result = await stripe.confirmSetup({
                        elements,
                        confirmParams: {
                            return_url: window.location.href,
                        },
                    })
                }
            }

            if (result && result.error) {
                dispatch(showErrorSnackbar(result.error.message || intl.formatMessage({ id: 'hint.account.payment-failed-default-message' })))
            }
        }

        if (stripeObj) {
            await new Promise((resolve) => {
                processPayment(stripeObj).then(resolve)
            })
        }

        setSubmitting(false)
    }

    const onSelect = (paymentMethod: PaymentMethod) => {
        setSelectedPaymentMethodId(paymentMethod.id)
        setNewPaymentMethod(false)
    }

    return (
        <>
            <Box p={2} pt={4}>
                {!newPaymentMethod && !selectedPaymentMethodId && (
                    <Alert color="info" icon={<Info />}>
                        <FormattedMessage id="billing.no-cost-until-end-of-trial" />
                    </Alert>
                )}
            </Box>

            <List dense={true} sx={{ mb: 2, pl: 2 }}>
                {Object.values(paymentMethods).map((pm) => (
                    <BillingPaymentMethodItem key={pm.id} paymentMethod={pm}>
                        <ListItemSecondaryAction>
                            <Radio checked={pm.id === selectedPaymentMethodId} onClick={() => onSelect(pm)} />
                        </ListItemSecondaryAction>
                    </BillingPaymentMethodItem>
                ))}

                <ListItem key="new" sx={{ pt: 2 }}>
                    <ListItemIcon>
                        <AddCard />
                    </ListItemIcon>

                    <ListItemText>
                        <FormattedMessage id="billing.payment-methods.add" />
                    </ListItemText>

                    <ListItemSecondaryAction>
                        <Radio
                            checked={newPaymentMethod}
                            onClick={() => {
                                setNewPaymentMethod(true)
                                setSelectedPaymentMethodId(undefined)
                            }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            {clientSecret && clientSecret !== 'REDACTED' && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    {newPaymentMethod && (
                        <Box p={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <PaymentElement />
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    <ElementsConsumer>
                        {({ stripe, elements }) => (
                            <DialogActions sx={{ mt: 2, pr: 0 }}>
                                <Tooltip
                                    title={!newPaymentMethod && !selectedPaymentMethodId ? <FormattedMessage id="billing.please-select-payment-method" /> : ''}
                                >
                                    <span>
                                        <Button
                                            startIcon={submitting ? <CircularProgress color="inherit" size={20} /> : <Save />}
                                            disabled={submitting || (!newPaymentMethod && !selectedPaymentMethodId)}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleSubmit(stripe, elements)}
                                        >
                                            <FormattedMessage id="general.save" />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </DialogActions>
                        )}
                    </ElementsConsumer>
                </Elements>
            )}
        </>
    )
}
