import { FC, PropsWithChildren } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import hmstrOnly from '../../resources/images/hmstr-only.png'
import { useSelector } from 'react-redux'
import { getIsMobile } from '../../core/slices/CoreSlice'
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints'

type HmstrAsideLayoutProps = {
    sideBreakpoint?: Breakpoint
    sideContent?: JSX.Element
}

export const HmstrAsideLayout: FC<PropsWithChildren<HmstrAsideLayoutProps>> = ({ children, sideContent, sideBreakpoint }) => {
    const theme = useTheme()
    const displaySideContent = useMediaQuery(theme.breakpoints.up(sideBreakpoint || 'lg'))
    const isMobileDevice = useSelector(getIsMobile)
    const showHmstrInAside = useMediaQuery('(min-height:950px)')

    return (
        <Box height="100dvh" width="100vw" display="flex">
            {displaySideContent && (
                <Box sx={{ backgroundColor: 'primary.main', height: '100dvh', width: 400, minWidth: 400 }}>
                    {sideContent && (
                        <Box minHeight={showHmstrInAside ? '60vh' : '100vh'} display="flex" alignItems="center">
                            <Box px={4}>{sideContent}</Box>
                        </Box>
                    )}
                    {showHmstrInAside && <img alt="hmstr-sidebar-background" style={{ position: 'absolute', bottom: -128, left: -128 }} src={hmstrOnly} />}
                </Box>
            )}

            <Box
                sx={
                    isMobileDevice
                        ? {
                              maxWidth: '100dvw',
                              height: '100dvh',
                              position: 'fixed',
                              top: '0',
                              bottom: '0',
                              flexGrow: '1',
                              overflowX: 'auto',
                          }
                        : {
                              maxWidth: displaySideContent ? 'calc(100dvw - 400px)' : undefined,
                              flexGrow: 1,
                              overflowX: 'auto',
                              height: '100dvh',
                          }
                }
            >
                {children}
            </Box>
        </Box>
    )
}
