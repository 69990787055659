import { disableGuideButtons, enableGuideButtons, setActiveTarget } from './GuideSlice'
import { toggleDarkMode } from '../../core/slices/CoreSlice'
import { CallBackProps } from 'react-joyride'

export const handleJoyrideStep = (actionType: CallBackProps['type'], stepTarget: CallBackProps['step']['target'], navigate: any, dispatch: any) => {
    if (actionType === 'step:before') {
        dispatch(setActiveTarget(stepTarget as string))
    }
    if (actionType === 'step:after') {
        dispatch(setActiveTarget(undefined))
    }

    if (actionType === 'step:before' && stepTarget === '#primary_nav_publishing') {
        navigate('/publishing/calendar')
    }
    if (actionType === 'step:before' && stepTarget === '#primary_nav_engagement') {
        navigate('/engagement/tickets/new')
    }
    if (actionType === 'step:before' && stepTarget === '#primary_nav_analytics') {
        navigate('/analytics/dashboards')
    }
    if (actionType === 'step:before' && stepTarget === '#primary_nav_settings') {
        navigate('/settings/data-sources')
    }
    if (actionType === 'step:before' && stepTarget === '#inbox_tab') {
        navigate('/engagement/tickets/new')
    }
    if (actionType === 'step:before' && stepTarget === '#archive_tab') {
        navigate('/engagement/tickets/archived')
    }
    if (actionType === 'step:after' && stepTarget === '#archive_tab') {
        navigate('/engagement/tickets/new')
    }
    if (actionType === 'step:before' && stepTarget === '#datasources_tab') {
        navigate('/settings/data-sources')
    }
    if (actionType === 'step:before' && stepTarget === '#project_tab') {
        navigate('/settings/project-settings')
    }
    if (actionType === 'step:before' && stepTarget === '#linked_accounts_tab') {
        navigate('/settings/linked-accounts')
    }
    if (actionType === 'step:before' && stepTarget === '#interface_tab') {
        navigate('/settings/display')
    }
    if (actionType === 'step:before' && stepTarget === '#darkmode_switch') {
        dispatch(disableGuideButtons())
        setTimeout(() => {
            dispatch(toggleDarkMode())
        }, 1000)
        setTimeout(() => {
            dispatch(toggleDarkMode())
            dispatch(enableGuideButtons())
        }, 2000)
    }
    if (actionType === 'step:before' && stepTarget === '#subscription_tab') {
        navigate('/settings/account-settings')
    }
    if (actionType === 'step:before' && stepTarget === '#invoice_tab') {
        navigate('/settings/billing/general')
    }
    if (actionType === 'step:before' && stepTarget === '#users_tab') {
        navigate('/settings/manage-users')
    }
    if (actionType === 'step:before' && stepTarget === '#projects_tab') {
        navigate('/settings/projects')
    }
}
