import moment from 'moment'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { HmstrState } from '../../core/Store'
import { doGet, doPut } from '../../core/api/ApiClient'
import { FacebookPostData } from './FacebookPostData'
import { InstagramPostData } from './InstagramPostData'
import { CommonPostData } from './CommonPostData'
import { Project } from '../../settings/project-management/Project'
import _ from 'lodash'

const POSTS_SLASH = (action: string) => `posts/${action}`

type FetchPostDataForDatasourcesArgs = {
    data_source_ids: string[]
    since?: string
    until?: string
}
type FetchPostDataForPostIdArgs = {
    data_source_ids: string[]
    post_ids: string[]
}

type FetchPostDataForDatasourceArgs = {
    datasource: ProjectDatasource
    since?: string
    until?: string
}

export type FacebookPostTagData = {
    _id: string
    post_id: string
    tag_ids: string[]
    data_source_id: string
}

export const fetchCommonPostData = createAsyncThunk<CommonPostData[], FetchPostDataForDatasourcesArgs, { state: HmstrState }>(
    POSTS_SLASH('fetchCommonPostData'),
    async ({ data_source_ids, since, until }, thunkAPI) => {
        const projectId = thunkAPI.getState().core.selectedProjectId || ''
        const selectedProject = thunkAPI.getState().data.projects[projectId] as Project

        const startDate = moment(since).startOf('day').format()
        const endDate = moment(until).endOf('day').format()

        if (data_source_ids.length === 0) {
            return []
        }

        return await doGet<CommonPostData[]>(thunkAPI, selectedProject._links.posts, undefined, {
            params: { since: startDate, until: endDate, data_source_ids: data_source_ids.join(',') },
        })
    }
)

export const fetchCommonPostDataByPostIds = createAsyncThunk<CommonPostData[], FetchPostDataForPostIdArgs, { state: HmstrState }>(
    POSTS_SLASH('fetchCommonPostDataByPostIds'),
    async ({ data_source_ids, post_ids }, thunkAPI) => {
        const projectId = thunkAPI.getState().core.selectedProjectId || ''
        const selectedProject = thunkAPI.getState().data.projects[projectId] as Project

        if (data_source_ids.length === 0) {
            return []
        }

        return await doGet<CommonPostData[]>(thunkAPI, selectedProject._links.posts_by_id, undefined, {
            params: { post_ids_query: _.uniq(post_ids).join(','), data_source_ids: _.uniq(data_source_ids).join(',') },
        })
    }
)

export const fetchFacebookPostDataForDatasource = createAsyncThunk<FacebookPostData[], FetchPostDataForDatasourceArgs, { state: HmstrState }>(
    POSTS_SLASH('fetchFacebookPostData'),
    async ({ datasource, since, until }, thunkAPI) => {
        return await doGet(thunkAPI, datasource._links.facebook_posts.href, undefined, {
            params: { since, until },
        })
    }
)

export const fetchInstagramPostDataForDatasource = createAsyncThunk<InstagramPostData[], FetchPostDataForDatasourceArgs, { state: HmstrState }>(
    POSTS_SLASH('fetchInstagramPostData'),
    async ({ datasource, since, until }, thunkAPI) => {
        return await doGet(thunkAPI, datasource._links.instagram_media.href, undefined, {
            params: { since, until },
        })
    }
)

export const updateFacebookPostsData = createAsyncThunk<FacebookPostTagData[], { project: Project; postData: FacebookPostTagData[] }, { state: HmstrState }>(
    POSTS_SLASH('updateFacebookPosts'),
    async (params, thunkAPI) => {
        await doPut(thunkAPI, params.project._links.post_tags, params.postData)
        return params.postData
    }
)
