import * as _ from 'lodash'

export const replaceMentions = (
    text: string,
    htmlColor?: string,
    options?: {
        bold?: boolean
        disableCursorPointer?: boolean
        paddingInline?: string
        backgroundColor?: string
        border?: string
        borderRadius?: string
    }
): string => {
    let newText = _.clone(text)
    const mentions = text.match(/@\[[^[\]]*]\([^()]*\)/g) || []

    mentions.forEach((mention) => {
        const mentionDisplayName = mention.match(/^@\[.*]/g)
        if (mentionDisplayName && mentionDisplayName.length > 0) {
            const replaceText = mentionDisplayName[0].slice(2, -1)

            if (htmlColor) {
                newText = newText.replace(
                    mention,
                    `<span style="color: ${htmlColor}; cursor: ${options?.disableCursorPointer ? 'unset' : 'pointer'}; font-weight: ${
                        options?.bold ? '600' : 'inherit'
                    }; background: ${options?.backgroundColor || 'transparent'}; padding-inline: ${options?.paddingInline || 'unset'}; border: ${
                        options?.border || 'unset'
                    }; border-radius: ${options?.borderRadius || 'unset'};">${replaceText}</span>`
                )
            } else {
                newText = newText.replace(mention, '@' + replaceText)
            }
        }
    })

    return newText
}
