import * as React from 'react'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import {
    alpha,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    IconButton,
    keyframes,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    ThemeProvider,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import TableHead from '@mui/material/TableHead'
import { useDispatch, useSelector } from 'react-redux'
import {
    getCurrentUser,
    getDatasourcesAsIdMap,
    getIsMobile,
    getSelectedProject,
    getSimpleUsersForSelectedProject,
    showErrorSnackbar,
    showSuccessSnackbar,
} from '../core/slices/CoreSlice'
import {
    decrementOpenTicketCountForProject,
    getCommonPostsByPostId,
    getCurrentInboxPage,
    getEntriesPerPage,
    getLoadingSimpleTickets,
    getOpenTicketCountByProjectByCategory,
    getSelectedDatasourceIds,
    incrementTicketCountForProject,
    selectInboxPage,
    selectTicketId,
    setEntriesPerPage,
} from './EngagementSlice'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { getIconForDatasourceType } from '../settings/datasources/DatasourceTypeMappings'
import {
    Archive,
    ArrowForwardIos,
    ChatBubbleOutline,
    History,
    KeyboardDoubleArrowUp,
    Link,
    Message,
    MoveToInbox,
    PhotoCamera,
    PhotoLibrary,
    QuestionAnswer,
    QuestionMark,
    SwipeDownOutlined,
    Videocam,
} from '@mui/icons-material'
import { CommonPostData } from '../content-analytics/posts/CommonPostData'
import { SimpleTicket } from './SimpleTicket'
import { HmstrDispatch } from '../core/Store'
import { renderTicketId } from './renderTicketId'
import { TicketState } from './TicketState'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { useNavigate } from 'react-router-dom'
import { UserAvatar } from '../common/avatars/UserAvatar'
import { TicketCardMobile } from './TicketCardMobile'
import { fetchOpenTicketCountForUserByCategory, fetchSimpleTicketsForProject, updateMultipleTickets, UpdateTicketRequest } from './EngagementActions'
import { Project } from '../settings/project-management/Project'
import _, { clamp } from 'lodash'
import { TicketCardMobileLoader } from './TicketCardMobileLoader'
import { Loading } from '../common/loading/Loading'
import { useHasAnyDatasourcePermission } from '../core/hooks/useHasPermission'
import { AssigneeDropdown } from '../common/assignee-dropdown/AssigneeDropdown'
import { SimpleUser } from '../settings/user-management/SimpleUser'
import { createHmstrTheme } from '../core/theme/hmstr-theme'

type TicketTableProps = {
    tickets: SimpleTicket[]
    state: TicketState
    emptyState: JSX.Element
    tab: 'mine' | 'others' | 'archived' | 'new'
}

export const TicketTable: FC<TicketTableProps> = ({ tickets, state, emptyState, tab }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const darkTheme = createHmstrTheme('dark')
    const intl = useIntl()
    const posts = useSelector(getCommonPostsByPostId)
    const navigate = useNavigate()
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)
    const simpleUsers = useSelector(getSimpleUsersForSelectedProject)
    const page = useSelector(getCurrentInboxPage)
    const rowsPerPage = useSelector(getEntriesPerPage)
    const project = useSelector(getSelectedProject)
    const isLoadingTickets = useSelector(getLoadingSimpleTickets)
    const [isLoadingTicketsDelayed, setIsLoadingTicketsDelayed] = useState(false)
    const [finishAnimations, setFinishAnimations] = useState(false)
    const [scrollOffset, setScrollOffset] = useState(0)
    const [startedScrollingSideways, setStartedScrollingSideways] = useState(false)
    const ticketContainerRef = useRef<any>(null)
    const isMobile = useSelector(getIsMobile)
    const ticketCountsForProject = useSelector(getOpenTicketCountByProjectByCategory)
    const ticketCountForTab = ticketCountsForProject[tab]
    const [selectedTicketIds, setSelectedTicketIds] = useState<any[]>([])
    const selectedTickets = tickets.filter((t) => selectedTicketIds.includes(t.id))
    const currentUser = useSelector(getCurrentUser)

    const shouldShowLastTickets = Math.ceil(tickets.length / rowsPerPage) * rowsPerPage >= ticketCountForTab

    const pullDownAnimation = keyframes`
        0% {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            box-shadow: 0 0 0 0 ${theme.palette.secondary.main}50, 0 0 0 0 ${theme.palette.secondary.main}50 inset;
        }
        100% {
            height: 100vmin;
            width: 100vmin;
            top: calc(-50vmin + 50%);
            left: calc(-50vmin + 50%);
            box-shadow: 0 0 15vmin 10vmin ${theme.palette.secondary.main}00, 0 0 15vmin 0 ${theme.palette.secondary.main}00 inset;
        }
    `

    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(selectInboxPage(newPage))
    }

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setEntriesPerPage(+event.target.value))
    }

    const handleOpenTicket = (ticket: SimpleTicket) => {
        if (finishAnimations && scrollOffset === 0) {
            dispatch(selectTicketId(ticket.id))
            navigate(ticket.id)
        }
    }

    const getIconForType = (ticket: SimpleTicket, post: CommonPostData | undefined) => {
        if (['POST', 'STORY'].includes(ticket.type)) {
            switch (post?.post_type?.toLowerCase()) {
                case 'link':
                    return (
                        <Tooltip title={<FormattedMessage id="engagement.ticket-type.link" />}>
                            <Link color="action" />
                        </Tooltip>
                    )
                case 'photo':
                case 'image':
                    return (
                        <Tooltip title={<FormattedMessage id={'engagement.ticket-type.' + post?.post_type?.toLowerCase()} />}>
                            <PhotoCamera color="action" />
                        </Tooltip>
                    )
                case 'album':
                case 'carousel_album':
                    return (
                        <Tooltip title={<FormattedMessage id={'engagement.ticket-type.' + post?.post_type?.toLowerCase()} />}>
                            <PhotoLibrary color="action" />
                        </Tooltip>
                    )
                case 'video':
                    return (
                        <Tooltip title={<FormattedMessage id="engagement.ticket-type.video" />}>
                            <Videocam color="action" />
                        </Tooltip>
                    )
                case 'status':
                    return (
                        <Tooltip title={<FormattedMessage id="engagement.ticket-type.status" />}>
                            <Message color="action" />
                        </Tooltip>
                    )
                default:
                    return ticket.type === 'STORY' ? (
                        <Tooltip title={<FormattedMessage id="engagement.ticket-type.story" />}>
                            <History color="action" />
                        </Tooltip>
                    ) : (
                        <Tooltip title={<FormattedMessage id="engagement.ticket-type.unkown" />}>
                            <QuestionMark fontSize="small" color="action" />
                        </Tooltip>
                    )
            }
        } else if (ticket.type === 'MESSAGE') {
            return (
                <Tooltip title={<FormattedMessage id="engagement.ticket-type.message" />}>
                    <QuestionAnswer color="action" />
                </Tooltip>
            )
        } else {
            return (
                <Tooltip title={<FormattedMessage id="engagement.ticket-type.status" />}>
                    <QuestionMark fontSize="small" color="action" />
                </Tooltip>
            )
        }
    }

    const rubberBandValue = startedScrollingSideways
        ? 0
        : scrollOffset === 0
        ? 0
        : scrollOffset === 60 && isLoadingTicketsDelayed
        ? 60
        : scrollOffset / (Math.sqrt(scrollOffset) / 5)

    const handleRefresh = (overrideTab?: 'mine' | 'others' | 'archived' | 'new' | undefined, refreshCurrentTabAfterwards?: boolean) => {
        if (project) {
            dispatch(
                fetchOpenTicketCountForUserByCategory({
                    datasource_ids: selectedDatasourceIds,
                    project: project,
                })
            )
            dispatch(
                fetchSimpleTicketsForProject({
                    project: project as Project,
                    page: page,
                    tab: overrideTab || tab,
                    entriesPerPage: rowsPerPage,
                    datasource_ids: selectedDatasourceIds,
                })
            ).then(() => {
                if (refreshCurrentTabAfterwards) {
                    dispatch(
                        fetchSimpleTicketsForProject({
                            project: project as Project,
                            page: page,
                            tab: tab,
                            entriesPerPage: rowsPerPage,
                            datasource_ids: selectedDatasourceIds,
                        })
                    )
                }
            })
        }
    }

    useEffect(() => {
        let touchstartY = 0
        let touchDiff = 0
        const handleTouchMove = (e: TouchEvent) => {
            if (ticketContainerRef.current?.getBoundingClientRect().x < 8 || ticketContainerRef.current?.getBoundingClientRect().x > 8) {
                setStartedScrollingSideways(true)
            }
            if (ticketContainerRef.current?.getBoundingClientRect().top === 64 && !startedScrollingSideways) {
                const touchY = e.touches[0].clientY
                touchDiff = clamp(touchY - touchstartY, 0, 1000)
                setScrollOffset(touchDiff)
                e.preventDefault()
                e.stopPropagation()
            }
        }

        const handleTouchEnd = (e: TouchEvent) => {
            if (startedScrollingSideways) {
                setTimeout(() => {
                    if (ticketContainerRef.current?.getBoundingClientRect().x > 0 && ticketContainerRef.current?.getBoundingClientRect().x < 20)
                        setStartedScrollingSideways(false)
                    setScrollOffset(0)
                }, 300)
            }
            const rubberBandValueInternal = startedScrollingSideways ? 0 : touchDiff === 0 ? 0 : touchDiff === 60 ? 60 : touchDiff / (Math.sqrt(touchDiff) / 5)
            if (rubberBandValueInternal >= 60 && ticketContainerRef.current?.getBoundingClientRect().top === 64) {
                setIsLoadingTicketsDelayed(true)
                setFinishAnimations(false)
                setScrollOffset(60)
                handleRefresh()
            } else if (touchDiff > 2) {
                setScrollOffset(0)
                setFinishAnimations(false)
                setTimeout(() => {
                    setStartedScrollingSideways(false)
                }, 300)
                touchDiff = 0
            } else if (touchDiff <= 2) {
                setScrollOffset(0)
                setFinishAnimations(true)
                setTimeout(() => {
                    setStartedScrollingSideways(false)
                }, 300)
                touchDiff = 0
            }
            if (touchDiff > 2) {
                e.preventDefault()
                touchDiff = 0
            }
        }
        const handleTouchStart = (e: TouchEvent) => {
            setFinishAnimations(true)
            touchstartY = e.touches[0].clientY
        }
        document.addEventListener('touchstart', handleTouchStart)
        document.addEventListener('touchmove', handleTouchMove)
        document.addEventListener('touchend', handleTouchEnd)
        return () => {
            document.removeEventListener('touchstart', handleTouchStart)
            document.removeEventListener('touchmove', handleTouchMove)
            document.removeEventListener('touchend', handleTouchEnd)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingTicketsDelayed, startedScrollingSideways])

    useEffect(() => {
        if (!isLoadingTickets) {
            setFinishAnimations(false)
            setTimeout(() => {
                setScrollOffset(0)
                setIsLoadingTicketsDelayed(false)
                setTimeout(() => {
                    setFinishAnimations(true)
                }, 300)
            }, 500)
        }
    }, [isLoadingTickets])
    const renderAuthor = (ticket: SimpleTicket) => {
        return ticket.author?.name || intl.formatMessage({ id: 'engagement.anonymous' })
    }

    const allTicketsSelected = tickets.length === selectedTicketIds.length && tickets.length !== 0
    const isIndeterminate = 0 < selectedTicketIds.length && selectedTicketIds.length < tickets.length

    const handleSelectAllTickets = () => {
        if (isIndeterminate || selectedTicketIds.length === 0) {
            setSelectedTicketIds(tickets.map((t) => t.id))
        } else if (allTicketsSelected) {
            setSelectedTicketIds([])
        }
    }

    const canReopenTickets = useHasAnyDatasourcePermission('ticket.reopen')
    const canCloseTickets = useHasAnyDatasourcePermission('ticket.close')

    const handleTicketStateToggle = () => {
        const newTicketState = tab === 'archived' ? 'OPEN' : 'CLOSED'
        let updates: UpdateTicketRequest[] = []
        selectedTickets.forEach((ticket) => {
            const datasource = datasourcesById[ticket.data_source_id ?? '']
            updates.push({
                ...ticket,
                state: newTicketState,
                data_source_id: datasource.id,
                data_source_name: datasource.name,
                data_source_type: datasource.type,
            })
        })
        if (project) {
            dispatch(updateMultipleTickets({ project: project, updates: updates })).then((res) => {
                const modified_count = _.get(res.payload, 'modified_count')
                const success = _.get(res.payload, 'success')
                if (success) {
                    dispatch(showSuccessSnackbar(intl.formatMessage({ id: 'engagement.bulk-action-statechange-success' }, { amount: modified_count })))
                } else if (_.get(res.payload, 'status') === 403) {
                    dispatch(showErrorSnackbar('general.no-permission'))
                } else {
                    dispatch(showErrorSnackbar('engagement.bulk-action-error'))
                }
                if (newTicketState === 'CLOSED') {
                    dispatch(decrementOpenTicketCountForProject({ project_id: project.id, value: modified_count }))
                } else if (newTicketState === 'OPEN') {
                    dispatch(incrementTicketCountForProject({ project_id: project.id, value: modified_count }))
                }
                handleRefresh(tab === 'archived' ? 'new' : 'archived', true)
                setSelectedTicketIds([])
            })
        }
    }

    const handleMultipleAssign = (assignee: SimpleUser | undefined) => {
        let updates: UpdateTicketRequest[] = []
        const movedToTab = () => {
            if (assignee) {
                if (assignee.id === currentUser.id) {
                    return 'mine'
                } else if (assignee.id !== currentUser.id) {
                    return 'others'
                }
            } else {
                return 'new'
            }
        }
        selectedTickets.forEach((ticket) => {
            const datasource = datasourcesById[ticket.data_source_id ?? '']
            updates.push({
                ...ticket,
                state: 'OPEN',
                assignee_id: assignee?.id,
                data_source_id: datasource.id,
                data_source_name: datasource.name,
                data_source_type: datasource.type,
            })
        })
        if (project) {
            dispatch(updateMultipleTickets({ project: project, updates: updates })).then((res) => {
                const modified_count = _.get(res.payload, 'modified_count')
                const success = _.get(res.payload, 'success')
                if (success) {
                    dispatch(showSuccessSnackbar(intl.formatMessage({ id: 'engagement.bulk-action-assign-success' }, { amount: modified_count })))
                    const shush = 'please stop complaining typescript, this case could never happen.'
                    if (!['mine', 'new'].includes(tab) && ['mine', 'new'].includes(movedToTab() || shush)) {
                        dispatch(incrementTicketCountForProject({ project_id: project.id, value: modified_count }))
                    } else if (['mine', 'new'].includes(tab) && !['mine', 'new'].includes(movedToTab() || shush)) {
                        dispatch(decrementOpenTicketCountForProject({ project_id: project.id, value: modified_count }))
                    }
                    handleRefresh(movedToTab())
                } else if (_.get(res.payload, 'status') === 403) {
                    dispatch(showErrorSnackbar('general.no-permission'))
                    setSelectedTicketIds([])
                } else {
                    dispatch(showErrorSnackbar('engagement.bulk-action-error'))
                    setSelectedTicketIds([])
                }
            })
        }
    }

    return !isMobile ? (
        <Box display={'flex'} flexDirection={'column'} maxHeight={'100%'}>
            <TableContainer
                sx={{
                    width: '100%',
                    flexShrink: 1,
                    overflow: 'auto',
                    background: `
                        linear-gradient(${theme.palette.background.default} 30%,
                        rgba(255, 255, 255, 0)
                        ) center top,

                        /* Shadow Cover BOTTOM */
                        linear-gradient(
                        rgba(255, 255, 255, 0),
                        ${theme.palette.background.default} 70%
                        ) center bottom,

                        /* Shadow TOP */
                        radial-gradient(
                        farthest-side at 50% 20px,
                        ${alpha(theme.palette.getContrastText(theme.palette.background.default), 0.25)},
                        rgba(0, 0, 0, 0)
                        ) center top,

                        /* Shadow BOTTOM */
                        radial-gradient(
                        farthest-side at 50% 100%,
                        ${alpha(theme.palette.getContrastText(theme.palette.background.default), 0.2)},
                        rgba(0, 0, 0, 0)
                        ) center bottom`,

                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 90px, 100% 40px, 100% 44px, 100% 14px',
                    backgroundAttachment: 'local, local, scroll, scroll',
                    backgroundPosition: '0px 10px, 100% 100%, 0 10px, 100% 100%',
                }}
            >
                <Table stickyHeader={true} size="small">
                    <TableHead>
                        <TableRow
                            sx={{
                                '& th': {
                                    backgroundColor: darkTheme.palette.background.paper,
                                    color: darkTheme.palette.text.primary,
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                },
                                '& th:last-child': {
                                    borderTopRightRadius: theme.shape.borderRadius + 'px',
                                    borderBottomRightRadius: theme.shape.borderRadius + 'px',
                                },
                                '& th:first-of-type': {
                                    borderTopLeftRadius: theme.shape.borderRadius + 'px',
                                    borderBottomLeftRadius: theme.shape.borderRadius + 'px',
                                },
                            }}
                        >
                            <TableCell padding={'checkbox'}>
                                <ThemeProvider theme={darkTheme}>
                                    <Checkbox
                                        color={'default'}
                                        indeterminate={isIndeterminate}
                                        checked={allTicketsSelected}
                                        onChange={handleSelectAllTickets}
                                    />
                                </ThemeProvider>
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="engagement.table.ticket-id" />
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                <FormattedMessage id="engagement.table.type" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="engagement.table.message" />
                            </TableCell>
                            <TableCell align="right">
                                <FormattedMessage id="engagement.comments" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="engagement.datasource" />
                            </TableCell>
                            <TableCell align="center">
                                <FormattedMessage id="engagement.assigned" />
                            </TableCell>
                            <TableCell align="right">
                                <FormattedMessage id="engagement.table.date" />
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tickets.map((ticket) => {
                            const datasource = datasourcesById[ticket.data_source_id] as ProjectDatasource | undefined

                            const post = posts[ticket.target_id] as CommonPostData | undefined
                            const isItemSelected = selectedTicketIds.includes(ticket.id)
                            const assignee = simpleUsers[ticket.assignee_id || '']

                            if (!datasource) {
                                return null
                            }

                            const handleSelectedChange = () => {
                                if (isItemSelected) {
                                    setSelectedTicketIds(selectedTicketIds.filter((entry) => entry !== ticket.id))
                                } else {
                                    setSelectedTicketIds([...selectedTicketIds, ticket.id])
                                }
                            }

                            return (
                                <TableRow
                                    key={ticket.id}
                                    hover={true}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleOpenTicket(ticket)}
                                >
                                    <TableCell padding={'checkbox'}>
                                        <Checkbox
                                            color={'default'}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={handleSelectedChange}
                                            checked={isItemSelected}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontWeight={600}>#{renderTicketId(ticket.ticket_id)}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <Box mb={-0.6}>{getIconForType(ticket, post)}</Box>
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: 400 }}>
                                        <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                            {['POST', 'STORY'].includes(ticket.type)
                                                ? post?.message || (
                                                      <Box component="em" sx={{ color: 'text.secondary' }}>
                                                          <FormattedMessage id="engagement.post-not-found" />
                                                      </Box>
                                                  )
                                                : renderAuthor(ticket)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: 150 }}>
                                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                                            <Box display="flex" alignItems="center" justifyContent="center" gap={0.5}>
                                                <ChatBubbleOutline fontSize="small" htmlColor={theme.palette.text.secondary} />
                                                <Typography sx={{ pr: 1 }}>{ticket.node_count}</Typography>
                                            </Box>
                                            <Box>
                                                {ticket.unread_node_count > 0 && state === 'OPEN' && (
                                                    <Tooltip title={<FormattedMessage id="engagement.unread-messages" />} placement="right">
                                                        <Box
                                                            sx={{
                                                                pl: 1,
                                                                pr: 1,
                                                                backgroundColor: theme.palette.secondary.main,
                                                                height: 24,
                                                                borderRadius: 12,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Typography color="white">
                                                                {ticket.unread_node_count < 100 ? ticket.unread_node_count : '99+'}
                                                            </Typography>
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: 250 }}>
                                        <Box display="flex" alignItems="center" gap={0.5}>
                                            {getIconForDatasourceType(datasource.type)}
                                            <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                                {datasource.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {assignee && (
                                            <Tooltip title={`${assignee.first_name} ${assignee.last_name}`}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <UserAvatar user={assignee} />
                                                </Box>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography>{moment(ticket.created_at).format('HH:mm')}</Typography>
                                        <Typography>{moment(ticket.created_at).format('L')}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'right', pr: 1 }} padding="checkbox">
                                        <IconButton size="small" color="primary" onClick={() => handleOpenTicket(ticket)}>
                                            <ArrowForwardIos fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        {tickets.length === 0 &&
                            (isLoadingTickets ? (
                                <TableRow sx={{ borderBottom: 0 }}>
                                    <TableCell colSpan={8} sx={{ textAlign: 'center', borderBottom: 0 }}>
                                        <Loading />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow sx={{ borderBottom: 0 }}>
                                    <TableCell colSpan={8} sx={{ textAlign: 'center', borderBottom: 0 }}>
                                        {emptyState}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {tickets.length > 0 && (
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    {selectedTicketIds.length > 0 ? (
                        <ButtonGroup>
                            <Button
                                color="secondary"
                                size="small"
                                disabled={!canReopenTickets || !canCloseTickets}
                                onClick={handleTicketStateToggle}
                                startIcon={tab !== 'archived' ? <Archive fontSize="small" /> : <MoveToInbox fontSize="small" />}
                            >
                                {tab !== 'archived' ? (
                                    <FormattedMessage id={isMobile ? 'general.close' : 'engagement.close-plural'} />
                                ) : (
                                    <FormattedMessage id={isMobile ? 'general.open' : 'engagement.open-plural'} />
                                )}
                            </Button>
                            <AssigneeDropdown assigneeId={undefined} onAssign={handleMultipleAssign} variant={'outlined'} allowUnassignOverride />
                        </ButtonGroup>
                    ) : (
                        <Box></Box>
                    )}
                    <TablePagination
                        labelRowsPerPage={<FormattedMessage id="general.rowsPerPage" />}
                        labelDisplayedRows={(args) => <FormattedMessage id="general.displayedRows" values={args as any} />}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        component="div"
                        count={ticketCountForTab}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ flexShrink: 1, flexGrow: 0, width: 'fit-content' }}
                    />
                </Box>
            )}
        </Box>
    ) : (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            position={'relative'}
            flexGrow={1}
            ref={ticketContainerRef}
            sx={{
                overscrollBehavior: 'none !important',
                touchActions: 'none !important',
            }}
        >
            <Box
                height={`${clamp(rubberBandValue, 0, 2000)}px`}
                width={'100%'}
                mb={0}
                mt={-1}
                flexShrink={0}
                sx={{
                    transition: finishAnimations && !isLoadingTicketsDelayed ? 'none' : 'height 0.3s',
                    willChange: 'height',
                    overflow: !finishAnimations && isLoadingTicketsDelayed ? 'visible' : 'hidden',
                }}
            >
                <Box
                    sx={{
                        height: `${clamp(rubberBandValue, 0, 2000)}px`,
                        mt: !isLoadingTicketsDelayed ? 0 : '-60px',
                        transition: finishAnimations && !isLoadingTicketsDelayed ? 'none' : 'height 0.3s, opacity 0.3s',
                        willChange: 'height, opacity',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <SwipeDownOutlined
                        sx={{
                            fontSize: '1.8em',
                            transform: `rotate(-${(rubberBandValue / 59) * 40 - 10}deg)`,
                            opacity: rubberBandValue / 59,
                            maxHeight: `${rubberBandValue >= 60 ? 0 : 'min(1em, 100%)'}`,
                            transition: `max-height 0.3s`,
                        }}
                    />
                    <Typography
                        sx={{
                            transition: 'opacity 0.3s,max-height 0.3s',
                            color: theme.palette.text.disabled,
                            opacity: !isLoadingTicketsDelayed && rubberBandValue >= 60 ? 1 : 0,
                            maxHeight: !isLoadingTicketsDelayed && rubberBandValue >= 60 ? '30px' : 0,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <KeyboardDoubleArrowUp />
                        <FormattedMessage id={'general.let-go-to-refresh'} />
                        <KeyboardDoubleArrowUp />
                    </Typography>
                </Box>
                <Box display={'flex'} height={'60px'} alignItems={'center'} justifyContent={'center'}>
                    <CircularProgress
                        className={'spinner'}
                        variant={'indeterminate'}
                        size={30}
                        sx={{
                            position: 'relative',
                            opacity: isLoadingTicketsDelayed ? 1 : 0,
                            transition: 'opacity 0.3s',
                            color: theme.palette.secondary.contrastText,
                            background: theme.palette.secondary.main,
                            p: 0.5,
                            borderRadius: 100,
                            display: 'flex',
                            margin: '0 auto',
                            '&:after': isLoadingTicketsDelayed
                                ? {
                                      content: "''",
                                      position: 'absolute',
                                      display: 'block',
                                      height: '100%',
                                      width: '100%',
                                      top: '0px',
                                      left: '0px',
                                      borderRadius: 1000,
                                      zIndex: 'modal',
                                      animation: `${pullDownAnimation} 0.34s 1 ease-in`,
                                  }
                                : {},
                        }}
                    />
                </Box>
            </Box>
            {tickets.length === 0 && emptyState}
            {tickets.map((ticket) => {
                const post = posts[ticket.target_id] as CommonPostData | undefined
                const datasource = datasourcesById[ticket.data_source_id] as ProjectDatasource | undefined

                const assignee = simpleUsers[ticket.assignee_id || '']
                if (!datasource) {
                    return null
                }
                return (
                    <TicketCardMobile
                        key={ticket.id + ticket.ticket_id}
                        ticket={ticket}
                        icon={getIconForType(ticket, post)}
                        message={
                            ['POST', 'STORY'].includes(ticket.type)
                                ? post?.message || (
                                      <Box component="em" sx={{ color: 'text.secondary' }}>
                                          <FormattedMessage id="engagement.post-not-found" />
                                      </Box>
                                  )
                                : renderAuthor(ticket)
                        }
                        datasourceIcon={getIconForDatasourceType(datasource?.type)}
                        datasource={datasource}
                        assignee={assignee}
                        onClick={() => {
                            handleOpenTicket(ticket)
                        }}
                    />
                )
            })}
            {tickets.length !== ticketCountForTab && !isLoadingTickets && (
                <TicketCardMobileLoader
                    shouldShowLastTickets={shouldShowLastTickets}
                    currentlyDisplayedTickets={tickets.length}
                    openTicketCount={ticketCountForTab}
                    tab={tab}
                />
            )}
            {tickets.length >= ticketCountForTab && !isLoadingTickets && ticketCountForTab > 0 && (
                <Typography textAlign={'center'} color={theme.palette.action.disabled}>
                    <FormattedMessage id={'engagement.all-tickets-displayed'} />
                </Typography>
            )}
            <Box mt={-2}>&nbsp;</Box>
        </Box>
    )
}
