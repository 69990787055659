import * as React from 'react'
import { FC } from 'react'
import { DetailedTicket } from './DetailedTicket'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getIconForDatasourceType } from '../settings/datasources/DatasourceTypeMappings'
import moment from 'moment'
import { UserAvatar } from '../common/avatars/UserAvatar'
import { TicketNoteCommentBar } from './TicketNoteComponents/TicketNoteCommentBar'
import { TicketActivities } from './TicketActivities'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { SimpleUser } from '../settings/user-management/SimpleUser'
import { MobileAccordion } from '../common/MobileAccordion'
import { useSelector } from 'react-redux'
import { getIsMobile } from '../core/slices/CoreSlice'
import { Archive, Inbox } from '@mui/icons-material'

type TicketOverviewProps = { detailedTicket: DetailedTicket; datasource: ProjectDatasource; assignee?: SimpleUser }

export const TicketOverview: FC<TicketOverviewProps> = ({ detailedTicket, datasource, assignee }) => {
    const isMobile = useSelector(getIsMobile)
    const theme = useTheme()
    return (
        <MobileAccordion summary={<FormattedMessage id="engagement.ticket-overview" />}>
            <Box
                ml={isMobile ? 0 : 4}
                p={1}
                px={isMobile ? 3 : 0}
                sx={{
                    [theme.breakpoints.up('xxl')]: {
                        px: 3,
                    },
                    [theme.breakpoints.down('xxl')]: {
                        px: 1,
                    },
                }}
                display="flex"
                flexDirection="column"
                maxHeight="100%"
                overflow="hidden"
            >
                <Typography variant="h5">
                    <FormattedMessage id="engagement.ticket-overview" />
                </Typography>

                <Grid container spacing={1} mt={2}>
                    {!isMobile && (
                        <Grid item xs={12} xxl={4}>
                            <Typography fontWeight="600">
                                <FormattedMessage id="engagement.datasource" />
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} xxl={8}>
                        <Box display="flex" alignItems="center" gap={1}>
                            {getIconForDatasourceType(datasource.type)}
                            <Typography>{datasource.name}</Typography>
                        </Box>
                    </Grid>

                    {!isMobile && (
                        <Grid item xs={12} xxl={4}>
                            <Typography fontWeight="600">
                                <FormattedMessage id="engagement.state" />
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} xxl={8}>
                        <Typography display={'flex'} alignItems={'center'} gap={1}>
                            {isMobile && detailedTicket.state === 'OPEN' ? (
                                <Inbox fontSize={'small'} />
                            ) : isMobile && detailedTicket.state === 'CLOSED' ? (
                                <Archive fontSize={'small'} />
                            ) : null}
                            <FormattedMessage id={`engagement.state.${detailedTicket.state}`} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xxl={4}>
                        <Typography fontWeight="600">
                            <FormattedMessage id="engagement.created-at" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xxl={8}>
                        <Typography>{moment(detailedTicket.created_at).format('LLL')}</Typography>
                    </Grid>
                    <Grid item xs={12} xxl={4}>
                        <Typography fontWeight="600">
                            <FormattedMessage id="engagement.last-update" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xxl={8}>
                        <Typography>{moment(detailedTicket.last_node_recieved_at).format('LLL')}</Typography>
                    </Grid>
                    {detailedTicket.state !== 'CLOSED' && assignee && (
                        <>
                            <Grid item xs={12} xxl={4}>
                                <Typography fontWeight="600">
                                    <FormattedMessage id="engagement.assigned-to" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} xxl={8}>
                                <Box display="flex" gap={1} alignItems="center">
                                    {assignee && <UserAvatar user={assignee} sx={{ height: 32, width: 32, fontSize: 14 }} />}

                                    <Typography>
                                        {assignee ? `${assignee.first_name} ${assignee.last_name}` : <FormattedMessage id="engagement.assigned-to-nobody" />}
                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
                <TicketNoteCommentBar detailedTicket={detailedTicket} datasource={datasource} />

                <TicketActivities detailedTicket={detailedTicket} />
            </Box>
        </MobileAccordion>
    )
}
