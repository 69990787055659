import { FC } from 'react'
import { FormattedNumber } from 'react-intl'

type FormattedPercentProps = {
    value: number
}

export const FormattedPercent: FC<FormattedPercentProps> = ({ value }) => {
    return (
        <FormattedNumber
            value={value}
            // eslint-disable-next-line react/style-prop-object
            style="percent"
            minimumFractionDigits={2}
        />
    )
}
