import { Box, FormControlLabel, FormHelperText, Switch, Tooltip } from '@mui/material'
import { Info } from '@mui/icons-material'
import { FC } from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import * as React from 'react'

type ConnectedSwitchProps = {
    name: string
    label?: string
    tooltip?: string
    disabled?: boolean
    onChange?: (checked: boolean) => any
    helperText?: string
}

export const ConnectedSwitch: FC<ConnectedSwitchProps> = ({ name, label, tooltip, onChange, disabled, helperText }) => {
    return (
        <Field name={name} type="checkbox" isEqual={(a: any, b: any) => Boolean(a) === Boolean(b)}>
            {({ input }) => {
                const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    input.onChange(event)
                    if (onChange) {
                        onChange(checked)
                    }
                }

                return (
                    <>
                        <FormControlLabel
                            disabled={disabled}
                            control={<Switch color="secondary" checked={input.checked} onChange={handleChange} disabled={disabled} />}
                            label={
                                <>
                                    <Box alignItems="center" display="flex">
                                        <Box marginRight={1}>
                                            <FormattedMessage id={label} />
                                        </Box>
                                        {tooltip && (
                                            <Tooltip title={<FormattedMessage id={tooltip} />}>
                                                <Info fontSize="small" />
                                            </Tooltip>
                                        )}
                                    </Box>
                                </>
                            }
                        />
                        {helperText && (
                            <FormHelperText sx={{ ml: 6 }}>
                                <FormattedMessage id={helperText} />
                            </FormHelperText>
                        )}
                    </>
                )
            }}
        </Field>
    )
}
