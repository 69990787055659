import { alpha, createTheme } from '@mui/material/styles'

// this is necessary to augment type definitions for theme options into mui theme
// noinspection ES6UnusedImports
import type {} from '@mui/x-date-pickers/themeAugmentation'

export const HmstrColors = {
    DarkGrey: '#2c2e33',
    Grey: '#4f5058',
    LightGrey: '#bebec0',
    BackgroundGrey: '#f5f5f5',
    BackgroundOrange: '#fef0e6',
    White: '#ffffff',
    Black: '#000000DE',
    LightDark: '#2c2e33',

    Orange: '#ff6900',
    OrangeDark: '#b24200',
    OrangeLight: '#ffd2b3',
    OrangeLight2: '#ff9e59',

    Palette: ['#2c2e33', '#88a6f2', '#ced8f0', '#3f4d70', '#a2aabd', '#00838f'],

    Platforms: {
        Facebook: '#4267b2',
        Instagram: '#833ab4',
        Messenger: '#00b2ff',
        AudienceNetwork: '#898f9c',
        X: 'rgba(15,20,25,1.00)',
        Youtube: '#ff0000',
        Flickr: '#5f2a89',
        LinkedIn: '#0a66c2',
        Tiktok: 'text.primary',
    },
    Approval: {
        pending: '#ff9800',
        approved: '#4caf50',
    },
    PostPreviewColorsDarkMode: {
        background: '#242526',
        linkPreviewButton: alpha('#ffffff', 0.1),
    },
}

const errorColor = '#d32f2f'

export const createHmstrTheme = (mode: 'light' | 'dark') =>
    createTheme({
        palette: {
            mode: mode,
            primary: {
                main: HmstrColors.DarkGrey,
            },
            secondary: {
                main: HmstrColors.Orange,
                dark: HmstrColors.OrangeDark,
                contrastText: HmstrColors.White,
            },
            background: {
                default: mode === 'light' ? '#fafafa' : '#303030',
                paper: mode === 'light' ? '#ffffff' : '#424242',
            },
        },
        typography: {
            fontFamily: 'foco, Calibri, Arial, sans-serif',
            fontWeightMedium: 600,

            h5: {
                fontSize: '1.6rem',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 1600,
            },
        },
        components: {
            MuiCheckbox: {
                defaultProps: {
                    color: mode === 'dark' ? 'secondary' : 'primary',
                },
            },
            MuiInputLabel: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: mode === 'light' ? HmstrColors.DarkGrey : HmstrColors.White,
                        },
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiTabs: {
                styleOverrides: {
                    scrollButtons: {
                        '&.Mui-disabled': {
                            opacity: 0.1,
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    underline: {
                        '&.Mui-focused:after': {
                            borderBottomColor: mode === 'light' ? HmstrColors.DarkGrey : HmstrColors.Orange,
                        },
                        '&.Mui-error:after': {
                            borderBottomColor: errorColor,
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: mode === 'light' ? HmstrColors.DarkGrey : HmstrColors.Orange,
                            },
                        },
                        '&.Mui-error': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: errorColor,
                            },
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: mode === 'light' ? HmstrColors.DarkGrey : HmstrColors.White,
                        textDecorationColor: mode === 'light' ? HmstrColors.DarkGrey : HmstrColors.White,
                    },
                },
            },
        },
    })
