import * as React from 'react'
import { FC, useState } from 'react'
import { Box, Button, Checkbox, Grid, Paper, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { TagsRow } from './Tags.styles'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Tag as TagModel } from '../../tags/Tag'
import { Tag } from './Tag'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedProject, isProjectAdmin, showErrorSnackbar } from '../../core/slices/CoreSlice'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { createTag } from '../../tags/TagsActions'
import { textColorBasedOnBackground } from '../../core/theme/helper'
import { TagColorPicker } from './components/TagColorPicker'
import { TagsHeaderRow } from './TagsHeader.styles'
import _ from 'lodash'
import { HmstrDispatch } from '../../core/Store'
import { getTagLimitReached } from '../post-analysis/PostAnalysisSlice'

type TagsListProps = {
    tags: TagModel[]
    orderedTags: string[]
}

export const TagsList: FC<TagsListProps> = ({ tags, orderedTags }) => {
    const intl = useIntl()
    const theme = useTheme()
    const disableNewTags = useSelector(getTagLimitReached)
    const selectedProject = useSelector(getSelectedProject)
    const dispatch = useDispatch<HmstrDispatch>()
    const isUserProjectAdmin = useSelector(isProjectAdmin)

    const emptyTag = {
        id: '',
        tag_id: '',
        label: '',
        active: true,
        color: '#EEE',
        project_id: selectedProject?.id ?? '',
        _links: { self: { href: '' } },
        usage_count: 0,
    }

    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [newTag, setNewTag] = useState<TagModel>(emptyTag)
    const [tagAlreadyInUse, setTagAlreadyInUse] = useState(_.map(tags, (t) => t.label).includes(newTag.label))

    const newTagRow = (
        <TagsRow
            container
            alignItems="center"
            style={{
                padding: '12px 0 8px 0',
                height: 'auto',
                borderBottom: `solid 1px ${theme.palette.secondary}`,
            }}
        >
            <Grid item xs={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <TextField
                    fullWidth
                    sx={{ margin: '0 0 0 20px' }}
                    placeholder={intl.formatMessage({ id: 'tags.new-placeholder' })}
                    value={newTag.label}
                    onChange={(e) => {
                        setNewTag((prevState) => {
                            if (e.target.value.trim().length === 0) {
                                prevState.label = ''
                            } else {
                                prevState.label = e.target.value
                            }
                            setTagAlreadyInUse(_.map(tags, (t) => t.label).includes(newTag.label))
                            return { ...prevState }
                        })
                    }}
                    error={tagAlreadyInUse}
                    helperText={
                        tagAlreadyInUse && (
                            <>
                                <FormattedMessage id={'post.analysis.tag-already-in-use.helper'} />
                            </>
                        )
                    }
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            if (!!selectedProject) {
                                dispatch(
                                    createTag({
                                        project: selectedProject,
                                        tag: { ...newTag, label: newTag.label.trim() },
                                    })
                                )
                                setNewTag({ ...emptyTag })
                            }
                            ev.preventDefault()
                        }
                    }}
                />
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                <div
                    style={{
                        backgroundColor: newTag.color ?? '#EEE',
                        borderRadius: '4px',
                        padding: '4px 8px',
                        width: 'fit-content',
                        color: textColorBasedOnBackground(newTag.color ?? '#EEE'),
                    }}
                >
                    {newTag.label.length > 0 ? newTag.label : <FormattedMessage id="tags.new-placeholder" />}
                </div>
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                <TagColorPicker
                    color={newTag.color ?? '#EEE'}
                    changeCallback={(color) => {
                        setNewTag((prevState) => {
                            prevState.color = color.hex
                            return { ...prevState }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                <Typography>{newTag.usage_count}</Typography>
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                <Tooltip title={disableNewTags ? intl.formatMessage({ id: 'tags.tag-limit-reached' }) : ''}>
                    <span>
                        <Button
                            variant="outlined"
                            disabled={newTag.label.length === 0 || tagAlreadyInUse || disableNewTags}
                            color={newTag.label.trim().length === 0 ? 'primary' : 'warning'}
                            startIcon={<AddBoxIcon sx={{ height: '24px', width: '24px', marginRight: '8px' }} />}
                            onClick={() => {
                                if (!!selectedProject && !disableNewTags) {
                                    dispatch(
                                        createTag({
                                            project: selectedProject,
                                            tag: { ...newTag, label: newTag.label.trim() },
                                        })
                                    ).then((res) => {
                                        if (res.meta.requestStatus === 'rejected' && (res.payload as any).status === 426) {
                                            dispatch(showErrorSnackbar('tags.tag-limit-reached'))
                                        }
                                    })
                                    setNewTag({ ...emptyTag })
                                }
                            }}
                        >
                            <Typography variant={'subtitle2'}>
                                <FormattedMessage id="tag.create-tag" />
                            </Typography>
                        </Button>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={1} display={'flex'} justifyContent={'flex-end'} />
        </TagsRow>
    )

    return (
        <AutoSizer>
            {({ height, width }) => {
                return (
                    <Box
                        height={height}
                        width={width}
                        style={{
                            overflow: 'auto',
                        }}
                    >
                        <Paper style={{ minWidth: '1200px' }}>
                            <Box width="100%" height="100%">
                                <Box style={{ minWidth: 1200, paddingBottom: 4 }}>
                                    <TagsHeaderRow container alignItems="center">
                                        <Grid item xs={3} display={'flex'} alignItems={'center'}>
                                            <Checkbox
                                                style={{ margin: '0 8px 0 8px' }}
                                                checked={selectedTags.length === tags.length}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedTags(tags.map((tag) => tag.id))
                                                    } else {
                                                        setSelectedTags([])
                                                    }
                                                }}
                                            />
                                            <FormattedMessage id="tags.choose-all" />
                                        </Grid>
                                        <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                                            <FormattedMessage id="tag.tag-preview" />
                                        </Grid>
                                        <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                                            <FormattedMessage id="tags.selected-color" />
                                        </Grid>
                                        <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                                            <FormattedMessage id="tags.usage_count" />
                                        </Grid>
                                        <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                                            <FormattedMessage id="tags.active" />
                                        </Grid>
                                        {/*WILL BE IMPLEMENTED LATER*/}
                                        {/*<Grid item xs={2} display={'flex'} justifyContent={'center'}>*/}
                                        {/*    <TableSortLabel*/}
                                        {/*        style={{ textAlign: 'center' }}*/}
                                        {/*        // active={orderBy === 'insights.post_impressions_unique'}*/}
                                        {/*        // direction={*/}
                                        {/*        //     orderBy === 'insights.post_impressions_unique' ? order : 'desc'*/}
                                        {/*        // }*/}
                                        {/*        // onClick={() => handleRequestSort('insights.post_impressions_unique')}*/}
                                        {/*    >*/}
                                        {/*        <FormattedMessage id="tags.used-how-often" />*/}
                                        {/*    </TableSortLabel>*/}
                                        {/*</Grid>*/}
                                        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
                                            <FormattedMessage id="tags.delete" />
                                        </Grid>
                                    </TagsHeaderRow>
                                    {isUserProjectAdmin && newTagRow}

                                    {orderedTags.map((tagId, index) => {
                                        let tag = tags.find((tag) => tag.id === tagId)
                                        return (
                                            tag && (
                                                <Tag
                                                    key={`tag-${tagId}`}
                                                    tag={tag}
                                                    selectedTags={selectedTags}
                                                    allTags={tags}
                                                    dividerBottom={index !== tags.length - 1}
                                                    selectCallback={(tagId) => {
                                                        if (selectedTags.includes(tagId)) {
                                                            setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tagId))
                                                        } else {
                                                            setSelectedTags([...selectedTags, tagId])
                                                        }
                                                    }}
                                                />
                                            )
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                )
            }}
        </AutoSizer>
    )
}
