import * as _ from 'lodash'
import { FC, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { fetchUsersForProject } from '../../settings/user-management/UserActions'
import { getSelectedProject, getSimpleUsersForSelectedProject, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { Add, Send } from '@mui/icons-material'
import { validationEmail } from '../../form/validate'
import { sendMonthlyReport } from './MonthlyReportActions'
import { HmstrDispatch } from '../../core/Store'
import { Dashboard } from '../../dashboards/Dashboard'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { closeSendDashboardDialog } from '../../dashboards/DashboardsSlice'
import { MonthlyReportRequest } from './MonthlyReportRequest'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'

type SendMonthlyReportDialogProps = {
    open: boolean
    selectedMonth: string
    selectedCompareMonth: string
    selectedProjectId?: string
    selectedDashboard?: Dashboard
    selectedPage?: ProjectDatasource
    selectedAdAccounts?: ProjectDatasource[]
}

export const SendMonthlyReportDialog: FC<SendMonthlyReportDialogProps> = (props) => {
    const { open, selectedProjectId, selectedDashboard, selectedMonth, selectedCompareMonth, selectedPage, selectedAdAccounts } = props
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const [sendingReport, setSendingReport] = useState(false)
    const [emails, setEmails] = useState<string[]>([])
    const [emailToAdd, setEmailToAdd] = useState('')
    const [additionalEmails, setAdditionalEmails] = useState<string[]>([])
    const usersIdMap = useSelector(getSimpleUsersForSelectedProject)
    const users = _.sortBy(Object.values(usersIdMap), (u) => `${u.first_name} ${u.last_name}`)
    const selectedProject = useSelector(getSelectedProject)
    const noEmailsSelected = emails.length === 0 && additionalEmails.length === 0

    const handleSend = () => {
        if (selectedProjectId && selectedProject && selectedDashboard && selectedPage) {
            const emailsToSend = _.uniq([...emails, ...additionalEmails]) as string[]

            const request: MonthlyReportRequest = {
                type: 'MAIL',
                project_id: selectedProjectId,
                account_id: selectedProject.id,
                dashboard_id: selectedDashboard.id,
                dashboard_name: selectedDashboard.name,
                datasource_id: selectedPage.id,
                datasource_name: selectedPage.name,
                ad_account_ids: (selectedAdAccounts || []).map((acc) => acc.id),
                month: selectedMonth,
                compare_month: selectedCompareMonth,
                recipients: emailsToSend,
            }

            setSendingReport(true)

            dispatch(sendMonthlyReport(request)).then((action: any) => {
                if (action.payload) {
                    setSendingReport(false)
                    dispatch(showSuccessSnackbar('facebook.monthly-report.sent'))
                    handleClose()
                }
            })
        }
    }

    const handleCheckboxClick = (email: string, checked: boolean) => {
        if (checked) {
            setEmails(_.uniq([...emails, email]))
        } else {
            const newEmails = [...emails]
            _.remove(newEmails, (e) => e === email)
            setEmails(newEmails)
        }
    }

    const handleDeleteAdditionalEmail = (email: string) => {
        const newAdditionalEmails = [...additionalEmails]
        _.remove(newAdditionalEmails, (e) => e === email)
        setAdditionalEmails(newAdditionalEmails)
    }

    const handleAddAdditionalEmail = () => {
        setAdditionalEmails([...additionalEmails, emailToAdd])
        setEmailToAdd('')
    }

    const getErrorText = () => {
        if (emailToAdd === '') {
            return undefined
        }

        const emailNotValid = validationEmail(emailToAdd)

        if (emailNotValid) {
            return intl.formatMessage({ id: emailNotValid })
        }

        if (additionalEmails.includes(emailToAdd) || emails.includes(emailToAdd)) {
            return intl.formatMessage({
                id: 'validations.email-already-exists',
            })
        }
    }

    const handleClose = () => {
        setEmails([])
        setAdditionalEmails([])
        setEmailToAdd('')
        dispatch(closeSendDashboardDialog())
    }

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchUsersForProject(selectedProject))
        }
    }, [selectedProject])

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogContent sx={{ width: 600 }}>
                <Typography variant="h6">
                    <FormattedMessage id="facebook.monthly-report.select-targets" />:
                </Typography>

                <Box mt={2} mb={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <List dense={true} disablePadding={true}>
                                {users.map((u) => (
                                    <ListItem key={u.email} disablePadding={true}>
                                        <ListItemIcon>
                                            <Checkbox
                                                size="small"
                                                checked={emails.includes(u.email)}
                                                onClick={(e: any) => handleCheckboxClick(u.email, e.target.checked)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={`${u.first_name} ${u.last_name}`} secondary={u.email} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>

                        <Grid item xs={12}>
                            <Box mt={2}>
                                <Typography variant="caption">
                                    <FormattedMessage id="facebook.monthly-report.additional-emails" />:
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            {additionalEmails.length === 0 ? (
                                <Box>
                                    <Typography variant="caption" color="text.secondary">
                                        <FormattedMessage id="facebook.monthly-report.no-additional-emails" />
                                    </Typography>
                                </Box>
                            ) : (
                                <Box mt={1}>
                                    {additionalEmails.map((e) => (
                                        <Chip key={e} sx={{ mb: 1, mr: 1 }} label={e} onDelete={() => handleDeleteAdditionalEmail(e)} />
                                    ))}
                                </Box>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <Box mt={3} height={50}>
                                <TextField
                                    value={emailToAdd}
                                    onChange={(e) => setEmailToAdd(e.target.value)}
                                    placeholder={intl.formatMessage({
                                        id: 'facebook.monthly-report.add-additional-emails',
                                    })}
                                    error={getErrorText() !== undefined}
                                    helperText={getErrorText()}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={<FormattedMessage id="facebook.send-monthly-add-email" />} placement="top">
                                                <span>
                                                    <IconButton
                                                        disabled={getErrorText() !== undefined}
                                                        sx={{ mb: 1 }}
                                                        onClick={() => handleAddAdditionalEmail()}
                                                    >
                                                        <Add />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        ),
                                    }}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>
                    <FormattedMessage id="general.close" />
                </Button>
                <Tooltip
                    placement="top"
                    title={
                        noEmailsSelected ? (
                            <FormattedMessage id="facebook.send-monthly-report-disabled" />
                        ) : emailToAdd !== '' ? (
                            <FormattedMessage id="facebook.send-monthly-email-not-added" />
                        ) : (
                            ''
                        )
                    }
                >
                    <span>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSend}
                            disabled={sendingReport || noEmailsSelected || emailToAdd !== ''}
                            startIcon={sendingReport ? <CircularProgress color="inherit" size={20} /> : <Send />}
                        >
                            <FormattedMessage id="facebook.send-monthly-report" />
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    )
}
