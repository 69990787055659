import { EffectCallback, useEffect } from 'react'
import * as _ from 'lodash'

const replaceDependenciesWithId = (dependencies: any[]) => {
    return dependencies.map((dep) => {
        // If the dependency has an id property, use the id as dependency instead of the whole object.
        if (_.has(dep, 'id')) {
            return dep.id
        }

        return dep
    })
}

/**
 * This hook behaves exactly as the standard `useEffect` from react, except that when a dependency
 * has an `id` property, it only compares the ids instead of doing a strict equality comparison.
 *
 * @param callback is the function which should execute the side effect logic
 * @param dependencies define when the effect has to be executed again.
 */
export const useEffectWithIdComparison = (callback: EffectCallback, dependencies: any[]) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, replaceDependenciesWithId(dependencies))
}
