import { FieldValidator } from 'final-form'
import { IntlShape } from 'react-intl'
import moment, { Moment } from 'moment'

export const composeValidators =
    (...validators: FieldValidator<any>[]) =>
    (value: any, allValues: any) => {
        return validators.reduce((error, validator) => error || validator(value, allValues), undefined)
    }

export const validationRequired = (value: any) => {
    return value ? undefined : 'validations.required'
}

export const validationInTimeRange = (value: any, intl: IntlShape, minDateTime?: Moment, maxDateTime?: Moment) => {
    const exceedsMinDate = Boolean(minDateTime && value && minDateTime.isAfter(value))
    const exceedsMaxDate = Boolean(maxDateTime && value && maxDateTime.isBefore(value))
    const dateAsMoment = moment(value)
    if (!dateAsMoment.isValid()) {
        return 'validations.required'
    }

    const getDateRangeError = () => {
        if (exceedsMinDate && minDateTime) {
            return intl.formatMessage({ id: 'validations.min-date-time' }, { date: minDateTime.format('LLL') })
        } else if (exceedsMaxDate && maxDateTime) {
            return intl.formatMessage({ id: 'validations.max-date-time' }, { date: maxDateTime.format('LLL') })
        }
        return undefined
    }

    return getDateRangeError()
}

export const validationMaxLength = (intl: IntlShape, length: number) => (value: any) => {
    if (value.toString().length > length) {
        return intl.formatMessage({ id: 'validations.max-length' }, { maxLength: length })
    }
    return undefined
}

export const validationEmail = (value: any) => {
    const regex = new RegExp('^[A-Z0-9._%+-\u00c4\u00d6\u00dc\u00df]+@[A-Z0-9.-]+\\.[A-Z]{2,20}$', 'i')

    if (value && !regex.test(value)) {
        return 'validations.email'
    }

    return undefined
}

const urlRegex = /^(https:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/

export const validateUrl = (url: string) => {
    if (!url) {
        return 'validations.required'
    }

    if (!url.startsWith('https')) {
        return 'validations.only-https'
    }

    if (urlRegex.test(url)) {
        return undefined
    } else {
        return 'validations.illegal-url'
    }
}
