import * as _ from 'lodash'
import { FC } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, ListSubheader, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getAvailableMediaTypes, getSelectedMediaTypes } from './ResultsSlice'

type ResultMediatypeFilterProps = {
    open: boolean
    onOpen: () => void
    onClose: () => void
    onChange: (event: SelectChangeEvent<any>, newValue: any) => void
    value: string[]
    fullWidth: boolean
}

export const ResultMediatypeFilter: FC<ResultMediatypeFilterProps> = ({ open, onOpen, onClose, onChange, value, fullWidth }) => {
    const intl = useIntl()
    const availableMediaTypes = useSelector(getAvailableMediaTypes)
    const selectedMediaTypes = useSelector(getSelectedMediaTypes)

    const renderValue = () => {
        if (value.length === 0) {
            return <FormattedMessage id="filters.all-mediatypes" />
        }

        return value.map((mt) => intl.formatMessage({ id: mt })).join(', ')
    }

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel shrink={true} variant={'outlined'}>
                <FormattedMessage id="filters.mediatype" />
            </InputLabel>
            <Select
                variant={'outlined'}
                label={<FormattedMessage id="filters.mediatype" />}
                notched
                fullWidth={fullWidth}
                value={value}
                onChange={onChange}
                onOpen={onOpen}
                open={open}
                onClose={onClose}
                renderValue={renderValue}
                MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'right' },
                }}
                displayEmpty
                multiple
            >
                {_.flatMap(availableMediaTypes, (value, key) => {
                    return [
                        <ListSubheader style={{ cursor: 'pointer' }} key={key}>
                            {key}
                        </ListSubheader>,

                        ...value.map((v) => (
                            <MenuItem dense key={v} value={v}>
                                <Checkbox size="small" checked={selectedMediaTypes.indexOf(v) > -1} />
                                <ListItemText primary={<FormattedMessage id={v} />} />
                            </MenuItem>
                        )),
                    ]
                })}
            </Select>
        </FormControl>
    )
}
