import { Box, Button, Container, Grid, Tooltip, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading/Loading'
import { getAccountById } from '../../../core/slices/DataSlice'
import { HmstrDispatch } from '../../../core/Store'
import { UserAccountAccess } from '../../user-management/User'
import { getSelectedAccountSubscription } from '../../SettingsSlice'
import { fetchPaymentMethods, fetchProducts, fetchSubscriptionById } from '../../billing/BillingActions'
import { AccountOverviewMetadata } from './AccountOverviewMetadata'
import { useEffectWithIdComparison } from '../../../core/hooks/useEffectWithIdComparison'
import { AccountOverviewSubscription } from './AccountOverviewSubscription'
import { AccountOverviewAdditionals } from './AccountOverviewAdditionals'
import { getSelectedAccountAccess, openFullscreenDialog } from '../../../core/slices/CoreSlice'
import { fetchAccount } from '../AccountActions'
import { disableGuideButtons, enableGuideButtons } from '../../../common/guides/GuideSlice'
import { useHasAccountPermission } from '../../../core/hooks/useHasPermission'

type AccountEditProps = {}

export const AccountOverview: FC<AccountEditProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const accountAccess = useSelector(getSelectedAccountAccess) as UserAccountAccess
    const selectedAccount = useSelector(getAccountById(accountAccess.account_id))
    const subscriptionIdForSelectedAccount = selectedAccount?.subscription_id
    const selectedSubscription = useSelector(getSelectedAccountSubscription)

    const canManageBilling = useHasAccountPermission('account.manage_subscription', selectedAccount?.id)
    const canReadBillling = useHasAccountPermission('account.read_billing', selectedAccount?.id)

    const handleExtendAccountClick = () => {
        dispatch(openFullscreenDialog('extend-account'))
    }

    useEffect(() => {
        dispatch(fetchProducts())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffectWithIdComparison(() => {
        if (selectedAccount) {
            dispatch(fetchSubscriptionById(selectedAccount))
            dispatch(fetchAccount(selectedAccount._links.self))
            if (canReadBillling) {
                dispatch(fetchPaymentMethods(selectedAccount))
            }
        }
    }, [selectedAccount, subscriptionIdForSelectedAccount])

    useEffect(() => {
        if (selectedAccount && selectedSubscription) {
            dispatch(enableGuideButtons())
        } else {
            dispatch(disableGuideButtons())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, selectedSubscription])

    return (
        <Container maxWidth="sm">
            {selectedAccount && selectedSubscription ? (
                <Box marginTop={4}>
                    <Box marginBottom={2}>
                        <Typography variant="h4">
                            <FormattedMessage id="accounts.settings" />
                        </Typography>
                    </Box>

                    <Grid container spacing={3}>
                        <AccountOverviewMetadata />
                        <AccountOverviewSubscription />
                        <AccountOverviewAdditionals />

                        <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <Tooltip placement="top" title={canManageBilling ? '' : <FormattedMessage id="billing.change.only-owner" />}>
                                <span>
                                    <Button disabled={!canManageBilling} variant="contained" color="secondary" onClick={handleExtendAccountClick}>
                                        {selectedAccount.status === 'TRIALING' ? (
                                            <FormattedMessage id="billing.extend-subscription" />
                                        ) : (
                                            <FormattedMessage id="billing.update-subscription" />
                                        )}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Loading />
            )}
        </Container>
    )
}
