import * as React from 'react'
import { FC } from 'react'
import {
    changePostDialogSelectedNetwork,
    changePostDialogSelectedTab,
    getExternalActivities,
    getInternalActivities,
    getPostDialogSelectedTab,
    PublishingState,
} from './PublishingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Box, darken, keyframes, lighten, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Person } from '@mui/icons-material'
import moment from 'moment/moment'
import { HmstrDispatch } from '../core/Store'
import { Mention } from 'react-mentions'
import { getIsMobile, getSimpleUsersAsSuggestion, getSimpleUsersForSelectedProject, isDarkMode, isExternalForProject, showErrorSnackbar } from '../core/slices/CoreSlice'
import { FormApi } from 'final-form'
import reactStringReplace from 'react-string-replace'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { PostGroupActivity } from './post-groups/PostGroupActivity'
import { PostGroup } from './post-groups/PostGroup'
import { createNoteForPostGroup, fetchPostGroupActivities } from './post-groups/PostGroupActions'
import { NoteForm, NoteFormValues } from '../common/note-form/NoteForm'
import { MobileAccordion } from '../common/MobileAccordion'
import { PublishingFormValues, ValidDatasourceTypeForPublishing } from './PublishingForm'
import { validTabValuesWithoutNetworks } from './PublishingFormComponent'
import { useFormState } from 'react-final-form'
import { PublishingTabsMobile } from './PublishingTabsMobile'
import _ from 'lodash'

type PublishingPostGroupActivitiesProps = {
    postGroup: PostGroup
    isExternal?: boolean
}

export const PublishingPostGroupActivities: FC<PublishingPostGroupActivitiesProps> = ({ postGroup, isExternal }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)
    const highlightColor = darkmode ? darken(theme.palette.info.dark, 0.2) : lighten(theme.palette.info.light, 0.8)
    const users = useSelector(getSimpleUsersForSelectedProject)
    const usersAsSuggestions = useSelector(getSimpleUsersAsSuggestion)
    const internalActivities = useSelector(getInternalActivities)
    const externalActivities = useSelector(getExternalActivities)
    const selectedTab = useSelector(getPostDialogSelectedTab)
    const isUserExternal = useSelector(isExternalForProject)
    const external = selectedTab === 'EXTERNAL' || isExternal || isUserExternal
    const urlParams = new URLSearchParams(window.location.search)
    const highlightedActivityId = urlParams.get('highlightedActivity')
    const isMobile = useSelector(getIsMobile)
    const formState = useFormState<PublishingFormValues>()
    const customizePostsByNetwork = formState.values.customize_posts_by_network

    useEffectWithIdComparison(() => {
        dispatch(fetchPostGroupActivities(postGroup))
    }, [postGroup])

    const getAssigneeNames = (activity: PostGroupActivity) => {
        const assignees = activity.assignee_ids.map((assigneeId) => {
            const assignee = users[assigneeId]
            return assignee ? `${assignee.first_name} ${assignee.last_name}` : ''
        })

        return assignees.join('/')
    }

    const handleSubmitNote = (values: NoteFormValues, form: FormApi<NoteFormValues, Partial<NoteFormValues>>) => {
        const formattedNoteMessage = values.message.replaceAll('\n', '​')
        dispatch(
            createNoteForPostGroup({
                postGroup: postGroup,
                noteRequest: {
                    message: formattedNoteMessage,
                    external: external,
                },
            })
        ).then((action) => {
            if (_.get(action, 'payload.status') === 403) {
                dispatch(showErrorSnackbar('publishing.disabled-tooltip-no-permission'))
            }
            form.reset()
        })
    }

    const generateContent = (text: string) => {
        return reactStringReplace(
            reactStringReplace(text, /@(\[.+?]\(.+?\))/, (match, idx) => {
                const uid = match.split('(')[1].replace(')', '')
                const name = match.split('[')[1].split(']')[0]
                const user = users[uid] || name
                return (
                    <Typography
                        component={'span'}
                        key={`${match}${idx}`}
                        sx={{
                            display: 'inline',
                            backgroundColor: highlightColor,
                            px: 0.5,
                            py: 0.1,
                            borderRadius: 1,
                        }}
                    >
                        {user ? `${user.first_name} ${user.last_name}` : name}
                    </Typography>
                )
            }),
            '​', // replace all zero width spaces with line breaks
            () => {
                return <br />
            }
        )
    }

    const highlightAnimation = keyframes`
        0% {
            transform: scale(1);
        }
        40% {
            transform: scale(1);
        }
        50% {
            transform: scale(0.98) translateX(4px);
            box-shadow: 0 0 2px ${theme.palette.secondary.main};
        }
        60% {
            transform: scale(0.98) translateX(4px);
        }
        95% {
        }
        100% {
            box-shadow: 0 0 50px ${theme.palette.secondary.main}00;
            transform: scale(1);
        }`

    const handleTabChange = (newTab: PublishingState['postDialogSelectedTab']) => {
        if (customizePostsByNetwork && !validTabValuesWithoutNetworks.includes(newTab)) {
            dispatch(changePostDialogSelectedNetwork(newTab as ValidDatasourceTypeForPublishing))
        }

        dispatch(changePostDialogSelectedTab(newTab))
    }

    return (
        <MobileAccordion summary={<FormattedMessage id="publishing.activities" />}>
            {isMobile && <PublishingTabsMobile onTabChange={handleTabChange} />}
            <Box>
                <Box pb={2} px={1}>
                    <NoteForm
                        onSubmit={handleSubmitNote}
                        initialValues={{ message: '' }}
                        placeholder={external ? (isUserExternal ? 'publishing.note' : 'publishing.external-note') : 'publishing.internal-note'}
                    >
                        <Mention
                            trigger="@"
                            data={usersAsSuggestions}
                            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
                                return <ListItemButton selected={focused}>{highlightedDisplay}</ListItemButton>
                            }}
                            style={{
                                fontSize: 'inherit',
                                background: highlightColor,
                                borderRadius: 4,
                            }}
                        />
                    </NoteForm>
                </Box>

                <List sx={{ overflow: 'auto' }}>
                    {(external ? externalActivities : internalActivities)
                        .slice()
                        .reverse()
                        .map((activity) => {
                            return (
                                <ListItem key={activity.id}>
                                    <ListItemIcon sx={{ minWidth: 42 }}>
                                        {activity.user_id ? (
                                            <Box
                                                sx={{
                                                    borderRadius: 12,
                                                    backgroundColor: 'divider',
                                                    height: 24,
                                                    width: 24,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Person fontSize="small" />
                                            </Box>
                                        ) : (
                                            <img height={24} width={24} src="/portal/logo64.png" alt="hmstr-icon" />
                                        )}
                                    </ListItemIcon>

                                    <ListItemText
                                        primary={
                                            <>
                                                <FormattedMessage
                                                    id={`publishing.activity.${isUserExternal ? activity.type.replace('EXTERNAL_', '') : activity.type}`}
                                                    values={{
                                                        name: activity.user_name,
                                                        assignee: getAssigneeNames(activity),
                                                    }}
                                                />
                                                {!!activity.text && (
                                                    <Paper
                                                        sx={{
                                                            width: '100%',
                                                            my: 1,
                                                            animation: activity.id === highlightedActivityId ? `${highlightAnimation} 1.5s 3` : undefined,
                                                        }}
                                                    >
                                                        <Box p={1} display={'flex'} flexDirection={'column'} gap={1}>
                                                            <Box>
                                                                <Typography
                                                                    lineHeight={'180%'}
                                                                    sx={{
                                                                        hyphens: 'auto',
                                                                    }}
                                                                >
                                                                    {generateContent(activity.text || '')}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Paper>
                                                )}
                                            </>
                                        }
                                        secondary={moment(activity.created_at).format('LLL')}
                                    />
                                </ListItem>
                            )
                        })}
                </List>
            </Box>
        </MobileAccordion>
    )
}
