import * as React from 'react'
import { FC, useEffect } from 'react'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { HmstrDispatch } from '../core/Store'
import { fetchCommonPostDataByPostIds } from '../content-analytics/posts/PostsActions'
import { getIsMobile, getPagesForEngage, getSelectedProject } from '../core/slices/CoreSlice'
import { TicketTable } from './TicketTable'
import { DatasourceSelection } from '../content-analytics/datasource/DatasoureSelection'
import { getCurrentInboxPage, getEntriesPerPage, getSelectedDatasourceIds, selectDatasourceIds } from './EngagementSlice'
import { fetchOpenTicketCountForUserByCategory, fetchSimpleTicketsForProject } from './EngagementActions'
import { Refresh, Web } from '@mui/icons-material'
import { Project } from '../settings/project-management/Project'
import { TicketState } from './TicketState'
import { SimpleTicket } from './SimpleTicket'
import { fetchUsersForProject } from '../settings/user-management/UserActions'
import { FilterDrawer, FilterSetting } from '../common/filterbar/FilterDrawer'
import { TitlebarWithFilters } from '../common/filterbar/TitlebarWithFilters'
import _ from 'lodash'
import { getDatasourceSelectionOpen, setDatasourceSelectionOpen } from '../common/filterbar/FilterSlice'

type TicketsProps = {
    tickets: SimpleTicket[]
    state: TicketState
    title: string
    emptyState: JSX.Element
    icon: JSX.Element
    tab: 'mine' | 'others' | 'new' | 'archived'
}

export const Tickets: FC<TicketsProps> = ({ state, tickets, title, emptyState, icon, tab }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const theme = useTheme()
    const project = useSelector(getSelectedProject)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)
    const isMobile = useSelector(getIsMobile)
    const pagesForEngage = useSelector(getPagesForEngage)

    const currentPage = useSelector(getCurrentInboxPage)
    const entriesPerPage = useSelector(getEntriesPerPage)
    const allTicketIds = tickets.map((t) => t.ticket_id).toString()
    const datasourceSelectionOpen = useSelector(getDatasourceSelectionOpen)

    const handleDatasourceSelectionOpen = () => dispatch(setDatasourceSelectionOpen(true))
    const handleDatasourceSelectionClose = () => dispatch(setDatasourceSelectionOpen(false))

    useEffectWithIdComparison(() => {
        if (project && !isMobile) {
            dispatch(
                fetchSimpleTicketsForProject({
                    project,
                    page: currentPage,
                    tab: tab,
                    entriesPerPage: entriesPerPage,
                    datasource_ids: selectedDatasourceIds,
                })
            )
        }
    }, [project, currentPage, entriesPerPage, tab, selectedDatasourceIds.toString()])

    useEffectWithIdComparison(() => {
        if (project) {
            dispatch(fetchUsersForProject(project))
            dispatch(
                fetchOpenTicketCountForUserByCategory({
                    datasource_ids: selectedDatasourceIds,
                    project: project,
                })
            )
        }
    }, [project, selectedDatasourceIds.toString()])

    useEffect(() => {
        dispatch(
            fetchCommonPostDataByPostIds({
                data_source_ids: tickets.map((t) => (t.type !== 'MESSAGE' ? t.data_source_id : undefined)).filter((d) => d) as string[],
                post_ids: tickets.map((t) => (t.type !== 'MESSAGE' ? t.target_id : undefined)).filter((d) => d) as string[],
            })
        ) // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allTicketIds])

    const handleChange = (datasourceIds: string[]) => {
        dispatch(selectDatasourceIds(datasourceIds))
    }

    const handleRefreshClick = () => {
        if (project) {
            dispatch(
                fetchOpenTicketCountForUserByCategory({
                    datasource_ids: selectedDatasourceIds,
                    project: project,
                })
            )
            dispatch(
                fetchSimpleTicketsForProject({
                    project: project as Project,
                    tab: tab,
                    page: currentPage,
                    entriesPerPage: entriesPerPage,
                    datasource_ids: selectedDatasourceIds,
                })
            )
        }
    }

    const getDatasourceFilterSetting = () => {
        const defaultValue: string[] = []
        const hasChanged = !_.isEqual(_.sortBy(defaultValue), _.sortBy(selectedDatasourceIds))
        const generateSettingString = () => {
            if (selectedDatasourceIds.length === 0) return intl.formatMessage({ id: 'filters.all' })
            return selectedDatasourceIds.length === 1
                ? pagesForEngage.find((page) => page.id === selectedDatasourceIds[0])?.name
                : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selectedDatasourceIds.length })
        }
        const onDeleteFunction = () => {
            dispatch(selectDatasourceIds([]))
        }

        const onChipClick = () => {
            setTimeout(handleDatasourceSelectionOpen, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <DatasourceSelection
                    datasources={pagesForEngage}
                    fullWidth
                    initialIds={selectedDatasourceIds}
                    onDatasourceChange={handleChange}
                    isOpen={datasourceSelectionOpen}
                    handleOpen={handleDatasourceSelectionOpen}
                    handleClose={handleDatasourceSelectionClose}
                />
            ),
            tooltip: 'general.datasources',
            important: true,
            deletable: true,
            hasChangedFromDefault: hasChanged,
            currentValueAsString: generateSettingString(),
            onDeleteFunction: onDeleteFunction,
            onClickFunction: onChipClick,
            icon: <Web fontSize={'small'} />,
        }
        return set
    }

    return isMobile ? (
        <TicketTable tickets={tickets} state={state} emptyState={emptyState} tab={tab} />
    ) : (
        <ContentPanel
            fullWidthTitle
            maxWidth={false}
            title={
                <TitlebarWithFilters
                    title={
                        <Typography variant="h5" display="flex" alignItems="center">
                            {icon}
                            <FormattedMessage id={title} />

                            <Tooltip title={<FormattedMessage id="engagement.refresh" />} placement="right">
                                <IconButton
                                    size="small"
                                    onClick={handleRefreshClick}
                                    sx={{
                                        ml: 1,
                                        backgroundColor: `${theme.palette.secondary.main} !important`,
                                        cursor: 'pointer !important',
                                        color: 'white',
                                        '&:hover': { backgroundColor: `${theme.palette.secondary.light} !important` },
                                    }}
                                >
                                    <Refresh htmlColor="white" fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    }
                    filterBar={<FilterDrawer filters={[getDatasourceFilterSetting()]}></FilterDrawer>}
                    disableXPadding
                />
            }
            toolbarInSameLine={true}
            disableToolbarToggle={true}
        >
            <TicketTable tickets={tickets} state={state} emptyState={emptyState} tab={tab} />
        </ContentPanel>
    )
}
