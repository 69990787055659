import { FC, useState } from 'react'
import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Autorenew } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { selectProject } from '../core/slices/CoreSlice'
import { fetchCurrentUser } from '../settings/user-management/UserActions'
import { User } from '../settings/user-management/User'
import { fetchAccountsForUser } from '../settings/accounts/AccountActions'
import { fetchAllProjects } from '../settings/project-management/ProjectActions'
import { Project } from '../settings/project-management/Project'
import { getProducts } from '../core/slices/DataSlice'
import { Account } from '../settings/accounts/Account'
import { Product } from '../settings/accounts/Product'
import { useNavigate } from 'react-router-dom'

type OnboardingWizardPackageProps = {}

export const OnboardingPackageSelection: FC<OnboardingWizardPackageProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useNavigate()
    const products = useSelector(getProducts)
    const [loading, setLoading] = useState(false)
    const [notAdded, setNotAdded] = useState(false)

    const handleRefresh = () => {
        setNotAdded(false)
        setLoading(true)
        setTimeout(() => {
            dispatch(fetchCurrentUser()).then((action) => {
                const payload = action.payload as User
                if (payload && payload.accounts && payload.accounts.length > 0) {
                    dispatch(fetchAccountsForUser()).then(() => {
                        dispatch(fetchAllProjects()).then((action) => {
                            const projects = action.payload as Project[]

                            if (projects.length > 0) {
                                setLoading(false)
                                dispatch(selectProject(projects[0].id))
                                navigate(`/${projects[0].id}/publishing/calendar`)
                            } else {
                                setLoading(false)
                                setNotAdded(true)
                            }
                        })
                    })
                }

                setLoading(false)
                setNotAdded(true)
            })
        }, 5000)
    }

    const getProductByPackage = (packageType: Account['package_type']): Product => {
        return Object.values(products).find((p) => p.metadata.package === packageType) as Product
    }

    const trialProduct = getProductByPackage('trial')

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ fontSize: 16 }} color="secondary" textAlign="center">
                    <FormattedMessage id="onboarding.trial-hint" />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{ display: 'flex', flexDirection: 'column', justifiyContent: 'space-between' }}>
                            <Box height="fit-content" p={2} overflow="hidden" position="relative">
                                <Typography variant="h6" fontSize={24} sx={{ mb: 2 }}>
                                    <FormattedMessage id="onboarding.contingents-during-trial" />
                                </Typography>

                                <Box textAlign="left">
                                    <Box pl={3} mt={2}>
                                        <Typography fontWeight={600}>
                                            <FormattedMessage id="billing.users" values={{ value: trialProduct?.metadata.users }} />
                                        </Typography>
                                        <Typography fontWeight={600}>
                                            <FormattedMessage id="billing.datasources" values={{ value: trialProduct?.metadata.datasources }} />
                                        </Typography>
                                    </Box>
                                    <ul>
                                        <li>
                                            <Typography>
                                                <FormattedMessage id="features.analytics.unlimitedDashboards" />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography>
                                                <FormattedMessage id="features.analytics.templates" />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography>
                                                <FormattedMessage id="features.analytics.postAnalysis" />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography>
                                                <FormattedMessage id="features.analytics.crossNetworkReports" />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography>
                                                <FormattedMessage id="features.analytics.exportableReports" />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography>
                                                <FormattedMessage id="onboarding.features.plan-posts" />
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography>
                                                <FormattedMessage id="onboarding.features.social-media-inbox" />
                                            </Typography>
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper sx={{ backgroundColor: 'divider', p: 2 }} variant="outlined">
                            <Typography variant="h6" sx={{ mb: 1 }}>
                                <FormattedMessage id="onboarding.work-with-others" />
                            </Typography>
                            <Typography>
                                <FormattedMessage id="onboarding.work-with-others.hint" />
                            </Typography>

                            <Box marginTop={2} display="flex" alignItems="center" justifyContent="flex-end">
                                <Button
                                    startIcon={loading ? <CircularProgress color="inherit" size={20} /> : <Autorenew />}
                                    variant="outlined"
                                    color={'inherit'}
                                    onClick={handleRefresh}
                                    disabled={loading}
                                >
                                    <FormattedMessage id="onboarding.check-access" />
                                </Button>
                            </Box>
                            {notAdded && (
                                <Typography sx={{ pl: 1, pt: 1, color: 'error.main' }} fontSize={13}>
                                    <FormattedMessage id="onboarding.check-access-error" />
                                </Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
