import * as _ from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { getAccounts, getProducts } from '../../core/slices/DataSlice'
import { Box, Button, Chip, Grid, Link, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import moment from 'moment'
import { getBillingDataLoading } from '../../core/slices/CoreSlice'
import { Loading } from '../../common/loading/Loading'
import { getInvoicesForSelectedCustomer } from '../SettingsSlice'

type BillingInvoicesProps = {}

export const BillingInvoices: FC<BillingInvoicesProps> = () => {
    const isBillingDataLoading = useSelector(getBillingDataLoading)
    const invoices = useSelector(getInvoicesForSelectedCustomer)
    const accounts = useSelector(getAccounts)
    const products = useSelector(getProducts)
    const accountsBySubscriptionId = _.mapKeys(accounts, 'subscription_id')

    const getColorForInvoiceState = (invoiceStatus: string) => {
        switch (invoiceStatus) {
            case 'paid':
                return 'success'
            case 'open':
                return 'info'
            case 'void':
            default:
                return undefined
        }
    }

    if (isBillingDataLoading && Object.values(invoices).length === 0) {
        return <Loading />
    }

    return (
        <List>
            {Object.values(invoices).map((invoice) => {
                const invoiceLines = invoice.lines.data.map((d) => products[d.plan?.product || '']?.name).join(', ')

                return (
                    <ListItem key={invoice.id}>
                        <Box mr={3}>
                            <Typography>{moment.unix(invoice.created).format('L')}</Typography>
                        </Box>

                        <ListItemText secondary={invoiceLines || <FormattedMessage id="billing.custom-offer" />}>
                            <Link underline="hover" sx={{ cursor: 'pointer' }} href={invoice.hosted_invoice_url} target="_blank">
                                {invoice.number ? (
                                    <>
                                        {invoice.number}
                                        {accountsBySubscriptionId[invoice.subscription] &&
                                            ` (Account: "${accountsBySubscriptionId[invoice.subscription]?.name}")`}
                                    </>
                                ) : (
                                    invoiceLines || <FormattedMessage id="billing.custom-offer" />
                                )}
                            </Link>
                        </ListItemText>

                        <ListItemSecondaryAction>
                            <Box width={400}>
                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={6} minWidth={300}>
                                            {invoice.status === 'open' && (
                                                <Button
                                                    component={Link}
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    target="_blank"
                                                    href={invoice.hosted_invoice_url}
                                                >
                                                    <FormattedMessage id="Jetzt bezahlen" />
                                                </Button>
                                            )}
                                            {invoice.amount_due !== 0 && (
                                                <Chip
                                                    size="small"
                                                    color={getColorForInvoiceState(invoice.status)}
                                                    label={<FormattedMessage id={`billing.invoice.status.${invoice.status}`} />}
                                                />
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <Box minWidth={50} textAlign={'right'}>
                                            <FormattedNumber value={invoice.amount_due / 100} minimumFractionDigits={2} />
                                            &nbsp;€
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })}
        </List>
    )
}
