import * as React from 'react'
import { FC, useState } from 'react'
import { Box, Chip, Tooltip } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import CancelIcon from '@mui/icons-material/Cancel'
import { useSelector } from 'react-redux'
import { getIsMobile } from '../../core/slices/CoreSlice'

type FilterChipProps = {
    val?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    tooltip: string
    onDelete?: () => void
    icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
    onClick?: () => void
}

export const FilterChip: FC<FilterChipProps> = ({ val, tooltip, onDelete, icon, onClick }) => {
    const intl = useIntl()
    const [generalTooltipOpen, setGeneralTooltipOpen] = useState(false)
    const isMobile = useSelector(getIsMobile)

    return (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
            <Tooltip title={<FormattedMessage id={tooltip} />} disableInteractive open={isMobile ? false : generalTooltipOpen} placement={'left'}>
                <Chip
                    onMouseEnter={() => setGeneralTooltipOpen(true)}
                    onMouseLeave={() => setGeneralTooltipOpen(false)}
                    label={typeof val === 'string' ? intl.formatMessage({ id: val }) : val}
                    onDelete={onDelete}
                    onClick={onClick}
                    sx={{ maxWidth: '250px' }}
                    deleteIcon={
                        <Tooltip
                            title={<FormattedMessage id={'general.filter-reset'} />}
                            disableInteractive
                            placement={'left'}
                            open={isMobile ? false : undefined}
                        >
                            <CancelIcon fontSize={'small'} onMouseEnter={() => setGeneralTooltipOpen(false)} onMouseLeave={() => setGeneralTooltipOpen(true)} />
                        </Tooltip>
                    }
                    icon={icon}
                />
            </Tooltip>
        </Box>
    )
}
