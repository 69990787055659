import * as React from 'react'
import { FC, useEffect } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { getAiResults, getSelectedResultToRefine } from './PostTextGenerationSlice'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { isDarkMode } from '../../core/slices/CoreSlice'
import { useForm } from 'react-final-form'
import { SparkleBackground } from '../../common/ai/SparkleBackground'

type RefineResultDialogProps = {}

export const RefineResultDialog: FC<RefineResultDialogProps> = () => {
    const resultToRefine = useSelector(getSelectedResultToRefine)
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)
    const results = useSelector(getAiResults)
    const form = useForm()
    const hasResults = results.length > 0
    useEffect(() => {
        return () => {
            form.change('refine_request', undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                '& fieldset': {
                    borderRadius: theme.shape.borderRadius,
                },
                '& .Mui-focused fieldset': {
                    borderColor: `${darkmode ? theme.palette.info.dark : theme.palette.info.light} !important`,
                    borderWidth: '1px !important',
                },
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    p: 2,
                    border: `1px solid ${darkmode ? theme.palette.info.dark : theme.palette.info.light}`,
                    borderRadius: theme.shape.borderRadius,
                    background: theme.palette.background.paper,
                    overflow: 'hidden',
                    '& .particlesFullHeight': {
                        height: '100%',
                        zIndex: -1,
                    },
                }}
            >
                <SparkleBackground
                    colors={[theme.palette.info.main, theme.palette.info.light]}
                    sparkleKey={resultToRefine?.toString() || 'sparkleBG1'}
                    maxWidth={400}
                    maxHeight={200}
                    particleAmount={300}
                />
                <Typography color={'inherit'} sx={{ whiteSpace: 'break-spaces' }}>
                    {resultToRefine}
                </Typography>
            </Box>
            <ConnectedTextField
                updateKey={resultToRefine?.toString() + hasResults.toString()}
                name={'refine_request'}
                required
                disableMaxLength
                multiline
                variant={'outlined'}
                label={'ai.refine-label'}
                validate={(val: string | undefined) => {
                    if (!val && hasResults) {
                        return 'validations.required'
                    }
                }}
            />
        </Box>
    )
}
