import { Box, Typography } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

type FallbackProps = {
    condition: boolean
    messageId?: string
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2'
    actionButton?: JSX.Element
    marginTop?: number
    centered?: boolean
    icon?: ReactJSXElement
}

export const Fallback: FC<PropsWithChildren<FallbackProps>> = ({ children, condition, messageId, marginTop, actionButton, variant, centered, icon }) => {
    if (!condition) {
        return <>{children}</>
    }

    return (
        <Box
            textAlign="center"
            marginTop={marginTop || 0}
            sx={
                centered
                    ? {
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          gap: 3,
                      }
                    : {
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          gap: 3,
                      }
            }
        >
            {icon}
            {messageId && (
                <Typography variant={variant || 'subtitle2'} color="textSecondary" sx={{ maxWidth: '70%' }}>
                    <FormattedMessage id={messageId} />
                </Typography>
            )}

            {actionButton && <Box marginTop={4}>{actionButton}</Box>}
        </Box>
    )
}
