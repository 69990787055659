import { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { BillingSubscription } from './BillingSubscription'
import { useSelector } from 'react-redux'
import { getProducts } from '../../core/slices/DataSlice'
import moment from 'moment'
import { getBaseProductForSubscription } from './BillingUtils'

type BillingCancelSubscriptionDialogProps = {
    open: boolean
    onClose: () => any
    onSave: () => any
    subscription: BillingSubscription
}

export const BillingCancelSubscriptionDialog: FC<BillingCancelSubscriptionDialogProps> = (props) => {
    const { open, onClose, subscription, onSave } = props
    const products = useSelector(getProducts)
    const basePackage = getBaseProductForSubscription(subscription, products)

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" keepMounted={false}>
            <DialogTitle>
                <FormattedMessage id="billing.cancel-account" />
                <br />
                <Typography component="span" variant={'subtitle2'}>
                    {basePackage?.name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage
                        id="billing.cancel-account-details"
                        values={{
                            date: moment.unix(subscription.current_period_end).format('L'),
                        }}
                    />
                    <br />
                    <br />
                    <FormattedMessage id="billing.extend-if-you-want" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage id="general.close" />
                </Button>
                <Button color="error" variant="contained" onClick={onSave}>
                    <FormattedMessage id="billing.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
