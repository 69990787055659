import * as React from 'react'
import { FC } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import { getConversionTrackingAdsets, getConversionTrackingSelectedAdsetIds, selectAdsetIds } from './ConversionTrackingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getAdsetSelectOpen, setAdsetSelectOpen } from '../common/filterbar/FilterSlice'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

type ConversionTrackingAdsetSelectProps = {}

export const renderAdsetValueAsString = (ids: string[], adsets: { id: string; name: string }[], intl: IntlShape) => {
    if (ids.length === 0) {
        return intl.formatMessage({ id: 'conversion-tracking.all-adsets' })
    }

    if (ids.length === 1) {
        return adsets.find((c) => c.id === ids[0])?.name || '1 ' + intl.formatMessage({ id: 'conversion-tracking.adset' })
    }

    return ids.length + ' ' + intl.formatMessage({ id: 'conversion-tracking.adsets' })
}

export const ConversionTrackingAdsetSelect: FC<ConversionTrackingAdsetSelectProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const adsets = useSelector(getConversionTrackingAdsets)
    const selectedAdsetIds = useSelector(getConversionTrackingSelectedAdsetIds)
    const open = useSelector(getAdsetSelectOpen)

    return (
        <FormControl fullWidth>
            <InputLabel id="adsets-label" variant="outlined">
                <FormattedMessage id="conversion-tracking.adsets" />
            </InputLabel>
            <Select
                open={open}
                labelId="adsets-label"
                onOpen={() => dispatch(setAdsetSelectOpen(true))}
                onClose={() => dispatch(setAdsetSelectOpen(false))}
                value={selectedAdsetIds}
                multiple
                input={<OutlinedInput label={<FormattedMessage id="conversion-tracking.adsets" />} />}
                renderValue={(selected) => renderAdsetValueAsString(selected, adsets, intl)}
                onChange={({ target: { value } }: any) => dispatch(selectAdsetIds(typeof value === 'string' ? value.split(',') : value))}
            >
                {adsets.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                        <Checkbox checked={selectedAdsetIds.includes(c.id)} />
                        <ListItemText primary={c.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
