import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { FC } from 'react'

export const dynamicMonthOptions: string[] = ['current_month', 'last_month', 'next_to_last_month']

type DynamicSelectionProps = {
    label: string
    baseValue: 'current_month' | 'last_month' | 'next_to_last_month'
    onChange: (value: any) => void
}

export const RenderDynamicSelection: FC<DynamicSelectionProps> = ({ label, baseValue, onChange }) => {
    const intl = useIntl()

    const selectProps: SelectProps<any> = {
        labelId: 'label-' + label,
        label: label,
        onChange: (e) => onChange(e.target.value),
        variant: 'outlined',
        value: baseValue,
    }

    return (
        <FormControl sx={{ minWidth: 230 }} fullWidth={true}>
            <InputLabel id={selectProps.labelId} variant={selectProps.variant}>
                <FormattedMessage id={label} />
            </InputLabel>
            <Select {...selectProps} label={intl.formatMessage({ id: label })}>
                {dynamicMonthOptions.map((key) => {
                    return (
                        <MenuItem value={key} key={key}>
                            <FormattedMessage id={'reporting.dashboards.' + key} />
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
