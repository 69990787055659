import { FC, useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material'
import { Form } from 'react-final-form'
import { ConnectedTextField } from '../../../form/ConnectedTextField'
import { validationRequired } from '../../../form/validate'
import { AccountFormValues } from '../AccountFormValues'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedAccount, showSuccessSnackbar } from '../../../core/slices/CoreSlice'
import { FormattedMessage } from 'react-intl'
import { updateAccount } from '../AccountActions'
import { HmstrDispatch } from '../../../core/Store'
import { useHasAccountPermission } from '../../../core/hooks/useHasPermission'

type AccountEditFormProps = {}

export const AccountOverviewMetadata: FC<AccountEditFormProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const [dialogOpen, setDialogOpen] = useState(false)
    const account = useSelector(getSelectedAccount)
    const canUpdateAccount = useHasAccountPermission('account.update', account?.id)

    const handleSubmit = (values: AccountFormValues) => {
        return new Promise((resolve) => {
            dispatch(updateAccount({ values, account })).then(() => {
                dispatch(showSuccessSnackbar('accounts.updated'))
                setDialogOpen(false)
                resolve(true)
            })
        })
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Typography color="text.secondary" gutterBottom>
                        <FormattedMessage id="account" />
                    </Typography>
                    <Typography variant="h6">{account.name}</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Tooltip placement="top" title={canUpdateAccount ? '' : <FormattedMessage id="billing.change.only-owner" />}>
                        <span>
                            <Button color="inherit" onClick={() => setDialogOpen(true)} disabled={!canUpdateAccount}>
                                <FormattedMessage id="accounts.edit" />
                            </Button>
                        </span>
                    </Tooltip>
                </CardActions>
            </Card>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <Form<AccountFormValues>
                    onSubmit={handleSubmit}
                    initialValues={{
                        name: account.name,
                    }}
                    render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle>
                                <FormattedMessage id="accounts.edit" />
                            </DialogTitle>
                            <DialogContent>
                                <Box minWidth={400}>
                                    <ConnectedTextField name="name" label="accounts.name" required validate={validationRequired} />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <DialogActions>
                                    <Button color={'inherit'} variant="text" onClick={() => setDialogOpen(false)}>
                                        <FormattedMessage id="general.close" />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={submitting}
                                        startIcon={submitting ? <CircularProgress color="inherit" size={20} /> : undefined}
                                    >
                                        <FormattedMessage id="general.save" />
                                    </Button>
                                </DialogActions>
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>
        </Grid>
    )
}
