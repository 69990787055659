export enum PostAnalysisSortType {
    NAME = 'NAME',
    AMOUNT = 'AMOUNT',
    REACTIONS = 'REACTIONS',
    COMMENTS = 'COMMENTS',
    SHARES = 'SHARES',
    RKS = 'RKS',
    ENGAGEMENTRATE = 'ENGAGEMENTRATE',
    IMPRESSIONS = 'IMPRESSIONS',
    ENGANGEMENT = 'ENGANGEMENT',
    SPEND = 'SPEND',
}
