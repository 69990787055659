import * as React from 'react'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { getSelectedPostGroupFailedPosts } from './PublishingSlice'
import { Alert, Box, Typography } from '@mui/material'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { getDatasourcesAsIdMap } from '../core/slices/CoreSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'

type FailedPostsAlertProps = {}

export const FailedPostsAlert: FC<FailedPostsAlertProps> = () => {
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const postsWithErrors = useSelector(getSelectedPostGroupFailedPosts)
    let postsWithErrorsByDatasourceId: { [dsid: string]: string | null | undefined } = {}
    _.forEach(postsWithErrors, (post) => {
        postsWithErrorsByDatasourceId[post.data_source_id] = post.error_message
    })
    const datasourcesWithErrors = postsWithErrors.map((post) => {
        return !!datasourcesById[post.data_source_id] ? datasourcesById[post.data_source_id] : undefined
    }) as ProjectDatasource[]
    const datasourcesWithErrorsMappedByType = _.chain(datasourcesWithErrors)
        .groupBy((datasource) => {
            return datasource.type
        })
        .value()

    return !!postsWithErrors.length ? (
        <Alert severity={'error'}>
            {_.map(Object.keys(datasourcesWithErrorsMappedByType), (networkType) => {
                return (
                    <Box key={networkType}>
                        <Typography fontWeight={600}>
                            {' '}
                            <FormattedMessage id={networkType} />:
                        </Typography>
                        <ul
                            style={{
                                padding: 0,
                                margin: 0,
                                marginLeft: 24,
                            }}
                        >
                            {_.map(datasourcesWithErrorsMappedByType[networkType], (datasource) => {
                                return (
                                    <li key={datasource.id}>
                                        {datasource.name}:&nbsp;
                                        {postsWithErrorsByDatasourceId[datasource.id] ? (
                                            postsWithErrorsByDatasourceId[datasource.id]!.toString()
                                        ) : (
                                            <FormattedMessage id={'general.na'} />
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    </Box>
                )
            })}
        </Alert>
    ) : (
        <></>
    )
}
