import * as React from 'react'
import { FC, Fragment } from 'react'
import { KeyDisplay } from './KeyDisplay'
import { Box } from '@mui/material'

type HotkeyTooltipProps = { keys: string[]; invert?: boolean; fontSize?: number }

export const HotkeyTooltip: FC<HotkeyTooltipProps> = ({ keys, invert, fontSize }) => {
    return (
        <Box display={'flex'} alignItems={'center'} gap={1} flexShrink={0} fontSize={`${fontSize || 16}px`}>
            {keys.map((keyboardKey, idx) => {
                return idx === keys.length - 1 ? (
                    <Box display={'inline-block'} key={keyboardKey}>
                        <KeyDisplay keyboardKey={keyboardKey} invert={invert} fontSize={fontSize} />
                    </Box>
                ) : (
                    <Fragment key={keyboardKey}>
                        <Box display={'inline-block'}>
                            <KeyDisplay keyboardKey={keyboardKey} invert={invert} fontSize={fontSize} />
                        </Box>
                        {' + '}
                    </Fragment>
                )
            })}
        </Box>
    )
}
