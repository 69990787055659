import * as React from 'react'
import { FC, PropsWithChildren } from 'react'
import { FormControl, Select, styled } from '@mui/material'
import { Field } from 'react-final-form'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { Translate } from '@mui/icons-material'

type ConnectedSimpleLanguageDropdownProps = {
    name: string
    fullWidth?: boolean
    onChange?: (val: string) => void
    disabled?: boolean
    defaultValue?: string
}

export const StyledLanguageSelect = styled(Select as any)(() => ({
    display: 'flex',
    gap: '8px',
})) as any as typeof Select

export const ConnectedSimpleLanguageDropdown: FC<PropsWithChildren<ConnectedSimpleLanguageDropdownProps>> = ({
    name,
    fullWidth,
    onChange,
    disabled,
    children,
    defaultValue,
}) => {
    return (
        <Field name={name} defaultValue={defaultValue}>
            {({ input, meta }) => {
                const handleChange = (event: SelectChangeEvent) => {
                    input.onChange(event)

                    if (onChange) {
                        onChange(event.target.value)
                    }
                }

                return (
                    <FormControl
                        error={meta.error && (meta.touched || meta.dirty || meta.initial !== undefined)}
                        fullWidth={fullWidth !== false}
                        disabled={disabled}
                    >
                        <StyledLanguageSelect
                            startAdornment={<Translate fontSize={'small'} />}
                            displayEmpty={true}
                            value={input.value || ''}
                            onChange={handleChange}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            labelId={'select-' + name}
                            fullWidth={fullWidth !== false}
                            error={meta.error && (meta.touched || meta.dirty || meta.initial !== undefined)}
                        >
                            {children}
                        </StyledLanguageSelect>
                    </FormControl>
                )
            }}
        </Field>
    )
}
