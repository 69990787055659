import { Dashboard } from './Dashboard'
import { Box, Divider, Tooltip, Typography } from '@mui/material'
import { HmstrColors } from '../core/theme/hmstr-theme'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { updateUserSettings } from '../settings/user-management/UserActions'
import { getSelectedDashboard, getSortedDashboards, selectDashboard } from './DashboardsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getLastModulePages } from '../core/slices/CoreSlice'
import { useIntl } from 'react-intl'
import { HmstrDispatch } from '../core/Store'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'

export const FavoriteDashboardsList = () => {
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()

    const currentUser = useSelector(getCurrentUser)
    const dashboards = useSelector(getSortedDashboards)
    const selectedDashboard = useSelector(getSelectedDashboard)
    const lastModulePages = useSelector(getLastModulePages)
    const dashboardViewOpen = !!selectedDashboard && lastModulePages.analytics.includes(selectedDashboard.id)

    const handleSelectDashboard = (dashboard: Dashboard) => {
        dispatch(selectDashboard(dashboard))
        navigate(`/analytics/dashboards/${dashboard.id}`)
    }

    const updateDashboardFavorites = (dashboard: Dashboard) => {
        if (!currentUser.settings.favorite_dashboard_ids.includes(dashboard.id)) {
            let newFavorites = [...currentUser.settings.favorite_dashboard_ids, dashboard.id]
            dispatch(
                updateUserSettings({
                    settings: { ...currentUser.settings, favorite_dashboard_ids: newFavorites },
                    user: currentUser,
                })
            )
        } else {
            let newFavorites = currentUser.settings.favorite_dashboard_ids.filter((dashboardId) => dashboardId !== dashboard.id)
            dispatch(
                updateUserSettings({
                    settings: { ...currentUser.settings, favorite_dashboard_ids: newFavorites },
                    user: currentUser,
                })
            )
        }
    }

    const dashboardLabel = (dashboard: Dashboard, active?: boolean) => {
        let labelStyleProps = active
            ? {
                  color: HmstrColors.Orange,
                  wordBreak: 'break-all',
                  whiteSpace: 'break-spaces',
              }
            : {}

        const inner = (
            <Typography
                variant={'body1'}
                noWrap={!active}
                sx={{
                    marginRight: '8px',
                    cursor: !active ? 'pointer' : 'default',
                    '&:hover': {
                        color: HmstrColors.Orange,
                    },
                    ...labelStyleProps,
                }}
                onClick={() => {
                    if (!active) {
                        handleSelectDashboard(dashboard)
                    }
                }}
            >
                {dashboard.name}
            </Typography>
        )

        if (!active) {
            return (
                <Tooltip placement="top" arrow={true} title={dashboard.name} disableInteractive>
                    {inner}
                </Tooltip>
            )
        }
        return inner
    }

    const dashboardIcon = (dashboard: Dashboard, active?: boolean) => {
        let iconProps = {
            sx: {
                color: active ? HmstrColors.Orange : '',
            },
        }

        let hoverProps = {
            onClick: () => updateDashboardFavorites(dashboard),
            sx: {
                cursor: 'pointer',
                color: active ? HmstrColors.Orange : '',
                '> .star-default': {
                    display: 'inline-block',
                },
                '> .star-hover': {
                    display: 'none',
                },
                '&:hover': {
                    '> .star-default': {
                        display: 'none',
                    },
                    '> .star-hover': {
                        display: 'inline-block',
                    },
                },
            },
        }

        if (!currentUser.settings.favorite_dashboard_ids.includes(dashboard.id)) {
            return (
                <Box {...hoverProps}>
                    <StarIcon className={'star-hover'} {...iconProps} color={'action'} />
                    <StarBorderIcon className={'star-default'} {...iconProps} color={'action'} />
                </Box>
            )
        }

        return (
            <Box {...hoverProps}>
                <StarIcon className={'star-default'} {...iconProps} color={'action'} />
                <StarBorderIcon className={'star-hover'} {...iconProps} color={'action'} />
            </Box>
        )
    }

    const favoriteDashboardRow = (dashboard: Dashboard, active?: boolean) => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '8px',
                }}
                key={dashboard.id + '-favorite-row'}
            >
                {dashboardLabel(dashboard, active)}
                {dashboardIcon(dashboard, active)}
            </div>
        )
    }

    const inactiveFavorites = currentUser.settings.favorite_dashboard_ids.filter((dashboardId) => {
        const inactiveFavorite = dashboards.find((dashboard) => dashboard.id === dashboardId)
        if (!dashboardViewOpen) {
            return inactiveFavorite
        }
        return !!inactiveFavorite && ((!!selectedDashboard && selectedDashboard.id !== dashboardId) || !selectedDashboard)
    })

    const inactiveFavoritesElements = inactiveFavorites.map((dashboardId) => {
        const inactiveFavorite = dashboards.find((dashboard) => dashboard.id === dashboardId)
        return favoriteDashboardRow(inactiveFavorite!)
    })

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '80%',
                    padding: '8px 16px 8px 8px',
                }}
            >
                {dashboardViewOpen && favoriteDashboardRow(selectedDashboard, true)}
                {inactiveFavoritesElements.length > 0 && (
                    <>
                        <Typography>{intl.formatMessage({ id: 'reporting.dashboards.favorites' })}</Typography>
                        <Divider orientation={'horizontal'} style={{ marginBottom: '8px' }} />
                        {inactiveFavoritesElements}
                        <Divider orientation={'horizontal'} />
                    </>
                )}
            </div>
        </div>
    )
}
