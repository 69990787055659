import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getCurrentUserPermissions, getSelectedProjectPermissions } from '../slices/CoreSlice'

export const useHasAnyDatasourcePermission = (permission: string): boolean => {
    const permissionsForSelectedProject = useSelector(getSelectedProjectPermissions)

    return _.chain(permissionsForSelectedProject).values().flatten().value().includes(permission)
}

export const useHasDatasourcePermission = (permission: string, datasource_ids?: string | string[]) => {
    const permissionsForSelectedProject = useSelector(getSelectedProjectPermissions)

    if (!datasource_ids) {
        return false
    }

    function checkPermissionForDatasourceId(datasource_id: string) {
        if (!permissionsForSelectedProject) {
            return false
        }

        const hasDatasourcePermissions = Object.keys(permissionsForSelectedProject).includes(datasource_id)
        return hasDatasourcePermissions && permissionsForSelectedProject[datasource_id].includes(permission)
    }

    if (typeof datasource_ids === 'string') {
        return checkPermissionForDatasourceId(datasource_ids)
    } else {
        if (datasource_ids.length === 0) {
            return false
        }

        for (let i = 0; i < datasource_ids.length; i++) {
            const hasPermission = checkPermissionForDatasourceId(datasource_ids[i])

            if (!hasPermission) {
                return false
            }
        }

        return true
    }
}

export const useHasProjectPermission = (permission: string, project_id?: string) => {
    const userPermissions = useSelector(getCurrentUserPermissions)

    if (!project_id) {
        return false
    }

    return (userPermissions.project_permissions[project_id] || []).includes(permission)
}

export const useHasAccountPermission = (permission: string, account_id?: string) => {
    const userPermissions = useSelector(getCurrentUserPermissions)

    if (!account_id) {
        return false
    }

    return (userPermissions.account_permissions[account_id] || []).includes(permission)
}

export const useHasUserPermission = (permission: string) => {
    const userPermissions = useSelector(getCurrentUserPermissions)
    return (userPermissions.user_permissions || []).includes(permission)
}
