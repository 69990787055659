import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type InstagramCommentProps = SvgIconProps

export const InstagramComment: FC<InstagramCommentProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    fill: 'rgb(115,115,115) !important',
                    color: 'rgb(115,115,115) !important',
                },
            }}
        >
            <svg aria-label="Comment" className="x1lliihq x1n2onr6" height="24" role="img" viewBox="0 0 24 24" width="24">
                <title>Comment</title>
                <path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></path>
            </svg>
        </SvgIcon>
    )
}
