import { Post } from './posts/Post'
import moment from 'moment'

export const getRelevantPostState = (posts: Post[]): Post['state'] | 'PLANNED_BY_FACEBOOK' => {
    const anyPublishFailed = !!posts?.find((p) => p.state === 'PUBLISH_FAILED')
    const anyInProgress = !!posts?.find((p) => p.state === 'PUBLISH_IN_PROGRESS')
    const postPlannedByFacebook = (posts || []).find((p) => {
        // Check if publish time is set and post is not draft or publish failed
        return p.fb_publish_time !== undefined && !(p.state === 'DRAFT' || p.state === 'PUBLISH_FAILED')
    })
    const plannedByFacebook = postPlannedByFacebook && moment.utc(postPlannedByFacebook.fb_publish_time).isAfter(moment())
    const isPlannedByFacebookAndPublished = postPlannedByFacebook && moment.utc(postPlannedByFacebook.fb_publish_time).isBefore(moment())

    if (!posts) {
        return 'PLANNED'
    }

    if (anyPublishFailed) {
        return 'PUBLISH_FAILED'
    }

    if (anyInProgress) {
        return 'PUBLISH_IN_PROGRESS'
    }

    if (plannedByFacebook) {
        return 'PLANNED_BY_FACEBOOK'
    }

    if (isPlannedByFacebookAndPublished) {
        return 'PUBLISH_SUCCESS'
    }

    if (posts.length > 0) {
        return posts[0].state
    }

    return 'PLANNED'
}
