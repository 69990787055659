import { FC, PropsWithChildren } from 'react'
import { Box, Chip, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { AccountBalance, CreditCard } from '@mui/icons-material'
import { PaymentMethod } from '@stripe/stripe-js'
import { FormattedMessage } from 'react-intl'

type BillingPaymentMethodItemProps = {
    paymentMethod: PaymentMethod
    onSelect?: () => any
    selected?: boolean
}

export const BillingPaymentMethodItem: FC<PropsWithChildren<BillingPaymentMethodItemProps>> = (props) => {
    const { paymentMethod, children, onSelect, selected } = props

    const getText = () => {
        switch (paymentMethod.type) {
            case 'sepa_debit':
                return (
                    <ListItemText
                        primary={
                            <FormattedMessage
                                id="billing.payment-methods.sepa"
                                values={{
                                    last4digits: paymentMethod.sepa_debit?.last4,
                                }}
                            />
                        }
                        secondary={
                            <FormattedMessage
                                id="billing.payment-methods.sepa-bankcode"
                                values={{
                                    bankcode: paymentMethod.sepa_debit?.bank_code,
                                    country: paymentMethod.sepa_debit?.country,
                                }}
                            />
                        }
                    />
                )
            case 'card':
            default:
                return (
                    <ListItemText
                        primary={
                            <FormattedMessage
                                id="billing.payment-methods.creditcard"
                                values={{
                                    brand: paymentMethod.card?.brand.toLocaleUpperCase(),
                                    last4digits: paymentMethod.card?.last4,
                                }}
                            />
                        }
                        secondary={
                            <FormattedMessage
                                id="billing.payment-methods.creditcard-expires"
                                values={{
                                    month: paymentMethod.card?.exp_month,
                                    year: paymentMethod.card?.exp_year,
                                }}
                            />
                        }
                    />
                )
        }
    }

    return (
        <ListItem key={paymentMethod.id} button={(Boolean(onSelect) ? true : undefined) as any} selected={selected} onClick={onSelect}>
            <ListItemIcon>{paymentMethod.type === 'card' ? <CreditCard /> : <AccountBalance />}</ListItemIcon>

            <Box display="flex" alignItems="center">
                {getText()}

                {paymentMethod.default && <Chip sx={{ ml: 2 }} label={<FormattedMessage id="general.default" />} color="info" variant="filled" size="small" />}
            </Box>

            {children}
        </ListItem>
    )
}
