import { FC } from 'react'
import { ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'

type SecondaryNavigationButtonProps = {
    path: string
    icon: JSX.Element
    title: string
    hasSubRoutes?: boolean
    badge?: JSX.Element
    id?: string
    onClick?: () => any
}

export const SecondaryNavigationButton: FC<SecondaryNavigationButtonProps> = ({ path, title, icon, badge, id, onClick }) => {
    const navigate = useProjectNavigate()
    const location = useLocation()

    const handleClick = () => {
        navigate(path)

        if (onClick) {
            onClick()
        }
    }

    return (
        <ListItemButton id={id} onClick={handleClick} selected={location.pathname.includes(path)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>
                <FormattedMessage id={title} />
            </ListItemText>
            {badge && <ListItemSecondaryAction>{badge}</ListItemSecondaryAction>}
        </ListItemButton>
    )
}
