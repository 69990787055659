import * as React from 'react'
import { FC } from 'react'
import _ from 'lodash'
import { NotificationProjectEntry } from './NotificationProjectEntry'
import { SettingsSubmitButton } from './SettingsSubmitButton'
import { Box, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { getAccounts, getProjects } from '../../core/slices/DataSlice'
import { ConfirmDialog } from '../../common/confirm-dialog/ConfirmDialog'
import { useForm, useFormState } from 'react-final-form'
import { Save } from '@mui/icons-material'
import { NotificationSettingsTabType } from './NotificationSettingsTab'
import { useBlocker } from 'react-router-dom'

type NotificationSettingsComponentProps = {
    tab: NotificationSettingsTabType
}

export const NotificationSettingsComponent: FC<NotificationSettingsComponentProps> = ({ tab }) => {
    const projects = useSelector(getProjects)
    const accounts = useSelector(getAccounts)
    const formState = useFormState()
    const form = useForm()
    const hasMultipleAccounts = Object.keys(accounts).length > 1
    const blocker = useBlocker(formState.dirty)

    const handleConfirm = () => {
        form.submit()?.then(() => {
            if (blocker.state === 'blocked') {
                blocker.proceed()
            }
        })
    }

    const handleDiscard = () => {
        if (blocker.state === 'blocked') {
            blocker.proceed()
        }
    }

    const handleCancel = () => {
        if (blocker.state === 'blocked') {
            blocker.reset()
        }
    }

    return (
        <Box display="flex" flexDirection="column" gap={2} width="100%">
            {projects &&
                _.sortBy(projects, (project) => project.name.toLowerCase()).map((project) => {
                    return (
                        <NotificationProjectEntry
                            project={project}
                            accountName={!hasMultipleAccounts ? accounts[project.account_id]?.name : undefined}
                            tab={tab}
                            key={project.id}
                        />
                    )
                })}
            <SettingsSubmitButton tab={tab} />
            <ConfirmDialog
                open={blocker.state === 'blocked'}
                onConfirm={handleConfirm}
                onDismiss={handleCancel}
                onClose={handleDiscard}
                confirmButtonDisabled={formState.submitting}
                confirmIcon={formState.submitting ? <CircularProgress color="inherit" size={20} /> : <Save />}
                title="general.unsaved-changes"
                abortText="general.discard"
                confirmText="general.save"
            />
        </Box>
    )
}
