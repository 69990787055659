import * as React from 'react'
import { FC, useState } from 'react'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Paper, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeOpinionLeaderSorting,
    changeSelectedDatasourceIds,
    changeSelectedMediaTypes,
    changeTimePeriod,
    getAvailableMediaTypes,
    getOpinionLeaders,
    getOpinionLeaderSortBy,
    getOpinionLeaderSortDirection,
    getResultDatasources,
    getSelectedDatasourceIds,
    getSelectedMediaTypes,
    openPreviewDialog,
} from './ResultsSlice'
import { ResultDatasourceFilter } from './ResultDatasourceFilter'
import { ResultMediatypeFilter } from './ResultMediatypeFilter'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { fetchResultsForProject } from './ResultsActions'
import { getSelectedProject } from '../core/slices/CoreSlice'
import { DateRangePicker } from '../common/daterange-picker/DateRangePicker'
import { HmstrDispatch } from '../core/Store'
import moment from 'moment/moment'
import { TitlebarWithFilters } from '../common/filterbar/TitlebarWithFilters'
import { FilterDrawer, FilterSetting } from '../common/filterbar/FilterDrawer'
import { renderTimePeriodString } from '../resources/translations/Helper'
import { CalendarToday, Language, Web } from '@mui/icons-material'
import {
    getDatasourceSelectionOpen,
    getMediaTypeSelectOpen,
    getTimePeriodSettingOpen,
    setDatasourceSelectionOpen,
    setMediaTypeSelectOpen,
    setTimePeriodSettingOpen,
} from '../common/filterbar/FilterSlice'
import _ from 'lodash'

type OpinionLeadersProps = {}

export const OpinionLeaders: FC<OpinionLeadersProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedProject = useSelector(getSelectedProject)
    const [startDate, setStartDate] = useState<string>(moment().subtract(30, 'days').toISOString())
    const [endDate, setEndDate] = useState<string>(moment().toISOString())
    const opinionLeaders = useSelector(getOpinionLeaders)
    const sortBy = useSelector(getOpinionLeaderSortBy)
    const sortDirection = useSelector(getOpinionLeaderSortDirection)
    const availableMediaTypes = useSelector(getAvailableMediaTypes)
    const selectedMediaTypes = useSelector(getSelectedMediaTypes)
    const datasources = useSelector(getResultDatasources)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)

    const datasourceFilterOpen = useSelector(getDatasourceSelectionOpen)
    const handleDatasourceFilterOpen = () => dispatch(setDatasourceSelectionOpen(true))
    const handleDatasourceFilterClose = () => dispatch(setDatasourceSelectionOpen(false))

    const timeperiodOpen = useSelector(getTimePeriodSettingOpen)
    const handleTimePeriodOpen = () => dispatch(setTimePeriodSettingOpen(true))
    const handleTimePeriodClose = () => dispatch(setTimePeriodSettingOpen(false))

    const mediaTypeSelectOpen = useSelector(getMediaTypeSelectOpen)
    const handleMediaTypeSelectOpen = () => dispatch(setMediaTypeSelectOpen(true))
    const handleMediaTypeSelectClose = () => dispatch(setMediaTypeSelectOpen(false))

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchResultsForProject(selectedProject))
        }
    }, [selectedProject, startDate, endDate])

    const handleRowClick = (domain: string) => {
        dispatch(openPreviewDialog({ domain, allowDrilldown: false }))
    }

    const handleSortChange = (newSortby: string) => {
        dispatch(
            changeOpinionLeaderSorting({
                sortBy: newSortby,
                sortDirection: newSortby === sortBy ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'desc',
            })
        )
    }

    const getTimePeriodSetting = () => {
        const defaultStartDate = moment().subtract(30, 'day').format('YYYY-MM-DD')
        const defaultEndDate = moment().format('YYYY-MM-DD')
        const hasChangedFromDefault = !(
            moment(defaultStartDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') &&
            moment(defaultEndDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')
        )
        const onChipClick = () => {
            setTimeout(handleTimePeriodOpen, 300)
        }

        const getTimePeriodResetFunction = () => {
            return () => {
                setStartDate(defaultStartDate)
                setEndDate(defaultEndDate)
                dispatch(changeTimePeriod({ startDate: defaultStartDate, endDate: defaultEndDate }))
            }
        }

        const set: FilterSetting = {
            settingInput: (
                <DateRangePicker
                    open={timeperiodOpen}
                    onOpen={handleTimePeriodOpen}
                    onClose={handleTimePeriodClose}
                    variant={'outlined'}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(startDate, endDate) => {
                        setStartDate(startDate)
                        setEndDate(endDate)
                        dispatch(changeTimePeriod({ startDate, endDate }))
                    }}
                />
            ),
            tooltip: 'filters.timeperiod',
            important: true,
            currentValueAsString: renderTimePeriodString(startDate, endDate, intl),
            icon: <CalendarToday fontSize={'small'} />,
            hasChangedFromDefault: hasChangedFromDefault,
            onDeleteFunction: getTimePeriodResetFunction(),
            onClickFunction: onChipClick,
            deletable: true,
        }
        return set
    }

    const getResultMediaTypeFilter = () => {
        const defaultValue: string[] = []
        const isEqual = _.isEqual(_.sortBy(selectedMediaTypes), _.sortBy(defaultValue))

        const handleChange = (event: SelectChangeEvent<any>, newValue: any) => {
            const currentValues = event.target.value.filter((e: any) => e !== undefined)
            const value = newValue.key.substr(2)
            const isSubHeader = value === 'OTHER' ? false : !value.includes(',')

            if (isSubHeader) {
                const newValues = [...currentValues]
                const subGroup = availableMediaTypes[value]
                const allSelected = _.intersection(selectedMediaTypes, subGroup).length === subGroup.length

                dispatch(changeSelectedMediaTypes(_.uniq(allSelected ? _.pullAll(newValues, subGroup) : [...newValues, ...subGroup])))
            } else {
                dispatch(changeSelectedMediaTypes(currentValues))
            }
        }

        const renderValue = () => {
            if (selectedMediaTypes.length === 0) {
                return intl.formatMessage({ id: 'filters.all-mediatypes' })
            }

            return selectedMediaTypes.map((mt) => intl.formatMessage({ id: mt })).join(', ')
        }
        const onChipClick = () => {
            setTimeout(handleMediaTypeSelectOpen, 300)
        }
        const onReset = () => {
            dispatch(changeSelectedMediaTypes([]))
        }

        const set: FilterSetting = {
            settingInput: (
                <ResultMediatypeFilter
                    open={mediaTypeSelectOpen}
                    onOpen={handleMediaTypeSelectOpen}
                    onClose={handleMediaTypeSelectClose}
                    onChange={handleChange}
                    fullWidth
                    value={selectedMediaTypes}
                />
            ),
            tooltip: 'filters.mediatypes',
            important: false,
            currentValueAsString: renderValue(),
            hasChangedFromDefault: !isEqual,
            icon: <Language fontSize={'small'} />,
            deletable: true,
            onDeleteFunction: onReset,
            onClickFunction: onChipClick,
        }
        return set
    }
    const getDatasourceFilter = () => {
        const defaultValue: string[] = []
        const isEqual = _.isEqual(_.sortBy(selectedDatasourceIds), _.sortBy(defaultValue))

        const renderValue = () => {
            return selectedDatasourceIds.length === 1
                ? datasources.find((project) => project.id === selectedDatasourceIds[0])?.name
                : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selectedDatasourceIds.length })
        }
        const onChipClick = () => {
            setTimeout(handleDatasourceFilterOpen, 300)
        }
        const onReset = () => {
            dispatch(changeSelectedDatasourceIds([]))
        }

        const set: FilterSetting = {
            settingInput: (
                <ResultDatasourceFilter
                    fullWidth
                    open={datasourceFilterOpen}
                    onOpen={handleDatasourceFilterOpen}
                    onClose={handleDatasourceFilterClose}
                    onChange={(val: string[]) => dispatch(changeSelectedDatasourceIds(val))}
                    value={selectedDatasourceIds}
                />
            ),
            tooltip: 'filters.datasource',
            important: false,
            currentValueAsString: renderValue(),
            hasChangedFromDefault: !isEqual,
            deletable: true,
            icon: <Web fontSize={'small'} />,
            onDeleteFunction: onReset,
            onClickFunction: onChipClick,
        }
        return set
    }

    return (
        <ContentPanel
            fullWidthTitle
            disableToolbarToggle
            title={
                <TitlebarWithFilters
                    title={'opinions.title'}
                    filterBar={<FilterDrawer filters={[getTimePeriodSetting(), getDatasourceFilter(), getResultMediaTypeFilter()]} />}
                    filterBarInSameLine
                />
            }
        >
            <Box sx={{ pb: 2 }}>
                <Paper sx={{ overflowX: 'auto' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="opinions.ranking" />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'domain'} direction={sortDirection} onClick={() => handleSortChange('domain')}>
                                        <FormattedMessage id="opinions.title" />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'source_type'} direction={sortDirection} onClick={() => handleSortChange('source_type')}>
                                        <FormattedMessage id="results.mediatype" />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'total'} direction={sortDirection} onClick={() => handleSortChange('total')}>
                                        <FormattedMessage id="results.results" />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'reach'} direction={sortDirection} onClick={() => handleSortChange('reach')}>
                                        <FormattedMessage id="reach" />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'engagement'} direction={sortDirection} onClick={() => handleSortChange('engagement')}>
                                        <FormattedMessage id="engagement" />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{ px: 0 }}>
                                    <TableSortLabel active={sortBy === 'positive'} direction={sortDirection} onClick={() => handleSortChange('positive')}>
                                        <FormattedMessage id="results.positive" />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{ px: 0 }}>
                                    <TableSortLabel active={sortBy === 'neutral'} direction={sortDirection} onClick={() => handleSortChange('neutral')}>
                                        <FormattedMessage id="results.neutral" />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{ px: 0 }}>
                                    <TableSortLabel active={sortBy === 'negative'} direction={sortDirection} onClick={() => handleSortChange('negative')}>
                                        <FormattedMessage id="results.negative" />
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {opinionLeaders.map((value, index) => (
                                <TableRow key={value.domain} sx={{ cursor: 'pointer' }} hover onClick={() => handleRowClick(value.domain)}>
                                    <TableCell># {index + 1}</TableCell>
                                    <TableCell>{value.domain}</TableCell>
                                    <TableCell>
                                        <FormattedMessage id={value.source_type} />
                                    </TableCell>
                                    <TableCell>{value.total}</TableCell>
                                    <TableCell>{value.reach}</TableCell>
                                    <TableCell>{value.engagement}</TableCell>
                                    <TableCell sx={{ px: 0 }}>{value.positive}</TableCell>
                                    <TableCell sx={{ px: 0 }}>{value.neutral}</TableCell>
                                    <TableCell sx={{ px: 0 }}>{value.negative}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Box>
        </ContentPanel>
    )
}
