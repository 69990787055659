import { FC } from 'react'
import { Box, Grid, InputLabel, useTheme } from '@mui/material'
import { Dot } from '../../common/dot/Dot'
import { FormattedMessage } from 'react-intl'
import { useFormState } from 'react-final-form'
import { ProjectDatasource } from './ProjectDatasource'

type DatasourceWebhooksFieldProps = {}

export const DatasourceWebhooksField: FC<DatasourceWebhooksFieldProps> = () => {
    const theme = useTheme()
    const formState = useFormState<ProjectDatasource>()

    return (
        <Grid item xs={12}>
            <Box display="flex" gap={1} alignItems="center" mt={1}>
                <Box>
                    <Dot size={16} color={formState.values.webhooks_enabled ? theme.palette.success.light : theme.palette.error.main} />
                </Box>
                <InputLabel>
                    <FormattedMessage id={formState.values.webhooks_enabled ? 'datasources.webhooks-enabled' : 'datasources.webhooks-disabled'} />
                </InputLabel>
            </Box>
        </Grid>
    )
}
