import * as React from 'react'
import { FC, Fragment, useEffect, useState } from 'react'
import { Box, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { getShowFullTagNames, selectPostGroup } from '../PublishingSlice'
import { HmstrDispatch } from '../../core/Store'
import { useDispatch, useSelector } from 'react-redux'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import _ from 'lodash'
import { getIconForDatasourceType } from '../../settings/datasources/DatasourceTypeMappings'
import { PublishingListPostImage } from './PublishingListPostImage'
import { getMedia } from '../../core/slices/DataSlice'
import { PublishingListTimeDisplay } from './PublishingListTimeDisplay'
import { FormattedMessage } from 'react-intl'
import { PostTagsList } from './PostTagsList'
import { Dot } from '../../common/dot/Dot'
import { getIsMobile, getSimpleUsersForSelectedProject } from '../../core/slices/CoreSlice'
import { UserAvatar } from '../../common/avatars/UserAvatar'
import { fetchLinkPreviewMetadata } from '../PublishingActions'
import { DynamicFeed, History, InsertLink, Notes, Photo, QuestionMark, Subscriptions, Videocam } from '@mui/icons-material'
import { PostGroup } from '../post-groups/PostGroup'

type PublishingListPostCardProps = {
    postGroup: PostGroup
    showOnlyTime?: boolean
}

export const PublishingListPostCard: FC<PublishingListPostCardProps> = ({ postGroup, showOnlyTime }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const isMobile = useSelector(getIsMobile)
    const theme = useTheme()
    const media = useSelector(getMedia)
    const users = useSelector(getSimpleUsersForSelectedProject)
    const assignee = postGroup.assignee_id ? users[postGroup.assignee_id] : undefined
    const showFullTagNames = useSelector(getShowFullTagNames)
    const [cardHovered, setCardHovered] = useState(false)
    const post = postGroup.posts[0]
    const image = post.link
        ? post.link_preview_file_id
            ? media[post.link_preview_file_id]
            : undefined
        : post.file_ids
        ? post.file_ids.map((file_id) => media[file_id]).filter((m) => !!m)[0]
        : undefined
    const isOnList = window.location.pathname.includes('/publishing/list')
    const allPostsStories = _.map(postGroup.posts, (post) => post.ig_publish_as_story).every((publish_as_story) => publish_as_story)

    const [previewMetadata, setPreviewMetadata] = useState<any>(undefined)

    useEffect(() => {
        if (post.link && !image) {
            dispatch(fetchLinkPreviewMetadata(post.link)).then((action) => setPreviewMetadata(action.payload))
        } else {
            setPreviewMetadata(undefined)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post.link, image])
    const finalImage = image?._links.file.href || (previewMetadata && _.get(previewMetadata, 'image')) || undefined

    const datasourceTypes = _.chain(postGroup.posts)
        .map((posts) => {
            return posts.data_source_type
        })
        .uniq()
        .value()
    const handleClick = () => {
        dispatch(selectPostGroup(postGroup.id))
        navigate(isOnList ? `/publishing/list/${postGroup.id}` : `/publishing/calendar/${postGroup.id}`)
    }

    const renderDatasources = () => {
        if (datasourceTypes.length > 1) {
            return (
                <Tooltip
                    title={
                        <Box display={'flex'} gap={0.5}>
                            {datasourceTypes.map((dsType) => (
                                <Fragment key={dsType}>{getIconForDatasourceType(dsType, 'small')}</Fragment>
                            ))}
                        </Box>
                    }
                    placement={'top'}
                >
                    <Box>
                        <Dot size={22} color={theme.palette.secondary.main}>
                            <span style={{ fontWeight: 600, color: 'white' }}>{datasourceTypes.length}</span>
                        </Dot>
                    </Box>
                </Tooltip>
            )
        }
        return (
            <Box display={'inline-flex'} gap={0.5}>
                {datasourceTypes.map((dsType) => (
                    <Fragment key={dsType}>{getIconForDatasourceType(dsType, 'small')}</Fragment>
                ))}
            </Box>
        )
    }

    const getRelevantPostTypeForGroup = (passedPostGroup: PostGroup) => {
        const allPostTypes = _.uniq(
            passedPostGroup.posts.map((post) => {
                return post.post_type
            })
        )

        return allPostTypes.length > 1 ? 'MULTIPLE' : allPostTypes[0]
    }

    const generatePostTypeIcon = (type: string) => {
        switch (type) {
            case 'TEXT':
                return <Notes />
            case 'LINK':
                return <InsertLink />
            case 'VIDEO':
                return <Videocam />
            case 'IMAGE':
                return <Photo />
            case 'MULTI_MEDIA':
                return <Subscriptions />
            case 'MULTIPLE':
                return <DynamicFeed />
            default:
                return <QuestionMark />
        }
    }

    const generatePostType = () => {
        const postType = getRelevantPostTypeForGroup(postGroup)
        return (
            <Box p={1} sx={{ color: isMobile ? 'inherit' : theme.palette.text.disabled, display: 'flex', gap: 1 }}>
                {generatePostTypeIcon(postType)}
                <FormattedMessage id={`publishing.type.${postType}`} />
            </Box>
        )
    }

    return (
        <Paper
            sx={{
                width: '100%',
                cursor: 'pointer',
                outline: '1px solid transparent',
                borderRadius: '10px',
                transition: 'outline 0.3s',
                '&:hover': {
                    outline: '1px solid #ff6900',
                },
            }}
            elevation={3}
            onClick={() => handleClick()}
            onMouseEnter={() => {
                setCardHovered(true)
            }}
            onMouseLeave={() => {
                setCardHovered(false)
            }}
        >
            {generatePostType()}
            {(finalImage || post.post_type === 'VIDEO') && (
                <>
                    <PublishingListPostImage
                        publishingImage={finalImage}
                        isFinalLink={!!previewMetadata?.image}
                        isVideo={post.post_type === 'VIDEO' || image?.mime_type.includes('video')}
                    />
                </>
            )}
            <Box
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                }}
            >
                <Box display="flex" justifyContent={'space-between'} alignItems="center">
                    <PublishingListTimeDisplay time={post.publish_time} fullTime={cardHovered} now={post.state === 'PUBLISH_NOW'} showOnlyTime={showOnlyTime} />
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        {assignee && (
                            <UserAvatar
                                user={assignee}
                                sx={{
                                    height: 24,
                                    width: 24,
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                }}
                            />
                        )}
                        {allPostsStories && <History />}
                        {renderDatasources()}
                    </Box>
                </Box>
                {post.text ? (
                    <Typography
                        sx={{ wordWrap: 'break-word', wordBreak: 'break-word', pl: isMobile ? 0.5 : 0 }}
                        color={isMobile ? theme.palette.text.disabled : undefined}
                    >
                        {post.text.length > 100 ? `${post.text.slice(0, 100)}...` : post.text}
                    </Typography>
                ) : (
                    <Typography color={theme.palette.text.disabled}>
                        <FormattedMessage id={'publishing.no-text-available'} />
                    </Typography>
                )}
            </Box>
            <Box>
                <PostTagsList postGroup={postGroup} displayTags={showFullTagNames || isMobile} />
            </Box>
        </Paper>
    )
}
