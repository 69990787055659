import { FC, MouseEvent, MouseEventHandler } from 'react'
import { Box, IconButton, IconButtonProps, Theme, Tooltip, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { SxProps } from '@mui/system'
import { useLocation } from 'react-router-dom'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { useSelector } from 'react-redux'
import { getLastModulePages } from '../core/slices/CoreSlice'

type PrimaryNavigationButtonProps = {
    tooltip?: string | JSX.Element
    icon: JSX.Element
    sx?: SxProps<Theme>
    path?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    size?: IconButtonProps['size']
    color?: 'primary' | 'secondary' | 'info' | 'error' | 'success'
    alwaysActive?: boolean
    disabled?: boolean
    id?: string
}

export const PrimaryNavigationButton: FC<PrimaryNavigationButtonProps> = ({
    tooltip,
    icon,
    sx,
    path,
    onClick,
    size,
    alwaysActive,
    disabled,
    color = 'secondary',
    id,
}) => {
    const theme = useTheme()
    const location = useLocation()
    const navigate = useProjectNavigate()
    const lastModulePages = useSelector(getLastModulePages)

    // Do not ever set the root icon to active
    const isActive = path && path !== '/' && location.pathname.includes(path)

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (path) {
            const module = path.substring(1)

            if (Object.keys(lastModulePages).includes(module)) {
                navigate(path + (lastModulePages as any)[module])
            } else {
                navigate(path)
            }
        }

        if (onClick) {
            onClick(event)
        }
    }

    const buttonStyles = {
        color: 'white',

        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08) !important',
        },

        '&.active': {
            backgroundColor: `${theme.palette[color].main} !important`,
            '&:hover': {
                backgroundColor: `${theme.palette[color].light} !important`,
            },
        },

        '&.Mui-disabled': {
            color: 'rgba(256, 256, 256, 0.26)',
        },
    }

    return (
        <Box mb={1.5} id={id}>
            <Tooltip
                placement="right"
                disableInteractive
                title={
                    tooltip ? (
                        typeof tooltip === 'string' ? (
                            <Typography fontSize={12} fontWeight={800}>
                                <FormattedMessage id={tooltip} />
                            </Typography>
                        ) : (
                            tooltip
                        )
                    ) : (
                        ''
                    )
                }
            >
                <span>
                    <IconButton
                        disabled={disabled}
                        size={size}
                        sx={{ ...buttonStyles, ...sx }}
                        className={alwaysActive || isActive ? 'active' : undefined}
                        onClick={handleClick}
                    >
                        {icon}
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    )
}
