import * as _ from 'lodash'
import { FC } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { getSettingsSelectedUser } from '../SettingsSlice'
import { User, UserAccountAccess, UserDatasourceAccess, UserProjectAccess } from './User'
import { updateUserAccountAccess } from './UserActions'
import { processUserFormValues, UserForm, UserFormValues } from './UserForm'
import { closeFullscreenDialog, getProjectsForSelectedAccount, getSelectedAccount, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { FORM_ERROR } from 'final-form'

type UserEditProps = {}

export const UserEdit: FC<UserEditProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const user = useSelector(getSettingsSelectedUser) as User
    const projects = useSelector(getProjectsForSelectedAccount)
    const account = useSelector(getSelectedAccount)
    const userAccess = user.accounts.find((acc) => acc.account_id === account.id) as UserAccountAccess

    const convertProjects = (): UserProjectAccess[] => {
        return _.values(projects).map((p) => {
            let userProjectAccess = userAccess.projects.find((up) => up.project_id === p.id)
            const access = [] as UserDatasourceAccess[]

            p.data_sources.forEach((ds) => {
                const existingProjectAccess = userProjectAccess ? userProjectAccess.access.find((a) => a.data_source_id === ds.id) : undefined

                access.push(existingProjectAccess || { data_source_id: ds.id })
            })

            return {
                project_id: p.id,
                name: p.name,
                role: userProjectAccess ? userProjectAccess.role : '',
                description: p.description,
                access: access,
                external: false,
                _links: { project: p._links.self },
            }
        })
    }

    const onSubmit = (values: UserFormValues) => {
        const processedValues = processUserFormValues(values)

        if (processedValues.account_role === 'USER' && processedValues.projects.length === 0) {
            return {
                [FORM_ERROR]: 'accounts.at-least-one-project-required',
            }
        }

        dispatch(
            updateUserAccountAccess({
                accountAccess: userAccess,
                request: {
                    name: processedValues.account_name,
                    role: processedValues.account_role,
                    projects: processedValues.projects,
                    external: processedValues.external,
                },
            })
        ).then(() => {
            dispatch(closeFullscreenDialog())
            dispatch(showSuccessSnackbar('users.rights.updated'))
        })
    }

    return (
        <Container maxWidth="lg">
            <Box marginTop={4} marginBottom={2}>
                <Box marginBottom={4}>
                    <Typography variant="h4">
                        <FormattedMessage id="users.settings" />
                    </Typography>
                </Box>
                <UserForm
                    onSubmit={onSubmit}
                    mode="edit"
                    user={user}
                    initialValues={{
                        email: user.email,
                        account_name: account?.name,
                        account_role: userAccess.role,
                        external: userAccess.external,
                        projects: convertProjects(),
                    }}
                />
            </Box>
        </Container>
    )
}
