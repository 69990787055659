import * as React from 'react'
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { alpha, Box, darken, lighten, SwipeableDrawer, Typography, useTheme } from '@mui/material'
import { ArrowUpward } from '@mui/icons-material'
import { getIsMobile, isDarkMode } from '../core/slices/CoreSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getHighlightedNodeIndex, getNodeToMention, setNodeToMention } from '../engagement/EngagementSlice'

interface MobileAccordionProps extends PropsWithChildren {
    summary: JSX.Element
    overwriteOpenState?: boolean
}

export const MobileAccordion: FC<MobileAccordionProps> = ({ children, summary, overwriteOpenState }) => {
    const isMobile = useSelector(getIsMobile)
    const [shouldDisplayOnSide, setShouldDisplayOnSide] = useState<boolean>(!isMobile && window.innerWidth < 1600)
    const dispatch = useDispatch()
    const [accordionExpanded, setAccordionExpanded] = useState(!!overwriteOpenState)
    const theme = useTheme()
    const accordionRef = useRef<any>(null)
    const darkMode = useSelector(isDarkMode)
    const highlightedNode = useSelector(getHighlightedNodeIndex)
    const nodeToMention = useSelector(getNodeToMention)

    useEffect(() => {
        if (accordionExpanded && accordionRef.current) {
            setTimeout(() => {
                accordionRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
                document.scrollingElement?.scrollTo(0, 0)
            }, 200)
        }
    }, [accordionExpanded])

    useEffect(() => {
        if (highlightedNode !== undefined) {
            setAccordionExpanded(false)
        }
    }, [highlightedNode])

    useEffect(() => {
        if (!!nodeToMention) {
            setAccordionExpanded(true)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!nodeToMention])

    const handleResize = () => {
        if (window.innerWidth < 1600 && !isMobile) {
            setShouldDisplayOnSide(true)
        } else {
            setShouldDisplayOnSide(false)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return isMobile ? (
        <>
            <Box
                sx={{
                    position: 'sticky',
                    bottom: '0px',
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    overflow: 'hidden',
                    boxShadow: theme.shadows[3],
                    display: 'flex',
                    alignItems: 'center',
                    px: 2,
                    py: 1,
                    width: '100vw',
                    justifyContent: 'space-between',
                }}
                onClick={() => setAccordionExpanded(true)}
            >
                <Typography variant={'subtitle2'}>{summary}</Typography>
                <Box>
                    <ArrowUpward />
                </Box>
            </Box>
            <SwipeableDrawer
                anchor={'bottom'}
                disableSwipeToOpen
                open={accordionExpanded}
                onOpen={() => {}}
                onClose={() => {
                    setAccordionExpanded(false)
                    dispatch(setNodeToMention(undefined))
                }}
                PaperProps={{
                    sx: {
                        top: 32,
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                        pb: 4,
                    },
                }}
            >
                <Box
                    sx={{
                        height: '5px',
                        flexShrink: 0,
                        width: '30%',
                        background: darkMode ? lighten(theme.palette.background.paper, 0.3) : darken(theme.palette.background.paper, 0.3),
                        margin: '10px auto',
                        borderRadius: 100,
                    }}
                />
                {children}
            </SwipeableDrawer>
        </>
    ) : shouldDisplayOnSide ? (
        <>
            <SwipeableDrawer
                swipeAreaWidth={30}
                anchor={'right'}
                disableDiscovery={true}
                disableSwipeToOpen={true}
                open={accordionExpanded}
                onOpen={() => {
                    setAccordionExpanded(true)
                }}
                onClose={() => {
                    setAccordionExpanded(false)
                    dispatch(setNodeToMention(undefined))
                }}
                ModalProps={{ keepMounted: true }}
                PaperProps={{
                    sx: {
                        height: '90dvh',
                        top: '5dvh',
                        marginLeft: '50vw',
                        pr: 2,
                        maxWidth: '50vw',
                        position: 'relative',
                        overflow: 'visible',
                        visibility: 'visible !important',
                        borderBottomLeftRadius: 10,
                        borderTopLeftRadius: 10,
                    },
                }}
            >
                {children}
                <Box
                    sx={{
                        position: 'absolute',
                        marginLeft: '-30px',
                        height: '50dvh',
                        marginTop: '20dvh',
                        borderBottomLeftRadius: 10,
                        borderTopLeftRadius: 10,
                        background: theme.palette.background.paper,
                        boxShadow: `-5px 0 5px ${alpha(theme.palette.getContrastText(theme.palette.background.paper), 0.2)}`,
                        display: 'flex',
                        width: '30px',
                        pointerEvents: 'all',
                        touchAction: 'all',
                    }}
                    onClick={() => {
                        if (accordionExpanded) {
                            dispatch(setNodeToMention(undefined))
                        }
                        setAccordionExpanded(!accordionExpanded)
                    }}
                >
                    <Box
                        sx={{
                            minWidth: '50dvh',
                            height: '30px',
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 1,
                            transform: 'rotate(-90deg) translateX(-100%)',
                            transformOrigin: '0 0',
                        }}
                        onClick={() => {}}
                    >
                        <Typography>{summary}</Typography>
                        <Box>
                            <ArrowUpward sx={{ transform: accordionExpanded ? 'rotate(180deg)' : undefined }} />
                        </Box>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </>
    ) : (
        <>{children}</>
    )
}
