import _ from 'lodash'
import moment, { Moment } from 'moment'
import { Box, Button, Grid, styled, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses, Tooltip, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { FC, useMemo } from 'react'
import { CalendarWeek } from './CalendarWeek'
import { CalendarMonth, CalendarViewWeek, Group, Label, LabelOff, PostAdd, VerticalAlignCenter, Web } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeCalendarTimePeriod,
    changeDate,
    getCompactCalendarViewForMonthly,
    getCompactCalendarViewForWeekly,
    getSelectedCalendarTimePeriod,
    getSelectedDatasourceIds,
    getSelectedEndDate,
    getShowFullTagNames,
    getShowOnlyAssignedPosts,
    getShowOnlyAssignedToOthersPosts,
    getShowOnlyUnassignedPosts,
    selectDatasourceIds,
    startNewPost,
    toggleCompactCalendarMonthly,
    toggleCompactCalendarWeekly,
    toggleOnlyAssignedPosts,
    toggleOnlyAssignedToOthersPosts,
    toggleShowFullTagNames,
    toggleShowOnlyUnassignedPosts,
} from '../PublishingSlice'
import { CalendarDatePicker } from './CalendarDatePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { getPagesForPublish, getSelectedProject, getSimpleUsersForSelectedProject } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchUsersForProject } from '../../settings/user-management/UserActions'
import { HmstrDispatch } from '../../core/Store'
import { createHmstrTheme } from '../../core/theme/hmstr-theme'
import { FilterDrawer, FilterSetting } from '../../common/filterbar/FilterDrawer'
import { PublishingAssignmentSelect } from '../PublishingListCardComponents/PublishingAssignmentSelect'
import { DatasourceSelection } from '../../content-analytics/datasource/DatasoureSelection'
import { TitlebarWithFilters } from '../../common/filterbar/TitlebarWithFilters'
import { CalendarDatePickerButtons } from './CalendarDatePickerButtons'
import { HotkeyTooltip } from '../../common/hotkeys/HotkeyTooltip'
import { useHotkeys } from 'react-hotkeys-hook'
import { getAssigneeSelectionOpen, getDatasourceSelectionOpen, setAssigneeSelectionOpen, setDatasourceSelectionOpen } from '../../common/filterbar/FilterSlice'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        marginX: theme.spacing(0.5),
        marginY: 0,
        height: '36.5px',
        paddingY: 0,
        borderRadius: theme.shape.borderRadius,
    },
}))

type CalendarGridProps = {
    selectedStartDate: string
}

export const CalendarGrid: FC<CalendarGridProps> = ({ selectedStartDate }) => {
    const intl = useIntl()
    const theme = useTheme()
    const dispatch = useDispatch<HmstrDispatch>()
    const darkTheme = createHmstrTheme('dark')
    const navigate = useProjectNavigate()
    const usersAsIdMap = useSelector(getSimpleUsersForSelectedProject)
    const usersAsObject = Object.values(usersAsIdMap)
    const selectedProject = useSelector(getSelectedProject)
    const calendarView = useSelector(getSelectedCalendarTimePeriod)
    const compactViewMonthly = useSelector(getCompactCalendarViewForMonthly)
    const compactViewWeekly = useSelector(getCompactCalendarViewForWeekly)
    const selectedEndDate = useSelector(getSelectedEndDate)
    const relevantCompactView = calendarView === 'month' ? compactViewMonthly : compactViewWeekly
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)
    const onlyAssigned = useSelector(getShowOnlyAssignedPosts)
    const onlyAssignedToOthers = useSelector(getShowOnlyAssignedToOthersPosts)
    const onlyUnassigned = useSelector(getShowOnlyUnassignedPosts)
    const showFullTagNames = useSelector(getShowFullTagNames)
    const datasourceSelectionOpen = useSelector(getDatasourceSelectionOpen)
    const assigneeSelectionOpen = useSelector(getAssigneeSelectionOpen)
    const pagesForPublishing = useSelector(getPagesForPublish)

    const handleDatasourceSelectionOpen = () => dispatch(setDatasourceSelectionOpen(true))
    const handleDatasourceSelectionClose = () => dispatch(setDatasourceSelectionOpen(false))
    const handleAssigneeSelectionOpen = () => dispatch(setAssigneeSelectionOpen(true))
    const handleAssigneeSelectionClose = () => dispatch(setAssigneeSelectionOpen(false))

    useHotkeys('shift+t', () => {
        dispatch(toggleShowFullTagNames())
    })

    useHotkeys('m', () => handleSelect('month'))
    useHotkeys('w', () => handleSelect('week'))
    useHotkeys('n', () => handleCreatePostNowClick())
    useHotkeys('k', () => {
        if (calendarView === 'month') dispatch(toggleCompactCalendarMonthly())
        else dispatch(toggleCompactCalendarWeekly())
    })

    const handleCompactViewChange = () => {
        if (calendarView === 'month') dispatch(toggleCompactCalendarMonthly())
        else dispatch(toggleCompactCalendarWeekly())
    }
    const handleChangeFullTagNames = () => {
        dispatch(toggleShowFullTagNames())
    }

    useEffectWithIdComparison(() => {
        if (selectedProject && usersAsObject.length === 0) {
            dispatch(fetchUsersForProject(selectedProject))
        }
    }, [selectedProject, usersAsObject.length])

    const daysPerWeek = useMemo(() => {
        const calendarState = [] as any
        const start = moment(selectedStartDate).startOf(calendarView).startOf('week')
        const end = moment(selectedStartDate).endOf(calendarView).endOf('week')

        while (start.isBefore(end)) {
            const week = start.week()

            if (!calendarState[week]) {
                calendarState[week] = {}
            }

            calendarState[week][start.format('YYYY-MM-DD')] = []
            start.add(1, 'day')
        }

        return calendarState
    }, [selectedStartDate, calendarView])

    const weekdayNames = useMemo(() => moment.weekdaysMin(true), [])
    const weeksOfMonth = useMemo(() => {
        const startWeek = moment(selectedStartDate).startOf(calendarView).startOf('week')
        const endWeek = moment(selectedStartDate).endOf(calendarView).endOf('week')
        let weekRange = [] as number[]
        if (startWeek.week() < endWeek.week()) {
            weekRange = _.range(startWeek.week(), endWeek.week() + 1)
        } else {
            const fakeStart = startWeek.clone()
            let i = 0
            while (fakeStart < endWeek) {
                weekRange[i++] = fakeStart.week()
                fakeStart.add(1, 'week')
            }
        }
        return weekRange
    }, [selectedStartDate, calendarView])

    const onChange = (value: Moment | null) => {
        if (value === null) {
            return null
        }

        dispatch(
            changeDate({
                startDate: value.startOf(calendarView).format('YYYY-MM-DD'),
                endDate: value.endOf(calendarView).format('YYYY-MM-DD'),
            })
        )
    }

    const handleCreatePostNowClick = () => {
        dispatch(startNewPost())
        navigate('/publishing/calendar/new')
    }

    const handleSelect = (value: 'month' | 'week') => {
        if (value === 'month') {
            const hasMultipleMonths = moment(selectedStartDate).month() !== moment(selectedEndDate).month()
            const daysInStartMonth = moment(selectedStartDate).endOf('month').add(1, 'day').diff(moment(selectedStartDate), 'days')
            const daysInEndMonth = moment(selectedEndDate).add(1, 'day').diff(moment(selectedEndDate).startOf('month'), 'days')
            const getDatesToSet = (): { startDate: string; endDate: string } => {
                if (hasMultipleMonths) {
                    if (daysInStartMonth > daysInEndMonth) {
                        return {
                            startDate: moment(selectedStartDate).startOf('month').format('YYYY-MM-DD'),
                            endDate: moment(selectedStartDate).endOf('month').format('YYYY-MM-DD'),
                        }
                    } else {
                        return {
                            startDate: moment(selectedEndDate).startOf('month').format('YYYY-MM-DD'),
                            endDate: moment(selectedEndDate).endOf('month').format('YYYY-MM-DD'),
                        }
                    }
                }
                return {
                    startDate: moment(selectedStartDate).startOf('month').format('YYYY-MM-DD'),
                    endDate: moment(selectedStartDate).endOf('month').format('YYYY-MM-DD'),
                }
            }
            dispatch(changeCalendarTimePeriod('month'))
            dispatch(
                changeDate({
                    startDate: getDatesToSet().startDate,
                    endDate: getDatesToSet().endDate,
                })
            )
        } else if (value === 'week') {
            const startDate =
                moment(selectedStartDate).month() === moment().month()
                    ? moment().startOf('week').format('YYYY-MM-DD')
                    : moment(selectedStartDate).startOf('month').startOf('week').format('YYYY-MM-DD')
            const endDate =
                moment(selectedStartDate).month() === moment().month()
                    ? moment().endOf('week').format('YYYY-MM-DD')
                    : moment(selectedStartDate).startOf('month').endOf('week').format('YYYY-MM-DD')
            dispatch(changeCalendarTimePeriod('week'))
            dispatch(
                changeDate({
                    startDate: startDate,
                    endDate: endDate,
                })
            )
        }
    }

    const generateSettingString = () => {
        if (onlyAssigned) {
            return intl.formatMessage({ id: 'publishing.filter.assigned-to-me' })
        } else if (onlyUnassigned) {
            return intl.formatMessage({ id: 'filters.unassigned' })
        } else if (onlyAssignedToOthers) {
            return intl.formatMessage({ id: 'engagement.inbox.assigned-to-others' })
        } else {
            return intl.formatMessage({ id: 'filters.all' })
        }
    }

    const getAssignmentFilterSetting = () => {
        const onDeleteFunction = () => {
            if (onlyAssigned) {
                dispatch(toggleOnlyAssignedPosts())
            } else if (onlyUnassigned) {
                dispatch(toggleShowOnlyUnassignedPosts())
            } else if (onlyAssignedToOthers) {
                dispatch(toggleOnlyAssignedToOthersPosts())
            }
        }

        const hasChangedFromDefault = onlyAssigned || onlyUnassigned || onlyAssignedToOthers
        const onChipClick = () => {
            setTimeout(handleAssigneeSelectionOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: (
                <PublishingAssignmentSelect open={assigneeSelectionOpen} onOpen={handleAssigneeSelectionOpen} onClose={handleAssigneeSelectionClose} />
            ),
            tooltip: 'filters.assignment.title',
            hasChangedFromDefault: hasChangedFromDefault,
            icon: <Group fontSize={'small'} />,
            currentValueAsString: generateSettingString(),
            onDeleteFunction: onDeleteFunction,
            onClickFunction: onChipClick,
            deletable: true,
            important: true,
        }
        return set
    }

    const getDatasourceSetting = () => {
        const defaultValue: string[] = []
        const hasChanged = !_.isEqual(_.sortBy(defaultValue), _.sortBy(selectedDatasourceIds))
        const onDeleteFunction = () => {
            dispatch(selectDatasourceIds([]))
        }
        const generateSettingString = () => {
            return selectedDatasourceIds.length === 1
                ? pagesForPublishing.find((page) => page.id === selectedDatasourceIds[0])?.name
                : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selectedDatasourceIds.length })
        }

        const onChipClick = () => {
            setTimeout(handleDatasourceSelectionOpen, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <DatasourceSelection
                    datasources={pagesForPublishing}
                    isOpen={datasourceSelectionOpen}
                    handleClose={handleDatasourceSelectionClose}
                    handleOpen={handleDatasourceSelectionOpen}
                    fullWidth
                    initialIds={selectedDatasourceIds}
                    size={'small'}
                    onDatasourceChange={(ids) => dispatch(selectDatasourceIds(ids))}
                />
            ),
            tooltip: 'general.datasources',
            currentValueAsString: generateSettingString(),
            important: false,
            deletable: true,
            hasChangedFromDefault: hasChanged,
            onClickFunction: onChipClick,
            onDeleteFunction: onDeleteFunction,
            icon: <Web fontSize={'small'} />,
        }
        return set
    }

    const renderToggleButtons = () => {
        return (
            <Box display="flex" gap={2}>
                <StyledToggleButtonGroup value={calendarView}>
                    <ToggleButton value="month" onClick={() => handleSelect('month')}>
                        <Tooltip
                            placement={'left'}
                            title={
                                <Box display={'flex'} alignItems={'center'} gap={1}>
                                    <FormattedMessage id={'publishing.calendar-period-month'} />
                                    <HotkeyTooltip keys={['M']} invert fontSize={14} />
                                </Box>
                            }
                            disableInteractive
                        >
                            <CalendarMonth />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="week" onClick={() => handleSelect('week')}>
                        <Tooltip
                            placement={'left'}
                            title={
                                <Box display={'flex'} alignItems={'center'} gap={1}>
                                    <FormattedMessage id={'publishing.calendar-period-week'} />
                                    <HotkeyTooltip keys={['W']} invert fontSize={14} />
                                </Box>
                            }
                            disableInteractive
                        >
                            <CalendarViewWeek />
                        </Tooltip>
                    </ToggleButton>
                </StyledToggleButtonGroup>
                <StyledToggleButtonGroup value={relevantCompactView && 'compact'} size={'small'}>
                    <ToggleButton value="compact" onClick={() => handleCompactViewChange()}>
                        <Tooltip
                            placement={'left'}
                            title={
                                <Box display={'flex'} alignItems={'center'} gap={1}>
                                    <FormattedMessage id={'publishing.toggle-compact-view'} />{' '}
                                    <FormattedMessage id={`publishing.calendar-period-${calendarView}`} />
                                    <HotkeyTooltip keys={['K']} invert fontSize={14} />
                                </Box>
                            }
                            disableInteractive
                        >
                            <VerticalAlignCenter />
                        </Tooltip>
                    </ToggleButton>
                </StyledToggleButtonGroup>
                <StyledToggleButtonGroup>
                    <Tooltip
                        title={
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                                <FormattedMessage id={`publishing.filter.${showFullTagNames ? 'hide' : 'show'}-full-tag-names`} />
                                <HotkeyTooltip invert keys={['shift', 't']} fontSize={14} />
                            </Box>
                        }
                        placement={'right'}
                        disableInteractive
                    >
                        <ToggleButton value={showFullTagNames} selected={showFullTagNames} onChange={handleChangeFullTagNames} size={'small'}>
                            {showFullTagNames ? <LabelOff /> : <Label />}
                        </ToggleButton>
                    </Tooltip>
                </StyledToggleButtonGroup>
            </Box>
        )
    }

    const renderFilters = () => {
        return (
            <Box display={'flex'} alignItems={'center'} gap={1}>
                <FilterDrawer filters={[getAssignmentFilterSetting(), getDatasourceSetting()]} />
            </Box>
        )
    }

    const renderCreateButton = () => {
        return (
            <Box>
                <Tooltip
                    title={
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                            <FormattedMessage id="publishing.create-post" />
                            <HotkeyTooltip keys={['n']} invert fontSize={14} />
                        </Box>
                    }
                    placement={'left'}
                    disableInteractive
                >
                    <Button id={'publishing_create_post_btn'} startIcon={<PostAdd />} color="secondary" variant="contained" onClick={handleCreatePostNowClick}>
                        <span
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            <FormattedMessage id="publishing.create-post" />
                        </span>
                    </Button>
                </Tooltip>
            </Box>
        )
    }

    const renderDatePickerButtons = () => {
        return <CalendarDatePickerButtons onChange={onChange} selectedStartDate={selectedStartDate} />
    }
    const renderViewOptions = () => {
        return (
            <Box display={'flex'} gap={4}>
                {renderDatePickerButtons()}
                {renderToggleButtons()}
            </Box>
        )
    }
    const renderToolbar = () => {
        return (
            <Box position={'sticky'} top={0} zIndex={3} bgcolor={theme.palette.background.paper}>
                <TitlebarWithFilters
                    title={<CalendarDatePicker selectedStartDate={selectedStartDate} onChange={onChange} />}
                    viewOptions={renderViewOptions()}
                    actionButton={renderCreateButton()}
                    filterBar={renderFilters()}
                />
            </Box>
        )
    }

    return (
        <Box width="100%" style={{ minWidth: '950px', minHeight: '100vh', display: 'flex', flexDirection: 'column' }} id={'publishing_calendar'}>
            {renderToolbar()}
            <Grid
                container
                display="table"
                sx={{
                    position: 'sticky',
                    top: '108.5px',
                    zIndex: '3',
                }}
            >
                {_.map(weekdayNames, (label, idx) => {
                    return (
                        <Grid
                            key={label}
                            item
                            sx={{
                                p: 1,
                                display: 'table-cell',
                                width: 1 / 7,
                                borderRight: 1,
                                borderColor: 'divider',
                                background: darkTheme.palette.background.paper,
                                color: darkTheme.palette.text.primary,
                                textAlign: 'right',
                                '&:last-child': {
                                    borderRight: 0,
                                },
                            }}
                        >
                            {idx === 0 && (
                                <Box display={'inline'} sx={{ float: 'left' }}>
                                    <Typography variant="subtitle2" display={'inline'}>
                                        <FormattedMessage id="publishing.calendar-week-abbr" />
                                    </Typography>
                                </Box>
                            )}
                            <Typography sx={{ mr: 0.5 }} variant="subtitle2" display={'inline'}>
                                {label}
                            </Typography>
                        </Grid>
                    )
                })}
            </Grid>
            <Box
                sx={
                    calendarView === 'week'
                        ? { flexGrow: 1, display: 'flex', flexShrink: 1, overflow: 'auto' }
                        : { flexGrow: 1, display: 'flex', flexDirection: 'column' }
                }
            >
                {weeksOfMonth.map((week) => (
                    <CalendarWeek
                        key={week}
                        week={week}
                        selectedMonth={moment(selectedStartDate).month()}
                        calendarWeek={daysPerWeek[week]}
                        countOfWeeks={calendarView === 'week' ? 1 : _.size(weeksOfMonth)}
                    />
                ))}
            </Box>
        </Box>
    )
}
