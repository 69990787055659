import React, { FC } from 'react'
import { Alert, Grid, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { scopesByModule } from './AccountLinkScopesByModule'
import { FederatedIdentity } from './FederatedIdentity'
import { useSelector } from 'react-redux'
import { getScopeErrors, IdMap } from '../../core/slices/DataSlice'

type AccountLinkErrorAlertProps = {
    identities: FederatedIdentity[]
}

export const AccountLinkErrorAlert: FC<AccountLinkErrorAlertProps> = ({ identities }) => {
    const missingScopes = useSelector(getScopeErrors)
    const identityIds = _.map(identities, (identity) => identity.id)

    const actualMissingScopes = _.chain(missingScopes)
        .pickBy((value, key) => identityIds.includes(key))
        .values()
        .flatten()
        .uniq()
        .groupBy((scope: any) => scopesByModule[scope] as string)
        .value() as any as IdMap<string[]>

    return (
        <Alert severity="error" sx={{ m: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        <FormattedMessage id={'settings.missing-scopes-info'} />
                    </Typography>
                </Grid>
                {Object.keys(actualMissingScopes).map((module: string) => {
                    return (
                        <Grid key={module} item xs={12}>
                            <Typography variant={'subtitle2'}>
                                <FormattedMessage id={`settings.permissions.category.${module}`} />
                            </Typography>
                            {actualMissingScopes[module].map((scope) => (
                                <Typography key={scope} variant={'body2'}>
                                    <FormattedMessage id={`settings.permissions.${scope}`} />
                                </Typography>
                            ))}
                        </Grid>
                    )
                })}
            </Grid>
        </Alert>
    )
}
