import * as React from 'react'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { getDatasourcesAsIdMap, isDarkMode } from '../../../core/slices/CoreSlice'
import { Post } from '../../posts/Post'
import { getMedia } from '../../../core/slices/DataSlice'
import { getErrorsForCommonFile } from '../../PublishingSlice'
import { InstagramPostPreview } from './InstagramPostPreview'
import { InstagramStoryPreview } from './InstagramStoryPreview'
import { hashTagExpr } from '../../../core/theme/helper'

type InstagramPostPreviewProps = {
    post: Post
    mockupDevice: 'mobile' | 'desktop'
}

export const InstagramPreview: FC<InstagramPostPreviewProps> = ({ post, mockupDevice }) => {
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const selectedDatasource = datasourcesById[post.data_source_id]
    const postType = post.post_type
    const media = useSelector(getMedia)
    const files = (post.file_ids || []).map((file_id) => media[file_id]).filter((m) => !!m)
    const errorsForFiles = useSelector(getErrorsForCommonFile)
    const igFontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
    const darkmode = useSelector(isDarkMode)
    const actualFiles = files.filter((f) => {
        const hasFileRelevantErrors = errorsForFiles[f.id]
            ?.map((error) => {
                return error.network === selectedDatasource.type
            })
            .includes(true)
        return !hasFileRelevantErrors && (f.category === postType || postType === 'MULTI_MEDIA')
    })

    const generatePostText = (text: string) => {
        const escapeHtml = (unsafe: string) => {
            return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
        }
        const hashtags = text.match(hashTagExpr)
        const mentions = text.match(/@[\wäöüÄÖÜß]+/g)
        const generateContent = () => {
            let newText = escapeHtml(text)
            hashtags
                ?.sort((a, b) => b.length - a.length)
                .forEach((hashtag: string) => {
                    const pattern = `${hashtag}(?!\\w)`
                    const hashtagRegex = new RegExp(pattern, 'g')
                    newText = newText.replaceAll(
                        hashtagRegex,
                        `<a style="color: ${
                            darkmode ? 'hsl(214, 100%, 59%)' : 'rgb(0, 55, 200)'
                        }; cursor: pointer; text-decoration: none" onclick="window.open('https://instagram.com/explore/tags/${hashtag.replace(
                            '#',
                            ''
                        )}/', '_blank')">${escapeHtml(hashtag)}</a>`
                    )
                })
            mentions?.forEach((username: string) => {
                const pattern = `${username}(?!\\w)`
                const usernameRegex = new RegExp(pattern, 'g')
                newText = newText.replaceAll(
                    usernameRegex,
                    `<a style="color: ${
                        darkmode ? 'hsl(214, 100%, 59%)' : 'rgb(0, 55, 200)'
                    }; cursor: pointer; text-decoration: none" href="https://instagram.com/${username.replace('@', '')}/" target="_blank">${username}</a>`
                )
            })
            return <span dangerouslySetInnerHTML={{ __html: newText }}></span>
        }

        return (
            <span
                style={{
                    fontSize: 14,
                    color: darkmode ? 'inherit' : '#050505',
                    fontWeight: 400,
                    lineHeight: '14px',
                    fontFamily: igFontFamily,
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                }}
            >
                {generateContent()}
            </span>
        )
    }

    return post.ig_publish_as_story ? (
        <InstagramStoryPreview
            post={post}
            selectedDatasource={selectedDatasource}
            igFontFamily={igFontFamily}
            actualFiles={actualFiles}
            mockupDevice={mockupDevice}
        />
    ) : (
        <InstagramPostPreview
            post={post}
            igFontFamily={igFontFamily}
            generatePostText={generatePostText}
            darkmode={darkmode}
            actualFiles={actualFiles}
            selectedDatasource={selectedDatasource}
            mockupDevice={mockupDevice}
        />
    )
}
