import * as _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { User, UserAccountAccess, UserProjectAccess } from './User'
import { HmstrState } from '../../core/Store'
import { IdMap } from '../../core/slices/DataSlice'
import { UserFormValues } from './UserForm'
import { Invite, InviteProjectAccess } from './Invite'
import { doDelete, doGet, doPost, doPut } from '../../core/api/ApiClient'
import { FederatedIdentity } from '../linked-accounts/FederatedIdentity'
import { PotentialDatasource } from '../datasources/PotentialDatasource'
import { ProjectDatasourceType } from '../datasources/ProjectDatasource'
import { Account } from '../accounts/Account'
import { orderedAvailableTours } from '../../common/guides/OrderedAvailableTours'
import { Project } from '../project-management/Project'
import { SimpleUser } from './SimpleUser'
import { CoreState } from '../../core/slices/CoreSlice'

const USERS_SLASH = (action: string) => `users/${action}`

export const fetchCurrentUser = createAsyncThunk<User, void, { state: HmstrState }>(USERS_SLASH('fetchCurrent'), async (args, thunkApi) => {
    return await doGet<User>(thunkApi, thunkApi.getState().api.entryPoint._links.currentUser)
})

export const updateUserSettings = createAsyncThunk<User, { settings: User['settings']; user: User }, { state: HmstrState }>(
    USERS_SLASH('updateUserSettings'),
    async ({ settings, user }, thunkAPI) => {
        return await doPut(thunkAPI, user._links.self.href + '/settings', settings)
    }
)

export const finishTour = createAsyncThunk<User, keyof User['settings']['viewed_tutorials'], { state: HmstrState }>(
    USERS_SLASH('finishTour'),
    async (tourName, thunkAPI) => {
        const currentUser = thunkAPI.getState().core.currentUser as User
        const newSettings = _.cloneDeep(currentUser.settings)
        newSettings.viewed_tutorials[tourName] = true
        return await doPut(thunkAPI, currentUser._links.self.href + '/settings', newSettings)
    }
)
export const finishAllTours = createAsyncThunk<User, void, { state: HmstrState }>(USERS_SLASH('finishAllTours'), async (args, thunkAPI) => {
    const currentUser = thunkAPI.getState().core.currentUser as User
    const newSettings = _.cloneDeep(currentUser.settings)
    orderedAvailableTours.forEach((tourName) => {
        newSettings.viewed_tutorials[tourName] = true
    })
    return await doPut(thunkAPI, currentUser._links.self.href + '/settings', newSettings)
})
export const resetTours = createAsyncThunk<User, void, { state: HmstrState }>(USERS_SLASH('resetTours'), async (args, thunkAPI) => {
    const currentUser = thunkAPI.getState().core.currentUser as User
    const newSettings = _.cloneDeep(currentUser.settings)
    newSettings.viewed_tutorials = {}
    return await doPut(thunkAPI, currentUser._links.self.href + '/settings', newSettings)
})
export const resetSingleTour = createAsyncThunk<User, keyof User['settings']['viewed_tutorials'], { state: HmstrState }>(
    USERS_SLASH('resetSingleTour'),
    async (tourName, thunkAPI) => {
        const currentUser = thunkAPI.getState().core.currentUser as User
        const newSettings = _.cloneDeep(currentUser.settings)
        newSettings.viewed_tutorials[tourName] = false
        return await doPut(thunkAPI, currentUser._links.self.href + '/settings', newSettings)
    }
)

export const resetMultipleTours = createAsyncThunk<User, (keyof User['settings']['viewed_tutorials'])[], { state: HmstrState }>(
    USERS_SLASH('resetMultipleTours'),
    async (tourNames, thunkAPI) => {
        const currentUser = thunkAPI.getState().core.currentUser as User
        const newSettings = _.cloneDeep(currentUser.settings)
        tourNames.forEach((tourName) => {
            newSettings.viewed_tutorials[tourName] = false
        })

        return await doPut(thunkAPI, currentUser._links.self.href + '/settings', newSettings)
    }
)

export const fetchInvitesForAccount = createAsyncThunk<IdMap<Invite[]>, UserAccountAccess>(
    USERS_SLASH('fetchInvitesForAccount'),
    async (userAccountAccess, thunkAPI) => {
        const transform = (invites: Invite[]) => ({
            [userAccountAccess.account_id]: invites,
        })
        return await doGet(thunkAPI, userAccountAccess._links.invites, transform)
    }
)

export const updateInvite = createAsyncThunk<Invite, Invite>(USERS_SLASH('editInvite'), async (invite, thunkAPI) => {
    return await doPut<Invite>(thunkAPI, invite._links.self, {
        account_id: invite.account.account_id,
        account_role: invite.account.role,
        projects: invite.account.projects,
        external: invite.account.external,
    })
})

export const fetchUsersForAccount = createAsyncThunk<IdMap<User[]>, UserAccountAccess>(
    USERS_SLASH('fetchUsersForAccount'),
    async (userAccountAccess, thunkAPI) => {
        const transform = (users: User[]) => ({
            [userAccountAccess.account_id]: users,
        })
        return await doGet(thunkAPI, userAccountAccess._links.users, transform)
    }
)

export const inviteUserToAccount = createAsyncThunk<boolean | Invite, { account: Account; userFormValues: UserFormValues }>(
    USERS_SLASH('inviteUserToAccount'),
    async ({ account, userFormValues }, thunkAPI) => {
        return await doPost<boolean | Invite>(thunkAPI, account._links.invites, userFormValues)
    }
)

export type UpdateUserAccountAccessRequest = {
    name: string
    role: UserAccountAccess['role']
    projects: (UserProjectAccess | InviteProjectAccess)[]
    external: boolean
}

export const updateUserAccountAccess = createAsyncThunk<
    { accountId: string; data: User },
    {
        request: UpdateUserAccountAccessRequest
        accountAccess: UserAccountAccess
    }
>(USERS_SLASH('updateAccountAccesss'), async ({ accountAccess, request }, thunkAPI) => {
    const transform = (user: User) => ({
        accountId: accountAccess.account_id,
        data: user,
    })
    return await doPut(thunkAPI, accountAccess._links.self.href, request, transform)
})

export const deleteInvite = createAsyncThunk<Invite, Invite>(USERS_SLASH('deleteInvite'), async (invite, thunkAPI) => {
    const transform = () => invite
    return await doDelete(thunkAPI, invite._links.self, transform)
})

export const deleteAccountAccess = createAsyncThunk<{ access: UserAccountAccess; user_id: string }, { access: UserAccountAccess; user_id: string }>(
    USERS_SLASH('deleteAccountAccess'),
    async (args, thunkAPI) => {
        const transform = () => args
        return await doDelete(thunkAPI, args.access._links.self, transform)
    }
)

export const fetchFederatedIdentites = createAsyncThunk<FederatedIdentity[], User, { state: HmstrState }>(
    USERS_SLASH('fetchFederatedIdentitesForAccount'),
    async (user, thunkAPI) => {
        return await doGet<FederatedIdentity[]>(thunkAPI, user._links.federated_identities)
    }
)

export const fetchPotentialDatasources = createAsyncThunk<PotentialDatasource[], { user: User; type: ProjectDatasourceType }, { state: HmstrState }>(
    USERS_SLASH('fetchPotentialDatasources'),
    async ({ user, type }, thunkAPI) => {
        return await doGet<PotentialDatasource[]>(thunkAPI, user._links.potential_datasources, undefined, {
            params: {
                project_datasource_type: type,
            },
        })
    }
)

export const deleteFederatedIdentity = createAsyncThunk<any, FederatedIdentity, { state: HmstrState }>(
    USERS_SLASH('deleteFederatedIdentity'),
    async (federatedIdentity, thunkAPI) => {
        return await doDelete(thunkAPI, federatedIdentity._links.self)
    }
)

export const fetchUsersForProject = createAsyncThunk<SimpleUser[], Project, { state: HmstrState }>(
    USERS_SLASH('fetchUsersForProject'),
    async (project, thunkAPI) => {
        return doGet(thunkAPI, project._links.users)
    }
)

export const fetchCurrentUserPermissions = createAsyncThunk<
    CoreState['currentUserPermissions'],
    User,
    {
        state: HmstrState
    }
>(USERS_SLASH('fetchPermissionsForCurrentUser'), async (user, thunkAPI) => {
    return doGet(thunkAPI, user._links.permissions)
})

export const fetchPermissionsForProject = createAsyncThunk<IdMap<string[]>, Project, { state: HmstrState }>(
    USERS_SLASH('fetchPermissionsForProject'),
    async (project, thunkAPI) => {
        return doGet(thunkAPI, project._links.permissions)
    }
)
