import { FC } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { Box, Typography, useTheme } from '@mui/material'
import { TooltipProps } from 'recharts'
import { ResultTooltipBox } from '../../monitoring/Results.styles'
import { Dot } from '../dot/Dot'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { getTimePeriodWithPrefix } from '../../core/theme/helper'

interface ResultsChartTooltipProps extends TooltipProps<any, any> {
    dataSets?: any
    dateFormat?: string
    dateFormatForArea?: string
}

export const ChartTooltip: FC<ResultsChartTooltipProps> = ({ active, label, payload, dataSets, dateFormat, dateFormatForArea }) => {
    const intl = useIntl()
    const findLabel = (toConvert: string) => {
        let labelConverted = undefined
        if (dataSets) {
            labelConverted = dataSets.find((o: any) => o.dataKey === toConvert)
        }
        if (labelConverted) {
            return labelConverted.label
        } else {
            return toConvert
        }
    }
    const theme = useTheme()
    if (active && payload) {
        return (
            <ResultTooltipBox>
                {label && (
                    <Typography>
                        <strong>
                            {dateFormatForArea
                                ? getTimePeriodWithPrefix(label, dateFormatForArea, intl)
                                : moment(label, '', true).isValid()
                                ? moment(label).format('D MMM')
                                : label}
                        </strong>
                    </Typography>
                )}

                {_.orderBy(payload, ['value'], ['desc'])
                    .filter((p) => p.value > 0 || p.value < 0)
                    .map((p) => (
                        <Box key={p.dataKey as any} display="flex" alignItems="center">
                            <Box paddingRight={1}>
                                <Dot color={p.color || p.payload.color || theme.palette.secondary.main} />
                            </Box>
                            <Typography>
                                {!dateFormat && <FormattedMessage id={findLabel(p.name)} />}
                                {dateFormat && getTimePeriodWithPrefix(p.name, dateFormat, intl)}:{' '}
                                <FormattedNumber value={p.value as any} maximumFractionDigits={2} />
                            </Typography>
                        </Box>
                    ))}
            </ResultTooltipBox>
        )
    }

    return null
}
