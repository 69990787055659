import * as React from 'react'
import { FC } from 'react'
import { Alert, Link } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { openFullscreenDialog } from '../core/slices/CoreSlice'
import { setDashboardWizardStep } from './DashboardsSlice'

type NoAdAccountDashboardHintProps = {}

export const NoAdAccountDashboardHint: FC<NoAdAccountDashboardHintProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const handleOpenSettings = () => {
        dispatch(openFullscreenDialog('edit-dashboard'))
        dispatch(setDashboardWizardStep(1))
    }
    return (
        <Alert severity={'warning'} color={'warning'}>
            <FormattedMessage
                id={'reporting.dashboards.monthly-report.no-ad-account-set'}
                values={{
                    link: (
                        <Link onClick={handleOpenSettings} sx={{ cursor: 'pointer' }}>
                            <FormattedMessage id={'reporting.dashboards.settings'} />
                        </Link>
                    ),
                }}
            />
        </Alert>
    )
}
