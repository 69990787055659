import { FC, useState } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Cell, Legend, Pie, PieChart as RPieChart, ResponsiveContainer, Sector, Tooltip, TooltipProps } from 'recharts'
import { ResultTooltipBox } from '../../monitoring/Results.styles'
import { AggregatedTagData } from '../../tags/AggregatedTagData'
import { Dot } from '../../common/dot/Dot'
import { PieChartLabel } from '../../common/pie-chart-label/PieChartLabel'
import Animation from 'react-smooth'

type PostAnalysisBarChartProps = {
    title: string
    data: AggregatedTagData[]
    getValue: (entry: AggregatedTagData) => number
    unit?: string
}

const activePieOffset = 5
const activePieAnimationDurationMs = 200

export const PostAnalysisPieChart: FC<PostAnalysisBarChartProps> = ({ title, data, getValue, unit }) => {
    const theme = useTheme()
    const intl = useIntl()
    const matches = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const [activePieIndex, setActivePieIndex] = useState<number | undefined>(undefined)
    const [pieOffset, setPieOffset] = useState<number>(0)

    const formatTagName = (tagName: string) => {
        const maxLength = 17
        if (tagName === 'tags.untagged-posts') {
            return intl.formatMessage({ id: tagName })
        } else if (tagName.length <= maxLength) {
            return tagName
        } else {
            return tagName.substring(0, maxLength - 3) + '...'
        }
    }

    const onMouseEnter = (_: any, index: number) => {
        setPieOffset(activePieOffset)
        setActivePieIndex(index)
    }

    const onMouseLeave = () => {
        setPieOffset(0)
    }

    const formatData = () => {
        let transformedData: any[] = []
        data.forEach((value) => {
            transformedData.push({ name: value.tag.label, total: getValue(value), tag: value.tag })
        })
        transformedData = transformedData.sort((a, b) => b.total - a.total)
        return transformedData
    }

    const PieChartTooltip: FC<TooltipProps<any, any>> = ({ active, payload }) => {
        if (active && payload && payload[0]) {
            return (
                <ResultTooltipBox>
                    <Box display="flex" alignItems="center">
                        {payload[0].payload.tag && (
                            <Box paddingRight={1}>
                                <Dot color={payload[0].payload.tag.color} />
                            </Box>
                        )}
                        <Typography>
                            {formatTagName(payload[0].payload.name)}&nbsp;
                            <FormattedNumber value={payload[0].value as any} maximumFractionDigits={2} />
                            {unit ? <>&nbsp;{unit}</> : null}
                        </Typography>
                    </Box>
                </ResultTooltipBox>
            )
        }
        return null
    }

    const renderActiveShape = (props: any) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

        return (
            <g>
                <Animation from={{ t: 0 }} to={{ t: pieOffset }} duration={activePieAnimationDurationMs}>
                    {({ t }: { t: number }) => {
                        return (
                            <Sector
                                style={{ cursor: 'pointer' }}
                                cx={cx}
                                cy={cy}
                                innerRadius={innerRadius - t}
                                outerRadius={outerRadius + t}
                                startAngle={startAngle}
                                endAngle={endAngle}
                                fill={fill}
                            />
                        )
                    }}
                </Animation>
            </g>
        )
    }

    return (
        <>
            <Box padding={1} paddingBottom={2} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Typography sx={{ pl: 1 }}>
                    <strong>
                        <FormattedMessage id={title} />
                    </strong>
                </Typography>
            </Box>

            <Box width="100%" padding={2} paddingTop={0} height={450}>
                <ResponsiveContainer width="100%" height="100%">
                    <RPieChart>
                        <Pie
                            dataKey="total"
                            data={formatData()}
                            activeShape={renderActiveShape}
                            activeIndex={activePieIndex}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            innerRadius={matches ? 40 : 60}
                            outerRadius={matches ? 90 : 130}
                            startAngle={90}
                            endAngle={-270}
                            paddingAngle={1}
                            minAngle={2}
                            label={PieChartLabel}
                            labelLine={false}
                        >
                            {formatData().map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.tag.color} />
                            ))}
                        </Pie>
                        <Tooltip content={PieChartTooltip} cursor={false} />
                        <Legend formatter={(value) => <>{formatTagName(value)}</>} />
                    </RPieChart>
                </ResponsiveContainer>
            </Box>
        </>
    )
}
