import React, { FC } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { useSelector } from 'react-redux'
import { isExternalForProject } from '../core/slices/CoreSlice'
import { getPostDialogSelectedTab, getSelectedPostGroup, PublishingState } from './PublishingSlice'
import { FormattedMessage } from 'react-intl'

type PublishingTabsMobileProps = {
    onTabChange: (newTabValue: PublishingState['postDialogSelectedTab']) => any
}
export const PublishingTabsMobile: FC<PublishingTabsMobileProps> = ({ onTabChange }) => {
    const selectedTab = useSelector(getPostDialogSelectedTab)
    const isUserExternal = useSelector(isExternalForProject)
    const getActualTab = () => {
        if (selectedTab === 'COMMON' && !isUserExternal) return 'INTERNAL'
        if (selectedTab === 'COMMON' && isUserExternal) return 'EXTERNAL'
        else return selectedTab
    }
    const selectedPostGroup = useSelector(getSelectedPostGroup)

    return (
        <Box color={'inherit'}>
            <Tabs value={getActualTab()} onChange={(e, value) => onTabChange(value)} indicatorColor={'secondary'} color={'inherit'} variant={'fullWidth'}>
                {selectedPostGroup && !isUserExternal && (
                    <Tab
                        label={<FormattedMessage id="general.internal-activity-log" />}
                        value="INTERNAL"
                        sx={{
                            color: getActualTab() === 'INTERNAL' ? 'inherit !important' : undefined,
                        }}
                    />
                )}
                {selectedPostGroup && (
                    <Tab
                        label={<FormattedMessage id={isUserExternal ? 'general.activity-log' : 'general.external-activity-log'} />}
                        sx={{
                            color: getActualTab() === 'EXTERNAL' ? 'inherit !important' : undefined,
                        }}
                        value="EXTERNAL"
                    />
                )}
            </Tabs>
        </Box>
    )
}
