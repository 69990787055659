import * as React from 'react'
import { FC } from 'react'
import { NotificationCheckbox } from './NotificationCheckbox'
import { settingsForTab } from './NotificationProjectEntry'
import { NotificationSettingsTabType } from './NotificationSettingsTab'

type NotificationSettingsRowProps = {
    tab: NotificationSettingsTabType
    highlightedSetting?: string
    highlighted: boolean
    rowName: string
}

export const NotificationSettingsRow: FC<NotificationSettingsRowProps> = ({ tab, highlightedSetting, highlighted, rowName }) => {
    return (
        <>
            {settingsForTab[tab].map((setting: string) => {
                return (
                    <NotificationCheckbox
                        key={`${setting}`}
                        rowName={rowName}
                        settingName={setting}
                        highlighted={highlighted || setting === highlightedSetting}
                    />
                )
            })}
        </>
    )
}
