import * as React from 'react'
import { FC, useRef, useState } from 'react'
import { alpha, Box, Chip, ClickAwayListener, TextField, Typography, useTheme } from '@mui/material'
import { Search } from '@mui/icons-material'
import { AnimatePresence, motion } from 'framer-motion'
import { useIntl } from 'react-intl'
import { useHotkeys } from 'react-hotkeys-hook'
import { KeyDisplay } from '../hotkeys/KeyDisplay'
import { useSelector } from 'react-redux'
import { getIsMobile } from '../../core/slices/CoreSlice'

type SearchChipProps = {
    onChange: (value: string) => void
    value: string
    searchbarTitle?: string
}

export const SearchChip: FC<SearchChipProps> = ({ onChange, value, searchbarTitle }) => {
    const [isFocused, setIsFocused] = useState(false)
    const theme = useTheme()
    const intl = useIntl()
    const inputRef = useRef<any>(null)
    const isMobile = useSelector(getIsMobile)

    const closeSearchBar = () => {
        setIsFocused(false)
        if (inputRef.current) {
            inputRef.current.blur()
        }
    }

    const handleClickSearchChip = () => {
        setIsFocused(true)
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus()
                inputRef.current.select()
            }
        }, 100)
    }

    useHotkeys('enter', () => closeSearchBar(), { enableOnFormTags: true })
    useHotkeys('esc', () => closeSearchBar(), { enableOnFormTags: true })
    useHotkeys('/', () => handleClickSearchChip(), { preventDefault: true })
    const placeholder = value.length > 0 ? value : intl.formatMessage({ id: searchbarTitle || 'general.search' })
    const searchInput = () => {
        return (
            <TextField
                inputRef={inputRef}
                value={value}
                onBlur={() => {
                    setIsFocused(false)
                }}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{ startAdornment: <Search fontSize={'small'} /> }}
                color={'secondary'}
                placeholder={placeholder}
                sx={{
                    flexGrow: 1,
                    '& .MuiInput-root:before, .MuiInput-root:after': { display: 'none !important' },
                }}
            />
        )
    }
    return (
        <AnimatePresence>
            <ClickAwayListener onClickAway={() => setIsFocused(false)}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'flex-end'}
                    sx={{
                        '& .MuiButtonBase-root.MuiChip-root': {
                            backgroundColor: isFocused ? theme.palette.background.paper : undefined,
                        },
                        '& .MuiTouchRipple-root': { display: 'none' },
                    }}
                >
                    <motion.div
                        onFocus={handleClickSearchChip}
                        initial={false}
                        animate={{ width: isFocused ? (isMobile ? '200px' : '300px') : 'auto' }}
                        style={{ display: 'flex' }}
                        onClick={!isFocused ? handleClickSearchChip : undefined}
                    >
                        <Chip
                            label={
                                isFocused ? (
                                    searchInput()
                                ) : (
                                    <Box display={'flex'} gap={1} sx={{ alignItems: 'center' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '0.8125rem',
                                                maxWidth: value.length > 0 ? '200px' : undefined,
                                                overflow: value.length > 0 ? 'hidden' : undefined,
                                                textOverflow: value.length > 0 ? 'ellipsis' : undefined,
                                                fontStyle: value.length > 0 ? 'italic' : undefined,
                                            }}
                                        >
                                            {placeholder}
                                        </Typography>
                                        {!isMobile && <KeyDisplay keyboardKey={'/'} />}
                                    </Box>
                                )
                            }
                            icon={isFocused ? undefined : <Search fontSize={'small'} />}
                            onDelete={() => onChange('')}
                            deleteIcon={value.length > 0 ? undefined : <></>}
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                outline: isFocused ? `2px solid ${theme.palette.primary.main}` : undefined,
                                backgroundColor: isFocused ? theme.palette.background.paper : undefined,
                                cursor: !isFocused ? 'pointer' : 'unset',
                                '&:hover': {
                                    backgroundColor: alpha(
                                        theme.palette.action.selected,
                                        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
                                    ),
                                },
                                '& .MuiChip-label': {
                                    flexGrow: 1,
                                    display: 'flex',
                                },
                            }}
                        />
                    </motion.div>
                </Box>
            </ClickAwayListener>
        </AnimatePresence>
    )
}
