import _ from 'lodash'
import { useForm, useFormState } from 'react-final-form'
import { PublishingFormValues, ValidDatasourceTypeForPublishing } from './PublishingForm'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { useSelector } from 'react-redux'
import { getDatasourcesAsIdMap } from '../core/slices/CoreSlice'
import { getSelectedPostGroup, isApprovalProcessActive } from './PublishingSlice'

export const usePublishingFormDatasources = (): ProjectDatasource[] => {
    const formState = useFormState<PublishingFormValues>()
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    return formState.values.data_source_ids
        .map((ds_id) => {
            const channel = datasourcesById[ds_id]

            if (channel && channel.type === 'LINKED_IN_PROFILE') {
                return { ...channel, type: 'LINKED_IN' }
            }

            return channel
        })
        .filter((d) => !!d) as ProjectDatasource[]
}

export const usePublishingFormDatasourceTypes = (): ValidDatasourceTypeForPublishing[] => {
    const datasources = usePublishingFormDatasources()
    return _.chain(datasources).groupBy('type').keys().value().sort() as ValidDatasourceTypeForPublishing[]
}

export const useWillSaveRevokeApproval = (): boolean => {
    const approvalActive = useSelector(isApprovalProcessActive)
    const formState = useFormState()
    const selectedPostGroup = useSelector(getSelectedPostGroup)
    const approved =
        selectedPostGroup && (selectedPostGroup.internal_approval?.state === 'APPROVED' || selectedPostGroup.external_approval?.state === 'APPROVED')
    const fields = formState.submitSucceeded ? formState.dirtyFieldsSinceLastSubmit : formState.dirtyFields
    const dirtyFieldKeys = Object.keys(fields)
    const excludedFields = ['common_post.publish_time', 'common_post.state', 'tag_ids', 'assignee_id']
    const relevantDirtyFields = dirtyFieldKeys.filter((f) => !excludedFields.includes(f))

    return Boolean(approvalActive && approved && relevantDirtyFields.length > 0)
}

export const useCanDraftBeSaved = (): boolean => {
    const form = useForm()
    const formState = useFormState()
    const customizePostsByNetwork = formState.values.customize_posts_by_network
    let postTypeValid = customizePostsByNetwork ? true : !!formState.values.common_post.post_type

    if (formState.values.customize_posts_by_network) {
        if (formState.values.postByType) {
            const networks = Object.keys(formState.values.postByType)

            networks.forEach((network) => {
                const fieldState = form.getFieldState(`postByType.${network}.post_type`)
                if (!fieldState?.valid) {
                    postTypeValid = false
                }
            })
        } else {
            postTypeValid = false
        }
    }

    return formState.values.data_source_ids.length > 0 && !!formState.values.common_post.publish_time && postTypeValid
}
