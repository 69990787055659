import { FC } from 'react'
import { Field } from 'react-final-form'
import { Chip, TextField, Autocomplete, createFilterOptions } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

type ConnectedAutocompleteProps = {
    name: string
    options: any[]
    label?: string
    helperText?: string
    shrinkLabel?: boolean
    validate?: any
    fullWidth?: boolean
    disabled?: boolean
    onAddOption?: (option: string) => any
}

type Option = {
    value: string
    label: string
}

const filter = createFilterOptions<Option>()

export const ConnectedAutocomplete: FC<ConnectedAutocompleteProps> = (props) => {
    const { name, label, shrinkLabel, validate, helperText, fullWidth, disabled, options, onAddOption } = props
    const intl = useIntl()

    const handleChange = (event: any, newValues: any[], input: any) => {
        const mappedValues = newValues.map((v) => {
            if (typeof v === 'string') {
                return v
            } else {
                if (onAddOption) {
                    onAddOption(v.value)
                }

                return v.value
            }
        })

        input.onChange(mappedValues)
    }

    return (
        <Field name={name} validate={validate}>
            {({ input, meta }) => (
                <Autocomplete
                    multiple
                    renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => <Chip size="small" label={option} {...getTagProps({ index })} />)
                    }
                    options={options}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params)

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                value: params.inputValue,
                                label: intl.formatMessage({ id: 'general.autocomplete.add-option' }, { option: params.inputValue }),
                            })
                        }

                        return filtered
                    }}
                    value={input.value || []}
                    onChange={(event, newValue: any) => handleChange(event, newValue, input)}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    disabled={disabled}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue
                        }
                        // Regular option
                        return option.label
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{ shrink: shrinkLabel }}
                            label={label && <FormattedMessage id={label} />}
                            fullWidth={fullWidth !== false}
                            error={meta.error && meta.touched}
                            helperText={meta.touched && meta.error ? <FormattedMessage id={meta.error} /> : helperText && <FormattedMessage id={helperText} />}
                        />
                    )}
                />
            )}
        </Field>
    )
}
