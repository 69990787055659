import * as React from 'react'
import { FC } from 'react'
import { useStartTour } from '../common/guides/useStartTour'
import { FormattedMessage } from 'react-intl'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../core/slices/CoreSlice'

type IndividualizeNetworksButtonProps = { handleCustomizeClick: () => void; datasourceTypes: any; disabled?: boolean }

export const IndividualizeNetworksButton: FC<IndividualizeNetworksButtonProps> = ({ handleCustomizeClick, disabled, datasourceTypes }) => {
    useStartTour('publishingFormIndividualizeTour')
    const darkmodeEnabled = useSelector(isDarkMode)
    return (
        <Button
            id={'individualize_networks_button'}
            onClick={handleCustomizeClick}
            variant="contained"
            color={darkmodeEnabled ? 'primary' : 'inherit'}
            size="small"
            disabled={disabled || datasourceTypes.length <= 1}
            sx={{ whiteSpace: 'nowrap', minWidth: 'unset' }}
        >
            <FormattedMessage id={'publishing.individualize-networks'} />
        </Button>
    )
}
