import { FC, PropsWithChildren, useState } from 'react'
import { Box, Collapse, Divider, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

type SectionCollapseProps = {
    label: string
    defaultOpen?: boolean
}

export const SectionCollapse: FC<PropsWithChildren<SectionCollapseProps>> = ({ defaultOpen, label, children }) => {
    const [sectionOpen, setSectionOpen] = useState(!!defaultOpen)

    return (
        <Box>
            <Box sx={{ cursor: 'pointer', p: 2, display: 'flex', alignItems: 'center' }} onClick={() => setSectionOpen(!sectionOpen)}>
                {sectionOpen ? <ExpandLess /> : <ExpandMore />}
                <Typography variant="subtitle2">
                    <FormattedMessage id={label} />
                </Typography>
            </Box>
            <Collapse in={sectionOpen}>
                <Box p={2}>{children}</Box>
            </Collapse>
            <Divider />
        </Box>
    )
}
