import { createSlice, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { fetchMentionsForName } from './MentionActions'
import _ from 'lodash'
import { MentionCandidate } from './MentionCandidate'

export interface MentionState {
    mentionCandidates: MentionCandidate[]
    mentionsLoading: boolean
    alreadyFetchedQueries: {
        LINKED_IN: { [data_source_id: string]: string[] }
        TIKTOK_ACCOUNT: { [data_source_id: string]: string[] }
        FACEBOOK_PAGE: { [data_source_id: string]: string[] }
        INSTAGRAM_ACCOUNT: { [data_source_id: string]: string[] }
    }
}

const initialState: MentionState = {
    mentionCandidates: [],
    mentionsLoading: false,
    alreadyFetchedQueries: {
        LINKED_IN: {},
        FACEBOOK_PAGE: {},
        TIKTOK_ACCOUNT: {},
        INSTAGRAM_ACCOUNT: {},
    },
}

export const MentionSlice = createSlice({
    name: 'mentions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMentionsForName.pending, (state) => {
                state.mentionsLoading = true
            })
            .addCase(fetchMentionsForName.rejected, (state) => {
                state.mentionsLoading = false
            })
            .addCase(fetchMentionsForName.fulfilled, (state, action) => {
                state.mentionsLoading = false
                const type = action.meta.arg.datasource.type as keyof MentionState['alreadyFetchedQueries']
                const datasourceId = action.meta.arg.datasource.id
                const queriesForDatasource = state.alreadyFetchedQueries[type][datasourceId] || []
                if (!queriesForDatasource.includes(action.meta.arg.searchString)) {
                    queriesForDatasource.push(action.meta.arg.searchString)
                }
                state.alreadyFetchedQueries[type][datasourceId] = queriesForDatasource
                state.mentionCandidates = _.uniqBy([...state.mentionCandidates, ...action.payload], 'id')
            })
    },
})

export const getMentions: Selector<HmstrState, MentionState['mentionCandidates']> = (state) => state.mentions.mentionCandidates

export const getMentionsLoading: Selector<HmstrState, MentionState['mentionsLoading']> = (state) => state.mentions.mentionsLoading
export const getAlreadySearchedMentions: Selector<HmstrState, MentionState['alreadyFetchedQueries']> = (state) => state.mentions.alreadyFetchedQueries

export const MentionReducer = MentionSlice.reducer
