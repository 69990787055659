import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { AiGenerationFormValues } from './PostTextGenerationForm'
import { Project } from '../../settings/project-management/Project'
import { doPost } from '../../core/api/ApiClient'

const POST_TEXT_GENERATION_SLASH = (action: string) => `post_text_generation/${action}`

export const fetchPostTextSuggestions = createAsyncThunk<
    string[],
    {
        values: AiGenerationFormValues
        project: Project
    },
    { state: HmstrState }
>(POST_TEXT_GENERATION_SLASH('fetchPostTextSuggestions'), async ({ values, project }, thunkAPI) => {
    return await doPost(thunkAPI, project._links.post_text_suggestions, values)
})
