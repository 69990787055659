import { DateRangePickerStaticRanges } from '../../common/daterange-picker/DateRangePickerRanges'
import { IntlShape } from 'react-intl'
import moment from 'moment'

export const getAttributionTranslation = (attributionWindow: '1d_click' | '7d_click' | '1d_view') => {
    switch (attributionWindow) {
        case '1d_click':
            return 'facebook.ads.attribution-window.1d_click'
        case '7d_click':
            return 'facebook.ads.attribution-window.7d_click'
        case '1d_view':
            return 'facebook.ads.attribution-window.1d_view'
    }
}

export const checkStaticValues = (start: Date | undefined, end: Date | undefined, intl: IntlShape, strict: boolean = false) => {
    const staticRanges = DateRangePickerStaticRanges(intl, strict)

    for (let i = 0; i < staticRanges.length; i++) {
        if (
            staticRanges[i].isSelected({
                startDate: start,
                endDate: end,
            })
        ) {
            return staticRanges[i]
        }
    }
}

export const renderTimePeriodString = (startTime: string, endTime: string, intl: IntlShape) => {
    const selectionRange = {
        startDate: moment(startTime).toDate(),
        endDate: moment(endTime).toDate(),
        key: 'selection',
    }
    let staticMatch = checkStaticValues(selectionRange.startDate, selectionRange.endDate, intl, false)
    if (staticMatch) {
        return staticMatch.label
    }
    return moment(startTime).format('l') + ' - ' + moment(endTime).format('l')
}
