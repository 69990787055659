import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../core/Store'
import { doDelete, doGet, doPost, doPut } from '../core/api/ApiClient'
import { Dashboard } from './Dashboard'
import { DashboardTemplate } from './DashboardTemplate'
import { Moment } from 'moment'
import axios from 'axios'
import { showSuccessSnackbar } from '../core/slices/CoreSlice'
import { Project } from '../settings/project-management/Project'

const DASHBOARDS_SLASH = (action: string) => `dashboards/${action}`

export const fetchDashboardsForProject = createAsyncThunk<Dashboard[], Project, { state: HmstrState }>(
    DASHBOARDS_SLASH('fetchDashboardsForProject'),
    async (project, thunkAPI) => {
        return await doGet(thunkAPI, project._links.dashboards)
    }
)

export const fetchTemplates = createAsyncThunk<DashboardTemplate[], Project, { state: HmstrState }>(
    DASHBOARDS_SLASH('fetchTemplates'),
    async (project, thunkAPI) => {
        return await doGet(thunkAPI, project._links.templates)
    }
)

export const createDashboard = createAsyncThunk<Dashboard, { project: Project; dashboard: Dashboard }, { state: HmstrState }>(
    DASHBOARDS_SLASH('createDashboard'),
    async (args, thunkAPI) => {
        let result = await doPost(thunkAPI, args.project._links.dashboards, args.dashboard)
        thunkAPI.dispatch(showSuccessSnackbar('reporting.dashboards.create-success'))
        return result
    }
)

export const editDashboard = createAsyncThunk<Dashboard, { project: Project; dashboard: Dashboard }, { state: HmstrState }>(
    DASHBOARDS_SLASH('editDashboard'),
    async (args, thunkAPI) => {
        let result = await doPut(thunkAPI, args.dashboard._links.self, args.dashboard)
        thunkAPI.dispatch(showSuccessSnackbar('reporting.dashboards.edit-success'))
        return result
    }
)

export const deleteDashboard = createAsyncThunk<Dashboard, Dashboard>(DASHBOARDS_SLASH('deleteDashboard'), async (dashboard, thunkAPI) => {
    await doDelete(thunkAPI, dashboard._links.self)
    return dashboard
})

export type PdfParams = {
    startDate: Moment
    endDate: Moment
    compareStartDate?: Moment
    compareEndDate?: Moment
}

export type ExportParams = {
    fileType: 'pdf' | 'pptx'
    startDate: Moment
    endDate: Moment
    compareStartDate?: Moment
    compareEndDate?: Moment
    attribution_windows?: string
    without_consent_loss?: boolean
    selected_datasources?: any
    selected_tag_ids?: any
}

export type GenerateDashboardResult = {
    dashboard: Dashboard
    downloaded: boolean
    statusCode: number
}

export type DashboardRequest = {
    start_date: string
    end_date: string
    start_compare_date: string
    end_compare_date: string
    without_consent_loss?: boolean
    selected_datasources?: string[]
    selected_tags?: string[]
    attribution_windows?: string[]
}

export const fetchDataForSelectedDashboard = createAsyncThunk<
    any,
    {
        reloadDashboard: boolean
    }
>(DASHBOARDS_SLASH('fetchDataForDashboard'), async ({ reloadDashboard }, thunkAPI) => {
    const state = thunkAPI.getState() as HmstrState

    if (!state.dashboards.selectedDashboardId) {
        return Promise.reject()
    }

    const selectedDashboard = state.data.dashboards[state.dashboards.selectedDashboardId]

    return await doGet(thunkAPI, selectedDashboard._links.data, (response) => response.widgets, {
        params: {
            start_date: state.dashboards.startDate,
            end_date: state.dashboards.endDate,
            start_compare_date: state.dashboards.compareStartDate,
            end_compare_date: state.dashboards.compareEndDate,
            selected_datasources: state.dashboards.selectedDatasourceIds,
            selected_tags: state.dashboards.selectedTags,
            attribution_windows: state.dashboards.attributionWindows,
            without_consent_loss: state.dashboards.estimateWithoutConsentLoss,
        },
    })
})

const generateDashboardExportFile = (args: { exportParams: ExportParams; dashboard: Dashboard }) => {
    const { startDate, endDate, compareStartDate, compareEndDate } = args.exportParams
    const dashboard = args.dashboard

    return new Promise<GenerateDashboardResult>((resolve) => {
        axios
            .get(dashboard._links[args.exportParams.fileType].href, {
                responseType: 'blob',
                params: {
                    start_date: startDate.format('YYYY-MM-DD'),
                    end_date: endDate.format('YYYY-MM-DD'),
                    start_compare_date: compareStartDate?.format('YYYY-MM-DD'),
                    end_compare_date: compareEndDate?.format('YYYY-MM-DD'),
                    without_consent_loss: args.exportParams.without_consent_loss,
                    selected_datasources: args.exportParams.selected_datasources,
                    attribution_windows: args.exportParams.attribution_windows,
                    selected_tag_ids: args.exportParams.selected_tag_ids,
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${dashboard.name}_${startDate.format('YYYY-MM')}.${args.exportParams.fileType}`)
                document.body.appendChild(link)
                link.click()
                link.remove()
                resolve({ dashboard, downloaded: true, statusCode: response.status })
            })
            .catch((response) => resolve({ dashboard, downloaded: false, statusCode: response.status }))
    })
}

export const generateDashboardPdf = createAsyncThunk<GenerateDashboardResult, { dashboard: Dashboard; exportParams: ExportParams }>(
    DASHBOARDS_SLASH('generateDashboardPdf'),
    async (args) => {
        return generateDashboardExportFile(args)
    }
)

export const generateDashboardPptx = createAsyncThunk<GenerateDashboardResult, { dashboard: Dashboard; exportParams: ExportParams }>(
    DASHBOARDS_SLASH('generateDashboardPptx'),
    async (args) => {
        return await generateDashboardExportFile(args)
    }
)
