import { FC } from 'react'
import { Tickets } from './Tickets'
import { getOpenUnassignedTickets } from './EngagementSlice'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { Inbox as InboxIcon } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { Relax } from '../common/microinteractions/Relax'

type InboxProps = {}

export const Inbox: FC<InboxProps> = () => {
    const tickets = useSelector(getOpenUnassignedTickets)

    return (
        <Tickets
            tab={'new'}
            state="OPEN"
            tickets={tickets}
            title="engagement.inbox"
            icon={<InboxIcon sx={{ mr: 1 }} fontSize="inherit" />}
            emptyState={
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Box width={'100%'} sx={{ maxWidth: '600px' }}>
                        <Relax />
                    </Box>

                    <Box mt={4}>
                        <Typography variant={'h5'} textAlign={'center'} color={'primary.light'} maxWidth={'500px'} fontWeight={'lighter'}>
                            <FormattedMessage id="engagement.no-tickets-open" />
                        </Typography>
                    </Box>
                </Box>
            }
        />
    )
}
