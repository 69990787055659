import * as React from 'react'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { getDatasourcesAsIdMap, isDarkMode } from '../../../core/slices/CoreSlice'
import { Grid, Skeleton, Typography } from '@mui/material'
import { PostAvatar } from '../../../common/avatars/PostAvatar'
import { Public } from '@mui/icons-material'
import { PostPreviewVideo } from '../PostPreviewVideo'
import { PostPreviewImageGrid } from '../PostPreviewImageGrid'
import { LinkPreview } from '../../../common/link-preview/LinkPreview'
import { Post } from '../../posts/Post'
import { LinkedInPostActionsPreview } from './LinkedInPostActionsPreview'
import { getMedia } from '../../../core/slices/DataSlice'
import { getErrorsForCommonFile } from '../../PublishingSlice'
import { replaceMentions } from '../../mentions/MentionService'
import { hashTagExpr } from '../../../core/theme/helper'

type LinkedInPostPreviewProps = {
    post: Post
}

export const LinkedInPostPreview: FC<LinkedInPostPreviewProps> = ({ post }) => {
    const datasourcesById = useSelector(getDatasourcesAsIdMap)
    const selectedDatasource = datasourcesById[post.data_source_id]
    const postType = post.post_type
    const media = useSelector(getMedia)
    const files = (post.file_ids || []).map((file_id) => media[file_id]).filter((m) => !!m)
    const thumbnailFile = post.link_preview_file_id ? media[post.link_preview_file_id] : undefined
    const errorsForFiles = useSelector(getErrorsForCommonFile)
    const darkmode = useSelector(isDarkMode)
    const hashtagColor = darkmode ? 'hsl(214, 100%, 59%)' : 'rgb(0, 55, 200)'

    const actualFiles = files.filter(
        (f) =>
            (f.category === postType || postType === 'MULTI_MEDIA') &&
            !errorsForFiles[f.id]
                ?.map((error) => {
                    return error ? error.network === selectedDatasource.type : false
                })
                .includes(true)
    )

    const generatePostText = (text: string) => {
        const escapeHtml = (unsafe: string) => {
            return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
        }
        const hashtags = text.match(hashTagExpr)
        const generateContent = () => {
            let newText = escapeHtml(text)
            newText = replaceMentions(newText, hashtagColor)

            hashtags
                ?.sort((a, b) => b.length - a.length)
                .forEach((hashtag: string) => {
                    const pattern = `${hashtag}(?!\\w)`
                    const hashtagRegex = new RegExp(pattern, 'g')
                    newText = newText.replaceAll(hashtagRegex, `<span style='color: ${hashtagColor}; cursor: pointer;'>${escapeHtml(hashtag)}</span>`)
                })
            return <span dangerouslySetInnerHTML={{ __html: newText }}></span>
        }

        return (
            <span
                style={{
                    fontSize: 15,
                    color: darkmode ? 'inherit' : '#050505',
                    fontWeight: 400,
                    lineHeight: '14px',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                }}
            >
                {generateContent()}
            </span>
        )
    }

    return (
        <Grid container spacing={1.5} alignItems="center">
            <Grid item>
                {selectedDatasource.metadata.profile_picture_url && (
                    <PostAvatar width={40} height={40} name={selectedDatasource.name} src={selectedDatasource.metadata.profile_picture_url} />
                )}
            </Grid>
            <Grid item xs={6} sx={{ lineHeight: 1 }}>
                <Typography sx={{ fontSize: 15, color: darkmode ? 'inherit' : '#050505', fontWeight: 600, lineHeight: 1.3 }}>
                    {selectedDatasource.name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                        color: '#65676b',
                        lineHeight: 1.3,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Just now &nbsp;·&nbsp;
                    <Public sx={{ fontSize: 12 }} />
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography sx={{ wordWrap: 'break-word', color: 'inherit' }}>
                    {post.text ? (
                        generatePostText(post.text)
                    ) : (
                        <>
                            <Skeleton animation={false} />
                            <Skeleton animation={false} />
                        </>
                    )}
                </Typography>
            </Grid>

            {postType === 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12}>
                    <PostPreviewVideo variant="STANDARD" file={actualFiles[0]} title={post.video_title} />
                </Grid>
            )}

            {postType !== 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12}>
                    <PostPreviewImageGrid uploadFiles={actualFiles} />
                </Grid>
            )}

            {post.link && (
                <Grid item xs={12}>
                    <LinkPreview
                        url={post.link}
                        ctaType={post.data_source_type === 'FACEBOOK_PAGE' ? post.cta_type : undefined}
                        title={post.link_preview_title}
                        description={post.link_preview_description}
                        thumbnail={thumbnailFile}
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <LinkedInPostActionsPreview />
            </Grid>
        </Grid>
    )
}
