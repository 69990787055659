import { FC } from 'react'
import { Box, Grid, Link, Paper, Typography } from '@mui/material'
import { Facebook, Mail, Phone } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { ContactPerson } from '../../settings/project-management/Project'

type FacebookContactPersonCardProps = {
    contactPerson: ContactPerson
}

export const ContactPersonCard: FC<FacebookContactPersonCardProps> = ({ contactPerson }) => {
    const { first_name, last_name, email, phone, title, facebook } = contactPerson

    return (
        <Paper>
            <Box p={2} height={199}>
                <Typography noWrap={true} sx={{ mb: 1 }}>
                    <strong>
                        <FormattedMessage id="general.contactPerson.title" />
                    </strong>
                </Typography>

                <Grid container>
                    {first_name && last_name && (
                        <Grid item xs={12}>
                            <Typography noWrap={true}>
                                {first_name} {last_name}
                            </Typography>
                        </Grid>
                    )}

                    {title && (
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Typography noWrap={true} variant="body2" color="text.secondary">
                                {title}
                            </Typography>
                        </Grid>
                    )}

                    {email && (
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                            <Mail fontSize="small" color="action" sx={{ mr: 1 }} />
                            <Typography noWrap={true} variant="body2" color="text.secondary">
                                <Link href={`mailto:${email}`} target="_blank">
                                    {email}
                                </Link>
                            </Typography>
                        </Grid>
                    )}

                    {phone && (
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Phone fontSize="small" color="action" sx={{ mr: 1 }} />
                            <Typography noWrap={true} variant="body2" color="text.secondary">
                                {phone}
                            </Typography>
                        </Grid>
                    )}

                    {facebook && (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography color="text.primary">
                                <Link href={facebook} sx={{ color: 'text.primary' }} target="_blank">
                                    <Facebook color="action" />
                                </Link>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper>
    )
}
