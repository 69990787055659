import * as React from 'react'
import { FC, useState } from 'react'
import { TicketNode } from './TicketNode'
import { Box, Skeleton, Tooltip, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { DetailedTicket } from './DetailedTicket'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { BrokenImage, Delete } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../core/slices/CoreSlice'
import { useImage } from '../core/hooks/useImage'

type TicketAttachmentProps = {
    ticket: DetailedTicket
    node: TicketNode
    datasource: ProjectDatasource
    openCommentFunction?: () => void
    isMobile?: boolean
}

export const TicketAttachment: FC<TicketAttachmentProps> = ({ ticket, node, datasource, openCommentFunction, isMobile }) => {
    const theme = useTheme()
    const intl = useIntl()
    const [cantDisplayAttachment, setCantDisplayAttachment] = useState(false)
    const darkmode = useSelector(isDarkMode)

    const imageLink = node.has_attachment && !node.is_attachment_unsupported ? node._links.attachment.href : node.initial_attachment_link

    const attachment = useImage(imageLink, true, {
        'X-Datasource-Id': ticket.data_source_id,
        'X-Target-Id': ticket.target_id,
    })

    const handleOpenComment = () => {
        if (openCommentFunction) {
            openCommentFunction()
        }
    }

    if (node.is_attachment_unsupported && !node.initial_attachment_link) {
        return (
            <Tooltip
                title={
                    ticket.type === 'POST' && datasource.type === 'INSTAGRAM_ACCOUNT' ? <FormattedMessage id={'engagement.instagram.gif-not-available'} /> : ''
                }
                disableInteractive
            >
                <Box
                    sx={{
                        width: isMobile ? '100px' : '200px',
                        height: isMobile ? '100px' : '200px',
                        marginTop: 2,
                        borderRadius: '20px',
                        background: theme.palette.grey.A200,
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: isMobile ? '1rem' : '20px',
                        cursor: 'pointer',
                    }}
                    onClick={handleOpenComment}
                >
                    {ticket.type === 'POST' && datasource.type === 'INSTAGRAM_ACCOUNT' ? (
                        'GIF'
                    ) : (
                        <FormattedMessage id={'engagement.attachment-unsupported-by'} values={{ network: intl.formatMessage({ id: datasource.type }) }} />
                    )}
                </Box>
            </Tooltip>
        )
    }

    if (cantDisplayAttachment || attachment.hasErrors) {
        return (
            <Box
                sx={{
                    width: isMobile ? '100px' : '200px',
                    height: isMobile ? '100px' : '200px',
                    marginTop: 2,
                    borderRadius: '20px',
                    background: darkmode ? theme.palette.grey[600] : theme.palette.grey.A200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '16px',
                    cursor: openCommentFunction ? 'pointer' : undefined,
                    flexDirection: 'column',
                    gap: 1,
                }}
                onClick={handleOpenComment}
            >
                {openCommentFunction ? (
                    <>
                        <BrokenImage color={'action'} fontSize={'large'} />
                        <FormattedMessage id={'general.loading-error'} />
                    </>
                ) : (
                    <>
                        <Delete color={'action'} fontSize={'large'} />
                        <FormattedMessage id={'engagement.REMOVED_BY_USER'} />
                    </>
                )}
            </Box>
        )
    }

    const handleError = () => {
        setCantDisplayAttachment(true)
    }

    return (
        <>
            {imageLink && !attachment.objectUrl && (
                <Skeleton
                    variant={'rectangular'}
                    animation={'wave'}
                    sx={{ borderRadius: '20px', marginTop: 2 }}
                    width={isMobile ? '100px' : '200px'}
                    height={isMobile ? '100px' : '200px'}
                />
            )}
            {attachment.contentType?.includes('video') && attachment.objectUrl && (
                <video
                    style={{
                        maxWidth: '100%',
                        maxHeight: isMobile ? '100px' : '200px',
                        marginTop: 16,
                        borderRadius: '20px',
                    }}
                    autoPlay
                    muted
                    loop
                    playsInline={true}
                    controls={ticket.type === 'MESSAGE'}
                    src={imageLink}
                    onError={handleError}
                />
            )}
            {attachment.contentType?.includes('image') && attachment.objectUrl && (
                <img
                    style={{
                        maxWidth: '100%',
                        maxHeight: isMobile ? '100px' : '200px',
                        marginTop: 16,
                        borderRadius: '20px',
                    }}
                    alt={'document attachment'}
                    src={imageLink}
                    onError={handleError}
                />
            )}
        </>
    )
}
