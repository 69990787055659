import * as React from 'react'
import { FC } from 'react'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { Button, Card, CardActions, CardContent, Grid, Tooltip, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Upload } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getSelectedAccount, isProjectAdmin } from '../core/slices/CoreSlice'

type ConversionTrackingCardProps = {
    adAccount: ProjectDatasource
    onUpload?: (adAccount: ProjectDatasource) => any
    onActivate?: (adAccount: ProjectDatasource) => any
    onDeactivate?: (adAccount: ProjectDatasource) => any
}

export const ConversionTrackingCard: FC<ConversionTrackingCardProps> = ({ adAccount, onActivate, onDeactivate, onUpload }) => {
    const isUserProjectAdmin = useSelector(isProjectAdmin)
    const currentAccount = useSelector(getSelectedAccount)
    const disableActivateButton = currentAccount.conversion_tracking_count >= currentAccount.conversion_tracking_limit

    const handleActivate = () => {
        if (onActivate) {
            onActivate(adAccount)
        }
    }

    const handleDeactivate = () => {
        if (onDeactivate) {
            onDeactivate(adAccount)
        }
    }

    const handleUpload = () => {
        if (onUpload) {
            onUpload(adAccount)
        }
    }

    return (
        <Grid item key={adAccount.id} xs={6}>
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom={true}>
                        {adAccount.name}
                    </Typography>

                    <Typography color="text.secondary">
                        <FormattedMessage id="conversion-tracking.adaccount-id" />: {adAccount.metadata.id}
                    </Typography>
                </CardContent>

                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    {!adAccount.conversion_tracking && (
                        <Button onClick={handleActivate} color={'inherit'} disabled={disableActivateButton}>
                            <FormattedMessage id="conversion-tracking.activate" />
                        </Button>
                    )}
                    {adAccount.conversion_tracking && (
                        <Tooltip title={!isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : ''}>
                            <span>
                                <Button onClick={handleDeactivate} disabled={!isUserProjectAdmin} color={'inherit'}>
                                    <FormattedMessage id="conversion-tracking.deactivate" />
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                    {adAccount.conversion_tracking && (
                        <Button startIcon={<Upload />} onClick={handleUpload} variant="contained" color="secondary">
                            <FormattedMessage id="conversion-tracking.upload" />
                        </Button>
                    )}
                </CardActions>
            </Card>
        </Grid>
    )
}
