import { FC, memo, MouseEvent, useState } from 'react'
import moment from 'moment'
import { Box, Button, ButtonGroup, Grid, Menu, MenuItem, Paper, Typography, useTheme } from '@mui/material'
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ReferenceArea,
    ResponsiveContainer,
    Sector,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { ChartTooltip } from '../common/chart-tooltip/ChartTooltip'
import { GraphBox } from './Results.styles'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeGroupBy,
    getIsLoadingResults,
    getResultsGroupedBy,
    getResultsPieData,
    getSelectedChartType,
    getShowPieChart,
    openPreviewDialog,
    selectChartType,
    togglePieChart,
} from './ResultsSlice'
import { BarChart as BarChartIcon, ExpandMore, PieChart as PieChartIcon, Timeline } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import Animation from 'react-smooth'
import { PieChartLabel } from '../common/pie-chart-label/PieChartLabel'
import { Loading } from '../common/loading/Loading'
import { NoSelect } from '../common/no-select/NoSelect'

type ResultsChartProps = {
    height: number
    data: any[]
    schema: {
        key: string
        dataSets: {
            color: string
            dataKey: string
            label: string | JSX.Element
        }[]
    }
}

const activePieOffset = 5
const activePieAnimationDurationMs = 200

export const ResultsChart: FC<ResultsChartProps> = memo(({ height, data, schema }) => {
    const dispatch = useDispatch()
    const theme = useTheme()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [activePieIndex, setActivePieIndex] = useState<number | undefined>(undefined)
    const [pieOffset, setPieOffset] = useState<number>(0)

    const isLoadingResults = useSelector(getIsLoadingResults)
    const resultsGroupedBy = useSelector(getResultsGroupedBy)
    const selectedChartType = useSelector(getSelectedChartType)
    const showPieChart = useSelector(getShowPieChart)
    const pieChartData = useSelector(getResultsPieData)
    const { key, dataSets } = schema

    const Chart = selectedChartType === 'line' ? AreaChart : BarChart
    const ChartInner = selectedChartType === 'line' ? Area : (Bar as any)

    const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClick = (selectedItem: any) => {
        setAnchorEl(null)
        dispatch(changeGroupBy(selectedItem))
    }

    const handleMouseUp = () => {
        let areaLeft = refAreaLeft
        let areaRight = refAreaRight

        if (areaLeft === areaRight || areaRight === '') {
            setRefAreaLeft('')
            setRefAreaRight('')
            return
        }

        if (areaLeft > areaRight) {
            areaLeft = refAreaRight
            areaRight = refAreaLeft
        }

        dispatch(openPreviewDialog({ startDate: areaLeft, endDate: areaRight }))

        setRefAreaLeft('')
        setRefAreaRight('')
    }

    const handleGraphClick = (event: any) => {
        if (event) {
            dispatch(
                openPreviewDialog({
                    startDate: event.activeLabel,
                    endDate: event.activeLabel,
                })
            )
        }
    }

    const handlePieClick = (event: any) => {
        if (event) {
            switch (resultsGroupedBy) {
                case 'data_source_id':
                    dispatch(
                        openPreviewDialog({
                            selectedDatasourceId: event.real_value,
                        })
                    )
                    break
                case 'source_type':
                    dispatch(
                        openPreviewDialog({
                            selectedMediaType: event.real_value,
                        })
                    )
                    break
                default:
                    dispatch(openPreviewDialog({}))
            }
        }
    }

    const renderActiveShape = (props: any) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

        return (
            <g>
                <Animation from={{ t: 0 }} to={{ t: pieOffset }} duration={activePieAnimationDurationMs}>
                    {({ t }: { t: number }) => {
                        return (
                            <Sector
                                style={{ cursor: 'pointer' }}
                                cx={cx}
                                cy={cy}
                                innerRadius={innerRadius - t}
                                outerRadius={outerRadius + t}
                                startAngle={startAngle}
                                endAngle={endAngle}
                                fill={fill}
                            />
                        )
                    }}
                </Animation>
            </g>
        )
    }

    const onMouseEnter = (_: any, index: number) => {
        setPieOffset(activePieOffset)
        setActivePieIndex(index)
    }

    const onMouseLeave = () => {
        setPieOffset(0)
    }

    return (
        <NoSelect>
            <Paper sx={{ mb: 2 }}>
                <Box padding={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Box paddingLeft={1}>
                        <Typography>
                            <strong>
                                <FormattedMessage id="results.per-timeperiod" />
                            </strong>
                        </Typography>
                    </Box>

                    <ButtonGroup color="inherit" variant="text" size="small">
                        <Button
                            endIcon={<ExpandMore />}
                            style={{
                                color: theme.palette.text.secondary,
                                width: '170px',
                            }}
                            onClick={openMenu}
                        >
                            <FormattedMessage id={resultsGroupedBy || 'results.groupby.nothing'} />
                        </Button>
                        <Button
                            style={{
                                borderRight: 'none',
                                backgroundColor: selectedChartType === 'line' ? 'rgba(0, 0, 0, 0.12)' : 'inherit',
                            }}
                            onClick={() => dispatch(selectChartType('line'))}
                        >
                            <Timeline color="action" />
                        </Button>
                        <Button
                            style={{
                                backgroundColor: selectedChartType === 'bar' ? 'rgba(0, 0, 0, 0.12)' : 'inherit',
                            }}
                            onClick={() => dispatch(selectChartType('bar'))}
                        >
                            <BarChartIcon color="action" />
                        </Button>
                        <Button
                            style={{
                                backgroundColor: showPieChart ? 'rgba(0, 0, 0, 0.12)' : 'inherit',
                            }}
                            onClick={() => dispatch(togglePieChart())}
                        >
                            <PieChartIcon color="action" />
                        </Button>
                    </ButtonGroup>
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem dense onClick={() => handleMenuClick(undefined)}>
                        <FormattedMessage id="results.groupby.nothing" />
                    </MenuItem>
                    <MenuItem dense onClick={() => handleMenuClick('data_source_id')}>
                        <FormattedMessage id="results.groupby.datasource" />
                    </MenuItem>
                    <MenuItem dense onClick={() => handleMenuClick('source_type')}>
                        <FormattedMessage id="results.groupby.mediatype" />
                    </MenuItem>
                </Menu>

                <Grid container spacing={1}>
                    <Grid item sm={12} md={showPieChart && !isLoadingResults ? 9 : 12}>
                        <GraphBox height={height + 'px'}>
                            {isLoadingResults ? (
                                <Loading />
                            ) : (
                                <ResponsiveContainer width="100%" height="100%">
                                    <Chart
                                        data={data}
                                        onMouseDown={(event: any) => event && setRefAreaLeft(event.activeLabel)}
                                        onMouseMove={(event: any) => refAreaLeft && event && setRefAreaRight(event.activeLabel)}
                                        onMouseUp={handleMouseUp}
                                        onClick={handleGraphClick}
                                    >
                                        <defs>
                                            {dataSets.map((ds) => (
                                                <linearGradient key={ds.dataKey} id={ds.dataKey} x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="50%" stopColor={ds.color} stopOpacity={1} />
                                                    <stop offset="100%" stopColor={ds.color} stopOpacity={0} />
                                                </linearGradient>
                                            ))}
                                        </defs>
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <XAxis
                                            tickFormatter={(value) => moment(value).format('D MMM')}
                                            dataKey={key}
                                            tickMargin={8}
                                            tickLine={false}
                                            minTickGap={50}
                                        />
                                        <YAxis tickLine={false} tickMargin={8} />
                                        <Tooltip content={ChartTooltip} />
                                        {dataSets.map((ds) => (
                                            <ChartInner
                                                onClick={handleGraphClick}
                                                key={ds.dataKey}
                                                type="monotone"
                                                name={ds.label as string}
                                                dataKey={ds.dataKey}
                                                stroke={ds.color}
                                                fill={selectedChartType === 'line' ? `url(#${ds.dataKey})` : ds.color}
                                                strokeWidth={selectedChartType === 'line' ? 2 : 0}
                                                stackId={selectedChartType === 'line' ? undefined : key}
                                            />
                                        ))}
                                        {refAreaLeft && refAreaRight ? <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} /> : null}
                                        <Legend
                                            formatter={(value, entry, index) => <FormattedMessage id={dataSets[index as any].label as string} />}
                                            height={40}
                                        />
                                    </Chart>
                                </ResponsiveContainer>
                            )}
                        </GraphBox>
                    </Grid>
                    {showPieChart && !isLoadingResults && (
                        <Grid item md={3} sm={12}>
                            <GraphBox height={height + 'px'}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            activeShape={renderActiveShape}
                                            activeIndex={activePieIndex}
                                            data={pieChartData}
                                            dataKey="value"
                                            stroke="0"
                                            innerRadius={60}
                                            outerRadius={100}
                                            paddingAngle={1}
                                            onClick={handlePieClick}
                                            onMouseEnter={onMouseEnter}
                                            onMouseLeave={onMouseLeave}
                                            labelLine={false}
                                            label={PieChartLabel}
                                        >
                                            {pieChartData.map((entry, index) => (
                                                <Cell
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    key={`cell-${index}`}
                                                    fill={entry.color}
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip content={ChartTooltip} />
                                        <Legend formatter={(() => {}) as any} height={40} iconSize={0} />
                                    </PieChart>
                                </ResponsiveContainer>
                            </GraphBox>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </NoSelect>
    )
})
