import { Grid, styled } from '@mui/material'

export const TagsRow = styled(Grid)(({ theme }) => ({
    borderBottom: `solid 1px ${theme.palette.divider}`,
    height: 66,
    padding: '12px 0px 4px',

    '& .MuiAvatar-root': {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    '& > div': {
        textAlign: 'right',
        padding: theme.spacing(0.5),
    },

    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
}))
