import * as _ from 'lodash'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../core/Store'
import { getCommonPostDataAsIdMap, getSimpleTickets, IdMap } from '../core/slices/DataSlice'
import { CommonPostData } from '../content-analytics/posts/CommonPostData'
import { DetailedTicket } from './DetailedTicket'
import {
    addTicketNote,
    createTicketNode,
    fetchDetailedTicket,
    fetchDetailedTicketById,
    fetchOpenTicketCountForUser,
    fetchOpenTicketCountForUserByCategory,
    fetchSimpleTicketsForProject,
    updateComment,
    updateTicket,
} from './EngagementActions'
import { SimpleTicket } from './SimpleTicket'
import { getCurrentUser, getSelectedProject, getSelectedProjectId } from '../core/slices/CoreSlice'
import { fetchCommonPostData, fetchCommonPostDataByPostIds } from '../content-analytics/posts/PostsActions'

export interface EngagmentState {
    selectedDatasourceIds: string[]
    detailedTicket?: DetailedTicket
    loadingTicketDetails: boolean
    loadingSimpleTickets: boolean
    loadingPosts: boolean
    selectedTicketId?: string
    onlyShowUnreadComments: boolean
    showCloseTicketDialog: boolean
    openTicketCountForProject: {
        [project_id: string]: number
    }
    openTicketCountForProjectByCategory: {
        new: number
        mine: number
        others: number
        archived: number
    }
    answerDialogForStoryReplyAuthorId?: string
    highlightedNode?: string
    nodeToMention?: string
    currentInboxPage: number
    entriesPerPage: number
}

const showUnreadCommentsLocalStorageKey = 'hmstr.showUnreadComments'
const showUnreadCommentsString = localStorage.getItem(showUnreadCommentsLocalStorageKey)
const onlyShowUnreadCommentsDefault = showUnreadCommentsString === null ? true : showUnreadCommentsString === 'true'

const initialState: EngagmentState = {
    selectedDatasourceIds: [],
    loadingTicketDetails: true,
    loadingSimpleTickets: true,
    loadingPosts: true,
    onlyShowUnreadComments: onlyShowUnreadCommentsDefault,
    showCloseTicketDialog: false,
    openTicketCountForProject: {},
    openTicketCountForProjectByCategory: {
        new: 0,
        mine: 0,
        others: 0,
        archived: 0,
    },
    currentInboxPage: 0,
    entriesPerPage: 10,
}

export const EngagementSlice = createSlice({
    name: 'engagement',
    initialState,
    reducers: {
        selectDatasourceIds: (state, action: PayloadAction<string[]>) => {
            state.selectedDatasourceIds = action.payload
        },
        selectTicketId: (state, action: PayloadAction<string | undefined>) => {
            state.selectedTicketId = action.payload
        },
        selectInboxPage: (state, action: PayloadAction<number>) => {
            state.currentInboxPage = action.payload
        },
        setEntriesPerPage: (state, action: PayloadAction<number>) => {
            state.entriesPerPage = action.payload
        },
        setNodeToMention: (state, action: PayloadAction<string | undefined>) => {
            state.nodeToMention = action.payload
        },
        setOnlyShowUnreadComments: (state, action: PayloadAction<boolean>) => {
            localStorage.setItem(showUnreadCommentsLocalStorageKey, action.payload.toString())
            state.onlyShowUnreadComments = action.payload
        },
        openShowCloseTicketDialog: (state) => {
            state.showCloseTicketDialog = true
        },
        closeShowCloseTicketDialog: (state) => {
            state.showCloseTicketDialog = false
        },
        decrementOpenTicketCountForProject: (
            state,
            action: PayloadAction<{
                project_id: string
                value: number
            }>
        ) => {
            state.openTicketCountForProject = {
                ...state.openTicketCountForProject,
                [action.payload.project_id]: state.openTicketCountForProject[action.payload.project_id] - action.payload.value,
            }
        },
        incrementTicketCountForProject: (
            state,
            action: PayloadAction<{
                project_id: string
                value: number
            }>
        ) => {
            state.openTicketCountForProject = {
                ...state.openTicketCountForProject,
                [action.payload.project_id]: state.openTicketCountForProject[action.payload.project_id] + action.payload.value,
            }
        },
        openAnswerDialogForStoryReply: (state, action: PayloadAction<string>) => {
            state.answerDialogForStoryReplyAuthorId = action.payload
        },
        closeAnswerDialogForStoryReply: (state) => {
            state.answerDialogForStoryReplyAuthorId = undefined
        },
        highlightNode: (state, action: PayloadAction<string | undefined>) => {
            state.highlightedNode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailedTicket.fulfilled, (state, action) => {
                state.detailedTicket = action.payload
                state.loadingTicketDetails = false
            })
            .addCase(fetchDetailedTicket.pending, (state) => {
                state.loadingTicketDetails = true
            })
            .addCase(fetchDetailedTicketById.fulfilled, (state, action) => {
                state.detailedTicket = action.payload
                state.loadingTicketDetails = false
            })
            .addCase(fetchDetailedTicketById.pending, (state) => {
                state.loadingTicketDetails = true
            })
            .addCase(fetchSimpleTicketsForProject.pending, (state) => {
                state.loadingSimpleTickets = true
            })
            .addCase(fetchSimpleTicketsForProject.fulfilled, (state) => {
                state.loadingSimpleTickets = false
            })
            .addCase(updateTicket.fulfilled, (state, action) => {
                state.detailedTicket = action.payload
            })
            .addCase(updateComment.fulfilled, (state, action) => {
                state.detailedTicket = action.payload
            })
            .addCase(fetchCommonPostData.pending, (state) => {
                state.loadingPosts = true
            })
            .addCase(fetchCommonPostData.fulfilled, (state) => {
                state.loadingPosts = false
            })
            .addCase(fetchCommonPostData.rejected, (state) => {
                state.loadingPosts = false
            })
            .addCase(fetchCommonPostDataByPostIds.pending, (state) => {
                state.loadingPosts = true
            })
            .addCase(fetchCommonPostDataByPostIds.fulfilled, (state) => {
                state.loadingPosts = false
            })
            .addCase(fetchCommonPostDataByPostIds.rejected, (state) => {
                state.loadingPosts = false
            })
            .addCase(createTicketNode.fulfilled, (state, action) => {
                state.detailedTicket = action.payload
            })
            .addCase(addTicketNote.fulfilled, (state, action) => {
                state.detailedTicket = action.payload
            })
            .addCase('core/selectProject', (state) => {
                state.selectedDatasourceIds = []
                state.selectedTicketId = undefined
                state.loadingSimpleTickets = true
            })
            .addCase(fetchOpenTicketCountForUser.fulfilled, (state, action) => {
                state.openTicketCountForProject = action.payload
            })
            .addCase(fetchOpenTicketCountForUserByCategory.fulfilled, (state, action) => {
                state.openTicketCountForProjectByCategory = action.payload
            })
    },
})

export const getSelectedDatasourceIds: Selector<HmstrState, string[]> = (state) => state.engagement.selectedDatasourceIds
export const getCurrentInboxPage: Selector<HmstrState, number> = (state) => state.engagement.currentInboxPage
export const getEntriesPerPage: Selector<HmstrState, number> = (state) => state.engagement.entriesPerPage

export const getNodeToMention: Selector<HmstrState, string | undefined> = (state) => state.engagement.nodeToMention
export const getHighlightedNodeIndex: Selector<HmstrState, string | undefined> = (state) => state.engagement.highlightedNode
export const getSelectedTicketId: Selector<HmstrState, string | undefined> = (state) => state.engagement.selectedTicketId
export const getDetailedTicket: Selector<HmstrState, DetailedTicket | undefined> = (state) => state.engagement.detailedTicket
export const getShowCloseTicketDialog: Selector<HmstrState, boolean> = (state) => state.engagement.showCloseTicketDialog
export const getOnlyShowUnreadComments: Selector<HmstrState, boolean> = (state) => state.engagement.onlyShowUnreadComments
export const getLoadingPosts: Selector<HmstrState, boolean> = (state) => state.engagement.loadingPosts
export const getLoadingSimpleTickets: Selector<HmstrState, boolean> = (state) => state.engagement.loadingSimpleTickets
export const getOpenTicketCountByProject: Selector<HmstrState, EngagmentState['openTicketCountForProject']> = (state) =>
    state.engagement.openTicketCountForProject
export const getOpenTicketCountByProjectByCategory: Selector<HmstrState, EngagmentState['openTicketCountForProjectByCategory']> = (state) =>
    state.engagement.openTicketCountForProjectByCategory
export const getAnswerDialogForStoryReplyAuthorId: Selector<HmstrState, string | undefined> = (state) => state.engagement.answerDialogForStoryReplyAuthorId

export const getSelectedDetailedTicket: Selector<HmstrState, DetailedTicket | undefined> = createSelector(
    [getSelectedTicketId, getDetailedTicket],
    (ticketId, detailedTicket) => {
        if (ticketId && detailedTicket && detailedTicket.id === ticketId) {
            return detailedTicket
        }

        return undefined
    }
)

export const getOpenTicketCountForSelectedProject: Selector<HmstrState, number> = createSelector(
    [getOpenTicketCountByProject, getSelectedProjectId],
    (openTicketsByProjectByCategory, projectId) => {
        const openTicketCount = _.chain(openTicketsByProjectByCategory)
            .find((value, key) => {
                return key === projectId
            })
            .value()

        if (openTicketCount) {
            return openTicketCount
        }
        return 0
    }
)

export const getSelectedSimpleTicket: Selector<HmstrState, SimpleTicket | undefined> = createSelector(
    [getSimpleTickets, getSelectedTicketId],
    (tickets, ticketId) => {
        return ticketId ? tickets[ticketId] : undefined
    }
)

export const getCommonPostsByPostId = createSelector([getCommonPostDataAsIdMap], (posts) => {
    return _.chain(posts)
        .values()
        .flatten()
        .mapKeys((p) => p.post_id)
        .value() as IdMap<CommonPostData>
})

export const getFilteredTickets = createSelector(
    [getSimpleTickets, getSelectedDatasourceIds, getCommonPostsByPostId, getSelectedProject],
    (tickets, datasourceIds, postsById, selectedProject) => {
        const datasourcesById = _.mapKeys(selectedProject?.data_sources || [], 'id')

        return _.chain(tickets)
            .values()
            .filter((t) => {
                const dsExists = !!datasourcesById[t.data_source_id]
                return (datasourceIds.length === 0 || datasourceIds.includes(t.data_source_id)) && dsExists
            })
            .sortBy((t) => t.last_node_recieved_at)
            .reverse()
            .value()
    }
)

export const getOpenUnassignedTickets = createSelector([getFilteredTickets], (tickets) => {
    return _.filter(tickets, (t) => t.state === 'OPEN' && !t.assignee_id)
})

export const getTicketsAssignedToMe = createSelector([getFilteredTickets, getCurrentUser], (tickets, currentUser) => {
    return _.filter(tickets, (t) => t.state === 'OPEN' && t.assignee_id !== undefined && t.assignee_id === currentUser.id)
})

export const getOpenTicketsAssignedToOthers = createSelector([getFilteredTickets, getCurrentUser], (tickets, currentUser) => {
    return _.filter(tickets, (t) => t.state === 'OPEN' && !!t.assignee_id && t.assignee_id !== currentUser.id)
})

export const getClosedTickets = createSelector([getFilteredTickets], (tickets) => {
    return _.filter(tickets, (t) => t.state === 'CLOSED')
})

export const getPostForSelectedTicket: Selector<HmstrState, CommonPostData | undefined> = createSelector(
    [getSelectedSimpleTicket, getSelectedDetailedTicket, getCommonPostsByPostId],
    (simpleTicket, detailedTicket, posts) => {
        if (simpleTicket) return posts[simpleTicket.target_id]
        else if (detailedTicket) return posts[detailedTicket.target_id]
        return undefined
    }
)

export const getPrivateConversationForAuthor = createSelector(
    [getSimpleTickets, getAnswerDialogForStoryReplyAuthorId, getSelectedSimpleTicket],
    (tickets, authorId, selectedSimpleTicket) => {
        return _.values(tickets).find((t) => t.target_id === authorId && t.data_source_id === selectedSimpleTicket?.data_source_id)
    }
)

export const {
    selectDatasourceIds,
    selectTicketId,
    setOnlyShowUnreadComments,
    openShowCloseTicketDialog,
    closeShowCloseTicketDialog,
    decrementOpenTicketCountForProject,
    incrementTicketCountForProject,
    openAnswerDialogForStoryReply,
    closeAnswerDialogForStoryReply,
    highlightNode,
    setNodeToMention,
    selectInboxPage,
    setEntriesPerPage,
} = EngagementSlice.actions

export const EngagementReducer = EngagementSlice.reducer
