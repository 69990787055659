import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Button, IconButton } from '@mui/material'
import { MarkChatRead } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useHasDatasourcePermission } from '../core/hooks/useHasPermission'
import { useDispatch, useSelector } from 'react-redux'
import { getIsMobile, isDarkMode, showErrorSnackbar } from '../core/slices/CoreSlice'
import { getSelectedDetailedTicket, openShowCloseTicketDialog } from './EngagementSlice'
import { updateTicketNodes } from './EngagementActions'
import { HmstrDispatch } from '../core/Store'
import _ from 'lodash'

type MarkAllAsReadButtonProps = {}

export const MarkAllAsReadButton: FC<MarkAllAsReadButtonProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const isMobile = useSelector(getIsMobile)
    const detailedTicket = useSelector(getSelectedDetailedTicket)
    const canMarkAsRead = useHasDatasourcePermission('ticket.mark_as_read', detailedTicket?.data_source_id)
    const darkmode = useSelector(isDarkMode)
    const [scaleDownToolbar, setScaleDownToolbar] = useState(false)
    const allTicketsRead = detailedTicket ? detailedTicket.unread_node_count === 0 : true

    const handleResize = () => {
        if (window.innerWidth < 1200 && !isMobile) {
            setScaleDownToolbar(true)
        } else {
            setScaleDownToolbar(false)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleMarkAllAsRead = () => {
        if (detailedTicket) {
            dispatch(
                updateTicketNodes({
                    detailedTicket,
                    request: {
                        is_read: true,
                    },
                })
            ).then(({ payload }) => {
                if (_.get(payload as any, 'status') === 403) {
                    dispatch(showErrorSnackbar('general.no-permission'))
                } else {
                    if (detailedTicket.state === 'OPEN') {
                        dispatch(openShowCloseTicketDialog())
                    }
                }
            })
        }
    }
    return (
        <>
            {!scaleDownToolbar && (
                <Button
                    disabled={!canMarkAsRead || allTicketsRead}
                    sx={{
                        mb: 0.5,
                        textTransform: 'none',
                        whiteSpace: 'nowrap',
                        flexShrink: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    size="small"
                    color={darkmode ? 'secondary' : 'inherit'}
                    variant="contained"
                    startIcon={<MarkChatRead fontSize="small" />}
                    onClick={handleMarkAllAsRead}
                >
                    <FormattedMessage id="engagement.mark-all-as-read" />
                </Button>
            )}
            {scaleDownToolbar && (
                <IconButton
                    disabled={!canMarkAsRead || allTicketsRead}
                    sx={{
                        mb: 0.5,
                    }}
                    size="small"
                    color={darkmode ? 'secondary' : 'inherit'}
                    onClick={handleMarkAllAsRead}
                >
                    <MarkChatRead fontSize="small" />
                </IconButton>
            )}
        </>
    )
}
