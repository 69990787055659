import { FC } from 'react'
import { Badge, Box, FormLabel, Grid, Radio, Switch, Tooltip, Typography, useTheme } from '@mui/material'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { BarChart, CalendarMonth, Check, Mail, QuestionMark } from '@mui/icons-material'
import { features, HmstrFeatures, packages } from '../../../core/Packages'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../../core/slices/CoreSlice'

type AccountPackageColumnProps = {
    name: string
    packageId: string
    price?: number
    billingInterval: 'month' | 'year'
    users?: number
    datasources?: number
    tagLimit?: number
    postLimit?: number
    selected?: boolean
    disabled?: boolean
    onSelect?: () => any
    withoutPackage?: boolean
}

export const AccountPackageColumn: FC<AccountPackageColumnProps> = ({
    name,
    packageId,
    selected,
    price,
    billingInterval,
    users,
    datasources,
    disabled,
    onSelect,
    withoutPackage,
}) => {
    const intl = useIntl()
    const theme = useTheme()
    const form = useForm()
    const darkMode = useSelector(isDarkMode)

    const handleSwitchChange = () => {
        form.change('interval', billingInterval === 'month' ? 'year' : 'month')
    }

    const renderPrice = () => {
        let priceText
        let displayPrice = price

        if (price && billingInterval === 'year') {
            displayPrice = price / 12
        }

        if (displayPrice === undefined) {
            priceText = intl.formatMessage({ id: 'billing.on-request' })
        } else if (displayPrice === 0) {
            priceText = intl.formatMessage({ id: 'billing.free' })
        } else {
            priceText = intl.formatNumber(displayPrice, { minimumFractionDigits: 2 }) + ' €'
        }

        return (
            <>
                <Typography variant="h6" fontSize={24}>
                    {priceText}
                </Typography>
            </>
        )
    }

    const getMonthlyDifference = () => {
        if (!price) {
            return 0
        }

        const monthlyPriceForYear = price / 12
        const monthlyPriceForMonth = price / 11

        return (monthlyPriceForMonth - monthlyPriceForYear) * -1
    }

    function renderModuleIcon(module: keyof HmstrFeatures) {
        switch (module) {
            case 'publishing':
                return <CalendarMonth sx={{ color: 'white' }} />
            case 'analytics':
                return <BarChart sx={{ color: 'white' }} />
            case 'engagement':
                return <Mail sx={{ color: 'white' }} />
            default:
                return <QuestionMark sx={{ color: 'white' }} />
        }
    }

    const tableHeaderStyles = {
        backgroundColor: darkMode ? 'primary.main' : 'primary.light',
        p: 1,
        mt: 1,
        mb: 1,
        textTransform: 'uppercase',
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const tableRowStyles = {
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    return (
        <Grid item flex={`1 1 ${withoutPackage ? 150 : 0}px`} sx={{ backgroundColor: selected ? theme.palette.action.selected : undefined }}>
            <Box mt={2} mb={2}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box pb={2} height={48}>
                            {price !== undefined ? (
                                <Box textAlign="center" pt={2}>
                                    <Tooltip title={disabled ? <FormattedMessage id="billing.no-free-package" /> : ''} placement="top">
                                        <span>
                                            <Radio disabled={disabled} color="secondary" checked={selected} onChange={() => (onSelect ? onSelect() : {})} />
                                        </span>
                                    </Tooltip>
                                </Box>
                            ) : (
                                <Box textAlign="left" pl={2}>
                                    {withoutPackage && (
                                        <>
                                            <Switch color="secondary" checked={billingInterval === 'year'} onChange={handleSwitchChange} />
                                            <FormLabel>
                                                <FormattedMessage id="billing.subscription.interval.year" />
                                            </FormLabel>
                                            <Typography variant="body2" color="text.secondary" sx={{ ml: 7 }}>
                                                <FormattedMessage id="billing.subscription.interval.year.saves" />
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Box pt={2} pb={2} height={32} />
                    </Grid>
                    <Grid item xs={12} textAlign="center" height={32}>
                        {!withoutPackage && (
                            <Typography sx={{ textTransform: 'uppercase' }} variant="subtitle2">
                                {name}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} textAlign="center" height={96}>
                        {!withoutPackage && renderPrice()}

                        <Box height={32} mb={2}>
                            {!withoutPackage && price !== undefined && price > 0 && (
                                <Typography fontSize={12}>
                                    *&nbsp;
                                    <FormattedMessage id="billing.per-month" />
                                </Typography>
                            )}
                            {
                                <Badge
                                    sx={{ mt: 1 }}
                                    color="success"
                                    badgeContent={
                                        !withoutPackage && price !== undefined && price > 0 && billingInterval === 'year' ? (
                                            <Typography sx={{ p: 0.5 }}>
                                                <FormattedNumber
                                                    value={getMonthlyDifference()}
                                                    // eslint-disable-next-line react/style-prop-object
                                                    style="currency"
                                                    currency="EUR"
                                                />
                                            </Typography>
                                        ) : undefined
                                    }
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                />
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Box pt={2} height={48}>
                            {!withoutPackage && (
                                <Typography>
                                    {users ? (
                                        <FormattedMessage id="billing.users" values={{ value: users }} />
                                    ) : (
                                        <FormattedMessage id="billing.users-unlimited" />
                                    )}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Box pb={2} height={64}>
                            {!withoutPackage && (
                                <Typography>
                                    {datasources ? (
                                        <FormattedMessage id="billing.datasources" values={{ value: datasources }} />
                                    ) : (
                                        <FormattedMessage id="billing.datasources-unlimited" />
                                    )}
                                </Typography>
                            )}
                        </Box>
                    </Grid>

                    {withoutPackage &&
                        Object.keys(features).map((module: string) => {
                            const subFeatures = features[module as keyof HmstrFeatures]

                            return (
                                <Grid key={module} item xs={12} sx={{ position: 'relative' }}>
                                    <Box sx={tableHeaderStyles}>
                                        {renderModuleIcon(module as keyof HmstrFeatures)}
                                        <Typography variant="subtitle2" color="white" sx={{ pl: 1 }}>
                                            <FormattedMessage id={`features.${module}`} />
                                        </Typography>
                                    </Box>
                                    {(subFeatures || []).map((f) => (
                                        <Box key={f} sx={tableRowStyles}>
                                            <Typography>
                                                <FormattedMessage id={`features.${module}.${f}`} />
                                            </Typography>
                                        </Box>
                                    ))}
                                </Grid>
                            )
                        })}

                    {!withoutPackage &&
                        Object.keys(features).map((module) => {
                            const subFeatures = features[module as keyof HmstrFeatures]
                            const currentPackageFeatures = (packages[packageId] as any)[module]

                            const renderContent = (value: any) => {
                                if (value === true) {
                                    return <Check color="secondary" />
                                } else if (value === 'unlimited') {
                                    return <FormattedMessage id="billing.unlimited" />
                                } else if (typeof value === 'number') {
                                    return value
                                } else {
                                    return <div />
                                }
                            }

                            return (
                                <Grid key={module} item xs={12}>
                                    <Box sx={tableHeaderStyles} />
                                    {(subFeatures || []).map((f) => {
                                        return (
                                            <Box key={f} sx={tableRowStyles}>
                                                {renderContent(currentPackageFeatures[f])}
                                            </Box>
                                        )
                                    })}
                                </Grid>
                            )
                        })}
                </Grid>
            </Box>
        </Grid>
    )
}
