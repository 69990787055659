import { FC } from 'react'
import { PostGroup } from '../post-groups/PostGroup'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { Form } from 'react-final-form'
import { fetchPostGroupActivities, updatePostGroupApproval } from '../post-groups/PostGroupActions'
import { getIsMobile, showErrorSnackbar, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { validationRequired } from '../../form/validate'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'

type RejectApprovalDialogProps = {
    postGroup: PostGroup
    external?: boolean
    open: boolean
    onClose: () => any
}

export const RejectApprovalDialog: FC<RejectApprovalDialogProps> = ({ onClose, open, external, postGroup }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const isMobile = useSelector(getIsMobile)

    const handleRejectApproval = (values: any) => {
        dispatch(
            updatePostGroupApproval({
                postGroup,
                request: {
                    external: Boolean(external),
                    message: values.message,
                    state: 'REWORK',
                },
            })
        ).then((action) => {
            if (_.get(action, 'payload.status') === 403) {
                dispatch(showErrorSnackbar('publishing.disabled-tooltip-no-permission'))
            } else {
                dispatch(showSuccessSnackbar('publishing.approval.rework-requested'))
            }
            dispatch(fetchPostGroupActivities(postGroup))
            onClose()
        })
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'sm'}>
            <DialogTitle sx={{ pb: 0 }}>Änderungen anfordern</DialogTitle>
            <Form
                onSubmit={handleRejectApproval}
                render={({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <Grid container spacing={1} minWidth={isMobile ? '70vw' : 600 - 48}>
                                    <Grid item xs={12}>
                                        <Alert severity="info">
                                            <FormattedMessage id="publishing.approval.rework-hint" />
                                        </Alert>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ConnectedTextField
                                            name="message"
                                            label="general.message"
                                            disableMaxLength={true}
                                            required
                                            validate={validationRequired}
                                            multiline={true}
                                            minRows={5}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={onClose}>
                                    <FormattedMessage id="general.cancel" />
                                </Button>
                                <Button variant="contained" color="secondary" type="submit">
                                    <FormattedMessage id="publishing.request-rework" />
                                </Button>
                            </DialogActions>
                        </form>
                    )
                }}
            />
        </Dialog>
    )
}
