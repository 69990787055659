import * as React from 'react'
import { FC } from 'react'
import * as _ from 'lodash'
import { TicketNode } from './TicketNode'
import { DetailedTicket } from './DetailedTicket'
import { useSelector } from 'react-redux'
import { getOnlyShowUnreadComments } from './EngagementSlice'
import { TicketNodePaper } from './TicketNodePaper'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { TicketNodePaperMobile } from './TicketNodePaperMobile'
import { getIsMobile } from '../core/slices/CoreSlice'

type TicketViewNodeProps = {
    ticket: DetailedTicket
    node: TicketNode
    level: number
    parent?: TicketNode
    withoutChildren?: boolean
    datasource: ProjectDatasource
    index: number
    parentIndex?: number
    first?: boolean
    last?: boolean
}

export const TicketViewNode: FC<TicketViewNodeProps> = ({ datasource, node, level, ticket, withoutChildren, index, parentIndex, first, last }) => {
    const onlyShowUnreadComments = useSelector(getOnlyShowUnreadComments)
    const isMobile = useSelector(getIsMobile)

    return (
        <>
            {/*{displayParent && <TicketNodePaper ticket={ticket} node={parent} level={level - 1} />}*/}

            {(!onlyShowUnreadComments || !node.is_read) &&
                (isMobile ? (
                    <TicketNodePaperMobile
                        datasource={datasource}
                        ticket={ticket}
                        node={node}
                        level={level}
                        index={parentIndex !== undefined ? `${parentIndex}.${index}` : index.toString()}
                        lastElement={last}
                        firstElement={first}
                        hasChildren={node.children.length > 0}
                    />
                ) : (
                    <TicketNodePaper
                        datasource={datasource}
                        ticket={ticket}
                        node={node}
                        level={level}
                        index={parentIndex !== undefined ? `${parentIndex}.${index}` : index.toString()}
                    />
                ))}

            {!withoutChildren &&
                _.uniqBy(node.children, 'node_id').map((child_node, idx) => {
                    const isLastElement = idx === node.children.length - 1
                    return (
                        <TicketViewNode
                            datasource={datasource}
                            key={child_node.node_id}
                            node={child_node}
                            level={level + 1}
                            ticket={ticket}
                            parent={node}
                            index={idx + 1}
                            parentIndex={index}
                            last={isLastElement}
                            first={idx === 0}
                        />
                    )
                })}
        </>
    )
}
