import { Box, Button, Card, CardActions, CardContent, CardHeader, DialogContentText, Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { CheckCircleRounded, Delete, Edit, Language } from '@mui/icons-material'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { UserProjectAccess } from '../user-management/User'
import { getProjectsForSelectedAccount, getSelectedProject, isDarkMode, selectProject, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { deleteProject, fetchProject } from './ProjectActions'
import { getProjects } from '../../core/slices/DataSlice'
import { TiktokIcon } from '../../common/icons/TiktokIcon'
import { InstagramIcon } from '../../common/icons/InstagramIcon'
import { FacebookIcon } from '../../common/icons/FacebookIcon'
import { LinkedInIcon } from '../../common/icons/LinkedInIcon'
import { fetchCurrentUser } from '../user-management/UserActions'
import { ConfirmDialog } from '../../common/confirm-dialog/ConfirmDialog'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { useHasProjectPermission } from '../../core/hooks/useHasPermission'

type ProjectCardProps = {
    projectAccess: UserProjectAccess
}

export const ProjectCard: FC<ProjectCardProps> = ({ projectAccess }) => {
    const theme = useTheme()
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const selectedProject = useSelector(getSelectedProject)
    const projects = useSelector(getProjects)
    const project = projects[projectAccess.project_id]
    const projectsForAccount = useSelector(getProjectsForSelectedAccount)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const selected = projectAccess.project_id === selectedProject?.id
    const canDeleteProject = useHasProjectPermission('project.delete', projectAccess.project_id)
    const canUpdateProject = useHasProjectPermission('project.update', projectAccess.project_id)
    const darkMode = useSelector(isDarkMode)
    const shadowColor = darkMode ? theme.palette.secondary.main + '80' : theme.palette.secondary.dark + '80'

    const onSelectProject = () => {
        dispatch(fetchProject(projectAccess)).then(() => {
            dispatch(selectProject(projectAccess.project_id))
        })
    }

    const handleDeleteProjectClick = () => {
        if (showConfirmDialog) {
            if (selected) {
                const nextProjectinAccount = projectsForAccount.find((p) => p.id !== selectedProject.id)
                if (nextProjectinAccount) {
                    dispatch(selectProject(nextProjectinAccount.id))
                }
            }
            dispatch(deleteProject(project)).then(() => {
                dispatch(fetchCurrentUser())
                dispatch(showSuccessSnackbar('projects.deleted'))
            })

            setShowConfirmDialog(false)
            return
        }

        setShowConfirmDialog(true)
    }

    const handleEditProjectClick = () => {
        dispatch(selectProject(projectAccess.project_id))
        navigate('/settings/project-settings')
    }

    return (
        <Grid item xs={12} md={6}>
            <Card
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: selected ? `0px 2px 1px -1px ${shadowColor}, 0px 1px 1px 0px ${shadowColor}, 0px 1px 3px 0px ${shadowColor};` : undefined,
                }}
            >
                <CardHeader
                    title={
                        <Box display="flex" alignItems="center" gap={1}>
                            {projectAccess.name}
                            {selected && <CheckCircleRounded fontSize="small" color="success" />}
                        </Box>
                    }
                    sx={{ wordBreak: 'break-all' }}
                    action={
                        <Box>
                            {canUpdateProject && (
                                <IconButton size="small" sx={{ mr: 1 }} onClick={handleEditProjectClick}>
                                    <Edit />
                                </IconButton>
                            )}

                            {canDeleteProject && (
                                <Tooltip
                                    title={
                                        projectsForAccount.length <= 1 ? (
                                            <FormattedMessage id="projects.cant-delete-last-project" />
                                        ) : (
                                            <FormattedMessage id="projects.settings" />
                                        )
                                    }
                                    placement="top"
                                >
                                    <span>
                                        <IconButton disabled={projectsForAccount.length <= 1} size="small" onClick={() => handleDeleteProjectClick()}>
                                            <Delete />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    }
                />
                <CardContent sx={{ flexGrow: 1, pt: 0 }}>
                    {projectAccess.description && (
                        <Typography color="textSecondary">
                            <FormattedMessage id="general.description" />
                        </Typography>
                    )}
                    <Typography variant="body2">{projectAccess.description}</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    {project ? (
                        <Box>
                            {project.data_sources.find((ds) => ds.category === 'DATA_STREAMS') && <Language color="secondary" />}
                            {project.data_sources.find((ds) => ds.category === 'FACEBOOK') && <FacebookIcon />}
                            {project.data_sources.find((ds) => ds.category === 'INSTAGRAM') && <InstagramIcon />}
                            {project.data_sources.find((ds) => ds.category === 'LINKED_IN') && <LinkedInIcon />}
                            {project.data_sources.find((ds) => ds.category === 'TIKTOK') && (
                                <TiktokIcon sx={{ height: 18, mb: '3px', width: 21 }} htmlColor="text.primary" />
                            )}
                        </Box>
                    ) : (
                        <Box />
                    )}
                    <Button disabled={selected} variant="text" size="small" color="inherit" onClick={onSelectProject}>
                        <FormattedMessage id="projects.select" />
                    </Button>
                </CardActions>
            </Card>

            <ConfirmDialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={handleDeleteProjectClick}
                confirmText="general.delete"
                confirmIcon={<Delete />}
                title="projects.delete"
            >
                <DialogContentText>
                    <FormattedMessage id="projects.delete-confirm" />
                </DialogContentText>
            </ConfirmDialog>
        </Grid>
    )
}
