import { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

type InstagramMoreProps = SvgIconProps

export const InstagramMore: FC<InstagramMoreProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg
                aria-label="More options"
                fill={props.htmlColor || 'rgb(115,115,115)'}
                color={props.htmlColor || 'rgb(115,115,115)'}
                className="_ab6-"
                height="24"
                role="img"
                viewBox="0 0 24 24"
                width="24"
            >
                <circle cx="12" cy="12" r="1.5"></circle>
                <circle cx="6" cy="12" r="1.5"></circle>
                <circle cx="18" cy="12" r="1.5"></circle>
            </svg>
        </SvgIcon>
    )
}
