import * as React from 'react'
import { FC } from 'react'
import { AiSubmitButton } from '../../common/ai/AiSubmitButton'
import { AiGenerationFormValues, PostTextGenerationForm } from './PostTextGenerationForm'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPostTextSuggestions } from './PostTextGenerationActions'
import { HmstrDispatch } from '../../core/Store'
import { getAiDialogOpen, getSelectedResultToRefine, setAiDialogOpen, setSelectedResultToRefine } from './PostTextGenerationSlice'
import { createPortal } from 'react-dom'
import { getSelectedProject } from '../../core/slices/CoreSlice'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'

type PostTextGenerationComponentProps = {
    handleSelect: (val: string) => void
    datasources: ProjectDatasource[]
}

export const PostTextGenerationComponent: FC<PostTextGenerationComponentProps> = ({ handleSelect, datasources }) => {
    const dispatch = useDispatch<HmstrDispatch>()

    const formOpen = useSelector(getAiDialogOpen)
    const project = useSelector(getSelectedProject)
    const resultToRefine = useSelector(getSelectedResultToRefine)

    const handleSubmit = (values: AiGenerationFormValues) => {
        if (project) {
            const payload = {
                ...values,
                channels: datasources,
            }

            if (values.refine_request && resultToRefine) {
                payload['refine_request'] = values.refine_request
                payload['result_to_refine'] = resultToRefine
            }

            dispatch(fetchPostTextSuggestions({ values: payload, project })).then(() => dispatch(setSelectedResultToRefine(undefined)))
        }
    }

    return (
        <>
            <AiSubmitButton onClick={() => dispatch(setAiDialogOpen(true))} color={'inherit'} />
            {createPortal(
                <PostTextGenerationForm open={formOpen} handleSubmit={handleSubmit} handleSelect={handleSelect} channels={datasources} />,
                document.body
            )}
        </>
    )
}
