import * as React from 'react'
import { FC } from 'react'
import { Box, useTheme } from '@mui/material'
import { ArrowLeft, ArrowRight, Forward } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'

type KeyDisplayProps = { keyboardKey: string; invert?: boolean; fontSize?: number }

export const KeyDisplay: FC<KeyDisplayProps> = ({ keyboardKey, invert, fontSize }) => {
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)
    const getKeyIcon = () => {
        if (keyboardKey === 'shift') {
            return (
                <Forward
                    fontSize={!fontSize ? 'small' : undefined}
                    sx={fontSize ? { fontSize: fontSize + 'px', transform: 'rotate(270deg)' } : { transform: 'rotate(270deg)' }}
                />
            )
        } else if (keyboardKey === 'arrowRight') {
            return <ArrowRight fontSize={!fontSize ? 'small' : undefined} sx={fontSize ? { fontSize: fontSize + 5 + 'px' } : {}} />
        } else if (keyboardKey === 'arrowLeft') {
            return <ArrowLeft fontSize={!fontSize ? 'small' : undefined} sx={fontSize ? { fontSize: fontSize + 5 + 'px' } : {}} />
        } else {
            return keyboardKey.toUpperCase()
        }
    }

    const color = invert ? (darkmode ? '#fff' : theme.palette.getContrastText(theme.palette.text.primary)) : theme.palette.text.primary
    return (
        <Box
            border={`1px solid ${color}`}
            borderRadius={'4px'}
            sx={{
                display: 'flex',
                minWidth: `${(fontSize || 16) + 4}px`,
                height: `${(fontSize || 16) + 4}px`,
                aspectRatio: '1',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: `${fontSize || 16}px`,
            }}
        >
            {getKeyIcon()}
        </Box>
    )
}
