import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../core/Store'
import { doGet, doPost, doDelete, doPut } from '../core/api/ApiClient'
import { Tag } from './Tag'
import { Project } from '../settings/project-management/Project'

const TAGS_SLASH = (action: string) => `tags/${action}`

export const fetchTagsForProject = createAsyncThunk<Tag[], Project, { state: HmstrState }>(TAGS_SLASH('fetchTagsForProject'), async (project, thunkAPI) => {
    return await doGet(thunkAPI, project._links.tags)
})

export const createTag = createAsyncThunk<Tag, { project: Project; tag: Tag }, { state: HmstrState }>(TAGS_SLASH('createTag'), async (args, thunkAPI) => {
    return await doPost(thunkAPI, args.project._links.tags, args.tag)
})

export const editTag = createAsyncThunk<Tag, { tag: Tag }, { state: HmstrState }>(TAGS_SLASH('editTag'), async (args, thunkAPI) => {
    return await doPut(thunkAPI, args.tag._links.self, args.tag)
})

export const deleteTag = createAsyncThunk<Tag, Tag>(TAGS_SLASH('deleteTag'), async (tag, thunkAPI) => {
    await doDelete(thunkAPI, tag._links.self)
    return tag
})
