import { Tag } from '../../tags/Tag'
import { Autocomplete, Checkbox, MenuItem, Typography } from '@mui/material'
import { TagChip } from '../../content-analytics/tags/TagChip'
import * as React from 'react'
import { FC, useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { TagColorPicker } from '../../content-analytics/tags/components/TagColorPicker'
import { getUntaggedTag } from '../../tags/TagsSlice'
import { StyledTextField } from '../../form/ConnectedTextField'

type CustomTagFilterProps = {
    label: string
    selectedTagIds: string[]
    onChange: (newValue: (String | Tag | undefined)[]) => any
    tags: Tag[]
    showUntaggedOption?: boolean
    fullWidth?: boolean
    disabled?: boolean
    handleOpen?: () => void
    handleClose?: () => void
    isOpen?: boolean
}

export const CustomTagFilter: FC<CustomTagFilterProps> = ({
    label,
    selectedTagIds,
    onChange,
    tags,
    showUntaggedOption = false,
    fullWidth = false,
    disabled,
    handleOpen,
    handleClose,
    isOpen,
}) => {
    const intl = useIntl()

    if (showUntaggedOption) {
        tags = [getUntaggedTag(intl.formatMessage({ id: 'tags.untagged-posts' })), ...tags]
    }

    const autocompleteRef = useRef<any>(null)
    const selectedTags = selectedTagIds.map((id) => tags.find((tag) => tag.id === id)).filter((tag) => !!tag)
    const notSelectedTags = tags.filter((t) => !selectedTagIds.includes(t.id))
    const allActiveTagsSelected = tags.filter((t) => !selectedTagIds.includes(t.id) && t.active).length === 0
    const allInactiveTagsSelected = tags.filter((t) => !selectedTagIds.includes(t.id) && !t.active).length === 0

    useEffect(() => {
        if (isOpen) {
            setTimeout(handleFocusField, 100)
        }
    }, [isOpen])

    const handleFocusField = () => {
        if (autocompleteRef.current) {
            autocompleteRef.current.querySelector('input').focus()
        }
    }

    let lastTag: Tag | null
    return (
        <Autocomplete
            ref={autocompleteRef}
            sx={{ minWidth: 300 }}
            multiple
            disabled={disabled}
            options={tags}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            value={selectedTags}
            onChange={(event, newValue) => {
                onChange(newValue)
                handleFocusField()
            }}
            disableClearable
            disableCloseOnSelect
            disablePortal
            fullWidth={fullWidth}
            renderOption={(props, option, { selected }) => {
                if (!option) {
                    return undefined
                }

                let renderGroup = false
                // @ts-ignore
                if (props['data-option-index'] === 0) {
                    renderGroup = true
                    lastTag = option
                } else if (lastTag?.active !== option.active) {
                    renderGroup = true
                }

                lastTag = option

                return (
                    <div key={`menu-${option.id}`}>
                        {renderGroup && (
                            <MenuItem
                                key={`menu-${option.id}-${option.active}`}
                                onClick={() =>
                                    onChange(
                                        (option.active ? allActiveTagsSelected : allInactiveTagsSelected)
                                            ? selectedTags.filter((t) => t && (option.active ? !t.active : t.active))
                                            : option.active
                                            ? [...selectedTags, ...notSelectedTags.filter((t) => t.active)]
                                            : [...selectedTags, ...notSelectedTags.filter((t) => !t.active)]
                                    )
                                }
                                selected={option.active ? allActiveTagsSelected : allInactiveTagsSelected}
                            >
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={option.active ? allActiveTagsSelected : allInactiveTagsSelected}
                                />
                                <Typography variant={'body1'} sx={{ marginRight: '8px' }}>
                                    <FormattedMessage id={option.active ? 'tags.active' : 'tags.inactive'} />
                                </Typography>
                            </MenuItem>
                        )}

                        <MenuItem {...props} key={`menu-${option.id}`}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8, marginLeft: 32 }}
                                checked={selected}
                            />
                            <Typography variant={'body1'} sx={{ marginRight: '8px' }}>
                                {option.label}
                            </Typography>
                            <TagColorPicker color={option.color ?? '#EEE'} changeCallback={() => {}} disabled={true} />
                        </MenuItem>
                    </div>
                )
            }}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={{
                        variant: 'outlined',
                    }}
                    label={label}
                    placeholder={intl.formatMessage({ id: 'general.add-tags' })}
                />
            )}
            renderTags={(value, getTagProps) => {
                if (value.length > 2) {
                    return <FormattedMessage id="filters.xTagsSelected" values={{ x: value.length }} />
                }

                return value.map((option, index) => {
                    if (!!option) {
                        return <TagChip key={option.id} tag={option} additionalProps={getTagProps({ index })} />
                    }

                    return <></>
                })
            }}
        />
    )
}
