import React, { FC } from 'react'
import { ValidDatasourceTypeForPublishing } from './PublishingForm'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { TabPanel } from '../common/tab-panel/TabPanel'
import { PostFormPlatformSection } from './post-form-sections/PostFormPlatformSection'
import { ProjectDatasource, ProjectDatasourceType } from '../settings/datasources/ProjectDatasource'
import { TiktokCreatorInfo } from './TiktokCreatorInfo'
import { changePostDialogSelectedNetwork, getPostDialogSelectedTab } from './PublishingSlice'
import { HmstrDispatch } from '../core/Store'
import { useDispatch, useSelector } from 'react-redux'
import { IdMap } from '../core/slices/DataSlice'
import { Post } from './posts/Post'
import { getIconForDatasourceType } from '../settings/datasources/DatasourceTypeMappings'

type PostFormPlatformSpecificTabsProps = {
    postType?: Post['post_type']
    selectedNetwork?: ValidDatasourceTypeForPublishing
    datasourceKeys: ValidDatasourceTypeForPublishing[]
    disabled: boolean
    prefix: string
    tiktokCreatorInfo: TiktokCreatorInfo
    datasources: IdMap<ProjectDatasource[]>
    alreadyPlannedOnFacebook: boolean
}

export const PostFormPlatformSpecificTabs: FC<PostFormPlatformSpecificTabsProps> = ({
    datasourceKeys,
    tiktokCreatorInfo,
    postType,
    prefix,
    disabled,
    selectedNetwork,
    datasources,
    alreadyPlannedOnFacebook,
}) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedTab = useSelector(getPostDialogSelectedTab)

    const handleTabChange = (newType: ValidDatasourceTypeForPublishing) => {
        dispatch(changePostDialogSelectedNetwork(newType))
    }

    return (
        <Grid item xs={12}>
            {postType && selectedNetwork && (
                <>
                    <Box mb={2} display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Box>
                            <Typography variant="subtitle2">
                                <FormattedMessage id="publishing.platform-specific-setting" />
                            </Typography>
                        </Box>
                        {selectedTab === 'COMMON' && (
                            <Tabs value={selectedNetwork} onChange={(e, value) => handleTabChange(value)} indicatorColor={'secondary'}>
                                {(datasourceKeys || []).sort().map((type) => {
                                    return (
                                        <Tab
                                            key={type}
                                            sx={{
                                                p: 0,
                                            }}
                                            value={type}
                                            icon={getIconForDatasourceType(type)}
                                            iconPosition="start"
                                        />
                                    )
                                })}
                            </Tabs>
                        )}
                    </Box>

                    <Box>
                        {datasourceKeys.map((dsKey) => (
                            <TabPanel key={dsKey} value={selectedNetwork} index={dsKey} keepMounted={true}>
                                <Grid container spacing={1}>
                                    <PostFormPlatformSection
                                        noHeader={true}
                                        disabled={disabled || (alreadyPlannedOnFacebook && (dsKey as ProjectDatasourceType) === 'FACEBOOK_PAGE')}
                                        prefix={prefix}
                                        datasourceType={dsKey as ProjectDatasourceType}
                                        postType={postType}
                                        tiktokCreatorInfo={tiktokCreatorInfo}
                                        datasources={datasources}
                                    />
                                </Grid>
                            </TabPanel>
                        ))}
                    </Box>
                </>
            )}
        </Grid>
    )
}
