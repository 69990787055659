import { FC } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { startNewPost } from '../PublishingSlice'
import { Add } from '@mui/icons-material'
import moment, { Moment } from 'moment'
import { useDispatch } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { FormattedMessage } from 'react-intl'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'

type CalendarDayAddButtonProps = {
    day: Moment
}

export const CalendarDayAddButton: FC<CalendarDayAddButtonProps> = ({ day }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const navigate = useProjectNavigate()
    const dayInPast = day.isBefore(moment(), 'day')
    const getDayWithCurrentTime = () => {
        const currentTimestamp = moment()
        const currentDay = day.clone()

        currentDay.set({ hours: currentTimestamp.hours(), minutes: currentTimestamp.minutes() })

        if (currentDay.isSame(currentTimestamp, 'day')) {
            currentDay.add(3, 'hours')
        }

        return currentDay.format()
    }

    const handleClick = () => {
        dispatch(startNewPost(getDayWithCurrentTime()))
        navigate('/publishing/calendar/new')
    }

    return (
        <Box position="absolute" bottom={0} height="100%" width="100%" display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Tooltip title={<FormattedMessage id={dayInPast ? 'publishing.no-posts-in-past' : 'publishing.plan-new-post'} />}>
                <span>
                    <IconButton
                        disabled={dayInPast}
                        sx={{
                            m: 0.5,
                            backgroundColor: 'secondary.main',
                            color: 'primary.contrastText',

                            '&:hover': { backgroundColor: 'secondary.light' },
                        }}
                        size="small"
                        onClick={handleClick}
                    >
                        <Add />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    )
}
