import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'

export interface FilterState {
    filterDrawerOpen: boolean
    datasourceSelectionOpen: boolean
    tagSelectionOpen: boolean
    timePeriodSettingOpen: boolean
    tagStateSettingOpen: boolean
    templateTypeFocused: boolean
    templateTypeFocusedFromHighlight: boolean
    assigneeSelectionOpen: boolean
    compareTimePeriodSettingOpen: boolean
    attributionSettingsOpen: boolean
    conversionEventSelectOpen: boolean
    mediaTypeSelectOpen: boolean
    adCampaignSelectOpen: boolean
    adAdsetSelectOpen: boolean
}

export const initialFilterState: FilterState = {
    filterDrawerOpen: false,
    datasourceSelectionOpen: false,
    tagSelectionOpen: false,
    timePeriodSettingOpen: false,
    compareTimePeriodSettingOpen: false,
    tagStateSettingOpen: false,
    templateTypeFocused: false,
    templateTypeFocusedFromHighlight: false,
    assigneeSelectionOpen: false,
    attributionSettingsOpen: false,
    conversionEventSelectOpen: false,
    mediaTypeSelectOpen: false,
    adAdsetSelectOpen: false,
    adCampaignSelectOpen: false,
}

export const FilterSlice = createSlice({
    name: 'filters',
    initialState: initialFilterState as FilterState,
    reducers: {
        toggleFilterDrawerOpen: (state) => {
            if (state.filterDrawerOpen) {
                state.datasourceSelectionOpen = !state.filterDrawerOpen
                state.tagSelectionOpen = !state.filterDrawerOpen
                state.timePeriodSettingOpen = !state.filterDrawerOpen
                state.compareTimePeriodSettingOpen = !state.filterDrawerOpen
                state.tagStateSettingOpen = !state.filterDrawerOpen
                state.templateTypeFocused = !state.filterDrawerOpen
                state.templateTypeFocusedFromHighlight = !state.filterDrawerOpen
                state.assigneeSelectionOpen = !state.filterDrawerOpen
                state.attributionSettingsOpen = !state.filterDrawerOpen
                state.conversionEventSelectOpen = !state.filterDrawerOpen
                state.mediaTypeSelectOpen = !state.filterDrawerOpen
            }
            state.filterDrawerOpen = !state.filterDrawerOpen
        },
        setDatasourceSelectionOpen: (state, action: { payload: FilterState['datasourceSelectionOpen'] }) => {
            state.datasourceSelectionOpen = action.payload
        },
        setTagSelectionOpen: (state, action: { payload: FilterState['tagSelectionOpen'] }) => {
            state.tagSelectionOpen = action.payload
        },
        setTimePeriodSettingOpen: (state, action: { payload: FilterState['timePeriodSettingOpen'] }) => {
            state.timePeriodSettingOpen = action.payload
        },
        setCompareTimePeriodSettingOpen: (state, action: { payload: FilterState['compareTimePeriodSettingOpen'] }) => {
            state.compareTimePeriodSettingOpen = action.payload
        },
        setTagStateSettingOpen: (state, action: { payload: FilterState['tagStateSettingOpen'] }) => {
            state.tagStateSettingOpen = action.payload
        },
        setTemplateTypeFocused: (state, action: { payload: FilterState['templateTypeFocused'] }) => {
            state.templateTypeFocused = action.payload
        },
        setAssigneeSelectionOpen: (state, action: { payload: FilterState['assigneeSelectionOpen'] }) => {
            state.assigneeSelectionOpen = action.payload
        },
        setAttributionSettingsOpen: (state, action: { payload: FilterState['attributionSettingsOpen'] }) => {
            state.attributionSettingsOpen = action.payload
        },
        setConversionEventSelectOpen: (state, action: { payload: FilterState['conversionEventSelectOpen'] }) => {
            state.conversionEventSelectOpen = action.payload
        },
        setTemplateTypeFocusedFromHighlight: (state, action: { payload: FilterState['templateTypeFocusedFromHighlight'] }) => {
            state.templateTypeFocusedFromHighlight = action.payload
        },
        setMediaTypeSelectOpen: (state, action: { payload: FilterState['mediaTypeSelectOpen'] }) => {
            state.mediaTypeSelectOpen = action.payload
        },
        setCampaignSelectOpen: (state, action: PayloadAction<boolean>) => {
            state.adCampaignSelectOpen = action.payload
        },
        setAdsetSelectOpen: (state, action: PayloadAction<boolean>) => {
            state.adAdsetSelectOpen = action.payload
        },
    },
})

export const getFilterDrawerOpen: Selector<HmstrState, FilterState['filterDrawerOpen']> = (state) => state.filters.filterDrawerOpen
export const getDatasourceSelectionOpen: Selector<HmstrState, FilterState['datasourceSelectionOpen']> = (state) => state.filters.datasourceSelectionOpen
export const getTagSelectionOpen: Selector<HmstrState, FilterState['tagSelectionOpen']> = (state) => state.filters.tagSelectionOpen
export const getTimePeriodSettingOpen: Selector<HmstrState, FilterState['timePeriodSettingOpen']> = (state) => state.filters.timePeriodSettingOpen
export const getCompareTimePeriodSettingOpen: Selector<HmstrState, FilterState['compareTimePeriodSettingOpen']> = (state) =>
    state.filters.compareTimePeriodSettingOpen
export const getTagStateSettingOpen: Selector<HmstrState, FilterState['tagStateSettingOpen']> = (state) => state.filters.tagStateSettingOpen
export const getTemplateTypeFocused: Selector<HmstrState, FilterState['templateTypeFocused']> = (state) => state.filters.templateTypeFocused

export const getAssigneeSelectionOpen: Selector<HmstrState, FilterState['assigneeSelectionOpen']> = (state) => state.filters.assigneeSelectionOpen

export const getAttributionSettingsOpen: Selector<HmstrState, FilterState['attributionSettingsOpen']> = (state) => state.filters.attributionSettingsOpen

export const getConversionEventSelectOpen: Selector<HmstrState, FilterState['conversionEventSelectOpen']> = (state) => state.filters.conversionEventSelectOpen
export const getMediaTypeSelectOpen: Selector<HmstrState, FilterState['mediaTypeSelectOpen']> = (state) => state.filters.mediaTypeSelectOpen
export const getCampaignSelectOpen: Selector<HmstrState, FilterState['adCampaignSelectOpen']> = (state) => state.filters.adCampaignSelectOpen
export const getAdsetSelectOpen: Selector<HmstrState, FilterState['adAdsetSelectOpen']> = (state) => state.filters.adAdsetSelectOpen
export const getTemplateTypeFocusedFromHighlight: Selector<HmstrState, FilterState['templateTypeFocusedFromHighlight']> = (state) =>
    state.filters.templateTypeFocusedFromHighlight
export const {
    toggleFilterDrawerOpen,
    setDatasourceSelectionOpen,
    setTagSelectionOpen,
    setTimePeriodSettingOpen,
    setTagStateSettingOpen,
    setConversionEventSelectOpen,
    setTemplateTypeFocused,
    setTemplateTypeFocusedFromHighlight,
    setAssigneeSelectionOpen,
    setCompareTimePeriodSettingOpen,
    setAttributionSettingsOpen,
    setMediaTypeSelectOpen,
    setCampaignSelectOpen,
    setAdsetSelectOpen,
} = FilterSlice.actions

export const FilterReducer = FilterSlice.reducer
