import React, { FC, ReactNode, useEffect } from 'react'
import { Avatar, Box, ListItemButton, Typography } from '@mui/material'
import { SuggestionDataItem } from 'react-mentions'
import { MentionCandidate } from './MentionCandidate'
import { useKeycloak } from '../../core/hooks/useKeycloak'

type CustomSuggestionProps = {
    focused: boolean
    snap: boolean
    highlightedDisplay: ReactNode
    suggestion: SuggestionDataItem & MentionCandidate
    setExitMention: (exit: boolean) => any
}

export const CustomSuggestion: FC<CustomSuggestionProps> = ({ focused, snap, highlightedDisplay, suggestion, setExitMention }) => {
    const keycloak = useKeycloak()

    useEffect(() => {
        setExitMention(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getImageUrl = () => {
        if (suggestion.type === 'FACEBOOK_PAGE') {
            return suggestion.image_url + `&access_token=${keycloak.getAccessToken()}`
        }

        return suggestion.image_url
    }

    return (
        <ListItemButton
            id={focused ? 'focussedListItem' : undefined}
            selected={focused}
            style={{
                whiteSpace: 'normal',
                display: 'flex',
                gap: 10,
                scrollSnapAlign: focused && snap ? 'center' : undefined,
            }}
        >
            <Avatar src={getImageUrl()} style={{ width: '40px', height: '40px' }} />
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} maxWidth={'85%'}>
                <Typography color={'inherit'} sx={{ m: 0, p: 0 }}>
                    {highlightedDisplay}
                </Typography>
                {suggestion.description && (
                    <Typography
                        color={'inherit'}
                        sx={{
                            m: 0,
                            p: 0,
                            height: '1.5em',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            opacity: 0.5,
                        }}
                    >
                        {suggestion.description}
                    </Typography>
                )}
            </Box>
        </ListItemButton>
    )
}
