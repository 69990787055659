import _ from 'lodash'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { getCurrentUser, getDatasources, getSelectedAccount, getSelectedAccountAccess, getSelectedAccountId } from '../core/slices/CoreSlice'
import { getAccounts, getInvites, getInvoices, getPaymentMethods, getProducts, getProjects, getSubscriptions, getUsers, IdMap } from '../core/slices/DataSlice'
import { HmstrState } from '../core/Store'
import { UserTableEntry } from './user-management/table/UserTableEntry'
import { UserAccountAccess } from './user-management/User'
import { AccountWizardSteps } from './accounts/AccountCreateWizard'
import { BillingSubscription } from './billing/BillingSubscription'
import { createAccount } from './accounts/AccountActions'
import { fetchCurrentUser } from './user-management/UserActions'
import { Invite } from './user-management/Invite'
import { ProjectDatasource, ProjectDatasourceType } from './datasources/ProjectDatasource'
import { PaymentMethod } from '@stripe/stripe-js'
import { BillingInvoice } from './billing/BillingInvoice'
import { updateDatasourceInProject } from './datasources/ProjectDatasourceActions'
import { fetchNotificationSettingsForUser, updateNotificationSettingsForUser } from './SettingsActions'
import { updateProject } from './project-management/ProjectActions'
import { DatasourceNotificationSettings } from './notifications/DatasourceNotificationSettings'

export interface SettingsState {
    selectedUserId?: string
    selectedInviteId?: string
    datasourceTags: string[]
    userSearchTerm: string
    accountWizardState: number
    accountSettingsTab: number
    isCreatingAccount: boolean
    accountIdForProjectWizard?: string
    projectUpdating: boolean
    projectDatasourceFilter: {
        searchTerm: string
        datasourceType: ProjectDatasourceType | 'all'
    }
    isUpdatingDatasource: boolean
    loadingNotificationSettings: boolean
    notificationSettingsForUser: IdMap<DatasourceNotificationSettings>
}

const initialState: SettingsState = {
    datasourceTags: [],
    userSearchTerm: '',
    projectUpdating: false,
    accountWizardState: 0,
    accountSettingsTab: 0,
    isCreatingAccount: false,
    projectDatasourceFilter: {
        searchTerm: '',
        datasourceType: 'all',
    },
    isUpdatingDatasource: false,
    loadingNotificationSettings: false,
    notificationSettingsForUser: {},
}

export const SettingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        openUserSettings: (state, action: PayloadAction<string>) => {
            state.selectedUserId = action.payload
        },
        closeUserSettings: (state) => {
            state.selectedUserId = undefined
        },
        openEditInvite: (state, action: PayloadAction<string>) => {
            state.selectedInviteId = action.payload
        },
        closeEditInvite: (state) => {
            state.selectedInviteId = undefined
        },
        addDatasourceTag: (state, action: PayloadAction<string>) => {
            state.datasourceTags.push(action.payload)
        },
        changeUserSearchTerm: (state, action: PayloadAction<string>) => {
            state.userSearchTerm = action.payload
        },
        changeAccountWizardState: (state, action: PayloadAction<number>) => {
            if (0 <= action.payload && action.payload < AccountWizardSteps.length) {
                state.accountWizardState = action.payload
            }
        },
        changeAccountSettingsTab: (state, action: PayloadAction<number>) => {
            state.accountSettingsTab = action.payload
        },
        setAccountIdForProjectWizard: (state, action: PayloadAction<SettingsState['accountIdForProjectWizard']>) => {
            state.accountIdForProjectWizard = action.payload
        },
        changeProjectDatasourceFilterSearchTerm: (state, action: PayloadAction<SettingsState['projectDatasourceFilter']['searchTerm']>) => {
            state.projectDatasourceFilter.searchTerm = action.payload
        },
        changeProjectDatasourceFilterType: (state, action: PayloadAction<SettingsState['projectDatasourceFilter']['datasourceType']>) => {
            state.projectDatasourceFilter.datasourceType = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAccount.pending, (state) => {
                state.isCreatingAccount = true
            })
            .addCase(createAccount.rejected, (state) => {
                state.isCreatingAccount = false
            })
            .addCase(fetchCurrentUser.fulfilled, (state) => {
                state.isCreatingAccount = false
            })
            .addCase(updateProject.pending, (state) => {
                state.projectUpdating = true
            })
            .addCase(updateProject.fulfilled, (state) => {
                state.projectUpdating = false
            })
            .addCase('core/closeFullscreenDialog', (state) => {
                state.accountIdForProjectWizard = undefined
            })
            .addCase(updateDatasourceInProject.pending, (state) => {
                state.isUpdatingDatasource = true
            })
            .addCase(updateDatasourceInProject.fulfilled, (state) => {
                state.isUpdatingDatasource = false
            })
            .addCase(fetchNotificationSettingsForUser.pending, (state) => {
                state.loadingNotificationSettings = true
            })
            .addCase(fetchNotificationSettingsForUser.fulfilled, (state, action) => {
                state.notificationSettingsForUser = _.mapKeys(action.payload, 'data_source_id')
                state.loadingNotificationSettings = false
            })
            .addCase(updateNotificationSettingsForUser.fulfilled, (state, action) => {
                state.notificationSettingsForUser = _.mapKeys(action.payload, 'data_source_id')
            })
    },
})

export const getSettingsSelectedUserId: Selector<HmstrState, string | undefined> = (state) => state.settings.selectedUserId
export const getIsProjectUpdating: Selector<HmstrState, boolean> = (state) => state.settings.projectUpdating
export const getAreNotificationSettingsLoading: Selector<HmstrState, boolean> = (state) => state.settings.loadingNotificationSettings
export const getSettingsSelectedInviteId: Selector<HmstrState, string | undefined> = (state) => state.settings.selectedInviteId
export const getAddedDatasourceTags: Selector<HmstrState, string[]> = (state) => state.settings.datasourceTags
export const getUserSearchTerm: Selector<HmstrState, string> = (state) => state.settings.userSearchTerm
export const getAccountWizardState: Selector<HmstrState, number> = (state) => state.settings.accountWizardState
export const getAccountIdForProjectWizard: Selector<HmstrState, string | undefined> = (state) => state.settings.accountIdForProjectWizard
export const getProjectDatasourceFilters: Selector<HmstrState, SettingsState['projectDatasourceFilter']> = (state) => state.settings.projectDatasourceFilter
export const getIsUpdatingDatasource: Selector<HmstrState, SettingsState['isUpdatingDatasource']> = (state) => state.settings.isUpdatingDatasource
export const getNotificationSettingsForUser: Selector<HmstrState, SettingsState['notificationSettingsForUser']> = (state) =>
    state.settings.notificationSettingsForUser

export const getUsersForSelectedAccount = createSelector([getSelectedAccountId, getUsers], (selectedAccountId, users) => users[selectedAccountId || ''] || [])

export const getInvitesForSelectedAccount = createSelector(
    [getSelectedAccountId, getInvites],
    (selectedAccountId, invites) => invites[selectedAccountId || ''] || []
)

export const getPersistedDatasourceTags = createSelector([getProjects], (projects) => {
    return _.chain(projects).values().flatMap('data_sources').flatMap('tags').uniq().value()
})

export const getDatasourceTags = createSelector([getPersistedDatasourceTags, getAddedDatasourceTags], (persistedDatasourceTags, addedDatsourceTags) => {
    return _.uniq([...persistedDatasourceTags, ...addedDatsourceTags])
})

export const getSettingsSelectedUser = createSelector([getSettingsSelectedUserId, getUsersForSelectedAccount], (selectedUserId, users) =>
    users.find((u) => u.id === selectedUserId)
)

export const getSettingsSelectedInvite = createSelector(
    [getSettingsSelectedInviteId, getInvites, getSelectedAccountId],
    (inviteId, invites, accountId): Invite | undefined => {
        const accountInvites = invites[accountId || ''] || []
        return accountInvites.find((i) => i.id === inviteId)
    }
)

export const getFilteredUserTableEntries = createSelector(
    [getUsersForSelectedAccount, getInvitesForSelectedAccount, getSelectedAccountId, getUserSearchTerm],
    (users, invites, selectedAccountId, userSearchTerm) => {
        const filteredTableEntries: UserTableEntry[] = []

        users.forEach((u) => {
            if (
                userSearchTerm !== '' &&
                !(
                    u.first_name.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase()) ||
                    u.last_name.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase()) ||
                    u.email.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase())
                )
            ) {
                return
            }

            const account = u.accounts.find((a) => a.account_id === selectedAccountId) as UserAccountAccess

            filteredTableEntries.push({
                id: u.id,
                first_name: u.first_name,
                last_name: u.last_name,
                email: u.email,
                is_admin: account.role === 'ADMIN',
                is_owner: account.role === 'OWNER',
                status: 'active',
                user_access: account,
            })
        })

        invites.forEach((i) => {
            if (userSearchTerm !== '' && !i.email.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase())) {
                return
            }

            filteredTableEntries.push({
                id: i.id,
                first_name: '',
                last_name: '',
                email: i.email,
                is_admin: i.account.role === 'ADMIN',
                is_owner: i.account.role === 'OWNER',
                status: 'pending',
                invite: i,
            })
        })

        return filteredTableEntries
    }
)

export const getSelectedAccountSubscription = createSelector(
    [getSubscriptions, getSelectedAccountAccess, getAccounts],
    (subscriptions, selectedAccountAccess, accounts): BillingSubscription | undefined => {
        const selectedAccount = accounts[selectedAccountAccess?.account_id || '']
        return subscriptions[selectedAccount?.subscription_id || '']
    }
)

export const getSubscriptionsForSelectedCustomer = createSelector(
    [getSubscriptions, getSelectedAccount],
    (subscriptions, account): IdMap<BillingSubscription> => {
        return _.chain(subscriptions)
            .values()
            .filter((s) => s.customer === account.customer_id)
            .mapKeys('id')
            .value()
    }
)

export const getPaymentMethodsForSelectedCustomer = createSelector([getPaymentMethods, getSelectedAccount], (paymentMethods, account): IdMap<PaymentMethod> => {
    return _.chain(paymentMethods)
        .values()
        .filter((pm) => pm.customer === account.customer_id)
        .mapKeys('id')
        .value()
})

export const getInvoicesForSelectedCustomer = createSelector([getInvoices, getSelectedAccount], (invoices, account): IdMap<BillingInvoice> => {
    return _.chain(invoices)
        .values()
        .filter((invoice) => invoice.customer === account.customer_id)
        .mapKeys('id')
        .value()
})

export const getSelectedAccountProduct = createSelector([getProducts, getSelectedAccountSubscription], (products, subscription) => {
    return (subscription?.items.data || [])
        .map((item) => (typeof item.plan.product === 'string' ? products[item.plan.product] : item.plan.product))
        .find((p) => Boolean(p?.metadata.package))
})

export const getFilteredDatasourcesForProject = createSelector([getDatasources, getProjectDatasourceFilters], (datasources, filters) => {
    return datasources.filter((ds) => {
        let searchTermMatch, typeMatch

        if (filters.searchTerm === '') {
            searchTermMatch = true
        } else {
            searchTermMatch = ds.name.toLowerCase().includes(filters.searchTerm.toLowerCase())
        }

        if (filters.datasourceType === 'all') {
            typeMatch = true
        } else {
            typeMatch = ds.type === filters.datasourceType
        }

        return searchTermMatch && typeMatch
    })
})

export const getAllowedToCreateAccount = createSelector([getAccounts, getCurrentUser], (accounts, currentUser) => {
    const trialOrFreeAccount = _.chain(accounts)
        .values()
        .filter((a) => a.owner_id === currentUser.id)
        .find((a) => a.status === 'TRIALING' || a.package_type === 'free')
        .value()

    return !trialOrFreeAccount
})

export const getDatasourcesForNotificationSettingsTabByProject = createSelector(
    [getProjects, getNotificationSettingsForUser],
    (projects, notificationSettings): IdMap<IdMap<ProjectDatasource[]>> => {
        const datasourcesTypesForTab = {
            engage: ['FACEBOOK_PAGE', 'INSTAGRAM_ACCOUNT', 'LINKED_IN', 'TIKTOK_ACCOUNT'],
            analyse: ['FACEBOOK_PAGE', 'INSTAGRAM_ACCOUNT', 'LINKED_IN', 'TIKTOK_ACCOUNT', 'TALKWALKER_JSON', 'FACEBOOK_AD_ACCOUNT'],
            publish: ['FACEBOOK_PAGE', 'INSTAGRAM_ACCOUNT', 'LINKED_IN', 'TIKTOK_ACCOUNT', 'LINKED_IN_PROFILE'],
            core: ['FACEBOOK_PAGE', 'INSTAGRAM_ACCOUNT', 'LINKED_IN', 'TIKTOK_ACCOUNT', 'TALKWALKER_JSON', 'FACEBOOK_AD_ACCOUNT'],
        }

        return _.mapValues(projects, (project) => {
            return _.mapValues(datasourcesTypesForTab, (types, notificationModule) => {
                return project.data_sources.filter((channel) => {
                    const channelSettings: any = notificationSettings[channel.id] || {}
                    const isModuleActive = Boolean(channelSettings[notificationModule])
                    return types.includes(channel.type) && isModuleActive
                })
            })
        })
    }
)

export const {
    openUserSettings,
    openEditInvite,
    addDatasourceTag,
    changeUserSearchTerm,
    changeAccountWizardState,
    setAccountIdForProjectWizard,
    changeProjectDatasourceFilterSearchTerm,
    changeProjectDatasourceFilterType,
} = SettingsSlice.actions

export const SettingsReducer = SettingsSlice.reducer
