import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import * as React from 'react'
import { Box } from '@mui/material'
import { getIsMobile } from '../../../core/slices/CoreSlice'
import { useSelector } from 'react-redux'

type InstagramStoryVideoProps = {
    objectUrl: string | undefined
    playerRef: any
    height: number | string
}

export const InstagramStoryVideo: FC<InstagramStoryVideoProps> = ({ objectUrl, playerRef, height }) => {
    const isMobile = useSelector(getIsMobile)
    return (
        <Box mr={-2} ml={-2} position="relative" height="100%" display="flex" alignItems="center">
            <video
                autoPlay={true}
                muted={true}
                loop
                playsInline
                style={{
                    zIndex: 0,
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    objectFit: 'cover',
                    filter: 'blur(200px) brightness(0.9)',
                    pointerEvents: 'none',
                }}
                src={objectUrl}
            >
                shot
            </video>
            <video
                playsInline
                ref={playerRef}
                autoPlay={true}
                muted={true}
                loop
                controls={false}
                width="100%"
                height={height}
                style={{
                    objectFit: height === '100%' ? 'cover' : 'contain',
                    zIndex: 0,
                    pointerEvents: 'none',
                }}
                src={objectUrl}
            >
                <FormattedMessage id="publishing.video-not-supported" />
            </video>
            {isMobile && <Box sx={{ position: 'absolute', background: 'transparent', height: '100%', width: '100%' }} />}
        </Box>
    )
}
