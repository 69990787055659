import * as React from 'react'
import { FC } from 'react'
import { Dashboard } from './Dashboard'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { DashboardTemplate } from './DashboardTemplate'
import moment from 'moment/moment'
import { FormattedMessage } from 'react-intl'

type MobileDashboardCardProps = { dashboard: Dashboard; template?: DashboardTemplate; onClick?: () => void }

export const MobileDashboardCard: FC<MobileDashboardCardProps> = ({ dashboard, template, onClick }) => {
    return (
        <Card onClick={onClick}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography fontSize={14} color={'text.secondary'}>
                    {template?.display_name}
                </Typography>
                <Typography fontWeight={600} maxWidth={'100%'}>
                    {dashboard.name}
                </Typography>
                <Box display={'flex'} justifyContent={'space-between'} mt={1}>
                    <Box>
                        <Typography fontSize={14} color={'text.secondary'}>
                            <FormattedMessage id={'reporting.dashboards.creation-date'} />
                        </Typography>
                        <Typography fontSize={14}>{moment(dashboard.created_at).format('DD.MM.YYYY')}</Typography>
                    </Box>
                    <Box>
                        <Typography fontSize={14} color={'text.secondary'}>
                            <FormattedMessage id={'reporting.dashboards.date-of-change'} />
                        </Typography>
                        <Typography fontSize={14}>{moment(dashboard.updated_at).format('DD.MM.YYYY')}</Typography>
                    </Box>
                    <Box>
                        <Typography fontSize={14} color={'text.secondary'}>
                            <FormattedMessage id={'reporting.dashboards.author'} />
                        </Typography>
                        <Typography fontSize={14}>{dashboard.created_by}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
