import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { Tag } from './Tag'
import { fetchTagsForProject } from './TagsActions'
import { HmstrState } from '../core/Store'
import { HmstrColors } from '../core/theme/hmstr-theme'

interface TagState {
    isLoadingTags: boolean
    searchTerm: string
    selectedTagIds: string[]
    filteredTagIds: string[]
    sortedBy: keyof Tag
    sortDirection: 'asc' | 'desc'
}

const initialState: TagState = {
    isLoadingTags: false,
    searchTerm: '',
    selectedTagIds: [],
    filteredTagIds: [],
    sortedBy: 'label',
    sortDirection: 'desc',
}

export const UNTAGGED = 'UNTAGGED'

export const getUntaggedTag = (label: string) => {
    return {
        id: UNTAGGED,
        tag_id: UNTAGGED,
        project_id: '',
        label: label,
        color: HmstrColors.Black,
        active: true,
        _links: { self: { href: '' } },
    } as Tag
}

export const TagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        changeSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload
        },
        changeSortedBy: (state, action: PayloadAction<keyof Tag>) => {
            state.sortedBy = action.payload
        },
        toggleSortDirection: (state) => {
            state.sortDirection = state.sortDirection === 'desc' ? 'asc' : 'desc'
        },
        changeFilteredDatasourceIds: (state, action: PayloadAction<string[]>) => {
            state.filteredTagIds = action.payload
        },
        changeSelectedTagIds: (state, action: PayloadAction<string[]>) => {
            state.selectedTagIds = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTagsForProject.pending, (state) => {
                state.isLoadingTags = true
            })
            .addCase(fetchTagsForProject.fulfilled, (state) => {
                state.isLoadingTags = false
            })
    },
})

export const getIsLoadingTags: Selector<HmstrState, boolean> = (state) => state.tags.isLoadingTags
export const getTagsSearchTerm: Selector<HmstrState, string> = (state) => state.tags.searchTerm
export const getSelectedTags: Selector<HmstrState, String[] | undefined> = (state) => state.tags.selectedTagIds
export const getFilteredTagIds: Selector<HmstrState, string[]> = (state) => state.tags.filteredTagIds

export const { changeSearchTerm, changeSortedBy, toggleSortDirection, changeFilteredDatasourceIds, changeSelectedTagIds } = TagsSlice.actions

export const TagReducer = TagsSlice.reducer
