import * as React from 'react'
import { FC } from 'react'
import { DateRangePicker } from '../../common/daterange-picker/DateRangePicker'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeDateListView,
    getSelectedDatasourceIds,
    getSelectedEndDateListView,
    getSelectedStartDateListView,
    getShowFullTagNames,
    getShowOnlyAssignedPosts,
    getShowOnlyAssignedToOthersPosts,
    getShowOnlyUnassignedPosts,
    selectDatasourceIds,
    startNewPost,
    toggleOnlyAssignedPosts,
    toggleOnlyAssignedToOthersPosts,
    toggleShowFullTagNames,
    toggleShowOnlyUnassignedPosts,
} from '../PublishingSlice'
import { Box, Button, ToggleButton, Tooltip, useTheme } from '@mui/material'
import { HmstrDispatch } from '../../core/Store'
import { getPagesForPublish, getSelectedProject } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { replaceUrlParams } from '../../core/helpers/replace-url-params'
import { fetchCommonPostData } from '../../content-analytics/posts/PostsActions'
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { CalendarToday, Group, Label, LabelOff, PostAdd, Web } from '@mui/icons-material'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { DatasourceSelection } from '../../content-analytics/datasource/DatasoureSelection'
import { PublishingAssignmentSelect } from './PublishingAssignmentSelect'
import { fetchPostGroups } from '../post-groups/PostGroupActions'
import { FilterDrawer, FilterSetting } from '../../common/filterbar/FilterDrawer'
import moment from 'moment/moment'
import { renderTimePeriodString } from '../../resources/translations/Helper'
import { TitlebarWithFilters } from '../../common/filterbar/TitlebarWithFilters'
import { useHotkeys } from 'react-hotkeys-hook'
import { HotkeyTooltip } from '../../common/hotkeys/HotkeyTooltip'
import {
    getAssigneeSelectionOpen,
    getDatasourceSelectionOpen,
    getTimePeriodSettingOpen,
    setAssigneeSelectionOpen,
    setDatasourceSelectionOpen,
    setTimePeriodSettingOpen,
} from '../../common/filterbar/FilterSlice'

type PublishingListViewToolbarProps = {}

export const PublishingListViewToolbar: FC<PublishingListViewToolbarProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const timePeriodStart = useSelector(getSelectedStartDateListView)
    const timePeriodEnd = useSelector(getSelectedEndDateListView)
    const selectedProject = useSelector(getSelectedProject)
    const showFullTagNames = useSelector(getShowFullTagNames)
    const navigate = useProjectNavigate()
    const theme = useTheme()
    const onlyAssigned = useSelector(getShowOnlyAssignedPosts)
    const onlyAssignedToOthers = useSelector(getShowOnlyAssignedToOthersPosts)
    const onlyUnassigned = useSelector(getShowOnlyUnassignedPosts)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)
    const pagesForPublishing = useSelector(getPagesForPublish)
    const datasourceSelectionOpen = useSelector(getDatasourceSelectionOpen)
    const timePeriodOpen = useSelector(getTimePeriodSettingOpen)
    const assigneeSelectionOpen = useSelector(getAssigneeSelectionOpen)

    useHotkeys('shift+t', () => {
        dispatch(toggleShowFullTagNames())
    })

    useHotkeys('n', () => handleCreatePostNowClick())

    const handleDatasourceSelectionOpen = () => dispatch(setDatasourceSelectionOpen(true))
    const handleDatasourceSelectionClose = () => dispatch(setDatasourceSelectionOpen(false))
    const handleAssigneeSelectionOpen = () => dispatch(setAssigneeSelectionOpen(true))
    const handleAssigneeSelectionClose = () => dispatch(setAssigneeSelectionOpen(false))
    const handleTimePeriodOpen = () => dispatch(setTimePeriodSettingOpen(true))
    const handleTimePeriodClose = () => dispatch(setTimePeriodSettingOpen(false))

    const handleChangeFullTagNames = () => {
        dispatch(toggleShowFullTagNames())
    }
    const handleCreatePostNowClick = () => {
        dispatch(startNewPost())
        navigate('/publishing/list/new')
    }

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchPostGroups({ project: selectedProject, since: timePeriodStart, until: timePeriodEnd }))
            dispatch(
                fetchCommonPostData({
                    since: timePeriodStart,
                    until: timePeriodEnd,
                    data_source_ids: _.map(selectedProject.data_sources, (ds) => ds.id),
                })
            )
        }
    }, [timePeriodEnd, timePeriodStart, selectedProject])

    useEffectWithIdComparison(() => {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.set('startDate', timePeriodStart)
        urlParams.set('endDate', timePeriodEnd)
        replaceUrlParams(urlParams)
    }, [timePeriodEnd, timePeriodStart])

    const getTimePeriodFilterSetting = () => {
        const defaultStartDate = moment().startOf('month').format('YYYY-MM-DD')
        const defaultEndDate = moment().endOf('month').format('YYYY-MM-DD')

        const hasChanged = () => {
            return !(timePeriodStart === defaultStartDate && timePeriodEnd === defaultEndDate)
        }
        const onDeleteFunction = () => {
            dispatch(changeDateListView({ startDate: defaultStartDate, endDate: defaultEndDate }))
        }

        const onChipClick = () => {
            setTimeout(handleTimePeriodOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: (
                <Box maxWidth={'100%'}>
                    <DateRangePicker
                        onOpen={handleTimePeriodOpen}
                        onClose={handleTimePeriodClose}
                        open={timePeriodOpen}
                        fullWidth
                        startDate={timePeriodStart}
                        endDate={timePeriodEnd}
                        onChange={(startDate, endDate) => {
                            dispatch(changeDateListView({ startDate, endDate }))
                        }}
                        variant={'outlined'}
                        size={'small'}
                    />
                </Box>
            ),
            tooltip: 'filters.timeperiod',
            currentValueAsString: renderTimePeriodString(timePeriodStart, timePeriodEnd, intl),
            important: true,
            deletable: true,
            hasChangedFromDefault: hasChanged(),
            onDeleteFunction: onDeleteFunction,
            onClickFunction: onChipClick,
            icon: <CalendarToday fontSize={'small'} />,
        }
        return set
    }

    const getAssigneeFilterSetting = () => {
        const hasChanged = onlyAssigned || onlyUnassigned || onlyAssignedToOthers
        const onDeleteFunction = () => {
            if (onlyAssigned) {
                dispatch(toggleOnlyAssignedPosts())
            } else if (onlyUnassigned) {
                dispatch(toggleShowOnlyUnassignedPosts())
            } else if (onlyAssignedToOthers) {
                dispatch(toggleOnlyAssignedToOthersPosts())
            }
        }

        const generateSettingString = () => {
            if (onlyAssigned) {
                return intl.formatMessage({ id: 'publishing.filter.assigned-to-me' })
            } else if (onlyUnassigned) {
                return intl.formatMessage({ id: 'filters.unassigned' })
            } else if (onlyAssignedToOthers) {
                return intl.formatMessage({ id: 'engagement.inbox.assigned-to-others' })
            }
        }

        const onChipClick = () => {
            setTimeout(handleAssigneeSelectionOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: (
                <PublishingAssignmentSelect onClose={handleAssigneeSelectionClose} onOpen={handleAssigneeSelectionOpen} open={assigneeSelectionOpen} />
            ),
            tooltip: 'filters.assignment.title',
            currentValueAsString: generateSettingString(),
            important: false,
            deletable: true,
            hasChangedFromDefault: hasChanged,
            onDeleteFunction: onDeleteFunction,
            onClickFunction: onChipClick,
            icon: <Group fontSize={'small'} />,
        }
        return set
    }

    const getDatasourceSetting = () => {
        const defaultValue: string[] = []
        const hasChanged = !_.isEqual(_.sortBy(defaultValue), _.sortBy(selectedDatasourceIds))
        const onDeleteFunction = () => {
            dispatch(selectDatasourceIds([]))
        }
        const generateSettingString = () => {
            return selectedDatasourceIds.length === 1
                ? pagesForPublishing.find((project) => project.id === selectedDatasourceIds[0])?.name
                : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selectedDatasourceIds.length })
        }

        const onChipClick = () => {
            setTimeout(handleDatasourceSelectionOpen, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <DatasourceSelection
                    datasources={pagesForPublishing}
                    handleOpen={handleDatasourceSelectionOpen}
                    handleClose={handleDatasourceSelectionClose}
                    isOpen={datasourceSelectionOpen}
                    fullWidth
                    initialIds={selectedDatasourceIds}
                    size={'small'}
                    onDatasourceChange={(ids) => dispatch(selectDatasourceIds(ids))}
                />
            ),
            tooltip: 'general.datasources',
            currentValueAsString: generateSettingString(),
            important: false,
            deletable: true,
            onClickFunction: onChipClick,
            hasChangedFromDefault: hasChanged,
            onDeleteFunction: onDeleteFunction,
            icon: <Web fontSize={'small'} />,
        }
        return set
    }

    return (
        <>
            <TitlebarWithFilters
                title={'features.publishing.list-view'}
                filterBar={
                    <FilterDrawer
                        filters={[getTimePeriodFilterSetting(), getAssigneeFilterSetting(), getDatasourceSetting()]}
                        previousAppThemeColor={theme.palette.background.default}
                    />
                }
                viewOptions={
                    <Box>
                        <Tooltip
                            placement={'right'}
                            title={
                                <Box display={'flex'} alignItems={'center'} gap={1}>
                                    <FormattedMessage id={`publishing.filter.${showFullTagNames ? 'hide' : 'show'}-full-tag-names`} />
                                    <HotkeyTooltip invert keys={['shift', 't']} fontSize={14} />
                                </Box>
                            }
                        >
                            <ToggleButton value={showFullTagNames} selected={showFullTagNames} onChange={handleChangeFullTagNames} size={'small'}>
                                {showFullTagNames ? <LabelOff /> : <Label />}
                            </ToggleButton>
                        </Tooltip>
                    </Box>
                }
                actionButton={
                    <Tooltip
                        title={
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                                <FormattedMessage id="publishing.create-post" />
                                <HotkeyTooltip keys={['n']} invert fontSize={14} />
                            </Box>
                        }
                        placement={'left'}
                    >
                        <Button
                            id={'publishing_create_post_btn'}
                            startIcon={<PostAdd />}
                            color="secondary"
                            variant="contained"
                            onClick={handleCreatePostNowClick}
                        >
                            <FormattedMessage id="publishing.create-post" />
                        </Button>
                    </Tooltip>
                }
            />
        </>
    )
}
