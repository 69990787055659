import * as _ from 'lodash'
import moment from 'moment'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../../core/Store'
import { fetchCommonPostData } from './PostsActions'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { getPagesForAnalyse } from '../../core/slices/CoreSlice'
import { getCommonPostDataAsIdMap } from '../../core/slices/DataSlice'
import { PostSortType } from '../../common/filter/PostSortType'
import { TagStatusFilterType } from '../../common/filter/TagStatusFilterType'
import { UrlParamsParser } from '../../common/parse-url-params/parseUrlParams'
import { CommonPostData } from './CommonPostData'

interface PostsState {
    selectedTimePeriodStartDate: string
    selectedTimePeriodEndDate: string
    selectedDatasourceIds: string[]
    isPostDataLoading: boolean
    previewPost?: CommonPostData
    tagStatusSelection: TagStatusFilterType
    selectedTags: string[]
    showUntaggedPosts: boolean
    showDarkPosts: boolean
    lockedPosts: string[]
    page: number
    pageAmount: number
    sortDirection: 'asc' | 'desc'
    sortField: PostSortType
}

const generateInitialState = (): PostsState => {
    const urlParser = new UrlParamsParser('/tagging')

    return {
        selectedTimePeriodStartDate: urlParser.getDateFromUrl('startDate', moment().subtract(30, 'days').format()),
        selectedTimePeriodEndDate: urlParser.getDateFromUrl('endDate', moment().format()),
        isPostDataLoading: true,
        lockedPosts: [],
        pageAmount: 25,
        selectedDatasourceIds: urlParser.getArrayFromUrl('dataSources', []),
        tagStatusSelection: urlParser.getEnumValueFromUrl<typeof TagStatusFilterType>(TagStatusFilterType, 'tagStatus', TagStatusFilterType.ALL),
        selectedTags: urlParser.getArrayFromUrl('tags', []),
        showUntaggedPosts: urlParser.getBooleanFromUrl('showUntagged', false),
        showDarkPosts: urlParser.getBooleanFromUrl('showDarkPosts', false),
        page: urlParser.getIntegerFromUrl('page', 1),
        sortDirection: urlParser.getStringFromUrl('sortDirection', 'desc') as 'asc' | 'desc',
        sortField: urlParser.getEnumValueFromUrl<typeof PostSortType>(PostSortType, 'sortBy', PostSortType.DATE),
    }
}

export const PostsSlice = createSlice({
    name: 'posts',
    initialState: generateInitialState(),
    reducers: {
        changeTimePeriod: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
            state.selectedTimePeriodStartDate = action.payload.startDate
            state.selectedTimePeriodEndDate = action.payload.endDate
            state.page = 1
            state.lockedPosts = []
        },
        selectDatasources: (state, action: PayloadAction<string[]>) => {
            state.selectedDatasourceIds = action.payload
            state.page = 1
            state.lockedPosts = []
        },
        previewPost: (state, action: PayloadAction<CommonPostData>) => {
            state.previewPost = action.payload
        },
        hidePreview: (state) => {
            state.previewPost = undefined
        },
        setShowUntaggedPosts: (state, action: PayloadAction<boolean>) => {
            state.showUntaggedPosts = action.payload
            state.page = 1
            state.lockedPosts = []
        },
        setShowDarkPosts: (state, action: PayloadAction<boolean>) => {
            state.showDarkPosts = action.payload
            state.page = 1
            state.lockedPosts = []
        },
        setSelectedTags: (state, action: PayloadAction<string[]>) => {
            state.selectedTags = action.payload
            state.showUntaggedPosts = false
            state.page = 1
            state.lockedPosts = []
        },
        setLockedPosts: (state, action: PayloadAction<string[]>) => {
            state.lockedPosts = action.payload
        },
        addLockedPosts: (state, action: PayloadAction<string>) => {
            state.lockedPosts.push(action.payload)
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload
        },
        setPageAmount: (state, action: PayloadAction<number>) => {
            state.pageAmount = action.payload
            state.page = 1
        },
        setSortDirection: (state, action: PayloadAction<'asc' | 'desc'>) => {
            state.sortDirection = action.payload
        },
        setSortField: (state, action: PayloadAction<PostSortType>) => {
            state.sortField = action.payload
        },
        setTagStatusSelection: (state, action: PayloadAction<TagStatusFilterType>) => {
            state.tagStatusSelection = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCommonPostData.pending, (state) => {
                state.isPostDataLoading = true
            })
            .addCase(fetchCommonPostData.fulfilled, (state) => {
                state.isPostDataLoading = false
            })
            .addCase('core/selectProject', (state) => {
                state.selectedDatasourceIds = []
                state.selectedTags = []
                state.isPostDataLoading = true
            })
    },
})

export const getSelectedTimePeriodStart: Selector<HmstrState, string> = (state) => state.posts.selectedTimePeriodStartDate
export const getSelectedTimePeriodEnd: Selector<HmstrState, string> = (state) => state.posts.selectedTimePeriodEndDate
export const getSelectedDatasourceIds: Selector<HmstrState, string[]> = (state) => state.posts.selectedDatasourceIds
export const isPostDataLoading: Selector<HmstrState, boolean> = (state) => state.posts.isPostDataLoading
export const getPreviewPost: Selector<HmstrState, CommonPostData | undefined> = (state) => state.posts.previewPost
export const getPage: Selector<HmstrState, number> = (state) => state.posts.page
export const getPageAmount: Selector<HmstrState, number> = (state) => state.posts.pageAmount
export const getSelectedTags: Selector<HmstrState, string[]> = (state) => state.posts.selectedTags
export const getSortDirection: Selector<HmstrState, 'asc' | 'desc'> = (state) => state.posts.sortDirection
export const getSortField: Selector<HmstrState, PostSortType> = (state) => state.posts.sortField
export const getShowUntaggedPosts: Selector<HmstrState, boolean> = (state) => state.posts.showUntaggedPosts
export const getShowDarkPosts: Selector<HmstrState, boolean> = (state) => state.posts.showDarkPosts
export const getLockedPosts: Selector<HmstrState, string[]> = (state) => state.posts.lockedPosts
export const getTagSelectionStatus: Selector<HmstrState, TagStatusFilterType> = (state) => state.posts.tagStatusSelection

export const getSelectedDatasources: Selector<HmstrState, ProjectDatasource[]> = createSelector(
    [getPagesForAnalyse, getSelectedDatasourceIds],
    (pages, datasourceIds) => {
        return pages.filter((page) => datasourceIds.includes(page.id))
    }
)

export const getPostDataForDatasources = createSelector(
    [getCommonPostDataAsIdMap, getSelectedDatasourceIds],
    (commonPostData, selectedDatasourceIds): CommonPostData[] => {
        return _.chain(selectedDatasourceIds)
            .map((dsid) => (commonPostData[dsid] || []) as CommonPostData[])
            .flatten()
            .value()
    }
)

export const getFilteredPostsForSelectedDatasources = createSelector(
    [getPostDataForDatasources, getSelectedTimePeriodStart, getSelectedTimePeriodEnd, getSelectedTags, getShowUntaggedPosts, getLockedPosts, getShowDarkPosts],
    (posts, startDate, endDate, filterTags, showOnlyUntagged, lockedPosts, showDarkPosts) => {
        const postsForSelectedDatasources: CommonPostData[] = []
        const start = moment(startDate)
        const end = moment(endDate)

        postsForSelectedDatasources.push(
            ...posts.filter(
                (post) =>
                    moment(post.created_at).isBetween(start, end, 'day', '[]') &&
                    ((filterTags.length > 0 ? filterTags.every((tag) => post.tags.includes(tag)) : showOnlyUntagged ? post.tags.length === 0 : true) ||
                        lockedPosts.includes(post.post_id)) &&
                    (showDarkPosts ? true : post.type !== 'DARK_POST')
            )
        )

        return postsForSelectedDatasources
    }
)

export const getFilteredSortedPostsForSelectedDatasources = createSelector(
    [getFilteredPostsForSelectedDatasources, getSortDirection, getSortField],
    (postData, sortDirection, sortSelection) => {
        return [...postData].sort((a, b) => {
            let order

            if (sortSelection === PostSortType.DATE) {
                order = new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            } else if (sortSelection === PostSortType.ENGAGEMENT) {
                order = a.insights.engagement - b.insights.engagement
            } else {
                order = a.insights.impressions - b.insights.impressions
            }
            return (sortDirection === 'asc' ? 1 : -1) * order
        })
    }
)

export const getFilteredPostsForSelectedDatasourcesLength = createSelector([getFilteredPostsForSelectedDatasources], (postData) => {
    return postData.length
})

export const getFilteredSortedPostsForSelectedDatasourcesPaginated = createSelector(
    [getFilteredSortedPostsForSelectedDatasources, getPage, getPageAmount],
    (postData, page, pageAmount) => {
        return postData.slice((page - 1) * pageAmount, page * pageAmount)
    }
)

export const {
    changeTimePeriod,
    selectDatasources,
    previewPost,
    hidePreview,
    setShowUntaggedPosts,
    setShowDarkPosts,
    setSelectedTags,
    setPage,
    setPageAmount,
    setSortDirection,
    setSortField,
    addLockedPosts,
    setTagStatusSelection,
} = PostsSlice.actions

export const PostsReducer = PostsSlice.reducer
