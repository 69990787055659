import * as React from 'react'
import { FC } from 'react'
import { Box } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { AnalyticsNavigation } from './secondary-navigations/AnalyticsNavigation'
import { PublishingNavigation } from './secondary-navigations/PublishingNavigation'
import { SettingsNavigation } from './secondary-navigations/SettingsNavigation'
import { EngagementNavigation } from './secondary-navigations/EngagementNavigation'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../core/slices/CoreSlice'
import { SecondaryNavigationDrawer } from './SecondaryNavigationDrawer'

type SecondaryNavigationProps = {}

export const SecondaryNavigation: FC<SecondaryNavigationProps> = () => {
    const currentUser = useSelector(getCurrentUser)

    return (
        currentUser && (
            <Routes>
                <Route path="/:projectId">
                    <Route index element={<Box />} />
                    <Route element={<SecondaryNavigationDrawer />}>
                        <Route path="publishing/*" element={<PublishingNavigation />} />
                        <Route path="analytics/*" element={<AnalyticsNavigation />} />
                        <Route path="engagement/*" element={<EngagementNavigation />} />
                        <Route path="settings/*" element={<SettingsNavigation />} />
                    </Route>
                </Route>
            </Routes>
        )
    )
}
