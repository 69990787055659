import * as React from 'react'
import { FC, Fragment, useEffect, useRef } from 'react'
import {
    Autocomplete,
    Box,
    Checkbox,
    Dialog,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Pagination,
    Paper,
    Select,
    SelectProps,
    Switch,
    TextField,
    Theme,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCommonPostData } from './PostsActions'
import { getPagesForAnalyse, getSelectedProject, isDarkMode } from '../../core/slices/CoreSlice'
import {
    changeTimePeriod,
    getFilteredPostsForSelectedDatasourcesLength,
    getFilteredSortedPostsForSelectedDatasourcesPaginated,
    getPage,
    getPageAmount,
    getPreviewPost,
    getSelectedDatasourceIds,
    getSelectedDatasources,
    getSelectedTags,
    getSelectedTimePeriodEnd,
    getSelectedTimePeriodStart,
    getShowDarkPosts,
    getShowUntaggedPosts,
    getSortDirection,
    getSortField,
    getTagSelectionStatus,
    hidePreview,
    isPostDataLoading,
    selectDatasources,
    setPage,
    setPageAmount,
    setSelectedTags,
    setShowDarkPosts,
    setShowUntaggedPosts,
    setSortDirection,
    setSortField,
    setTagStatusSelection,
} from './PostsSlice'
import { Loading } from '../../common/loading/Loading'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { Fallback } from '../../common/fallback/Fallback'
import { FacebookPostPreview } from './FacebookPostPreview'
import { PostCardsList } from './PostCardsList'
import { StyledMainElement } from '../../common/content-panel/ContentPanel.styles'
import { useDrawerState } from '../../core/hooks/useDrawerState'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { TagStatusFilterType } from '../../common/filter/TagStatusFilterType'
import { PostSortType } from '../../common/filter/PostSortType'
import { DateRangePicker } from '../../common/daterange-picker/DateRangePicker'
import { fetchTagsForProject } from '../../tags/TagsActions'
import { getSortedTagsData } from '../../core/slices/DataSlice'
import { Tag } from '../../tags/Tag'
import { TagChip } from '../tags/TagChip'
import { DatasourceSelection } from '../datasource/DatasoureSelection'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { TagColorPicker } from '../tags/components/TagColorPicker'
import { SxProps } from '@mui/system'
import { HmstrDispatch } from '../../core/Store'
import { TiktokPostPreview } from './TiktokPostPreview'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { replaceUrlParams } from '../../core/helpers/replace-url-params'
import { createHmstrTheme } from '../../core/theme/hmstr-theme'
import { FilterDrawer, FilterSetting } from '../../common/filterbar/FilterDrawer'
import { TitlebarWithFilters } from '../../common/filterbar/TitlebarWithFilters'
import { CalendarToday, Sell, ToggleOff, ToggleOn, VisibilityOff, Web } from '@mui/icons-material'
import _ from 'lodash'
import moment from 'moment/moment'
import { renderTimePeriodString } from '../../resources/translations/Helper'
import {
    getDatasourceSelectionOpen,
    getTagSelectionOpen,
    getTagStateSettingOpen,
    getTimePeriodSettingOpen,
    setDatasourceSelectionOpen,
    setTagSelectionOpen,
    setTagStateSettingOpen,
    setTimePeriodSettingOpen,
} from '../../common/filterbar/FilterSlice'

type FacebookPostsProps = {}
type FilterOption = { value: string | number; label: string; selected?: boolean }

export const Posts: FC<FacebookPostsProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const intl = useIntl()
    const navigate = useProjectNavigate()
    const mainElementRef = useRef<any>(null)
    const isLoading = useSelector(isPostDataLoading)
    const selectedProject = useSelector(getSelectedProject)
    const pagesForAnalyse = useSelector(getPagesForAnalyse)
    const selectedDataSources = useSelector(getSelectedDatasources)
    const selectedDataSourceIds = useSelector(getSelectedDatasourceIds)
    const datasourceIds = _.map(pagesForAnalyse, (datasource) => datasource.id).filter((d) => d)
    const startDate = useSelector(getSelectedTimePeriodStart)
    const endDate = useSelector(getSelectedTimePeriodEnd)
    const previewPost = useSelector(getPreviewPost)
    const drawerState = useDrawerState()

    const filteredPostsAmount = useSelector(getFilteredPostsForSelectedDatasourcesLength)
    const posts = useSelector(getFilteredSortedPostsForSelectedDatasourcesPaginated)
    const allTags = useSelector(getSortedTagsData)
    const selectedTags = useSelector(getSelectedTags)
    const currentPage = useSelector(getPage)
    const resultAmountSelection = useSelector(getPageAmount)
    const sortField = useSelector(getSortField)
    const sortDirection = useSelector(getSortDirection)
    const showUntaggedPosts = useSelector(getShowUntaggedPosts)
    const showDarkPosts = useSelector(getShowDarkPosts)
    const tagStatusSelection = useSelector(getTagSelectionStatus)
    const darkmode = useSelector(isDarkMode)
    const datasourceSelectionOpen = useSelector(getDatasourceSelectionOpen)
    const tagSelectionOpen = useSelector(getTagSelectionOpen)
    const tagStateSettingOpen = useSelector(getTagStateSettingOpen)
    const datePickerOpen = useSelector(getTimePeriodSettingOpen)
    const tagSelectionRef = useRef<any>(null)

    const handleDatasourceSelectionClose = () => dispatch(setDatasourceSelectionOpen(false))
    const handleDatasourceSelectionOpen = () => dispatch(setDatasourceSelectionOpen(true))
    const handleTagSelectionClose = () => dispatch(setTagSelectionOpen(false))
    const handleTagSelectionOpen = () => {
        dispatch(setTagSelectionOpen(true))
        setTimeout(() => {
            handleFocusTagSelection()
        }, 100)
    }

    const handleFocusTagSelection = () => {
        if (tagSelectionRef.current) {
            tagSelectionRef.current.querySelector('input').focus()
        }
    }

    const handleTagStateClose = () => dispatch(setTagStateSettingOpen(false))
    const handleTagStateOpen = () => dispatch(setTagStateSettingOpen(true))
    const handleDatePickerClose = () => dispatch(setTimePeriodSettingOpen(false))
    const handleDatePickerOpen = () => dispatch(setTimePeriodSettingOpen(true))

    const handlePaginationChange = (event: React.ChangeEvent<any>, page: number) => {
        dispatch(setPage(page))

        if (mainElementRef.current !== null) {
            mainElementRef.current.scroll(0, 0)
        }
    }

    useEffectWithIdComparison(() => {
        if (pagesForAnalyse.length > 0 && selectedDataSources.length > 0) {
            const urlParams = new URLSearchParams(window.location.search)
            urlParams.set('dataSources', selectedDataSources.map((project) => project.id).join(','))
            urlParams.set('startDate', startDate.split('T')[0])
            urlParams.set('endDate', endDate.split('T')[0])
            urlParams.set('tags', selectedTags.join(','))
            urlParams.set('page', currentPage.toString())
            urlParams.set('sortBy', PostSortType[sortField])
            urlParams.set('sortDirection', sortDirection)
            urlParams.set('showUntagged', showUntaggedPosts.toString())
            urlParams.set('showDarkPosts', showDarkPosts.toString())
            urlParams.set('tagStatus', TagStatusFilterType[tagStatusSelection])
            replaceUrlParams(urlParams)
        } else if (pagesForAnalyse.length > 0 && selectedDataSources.length === 0) {
            navigate('/analytics/tagging')
        }
    }, [startDate, endDate, selectedTags, currentPage, sortDirection, sortField, showUntaggedPosts, showDarkPosts, tagStatusSelection, selectedDataSources])

    useEffect(() => {
        if (selectedDataSourceIds.length === 0) {
            dispatch(selectDatasources(datasourceIds))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTagsForProject(selectedProject))
        }
    }, [selectedProject])

    useEffectWithIdComparison(() => {
        if (selectedDataSourceIds.length > 0) {
            dispatch(fetchCommonPostData({ since: startDate, until: endDate, data_source_ids: selectedDataSourceIds }))
        }
    }, [selectedDataSources, startDate, endDate, currentPage])

    const renderTagFilter = (
        label: string,
        options: Tag[],
        active: string[],
        onChange: (newValue: (String | Tag | undefined)[]) => any,
        disableActiveLabel?: boolean
    ) => {
        const activeTags = active.map((id) => allTags.find((tag) => tag.id === id))

        allTags.sort((a, b) => {
            if (a.active && b.active) {
                return a.label.localeCompare(b.label)
            } else if (a.active) {
                return -1
            } else if (b.active) {
                return 1
            } else {
                return a.label.localeCompare(b.label)
            }
        })

        let lastTag: Tag | null
        return (
            <Autocomplete
                multiple
                ref={tagSelectionRef}
                id="tag-filter-outlined"
                options={allTags}
                key={tagSelectionOpen.toString() + !!tagSelectionRef.current}
                fullWidth
                value={activeTags}
                open={tagSelectionOpen}
                onOpen={handleTagSelectionOpen}
                onClose={handleTagSelectionClose}
                onChange={(event, newValue) => {
                    onChange(newValue)
                    handleFocusTagSelection()
                }}
                disableClearable
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                    if (!option) {
                        return undefined
                    }

                    let renderGroup = !disableActiveLabel && (!lastTag || lastTag.active !== option.active)
                    lastTag = option

                    return (
                        <Fragment key={option.id + 'fragment'}>
                            {renderGroup && (
                                <MenuItem
                                    key={`menu-${option.id}-${option.active}`}
                                    sx={{
                                        cursor: 'default',
                                        '&:hover': { backgroundColor: 'unset' },
                                        padding: '16px 16px 12px',
                                    }}
                                >
                                    <ListItemText
                                        primary={intl.formatMessage({
                                            id: option.active ? 'tags.active' : 'tags.inactive',
                                        })}
                                    />
                                </MenuItem>
                            )}

                            <MenuItem {...props} key={`menu-${option.id}`}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                <Typography variant={'body1'} sx={{ marginRight: '8px' }}>
                                    {option.label}
                                </Typography>
                                <TagColorPicker color={option.color ?? '#EEE'} changeCallback={() => {}} disabled={true} />
                            </MenuItem>
                        </Fragment>
                    )
                }}
                renderInput={(params) => (
                    <TextField
                        key={'textFieldTags'}
                        {...params}
                        label={label}
                        placeholder={intl.formatMessage({ id: 'general.add-tags' })}
                        variant={'outlined'}
                        sx={{
                            margin: 0,
                            paddingY: '8px',
                            minWidth: 200,
                            minHeight: '65px',
                            height: '100%',
                            '&>#tag-filter-outlined-label': {
                                left: '14px',
                                paddingTop: '2px',
                            },
                        }}
                    />
                )}
                renderTags={(value, getTagProps) => {
                    if (value.length > 2) {
                        return <FormattedMessage key={'xTagsSelected'} id="filters.xTagsSelected" values={{ x: value.length }} />
                    }

                    return value.map((option, index) => {
                        if (!!option) {
                            return <TagChip key={option.id} tag={option} additionalProps={getTagProps({ index })} />
                        }
                        return null
                    })
                }}
            />
        )
    }

    const renderFilter = (
        label: string,
        options: FilterOption[],
        onChange: any,
        customStyle?: SxProps<Theme>,
        onOpen?: () => void,
        onClose?: () => void,
        open?: boolean
    ) => {
        let props: SelectProps<any> = {
            labelId: 'label-' + label,
            label: label,
            onChange: onChange,
            variant: 'outlined',
            value: options.filter((option) => option.selected).map((option) => option.value),
            onOpen: onOpen,
            onClose: onClose,
            open: open,
        }

        return (
            <FormControl sx={{ margin: 0, paddingY: '8px', minWidth: 130, minHeight: '50px', height: '100%' }} fullWidth>
                <InputLabel id={props.labelId} style={{ left: '14px', paddingTop: '2px' }}>
                    {label}
                </InputLabel>
                <Select {...props} sx={customStyle} MenuProps={{ disablePortal: true }}>
                    {options.map((option) => {
                        return (
                            <MenuItem value={option.value} key={option.value}>
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }

    const getOnlyUntaggedPostsSetting = () => {
        const onDeleteFunction = () => {
            dispatch(setShowUntaggedPosts(false))
        }

        const set: FilterSetting = {
            settingInput: (
                <Box display={'flex'} alignItems={'center'}>
                    <Switch
                        color="secondary"
                        checked={showUntaggedPosts}
                        onChange={(e) => {
                            dispatch(setShowUntaggedPosts(e.target.checked))
                            e.target.blur()
                        }}
                    />
                    <Typography variant="subtitle1">
                        <FormattedMessage id="filters.only-untagged-posts" />
                    </Typography>
                </Box>
            ),
            tooltip: 'filters.only-untagged-posts',
            icon: <Sell fontSize={'small'} />,
            currentValueAsString: showUntaggedPosts ? 'filters.only-untagged-posts' : 'general.off',
            deletable: true,
            onDeleteFunction: onDeleteFunction,
            hasChangedFromDefault: showUntaggedPosts,
        }
        return set
    }

    const getShowDarkPostsSetting = () => {
        const onDeleteFunction = () => {
            dispatch(setShowDarkPosts(false))
        }
        const set: FilterSetting = {
            settingInput: (
                <Box display={'flex'} alignItems={'center'}>
                    <Switch
                        color="secondary"
                        checked={showDarkPosts}
                        onChange={(e) => {
                            dispatch(setShowDarkPosts(e.target.checked))
                            e.target.blur()
                        }}
                    />
                    <Typography variant="subtitle1">
                        <FormattedMessage id="filters.show-dark-posts" />
                    </Typography>
                </Box>
            ),
            tooltip: 'filters.show-dark-posts',
            icon: <VisibilityOff fontSize={'small'} />,
            onDeleteFunction: onDeleteFunction,
            deletable: true,
            hasChangedFromDefault: showDarkPosts,
            currentValueAsString: showDarkPosts ? 'filters.show-dark-posts' : 'general.off',
        }
        return set
    }

    const getDatasourceSetting = () => {
        const defaultValue: string[] = []
        const hasChanged = !_.isEqual(_.sortBy(defaultValue), _.sortBy(selectedDataSourceIds))

        const handleChipClick = () => {
            setTimeout(() => handleDatasourceSelectionOpen(), 250)
        }
        const generateSettingString = () => {
            return selectedDataSourceIds.length === 1
                ? pagesForAnalyse.find((project) => project.id === selectedDataSourceIds[0])?.name
                : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selectedDataSourceIds.length })
        }
        const set: FilterSetting = {
            settingInput: (
                <DatasourceSelection
                    datasources={pagesForAnalyse}
                    fullWidth
                    handleOpen={handleDatasourceSelectionOpen}
                    handleClose={handleDatasourceSelectionClose}
                    isOpen={datasourceSelectionOpen}
                    initialIds={selectedDataSourceIds}
                    onDatasourceChange={(ids) => {
                        dispatch(selectDatasources(ids))
                    }}
                />
            ),
            tooltip: 'general.datasources',
            important: true,
            deletable: true,
            icon: <Web fontSize={'small'} />,
            currentValueAsString: generateSettingString(),
            onClickFunction: handleChipClick,
            hasChangedFromDefault: hasChanged,
            onDeleteFunction: () => {
                dispatch(selectDatasources([]))
            },
        }
        return set
    }

    const getTagFilterSetting = () => {
        const renderValueString = () => {
            if (selectedTags.length > 2) {
                return intl.formatMessage({ id: 'filters.xTagsSelected' }, { x: selectedTags.length })
            } else if (selectedTags.length > 0) {
                const tagNames = allTags.map((tag) => (selectedTags.includes(tag.id) ? tag.label : undefined)).filter((d) => d)
                return tagNames.join(', ')
            } else {
                return intl.formatMessage({ id: 'filters.no-tags' })
            }
        }
        const onDeleteFunction = () => {
            dispatch(setSelectedTags([]))
        }

        const handleChipClick = () => {
            setTimeout(() => {
                handleTagSelectionOpen()
            }, 250)
        }
        const hasChangedFromDefault = selectedTags.length > 0
        const set: FilterSetting = {
            settingInput: renderTagFilter(
                intl.formatMessage({ id: 'filters.tags' }),
                allTags,
                selectedTags,
                (newValue: (String | Tag | undefined)[]) => {
                    let values = Object.values(newValue)
                    let selectedTags: string[] = []

                    values.forEach((element) => {
                        // noinspection SuspiciousTypeOfGuard
                        if (!!element && typeof element !== 'string') {
                            // @ts-ignore

                            selectedTags.push(element.id)
                        }
                    })
                    dispatch(setSelectedTags(selectedTags))
                },
                true
            ),
            tooltip: 'filters.tags',
            onDeleteFunction,
            onClickFunction: handleChipClick,
            deletable: true,
            hasChangedFromDefault,
            currentValueAsString: renderValueString(),
        }
        return set
    }

    const getTagStateSetting = () => {
        const tagStateOptions = [
            {
                value: TagStatusFilterType.ALL,
                label: intl.formatMessage({ id: 'filters.all' }),
                selected: tagStatusSelection === TagStatusFilterType.ALL,
            },
            {
                value: TagStatusFilterType.ACTIVE,
                label: intl.formatMessage({ id: 'filters.active' }),
                selected: tagStatusSelection === TagStatusFilterType.ACTIVE,
            },
            {
                value: TagStatusFilterType.INACTIVE,
                label: intl.formatMessage({ id: 'filters.inactive' }),
                selected: tagStatusSelection === TagStatusFilterType.INACTIVE,
            },
        ]
        const hasChangedFromDefault = tagStateOptions[tagStatusSelection].value !== TagStatusFilterType.ALL

        const handleChipClick = () => {
            setTimeout(() => {
                handleTagStateOpen()
            }, 300)
        }
        const onDeleteFunction = () => {
            dispatch(setTagStatusSelection(0))
        }
        const set: FilterSetting = {
            settingInput: renderFilter(
                intl.formatMessage({ id: 'general.tag-status' }),
                tagStateOptions,
                (e: any) => {
                    dispatch(setTagStatusSelection(e.target.value))
                },
                undefined,
                handleTagStateOpen,
                handleTagStateClose,
                tagStateSettingOpen
            ),
            tooltip: 'general.tag-status',
            deletable: true,
            currentValueAsString: tagStateOptions[tagStatusSelection].label,
            icon: tagStateOptions[tagStatusSelection].value === TagStatusFilterType.ACTIVE ? <ToggleOn fontSize={'small'} /> : <ToggleOff fontSize={'small'} />,
            hasChangedFromDefault,
            onDeleteFunction,
            onClickFunction: handleChipClick,
        }
        return set
    }

    const getTimePeriodSetting = () => {
        const defaultStartTime = moment().subtract(30, 'days').format()
        const defaultEndTime = moment().format()
        const onDeleteFunction = () => {
            dispatch(changeTimePeriod({ startDate: defaultStartTime, endDate: defaultEndTime }))
        }

        const hasChangedFromDefault =
            moment(startDate).format('YYYY-MM-DD') !== moment(defaultStartTime).format('YYYY-MM-DD') ||
            moment(endDate).format('YYYY-MM-DD') !== moment(defaultEndTime).format('YYYY-MM-DD')

        const handleChipClick = () => {
            setTimeout(() => {
                handleDatePickerOpen()
            }, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <DateRangePicker
                    onOpen={handleDatePickerOpen}
                    onClose={handleDatePickerClose}
                    open={datePickerOpen}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(startDate, endDate) => {
                        dispatch(changeTimePeriod({ startDate, endDate }))
                    }}
                    variant={'outlined'}
                />
            ),
            tooltip: 'filters.timeperiod',
            icon: <CalendarToday fontSize={'small'} />,
            deletable: true,
            important: true,
            hasChangedFromDefault,
            onDeleteFunction,
            onClickFunction: handleChipClick,
            currentValueAsString: renderTimePeriodString(startDate, endDate, intl),
        }
        return set
    }

    const filterSettings = [
        getOnlyUntaggedPostsSetting(),
        getShowDarkPostsSetting(),
        getDatasourceSetting(),
        getTagFilterSetting(),
        getTagStateSetting(),
        getTimePeriodSetting(),
    ]

    const resultAmountDescription = () => {
        if (filteredPostsAmount < resultAmountSelection || resultAmountSelection === 0) {
            return intl.formatMessage({ id: 'filters.xResults' }, { x: filteredPostsAmount })
        } else {
            return intl.formatMessage(
                { id: 'filters.x-y-of-z-Results' },
                {
                    x: currentPage - 1 === 0 ? 1 : (currentPage - 1) * resultAmountSelection + 1,
                    y: currentPage * resultAmountSelection > filteredPostsAmount ? filteredPostsAmount : currentPage * resultAmountSelection,
                    z: filteredPostsAmount,
                }
            )
        }
    }

    const topBarFilters = () => {
        let amountOptions = [
            {
                value: 5,
                label: '5/' + intl.formatMessage({ id: 'general.page' }),
                selected: resultAmountSelection === 5,
            },
            {
                value: 10,
                label: '10/' + intl.formatMessage({ id: 'general.page' }),
                selected: resultAmountSelection === 10,
            },
            {
                value: 25,
                label: '25/' + intl.formatMessage({ id: 'general.page' }),
                selected: resultAmountSelection === 25,
            },
        ]

        return (
            <>
                <Grid item>
                    {filteredPostsAmount > 5 &&
                        renderFilter(
                            intl.formatMessage({ id: 'filters.amount-of-results' }),
                            amountOptions,
                            (e: any) => {
                                dispatch(setPageAmount(e.target.value))
                            },
                            {
                                height: '100%',
                                '& > .MuiSelect-select': {
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                },
                            }
                        )}
                </Grid>
                {/*Will be implemented later*/}
                {/*<Grid item>*/}
                {/*    {renderFilter(*/}
                {/*        intl.formatMessage({ id: 'filters.view' }),*/}
                {/*        [*/}
                {/*            { value: 'detail', label: 'Detailliert', selected: viewSelection === 'detail' },*/}
                {/*            { value: 'simple', label: 'Einfach', selected: viewSelection === 'simple' },*/}
                {/*        ],*/}
                {/*        (e: any) => {*/}
                {/*            setViewSelection(e.target.value)*/}
                {/*        }*/}
                {/*    )}*/}
                {/*</Grid>*/}
                <Grid item>
                    {renderFilter(
                        intl.formatMessage({ id: 'filters.sort' }),
                        [
                            {
                                value: PostSortType.DATE,
                                label: intl.formatMessage({ id: 'published_ts' }),
                                selected: sortField === PostSortType.DATE,
                            },
                            {
                                value: PostSortType.IMPRESSIONS,
                                label: intl.formatMessage({ id: 'facebook.impressions' }),
                                selected: sortField === PostSortType.IMPRESSIONS,
                            },
                            {
                                value: PostSortType.ENGAGEMENT,
                                label: intl.formatMessage({ id: 'facebook.engagement' }),
                                selected: sortField === PostSortType.ENGAGEMENT,
                            },
                        ],
                        (e: any) => {
                            dispatch(setSortField(e.target.value))
                        },
                        {
                            height: '100%',
                            '& > .MuiSelect-select': { paddingTop: '6px', paddingBottom: '6px' },
                        }
                    )}
                </Grid>
            </>
        )
    }

    const topBar = (
        <Box
            sx={{
                zIndex: 10,
                padding: '16px',
                py: 1,
            }}
        >
            <TitlebarWithFilters title={'facebook.post-insights'} filterBar={<FilterDrawer filters={filterSettings} />} disableXPadding />
            <ThemeProvider theme={createHmstrTheme(theme.palette.mode === 'light' ? 'dark' : 'dark')}>
                <Paper
                    style={{
                        zIndex: 11,
                        margin: '12px 0 0 0',
                        color: 'inherit',
                        backgroundColor: darkmode ? theme.palette.background.paper : '',
                    }}
                    elevation={0}
                >
                    <Grid container justifyContent={'space-between'}>
                        <Grid alignItems={'center'} display={'flex'} padding={'0 0 0 20px'} item>
                            <Typography variant="subtitle1" color={'#fff'}>
                                {resultAmountDescription()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} sx={{ padding: '2px 0' }}>
                                {topBarFilters()}
                                <Grid display={'flex'} item>
                                    <Divider orientation={'vertical'} variant={'middle'} flexItem />
                                </Grid>
                                <Grid alignItems={'center'} display={'flex'} padding={'0 20px 0 0'} item>
                                    <IconButton onClick={() => dispatch(setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'))}>
                                        <SwapVertIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </ThemeProvider>
        </Box>
    )

    return (
        <StyledMainElement drawerState={drawerState}>
            <Box
                ref={mainElementRef}
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >
                {topBar}
                <div style={{ padding: '0 16px', margin: '24px 0 24px 0' }}>
                    <Fallback condition={selectedDataSources.length === 0} messageId="datasource.no-datasources-selected" variant={'h6'}>
                        {isLoading ? (
                            <Box padding={4}>
                                <Loading />
                            </Box>
                        ) : (
                            <>
                                <PostCardsList posts={posts} tagFilter={tagStatusSelection} />
                                {resultAmountSelection !== 0 && filteredPostsAmount > 0 && (
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <Pagination
                                                count={Math.ceil(filteredPostsAmount / resultAmountSelection)}
                                                variant="outlined"
                                                shape="rounded"
                                                sx={{ margin: '20px 0 40px 0' }}
                                                onChange={handlePaginationChange}
                                                page={currentPage}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        )}
                        <Dialog open={Boolean(previewPost)} onClose={() => dispatch(hidePreview())}>
                            {(previewPost?.type === 'FACEBOOK' || previewPost?.type === 'DARK_POST') && <FacebookPostPreview post={previewPost} />}
                            {previewPost?.type === 'TIKTOK' && <TiktokPostPreview post={previewPost} />}
                        </Dialog>
                    </Fallback>
                </div>
            </Box>
        </StyledMainElement>
    )
}
