import * as _ from 'lodash'
import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { PublishingPostTypeSelect } from '../form-components/PublishingPostTypeSelect'
import { PublishingFileField } from '../PublishingFileField'
import { PublishingLinkField } from '../PublishingLinkField'
import { useFormState } from 'react-final-form'
import { PublishingFormValues, ValidDatasourceTypeForPublishing } from '../PublishingForm'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { IdMap } from '../../core/slices/DataSlice'
import moment from 'moment'
import { ConnectedDateTimePicker } from '../../form/ConnectedDateTimePicker'
import { useDispatch, useSelector } from 'react-redux'
import {
    getPostDialogSelectedNetwork,
    getRelevantStateForSelectedPostGroup,
    getSelectedPostGroup,
    getSelectedPostGroupIncludesPlannedByFacebook,
    getTiktokCreatorInfos,
} from '../PublishingSlice'
import { validationInTimeRange, validationRequired } from '../../form/validate'
import { fetchTiktokCreatorInfo } from '../PublishingActions'
import { HmstrDispatch } from '../../core/Store'
import { mergeTiktokCreatorInfos } from '../TiktokCreatorInfo'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { PublishingTextAreaComponent } from '../mentions/PublishingTextAreaComponent'
import { ConnectedTextField } from '../../form/ConnectedTextField'
import { PostFormPlatformSection } from './PostFormPlatformSection'
import { PostFormPlatformSpecificTabs } from '../PostFormPlatformSpecificTabs'
import { useIntl } from 'react-intl'

type CommonPostFormSectionProps = {
    disabled?: boolean
    datasourcesByType: IdMap<ProjectDatasource[]>
    channelType?: ValidDatasourceTypeForPublishing
}

export const CommonPostFormSection: FC<CommonPostFormSectionProps> = ({ disabled, datasourcesByType, channelType }) => {
    const prefix = channelType ? `postByType.${channelType}` : 'common_post'
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const formState = useFormState<PublishingFormValues>()
    const type = _.get(formState.values, `${prefix}.post_type`)
    const tiktokDatasources: ProjectDatasource[] = datasourcesByType['TIKTOK_ACCOUNT'] || []
    const currentPostGroup = useSelector(getSelectedPostGroup)
    const tiktokCreatorInfos = useSelector(getTiktokCreatorInfos)
    const mergedCreatorInfo = mergeTiktokCreatorInfos(tiktokDatasources.map((ds) => tiktokCreatorInfos[ds.id]).filter((ds) => !!ds))
    const selectedNetworks: ValidDatasourceTypeForPublishing[] = (channelType ? [channelType] : Object.keys(datasourcesByType || {})) as any
    const relevantPostState = useSelector(getRelevantStateForSelectedPostGroup)
    const isForFacebook = ['common_post', 'postByType.FACEBOOK_PAGE'].includes(prefix) && selectedNetworks.includes('FACEBOOK_PAGE')
    const planViaFacebook = isForFacebook && _.get(formState.values, `${prefix}.fb_plan_via_facebook`)
    const commonPostState = formState.values.common_post.state
    const selectedNetwork = useSelector(getPostDialogSelectedNetwork)
    const isCommonOrFacebookSection = !channelType || channelType === 'FACEBOOK_PAGE'
    const isDisabledDueToPlannedViaFacebook = relevantPostState === 'PLANNED_BY_FACEBOOK' && isCommonOrFacebookSection
    const disablePlatformTabs = relevantPostState === 'PLANNED_BY_FACEBOOK' && selectedNetwork === 'FACEBOOK_PAGE'
    const postGroupIncludesPlannedViaFacebook = useSelector(getSelectedPostGroupIncludesPlannedByFacebook)
    const isAlreadyPlannedOnFacebook = postGroupIncludesPlannedViaFacebook && commonPostState !== 'DRAFT'

    const onlyDatasourceType = channelType || Object.keys(datasourcesByType || {}).length === 1 ? selectedNetworks[0] : undefined

    useEffectWithIdComparison(() => {
        tiktokDatasources.forEach((ds) => {
            if (!Object.keys(tiktokCreatorInfos).includes(ds.id)) {
                dispatch(fetchTiktokCreatorInfo(ds))
            }
        })
    }, [tiktokDatasources.length])

    const getTextFieldDisabled = () => {
        if (relevantPostState === 'PLANNED_BY_FACEBOOK' && planViaFacebook) {
            return moment.utc(planViaFacebook).isBefore(moment()) ? true : disabled
        }

        return disabled
    }

    const minDateTime = planViaFacebook || !currentPostGroup ? moment().add(15, 'minute') : moment()
    const disableValidation = relevantPostState === 'PUBLISH_SUCCESS' || relevantPostState === 'PUBLISH_IN_PROGRESS' || isAlreadyPlannedOnFacebook

    return (
        <Grid container spacing={2} alignItems="center">
            {formState.values.common_post.state !== 'PUBLISH_NOW' && (
                <Grid item xs={6} alignSelf="flex-start">
                    <ConnectedDateTimePicker
                        required={true}
                        validate={(value: any) => validationRequired(value) || validationInTimeRange(value, intl, minDateTime)}
                        disabled={disabled || relevantPostState === 'PLANNED_BY_FACEBOOK'}
                        disableValidation={disableValidation}
                        minDateTime={minDateTime}
                        name="common_post.publish_time"
                        label="publishing.publish-time"
                        fullWidth
                    />
                </Grid>
            )}

            <Grid id="publishing_post_type_select" item alignSelf="flex-start" xs={formState.values.common_post.state !== 'PUBLISH_NOW' ? 6 : 12}>
                <PublishingPostTypeSelect
                    name={`${prefix}.post_type`}
                    disabled={disabled || isDisabledDueToPlannedViaFacebook}
                    datasourceKeys={selectedNetworks}
                    datasourceKeyOverride={channelType}
                />
            </Grid>

            {type && (
                <Grid item xs={12}>
                    <PublishingTextAreaComponent
                        prefix={prefix}
                        disabled={getTextFieldDisabled()}
                        disableValidation={commonPostState === 'DRAFT'}
                        datasourceKeyOverride={channelType || onlyDatasourceType}
                        hasAi={!formState.values.customize_posts_by_network || selectedNetwork === channelType}
                    />
                </Grid>
            )}

            {type === 'LINK' && (
                <Grid item xs={12}>
                    <PublishingLinkField
                        prefix={prefix}
                        disabled={disabled || isDisabledDueToPlannedViaFacebook}
                        datasourcesByType={datasourcesByType}
                        disableValidation={commonPostState === 'DRAFT'}
                        channelType={channelType}
                    />
                </Grid>
            )}

            {type === 'VIDEO' && selectedNetworks.includes('LINKED_IN') && (
                <Grid item xs={11}>
                    <ConnectedTextField name={`${prefix}.video_title`} label="publishing.video-title" disabled={disabled} />
                </Grid>
            )}

            {['IMAGE', 'VIDEO', 'MULTI_MEDIA'].includes(type) && (
                <Grid item xs={12}>
                    <PublishingFileField
                        disabled={disabled || isDisabledDueToPlannedViaFacebook}
                        name={`${prefix}.file_ids`}
                        dataSourceTypes={datasourcesByType}
                        publishingType={type}
                        disableValidation={commonPostState === 'DRAFT'}
                    />
                </Grid>
            )}

            {selectedNetworks.length > 0 && selectedNetwork && (
                <>
                    {selectedNetworks.length === 1 && type && channelType ? (
                        <PostFormPlatformSection
                            disabled={!!disabled || disablePlatformTabs}
                            prefix={prefix}
                            datasourceType={channelType}
                            postType={type}
                            tiktokCreatorInfo={mergedCreatorInfo}
                            datasources={datasourcesByType}
                        />
                    ) : (
                        <PostFormPlatformSpecificTabs
                            prefix={prefix}
                            postType={type}
                            selectedNetwork={selectedNetwork}
                            datasourceKeys={selectedNetworks}
                            disabled={!!disabled}
                            alreadyPlannedOnFacebook={relevantPostState === 'PLANNED_BY_FACEBOOK'}
                            tiktokCreatorInfo={mergedCreatorInfo}
                            datasources={datasourcesByType}
                        />
                    )}
                </>
            )}
        </Grid>
    )
}
