import * as _ from 'lodash'
import { FC, useState } from 'react'
import {
    Alert,
    Box,
    Card,
    CardContent,
    CircularProgress,
    DialogContentText,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedAccountProduct, getSelectedAccountSubscription } from '../../SettingsSlice'
import { Loading } from '../../../common/loading/Loading'
import { Info } from '@mui/icons-material'
import { getSelectedAccount, showSuccessSnackbar } from '../../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../../core/Store'
import { cancelSubscriptionUpdate } from '../../billing/BillingActions'
import { ConfirmDialog } from '../../../common/confirm-dialog/ConfirmDialog'

type AccountEditPackageFormProps = {}

export const AccountOverviewSubscription: FC<AccountEditPackageFormProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const account = useSelector(getSelectedAccount)
    const subscription = useSelector(getSelectedAccountSubscription)
    const selectedProduct = useSelector(getSelectedAccountProduct)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const onCancelSubscriptionUpdate = () => {
        if (subscription) {
            setShowLoading(true)
            dispatch(cancelSubscriptionUpdate(subscription)).then(() => {
                setShowConfirmDialog(false)
                setShowLoading(false)
                dispatch(showSuccessSnackbar('billing.downgrade-cancel-success'))
            })
        }
    }

    const hasScheduledUpdate = () => {
        if (!subscription || !subscription.schedule || typeof subscription.schedule === 'string') {
            return false
        }

        const lastSchedulePhase = _.last(subscription.schedule.phases) as any
        const lastPhaseIsActive = subscription.current_period_end === lastSchedulePhase.end_date
        return !lastPhaseIsActive
    }

    if (!subscription) {
        return <Loading />
    }

    const renderDescription = () => {
        if (subscription?.cancel_at_period_end) {
            return <FormattedMessage id="billing.cancel-account-details" values={{ date: moment.unix(subscription.cancel_at).format('L') }} />
        } else if (account.status === 'TRIALING') {
            return <FormattedMessage id="billing.subscription.end-of-trial-hint" />
        } else {
            return selectedProduct?.description
        }
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Typography color="text.secondary" gutterBottom>
                        <FormattedMessage id="billing.current-plan" />
                    </Typography>

                    <Typography variant="h6">
                        {selectedProduct?.name}{' '}
                        {subscription.status === 'trialing' && `(${intl.formatMessage({ id: 'billing.subscription.status.trialing' })})`}
                    </Typography>

                    <Typography color="text.secondary" gutterBottom>
                        {renderDescription()}
                    </Typography>

                    {account.status !== 'TRIALING' && hasScheduledUpdate() && (
                        <Box>
                            <Alert color="info" icon={<Info />}>
                                <FormattedMessage
                                    id="billing.downgrade-cancel-info"
                                    values={{
                                        endDate: moment.unix(subscription.current_period_end).format('L'),
                                        revertDowngradeLink: (
                                            <Link sx={{ cursor: 'pointer' }} onClick={() => setShowConfirmDialog(true)}>
                                                <FormattedMessage id="billing.downgrade-confirm" />
                                            </Link>
                                        ),
                                    }}
                                />
                            </Alert>
                        </Box>
                    )}

                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="billing.subscription.startdate" />
                                </TableCell>
                                <TableCell>{moment.unix(subscription.start_date).format('L')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {account.status === 'TRIALING' ? (
                                        <FormattedMessage id="billing.subscription.end-of-trial" />
                                    ) : (
                                        <FormattedMessage id="billing.subscription.next-payment-due" />
                                    )}
                                </TableCell>
                                <TableCell>{moment.unix(subscription.current_period_end).format('L')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="billing.subscription.state" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id={`billing.subscription.status.${subscription.status}`} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>

                <ConfirmDialog
                    open={showConfirmDialog}
                    onClose={() => setShowConfirmDialog(false)}
                    onConfirm={onCancelSubscriptionUpdate}
                    confirmText="billing.downgrade-confirm"
                    confirmIcon={showLoading ? <CircularProgress color="inherit" size={20} /> : undefined}
                    confirmButtonDisabled={showLoading}
                    title="billing.downgrade-cancel"
                >
                    <DialogContentText>
                        <FormattedMessage id="billing.downgrade-confirm-text" />
                    </DialogContentText>
                </ConfirmDialog>
            </Card>
        </Grid>
    )
}
