import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import * as React from 'react'
import { FC, Fragment, useEffect, useState } from 'react'
import { TypographyWithEllipsis } from '../../common/typography/TypographyWithEllipsis'
import { Tag as TagModel } from '../../tags/Tag'
import { TagsRow } from './Tags.styles'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTag, editTag } from '../../tags/TagsActions'
import DoneIcon from '@mui/icons-material/Done'
import { getInactiveColor, textColorBasedOnBackground } from '../../core/theme/helper'
import { TagColorPicker } from './components/TagColorPicker'
import { HmstrDispatch } from '../../core/Store'
import { isProjectAdmin } from '../../core/slices/CoreSlice'

type TagsListProps = {
    tag: TagModel
    allTags: TagModel[]
    selectedTags: string[]
    dividerBottom: boolean
    selectCallback: (id: string) => void
}

export const Tag: FC<TagsListProps> = ({ tag, allTags, selectedTags, dividerBottom = true, selectCallback }) => {
    const theme = useTheme()
    const dispatch = useDispatch<HmstrDispatch>()
    const isUserProjectAdmin = useSelector(isProjectAdmin)

    const [editModeEnabled, setEditModeEnabled] = useState(false)
    const [newLabelValue, setNewLabelValue] = useState(tag.label)
    const [baseColor, setBaseColor] = useState(tag.color)
    let textColor = textColorBasedOnBackground(baseColor)
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        setBaseColor(tag.active ? tag.color : getInactiveColor(tag.color))
    }, [tag])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Fragment key={tag.tag_id}>
            <TagsRow
                container
                alignItems="center"
                style={{
                    padding: '12px 0 8px 0',
                    height: 'auto',
                    borderBottom: dividerBottom ? `solid 1px ${theme.palette.divider}` : 'none',
                }}
            >
                <Grid item xs={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <Checkbox style={{ margin: '0 8px 0 8px' }} checked={selectedTags.includes(tag.id)} onChange={() => selectCallback(tag.id)} />
                        {editModeEnabled ? (
                            <TextField
                                fullWidth
                                value={newLabelValue}
                                onChange={(e) => {
                                    setNewLabelValue(e.target.value)
                                }}
                            />
                        ) : (
                            <TypographyWithEllipsis>{tag.label ?? ''}</TypographyWithEllipsis>
                        )}
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={!isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : ''}>
                        <span>
                            {tag.active && (
                                <IconButton
                                    disabled={!isUserProjectAdmin}
                                    sx={{ margin: '0 0 0 40px' }}
                                    onClick={() => {
                                        if (editModeEnabled) {
                                            dispatch(editTag({ tag: { ...tag, label: newLabelValue } }))
                                        }
                                        setEditModeEnabled((prevState) => !prevState)
                                    }}
                                >
                                    {editModeEnabled ? <DoneIcon /> : <EditIcon />}
                                </IconButton>
                            )}
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                    <div
                        style={{
                            backgroundColor: baseColor,
                            borderRadius: '4px',
                            padding: '4px 8px',
                            width: 'fit-content',
                            color: textColor,
                            opacity: tag.active ? '1.0' : '0.4',
                        }}
                    >
                        {tag.label ?? ''}
                    </div>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                    <Tooltip title={!isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : ''}>
                        <span>
                            {tag.active ? (
                                <TagColorPicker
                                    disabled={!isUserProjectAdmin}
                                    color={baseColor}
                                    changeCallback={(color) => {
                                        setBaseColor(color.hex)
                                    }}
                                    changeCompleteCallback={(color) => {
                                        dispatch(editTag({ tag: { ...tag, color: color.hex } }))
                                    }}
                                />
                            ) : (
                                <Typography variant={'body1'} sx={{ color: theme.palette.secondary.main }}>
                                    <FormattedMessage id="tags.inactive" />
                                </Typography>
                            )}
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                    <Typography>{tag.usage_count}</Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                    <Tooltip title={!isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : ''}>
                        <span>
                            <Switch
                                disabled={!isUserProjectAdmin}
                                color={tag.active ? 'warning' : 'primary'}
                                checked={tag.active}
                                onChange={(e) => {
                                    if (selectedTags.length === 0) {
                                        dispatch(editTag({ tag: { ...tag, active: e.target.checked } }))
                                    } else {
                                        const clone = [...selectedTags]
                                        if (!clone.includes(tag.id)) {
                                            selectCallback(tag.id)
                                            clone.push(tag.id)
                                        }
                                        clone.forEach((selectedTag) => {
                                            let tag = allTags.find((tag) => tag.id === selectedTag)
                                            if (tag) {
                                                dispatch(editTag({ tag: { ...tag, active: e.target.checked } }))
                                            }
                                        })
                                    }
                                }}
                            />
                        </span>
                    </Tooltip>
                </Grid>
                {/*<Grid item xs={2} display={'flex'} justifyContent={'center'}>*/}
                {/*    /!*TODO DISPLAY REAL NUMBER*!/*/}
                {/*    <Typography variant="body1">1234</Typography>*/}
                {/*</Grid>*/}
                <Grid item xs={1} display={'flex'} justifyContent={'center'}>
                    <Tooltip
                        placement="top"
                        arrow={true}
                        disableInteractive
                        title={
                            <Typography>
                                <FormattedMessage id={!isUserProjectAdmin ? 'general.disabled-ask-project-admin' : 'tag.delete-hint'} />
                            </Typography>
                        }
                    >
                        <span>
                            <IconButton onClick={handleClickOpen} disabled={!isUserProjectAdmin}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </TagsRow>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {selectedTags.length > 1 ? <FormattedMessage id="tags.delete-headline-multiple" /> : <FormattedMessage id="tags.delete-headline" />}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {selectedTags.length > 1 ? (
                            <FormattedMessage
                                id="tags.delete-description-multiple"
                                values={{
                                    b: (chunks: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => (
                                        <b style={{ color: '#ff6900' }}>{chunks}</b>
                                    ),
                                    amount: selectedTags.length,
                                }}
                            />
                        ) : (
                            <FormattedMessage id="tags.delete-description" />
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <FormattedMessage id="tags.cancel" />
                    </Button>
                    <Button
                        onClick={() => {
                            if (selectedTags.length === 0) {
                                dispatch(deleteTag(tag))
                            } else {
                                const clone = [...selectedTags]
                                if (!clone.includes(tag.id)) {
                                    selectCallback(tag.id)
                                    clone.push(tag.id)
                                }
                                clone.forEach((selectedTag) => {
                                    let tag = allTags.find((tag) => tag.id === selectedTag)
                                    if (tag) {
                                        dispatch(deleteTag(tag))
                                    }
                                })
                            }
                            handleClose()
                        }}
                        autoFocus
                    >
                        <FormattedMessage id="tags.delete" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
