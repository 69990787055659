import { Box } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

type DotProps = {
    color: string
    size?: number
}

export const Dot: FC<PropsWithChildren<DotProps>> = ({ color, children, size = 12 }) => {
    return (
        <Box
            width={`${size}px`}
            height={`${size}px`}
            borderRadius="50%"
            style={{ backgroundColor: color }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {children}
        </Box>
    )
}
