import * as React from 'react'
import { FC, useRef } from 'react'
import { TicketNode } from './TicketNode'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { Box, Button, CircularProgress, IconButton, keyframes, Tooltip, useTheme } from '@mui/material'
import { ChatBubble, Delete, MarkEmailRead, MarkEmailUnread, ThumbUp, Visibility, VisibilityOff } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import confetti from 'canvas-confetti'

type TicketNodeActionsProps = {
    canMarkAsRead: boolean
    node: TicketNode
    handleStateChange: (state: 'MARK_AS_READ' | 'MARK_AS_UNREAD') => void
    isPost: boolean
    isStory: boolean
    datasource: ProjectDatasource
    level: number
    removed: boolean
    canAnswerTicket: boolean
    handleAnswerClick: () => void
    canDeleteTicket: boolean
    handleDelete: () => void
    isHiding: boolean
    isOwnComment: boolean
    canHideTicket: boolean
    hidden: boolean
    handleHiddenChange: (action: 'HIDE' | 'SHOW') => void
    isLiking: boolean
    canLikeTicket: boolean
    handleLikeChange: (action: 'LIKE' | 'UNLIKE') => void
}

export const TicketNodeActions: FC<TicketNodeActionsProps> = ({
    canMarkAsRead,
    node,
    handleStateChange,
    isPost,
    isStory,
    datasource,
    level,
    removed,
    canAnswerTicket,
    handleAnswerClick,
    canDeleteTicket,
    handleDelete,
    isHiding,
    isOwnComment,
    canHideTicket,
    hidden,
    handleHiddenChange,
    isLiking,
    canLikeTicket,
    handleLikeChange,
}) => {
    const theme = useTheme()
    const likeButtonRef = useRef<any>(null)

    const likeAnimation = keyframes`
      0% {
        transform: scale(1) rotate(0deg);
      }
      10% {
        transform: scale(1.3) rotate(-10deg);
      }
      100% {
        transform: scale(1) rotate(0deg);
      }
    `

    const unLikeAnimation = keyframes`
      0% {
        transform: scale(1) rotate(0deg);
      }
      10% {
        transform: scale(1) rotate(-180deg);
      }
      50% {
        transform: scale(1.2) rotate(-180deg);
        color: unset;

      }
      60% {
        color: ${theme.palette.error.main};
        transform: scale(1) rotate(-180deg) translateY(-10px);
      }
      80% {
        transform: scale(1) rotate(-180deg) translateY(0px);
      }
      100% {
        transform: scale(1) rotate(0deg);
      }
    `

    const handleLikeClick = () => {
        if (likeButtonRef.current && !node.is_liked) {
            const x = likeButtonRef.current.getBoundingClientRect().x / window.innerWidth
            const y = likeButtonRef.current.getBoundingClientRect().y / window.innerHeight
            confetti({ origin: { x: x, y: y }, spread: 360, scalar: 0.5, startVelocity: 5, gravity: 0.4 })
        }
        handleLikeChange(node.is_liked ? 'UNLIKE' : 'LIKE')
    }

    return (
        <Box borderTop={1} borderColor="divider" mt={1} p={1} pb={0} display="flex" gap={1}>
            <Button
                disabled={!canMarkAsRead}
                color="inherit"
                size="small"
                startIcon={node.is_read ? <MarkEmailUnread color="action" fontSize="small" /> : <MarkEmailRead color="action" fontSize="small" />}
                onClick={() => handleStateChange(node.is_read ? 'MARK_AS_UNREAD' : 'MARK_AS_READ')}
            >
                {node.is_read ? <FormattedMessage id="engagement.mark-as-unread" /> : <FormattedMessage id="engagement.mark-as-read" />}
            </Button>
            <Box flexGrow={1} />
            {(isPost || isStory) && (
                <Tooltip title={datasource.type === 'LINKED_IN' && level > 0 ? <FormattedMessage id="engagement.linkedin.only-first-level" /> : ''}>
                    <span>
                        <IconButton
                            color="inherit"
                            disabled={removed || (datasource.type === 'LINKED_IN' && level > 0) || !canAnswerTicket}
                            size="small"
                            onClick={handleAnswerClick}
                        >
                            <ChatBubble color="action" fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
            {isPost && (
                <IconButton size="small" disabled={removed || !canDeleteTicket} onClick={handleDelete} color={'inherit'}>
                    <Delete color="action" fontSize="small" />
                </IconButton>
            )}
            {isPost && datasource.type !== 'LINKED_IN' && (
                <IconButton
                    size="small"
                    disabled={removed || isHiding || isOwnComment || !canHideTicket}
                    color={'inherit'}
                    onClick={() => handleHiddenChange(hidden ? 'SHOW' : 'HIDE')}
                >
                    {isHiding ? (
                        <CircularProgress color="inherit" size={18} />
                    ) : hidden ? (
                        <Visibility color="action" fontSize="small" />
                    ) : (
                        <VisibilityOff color="action" fontSize="small" />
                    )}
                </IconButton>
            )}
            {((isPost && datasource.type !== 'INSTAGRAM_ACCOUNT') || isStory) && !removed && canLikeTicket && (
                <IconButton
                    size="small"
                    disabled={removed || isLiking || !canLikeTicket}
                    color={'inherit'}
                    sx={{
                        textTransform: 'lowercase',
                        color:
                            (node.is_liked && !isLiking) || (!node.is_liked && isLiking)
                                ? theme.palette.info.main + ' !important'
                                : theme.palette.action.active + ' !important',
                    }}
                    onClick={() => handleLikeClick()}
                >
                    <ThumbUp
                        sx={{
                            animation: !node.is_liked && isLiking ? `${likeAnimation} 1s 1` : isLiking && node.is_liked ? `${unLikeAnimation} 1s 1` : undefined,
                        }}
                        color={(node.is_liked && !isLiking) || (!node.is_liked && isLiking) ? 'info' : 'action'}
                        fontSize="small"
                        ref={likeButtonRef}
                    />
                </IconButton>
            )}
        </Box>
    )
}
