import * as _ from 'lodash'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { Dashboard } from './Dashboard'
import { fetchDashboardsForProject, fetchDataForSelectedDashboard, fetchTemplates, generateDashboardPdf, generateDashboardPptx } from './DashboardsActions'
import { HmstrState } from '../core/Store'
import { DashboardTemplate } from './DashboardTemplate'
import { getDashboardsData, getTemplates } from '../core/slices/DataSlice'
import moment from 'moment'
import { ProjectDatasource, ProjectDatasourceType } from '../settings/datasources/ProjectDatasource'
import { getDatasources, getSelectedProjectId } from '../core/slices/CoreSlice'
import { defaultMonthlyPeriod } from './TemplateDefaultTimePeriods'
import { createOrUpdateFacebookMonthlyReport, fetchFacebookMonthlyReport } from '../templates/monthly-report/MonthlyReportActions'
import { FacebookMonthlyReport } from '../templates/monthly-report/FacebookMonthlyReport'
import { DashboardSortType } from '../common/filter/DashboardSortType'
import { generateEffectiveTimePeriodForDashboard } from './generateEffectiveTimePeriodForDashboard'
import { DashboardData } from './DashboardData'
import { Path } from 'path-parser'

interface DashboardState {
    selectedDashboardId?: string
    selectedDatasourceIds: string[]
    startDate: string
    endDate: string
    compareStartDate?: string
    compareEndDate?: string
    attributionWindows?: ('1d_click' | '7d_click' | '1d_view')[]
    estimateWithoutConsentLoss?: boolean
    selectedTags?: string[]

    isLoadingDashboards: boolean
    isLoadingTemplates: boolean
    isDashboardAccessDenied: boolean
    dashboardDataLoading: boolean
    dashboardDataLoadingInBackground: boolean
    searchTerm: string
    sortField: DashboardSortType
    sortDirection: 'asc' | 'desc'
    openDialog?: 'new' | 'edit'
    filteredDatasourceIds: string[]
    monthlyReport?: FacebookMonthlyReport
    sendDashboardDialogOpen: boolean
    runningExports: { dashboardId: string; type: 'pdf' | 'pptx' }[]
    dashboardWizardStep: number
    includeDarkPosts: boolean
    dashboardData: DashboardData
}

const urlParams = new URLSearchParams(window.location.search)
const dashboardPath = '/portal/:projectId/analytics/dashboards/:dashboardId'
const path = new Path<{ projectId: string; dashboardId: string }>(dashboardPath)
const pathVariables = path.partialTest(window.location.pathname)

const selectedDatasourceIdsFromParams = urlParams.get('selected_datasources')
const attributionWindowsFromParams = urlParams.get('attributionWindows')
const selectedTagsFromParams = urlParams.get('selected_tags')
const initialState: DashboardState = {
    selectedDashboardId: pathVariables ? pathVariables.dashboardId : undefined,
    attributionWindows:
        attributionWindowsFromParams !== null ? (attributionWindowsFromParams.split(',') as ('1d_click' | '7d_click' | '1d_view')[]) : undefined,
    estimateWithoutConsentLoss: urlParams.get('estimateWithoutConsentLoss') !== null ? JSON.parse(urlParams.get('estimateWithoutConsentLoss')!) : undefined,
    startDate: urlParams.get('startDate') || defaultMonthlyPeriod.startDate,
    endDate: urlParams.get('endDate') || defaultMonthlyPeriod.endDate,
    compareStartDate: urlParams.get('compareStartDate') || undefined,
    compareEndDate: urlParams.get('compareEndDate') || undefined,
    selectedDatasourceIds: selectedDatasourceIdsFromParams !== null ? selectedDatasourceIdsFromParams.split(',') : [],

    dashboardDataLoading: true,
    isDashboardAccessDenied: false,
    dashboardDataLoadingInBackground: false,
    isLoadingDashboards: true,
    isLoadingTemplates: true,
    searchTerm: '',
    sortField: DashboardSortType.NAME,
    sortDirection: 'desc',
    filteredDatasourceIds: [],
    sendDashboardDialogOpen: false,
    runningExports: [],
    selectedTags: selectedTagsFromParams !== null ? selectedTagsFromParams.split(',') : [],
    dashboardWizardStep: 0,
    includeDarkPosts: false,
    dashboardData: [],
}

export const DashboardsSlice = createSlice({
    name: 'dashboards',
    initialState,
    reducers: {
        changeAttributionWindows: (state, action: PayloadAction<('1d_click' | '7d_click' | '1d_view')[]>) => {
            state.attributionWindows = action.payload
        },
        setSelectedDashboardTags: (state, action: PayloadAction<string[]>) => {
            state.selectedTags = action.payload
        },
        selectDatasourcesDashboard: (state, action: PayloadAction<string[]>) => {
            state.selectedDatasourceIds = action.payload
        },
        changeSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload
        },
        setSortField: (state, action: PayloadAction<DashboardSortType>) => {
            state.sortField = action.payload
        },
        setSortDirection: (state, action: PayloadAction<'desc' | 'asc'>) => {
            state.sortDirection = action.payload
        },
        selectDashboard: (state, action: PayloadAction<Dashboard | undefined>) => {
            state.selectedDashboardId = action.payload?.id
            state.isDashboardAccessDenied = false
            if (action.payload === undefined) {
                state.selectedDatasourceIds = []
            } else {
                const dashboard = action.payload
                const effectiveTimePeriod = generateEffectiveTimePeriodForDashboard(dashboard)

                state.startDate = effectiveTimePeriod.startDate
                state.endDate = effectiveTimePeriod.endDate
                state.compareStartDate = effectiveTimePeriod.compareStartDate
                state.compareEndDate = effectiveTimePeriod.compareEndDate
                state.attributionWindows = dashboard.settings.attribution_windows
                state.selectedTags = dashboard.settings.tag_ids
                state.estimateWithoutConsentLoss = !!dashboard.settings.conversions_with_consent_loss
                state.selectedDatasourceIds = dashboard.data_source_ids
            }
        },
        cleanUpDashboardUrlParams: (state, action: PayloadAction<Dashboard>) => {
            const effectiveTimePeriod = generateEffectiveTimePeriodForDashboard(action.payload)
            if (!state.startDate) state.startDate = effectiveTimePeriod.startDate
            if (!state.endDate) state.endDate = effectiveTimePeriod.endDate
            if (!state.compareStartDate) state.compareStartDate = effectiveTimePeriod.compareStartDate
            if (!state.compareEndDate) state.compareEndDate = effectiveTimePeriod.compareEndDate
            if (state.selectedDatasourceIds.length <= 0) state.selectedDatasourceIds = action.payload.data_source_ids
            if (!state.attributionWindows) state.attributionWindows = action.payload.settings.attribution_windows
            if (!state.estimateWithoutConsentLoss) state.estimateWithoutConsentLoss = !!action.payload.settings.conversions_with_consent_loss
            if (!state.selectedTags) state.selectedTags = action.payload.settings.tag_ids || []
        },
        changeTimePeriod: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
            state.startDate = moment(action.payload.startDate).utcOffset(0, true).format('YYYY-MM-DD')
            state.endDate = moment(action.payload.endDate).utcOffset(0, true).format('YYYY-MM-DD')
        },
        changeCompareTimePeriod: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
            state.compareStartDate = moment(action.payload.startDate).format('YYYY-MM-DD')
            state.compareEndDate = moment(action.payload.endDate).format('YYYY-MM-DD')
        },
        changeEstimateWithoutConsentLoss: (state, action: PayloadAction<boolean>) => {
            state.estimateWithoutConsentLoss = action.payload
        },
        openSendDashboardDialog: (state) => {
            state.sendDashboardDialogOpen = true
        },
        closeSendDashboardDialog: (state) => {
            state.sendDashboardDialogOpen = false
        },
        setDashboardWizardStep: (state, action: PayloadAction<number>) => {
            state.dashboardWizardStep = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardsForProject.pending, (state) => {
                state.isLoadingDashboards = true
            })
            .addCase(fetchDashboardsForProject.fulfilled, (state) => {
                state.isLoadingDashboards = false
            })
            .addCase(fetchDashboardsForProject.rejected, (state) => {
                state.isLoadingDashboards = false
            })
            .addCase(fetchFacebookMonthlyReport.fulfilled, (state, action) => {
                state.monthlyReport = action.payload
            })
            .addCase(createOrUpdateFacebookMonthlyReport.fulfilled, (state, action) => {
                state.monthlyReport = action.payload
            })
            .addCase(generateDashboardPdf.fulfilled, (state, action) => {
                state.runningExports = state.runningExports.filter(
                    (runningExport) => runningExport.dashboardId !== action.payload.dashboard.id && runningExport.type !== 'pdf'
                )
            })
            .addCase(generateDashboardPdf.pending, (state, action) => {
                state.runningExports.push({
                    dashboardId: action.meta.arg.dashboard.id,
                    type: 'pdf',
                })
            })
            .addCase(generateDashboardPptx.fulfilled, (state, action) => {
                state.runningExports = state.runningExports.filter(
                    (runningExport) => runningExport.dashboardId !== action.payload.dashboard.id && runningExport.type !== 'pptx'
                )
            })
            .addCase(generateDashboardPptx.pending, (state, action) => {
                state.runningExports.push({
                    dashboardId: action.meta.arg.dashboard.id,
                    type: 'pptx',
                })
            })
            .addCase(fetchDataForSelectedDashboard.fulfilled, (state, action) => {
                state.dashboardData = action.payload
                state.dashboardDataLoading = false
                state.isDashboardAccessDenied = false
                state.dashboardDataLoadingInBackground = false
            })
            .addCase(fetchDataForSelectedDashboard.pending, (state, action) => {
                if (action.meta.arg.reloadDashboard) {
                    state.dashboardDataLoading = true
                    state.isDashboardAccessDenied = true
                } else {
                    state.dashboardDataLoadingInBackground = true
                }
            })
            .addCase(fetchDataForSelectedDashboard.rejected, (state, action) => {
                state.dashboardDataLoading = false
                state.dashboardDataLoadingInBackground = false
                if (action.payload) {
                    if ((action.payload as any).statusCode === 403) {
                        state.isDashboardAccessDenied = true
                    }
                }
            })
            .addCase('core/selectProject', (state) => {
                state.selectedDashboardId = undefined
            })
            .addCase(fetchTemplates.pending, (state) => {
                state.isLoadingTemplates = true
            })
            .addCase(fetchTemplates.fulfilled, (state) => {
                state.isLoadingTemplates = false
            })
            .addCase(fetchTemplates.rejected, (state) => {
                state.isLoadingTemplates = false
            })
    },
})

export const getSelectedDashboard: Selector<HmstrState, Dashboard | undefined> = (state) => state.data.dashboards[state.dashboards.selectedDashboardId || '']
export const getSelectedDashboardDatasourceIds: Selector<HmstrState, DashboardState['selectedDatasourceIds']> = (state) =>
    state.dashboards.selectedDatasourceIds
export const getSelectedDashboardStartDate: Selector<HmstrState, DashboardState['startDate']> = (state) => state.dashboards.startDate
export const getSelectedDashboardEndDate: Selector<HmstrState, DashboardState['endDate']> = (state) => state.dashboards.endDate
export const getSelectedDashboardCompareStartDate: Selector<HmstrState, DashboardState['compareStartDate']> = (state) => state.dashboards.compareStartDate
export const getSelectedDashboardCompareEndDate: Selector<HmstrState, DashboardState['compareEndDate']> = (state) => state.dashboards.compareEndDate
export const getSelectedDashboardTags: Selector<HmstrState, DashboardState['selectedTags']> = (state) => state.dashboards.selectedTags
export const getSelectedDashboardAttributionWindows: Selector<HmstrState, DashboardState['attributionWindows']> = (state) => state.dashboards.attributionWindows
export const getSelectedDashboardEstimateWithoutConsentLoss: Selector<HmstrState, DashboardState['estimateWithoutConsentLoss']> = (state) =>
    state.dashboards.estimateWithoutConsentLoss

export const getSortDirection: Selector<HmstrState, 'asc' | 'desc'> = (state) => state.dashboards.sortDirection
export const getSortField: Selector<HmstrState, DashboardSortType> = (state) => state.dashboards.sortField
export const getIsLoadingDashboards: Selector<HmstrState, boolean> = (state) => state.dashboards.isLoadingDashboards
export const getIsLoadingTemplates: Selector<HmstrState, boolean> = (state) => state.dashboards.isLoadingTemplates
export const getDashboardData: Selector<HmstrState, DashboardData> = (state) => state.dashboards.dashboardData
export const getRunningExports: Selector<HmstrState, { dashboardId: string; type: 'pdf' | 'pptx' }[]> = (state) => state.dashboards.runningExports
export const isDashboardDataLoading: Selector<HmstrState, boolean> = (state) => state.dashboards.dashboardDataLoading
export const isDashboardDataLoadingInBackground: Selector<HmstrState, boolean> = (state) => state.dashboards.dashboardDataLoadingInBackground
export const getFilteredDatasourceIds: Selector<HmstrState, string[]> = (state) => state.dashboards.filteredDatasourceIds
export const getFacebookMonthlyReport: Selector<HmstrState, FacebookMonthlyReport | undefined> = (state) => state.dashboards.monthlyReport
export const getSendDashboardDialogOpen: Selector<HmstrState, boolean> = (state) => state.dashboards.sendDashboardDialogOpen
export const getDashboardWizardStep: Selector<HmstrState, number> = (state) => state.dashboards.dashboardWizardStep
export const getDatasourcesForSelectedDashboard = createSelector([getSelectedDashboard, getDatasources], (dashboard, datasources) => {
    if (!dashboard) {
        return []
    }

    const datasourcesById = _.mapKeys(datasources, 'id')
    return dashboard.data_source_ids.map((dsid) => datasourcesById[dsid]).filter((ds) => ds !== undefined)
})

export const getTemplateOfSelectedDashboard: Selector<HmstrState, DashboardTemplate | undefined> = createSelector(
    [getSelectedDashboard, getTemplates],
    (selectedDashboard, templates) => {
        if (!selectedDashboard) {
            return undefined
        }

        return templates[selectedDashboard.template_id]
    }
)

const getDatasourcesByTypeForSelectedDashboard = (
    selectedDashboard: Dashboard | undefined,
    datasources: ProjectDatasource[],
    datasourceType: ProjectDatasourceType
): ProjectDatasource[] => {
    if (!selectedDashboard) {
        return []
    }

    const datasourcesById = _.mapKeys(datasources, 'id')
    return selectedDashboard.data_source_ids.map((dsid) => datasourcesById[dsid]).filter((ds) => ds && ds.type === datasourceType)
}

export const getFbPagesForSelectedDashboard = createSelector([getSelectedDashboard, getDatasources], (selectedDashboard, datasources) => {
    return getDatasourcesByTypeForSelectedDashboard(selectedDashboard, datasources, 'FACEBOOK_PAGE')
})
createSelector([getFbPagesForSelectedDashboard, getFilteredDatasourceIds], (fbPages, datasourceIds) => {
    if (datasourceIds.length === 0) {
        return fbPages
    }

    return fbPages.filter((page) => datasourceIds.includes(page.id))
})
export const getFirstPageForSelectedDashboard = createSelector([getFbPagesForSelectedDashboard], (pages) => pages[0])

export const getFbAdAccountsForSelectedDashboard = createSelector([getSelectedDashboard, getDatasources], (selectedDashboard, datasources) => {
    return getDatasourcesByTypeForSelectedDashboard(selectedDashboard, datasources, 'FACEBOOK_AD_ACCOUNT')
})

export const getIsPeriodStartDateAndEndateEqualsCompareDate = createSelector(
    [getSelectedDashboardStartDate, getSelectedDashboardEndDate, getSelectedDashboardCompareStartDate, getSelectedDashboardCompareEndDate],
    (periodStart, periodEnd, compareStart, compareEnd) => {
        return moment(periodStart).isSame(moment(compareStart), 'D') && moment(periodEnd).isSame(moment(compareEnd), 'D')
    }
)

export const getDashboardsForSelectedProject = createSelector([getDashboardsData, getSelectedProjectId], (dashboards, projectId) =>
    dashboards.filter((d) => d.project_id === projectId)
)

export const getDashboardAccessDenied: Selector<HmstrState, boolean> = (state) => state.dashboards.isDashboardAccessDenied

export const getSortedDashboards = createSelector(
    [getDashboardsForSelectedProject, getTemplates, getSortField, getSortDirection],
    (dashboards, templates, sortField, sortDirection) => {
        return dashboards.sort((a, b) => {
            let res: number = 0
            switch (sortField) {
                case DashboardSortType.NAME:
                    res = a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    break
                case DashboardSortType.AUTHOR:
                    res = a.created_by.toLowerCase().localeCompare(b.created_by.toLowerCase())
                    break
                case DashboardSortType.TEMPLATE_TYPE:
                    const tempA = templates[a.template_id]
                    const tempB = templates[b.template_id]
                    res = tempA.name.toLowerCase().localeCompare(tempB.name.toLowerCase())
                    break
                case DashboardSortType.CREATION_DATE:
                    res = a.created_at.localeCompare(b.created_at)
                    break
                case DashboardSortType.DATE_OF_CHANGE:
                    res = a.updated_at.localeCompare(b.updated_at)
                    break
            }
            return sortDirection === 'asc' ? res : res * -1
        })
    }
)

export const {
    setSortDirection,
    setSortField,
    selectDashboard,
    changeTimePeriod,
    changeCompareTimePeriod,
    changeAttributionWindows,
    changeEstimateWithoutConsentLoss,
    openSendDashboardDialog,
    closeSendDashboardDialog,
    setSelectedDashboardTags,
    selectDatasourcesDashboard,
    setDashboardWizardStep,
    cleanUpDashboardUrlParams,
} = DashboardsSlice.actions

export const DashboardsReducer = DashboardsSlice.reducer
