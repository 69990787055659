import { Box, styled } from '@mui/material'

export const ResultsListBox = styled(Box)(({ theme }) => ({
    '& li': {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}))

export const ResultTooltipBox = styled(Box)(() => ({
    color: '#fff',
    padding: '4px 8px',
    fontSize: '0.625rem',
    maxWidth: 300,
    borderRadius: '4px',
    backgroundColor: 'rgba(97, 97, 97, 0.9)',
}))

export const GraphBox = styled(Box)(({ theme }) => ({
    transitionProperty: 'height',
    transitionDuration: '.2s',
    transitionTimingFunction: 'ease-in-out',
    padding: theme.spacing(1),
    width: '100%',
}))
