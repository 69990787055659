import { createAsyncThunk } from '@reduxjs/toolkit'
import { PostGroup, PostGroupApproval } from './PostGroup'
import { HmstrState } from '../../core/Store'
import moment from 'moment'
import { doDelete, doGet, doPost, doPut } from '../../core/api/ApiClient'
import { Project } from '../../settings/project-management/Project'
import { PostGroupRequest } from './PostGroupRequest'
import { PostGroupActivity } from './PostGroupActivity'

const POST_GROUPS_SLASH = (action: string) => `postGroups/${action}`

type fetchPostGroupsArgs = {
    project: Project
    data_source_ids?: string[]
    since?: string
    until?: string
}

export const fetchPostGroups = createAsyncThunk<PostGroup[], fetchPostGroupsArgs>(POST_GROUPS_SLASH('fetchPostGroups'), async (args, thunkAPI) => {
    const { since, until, project, data_source_ids } = args

    const startDate = moment(since).startOf('day').format()
    const endDate = moment(until).endOf('day').format()

    return await doGet(thunkAPI, project._links.post_groups, undefined, {
        params: { since: startDate, until: endDate, data_source_ids },
    })
})
export const fetchPostGroupById = createAsyncThunk<PostGroup, string>(POST_GROUPS_SLASH('fetchPostGroupById'), async (groupId, thunkAPI) => {
    const state = thunkAPI.getState() as HmstrState
    const project = state.data.projects[state.core.selectedProjectId || '']

    return await doGet(thunkAPI, project._links.post_groups.href + `/${groupId}`)
})

export const createPostGroup = createAsyncThunk<PostGroup, { request: PostGroupRequest; project: Project }>(
    POST_GROUPS_SLASH('createPostGroup'),
    async ({ request, project }, thunkAPI) => {
        return await doPost(thunkAPI, project._links.post_groups, request)
    }
)

export const updatePostGroup = createAsyncThunk<PostGroup, { request: PostGroupRequest; postGroup: PostGroup }>(
    POST_GROUPS_SLASH('updatePostGroup'),
    async ({ request, postGroup }, thunkAPI) => {
        return await doPut(thunkAPI, postGroup._links.self, request)
    }
)

export const deletePostGroup = createAsyncThunk<string, PostGroup>(POST_GROUPS_SLASH('deletePostGroup'), async (postGroup, thunkAPI) => {
    const deleteResponse = await doDelete(thunkAPI, postGroup._links.self)
    if (deleteResponse !== true && deleteResponse.payload.status === 403) {
        return deleteResponse
    }
    return postGroup.id
})

export type RequestApprovalArgs = {
    postGroup: PostGroup
    request: {
        external?: boolean
        assignee_ids: string[]
        message: string
        type: PostGroupApproval['type']
    }
}

export const requestPostGroupApproval = createAsyncThunk<PostGroup, RequestApprovalArgs>(
    POST_GROUPS_SLASH('requestPostGroupApproval'),
    async ({ postGroup, request }, thunkAPI) => {
        const link = request.external ? postGroup._links.external_approval : postGroup._links.internal_approval
        return await doPost(thunkAPI, link, request)
    }
)

export type UpdateApprovalArgs = {
    postGroup: PostGroup
    request: {
        external?: boolean
        state: 'APPROVED' | 'REWORK'
        message: string
    }
}

export const updatePostGroupApproval = createAsyncThunk<PostGroup, UpdateApprovalArgs>(
    POST_GROUPS_SLASH('updatePostGroupApproval'),
    async ({ postGroup, request }, thunkAPI) => {
        const link = request.external ? postGroup._links.external_approval : postGroup._links.internal_approval
        return await doPut(thunkAPI, link, request)
    }
)

export type DeleteApprovalArgs = {
    postGroup: PostGroup
    request: {
        external?: boolean
    }
}

export const deletePostGroupApproval = createAsyncThunk<PostGroup, DeleteApprovalArgs>(
    POST_GROUPS_SLASH('deletePostGroupApproval'),
    async ({ postGroup, request }, thunkAPI) => {
        const link = request.external ? postGroup._links.external_approval : postGroup._links.internal_approval
        return await doDelete(thunkAPI, link)
    }
)

export const fetchPostGroupActivities = createAsyncThunk<PostGroupActivity[], PostGroup>(
    POST_GROUPS_SLASH('fetchPostGroupActivities'),
    async (postGroup, thunkAPI) => {
        return await doGet(thunkAPI, postGroup._links.activities)
    }
)

export const createNoteForPostGroup = createAsyncThunk<
    PostGroupActivity,
    {
        postGroup: PostGroup
        noteRequest: { message: string; file?: any; external?: boolean }
    }
>(POST_GROUPS_SLASH('createNoteForPostGroup'), async ({ postGroup, noteRequest }, thunkAPI) => {
    return await doPost(thunkAPI, postGroup._links.activities, noteRequest)
})
