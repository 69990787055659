import * as _ from 'lodash'
import axios, { AxiosResponse } from 'axios'
import { User } from '../../settings/user-management/User'
import { Account } from '../../settings/accounts/Account'
import { ApiEntryPoint } from '../api/ApiEntryPoint'
import { IdMap } from '../slices/DataSlice'
import { Path } from 'path-parser'
import { Project } from '../../settings/project-management/Project'
import { Product } from '../../settings/accounts/Product'
import { CoreState } from '../slices/CoreSlice'

export interface InitData {
    currentUser?: User
    currentUserPermissions?: CoreState['currentUserPermissions']
    accounts?: IdMap<Account>
    projects?: IdMap<Project>
    products?: IdMap<Product>
    selectedProjectId?: string
    currentModule?: string
    currentPage?: string
}

export const loadInitialData = (apiEntryPoint: ApiEntryPoint, currentUser: User): Promise<InitData> => {
    return new Promise<InitData>((resolve) => {
        // (!) Do not redirect for identity provider callbacks. Data fetching is also unnecessary.
        if (window.location.pathname.includes('/account-link-success') || window.location.pathname.includes('/account-link-error')) {
            resolve({})
        }

        const requests: Promise<AxiosResponse>[] = [
            axios.get<Account[]>(apiEntryPoint._links.accounts.href),
            axios.get<Project[]>(apiEntryPoint._links.projects.href),
            axios.get<CoreState['currentUserPermissions']>(currentUser._links.permissions.href),
        ]

        Promise.all(requests).then((responses) => {
            const accounts: IdMap<Account> = _.mapKeys(responses[0].data, 'id')
            const projects: IdMap<Project> = _.mapKeys(responses[1].data, 'id')
            const currentUserPermissions: CoreState['currentUserPermissions'] = responses[2].data

            let selectedProjectId: string | undefined

            if (Object.values(accounts).length > 0 && Object.values(projects).length > 0) {
                const modules = ['publishing', 'analytics', 'engagement', 'settings', 'home']
                const path = new Path<{ projectId: string; splat: string }>('/portal/:projectId*splat')
                const pathVariables = path.partialTest(window.location.pathname)

                if (pathVariables) {
                    if (modules.includes(pathVariables.projectId)) {
                        pathVariables.splat = '/' + pathVariables.projectId + pathVariables.splat
                        pathVariables.projectId = ''
                    } else if (pathVariables.projectId === 'dashboards') {
                        pathVariables.splat = '/analytics/dashboards'
                        pathVariables.projectId = ''
                    }
                }

                if (pathVariables && projects[pathVariables.projectId]) {
                    selectedProjectId = pathVariables.projectId
                } else {
                    selectedProjectId = localStorage.getItem('hmstr.selectedProjectId') || Object.values(projects)[0].id

                    const project = projects[selectedProjectId]

                    if (!project || !Object.keys(accounts).includes(project.account_id)) {
                        const projectArray = Object.values(projects)

                        for (let i = 0; i < projectArray.length; i++) {
                            if (Object.keys(accounts).includes(projectArray[i].account_id)) {
                                selectedProjectId = projectArray[i].id
                                break
                            }
                        }
                    }
                }

                let selectedProject: Project | undefined = projects[selectedProjectId || '']

                if (!selectedProject) {
                    selectedProjectId = undefined
                    localStorage.removeItem('hmstr.selectedProjectId')
                } else if (selectedProjectId) {
                    localStorage.setItem('hmstr.selectedProjectId', selectedProjectId)

                    const proto = window.location.protocol
                    const host = window.location.host

                    window.history.replaceState(
                        null,
                        '',
                        `${proto}//${host}${path.build({
                            projectId: selectedProjectId,
                            splat: pathVariables?.splat || '/',
                        })}${window.location.search}`
                    )
                }
            }

            const modulePath = new Path<{ projectId: string; module: string; splat: string }>('/portal/:projectId/:module/*splat')
            const modulePathVariables = modulePath.partialTest(window.location.pathname)

            const initData = {
                currentUser: currentUser,
                currentUserPermissions: currentUserPermissions,
                accounts: accounts,
                projects: projects,
                products: {},
                selectedProjectId: selectedProjectId,
                currentModule: modulePathVariables?.module,
                currentPage: '/' + modulePathVariables?.splat,
            }

            if (!selectedProjectId) {
                axios.get<Product[]>(apiEntryPoint._links.products.href).then((response) => {
                    initData.products = _.mapKeys(response.data, 'id')
                    resolve(initData)
                })
            } else {
                resolve(initData)
            }
        })
    })
}
