export const IdentityProviderScopes = {
    LINKED_IN: [
        'r_basicprofile',
        'r_emailaddress',
        'r_ads',
        'r_ads_reporting',
        'rw_organization_admin',
        'r_organization_social',
        'w_organization_social',
        'w_member_social',
    ],
    META: [
        'email',
        'pages_read_engagement',
        'read_insights',
        'ads_read',
        'ads_management',
        'pages_show_list',
        'pages_read_user_content',
        'instagram_basic',
        'instagram_manage_insights',
        'instagram_content_publish',
        'instagram_manage_comments',
        'instagram_manage_messages',
        'pages_manage_posts',
        'pages_manage_metadata',
        'pages_messaging',
        'pages_manage_engagement',
        'business_management',
    ],
    TIKTOK: ['user.info.basic', 'user.info.profile', 'user.info.stats', 'video.list', 'video.publish', 'video.upload'],
    TIKTOK_BUSINESS: [],
    TWITTER: [],
}

export const getMissingScopes = (platform: keyof typeof IdentityProviderScopes, scopes: string[]) => {
    const neededScopes = IdentityProviderScopes[platform]
    return neededScopes.filter((x) => !scopes.includes(x))
}
