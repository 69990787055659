import { FC, useState } from 'react'
import { Box, Button, CircularProgress, Container, Tooltip, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedAccount, getSelectedProject, isProjectAdmin, showSuccessSnackbar, getCurrentUser } from '../../core/slices/CoreSlice'
import { Loading } from '../../common/loading/Loading'
import { PotentialDatasource } from './PotentialDatasource'
import { FormattedMessage } from 'react-intl'
import { DatasourceOverviewToolbar } from './DatasourceOverviewToolbar'
import { DatasourcesGrid } from './DatasourcesGrid'
import { ProjectDatasource } from './ProjectDatasource'
import { getFilteredDatasourcesForProject } from '../SettingsSlice'
import { HmstrDispatch } from '../../core/Store'
import { Add, Delete } from '@mui/icons-material'
import { DatasourceSelectDialog } from './DatasourceSelectDialog'
import { fetchAccount } from '../accounts/AccountActions'
import { ConfirmDialog } from '../../common/confirm-dialog/ConfirmDialog'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchFederatedIdentites, fetchPermissionsForProject } from '../user-management/UserActions'
import { createDatasourceForProject, deleteDatasourceFromProject, updateDatasourceInProject } from './ProjectDatasourceActions'

type DatasourceOverviewProps = {}

export const ProjectDatasourceOverview: FC<DatasourceOverviewProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const currentUser = useSelector(getCurrentUser)
    const selectedProject = useSelector(getSelectedProject)
    const selectedAccount = useSelector(getSelectedAccount)
    const filteredDatasources = useSelector(getFilteredDatasourcesForProject)
    const [open, setOpen] = useState(false)
    const [datasourceToDelete, setDatasourceToDelete] = useState<undefined | ProjectDatasource>()
    const isUserProjectAdmin = useSelector(isProjectAdmin)
    const [datasourceDeleting, setDatasourceDeleting] = useState(false)

    useEffectWithIdComparison(() => {
        dispatch(fetchFederatedIdentites(currentUser))
    }, [currentUser])

    const handleDatasourceSelect = (potentialDatasource: PotentialDatasource) => {
        if (selectedProject) {
            dispatch(
                createDatasourceForProject({
                    project: selectedProject,
                    datasource: potentialDatasource,
                })
            ).then(() => {
                dispatch(fetchAccount(selectedProject._links.account))
                dispatch(fetchPermissionsForProject(selectedProject))
            })
        }
    }

    const handleDatasourceDelete = (datasourceId: string) => {
        const datasource = selectedProject?.data_sources.find((ds) => ds.metadata.id === datasourceId) as ProjectDatasource
        setDatasourceToDelete(datasource)
    }

    const deleteDatasourceById = (datasource: ProjectDatasource) => {
        if (selectedProject) {
            setDatasourceDeleting(true)
            dispatch(deleteDatasourceFromProject({ project: selectedProject, datasource })).then(() => {
                setDatasourceToDelete(undefined)
                setDatasourceDeleting(false)
                dispatch(fetchAccount(selectedProject._links.account))
                dispatch(showSuccessSnackbar('datasources.deleted-success'))
            })
        }
    }

    const handleDatasourceEdit = (projectDatasource: ProjectDatasource, resolve: any) => {
        if (selectedProject) {
            dispatch(updateDatasourceInProject({ project: selectedProject, datasource: projectDatasource })).then(() => {
                dispatch(showSuccessSnackbar('datasources.updated-success'))
                resolve()
            })
        }
    }

    if (!selectedProject) {
        return <Loading />
    }

    return (
        <Container>
            <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h5">
                    <FormattedMessage id="general.datasources" />
                </Typography>

                <Tooltip
                    title={
                        selectedAccount.data_source_count >= selectedAccount.data_source_limit ? (
                            <FormattedMessage id="projects.no-more-datasources-hint" />
                        ) : !isUserProjectAdmin ? (
                            <FormattedMessage id="general.disabled-ask-project-admin" />
                        ) : (
                            ''
                        )
                    }
                    placement="left"
                >
                    <span>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Add />}
                            onClick={() => setOpen(true)}
                            disabled={selectedAccount.data_source_count >= selectedAccount.data_source_limit || !isUserProjectAdmin}
                        >
                            <FormattedMessage id="projects.add-source" />
                        </Button>
                    </span>
                </Tooltip>
            </Box>

            <DatasourceOverviewToolbar />

            <Box mt={2}>
                <DatasourcesGrid datasources={filteredDatasources} onDelete={handleDatasourceDelete} hideAlert={true} onEdit={handleDatasourceEdit} />
            </Box>
            <DatasourceSelectDialog
                open={open}
                onSelect={handleDatasourceSelect}
                onClose={() => setOpen(false)}
                selectedDatasources={selectedProject.data_sources}
                limitExceeded={selectedAccount.data_source_count >= selectedAccount.data_source_limit}
            />
            {datasourceToDelete && (
                <ConfirmDialog
                    open={!!datasourceToDelete}
                    onClose={() => setDatasourceToDelete(undefined)}
                    onConfirm={() => deleteDatasourceById(datasourceToDelete)}
                    confirmText="general.delete"
                    confirmIcon={datasourceDeleting ? <CircularProgress color="inherit" size={20} /> : <Delete />}
                    confirmButtonDisabled={datasourceDeleting}
                    title={<FormattedMessage id="settings.datasource-delete" values={{ name: datasourceToDelete.name }} />}
                >
                    <FormattedMessage id="settings.datasource-delete.sure-verbose" values={{ name: datasourceToDelete.name }} />
                </ConfirmDialog>
            )}
        </Container>
    )
}
