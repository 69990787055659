import axios from 'axios'
import { ApiEntryPoint } from '../../core/api/ApiEntryPoint'
import { FederatedIdentity } from './FederatedIdentity'
import { ProjectDatasourceType } from '../datasources/ProjectDatasource'

const authenticateExternalAccount = (baseAuthLink: string, datasourceType?: ProjectDatasourceType): Promise<boolean> => {
    const windowHeight = window.innerHeight > 900 ? 900 : window.innerHeight
    const authHeader = axios.defaults.headers.common.Authorization as string
    const currentToken = authHeader.split(' ')[1]

    const windowLocation = window.location.href
    const bc = new BroadcastChannel('hmstr-account-link')

    let linkWithToken = baseAuthLink + `?access_token=${currentToken}`

    if (datasourceType) {
        linkWithToken += `&data_source_type=${datasourceType}`
    }

    if (!windowLocation.includes('/account-link-error')) {
        window.open(linkWithToken, 'identity', `toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=${windowHeight},width=600`)
    } else {
        window.location.href = linkWithToken
    }

    return new Promise((resolve) => {
        bc.onmessage = (event: MessageEvent<boolean>) => {
            if (event.isTrusted) {
                resolve(event.data)
            }
        }
    })
}

export const linkAccount = (apiEntryPoint: ApiEntryPoint, type: FederatedIdentity['type'], datasourceType?: ProjectDatasourceType) => {
    const baseAuthLink = apiEntryPoint._links.link_account.href.replace('{federated_identity_type}', type)
    return authenticateExternalAccount(baseAuthLink, datasourceType)
}

export const refreshAccount = (federatedIdentity: FederatedIdentity) => {
    return authenticateExternalAccount(federatedIdentity._links.self.href)
}
