import { FC, MouseEvent } from 'react'
import { Box, Grid, List, ListItem, ListSubheader, Tooltip, Typography } from '@mui/material'
import { FacebookConversionData } from './FacebookConversionData'
import { CheckCircle, CheckCircleOutlined, Visibility } from '@mui/icons-material'
import { getConversionTrackingAttributionWindows, showAdPreview } from './ConversionTrackingSlice'
import { FormattedMessage } from 'react-intl'
import { FormattedCurrency } from '../common/formatted-currency/FormattedCurrency'
import { IdMap } from '../core/slices/DataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Conversion } from './Conversion'
import * as _ from 'lodash'

type ConversionTrackingTableListProps = {
    data: IdMap<IdMap<FacebookConversionData[]>>
    hour: string
    day: string
    onPreview: (event: MouseEvent<any>, conversions: Conversion[]) => any
}

export const ConversionTrackingTableList: FC<ConversionTrackingTableListProps> = ({ hour, day, data, onPreview }) => {
    const dispatch = useDispatch()
    const attributionWindows = useSelector(getConversionTrackingAttributionWindows)

    const getActalConversionForAd = (ad: FacebookConversionData): Conversion[] => {
        return _.flatMap(attributionWindows, (aw) => ad[`actual_conversions_${aw}`] || [])
    }

    const getConversionsForAd = (ad: FacebookConversionData) => {
        return _.sum(_.map(attributionWindows, (aw) => ad[`conversions_${aw}`] || 0))
    }

    const getConversionValuesForAd = (ad: FacebookConversionData) => {
        return _.sum(_.map(attributionWindows, (aw) => ad[`conversion_values_${aw}`] || 0))
    }

    return (
        <List dense disablePadding>
            <ListSubheader disableSticky>{hour}</ListSubheader>
            {data[day][hour].map((ad: FacebookConversionData) => {
                const actualConversions = getActalConversionForAd(ad)
                const conversionsForAd = getConversionsForAd(ad)
                const exactMatch = actualConversions.length === conversionsForAd
                const MatchIcon = exactMatch ? CheckCircle : CheckCircleOutlined

                return (
                    <ListItem key={ad.ad_id}>
                        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                            <Box flexGrow={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <Typography>
                                            {conversionsForAd} <FormattedMessage id="conversion-tracking.conversions" />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display="flex" alignItems="center">
                                            <Box height="24px">
                                                <Tooltip title={<FormattedMessage id="conversion-tracking.load-ad-preview" />} placement="top">
                                                    <Visibility color="action" sx={{ cursor: 'pointer' }} onClick={() => dispatch(showAdPreview(ad.ad_id))} />
                                                </Tooltip>
                                            </Box>
                                            <Typography color="text.secondary" sx={{ pl: 2 }}>
                                                {ad.name}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box minWidth={200}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        {actualConversions.length > 0 && (
                                            <Tooltip
                                                title={
                                                    <FormattedMessage
                                                        id={exactMatch ? 'conversion-tracking.exact-match' : 'conversion-tracking.partial-match'}
                                                    />
                                                }
                                            >
                                                <MatchIcon onClick={(event) => onPreview(event, actualConversions)} cursor="pointer" color="success" />
                                            </Tooltip>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ pr: 5, textAlign: 'right' }}>
                                            <strong>
                                                <FormattedCurrency value={getConversionValuesForAd(ad)} />
                                            </strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </ListItem>
                )
            })}
        </List>
    )
}
