import { FC } from 'react'
import { Tickets } from './Tickets'
import { useSelector } from 'react-redux'
import { getClosedTickets } from './EngagementSlice'
import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Archive as ArchiveIcon } from '@mui/icons-material'
import { EmptyBox } from '../common/microinteractions/EmptyBox'

type ArchiveProps = {}

export const Archive: FC<ArchiveProps> = () => {
    const tickets = useSelector(getClosedTickets)

    return (
        <Tickets
            tab={'archived'}
            state="CLOSED"
            tickets={tickets}
            title="engagement.archive"
            icon={<ArchiveIcon sx={{ mr: 1 }} fontSize="inherit" />}
            emptyState={
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Box width={'100%'} maxWidth={'600px'}>
                        <EmptyBox />
                    </Box>

                    <Box mt={4}>
                        <Typography variant={'h5'} textAlign={'center'} color={'primary.light'} maxWidth={'500px'} fontWeight={'lighter'}>
                            <FormattedMessage id="engagement.no-tickets" />
                        </Typography>
                    </Box>
                </Box>
            }
        />
    )
}
