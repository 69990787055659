import { createAsyncThunk } from '@reduxjs/toolkit'
import { HmstrState } from '../Store'
import { doGet } from '../api/ApiClient'

const CORE_SLASH = (action: string) => `core/${action}`

export const fetchGatewayVersion = createAsyncThunk<{ version: string }, void, { state: HmstrState }>(
    CORE_SLASH('fetchGatewayVersion'),
    async (args, thunkApi) => {
        return await doGet<{ version: string }>(thunkApi, thunkApi.getState().api.gatewayLink)
    }
)
