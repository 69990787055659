import { FC } from 'react'
import { Box, Button, Chip } from '@mui/material'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { closePreviewDialog, getPreviewResultsForProject, getResultsPreviewFilter, previewDrillDown } from './ResultsSlice'
import { ResultEntry } from './ResultEntry'
import { FullscreenDialog } from '../common/fullscreen-dialog/FullscreenDialog'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { Fallback } from '../common/fallback/Fallback'
import { Check } from '@mui/icons-material'
import { getDatasourcesAsIdMap } from '../core/slices/CoreSlice'
import { ResultsListBox } from './Results.styles'

type ResultsPreviewProps = {
    open: boolean
    filter?: {
        startDate: string
        endDate: string
    }
}

export const ResultsPreview: FC<ResultsPreviewProps> = ({ open }) => {
    const dispatch = useDispatch()
    const results = useSelector(getPreviewResultsForProject)
    const datasources = useSelector(getDatasourcesAsIdMap)
    const { startDate, endDate, selectedMediaType, selectedDatasourceId, domain, allowDrilldown } = useSelector(getResultsPreviewFilter)

    let handleClose = () => {
        dispatch(closePreviewDialog())
    }
    return (
        <FullscreenDialog
            open={open}
            handleClose={handleClose}
            title={
                <Box display="flex">
                    <Box>
                        {results.length} <FormattedMessage id="results.title" />
                    </Box>

                    <Box paddingLeft={3}>
                        {startDate && endDate && (
                            <Chip color="secondary" size="small" label={`${moment(startDate).format('l')} - ${moment(endDate).format('l')}`} />
                        )}
                        {selectedMediaType && <Chip size="small" label={<FormattedMessage id={selectedMediaType} />} />}
                        {selectedDatasourceId && <Chip size="small" label={datasources[selectedDatasourceId].name} />}
                        {domain && <Chip size="small" label={domain} />}
                    </Box>
                </Box>
            }
            actionIcon={
                allowDrilldown === false ? undefined : (
                    <Button size="small" variant="contained" color="secondary" endIcon={<Check />} onClick={() => dispatch(previewDrillDown())}>
                        <FormattedMessage id="results.drilldown" />
                    </Button>
                )
            }
        >
            <Box height="calc(100dvh - 64px)">
                <AutoSizer>
                    {({ height, width }) => {
                        return (
                            <Fallback condition={results.length === 0}>
                                <ResultsListBox>
                                    <FixedSizeList width={width} height={height} itemSize={106} itemCount={results.length} itemData={results}>
                                        {ResultEntry}
                                    </FixedSizeList>
                                </ResultsListBox>
                            </Fallback>
                        )
                    }}
                </AutoSizer>
            </Box>
        </FullscreenDialog>
    )
}
