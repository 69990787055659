import { FC, Fragment } from 'react'
import { Grid, Paper } from '@mui/material'
import { Product } from '../Product'
import { useForm, useFormState } from 'react-final-form'
import { AccountFormValues } from '../AccountFormValues'
import { useSelector } from 'react-redux'
import { getAccounts, getProductsWithoutTrial } from '../../../core/slices/DataSlice'
import { AccountPackageColumn } from './AccountPackageColumn'
import { getCurrentUser } from '../../../core/slices/CoreSlice'

type AccountPackageTypeInputProps = {
    paper?: boolean
}

export const AccountPackageTypeInput: FC<AccountPackageTypeInputProps> = ({ paper }) => {
    const form = useForm()
    const formState = useFormState<AccountFormValues>()
    const products = useSelector(getProductsWithoutTrial)
    const currentUser = useSelector(getCurrentUser)
    const accounts = useSelector(getAccounts)
    const ownedAccountsCount = Object.values(accounts).filter((acc) => acc.owner_id === currentUser.id).length

    const getPriceForProduct = (product: Product, interval: 'month' | 'year') => {
        const price = product.prices.find((p) => p.recurring?.interval === interval)

        return price ? price.unit_amount / 100 : 0
    }

    const selectedType = formState.values.package_type
    const billingInterval = formState.values.interval

    const handleRadioSelect = (packageType: string) => {
        form.change('package_type', packageType)

        if (packageType === 'free') {
            form.change('additional_user', 0)
            form.change('additional_datasource', 0)
            form.change('conversion_tracking', 0)
        }
    }

    const comparePriceFn = (p1: Product, p2: Product) => {
        return getPriceForProduct(p1, 'month') - getPriceForProduct(p2, 'month')
    }

    const PaperComponent = paper ? Paper : Fragment

    return (
        <Grid item xs={12}>
            <PaperComponent>
                <Grid container flexWrap="nowrap">
                    <AccountPackageColumn packageId="none" name="columns" billingInterval={billingInterval} withoutPackage={true} />

                    {Object.values(products)
                        .filter((p) => Boolean(p.metadata.package))
                        .sort(comparePriceFn)
                        .map((p) => {
                            const priceForProduct = getPriceForProduct(p, billingInterval)
                            const freeNotAllowed = p.metadata.package === 'free' && ownedAccountsCount > 1

                            return (
                                <AccountPackageColumn
                                    key={p.metadata.package}
                                    disabled={freeNotAllowed}
                                    packageId={p.metadata.package}
                                    name={p.name}
                                    billingInterval={billingInterval}
                                    datasources={p.metadata.datasources}
                                    users={p.metadata.users}
                                    price={priceForProduct}
                                    selected={p.metadata.package === selectedType}
                                    postLimit={p.metadata.post_limit || undefined}
                                    tagLimit={p.metadata.tag_limit || undefined}
                                    onSelect={() => handleRadioSelect(p.metadata.package)}
                                />
                            )
                        })}

                    <AccountPackageColumn packageId="enterprise" name="Enterprise" billingInterval={billingInterval} />
                </Grid>
            </PaperComponent>
        </Grid>
    )
}
