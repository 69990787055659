import moment from 'moment'
import { FC, useState } from 'react'
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Check, Edit } from '@mui/icons-material'
import { StyledTextField } from '../../form/ConnectedTextField'
import { useDispatch, useSelector } from 'react-redux'
import { FacebookMonthlyReport } from './FacebookMonthlyReport'
import { useKeycloak } from '../../core/hooks/useKeycloak'
import { HmstrDispatch } from '../../core/Store'
import { createOrUpdateFacebookMonthlyReport } from './MonthlyReportActions'
import { Dashboard } from '../../dashboards/Dashboard'
import { getFacebookMonthlyReport } from '../../dashboards/DashboardsSlice'
import { getSelectedProjectAccess, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { Project } from '../../settings/project-management/Project'

type FacebookSummaryProps = {
    month: string
    selectedProject: Project | undefined
    selectedDashboard: Dashboard | undefined
}

export const MonthlyReportSummary: FC<FacebookSummaryProps> = ({ selectedDashboard, selectedProject, month }) => {
    const intl = useIntl()
    const keycloak = useKeycloak()
    const dispatch = useDispatch<HmstrDispatch>()
    const monthlyReport = useSelector(getFacebookMonthlyReport)
    const [summaryEditable, setSummaryEditable] = useState(false)
    const [summary, setSummary] = useState('')
    const projectAccess = useSelector(getSelectedProjectAccess)
    const isProjectAdmin = projectAccess?.role === 'ADMIN'

    const getMonthlyReportModel = (selectedDashboard: Dashboard) => {
        return {
            summary: summary,
            project_id: selectedProject?.id,
            dashboard_id: selectedDashboard.id,
            month: month,
            updated_by: keycloak.fullName,
            updated_by_id: keycloak.id,
        } as FacebookMonthlyReport
    }

    const handleClick = () => {
        if (!selectedDashboard) {
            return
        }

        if (!summaryEditable) {
            if (monthlyReport) {
                setSummary(monthlyReport.summary)
            }
            setSummaryEditable(true)
        } else if (summary !== monthlyReport?.summary) {
            dispatch(
                createOrUpdateFacebookMonthlyReport({
                    dashboard: selectedDashboard,
                    monthlyReport: getMonthlyReportModel(selectedDashboard),
                })
            ).then(() => {
                setSummaryEditable(false)
                dispatch(showSuccessSnackbar('facebook.summary-save.success'))
            })
        } else {
            setSummaryEditable(false)
        }
    }

    return (
        <Paper>
            <Box p={2} height={199}>
                <Box
                    sx={{
                        mb: 1,
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography noWrap={true}>
                        <strong>
                            <FormattedMessage id="facebook.monthly-report.summary" />
                        </strong>
                    </Typography>

                    <Box display="flex" alignItems="center">
                        {monthlyReport && (
                            <Typography fontSize={12} sx={{ mr: 1 }} fontStyle="italic" color="text.secondary">
                                <FormattedMessage
                                    id="general.last-changed-by"
                                    values={{
                                        user: monthlyReport.updated_by,
                                        datetime: moment(monthlyReport.updated_at).format('LLL'),
                                    }}
                                />
                            </Typography>
                        )}
                        <Tooltip
                            placement="left"
                            title={
                                !isProjectAdmin ? (
                                    <FormattedMessage id={'facebook.monthly-report.editing-disabled'} />
                                ) : (
                                    <FormattedMessage id={summaryEditable ? 'facebook.summary-save' : 'facebook.summary-edit'} />
                                )
                            }
                        >
                            <span>
                                <IconButton onClick={handleClick} size="small" disabled={!isProjectAdmin}>
                                    {summaryEditable ? <Check /> : <Edit />}
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>

                <StyledTextField
                    disabled={!summaryEditable || !isProjectAdmin}
                    readonly={!summaryEditable || !isProjectAdmin}
                    placeholder={intl.formatMessage({
                        id: 'facebook.summary-placeholder',
                    })}
                    value={summaryEditable ? summary : monthlyReport?.summary || ''}
                    onChange={(e) => setSummary(e.target.value)}
                    multiline={true}
                    fullWidth={true}
                    rows={5}
                    variant="standard"
                />
            </Box>
        </Paper>
    )
}
