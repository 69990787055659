import { ApiLink } from '../api/ApiLink'

export interface ClientConfig {
    keycloak: { url: string; realm: string; clientId: string }
    backend: { dev: string; basePath: string }
    profiles: string[]
    version: string
    stripe_public_key: string
    _links: {
        gateway: ApiLink
    }
}

const defaultConfig = {
    keycloak: {
        url: 'https://dev-sso.hmstr.de/auth',
        realm: 'hmstr',
        clientId: 'hmstr-frontend',
    },
    backend: {
        basePath: '/cms/api/v1',
        dev: 'https://dev-app.hmstr.de/cms/api/v1',
    },
}

let config = defaultConfig

// try to load config.local if exists
if (process.env.REACT_APP_ENV === 'local') {
    let localConfig: any

    try {
        localConfig = require('./config.local')
        config = Object.assign({}, defaultConfig, localConfig.default)
    } catch (e) {
        console.log('Could not load local config.')
    }
}

export default config
