import * as React from 'react'
import { FC, useRef, useState } from 'react'
import { alpha, Box, Popover, Typography, useTheme } from '@mui/material'
import moment, { Moment } from 'moment'
import { Event, ExpandMore } from '@mui/icons-material'
import { getSelectedCalendarTimePeriod } from '../PublishingSlice'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { DatePickerWithWeekSelection } from './DatePickerWithWeekSelection'
import { isDarkMode } from '../../core/slices/CoreSlice'

type CalendarDatePickerProps = {
    selectedStartDate: string
    onChange: (date: Moment | null) => any
}

export const CalendarDatePicker: FC<CalendarDatePickerProps> = ({ onChange, selectedStartDate }) => {
    const buttonRef = useRef(null)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const calendarView = useSelector(getSelectedCalendarTimePeriod)
    const intl = useIntl()
    const theme = useTheme()
    const darkmode = useSelector(isDarkMode)

    const handleChange = (value: Moment | null) => {
        if (value === null) {
            return null
        }

        onChange(value)
        setAnchorEl(null)
    }

    return (
        <>
            <Box
                ref={buttonRef}
                sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    width: 'fit-content',
                }}
            >
                <Event />
                <Box
                    className={'displayDate'}
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        pl: 1,
                        cursor: 'pointer',
                        borderRadius: theme.shape.borderRadius + 'px',
                        transition: 'all 0.2s',
                        '&:hover': {
                            backgroundColor: darkmode ? alpha('#fff', 0.08) : alpha('#000', 0.12),
                        },
                    }}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    <Typography variant={'h5'}>
                        {calendarView === 'week'
                            ? intl.formatMessage({ id: 'publishing.calendar-week-abbr' }) +
                              moment(selectedStartDate).week() +
                              ' ' +
                              moment(selectedStartDate).format('YYYY')
                            : moment(selectedStartDate).format('MMMM') + ' ' + moment(selectedStartDate).format('YYYY')}
                    </Typography>
                    <ExpandMore
                        sx={{
                            transform: !!anchorEl ? 'rotate(180deg)' : undefined,
                            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        }}
                    />
                </Box>
            </Box>
            <Popover
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={buttonRef.current}
                open={!!anchorEl}
                onClose={() => {
                    setAnchorEl(null)
                }}
                PaperProps={{ sx: { mt: 0.25 } }}
            >
                <DatePickerWithWeekSelection weekView={calendarView === 'week'} selectedStartDate={selectedStartDate} handleChange={handleChange} />
            </Popover>
        </>
    )
}
