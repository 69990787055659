import * as _ from 'lodash'
import * as React from 'react'
import { FC, useState } from 'react'
import { Assignment, KeyboardArrowDown, KeyboardArrowUp, PersonAdd, PersonRemove } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { Button, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getSelectedProject, getSimpleUsersForSelectedProject } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { fetchUsersForProject } from '../../settings/user-management/UserActions'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { Loading } from '../loading/Loading'
import { UserAvatar } from '../avatars/UserAvatar'
import { SimpleUser } from '../../settings/user-management/SimpleUser'
import { OverridableStringUnion } from '@mui/types'
import { ButtonPropsVariantOverrides } from '@mui/material/Button/Button'

type AssigneeDropdownProps = {
    assigneeId: string | undefined
    onAssign: (assignee: SimpleUser | undefined) => any
    avatar?: boolean
    disabled?: boolean
    variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>
    allowUnassignOverride?: boolean
}

export const AssigneeDropdown: FC<AssigneeDropdownProps> = ({ onAssign, assigneeId, avatar, disabled, variant, allowUnassignOverride }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const usersIdMap = useSelector(getSimpleUsersForSelectedProject)
    const currentUser = useSelector(getCurrentUser)
    const users = Object.values(usersIdMap)

    const sortedUsers = [
        ...users.filter((elem) => elem.id === (assigneeId ? assigneeId : currentUser.id)),
        ..._.sortBy(
            users.filter((elem) => elem.id !== (assigneeId ? assigneeId : currentUser.id)),
            (user) => `${user.first_name} ${user.last_name}`
        ),
    ]

    const selectedProject = useSelector(getSelectedProject)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [loading, setLoading] = useState(false)

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            setLoading(true)
            dispatch(fetchUsersForProject(selectedProject)).then(() => setLoading(false))
        }
    }, [selectedProject])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAssign = (user: SimpleUser | undefined) => {
        onAssign(user)
        handleClose()
    }

    const assignedUser = assigneeId ? usersIdMap[assigneeId] : undefined

    const assigneeMenu = () => {
        return (
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                id="assignee-dropdown-menu"
                MenuListProps={{
                    'aria-labelledby': 'assignee-dropdown-button',
                }}
                disablePortal
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {sortedUsers.map((user) => (
                    <MenuItem key={user.id} onClick={() => handleAssign(user)} disabled={user.id === assigneeId}>
                        <UserAvatar
                            user={user}
                            sx={{
                                width: 24,
                                height: 24,
                                mr: 1,
                                fontSize: 12,
                            }}
                        />
                        {user.first_name} {user.last_name}
                    </MenuItem>
                ))}
                {users.length > 0 && !loading && (
                    <MenuItem onClick={() => handleAssign(undefined)} disabled={!allowUnassignOverride && !assigneeId}>
                        <PersonRemove
                            sx={{
                                width: 24,
                                height: 24,
                                mr: 1,
                                fontSize: 12,
                            }}
                            color={'action'}
                        />
                        <FormattedMessage id="general.remove-assignment" />
                    </MenuItem>
                )}
                {users.length === 0 && loading && <Loading />}
                {users.length === 0 && !loading && (
                    <MenuItem>
                        <Typography color="text.secondary">
                            <FormattedMessage id="general.no-users-found" />
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        )
    }

    return avatar ? (
        <>
            <Tooltip title={<FormattedMessage id="general.assign" />}>
                <IconButton
                    disabled={disabled}
                    onClick={handleClick}
                    aria-controls={open ? 'assignee-dropdown-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {assignedUser ? <UserAvatar user={assignedUser} /> : <PersonAdd sx={{ m: 1 }} />}
                </IconButton>
            </Tooltip>
            {assigneeMenu()}
        </>
    ) : (
        <>
            <Button
                id="assignee-dropdown-button"
                variant={variant || 'contained'}
                color="secondary"
                size="small"
                aria-controls={open ? 'assignee-dropdown-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<Assignment fontSize="small" />}
                endIcon={open ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
                disabled={disabled}
            >
                <FormattedMessage id="general.assign" />
            </Button>
            {assigneeMenu()}
        </>
    )
}
