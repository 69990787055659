import { FC } from 'react'
import { BillingSubscription } from './BillingSubscription'
import { Button, Tooltip } from '@mui/material'
import { Cancel, PlayArrow } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { getPaymentMethods } from '../../core/slices/DataSlice'

type BillingCurrentPlanActionButtonProps = {
    subscription: BillingSubscription
    onContiue: () => any
    onReactive: () => any
    onCancel: () => any
    disabled?: boolean
}

export const BillingCurrentPlanActionButton: FC<BillingCurrentPlanActionButtonProps> = (props) => {
    const { subscription, onContiue, onReactive, onCancel, disabled } = props
    const paymentMethods = useSelector(getPaymentMethods)
    const hasPaymentMethods = Object.values(paymentMethods).length > 0

    if (subscription.cancel_at_period_end && !['canceled', 'incomplete_expired'].includes(subscription.status)) {
        return (
            <Tooltip placement="top" title={hasPaymentMethods ? '' : <FormattedMessage id="billing.no-extend-without-payment-methods" />}>
                <span>
                    <Button
                        size="small"
                        startIcon={<PlayArrow />}
                        color="secondary"
                        variant="contained"
                        onClick={onContiue}
                        disabled={!hasPaymentMethods || disabled}
                    >
                        <FormattedMessage id="billing.subscription.account-extend" />
                    </Button>
                </span>
            </Tooltip>
        )
    } else if (['canceled', 'incomplete_expired'].includes(subscription.status)) {
        return (
            <Tooltip placement="top" title={hasPaymentMethods ? '' : <FormattedMessage id="billing.no-reactivate-without-payment-methods" />}>
                <span>
                    <Button
                        size="small"
                        startIcon={<PlayArrow />}
                        color="secondary"
                        variant="contained"
                        onClick={onReactive}
                        disabled={!hasPaymentMethods || disabled}
                    >
                        <FormattedMessage id="billing.subscription.account-reactivate" />
                    </Button>
                </span>
            </Tooltip>
        )
    } else {
        return (
            <Button size="small" startIcon={<Cancel />} color="error" variant="contained" onClick={onCancel} disabled={disabled}>
                <FormattedMessage id="billing.cancel-account" />
            </Button>
        )
    }
}
