export interface PackageFeatures {
    analytics: {
        unlimitedDashboards: boolean
        templates: boolean
        postAnalysis: boolean
        postTagLimit: number | 'unlimited'
        crossNetworkReports: boolean
        exportableReports: boolean
        individualTemplates: boolean
    }
    publishing: {
        calendar: boolean
        postLimit: number | 'unlimited'
        publishingApprovals: boolean
    }
    engagement: {
        socialMediaInbox: boolean
        directResponses: boolean
        assignMessages: boolean
    }
}

export interface HmstrFeatures {
    analytics: (keyof PackageFeatures['analytics'])[]
    publishing?: (keyof PackageFeatures['publishing'])[]
    engagement?: (keyof PackageFeatures['engagement'])[]
}

export const features: HmstrFeatures = {
    analytics: ['unlimitedDashboards', 'templates', 'postAnalysis', 'postTagLimit', 'exportableReports', 'crossNetworkReports', 'individualTemplates'],
    publishing: ['calendar', 'postLimit', 'publishingApprovals'],
    engagement: ['socialMediaInbox', 'directResponses', 'assignMessages'],
}

export const packages: { [module: string]: PackageFeatures } = {
    free: {
        analytics: {
            unlimitedDashboards: true,
            templates: true,
            exportableReports: false,
            crossNetworkReports: false,
            postAnalysis: true,
            postTagLimit: 2,
            individualTemplates: false,
        },
        publishing: {
            calendar: true,
            publishingApprovals: false,
            postLimit: 5,
        },
        engagement: {
            socialMediaInbox: true,
            directResponses: false,
            assignMessages: false,
        },
    },
    basic: {
        analytics: {
            unlimitedDashboards: true,
            templates: true,
            exportableReports: true,
            crossNetworkReports: true,
            postAnalysis: true,
            postTagLimit: 'unlimited',
            individualTemplates: false,
        },
        publishing: {
            calendar: true,
            postLimit: 'unlimited',
            publishingApprovals: false,
        },
        engagement: {
            socialMediaInbox: true,
            directResponses: false,
            assignMessages: false,
        },
    },
    pro: {
        analytics: {
            unlimitedDashboards: true,
            templates: true,
            exportableReports: true,
            crossNetworkReports: true,
            postAnalysis: true,
            postTagLimit: 'unlimited',
            individualTemplates: false,
        },
        publishing: {
            calendar: true,
            postLimit: 'unlimited',
            publishingApprovals: true,
        },
        engagement: {
            socialMediaInbox: true,
            directResponses: true,
            assignMessages: false,
        },
    },
    enterprise: {
        analytics: {
            unlimitedDashboards: true,
            templates: true,
            exportableReports: true,
            crossNetworkReports: true,
            postAnalysis: true,
            postTagLimit: 'unlimited',
            individualTemplates: true,
        },
        publishing: {
            calendar: true,
            publishingApprovals: true,
            postLimit: 'unlimited',
        },
        engagement: {
            socialMediaInbox: true,
            directResponses: true,
            assignMessages: true,
        },
    },
}
