import { FC } from 'react'
import { Grid, Paper, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PostAnalysisBarChart } from './PostAnalysisBarChart'
import { AggregatedTagData } from '../../tags/AggregatedTagData'
import { PostAnalysisPieChart } from './PostAnalysisPieChart'
import { PostAnalysisTreeChart } from './PostAnalysisTreeChart'
import { CommonPostData } from '../posts/CommonPostData'
import { previewPost } from '../posts/PostsSlice'
import SortableTableHead from '../../common/sorted-paginated-table/SortableTableHead'
import * as React from 'react'
import { PostAnalysisSortType } from '../../common/filter/PostAnalysisSortType'
import { setSortDirection, setSortField } from './PostAnalysisSlice'
import { formatNumber } from '../../core/theme/helper'
import { FormattedNumber, useIntl } from 'react-intl'
import { Tag } from '../../tags/Tag'
import { useDispatch } from 'react-redux'
import { IdMap } from '../../core/slices/DataSlice'

type PostAnalysisContentProps = {
    aggregatedTagData: AggregatedTagData[]
    postsByTagId: IdMap<CommonPostData[]>
    tagsWithUntagged: Tag[]
    sortField: PostAnalysisSortType
    sortDirection: 'desc' | 'asc'
    sortedAggregatedTagData: AggregatedTagData[]
}

export const PostAnalysisContent: FC<PostAnalysisContentProps> = ({
    aggregatedTagData,
    postsByTagId,
    tagsWithUntagged,
    sortField,
    sortDirection,
    sortedAggregatedTagData,
}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const formatTagName = (tagName: string) => {
        const maxLength = 17
        if (tagName === 'tags.untagged-posts') {
            return intl.formatMessage({ id: tagName })
        } else if (tagName.length <= maxLength) {
            return tagName
        } else {
            return tagName.substring(0, maxLength - 3) + '...'
        }
    }

    return (
        <Grid container spacing={1} sx={{ pb: 2 }}>
            <Grid item container xs={12} spacing={1}>
                <Grid item sm={12} md={8}>
                    <Paper>
                        <PostAnalysisBarChart
                            title="post.analysis.amountOfPosts"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.post_amount}
                        />
                    </Paper>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Paper>
                        <PostAnalysisPieChart
                            title="post.analysis.amountOfPosts.distribution"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.post_amount}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
                <Grid item xs={8}>
                    <Paper>
                        <PostAnalysisBarChart
                            title="post.analysis.impressions"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.post_impressions}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <PostAnalysisPieChart
                            title="post.analysis.impressions.distribution"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.post_impressions}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
                <Grid item sm={12} md={8}>
                    <Paper>
                        <PostAnalysisBarChart
                            title="post.analysis.reactions"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.post_reactions}
                        />
                    </Paper>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Paper>
                        <PostAnalysisPieChart
                            title="post.analysis.reactions.distribution"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.post_reactions}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
                <Grid item xs={8}>
                    <Paper>
                        <PostAnalysisBarChart
                            title="post.analysis.engagement-rate"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.post_engagement_rate}
                            unit="%"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <PostAnalysisPieChart
                            title="post.analysis.engagement.distribution"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.post_engagement}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
                <Grid item xs={8}>
                    <Paper>
                        <PostAnalysisBarChart
                            title="post.analysis.spend"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.spend}
                            unit="€"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <PostAnalysisPieChart
                            title="post.analysis.spend.distribution"
                            data={aggregatedTagData}
                            getValue={(data: AggregatedTagData) => data.spend}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Paper>
                        <PostAnalysisTreeChart
                            title="facebook.impressions"
                            data={postsByTagId}
                            tags={tagsWithUntagged}
                            getValue={(data: CommonPostData) => data.insights.impressions}
                            onClick={(post) => dispatch(previewPost(post))}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Grid item container xs={12}>
                <Paper sx={{ overflowX: 'auto', width: '100%' }}>
                    <Table size="small">
                        <SortableTableHead
                            onRequestSort={(event: React.MouseEvent<unknown>, property: string) => {
                                if (sortField !== (property as PostAnalysisSortType)) {
                                    dispatch(setSortField(property as PostAnalysisSortType))
                                    dispatch(setSortDirection('desc'))
                                } else {
                                    dispatch(setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc'))
                                }
                            }}
                            order={sortDirection}
                            orderBy={sortField}
                            headCells={[
                                {
                                    id: PostAnalysisSortType.NAME,
                                    label: intl.formatMessage({ id: 'post.analysis.board.name' }),
                                },
                                {
                                    id: PostAnalysisSortType.AMOUNT,
                                    label: intl.formatMessage({ id: 'post.analysis.board.amount' }),
                                },
                                {
                                    id: PostAnalysisSortType.REACTIONS,
                                    label: intl.formatMessage({ id: 'post.analysis.board.reactions' }),
                                },
                                {
                                    id: PostAnalysisSortType.COMMENTS,
                                    label: intl.formatMessage({ id: 'post.analysis.board.comments' }),
                                },
                                {
                                    id: PostAnalysisSortType.SHARES,
                                    label: intl.formatMessage({ id: 'post.analysis.board.shares' }),
                                },
                                {
                                    id: PostAnalysisSortType.RKS,
                                    label: intl.formatMessage({ id: 'post.analysis.board.rks' }),
                                    tooltip: intl.formatMessage({ id: 'post.analysis.board.rks.tooltip' }),
                                },
                                {
                                    id: PostAnalysisSortType.ENGAGEMENTRATE,
                                    label: intl.formatMessage({ id: 'post.analysis.board.engagement-rate' }),
                                    tooltip: intl.formatMessage({ id: 'post.analysis.board.engagement-rate.tooltip' }),
                                },
                                {
                                    id: PostAnalysisSortType.IMPRESSIONS,
                                    label: intl.formatMessage({ id: 'post.analysis.board.impressions' }),
                                },
                                {
                                    id: PostAnalysisSortType.ENGANGEMENT,
                                    label: intl.formatMessage({ id: 'post.analysis.board.engagement' }),
                                },
                                {
                                    id: PostAnalysisSortType.SPEND,
                                    label: intl.formatMessage({ id: 'post.analysis.board.spend' }),
                                },
                            ]}
                        />
                        <TableBody>
                            {sortedAggregatedTagData.map((data) => (
                                <TableRow key={data.tag.id} hover>
                                    <TableCell sx={{ borderLeft: '5px solid ' + data.tag.color }}>{formatTagName(data.tag.label)}</TableCell>
                                    <TableCell>{formatNumber(data.post_amount)}</TableCell>
                                    <TableCell>{formatNumber(data.post_reactions)}</TableCell>
                                    <TableCell>{formatNumber(data.post_comments)}</TableCell>
                                    <TableCell>{formatNumber(data.post_shares)}</TableCell>
                                    <TableCell>{formatNumber(data.post_reactions + data.post_comments + data.post_shares)}</TableCell>
                                    <TableCell>{data.post_engagement_rate.toFixed(2) + '%'}</TableCell>
                                    <TableCell>{formatNumber(data.post_impressions)}</TableCell>
                                    <TableCell>{formatNumber(data.post_engagement)}</TableCell>
                                    <TableCell>
                                        <FormattedNumber value={data.spend} maximumFractionDigits={2} />
                                        &nbsp;€
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    )
}
