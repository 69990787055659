import { Box, CircularProgress, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

type LoadingProps = {
    messageId?: string
    withoutPaddingAndMargin?: boolean
}

export const Loading: FC<LoadingProps> = ({ messageId, withoutPaddingAndMargin }) => {
    const [color, setColor] = useState<any>('secondary')

    useEffect(() => {
        const interval = setInterval(() => setColor(color === 'secondary' ? 'primary' : 'secondary'), 1000)
        return () => clearInterval(interval)
    }, [color])

    return (
        <Box paddingTop={withoutPaddingAndMargin ? 0 : 4} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <CircularProgress color={color} />
            <Box marginTop={withoutPaddingAndMargin ? 0 : 2}>
                <Typography variant="subtitle2" color="textSecondary">
                    {messageId && <FormattedMessage id={messageId} />}
                </Typography>
            </Box>
        </Box>
    )
}
