import { forwardRef, useState } from 'react'
import { Box, Button, CircularProgress, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ListChildComponentProps } from 'react-window'
import { IdMap } from '../../core/slices/DataSlice'
import { ProjectDatasource } from './ProjectDatasource'
import { PotentialDatasource } from './PotentialDatasource'
import { Add, Check } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getIsMobile } from '../../core/slices/CoreSlice'

type ProjectDatasourceNewSelectItemProps = ListChildComponentProps<PotentialDatasource[]>
type ProjectDatasourceNewSelectItemData = {
    onSelectDatasource: (potentialDatasource: PotentialDatasource) => any
    datasourcesByMetadataId: IdMap<ProjectDatasource>
    icon: JSX.Element
    limitExceeded?: boolean
}

export const DatasourceNewSelectItem = (args: ProjectDatasourceNewSelectItemData) =>
    forwardRef<HTMLLIElement, ProjectDatasourceNewSelectItemProps>((props, ref) => {
        const { style, data, index } = props
        const [clicked, setClicked] = useState(false)
        const potentialDatasource = data[index]
        const alreadyAdded = Boolean(args.datasourcesByMetadataId[potentialDatasource.metadata.id])
        const isMobile = useSelector(getIsMobile)

        const handleClick = () => {
            setClicked(true)
            args.onSelectDatasource(potentialDatasource)
        }

        return (
            <ListItem
                ref={ref}
                style={style}
                key={potentialDatasource.metadata.id}
                disabled={Boolean(args.datasourcesByMetadataId[potentialDatasource.metadata.id])}
            >
                <ListItemIcon>{args.icon}</ListItemIcon>
                <ListItemText
                    sx={{
                        '& .MuiTypography-body1, .MuiTypography-body2': {
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        },
                    }}
                    style={{
                        maxWidth: Boolean(args.datasourcesByMetadataId[potentialDatasource.metadata.id]) ? 380 : undefined,
                    }}
                    primary={potentialDatasource.name}
                    secondary={potentialDatasource.metadata.description}
                />
                <Box flexGrow={1} />
                <Box paddingLeft={1}>
                    <Tooltip title={args.limitExceeded && !alreadyAdded ? <FormattedMessage id="projects.no-more-datasources-hint" /> : ''} placement="left">
                        <span>
                            <Button
                                onClick={handleClick}
                                startIcon={alreadyAdded ? <Check /> : clicked ? <CircularProgress color="inherit" size={20} /> : <Add />}
                                disabled={alreadyAdded || args.limitExceeded || clicked}
                            >
                                {isMobile ? null : alreadyAdded ? (
                                    <FormattedMessage id="projects.source-already-added" />
                                ) : (
                                    <FormattedMessage id="general.add" />
                                )}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </ListItem>
        )
    })
