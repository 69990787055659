import _ from 'lodash'
import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, Typography } from '@mui/material'
import * as React from 'react'
import { FC, useState } from 'react'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getSelectedProject, getSimpleUsersForSelectedProject } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchUsersForProject } from '../../settings/user-management/UserActions'
import { HmstrDispatch } from '../../core/Store'
import { Loading } from '../loading/Loading'
import { FormattedMessage } from 'react-intl'

type AssigneeMultipleDropdownProps = {
    assigneeIds: string[]
    onChange: (assigneIds: string[]) => any
    mode?: 'ALL' | 'EXTERNAL' | 'INTERNAL'
}

export const AssigneeMultipleDropdown: FC<AssigneeMultipleDropdownProps> = ({ assigneeIds, onChange, mode = 'ALL' }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const currentUser = useSelector(getCurrentUser)
    const [loading, setLoading] = useState(false)
    const selectedProject = useSelector(getSelectedProject)
    const usersIdMap = useSelector(getSimpleUsersForSelectedProject)

    let userChain = _.chain(usersIdMap).omit([currentUser.id]).values()

    if (mode === 'EXTERNAL') {
        userChain = userChain.filter((u) => u.external)
    } else if (mode === 'INTERNAL') {
        userChain = userChain.filter((u) => !u.external)
    }

    const sortedUsers = userChain.sortBy((user) => `${user.first_name} ${user.last_name}`).value()

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            setLoading(true)
            dispatch(fetchUsersForProject(selectedProject)).then(() => setLoading(false))
        }
    }, [selectedProject])

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value
        onChange(typeof value === 'string' ? value.split(',') : value)
    }

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="multiple-assignee-select-label">
                    <FormattedMessage id="publishing.approval.recipient" />
                </InputLabel>
                <Select
                    labelId="multiple-assignee-select-label"
                    id="multiple-assignee-select"
                    multiple
                    value={assigneeIds}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(assigneeIds) => assigneeIds.map((a) => `${usersIdMap[a].first_name} ${usersIdMap[a].last_name}`).join(', ')}
                >
                    {sortedUsers.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            <Checkbox checked={assigneeIds.indexOf(user.id) > -1} />
                            <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                        </MenuItem>
                    ))}

                    {sortedUsers.length === 0 && loading && <Loading />}
                    {sortedUsers.length === 0 && !loading && (
                        <MenuItem>
                            <Typography color="text.secondary">
                                <FormattedMessage
                                    id={
                                        mode === 'ALL'
                                            ? 'general.no-users-found'
                                            : mode === 'EXTERNAL'
                                            ? 'general.no-external-users-found'
                                            : 'general.no-internal-users-found'
                                    }
                                />
                            </Typography>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )
}
