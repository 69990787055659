import { FC, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material'
import { Download } from '@mui/icons-material'
import DomToImage from 'dom-to-image'
import { saveAs } from 'file-saver'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../core/slices/CoreSlice'

type DownloadGraphButtonProps = {
    tileId: string
    parentRelativeFlex?: boolean
}

export const DownloadGraphButton: FC<DownloadGraphButtonProps> = ({ tileId, parentRelativeFlex = false }) => {
    const [open, setOpen] = useState(false)
    const [hovering, setHovering] = useState(false)
    const darkMode = useSelector(isDarkMode)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        document.getElementById(tileId)!.addEventListener('mouseover', () => {
            setHovering(true)
        })
        document.getElementById(tileId)!.addEventListener('mouseout', () => {
            setHovering(false)
        })
    }, [tileId])

    const cssName = 'innerStyleForAdobeFonts' + tileId
    const generateImage = (id: string, transparent: boolean = false) => {
        const node = document.getElementById(id)
        if (node) {
            const title = node.querySelector('.MuiTypography-root') as HTMLElement
            if (title) {
                title.innerHTML = title.innerHTML.replaceAll(' ', '&nbsp;')
            }
            const nodeHeight = node.getBoundingClientRect().height
            const nodeWidth = node.getBoundingClientRect().width
            const tooltip = node.querySelectorAll('[data-testid="InfoIcon"]')[0] as HTMLElement
            let initialDisplayTooltip = 'none'
            if (tooltip) {
                initialDisplayTooltip = tooltip.style.display
                tooltip.style.display = 'none'
            }
            const originalBackground = node.style.background
            const downloadButton = node.getElementsByClassName('DownloadImageButton')[0] as HTMLElement
            const initialDisplayDownload = downloadButton.style.display
            downloadButton.style.display = 'none'
            transparent ? (node.style.background = 'transparent') : (node.style.background = originalBackground)
            const cssElem = document.getElementById(cssName)
            if (cssElem) {
                cssElem.innerHTML = styleFonts
            }
            DomToImage.toBlob(node, {
                bgcolor: transparent ? 'rgba(255,0,0,0)' : 'rgba(255,255,255,1)',
                height: nodeHeight,
                width: nodeWidth,
            })
                .then(function (blob) {
                    saveAs(blob, 'graph-' + new Date().getTime() + (transparent ? '.png' : '.jpg'))
                    node.style.background = originalBackground
                    downloadButton.style.display = initialDisplayDownload
                    if (tooltip) {
                        tooltip.style.display = initialDisplayTooltip
                    }
                })
                .then(() => {
                    if (cssElem) {
                        cssElem.innerHTML = ''
                    }
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error)
                })
            handleClose()
        }
    }
    const styleFonts =
        '/*\n' +
        ' * The Typekit service used to deliver this font or fonts for use on websites\n' +
        ' * is provided by Adobe and is subject to these Terms of Use\n' +
        ' * http://www.adobe.com/products/eulas/tou_typekit. For font license\n' +
        ' * information, see the list below.\n' +
        ' *\n' +
        ' * foco:\n' +
        ' *   - http://typekit.com/eulas/00000000000000003b9b033a\n' +
        ' *   - http://typekit.com/eulas/00000000000000003b9b033b\n' +
        ' *   - http://typekit.com/eulas/00000000000000003b9b033d\n' +
        ' *   - http://typekit.com/eulas/00000000000000003b9b033c\n' +
        ' *   - http://typekit.com/eulas/00000000000000003b9b0338\n' +
        ' *   - http://typekit.com/eulas/00000000000000003b9b0339\n' +
        ' *   - http://typekit.com/eulas/00000000000000003b9b033f\n' +
        ' *   - http://typekit.com/eulas/00000000000000003b9b033e\n' +
        ' *\n' +
        ' * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.\n' +
        ' */\n' +
        '/*{"last_published":"2022-07-20 09:29:54 UTC"}*/\n' +
        '\n' +
        '@import url("https://p.typekit.net/p.css?s=1&k=shm7ele&ht=tk&f=9795.9796.9797.9798.9799.9800.9801.9802&a=85251367&app=typekit&e=css");\n' +
        '\n' +
        '@font-face {\n' +
        'font-family:"foco";\n' +
        'src:url("https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");\n' +
        'font-display:fallback;font-style:normal;font-weight:400;font-stretch:normal;\n' +
        '}\n' +
        '\n' +
        '@font-face {\n' +
        'font-family:"foco";\n' +
        'src:url("https://use.typekit.net/af/d5b36a/00000000000000003b9b033b/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/d5b36a/00000000000000003b9b033b/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/d5b36a/00000000000000003b9b033b/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("opentype");\n' +
        'font-display:fallback;font-style:italic;font-weight:400;font-stretch:normal;\n' +
        '}\n' +
        '\n' +
        '@font-face {\n' +
        'font-family:"foco";\n' +
        'src:url("https://use.typekit.net/af/09a46f/00000000000000003b9b033d/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/09a46f/00000000000000003b9b033d/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/09a46f/00000000000000003b9b033d/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");\n' +
        'font-display:fallback;font-style:normal;font-weight:700;font-stretch:normal;\n' +
        '}\n' +
        '\n' +
        '@font-face {\n' +
        'font-family:"foco";\n' +
        'src:url("https://use.typekit.net/af/3bde18/00000000000000003b9b033c/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/3bde18/00000000000000003b9b033c/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/3bde18/00000000000000003b9b033c/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("opentype");\n' +
        'font-display:fallback;font-style:italic;font-weight:700;font-stretch:normal;\n' +
        '}\n' +
        '\n' +
        '@font-face {\n' +
        'font-family:"foco";\n' +
        'src:url("https://use.typekit.net/af/69890d/00000000000000003b9b0338/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/69890d/00000000000000003b9b0338/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/69890d/00000000000000003b9b0338/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("opentype");\n' +
        'font-display:fallback;font-style:normal;font-weight:300;font-stretch:normal;\n' +
        '}\n' +
        '\n' +
        '@font-face {\n' +
        'font-family:"foco";\n' +
        'src:url("https://use.typekit.net/af/7b8eae/00000000000000003b9b0339/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/7b8eae/00000000000000003b9b0339/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/7b8eae/00000000000000003b9b0339/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3") format("opentype");\n' +
        'font-display:fallback;font-style:italic;font-weight:300;font-stretch:normal;\n' +
        '}\n' +
        '\n' +
        '@font-face {\n' +
        'font-family:"foco";\n' +
        'src:url("https://use.typekit.net/af/05b406/00000000000000003b9b033f/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/05b406/00000000000000003b9b033f/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/05b406/00000000000000003b9b033f/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("opentype");\n' +
        'font-display:fallback;font-style:normal;font-weight:900;font-stretch:normal;\n' +
        '}\n' +
        '\n' +
        '@font-face {\n' +
        'font-family:"foco";\n' +
        'src:url("https://use.typekit.net/af/0cf3ef/00000000000000003b9b033e/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/0cf3ef/00000000000000003b9b033e/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/0cf3ef/00000000000000003b9b033e/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3") format("opentype");\n' +
        'font-display:fallback;font-style:italic;font-weight:900;font-stretch:normal;\n' +
        '}\n' +
        '\n' +
        '.tk-foco { font-family: "foco",sans-serif; }\n'

    return (
        <>
            <style id={cssName}></style>
            <Tooltip
                title={
                    <>
                        <FormattedMessage id={'graphdownload.download-as'} />
                    </>
                }
                disableInteractive
                arrow
            >
                <Button
                    sx={{
                        position: parentRelativeFlex ? 'absolute' : 'relative',
                        marginTop: '10px',
                        marginRight: '10px',
                        float: 'right',
                        height: '25px',
                        width: '25px',
                        minWidth: '0px',
                        background: 'inherit',
                        right: '0px',
                        padding: '0px',
                        marginBottom: '-35px',
                        color: 'primary',
                        borderRadius: '100%',
                        transition: 'all .2s',
                        opacity: hovering ? 0.9 : 0,
                        '&:hover': {
                            background: 'inherit',
                            opacity: hovering ? 1 : 0,
                            '& .downloadImageBtn': {
                                opacity: 1,
                            },
                        },
                    }}
                    variant="text"
                    onClick={handleClickOpen}
                    className="DownloadImageButton"
                >
                    <Download
                        className={'downloadImageBtn'}
                        sx={{
                            fontSize: '20px',
                            color: darkMode ? '#ffffff' : '#000000',
                            opacity: 0.6,
                            transition: 'all .1s',
                        }}
                    />
                </Button>
            </Tooltip>

            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id={'graphdownload.format'} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id={'graphdownload.format-as'} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            generateImage(tileId)
                        }}
                    >
                        .jpg
                    </Button>
                    <Button
                        onClick={() => {
                            generateImage(tileId, true)
                        }}
                        autoFocus
                    >
                        .png
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
