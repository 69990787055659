import React, { FC } from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'
import { useForm } from 'react-final-form'
import { FullScreenDialogTransition } from '../common/fullscreen-dialog/FullscreenDialog'
import { FormattedMessage } from 'react-intl'
import hmstrLogoWhite from '../resources/images/hmstr-only.png'

type WhiteLabelingPreviewDialogProps = {
    open: boolean
    onClose: () => any
    passedTitle: string
}

export const WhiteLabelingPreviewDialog: FC<WhiteLabelingPreviewDialogProps> = ({ open, onClose, passedTitle }) => {
    const form = useForm()
    const theme = useTheme()
    const passedImage =
        typeof form.getFieldState('whitelabel_image')?.value === 'string'
            ? form.getFieldState('whitelabel_image')?.value
            : form.getFieldState('whitelabel_image')?.value
            ? URL.createObjectURL(form.getFieldState('whitelabel_image')?.value)
            : hmstrLogoWhite
    return (
        <Dialog open={open} TransitionComponent={FullScreenDialogTransition} fullScreen maxWidth={'xl'}>
            <DialogTitle
                sx={{
                    backgroundColor: form.getFieldState('whitelabel_bg')?.value ? form.getFieldState('whitelabel_bg')?.value : theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        color: form.getFieldState('whitelabel_text')?.value ? form.getFieldState('whitelabel_text')?.value : theme.palette.primary.contrastText,
                        display: 'inline-block',
                        fontWeight: 'bold',
                        fontSize: '2em',
                    }}
                >
                    {passedTitle}
                </Typography>
                <Box display={'flex'} flexGrow={1}></Box>
                <img src={passedImage} alt={'logo'} style={{ maxHeight: 40 }} />
            </DialogTitle>
            <DialogContent
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    flexDirection: 'column',
                    backgroundColor: '#e7e7e7',
                }}
            >
                <Typography variant={'h6'} fontWeight={'normal'}>
                    <FormattedMessage id={'settings.whitelabeling.preview-explaination'} />
                </Typography>
                <Button variant={'contained'} color="secondary" onClick={onClose}>
                    <FormattedMessage id={'settings.whitelabeling.close-preview'} />
                </Button>
            </DialogContent>
        </Dialog>
    )
}
