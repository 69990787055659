import * as React from 'react'
import { FC, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { TicketNode } from './TicketNode'

type TicketNodeStoryPreviewProps = {
    story: TicketNode['story']
}

export const TicketNodeStoryPreview: FC<TicketNodeStoryPreviewProps> = ({ story }) => {
    const [hasErrors, setHasErrors] = useState(false)

    return (
        <Box pl={1}>
            {hasErrors ? (
                <Box
                    component="a"
                    href={story?.url}
                    target="_blank"
                    m={0.5}
                    ml={0}
                    borderRadius={1}
                    width={80}
                    height={80}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ backgroundColor: 'grey.A200' }}
                >
                    <IconButton>
                        <OpenInNew fontSize="large" color="action" />
                    </IconButton>
                </Box>
            ) : (
                <img src={story?.url} onError={() => setHasErrors(true)} alt="story-preview" height="100px" />
            )}
        </Box>
    )
}
