import * as React from 'react'
import { FC } from 'react'
import { Form } from 'react-final-form'
import { PostTextGenerationDialog } from './PostTextGenerationDialog'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../core/slices/CoreSlice'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'

export type AiGenerationFormValues = {
    channels: ProjectDatasource[]
    prompt: string
    amount_of_suggestions: number
    target_audience?: string
    sentiment?: string
    language?: string
    use_emojis?: boolean
    use_hashtags?: boolean
    text_length?: 'short' | 'long' | 'normal'

    refine_request?: string
    result_to_refine?: string
}

type PostTextGenerationDialogProps = {
    open: boolean
    handleSubmit: (val: AiGenerationFormValues) => void
    handleSelect: (val: string) => void
    channels: ProjectDatasource[]
}

export const PostTextGenerationForm: FC<PostTextGenerationDialogProps> = ({ open, handleSubmit, handleSelect, channels }) => {
    const currentUser = useSelector(getCurrentUser)
    const initialValues: AiGenerationFormValues = {
        channels: channels,
        prompt: '',
        amount_of_suggestions: 3,
        language: currentUser.settings.language || 'de',
        use_emojis: true,
        use_hashtags: true,
        sentiment: 'default',
        text_length: 'normal',
    }

    return (
        <Form<AiGenerationFormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize={false}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <PostTextGenerationDialog open={open} handleSubmit={handleSubmit} handleSelect={handleSelect} fullScreenLoader={true} />
                    </form>
                )
            }}
        ></Form>
    )
}
