import { FC, useState } from 'react'
import { Container, Typography } from '@mui/material'
import { AccountFormValues } from './AccountFormValues'
import { updateAccountBillingInfo, updateAccountPackage } from './AccountActions'
import { closeFullscreenDialog, getSelectedAccount } from '../../core/slices/CoreSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../../core/Store'
import { AccountUpdateSubscriptionForm } from './AccountUpdateSubscriptionForm'
import { AccountPaymentForm } from './AccountPaymentForm'
import { Account, BillingInfo } from './Account'
import { fetchSubscriptionById } from '../billing/BillingActions'
import { FORM_ERROR } from 'final-form'
import { FormattedMessage } from 'react-intl'
import { BillingInfoForm } from '../billing/BillingInfoForm'

type AccountUpdateSubscriptionWizardProps = {}

export const AccountUpdateSubscriptionWizard: FC<AccountUpdateSubscriptionWizardProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const account = useSelector(getSelectedAccount)
    const hasBillingAddress = !!account.billing_info.address
    const [accountFormValues, setAccountFormValues] = useState<AccountFormValues>()

    const [step, setStep] = useState(0)

    const handleAccountPackage = (values: AccountFormValues) => {
        return new Promise((resolve) => {
            dispatch(updateAccountPackage({ values, account })).then((action: any) => {
                if (action.type.includes('fulfilled')) {
                    const account = action.payload as Account
                    dispatch(fetchSubscriptionById(account))

                    if (account.payment_intent_secret === 'REDACTED') {
                        dispatch(closeFullscreenDialog())
                    } else {
                        setStep(2)
                    }
                    resolve(true)
                } else {
                    if (values.promotion_code) {
                        resolve({ [FORM_ERROR]: (action.payload && action.payload.detail) || 'general.error' })
                    } else {
                        resolve({ [FORM_ERROR]: 'general.error' })
                    }
                }
            })
        })
    }

    const handleBillingInfoSubmit = (billingInfo: BillingInfo) => {
        if (accountFormValues) {
            return new Promise((resolve) => {
                dispatch(updateAccountBillingInfo({ billingInfo, account })).then(() => {
                    handleAccountPackage(accountFormValues).then((result) => {
                        resolve(result)
                    })
                })
            })
        }
    }

    const handleSubscriptionFormSubmit = (values: AccountFormValues) => {
        if (!hasBillingAddress) {
            setAccountFormValues(values)
            setStep(1)
        } else {
            return handleAccountPackage(values)
        }
    }

    const handleClose = () => {
        dispatch(closeFullscreenDialog())
    }

    return (
        <Container maxWidth="lg">
            <Typography sx={{ mt: 2, mb: 4 }} variant="h4">
                {step === 0 ? <FormattedMessage id="billing.choose-new-plan" /> : <FormattedMessage id="billing.complete-billing-info" />}
            </Typography>

            {step === 0 && (
                <AccountUpdateSubscriptionForm handleSubmit={handleSubscriptionFormSubmit} handleClose={handleClose} initialValues={accountFormValues} />
            )}
            {step === 1 && <BillingInfoForm initialValues={account} onBack={() => setStep(0)} onSubmit={handleBillingInfoSubmit} inWizard={true} />}
            {step === 2 && <AccountPaymentForm clientSecret={account.payment_intent_secret} customerId={account.customer_id} account={account} />}
        </Container>
    )
}
