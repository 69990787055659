import * as React from 'react'
import { FC, useState } from 'react'
import {
    alpha,
    Box,
    Breadcrumbs,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link as MuiLink,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Dashboard } from './Dashboard'
import DownloadIcon from '@mui/icons-material/Download'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import SettingsIcon from '@mui/icons-material/Settings'
import { HmstrColors } from '../core/theme/hmstr-theme'
import {
    getRunningExports,
    getSelectedDashboardAttributionWindows,
    getSelectedDashboardCompareEndDate,
    getSelectedDashboardCompareStartDate,
    getSelectedDashboardDatasourceIds,
    getSelectedDashboardEndDate,
    getSelectedDashboardEstimateWithoutConsentLoss,
    getSelectedDashboardStartDate,
    getSelectedDashboardTags,
    openSendDashboardDialog,
    setDashboardWizardStep,
} from './DashboardsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { generateDashboardPdf, generateDashboardPptx } from './DashboardsActions'
import moment from 'moment'
import { getTemplates } from '../core/slices/DataSlice'
import { Dashboard as DashboardIcon, Facebook, Instagram, LinkedIn, NavigateNext } from '@mui/icons-material'
import { getIsMobile, getSelectedProjectId, isProjectAdmin, openFullscreenDialog, showErrorSnackbar } from '../core/slices/CoreSlice'

type DashboardTitleProps = {
    dashboard?: Dashboard
    hasChanges: boolean
    mainDatasourceType?: 'facebook' | 'instagram' | 'linkedin'
}

export const DashboardTitle: FC<DashboardTitleProps> = ({ dashboard, mainDatasourceType }) => {
    const theme = useTheme()
    const dispatch = useDispatch<HmstrDispatch>()
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
    const isUserProjectAdmin = useSelector(isProjectAdmin)

    const projectId = useSelector(getSelectedProjectId)
    const stateStartDate = useSelector(getSelectedDashboardStartDate)
    const stateCompareStartDate = useSelector(getSelectedDashboardCompareStartDate)
    const stateEndDate = useSelector(getSelectedDashboardEndDate)
    const stateCompareEndDate = useSelector(getSelectedDashboardCompareEndDate)
    const selectedDashboardAttributionWindows = useSelector(getSelectedDashboardAttributionWindows)
    const templates = useSelector(getTemplates)
    const runningExports = useSelector(getRunningExports)
    const estimateWithousConsentLoss = useSelector(getSelectedDashboardEstimateWithoutConsentLoss)
    const selectedTags = useSelector(getSelectedDashboardTags)
    const selectedDataSourceIds = useSelector(getSelectedDashboardDatasourceIds)

    const isMobile = useSelector(getIsMobile)

    const handleOpenDownloadDialog = () => {
        setOpenDownloadDialog(true)
    }
    const handleCloseDownloadDialog = () => {
        setOpenDownloadDialog(false)
    }

    const openEdit = () => {
        dispatch(openFullscreenDialog('edit-dashboard'))
        dispatch(setDashboardWizardStep(2))
    }

    const downloadPdf = () => {
        dispatch(
            generateDashboardPdf({
                dashboard: dashboard!,
                exportParams: {
                    fileType: 'pdf',
                    startDate: moment(stateStartDate),
                    endDate: moment(stateEndDate),
                    compareStartDate: moment(stateCompareStartDate),
                    compareEndDate: moment(stateCompareEndDate),
                    without_consent_loss: estimateWithousConsentLoss,
                    selected_datasources: selectedDataSourceIds.join(','),
                    attribution_windows: selectedDashboardAttributionWindows?.join(','),
                    selected_tag_ids: selectedTags?.join(','),
                },
            })
        ).then((action: any) => {
            if (!action.payload.downloaded) {
                dispatch(showErrorSnackbar('general.error-occured'))
            }
        })
        handleCloseDownloadDialog()
    }
    const downloadPptx = () => {
        dispatch(
            generateDashboardPptx({
                dashboard: dashboard!,
                exportParams: {
                    fileType: 'pptx',
                    startDate: moment(stateStartDate),
                    endDate: moment(stateEndDate),
                    compareStartDate: moment(stateCompareStartDate),
                    compareEndDate: moment(stateCompareEndDate),
                    without_consent_loss: estimateWithousConsentLoss,
                    selected_datasources: selectedDataSourceIds.join(','),
                    attribution_windows: selectedDashboardAttributionWindows?.join(','),
                    selected_tag_ids: selectedTags?.join(','),
                },
            })
        ).then((action: any) => {
            if (!action.payload.downloaded) {
                dispatch(showErrorSnackbar('general.error-occured'))
            }
        })
        handleCloseDownloadDialog()
    }

    const checkTitleIcon = () => {
        switch (mainDatasourceType) {
            case 'facebook':
                return <Facebook />
            case 'instagram':
                return <Instagram />
            default:
                return <LinkedIn />
        }
    }

    return (
        <Breadcrumbs separator={<NavigateNext />}>
            <MuiLink underline="hover" color="inherit" component={Link} to={`/${projectId}/analytics/dashboards`}>
                <Typography variant={'h5'} display="flex" alignItems="center">
                    <DashboardIcon sx={{ mr: 1 }} fontSize="inherit" />
                    <FormattedMessage id="reporting.dashboards.title" />
                </Typography>
            </MuiLink>
            <Box display="flex" alignItems={'flex-end'}>
                <Typography variant={'h5'} color="text.primary">
                    {dashboard?.name}
                </Typography>

                {mainDatasourceType && (
                    <Tooltip title={<FormattedMessage id={`datasource.${mainDatasourceType}`} />}>
                        <span
                            style={{
                                margin: '0 0px 0 16px',
                            }}
                        >
                            <IconButton
                                color={'secondary'}
                                size={'small'}
                                disabled
                                sx={{
                                    backgroundColor: 'rgba(255, 105, 0, 0.04) !important',
                                    color: `${theme.palette.secondary.main} !important`,
                                    borderColor: 'divider',
                                }}
                            >
                                {checkTitleIcon()}
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
                <Tooltip title={<FormattedMessage id={'download-dialog.title'} />}>
                    <span>
                        <IconButton
                            onClick={handleOpenDownloadDialog}
                            size={'small'}
                            disabled={selectedDataSourceIds.length === 0}
                            style={{
                                backgroundColor: HmstrColors.Orange,
                                margin: '0 8px 0 8px',
                                opacity: selectedDataSourceIds.length === 0 ? 0.5 : 1,
                            }}
                        >
                            <DownloadIcon sx={{ color: HmstrColors.White }} fontSize={'small'} />
                        </IconButton>
                    </span>
                </Tooltip>
                <Dialog
                    open={openDownloadDialog}
                    onClose={handleCloseDownloadDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <FormattedMessage id={'download-dialog.title'} />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={'download-dialog.description'} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={downloadPptx}
                            disabled={!!runningExports.find((e) => e.dashboardId === dashboard?.id && e.type === 'pptx')}
                            variant={'contained'}
                            color={'secondary'}
                        >
                            .pptx
                        </Button>
                        <Button
                            onClick={downloadPdf}
                            disabled={!!runningExports.find((e) => e.dashboardId === dashboard?.id && e.type === 'pdf')}
                            variant={'contained'}
                            color={'secondary'}
                        >
                            .pdf
                        </Button>
                    </DialogActions>
                </Dialog>
                {templates[dashboard!.template_id]!.name.includes('monthly-report') && (
                    <IconButton
                        onClick={() => dispatch(openSendDashboardDialog())}
                        size={'small'}
                        style={{ backgroundColor: HmstrColors.Orange, margin: '0 8px 0 0' }}
                    >
                        <AttachEmailIcon sx={{ color: HmstrColors.White }} fontSize={'small'} />
                    </IconButton>
                )}
                {!isMobile && (
                    <Tooltip
                        title={
                            !isUserProjectAdmin ? <FormattedMessage id="general.disabled-ask-project-admin" /> : <FormattedMessage id={'general.settings'} />
                        }
                    >
                        <span>
                            <IconButton
                                onClick={openEdit}
                                size={'small'}
                                disabled={!isUserProjectAdmin}
                                style={isUserProjectAdmin ? { backgroundColor: HmstrColors.Orange } : { backgroundColor: alpha(HmstrColors.Grey, 0.5) }}
                            >
                                <SettingsIcon sx={{ color: HmstrColors.White }} fontSize={'small'} />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </Box>
        </Breadcrumbs>
    )
}
