import * as React from 'react'
import { FC } from 'react'
import { Box } from '@mui/material'
import { CheckCircleRounded, Error } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { getRelevantStateForSelectedPostGroup, getSelectedPostGroup, getSelectedPostGroupFailedPosts } from './PublishingSlice'
import { useStartTour } from '../common/guides/useStartTour'

type PublishingFormTitleProps = {}
export const PublishingFormTitle: FC<PublishingFormTitleProps> = () => {
    const selectedPostGroup = useSelector(getSelectedPostGroup)
    const postState = useSelector(getRelevantStateForSelectedPostGroup)
    const postsWithErrors = useSelector(getSelectedPostGroupFailedPosts)

    const partiallyFailed = postState === 'PUBLISH_FAILED' && postsWithErrors.length < (selectedPostGroup?.posts || []).length

    useStartTour('publishingFormTour')

    if (selectedPostGroup === undefined) {
        return <FormattedMessage id="publishing.dialog-title.NEW" />
    }

    switch (postState) {
        case 'DRAFT':
            return <FormattedMessage id="publishing.dialog-title.DRAFT" />
        case 'PUBLISH_IN_PROGRESS':
            return <FormattedMessage id="publishing.dialog-title.PUBLISH_IN_PROGRESS" />
        case 'PLANNED':
        case 'PUBLISH_NOW':
        case 'PLANNED_BY_FACEBOOK':
            return <FormattedMessage id="publishing.dialog-title.PLANNED" />
        case 'PUBLISH_SUCCESS':
            return (
                <Box display="flex" alignItems="center" gap={1}>
                    <CheckCircleRounded color="success" />
                    <FormattedMessage id="publishing.dialog-title.PUBLISH_SUCCESS" />
                </Box>
            )
        case 'PUBLISH_FAILED':
            return (
                <Box display="flex" alignItems="center" gap={1}>
                    <Error color="error" />
                    <FormattedMessage id={`publishing.dialog-title.PUBLISH_FAILED${partiallyFailed ? '_PARTIALLY' : ''}`} />
                </Box>
            )
    }
}
