import { FC } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import { getConversionTrackingCampaigns, getConversionTrackingSelectedCampaignIds, selectCampaignIds } from './ConversionTrackingSlice'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCampaignSelectOpen, setCampaignSelectOpen } from '../common/filterbar/FilterSlice'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

type ConversionTrackingCampaignSelectProps = {}

export const renderCampaignValueAsString = (ids: string[], campaigns: { id: string; name: string }[], intl: IntlShape) => {
    if (ids.length === 0) {
        return intl.formatMessage({ id: 'conversion-tracking.all-campaigns' })
    }

    if (ids.length === 1) {
        return campaigns.find((c) => c.id === ids[0])?.name || '1 ' + intl.formatMessage({ id: 'conversion-tracking.campaign' })
    }

    return ids.length + ' ' + intl.formatMessage({ id: 'conversion-tracking.campaigns' })
}

export const ConversionTrackingCampaignSelect: FC<ConversionTrackingCampaignSelectProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const campaigns = useSelector(getConversionTrackingCampaigns)
    const selectedCampaignIds = useSelector(getConversionTrackingSelectedCampaignIds)
    const open = useSelector(getCampaignSelectOpen)

    return (
        <FormControl fullWidth>
            <InputLabel id="campaigns-label" variant="outlined">
                <FormattedMessage id="conversion-tracking.campaigns" />
            </InputLabel>
            <Select
                open={open}
                onOpen={() => dispatch(setCampaignSelectOpen(true))}
                onClose={() => dispatch(setCampaignSelectOpen(false))}
                value={selectedCampaignIds}
                multiple
                input={<OutlinedInput label={<FormattedMessage id="conversion-tracking.campaigns" />} />}
                renderValue={(selected) => renderCampaignValueAsString(selected, campaigns, intl)}
                onChange={({ target: { value } }: any) => dispatch(selectCampaignIds(typeof value === 'string' ? value.split(',') : value))}
            >
                {campaigns.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                        <Checkbox checked={selectedCampaignIds.includes(c.id)} />
                        <ListItemText primary={c.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
