import * as _ from 'lodash'
import { FC } from 'react'
import { Alert, Box, Breadcrumbs, FormLabel, Grid, LinearProgress, Link, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { useDispatch, useSelector } from 'react-redux'
import {
    closeConversionUploadDialog,
    getConversionTrackingUploadDialogOpen,
    getFacebookAdAccountsWithConversionTracking,
    getFacebookAdAccountsWithoutConversionTracking,
    openConversionUploadDialog,
} from './ConversionTrackingSlice'
import { ConversionTrackingUploadDialog } from './ConversionTrackingUploadDialog'
import { SectionCollapse } from '../common/section-collapse/SectionCollapse'
import { getSelectedAccount, getSelectedProject, showSuccessSnackbar } from '../core/slices/CoreSlice'
import { ConversionTrackingCard } from './ConversionTrackingCard'
import { Fallback } from '../common/fallback/Fallback'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { HmstrDispatch } from '../core/Store'
import { fetchAccount } from '../settings/accounts/AccountActions'
import { Info } from '@mui/icons-material'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { updateDatasourceInProject } from '../settings/datasources/ProjectDatasourceActions'

type ConversionTrackingManagementProps = {}

export const ConversionTrackingManagement: FC<ConversionTrackingManagementProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const intl = useIntl()
    const isDialogOpen = useSelector(getConversionTrackingUploadDialogOpen)
    const adAccountsWithConversionTracking = useSelector(getFacebookAdAccountsWithConversionTracking)
    const adAccountsWithoutConversionTracking = useSelector(getFacebookAdAccountsWithoutConversionTracking)
    const selectedAccount = useSelector(getSelectedAccount)
    const selectedProject = useSelector(getSelectedProject)
    const navigate = useProjectNavigate()

    const handleOpenUploadDialog = (adAccount: ProjectDatasource) => {
        dispatch(openConversionUploadDialog(adAccount.id))
    }

    const handleDialogClose = () => {
        dispatch(closeConversionUploadDialog())
    }

    const handleTrackingChange = (adAccount: ProjectDatasource, active: boolean) => {
        if (selectedProject) {
            const newProject = _.cloneDeep(selectedProject)
            const newDatasource = newProject?.data_sources.find((ds) => ds.id === adAccount.id)

            if (newDatasource) {
                newDatasource.conversion_tracking = active
                dispatch(
                    updateDatasourceInProject({
                        project: selectedProject,
                        datasource: newDatasource,
                    })
                ).then(() => {
                    dispatch(fetchAccount(newProject._links.account))
                    dispatch(
                        showSuccessSnackbar(
                            intl.formatMessage({ id: `conversion-tracking.${active ? 'activated' : 'deactivated'}` }, { name: newDatasource.name })
                        )
                    )
                })
            }
        }
    }

    return (
        <ContentPanel
            title={
                <Breadcrumbs>
                    <Typography variant="h5">
                        <FormattedMessage id="conversion-tracking.title" />
                    </Typography>

                    <Typography variant="h5">
                        <FormattedMessage id="conversion-tracking.management" />
                    </Typography>
                </Breadcrumbs>
            }
            toolbar={
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Box width={300}>
                            <FormLabel>
                                <FormattedMessage id="conversion-tracking.contingent" />: {selectedAccount.conversion_tracking_count} /{' '}
                                {selectedAccount.conversion_tracking_limit}
                            </FormLabel>
                            <LinearProgress
                                color="secondary"
                                variant="determinate"
                                value={(selectedAccount.conversion_tracking_count / selectedAccount.conversion_tracking_limit) * 100}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
            disableToolbarToggle
        >
            <SectionCollapse label="conversion-tracking.active-datasources" defaultOpen={true}>
                <Fallback condition={adAccountsWithConversionTracking.length === 0} messageId="conversion-tracking.no-active-datasources">
                    <Grid container spacing={2}>
                        {adAccountsWithConversionTracking.map((acc) => (
                            <ConversionTrackingCard
                                key={acc.id}
                                adAccount={acc}
                                onDeactivate={() => handleTrackingChange(acc, false)}
                                onUpload={handleOpenUploadDialog}
                            />
                        ))}
                    </Grid>
                </Fallback>
            </SectionCollapse>
            <SectionCollapse label="conversion-tracking.inactive-datasources" defaultOpen={adAccountsWithConversionTracking.length === 0}>
                <Box pb={2}>
                    <Alert color="info" icon={<Info />}>
                        <Typography variant="body2">
                            <FormattedMessage id="datasources.cant-find-question" />
                            &nbsp;
                            <Link sx={{ cursor: 'pointer' }} color="secondary" onClick={() => navigate('/settings/data-sources')}>
                                <FormattedMessage id="datasources.add-edit" />
                            </Link>
                        </Typography>
                    </Alert>
                </Box>

                <Fallback condition={adAccountsWithoutConversionTracking.length === 0} messageId="conversion-tracking.no-inactive-datasources">
                    <Grid container spacing={2}>
                        {adAccountsWithoutConversionTracking.map((acc) => (
                            <ConversionTrackingCard key={acc.id} adAccount={acc} onActivate={() => handleTrackingChange(acc, true)} />
                        ))}
                    </Grid>
                </Fallback>
            </SectionCollapse>

            <ConversionTrackingUploadDialog open={isDialogOpen} onClose={handleDialogClose} />
        </ContentPanel>
    )
}
