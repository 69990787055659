import { FC } from 'react'
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip, Typography, useTheme } from '@mui/material'
import { AddCircle, Apps, CreditCard, DisplaySettings, Folder, Link, NotificationsOutlined, People, ShoppingCart, Web } from '@mui/icons-material'
import { SecondaryNavigationButton } from '../SecondaryNavigationButton'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getInvalidDatasourceCountForProject, getSelectedAccount, getSelectedProjectId, openFullscreenDialog } from '../../core/slices/CoreSlice'
import { getAllowedToCreateAccount } from '../../settings/SettingsSlice'
import { useStartTour } from '../../common/guides/useStartTour'
import { getScopeErrors } from '../../core/slices/DataSlice'
import { useHasAccountPermission, useHasProjectPermission } from '../../core/hooks/useHasPermission'

type SettingsNavigationProps = {}

export const SettingsNavigation: FC<SettingsNavigationProps> = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const selectedAccount = useSelector(getSelectedAccount)
    const selectedProjectId = useSelector(getSelectedProjectId)
    const allowedToCreateAccount = useSelector(getAllowedToCreateAccount)
    const invalidDatasourceCount = useSelector(getInvalidDatasourceCountForProject)
    const scopeErrors = useSelector(getScopeErrors)
    const scopeErrorCount = Object.keys(scopeErrors).length

    const canReadBilling = useHasAccountPermission('account.read_billing', selectedAccount.id)
    const canManageUsers = useHasAccountPermission('account.manage_users', selectedAccount.id)
    const canUpdateProject = useHasProjectPermission('project.update', selectedProjectId)

    useStartTour('settingsTour')

    return (
        <>
            <List>
                <ListSubheader sx={{ mt: 2 }}>
                    <FormattedMessage id="settings.project" />
                </ListSubheader>
                <SecondaryNavigationButton
                    id={'datasources_tab'}
                    path="/settings/data-sources"
                    icon={<Web />}
                    title="general.datasources"
                    badge={
                        invalidDatasourceCount > 0 ? (
                            <Box
                                sx={{
                                    pl: 1,
                                    pr: 1,
                                    backgroundColor: theme.palette.error.main,
                                    height: 24,
                                    borderRadius: 12,
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'white',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="subtitle2">{invalidDatasourceCount < 100 ? invalidDatasourceCount : '99+'}</Typography>
                            </Box>
                        ) : undefined
                    }
                />
                {canUpdateProject && (
                    <SecondaryNavigationButton id={'project_tab'} path="/settings/project-settings" icon={<Folder />} title="settings.project" />
                )}
                <ListSubheader sx={{ mt: 2 }}>
                    <FormattedMessage id="settings.user" />
                </ListSubheader>
                <SecondaryNavigationButton
                    badge={
                        scopeErrorCount > 0 ? (
                            <Box
                                sx={{
                                    pl: 1,
                                    pr: 1,
                                    backgroundColor: theme.palette.error.main,
                                    height: 24,
                                    borderRadius: 12,
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'white',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="subtitle2">{scopeErrorCount < 100 ? scopeErrorCount : '99+'}</Typography>
                            </Box>
                        ) : undefined
                    }
                    id={'linked_accounts_tab'}
                    path="/settings/linked-accounts"
                    icon={<Link />}
                    title="accounts.manage-linked-accounts"
                />
                <SecondaryNavigationButton id={'interface_tab'} path="/settings/display" icon={<DisplaySettings />} title="settings.interface" />
                <SecondaryNavigationButton
                    id={'notifications_tab'}
                    path={'/settings/notifications'}
                    icon={<NotificationsOutlined />}
                    title="notifications.manage"
                />

                <ListSubheader sx={{ mt: 2 }}>
                    <FormattedMessage id="settings.account" />
                </ListSubheader>
                {canReadBilling && (
                    <SecondaryNavigationButton id={'subscription_tab'} path="/settings/account-settings" icon={<ShoppingCart />} title="billing.subscription" />
                )}
                {canReadBilling && (
                    <SecondaryNavigationButton id={'invoice_tab'} path="/settings/billing" icon={<CreditCard />} title="general.billing" hasSubRoutes={true} />
                )}
                {canManageUsers && <SecondaryNavigationButton id={'users_tab'} path="/settings/manage-users" icon={<People />} title="accounts.manage-users" />}
                <SecondaryNavigationButton id={'projects_tab'} path="/settings/projects" icon={<Apps />} title="projects.manage" />
            </List>

            <Box flexGrow={1} />
            <Divider />
            <Tooltip title={!allowedToCreateAccount ? <FormattedMessage id="accounts.new-disabled-hint" /> : ''}>
                <List>
                    <ListItemButton
                        id={'create_account_button'}
                        onClick={() => dispatch(openFullscreenDialog('new-account'))}
                        disabled={!allowedToCreateAccount}
                    >
                        <ListItemIcon>
                            <AddCircle />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="accounts.new" />
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Tooltip>
        </>
    )
}
