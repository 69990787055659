import { FC } from 'react'
import * as _ from 'lodash'
import { Box, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Select, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { FieldArray } from 'react-final-form-arrays'
import { ConnectedDropdown } from '../../form/ConnectedDropdown'
import { FormattedMessage } from 'react-intl'
import { UserDatasourceAccess } from './User'
import { Fallback } from '../../common/fallback/Fallback'
import { Project } from '../project-management/Project'
import { getIconForDatasourceType } from '../datasources/DatasourceTypeMappings'
import TableHead from '@mui/material/TableHead'
import { useForm, useFormState } from 'react-final-form'
import { UserFormValues } from './UserForm'

type UserFormProjectItemProps = {
    projectFormName: string
    project: Project
}

const PublishingRoles = [
    <MenuItem key="NONE" value="">
        <FormattedMessage id="settings.roles.NONE" />
    </MenuItem>,
    <MenuItem key="EDITOR" value="EDITOR">
        <FormattedMessage id="settings.roles.publishing.EDITOR" />
    </MenuItem>,
    <MenuItem key="CONTENT_CREATOR" value="CONTENT_CREATOR">
        <FormattedMessage id="settings.roles.publishing.CONTENT_CREATOR" />
    </MenuItem>,
    <MenuItem key="OBSERVER" value="OBSERVER">
        <FormattedMessage id="settings.roles.publishing.OBSERVER" />
    </MenuItem>,
]

const EngagementRoles = [
    <MenuItem key="NONE" value="">
        <FormattedMessage id="settings.roles.NONE" />
    </MenuItem>,
    <MenuItem key="MODERATOR" value="MODERATOR">
        <FormattedMessage id="settings.roles.engagement.MODERATOR" />
    </MenuItem>,
    <MenuItem key="REVIEWER" value="REVIEWER">
        <FormattedMessage id="settings.roles.engagement.REVIEWER" />
    </MenuItem>,
    <MenuItem key="OBSERVER" value="OBSERVER">
        <FormattedMessage id="settings.roles.engagement.OBSERVER" />
    </MenuItem>,
]

const AnalyticsRoles = [
    <MenuItem key="NONE" value="">
        <FormattedMessage id="settings.roles.NONE" />
    </MenuItem>,
    <MenuItem key="MANAGER" value="MANAGER">
        <FormattedMessage id="settings.roles.analytics.MANAGER" />
    </MenuItem>,
    <MenuItem key="ANALYST" value="ANALYST">
        <FormattedMessage id="settings.roles.analytics.ANALYST" />
    </MenuItem>,
]

export const UserFormProjectItem: FC<UserFormProjectItemProps> = ({ project, projectFormName }) => {
    const form = useForm()
    const formState = useFormState<UserFormValues>()
    const formValues = formState.values

    const currentProjectAccess = formValues.projects.find((p) => p.project_id === project.id)

    const validateExternalUserNotProjectAdmin = (value: string, values: UserFormValues) => {
        if (value === 'ADMIN' && values.external) {
            return 'users.external-no-project-admin'
        }

        return undefined
    }

    return (
        <Paper sx={{ mb: 2, p: 1 }} elevation={2}>
            <ListItem>
                <ListItemText>
                    <strong>{project.name}</strong>
                </ListItemText>
                <ListItemSecondaryAction>
                    <Box width="300px">
                        <ConnectedDropdown name={`${projectFormName}.role`} fullWidth validate={validateExternalUserNotProjectAdmin}>
                            <MenuItem value="">
                                <FormattedMessage id="projects.no-access" />
                            </MenuItem>
                            <MenuItem value="ADMIN">
                                <FormattedMessage id="projects.admin" />
                            </MenuItem>
                            <MenuItem value="USER">
                                <FormattedMessage id="projects.user" />
                            </MenuItem>
                        </ConnectedDropdown>
                    </Box>
                </ListItemSecondaryAction>
            </ListItem>
            {currentProjectAccess && currentProjectAccess.role === 'USER' && (
                <Box pb={2}>
                    <Fallback condition={project.data_sources.length === 0} messageId="general.no-datasources-found" marginTop={2}>
                        <FieldArray name={`${projectFormName}.access`}>
                            {({ fields }) => {
                                const datasources: UserDatasourceAccess[] = _.get(formState.values, `${projectFormName}.access`) || []
                                const handleChangeAll = (type: 'publishing_role' | 'engagement_role' | 'analytics_role', role: any) => {
                                    if (role !== 'CUSTOM') {
                                        datasources.forEach((ds, index: number) => {
                                            form.change(`${projectFormName}.access[${index}].${type}`, role)
                                        })
                                    }
                                }

                                const getValue = (type: 'publishing_role' | 'engagement_role' | 'analytics_role') => {
                                    const uniqueRoles = _.uniq(datasources.map((ds) => ds[type] || ''))

                                    if (uniqueRoles.length === 1) {
                                        return uniqueRoles[0]
                                    }

                                    return 'CUSTOM'
                                }

                                return (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'text.secondary', verticalAlign: 'bottom' }}>
                                                    <FormattedMessage id="accounts.datasources" />
                                                </TableCell>
                                                <TableCell sx={{ color: 'text.secondary', minWidth: 180 }}>
                                                    <FormattedMessage id="publishing.title" />
                                                </TableCell>
                                                <TableCell sx={{ color: 'text.secondary', minWidth: 180 }}>
                                                    <FormattedMessage id="engagement.title" />
                                                </TableCell>
                                                <TableCell sx={{ color: 'text.secondary', minWidth: 180 }}>
                                                    <FormattedMessage id="analytics.title" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>
                                                    <Select
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        value={getValue('publishing_role')}
                                                        onChange={(event) => handleChangeAll('publishing_role', event.target.value)}
                                                    >
                                                        {[
                                                            ...PublishingRoles,
                                                            <MenuItem key="CUSTOM" value="CUSTOM">
                                                                <FormattedMessage id="settings.roles.CUSTOM" />
                                                            </MenuItem>,
                                                        ]}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        value={getValue('engagement_role')}
                                                        onChange={(event) => handleChangeAll('engagement_role', event.target.value)}
                                                    >
                                                        {[
                                                            ...EngagementRoles,
                                                            <MenuItem key="CUSTOM" value="CUSTOM">
                                                                <FormattedMessage id="settings.roles.CUSTOM" />
                                                            </MenuItem>,
                                                        ]}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        value={getValue('analytics_role')}
                                                        onChange={(event) => handleChangeAll('analytics_role', event.target.value)}
                                                    >
                                                        {[
                                                            ...AnalyticsRoles,
                                                            <MenuItem key="CUSTOM" value="CUSTOM">
                                                                <FormattedMessage id="settings.roles.CUSTOM" />
                                                            </MenuItem>,
                                                        ]}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {fields.map((name, index) => {
                                                const datasource = project.data_sources.find((ds) => ds.id === fields.value[index].data_source_id)

                                                if (!datasource) {
                                                    return null
                                                }

                                                return (
                                                    <TableRow key={name} sx={{ border: 'none' }}>
                                                        <TableCell
                                                            sx={{
                                                                border: 'none',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <Box display="flex" alignItems="center" gap={2}>
                                                                {getIconForDatasourceType(datasource.type)}
                                                                <ListItemText primary={datasource.name} />
                                                            </Box>
                                                        </TableCell>

                                                        <TableCell sx={{ border: 'none' }}>
                                                            {datasource.type !== 'TALKWALKER_JSON' && (
                                                                <ConnectedDropdown name={`${name}.publishing_role`} fullWidth>
                                                                    {PublishingRoles}
                                                                </ConnectedDropdown>
                                                            )}
                                                        </TableCell>
                                                        <TableCell sx={{ border: 'none' }}>
                                                            {datasource.type !== 'TALKWALKER_JSON' && (
                                                                <ConnectedDropdown name={`${name}.engagement_role`} fullWidth>
                                                                    {EngagementRoles}
                                                                </ConnectedDropdown>
                                                            )}
                                                        </TableCell>
                                                        <TableCell sx={{ border: 'none' }}>
                                                            <ConnectedDropdown name={`${name}.analytics_role`} fullWidth>
                                                                {AnalyticsRoles}
                                                            </ConnectedDropdown>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                )
                            }}
                        </FieldArray>
                    </Fallback>
                </Box>
            )}
        </Paper>
    )
}
