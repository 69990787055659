import { FC } from 'react'
import { Box, Link, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { AccountSwitcher } from '../account-switcher/AccountSwitcher'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../core/slices/CoreSlice'
import { useKeycloak } from '../../core/hooks/useKeycloak'

type HeaderWithSwitcherProps = {}

export const HeaderWithSwitcher: FC<HeaderWithSwitcherProps> = () => {
    const keycloak = useKeycloak()
    const currentUser = useSelector(getCurrentUser)

    return (
        <Box display="flex" justifyContent="space-between" mt={8}>
            <Box textAlign="left">
                <Typography variant="h4">
                    <FormattedMessage id="general.welcome" values={{ name: `${currentUser.first_name} ${currentUser.last_name}` }} />
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="general.not-you" />
                    &nbsp;
                    <Link sx={{ cursor: 'pointer' }} onClick={() => keycloak.logout()}>
                        <FormattedMessage id="general.change-user" />
                    </Link>
                </Typography>
            </Box>

            <Box p={1} width={250} position="relative">
                {currentUser.accounts.length > 1 && <AccountSwitcher />}
            </Box>
        </Box>
    )
}
