import { Dashboard } from './Dashboard'
import { defaultMonthlyPeriod } from './TemplateDefaultTimePeriods'
import { DateRangePickerStaticRanges } from '../common/daterange-picker/DateRangePickerRanges'
import moment from 'moment'

type EffectiveTimePeriod = {
    startDate: string
    endDate: string
    compareStartDate: string
    compareEndDate: string
}

export const generateEffectiveTimePeriodForDashboard = (dashboard: Dashboard): EffectiveTimePeriod => {
    let startDate = dashboard.settings.fixed_start_date || defaultMonthlyPeriod.startDate
    let endDate = dashboard.settings.fixed_end_date || defaultMonthlyPeriod.endDate
    let compareStartDate = dashboard.settings.fixed_compare_start_date || defaultMonthlyPeriod.compareStartDate
    let compareEndDate = dashboard.settings.fixed_compare_end_date || defaultMonthlyPeriod.compareEndDate

    if (dashboard.settings.relative_time_period) {
        let range = DateRangePickerStaticRanges().find((range) => range.id === dashboard.settings.relative_time_period)

        if (range) {
            startDate = moment(range.range().startDate).format('YYYY-MM-DD')
            endDate = moment(range.range().endDate).format('YYYY-MM-DD')
        }
    }

    if (dashboard.settings.relative_compare_time_period) {
        let range = DateRangePickerStaticRanges().find((range) => range.id === dashboard.settings.relative_compare_time_period)
        if (range) {
            compareStartDate = moment(range.range().startDate).format('YYYY-MM-DD')
            compareEndDate = moment(range.range().endDate).format('YYYY-MM-DD')
        }
    }

    return { startDate, endDate, compareStartDate, compareEndDate }
}
