import { Box, styled } from '@mui/material'

export const NoSelect = styled(Box)(() => ({
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    UserSelect: 'none',
}))
