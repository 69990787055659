import { createContext, FC, forwardRef, HTMLAttributes, ReactElement, useContext, useMemo } from 'react'
import { countries } from '../core/init/init-localization-context'
import { Field } from 'react-final-form'
import { Autocomplete, Typography, useMediaQuery, useTheme } from '@mui/material'
import { StyledTextField } from './ConnectedTextField'
import { FormattedMessage } from 'react-intl'
import { useKeycloak } from '../core/hooks/useKeycloak'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { IdMap } from '../core/slices/DataSlice'

type ConnectedCountryDropdownProps = {
    name: string
    label?: string
    helperText?: string
    shrinkLabel?: boolean
    required?: boolean
    validate?: any
    fullWidth?: boolean
    disabled?: boolean
}

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props
    const dataSet = data[index]

    return (
        <Typography component="li" {...dataSet.props} noWrap style={style}>
            {dataSet.key}
        </Typography>
    )
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props

    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })

    const itemData = children as ReactElement[]
    const itemCount = itemData.length
    const itemSize = smUp ? 36 : 48

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize
        }
        return itemData.map(() => itemSize).reduce((a, b) => a + b, 0)
    }

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <FixedSizeList
                    itemData={itemData}
                    height={getHeight()}
                    width="100%"
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={itemSize}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </FixedSizeList>
            </OuterElementContext.Provider>
        </div>
    )
})

export const ConnectedCountryDropdown: FC<ConnectedCountryDropdownProps> = (props) => {
    const { name, label, required, helperText, shrinkLabel, validate, fullWidth, disabled } = props
    const keycloak = useKeycloak()
    const language = keycloak.locale

    const countryOptions = useMemo(() => {
        const countryCodes = Object.keys(countries.getAlpha2Codes())
        const countryOptionsByCountryCode = {} as IdMap<{ countryCode: string; countryName: string }>

        countryCodes.forEach((code) => {
            countryOptionsByCountryCode[code] = {
                countryCode: code,
                countryName: countries.getName(code, language),
            }
        })

        return countryOptionsByCountryCode
    }, [language])

    return (
        <Field name={name} validate={validate}>
            {({ input, meta }) => (
                <Autocomplete
                    disablePortal
                    disableListWrap
                    options={Object.values(countryOptions)}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    value={!input.value ? null : countryOptions[input.value]}
                    onChange={(event, value) => {
                        input.onChange(value === null ? null : value.countryCode)
                    }}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    ListboxComponent={ListboxComponent}
                    isOptionEqualToValue={(option, value) => option.countryCode === value.countryCode}
                    getOptionLabel={(option) => option.countryName}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            required={required}
                            label={<FormattedMessage id={label} />}
                            error={meta.error && (meta.touched || meta.dirty || meta.initial !== undefined)}
                            helperText={
                                (meta.touched || meta.dirty || meta.initial !== undefined) && Boolean(meta.error) ? (
                                    <FormattedMessage id={meta.error} />
                                ) : (
                                    helperText && <FormattedMessage id={helperText} />
                                )
                            }
                            InputLabelProps={{ shrink: shrinkLabel }}
                        />
                    )}
                />
            )}
        </Field>
    )
}
