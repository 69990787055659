import { AppBar, Box, Dialog, IconButton, Slide, SlideProps, styled, Toolbar, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { FC, forwardRef, PropsWithChildren } from 'react'

type FullscreenDialogProps = {
    open: boolean
    title?: string | JSX.Element
    actionIcon?: JSX.Element
    handleClose?: () => any
    keepMounted?: boolean
    disableEnforceFocus?: boolean
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 2,
    background: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
}))

export const FullScreenDialogTransition = forwardRef((props: SlideProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
))

export const FullscreenDialog: FC<PropsWithChildren<FullscreenDialogProps>> = (props) => {
    const { actionIcon, title, open, children, handleClose, keepMounted, disableEnforceFocus } = props

    return (
        <Dialog
            open={open}
            TransitionComponent={FullScreenDialogTransition}
            onClose={handleClose}
            keepMounted={keepMounted}
            disableEnforceFocus={disableEnforceFocus}
            fullScreen
        >
            <StyledAppBar>
                <Toolbar>
                    <Box display="flex" alignItems="center" width="100%">
                        <Box style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>{actionIcon}</Box>
                        <Box display="flex" alignItems="center">
                            {title && <Typography variant="h5">{title}</Typography>}
                        </Box>
                        <Box style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            {handleClose && (
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClose}>
                                    <Close />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </StyledAppBar>
            <Box marginTop={8} height="calc(100dvh - 64px)" overflow="always">
                {children}
            </Box>
        </Dialog>
    )
}
