import * as React from 'react'
import { FC } from 'react'
import { IconButton, ListSubheader, Tooltip } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { setAccountIdForProjectWizard } from '../settings/SettingsSlice'
import { isDarkMode, openFullscreenDialog } from '../core/slices/CoreSlice'
import { AddCircleOutlined } from '@mui/icons-material'
import { UserAccountAccess, UserProjectAccess } from '../settings/user-management/User'
import { useDispatch, useSelector } from 'react-redux'
import { Account } from '../settings/accounts/Account'
import { HmstrDispatch } from '../core/Store'
import { useHasAccountPermission } from '../core/hooks/useHasPermission'

type AccountAddProjectButtonProps = {
    accountAccess: UserAccountAccess
    account: Account
    renderSecondaryAction: (account: Account) => JSX.Element | null
    projectAccessesForAccount: UserProjectAccess[]
}

export const ProjectSelectAccountSubheader: FC<AccountAddProjectButtonProps> = ({
    accountAccess,
    account,
    renderSecondaryAction,
    projectAccessesForAccount,
}) => {
    const darkmode = useSelector(isDarkMode)
    const dispatch = useDispatch<HmstrDispatch>()
    const canCreateNewProjects = useHasAccountPermission('account.create_project', account.id)

    return (
        <ListSubheader
            sx={{
                display: 'flex',
                alignItems: 'center',
                borderLeft: 4,
                borderColor: darkmode ? 'grey.600' : 'grey.200',
            }}
            disableSticky
        >
            {account && renderSecondaryAction(account)}
            {account?.name}
            {canCreateNewProjects && projectAccessesForAccount.length === 0 && account?.status === 'ACTIVE' && (
                <Tooltip title={<FormattedMessage id="projects.new-in-account" />} placement="top">
                    <IconButton
                        color="secondary"
                        size="small"
                        sx={{ ml: 0.5, mb: -0.5 }}
                        onClick={() => {
                            dispatch(setAccountIdForProjectWizard(accountAccess.account_id))
                            dispatch(openFullscreenDialog('new-project'))
                        }}
                    >
                        <AddCircleOutlined fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </ListSubheader>
    )
}
