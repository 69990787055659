import moment from 'moment'
import * as _ from 'lodash'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { HmstrState } from '../core/Store'
import { getDatasourcesAsIdMap, getSelectedProject } from '../core/slices/CoreSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { getConversionData, IdMap } from '../core/slices/DataSlice'
import { fetchFacebookAdPerformanceData, fetchFacebookConversionData, fetchFacebookDailyAdInsights } from './ConversionActions'
import { ConversionTrackingAd, ConversionTrackingAdWithConversions } from './ConversionTrackingAd'
import { FacebookDailyInsights, FacebookDailyInsightsWithConversions, FacebookDailyInsightsWithPrevDay } from './FacebookDailyInsights'
import { Conversion } from './Conversion'

interface ConversionTrackingState {
    selectedTimePeriodStartDate: string
    selectedTimePeriodEndDate: string
    selectedDatasourceId: string
    conversionDataLoading: boolean
    adDataLoading: boolean
    dailyAdInsightsLoading: boolean
    attributionWindows: ('1d_click' | '1d_view' | '7d_click')[]
    campaignIds: string[]
    adsetIds: string[]
    conversionEvent: string
    conversionTrackingSearchTerm: string
    previewAdId?: string
    uploadConversionsDialogOpen: boolean
    uploadConversionsDatasourceId?: string
    adData: IdMap<ConversionTrackingAd>
    dailyAdInsights: IdMap<FacebookDailyInsights>
}

const urlParams = new URLSearchParams(window.location.search)

const initialState: ConversionTrackingState = {
    selectedTimePeriodStartDate: urlParams.get('startDate') || moment().startOf('month').format(),
    selectedTimePeriodEndDate: urlParams.get('endDate') || moment().endOf('month').format(),
    selectedDatasourceId: urlParams.get('datasourceId') || '',
    conversionDataLoading: true,
    adDataLoading: true,
    dailyAdInsightsLoading: true,
    campaignIds: [],
    adsetIds: [],
    attributionWindows: ['7d_click', '1d_view'],
    conversionTrackingSearchTerm: '',
    conversionEvent: urlParams.get('eventType') || 'offsite_conversion.fb_pixel_purchase',
    uploadConversionsDialogOpen: false,
    adData: {},
    dailyAdInsights: {},
}

export const ConversionTrackingSlice = createSlice({
    name: 'conversion_tracking',
    initialState,
    reducers: {
        selectDatasource: (state, action: PayloadAction<string>) => {
            state.conversionDataLoading = true
            state.selectedDatasourceId = action.payload
        },
        changeTimePeriod: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
            state.conversionDataLoading = true
            state.selectedTimePeriodStartDate = moment(action.payload.startDate).format('YYYY-MM-DD')
            state.selectedTimePeriodEndDate = moment(action.payload.endDate).format('YYYY-MM-DD')
        },
        changeAttributionWindows: (state, action: PayloadAction<ConversionTrackingState['attributionWindows']>) => {
            state.attributionWindows = action.payload
        },
        changeConversionEvent: (state, action: PayloadAction<string>) => {
            state.conversionDataLoading = true
            state.conversionEvent = action.payload
        },
        showAdPreview: (state, action: PayloadAction<string>) => {
            state.previewAdId = action.payload
        },
        closeAdPreview: (state) => {
            state.previewAdId = undefined
        },
        changeSearchTerm: (state, action: PayloadAction<string>) => {
            state.conversionTrackingSearchTerm = action.payload
        },
        openConversionUploadDialog: (state, action: PayloadAction<string>) => {
            state.uploadConversionsDialogOpen = true
            state.uploadConversionsDatasourceId = action.payload
        },
        closeConversionUploadDialog: (state) => {
            state.uploadConversionsDialogOpen = false
            state.uploadConversionsDatasourceId = undefined
        },
        selectCampaignIds: (state, action: PayloadAction<string[]>) => {
            state.campaignIds = action.payload
            state.adsetIds = []
        },
        selectAdsetIds: (state, action: PayloadAction<string[]>) => {
            state.adsetIds = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFacebookConversionData.pending, (state) => {
                state.conversionDataLoading = true
            })
            .addCase(fetchFacebookConversionData.fulfilled, (state) => {
                state.conversionDataLoading = false
            })
            .addCase(fetchFacebookAdPerformanceData.pending, (state) => {
                state.adDataLoading = true
            })
            .addCase(fetchFacebookAdPerformanceData.fulfilled, (state, action) => {
                state.adData = _.mapKeys(action.payload, 'id')
                state.adDataLoading = false
            })
            .addCase('core/selectProject', (state) => {
                state.selectedDatasourceId = ''
                state.conversionTrackingSearchTerm = ''
            })
            .addCase(fetchFacebookDailyAdInsights.pending, (state) => {
                state.dailyAdInsightsLoading = true
            })
            .addCase(fetchFacebookDailyAdInsights.fulfilled, (state, action) => {
                state.dailyAdInsights = _.mapKeys(action.payload, 'day')
                state.dailyAdInsightsLoading = false
            })
    },
})

export const getConversionTrackingDataLoading: Selector<HmstrState, boolean> = (state) => state.conversion_tracking.conversionDataLoading
export const getConversionTrackingAdDataLoading: Selector<HmstrState, boolean> = (state) => state.conversion_tracking.adDataLoading
export const getConversionTrackingDatasourceId: Selector<HmstrState, string> = (state) => state.conversion_tracking.selectedDatasourceId
export const getConversionTrackingStartDate: Selector<HmstrState, string> = (state) => state.conversion_tracking.selectedTimePeriodStartDate
export const getConversionTrackingEndDate: Selector<HmstrState, string> = (state) => state.conversion_tracking.selectedTimePeriodEndDate
export const getConversionTrackingAttributionWindows: Selector<HmstrState, ConversionTrackingState['attributionWindows']> = (state) =>
    state.conversion_tracking.attributionWindows
export const getConversionTrackingEvent: Selector<HmstrState, string> = (state) => state.conversion_tracking.conversionEvent
export const getConversionTrackingPreviewAdId: Selector<HmstrState, string | undefined> = (state) => state.conversion_tracking.previewAdId
export const getConversionTrackingSearchTerm: Selector<HmstrState, string> = (state) => state.conversion_tracking.conversionTrackingSearchTerm
export const getConversionTrackingUploadDialogOpen: Selector<HmstrState, boolean> = (state) => state.conversion_tracking.uploadConversionsDialogOpen
export const getConversionTrackingUploadDialogDatasourceId: Selector<HmstrState, string | undefined> = (state) =>
    state.conversion_tracking.uploadConversionsDatasourceId
export const getConversionTrackingAdData: Selector<HmstrState, HmstrState['conversion_tracking']['adData']> = (state) => state.conversion_tracking.adData
export const getConversionTrackingSelectedCampaignIds: Selector<HmstrState, HmstrState['conversion_tracking']['campaignIds']> = (state) =>
    state.conversion_tracking.campaignIds
export const getConversionTrackingSelectedAdsetIds: Selector<HmstrState, HmstrState['conversion_tracking']['adsetIds']> = (state) =>
    state.conversion_tracking.adsetIds
export const getConversionTrackingDailyAdInsightsLoading: Selector<HmstrState, HmstrState['conversion_tracking']['dailyAdInsightsLoading']> = (state) =>
    state.conversion_tracking.dailyAdInsightsLoading
export const getConversionTrackingDailyAdInsights: Selector<HmstrState, HmstrState['conversion_tracking']['dailyAdInsights']> = (state) =>
    state.conversion_tracking.dailyAdInsights

export const getConversionTrackingDatasource = createSelector(
    [getConversionTrackingDatasourceId, getDatasourcesAsIdMap],
    (conversionTrackingDatasourceId, datasources): ProjectDatasource | undefined => datasources[conversionTrackingDatasourceId]
)

export const getFacebookAdAccounts = createSelector([getSelectedProject], (project) =>
    (project ? project.data_sources : []).filter((ds) => ds.type === 'FACEBOOK_AD_ACCOUNT')
)

export const getFacebookAdAccountsWithConversionTracking = createSelector([getFacebookAdAccounts], (adAccounts) =>
    adAccounts.filter((acc) => acc.conversion_tracking)
)

export const getFacebookAdAccountsWithoutConversionTracking = createSelector([getFacebookAdAccounts], (adAccounts) =>
    adAccounts.filter((acc) => !acc.conversion_tracking)
)

export const getFacebookAdAccountForUpload = createSelector(
    [getFacebookAdAccountsWithConversionTracking, getConversionTrackingUploadDialogDatasourceId],
    (adAccounts, datasourceId) => adAccounts.find((acc) => acc.id === datasourceId)
)

export const getConversionOverviewData = createSelector(
    [
        getConversionData,
        getConversionTrackingDatasource,
        getConversionTrackingStartDate,
        getConversionTrackingEndDate,
        getConversionTrackingAttributionWindows,
        getConversionTrackingEvent,
        getConversionTrackingSearchTerm,
    ],
    (conversionData, datasource, startDate, endDate, attributionWindows, conversionTrackingEvent, searchTerm) => {
        const conversionDataForDatasource = _.flatten(_.values(conversionData[datasource?.id || '']))
        const start = moment(startDate)
        const end = moment(endDate)
        const filteredConversionData = conversionDataForDatasource.filter((conversionData) => {
            if (conversionTrackingEvent !== conversionData.conversion_event) {
                return false
            }

            if (!!searchTerm && !conversionData.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return false
            }

            return _.sum(_.map(attributionWindows, (aw) => conversionData[`conversions_${aw}`] || 0)) > 0
        })

        return _.chain(filteredConversionData)
            .groupBy('day')
            .pickBy((value, day) => moment(day).isBetween(start, end, 'day', '[]'))
            .mapValues((values) => _.groupBy(values, 'hour'))
            .value()
    }
)

export const getConversionAdData = createSelector(
    [
        getConversionData,
        getConversionTrackingDatasource,
        getConversionTrackingStartDate,
        getConversionTrackingEndDate,
        getConversionTrackingAttributionWindows,
        getConversionTrackingEvent,
        getConversionTrackingSearchTerm,
    ],
    (conversionData, datasource, startDate, endDate, attributionWindows, conversionTrackingEvent, searchTerm) => {
        const conversionDataForDatasource = _.flatten(_.values(conversionData[datasource?.id || '']))
        const start = moment(startDate)
        const end = moment(endDate)
        const filteredConversionData = conversionDataForDatasource.filter((conversionData) => {
            if (conversionTrackingEvent !== conversionData.conversion_event) {
                return false
            }

            if (!!searchTerm && !conversionData.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return false
            }

            return _.sum(_.map(attributionWindows, (aw) => conversionData[`conversions_${aw}`] || 0)) > 0
        })

        return _.chain(filteredConversionData)
            .filter((c) => moment(c.day).isBetween(start, end, 'day', '[]'))
            .groupBy('ad_id')
            .value()
    }
)

export const getConversionTrackingCampaigns = createSelector(
    [getConversionTrackingAdData],
    (
        adData
    ): {
        id: string
        name: string
    }[] => {
        return _.chain(adData)
            .map((ad) => ({ id: ad.campaign_id, name: ad.campaign_name }))
            .uniqWith(_.isEqual)
            .value()
    }
)

export const getConversionTrackingAdsets = createSelector(
    [getConversionTrackingAdData, getConversionTrackingSelectedCampaignIds],
    (adData, selectedCampaignIds): { id: string; name: string }[] => {
        let filteredAdData = _.values(adData)

        if (selectedCampaignIds.length > 0) {
            filteredAdData = _.filter(_.values(adData), (ad) => selectedCampaignIds.includes(ad.campaign_id))
        }

        return _.chain(filteredAdData)
            .map((ad) => ({ id: ad.adset_id, name: ad.adset_name }))
            .uniqWith(_.isEqual)
            .value()
    }
)

export const getConversionTrackingFilteredAdDataWithConversions = createSelector(
    [
        getConversionAdData,
        getConversionTrackingAdData,
        getConversionTrackingAttributionWindows,
        getConversionTrackingSelectedCampaignIds,
        getConversionTrackingSelectedAdsetIds,
    ],
    (conversionData, adDatabyId, attributionWindows, campaignIds, adsetIds): ConversionTrackingAdWithConversions[] => {
        return Object.keys(adDatabyId)
            .filter((adId) => {
                const ad = adDatabyId[adId]

                if (campaignIds.length > 0 && !campaignIds.includes(ad.campaign_id)) {
                    return false
                }

                return !(adsetIds.length > 0 && !adsetIds.includes(ad.adset_id))
            })
            .map((adId) => {
                const ad = adDatabyId[adId]
                const adConversions = conversionData[adId]

                const conversion_count = _.sum(attributionWindows.map((aw) => _.sumBy(adConversions, 'conversions_' + aw)))
                const actualConversions = _.flatMap(attributionWindows, (aw) => _.flatMap(adConversions, 'actual_conversions_' + aw))
                const actualConversionCount = actualConversions.length
                const trialStarted = _.sumBy(actualConversions, (c) => parseInt(c['metadata']['Testphase gestartet'] || '0'))
                const qualityScore =
                    conversion_count > 0 && actualConversionCount > 0
                        ? _.sumBy(actualConversions, (c) => parseInt(c['metadata']['quality score (0-3)'])) / actualConversionCount
                        : -1

                return {
                    ...ad,
                    ctr: ad.impressions > 0 ? ad.clicks / ad.impressions : 0,
                    outbound_ctr: ad.impressions > 0 ? ad.outbound_clicks / ad.impressions : 0,
                    cost_per_click: ad.clicks > 0 ? ad.spend / ad.clicks : 0,
                    cost_per_outgoing_click: ad.outbound_clicks > 0 ? ad.spend / ad.outbound_clicks : 0,
                    cost_per_conversion: conversion_count > 0 ? ad.spend / conversion_count : 0,
                    fb_conversion_count: conversion_count,
                    actual_conversion_count: actualConversionCount,
                    conversions: actualConversions,
                    qualityScore,
                    trial_started: trialStarted,
                    trial_started_rate: actualConversionCount > 0 ? trialStarted / actualConversionCount : 0,
                }
            })
    }
)

export const getConversionDailyData = createSelector(
    [
        getConversionData,
        getConversionTrackingDatasource,
        getConversionTrackingStartDate,
        getConversionTrackingEndDate,
        getConversionTrackingAttributionWindows,
        getConversionTrackingEvent,
    ],
    (conversionData, datasource, startDate, endDate, attributionWindows, conversionTrackingEvent) => {
        const conversionDataForDatasource = _.flatten(_.values(conversionData[datasource?.id || '']))
        const start = moment(startDate)
        const end = moment(endDate)
        const filteredConversionData = conversionDataForDatasource.filter((conversionData) => {
            if (conversionTrackingEvent !== conversionData.conversion_event) {
                return false
            }

            return _.sum(_.map(attributionWindows, (aw) => conversionData[`conversions_${aw}`] || 0)) > 0
        })

        return _.chain(filteredConversionData)
            .groupBy('day')
            .pickBy((value, day) => moment(day).isBetween(start, end, 'day', '[]'))
            .value()
    }
)

export const getConversionTrackingFilteredDayPerformance = createSelector(
    [getConversionDailyData, getConversionTrackingDailyAdInsights, getConversionTrackingAttributionWindows],
    (conversionData, dailyAdInsights, attributionWindows): FacebookDailyInsightsWithPrevDay[] => {
        const days = Object.keys(dailyAdInsights)

        if (days.length === 0) {
            return []
        }

        const convertDay = (day: string): FacebookDailyInsightsWithConversions => {
            const insights = dailyAdInsights[day]

            const impressions = parseInt(insights.metrics.impressions as any)
            const clicks = parseInt(insights.metrics.clicks as any)
            const spend = parseFloat(insights.metrics.spend as any)

            const conversionsForDay = conversionData[day]

            const conversion_count = conversionsForDay ? _.sum(attributionWindows.map((aw) => _.sumBy(conversionsForDay, 'conversions_' + aw))) : 0
            const actualConversions = conversionsForDay ? _.flatMap(attributionWindows, (aw) => _.flatMap(conversionsForDay, 'actual_conversions_' + aw)) : []
            const landingPageViewActions = insights.metrics.actions.find((a) => a.action_type === 'landing_page_view') || {
                '1d_click': '0',
                '7d_click': '0',
                '1d_view': '0',
            }
            const landingPageViews = _.sum(attributionWindows.map((aw) => parseInt(landingPageViewActions[aw] || '0')))
            const actualConversionCount = actualConversions.length
            const qualityScore =
                conversion_count > 0 && actualConversionCount > 0 && actualConversions
                    ? _.sumBy(actualConversions, (c) => parseInt(c['metadata']['quality score (0-3)'])) / actualConversionCount
                    : -1
            const outbound_clicks = _.sum(insights.metrics.outbound_clicks.map((oc) => parseInt(oc.value)))
            const trialStarted = _.sumBy(actualConversions, (c) => parseInt(c['metadata']['Testphase gestartet'] || '0'))

            return {
                ...insights,
                impressions,
                clicks,
                spend,
                outbound_clicks,
                landing_page_views: landingPageViews,
                landing_page_conversion_rate: landingPageViews > 0 ? conversion_count / landingPageViews : 0,
                cpm: impressions > 0 ? spend / (impressions / 1000) : 0,
                ctr: impressions > 0 ? clicks / impressions : 0,
                outbound_ctr: impressions > 0 ? outbound_clicks / impressions : 0,
                cost_per_click: clicks > 0 ? spend / clicks : 0,
                cost_per_outgoing_click: outbound_clicks > 0 ? spend / outbound_clicks : 0,
                cost_per_conversion: conversion_count > 0 ? spend / conversion_count : 0,
                fb_conversion_count: conversion_count,
                trial_started: trialStarted,
                trial_started_rate: conversion_count > 0 ? trialStarted / conversion_count : 0,
                actual_conversion_count: actualConversionCount,
                conversions: actualConversions,
                qualityScore,
            }
        }

        let previousDay = convertDay(days[0])

        return days.map((day) => {
            const dayWithConversions = convertDay(day)
            const dayWithPrev = {
                ...dayWithConversions,
                previousDay: previousDay,
            }

            previousDay = dayWithConversions

            return dayWithPrev
        })
    }
)

const sumAdData = (
    adData: {
        impressions: number
        spend: number
        clicks: number
        outbound_clicks: number
        fb_conversion_count: number
        actual_conversion_count: number
        landing_page_views?: number
        landing_page_rate?: number
        trial_started: number
        trial_started_rate: number
        qualityScore: number
        conversions: Conversion[]
    }[]
) => {
    let totalImpressions = 0,
        totalSpend = 0,
        totalClicks = 0,
        totalOutboundClicks = 0,
        totalConversionCount = 0,
        totalMatches = 0,
        totalQuality = 0,
        totalLandingPageViews = 0,
        totalTrialStarted = 0
    let conversions = [] as Conversion[]

    adData.forEach((ad) => {
        totalImpressions += ad.impressions
        totalSpend += ad.spend
        totalClicks += ad.clicks
        totalOutboundClicks += ad.outbound_clicks
        totalConversionCount += ad.fb_conversion_count
        totalMatches += ad.actual_conversion_count
        totalLandingPageViews += ad.landing_page_views || 0
        totalTrialStarted += ad.trial_started
        if (ad.qualityScore >= 0) {
            totalQuality += _.sumBy(ad.conversions, (c) => parseInt(c['metadata']['quality score (0-3)']) || 0)
        }
        conversions = [...conversions, ...ad.conversions]
    })

    return {
        impressions: totalImpressions,
        spend: totalSpend,
        clicks: totalClicks,
        cpm: totalImpressions > 0 ? totalSpend / (totalImpressions / 1000) : 0,
        ctr: totalImpressions > 0 ? totalClicks / totalImpressions : 0,
        outbound_clicks: totalOutboundClicks,
        outbound_ctr: totalImpressions > 0 ? totalOutboundClicks / totalImpressions : 0,
        conversion_count: totalConversionCount,
        landing_page_views: totalLandingPageViews,
        landing_page_rate: totalLandingPageViews > 0 ? totalConversionCount / totalLandingPageViews : 0,
        matches: totalMatches,
        cost_per_conversion: totalConversionCount > 0 ? totalSpend / totalConversionCount : 0,
        quality: totalMatches > 0 ? totalQuality / totalMatches : 0,
        trial_started: totalTrialStarted,
        trial_started_rate: totalMatches > 0 ? totalTrialStarted / totalMatches : 0,
    }
}

export const getConversionTrackingDailyTotal = createSelector([getConversionTrackingFilteredDayPerformance], (dailyData) => {
    return sumAdData(dailyData)
})

export const getConversionTrackingAdDataTotal = createSelector([getConversionTrackingFilteredAdDataWithConversions], (adData) => {
    return sumAdData(adData)
})

export const {
    selectDatasource,
    changeTimePeriod,
    changeAttributionWindows,
    changeConversionEvent,
    showAdPreview,
    closeAdPreview,
    changeSearchTerm,
    openConversionUploadDialog,
    closeConversionUploadDialog,
    selectAdsetIds,
    selectCampaignIds,
} = ConversionTrackingSlice.actions

export const ConversionTrackingReducer = ConversionTrackingSlice.reducer
