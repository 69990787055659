import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { Box, Breadcrumbs, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import BaseImageEditor from '@toast-ui/react-image-editor'
import { useKeycloak } from '../core/hooks/useKeycloak'
import { GermanLocale } from './ImageEditorLocales'
import { FullScreenDialogTransition } from '../common/fullscreen-dialog/FullscreenDialog'
import { ConfirmDialog } from '../common/confirm-dialog/ConfirmDialog'
import { v4 as uuidv4 } from 'uuid'

import 'tui-image-editor/dist/tui-image-editor.css'
import './publishing-edit-file-dialog.css'
import { PublishingMedia } from './PublishingMedia'
import { useSelector } from 'react-redux'
import { getErrorsForCommonFile, getPostDialogSelectedNetwork } from './PublishingSlice'
import { useFormState } from 'react-final-form'
import { PublishingFormValues } from './PublishingForm'
import { imageLimitations } from './FormValidators'
import { IdMap } from '../core/slices/DataSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { renderToStaticMarkup } from 'react-dom/server'
import { ImageEditorCropIcon } from './post-preview/image-editor-icons/ImageEditorIcons'

type PublishingEditFileDialogProps = {
    open: boolean
    media?: PublishingMedia
    onClose: () => any
    onSave: (media: PublishingMedia, file: File) => any
    datasourceTypes: IdMap<ProjectDatasource[]>
}

const whiteTheme = {
    'common.bisize.width': '0',
    'common.bisize.height': '0',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#fafafa',
    'common.border': '0px solid #c1c1c1',

    // header
    'header.backgroundImage': 'none',
    'header.backgroundColor': 'transparent',
    'header.border': '0px',

    // main icons
    'menu.normalIcon.color': 'rgba(0, 0, 0, 0.87)',
    'menu.activeIcon.color': 'rgba(0, 0, 0, 0.87)',
    'menu.disabledIcon.color': 'rgba(0, 0, 0, 0.16)',
    'menu.hoverIcon.color': 'rgba(0, 0, 0, 0.87)',
    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    // submenu icons
    'submenu.normalIcon.color': 'rgba(0, 0, 0, 0.26)',
    'submenu.activeIcon.color': 'rgba(0, 0, 0, 0.87)',
    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu primary color
    'submenu.backgroundColor': 'transparent',
    'submenu.partition.color': '#e5e5e5',

    // submenu labels
    'submenu.normalLabel.color': 'rgba(0, 0, 0, 0.26)',
    'submenu.normalLabel.fontWeight': '400',
    'submenu.activeLabel.color': 'rgba(0, 0, 0, 0.87)',
    'submenu.activeLabel.fontWeight': '400',

    // checkbox style
    'checkbox.border': '1px solid rgba(0, 0, 0, 0.26)',
    'checkbox.backgroundColor': '#fff',

    // rango style
    'range.pointer.color': '#2c2e33',
    'range.bar.color': '#ccc',
    'range.subbar.color': '#606060',

    'range.disabledPointer.color': '#d3d3d3',
    'range.disabledBar.color': 'rgba(85,85,85,0.06)',
    'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

    'range.value.color': 'rgba(0, 0, 0, 0.87)',
    'range.value.fontWeight': '600',
    'range.value.fontSize': '11px',
    'range.value.border': '0',
    'range.value.backgroundColor': '#fafafa',
    'range.title.color': 'rgba(0, 0, 0, 0.87)',
    'range.title.fontWeight': '600',

    // colorpicker style
    'colorpicker.button.border': '0px',
    'colorpicker.title.color': '#000',
}

const rImageType = /data:(image\/.+);base64,/

function base64ToFile(data: string, media: PublishingMedia) {
    let mimeString = ''
    let raw: any, uInt8Array: any, i: any, rawLength: any

    raw = data.replace(rImageType, function (header, imageType) {
        mimeString = imageType

        return ''
    })

    raw = atob(raw)
    rawLength = raw.length
    uInt8Array = new Uint8Array(rawLength) // eslint-disable-line

    for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i)
    }

    const newFile: any = new File([uInt8Array], media.name, { type: mimeString })
    newFile['id'] = media.id
    newFile['reloadHash'] = uuidv4()
    return newFile
}

export const PublishingEditFileDialog: FC<PublishingEditFileDialogProps> = ({ open, media, onClose, onSave, datasourceTypes }) => {
    const formState = useFormState<PublishingFormValues>()
    const keycloak = useKeycloak()
    const editorRef = useRef<any>(null)
    const [closeHint, setCloseHint] = useState(false)
    const [saving, setSaving] = useState(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [askForResize, setAskForResize] = useState(false)
    const imageErrors = useSelector(getErrorsForCommonFile)
    const postType = useSelector(getPostDialogSelectedNetwork)
    const [minHeight, setMinHeight] = useState(0)
    const [minWidth, setMinWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const intervalTryLoad = useRef<NodeJS.Timer | null>(null)
    const [retries, setRetries] = useState(0)

    const handleCrop = (ratio: number) => {
        editorRef.current.getInstance().setCropzoneRect(ratio)
        const applyButton = document.querySelector('.tui-image-editor-button.apply') as HTMLElement
        if (applyButton) {
            // Reload canvas size in state after crop was applied, to see if crop affected if the image is smaller than the minimum sizes (timeout due to delays of crop processing)
            applyButton.onclick = () => {
                setTimeout(() => {
                    setHeight(editorRef.current.getInstance()._graphics._canvas.height)
                    setWidth(editorRef.current.getInstance()._graphics._canvas.width)
                }, 200)
            }
        }
    }

    const createNewCropButton = (name: string, ratio: number, icon: JSX.Element, label: string, insertFirst: boolean = false) => {
        const buttonAlreadyExists = !!document.getElementById(name)
        if (!buttonAlreadyExists) {
            const newEditorButton = document.createElement('div')
            const newLabel = document.createElement('label')
            const parser = new DOMParser()
            const css = `#${name} svg{
                            color: rgba(0, 0, 0, 0.26)
                        }
                        
                        #${name}:hover svg, #${name}.active svg{
                            color: rgba(0, 0, 0, 0.87)
                        }
                        
                        .tie-crop-preset-button{
                            padding: 0 8px !important;
                        }`

            const style = document.createElement('style')
            style.innerHTML = css
            document.getElementsByTagName('head')[0].appendChild(style)
            const newIcon = parser.parseFromString('<div>' + renderToStaticMarkup(icon) + '</div>', 'text/html').body.firstElementChild // convert react node to html string and parse it to get an Element
            newLabel.innerText = label
            if (newIcon) {
                newEditorButton.appendChild(newIcon)
            }
            newEditorButton.appendChild(newLabel)
            newEditorButton.className = 'tui-image-editor-button preset'
            newEditorButton.id = name
            const cropZoneButtons = document.getElementsByClassName('tie-crop-preset-button').item(0)
            newEditorButton.onclick = () => {
                if (cropZoneButtons) {
                    const previouslyActiveButton = cropZoneButtons.querySelectorAll('div.active')
                    if (previouslyActiveButton) {
                        previouslyActiveButton.forEach((el) => {
                            el.classList.remove('active') // clear active from default buttons, so only custom button will be styled as active
                        })
                    }
                    newEditorButton.classList.add('active')
                }
                handleCrop(ratio) // cropping logic
            }
            if (cropZoneButtons) {
                if (insertFirst) {
                    cropZoneButtons.insertBefore(newEditorButton, cropZoneButtons.children[1]) // first item should always stay set as the custom aspect ratio
                } else {
                    cropZoneButtons.appendChild(newEditorButton)
                }
            }
        }
    }
    const tryLoadImage = () => {
        if (editorRef.current && media && media._links.file.href && loading) {
            setRetries(retries + 1)
            if (editorRef.current.getInstance().isEmptyUndoStack()) {
                editorRef.current
                    .getInstance()
                    .loadImageFromURL(media?._links.file.href, media.name)
                    .then(() => {
                        setLoading(false)
                        editorRef.current.getInstance().ui.activeMenuEvent()
                        editorRef.current.getInstance().clearUndoStack()
                        createNewCropButton(
                            'link-thumbnail',
                            1.91,
                            <ImageEditorCropIcon
                                sx={{
                                    fontSize: 32,
                                }}
                            />,
                            'Link',
                            true
                        )
                        createNewCropButton(
                            'crop3to4',
                            0.75,
                            <ImageEditorCropIcon
                                sx={{
                                    fontSize: 32,
                                }}
                            />,
                            '3:4'
                        )
                        createNewCropButton(
                            'crop9to16',
                            0.5625,
                            <ImageEditorCropIcon
                                sx={{
                                    fontSize: 32,
                                }}
                            />,
                            '9:16'
                        )
                    })
            }
        }
    }

    const resizeImage = (instance: any) => {
        if (postType) {
            setHeight(instance._graphics._canvas.height)
            setWidth(instance._graphics._canvas.width)
            if (minWidth && minHeight) {
                let ratio = Math.max(minWidth / width, minHeight / height)
                instance.resize({ width: width * ratio, height: height * ratio })
            }
            handleCloseDialog()
        }
    }
    const handleCloseDialog = () => {
        setAskForResize(false)
    }

    useEffect(() => {
        if (editorRef.current && !loading) {
            if (formState.values.customize_posts_by_network) {
                if (media) {
                    imageErrors[media.id]?.forEach((error) => {
                        if (error) {
                            if (error.network) {
                                if (error.network === postType && editorRef.current) {
                                    const wantedMinHeight = media.link_preview
                                        ? imageLimitations[postType].minHeightLinkPreview
                                        : imageLimitations[postType].minHeight
                                    const wantedMinWidth = media.link_preview
                                        ? imageLimitations[postType].minWidthLinkPreview
                                        : imageLimitations[postType].minWidth
                                    setMinHeight(wantedMinHeight)
                                    setMinWidth(wantedMinWidth)
                                    setHeight(editorRef.current.getInstance()._graphics._canvas.height)
                                    setWidth(editorRef.current.getInstance()._graphics._canvas.width)
                                    if (
                                        editorRef.current.getInstance()._graphics._canvas.height < wantedMinHeight ||
                                        editorRef.current.getInstance()._graphics._canvas.width < wantedMinWidth
                                    ) {
                                        setAskForResize(true)
                                    }
                                }
                            }
                        }
                    })
                }
            } else {
                if (media && datasourceTypes) {
                    imageErrors[media.id]?.forEach((error) => {
                        if (error) {
                            const allMinHeights: number[] = []
                            const allMinWidths: number[] = []
                            Object.keys(datasourceTypes).forEach((datasourceType) => {
                                allMinHeights.push(
                                    media.link_preview ? imageLimitations[datasourceType].minHeightLinkPreview : imageLimitations[datasourceType].minHeight
                                )
                                allMinWidths.push(
                                    media.link_preview ? imageLimitations[datasourceType].minWidthLinkPreview : imageLimitations[datasourceType].minWidth
                                )
                            })
                            setMinHeight(allMinHeights.reduce((a, b) => Math.max(a, b), -Infinity))
                            setMinWidth(allMinWidths.reduce((a, b) => Math.max(a, b), -Infinity))
                            setHeight(editorRef.current.getInstance()._graphics._canvas.height)
                            setWidth(editorRef.current.getInstance()._graphics._canvas.width)
                            if (
                                editorRef.current.getInstance()._graphics._canvas.height < allMinHeights.reduce((a, b) => Math.max(a, b), -Infinity) ||
                                editorRef.current.getInstance()._graphics._canvas.width < allMinWidths.reduce((a, b) => Math.max(a, b), -Infinity)
                            ) {
                                setAskForResize(true)
                            }
                        }
                    })
                }
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, minHeight, minWidth, height, width, datasourceTypes])

    useEffect(() => {
        if (retries < 5 && open && loading) {
            intervalTryLoad.current = setInterval(tryLoadImage, 200)
        } else if (intervalTryLoad.current && open && !loading) {
            clearInterval(intervalTryLoad.current)
        } else if (intervalTryLoad.current) {
            clearInterval(intervalTryLoad.current)
        }
        return () => {
            if (intervalTryLoad.current) {
                clearInterval(intervalTryLoad.current)
            }
            if (retries > 0) {
                setRetries(0)
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retries, open, loading])

    const handleApplyCropAndSave = () => {
        if (editorRef.current) {
            try {
                const rect = editorRef.current.getInstance().getCropzoneRect()
                editorRef.current
                    .getInstance()
                    .crop(rect)
                    .then(() => {
                        handleSave()
                    })
            } catch {}
        }
    }

    const handleClose = () => {
        const hasNoChanges = editorRef.current && editorRef.current.getInstance().isEmptyUndoStack()

        if (closeHint || hasNoChanges) {
            setLoading(true)
            onClose()
            setCloseHint(false)
        } else {
            setCloseHint(true)
        }
    }

    const calculateMaxCanvasHeight = () => {
        return window.innerHeight - 150
    }

    const calculateMaxCanvasWidth = () => {
        return window.innerWidth - 600
    }

    const handleSave = () => {
        if (editorRef.current && media) {
            const editorInstance = editorRef.current.getInstance()
            const dataURL = editorInstance.toDataURL({ format: 'jpeg', quality: 1 })
            const newFile = base64ToFile(dataURL, media)
            setSaving(true)
            setLoading(true)
            onSave(media, newFile)
        }
    }

    const handleChange = () => {
        if (editorRef.current && media) {
            const cropNotApplied = () => {
                if (editorRef.current) {
                    try {
                        const rect = editorRef.current.getInstance().getCropzoneRect()
                        return rect.width > 1 && rect.height > 1
                    } catch {
                        return false
                    }
                }
            }

            const needsToApply = cropNotApplied()
            if (!needsToApply) {
                handleSave()
            } else {
                handleApplyCropAndSave()
            }
        }
    }

    useEffect(() => {
        const recalculateCanvasHeight = () => {
            if (editorRef.current) {
                const editorInstance = editorRef.current.getInstance()
                editorInstance.resizeCanvasDimension({
                    height: calculateMaxCanvasHeight(),
                    width: calculateMaxCanvasWidth(),
                })
            }
        }

        window.addEventListener('resize', recalculateCanvasHeight)

        return () => {
            window.removeEventListener('resize', recalculateCanvasHeight)
            setLoading(true)
        }
    }, [])

    useEffect(() => {
        setSaving(false)
        if (open) {
            setRetries(0)
            setLoading(true)
        }
    }, [open])

    return (
        <Dialog
            open={open}
            fullScreen
            TransitionComponent={FullScreenDialogTransition}
            keepMounted={false}
            disableEnforceFocus
            onClose={handleClose}
            sx={{
                '.tui-image-editor-button.apply.active label': {
                    cursor: 'pointer',
                },
            }}
        >
            {media ? (
                <>
                    <Box position="fixed" left={90} top={0} p={2} zIndex={1}>
                        <Breadcrumbs>
                            <Typography component="span" variant="h5">
                                <FormattedMessage id="publishing.edit-picture" />
                            </Typography>
                            <Typography component="span" variant="h6">
                                {media.name}
                            </Typography>
                        </Breadcrumbs>
                    </Box>

                    <BaseImageEditor
                        ref={editorRef as any}
                        includeUI={
                            {
                                theme: whiteTheme,
                                locale: keycloak.locale === 'de' ? GermanLocale : {},
                                menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'],
                                initMenu: 'filter',
                                uiSize: {
                                    width: '100vw',
                                    height: '100dvh',
                                },
                                menuBarPosition: 'left',
                            } as any
                        }
                        cssMaxHeight={calculateMaxCanvasHeight()}
                        cssMaxWidth={calculateMaxCanvasWidth()}
                        selectionStyle={{
                            cornerSize: 20,
                            rotatingPointOffset: 70,
                        }}
                        usageStatistics={false}
                    />

                    {loading && !saving && (
                        <Box
                            height={'100dvh'}
                            width={'100vw'}
                            position={'fixed'}
                            top={0}
                            left={0}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <CircularProgress variant={'indeterminate'} color={'secondary'} />
                        </Box>
                    )}
                </>
            ) : null}

            <ConfirmDialog
                open={closeHint}
                onClose={() => setCloseHint(false)}
                onConfirm={handleClose}
                abortText="publishing.edit-picture-abort"
                confirmText="publishing.edit-picture-discard"
            >
                <FormattedMessage id="publishing.edit-picture-close-are-you-sure" />
            </ConfirmDialog>

            <DialogActions sx={{ position: 'absolute', bottom: 0, right: 0, background: 'transparent' }}>
                <Button onClick={handleClose}>
                    <FormattedMessage id="general.close" />
                </Button>
                <Button variant="contained" color="secondary" disabled={saving} onClick={handleChange}>
                    {saving ? <CircularProgress size={'20px'} sx={{ mx: 1 }} color={'secondary'} /> : <FormattedMessage id="general.save" />}
                </Button>
            </DialogActions>
            <Dialog open={askForResize} onClose={handleCloseDialog}>
                <DialogTitle>
                    <FormattedMessage id={'publishing.resize-image-title'} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage
                            id={'publishing.errors.min-size'}
                            values={{
                                minheight: minHeight,
                                minwidth: minWidth,
                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        <FormattedMessage id={'general.cancel'} />
                    </Button>
                    <Button onClick={() => resizeImage(editorRef.current.getInstance())} autoFocus color={'secondary'} variant={'contained'}>
                        <FormattedMessage id={'publishing.resize-image-resize'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    )
}
