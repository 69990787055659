import * as _ from 'lodash'
import * as React from 'react'
import { FC } from 'react'
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../core/slices/CoreSlice'
import { ProjectSelect } from './ProjectSelect'

type SecondaryNavigationHeaderProps = {}

export const SecondaryNavigationHeader: FC<SecondaryNavigationHeaderProps> = () => {
    const location = useLocation()
    const currentUser = useSelector(getCurrentUser)
    const projectsByAccount = _.chain(currentUser?.accounts || [])
        .mapKeys('account_id')
        .mapValues((acc) => acc.projects)
        .value()

    const projectCount = _.flatten(Object.values(projectsByAccount)).length
    const multipleProjectsOrAccounts = currentUser.accounts.length > 1 || projectCount > 1

    const generateTitle = () => {
        const pathName = location.pathname

        if (pathName.includes('/analytics')) {
            return 'analytics.title'
        } else if (pathName.includes('/publishing')) {
            return 'publishing.title'
        } else if (pathName.includes('/engagement')) {
            return 'engagement.title'
        } else if (pathName.includes('/settings')) {
            return 'settings.title'
        } else {
            return 'hmstr'
        }
    }

    const generateDescription = () => {
        const pathName = location.pathname

        if (pathName.includes('/analytics')) {
            return 'analytics.description'
        } else if (pathName.includes('/publishing')) {
            return 'publishing.description'
        } else if (pathName.includes('/engagement')) {
            return 'engagement.description'
        } else if (pathName.includes('/settings')) {
            return 'settings.description'
        } else {
            return 'hmstr'
        }
    }

    return (
        <List>
            <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <ListItemText
                    primary={
                        <Typography variant="overline" fontSize={16} fontWeight={800}>
                            <FormattedMessage id={generateTitle()} />
                        </Typography>
                    }
                />
                {multipleProjectsOrAccounts ? (
                    <ProjectSelect />
                ) : (
                    <Box>
                        <Typography variant="caption">
                            <FormattedMessage id={generateDescription()} />
                        </Typography>
                    </Box>
                )}
            </ListItem>
        </List>
    )
}
