import * as _ from 'lodash'
import { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Conversion } from './Conversion'
import { FormattedMessage } from 'react-intl'

type ActualConversionPreviewProps = {
    open: boolean
    onClose: () => any
    conversions: Conversion[]
}

export const ActualConversionPreview: FC<ActualConversionPreviewProps> = (props) => {
    const { open, onClose, conversions } = props
    const keys = _.chain(conversions)
        .flatMap((c) => Object.keys(c.metadata))
        .uniq()
        .value()

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xl'}>
            <DialogTitle>
                <FormattedMessage id="conversion-tracking.actual-conversions" />
            </DialogTitle>

            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            {keys.map((key) => (
                                <TableCell key={key}>{key}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {conversions.map((conversion) => (
                            <TableRow key={conversion.identifier}>
                                {keys.map((key) => (
                                    <TableCell key={key}>{conversion.metadata[key] || ''}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    <FormattedMessage id="general.close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
