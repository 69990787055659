import React, { FC, useEffect } from 'react'
import { ConfirmDialog } from '../common/confirm-dialog/ConfirmDialog'
import { FormattedMessage } from 'react-intl'
import { Alert, CircularProgress, Typography } from '@mui/material'
import { useBlocker } from 'react-router-dom'
import { useForm, useFormState } from 'react-final-form'
import { useCanDraftBeSaved, useWillSaveRevokeApproval } from './PublishingHooks'
import { unsetErrorsForAllCommonFiles } from './PublishingSlice'
import { useDispatch } from 'react-redux'
import { Save, Undo } from '@mui/icons-material'

type PublishingFormConfirmDialogProps = {}

export const PublishingFormNavigationBlocker: FC<PublishingFormConfirmDialogProps> = () => {
    const dispatch = useDispatch()
    const form = useForm()
    const formState = useFormState()
    const groupNotPublished = !['PUBLISH_IN_PROGRESS', 'PUBLISH_SUCCESS'].includes(formState.values.common_post.state)
    const postGroupHasChanges =
        (!formState.submitSucceeded && formState.dirty) || // Check for pristine if form was not submitted yet
        (formState.submitSucceeded && formState.dirtySinceLastSubmit) // Check modifications since last submit if form was already submitted
    const blocker = useBlocker(postGroupHasChanges && groupNotPublished)
    const canBeSavedAsDraft = useCanDraftBeSaved()
    const actionWillRevokeApproval = useWillSaveRevokeApproval()

    useEffect(() => {
        const preventNavigation = (event: any) => {
            if (postGroupHasChanges && groupNotPublished) {
                event.preventDefault()
                event.returnValue = true
            }
        }

        window.addEventListener('beforeunload', preventNavigation)

        return () => window.removeEventListener('beforeunload', preventNavigation)
    }, [postGroupHasChanges, groupNotPublished])

    const saveAndClosePostGroup = () => {
        form.change('common_post.state', 'DRAFT')
        form.submit()?.then(() => {
            dispatch(unsetErrorsForAllCommonFiles())
            if (blocker.state === 'blocked') {
                blocker.proceed()
            }
        })
    }

    const handleDiscard = () => {
        dispatch(unsetErrorsForAllCommonFiles())
        if (blocker.state === 'blocked') {
            blocker.proceed()
        }
    }

    const handleCancel = () => {
        if (blocker.state === 'blocked') {
            blocker.reset()
        }
    }

    return (
        <ConfirmDialog
            open={blocker.state === 'blocked'}
            onClose={canBeSavedAsDraft ? handleDiscard : handleCancel}
            onConfirm={canBeSavedAsDraft ? () => saveAndClosePostGroup() : handleDiscard}
            onDismiss={handleCancel}
            title="publishing.close-dialog-confirm-title"
            confirmIcon={formState.submitting ? <CircularProgress color="inherit" size={20} /> : canBeSavedAsDraft ? <Save /> : <Undo />}
            confirmButtonDisabled={formState.submitting}
            abortText={canBeSavedAsDraft ? 'publishing.discard-post' : 'general.continue-editing'}
            confirmText={canBeSavedAsDraft ? 'publishing.save-post-as-draft' : 'publishing.discard-new-post'}
        >
            <FormattedMessage id={canBeSavedAsDraft ? 'general.unsaved-changes' : 'publishing.can-not-save'} />

            {actionWillRevokeApproval && (
                <Alert variant="standard" severity="warning" sx={{ mt: 2 }}>
                    <Typography>
                        <FormattedMessage id="publishing.save-will-revoke-approval" />
                    </Typography>
                </Alert>
            )}
        </ConfirmDialog>
    )
}
