import { FC } from 'react'
import { Dialog, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material'
import { Euro, Facebook, Instagram, Language, LinkedIn } from '@mui/icons-material'
import { ProjectDatasource } from './ProjectDatasource'
import { FormattedMessage } from 'react-intl'
import { TiktokIcon } from '../../common/icons/TiktokIcon'
import { DatasourceNewSelect } from './DatasourceNewSelect'
import { useDispatch, useSelector } from 'react-redux'
import { getDatasourceTypeToAdd, selectDatasourceTypeToAdd } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { TalkwalkerNew } from './TalkwalkerNew'
import { PotentialDatasource } from './PotentialDatasource'

type DatasourceSelectDialogProps = {
    open: boolean
    onSelect: (datasource: PotentialDatasource) => any
    onClose: () => any
    selectedDatasources: ProjectDatasource[]
    limitExceeded?: boolean
}

export const DatasourceSelectDialog: FC<DatasourceSelectDialogProps> = ({ open, onSelect, onClose, selectedDatasources, limitExceeded }) => {
    const dispatch = useDispatch<HmstrDispatch>()
    const type = useSelector(getDatasourceTypeToAdd)

    const handleClose = () => {
        dispatch(selectDatasourceTypeToAdd(undefined))
        onClose()
    }

    const handleSelect = (potentialDatasource: PotentialDatasource) => {
        onSelect(potentialDatasource)
    }

    const renderSelect = () => {
        if (type === 'TALKWALKER_JSON') {
            return <TalkwalkerNew handleClose={handleClose} onAddDatasource={handleSelect} />
        } else if (type !== undefined) {
            return (
                <DatasourceNewSelect
                    limitExceeded={limitExceeded}
                    type={type}
                    handleClose={handleClose}
                    selectedDatasources={selectedDatasources}
                    onSelect={handleSelect}
                />
            )
        } else {
            return undefined
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            {!type && (
                <List>
                    <ListSubheader sx={{ mb: 2 }}>
                        <Typography variant="h6">
                            <FormattedMessage id="projects.add-source-which-type" />
                        </Typography>
                    </ListSubheader>
                    <ListItemButton onClick={() => dispatch(selectDatasourceTypeToAdd('FACEBOOK_PAGE'))}>
                        <ListItemIcon>
                            <Facebook />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="FACEBOOK_PAGE" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => dispatch(selectDatasourceTypeToAdd('FACEBOOK_AD_ACCOUNT'))}>
                        <ListItemIcon>
                            <Euro />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="FACEBOOK_AD_ACCOUNT" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => dispatch(selectDatasourceTypeToAdd('INSTAGRAM_ACCOUNT'))}>
                        <ListItemIcon>
                            <Instagram />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="INSTAGRAM_ACCOUNT" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => dispatch(selectDatasourceTypeToAdd('LINKED_IN'))}>
                        <ListItemIcon>
                            <LinkedIn />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="LINKED_IN" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => dispatch(selectDatasourceTypeToAdd('LINKED_IN_PROFILE'))}>
                        <ListItemIcon>
                            <LinkedIn />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="LINKED_IN_PROFILE" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => dispatch(selectDatasourceTypeToAdd('TIKTOK_ACCOUNT'))}>
                        <ListItemIcon>
                            <TiktokIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="TIKTOK_ACCOUNT" />
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => dispatch(selectDatasourceTypeToAdd('TALKWALKER_JSON'))}>
                        <ListItemIcon>
                            <Language />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="TALKWALKER_JSON" />
                        </ListItemText>
                    </ListItemButton>
                </List>
            )}

            {type && renderSelect()}
        </Dialog>
    )
}
