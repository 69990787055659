import { configureStore } from '@reduxjs/toolkit'
import { CoreReducer, CoreState, initialCoreState } from './slices/CoreSlice'
import { initKeycloakSlice } from './keycloak/KeycloakSlice'
import { KeycloakWrapper } from './keycloak/KeycloakWrapper'
import { SettingsReducer } from '../settings/SettingsSlice'
import { initApiSlice } from './api/ApiSlice'
import { ClientConfig } from './config/config'
import { ApiEntryPoint } from './api/ApiEntryPoint'
import { DataReducer, initialDataState } from './slices/DataSlice'
import { ResultsReducer } from '../monitoring/ResultsSlice'
import { DashboardsReducer } from '../dashboards/DashboardsSlice'
import { PostsReducer } from '../content-analytics/posts/PostsSlice'
import { TagReducer } from '../tags/TagsSlice'
import { ConversionTrackingReducer } from '../conversion-tracking/ConversionTrackingSlice'
import { PostAnalysisReducer } from '../content-analytics/post-analysis/PostAnalysisSlice'
import { InitData } from './init/init-data'
import { PublishingReducer } from '../publishing/PublishingSlice'
import { EngagementReducer } from '../engagement/EngagementSlice'
import { GuideReducer } from '../common/guides/GuideSlice'
import { MentionReducer } from '../publishing/mentions/MentionSlice'
import { FilterReducer } from '../common/filterbar/FilterSlice'
import { PostTextGenerationReducer } from '../publishing/text-generation/PostTextGenerationSlice'

export const initHmstrStore = (keycloakWrapper: KeycloakWrapper, clientConfig: ClientConfig, apiEntrypoint: ApiEntryPoint, initData: InitData) => {
    const lastModulePages = {
        ...initialCoreState.lastModulePages,
    }

    if (initData.currentModule && initData.currentPage) {
        if (initData.currentPage.includes('calendar/new')) {
            lastModulePages[initData.currentModule as keyof CoreState['lastModulePages']] = '/calendar'
        } else {
            lastModulePages[initData.currentModule as keyof CoreState['lastModulePages']] = initData.currentPage
        }
    }

    return configureStore({
        reducer: {
            keycloak: initKeycloakSlice(keycloakWrapper).reducer,
            api: initApiSlice(clientConfig, apiEntrypoint).reducer,
            dashboards: DashboardsReducer,
            tags: TagReducer,
            data: DataReducer,
            core: CoreReducer,
            filters: FilterReducer,
            settings: SettingsReducer,
            results: ResultsReducer,
            posts: PostsReducer,
            post_analysis: PostAnalysisReducer,
            conversion_tracking: ConversionTrackingReducer,
            publishing: PublishingReducer,
            engagement: EngagementReducer,
            guides: GuideReducer,
            mentions: MentionReducer,
            post_text_generation: PostTextGenerationReducer,
        },
        preloadedState: {
            core: {
                ...initialCoreState,
                selectedProjectId: initData.selectedProjectId,
                currentUser: initData.currentUser || ({} as any),
                currentUserPermissions: initData.currentUserPermissions || ({} as any),
                lastModulePages: lastModulePages,
            },
            data: {
                ...initialDataState,
                products: initData.products || {},
                projects: initData.projects || {},
                accounts: initData.accounts || {},
            },
        },
    })
}

export type HmstrStore = ReturnType<typeof initHmstrStore>
export type HmstrState = ReturnType<ReturnType<typeof initHmstrStore>['getState']>
export type HmstrDispatch = ReturnType<typeof initHmstrStore>['dispatch']
export type HmstrAsyncThunkConfig = {
    state: HmstrState
    dispatch: HmstrDispatch
}
