import * as React from 'react'
import { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { GooeyLoader } from './GooeyLoader'

type AiLoadingButtonProps = {
    label: string
    loading?: boolean
    textColor?: string
    strokeColor?: string
    hideOnLoading?: boolean
} & ButtonProps

export const AiLoadingButton: FC<AiLoadingButtonProps> = (props) => {
    const { label, loading, textColor, strokeColor, hideOnLoading, ...otherProps } = props

    return (
        <>
            {!loading && (
                <Button
                    {...otherProps}
                    sx={{
                        minWidth: '100px',
                        background: 'linear-gradient(45deg, rgba(255,105,0,1) 0%, #ff006e 100%)',
                    }}
                >
                    <FormattedMessage id={label} />
                </Button>
            )}
            {loading && !hideOnLoading && <GooeyLoader size={100} animationDuration={1} />}
        </>
    )
}
