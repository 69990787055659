import { FC } from 'react'
import { Instagram } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'

type InstagramIconProps = SvgIconProps

export const InstagramIcon: FC<InstagramIconProps> = (props) => {
    return (
        <>
            <svg width={0} height={0} style={{ position: 'absolute' }}>
                <radialGradient id="instagramGradient" r="150%" cx="30%" cy="107%">
                    <stop stopColor="#fdf497" offset="0" />
                    <stop stopColor="#fdf497" offset="0.05" />
                    <stop stopColor="#fd5949" offset="0.45" />
                    <stop stopColor="#d6249f" offset="0.6" />
                    <stop stopColor="#285AEB" offset="0.9" />
                </radialGradient>
            </svg>
            <Instagram style={{ fill: 'url(#instagramGradient)' }} {...props} />
        </>
    )
}
