import { FC } from 'react'
import { Box, Grid, Paper, Slider, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { AttachMoney, DataObject, Person } from '@mui/icons-material'
import { Field, useFormState } from 'react-final-form'
import { FormattedCurrency } from '../../../common/formatted-currency/FormattedCurrency'
import { AccountFormValues } from '../AccountFormValues'
import { Product } from '../Product'
import { useSelector } from 'react-redux'
import { getProducts } from '../../../core/slices/DataSlice'

type AccountAdditionalsInputProps = {
    paper?: boolean
}

export const AccountAdditionalsInput: FC<AccountAdditionalsInputProps> = ({ paper }) => {
    const formState = useFormState<AccountFormValues>()
    const products = useSelector(getProducts)

    const basePackage = formState.values.package_type
    const additionalUsers = formState.values.additional_user
    const additionalDatasources = formState.values.additional_datasource
    const additionalConversionTracking = formState.values.conversion_tracking
    const billingInterval = formState.values.interval

    const getPriceForProduct = (product: Product, interval: 'month' | 'year') => {
        const price = product.prices.find((p) => p.recurring?.interval === interval)

        return price ? price.unit_amount / 100 : 0
    }

    const getPriceForFeature = (feature: Product['metadata']['additional_feature']) => {
        const product = Object.values(products).find((p) => p.metadata.additional_feature === feature)

        if (product) {
            return getPriceForProduct(product, billingInterval)
        }

        return 0
    }

    const PaperComponent: any = paper ? Paper : Box

    return (
        <Grid item xs={12}>
            <PaperComponent sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                            <Box flexGrow={1}>
                                <Typography>
                                    <FormattedMessage id="accounts.billing.additional-users" />
                                </Typography>

                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Person color="action" />
                                    </Grid>
                                    <Grid item xs>
                                        <Field name="additional_user">
                                            {({ input }) => <Slider valueLabelDisplay="auto" color="secondary" disabled={basePackage === 'free'} {...input} />}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box minWidth={400} textAlign="right" display="flex" alignItems="center" justifyContent="flex-end">
                                <Typography sx={{ mr: 2 }}>
                                    {additionalUsers} <FormattedMessage id="accounts.users" /> x&nbsp;
                                    <FormattedCurrency value={getPriceForFeature('ADDITIONAL_USER')} />
                                </Typography>

                                <Typography variant="h6" minWidth={80}>
                                    <FormattedCurrency value={additionalUsers * getPriceForFeature('ADDITIONAL_USER')} />
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                            <Box flexGrow={1}>
                                <Typography>
                                    <FormattedMessage id="accounts.billing.additional-datasources" />
                                </Typography>

                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <DataObject color="action" />
                                    </Grid>
                                    <Grid item xs>
                                        <Field name="additional_datasource">
                                            {({ input }) => <Slider valueLabelDisplay="auto" color="secondary" disabled={basePackage === 'free'} {...input} />}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box minWidth={400} textAlign={'right'} display="flex" alignItems={'center'} justifyContent={'flex-end'}>
                                <Typography sx={{ mr: 2 }}>
                                    {additionalDatasources} <FormattedMessage id="accounts.datasources" /> x&nbsp;
                                    <FormattedCurrency value={getPriceForFeature('ADDITIONAL_DATASOURCE')} />
                                </Typography>

                                <Typography variant="h6" minWidth={80}>
                                    <FormattedCurrency value={additionalDatasources * getPriceForFeature('ADDITIONAL_DATASOURCE')} />
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                            <Box flexGrow={1}>
                                <Typography>
                                    <FormattedMessage id="accounts.billing.additional-conversion-tracking" />
                                </Typography>

                                <Grid container spacing={2} alignItems="flex-start">
                                    <Grid item>
                                        <AttachMoney color="action" />
                                    </Grid>
                                    <Grid item xs>
                                        <Field name="conversion_tracking">
                                            {({ input }) => <Slider valueLabelDisplay="auto" color="secondary" disabled={basePackage === 'free'} {...input} />}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box minWidth={400} textAlign={'right'} display="flex" alignItems={'center'} justifyContent={'flex-end'}>
                                <Typography sx={{ mr: 2 }}>
                                    {additionalConversionTracking} <FormattedMessage id="accounts.units" /> x&nbsp;
                                    <FormattedCurrency value={getPriceForFeature('CONVERSION_TRACKING')} />
                                </Typography>

                                <Typography variant="h6" minWidth={80}>
                                    <FormattedCurrency value={additionalConversionTracking * getPriceForFeature('CONVERSION_TRACKING')} />
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </PaperComponent>
        </Grid>
    )
}
