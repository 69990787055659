import { FC } from 'react'
import { Box, List, Typography, useTheme } from '@mui/material'
import { SecondaryNavigationButton } from '../SecondaryNavigationButton'
import { Archive, AssignmentInd, Groups, Inbox } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { getOpenTicketCountByProjectByCategory, getSelectedDatasourceIds, selectInboxPage } from '../../engagement/EngagementSlice'
import { useStartTour } from '../../common/guides/useStartTour'
import { Dot } from '../../common/dot/Dot'
import { HmstrDispatch } from '../../core/Store'
import { fetchOpenTicketCountForUserByCategory } from '../../engagement/EngagementActions'
import { getCurrentUser, getSelectedProject } from '../../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'

type EngagementNavigationProps = {}

export const EngagementNavigation: FC<EngagementNavigationProps> = () => {
    const theme = useTheme()
    const dispatch = useDispatch<HmstrDispatch>()
    const currentUser = useSelector(getCurrentUser)
    const selectedProject = useSelector(getSelectedProject)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)
    const ticketCountByCategory = useSelector(getOpenTicketCountByProjectByCategory)

    useStartTour('engagementTour')

    useEffectWithIdComparison(() => {
        if (selectedProject)
            dispatch(
                fetchOpenTicketCountForUserByCategory({
                    datasource_ids: selectedDatasourceIds,
                    project: selectedProject,
                })
            )
    }, [currentUser, selectedProject, selectedDatasourceIds.toString()])

    const resetTicketPage = () => {
        dispatch(selectInboxPage(0))
    }

    return (
        <List>
            <SecondaryNavigationButton
                id="inbox_tab"
                path="/engagement/tickets/new"
                title="engagement.inbox"
                icon={<Inbox />}
                onClick={resetTicketPage}
                badge={
                    <Dot color={theme.palette.action.hover} size={24}>
                        <Box px={1}>
                            <Typography variant="subtitle2">{ticketCountByCategory.new < 100 ? ticketCountByCategory.new : '99+'}</Typography>
                        </Box>
                    </Dot>
                }
            />
            <SecondaryNavigationButton
                path="/engagement/tickets/mine"
                title="engagement.inbox.assigned-to-me"
                icon={<AssignmentInd />}
                onClick={resetTicketPage}
                badge={
                    <Dot color={theme.palette.action.hover} size={24}>
                        <Box px={1}>
                            <Typography variant="subtitle2">{ticketCountByCategory.mine < 100 ? ticketCountByCategory.mine : '99+'}</Typography>
                        </Box>
                    </Dot>
                }
            />
            <SecondaryNavigationButton
                path="/engagement/tickets/others"
                title="engagement.inbox.assigned-to-others"
                onClick={resetTicketPage}
                icon={<Groups />}
                badge={
                    <Dot color={theme.palette.action.hover} size={24}>
                        <Box px={1}>
                            <Typography variant="subtitle2">{ticketCountByCategory.others < 100 ? ticketCountByCategory.others : '99+'}</Typography>
                        </Box>
                    </Dot>
                }
            />
            <SecondaryNavigationButton
                id="archive_tab"
                path="/engagement/tickets/archived"
                onClick={resetTicketPage}
                title="engagement.archive"
                icon={<Archive />}
            />
        </List>
    )
}
