import { FC, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Link, Skeleton, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { PublishingMedia } from '../../publishing/PublishingMedia'
import { useImage } from '../../core/hooks/useImage'
import { getIsLoadingPreview, getPreviewMetadata } from '../../publishing/PublishingSlice'
import { isDarkMode } from '../../core/slices/CoreSlice'
import { HmstrColors } from '../../core/theme/hmstr-theme'
import { NoLinkPreviewAvailable } from './NoLinkPreviewAvailable'
import { NoImageAvailablePlaceholder } from './NoImageAvailablePlaceholder'

type LinkPreviewProps = {
    url: string
    ctaType?: string
    title?: string
    description?: string
    thumbnail?: PublishingMedia
}
const urlRegex = /^(https:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/

export const validateUrl = (url: string) => {
    if (!url) {
        return undefined
    }

    if (!url.startsWith('https')) {
        return 'validations.only-https'
    }

    if (urlRegex.test(url)) {
        return undefined
    } else {
        return 'validations.illegal-url'
    }
}

export const LinkPreview: FC<LinkPreviewProps> = ({ url, ctaType, title, description, thumbnail }) => {
    const imageContainerRef = useRef<HTMLElement | null>(null)
    const metaData = useSelector(getPreviewMetadata)
    const loading = useSelector(getIsLoadingPreview)
    const passedThumbnailImage = useImage(thumbnail?._links.file.href || metaData?.image)
    const darkmode = useSelector(isDarkMode)

    if (validateUrl(url) !== undefined) {
        return null
    }

    if (metaData === null && !loading && !thumbnail && !title && !description) {
        return <NoLinkPreviewAvailable />
    }

    if (metaData === null && loading) {
        return <Skeleton animation="pulse" />
    }

    const hasValidImageUrl = () => {
        if (metaData && metaData.image) {
            const imageUrlErrors = validateUrl(metaData?.image)

            return !imageUrlErrors
        }
        return false
    }

    const getImageUrl = (): string | undefined => {
        if (thumbnail && passedThumbnailImage.objectUrl) {
            return passedThumbnailImage.objectUrl
        } else if (metaData?.image && hasValidImageUrl()) {
            return metaData.image
        }

        return undefined
    }

    const imageUrl = getImageUrl()

    return (
        <Box borderLeft={0} borderRight={0} borderBottom={1} borderColor="divider" mr={-2} ml={-2} display={'flex'} flexDirection={'column'}>
            {imageUrl ? (
                <img
                    alt="link-preview"
                    src={imageUrl}
                    style={{
                        width: '100%',
                        aspectRatio: '191 / 100',
                        objectFit: 'cover',
                    }}
                    onError={() => {
                        if (imageContainerRef.current) {
                            imageContainerRef.current.style.display = 'none'
                        }
                    }}
                />
            ) : (
                <NoImageAvailablePlaceholder />
            )}
            <Box
                component={Link}
                underline="none"
                target="_blank"
                href={url}
                display="flex"
                alignItems="center"
                pt={0}
                pl={2}
                pr={2}
                pb={1}
                sx={{
                    backgroundColor: darkmode ? 'grey.800' : 'grey.100',
                    width: '100%',
                }}
            >
                <Box overflow="hidden" flexGrow={1}>
                    {metaData?.hostname && (
                        <Typography variant="overline" sx={{ fontSize: 13, lineHeight: 1, color: 'text.secondary' }}>
                            {metaData.hostname}
                        </Typography>
                    )}
                    {!metaData?.hostname && (
                        <Typography variant="overline" sx={{ fontSize: 13, lineHeight: 1, color: 'text.secondary' }}>
                            {url.replace('https://', '')}
                        </Typography>
                    )}
                    {metaData?.title && !title && (
                        <Typography variant="h6" fontSize={17} lineHeight={1.3}>
                            {metaData.title}
                        </Typography>
                    )}
                    {title && (
                        <Typography
                            variant="h6"
                            fontSize={17}
                            lineHeight={1.3}
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            sx={{
                                WebkitLineClamp: '2',
                                WebKitBoxOrient: 'vertical !important',
                                overflow: 'hidden',
                                display: '-webkit-box',
                            }}
                        >
                            {title}
                        </Typography>
                    )}
                    {description && (
                        <Typography
                            sx={{
                                mt: 0.5,
                                color: 'text.secondary',
                                lineHeight: 1.2,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {description}
                        </Typography>
                    )}
                    {metaData?.description && !description && (
                        <Typography
                            sx={{
                                mt: 0.5,
                                color: 'text.secondary',
                                lineHeight: 1.2,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {metaData.description}
                        </Typography>
                    )}
                </Box>

                {ctaType && ctaType !== 'NO_BUTTON' && (
                    <Box pl={1} pt={1}>
                        <Button
                            variant="text"
                            color="inherit"
                            sx={{
                                whiteSpace: 'nowrap',
                                backgroundColor: darkmode ? HmstrColors.PostPreviewColorsDarkMode.linkPreviewButton : 'grey.300',
                                textTransform: 'none',
                            }}
                        >
                            <FormattedMessage id={`publishing.cta.${ctaType}`} />
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
