import { ChangeEvent, FC, useRef } from 'react'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { CameraAlt, DeleteForever } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { Field, FieldInputProps } from 'react-final-form'

type ConnectedImageUploadProps = {
    name: string
}

export const ConnectedImageUpload: FC<ConnectedImageUploadProps> = ({ name }) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null)

    const handleClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click()
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, input: FieldInputProps<any>) => {
        if (event.target.files) {
            const fileUploaded = event.target.files[0]
            input.onChange(fileUploaded)
        }
    }

    const handleDeleteFile = (input: FieldInputProps<any>) => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.value = ''
            input.onChange(null)
        }
    }

    return (
        <Field
            name={name}
            render={({ input }) => {
                return (
                    <Grid sx={{ mt: 2 }} container direction={'column'} alignItems={'center'} justifyContent={'center'}>
                        <Grid item>
                            {input.value ? (
                                <img src={URL.createObjectURL(input.value)} style={{ maxWidth: '200px' }} alt={'Thumbnail'} />
                            ) : (
                                <IconButton onClick={handleClick} size={'large'} style={{ backgroundColor: 'rgba(0,0,0,0.07)' }}>
                                    <CameraAlt fontSize={'large'} />
                                </IconButton>
                            )}
                        </Grid>
                        <Grid item mt={1}>
                            <Typography>
                                {input.value ? input.value.name : <FormattedMessage id={'general.no-image-selected'} />}
                                {input.value && (
                                    <Tooltip title={<FormattedMessage id={'general.delete'} />} placement={'right'}>
                                        <IconButton onClick={() => handleDeleteFile(input)}>
                                            <DeleteForever color={'error'} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Typography>

                            <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="feedbackFile"
                                ref={hiddenFileInput}
                                onChange={(event) => handleFileChange(event, input)}
                                style={{ display: 'none' }}
                            />
                        </Grid>
                    </Grid>
                )
            }}
        />
    )
}
