import * as React from 'react'
import { FC, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, Typography } from '@mui/material'
import { PublishingMedia } from '../PublishingMedia'
import { PublishingPreviewImageGridItem } from '../PublishingPreviewImageGridItem'
import { PostPreviewImageInstagram } from './instagram/PostPreviewImageInstagram'
import { FormattedMessage } from 'react-intl'
import { Close } from '@mui/icons-material'
import _ from 'lodash'

type PostPreviewImageGridProps = {
    uploadFiles: PublishingMedia[]
}

const GridWidthHeight = 418
const GapWidth = 4

const srcset = (rowHeight: number, colWidth: number, rows = 1, cols = 1) => {
    return {
        width: colWidth * cols + GapWidth * (cols - 1),
        height: rowHeight * rows + GapWidth * (rows - 1),
        // srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
    }
}

export const PostPreviewImageGrid: FC<PostPreviewImageGridProps> = ({ uploadFiles }) => {
    const [otherImagesOpen, setOtherImagesOpen] = useState(false)

    const calculateOrientationForFile = (image: PublishingMedia): 'landscape' | 'portrait' | 'square' => {
        const aspect = image.width / image.height
        if (aspect > 1) {
            return 'landscape'
        } else if (aspect < 1) {
            return 'portrait'
        } else {
            return 'square'
        }
    }

    const getGridLayoutSpansAndSplits = (): {
        structure: { rows: number; cols: number }
        spans: { [index: number]: { rows: number; cols: number } }
        orientation?: 'landscape' | 'portrait'
    } => {
        if (uploadFiles.length >= 5) {
            const lastThreeOrientations = _.map(uploadFiles, (file, idx) => {
                if (idx >= 2 && idx <= 4) {
                    return calculateOrientationForFile(file)
                }
            })
            const shouldBeLandscape = _.filter(lastThreeOrientations, (orientation) => orientation === 'landscape').length >= 2
            if (shouldBeLandscape) {
                return {
                    structure: { rows: 6, cols: 2 },
                    spans: {
                        0: { rows: 3, cols: 1 },
                        1: { rows: 3, cols: 1 },
                        2: { rows: 2, cols: 1 },
                        3: { rows: 2, cols: 1 },
                        4: { rows: 2, cols: 1 },
                    },
                    orientation: 'landscape',
                }
            } else {
                return {
                    structure: { rows: 12, cols: 6 },
                    spans: {
                        0: { rows: 7, cols: 3 },
                        1: { rows: 7, cols: 3 },
                        2: { rows: 5, cols: 2 },
                        3: { rows: 5, cols: 2 },
                        4: { rows: 5, cols: 2 },
                    },
                    orientation: 'portrait',
                }
            }
        } else if (uploadFiles.length === 4) {
            return {
                structure: { rows: 2, cols: 2 },
                spans: {
                    0: { rows: 1, cols: 1 },
                    1: { rows: 1, cols: 1 },
                    2: { rows: 1, cols: 1 },
                    3: { rows: 1, cols: 1 },
                },
            }
        } else if (uploadFiles.length === 3) {
            return {
                structure: { rows: 2, cols: 6 },
                spans: {
                    0: { rows: 1, cols: 6 },
                    1: { rows: 1, cols: 3 },
                    2: { rows: 1, cols: 3 },
                },
            }
        } else if (uploadFiles.length === 2) {
            if (calculateOrientationForFile(uploadFiles[0]) === 'portrait' && calculateOrientationForFile(uploadFiles[1]) === 'portrait') {
                return {
                    structure: { rows: 1, cols: 2 },
                    spans: {
                        0: { rows: 1, cols: 1 },
                        1: { rows: 1, cols: 1 },
                    },
                    orientation: 'landscape',
                }
            } else if (calculateOrientationForFile(uploadFiles[0]) === 'landscape' && calculateOrientationForFile(uploadFiles[1]) === 'landscape') {
                return {
                    structure: { rows: 2, cols: 1 },
                    spans: {
                        0: { rows: 1, cols: 1 },
                        1: { rows: 1, cols: 1 },
                    },
                    orientation: 'portrait',
                }
            } else {
                return {
                    structure: { rows: 1, cols: 2 },
                    spans: {
                        0: { rows: 1, cols: 1 },
                        1: { rows: 1, cols: 1 },
                    },
                }
            }
        }
        return {
            structure: { rows: 1, cols: 1 },
            spans: {
                0: { rows: 1, cols: 1 },
            },
        }
    }

    const gridStructure: { rows: number; cols: number } = getGridLayoutSpansAndSplits().structure
    const gridSpans: { [index: number]: { rows: number; cols: number } } = getGridLayoutSpansAndSplits().spans
    const split: 'landscape' | 'portrait' | undefined = getGridLayoutSpansAndSplits().orientation
    const getActualHeight = () => {
        if (uploadFiles.length >= 5 && split === 'portrait') {
            return GridWidthHeight / 1.2
        } else if (uploadFiles.length === 2 && !split) {
            return GridWidthHeight / 2
        } else {
            return GridWidthHeight
        }
    }

    const actualHeight = getActualHeight()

    if (uploadFiles.length === 1) {
        return (
            <Box mr={-2} ml={-2}>
                <PublishingPreviewImageGridItem media={uploadFiles[0]} width="100%" height="100%" />
            </Box>
        )
    }

    const rowHeight = (actualHeight - GapWidth * (gridStructure.rows - 1)) / gridStructure.rows
    const colWidth = (actualHeight - GapWidth * (gridStructure.cols - 1)) / gridStructure.cols

    return (
        <>
            <ImageList
                sx={{
                    width: GridWidthHeight,
                    height: actualHeight,
                    maxWidth: '100%',
                    overflow: 'hidden',
                }}
                variant="quilted"
                cols={gridStructure.cols}
                gap={GapWidth}
                rowHeight={rowHeight}
                onClick={() => setOtherImagesOpen(true)}
            >
                {uploadFiles.slice(0, 5).map((file, index) => {
                    const getCorrectIndex = () => {
                        if (uploadFiles.length >= 5 && split === 'landscape') {
                            if (index === 1) {
                                return 2
                            }
                            if (index === 2) {
                                return 3
                            }
                            if (index === 3) {
                                return 1
                            } else return index
                        } else return index
                    }
                    const getCorrectFile = () => {
                        return uploadFiles[getCorrectIndex()]
                    }
                    const item = {
                        img: getCorrectFile()._links.file.href,
                        title: getCorrectFile().name,
                        rows: gridSpans[getCorrectIndex()].rows,
                        cols: gridSpans[getCorrectIndex()].cols,
                    }

                    const lastItem = index === 4

                    return (
                        <ImageListItem key={getCorrectFile().id} cols={item.cols} rows={item.rows} sx={{ cursor: 'pointer' }}>
                            <PublishingPreviewImageGridItem {...srcset(rowHeight, colWidth, item.rows, item.cols)} width={'100%'} media={getCorrectFile()} />
                            {lastItem && uploadFiles.length > 5 && (
                                <Box
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                    height="100%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}
                                >
                                    <Typography variant="h4" color="white" fontWeight={600}>
                                        +{uploadFiles.length - 5}
                                    </Typography>
                                </Box>
                            )}
                        </ImageListItem>
                    )
                })}
            </ImageList>
            <Dialog open={otherImagesOpen} onClose={() => setOtherImagesOpen(false)} maxWidth={'sm'}>
                <DialogTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <FormattedMessage id={'publishing.all-images-dialog-title'} />
                        <IconButton onClick={() => setOtherImagesOpen(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <PostPreviewImageInstagram uploadFiles={uploadFiles} />
                </DialogContent>
            </Dialog>
        </>
    )
}
