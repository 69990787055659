import { FC, useRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { closeAdPreview, getConversionTrackingDatasource, getConversionTrackingPreviewAdId } from './ConversionTrackingSlice'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'

type ConversionTrackingAdPreviewProps = {}

export const ConversionTrackingAdPreview: FC<ConversionTrackingAdPreviewProps> = () => {
    const dispatch = useDispatch()
    const selectedAdId = useSelector(getConversionTrackingPreviewAdId)
    const datasource = useSelector(getConversionTrackingDatasource)
    const previewTarget = useRef<HTMLDivElement>(null)

    useEffectWithIdComparison(() => {
        if (selectedAdId && datasource) {
            axios.get(`${datasource._links.ad_preview.href}/${selectedAdId}/preview`).then((response) => {
                if (response.status === 200 && previewTarget.current) {
                    const responseData = JSON.parse(response.data)
                    previewTarget.current.innerHTML = responseData['data'][0]['body']

                    previewTarget.current.querySelectorAll('iframe')[0].height = `${window.innerHeight * 0.9}`
                }
            })
        }
    }, [selectedAdId, datasource])

    return (
        <Dialog open={!!selectedAdId} keepMounted={false}>
            <DialogTitle>
                <FormattedMessage id="conversion-tracking.ad-preview" />
            </DialogTitle>
            <DialogContent>
                <div ref={previewTarget} style={{ height: `${window.innerHeight * 0.9}` }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closeAdPreview())}>
                    <FormattedMessage id="general.close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
