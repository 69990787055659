import { createSlice } from '@reduxjs/toolkit'
import { KeycloakWrapper } from './KeycloakWrapper'
import config from '../config/config'

export interface KeycloakState {
    firstName: string
    lastName: string
    locale: string
    hasAccess: boolean
    isAdmin: boolean
}

export const initKeycloakSlice = (keycloakWrapper: KeycloakWrapper) => {
    const initialState: KeycloakState = {
        firstName: keycloakWrapper.firstName,
        lastName: keycloakWrapper.lastName,
        locale: keycloakWrapper.locale,
        hasAccess: keycloakWrapper.hasResourceRole('access', config.keycloak.clientId),
        isAdmin: keycloakWrapper.hasResourceRole('admin', config.keycloak.clientId),
    }

    return createSlice({
        name: 'keycloak',
        initialState,
        reducers: {},
    })
}
