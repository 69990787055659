import { HmstrColors } from './hmstr-theme'
import { alpha } from '@mui/material'
import moment, { Moment } from 'moment/moment'
import { IntlShape } from 'react-intl'
import { replaceMentions } from '../../publishing/mentions/MentionService'
import * as React from 'react'

export const isIPadPro = () => {
    return !!(navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform))
}
export const isMobileTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)

    return isTablet || isIPadPro()
}

export const isDeviceInLandscape = () => {
    return window.matchMedia('(orientation: landscape)').matches
}

export const isMobileDevice = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    return regex.test(navigator.userAgent) && !isDeviceInLandscape() && !isMobileTablet()
}
export const isLaunchedInStandalone = () => {
    var globalProps: any[] = []

    function readGlobalProps() {
        globalProps = Object.getOwnPropertyNames(window)
    }

    readGlobalProps()
    return window.matchMedia('(display-mode: standalone)').matches || globalProps.includes('launchedFromApp')
}
export const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor

    if (/android/i.test(userAgent)) {
        return 'android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'ios'
    }

    if (isMobileTablet()) {
        return 'ios'
    }

    return 'unknown'
}

export const blendColors = (colorA: string, colorB: string, amount: number) => {
    const matchesA = colorA.match(/\w\w/g)
    const matchesB = colorB.match(/\w\w/g)
    if (matchesA && matchesB) {
        const [rA, gA, bA] = matchesA.map((c) => parseInt(c, 16))
        const [rB, gB, bB] = matchesB.map((c) => parseInt(c, 16))
        const r = Math.round(rA + (rB - rA) * amount)
            .toString(16)
            .padStart(2, '0')
        const g = Math.round(gA + (gB - gA) * amount)
            .toString(16)
            .padStart(2, '0')
        const b = Math.round(bA + (bB - bA) * amount)
            .toString(16)
            .padStart(2, '0')
        return '#' + r + g + b
    } else {
        return '#ff0000'
    }
}

export const textColorBasedOnBackground = (bgColor: string) => {
    // Calculation is done according to https://www.w3.org/TR/WCAG20/
    let color = bgColor
    if (color.startsWith('#')) {
        color = bgColor.substr(1)
    }

    let r: number
    let g: number
    let b: number

    if (color.length === 3) {
        r = parseInt(color.substring(0, 1), 16) * 16
        g = parseInt(color.substring(1, 2), 16) * 16
        b = parseInt(color.substring(2, 3), 16) * 16
    } else if (color.length === 6 || color.length === 8) {
        r = parseInt(color.substring(0, 2), 16)
        g = parseInt(color.substring(2, 4), 16)
        b = parseInt(color.substring(4, 6), 16)
    }

    // @ts-ignore
    if (r !== undefined && g !== undefined && b !== undefined) {
        return r * 0.299 + g * 0.587 + b * 0.114 > 150 ? HmstrColors.Black : HmstrColors.White
    }
    return HmstrColors.Black
}

export const getInactiveColor = (initialHex: string, percent = 80) => {
    // strip the leading # if it's there
    let hex = initialHex.replace(/^\s*#|\s*$/g, '')

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1')
    }

    let r = parseInt(hex.substr(0, 2), 16),
        g = parseInt(hex.substr(2, 2), 16),
        b = parseInt(hex.substr(4, 2), 16)

    return (
        '#' +
        (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
        (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
        (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
    )
}

export const clampText = (text: string, maxLength: number = 80) => (text.length > maxLength ? text.substr(0, maxLength) + '...' : text)

export const replaceHashtags = (text: string) => {
    return text.replaceAll('{hashtag|\\#|', '#').replaceAll('}', '')
}

export const trimHtml = (
    html: string,
    options: {
        preserveTags?: boolean
        wordBreak?: string
        suffix?: string
        moreLink?: string
        limit: number
    }
) => {
    options = options || {}

    let limit = options.limit || 100,
        preserveTags = typeof options.preserveTags !== 'undefined' ? options.preserveTags : true,
        wordBreak = typeof options.wordBreak !== 'undefined' ? options.wordBreak : false,
        suffix = options.suffix !== undefined ? options.suffix : '...',
        moreLink = options.moreLink !== undefined ? options.moreLink : ''

    let arr = html.replace(/</g, '\n<').replace(/>/g, '>\n').replace(/\n\n/g, '\n').replace(/^\n/g, '').replace(/\n$/g, '').split('\n')

    var sum = 0,
        row,
        cut,
        add,
        tagMatch,
        tagName,
        tagStack = [],
        more = false

    for (var i = 0; i < arr.length; i++) {
        row = arr[i]
        // count multiple spaces as one character
        let rowCut = row.replace(/[ ]+/g, ' ')

        if (!row.length) {
            continue
        }

        if (row[0] !== '<') {
            if (sum >= limit) {
                row = ''
            } else if (sum + rowCut.length >= limit) {
                cut = limit - sum

                if (row[cut - 1] === ' ') {
                    while (cut) {
                        cut -= 1
                        if (row[cut - 1] !== ' ') {
                            break
                        }
                    }
                } else {
                    add = row.substring(cut).split('').indexOf(' ')

                    // break on halh of word
                    if (!wordBreak) {
                        if (add !== -1) {
                            cut += add
                        } else {
                            cut = row.length
                        }
                    }
                }

                row = row.substring(0, cut) + suffix

                if (moreLink) {
                    row += '<a href="' + moreLink + '" style="display:inline">»</a>'
                }

                sum = limit
                more = true
            } else {
                sum += rowCut.length
            }
        } else if (!preserveTags) {
            row = ''
        } else if (sum >= limit) {
            tagMatch = row.match(/[a-zA-Z]+/)
            tagName = tagMatch ? tagMatch[0] : ''

            if (tagName) {
                if (row.substring(0, 2) !== '</') {
                    tagStack.push(tagName)
                    row = ''
                } else {
                    while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
                        tagStack.pop()
                    }

                    if (tagStack.length) {
                        row = ''
                    }

                    tagStack.pop()
                }
            } else {
                row = ''
            }
        }

        arr[i] = row
    }

    return {
        html: arr.join('\n').replace(/\n/g, ''),
        more: more,
    }
}

export const formatNumber = (value: number) =>
    value > 999999
        ? (value / 1000000).toFixed(1) + 'M'
        : value > 999
        ? (value / 1000).toFixed(1) + 'K'
        : value !== undefined
        ? value
              .toFixed(2)
              .toString()
              .replace(/[.,]00$/, '')
        : '0'

export const getPaperElevationBackground = (elevation: number) => {
    const getOverlayAlpha = (elevation: number): number => {
        let alphaValue
        if (elevation < 1) {
            alphaValue = 5.11916 * elevation ** 2
        } else {
            alphaValue = 4.5 * Math.log(elevation + 1) + 2
        }
        return alphaValue / 100
    }

    return `linear-gradient(${alpha('#fff', getOverlayAlpha(elevation))}, ${alpha('#fff', getOverlayAlpha(elevation))})`
}

const formatDateString = (unformatted: string, dateFormat: string) => {
    return moment(unformatted).format(dateFormat)
}

function weekOfMonth(input: Moment) {
    const firstDayOfMonth = input.clone().startOf('month')
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week')

    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days')

    return Math.ceil((input.date() + offset) / 7)
}

const formatWeek = (unformatted: string) => {
    return weekOfMonth(moment(unformatted)).toString() + ' ' + moment(unformatted).format('MMM') + ' ' + moment(unformatted).year()
}

export const getTimePeriodWithPrefix = (value: any, dateFormat: string, intl: IntlShape) => {
    if (typeof value !== 'string') {
        return value
    }
    const firstDate = value.split(' - ')[0]
    const secondDate = value.split(' - ')[1]
    let formattedFirstDate = formatDateString(firstDate, dateFormat)
    let formattedSecondDate = formatDateString(secondDate, dateFormat)
    let prefix = ''
    if (dateFormat === 'weekends_range' || dateFormat === 'weekdays_range') {
        formattedFirstDate = formatWeek(firstDate)
        formattedSecondDate = formatWeek(firstDate)
        prefix = dateFormat === 'weekends_range' ? intl.formatMessage({ id: 'general.weekend' }) : intl.formatMessage({ id: 'general.work-week' })
        prefix = prefix + ' '
    }
    if (dateFormat === 'W') {
        prefix = intl.formatMessage({ id: 'publishing.calendar-week-abbr' }) + ' '
    }
    if (dateFormat === 'Q YYYY') {
        prefix = 'Q'
    }
    if (formattedFirstDate === formattedSecondDate) {
        return prefix + formattedFirstDate
    }
    return `${prefix}${formattedFirstDate} - ${formattedSecondDate}`
}

export const formatDateRangeDefaults = (passedString: string, dateFormat?: string) => {
    if (passedString && dateFormat) {
        const firstDate = passedString.split(' - ')[0]
        const formattedFirstDate = moment(firstDate, '', true).isValid() ? moment(firstDate).format(dateFormat) : firstDate
        const secondDate = passedString.split(' - ')[1]
        const formattedSecondDate = moment(secondDate, '', true).isValid() ? moment(secondDate).format(dateFormat) : secondDate

        if (formattedFirstDate === formattedSecondDate) {
            return formattedFirstDate
        } else {
            return `${formattedFirstDate} - ${formattedSecondDate}`
        }
    } else {
        return passedString
    }
}

export const hashTagExpr = /#[\wäöüÄÖÜß]+/g

export const formatPostText = (text: string, darkmode: boolean, hashtagColorPassed?: string) => {
    const hashtagColor = hashtagColorPassed || darkmode ? 'hsl(213,100%,73%)' : 'rgb(0, 55, 200)'
    const escapeHtml = (unsafe: string) => {
        return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
    }
    const hashtags = text.match(hashTagExpr)
    const generateContent = () => {
        let newText = escapeHtml(text)
        newText = replaceMentions(newText, hashtagColor)

        hashtags
            ?.sort((a, b) => b.length - a.length)
            .forEach((hashtag: string) => {
                const pattern = `${hashtag}(?!\\w)`
                const hashtagRegex = new RegExp(pattern, 'g')
                newText = newText.replaceAll(hashtagRegex, `<span style='color: ${hashtagColor}; cursor: pointer;'>${escapeHtml(hashtag)}</span>`)
            })
        return <span dangerouslySetInnerHTML={{ __html: newText }}></span>
    }

    return (
        <span
            style={{
                fontSize: 15,
                color: darkmode ? 'inherit' : '#050505',
                fontWeight: 400,
                lineHeight: '14px',
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
                hyphens: 'auto',
            }}
        >
            {generateContent()}
        </span>
    )
}
