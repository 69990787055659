import moment from 'moment'
import { Badge, BottomNavigation, Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material'
import { BarChart, CalendarMonth, InfoOutlined, Mail } from '@mui/icons-material'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAllowedModulesForSelectedProject,
    getCurrentUser,
    getIsMobile,
    getLaunchedAppBefore,
    getSelectedAccount,
    getSelectedProject,
    setLaunchedApp,
} from '../core/slices/CoreSlice'
import { FeedbackDialog } from './components/FeedbackDialog'
import { Account } from '../settings/accounts/Account'
import { TrialDialog } from './components/TrialDialog'
import { getOpenTicketCountForSelectedProject } from '../engagement/EngagementSlice'
import { PrimaryNavigationButtonMobile } from './PrimaryNavigationButtonMobile'
import { UserMenuMobile } from './UserMenuMobile'
import { isLaunchedInStandalone } from '../core/theme/helper'
import { FormattedMessage } from 'react-intl'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { HmstrDispatch } from '../core/Store'
import { fetchOpenTicketCountForUser } from '../engagement/EngagementActions'

interface NavbarProps {}

export const primaryMobileNavHeight = 80

export const PrimaryNavigationMobile: FC<NavbarProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const selectedAcount: Account | undefined = useSelector(getSelectedAccount)
    const remainingDays = moment(selectedAcount?.trial_end).diff(moment().subtract(1, 'day'), 'days')
    const appHasBeenLaunchedBefore = useSelector(getLaunchedAppBefore)

    const allowedModules = useSelector(getAllowedModulesForSelectedProject)
    const openTicketsForProject = useSelector(getOpenTicketCountForSelectedProject)
    const isStandalone = isLaunchedInStandalone()

    const [feedbackOpen, setFeedbackOpen] = useState(false)
    const [trialDialogOpen, setTrialDialogOpen] = useState(false)
    const [firstLaunchDialogOpen, setFirstLaunchDialogOpen] = useState(false)
    const currentUser = useSelector(getCurrentUser)
    const project = useSelector(getSelectedProject)
    const isMobile = useSelector(getIsMobile)

    useEffectWithIdComparison(() => {
        if (project && allowedModules.includes('engagement')) {
            dispatch(fetchOpenTicketCountForUser(currentUser))
        }
    }, [currentUser, project])

    useEffect(() => {
        setTimeout(() => {
            setFirstLaunchDialogOpen(!appHasBeenLaunchedBefore)
        }, 2000)
    }, [appHasBeenLaunchedBefore])

    return (
        <>
            <BottomNavigation
                sx={{
                    background: `${theme.palette.primary.main}`,
                    height: `${primaryMobileNavHeight}px`,
                    boxShadow: theme.shadows[10],
                    pb: isStandalone ? 1 : 0,
                    zIndex: 2,
                }}
            >
                <PrimaryNavigationButtonMobile
                    id={'primary_nav_publishing'}
                    disabled={!allowedModules.includes('publishing')}
                    icon={<CalendarMonth />}
                    path="/publishing"
                    tooltip="publishing.title"
                />
                <PrimaryNavigationButtonMobile
                    id={'primary_nav_engagement'}
                    disabled={!allowedModules.includes('engagement')}
                    icon={
                        <Badge badgeContent={allowedModules.includes('engagement') ? openTicketsForProject : undefined} color={'error'} overlap={'rectangular'}>
                            <Mail />
                        </Badge>
                    }
                    path="/engagement"
                    tooltip="engagement.title"
                />

                <PrimaryNavigationButtonMobile
                    id={'primary_nav_analytics'}
                    disabled={!allowedModules.includes('analytics')}
                    tooltip="analytics.title"
                    icon={<BarChart />}
                    path="/analytics"
                />

                <UserMenuMobile />

                {feedbackOpen && <FeedbackDialog open={feedbackOpen} onClose={() => setFeedbackOpen(false)} />}
                {trialDialogOpen && <TrialDialog open={trialDialogOpen} onClose={() => setTrialDialogOpen(false)} remainingDays={remainingDays} />}
            </BottomNavigation>
            <Dialog open={firstLaunchDialogOpen && isMobile}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                    }}
                    color={theme.palette.text.disabled}
                >
                    <InfoOutlined fontSize={'small'} />
                    <FormattedMessage id={'general.first-time-app-launch.title'} />
                </DialogTitle>
                <DialogContent>
                    <FormattedMessage id={'general.first-time-app-launch'} />
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {
                            dispatch(setLaunchedApp(true))
                            setFirstLaunchDialogOpen(false)
                        }}
                    >
                        <FormattedMessage id={'general.okay'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
