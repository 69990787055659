import { FC, useEffect, useMemo, useState } from 'react'
import { Alert, Box, Button, DialogActions, Grid, TextField, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Info, Search } from '@mui/icons-material'
import { Loading } from '../../common/loading/Loading'
import { ProjectDatasource, ProjectDatasourceType } from './ProjectDatasource'
import { PotentialDatasource } from './PotentialDatasource'
import { useDispatch, useSelector } from 'react-redux'
import { getPotentialDatasourcesForType } from '../../core/slices/DataSlice'
import { fetchFederatedIdentites, fetchPotentialDatasources } from '../user-management/UserActions'
import { getCurrentUser, getIsMobile, showErrorSnackbar, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { DatasourceNewSelectItem } from './DatasourceNewSelectItem'
import * as _ from 'lodash'
import { Fallback } from '../../common/fallback/Fallback'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { linkAccount } from '../linked-accounts/AccountLinkService'
import { getApiEntryPoint } from '../../core/api/ApiSlice'
import { getFederatedTypeForDatasourceType, getIconComponentForDatasourceType, getLabelForDatasourceType } from './DatasourceTypeMappings'

type ProjectDatasourceNewSelectProps = {
    type: ProjectDatasourceType
    handleClose: any
    onSelect: (potentialDatasource: PotentialDatasource) => any
    selectedDatasources: ProjectDatasource[]
    limitExceeded?: boolean
}

export const DatasourceNewSelect: FC<ProjectDatasourceNewSelectProps> = (props) => {
    const { handleClose, onSelect, selectedDatasources, type, limitExceeded } = props

    const dispatch = useDispatch<HmstrDispatch>()

    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(true)
    const potentialDatasources = useSelector(getPotentialDatasourcesForType(type))
    const user = useSelector(getCurrentUser)
    const apiEntryPoint = useSelector(getApiEntryPoint)
    const identityType = getFederatedTypeForDatasourceType(type)
    const datasourcesByMetadataId = useMemo(() => _.mapKeys(selectedDatasources, 'metadata.id'), [selectedDatasources])
    const isMobile = useSelector(getIsMobile)

    const handleReauthorize = () => {
        linkAccount(apiEntryPoint, identityType, type).then((success) => {
            if (success) {
                dispatch(showSuccessSnackbar('federated-identites.link-success'))
            } else {
                dispatch(showErrorSnackbar('federated-identites.link-failed'))
            }

            setLoading(true)
            dispatch(fetchFederatedIdentites(user))
            dispatch(fetchPotentialDatasources({ user, type })).then(() => {
                setLoading(false)
            })
        })
    }

    const filteredPages = useMemo(() => {
        if (searchTerm === '') {
            return potentialDatasources
        }

        return potentialDatasources.filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, potentialDatasources.length])

    useEffect(() => {
        dispatch(fetchPotentialDatasources({ user, type })).then(() => {
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Icon = getIconComponentForDatasourceType(type, true)

    return (
        <>
            <Box padding={2} id="guide-facebook-page-select" sx={isMobile ? { display: 'flex', flexDirection: 'column', flexGrow: 1 } : {}}>
                <Box>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography variant="h5">
                                <FormattedMessage id={getLabelForDatasourceType(type, true)} />
                            </Typography>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : undefined}>
                            <TextField
                                value={searchTerm}
                                fullWidth={isMobile}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                label={<FormattedMessage id="general.search" />}
                                InputProps={{
                                    endAdornment: <Search color="action" />,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Alert
                                variant="standard"
                                color="info"
                                icon={<Info />}
                                action={
                                    !isMobile && (
                                        <Button onClick={handleReauthorize} sx={{ whiteSpace: 'nowrap' }} variant="contained" color="info">
                                            <FormattedMessage id="general.connect-link" />
                                        </Button>
                                    )
                                }
                            >
                                {!isMobile && <FormattedMessage id="general.connect-help" />}
                                {isMobile && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 2,
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <FormattedMessage id="general.connect-help" />

                                        <Button onClick={handleReauthorize} sx={{ whiteSpace: 'nowrap' }} variant="contained" color="info">
                                            <FormattedMessage id="general.connect-link" />
                                        </Button>
                                    </Box>
                                )}
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>

                {loading && potentialDatasources.length === 0 ? (
                    <Box>
                        <Loading />
                    </Box>
                ) : (
                    <Fallback
                        condition={potentialDatasources.length === 0}
                        actionButton={
                            <Box marginTop={-2} display="flex" alignItems="center" justifyContent="center" height="60vh">
                                <Icon color={'action'} sx={{ mr: 1 }} />
                                <Typography variant="subtitle2">
                                    <FormattedMessage id="general.no-datasources-found" />
                                </Typography>
                            </Box>
                        }
                    >
                        <Box
                            marginRight={-2}
                            marginLeft={-2}
                            marginTop={2}
                            height={isMobile ? undefined : '60vh'}
                            flexGrow={isMobile ? 1 : undefined}
                            overflow="auto"
                        >
                            <AutoSizer>
                                {({ height, width }) => (
                                    <FixedSizeList height={height} width={width} itemCount={filteredPages.length} itemData={filteredPages} itemSize={72}>
                                        {DatasourceNewSelectItem({
                                            onSelectDatasource: onSelect,
                                            datasourcesByMetadataId: datasourcesByMetadataId,
                                            icon: <Icon />,
                                            limitExceeded,
                                        })}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </Box>
                    </Fallback>
                )}
            </Box>
            <DialogActions>
                <Button onClick={handleClose}>
                    <FormattedMessage id="general.close" />
                </Button>
            </DialogActions>
        </>
    )
}
