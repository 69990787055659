import * as React from 'react'
import { FC, useEffect } from 'react'
import { Box, Button, ThemeProvider, Typography } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { PublishingListPostCard } from './PublishingListCardComponents/PublishingListPostCard'
import { getPostGroupsForSelectedTimePeriodForListView, startNewPost } from './PublishingSlice'
import { HmstrDispatch } from '../core/Store'
import { fetchTagsForProject } from '../tags/TagsActions'
import { getIsMobile, getSelectedProject, isDarkMode, isExternalForProject, setAppThemeColor } from '../core/slices/CoreSlice'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { createHmstrTheme } from '../core/theme/hmstr-theme'
import { fetchUsersForProject } from '../settings/user-management/UserActions'
import { EmptySchedule } from '../common/microinteractions/EmptySchedule'
import { PostAdd } from '@mui/icons-material'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'

type PublishingListProps = {}

type PublishingListColumnType = 'DRAFT' | 'APPROVAL_PENDING' | 'REWORK' | 'APPROVED' | 'PLANNED' | 'PUBLISH_SUCCESS' | 'PUBLISH_FAILED'

export const PublishingList: FC<PublishingListProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const selectedProject = useSelector(getSelectedProject)
    const darkMode = useSelector(isDarkMode)
    const theme = createHmstrTheme('dark')
    const types: PublishingListColumnType[] = ['DRAFT', 'APPROVAL_PENDING', 'REWORK', 'APPROVED', 'PLANNED', 'PUBLISH_SUCCESS', 'PUBLISH_FAILED']
    const postGroups = useSelector(getPostGroupsForSelectedTimePeriodForListView)
    const navigate = useProjectNavigate()
    const isUserExternal = useSelector(isExternalForProject)

    const colWidth = 270

    const isMobile = useSelector(getIsMobile)
    useEffect(() => {
        dispatch(setAppThemeColor(theme.palette.background.paper))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [darkMode])

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTagsForProject(selectedProject))
            dispatch(fetchUsersForProject(selectedProject))
        }
    }, [selectedProject])

    const generatePostTypeTitle = (type: string, firstEl: boolean, lastEl: boolean) => {
        return (
            <Box
                p={1}
                pl={2}
                flex={`1 0 ${isMobile ? '80vw' : colWidth + 'px'}`}
                key={type}
                sx={{
                    background: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    borderBottomLeftRadius: firstEl || isMobile ? 4 : 0,
                    borderTopLeftRadius: firstEl || isMobile ? 4 : 0,
                    borderBottomRightRadius: lastEl || isMobile ? 4 : 0,
                    borderTopRightRadius: lastEl || isMobile ? 4 : 0,
                    borderRadius: isMobile ? 20 : undefined,
                    scrollSnapAlign: isMobile ? 'center' : undefined,
                    overscrollBehavior: 'none !important',
                    touchActions: 'none !important',
                }}
            >
                <Typography fontWeight={600} sx={{ pl: 1 }}>
                    <FormattedMessage id={`publishing.post-state.${type}`} />
                </Typography>
            </Box>
        )
    }

    const generatePostTypeColumn = (type: PublishingListColumnType) => {
        const postsForType = _.chain(postGroups)
            .values()
            .filter((postGroup) => {
                const internalPending = postGroup.internal_approval?.state === 'REQUESTED'
                const externalPending = postGroup.external_approval?.state === 'REQUESTED'
                const internalApproved = postGroup.internal_approval?.state === 'APPROVED'
                const externalApproved = postGroup.external_approval?.state === 'APPROVED'
                const internalRework = postGroup.internal_approval?.state === 'REWORK'
                const externalRework = postGroup.external_approval?.state === 'REWORK'
                const anyFailed = !!postGroup.posts.find((p) => p.state === 'PUBLISH_FAILED')
                const isDraft = postGroup.posts[0].state === 'DRAFT'
                const isPending = isDraft && (externalPending || (internalPending && !isUserExternal))
                const isRework = isDraft && (externalRework || (!isUserExternal && internalRework))
                const isApproved = externalApproved || (!isUserExternal && internalApproved)

                switch (type) {
                    case 'DRAFT':
                        const noApproval = !(!isUserExternal && postGroup.internal_approval) && !postGroup.external_approval
                        return isDraft && noApproval
                    case 'APPROVAL_PENDING':
                        return isPending
                    case 'REWORK':
                        return isRework
                    case 'APPROVED':
                        return isDraft && isApproved && !isPending
                    case 'PLANNED':
                        return postGroup.posts[0].state === 'PLANNED'
                    case 'PUBLISH_SUCCESS':
                        return postGroup.posts[0].state === 'PUBLISH_SUCCESS' && !anyFailed
                    case 'PUBLISH_FAILED':
                        return anyFailed
                }

                return false // eslint wont understand that the switch will always return something so we return a default false to mute the warning
            })
            .sort((a, b) => moment(a.publish_time).diff(b.publish_time))
            .value()

        return (
            <Box
                flex={`1 0 ${isMobile ? '80vw' : colWidth + 'px'}`}
                p={1}
                maxHeight={'100%'}
                display={'inline-flex'}
                key={`posttypecolumn${type}`}
                sx={{
                    overflowX: 'hidden',
                    '*': {
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                    },
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
            >
                <Box
                    overflow={'auto'}
                    pr={isMobile ? 0.1 : 2}
                    width={'100%'}
                    display={'flex'}
                    gap={2}
                    flexDirection={'column'}
                    pb={0.5}
                    pt={0.1}
                    pl={0.1}
                    sx={{
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {_.map(postsForType, (postGroup) => {
                        return <PublishingListPostCard postGroup={postGroup} key={postGroup.id} />
                    })}
                    {postsForType.length === 0 && (
                        <Box height="100%" display="flex" justifyContent="flex-start" gap={1} pl={2} width="100%">
                            <Typography color="text.secondary">
                                <FormattedMessage id="publishing.list-view.no-posts" />
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    const handleCreatePostNowClick = () => {
        dispatch(startNewPost())
        navigate('/publishing/list/new')
    }

    return (
        <Box
            width={'100%'}
            sx={{
                overflowY: 'hidden',
                scrollSnapType: isMobile ? 'x mandatory' : undefined,
                overscrollBehavior: 'none !important',
                touchActions: 'none !important',
            }}
            flexShrink={1}
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            pl={2}
        >
            <Box
                display={'flex'}
                gap={isMobile ? 2 : undefined}
                sx={{
                    overscrollBehavior: 'none !important',
                    touchActions: 'none !important',
                    touchAction: 'pan-x !important',
                }}
            >
                <ThemeProvider theme={createHmstrTheme(theme.palette.mode === 'light' ? 'dark' : 'dark')}>
                    {_.map(types, (post_type, idx) => {
                        return generatePostTypeTitle(post_type, idx === 0, idx === types.length - 1)
                    })}
                </ThemeProvider>
                <Box flex={'0 0 16px'} />
            </Box>
            {Object.keys(postGroups).length > 0 ? (
                <Box
                    flexShrink={1}
                    display={'flex'}
                    sx={{
                        maxHeight: 'calc(100% - 40px)',
                        overflowY: 'clip',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                    gap={isMobile ? 2 : undefined}
                >
                    {_.map(types, (post_type) => {
                        return generatePostTypeColumn(post_type)
                    })}
                </Box>
            ) : (
                <Box
                    width={'100%'}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 3,
                        flexDirection: 'column',
                        gap: 2,
                        height: '100%',
                        maxHeight: `calc(100% - 40px)`,
                        flexShrink: isMobile ? 1 : 0,
                        position: 'sticky',
                        left: 0,
                        bottom: 0,
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        width={'100%'}
                        maxWidth={'600px'}
                        sx={
                            isMobile
                                ? {
                                      display: 'flex',
                                      justifyContent: 'center',
                                      maxHeight: '100%',
                                      overflow: 'hidden',
                                      ml: -2,
                                  }
                                : { ml: -2 }
                        }
                    >
                        <EmptySchedule />
                    </Box>
                    <Box
                        flexGrow={1}
                        display={'flex'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={isMobile ? 0 : 2}
                        sx={{
                            ml: -2,
                        }}
                    >
                        <Typography variant={isMobile ? 'h6' : 'h4'} textAlign={'center'} color={'primary.light'} maxWidth={'500px'} fontWeight={'lighter'}>
                            <FormattedMessage id={'publishing.list-view.all-done'} />
                            {isMobile && <FormattedMessage id={'publishing.list-view.all-done-cta-mobile'} />}
                        </Typography>
                        {!isMobile && (
                            <Button startIcon={<PostAdd />} color="secondary" variant="contained" onClick={handleCreatePostNowClick}>
                                <FormattedMessage id={'publishing.create-post'} />
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}
