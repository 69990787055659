import * as React from 'react'
import { FC, PropsWithChildren, useState } from 'react'
import { useIntl } from 'react-intl'
import { renderToStaticMarkup } from 'react-dom/server'
import { trimHtml } from '../../core/theme/helper'

interface ReadMoreProps {
    sliceLimit: number
    passedElement?: JSX.Element
}

export const ReadMore: FC<PropsWithChildren<ReadMoreProps>> = ({ children, sliceLimit, passedElement }) => {
    const intl = useIntl()
    const [isReadMore, setIsReadMore] = useState(true)
    const text = children as string
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }

    if (passedElement) {
        const renderedHTML = renderToStaticMarkup(passedElement).replaceAll('\n', ' ')
        const trimmedHTML = trimHtml(renderedHTML, {
            moreLink: '',
            limit: sliceLimit,
            preserveTags: true,
            suffix: '...',
            wordBreak: 'break-word',
        })

        return (
            <>
                {isReadMore ? <span dangerouslySetInnerHTML={{ __html: trimmedHTML.html }}></span> : children}
                {trimmedHTML.more && (
                    <span
                        onClick={toggleReadMore}
                        style={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                    >
                        {' '}
                        {isReadMore ? intl.formatMessage({ id: 'general.read-more' }) : intl.formatMessage({ id: 'general.read-less' })}
                    </span>
                )}
            </>
        )
    } else {
        const textTooLong = text.length > sliceLimit
        return (
            <>
                <span
                    style={{
                        wordBreak: 'break-word',
                        hyphens: 'auto',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: isReadMore ? text.slice(0, sliceLimit) : text.replaceAll('\n', '<br/>'),
                    }}
                ></span>
                {textTooLong && (
                    <span
                        onClick={toggleReadMore}
                        style={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                    >
                        {'... '}
                        {isReadMore ? intl.formatMessage({ id: 'general.read-more' }) : intl.formatMessage({ id: 'general.read-less' })}
                    </span>
                )}
            </>
        )
    }
}
