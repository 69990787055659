import { FC } from 'react'
import { useSelector } from 'react-redux'
import { getSelectedAccount, getSelectedAccountAccess } from '../../core/slices/CoreSlice'
import { ProjectList } from './ProjectList'

type ProjectManagementProps = {}

export const ProjectManagement: FC<ProjectManagementProps> = () => {
    const selectedAccountAccess = useSelector(getSelectedAccountAccess)
    const selectedAccount = useSelector(getSelectedAccount)

    return <ProjectList projectAccessList={selectedAccountAccess ? selectedAccountAccess.projects : []} selectedAccount={selectedAccount} />
}
