export const GermanLocale = {
    // - 3:2
    // - 4:3
    // - 5:4
    // - 7:5
    // - 16:9
    Apply: 'Anwenden',
    Arrow: 'Pfeil',
    'Arrow-2': 'Pfeil 2',
    'Arrow-3': 'Pfeil 3',
    Blend: 'Mischen',
    Blur: 'Verschwommen',
    Bold: 'Fett',
    Brightness: 'Helligkeit',
    Bubble: 'Sprechblase',
    Cancel: 'Abbrechen',
    Center: 'Zentriert',
    Circle: 'Kreis',
    Color: 'Farbe',
    'Color Filter': 'Farbfilter',
    Crop: 'Zuschneiden',
    Custom: 'Benutzerdefiniert',
    'Custom icon': 'Eigenes Symbol hochladen',
    Delete: 'Löschen',
    DeleteAll: 'Alle löschen',
    Distance: 'Entfernung',
    Download: 'Herunterladen',
    Draw: 'Zeichnen',
    Emboss: 'Graviert',
    Fill: 'Füllfarbe',
    Filter: 'Filter',
    Flip: 'Spiegeln',
    'Flip X': 'Horizontal spiegeln',
    'Flip Y': 'Vertikal spiegeln',
    Free: 'Frei',
    Grayscale: 'Graustufen',
    Heart: 'Herz',
    History: 'Änderungsverlauf',
    Icon: 'Symbole',
    Invert: 'Umkehren',
    Italic: 'Kursiv',
    Left: 'Links',
    Load: 'Laden',
    'Load Mask Image': 'Maskenbild hochladen',
    Location: 'Standort',
    Mask: 'Maske',
    Multiply: 'Multiplizieren',
    Noise: 'Rauschen',
    Pixelate: 'Verpixelt',
    Polygon: 'Vieleck',
    Range: 'Bereich',
    Rectangle: 'Rechteck',
    Redo: 'Wiederholen',
    'Remove White': 'Weiß entfernen',
    Reset: 'Zurücksetzen',
    Right: 'Rechts',
    Rotate: 'Drehen',
    Sepia: 'Sepia',
    Sepia2: 'Sepia 2',
    Shape: 'Formen',
    Sharpen: 'Schärfen',
    Square: 'Quadrat',
    'Star-1': 'Stern-1',
    'Star-2': 'Stern-2',
    Straight: 'Gerade',
    Stroke: 'Rahmenfarbe',
    Text: 'Text',
    'Text size': 'Textgröße',
    Threshold: 'Schwellenwert',
    Tint: 'Einfärben',
    Triangle: 'Dreieck',
    Underline: 'Unterstrichen',
    Undo: 'Rückgängig machen',
    Value: 'Wert',
    ZoomOut: 'Verkleinern',
    ZoomIn: 'Vergrößern',
}
