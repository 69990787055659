import * as React from 'react'
import { FC } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { PostAvatar } from '../../../common/avatars/PostAvatar'
import { InstagramMore } from '../../../common/icons/InstagramMore'
import { PostPreviewImageInstagram } from './PostPreviewImageInstagram'
import { PostPreviewVideo } from '../PostPreviewVideo'
import { Post } from '../../posts/Post'
import { ProjectDatasource } from '../../../settings/datasources/ProjectDatasource'
import { InstagramLike } from '../../../common/icons/InstagramLike'

type InstagramPostShitProps = {
    post: Post
    selectedDatasource: ProjectDatasource
    igFontFamily: string
    actualFiles: any[]
    mockupDevice: 'mobile' | 'desktop'
}

export const InstagramStoryPreview: FC<InstagramPostShitProps> = ({ post, selectedDatasource, igFontFamily, actualFiles, mockupDevice }) => {
    return (
        <Box
            alignItems="center"
            position={'relative'}
            sx={{
                aspectRatio: '9 / 16',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
            }}
        >
            {selectedDatasource.metadata.profile_picture_url && (
                <Box
                    width={'100%'}
                    position={'absolute'}
                    zIndex={2}
                    top={mockupDevice === 'mobile' ? 40 : undefined}
                    left={0}
                    display={'flex'}
                    alignItems={'center'}
                    p={2}
                    sx={{
                        background: 'linear-gradient(#0008, transparent)',
                    }}
                >
                    <Box display={'flex'} gap={'10px'} alignItems={'center'} maxWidth={'100%'}>
                        <PostAvatar width={32} height={32} name={selectedDatasource.name} src={selectedDatasource.metadata.profile_picture_url} />

                        <Typography
                            sx={{
                                fontSize: 14,
                                color: '#ffffff',
                                fontWeight: 600,
                                lineHeight: '18px',
                                fontFamily: igFontFamily,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {selectedDatasource.metadata.description}
                            <Typography
                                component={'span'}
                                sx={{
                                    display: 'inline-block',
                                    ml: 1,
                                    lineHeight: '18px',
                                    fontWeight: 400,
                                    fontSize: 12,
                                    letterSpacing: '.2px',
                                    color: 'inherit',
                                    textTransform: 'lowercase',
                                    fontFamily: igFontFamily,
                                }}
                            >
                                {' '}
                                • now
                            </Typography>
                        </Typography>
                    </Box>
                    <Grid item flexGrow={1} justifyContent={'flex-end'} display={'flex'}>
                        <InstagramMore htmlColor={'#fff'} />
                    </Grid>
                </Box>
            )}
            {post.post_type !== 'VIDEO' && actualFiles.length > 0 && (
                <Box sx={{ height: '100%', width: '100%' }}>
                    <PostPreviewImageInstagram uploadFiles={actualFiles} story mockupDevice={mockupDevice} />
                </Box>
            )}

            {post.post_type === 'VIDEO' && actualFiles.length > 0 && (
                <Grid item xs={12} sx={{ height: '100%' }}>
                    <PostPreviewVideo variant="IG_STORY" file={actualFiles[0]} />
                </Grid>
            )}
            <Box
                display="flex"
                alignItems="center"
                width={'100%'}
                position={'absolute'}
                bottom={0}
                p={2}
                gap={2}
                zIndex={1100}
                sx={{
                    background: 'linear-gradient(transparent, #0008, #000a)',
                }}
            >
                <Box flexGrow={1} border={'1px solid #fff'} color={'#fff'} borderRadius={100} p={0.8} px={2}>
                    Nachricht senden
                </Box>
                <InstagramLike fontSize="medium" htmlColor={'#fff'} />
            </Box>
        </Box>
    )
}
