import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { ConnectedTextField, ConnectedTextFieldProps } from './ConnectedTextField'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material'

type ConnectedTextFieldWithSuggestionsProps = {
    suggestions: string[]
    colorfulPlaceholder?: boolean
} & ConnectedTextFieldProps

export const ConnectedTextFieldWithSuggestions: FC<ConnectedTextFieldWithSuggestionsProps> = (props) => {
    const { suggestions, colorfulPlaceholder, ...otherProps } = props
    const intl = useIntl()
    const theme = useTheme()
    const [suggestion, setSuggestion] = useState<number>(0)
    const [displayedSuggestion, setDisplayedSuggestion] = useState<string>('')
    const [suggestionIndexChar, setSuggestionIndexChar] = useState(0)
    const charDuration = 30
    const waitAfterComplete = 3000

    useEffect(() => {
        typeWriter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedSuggestion, suggestionIndexChar])

    var txt = intl.formatMessage({ id: suggestions[suggestion] })

    function typeWriter() {
        setTimeout(() => {
            if (txt && suggestionIndexChar < txt.length) {
                setDisplayedSuggestion(displayedSuggestion + txt.charAt(suggestionIndexChar))
                setSuggestionIndexChar(suggestionIndexChar + 1)
            }
        }, charDuration)
    }

    useEffect(() => {
        const durationForInterval = txt.length * charDuration + waitAfterComplete
        const interval = setInterval(() => {
            if (suggestions) {
                if (suggestion >= suggestions.length - 1) {
                    setSuggestion(0)
                    setSuggestionIndexChar(0)
                    setDisplayedSuggestion('')
                } else {
                    setSuggestion(suggestion + 1)
                    setSuggestionIndexChar(0)
                    setDisplayedSuggestion('')
                }
            }
        }, durationForInterval)

        return () => {
            clearInterval(interval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestion, suggestions.length])

    return (
        <ConnectedTextField
            {...otherProps}
            shrinkLabel
            placeholder={displayedSuggestion}
            inputSx={
                colorfulPlaceholder && {
                    background: 'red',
                    color: 'transparent',
                    '& ::placeholder': {
                        background: 'linear-gradient(90deg, rgba(255,105,0,1) 0%, #ff006e 100%)',
                        opacity: 1,
                        backgroundClip: 'text',
                        webkitTextFillColor: 'transparent',
                        width: 'fit-content',
                    },
                    '&:not(:placeholder-shown)': {
                        background: theme.palette.text.primary,
                        backgroundClip: 'text, padding-box',
                        webkitTextFillColor: 'transparent',
                    },
                }
            }
        />
    )
}
