import { createAsyncThunk } from '@reduxjs/toolkit'
import { Product } from '../accounts/Product'
import { HmstrState } from '../../core/Store'
import { doDelete, doGet, doPost, doPut } from '../../core/api/ApiClient'
import { PaymentMethod, SetupIntent } from '@stripe/stripe-js'
import { BillingSubscription } from './BillingSubscription'
import { BillingInvoice } from './BillingInvoice'
import { Account } from '../accounts/Account'

const BILLING_SLASH = (action: string) => `billing/${action}`

export const fetchProducts = createAsyncThunk<Product[], void, { state: HmstrState }>(BILLING_SLASH('fetchProducts'), async (args, thunkAPI) => {
    const state = thunkAPI.getState()
    return await doGet(thunkAPI, state.api.entryPoint._links.products, undefined, {
        params: { with_trial: true },
    })
})

export const fetchPaymentMethods = createAsyncThunk<PaymentMethod[], Account, { state: HmstrState }>(
    BILLING_SLASH('fetchPaymentMethods'),
    async (account, thunkAPI) => {
        return await doGet(thunkAPI, account._links.payment_methods)
    }
)

export const fetchSubscriptionsForUser = createAsyncThunk<BillingSubscription[], void, { state: HmstrState }>(
    BILLING_SLASH('fetchSubscriptions'),
    async (args, thunkAPI) => {
        return await doGet(thunkAPI, thunkAPI.getState().api.entryPoint._links.subscriptions)
    }
)

export const fetchSubscriptionById = createAsyncThunk<BillingSubscription, Account, { state: HmstrState }>(
    BILLING_SLASH('fetchSubscriptionById'),
    async (account, thunkAPI) => {
        return await doGet(thunkAPI, account._links.subscription)
    }
)

export const updateDefaultPaymentMethod = createAsyncThunk<void, { paymentMethod: PaymentMethod; account: Account }, { state: HmstrState }>(
    BILLING_SLASH('updateDefaultPaymentMethod'),
    async (args, thunkAPI) => {
        return await doPut(thunkAPI, args.account._links.default_payment_method, args.paymentMethod)
    }
)

export const fetchInvoices = createAsyncThunk<BillingInvoice[], Account, { state: HmstrState }>(BILLING_SLASH('fetchInvoices'), async (account, thunkAPI) => {
    return await doGet(thunkAPI, account._links.invoices)
})

export const updatePaymentMethodForSubscription = createAsyncThunk<
    BillingSubscription,
    { billingSubscription: BillingSubscription; paymentMethodId: string },
    { state: HmstrState }
>(BILLING_SLASH('updatePaymentMethodForSubscription'), async (args, thunkAPI) => {
    const { billingSubscription, paymentMethodId } = args
    return doPut(thunkAPI, billingSubscription._links.self, {
        payment_method_id: paymentMethodId,
    })
})

export const cancelBillingSubscription = createAsyncThunk<BillingSubscription, BillingSubscription, { state: HmstrState }>(
    BILLING_SLASH('cancelBillingSubscription'),
    async (subscription, thunkAPI) => {
        return doDelete(thunkAPI, subscription._links.self)
    }
)

export const continueBillingSubscription = createAsyncThunk<BillingSubscription, BillingSubscription, { state: HmstrState }>(
    BILLING_SLASH('continueBillingSubscription'),
    async (subscription, thunkAPI) => {
        return doPost(thunkAPI, subscription._links.self, {})
    }
)

export const deletePaymentMethod = createAsyncThunk<PaymentMethod, PaymentMethod>(BILLING_SLASH('deletePaymentMethod'), async (paymentMethod, thunkAPI) => {
    return doDelete(thunkAPI, paymentMethod._links.self, () => paymentMethod)
})

export const createSetupIntent = createAsyncThunk<SetupIntent, Account, { state: HmstrState }>(
    BILLING_SLASH('createSetupIntent'),
    async (account, thunkAPI) => {
        return doPost(thunkAPI, account._links.setup_intents, {})
    }
)

export const cancelSetupIntent = createAsyncThunk<SetupIntent, SetupIntent, { state: HmstrState }>(
    BILLING_SLASH('cancelSetupIntent'),
    async (args, thunkAPI) => {
        return doDelete(thunkAPI, args._links.self)
    }
)

export const cancelSubscriptionUpdate = createAsyncThunk<BillingSubscription, BillingSubscription>(
    BILLING_SLASH('cancelSubscriptionUpdate'),
    async (subscription, thunkAPI) => {
        return doDelete(thunkAPI, subscription._links.cancel_update)
    }
)
