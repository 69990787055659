import * as React from 'react'
import { FC, Fragment } from 'react'
import { alpha, Chip, Tooltip, Typography, useTheme } from '@mui/material'
import moment from 'moment/moment'
import { FormattedMessage } from 'react-intl'
import { clampText, replaceHashtags } from '../../core/theme/helper'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { previewPost } from '../../content-analytics/posts/PostsSlice'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { CommonPostData } from '../../content-analytics/posts/CommonPostData'
import { useDispatch, useSelector } from 'react-redux'
import { InstagramIcon } from '../icons/InstagramIcon'
import { FacebookIcon } from '../icons/FacebookIcon'
import { LinkedInIcon } from '../icons/LinkedInIcon'
import { TiktokIcon } from '../icons/TiktokIcon'
import { Link as LinkIcon, PhotoCamera, Videocam } from '@mui/icons-material'
import { getIsMobile, isDarkMode } from '../../core/slices/CoreSlice'
import { replaceMentions } from '../../publishing/mentions/MentionService'

type PostInfoProps = {
    post: CommonPostData
    datasource: ProjectDatasource
}

export const PostInfo: FC<PostInfoProps> = ({ post, datasource }) => {
    const dispatch = useDispatch()
    const isMobile = useSelector(getIsMobile)
    const darkmode = useSelector(isDarkMode)
    const mentionColor = darkmode ? 'hsl(212,100%,70%)' : 'rgb(0, 55, 200)'
    const backgroundColor = darkmode ? alpha('hsl(214, 100%, 59%)', 0.3) : alpha('rgb(0, 55, 200)', 0.1)
    const theme = useTheme()

    const getTypeIcon = () => {
        switch (post.post_type?.toLocaleLowerCase()) {
            case 'link':
                return (
                    <Tooltip
                        placement="top"
                        arrow={true}
                        disableInteractive
                        title={
                            <Typography>
                                <FormattedMessage id="facebook.link-posting" />
                            </Typography>
                        }
                    >
                        <LinkIcon color="action" fontSize="small" />
                    </Tooltip>
                )
            case 'photo':
            case 'image':
                return (
                    <Tooltip
                        placement="top"
                        arrow={true}
                        disableInteractive
                        title={
                            <Typography>
                                <FormattedMessage id="facebook.photo-posting" />
                            </Typography>
                        }
                    >
                        <PhotoCamera color="action" fontSize="small" />
                    </Tooltip>
                )
            case 'video':
                return (
                    <Tooltip
                        placement="top"
                        arrow={true}
                        disableInteractive
                        title={
                            <Typography>
                                <FormattedMessage id="facebook.video-posting" />
                            </Typography>
                        }
                    >
                        <Videocam color="action" fontSize="small" />
                    </Tooltip>
                )
            default:
                return null
        }
    }

    const getIconByType = (postType: CommonPostData['type']) => {
        switch (postType) {
            case 'INSTAGRAM':
                return <InstagramIcon />
            case 'FACEBOOK':
            case 'DARK_POST':
                return <FacebookIcon />
            case 'LINKED_IN':
                return <LinkedInIcon />
            case 'TIKTOK':
                return <TiktokIcon fontSize={'inherit'} fill={'inherit'} />
            default:
        }
    }

    const replaceMentionsInPostMessage = (oldText: string) => {
        const newText = replaceMentions(oldText, mentionColor, {
            bold: true,
            disableCursorPointer: true,
            paddingInline: '5px',
            backgroundColor: backgroundColor,
            borderRadius: `${theme.shape.borderRadius}px`,
        })
        return <span dangerouslySetInnerHTML={{ __html: newText }}></span>
    }

    return (
        <Fragment>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {getIconByType(post.type)}
                <Typography variant="subtitle1" sx={{ margin: '0 0 0 8px' }}>
                    {datasource.name}
                </Typography>
            </div>
            <Typography variant="subtitle1" gutterBottom component="div" style={{ display: 'flex', alignItems: 'center' }}>
                {moment(post.created_at).format('DD.MM.YYYY | HH:mm')}
                <div style={{ marginLeft: '12px', display: 'flex' }}>{getTypeIcon()}</div>
                {post.type === 'DARK_POST' && <Chip sx={{ ml: 1 }} size="small" color="primary" label={<FormattedMessage id="tagging.dark-post" />} />}
            </Typography>
            <Typography
                style={{ maxWidth: !post.picture ? 870 : 600, minHeight: 80 }}
                color={post.message === '{{product.brand}}' ? 'text.secondary' : undefined}
            >
                {post.message === '{{product.brand}}' ? (
                    <em>
                        <FormattedMessage id="tagging.dynamic-ad" />
                    </em>
                ) : (
                    replaceMentionsInPostMessage(clampText(replaceHashtags(post.message) || '', isMobile ? 500 : 220))
                )}
            </Typography>
            <div style={{ display: 'flex', margin: '12px 0 0 0' }}>
                <a style={{ display: 'flex', color: 'inherit' }} href={post.perma_link} target={'_blank'} rel="noreferrer">
                    <OpenInNewIcon style={{ margin: '0 4px 0 0' }} />
                    <FormattedMessage id="general.open-link" />
                </a>
                {(post.type === 'FACEBOOK' || post.type === 'DARK_POST' || post.type === 'TIKTOK') && (
                    <div
                        style={{ display: 'flex', margin: '0 0 0 8px', cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() => dispatch(previewPost(post))}
                    >
                        <OpenInBrowserIcon style={{ margin: '0 4px 0 0' }} />
                        <FormattedMessage id="general.view-post" />
                    </div>
                )}
            </div>
        </Fragment>
    )
}
