import React, { useEffect } from 'react'
import { Grid, Grow, Link, Typography } from '@mui/material'
import { BarChart, BubbleChart, DashboardCustomize, DonutLarge, Insights, MultilineChart, PieChart } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { BubbleBox } from './NoDashboards.styles'
import './NoDashboardsStyles.css'
import { setDashboardWizardStep } from './DashboardsSlice'
import { getIsMobile, openFullscreenDialog } from '../core/slices/CoreSlice'

export const NoDashboards = () => {
    const dispatch = useDispatch()
    const isMobile = useSelector(getIsMobile)

    const [grow1, setGrow1] = React.useState(false)
    const [grow2, setGrow2] = React.useState(false)
    const [grow3, setGrow3] = React.useState(false)

    useEffect(() => {
        const timeout1 = setTimeout(() => {
            setGrow1(true)
        }, 500)

        const timeout2 = setTimeout(() => {
            setGrow2(true)
        }, 1300)

        const timeout3 = setTimeout(() => {
            setGrow3(true)
        }, 1600)
        return () => {
            clearTimeout(timeout1)
            clearTimeout(timeout2)
            clearTimeout(timeout3)
        }
    }, [])

    const handleNewDashboard = () => {
        dispatch(setDashboardWizardStep(0))
        dispatch(openFullscreenDialog('new-dashboard'))
    }

    return (
        <Grid container direction={'column'} spacing={2} alignItems={'center'}>
            <Grid item xs={12}>
                <BubbleBox>
                    <Grow in={grow1} timeout={{ enter: 1000 }}>
                        <DashboardCustomize sx={{ fontSize: '15em', color: 'primary.light' }} />
                    </Grow>
                    <Grow in={grow2} timeout={{ enter: 600 }}>
                        <BarChart
                            sx={{
                                position: 'absolute',
                                fontSize: '5em',
                                right: '0.75rem',
                                top: '2.5rem',
                                animation: 'size 5s infinite',
                                color: 'grey !important',
                            }}
                        />
                    </Grow>
                    <Grow in={grow2} timeout={{ enter: 900 }}>
                        <BubbleChart
                            sx={{
                                position: 'absolute',
                                fontSize: '3em',
                                right: '5rem',
                                top: '2.5rem',
                                animation: 'size 7s infinite',
                                color: 'grey !important',
                            }}
                        />
                    </Grow>
                    <Grow in={grow2} timeout={{ enter: 300 }}>
                        <PieChart
                            sx={{
                                position: 'absolute',
                                fontSize: '3em',
                                right: '0.1rem',
                                top: '8rem',
                                animation: 'size 6s infinite',
                                color: 'grey !important',
                            }}
                        />
                    </Grow>
                    <Grow in={grow3} timeout={{ enter: 600 }}>
                        <Insights
                            sx={{
                                position: 'absolute',
                                fontSize: '5em',
                                left: '1rem',
                                bottom: '3.5rem',
                                animation: 'size 5s infinite',
                                color: 'grey !important',
                            }}
                        />
                    </Grow>
                    <Grow in={grow3} timeout={{ enter: 900 }}>
                        <DonutLarge
                            sx={{
                                position: 'absolute',
                                fontSize: '3em',
                                left: '0.1rem',
                                bottom: '7.5rem',
                                animation: 'size 6s infinite',
                                color: 'grey !important',
                            }}
                        />
                    </Grow>
                    <Grow in={grow3} timeout={{ enter: 300 }}>
                        <MultilineChart
                            sx={{
                                position: 'absolute',
                                fontSize: '2.5em',
                                left: '5rem',
                                bottom: '2rem',
                                animation: 'size 7s infinite',
                                color: 'grey !important',
                            }}
                        />
                    </Grow>
                </BubbleBox>
            </Grid>
            <Grid item>
                <Typography variant="h4">
                    <FormattedMessage id="hint.no-dashboards" />
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={'h6'} color={'inherit'} sx={{ fontWeight: 'lighter' }}>
                    <FormattedMessage
                        id={`reporting.dashboards.create-hint${isMobile ? '.mobile' : ''}`}
                        values={
                            !isMobile
                                ? {
                                      link: (
                                          <Link
                                              sx={{
                                                  display: 'inline',
                                                  fontWeight: 'lighter',
                                                  textDecoration: 'underline',
                                                  cursor: 'pointer',
                                              }}
                                              onClick={handleNewDashboard}
                                          >
                                              <FormattedMessage id="reporting.dashboards.create-label" />
                                          </Link>
                                      ),
                                  }
                                : undefined
                        }
                    />
                </Typography>
            </Grid>
        </Grid>
    )
}
