import * as React from 'react'
import { FC, useEffect } from 'react'
import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { dismissSnackbar, getActiveSnackBars, getIsMobile, SnackBar } from '../../core/slices/CoreSlice'
import { forEach } from 'lodash'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Box, IconButton, Slide, SlideProps, Typography } from '@mui/material'
import { Close, East, Error } from '@mui/icons-material'
import { ProgressHideBar } from './ProgressHideBar'

type SnackbarHandlerProps = {}

const TransitionRight = (props: SlideProps) => <Slide {...props} direction="left" />
const TransitionDown = (props: SlideProps) => <Slide {...props} direction="down" />

export const SnackbarHandler: FC<SnackbarHandlerProps> = () => {
    const dispatch = useDispatch()
    const activeSnackbars = useSelector(getActiveSnackBars)
    const isMobile = useSelector(getIsMobile)
    const anchorOrigin: { vertical: 'top' | 'bottom'; horizontal: 'right' | 'center' } = isMobile
        ? { vertical: 'top', horizontal: 'center' }
        : { vertical: 'bottom', horizontal: 'right' }
    const autoHideDuration = 4000

    const generateSnackbarContent = (snackbar: SnackBar) => {
        return (
            <>
                {snackbar.snackbarMessage && (
                    <FormattedMessage
                        id={snackbar.snackbarMessage}
                        values={{
                            link: snackbar.snackbarLink ? (
                                <Link style={{ color: 'inherit' }} to={snackbar.snackbarLink.href} onClick={() => closeSnackbar(snackbar.id)}>
                                    <Typography
                                        sx={{
                                            display: 'inline-flex',
                                            fontSize: 'inherit',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingInlineEnd: 0.5,
                                            paddingInlineStart: 0.5,
                                            gap: 0.25,
                                            fontWeight: 'normal',
                                            transition: 'all 0.3s',
                                            textDecoration: 'underline',
                                            '&:hover': {
                                                fontWeight: 'bolder',
                                            },
                                            '& .linkIcon': {
                                                maxWidth: '0px',
                                            },
                                            '&:hover .linkIcon': {
                                                maxWidth: '25px',
                                            },
                                        }}
                                    >
                                        {snackbar.snackbarLink.text}
                                        <East
                                            className={'linkIcon'}
                                            sx={{
                                                transition: 'all 0.3s',
                                                fontSize: 'inherit',
                                            }}
                                        />
                                    </Typography>
                                </Link>
                            ) : undefined,
                        }}
                    />
                )}
                {!snackbar.persistant && <ProgressHideBar duration={snackbar.autoHideDuration || autoHideDuration} />}
            </>
        )
    }

    useEffect(() => {
        forEach(activeSnackbars, (snackbar) => {
            const snackbarStatus = snackbar.snackbarStatus
            enqueueSnackbar(generateSnackbarContent(snackbar), {
                autoHideDuration: snackbar.autoHideDuration || autoHideDuration,
                variant: snackbarStatus,
                anchorOrigin: anchorOrigin,
                TransitionComponent: isMobile ? TransitionDown : TransitionRight,
                SnackbarProps: {
                    style: {
                        maxWidth: isMobile ? '100%' : '70vw',
                        display: 'flex',
                        position: 'relative',
                        pointerEvents: !snackbar.persistant ? 'none' : undefined,
                    },
                },
                persist: !!snackbar.persistant,
                action: !!snackbar.persistant
                    ? (snackbarId) => (
                          <IconButton onClick={() => closeSnackbar(snackbarId)} color={'inherit'}>
                              <Close />
                          </IconButton>
                      )
                    : undefined,
                key: snackbar.id,
                onClose: () => {
                    dispatch(dismissSnackbar(snackbar.id))
                },
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSnackbars.length])

    return (
        <Box
            sx={{
                '& .notistack-MuiContent': {
                    display: 'flex',
                    flexWrap: 'nowrap',
                },
                '& .notistack-MuiContent div:nth-of-type(2)': {
                    height: '100%',
                    alignItems: 'flex-start',
                },
            }}
        >
            <SnackbarProvider
                maxSnack={5}
                dense={isMobile}
                disableWindowBlurListener
                preventDuplicate
                iconVariant={{
                    error: <Error sx={{ marginInlineEnd: 1 }} fontSize={'small'} />,
                }}
            />
        </Box>
    )
}
