import { FC, useState } from 'react'
import { HmstrAsideLayout } from '../../common/hmstr-aside-layout/HmstrAsideLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedAccount } from '../../core/slices/CoreSlice'
import { Box, Container, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { HeaderWithSwitcher } from '../../common/header-with-switcher/HeaderWithSwitcher'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { HmstrDispatch } from '../../core/Store'
import { Loading } from '../../common/loading/Loading'
import { fetchAccount } from './AccountActions'

type AccountPaymentInProcessProps = {}

export const AccountPaymentInProcess: FC<AccountPaymentInProcessProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const account = useSelector(getSelectedAccount)
    const [counter, setCounter] = useState(0)

    useEffectWithIdComparison(() => {
        let interval: any

        if (counter <= 10) {
            interval = setInterval(() => {
                dispatch(fetchAccount(account._links.self))
                setCounter(counter + 1)
            }, 2000)
        }

        return () => clearInterval(interval)
    }, [account, counter])

    return (
        <HmstrAsideLayout>
            <Container maxWidth="md">
                <HeaderWithSwitcher />

                <Box mt={4}>
                    {counter <= 10 ? (
                        <Box>
                            <Typography variant="subtitle1" sx={{ mt: 4 }}>
                                <FormattedMessage id="hint.account.payment-in-process" />
                            </Typography>

                            <Box width="100%" textAlign="center">
                                <Loading />
                            </Box>
                        </Box>
                    ) : (
                        <Typography variant="subtitle1">
                            <FormattedMessage id="hint.account.payment-failed" />
                        </Typography>
                    )}
                </Box>
            </Container>
        </HmstrAsideLayout>
    )
}
