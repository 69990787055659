import { Box, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../core/slices/CoreSlice'
import { isMobileDevice } from '../core/theme/helper'

export const BubbleBox = styled(Box)(() => ({
    padding: '5em',
    display: 'flex',
    animation: 'bgsize 30s infinite',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginTop: isMobileDevice() ? '-5rem' : '0rem',
    maxWidth: '99vw',
    position: 'relative',
    backgroundImage: useSelector(isDarkMode)
        ? "url(\"data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff10' d='M56.9 -61C72.1 -55 81.6 -35.6 81.9 -17C82.1 1.6 73 19.2 61.3 31.5C49.6 43.7 35.2 50.6 19.9 57.3C4.5 64.1 -11.8 70.8 -29.2 69.2C-46.5 67.6 -64.8 57.8 -71.5 42.9C-78.1 28 -73.2 8 -66.2 -8.2C-59.1 -24.3 -50 -36.5 -38.7 -43.2C-27.3 -49.8 -13.7 -50.8 3.6 -55.1C20.8 -59.4 41.7 -67 56.9 -61Z' transform='translate(100 100)' /%3E%3C/svg%3E\")"
        : "url(\"data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2300000010' d='M56.9 -61C72.1 -55 81.6 -35.6 81.9 -17C82.1 1.6 73 19.2 61.3 31.5C49.6 43.7 35.2 50.6 19.9 57.3C4.5 64.1 -11.8 70.8 -29.2 69.2C-46.5 67.6 -64.8 57.8 -71.5 42.9C-78.1 28 -73.2 8 -66.2 -8.2C-59.1 -24.3 -50 -36.5 -38.7 -43.2C-27.3 -49.8 -13.7 -50.8 3.6 -55.1C20.8 -59.4 41.7 -67 56.9 -61Z' transform='translate(100 100)' /%3E%3C/svg%3E\")",
}))
