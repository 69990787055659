import { FC } from 'react'
import { Card, CardContent, Grid, InputLabel, LinearProgress, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { getSelectedAccount } from '../../../core/slices/CoreSlice'
import { getSelectedAccountSubscription } from '../../SettingsSlice'
import { Loading } from '../../../common/loading/Loading'

type AccountEditLimitsFormProps = {}

export const AccountOverviewAdditionals: FC<AccountEditLimitsFormProps> = () => {
    const account = useSelector(getSelectedAccount)
    const subscription = useSelector(getSelectedAccountSubscription)

    const renderProgressBar = (value: number, maxValue: number, label: string) => (
        <Grid item xs={12}>
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <InputLabel shrink={true}>
                        <FormattedMessage id={label} />
                    </InputLabel>
                </Grid>
                <Grid item xs={10}>
                    <LinearProgress color={'inherit'} variant="determinate" value={(value / maxValue) * 100} />
                </Grid>
                <Grid item xs={2} textAlign="right">
                    <Typography variant="body2" color="text.secondary">
                        {`${value} / ${maxValue}`}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )

    if (!account || !subscription) {
        return <Loading />
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        <FormattedMessage id="billing.limits" />
                    </Typography>

                    {renderProgressBar(account.user_count, account.user_limit, 'general.users')}
                    {renderProgressBar(account.data_source_count, account.data_source_limit, 'general.datasources')}
                    {renderProgressBar(account.conversion_tracking_count, account.conversion_tracking_limit, 'conversion-tracking.title')}
                </CardContent>
            </Card>
        </Grid>
    )
}
