import { FC } from 'react'
import { Field } from 'react-final-form'
import { Moment } from 'moment/moment'
import { DatetimePicker, DatetimePickerProps } from '../common/datetime-picker/DatetimePicker'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

type ConnectedDateTimePickerProps = {
    name: string
    label?: string
    variant?: DatetimePickerProps['variant']
    required?: boolean
    helperText?: string
    minDateHelperText?: string
    maxDateHelperText?: string
    shrinkLabel?: boolean
    validate?: any
    fullWidth?: boolean
    disabled?: boolean
    disableValidation?: boolean
    minDateTime?: Moment
    maxDateTime?: Moment
}

export const ConnectedDateTimePicker: FC<ConnectedDateTimePickerProps> = (props) => {
    const { name, validate, disabled, label, fullWidth, disableValidation, minDateTime, maxDateTime, required, variant } = props

    return (
        <Field name={name} validate={validate}>
            {({ input, meta }) => {
                const handleChange = (date: Moment | null) => {
                    if (date) {
                        input.onChange(date.clone().utc().format().replace('Z', '.000Z'))
                    } else {
                        input.onChange(undefined)
                    }
                }

                return (
                    <DatetimePicker
                        variant={variant}
                        onChange={handleChange}
                        value={moment(input.value)}
                        disabled={disabled}
                        error={(meta.error || meta.submitError) && (meta.visited || meta.touched || meta.dirty || meta.initial !== undefined)}
                        helperText={
                            (meta.visited || meta.touched || meta.dirty || meta.initial !== undefined) &&
                            (meta.error || meta.submitError) && <FormattedMessage id={meta.error || meta.submitError} />
                        }
                        label={label}
                        fullWidth={fullWidth}
                        minDateTime={minDateTime}
                        maxDateTime={maxDateTime}
                        disableValidation={disableValidation}
                        required={required}
                    />
                )
            }}
        </Field>
    )
}
