import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dismissApiError } from '../../core/slices/CoreSlice'
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Link, Tooltip, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApiError } from '../../core/api/ApiClient'
import { Error, FileCopy } from '@mui/icons-material'
import { getIsProductive } from '../../core/api/ApiSlice'

type ErrorDialogProps = {
    apiError?: ApiError
}

export const ErrorDialog: FC<ErrorDialogProps> = ({ apiError }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const mailSubject = intl.formatMessage({ id: 'general.error.mailSubject' }, { errorId: apiError?.error_id || '' })
    const mailToLink = `mailto:support@hmstr.de?subject=${mailSubject}`
    const isProd = useSelector(getIsProductive)

    const handleClose = () => dispatch(dismissApiError())

    const handleCopyClick = () => {
        navigator.clipboard.writeText(apiError?.error_id || '').then(() => console.log('Copied error id to clipboard'))
    }

    return (
        <Dialog open={Boolean(apiError)} onClose={handleClose} aria-labelledby="error-dialog-title" aria-describedby="error-dialog-description">
            <DialogContent>
                <Box padding={1} maxWidth={400}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Error sx={{ mr: 1 }} /> <FormattedMessage id="general.error" />
                        </Typography>
                    </Box>

                    {!isProd && (
                        <Typography variant="caption" component="div">
                            <FormattedMessage id="general.status" />: {apiError?.status}
                        </Typography>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="caption" component="div">
                            <FormattedMessage id="general.errorID" />: <strong>{apiError?.error_id}</strong>
                        </Typography>

                        <Tooltip placement="right" title={<FormattedMessage id="general.copy" />}>
                            <IconButton onClick={handleCopyClick} sx={{ ml: 2 }}>
                                <FileCopy />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Typography variant="caption" component="div">
                        {isProd ? (
                            <Box sx={{ mt: 2 }}>
                                <FormattedMessage
                                    id="general.contact-support"
                                    values={{
                                        supportMail: (
                                            <Link target="_blank" href={mailToLink}>
                                                support@hmstr.de
                                            </Link>
                                        ),
                                    }}
                                />
                            </Box>
                        ) : (
                            <Box component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
                                {apiError ? (typeof apiError.detail === 'object' ? JSON.stringify(apiError.detail) : apiError.detail) : ''}
                            </Box>
                        )}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    <FormattedMessage id="general.close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
