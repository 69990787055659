import * as React from 'react'
import { FC } from 'react'
import { SimpleTicket } from './SimpleTicket'
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { SimpleUser } from '../settings/user-management/SimpleUser'
import { ChatBubbleOutline } from '@mui/icons-material'
import { UserAvatar } from '../common/avatars/UserAvatar'
import { renderTicketId } from './renderTicketId'

type TicketCardMobileProps = {
    ticket: SimpleTicket
    icon: any
    message: string | JSX.Element
    datasourceIcon: any
    datasource: ProjectDatasource
    assignee?: SimpleUser
    onClick?: () => void
}

export const TicketCardMobile: FC<TicketCardMobileProps> = ({ ticket, icon, message, datasourceIcon, datasource, assignee, onClick }) => {
    const theme = useTheme()
    return (
        <Card onClick={onClick} sx={{ flexShrink: 0 }}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Box fontWeight={600} display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography>#{renderTicketId(ticket.ticket_id)}</Typography>
                    <Box
                        sx={{
                            pl: 1,
                            pr: 1,
                            backgroundColor: theme.palette.secondary.main,
                            height: 24,
                            borderRadius: 12,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography color="white">{ticket.unread_node_count < 100 ? ticket.unread_node_count : '99+'}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        wordBreak: 'break-all',
                    }}
                >
                    {message}
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" width={'100%'} gap={0.5}>
                    <Box display={'flex'} alignItems={'center'} gap={0.5}>
                        {datasourceIcon}
                        {datasource.name}
                    </Box>
                    <Box flexGrow={1} />
                    <Box>{assignee && <UserAvatar user={assignee} />}</Box>
                    {icon}
                    <Box display={'flex'} alignItems={'center'} gap={0.5}>
                        <ChatBubbleOutline fontSize="small" htmlColor={theme.palette.text.secondary} />
                        <Typography>{ticket.node_count}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
