import * as React from 'react'
import { FC } from 'react'
import { Box, FormControlLabel, Grid, LinearProgress, Switch, Tooltip, Typography } from '@mui/material'
import { Datepicker } from '../../common/datepicker/Datepicker'
import moment, { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeAttributionWindows,
    changeCompareTimePeriod,
    changeEstimateWithoutConsentLoss,
    changeTimePeriod,
    getSelectedDashboardAttributionWindows,
    getSelectedDashboardCompareEndDate,
    getSelectedDashboardCompareStartDate,
    getSelectedDashboardDatasourceIds,
    getSelectedDashboardEndDate,
    getSelectedDashboardEstimateWithoutConsentLoss,
    getSelectedDashboardStartDate,
    getSelectedDashboardTags,
    isDashboardDataLoadingInBackground,
    selectDatasourcesDashboard,
    setSelectedDashboardTags,
} from '../../dashboards/DashboardsSlice'
import { FormattedMessage, useIntl } from 'react-intl'
import { Info } from '@mui/icons-material'
import { AttributionWindowFilter } from '../../common/AttributionWindowFilter'
import { DatasourceSelection } from '../../content-analytics/datasource/DatasoureSelection'
import { CustomTagFilter } from '../../common/filter/CustomTagFilter'
import { Tag } from '../../tags/Tag'
import { getTags } from '../../core/slices/DataSlice'
import * as _ from 'lodash'
import { DateRangePicker } from '../../common/daterange-picker/DateRangePicker'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchTagsForProject } from '../../tags/TagsActions'
import { getIsMobile, getPagesForAnalyse, getSelectedProject } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { DashboardTemplate } from '../../dashboards/DashboardTemplate'
import { ProjectDatasourceType } from '../../settings/datasources/ProjectDatasource'
import { fetchDataForSelectedDashboard } from '../../dashboards/DashboardsActions'
import { defaultMonthlyPeriod } from '../../dashboards/TemplateDefaultTimePeriods'
import { DateView } from '@mui/x-date-pickers'

type DynamicToolbarProps = {
    template?: DashboardTemplate
}

export const DynamicToolbar: FC<DynamicToolbarProps> = ({ template }) => {
    const isMobile = useSelector(getIsMobile)
    const hasCompareTimePeriod = template?.settings.available_settings.compare_time_period
    const hasConsentLoss = template?.settings.available_settings.consent_loss
    const hasAttributionWindows = template?.settings.available_settings.attribution_windows
    const hasTagSelection = template?.settings.available_settings.tags || false
    const hasDatasourceSelection = template?.settings.available_settings.data_sources || false
    const granularity = template?.settings.available_time_interval || false
    const pagesForAnalyse = useSelector(getPagesForAnalyse)

    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()
    const monthlyReportStartDate = useSelector(getSelectedDashboardStartDate)
    const monthlyReportCompareStartDate = useSelector(getSelectedDashboardCompareStartDate)
    const attributionWindows = useSelector(getSelectedDashboardAttributionWindows)
    const estimateWithousConsentLoss = useSelector(getSelectedDashboardEstimateWithoutConsentLoss)
    const selectedDataSourceIds = useSelector(getSelectedDashboardDatasourceIds)
    const selectedProject = useSelector(getSelectedProject)
    const availableTags = useSelector(getTags)
    const tags = _.map(availableTags, (tag) => tag)
    const selectedTags = useSelector(getSelectedDashboardTags)
    const timePeriodStart = useSelector(getSelectedDashboardStartDate)
    const timePeriodEnd = useSelector(getSelectedDashboardEndDate)
    const compareTimePeriodStart = useSelector(getSelectedDashboardCompareStartDate)
    const compareTimePeriodEnd = useSelector(getSelectedDashboardCompareEndDate)
    const loadingInBackground = useSelector(isDashboardDataLoadingInBackground)
    const allowedDatasourceTypes: ProjectDatasourceType[] = _.flatMap(template?.data_sources, (d) => d.type)

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTagsForProject(selectedProject))
        }
    }, [selectedProject])

    const handleTimePeriodChange = (startDate: string, endDate: string) => {
        dispatch(changeTimePeriod({ startDate, endDate }))
        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
    }

    const handleCompareTimePeriodChange = (startDate: string, endDate: string) => {
        dispatch(changeCompareTimePeriod({ startDate, endDate }))
        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
    }

    const getMonthIntervalForDate = (date: Moment) => {
        const startDate = date.clone().startOf('month').format('YYYY-MM-DD')
        const endDate = date.clone().endOf('month').format('YYYY-MM-DD')
        return { startDate, endDate }
    }

    const getYearIntervalForDate = (date: Moment) => {
        const startDate = date.clone().startOf('year').format('YYYY-MM-DD')
        const endDate = date.clone().endOf('year').format('YYYY-MM-DD')
        return { startDate, endDate }
    }

    const handleMonthIntervalChange = (date: Moment | null) => {
        if (date) {
            dispatch(changeTimePeriod(getMonthIntervalForDate(date)))
            dispatch(changeCompareTimePeriod(getMonthIntervalForDate(date.clone().subtract(1, 'month'))))
            dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
        }
    }
    const handleYearIntervalChange = (date: Moment | null) => {
        if (date) {
            dispatch(changeTimePeriod(getYearIntervalForDate(date)))
            dispatch(changeCompareTimePeriod(getYearIntervalForDate(date.clone().subtract(1, 'year'))))
            dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
        }
    }

    const getDateGranularity = (): DateView[] => {
        if (granularity === 'flexible') {
            return ['day']
        } else if (granularity === 'month') {
            return ['year', 'month']
        } else if (granularity === 'year') {
            return ['year']
        } else {
            return ['day']
        }
    }

    const getOpenTo = (): DateView => {
        if (granularity === 'flexible') {
            return 'day'
        } else if (granularity === 'month') {
            return 'month'
        } else if (granularity === 'year') {
            return 'year'
        } else {
            return 'day'
        }
    }

    const handleConsentLossChange = () => {
        dispatch(changeEstimateWithoutConsentLoss(!estimateWithousConsentLoss))
        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
    }

    const handleAttributionWindowsChange = (attributionWindows: ('1d_click' | '7d_click' | '1d_view')[]) => {
        dispatch(changeAttributionWindows(attributionWindows))
        dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
    }

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
            <Grid item xs={12} width={'100%'} sx={{ height: '2px' }}>
                {loadingInBackground && <LinearProgress color={'secondary'} variant={'indeterminate'} sx={{ height: '2px' }} />}
            </Grid>
            {hasConsentLoss && (
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch color="secondary" disabled={loadingInBackground} checked={estimateWithousConsentLoss} onChange={handleConsentLossChange} />
                        }
                        label={
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ mr: 1 }}>
                                    <FormattedMessage id="facebook.ads.conversion-estimate-without-consent-loss" />
                                </Typography>
                                <Tooltip title={<FormattedMessage id="facebook.ads.conversion-estimate-without-consent-loss.tooltip" />}>
                                    <Info sx={{ cursor: 'default' }} />
                                </Tooltip>
                            </Box>
                        }
                    />
                </Grid>
            )}

            {hasAttributionWindows && (
                <Grid item xs={12}>
                    <AttributionWindowFilter
                        value={attributionWindows || []}
                        disabled={loadingInBackground}
                        onChange={handleAttributionWindowsChange}
                        variant={'outlined'}
                    />
                </Grid>
            )}

            {hasDatasourceSelection && (
                <Grid item xs={12}>
                    <DatasourceSelection
                        datasources={pagesForAnalyse}
                        fullWidth
                        allowedTypes={allowedDatasourceTypes}
                        disabled={loadingInBackground}
                        initialIds={selectedDataSourceIds!}
                        onDatasourceChange={(ids) => {
                            dispatch(selectDatasourcesDashboard(ids))
                            dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
                        }}
                    />
                </Grid>
            )}

            {hasTagSelection && (
                <Grid item xs={12}>
                    <CustomTagFilter
                        label={intl.formatMessage({ id: 'filters.tags' })}
                        tags={tags}
                        selectedTagIds={selectedTags!}
                        showUntaggedOption={true}
                        onChange={(newValue: (String | Tag | undefined)[]) => {
                            let values = Object.values(newValue)
                            let selectedTags: string[] = []

                            values.forEach((element) => {
                                // noinspection SuspiciousTypeOfGuard
                                if (!!element && typeof element !== 'string') {
                                    // @ts-ignore
                                    selectedTags.push(element.id)
                                }
                            })
                            if (selectedTags.length === 0) {
                                dispatch(setSelectedDashboardTags(['THEREISNOTAG']))
                                dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
                            } else {
                                dispatch(setSelectedDashboardTags(selectedTags))
                                dispatch(fetchDataForSelectedDashboard({ reloadDashboard: false }))
                            }
                        }}
                    />
                </Grid>
            )}
            {granularity === 'flexible' && (
                <Grid item xs={hasCompareTimePeriod ? 6 : 12}>
                    <DateRangePicker
                        startDate={timePeriodStart}
                        endDate={timePeriodEnd}
                        disabled={loadingInBackground}
                        onChange={(startDate, endDate) => {
                            handleTimePeriodChange(startDate, endDate)
                        }}
                        variant={'outlined'}
                    />
                </Grid>
            )}
            {granularity !== 'flexible' && (
                <Grid item xs={hasCompareTimePeriod ? 6 : 12}>
                    <Datepicker
                        disabled={loadingInBackground}
                        label="facebook.month"
                        format={'MMMM YYYY'}
                        openTo={getOpenTo()}
                        views={getDateGranularity()}
                        value={moment(monthlyReportStartDate)}
                        onChange={handleMonthIntervalChange}
                        variant={'outlined'}
                    />
                </Grid>
            )}
            {granularity === 'year' && (
                <Grid item xs={isMobile && hasCompareTimePeriod ? 6 : isMobile ? 12 : undefined}>
                    <Datepicker
                        disabled={loadingInBackground}
                        label="facebook.year"
                        format={'YYYY'}
                        openTo={getOpenTo()}
                        views={getDateGranularity()}
                        value={moment(monthlyReportStartDate)}
                        onChange={handleYearIntervalChange}
                        variant={'outlined'}
                    />
                </Grid>
            )}

            {hasCompareTimePeriod && granularity !== 'flexible' && (
                <Grid item xs={6}>
                    <Datepicker
                        disabled={loadingInBackground}
                        label="facebook.compare-month"
                        format={'MMMM YYYY'}
                        openTo={'month'}
                        views={['year', 'month']}
                        value={moment(monthlyReportCompareStartDate)}
                        onChange={(date: Moment | null) => (date ? dispatch(changeCompareTimePeriod(getMonthIntervalForDate(date))) : {})}
                        variant={'outlined'}
                    />
                </Grid>
            )}

            {hasCompareTimePeriod && granularity === 'flexible' && (
                <Grid item xs={6}>
                    <DateRangePicker
                        disabled={loadingInBackground}
                        startDate={compareTimePeriodStart || defaultMonthlyPeriod.compareStartDate}
                        endDate={compareTimePeriodEnd || defaultMonthlyPeriod.compareEndDate}
                        onChange={(startDate, endDate) => {
                            handleCompareTimePeriodChange(startDate, endDate)
                        }}
                        label="filters.compare-timeperiod"
                        variant={'outlined'}
                    />
                </Grid>
            )}
        </Grid>
    )
}
