import * as _ from 'lodash'
import { uniqueId } from 'lodash'
import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { User, UserDatasourceAccess, UserProjectAccess } from '../../settings/user-management/User'
import {
    fetchCurrentUser,
    fetchCurrentUserPermissions,
    fetchPermissionsForProject,
    finishAllTours,
    finishTour,
    resetMultipleTours,
    resetSingleTour,
    resetTours,
    updateUserSettings,
} from '../../settings/user-management/UserActions'
import { HmstrState } from '../Store'
import { getAccounts, getProjects, getSimpleUsers, IdMap } from './DataSlice'
import { ProjectDatasource } from '../../settings/datasources/ProjectDatasource'
import { ApiError } from '../api/ApiClient'
import { fetchInvoices, fetchPaymentMethods, fetchSubscriptionsForUser } from '../../settings/billing/BillingActions'
import { replaceProjectId } from '../helpers/replace-project-id'
import { Project } from '../../settings/project-management/Project'
import { Account } from '../../settings/accounts/Account'
import { getDatasourceErrors } from '../../settings/datasources/DatasourceError'
import { SimpleUser } from '../../settings/user-management/SimpleUser'
import { isMobileDevice } from '../theme/helper'
import { VariantType } from 'notistack'

export interface SnackBar {
    id: string
    snackbarMessage: string
    snackbarStatus: VariantType
    snackbarLink?: {
        href: string
        text: string
    }
    persistant?: boolean
    autoHideDuration?: number
}

export interface CoreState {
    drawerState: 'expanded' | 'collapsed'
    toolbarExpanded: boolean
    selectedProjectId?: string
    currentUser: User
    currentUserPermissions: {
        user_permissions: string[]
        account_permissions: IdMap<string[]>
        project_permissions: IdMap<string[]>
    }
    selectedProjectPermissions?: IdMap<string[]>
    showInfoDialog: boolean
    billingDataLoading: boolean
    darkMode: boolean
    activeSnackbars: SnackBar[]
    apiError?: ApiError
    impairedVision: boolean
    datasourceTypeToAdd?: ProjectDatasource['type']
    lastModulePages: {
        publishing: string
        analytics: string
        engagement: string
        settings: string
    }
    fullscreenDialog?: 'new-account' | 'new-project' | 'invite-user' | 'edit-user' | 'edit-invite' | 'extend-account' | 'new-dashboard' | 'edit-dashboard'
    isMobile: boolean
    mobileUserMenuExpanded: boolean
    appThemeColor: string
    launchedAppBefore: boolean
    prefersBrowser: boolean
}

export const initialCoreState: Omit<CoreState, 'currentUser' | 'currentUserPermissions' | 'selectedAccountId' | 'selectedProjectId'> = {
    drawerState: 'expanded',
    toolbarExpanded: !isMobileDevice(),
    showInfoDialog: false,
    billingDataLoading: true,
    darkMode: localStorage.getItem('hmstr.darkMode') === 'true',
    activeSnackbars: [],
    impairedVision: localStorage.getItem('hmstr.impairedVision') === 'true',
    lastModulePages: {
        publishing: '/calendar',
        analytics: '/dashboards',
        engagement: '/tickets/new',
        settings: '/data-sources',
    },
    mobileUserMenuExpanded: false,
    appThemeColor: '#000000',
    launchedAppBefore: localStorage.getItem('hmstr.launchedAppBefore') === 'true',
    prefersBrowser: localStorage.getItem('hmstr.prefersBrowser') === 'true',
    isMobile: isMobileDevice(),
}

export const CoreSlice = createSlice({
    name: 'core',
    initialState: initialCoreState as CoreState,
    reducers: {
        toggleDrawerState: (state) => {
            state.drawerState = state.drawerState === 'expanded' ? 'collapsed' : 'expanded'
        },
        toggleMobileUserMenuExpanded: (state) => {
            state.mobileUserMenuExpanded = !state.mobileUserMenuExpanded
        },
        setAppThemeColor: (state, action: PayloadAction<string>) => {
            state.appThemeColor = action.payload
        },
        selectProject: (state, action: PayloadAction<string>) => {
            state.selectedProjectId = action.payload
            localStorage.setItem('hmstr.selectedProjectId', action.payload)

            replaceProjectId(action.payload)
        },
        setCurrentUser: (state, action: PayloadAction<User>) => {
            state.currentUser = action.payload
        },
        showInfoDialog: (state) => {
            state.showInfoDialog = true
        },
        closeInfoDialog: (state) => {
            state.showInfoDialog = false
        },
        toggleDarkMode: (state) => {
            localStorage.setItem('hmstr.darkMode', !state.darkMode ? 'true' : 'false')
            state.darkMode = !state.darkMode
        },
        dismissSnackbar: (state, action: PayloadAction<string>) => {
            state.activeSnackbars = state.activeSnackbars.filter((bar) => bar.id !== action.payload).filter((d) => d)
        },
        showSuccessSnackbar: (state, action: PayloadAction<string>) => {
            const newSnackbar: SnackBar = {
                id: uniqueId('snackbar_'),
                snackbarLink: undefined,
                snackbarMessage: action.payload,
                snackbarStatus: 'success',
            }
            state.activeSnackbars = [...state.activeSnackbars, newSnackbar]
        },
        showInfoSnackbar: (state, action: PayloadAction<string>) => {
            const newSnackbar: SnackBar = {
                id: uniqueId('snackbar_'),
                snackbarLink: undefined,
                snackbarMessage: action.payload,
                snackbarStatus: 'info',
            }
            state.activeSnackbars = [...state.activeSnackbars, newSnackbar]
        },
        showSnackbarWithLink: (state, action: PayloadAction<{ id: string; link?: SnackBar['snackbarLink'] }>) => {
            const newSnackbar: SnackBar = {
                id: uniqueId('snackbar_'),
                snackbarLink: action.payload.link,
                snackbarMessage: action.payload.id,
                snackbarStatus: 'success',
                persistant: true,
            }
            state.activeSnackbars = [...state.activeSnackbars, newSnackbar]
        },
        showErrorSnackbarWithLink: (state, action: PayloadAction<{ id: string; link?: SnackBar['snackbarLink'] }>) => {
            const newSnackbar: SnackBar = {
                id: uniqueId('snackbar_'),
                snackbarLink: action.payload.link,
                snackbarMessage: action.payload.id,
                snackbarStatus: 'error',
                persistant: true,
            }
            state.activeSnackbars = [...state.activeSnackbars, newSnackbar]
        },
        showErrorSnackbar: (state, action: PayloadAction<string>) => {
            const newSnackbar: SnackBar = {
                id: uniqueId('snackbar_'),
                snackbarMessage: action.payload,
                snackbarStatus: 'error',
            }
            state.activeSnackbars = [...state.activeSnackbars, newSnackbar]
        },
        showWarningSnackbar: (state, action: PayloadAction<string>) => {
            const newSnackbar: SnackBar = {
                id: uniqueId('snackbar_'),
                snackbarMessage: action.payload,
                snackbarStatus: 'warning',
            }
            state.activeSnackbars = [...state.activeSnackbars, newSnackbar]
        },
        showCoreError: (state, action: PayloadAction<ApiError>) => {
            state.apiError = action.payload
        },
        dismissApiError: (state) => {
            state.apiError = undefined
        },
        toggleToolbar: (state) => {
            state.toolbarExpanded = !state.toolbarExpanded
        },
        setVisionImpaired: (state, action: PayloadAction<boolean>) => {
            localStorage.setItem('hmstr.impairedVision', action.payload ? 'true' : 'false')
            state.impairedVision = action.payload
        },
        setLaunchedApp: (state, action: PayloadAction<boolean>) => {
            localStorage.setItem('hmstr.launchedAppBefore', action.payload ? 'true' : 'false')
            state.launchedAppBefore = action.payload
        },
        setPrefersBrowser: (state, action: PayloadAction<boolean>) => {
            localStorage.setItem('hmstr.prefersBrowser', action.payload ? 'true' : 'false')
            state.prefersBrowser = action.payload
        },
        selectDatasourceTypeToAdd: (state, action: PayloadAction<ProjectDatasource['type'] | undefined>) => {
            state.datasourceTypeToAdd = action.payload
        },
        setLastPageForModule: (state, action: PayloadAction<{ module: 'publishing' | 'analytics' | 'engagement' | 'settings'; page: string }>) => {
            if (!action.payload.page.includes('calendar/new')) {
                state.lastModulePages[action.payload.module] = '/' + action.payload.page
            }
        },
        openFullscreenDialog: (state, action: PayloadAction<CoreState['fullscreenDialog']>) => {
            state.fullscreenDialog = action.payload
        },
        closeFullscreenDialog: (state) => {
            state.fullscreenDialog = undefined
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentUser.fulfilled, (state, action) => {
                state.currentUser = action.payload
            })
            .addCase(fetchPaymentMethods.pending, (state) => {
                state.billingDataLoading = true
            })
            .addCase(fetchSubscriptionsForUser.pending, (state) => {
                state.billingDataLoading = true
            })
            .addCase(fetchInvoices.pending, (state) => {
                state.billingDataLoading = true
            })
            .addCase(fetchPaymentMethods.fulfilled, (state) => {
                state.billingDataLoading = false
            })
            .addCase(fetchInvoices.fulfilled, (state) => {
                state.billingDataLoading = false
            })
            .addCase(fetchSubscriptionsForUser.fulfilled, (state) => {
                state.billingDataLoading = false
            })
            .addCase(updateUserSettings.fulfilled, (state, action) => {
                state.currentUser!.settings = action.payload.settings
            })
            .addCase(finishTour.fulfilled, (state, action) => {
                state.currentUser = action.payload
            })
            .addCase(finishAllTours.fulfilled, (state, action) => {
                state.currentUser = action.payload
            })
            .addCase(resetSingleTour.fulfilled, (state, action) => {
                state.currentUser = action.payload
            })
            .addCase(resetTours.fulfilled, (state, action) => {
                state.currentUser = action.payload
            })
            .addCase(resetMultipleTours.fulfilled, (state, action) => {
                state.currentUser = action.payload
            })
            .addCase(fetchPermissionsForProject.fulfilled, (state, action) => {
                state.selectedProjectPermissions = action.payload
            })
            .addCase(fetchCurrentUserPermissions.fulfilled, (state, action) => {
                state.currentUserPermissions = action.payload
            })
    },
})

// This is checked in the very first component
export const getCurrentUser: Selector<HmstrState, User> = (state) => state.core.currentUser as User
export const getIsMobile: Selector<HmstrState, boolean> = (state) => state.core.isMobile
export const getLaunchedAppBefore: Selector<HmstrState, boolean> = (state) => state.core.launchedAppBefore
export const getPrefersBrowser: Selector<HmstrState, boolean> = (state) => state.core.prefersBrowser
export const getMobileUserMenuExpanded: Selector<HmstrState, boolean> = (state) => state.core.mobileUserMenuExpanded
export const getAppThemeColor: Selector<HmstrState, string> = (state) => state.core.appThemeColor
export const getSelectedProjectId: Selector<HmstrState, string | undefined> = (state) => state.core.selectedProjectId
export const getShowInfoDialog: Selector<HmstrState, boolean> = (state) => state.core.showInfoDialog
export const getBillingDataLoading: Selector<HmstrState, boolean> = (state) => state.core.billingDataLoading
export const isDarkMode: Selector<HmstrState, boolean> = (state) => state.core.darkMode
export const getActiveSnackBars: Selector<HmstrState, CoreState['activeSnackbars']> = (state) => state.core.activeSnackbars
export const getApiError: Selector<HmstrState, ApiError | undefined> = (state) => state.core.apiError
export const getToolbarExpanded: Selector<HmstrState, boolean> = (state) => state.core.toolbarExpanded
export const getDatasourceTypeToAdd: Selector<HmstrState, CoreState['datasourceTypeToAdd']> = (state) => state.core.datasourceTypeToAdd
export const getImpairedVision: Selector<HmstrState, boolean> = (state) => state.core.impairedVision
export const getLastModulePages: Selector<HmstrState, CoreState['lastModulePages']> = (state) => state.core.lastModulePages
export const getFullscreenDialog: Selector<HmstrState, CoreState['fullscreenDialog']> = (state) => state.core.fullscreenDialog
export const getCurrentUserPermissions: Selector<HmstrState, CoreState['currentUserPermissions']> = (state) => state.core.currentUserPermissions
export const getSelectedProjectPermissions: Selector<HmstrState, CoreState['selectedProjectPermissions']> = (state) => state.core.selectedProjectPermissions

export const getSelectedProject = createSelector(
    [getSelectedProjectId, getProjects],
    (selectedProjectId, projects) => projects[selectedProjectId || ''] as Project | undefined
)

export const getInvalidDatasourceCountForProject = createSelector(
    [getSelectedProject],
    (selectedProject) => (selectedProject?.data_sources || []).filter((ds) => getDatasourceErrors(ds).length > 0).length
)

export const getDatasources = createSelector([getSelectedProject], (selectedProject): ProjectDatasource[] =>
    selectedProject ? selectedProject.data_sources : []
)

export const getDatasourcesAsIdMap = createSelector([getDatasources], (datasources): IdMap<ProjectDatasource> => _.mapKeys(datasources, 'id'))

export const getSelectedAccountId = createSelector([getSelectedProject, getAccounts], (selectedProject) => selectedProject?.account_id)

export const getSelectedAccount = createSelector([getSelectedAccountId, getAccounts], (selectedAccountId, accounts) => accounts[selectedAccountId || ''])

export const getOwnedAccounts = createSelector([getAccounts, getCurrentUser], (accounts, currentUser): Account[] => {
    return _.values(accounts).filter((acc) => {
        const access = currentUser.accounts.find((access) => access.account_id)
        return access && access.role === 'OWNER'
    })
})

export const getSelectedAccountAccess = createSelector([getSelectedAccountId, getCurrentUser], (selectedAccountId, currentUser) =>
    selectedAccountId ? currentUser?.accounts.find((a) => a.account_id === selectedAccountId) : undefined
)

export const isAccountAdminOrOwner = createSelector([getSelectedAccountAccess], (selectedAccountAccess) => {
    return selectedAccountAccess?.role === 'ADMIN' || selectedAccountAccess?.role === 'OWNER'
})

export const getSelectedProjectAccess = createSelector([getSelectedProjectId, getSelectedAccountAccess], (selectedProjectId, accountAccess) =>
    selectedProjectId ? (accountAccess?.projects || []).find((p) => p.project_id === selectedProjectId) : undefined
)

export const isExternalForProject = createSelector([getSelectedProjectAccess], (selectedProjectAccess) =>
    selectedProjectAccess ? selectedProjectAccess.external : true
)

export const isProjectAdmin = createSelector([getSelectedProjectAccess], (selectedProjectAccess) => {
    return selectedProjectAccess?.role === 'ADMIN' || !selectedProjectAccess
})

export const getAllowedModulesForSelectedProject = createSelector(
    [getSelectedProjectAccess, isAccountAdminOrOwner, isProjectAdmin],
    (projectAccess, accountAdmin, projectAdmin): ('publishing' | 'analytics' | 'engagement')[] => {
        if (accountAdmin || projectAdmin) {
            return ['publishing', 'analytics', 'engagement']
        }

        if (!projectAccess) {
            return []
        }

        const modules = [] as ('publishing' | 'analytics' | 'engagement')[]

        projectAccess.access.forEach((dsa) => {
            if (dsa.publishing_role) {
                modules.push('publishing')
            }

            if (dsa.analytics_role) {
                modules.push('analytics')
            }

            if (dsa.engagement_role) {
                modules.push('engagement')
            }
        })

        return _.uniq(modules)
    }
)

export const getProjectsForSelectedAccount = createSelector([getSelectedAccount, getProjects], (selectedAccount, projects) => {
    const projectsGroupedByAccountId = _.groupBy(projects, 'account_id')
    return projectsGroupedByAccountId[selectedAccount.id] || []
})

export const getSimpleUsersForSelectedProject = createSelector([getSelectedProject, getSimpleUsers], (selectedProject, simpleUsers): IdMap<SimpleUser> => {
    if (!selectedProject) {
        return {}
    }

    return simpleUsers[selectedProject.id] || {}
})

export const getSimpleUsersAsSuggestion = createSelector([getSimpleUsersForSelectedProject], (simpleUsers) =>
    _.map(simpleUsers, (user) => ({ id: user.id, display: `${user.first_name} ${user.last_name}` }))
)

const getPagesWithAccess = (
    selectedProject: Project | undefined,
    projectAccess: UserProjectAccess | undefined,
    roleKey: keyof UserDatasourceAccess,
    includeAdAccounts: boolean = false
) => {
    if (!selectedProject || !projectAccess) {
        return []
    }

    const channelsById = _.mapKeys(projectAccess.access, 'data_source_id')
    const channelTypes = ['FACEBOOK_PAGE', 'INSTAGRAM_ACCOUNT', 'LINKED_IN', 'TIKTOK_ACCOUNT']

    // Add channel types which are only available for publishing module
    if (roleKey === 'publishing_role') {
        channelTypes.push('LINKED_IN_PROFILE')
    }

    if (includeAdAccounts) {
        channelTypes.push('FACEBOOK_AD_ACCOUNT')
    }

    if (projectAccess.role === 'ADMIN') {
        return selectedProject.data_sources.filter((c) => channelTypes.includes(c.type))
    }

    return selectedProject.data_sources.filter((c) => channelTypes.includes(c.type) && channelsById[c.id] && Boolean(channelsById[c.id][roleKey]))
}

export const getPagesForPublish = createSelector([getSelectedProject, getSelectedProjectAccess], (selectedProject, projectAccess): ProjectDatasource[] =>
    getPagesWithAccess(selectedProject, projectAccess, 'publishing_role')
)

export const getPagesForEngage = createSelector([getSelectedProject, getSelectedProjectAccess], (selectedProject, projectAccess): ProjectDatasource[] =>
    getPagesWithAccess(selectedProject, projectAccess, 'engagement_role')
)

export const getPagesForAnalyse = createSelector([getSelectedProject, getSelectedProjectAccess], (selectedProject, projectAccess): ProjectDatasource[] =>
    getPagesWithAccess(selectedProject, projectAccess, 'analytics_role')
)

export const {
    toggleDrawerState,
    selectProject,
    showInfoDialog,
    closeInfoDialog,
    toggleDarkMode,
    showSuccessSnackbar,
    showInfoSnackbar,
    showSnackbarWithLink,
    showErrorSnackbarWithLink,
    showErrorSnackbar,
    showWarningSnackbar,
    showCoreError,
    dismissApiError,
    toggleToolbar,
    dismissSnackbar,
    setVisionImpaired,
    selectDatasourceTypeToAdd,
    setLastPageForModule,
    openFullscreenDialog,
    closeFullscreenDialog,
    toggleMobileUserMenuExpanded,
    setAppThemeColor,
    setLaunchedApp,
    setPrefersBrowser,
} = CoreSlice.actions

export const CoreReducer = CoreSlice.reducer
