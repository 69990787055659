import * as _ from 'lodash'
import { Post } from './posts/Post'

export interface TiktokCreatorInfo {
    privacy_level_options: Post['tt_privacy_level'][]
    comment_disabled: boolean
    duet_disabled: boolean
    stitch_disabled: boolean
    max_video_post_duration_sec: number
}

export const mergeTiktokCreatorInfos = (tiktokCreatorInfos: TiktokCreatorInfo[]): TiktokCreatorInfo => {
    const mergedCreatorInfo: TiktokCreatorInfo = {
        privacy_level_options: ['PUBLIC_TO_EVERYONE', 'MUTUAL_FOLLOW_FRIENDS', 'FOLLOWER_OF_CREATOR', 'SELF_ONLY'],
        comment_disabled: false,
        duet_disabled: false,
        stitch_disabled: false,
        max_video_post_duration_sec: 0,
    }

    tiktokCreatorInfos.forEach((tiktokCreatorInfo) => {
        mergedCreatorInfo.privacy_level_options = _.intersection(mergedCreatorInfo.privacy_level_options, tiktokCreatorInfo.privacy_level_options)

        if (
            mergedCreatorInfo.max_video_post_duration_sec === 0 ||
            tiktokCreatorInfo.max_video_post_duration_sec < mergedCreatorInfo.max_video_post_duration_sec
        ) {
            mergedCreatorInfo.max_video_post_duration_sec = tiktokCreatorInfo.max_video_post_duration_sec
        }

        if (tiktokCreatorInfo.comment_disabled) {
            mergedCreatorInfo.comment_disabled = tiktokCreatorInfo.comment_disabled
        }

        if (tiktokCreatorInfo.duet_disabled) {
            mergedCreatorInfo.duet_disabled = tiktokCreatorInfo.duet_disabled
        }

        if (tiktokCreatorInfo.stitch_disabled) {
            mergedCreatorInfo.stitch_disabled = tiktokCreatorInfo.stitch_disabled
        }
    })

    return mergedCreatorInfo
}
