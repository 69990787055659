import React, { FC, PropsWithChildren } from 'react'
import { Box, List, ListItemButton, Paper, Skeleton, useTheme } from '@mui/material'

type CustomSuggestionsContainerProps = {
    loading?: boolean
    setSnap: (snap: boolean) => any
}

export const CustomSuggestionsContainer: FC<PropsWithChildren<CustomSuggestionsContainerProps>> = ({ loading, setSnap, children }) => {
    const theme = useTheme()

    return (
        <Paper elevation={3}>
            {children && (
                <List
                    sx={{
                        background: theme.palette.background.paper,
                        maxHeight: '300px',
                        minWidth: '200px',
                        overflow: 'scroll',
                        scrollSnapType: 'y mandatory',
                    }}
                    onMouseEnter={() => {
                        setSnap(false)
                    }}
                    onMouseLeave={() => {
                        setSnap(true)
                    }}
                >
                    {loading && (
                        <ListItemButton
                            style={{
                                whiteSpace: 'normal',
                                display: 'flex',
                                gap: 10,
                            }}
                        >
                            <Skeleton variant={'circular'} width={30} height={30} />
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                <Box display="flex" gap={2}>
                                    <Skeleton width={100} sx={{ display: 'inline-flex' }} />
                                    <Skeleton width={100} sx={{ display: 'inline-flex' }} />
                                </Box>
                                <Skeleton width={100} />
                            </Box>
                        </ListItemButton>
                    )}
                    {children}
                </List>
            )}
        </Paper>
    )
}
