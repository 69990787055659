import { FC } from 'react'
import { Box, Container, FormControlLabel, Grid, Switch, Tooltip, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Info } from '@mui/icons-material'
import { getImpairedVision, isDarkMode, setVisionImpaired, toggleDarkMode } from '../core/slices/CoreSlice'
import { useDispatch, useSelector } from 'react-redux'
import { HmstrDispatch } from '../core/Store'
import { ResetGuidesDialog } from '../common/guides/ResetGuidesDialog'

type DisplaySettingsProps = {}

export const DisplaySettings: FC<DisplaySettingsProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const visionImpaired = useSelector(getImpairedVision)
    const darkModeEnabled = useSelector(isDarkMode)

    return (
        <Container maxWidth="lg">
            <Box marginTop={2}>
                <Box marginBottom={4}>
                    <Typography variant="h5">
                        <FormattedMessage id="settings.interface.title" />
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        id={'darkmode_switch'}
                        control={<Switch color="secondary" checked={darkModeEnabled} onClick={() => dispatch(toggleDarkMode())} />}
                        label={
                            <Box alignItems="center" display="flex">
                                <Box marginRight={1}>
                                    <FormattedMessage id="settings.dark-mode" />
                                </Box>
                                <Tooltip title={<FormattedMessage id="settings.dark-mode.tooltip" />}>
                                    <Info fontSize="small" />
                                </Tooltip>
                            </Box>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Switch color="secondary" checked={visionImpaired} onClick={() => dispatch(setVisionImpaired(!visionImpaired))} />}
                        label={
                            <Box alignItems="center" display="flex">
                                <Box marginRight={1}>
                                    <FormattedMessage id="settings.achromatopsy" />
                                </Box>
                                <Tooltip title={<FormattedMessage id="settings.achromatopsy.tooltip" />}>
                                    <Info fontSize="small" />
                                </Tooltip>
                            </Box>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <ResetGuidesDialog />
                </Grid>
            </Grid>
        </Container>
    )
}
