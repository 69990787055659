import * as React from 'react'
import { FC, MouseEvent, useState } from 'react'
import { Box, Button, ButtonGroup, Chip, Menu, MenuItem, Paper, SelectChangeEvent, Tooltip, Typography, useTheme } from '@mui/material'
import { ContentPanel } from '../common/content-panel/ContentPanel'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedProject } from '../core/slices/CoreSlice'
import { Loading } from '../common/loading/Loading'
import {
    changeResultsSearchTerm,
    changeResultsSortedBy,
    changeSelectedDatasourceIds,
    changeSelectedMediaTypes,
    changeSelectedTags,
    changeTimePeriod,
    getAvailableMediaTypes,
    getChartSchema,
    getIsLoadingResults,
    getResultDatasources,
    getResultsChartData,
    getResultsSearchTerm,
    getResultsSortDirection,
    getResultsSortedBy,
    getSelectedDatasourceIds,
    getSelectedMediaTypes,
    getSelectedTags,
    getShowGraph,
    getSortedFilteredResultsForProject,
    toggleGraph,
    toggleResultsSortDirection,
} from './ResultsSlice'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Fallback } from '../common/fallback/Fallback'
import { ResultEntry } from './ResultEntry'
import { ResultsChart } from './ResultsChart'
import { CalendarToday, ExpandMore, FilterList, Language, Sell, Visibility, VisibilityOff, Web } from '@mui/icons-material'
import { ResultMediatypeFilter } from './ResultMediatypeFilter'
import { ResultDatasourceFilter } from './ResultDatasourceFilter'
import { FormattedMessage, useIntl } from 'react-intl'
import { Result } from './Result'
import { ResultTagFilter } from './ResultTagFilter'
import { fetchResultsForProject } from './ResultsActions'
import { useEffectWithIdComparison } from '../core/hooks/useEffectWithIdComparison'
import { ResultsListBox } from './Results.styles'
import { DateRangePicker } from '../common/daterange-picker/DateRangePicker'
import { HmstrDispatch } from '../core/Store'
import moment from 'moment/moment'
import { TitlebarWithFilters } from '../common/filterbar/TitlebarWithFilters'
import { FilterDrawer, FilterSetting } from '../common/filterbar/FilterDrawer'
import {
    getDatasourceSelectionOpen,
    getMediaTypeSelectOpen,
    getTagSelectionOpen,
    getTimePeriodSettingOpen,
    setDatasourceSelectionOpen,
    setMediaTypeSelectOpen,
    setTagSelectionOpen,
    setTimePeriodSettingOpen,
} from '../common/filterbar/FilterSlice'
import _ from 'lodash'
import { SearchChip } from '../common/filterbar/SearchChip'
import { getPersistedDatasourceTags } from '../settings/SettingsSlice'
import { renderTimePeriodString } from '../resources/translations/Helper'

type ResultsProps = {}

export const Results: FC<ResultsProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const theme = useTheme()
    const intl = useIntl()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const searchTerm = useSelector(getResultsSearchTerm)
    const sortedBy = useSelector(getResultsSortedBy)
    const sortDirection = useSelector(getResultsSortDirection)
    const schema = useSelector(getChartSchema)
    const [startDate, setStartDate] = useState<string>(moment().subtract(30, 'days').toISOString())
    const [endDate, setEndDate] = useState<string>(moment().toISOString())
    const showGraph = useSelector(getShowGraph)
    const selectedProject = useSelector(getSelectedProject)
    const results = useSelector(getSortedFilteredResultsForProject)
    const filteredResults = useSelector(getResultsChartData)
    const isLoadingResults = useSelector(getIsLoadingResults)
    const selectedDatasourceIds = useSelector(getSelectedDatasourceIds)
    const datasources = useSelector(getResultDatasources)
    const availableTags = useSelector(getPersistedDatasourceTags)
    const selectedTags = useSelector(getSelectedTags)
    const availableMediaTypes = useSelector(getAvailableMediaTypes)
    const selectedMediaTypes = useSelector(getSelectedMediaTypes)

    const datasourceFilterOpen = useSelector(getDatasourceSelectionOpen)
    const handleDatasourceFilterOpen = () => dispatch(setDatasourceSelectionOpen(true))
    const handleDatasourceFilterClose = () => dispatch(setDatasourceSelectionOpen(false))

    const mediaTypeSelectOpen = useSelector(getMediaTypeSelectOpen)
    const handleMediaTypeSelectOpen = () => dispatch(setMediaTypeSelectOpen(true))
    const handleMediaTypeSelectClose = () => dispatch(setMediaTypeSelectOpen(false))

    const tagSelectionOpen = useSelector(getTagSelectionOpen)
    const handleTagSelectionOpen = () => dispatch(setTagSelectionOpen(true))
    const handleTagSelectionClose = () => dispatch(setTagSelectionOpen(false))

    const timeperiodOpen = useSelector(getTimePeriodSettingOpen)
    const handleTimePeriodOpen = () => dispatch(setTimePeriodSettingOpen(true))
    const handleTimePeriodClose = () => dispatch(setTimePeriodSettingOpen(false))

    const showLoading = results.length === 0 && isLoadingResults

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchResultsForProject(selectedProject))
        }
    }, [selectedProject, startDate, endDate])

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClick = (selectedItem: keyof Result) => {
        setAnchorEl(null)
        dispatch(changeResultsSortedBy(selectedItem))
    }

    if (!selectedProject) {
        return <Loading />
    }

    const getDatasourceFilter = () => {
        const defaultValue: string[] = []
        const isEqual = _.isEqual(_.sortBy(selectedDatasourceIds), _.sortBy(defaultValue))

        const renderValue = () => {
            return selectedDatasourceIds.length === 1
                ? datasources.find((project) => project.id === selectedDatasourceIds[0])?.name
                : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selectedDatasourceIds.length })
        }
        const onChipClick = () => {
            setTimeout(handleDatasourceFilterOpen, 300)
        }
        const onReset = () => {
            dispatch(changeSelectedDatasourceIds([]))
        }

        const set: FilterSetting = {
            settingInput: (
                <ResultDatasourceFilter
                    fullWidth
                    open={datasourceFilterOpen}
                    onOpen={handleDatasourceFilterOpen}
                    onClose={handleDatasourceFilterClose}
                    onChange={(val: string[]) => dispatch(changeSelectedDatasourceIds(val))}
                    value={selectedDatasourceIds}
                />
            ),
            tooltip: 'filters.datasource',
            important: false,
            currentValueAsString: renderValue(),
            hasChangedFromDefault: !isEqual,
            deletable: true,
            icon: <Web fontSize={'small'} />,
            onDeleteFunction: onReset,
            onClickFunction: onChipClick,
        }
        return set
    }

    const getTagFilterSetting = () => {
        const defaultValue: string[] = []
        const isEqual = _.isEqual(_.sortBy(selectedTags), _.sortBy(defaultValue))

        const renderValue = (tagsToDisplay: string[]) => {
            if (tagsToDisplay.length > 2) {
                return intl.formatMessage({ id: 'filters.xTagsSelected' }, { x: tagsToDisplay.length })
            }

            if (tagsToDisplay.length === 0) {
                return intl.formatMessage({ id: 'post.analysis.no-tags-selected' })
            }
            return tagsToDisplay
                .map((option, index) => {
                    if (!!option) {
                        return index === tagsToDisplay.length - 1 ? option : option + ', '
                    }

                    return ''
                })
                .toString()
        }
        const onChipClick = () => {
            setTimeout(handleTagSelectionOpen, 300)
        }
        const onReset = () => {
            dispatch(changeSelectedTags([]))
        }

        const set: FilterSetting = {
            settingInput: (
                <ResultTagFilter
                    fullWidth
                    open={tagSelectionOpen}
                    onOpen={handleTagSelectionOpen}
                    onClose={handleTagSelectionClose}
                    onChange={(value: string[]) => dispatch(changeSelectedTags(value))}
                    value={selectedTags}
                />
            ),
            tooltip: 'filters.tags',
            important: false,
            currentValueAsString: renderValue(selectedTags),
            hasChangedFromDefault: !isEqual,
            icon: <Sell fontSize={'small'} />,
            deletable: true,
            onDeleteFunction: onReset,
            onClickFunction: onChipClick,
        }
        return set
    }

    const getResultMediaTypeFilter = () => {
        const defaultValue: string[] = []
        const isEqual = _.isEqual(_.sortBy(selectedMediaTypes), _.sortBy(defaultValue))

        const handleChange = (event: SelectChangeEvent<any>, newValue: any) => {
            const currentValues = event.target.value.filter((e: any) => e !== undefined)
            const value = newValue.key.substr(2)
            const isSubHeader = value === 'OTHER' ? false : !value.includes(',')

            if (isSubHeader) {
                const newValues = [...currentValues]
                const subGroup = availableMediaTypes[value]
                const allSelected = _.intersection(selectedMediaTypes, subGroup).length === subGroup.length

                dispatch(changeSelectedMediaTypes(_.uniq(allSelected ? _.pullAll(newValues, subGroup) : [...newValues, ...subGroup])))
            } else {
                dispatch(changeSelectedMediaTypes(currentValues))
            }
        }

        const renderValue = () => {
            if (selectedMediaTypes.length === 0) {
                return intl.formatMessage({ id: 'filters.all-mediatypes' })
            }

            return selectedMediaTypes.map((mt) => intl.formatMessage({ id: mt })).join(', ')
        }
        const onChipClick = () => {
            setTimeout(handleMediaTypeSelectOpen, 300)
        }
        const onReset = () => {
            dispatch(changeSelectedMediaTypes([]))
        }

        const set: FilterSetting = {
            settingInput: (
                <ResultMediatypeFilter
                    open={mediaTypeSelectOpen}
                    onOpen={handleMediaTypeSelectOpen}
                    onClose={handleMediaTypeSelectClose}
                    onChange={handleChange}
                    fullWidth
                    value={selectedMediaTypes}
                />
            ),
            tooltip: 'filters.mediatypes',
            important: false,
            currentValueAsString: renderValue(),
            hasChangedFromDefault: !isEqual,
            icon: <Language fontSize={'small'} />,
            deletable: true,
            onDeleteFunction: onReset,
            onClickFunction: onChipClick,
        }
        return set
    }

    const getSearchSetting = () => {
        const hasChangedFromDefault = searchTerm.length > 0
        const onDeleteFunction = () => {
            dispatch(changeResultsSearchTerm(''))
        }

        const set: FilterSetting = {
            chipElement: <SearchChip onChange={(value) => dispatch(changeResultsSearchTerm(value))} value={searchTerm} searchbarTitle={'general.search'} />,
            onlyChip: true,
            tooltip: 'tags.search',
            hasChangedFromDefault,
            onDeleteFunction,
            deletable: true,
            important: true,
        }
        return set
    }

    const getTimePeriodSetting = () => {
        const defaultStartDate = moment().subtract(30, 'day').format('YYYY-MM-DD')
        const defaultEndDate = moment().format('YYYY-MM-DD')
        const hasChangedFromDefault = !(
            moment(defaultStartDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') &&
            moment(defaultEndDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')
        )
        const onChipClick = () => {
            setTimeout(handleTimePeriodOpen, 300)
        }

        const getTimePeriodResetFunction = () => {
            return () => {
                setStartDate(defaultStartDate)
                setEndDate(defaultEndDate)
                dispatch(changeTimePeriod({ startDate: defaultStartDate, endDate: defaultEndDate }))
            }
        }

        const set: FilterSetting = {
            settingInput: (
                <DateRangePicker
                    open={timeperiodOpen}
                    onOpen={handleTimePeriodOpen}
                    onClose={handleTimePeriodClose}
                    variant={'outlined'}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(startDate, endDate) => {
                        setStartDate(startDate)
                        setEndDate(endDate)
                        dispatch(changeTimePeriod({ startDate, endDate }))
                    }}
                />
            ),
            tooltip: 'filters.timeperiod',
            important: true,
            currentValueAsString: renderTimePeriodString(startDate, endDate, intl),
            icon: <CalendarToday fontSize={'small'} />,
            hasChangedFromDefault: hasChangedFromDefault,
            onDeleteFunction: getTimePeriodResetFunction(),
            onClickFunction: onChipClick,
            deletable: true,
        }
        return set
    }

    const getHideGraphChip = () => {
        const hasChangedFromDefault = searchTerm.length > 0
        const onDeleteFunction = () => {
            dispatch(changeResultsSearchTerm(''))
        }

        const onClickChip = () => {
            dispatch(toggleGraph())
        }

        const set: FilterSetting = {
            chipElement: (
                <Tooltip
                    title={showGraph ? <FormattedMessage id={'results.hide-graph'} /> : <FormattedMessage id={'results.show-graph'} />}
                    disableInteractive
                    placement={'left'}
                >
                    <Chip
                        onClick={onClickChip}
                        label={showGraph ? <Visibility fontSize={'small'} /> : <VisibilityOff fontSize={'small'} />}
                        sx={{
                            '& .MuiChip-label': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                        }}
                    />
                </Tooltip>
            ),
            onlyChip: true,
            tooltip: 'tags.search',
            hasChangedFromDefault,
            onDeleteFunction,
            deletable: true,
            important: true,
        }
        return set
    }

    const allFilters = [
        getSearchSetting(),
        getHideGraphChip(),
        getDatasourceFilter(),
        availableTags.length > 0 ? getTagFilterSetting() : undefined,
        getResultMediaTypeFilter(),
        getTimePeriodSetting(),
    ].filter((d) => d) as FilterSetting[]

    return (
        <ContentPanel
            disableToolbarToggle
            fullWidthTitle
            title={<TitlebarWithFilters title={'results.title'} filterBar={<FilterDrawer filters={allFilters} />} filterBarInSameLine />}
        >
            {showGraph && <ResultsChart height={300} data={filteredResults} schema={schema} />}

            <AutoSizer>
                {({ height, width }) => {
                    const actualHeight = height - (showGraph ? 364 : 0)
                    return (
                        <Fallback condition={results.length === 0}>
                            <Paper sx={{ height: actualHeight, width }}>
                                <Box
                                    padding={1}
                                    display="flex"
                                    justifyContent="space-between"
                                    borderBottom={`1px solid ${theme.palette.divider}`}
                                    alignItems="center"
                                >
                                    <Box paddingLeft={1}>
                                        <Typography>
                                            <strong>
                                                {results.length} <FormattedMessage id="results.title" />
                                            </strong>
                                        </Typography>
                                    </Box>

                                    <ButtonGroup color="inherit" variant="text" size="small">
                                        <Button
                                            endIcon={<ExpandMore />}
                                            onClick={handleClick}
                                            style={{
                                                width: '150px',
                                                color: theme.palette.text.secondary,
                                            }}
                                        >
                                            <FormattedMessage id={sortedBy} />
                                        </Button>
                                        <Button onClick={() => dispatch(toggleResultsSortDirection())}>
                                            <FilterList
                                                color="action"
                                                style={{
                                                    transform: sortDirection === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                                }}
                                            />
                                        </Button>
                                    </ButtonGroup>
                                </Box>

                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    {['published_ts', 'reach', 'engagement'].map((m: any) => (
                                        <MenuItem key={m} dense onClick={() => handleMenuClick(m)}>
                                            <FormattedMessage id={m} />
                                        </MenuItem>
                                    ))}
                                </Menu>

                                {showLoading ? (
                                    <Loading />
                                ) : (
                                    <ResultsListBox>
                                        <FixedSizeList width={width} itemSize={126} height={actualHeight - 49} itemCount={results.length} itemData={results}>
                                            {ResultEntry}
                                        </FixedSizeList>
                                    </ResultsListBox>
                                )}
                            </Paper>
                        </Fallback>
                    )
                }}
            </AutoSizer>
        </ContentPanel>
    )
}
