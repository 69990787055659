import axios from 'axios'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import moment from 'moment'
import { Project } from '../settings/project-management/Project'

export type CSVParams = {
    startDate: string
    endDate: string
    event: string
    datasource: ProjectDatasource
    project: Project | undefined
    gatewayAPILink: string
}

export const GenerateConversionCSV = async (CSVParams: CSVParams) => {
    const { project, startDate, endDate, event, datasource, gatewayAPILink } = CSVParams
    if (!project) {
        return null
    }

    const gatewaynewlink = gatewayAPILink.replace('/init', '/projects')

    return new Promise<void>((resolve) => {
        const month = moment(startDate).format('MMMM')
        const year = moment(startDate).format('YYYY')
        axios
            .get(gatewaynewlink + `/${project.id}/facebook/conversions/${datasource.id}/csv`, {
                responseType: 'blob',
                params: {
                    since: startDate,
                    until: endDate,
                    event: event,
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `Conversion_data_${datasource.name}_${month}_${year}.csv`)
                document.body.appendChild(link)
                link.click()
                link.remove()

                resolve()
            })
    })
}
