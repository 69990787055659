import axios from 'axios'
import Keycloak from 'keycloak-js'
import { ClientConfig } from '../config/config'

const registerUpdateTokenHandling = (keycloak: Keycloak) => {
    axios.defaults.headers.common['Accept'] = 'application/json'
    axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`

    let hiddenAttributeName: any, visibilityChangeEventName: any
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hiddenAttributeName = 'hidden'
        visibilityChangeEventName = 'visibilitychange'
    } else if (typeof (document as any).msHidden !== 'undefined') {
        hiddenAttributeName = 'msHidden'
        visibilityChangeEventName = 'msvisibilitychange'
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
        hiddenAttributeName = 'webkitHidden'
        visibilityChangeEventName = 'webkitvisibilitychange'
    } else {
        hiddenAttributeName = undefined
    }

    let refreshToken = (minValidity: number = 30) => {
        keycloak
            .updateToken(minValidity)
            .then((refreshed) => {
                if (refreshed) {
                    // Set new token if refreshed successfully
                    axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`
                }
            })
            .catch((error) => {
                console.log(error)
                console.log('error while refreshing token')
                // eslint-disable-next-line no-restricted-globals
                location.reload()
            })
    }

    let updateToken = () =>
            setInterval(() => {
                refreshToken()
            }, 10000),
        interval = updateToken()

    if (hiddenAttributeName) {
        const handler = () => {
            if ((document as any)[hiddenAttributeName]) {
                clearInterval(interval)
            } else {
                refreshToken(300)
                interval = updateToken()
            }
        }

        document.addEventListener(visibilityChangeEventName, handler, false)
    }

    axios.interceptors.response.use(
        (response) => response,
        (error) => Promise.reject(error.response)
    )
}

export const initKeycloak = (clientConfig: ClientConfig): Promise<Keycloak> => {
    const keycloak = new Keycloak(clientConfig.keycloak)

    return new Promise((resolve, reject) => {
        const silentCheckSsoRedirectUri = document.baseURI + '/silent-check-sso.html'

        keycloak
            .init({ onLoad: 'check-sso', silentCheckSsoRedirectUri, scope: 'openid profile email phone' })
            .then((authenticated) => {
                if (window.location.pathname.includes('/portal/registration')) {
                    const redirectUri = window.location.origin + '/portal/'

                    let keycloakRegistrationUrl = keycloak.createRegisterUrl({ redirectUri })
                    window.location.href = keycloakRegistrationUrl + '&' + window.location.search.slice(1)
                } else {
                    if (authenticated) {
                        registerUpdateTokenHandling(keycloak)
                        resolve(keycloak)
                    } else {
                        keycloak.login()
                    }
                }
            })
            .catch((e) => reject(e))
    })
}
