import { FC, useEffect, useState } from 'react'
import { Box, Grid, Paper, Switch, Tooltip as MuiTooltip, Typography } from '@mui/material'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Area, AreaChart as RAreaChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ChartTooltip } from '../../common/chart-tooltip/ChartTooltip'
import { AreaChartData } from '../../common/chart-data-types/AreaChartData'
import { Info, Square } from '@mui/icons-material'
import { DownloadGraphButton } from '../../common/download-graph-button/DownloadGraphButton'
import { useSelector } from 'react-redux'
import { getImpairedVision, isDarkMode } from '../../core/slices/CoreSlice'
import { calculateOptimalColor } from '../../common/accessibility/ContrastChecker'
import { formatDateRangeDefaults, getTimePeriodWithPrefix } from '../../core/theme/helper'
import { DashboardWidgetData } from '../DashboardData'

type AreaChartProps = {
    title: string
    tooltip?: JSX.Element
    total?: number
    data: AreaChartData
    schema: {
        key: string
        dataSets: {
            color: string
            dataKey: string
            label: string | JSX.Element
        }[]
    }
    disableGradient?: boolean
    withoutLegend?: boolean
    height?: number
    multipleAxis?: boolean
    yAxis1Unit?: string
    yAxis2Unit?: string
    deltaGradient?: boolean
    stacked?: boolean
    zeroLine?: boolean
    minValue?: number
    maxValue?: number
    metadata?: DashboardWidgetData['metadata']
}

export const AreaChart: FC<AreaChartProps> = (props) => {
    const {
        data,
        total,
        schema,
        title,
        withoutLegend,
        height,
        multipleAxis,
        yAxis1Unit,
        yAxis2Unit,
        tooltip,
        deltaGradient,
        stacked,
        disableGradient,
        zeroLine,
        minValue,
        maxValue,
        metadata,
    } = props
    const { key, dataSets } = schema
    const intl = useIntl()
    const darkMode = useSelector(isDarkMode)
    const impairedVision = useSelector(getImpairedVision)

    const [off, setOff] = useState(0)

    const formatNumber = (value: number): string => {
        if (minValue && maxValue && minValue >= 10000) {
            if (minValue !== value) {
                return '+' + (value - minValue).toString()
            }
        }

        if (isNaN(value)) {
            return '0'
        }

        if (value > 999999) {
            return value / 1000000 + 'M'
        }

        if (value > 9999) {
            return value / 1000 + 'K'
        }

        return value.toString()
    }

    useEffect(() => {
        let dataMax: any = 0
        let dataMin: any = 0
        dataSets.map(
            (ds) =>
                // @ts-ignore
                // prettier-ignore
                dataMax = Math.max(...data.map((i) => i[ds.dataKey])) > dataMax ? Math.max(...data.map((i) => i[ds.dataKey])) : dataMax
        )
        dataSets.map(
            (ds) =>
                // @ts-ignore
                // prettier-ignore
                dataMin = Math.min(...data.map((i) => i[ds.dataKey])) < dataMin ? Math.min(...data.map((i) => i[ds.dataKey])) : dataMin
        )

        if (dataMax <= 0) {
            setOff(0)
        }
        if (dataMin >= 0) {
            setOff(1)
        }
        setOff(dataMax / (dataMax - dataMin))
    }, [dataSets, data])

    const [greenRedChange, setGreenRedChange] = useState(<div />)
    const randomId = '_' + Math.random().toString(36).substr(2, 9)

    useEffect(() => {
        if (deltaGradient) {
            setGreenRedChange(
                <defs>
                    <linearGradient id={'greenRedChange' + off.toString()} x1="0" y1="0" x2="0" y2="1">
                        <stop offset={0} stopColor={'green'} stopOpacity={1} />
                        <stop offset={off - 0.01} stopColor={'green'} stopOpacity={0.2} />
                        <stop offset={off} stopColor={'black'} stopOpacity={1} />
                        <stop offset={off + 0.01} stopColor={'red'} stopOpacity={0.2} />
                        <stop offset={1} stopColor={'red'} stopOpacity={1} />
                    </linearGradient>
                </defs>
            )
        }
    }, [off, data, deltaGradient])

    const [stackedChart, setStackedChart] = useState(stacked)

    return (
        <Paper id={randomId} sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
            {stacked && <Switch checked={stackedChart} onChange={() => setStackedChart(!stackedChart)} />}
            <DownloadGraphButton tileId={randomId} parentRelativeFlex />
            <Box padding={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box paddingLeft={1} display="flex">
                    <Typography>
                        <strong>
                            <FormattedMessage id={title} />
                        </strong>
                    </Typography>
                    {tooltip && (
                        <MuiTooltip title={tooltip}>
                            <Info fontSize="small" sx={{ ml: 1, mt: 0.25 }} />
                        </MuiTooltip>
                    )}
                    <Typography color="textSecondary">
                        {total && (
                            <>
                                &nbsp; (<FormattedNumber value={total} />)
                            </>
                        )}
                    </Typography>
                </Box>
            </Box>

            <Box width="100%" padding={1} height={height || 210} paddingRight={multipleAxis ? 1 : 9} sx={{ overflow: 'hidden', flexGrow: 1 }}>
                <ResponsiveContainer width="100%" height="100%" debounce={50}>
                    <RAreaChart data={data} height={height || 210}>
                        <defs>
                            {dataSets.map((ds) => (
                                <linearGradient key={ds.dataKey} id={ds.dataKey} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="50%" stopColor={calculateOptimalColor(impairedVision, ds.color, darkMode)} stopOpacity={1} />
                                    <stop offset="100%" stopColor={calculateOptimalColor(impairedVision, ds.color, darkMode)} stopOpacity={0} />
                                </linearGradient>
                            ))}
                        </defs>
                        {greenRedChange}
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis
                            tickFormatter={(value) => {
                                return metadata?.date_format ? getTimePeriodWithPrefix(value, metadata.date_format, intl) : formatDateRangeDefaults(value)
                            }}
                            dataKey={key}
                            tickMargin={8}
                            tickLine={false}
                            minTickGap={50}
                            tick={{ fill: darkMode ? 'white' : 'rgb(102, 102, 102)' }}
                        />
                        <YAxis
                            yAxisId="yAxis1"
                            domain={[minValue ? minValue : 'auto', maxValue ? maxValue : 'auto']}
                            allowDataOverflow={true}
                            tickLine={false}
                            tickMargin={8}
                            unit={yAxis1Unit}
                            tickFormatter={formatNumber}
                            tick={{ fill: darkMode ? 'white' : 'rgb(102, 102, 102)' }}
                        />
                        {multipleAxis && (
                            <YAxis
                                yAxisId="yAxis2"
                                unit={yAxis2Unit}
                                tickLine={false}
                                tickMargin={8}
                                orientation="right"
                                tickFormatter={formatNumber}
                                tick={{ fill: darkMode ? 'white' : 'rgb(102, 102, 102)' }}
                            />
                        )}
                        <Tooltip
                            wrapperStyle={{ outline: 'none' }}
                            content={({ active, payload, label }) => (
                                <ChartTooltip active={active} payload={payload} label={label} dateFormatForArea={metadata?.date_format} />
                            )}
                        />
                        {zeroLine && <ReferenceLine y={0} yAxisId={'yAxis1'} stroke={darkMode ? '#ffffff' : '#000000'} strokeDasharray="5 1" />}
                        {dataSets.map((ds, index) => (
                            <Area
                                yAxisId={multipleAxis ? (index % 2 === 1 ? 'yAxis2' : 'yAxis1') : 'yAxis1'}
                                key={(ds.label as string) || 'test'}
                                type="monotone"
                                legendType={'none'}
                                stackId={stackedChart ? 1 : undefined}
                                name={ds.label as string}
                                dataKey={ds.dataKey}
                                stroke={calculateOptimalColor(impairedVision, ds.color, darkMode)}
                                fillOpacity={deltaGradient ? 0.3 : undefined}
                                fill={!deltaGradient ? (disableGradient ? 'transparent' : `url(#${ds.dataKey})`) : 'url(#greenRedChange' + off.toString() + ')'}
                                strokeWidth={2}
                            />
                        ))}
                        {withoutLegend !== true && (
                            <Legend formatter={(value, entry, index) => dataSets[index as any].label} wrapperStyle={{ position: 'relative' }} />
                        )}
                    </RAreaChart>
                </ResponsiveContainer>
            </Box>
            {withoutLegend !== true && (
                <Box pb={2}>
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        {dataSets.map((ds) => (
                            <Grid
                                key={ds.dataKey}
                                item
                                sx={{
                                    color: calculateOptimalColor(impairedVision, ds.color, darkMode),
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Square fontSize="small" />
                                &nbsp;
                                {typeof ds.label === 'string' ? <FormattedMessage id={ds.label} /> : ds.label}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Paper>
    )
}
