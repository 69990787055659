import { FC, useState } from 'react'
import { Button, CircularProgress, Tooltip } from '@mui/material'
import { Download } from '@mui/icons-material'
import { GenerateConversionCSV } from './generateConversionCSV'
import { useSelector } from 'react-redux'
import { getGatewayLink } from '../core/slices/DataSlice'
import { ProjectDatasource } from '../settings/datasources/ProjectDatasource'
import { FormattedMessage } from 'react-intl'
import { Project } from '../settings/project-management/Project'

type ConversionCSVButtonProps = {
    startDate: string
    endDate: string
    event: string
    datasource: ProjectDatasource
    project: Project | undefined
}

export const DownloadCSVButton: FC<ConversionCSVButtonProps> = ({ startDate, endDate, event, datasource, project }) => {
    const [loadingCsv, setLoadingCsv] = useState(false)
    const gatewayLink = useSelector(getGatewayLink)

    const handleDownload = () => {
        setLoadingCsv(true)
        GenerateConversionCSV({
            startDate: startDate,
            endDate: endDate,
            datasource: datasource,
            event: event,
            project: project,
            gatewayAPILink: gatewayLink.href,
        }).then(() => setLoadingCsv(false))
    }

    return (
        <Tooltip title={<FormattedMessage id={'post.analysis.download-csv-tooltip'} />} disableInteractive>
            <Button
                color={'secondary'}
                variant={'contained'}
                onClick={handleDownload}
                disabled={loadingCsv}
                startIcon={loadingCsv ? <CircularProgress color="inherit" size={20} /> : <Download />}
            >
                <FormattedMessage id={'post.analysis.download-csv'} />
            </Button>
        </Tooltip>
    )
}
