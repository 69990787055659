import { FC, useState } from 'react'
import { ColorChangeHandler, ColorResult, SketchPicker } from 'react-color'
import { Box, InputLabel, Popover } from '@mui/material'
import { Field, useField } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

type ConnectedColorpickerProps = {
    name: string
    label?: string
    type?: string
    required?: boolean
    helperText?: string
    shrinkLabel?: boolean
    validate?: any
    fullWidth?: boolean
    multiline?: boolean
    maxLength?: number
    disabled?: boolean
    outline?: string
    centerItems?: boolean
}

export const ConnectedColorpicker: FC<ConnectedColorpickerProps> = ({ label, name, disabled, required, outline, centerItems }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const field = useField(name)
    const [color, setColor] = useState(field.input.value)

    const openColorPicker = (event: any) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget)
        }
    }

    return (
        <Field name={name}>
            {({ input }) => {
                const onChangeComplete: ColorChangeHandler = (colorResult) => {
                    input.onChange(colorResult.hex)
                }

                return (
                    <>
                        <Box
                            sx={
                                centerItems
                                    ? {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                      }
                                    : {}
                            }
                        >
                            {label && (
                                <InputLabel
                                    sx={
                                        centerItems
                                            ? {
                                                  transformOrigin: 'center',
                                                  transform: 'translate(0, 0) scale(0.75)',
                                              }
                                            : {}
                                    }
                                    shrink={true}
                                    disabled={disabled}
                                    required={required}
                                >
                                    <FormattedMessage id={label} />
                                </InputLabel>
                            )}
                            <Box
                                onClick={openColorPicker}
                                borderRadius="50%"
                                height={32}
                                width={32}
                                style={{
                                    backgroundColor: color,
                                    border: outline ? `1px solid ${outline}` : undefined,
                                }}
                            />
                        </Box>
                        <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                            <SketchPicker
                                color={color}
                                onChange={(color: ColorResult) => setColor(color.hex)}
                                onChangeComplete={onChangeComplete}
                                disableAlpha={true}
                            />
                        </Popover>
                    </>
                )
            }}
        </Field>
    )
}
