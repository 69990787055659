import { FC } from 'react'
import { Box, Grid } from '@mui/material'
import { ConnectedTextField } from '../../../form/ConnectedTextField'
import { ConnectedAutocomplete } from '../../../form/ConnectedAutocomplete'
import { addDatasourceTag, getDatasourceTags } from '../../SettingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedColorpicker } from '../../../form/ConnectedColorPicker'
import { validationRequired } from '../../../form/validate'

type TalkwalkerDatasourceProps = { disabled?: boolean }

export const TalkwalkerDatasource: FC<TalkwalkerDatasourceProps> = ({ disabled }) => {
    const dispatch = useDispatch()
    const datasourceTags = useSelector(getDatasourceTags)

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <ConnectedTextField disabled={disabled} name="name" shrinkLabel={true} label="general.name" fullWidth />
            </Grid>
            <Grid item xs={12}>
                <ConnectedTextField
                    shrinkLabel={true}
                    name="metadata.link"
                    label="results.results-link"
                    required={true}
                    validate={validationRequired}
                    maxLength={400}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <ConnectedTextField
                    shrinkLabel={true}
                    name="metadata.deleted_link"
                    label="results.deleted-link"
                    maxLength={400}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={11}>
                <ConnectedAutocomplete
                    name="tags"
                    shrinkLabel={true}
                    label="general.tags"
                    options={datasourceTags}
                    onAddOption={(o) => dispatch(addDatasourceTag(o))}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1}>
                <Box display="flex" justifyContent="center">
                    <ConnectedColorpicker disabled={disabled} name="color" label="general.color" fullWidth />
                </Box>
            </Grid>
        </Grid>
    )
}
