import { Box, LinearProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { getRunningExports } from '../../dashboards/DashboardsSlice'
import { HmstrColors } from '../../core/theme/hmstr-theme'
import { FormattedMessage } from 'react-intl'
import * as React from 'react'
import { getDashboards } from '../../core/slices/DataSlice'
import { getIsMobile } from '../../core/slices/CoreSlice'

export const ExportHint = () => {
    const runningExports = useSelector(getRunningExports)
    const runningExportsReverted = [...runningExports].reverse()
    const dashboards = useSelector(getDashboards)
    const isMobile = useSelector(getIsMobile)

    return (
        <Box
            sx={
                isMobile
                    ? {
                          position: 'fixed',
                          top: '8px',
                          left: '8px',
                          zIndex: 'modal',
                      }
                    : {
                          position: 'fixed',
                          bottom: '24px',
                          right: '24px',
                          zIndex: 'modal',
                      }
            }
        >
            {runningExportsReverted.map((runningExport) => (
                <Box
                    key={'PDF-export-' + runningExport.dashboardId + runningExport.type}
                    sx={{
                        bgcolor: HmstrColors.Orange,
                        color: HmstrColors.White,
                        padding: '24px',
                        marginTop: isMobile ? 0 : '32px',
                        maxWidth: isMobile ? 'calc(100vw - 18px)' : '320px',
                        borderRadius: '0.3em',
                        overflow: 'hidden',
                        boxShadow: 3,
                    }}
                >
                    <LinearProgress
                        sx={{
                            color: HmstrColors.DarkGrey,
                            backgroundColor: HmstrColors.White,
                            marginBottom: '12px',
                        }}
                    />
                    <FormattedMessage
                        id={`reporting.dashboards.exporting-${runningExport.type}`}
                        values={{ dashboard: dashboards[runningExport.dashboardId].name }}
                    />
                </Box>
            ))}
        </Box>
    )
}
