import * as React from 'react'
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog } from '@mui/material'
import { ContentPanel } from '../../common/content-panel/ContentPanel'
import { DateRangePicker } from '../../common/daterange-picker/DateRangePicker'
import { Tag } from '../../tags/Tag'
import { CustomTagFilter } from '../../common/filter/CustomTagFilter'
import { DatasourceSelection } from '../datasource/DatasoureSelection'
import {
    changeTimePeriod,
    getAggregatedFilteredTagDataForSelectedDatasources,
    getFilteredPostsByTagIdForSelectedDatasources,
    getSelectedDatasourceIds,
    getSelectedDatasources,
    getSelectedTags,
    getSelectedTimePeriodEnd,
    getSelectedTimePeriodStart,
    getSortDirection,
    getSortedAggregatedFilteredTagDataForSelectedDatasources,
    getSortField,
    getTagsForFilteredPostsForSelectedDatasources,
    selectDatasources,
    setSelectedTags,
    setSortDirection,
    setSortField,
} from './PostAnalysisSlice'
import { getPreviewPost, hidePreview, isPostDataLoading } from '../posts/PostsSlice'
import { Fallback } from '../../common/fallback/Fallback'
import { Loading } from '../../common/loading/Loading'
import { useEffectWithIdComparison } from '../../core/hooks/useEffectWithIdComparison'
import { fetchTagsForProject } from '../../tags/TagsActions'
import { getPagesForAnalyse, getSelectedProject } from '../../core/slices/CoreSlice'
import { fetchCommonPostData } from '../posts/PostsActions'
import { FacebookPostPreview } from '../posts/FacebookPostPreview'
import { HmstrDispatch } from '../../core/Store'
import { getUntaggedTag } from '../../tags/TagsSlice'
import { PostAnalysisSortType } from '../../common/filter/PostAnalysisSortType'
import { PostAnalysisContent } from './PostAnalysisContent'
import { replaceUrlParams } from '../../core/helpers/replace-url-params'
import { useProjectNavigate } from '../../core/helpers/use-project-navigate'
import { FilterDrawer, FilterSetting } from '../../common/filterbar/FilterDrawer'
import { TitlebarWithFilters } from '../../common/filterbar/TitlebarWithFilters'
import _ from 'lodash'
import { CalendarToday, Sell, Web } from '@mui/icons-material'
import moment from 'moment'
import { renderTimePeriodString } from '../../resources/translations/Helper'
import {
    getDatasourceSelectionOpen,
    getTagSelectionOpen,
    getTimePeriodSettingOpen,
    setDatasourceSelectionOpen,
    setTagSelectionOpen,
    setTimePeriodSettingOpen,
} from '../../common/filterbar/FilterSlice'

type PostAnalysisProps = {}

export const PostAnalysis: FC<PostAnalysisProps> = () => {
    const intl = useIntl()
    const dispatch = useDispatch<HmstrDispatch>()

    const navigate = useProjectNavigate()
    const selectedProject = useSelector(getSelectedProject)
    const pagesForAnalyse = useSelector(getPagesForAnalyse)
    const availableDatasourceIds = _.map(pagesForAnalyse, (datasource) => datasource.id).filter((d) => d)
    const selectedDataSources = useSelector(getSelectedDatasources)
    const selectedDataSourceIds = useSelector(getSelectedDatasourceIds)
    const tags = useSelector(getTagsForFilteredPostsForSelectedDatasources)
    const startDate = useSelector(getSelectedTimePeriodStart)
    const endDate = useSelector(getSelectedTimePeriodEnd)
    const selectedTags = useSelector(getSelectedTags)
    const isLoading = useSelector(isPostDataLoading)
    const previewPost = useSelector(getPreviewPost)
    const aggregatedTagData = useSelector(getAggregatedFilteredTagDataForSelectedDatasources)
    const sortField = useSelector(getSortField)
    const sortDirection = useSelector(getSortDirection)
    const postsByTagId = useSelector(getFilteredPostsByTagIdForSelectedDatasources)
    const sortedAggregatedTagData = useSelector(getSortedAggregatedFilteredTagDataForSelectedDatasources)
    const tagsWithUntagged = [getUntaggedTag(intl.formatMessage({ id: 'tags.untagged-posts' })), ...tags]
    const datasourceSelectionOpen = useSelector(getDatasourceSelectionOpen)
    const tagSelectionOpen = useSelector(getTagSelectionOpen)
    const timePeriodSettingOpen = useSelector(getTimePeriodSettingOpen)

    useEffect(() => {
        if (selectedDataSourceIds.length === 0) {
            dispatch(selectDatasources(availableDatasourceIds))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDatasourceSelectionOpen = () => {
        dispatch(setDatasourceSelectionOpen(true))
    }

    const handleDatasourceSelectionClose = () => {
        dispatch(setDatasourceSelectionOpen(false))
    }

    const handleTagSelectionOpen = () => {
        dispatch(setTagSelectionOpen(true))
    }

    const handleTagSelectionClose = () => {
        dispatch(setTagSelectionOpen(false))
    }

    const handleTimePeriodOpen = () => {
        dispatch(setTimePeriodSettingOpen(true))
    }

    const handleTimePeriodClose = () => {
        dispatch(setTimePeriodSettingOpen(false))
    }

    useEffectWithIdComparison(() => {
        if (pagesForAnalyse.length > 0 && selectedDataSources.length > 0) {
            const urlParams = new URLSearchParams(window.location.search)
            urlParams.set('dataSources', selectedDataSources.map((project) => project.id).join(','))
            urlParams.set('startDate', startDate.split('T')[0])
            urlParams.set('endDate', endDate.split('T')[0])
            urlParams.set('tags', selectedTags.join(','))
            replaceUrlParams(urlParams)
        } else if (pagesForAnalyse.length > 0 && selectedDataSources.length === 0) {
            navigate('/analytics/post-analysis')
        }
    }, [pagesForAnalyse, selectedDataSourceIds, selectedTags])

    useEffect(() => {
        return () => {
            dispatch(setSortField(PostAnalysisSortType.NAME))
            dispatch(setSortDirection('desc'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchTagsForProject(selectedProject))
        }
    }, [selectedProject])

    useEffectWithIdComparison(() => {
        if (selectedDataSourceIds.length > 0) {
            dispatch(fetchCommonPostData({ since: startDate, until: endDate, data_source_ids: selectedDataSourceIds }))
        }
    }, [selectedDataSources, startDate, endDate])

    const getDatasourceSetting = () => {
        const defaultValue: string[] = []
        const hasChanged = !_.isEqual(_.sortBy(defaultValue), _.sortBy(selectedDataSourceIds))
        const generateSettingString = () => {
            return selectedDataSourceIds.length === 1
                ? pagesForAnalyse.find((project) => project.id === selectedDataSourceIds[0])?.name
                : intl.formatMessage({ id: 'datasource.amountSelected' }, { amount: selectedDataSourceIds.length })
        }

        const onDeleteFunction = () => {
            dispatch(selectDatasources([]))
        }

        const onChipClick = () => {
            setTimeout(handleDatasourceSelectionOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: (
                <DatasourceSelection
                    datasources={pagesForAnalyse}
                    handleClose={handleDatasourceSelectionClose}
                    handleOpen={handleDatasourceSelectionOpen}
                    isOpen={datasourceSelectionOpen}
                    fullWidth
                    initialIds={selectedDataSourceIds}
                    onDatasourceChange={(ids) => {
                        dispatch(selectDatasources(ids))
                    }}
                />
            ),
            tooltip: 'general.datasources',
            hasChangedFromDefault: hasChanged,
            currentValueAsString: generateSettingString(),
            onDeleteFunction,
            onClickFunction: onChipClick,
            deletable: true,
            important: true,
            icon: <Web fontSize={'small'} />,
        }
        return set
    }

    const getTagFilterSetting = () => {
        const onDeleteFunction = () => {
            dispatch(setSelectedTags([]))
        }
        const renderValueString = () => {
            if (selectedTags.length > 2) {
                return intl.formatMessage({ id: 'filters.xTagsSelected' }, { x: selectedTags.length })
            } else if (selectedTags.length > 0) {
                const tagNames = tagsWithUntagged.map((tag) => (selectedTags.includes(tag.id) ? tag.label : undefined)).filter((d) => d)
                if (tagNames.length > 0) {
                    return tagNames.join(', ')
                } else {
                    dispatch(setSelectedTags([]))
                    return intl.formatMessage({ id: 'filters.no-tags' })
                }
            } else {
                return intl.formatMessage({ id: 'filters.no-tags' })
            }
        }

        const hasChangedFromDefault = selectedTags.length > 0

        const handleChipClick = () => {
            setTimeout(handleTagSelectionOpen, 300)
        }

        const set: FilterSetting = {
            settingInput: (
                <CustomTagFilter
                    handleOpen={handleTagSelectionOpen}
                    handleClose={handleTagSelectionClose}
                    isOpen={tagSelectionOpen}
                    label={intl.formatMessage({ id: 'filters.tags' })}
                    tags={tags}
                    selectedTagIds={selectedTags}
                    showUntaggedOption={true}
                    onChange={(newValue: (String | Tag | undefined)[]) => {
                        let values = Object.values(newValue)
                        let selectedTags: string[] = []

                        values.forEach((element) => {
                            // noinspection SuspiciousTypeOfGuard
                            if (!!element && typeof element !== 'string') {
                                // @ts-ignore
                                selectedTags.push(element.id)
                            }
                        })
                        dispatch(setSelectedTags(selectedTags))
                    }}
                />
            ),
            tooltip: 'filters.tags',
            important: true,
            deletable: true,
            onDeleteFunction,
            onClickFunction: handleChipClick,
            hasChangedFromDefault,
            icon: <Sell fontSize={'small'} />,
            currentValueAsString: renderValueString(),
        }
        return set
    }

    const getTimePeriodSetting = () => {
        const defaultStartTime = moment().subtract(30, 'days').format()
        const defaultEndTime = moment().format()
        const onDeleteFunction = () => {
            dispatch(changeTimePeriod({ startDate: defaultStartTime, endDate: defaultEndTime }))
        }

        const hasChangedFromDefault =
            moment(startDate).format('YYYY-MM-DD') !== moment(defaultStartTime).format('YYYY-MM-DD') ||
            moment(endDate).format('YYYY-MM-DD') !== moment(defaultEndTime).format('YYYY-MM-DD')

        const onChipClick = () => {
            setTimeout(handleTimePeriodOpen, 300)
        }
        const set: FilterSetting = {
            settingInput: (
                <DateRangePicker
                    open={timePeriodSettingOpen}
                    onClose={handleTimePeriodClose}
                    onOpen={handleTimePeriodOpen}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(startDate, endDate) => {
                        dispatch(changeTimePeriod({ startDate, endDate }))
                    }}
                    variant={'outlined'}
                />
            ),
            tooltip: 'filters.timeperiod',
            onDeleteFunction,
            hasChangedFromDefault,
            onClickFunction: onChipClick,
            currentValueAsString: renderTimePeriodString(startDate, endDate, intl),
            icon: <CalendarToday fontSize={'small'} />,
            deletable: true,
            important: true,
        }

        return set
    }

    return (
        <ContentPanel
            disableToolbarToggle
            fullWidthTitle
            maxWidth={false}
            title={
                <TitlebarWithFilters
                    title={'post.analysis.page-title'}
                    filterBar={<FilterDrawer filters={[getDatasourceSetting(), getTagFilterSetting(), getTimePeriodSetting()]}></FilterDrawer>}
                />
            }
        >
            <Fallback
                condition={selectedDataSourceIds.length === 0 || selectedTags.length === 0}
                messageId="post.analysis.no-datasources-or-tags-selected"
                variant={'h6'}
            >
                <Fallback condition={aggregatedTagData.length === 0 && !isLoading} messageId="post.analysis.no-posts" variant={'h6'}>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <PostAnalysisContent
                            aggregatedTagData={aggregatedTagData}
                            postsByTagId={postsByTagId}
                            tagsWithUntagged={tagsWithUntagged}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            sortedAggregatedTagData={sortedAggregatedTagData}
                        />
                    )}
                </Fallback>
            </Fallback>

            <Dialog open={Boolean(previewPost)} onClose={() => dispatch(hidePreview())}>
                {previewPost && <FacebookPostPreview post={previewPost} />}
            </Dialog>
        </ContentPanel>
    )
}
