import { FC, MouseEvent, MouseEventHandler } from 'react'
import { BottomNavigationAction, Box, IconButtonProps, Theme, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { SxProps } from '@mui/system'
import { useLocation } from 'react-router-dom'
import { useProjectNavigate } from '../core/helpers/use-project-navigate'
import { useDispatch, useSelector } from 'react-redux'
import { getLastModulePages } from '../core/slices/CoreSlice'
import { selectTicketId } from '../engagement/EngagementSlice'
import { selectDashboard } from '../dashboards/DashboardsSlice'

type PrimaryNavigationButtonMobileProps = {
    tooltip?: string
    icon: JSX.Element
    sx?: SxProps<Theme>
    path?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    size?: IconButtonProps['size']
    color?: 'primary' | 'secondary' | 'info' | 'error' | 'success'
    alwaysActive?: boolean
    disabled?: boolean
    id?: string
}

export const PrimaryNavigationButtonMobile: FC<PrimaryNavigationButtonMobileProps> = ({ tooltip, icon, path, onClick, alwaysActive, disabled }) => {
    const theme = useTheme()
    const intl = useIntl()
    const location = useLocation()
    const navigate = useProjectNavigate()
    const lastModulePages = useSelector(getLastModulePages)
    const dispatch = useDispatch()

    // Do not ever set the root icon to active
    const isActive = path && path !== '/' && location.pathname.includes(path)

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (path) {
            const module = path.substring(1)
            if (module === 'engagement') {
                dispatch(selectTicketId(undefined))
            }
            if (module === 'analytics') {
                dispatch(selectDashboard(undefined))
            }

            if (Object.keys(lastModulePages).includes(module)) {
                navigate(path + (lastModulePages as any)[module])
            } else {
                navigate(path)
            }
        }

        if (onClick) {
            onClick(event)
        }
    }

    return (
        <BottomNavigationAction
            icon={
                <Box
                    sx={{
                        background: alwaysActive || isActive ? theme.palette.secondary.main : 'transparent',
                        borderRadius: 100,
                        transition: 'background 0.2s',
                        p: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {icon}
                </Box>
            }
            disabled={disabled}
            disableRipple
            label={tooltip ? <Typography fontSize={'0.9em'}>{intl.formatMessage({ id: tooltip })}</Typography> : undefined}
            sx={{
                color: disabled ? 'rgba(256, 256, 256, 0.26)' : 'white',
                // gap: tooltip ? 0.2 : 0,
                transition: 'all 0.3s',
                // mt: tooltip ? '0.9em' : 0,
            }}
            onClick={handleClick}
            // showLabel={!!tooltip}
        />
    )
}
