import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { InfoDialog } from '../common/info-dialog/InfoDialog'
import { PrimaryNavigation } from '../navigation/PrimaryNavigation'
import { ProjectManagement } from '../settings/project-management/ProjectManagement'
import {
    closeFullscreenDialog,
    getAllowedModulesForSelectedProject,
    getApiError,
    getAppThemeColor,
    getCurrentUser,
    getFullscreenDialog,
    getIsMobile,
    getMobileUserMenuExpanded,
    getPrefersBrowser,
    getSelectedProject,
    getShowInfoDialog,
    isDarkMode,
    isProjectAdmin,
    setPrefersBrowser,
} from './slices/CoreSlice'
import { Results } from '../monitoring/Results'
import { HmstrDispatch } from './Store'
import { OpinionLeaders } from '../monitoring/OpinionLeaders'
import { ResultsPreview } from '../monitoring/ResultsPreview'
import { getShowResultsPreview } from '../monitoring/ResultsSlice'
import { ErrorDialog } from '../common/error-dialog/ErrorDialog'
import { fetchGatewayVersion } from './slices/CoreActions'
import { Dashboards } from '../dashboards/Dashboards'
import { Posts } from '../content-analytics/posts/Posts'
import { Billing } from '../settings/billing/Billing'
import { Tags } from '../content-analytics/tags/Tags'
import { ConversionTracking } from '../conversion-tracking/ConversionTracking'
import { ConversionTrackingManagement } from '../conversion-tracking/ConversionTrackingManagement'
import { AdBlockerDialog } from '../common/adblocker-dialog/AdBlockerDialog'
import { getRunningExports } from '../dashboards/DashboardsSlice'
import { ExportHint } from '../common/export-hint/ExportHint'
import { PostAnalysis } from '../content-analytics/post-analysis/PostAnalysis'
import { AccountLinking } from '../settings/linked-accounts/AccountLinking'
import { Box, darken, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { SecondaryNavigation } from '../navigation/SecondaryNavigation'
import { DisplaySettings } from '../settings/DisplaySettings'
import { AccountOverview } from '../settings/accounts/overview/AccountOverview'
import { ProjectForm } from '../settings/ProjectForm'
import { UserManagement } from '../settings/user-management/UserManagement'
import { FullscreenDialog } from '../common/fullscreen-dialog/FullscreenDialog'
import { AccountCreateWizard } from '../settings/accounts/AccountCreateWizard'
import { UserInvite } from '../settings/user-management/UserInvite'
import { UserEdit } from '../settings/user-management/UserEdit'
import { InviteEdit } from '../settings/user-management/InviteEdit'
import { ProjectNewWizard } from '../settings/project-management/ProjectNewWizard'
import { AccountUpdateSubscriptionWizard } from '../settings/accounts/AccountUpdateSubscriptionWizard'
import { ProjectDatasourceOverview } from '../settings/datasources/ProjectDatasourceOverview'
import { Calendar } from '../publishing/calendar/Calendar'
import { DynamicDashboardView } from '../dashboards/DynamicDashboardView'
import { DashboardEdit } from '../dashboards/DashboardEdit'
import { DashboardNew } from '../dashboards/DashboardNew'
import { TicketView } from '../engagement/TicketView'
import { Inbox } from '../engagement/Inbox'
import { Archive } from '../engagement/Archive'
import { useProjectNavigate } from './helpers/use-project-navigate'
import { useEffectWithIdComparison } from './hooks/useEffectWithIdComparison'
import { CustomTour } from '../common/guides/CustomTour'
import { PublishingPostView } from '../publishing/PublishingPostView'
import { fetchFederatedIdentites, fetchPermissionsForProject } from '../settings/user-management/UserActions'
import { TicketsAssignedToOthers } from '../engagement/TicketsAssignedToOthers'
import { TicketsAssignedToMe } from '../engagement/TicketsAssignedToMe'
import { PublishingListView } from '../publishing/PublishingListCardComponents/PublishingListView'
import { isDeviceInLandscape, isLaunchedInStandalone, isMobileTablet } from './theme/helper'
import { PrimaryNavigationMobile } from '../navigation/PrimaryNavigationMobile'
import { TicketViewMobile } from '../engagement/TicketViewMobile'
import { PleaseInstallStandalone } from '../common/PleaseInstallStandalone'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { MobileRevealLoader } from '../common/MobileRevealLoader'
import { InfoOutlined } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { NotificationSettings } from '../settings/notifications/NotificationSettings'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from './ErrorComponent'
import { SnackbarHandler } from '../common/success-snackbar/SnackbarHandler'
import { ConversionTrackingAds } from '../conversion-tracking/ConversionTrackingAds'
import { ConversionTrackingDailyPerformance } from '../conversion-tracking/ConversionTrackingDailyPerformance'

interface AppProps {}

gsap.registerPlugin(ScrollTrigger)

export const App: FC<AppProps> = () => {
    const dispatch = useDispatch<HmstrDispatch>()
    const location = useLocation()
    const infoDialogOpen = useSelector(getShowInfoDialog)
    const showPreviewDialog = useSelector(getShowResultsPreview)
    const apiError = useSelector(getApiError)
    const runningExportsPresent = useSelector(getRunningExports).length > 0
    const fullscreenDialog = useSelector(getFullscreenDialog)
    const currentUser = useSelector(getCurrentUser)
    const selectedProject = useSelector(getSelectedProject)
    const appThemeColor = useSelector(getAppThemeColor)
    const userMenuMobileExpanded = useSelector(getMobileUserMenuExpanded)
    const darkMode = useSelector(isDarkMode)

    const [showAdBlockerHint, setShowAdBlockerHint] = useState(false)
    const [isLandscape, setIsLandscape] = useState(isDeviceInLandscape())
    const [isScreenToSmall, setIsScreenToSmall] = useState(false)

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', `${userMenuMobileExpanded ? darken(appThemeColor, 0.5) : appThemeColor}`)
    }, [appThemeColor, userMenuMobileExpanded, darkMode])

    useEffect(() => {
        const adBlockerInactive = (window as any).adBlockerInactive

        if (!adBlockerInactive) {
            setShowAdBlockerHint(true)
        }

        dispatch(fetchGatewayVersion())
        dispatch(fetchFederatedIdentites(currentUser))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const portrait = window.matchMedia('(orientation: portrait)')

    const handleRotationChange = (e: any) => {
        if (e.matches) {
            setIsLandscape(false)
        } else {
            setIsLandscape(true)
        }
    }

    const handleWindowResize = () => {
        if (window.innerWidth <= 1000) {
            setIsScreenToSmall(true)
        } else {
            setIsScreenToSmall(false)
        }
    }

    useEffect(() => {
        portrait.addEventListener('change', handleRotationChange)
        window.addEventListener('resize', handleWindowResize)
        handleWindowResize()
        return () => {
            portrait.removeEventListener('change', handleRotationChange)
            window.removeEventListener('resize', handleWindowResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     dispatch(showWarningSnackbar('current-outage'))
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const currentLocation = useLocation()
    const currentPath = currentLocation.pathname
    const navigate = useProjectNavigate()
    const isUserProjectAdmin = useSelector(isProjectAdmin)
    const allowedModules = useSelector(getAllowedModulesForSelectedProject)
    const isMobile = useSelector(getIsMobile)
    const continueInBrowser = useSelector(getPrefersBrowser)
    const isStandAlone = isLaunchedInStandalone()

    const navigateToNextAvailableModule = () => {
        if (allowedModules.includes('publishing')) {
            navigate('/publishing/calendar')
        } else if (allowedModules.includes('engagement')) {
            navigate('/engagement/tickets/new')
        } else if (allowedModules.includes('analytics')) {
            navigate('/analytics/dashboards')
        } else {
            navigate('/settings/display')
        }
    }

    useEffectWithIdComparison(() => {
        const publishNotAllowed = currentPath.includes('publishing') && !allowedModules.includes('publishing')
        const engageNotAllowed = currentPath.includes('engagement') && !allowedModules.includes('engagement')
        const analyticsNotAllowed = currentPath.includes('analytics') && !allowedModules.includes('analytics')

        if (publishNotAllowed || engageNotAllowed || analyticsNotAllowed) {
            navigateToNextAvailableModule()
        }
    }, [isUserProjectAdmin, currentPath])

    useEffectWithIdComparison(() => {
        if (selectedProject) {
            dispatch(fetchPermissionsForProject(selectedProject))
        }
    }, [selectedProject])

    return (
        <Box
            display="flex"
            height="100dvh"
            flexDirection={isMobile ? 'column' : 'row'}
            sx={{
                '*': {
                    overscrollBehavior: 'none !important',
                    userSelect: isMobile ? 'none !important' : 'unset',
                },
                overscrollBehavior: 'none !important',
                touchAction: 'none',
            }}
        >
            {!isMobile && (
                <>
                    <PrimaryNavigation />
                    <SecondaryNavigation />
                </>
            )}

            {!isStandAlone && isMobile && !continueInBrowser && <PleaseInstallStandalone onContinue={() => dispatch(setPrefersBrowser(true))} />}
            {isStandAlone && isMobile && (
                <>
                    <MobileRevealLoader />
                </>
            )}
            <ErrorBoundary FallbackComponent={ErrorComponent} key={location.pathname}>
                <Routes>
                    <Route path="/:projectId">
                        <Route index element={<Navigate to={isMobile ? 'publishing/list' : 'publishing/calendar'} replace />} />

                        <Route path="publishing">
                            <Route path="calendar/:groupId" element={<PublishingPostView />} />
                            <Route path="list/:groupId" element={<PublishingPostView />} />
                            <Route path="list" element={<PublishingListView />} />
                            <Route path="calendar/new" element={<PublishingPostView />} hasErrorBoundary={true} />
                            <Route path="list/new" element={<PublishingPostView />} />
                            <Route path="calendar" element={isMobile ? <PublishingListView /> : <Calendar />} />
                        </Route>

                        <Route path="engagement" element={<Outlet />}>
                            <Route path="tickets/mine/:ticketId" element={<TicketView />} />
                            <Route path="tickets/new/:ticketId" element={<TicketView />} />
                            <Route path="tickets/others/:ticketId" element={<TicketView />} />
                            <Route path="tickets/archived/:ticketId" element={<TicketView />} />
                            <Route path="tickets/new" element={isMobile ? <TicketViewMobile tab={'new'} /> : <Inbox />} />
                            <Route path="tickets/mine" element={isMobile ? <TicketViewMobile tab={'mine'} /> : <TicketsAssignedToMe />} />
                            <Route path="tickets/others" element={isMobile ? <TicketViewMobile tab={'others'} /> : <TicketsAssignedToOthers />} />
                            <Route path="tickets/archived" element={isMobile ? <TicketViewMobile tab={'archived'} /> : <Archive />} />
                        </Route>

                        <Route path="analytics" element={<Outlet />}>
                            <Route index element={<Navigate to="dashboards" replace />} />
                            <Route path="dashboards/:dashboardId" element={<DynamicDashboardView />} />
                            <Route path="dashboards" element={<Dashboards />} />
                            <Route path="opinions" element={<OpinionLeaders />} />
                            <Route path="results" element={<Results />} />
                            <Route path="tagging" element={<Posts />} />
                            <Route path="tags" element={<Tags />} />
                            <Route path="ads-trend" element={<ConversionTrackingDailyPerformance />} />
                            <Route path="ads-conversions" element={<ConversionTrackingAds />} />
                            <Route path="conversions" element={<ConversionTracking />} />
                            <Route path="ct-management" element={<ConversionTrackingManagement />} />
                            <Route path="post-analysis" element={<PostAnalysis />} />
                        </Route>

                        <Route
                            path="settings"
                            element={
                                <Box flexGrow={1} height="100dvh" overflow="auto" pb={4}>
                                    <Outlet />
                                </Box>
                            }
                        >
                            <Route index element={<Navigate to="data-sources" replace />} />
                            <Route path="data-sources" element={<ProjectDatasourceOverview />} />
                            <Route path="project-settings" element={<ProjectForm />} />
                            <Route path="linked-accounts" element={<AccountLinking />} />
                            <Route path="display" element={<DisplaySettings />} />
                            <Route path="billing/*" element={<Billing />} />
                            <Route path="account-settings" element={<AccountOverview />} />
                            <Route path="manage-users" element={<UserManagement />} />
                            <Route path="projects" element={<ProjectManagement />} />
                            <Route path="notifications/*" element={<NotificationSettings />} />
                            <Route path="notifications" element={<Navigate to="engage" replace />} />
                        </Route>
                    </Route>
                </Routes>
            </ErrorBoundary>

            <CustomTour continuous disableScrolling />
            <InfoDialog open={infoDialogOpen} />
            <ErrorDialog apiError={apiError} />
            <ResultsPreview open={showPreviewDialog} />
            <SnackbarHandler />
            <AdBlockerDialog open={showAdBlockerHint} onClose={() => setShowAdBlockerHint(false)} />
            {runningExportsPresent && <ExportHint />}

            <FullscreenDialog open={Boolean(fullscreenDialog)} keepMounted={false} handleClose={() => dispatch(closeFullscreenDialog())}>
                {fullscreenDialog === 'new-account' && <AccountCreateWizard />}
                {fullscreenDialog === 'invite-user' && <UserInvite />}
                {fullscreenDialog === 'edit-user' && <UserEdit />}
                {fullscreenDialog === 'edit-invite' && <InviteEdit />}
                {fullscreenDialog === 'new-project' && <ProjectNewWizard />}
                {fullscreenDialog === 'extend-account' && <AccountUpdateSubscriptionWizard />}
                {fullscreenDialog === 'new-dashboard' && <DashboardNew />}
                {fullscreenDialog === 'edit-dashboard' && <DashboardEdit />}
            </FullscreenDialog>
            {isMobile && <PrimaryNavigationMobile />}
            {!isMobile && ((!isLandscape && isMobileTablet()) || isScreenToSmall) && (
                <Dialog
                    open={(!isLandscape && isMobileTablet()) || isScreenToSmall}
                    fullScreen={isScreenToSmall}
                    PaperProps={{
                        sx: isScreenToSmall
                            ? {
                                  display: 'flex',
                                  justifyContent: 'center',
                                  height: '100dvh',
                                  width: '100dvw',
                                  flexGrow: 1,
                                  flexShrink: 0,
                              }
                            : {},
                    }}
                >
                    <DialogTitle
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                        color={'secondary'}
                    >
                        <InfoOutlined fontSize={'small'} />
                        <FormattedMessage id={'general.first-time-app-launch.title'} />
                    </DialogTitle>
                    <DialogContent sx={{ flexGrow: 0 }}>
                        <FormattedMessage id={isMobileTablet() ? 'general.screen-to-small.please-rotate' : 'general.screen-to-small'} />
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    )
}
