import { Alert, Box, Button, Container, DialogActions, Step, StepLabel, Stepper, Typography } from '@mui/material'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { closeFullscreenDialog, getSelectedAccountId, selectProject, showErrorSnackbar, showSuccessSnackbar } from '../../core/slices/CoreSlice'
import { HmstrDispatch } from '../../core/Store'
import { fetchCurrentUser } from '../user-management/UserActions'
import { createProject } from './ProjectActions'
import { FORM_ERROR, FormApi } from 'final-form'
import { Project } from './Project'
import { Form } from 'react-final-form'
import { ProjectNewWizardMetadata } from './ProjectNewWizardMetadata'
import { DatasourceManagement } from '../datasources/DatasourceManagement'
import { ProjectDatasource } from '../datasources/ProjectDatasource'
import { PotentialDatasource } from '../datasources/PotentialDatasource'
import { getAccounts, IdMap } from '../../core/slices/DataSlice'
import { getAccountIdForProjectWizard, setAccountIdForProjectWizard } from '../SettingsSlice'
import { Info } from '@mui/icons-material'

type ProjectNewWizardProps = {}

export const ProjectWizardSteps = ['projects.wizard.datasources', 'projects.wizard.metadata']

export const ProjectNewWizard: FC<ProjectNewWizardProps> = () => {
    const dispatch: HmstrDispatch = useDispatch<HmstrDispatch>()
    const selectedAccountId = useSelector(getSelectedAccountId)
    const accountIdForProjectWizard = useSelector(getAccountIdForProjectWizard)
    const [projectWizardStep, setProjectWizardStep] = useState(0)
    const [datasources, setDatasources] = useState<IdMap<ProjectDatasource>>({})
    const accounts = useSelector(getAccounts)
    const accountForProject = accounts[accountIdForProjectWizard || selectedAccountId || '']

    const handleSubmit = async (project: Project) => {
        return await new Promise((resolve) =>
            dispatch(
                createProject({
                    ...project,
                    data_sources: Object.values(datasources),
                })
            ).then((action) => {
                if (action.meta.requestStatus === 'fulfilled') {
                    const createdProject = action.payload as Project
                    dispatch(selectProject(createdProject.id))
                    dispatch(fetchCurrentUser())
                    dispatch(setAccountIdForProjectWizard(undefined))
                    dispatch(closeFullscreenDialog())
                    dispatch(showSuccessSnackbar('projects.created'))
                    resolve({})
                } else {
                    dispatch(showErrorSnackbar('projects.create-failed'))
                    resolve({ [FORM_ERROR]: 'projects.create-failed' })
                }
            })
        )
    }

    const handleContinue = (values: Project, form: FormApi<Project>) => {
        if (projectWizardStep === ProjectWizardSteps.length - 1) {
            form.submit()
        } else {
            setProjectWizardStep(projectWizardStep + 1)
        }
    }

    const handleDatasourceSelect = (potentialDatasource: PotentialDatasource) => {
        setDatasources({ ...datasources, [potentialDatasource.metadata.id]: potentialDatasource as ProjectDatasource })
    }

    const handleDatasourceDelete = (datasourceId: string) => {
        const newDatasources = { ...datasources }
        delete newDatasources[datasourceId]
        setDatasources(newDatasources)
    }

    const handleDatasourceEdit = (projectDatasource: ProjectDatasource) => {
        const newDatasources = { ...datasources }
        newDatasources[projectDatasource.metadata.id] = projectDatasource
        setDatasources(newDatasources)
    }

    return (
        <Container maxWidth="lg">
            <Box marginTop={4} marginBottom={4}>
                <Box marginBottom={4}>
                    <Typography variant="h4">
                        <FormattedMessage
                            id="projects.create"
                            values={{
                                accountName: accountForProject?.name,
                            }}
                        />
                    </Typography>
                </Box>

                <Stepper activeStep={projectWizardStep}>
                    {ProjectWizardSteps.map((label) => {
                        return (
                            <Step key={label}>
                                <StepLabel>
                                    <FormattedMessage id={label} />
                                </StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>

                <Box sx={{ mt: 4 }}>
                    <Alert color="info" icon={<Info />}>
                        <Typography>
                            <FormattedMessage id="projects.datasource-hint" />
                        </Typography>
                    </Alert>
                </Box>

                <Form<Project>
                    initialValues={{ account_id: accountIdForProjectWizard || selectedAccountId }}
                    onSubmit={handleSubmit}
                    render={({ handleSubmit, submitting, values, form, errors }) => {
                        const lastStep = projectWizardStep === ProjectWizardSteps.length - 1
                        return (
                            <Box>
                                <form onSubmit={handleSubmit}>
                                    {projectWizardStep === 0 && (
                                        <DatasourceManagement
                                            selectedDatasources={Object.values(datasources)}
                                            onSelect={handleDatasourceSelect}
                                            onDelete={handleDatasourceDelete}
                                            onEdit={handleDatasourceEdit}
                                            limitExceeded={
                                                accountForProject.data_source_count + Object.values(datasources).length >= accountForProject.data_source_limit
                                            }
                                        />
                                    )}
                                    {projectWizardStep === 1 && <ProjectNewWizardMetadata />}

                                    <DialogActions sx={{ p: 0, mt: 2 }}>
                                        <Button color="inherit" onClick={() => setProjectWizardStep(projectWizardStep - 1)} disabled={projectWizardStep === 0}>
                                            <FormattedMessage id="general.back" />
                                        </Button>

                                        <Button
                                            variant={lastStep ? 'contained' : 'text'}
                                            color={lastStep ? 'secondary' : 'inherit'}
                                            onClick={() => handleContinue(values, form)}
                                            disabled={
                                                submitting ||
                                                (errors && Object.keys(errors).length > 0) ||
                                                (projectWizardStep === 0 && Object.values(datasources).length === 0)
                                            }
                                        >
                                            <FormattedMessage id={lastStep ? 'general.save' : 'general.continue'} />
                                        </Button>
                                    </DialogActions>
                                </form>
                            </Box>
                        )
                    }}
                />
            </Box>
        </Container>
    )
}
