import { FC } from 'react'
import { List, ListSubheader } from '@mui/material'
import {
    CurrencyExchange,
    Dashboard as DashboardIcon,
    List as ListIcon,
    PostAdd as PostAddIcon,
    RecordVoiceOver,
    Sell,
    Settings,
    Storefront,
    TrendingUp,
} from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { FavoriteDashboardsList } from '../../dashboards/FavoriteDashboardsList'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import { useSelector } from 'react-redux'
import { getSelectedAccount, getSelectedProject, isProjectAdmin } from '../../core/slices/CoreSlice'
import { Account } from '../../settings/accounts/Account'
import { SecondaryNavigationButton } from '../SecondaryNavigationButton'

type AnalyticsNavigationProps = {}

export const AnalyticsNavigation: FC<AnalyticsNavigationProps> = () => {
    const selectedAccount = useSelector(getSelectedAccount) as Account | undefined
    const selectedProject = useSelector(getSelectedProject)
    const isUserProjectAdmin = useSelector(isProjectAdmin)

    const datasources = selectedProject?.data_sources || []
    const hasMonitoringSources = datasources.filter((ds) => ds.category === 'DATA_STREAMS').length > 0
    const hasConversionTracking = (selectedAccount?.conversion_tracking_limit || 0) > 0

    return (
        <List>
            <ListSubheader>
                <FormattedMessage id="reporting.title" />
            </ListSubheader>

            <SecondaryNavigationButton path="/analytics/dashboards" title="reporting.dashboards.title" icon={<DashboardIcon />} />

            <FavoriteDashboardsList />

            <ListSubheader>
                <FormattedMessage id="tagging.title" />
            </ListSubheader>

            <SecondaryNavigationButton path="/analytics/tagging" icon={<PostAddIcon />} title="tagging.posts.title" />
            <SecondaryNavigationButton path="/analytics/post-analysis" icon={<QueryStatsIcon />} title="post.analysis.title" />
            {isUserProjectAdmin && <SecondaryNavigationButton path="/analytics/tags" icon={<Sell />} title="tagging.tags.title" />}

            {hasMonitoringSources && (
                <>
                    <ListSubheader>
                        <FormattedMessage id="web-monitoring.title" />
                    </ListSubheader>

                    <SecondaryNavigationButton path="/analytics/results" icon={<ListIcon />} title="results.title" />
                    <SecondaryNavigationButton path="/analytics/opinions" icon={<RecordVoiceOver />} title="opinions.title" />
                </>
            )}
            {hasConversionTracking && (
                <>
                    <ListSubheader>
                        <FormattedMessage id="conversion-tracking.title" />
                    </ListSubheader>

                    <SecondaryNavigationButton path="/analytics/ads-trend" icon={<TrendingUp />} title="conversion-tracking.ads-trends" />
                    <SecondaryNavigationButton path="/analytics/ads-conversions" icon={<Storefront />} title="conversion-tracking.ads-conversions" />
                    <SecondaryNavigationButton path="/analytics/conversions" icon={<CurrencyExchange />} title="conversion-tracking.conversions" />
                    <SecondaryNavigationButton path="/analytics/ct-management" icon={<Settings />} title="conversion-tracking.management" />
                </>
            )}
        </List>
    )
}
