import * as React from 'react'
import { CSSProperties, FC, useEffect } from 'react'
import { Box } from '@mui/material'
import gsap, { Linear } from 'gsap'

type GooeyLoaderProps = { size: number; animationDuration?: number }

export const GooeyLoader: FC<GooeyLoaderProps> = ({ size, animationDuration }) => {
    const duration = animationDuration || 4
    const id = `${size}${animationDuration}`
    const loaderStyles: CSSProperties = {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        height: '100%',
        top: '50%',
        left: '50%',
        marginLeft: `-${size / 2}px`,
        marginTop: `-${size / 2}px`,
    }

    const rectStyles: CSSProperties = {
        transformOrigin: '50% 50%',
    }

    useEffect(() => {
        let radius = 1
        gsap.fromTo(`.blob${id}`, { opacity: 0 }, { opacity: 1, duration: duration / 5, stagger: duration / 5 })
        gsap.fromTo(
            `.blob${id}`,
            {
                rotate: 0,
                translateY: radius,
                scale: 1,
            },
            {
                rotate: 360,
                translateY: radius,
                duration: duration,
                ease: Linear.easeNone,
                repeat: -1,
                stagger: {
                    each: duration / 5,
                    repeat: -1,
                },
            }
        )
    }, [duration, id])

    return (
        <Box
            sx={{
                width: `${size}px`,
                height: `${size}px`,
                '& .blobs': {
                    webkitFilter: 'url("#goo")',
                    filter: 'url("#goo")',
                },
                '& .blob': {
                    fill: 'white',
                },
            }}
        >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" style={loaderStyles}>
                <defs>
                    <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
                        <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="
                            1 0 0 0 0
                            0 1 0 0 0
                            0 0 1 0 0
                            0 0 0 50 -16
                        "
                            result="matrix"
                        />
                        <feBlend in2="goo" in="SourceGraphic" result="mix" />
                    </filter>
                    <linearGradient id="MyGradient">
                        {/*<stop offset="0%" stopColor="#2E3192" />*/}
                        {/*<stop offset="100%" stopColor="#1BFFFF" />*/}
                        <stop offset="0%" stopColor="#ff6900" />
                        <stop offset="100%" stopColor="#ff00b3" />
                    </linearGradient>
                </defs>
                <mask id="maska">
                    <g className="blobs">
                        <circle className={`blob${id} blob`} cx={(440 / 800) * size} cy={(250 / 800) * size} r={(40 / 800) * size} />
                        <circle className={`blob${id} blob`} cx={(500 / 800) * size} cy={(320 / 800) * size} r={(70 / 800) * size} />
                        <circle className={`blob${id} blob`} cx={(300 / 800) * size} cy={(390 / 800) * size} r={(45 / 800) * size} />
                        <circle className={`blob${id} blob`} cx={(380 / 800) * size} cy={(480 / 800) * size} r={(80 / 800) * size} />
                        <circle className={`blob${id} blob`} cx={(470 / 800) * size} cy={(450 / 800) * size} r={(40 / 800) * size} />
                    </g>
                </mask>
                <rect x={0} y={0} mask="url(#maska)" fill="url(#MyGradient)" width={size} height={size} style={rectStyles} />
            </svg>
        </Box>
    )
}
