import { createAsyncThunk } from '@reduxjs/toolkit'
import { Project } from '../settings/project-management/Project'
import { HmstrDispatch, HmstrState } from '../core/Store'
import { ApiError, doGet, doPost, doPut } from '../core/api/ApiClient'
import { SimpleTicket } from './SimpleTicket'
import { DetailedTicket } from './DetailedTicket'
import { UpdateTicketNodeRequest } from './UpdateTicketNodeRequest'
import { TicketNode } from './TicketNode'
import { User } from '../settings/user-management/User'
import { EngagmentState } from './EngagementSlice'
import { ProjectDatasourceType } from '../settings/datasources/ProjectDatasource'
import { TicketState } from './TicketState'

const ENGAGEMENT_SLASH = (action: string) => `engagement/${action}`

export const fetchSimpleTicketsForProject = createAsyncThunk<
    SimpleTicket[],
    {
        project: Project
        page?: number
        tab: 'others' | 'mine' | 'archived' | 'new'
        entriesPerPage?: number
        datasource_ids?: string[]
        lazyScroll?: boolean
    },
    { state: HmstrState }
>(ENGAGEMENT_SLASH('fetchSimpleTicketsForProject'), async (args, thunkAPI) => {
    return await doGet(thunkAPI, args.project._links.tickets, undefined, {
        params: {
            page: args.page,
            tab: args.tab,
            entries_per_page: args.entriesPerPage,
            datasource_ids: args.datasource_ids,
        },
    })
})

export const fetchDetailedTicketById = createAsyncThunk<
    DetailedTicket,
    {
        project: Project
        ticket_id: string
    },
    { state: HmstrState }
>(ENGAGEMENT_SLASH('fetchDetailedTicketById'), async (args, thunkAPI) => {
    return await doGet(thunkAPI, args.project._links.tickets.href + `/${args.ticket_id}`)
})

export const fetchOpenTicketCountForUser = createAsyncThunk<{ [_id: string]: number }, User, { state: HmstrState }>(
    ENGAGEMENT_SLASH('fetchOpenTicketCountForProject'),
    async (currentUser, thunkAPI) => {
        return await doGet(thunkAPI, currentUser._links.open_ticket_counts)
    }
)

export const fetchOpenTicketCountForUserByCategory = createAsyncThunk<
    EngagmentState['openTicketCountForProjectByCategory'],
    {
        project: Project
        datasource_ids: string[]
    },
    { state: HmstrState }
>(ENGAGEMENT_SLASH('fetchOpenTicketCountForProjectByCategory'), async (args, thunkAPI) => {
    return await doGet(thunkAPI, args.project._links.open_ticket_counts_by_category, undefined, {
        params: { datasource_ids: args.datasource_ids },
    })
})

export const fetchDetailedTicket = createAsyncThunk<
    DetailedTicket,
    DetailedTicket | SimpleTicket | string,
    {
        state: HmstrState
        dispatch: HmstrDispatch
        rejectValue: ApiError
    }
>(ENGAGEMENT_SLASH('fetchDetailedTicket'), async (ticket, thunkAPI) => {
    if (typeof ticket === 'string') {
        return await doGet<DetailedTicket>(thunkAPI, ticket)
    }

    return await doGet<DetailedTicket>(thunkAPI, ticket._links.self)
})

export type CreateTicketNodeRequest = {
    ticket_id: string
    data_source_id: string
    target_id: string
    message?: string
}

export const createTicketNode = createAsyncThunk<
    DetailedTicket,
    {
        ticket: DetailedTicket
        payload: CreateTicketNodeRequest
    }
>(ENGAGEMENT_SLASH('createTicketNode'), async ({ ticket, payload }, thunkAPI) => {
    return doPost(thunkAPI, ticket._links.nodes, payload)
})
export type CreateInternalNoteRequest = {
    message: string
    file?: any
    data_source_id: string
    data_source_name: string
    data_source_type: ProjectDatasourceType
}
export const addTicketNote = createAsyncThunk<
    DetailedTicket,
    {
        ticket: DetailedTicket
        payload: CreateInternalNoteRequest
    }
>(ENGAGEMENT_SLASH('addTicketNote'), async ({ ticket, payload }, thunkAPI) => {
    return doPost(thunkAPI, ticket._links.notes, payload)
})

export const updateComment = createAsyncThunk<
    DetailedTicket,
    { request: UpdateTicketNodeRequest; node: TicketNode },
    {
        state: HmstrState
    }
>(ENGAGEMENT_SLASH('updateComment'), async (args, thunkAPI) => {
    return await doPut(thunkAPI, args.node._links.self, args.request)
})

export const updateTicketNodes = createAsyncThunk<
    DetailedTicket,
    { request: { is_read: boolean }; detailedTicket: DetailedTicket },
    {
        state: HmstrState
    }
>(ENGAGEMENT_SLASH('updateComment'), async (args, thunkAPI) => {
    return await doPut(thunkAPI, args.detailedTicket._links.nodes, args.request)
})

export type UpdateTicketRequest = {
    state: TicketState
    assignee_id?: string
    data_source_name: string
    data_source_id: string
    data_source_type: ProjectDatasourceType
} & (SimpleTicket | DetailedTicket)

export const updateTicket = createAsyncThunk<DetailedTicket, UpdateTicketRequest>(ENGAGEMENT_SLASH('updateTicket'), async (ticket, thunkAPI) => {
    return await doPut(thunkAPI, ticket._links.self.href, ticket)
})

export const updateMultipleTickets = createAsyncThunk<DetailedTicket, { project: Project; updates: UpdateTicketRequest[] }>(
    ENGAGEMENT_SLASH('updateMultipleTickets'),
    async (args, thunkAPI) => {
        return await doPut(thunkAPI, args.project._links.tickets, args.updates)
    }
)

export type FindOrCreateMissingTicketRequest = {
    data_source_id: string
    target_id: string
    timestamp: string
    message: string
    node_id: string
    author: {
        id: string
        name?: string
        profile_pic?: string
    }
    is_liked: boolean
    story?: TicketNode['story']
}

export const findOrCreateMissingTicket = createAsyncThunk<
    SimpleTicket,
    {
        project: Project
        request: FindOrCreateMissingTicketRequest
    },
    { state: HmstrState }
>(ENGAGEMENT_SLASH('findOrCreateMissingTicket'), async ({ project, request }, thunkAPI) => {
    return await doPost(thunkAPI, project._links.tickets, request)
})
