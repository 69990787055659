import { FC, PropsWithChildren } from 'react'
import { Box } from '@mui/material'

type TabPanelProps = {
    index: any
    value: any
    keepMounted?: boolean
}

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = (props) => {
    const { children, value, index, keepMounted, ...other } = props

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {keepMounted ? <Box>{children}</Box> : value === index && <Box>{children}</Box>}
        </div>
    )
}
