import * as React from 'react'
import { FC } from 'react'
import { Box, keyframes } from '@mui/material'

type AppInstallProps = {}

export const AppInstall: FC<AppInstallProps> = () => {
    const iconAnimation = keyframes`
      0% {
        transform: rotate(0deg) scale(1)
      }
      50% {
        transform: rotate(5deg) scale(1.2);
        filter: drop-shadow(0px 0px 10px);
      }
      100% {
        transform: rotate(0deg) scale(1)
      }
    `
    return (
        <Box
            sx={{
                '.installIcon': {
                    animation: `${iconAnimation} 5s infinite`,
                    transformOrigin: 'center center',
                },
            }}
        >
            <svg data-name="Layer 1" viewBox="0 0 738 729.04651">
                <defs></defs>
                <path
                    d="M781.44466,258.424h-3.99878V148.87868a63.40186,63.40186,0,0,0-63.4018-63.40193H481.95735a63.40186,63.40186,0,0,0-63.402,63.4017v600.9744a63.40189,63.40189,0,0,0,63.4018,63.40191H714.04378a63.40187,63.40187,0,0,0,63.402-63.40167V336.40024h3.99878Z"
                    transform="translate(-231 -85.47675)"
                    fill="#e6e6e6"
                ></path>
                <path
                    d="M763.95107,149.32105v600.09a47.35073,47.35073,0,0,1-47.35,47.35h-233.2a47.35085,47.35085,0,0,1-47.35-47.35v-600.09a47.35089,47.35089,0,0,1,47.35-47.35h28.29a22.50661,22.50661,0,0,0,20.83,30.99h132.96a22.50674,22.50674,0,0,0,20.83-30.99h30.29A47.35088,47.35088,0,0,1,763.95107,149.32105Z"
                    fill="#fff"
                    transform="translate(-231 -85.47675)"
                ></path>
                <path
                    d="M535.60344,266.51629h-35.8121a11.14181,11.14181,0,0,1-11.12921-11.12921V219.57515a11.1418,11.1418,0,0,1,11.12921-11.12921h35.8121a11.1418,11.1418,0,0,1,11.1292,11.12921v35.81193A11.1418,11.1418,0,0,1,535.60344,266.51629Z"
                    transform="translate(-231 -85.47675)"
                    fill="#ff6900"
                ></path>
                <path
                    d="M617.90712,266.51629H582.095a11.1418,11.1418,0,0,1-11.12921-11.12921V219.57515A11.1418,11.1418,0,0,1,582.095,208.44594h35.8121a11.1418,11.1418,0,0,1,11.1292,11.12921v35.81193A11.1418,11.1418,0,0,1,617.90712,266.51629Z"
                    transform="translate(-231 -85.47675)"
                    fill="#ff6900"
                ></path>
                <path
                    d="M700.2108,266.51629H664.3987a11.1418,11.1418,0,0,1-11.12921-11.12921V219.57515a11.1418,11.1418,0,0,1,11.12921-11.12921h35.8121A11.1418,11.1418,0,0,1,711.34,219.57515v35.81193A11.1418,11.1418,0,0,1,700.2108,266.51629Z"
                    transform="translate(-231 -85.47675)"
                    fill="#e6e6e6"
                ></path>
                <path
                    d="M535.60344,358.025h-35.8121a11.1418,11.1418,0,0,1-11.12921-11.12921V311.08384a11.14181,11.14181,0,0,1,11.12921-11.12921h35.8121a11.1418,11.1418,0,0,1,11.1292,11.12921v35.81193A11.1418,11.1418,0,0,1,535.60344,358.025Z"
                    transform="translate(-231 -85.47675)"
                    fill="#e6e6e6"
                ></path>
                <path
                    d="M617.90712,358.025H582.095a11.1418,11.1418,0,0,1-11.12921-11.12921V311.08384A11.1418,11.1418,0,0,1,582.095,299.95463h35.8121a11.1418,11.1418,0,0,1,11.1292,11.12921v35.81193A11.1418,11.1418,0,0,1,617.90712,358.025Z"
                    transform="translate(-231 -85.47675)"
                    fill="#e6e6e6"
                ></path>
                <path
                    d="M700.2108,358.025H664.3987a11.1418,11.1418,0,0,1-11.12921-11.12921V311.08384a11.1418,11.1418,0,0,1,11.12921-11.12921h35.8121A11.1418,11.1418,0,0,1,711.34,311.08384v35.81193A11.1418,11.1418,0,0,1,700.2108,358.025Z"
                    transform="translate(-231 -85.47675)"
                    fill="#e6e6e6"
                ></path>
                <path
                    d="M535.60344,449.51629h-35.8121a11.14181,11.14181,0,0,1-11.12921-11.12921V402.57515a11.1418,11.1418,0,0,1,11.12921-11.12921h35.8121a11.1418,11.1418,0,0,1,11.1292,11.12921v35.81193A11.1418,11.1418,0,0,1,535.60344,449.51629Z"
                    transform="translate(-231 -85.47675)"
                    fill="#e6e6e6"
                ></path>
                <path
                    d="M617.90712,449.51629H582.095a11.1418,11.1418,0,0,1-11.12921-11.12921V402.57515A11.1418,11.1418,0,0,1,582.095,391.44594h35.8121a11.1418,11.1418,0,0,1,11.1292,11.12921v35.81193A11.1418,11.1418,0,0,1,617.90712,449.51629Z"
                    transform="translate(-231 -85.47675)"
                    fill="#e6e6e6"
                ></path>
                <path
                    d="M700.2108,449.51629H664.3987a11.1418,11.1418,0,0,1-11.12921-11.12921V402.57515a11.1418,11.1418,0,0,1,11.12921-11.12921h35.8121A11.1418,11.1418,0,0,1,711.34,402.57515v35.81193A11.1418,11.1418,0,0,1,700.2108,449.51629Z"
                    transform="translate(-231 -85.47675)"
                    fill="#e6e6e6"
                ></path>
                <path
                    d="M535.60344,541.025h-35.8121a11.1418,11.1418,0,0,1-11.12921-11.12921V494.08384a11.14181,11.14181,0,0,1,11.12921-11.12921h35.8121a11.1418,11.1418,0,0,1,11.1292,11.12921v35.81193A11.1418,11.1418,0,0,1,535.60344,541.025Z"
                    fill="#e6e6e6"
                    transform="translate(-231 -85.47675)"
                ></path>
                <path
                    d="M617.90712,541.025H582.095a11.1418,11.1418,0,0,1-11.12921-11.12921V494.08384A11.1418,11.1418,0,0,1,582.095,482.95463h35.8121a11.1418,11.1418,0,0,1,11.1292,11.12921v35.81193A11.1418,11.1418,0,0,1,617.90712,541.025Z"
                    fill="#e6e6e6"
                    transform="translate(-231 -85.47675)"
                ></path>
                <path
                    d="M700.2108,541.025H664.3987a11.1418,11.1418,0,0,1-11.12921-11.12921V494.08384a11.1418,11.1418,0,0,1,11.12921-11.12921h35.8121A11.1418,11.1418,0,0,1,711.34,494.08384v35.81193A11.1418,11.1418,0,0,1,700.2108,541.025Z"
                    fill="#e6e6e6"
                    transform="translate(-231 -85.47675)"
                ></path>
                <g>
                    <path
                        d="M 409.941 444.936 L 374.129 444.936 C 367.71 444.929 362.507 439.726 362.5 433.307 L 362.5 397.495 C 362.508 391.075 367.71 385.873 374.129 385.866 L 409.941 385.866 C 416.361 385.873 421.563 391.075 421.571 397.495 L 421.571 433.307 C 421.563 439.726 416.361 444.929 409.941 444.936 Z"
                        fill="#ff6900"
                        className={'installIcon'}
                    ></path>
                    <path
                        d="M 240.496 537.352 C 246.26 534.483 248.031 527.102 244.194 521.931 L 251.928 446.32 L 235.215 446.32 L 229.069 520.15 C 222.97 525.334 224.77 535.176 232.309 537.866 C 234.993 538.824 237.953 538.638 240.496 537.352 Z"
                        fill="#ffb8b8"
                    ></path>
                    <circle cx="255.84119" cy="380.94671" r="23.64504" fill="#ffb8b8"></circle>
                    <path
                        d="M 260.293 490.353 C 258.347 490.353 256.642 489.053 256.125 487.177 C 254.231 480.295 249.302 468.657 241.476 452.588 C 233.991 437.189 241.593 418.706 257.747 413.03 C 272.963 407.657 289.611 415.91 294.548 431.274 C 300.614 450.291 300.321 470.662 299.913 478.537 C 299.809 480.486 298.417 482.126 296.511 482.546 L 261.222 490.253 C 260.917 490.32 260.605 490.353 260.293 490.353 Z"
                        fill="#ccc"
                    ></path>
                    <polygon points="294.466 714.884 306.269 714.883 311.884 669.359 294.464 669.36 294.466 714.884" fill="#ffb8b8"></polygon>
                    <path
                        d="M 291.937 711.511 L 329.031 711.511 C 329.031 711.511 329.031 711.511 329.031 711.511 L 329.031 725.842 C 329.031 725.842 329.031 725.842 329.031 725.842 L 306.269 725.842 C 298.354 725.842 291.937 719.426 291.937 711.511 C 291.937 711.511 291.937 711.511 291.937 711.511 Z"
                        fill="#2f2e41"
                        transform="matrix(-1, 0.000046, -0.000046, -1, -0.000053, -0.000113)"
                    ></path>
                    <polygon points="176.447 699.587 186.79 705.273 213.644 668.087 198.379 659.695 176.447 699.587" fill="#ffb8b8"></polygon>
                    <path
                        d="M 170.109 703.463 L 207.203 703.463 C 207.203 703.463 207.203 703.463 207.203 703.463 L 207.203 717.795 C 207.203 717.795 207.203 717.795 207.203 717.795 L 184.44 717.795 C 176.525 717.795 170.109 711.378 170.109 703.463 C 170.109 703.463 170.109 703.463 170.109 703.463 Z"
                        fill="#2f2e41"
                        transform="matrix(-0.876299, -0.481768, 0.481768, -0.876299, -0.000043, -0.000081)"
                    ></path>
                    <path
                        d="M 293.915 689.615 C 291.739 689.613 289.903 687.998 289.623 685.841 L 274.862 572.099 C 274.624 570.261 272.486 569.371 271.014 570.496 C 270.749 570.698 270.529 570.954 270.368 571.246 L 208.853 682.853 C 207.803 684.736 205.535 685.569 203.517 684.811 L 190.108 679.702 C 187.836 678.837 186.721 676.273 187.638 674.021 L 230.251 569.339 C 230.359 569.073 230.432 568.795 230.469 568.51 C 236.241 523.837 250.926 501.12 255.289 495.221 C 255.936 494.341 256.114 493.202 255.766 492.167 L 255.167 490.367 C 254.679 488.914 254.996 487.312 256 486.154 C 271.722 468.246 299.126 478.018 299.401 478.118 L 299.538 478.169 L 299.624 478.286 C 330.351 520.426 318.99 657.296 316.401 684.305 C 316.201 686.401 314.52 688.048 312.421 688.204 L 294.251 689.603 C 294.138 689.612 294.026 689.615 293.915 689.615 Z"
                        fill="#2f2e41"
                    ></path>
                    <path
                        d="M 347.764 408.413 C 347.646 408.932 347.569 409.46 347.534 409.992 L 306.178 433.85 L 296.126 428.063 L 285.41 442.092 L 302.21 454.065 C 304.998 456.052 308.763 455.961 311.45 453.84 L 354.095 420.198 C 361.39 422.898 368.873 416.688 367.564 409.02 C 366.255 401.352 357.136 397.976 351.149 402.944 C 349.456 404.35 348.267 406.27 347.764 408.413 L 347.764 408.413 Z"
                        fill="#ffb8b8"
                    ></path>
                    <path
                        d="M 304.796 434.98 L 291.43 452.302 C 289.814 454.397 286.715 454.567 284.88 452.661 L 269.749 436.949 C 262.45 431.247 264.061 419.782 272.648 416.313 C 276.609 414.712 281.121 415.332 284.504 417.941 L 303.481 428.553 C 305.79 429.844 306.412 432.885 304.796 434.98 L 304.796 434.98 Z"
                        fill="#ccc"
                    ></path>
                    <path
                        d="M 252.991 465.076 L 231.46 461.188 C 228.856 460.718 227.279 458.045 228.125 455.539 L 235.105 434.872 C 236.803 425.767 247.72 421.914 254.756 427.937 C 258.004 430.717 259.532 435.011 258.772 439.217 L 258.091 460.95 C 258.007 463.594 255.595 465.546 252.991 465.076 L 252.991 465.076 Z"
                        fill="#ccc"
                    ></path>
                    <path
                        d="M 259.584 375.508 C 265.661 379.667 273.641 383.943 279.848 379.514 C 283.835 376.477 285.345 371.175 283.555 366.493 C 280.582 357.994 272.417 354.252 264.608 351.384 C 254.457 347.655 243.404 344.689 232.924 347.359 C 222.445 350.03 213.183 360.176 215.075 370.823 C 216.596 379.386 224.673 386.51 223.527 395.131 C 222.373 403.807 212.616 408.374 204.002 409.925 C 195.388 411.475 185.748 411.973 179.558 418.161 C 171.661 426.054 173.633 440.162 181.064 448.495 C 188.495 456.829 199.708 460.606 210.574 463.174 C 224.971 466.577 240.542 468.365 254.216 462.718 C 267.889 457.071 278.529 441.871 274.568 427.618 C 272.895 421.597 268.954 416.509 265.145 411.556 C 261.336 406.602 257.479 401.422 256.026 395.345 C 254.815 390.28 255.711 384.4 259.07 380.601 C 260.323 379.237 260.554 377.221 259.642 375.608 L 259.584 375.508 Z"
                        fill="#2f2e41"
                    ></path>
                    <polygon points="500 716.459 487.74 716.459 481.908 669.171 500.002 669.171 500 716.459" fill="#ffb8b8"></polygon>
                    <path
                        d="M478.98321,712.95555h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H464.09635a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,478.98321,712.95555Z"
                        fill="#2f2e41"
                    ></path>
                    <polygon points="546 716.459 533.74 716.459 527.908 669.171 546.002 669.171 546 716.459" fill="#ffb8b8"></polygon>
                    <path
                        d="M524.98321,712.95555h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H510.09635a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,524.98321,712.95555Z"
                        fill="#2f2e41"
                    ></path>
                    <path
                        d="M 544.503 575.621 C 538.878 572.002 537.891 564.179 542.441 559.278 L 534.368 444.72 L 557.621 446.975 L 558.26 559.161 C 563.966 565.208 560.987 575.165 552.897 577.084 C 550.017 577.767 546.982 577.238 544.503 575.621 L 544.503 575.621 Z"
                        fill="#ffb8b8"
                    ></path>
                    <path
                        d="M 499.421 692.636 L 485.925 691.993 C 483.537 691.88 481.655 689.92 481.639 687.53 L 480.697 550.973 C 480.678 548.218 483.117 546.093 485.843 546.488 L 539.837 554.326 C 542.043 554.634 543.685 556.519 543.691 558.746 L 550.635 685.28 C 550.654 687.778 548.633 689.814 546.135 689.814 L 531.585 689.814 C 529.366 689.823 527.475 688.206 527.139 686.013 L 518.162 628.946 C 517.744 626.284 514.602 625.073 512.506 626.766 C 511.796 627.34 511.338 628.168 511.23 629.074 L 504.103 688.676 C 503.826 690.935 501.911 692.635 499.635 692.642 C 499.564 692.642 499.493 692.64 499.421 692.636 Z"
                        fill="#2f2e41"
                    ></path>
                    <path
                        d="M 508.922 558.752 C 497.023 552.14 487.725 550.403 483.242 549.956 C 482.038 549.839 480.935 549.235 480.189 548.283 C 479.426 547.324 479.09 546.095 479.257 544.881 L 492.195 448.831 C 493.709 437.405 501.038 427.581 511.558 422.874 C 521.742 418.192 533.624 419.124 542.954 425.335 C 543.397 425.629 543.832 425.931 544.259 426.238 C 555.064 434.155 560.049 447.792 556.896 460.811 C 548.962 493.266 546.237 546.473 545.771 556.81 C 545.696 558.612 544.545 560.192 542.853 560.815 C 537.972 562.589 532.821 563.506 527.627 563.526 C 521.086 563.566 514.644 561.921 508.922 558.752 Z"
                        fill="#ff6900"
                    ></path>
                    <path
                        d="M 539.632 483.213 C 538.529 482.419 537.845 481.169 537.773 479.812 L 536.069 448.936 C 535.544 439.406 545.532 432.881 554.048 437.191 C 557.182 438.777 559.496 441.619 560.416 445.009 L 567.9 472.614 C 568.548 475.012 567.131 477.482 564.735 478.135 L 543.444 483.907 C 542.134 484.265 540.732 484.01 539.632 483.213 Z"
                        fill="#ff6900"
                    ></path>
                    <circle cx="519.74361" cy="385.27072" r="24.56103" fill="#ffb8b8"></circle>
                    <path
                        d="M 430.74 433.525 C 430.863 434.065 430.943 434.613 430.979 435.165 L 473.937 459.947 L 484.378 453.937 L 495.509 468.509 L 473.172 484.429 L 424.164 445.767 C 416.587 448.571 408.815 442.12 410.176 434.156 C 411.536 426.192 421.008 422.687 427.225 427.847 C 428.984 429.307 430.218 431.3 430.74 433.525 L 430.74 433.525 Z"
                        fill="#ffb8b8"
                    ></path>
                    <path
                        d="M 475.471 457.84 C 475.326 456.489 475.802 455.146 476.764 454.187 L 498.627 432.318 C 505.376 425.569 516.9 428.656 519.371 437.875 C 520.28 441.268 519.708 444.888 517.796 447.834 L 502.226 471.827 C 500.871 473.909 498.086 474.502 496.001 473.152 L 477.497 461.143 C 476.356 460.406 475.611 459.191 475.471 457.84 Z"
                        fill="#ff6900"
                    ></path>
                    <path
                        d="M 537.124 404.702 C 532.542 409.583 524.033 406.963 523.436 400.295 C 523.389 399.777 523.392 399.256 523.446 398.739 C 523.754 395.786 525.461 393.104 525.052 389.986 C 524.959 389.21 524.67 388.47 524.212 387.837 C 520.561 382.948 511.989 390.024 508.544 385.598 C 506.431 382.884 508.914 378.61 507.293 375.577 C 505.153 371.573 498.814 373.548 494.839 371.356 C 490.416 368.916 490.681 362.131 493.592 358.003 C 497.143 352.97 503.368 350.284 509.516 349.897 C 515.663 349.51 521.768 351.171 527.508 353.408 C 534.029 355.949 540.495 359.462 544.508 365.196 C 549.389 372.169 549.858 381.544 547.418 389.697 C 545.933 394.657 540.866 400.717 537.124 404.702 Z"
                        fill="#2f2e41"
                    ></path>
                </g>
                <path d="M968,814.52325H232a1,1,0,0,1,0-2H968a1,1,0,0,1,0,2Z" transform="translate(-231 -85.47675)" fill="#ccc"></path>
            </svg>
        </Box>
    )
}
