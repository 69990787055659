import * as React from 'react'
import { FC } from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import { InstagramLike } from '../../../common/icons/InstagramLike'
import { InstagramComment } from '../../../common/icons/InstagramComment'
import { InstagramShare } from '../../../common/icons/InstagramShare'
import { InstagramSave } from '../../../common/icons/InstagramSave'
import { useSelector } from 'react-redux'
import { isDarkMode } from '../../../core/slices/CoreSlice'

type InstagramPostActionsPreviewProps = {}

export const InstagramPostActionsPreview: FC<InstagramPostActionsPreviewProps> = () => {
    const darkmode = useSelector(isDarkMode)
    const theme = useTheme()
    const actionColor = darkmode ? theme.palette.primary.contrastText : '#444444'
    return (
        <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item>
                <Box display="flex" alignItems="center">
                    <InstagramLike fontSize="medium" htmlColor={actionColor} />
                </Box>
            </Grid>
            <Grid item>
                <Box display="flex" alignItems="center">
                    <InstagramComment fontSize="medium" htmlColor={actionColor} />
                </Box>
            </Grid>
            <Grid item>
                <Box display="flex" alignItems="center">
                    <InstagramShare fontSize="medium" htmlColor={actionColor} />
                </Box>
            </Grid>
            <Grid item flexGrow={1}>
                <Box display="flex" width={'100%'} alignItems="center" justifyContent="flex-end">
                    <InstagramSave fontSize="medium" htmlColor={actionColor} />
                </Box>
            </Grid>
        </Grid>
    )
}
